<template>
	<div class="grid">
		<ProgressModal :progress="progress">
			<template #message>
				Save data in progress.<br />Please wait...
			</template>
		</ProgressModal>
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<!-- Shipment Info -->
		<div class="col-12 p-fluid mb-4">
			<div class="content-wrapper">
				<div class="content-header">E-Invoice Prepayment Summary</div>
				<div class="content-body">
					<div class="grid">
						<div class="col-12 md:col-6 p-fluid">
							<div class="grid formgrid">
								<div class="col-12 mb-0 lg:col-12 lg:mb-0">
									<div class="form-card">
										<div
											class="flex align-items-center justify-content-between mb-4"
										>
											<span
												style="color: #000"
												class="font-semibold"
												>COMPANY</span
											>
											<span class="font-bold">
												{{ this.company }}
											</span>
										</div>
										<div
											class="flex align-items-center justify-content-between mb-4"
										>
											<span
												style="color: #000"
												class="font-semibold"
												>SUPPLIER</span
											>
											<span class="font-bold">
												{{ this.supplier }}
											</span>
										</div>
										<div
											class="flex align-items-center justify-content-between mb-4"
										>
											<span
												style="color: #000"
												class="font-semibold"
												>SUPPLIER SITE</span
											>
											<span class="font-bold">
												{{ this.supplierSite }}
											</span>
										</div>
									</div>
								</div>
							</div>
							<form
								@submit.prevent="handleSubmit(!v$.$invalid)"
								class="p-fluid"
							>
								<div
									class="grid formgrid"
									style="margin-top: 30px"
								>
									<div class="col-12 mb-5 lg:col-6 lg:mb-5">
										<span class="p-float-label">
											<InputText
												id="username"
												type="text"
												v-model="
													v$.invoiceNumber.$model
												"
												:class="{
													'p-invalid':
														v$.invoiceNumber
															.$invalid &&
														submitted,
												}"
												:maxlength="50"
											/>
											<label for="username"
												>Invoice Number</label
											>
										</span>
										<small
											v-if="
												(v$.invoiceNumber.$invalid &&
													submitted) ||
												v$.invoiceNumber.$pending
													.$response
											"
											class="p-error"
											>{{
												v$.invoiceNumber.required.$message.replace(
													'Value',
													'Invoice Number'
												)
											}}</small
										>
									</div>
									<div class="col-12 mb-5 lg:col-6 lg:mb-5">
										<span class="p-float-label">
											<Calendar
												:showIcon="true"
												:showButtonBar="true"
												id="dateformat"
												dateFormat="dd M yy"
												style="text-align: center"
												v-model="v$.invoiceDate.$model"
												:class="{
													'p-invalid':
														v$.invoiceDate
															.$invalid &&
														submitted,
												}"
											></Calendar>
											<label for="username"
												>Invoice Date</label
											>
										</span>
										<small
											v-if="
												(v$.invoiceDate.$invalid &&
													submitted) ||
												v$.invoiceDate.$pending
													.$response
											"
											class="p-error"
											>{{
												v$.invoiceDate.required.$message.replace(
													'Value',
													'Invoice Date'
												)
											}}</small
										>
									</div>
									<div class="col-12 mb-5 lg:col-6 lg:mb-5">
										<span class="p-float-label">
											<Calendar
												:showIcon="true"
												:showButtonBar="true"
												id="dateformat"
												dateFormat="dd M yy"
												style="text-align: center"
												v-model="v$.glDate.$model"
												:class="{
													'p-invalid':
														v$.glDate.$invalid &&
														submitted,
												}"
											></Calendar>
											<label for="username"
												>GL Date</label
											>
										</span>
										<small
											v-if="
												(v$.glDate.$invalid &&
													submitted) ||
												v$.glDate.$pending.$response
											"
											class="p-error"
											>{{
												v$.glDate.required.$message.replace(
													'Value',
													'GL Date'
												)
											}}</small
										>
									</div>
								</div>
							</form>

							<div class="grid formgrid">
								<div class="col-12 mb-5 lg:col-12 lg:mb-5">
									<span class="p-float-label">
										<InputText
											type="text"
											v-model="v$.description.$model"
											:class="{
												'p-invalid':
													v$.description.$invalid &&
													submitted,
											}"
											:maxlength="240"
										/>
										<label for="username"
											>Description</label
										>
									</span>
									<small
										v-if="
											(v$.description.$invalid &&
												submitted) ||
											v$.description.$pending.$response
										"
										class="p-error"
										>{{
											v$.description.required.$message.replace(
												'Value',
												'Description'
											)
										}}</small
									>
								</div>
							</div>

							<hr />
							<div class="grid formgrid" style="margin-top: 30px">
								<div class="col-12 mb-5 lg:col-6 lg:mb-5">
									<span class="p-float-label">
										<InputText
											id="inputmask"
											@change="handleChangeTax($event)"
											:class="{
												'p-invalid':
													v$.taxInvoice.$invalid &&
													submitted,
											}"
											v-model="v$.taxInvoice.$model"
										/>
										<label for="username"
											>Tax Invoice No.</label
										>
									</span>
									<small
										v-if="
											(v$.taxInvoice.$invalid &&
												submitted) ||
											v$.taxInvoice.$pending.$response
										"
										class="p-error"
										>{{
											v$.taxInvoice.required.$message.replace(
												'Value',
												'Tax Invoice'
											)
										}}</small
									>
								</div>
								<div class="col-12 mb-5 lg:col-6 lg:mb-5">
									<span class="p-float-label">
										<Calendar
											:showIcon="true"
											:showButtonBar="true"
											id="dateformat"
											dateFormat="dd M yy"
											style="text-align: center"
											v-model="v$.taxInvoiceDate.$model"
											:class="{
												'p-invalid':
													v$.taxInvoiceDate
														.$invalid && submitted,
											}"
										></Calendar>
										<label for="username"
											>Tax Invoice Date</label
										>
									</span>
									<small
										v-if="
											(v$.taxInvoiceDate.$invalid &&
												submitted) ||
											v$.taxInvoiceDate.$pending.$response
										"
										class="p-error"
										>{{
											v$.taxInvoiceDate.required.$message.replace(
												'Value',
												'Tax Invoice Date'
											)
										}}</small
									>
								</div>
								<div
									class="col- mb-1 lg:col-2 lg:mb-1"
									style="margin-right: 10px"
								>
									<span
										style="color: #000; font-size: 20px;"
										class="font-semibold"
										>File :</span>
								</div>
								<div class="grid formgrid">
									<div
										v-if="savedFile"
										class="col-12 mb-5 lg:col-12 lg:mb-5"
									>
										<!-- <a class="button-download p-button" :href="attachmentUrl">
                      <span style="margin: auto" class="font-bold">
                        <i class="pi pi-download mr-2" />
                        {{ attachmentName }}
                      </span>
                    </a> -->
										<Button
											v-if="!loadingDownladFile"
											class="button-download font-normal"
											:label="attachmentName"
											icon="pi pi-download"
											@click="onDownload()"
											:disabled="loadingDownladFile"
										/>
										<Button
											v-if="loadingDownladFile"
											class="button-download font-normal"
											:label="`File is downloading`"
											icon="pi pi-download"
											:disabled="true"
										/>
									</div>
									<!-- <div v-if="savedFile" class="col-1 mb-1 lg:col-1 lg:mb-1">
									<Button
										type="button"
										class="p-button-rounded p-button-text ml-2"
										icon="pi pi-trash"
										@click="handleDeleteFile"
									/>
								</div> -->
								</div>
							</div>
							<div class="grid formgrid" style="margin-top: 30px">
								<div
									class="col-12 mb-5 lg:col-6 lg:mb-5"
									v-if="displayStatus"
								>
									<span class="p-float-label">
										<Textarea
											v-model="reject"
											:autoResize="true"
											:class="{
												'p-invalid':
													v$.reject.$invalid &&
													submitted,
											}"
											:maxlength="500"
										/>
										<label for="username"
											>Reject Reason</label
										>
									</span>
									<!-- <small v-if="rejectvalue" class="p-error">Cannot Be Empty</small> -->
									<small
										v-if="
											(v$.reject.$invalid && submitted) ||
											v$.reject.$pending.$response
										"
										class="p-error"
										>{{ 'Reject value is required' }}</small
									>
								</div>
							</div>
						</div>
						<div class="col-12 md:col-6">
							<div class="grid formgrid">
								<div class="col-12 mb-5 lg:col-12 lg:mb-5">
									<div class="form-card">
										<div
											class="flex align-items-center justify-content-between mb-4"
										>
											<span
												style="color: #000"
												class="font-semibold"
												>STATUS</span
											>
											<span class="font-bold">
												{{ this.status }}
											</span>
										</div>
										<div
											class="flex align-items-center justify-content-between mb-4"
										>
											<span
												style="color: #000"
												class="font-semibold"
												>INVOICE AMOUNT</span
											>
											<span class="font-bold">
												{{
													Number.parseFloat(
														this.invoiceAmount
													)
														.toFixed(2)
														.replace(
															/\B(?=(\d{3})+(?!\d))/g,
															','
														)
												}}
											</span>
										</div>
										<div
											class="flex align-items-center justify-content-between mb-4"
										>
											<span
												style="color: #000"
												class="font-semibold"
												>TAX AMOUNT</span
											>
											<span class="font-bold">
												{{
													Number.parseFloat(
														this.taxAmount
													)
														.toFixed(2)
														.replace(
															/\B(?=(\d{3})+(?!\d))/g,
															','
														)
												}}
											</span>
										</div>
										<div
											class="flex align-items-center justify-content-between mb-4"
										>
											<span
												style="color: #000"
												class="font-semibold"
												>TOTAL</span
											>
											<span class="font-bold">
												{{
													Number.parseFloat(
														this.total
													)
														.toFixed(2)
														.replace(
															/\B(?=(\d{3})+(?!\d))/g,
															','
														)
												}}
											</span>
										</div>
										<div
											class="flex align-items-center justify-content-between mb-4"
										>
											<span
												style="color: #000"
												class="font-semibold"
												>CURRENCY</span
											>
											<span class="font-bold">
												{{ this.currency }}
											</span>
										</div>
									</div>
								</div>
								<div class="col-12 mb-5 lg:col-6 lg:mb-5">
									<RemitToBankAccount
										:db_instance="
											db_instance ? db_instance : null
										"
										:bankId="
											remitToBankAccount
												? remitToBankAccount
												: null
										"
										:org_id="org_id ? org_id : null"
										:vendor_id="
											vendor_id ? vendor_id : null
										"
										@remit="getRemit"
										:key="reload"
										v-model="v$.remit.$model"
										:class="{
											'p-invalid':
												v$.remit.$invalid && submitted,
										}"
									/>
									<small
										v-if="
											(v$.remit.$invalid && submitted) ||
											v$.remit.$pending.$response
										"
										class="p-error"
										>{{
											v$.remit.required.$message.replace(
												'Value',
												'Remit Value'
											)
										}}</small
									>
								</div>

								<div class="col-12 mb-5 lg:col-6 lg:mb-5">
									<PaymentMethod
										:db_instance="
											db_instance ? db_instance : null
										"
										:vendor_id="
											vendor_id ? vendor_id : null
										"
										:payment="payment ? payment : null"
										@payment="getPayment"
										:key="reload"
										v-model="v$.paymentValue.$model"
										:class="{
											'p-invalid':
												v$.paymentValue.$invalid &&
												submitted,
										}"
									/>
									<small
										v-if="
											(v$.paymentValue.$invalid &&
												submitted) ||
											v$.paymentValue.$pending.$response
										"
										class="p-error"
										>{{
											v$.paymentValue.required.$message.replace(
												'Value',
												'Payment Value'
											)
										}}</small
									>
								</div>
								<div class="col-12 mb-5 lg:col-6 lg:mb-5">
									<Terms
										:db_instance="
											db_instance ? db_instance : null
										"
										:vendor_id="
											vendor_id ? vendor_id : null
										"
										:terms_id="terms ? terms : null"
										@terms="getTerms"
										:key="reload"
										v-model="v$.termsValue.$model"
										:class="{
											'p-invalid':
												v$.termsValue.$invalid &&
												submitted,
										}"
									/>
									<small
										v-if="
											(v$.termsValue.$invalid &&
												submitted) ||
											v$.termsValue.$pending.$response
										"
										class="p-error"
										>{{
											v$.termsValue.required.$message.replace(
												'Value',
												'Terms Value'
											)
										}}</small
									>
								</div>

								<div class="col-12 mb-5 lg:col-6 lg:mb-5">
									<span class="p-float-label">
									<DropdownPaygroup
										class="w-full"
										placeholder=" "
										:db_instance="db_instance ? db_instance : null"
										:key="reload"
										v-model="v$.payGroup.$model"
										@payGroup="getPayGroup"
									/>
									<label for="inputtext">Pay Group</label>
									</span>
									<small
										v-if="
											(v$.payGroup.$invalid &&
												submitted) ||
											v$.payGroup.$pending.$response
										"
										class="p-error"
										>{{
											v$.payGroup.required.$message.replace(
												'Value',
												'Paygroup Value'
											)
										}}</small
									>
								</div>

								<div class="col-12 mb-5 lg:col-6 lg:mb-5">
									<span class="p-float-label">
										<!-- <InputText id="username" type="text" v-model="taxRate" :disabled="disabledDropdown"/> -->
										<AutoComplete 
											forceSelection
											:dropdown="true"
											id="costcenter"
											:suggestions="filteredCostCenter"
											@complete="searchCostCenter($event)"
											v-model="costCenter"
											field="SEGMENT4"
										/>
										<label for="costcenter">Cost Center</label>
										<!-- <small v-if="(v$.taxRate.$invalid && submitted) || v$.taxRate.$pending.$response" class="p-error">{{'Tax Rate Value is required'}}</small> -->
									</span>
								</div>
							</div>

							<hr />

							<div v-if="currency !== 'IDR'" class="grid formgrid">
								<div class="col-12 mb-5 lg:col-12 ">
									<div class="form-card">
										<div class="grid py-4">
											<div class="col-12 mt-4 mb-4 lg:col-6 ">
												<span class="p-float-label">
													<!-- <InputText id="username" type="text" v-model="taxRate" :disabled="disabledDropdown"/> -->
													<!-- <InputText
														class="form-input"
														id="rateType"
														disabled
														v-model="rateType"
													/> -->
													<Dropdown
														:options="excDropdown"
														optionLabel="name"
														v-model="rateType"
														@change="onChangeExc($event)"
													/>
													<label for="rateType">Exchange Rate Type</label>
													<!-- <small v-if="(v$.taxRate.$invalid && submitted) || v$.taxRate.$pending.$response" class="p-error">{{'Tax Rate Value is required'}}</small> -->
												</span>
												<small
													v-if="
														(v$.rateType.$invalid &&
															submitted) ||
														v$.rateType.$pending
															.$response
													"
													class="p-error"
													>{{
														v$.rateType.required.$message.replace(
															'The value',
															'Exchange Rate Type'
														)
													}}
												</small>
											</div>
											<div class="col-12 mt-4 mb-4 lg:col-6 ">
												<span class="p-float-label">
													<Calendar
														:showIcon="true"
														:showButtonBar="true"
														id="dateformat"
														dateFormat="dd M yy"
														style="text-align: center"
														v-model="taxRateDate"
														:class="{
															'p-invalid':
																v$.taxInvoiceDate
																	.$invalid && submitted,
														}"
													></Calendar>
													<label for="taxratedate"
														>Exchange Rate Date</label
													>
												</span>
												<small
													v-if="
														(v$.taxRateDate.$invalid &&
															submitted) ||
														v$.taxRateDate.$pending
															.$response
													"
													class="p-error"
													>{{
														v$.taxRateDate.required.$message.replace(
															'The value',
															'Exchange Rate Date'
														)
													}}
												</small>
												<!-- <small
													v-if="
														(v$.taxInvoiceDate.$invalid &&
															submitted) ||
														v$.taxInvoiceDate.$pending.$response
													"
													class="p-error"
													>{{
														v$.taxInvoiceDate.required.$message.replace(
															'Value',
															'Tax Invoice Date'
														)
													}}</small
												> -->
											</div>
											<div class="col-12 mt-4 lg:col-6" v-if="rateType && rateType.name === 'User'">
												<span class="p-float-label">
													<!-- <InputText id="username" type="text" v-model="taxRate" :disabled="disabledDropdown"/> -->
													<InputNumber
														id="taxrateamount"
														mode="decimal"
														:minFractionDigits="2"
														:maxFractionDigits="2"
														v-model="taxRateAmount"
														:maxlength="20"
													/>
													<label for="username">Exchange Rate Amount</label>
													<!-- <small v-if="(v$.taxRate.$invalid && submitted) || v$.taxRate.$pending.$response" class="p-error">{{'Tax Rate Value is required'}}</small> -->
												</span>
												<small
													v-if="
														(v$.taxRateAmount.$invalid &&
															submitted) ||
														v$.taxRateAmount.$pending
															.$response
													"
													class="p-error"
													>{{
														v$.taxRateAmount.required.$message.replace(
															'The value',
															'Exchange Rate Amount'
														)
													}}
												</small>
											</div>
											<div class="col-12 mt-4 lg:col-6 ">
												
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12">
							<TableDetail
								:data="idValue ? idValue : null"
								:key="reloadTable"
								:invoiceDate="invoiceDate"
								@tableList="getTableList"
								:orgId="Number(org_id)"
								:costCenter="costCenter"
								:db_instance="db_instance"
								:tableVuelidate="tableVuelidate"
							></TableDetail>
						</div>
					</div>
					<form
						@submit.prevent="handleSubmit(!v$.$invalid)"
						class="p-fluid"
					>
						<div class="p-fluid grid formgrid">
							<div class="col-6 mb-2 lg:col-1 lg:mb-0">
								<Button
									:class="uppercase"
									class="p-button-secondary"
									label="Back"
									@click="button('B')"
								>
								</Button>
							</div>
							<div
								v-if="!statusButton && type !== 'list'"
								class="col-6 mb-2 lg:col-1 lg:mb-0"
							>
								<Button
									type="submit"
									label="Save"
									@click="button('S')"
								/>
							</div>
							<div
								v-if="!statusButton && type !== 'list'"
								class="col-6 mb-2 lg:col-1 lg:mb-0"
							>
								<Button
									label="Submit"
									@click="confirmation('S')"
									class="p-button-success"
								/>
							</div>
							<div
								v-if="!statusButton && type !== 'list'"
								class="col-6 mb-2 lg:col-1 lg:mb-0"
							>
								<Button
									class="p-button-error"
									label="Reject"
									@click="confirmation('R')"
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
import AuthService from '../../../service/AuthService';
import TableDetail from '@/components/searchinvoicewithpo/components/DetailTableSearchInvoicePrepayment';
import RemitToBankAccount from '@/components/searchinvoicewithpo/components/RemitToBankAccount';
import PaymentMethod from '@/components/searchinvoicewithpo/components/PaymentMethod';
import Terms from '@/components/searchinvoicewithpo/components/Terms';
import PPHCode from '@/components/searchinvoicewithpo/components/PPHCode';
import ChargeofAccount from '@/components/searchinvoicewithpo/components/ChargeOfAccount';
import InvoiceService from '../../../service/InvoiceSearchWPO';
import ProgressModal from '../../components/ProgressModal.vue';
import { useVuelidate } from '@vuelidate/core';
import { required, requiredIf, helpers } from '@vuelidate/validators';
import invoiceNonPOservice from "../../../service/InvoiceNonPOservice";
import Swal from "sweetalert2"
import DropdownPaygroup from '@/components/searchinvoicewithpo/components/DropdownPaygroup'
import SearchInvoicePo from '../../../service/SearchInvoicePo';
import { RateTypeEnum } from '../../../constants/POTypeEnum';


export default {
	setup: () => ({ v$: useVuelidate() }),
	name: 'DetailSearchInvoiceWithPo',
	components: {
		TableDetail,
		RemitToBankAccount,
		PaymentMethod,
		Terms,
		PPHCode,
		ChargeofAccount,
		ProgressModal,
		DropdownPaygroup
	},
	data() {
		return {
			payGroup: null,
			costData: [],
			reloadPaygroup: 0,
			filteredCostCenter: [],
			costCenterList: [],
			costCenter: null,
			detailData: null,
			idValue: this.$route.params.id,
			company: null,
			supplier: null,
			supplierSite: null,
			vendorSiteId: null,
			status: null,
			invoiceNumber: null,
			invoiceDate: null,
			glDate: null,
			invoiceAmount: null,
			taxAmount: null,
			total: null,
			currency: null,
			remit: null,
			payment: null,
			terms: null,
			taxInvoice: null,
			taxInvoiceDate: null,
			document: null,
			pphCeklis: false,
			pphValue: null,
			pphCode: null,
			taxRate: null,
			CoA: null,
			reload: null,
			reloadPPH: null,
			reloadTable: null,
			checkboxValue: false,
			disabledDropdown: true,
			flag_pph: null,
			remitToBankAccount: null,
			db_instance: null,
			org_id: null,
			ext_account: null,
			displayStatus: true,
			sukmai: null,
			paymentValue: null,
			termsValue: null,
			pphCodeValue: null,
			CoAValue: null,
			description: null,
			progress: false,
			userId: null,
			table: null,
			sender: null,
			leveltenant: null,
			vendorId: null,
			inputErrorArray: [],
			batch_no: null,
			savedFile: null,
			disabledDropdowntrue: true,
			attachmentName: null,
			submitted: false,
			attachmentUrl: null,
			name: null,
			buttonValue: null,
			rejectvalue: false,
			statusButton: true,
			reject: null,
			rateType: null,
			taxRateAmount: null,
			taxRateDate: null,
			excDropdown: [
				{id:1, name: RateTypeEnum.User, value: RateTypeEnum.User},
				{id:2, name: RateTypeEnum.TaxRate, value: RateTypeEnum.Thousand}
			],
			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [
				{
					label: 'Search Invoice With PO',
					to: '/mgm/monitor/invoicepo',
				},
				{
					label: 'Detail Search Invoice With PO',
					to: this.$route.path,
				},
			],
			fileBuffer: null,
			loadingDownladFile: false,
			tableVuelidate: null,
			type: null,
		};
	},
	validations() {
		const rules = {
			invoiceNumber: {
				required,
			},
			invoiceDate: {
				required,
			},
			payGroup: {
				required
			},
			glDate: {
				required,
			},
			taxInvoice: {
				required,
			},
			taxInvoiceDate: {
				required,
			},
			remit: {
				required,
			},
			paymentValue: {
				required,
			},
			termsValue: {
				required,
			},
			description: {
				required,
			},
			pphCodeValue: {
				required: requiredIf(this.checkboxValue),
			},
			CoAValue: {
				required: requiredIf(this.checkboxValue),
			},
			pphValue: {
				required: requiredIf(this.checkboxValue),
			},
			reject: {
				required: requiredIf(this.buttonValue === 'R'),
			},
			taxRateDate: {
				required: requiredIf(this.currency !== 'IDR')
			},
			rateType: {
				required: requiredIf(this.currency !== 'IDR')
			},
			taxRateAmount: {
				required: requiredIf(this.rateType && this.rateType.name === RateTypeEnum.User)
			},
			table: {
				$each: helpers.forEach({
					ACCOUNT: {
						required
					}
				})
			}
		}

		if(this.buttonValue === 'R'){
			delete rules["table"]
		}


		return rules
		
	},
	watch: {
		table(){

			console.log("TABLE GUA HAHA")

		},
		checkboxValue() {
			if (this.checkboxValue) {
				this.disabledDropdown = false;
				this.flag_pph = 'Y';
				this.reloadPPH++;
			} else {
				this.disabledDropdown = true;
				this.flag_pph = 'N';
				this.reloadPPH++;
			}
		},
		costCenter(){
			console.log(this.costCenter, "EMKE")
		},
		remit() {},
		reject() {
			if ((this.rejectvalue = false)) {
				if (this.reject.length === 0) {
					this.rejectvalue = true;
				} else {
					this.rejectvalue = false;
				}
			}
		},
		invoiceNumber() {
			//
			if (this.invoiceNumber !== null) {
				this.invoiceNumber = this.invoiceNumber.replace(/ /g, '');
			}
		},
	},
	async created() {
		//
		this.type = this.$route.query.t;

		console.log(this.type, "TYPE PAGE")
		AuthService.whoAmi().then(async (resp) => {
			this.userId = resp.data.data.id;
			this.sender = resp.data.data.bioemailactive;
			this.leveltenant = resp.data.data.leveltenant;
			if (resp.status === 200) {
				const respond = await InvoiceService.getDetailSearchInvoiceWPO(
					this.idValue
				);
				const find = this.excDropdown.find((item) => item.value === respond.data.data.data[0].RATE_TYPE)
				this.rateType = find ? find : null;
				console.log(respond.data.data.data[0].RATE_TYPE, "RATE TYPE")
				this.taxRateAmount =  respond.data.data.data[0].RATE_AMOUNT;
				if(respond.data.data.data[0].RATE_DATE){
					this.taxRateDate = new Date(respond.data.data.data[0].RATE_DATE)
				}else{
					this.taxRateDate = new Date()
				}
				this.batch_no = respond.data.data.data[0].BATCH_NO;
				this.company = respond.data.data.data[0].NAME;
				this.supplier = respond.data.data.data[0].vendor_name;
				this.vendorId = respond.data.data.data[0].VENDOR_ID;
				this.supplierSite = respond.data.data.data[0].VENDOR_SITE
					? respond.data.data.data[0].VENDOR_SITE
					: '';
				this.vendorSiteId = respond.data.data.data[0].VENDOR_SITE_ID;
				this.status = respond.data.data.data[0].STATUS;
				this.pphCodeValue = respond.data.data.data[0].WHT_CODE;
				if(this.status === 'PROCESSED')
				{
					this.statusButton = false;
					this.displayStatus = true;
				}else {
					this.statusButton = true;
					this.displayStatus = true;
				}
				this.currency = respond.data.data.data[0].CURRENCY
					? respond.data.data.data[0].CURRENCY
					: '';
				this.invoiceAmount = respond.data.data.data[0].INVOICE_AMOUNT;
				this.taxAmount = respond.data.data.data[0].TAX_AMOUNT;
				this.taxInvoice = respond.data.data.data[0].TAX_NO;
				this.total = respond.data.data.data[0].TOTAL_AMOUNT;
				this.invoiceNumber = respond.data.data.data[0].INVOICE_NO;
				this.payGroup = respond.data.data.data[0].PAYGROUP;
				this.invoiceDate = new Date(
					respond.data.data.data[0].INVOICE_DATE
				);
				this.taxInvoiceDate = new Date(
					respond.data.data.data[0].TAX_DATE
				);
				this.remitToBankAccount =
					respond.data.data.data[0].EXTERNAL_BANK_ACCOUNT_ID;
				this.db_instance = respond.data.data.data[0].DB_INSTANCE;
				this.org_id = respond.data.data.data[0].ORG_ID;
				this.vendor_id = respond.data.data.data[0].VENDOR_ID;
				this.payment =
					respond.data.data.data[0].PAYMENT_METHOD_LOOKUP_CODE;
				this.terms = respond.data.data.data[0].TERMS_ID;
				this.pphCode = respond.data.data.data[0].WHT_CODE;
				this.CoA = respond.data.data.data[0].ACCTS_CODE_COMBINATION_ID;
				this.glDate = respond.data.data.data[0].GL_DATE;
				if (this.glDate !== null) {
					this.glDate = new Date(respond.data.data.data[0].GL_DATE);
				}

				this.description = respond.data.data.data[0].DESCRIPTION;
				this.reject = respond.data.data.data[0].REASON;
				this.pphValue = respond.data.data.data[0].SERVICE_AMOUNT;
				if (this.pphValue === 0) {
					this.pphValue = null;
				}
				this.taxRate = respond.data.data.data[0].WHT_RATE
					? respond.data.data.data[0].WHT_RATE
					: null;
				this.taxRateBackUp = respond.data.data.data[0].WHT_RATE
					? respond.data.data.data[0].WHT_RATE
					: null;
				if (this.taxRate !== null && this.taxRate > 0) {
					this.checkboxValue = true;
				}

				const result = await invoiceNonPOservice.getListCostCenter(
					this.org_id,
					this.db_instance
				);

				this.costCenterList = result.data.data;
				console.log(respond.data.data, "REPOSN")

				if(respond.data.data.data[0].COST_CENTER) this.costCenter = result.data.data.find((item) => item.SEGMENT4 === respond.data.data.data[0].COST_CENTER)
				
				console.log(this.costCenter, "COCC")
				this.reload++;
				this.reloadTable++;
				this.reloadPaygroup++
				// this.reloadPPH++
				this.getAttachmentFile();
				// await this.getFileBase64(this.idValue);
			} else {
				alert('User session expired');
			}
		});
	},
	computed: {},
	methods: {
		handleChangeTax(event){
			const {value} = event.target;
			this.taxInvoice = value.replace(/[a-zA-Z]/g,"").substring(0,20);
		},
		async getTotalPPN(){

			const mapper = selectedPo.value.map((item) => {
				console.log(item.total_amount, item.percentage_rate)
				return{
					...item,
					pra_ppn: (item.total_amount * Number(item.percentage_rate)) / 100
				}
			});

			this.taxAmount = isNaN(mapper.reduce((a,b) => a + b.pra_ppn, 0)) ? 0 : mapper.reduce((a,b) => a + b.pra_ppn, 0)

		},
		searchCostCenter(event){
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredCostCenter = [...this.costCenterList];
				} else {
					this.filteredCostCenter = this.costCenterList.filter((costCenter) => {
						return costCenter.SEGMENT4.toLowerCase().match(
						event.query.toLowerCase()
						);
					});
				}
			}, 250);
		},
		formatDateDexa(date) {
			var date = new Date(date);
			var respondDate =
				('00' + (date.getMonth() + 1)).slice(-2) +
				'/' +
				('00' + date.getDate()).slice(-2) +
				'/' +
				date.getFullYear() +
				' ' +
				('00' + date.getHours()).slice(-2) +
				':' +
				('00' + date.getMinutes()).slice(-2) +
				':' +
				('00' + date.getSeconds()).slice(-2);
			return respondDate.replace('-', '');
		},
		async getRemit(value) {
			this.remit = value;
		},
		async getPayment(value) {
			this.paymentValue = value;
		},
		async getTerms(value) {
			this.termsValue = value;
		},
		async getPph(value) {
			this.pphCodeValue = value;
		},
		async getCoA(value) {
			this.CoAValue = value;
		},
		async getPayGroup(value){
			console.log("Pay Group : ", value)
			this.payGroup = value
		},
		async getTableList(value) {
			this.table = value;
			const res = await SearchInvoicePo.detailCalculate({
				orgId: this.org_id,
				vendorId: this.vendorId,
				dbInstance: this.db_instance,
				invoiceDate: this.invoiceDate,
				rows: this.table,
				einvoice_id: this.$route.params.id
			})

			console.log(res.data.data, "DATA RES")

			console.log(res.data, "HAHHAHAHA")
			if(res.data){
				this.invoiceAmount = res.data.data[0].total_dpp
				this.taxAmount = res.data.data[0].total_ppn
				console.log(res.data.data[0].total_dpp,res.data.data[0].total_ppn)
				this.total = Number((this.invoiceAmount + this.taxAmount).toFixed(2));
			}
		},
		async getAttachmentFile() {
			const res = await InvoiceService.getAttachmentFile(this.idValue);

			if (res.status === 200) {
				this.savedFile = res.data.data;
				this.attachmentUrl = res.data.data.url;
				this.attachmentName = res.data.data.filename;
			} else {
				this.$toast.add({
					severity: 'Error File',
					summary: 'Unable to Download File',
					life: 3000,
				});
			}
		},
		// async getFileBase64(invoiceId) {
		//   try {
		//     const leveltenant = sessionStorage.getItem("leveltenant");
		//     const res = await InvoiceService.getFileBase64(
		//       invoiceId,
		//       leveltenant,
		//       this.vendorId
		//     );
		//

		//     if (res.status === 200) {
		//       this.fileBuffer = res.data.data;
		//     }
		//   } catch (err) {
		//
		//   }
		// },
		async onDownload() {
			this.loadingDownladFile = true;
			try {
				const leveltenant = sessionStorage.getItem('leveltenant');
				const res = await InvoiceService.getFileBase64(
					this.idValue,
					leveltenant,
					this.vendorId
				);

				if (res.status === 200) {
					this.fileBuffer = res.data.data;
				}
			} catch (err) {
				this.loadingDownladFile = false;

				this.$toast.add({
					severity: 'error',
					summary: 'Connection Unstable !',
					life: 3000,
				});
				throw Error('Error downloading file');
			}
			const linkSource = `data:application/pdf;base64,${this.fileBuffer}`;
			const downloadLink = document.createElement('a');
			const fileName = this.attachmentName;
			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.click();
			this.loadingDownladFile = false;
		},
		async button(status) {
			if (status === 'B') {
				this.$router.go(-1);
			} else {
				this.buttonValue = status;
			}
		},
		async handleSubmit(isFormValid) {
			this.submitted = true;

			console.log(this.buttonValue, 'BUT VAL')
			if (!isFormValid) {
				console.log( this.v$.table.$silentErrors[0], "VIVO")
				this.tableVuelidate = this.v$.table.$silentErrors[0]
				? this.v$.table.$silentErrors[0].$response.$errors
				: null;

				this.$toast.add({
					severity: 'error',
					summary: 'All Fields are Required',
					life: 3000,
				});
				return;
			}

			if (this.buttonValue === 'S') {
				this.progress = true;
				const payload = {
					invoiceNumber: this.invoiceNumber.replace(/ /g, ''),
					invoiceDate: this.formatDateDexa(this.invoiceDate),
					glDate: this.formatDateDexa(this.glDate),
					taxInvoice: this.taxInvoice ? this.taxInvoice : null,
					taxInvoiceDate: this.formatDateDexa(this.taxInvoiceDate),
					rateType: this.rateType ? this.rateType.value : null,
					taxRateAmount: this.taxRateAmount,
					taxRateDate: this.formatDateDexa(this.taxRateDate),
					pphValue: this.pphValue ? this.pphValue : null,
					taxRate: this.taxRate ? this.taxRate : null,
					remit: this.remit.EXT_BANK_ACCOUNT_ID,
					payment: this.paymentValue.PAYMENT_METHOD,
					terms: this.termsValue.TERM_ID
						? this.termsValue.TERM_ID
						: null,
					pphCode: this.pphCodeValue
						? this.pphCodeValue.WHT_CODE
						: null,
					CoA: this.CoAValue
						? this.CoAValue.CODE_COMBINATION_ID
						: null,
					description: this.description ? this.description : '',
					status: 'PROCESSED',
					cost_center: this.costCenter ? this.costCenter.SEGMENT4 : null,
					id: this.idValue,
					userid: this.userId,
					line: this.table
				};

				console.log("save ", payload)
				const save = await InvoiceService.saveSearchInvoiceWPO(payload);
				if (save.status === 200) {
					this.$toast.add({
						severity: 'success',
						summary: 'Save data success',
						life: 3000,
					});
					this.status = 'PROCESSED';
					this.statusButton = false;
					this.displayStatus = true;
					this.progress = false;
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Save Error',
						life: 3000,
					});
					this.progress = false;
				}
			} else if (this.buttonValue === 'C') {
				this.progress = true;
				const payload = {
					paygroup: this.payGroup,
					module: 'SearchInvoiceWPO',
					batch_no: this.batch_no ? this.batch_no : '-',
					db_instance: this.db_instance ? this.db_instance : '',
					org_id: this.org_id ? this.org_id : 0,
					companyname: this.company ? this.company : '',
					vendorname: this.supplier ? this.supplier : '',
					created_by: this.leveltenant,
					rateType: this.rateType ? this.rateType.value : null,
					taxRateAmount: this.taxRateAmount,
					taxRateDate: this.formatDateDexa(this.taxRateDate),
					sender: this.sender,
					recipient: null,
					userreceiveid: this.vendorId,
					total: this.total ? this.total : 0,
					invoiceNumber: this.invoiceNumber
						? this.invoiceNumber.replace(/ /g, '')
						: '',
					invoiceDate: this.formatDateDexa(this.invoiceDate),
					vendor_id: this.vendor_id ? this.vendor_id : 0,
					vendor_site_id: this.vendorSiteId ? this.vendorSiteId : 0,
					currency: this.currency ? this.currency : '',
					glDate: this.formatDateDexa(this.glDate),
					taxInvoice: this.taxInvoice,
					taxInvoiceDate: this.formatDateDexa(this.taxInvoiceDate),
					pphValue: this.pphValue ? this.pphValue : null,
					taxRate: this.taxRate ? this.taxRate : null,
					description: this.description ? this.description : '',
					remit: this.remit.EXT_BANK_ACCOUNT_ID,
					payment: this.paymentValue.PAYMENT_METHOD,
					cost_center: this.costCenter ? this.costCenter.SEGMENT4 : null,
					terms: this.termsValue.TERM_ID
						? this.termsValue.TERM_ID
						: 0,
					pphCode: this.pphCodeValue
						? this.pphCodeValue.WHT_CODE
						: null,
					tax_amount: this.taxAmount ? this.taxAmount : 0,
					CoA: this.CoAValue
						? this.CoAValue.CODE_COMBINATION_ID
						: null,
					status: 'POSTED',
					status_mail: 'Confirm',
					id: parseInt(this.idValue),
					userid: this.userId,
					flag_pph: this.flag_pph,
					line: this.table,
				};
				//

				console.log(payload, "PAYLOAD")


				const submit = await InvoiceService.submitSearchInvoiceWPOPrepayment(
					payload
				);
				
				if (submit.status === 200) {
					this.$toast.add({
						severity: 'success',
						summary: 'Submit data success',
						life: 3000,
					});
					this.status = 'POSTED';
					this.progress = false;
					this.displayStatus = true;
					this.statusButton = true;
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Submit Error',
						life: 3000,
					});
					this.progress = false;
				}
			} else {
				this.rejectvalue = false;
				this.progress = true;

				if(this.reject === '' || this.reject === null){
					this.$toast.add({
						severity: 'error',
						summary: 'Reject field must be filled',
						life: 3000,
					});
					return;
				}

				const payload = {
					module: 'SearchInvoiceWPO',
					invoiceNumber: this.invoiceNumber
						? this.invoiceNumber.replace(/ /g, '')
						: '',
					invoiceDate: this.formatDateDexa(this.invoiceDate),
					glDate: this.formatDateDexa(this.glDate),
					taxInvoice: this.taxInvoice,
					taxInvoiceDate: this.formatDateDexa(this.taxInvoiceDate),
					pphValue: this.pphValue ? this.pphValue : null,
					taxRate: this.taxRate ? this.taxRate : null,
					remit: this.remit.EXT_BANK_ACCOUNT_ID,
					payment: this.paymentValue.PAYMENT_METHOD,
					taxRateAmount: this.taxRateAmount,
					taxRateDate: this.formatDateDexa(this.taxRateDate),
					terms: this.termsValue.TERM_ID
						? this.termsValue.TERM_ID
						: null,
					pphCode: this.pphCodeValue
						? this.pphCodeValue.WHT_CODE
						: null,
					CoA: this.CoAValue
						? this.CoAValue.CODE_COMBINATION_ID
						: null,
					description: this.description ? this.description : '',
					status: 'DRAFT',
					id: parseInt(this.idValue),
					userid: this.userId,
					reject: this.reject ? this.reject : '',
					batch_no: this.batch_no ? this.batch_no : '-',
					db_instance: this.db_instance ? this.db_instance : '',
					org_id: this.org_id ? this.org_id : 0,
					companyname: this.company ? this.company : '',
					vendorname: this.supplier ? this.supplier : '',
					created_by: this.leveltenant,
					sender: this.sender,
					recipient: null,
					userreceiveid: this.vendorId,
					status_mail: 'Reject',
					total: this.total ? this.total : 0,
					line: this.table
				};
				//
				const save = await InvoiceService.rejectSearchInvoiceWPOPrepayment(
					payload
				);

				if (save.status === 200) {
					this.$toast.add({
						severity: 'success',
						summary: 'Reject data success',
						life: 3000,
					});
					this.status = 'REJECTED';
					this.progress = false;
					this.displayStatus = true;
					this.statusButton = true;
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Reject Error',
						life: 3000,
					});
					this.progress = false;
				}
			}
			this.submitted = false;
		},
		onChangeExc(event){
			if(event.value.name === RateTypeEnum.TaxRate) this.taxRateAmount = null;
		},
		confirmation(type){
			Swal.fire({
				title: 'Submit Confirmation',
				text: 'Lanjut melakukan submit invoice ?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Ya, Lanjutkan',
				cancelButtonText: 'Tidak, Batal'
			}).then(async(result) => {
				if (result.isConfirmed) {
				// Tindakan jika pengguna mengonfirmasi
					if(type === 'S'){
						this.button('C')
						this.handleSubmit(!this.v$.$invalid)
					}else{
						this.button('R')
						this.handleSubmit(!this.v$.$invalid)
					}

				} else if (result.dismiss === Swal.DismissReason.cancel) {
				// Tindakan jika pengguna membatalkan
				Swal.fire('Dibatalkan', 'Tindakan dibatalkan', 'info')
				}
			})
		}
	},
};
</script>
<style scoped>
.input-number,
:disabled {
	background: #dfe6e9 !important;
	color: #a19f9f !important;
	border-color: #a19f9f;
}

/* input.dropdown {
	color: #000 !important;
} */
.form-card {
	padding: 10px;
	border: 1px solid #e0e0e0;
	border-radius: 16px;
}

.form-input{
	color: black !important;
}
</style>
