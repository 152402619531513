<template>
	<div class="Grid">
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Return</div>
				<div class="content-body">
					<div class="col-12 p-fluid">
						<div class="grid formgrid" style="margin: 1em 0 0 0">
							<div class="col-4 mb-5 lg:lcol-4">
								<span class="p-float-label">
									<CompaniesDropdown
										v-model="company"
										@itemSelect="onSelectCompany($event)"
										class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
										:disabled="true"
									/>
									<label for="inputtext">Company Name</label>
								</span>
							</div>
							<div class="col-4 mb-5 lg:lcol-4">
								<span class="p-float-label">
									<SupplierNonPoDropdown
										:disabled="
											userInfo
												? userInfo.leveltenant === 2
												: false
										"
										v-model="supplier"
										:company="company ? company : null"
										:userId="userId ? userId : null"
										:levelTenant="
											levelTenant ? levelTenant : null
										"
										@supplier="onSelectSupplier($event)"
										class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
										:key="supplierReload"
									/>
									<label for="inputtext">Supplier Name</label>
								</span>
							</div>
							<div class="col-4 mb-5 lg:lcol-4">
								<span class="p-float-label">
									<!-- <InputText type="text" v-model="poNumber" /> -->
									<DropdownPoNumber
										:disabled="levelTenant === 3"
										v-model="poNumber"
										:company="company ? company : null"
										:vendorId="vendorId ? vendorId : null"
										:levelTenant="
											levelTenant ? levelTenant : null
										"
										:key="poNumberReload"
										@searchComponent="getSearchComponent"
									/>
									<label for="inputtext">PO Number</label>
								</span>
							</div>
						</div>
						<div class="grid formgrid" style="margin: 1em 0 0 0">
							<div class="col-1">
								<Button
									label="Search"
									class="p-button-success uppercase"
									@click="goClick"
									:disabled="disabledButton"
								/>
							</div>
							<div class="col-1">
								<Button
									icon="pi pi-file"
									class="p-button-secondary"
									label="Export"
									@click="exportToExcel"
									style="float: right"
									:disabled="disabledButton"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search Result</div>
				<div class="content-body">
					<div class="grid">
						<div class="col-12">
							<TableReturn
								:company="company ? company : null"
								:vendorId="vendorId ? vendorId : null"
								:poNumber="poNumber ? poNumber : null"
								:levelTenant="levelTenant ? levelTenant : null"
								:searchAction="
									searchAction ? searchAction : null
								"
								:key="tableReload"
								@searchComponent="getSearchComponent"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup>
import { ref, watch } from 'vue';
import { useToast } from 'primevue/usetoast';
import SimpleCrypto from 'simple-crypto-js';
import XLSX from 'xlsx';

import AuthService from '@/service/AuthService';
import LovService from '@/service/LovService';
import PoFulfillment from '@/service/PoFulfillment';
import ReturnService from '../../service/ReturnService';

import CompaniesDropdown from '../components/CompaniesDropdown.vue';
import SupplierNonPoDropdown from './components/SupplierNonPoDropdown.vue';
import TableReturn from './components/TableReturn.vue';
import DropdownPoNumber from './components/DropdownPoNumber.vue';

import { useRouter } from 'vue-router';

const router = useRouter();
const breadcrumbHome = { icon: 'pi pi-home', to: '/dashboard' };
const breadcrumbItems = [{ label: 'Return', to: './returnlist' }];
const userId = ref();
const company = ref();
const supplier = ref(null);
const supplierReload = ref(0);
const userInfo = ref();
const levelTenant = ref();
const vendorId = ref();
const tableReload = ref(0);
const searchAction = ref(false);
const supplierList = ref([]);
const poNumber = ref();
const poNumberReload = ref(0);
const disabledButton = ref(true);
const toast = useToast();
const searchComponent = ref(null);

const userCompany = () => {
	const secretKey = 'some-unique-key';
	const simpleCrypto = new SimpleCrypto(secretKey);
	return simpleCrypto.decrypt(sessionStorage.getItem('dropdownCompany'));
};

watch(
	() => company.value,
	() => {
		// supplier.value = null;
		supplierReload.value++;
		poNumberReload.value++;
	}
);

watch(
	() => company.value && vendorId.value,
	() => {
		// supplier.value = null;
		poNumberReload.value++;
	}
);

AuthService.whoAmi().then(async (response) => {
	userInfo.value = response.data.data;
	userId.value = response.data.data.id;
	if (response.status === 200) {
		levelTenant.value = response.data.data.leveltenant;
		const respCbN = await LovService.getCompanyByName(userCompany());

		const companyTemp = respCbN.data.data[0];

		company.value = companyTemp;

		if (levelTenant.value == 3) {
			const result = await ReturnService.getvendorbytenant(
				userId.value,
				company.value.name,
				company.value.db_instance,
				company.value.value,
				levelTenant.value
			);

			for (const supp of result.data) {
				supplierList.value.push(supp.vendor_id);
			}
			if (supplierList.value.length > 0) {
				vendorId.value = supplierList.value;
			}
			disabledButton.value = false;
		} else if (levelTenant.value === 2) {
			if (companyTemp && !supplier.value) {
				const param = {
					db_instance: companyTemp.db_instance,
					org_id: companyTemp.value,
				};
				const respVendor = await PoFulfillment.getSuppBindComp(
					userInfo.value.id
				);
				const vendorName = respVendor.data.data[0][0].vendorname;
				const respSuppName = await LovService.getSupplierName(param);
				if (respSuppName.status === 200) {
					const suppList = respSuppName.data.data;
					for (const s of suppList) {
						if (s.vendor_name === vendorName) {
							supplier.value = s;
							vendorId.value = s.vendor_id;
							disabledButton.value = false;
							break;
						}
					}
					if (supplier.value === null) {
						router.push('/dashboard');
						window.alert('Connection Unstable, ID not Recognize');
					}
				}
			}
		} else {
			disabledButton.value = false;
		}
	}
});

const onSelectCompany = (event) => {
	company.value = event.value;
	supplier.value = null;
	vendorId.value = null;
	poNumber.value = null;
};

const onSelectSupplier = (event) => {
	if (event.value) {
		supplier.value = event.value;
		vendorId.value = event.value.vendor_id;
		poNumber.value = null;
	} else {
		vendorId.value = null;
		poNumber.value = null;
	}
};

const goClick = async () => {
	tableReload.value++;
	searchAction.value = true;
};

const getSearchComponent = (res) => {
	if (res.isSearch) {
		searchComponent.value = res;
	}
};

const exportToExcel = async () => {
	await AuthService.whoAmi().then((value) => {
		if (value.status === 200) {
			if (searchComponent.value) {
				setTimeout(async () => {
					const payload = {
						orgId: company.value ? company.value.value : null,
						dbInstance: company.value
							? company.value.db_instance
							: null,
						vendorId: vendorId.value ? vendorId.value : null,
						poNumber: poNumber.value ? poNumber.value.pono : null,
						levelTenant: levelTenant.value
							? levelTenant.value
							: null,
						sortField: searchComponent.value
							? searchComponent.value.sortField
							: null,
						sortOrder: searchComponent.value
							? searchComponent.value.sortOrder
							: null,
						search: searchComponent.value
							? searchComponent.value.search
							: null,
					};
					// //
					await ReturnService.getdownloaddata(payload).then(
						(respond) => {
							//

							if (respond.length !== 0) {
								let today = new Date();
								let dd = String(today.getDate()).padStart(
									2,
									'0'
								);
								let mm = String(today.getMonth() + 1).padStart(
									2,
									'0'
								); //January is 0!
								let yyyy = today.getFullYear();
								let combinedDate = dd + mm + yyyy;
								let filename =
									'Download Return _' +
									combinedDate +
									'.xlsx';
								let data = respond.data.data;
								//
								let ws = XLSX.utils.json_to_sheet(data);
								let wb = XLSX.utils.book_new();
								XLSX.utils.book_append_sheet(
									wb,
									ws,
									'My Worksheet'
								);
								XLSX.writeFile(wb, filename);
							} else {
								toast.add({
									severity: 'error',
									summary: 'Data is Empty',
									life: 3000,
								});
							}
						}
					);
				}, 250);
			} else {
				toast.add({
					severity: 'error',
					summary:
						'Data is Empty, Please click button Search before Export',
					life: 3000,
				});
			}
		}
	});
};
</script>

<style></style>
