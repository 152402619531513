<template>
	<div class="grid">
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<!-- Content -->
		<CreateEinvoiceProcurement
			v-if="currentUser"
			title="Create E-Invoice"
			:currentUser="currentUser"
		/>
	</div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import AuthService from '../../service/AuthService';
import CreateEinvoiceProcurement from './CreateEinvoiceProcurement.vue';

const route = useRoute();

const currentUser = ref(null);
const breadcrumbHome = ref({ icon: 'pi pi-home', to: '/dashboard' });
const breadcrumbItems = ref([
	{
		label: 'Create Invoice with PO',
		to: route.fullPath,
	},
]);

onMounted(async () => {
	const resAuth = await AuthService.whoAmi();
	currentUser.value = resAuth.data.data;

	// Check if user is not supplier nor admin
});
</script>
