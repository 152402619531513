<template>
  <AutoComplete
    :suggestions="filteredTerms"
    @complete="searchTerms($event)"
    :dropdown="true"
    field="term_name"
    forceSelection
  >
    <template #item="slotProp">
      {{ slotProp.item.term_name }}
    </template>
  </AutoComplete>
</template>

<script setup>
import { onMounted, ref } from "vue";
// import invoiceNonPOservice from "@/service/InvoiceNonPOservice";
// import spbInvoiceApi from "@/service/api/SpbInvoiceApi";
import { getListTerm } from "@/service/InvoiceProxyNonPOServices";

// eslint-disable-next-line no-undef
const filteredTerms = ref([]);
const terms = ref([]);
// const placeholder = ref("Select a Term");

// eslint-disable-next-line no-undef
const props = defineProps({
  company: {
    type: Object,
  },
  supplier: {
    type: Object,
  },
});

onMounted(async () => {
  try {
    if (props.company && props.supplier) {
      const result = await getListTerm(
        props.company.db_instance,
        props.supplier.vendor_id
      );
      terms.value = result.data.data;
    }

    // eslint-disable-next-line no-empty
  } catch (e) {
    throw new Error(e);
  }
});

const searchTerms = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredTerms.value = [...terms.value];
    } else {
      filteredTerms.value = terms.value.filter((term) => {
        return term.term_name
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};
</script>
