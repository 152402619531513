<template>
  <DataTable
    :value="createNotaDebit"
    :lazy="true"
    :paginator="true"
    :rows="10"
    :loading="loading"
    :totalRecords="totalData"
    @page="onPage($event)"
    @sort="onSort($event)"
    :rowsPerPageOptions="[10, 20, 30]"
    responsiveLayout="scroll"
    :sortable="true"
    :rowId="null"
    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
  >
    <template #empty>Data Not found</template>
    <template #loading>Loading data, please wait...</template>
    <Column header="No">
      <template #body="{ index }">
					{{ index + 1 }}
			</template>
		</Column>
    <Column header="Po Number" field="PO_NUMBER"> </Column>
    <Column header="Description" field="ITEM_DESCRIPTION"> </Column>
    <Column header="QTY" field="QUANTITY"> </Column>
    <Column header="UOM" field="UNIT_MEAS_LOOKUP_CODE"> </Column>
    <Column header="Unit Price" field="UNIT_PRICE"> 
      <template #body="{ data }">
              {{ formatCurrency(data.UNIT_PRICE) }}
      </template>
    </Column>
    <Column header="Amount" field="AMOUNT"> 
      <template #body="{ data }">
              {{ formatCurrency(data.AMOUNT) }}
      </template></Column>
  </DataTable>
</template>

<script setup>
import { onMounted, ref,defineEmits,watch} from "vue";
// import VendorServices from "../../../service/masterVendorService";
import AuthService from "@/service/AuthService";
import PoFulfillment from "@/service/PoFulfillment";
import LovService from "@/service/LovService";
import CreateDebitNote from "../../../service/DebitNoteService"


const company = ref();
const supplier = ref();
const emit = defineEmits('createNotaDebit');


onMounted(async () => {
  company.value = props.company;
  const info = await AuthService.whoAmi();
  if (info.status === 200) {
    levelTenant.value = info.data.data.leveltenant;
    if (levelTenant.value !== 2) {
      loading.value = true;
      supplier.value = props.supplier;
      // loading.value = true
      await populateTable();
    } else {
      if (company.value && !supplier.value) {
        const param = {
          db_instance: props.company.db_instance,
          org_id: props.company.value,
        };
        const respVendor = await PoFulfillment.getSuppBindComp(
          info.data.data.id
        );
        const vendorName = respVendor.data.data[0][0].vendorname;
        const respSuppName = await LovService.getSupplierName(param);
        if (respSuppName.status === 200) {
          const suppList = respSuppName.data.data;
          for (const s of suppList) {
            if (s.vendor_name === vendorName) {
              supplier.value = s;
              break;
            }
          }
        }
        await populateTable();
      }
      // loading.value = true
    }
  }
});
// eslint-disable-next-line no-undef
const props = defineProps({
  company: {
    type: Object,
  },
  supplier: {
    type: Object,
  },
  debitNoteNo: {
    type: Object,
  },
  getNewSupplier: {
    type: Object,
  },
  getNewDebitNo: {
    type: Object,
  },
});
// const masterVendor = ref();
// const totalData = ref(0);

watch(
	() => props.getNewSupplier,
	(newValue) => {

		if (newValue === null) {
			// if (!forwarder.value){
        createNotaDebit.value = null
			// }
		} 
	}
);

watch(
	() => props.getNewDebitNo,
	(newValue) => {

		if ( newValue !== null) {
			// if (!forwarder.value){
        createNotaDebit.value = null
			// }
		} 
	}
);

const loading = ref(false);
const page = ref(1);
const rowsPerPage = ref(10);
const sortColumn = ref();
const sortType = ref();
const levelTenant = ref();
const tableData = ref([]);
const createNotaDebit = ref();
const totalData = ref(0);

const populateTable = async () => {
  loading.value = false;
  if (props.company !== null && props.company !== undefined) {
    const payload = {
      org_id: parseInt(props.company.value),
      suppValue: supplier.value? supplier.value.vendor_id : null,
      debit_note: props.debitNoteNo ? props.debitNoteNo.INVOICE_ID: null,
      db_instance: props.company.db_instance,
      page: page.value,
      rows: rowsPerPage.value,
    };
    tableData.value = await CreateDebitNote.getDetail(payload);
    createNotaDebit.value = tableData.value.data.data.data;
    totalData.value = tableData.value.data.data.totalData[0].totalData;
    emit('createNotaDebit', createNotaDebit);

  }

  loading.value = false;
};

const onPage = async (event) => {
  // loading.value = true
  page.value = event.page + 1;
  rowsPerPage.value = event.rows;
  await populateTable();
};

const onSort = async (event) => {
  // loading.value = true
  page.value = 1;
  rowsPerPage.value = event.rows;
  sortColumn.value = event.sortField;
  sortType.value = event.sortOrder;
  await populateTable();
};

const formatCurrency = (value) => {
  if (value) return value.toLocaleString("id-ID");
  return;
};

</script>
<style scoped></style>
