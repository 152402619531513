import spbKkt from "./api/SpbKktApi";
class LoginService {
  async signViaAdminSc(data) {
    try {
        return await spbKkt.post("adm/auth/signviaadminsc", data);
    } 
    catch (e) {
      console.log("Error LoginService.js", e);
    }
  }

  async signViaCompanySc(data) {
    try {
      return await spbKkt.post("adm/auth/signviacompanysc", data);
    } catch (e) {
      console.log("Error LoginService.js", e);
    }
  }

}
export default new LoginService();