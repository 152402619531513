const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

export const fakePoNum = (total) => {
    let listResult = [];
    let charactersLength = characters.length;
    for(let i=0; i<total; i++){
        let invoiceNo = '';
        for(let x=1;x<=18;x++){
            invoiceNo += characters.charAt(Math.floor(Math.random() * charactersLength))
        }
        const obj = {
            ID: i,
            PO_NO: invoiceNo
        }
        listResult.push(obj);
    }
    return listResult
}

export const fakeData = (input) => {
    const fakeDataResult = [];

    for(let i=0; i <= input; i++){
        let obj = {
            id: rounded(90000),
            po_number: fakePoNum(1)[0].PO_NO,
            total_amount: rounded(80000),
            prepayment_no: rounded(300),
            percentage: `${rounded(2)}%`,
            amount: rounded(80000),
        }

        fakeDataResult.push(obj)
    }

    return fakeDataResult;
}

export const tes = (input) => {
    const fakeDataResult = [];

    for(let i=0; i <= input; i++){
        let obj = {
            id: rounded(90000),
            po_number: fakePoNum(1)[0].PO_NO,
            total_amount: rounded(80000),
            prepayment_no: rounded(300),
            percentage: `${rounded(2)}%`,
            amount: rounded(80000),
        }

        fakeDataResult.push(obj)
    }

    return fakeDataResult;
}


export const fakeDataProcurement = (input) => {
    const fakeDataResult = [];
    let charactersLength = characters.length
   
    for(let i=0; i <= input; i++){
        let invoiceNo = '';
        for(let x=1;x<=18;x++){
            invoiceNo += characters.charAt(Math.floor(Math.random() * charactersLength))
        }

        let poNumber = '';
        for(let x=1;x<=18;x++){
            poNumber += characters.charAt(Math.floor(Math.random() * charactersLength))
        }

        let noPrepayment = '';
        for(let x=1;x<=8;x++){
            noPrepayment += characters.charAt(Math.floor(Math.random() * charactersLength))
        }

        let obj = {
            id: rounded(90000),
            po_number: poNumber,
            total_amount: rounded(80000),
            prepayment_no: noPrepayment,
            percentage: `${rounded(2) + 1}%`,
            po_line: rounded(2),
            termin: nameList[Math.floor(Math.random() * nameList.length)],
            amount: rounded(80000),
            qty: rounded(100),
            bast_no: invoiceNo,
            vat: rounded(100),
            wht: rounded(100)
        }

        fakeDataResult.push(obj)
    }

    return fakeDataResult;
}


const rounded = (round) => {
    return Math.round(Math.random() * (round ? round * 10 : 10));
}

const nameList = [
    'Time', 'Past', 'Future', 'Dev',
    'Fly', 'Flying', 'Soar', 'Soaring', 'Power', 'Falling',
    'Fall', 'Jump', 'Cliff', 'Mountain', 'Rend', 'Red', 'Blue',
    'Green', 'Yellow', 'Gold', 'Demon', 'Demonic', 'Panda', 'Cat',
    'Kitty', 'Kitten', 'Zero', 'Memory', 'Trooper', 'XX', 'Bandit',
    'Fear', 'Light', 'Glow', 'Tread', 'Deep', 'Deeper', 'Deepest',
    'Mine', 'Your', 'Worst', 'Enemy', 'Hostile', 'Force', 'Video',
    'Game', 'Donkey', 'Mule', 'Colt', 'Cult', 'Cultist', 'Magnum',
    'Gun', 'Assault', 'Recon', 'Trap', 'Trapper', 'Redeem', 'Code',
    'Script', 'Writer', 'Near', 'Close', 'Open', 'Cube', 'Circle',
    'Geo', 'Genome', 'Germ', 'Spaz', 'Shot', 'Echo', 'Beta', 'Alpha',
    'Gamma', 'Omega', 'Seal', 'Squid', 'Money', 'Cash', 'Lord', 'King',
    'Duke', 'Rest', 'Fire', 'Flame', 'Morrow', 'Break', 'Breaker', 'Numb',
    'Ice', 'Cold', 'Rotten', 'Sick', 'Sickly', 'Janitor', 'Camel', 'Rooster',
    'Sand', 'Desert', 'Dessert', 'Hurdle', 'Racer', 'Eraser', 'Erase', 'Big',
    'Small', 'Short', 'Tall', 'Sith', 'Bounty', 'Hunter', 'Cracked', 'Broken',
    'Sad', 'Happy', 'Joy', 'Joyful', 'Crimson', 'Destiny', 'Deceit', 'Lies',
    'Lie', 'Honest', 'Destined', 'Bloxxer', 'Hawk', 'Eagle', 'Hawker', 'Walker',
    'Zombie', 'Sarge', 'Capt', 'Captain', 'Punch', 'One', 'Two', 'Uno', 'Slice',
    'Slash', 'Melt', 'Melted', 'Melting', 'Fell', 'Wolf', 'Hound',
    'Legacy', 'Sharp', 'Dead', 'Mew', 'Chuckle', 'Bubba', 'Bubble', 'Sandwich', 'Smasher', 'Extreme', 'Multi', 'Universe', 'Ultimate', 'Death', 'Ready', 'Monkey', 'Elevator', 'Wrench', 'Grease', 'Head', 'Theme', 'Grand', 'Cool', 'Kid', 'Boy', 'Girl', 'Vortex', 'Paradox'
  ];

const bank_list =  [
    {
        "name": "BANK BRI",
        "code": "002"
    },
    {
        "name": "BANK EKSPOR INDONESIA",
        "code": "003"
    },
    {
        "name": "BANK MANDIRI",
        "code": "008"
    },
    {
        "name": "BANK BNI",
        "code": "009"
    },
    {
        "name": "BANK DANAMON",
        "code": "011"
    },
    {
        "name": "PERMATA BANK",
        "code": "013"
    },
    {
        "name": "BANK BCA",
        "code": "014"
    },
    {
        "name": "BANK BII",
        "code": "016"
    },
    {
        "name": "BANK PANIN",
        "code": "019"
    },
    {
        "name": "BANK ARTA NIAGA KENCANA",
        "code": "020"
    },
    {
        "name": "BANK NIAGA",
        "code": "022"
    },
    {
        "name": "BANK BUANA IND",
        "code": "023"
    },
    {
        "name": "BANK LIPPO",
        "code": "026"
    },
    {
        "name": "BANK NISP",
        "code": "028"
    },
    {
        "name": "AMERICAN EXPRESS BANK LTD",
        "code": "030"
    },
    {
        "name": "CITIBANK N.A.",
        "code": "031"
    },
    {
        "name": "JP. MORGAN CHASE BANK, N.A.",
        "code": "032"
    },
    {
        "name": "BANK OF AMERICA, N.A",
        "code": "033"
    },
    {
        "name": "ING INDONESIA BANK",
        "code": "034"
    },
    {
        "name": "BANK MULTICOR TBK.",
        "code": "036"
    },
    {
        "name": "BANK ARTHA GRAHA",
        "code": "037"
    },
    {
        "name": "BANK CREDIT AGRICOLE INDOSUEZ",
        "code": "039"
    },
    {
        "name": "THE BANGKOK BANK COMP. LTD",
        "code": "040"
    },
    {
        "name": "THE HONGKONG & SHANGHAI B.C.",
        "code": "041"
    },
    {
        "name": "THE BANK OF TOKYO MITSUBISHI UFJ LTD",
        "code": "042"
    },
    {
        "name": "BANK SUMITOMO MITSUI INDONESIA",
        "code": "045"
    },
    {
        "name": "BANK DBS INDONESIA",
        "code": "046"
    },
    {
        "name": "BANK RESONA PERDANIA",
        "code": "047"
    },
    {
        "name": "BANK MIZUHO INDONESIA",
        "code": "048"
    },
    {
        "name": "STANDARD CHARTERED BANK",
        "code": "050"
    },
    {
        "name": "BANK ABN AMRO",
        "code": "052"
    },
    {
        "name": "BANK KEPPEL TATLEE BUANA",
        "code": "053"
    },
    {
        "name": "BANK CAPITAL INDONESIA, TBK.",
        "code": "054"
    },
    {
        "name": "BANK BNP PARIBAS INDONESIA",
        "code": "057"
    },
    {
        "name": "BANK UOB INDONESIA",
        "code": "058"
    },
    {
        "name": "KOREA EXCHANGE BANK DANAMON",
        "code": "059"
    },
    {
        "name": "RABOBANK INTERNASIONAL INDONESIA",
        "code": "060"
    },
    {
        "name": "ANZ PANIN BANK",
        "code": "061"
    },
    {
        "name": "DEUTSCHE BANK AG.",
        "code": "067"
    },
    {
        "name": "BANK WOORI INDONESIA",
        "code": "068"
    },
    {
        "name": "BANK OF CHINA LIMITED",
        "code": "069"
    },
    {
        "name": "BANK BUMI ARTA",
        "code": "076"
    },
    {
        "name": "BANK EKONOMI",
        "code": "087"
    },
    {
        "name": "BANK ANTARDAERAH",
        "code": "088"
    },
    {
        "name": "BANK HAGA",
        "code": "089"
    },
    {
        "name": "BANK IFI",
        "code": "093"
    },
    {
        "name": "BANK CENTURY, TBK.",
        "code": "095"
    },
    {
        "name": "BANK MAYAPADA",
        "code": "097"
    },
    {
        "name": "BANK JABAR",
        "code": "110"
    },
    {
        "name": "BANK DKI",
        "code": "111"
    },
    {
        "name": "BPD DIY",
        "code": "112"
    },
    {
        "name": "BANK JATENG",
        "code": "113"
    },
    {
        "name": "BANK JATIM",
        "code": "114"
    },
    {
        "name": "BPD JAMBI",
        "code": "115"
    },
    {
        "name": "BPD ACEH",
        "code": "116"
    },
    {
        "name": "BANK SUMUT",
        "code": "117"
    },
    {
        "name": "BANK NAGARI",
        "code": "118"
    },
    {
        "name": "BANK RIAU",
        "code": "119"
    },
    {
        "name": "BANK SUMSEL",
        "code": "120"
    },
    {
        "name": "BANK LAMPUNG",
        "code": "121"
    },
    {
        "name": "BPD KALSEL",
        "code": "122"
    },
    {
        "name": "BPD KALIMANTAN BARAT",
        "code": "123"
    },
    {
        "name": "BPD KALTIM",
        "code": "124"
    },
    {
        "name": "BPD KALTENG",
        "code": "125"
    },
    {
        "name": "BPD SULSEL",
        "code": "126"
    },
    {
        "name": "BANK SULUT",
        "code": "127"
    },
    {
        "name": "BPD NTB",
        "code": "128"
    },
    {
        "name": "BPD BALI",
        "code": "129"
    },
    {
        "name": "BANK NTT",
        "code": "130"
    },
    {
        "name": "BANK MALUKU",
        "code": "131"
    },
    {
        "name": "BPD PAPUA",
        "code": "132"
    },
    {
        "name": "BANK BENGKULU",
        "code": "133"
    },
    {
        "name": "BPD SULAWESI TENGAH",
        "code": "134"
    },
    {
        "name": "BANK SULTRA",
        "code": "135"
    },
    {
        "name": "BANK NUSANTARA PARAHYANGAN",
        "code": "145"
    },
    {
        "name": "BANK SWADESI",
        "code": "146"
    },
    {
        "name": "BANK MUAMALAT",
        "code": "147"
    },
    {
        "name": "BANK MESTIKA",
        "code": "151"
    },
    {
        "name": "BANK METRO EXPRESS",
        "code": "152"
    },
    {
        "name": "BANK SHINTA INDONESIA",
        "code": "153"
    },
    {
        "name": "BANK MASPION",
        "code": "157"
    },
    {
        "name": "BANK HAGAKITA",
        "code": "159"
    },
    {
        "name": "BANK GANESHA",
        "code": "161"
    },
    {
        "name": "BANK WINDU KENTJANA",
        "code": "162"
    },
    {
        "name": "HALIM INDONESIA BANK",
        "code": "164"
    },
    {
        "name": "BANK HARMONI INTERNATIONAL",
        "code": "166"
    },
    {
        "name": "BANK KESAWAN",
        "code": "167"
    },
    {
        "name": "BANK TABUNGAN NEGARA (PERSERO)",
        "code": "200"
    },
    {
        "name": "BANK HIMPUNAN SAUDARA 1906, TBK .",
        "code": "212"
    },
    {
        "name": "BANK TABUNGAN PENSIUNAN NASIONAL",
        "code": "213"
    },
    {
        "name": "BANK SWAGUNA",
        "code": "405"
    },
    {
        "name": "BANK JASA ARTA",
        "code": "422"
    },
    {
        "name": "BANK MEGA",
        "code": "426"
    },
    {
        "name": "BANK JASA JAKARTA",
        "code": "427"
    },
    {
        "name": "BANK BUKOPIN",
        "code": "441"
    },
    {
        "name": "BANK SYARIAH MANDIRI",
        "code": "451"
    },
    {
        "name": "BANK BISNIS INTERNASIONAL",
        "code": "459"
    },
    {
        "name": "BANK SRI PARTHA",
        "code": "466"
    },
    {
        "name": "BANK JASA JAKARTA",
        "code": "472"
    },
    {
        "name": "BANK BINTANG MANUNGGAL",
        "code": "484"
    },
    {
        "name": "BANK BUMIPUTERA",
        "code": "485"
    },
    {
        "name": "BANK YUDHA BHAKTI",
        "code": "490"
    },
    {
        "name": "BANK MITRANIAGA",
        "code": "491"
    },
    {
        "name": "BANK AGRO NIAGA",
        "code": "494"
    },
    {
        "name": "BANK INDOMONEX",
        "code": "498"
    },
    {
        "name": "BANK ROYAL INDONESIA",
        "code": "501"
    },
    {
        "name": "BANK ALFINDO",
        "code": "503"
    },
    {
        "name": "BANK SYARIAH MEGA",
        "code": "506"
    },
    {
        "name": "BANK INA PERDANA",
        "code": "513"
    },
    {
        "name": "BANK HARFA",
        "code": "517"
    },
    {
        "name": "PRIMA MASTER BANK",
        "code": "520"
    },
    {
        "name": "BANK PERSYARIKATAN INDONESIA",
        "code": "521"
    },
    {
        "name": "BANK AKITA",
        "code": "525"
    },
    {
        "name": "LIMAN INTERNATIONAL BANK",
        "code": "526"
    },
    {
        "name": "ANGLOMAS INTERNASIONAL BANK",
        "code": "531"
    },
    {
        "name": "BANK DIPO INTERNATIONAL",
        "code": "523"
    },
    {
        "name": "BANK KESEJAHTERAAN EKONOMI",
        "code": "535"
    },
    {
        "name": "BANK UIB",
        "code": "536"
    },
    {
        "name": "BANK ARTOS IND",
        "code": "542"
    },
    {
        "name": "BANK PURBA DANARTA",
        "code": "547"
    },
    {
        "name": "BANK MULTI ARTA SENTOSA",
        "code": "548"
    },
    {
        "name": "BANK MAYORA",
        "code": "553"
    },
    {
        "name": "BANK INDEX SELINDO",
        "code": "555"
    },
    {
        "name": "BANK VICTORIA INTERNATIONAL",
        "code": "566"
    },
    {
        "name": "BANK EKSEKUTIF",
        "code": "558"
    },
    {
        "name": "CENTRATAMA NASIONAL BANK",
        "code": "559"
    },
    {
        "name": "BANK FAMA INTERNASIONAL",
        "code": "562"
    },
    {
        "name": "BANK SINAR HARAPAN BALI",
        "code": "564"
    },
    {
        "name": "BANK HARDA",
        "code": "567"
    },
    {
        "name": "BANK FINCONESIA",
        "code": "945"
    },
    {
        "name": "BANK MERINCORP",
        "code": "946"
    },
    {
        "name": "BANK MAYBANK INDOCORP",
        "code": "947"
    },
    {
        "name": "BANK OCBC – INDONESIA",
        "code": "948"
    },
    {
        "name": "BANK CHINA TRUST INDONESIA",
        "code": "949"
    },
    {
        "name": "BANK COMMONWEALTH",
        "code": "950"
    }
];

export const fakeCompany = (input) => {
    const companyResult = [];

    for(let i=0; i <= input; i++){
        let obj = {
            id: rounded(90000),
            company_name: nameList[Math.floor(Math.random() * nameList.length)]
        }

        companyResult.push(obj)
    }
    return companyResult;
}


export const fakeBank = (input) => {
    const bankResult = [];

    for(let i=0; i <= input; i++){
        const val = bank_list[Math.floor(Math.random() * bank_list.length)]
        let obj = {
            id: rounded(90000),
            bank_name: `${val.code} - ${val.name}`
        }

        bankResult.push(obj)
    }

    return bankResult;

}

export const fakeBAST = (input) => {
    const bastResult = [];

    for(let i=0; i <= input; i++){
        let obj = {
            id: rounded(90000),
            bast_no: rounded(1000000).toString()
        }

        bastResult.push(obj)
    }

    return bastResult;

}
