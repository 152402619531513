<template>
	<div class="col-12 mb-4">
		<Toast />

		<!-- Progress modal -->
		<ProgressModal :progress="progress">
			<template #message>
				{{ progress }}
			</template>
		</ProgressModal>

		<div class="content-wrapper">
			<div class="content-header">{{ title }}</div>
			<div class="content-body">
				<!-- Input Form -->
				<div class="grid mb-6">
					<!-- Col 1: Form SPE Report -->
					<div class="col-12 md:col-6 lg:col-4">
						<div class="mb-5">
							<span class="p-float-label">
								<InputText
									id="company"
									:class="{
										'p-invalid': v$.company.$error,
									}"
									disabled
									v-model="companyName"
								/>
								<label for="company">Company</label>
							</span>
							<div v-if="v$.company.$error" class="mt-1">
								<small class="p-error" style="font-size: 12px">
									{{ v$.company.$errors[0].$message }}
								</small>
							</div>
						</div>
						<div class="mb-5">
							<span class="p-float-label">
								<InputText
									id="speNo"
									disabled
									v-model="speNo"
								/>
								<label for="speNo">No SPE</label>
							</span>
						</div>
						<div class="mb-5">
							<!-- <span class="p-float-label">
								<AutoComplete
									id="supplier"
									:class="{
										'p-invalid': v$.supplier.$error,
									}"
									class="w-full"
									:disabled="
										!supplierList ||
										(isDetailPage && isUserSupplier)
									"
									v-model="supplier"
									:suggestions="filteredSupplier"
									@complete="searchSupplier($event)"
									field="vendor_name"
									forceSelection
								/>
								<label for="supplier">Supplier</label>
							</span> -->
							<span class="p-float-label">
								<SupplierDropdown
									v-if="company"
									class="w-full"
									:class="{
										'p-invalid': v$.supplier.$error,
									}"
									:disabled="
										!supplierList ||
										(isDetailPage && isUserSupplier)
									"
									:placeholder="''"
									:company="company"
									v-model="supplier"
								/>
								<label for="supplier">Supplier</label>
							</span>
							<div v-if="v$.supplier.$error" class="mt-1">
								<small class="p-error" style="font-size: 12px">
									{{ v$.supplier.$errors[0].$message }}
								</small>
							</div>
						</div>
						<div class="mb-5">
							<!-- If status is CLOSED, change period dropdown to input text -->
							<div
								v-if="isDetailPage && statusReport === 'CLOSED'"
							>
								<span class="p-float-label">
									<InputText
										id="period"
										:disabled="
											isDetailPage && isUserSupplier
										"
										v-model="period.semesterName"
									/>
									<label for="period">Period</label>
								</span>
							</div>
							<div v-else>
								<span class="p-float-label">
									<Dropdown
										id="period"
										:class="{
											'p-invalid': v$.period.$error,
										}"
										:disabled="
											isDetailPage && isUserSupplier
										"
										:options="periodList"
										optionLabel="semesterName"
										v-model="period"
										@before-show="getPeriodOptions"
									/>
									<label for="period">Period</label>
								</span>
							</div>
							<div v-if="v$.period.$error" class="mt-1">
								<small class="p-error" style="font-size: 12px">
									{{ v$.period.$errors[0].$message }}
								</small>
							</div>
						</div>
						<div class="mb-5">
							<span class="p-float-label">
								<Dropdown
									id="grade"
									:class="{
										'p-invalid': v$.grade.$error,
									}"
									:disabled="isDetailPage && isUserSupplier"
									:options="gradeList"
									optionLabel="name"
									v-model="grade"
								/>
								<label for="grade">Grade</label>
							</span>
							<div v-if="v$.grade.$error" class="mt-1">
								<small class="p-error" style="font-size: 12px">
									{{ v$.grade.$errors[0].$message }}
								</small>
							</div>
						</div>
						<div class="mb-5">
							<span class="p-float-label">
								<InputText
									id="statusReport"
									disabled
									v-model="statusReport"
								/>
								<label for="statusReport">Status</label>
							</span>
						</div>
						<!-- Saved doc file -->
						<div v-if="purFilename || supFilename" class="mb-5">
							<!-- Purchasing file -->
							<div>
								<span
									v-if="purFilename"
									style="
										display: inline-block;
										font-size: 12px;
										color: #6c757d;
										margin: 0 0 4px 0.75rem;
									"
									>Attachment File</span
								>
								<Button
									v-if="purFilename && !loadingDownloadPur"
									class="button-download font-normal"
									style="font-size: 12.8px !important"
									:label="purFilename"
									icon="pi pi-download"
									@click="downloadPurFile"
								/>
								<Button
									v-else-if="loadingDownloadPur"
									class="button-download font-normal"
									style="font-size: 12.8px !important"
									:label="`${purFilename} is downloading...`"
									icon="pi pi-download"
									disabled
								/>
							</div>
							<!-- Supplier file -->
							<div class="mt-5">
								<span
									v-if="supFilename"
									style="
										display: inline-block;
										font-size: 12px;
										color: #6c757d;
										margin: 0 0 4px 0.75rem;
									"
									>Attachment Confirmation File</span
								>
								<Button
									v-if="supFilename && !loadingDownloadSup"
									class="button-download font-normal"
									style="font-size: 12.8px !important"
									:label="supFilename"
									icon="pi pi-download"
									@click="downloadSupFile"
								/>
								<Button
									v-else-if="loadingDownloadSup"
									class="button-download font-normal"
									style="font-size: 12.8px !important"
									:label="`${supFilename} is downloading...`"
									icon="pi pi-download"
									disabled
								/>
							</div>
						</div>
						<!-- Button upload file -->
						<div>
							<!-- For purchasing -->
							<div v-if="showFileUploadPur">
								<FileUpload
									ref="fileUpload"
									:class="{
										'error-file': v$.file.$error,
									}"
									mode="advanced"
									chooseLabel="Upload File"
									fileLimit="1"
									accept="application/pdf"
									:maxFileSize="5000000"
									:showUploadButton="false"
									:showCancelButton="false"
									:customUpload="true"
									@select="onSelectFile"
									@remove="onRemoveFile"
								/>
								<div
									v-if="v$.file.$error"
									class="mt-1"
									style="transform: translateY(-40px)"
								>
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.file.$errors[0].$message }}
									</small>
								</div>
							</div>
							<!-- For supplier -->
							<div v-if="showFileUploadSup">
								<FileUpload
									ref="fileUpload"
									:class="{
										'error-file': v$.file.$error,
									}"
									:disabled="
										((grade && grade.value === 'B') ||
											(grade && grade.value === 'P')) &&
										!capaId
									"
									mode="advanced"
									chooseLabel="Upload Confirmation File"
									fileLimit="1"
									accept="application/pdf"
									:maxFileSize="5000000"
									:showUploadButton="false"
									:showCancelButton="false"
									:customUpload="true"
									@select="onSelectFile"
									@remove="onRemoveFile"
								/>
								<div
									v-if="v$.file.$error"
									class="mt-1"
									style="transform: translateY(-40px)"
								>
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.file.$errors[0].$message }}
									</small>
								</div>
							</div>
						</div>
					</div>

					<!-- Col 2: Form CAPA Report -->
					<div
						v-if="showCapaForm"
						class="col-12 md:col-6 lg:col-4 lg:col-offset-4"
					>
						<div class="mb-5">
							<span class="p-float-label">
								<InputText
									id="capaNo"
									disabled
									v-model="capaNo"
								/>
								<label for="capaNo">No Doc CAPA</label>
							</span>
						</div>
						<div class="mb-5">
							<span class="p-float-label">
								<Textarea
									id="capaDesc"
									:class="{
										'p-invalid': v$.capaDesc.$error,
									}"
									:disabled="!isUserSupplier"
									rows="5"
									v-model="capaDesc"
								/>
								<label for="capaDesc">Description</label>
							</span>
							<div v-if="v$.capaDesc.$error" class="mt-1">
								<small class="p-error" style="font-size: 12px">
									{{ v$.capaDesc.$errors[0].$message }}
								</small>
							</div>
						</div>
						<div class="mb-5">
							<span class="p-float-label">
								<Textarea
									id="rootCause"
									:class="{
										'p-invalid': v$.rootCause.$error,
									}"
									:disabled="!isUserSupplier"
									v-model="rootCause"
									rows="1"
								/>
								<label for="rootCause">Root Cause</label>
							</span>
							<div v-if="v$.rootCause.$error" class="mt-1">
								<small class="p-error" style="font-size: 12px">
									{{ v$.rootCause.$errors[0].$message }}
								</small>
							</div>
						</div>
						<div class="mb-5">
							<span class="p-float-label">
								<Textarea
									id="correctiveAction"
									:class="{
										'p-invalid': v$.correctiveAction.$error,
									}"
									:disabled="!isUserSupplier"
									v-model="correctiveAction"
									rows="1"
								/>
								<label for="correctiveAction"
									>Corrective Action</label
								>
							</span>
							<div v-if="v$.correctiveAction.$error" class="mt-1">
								<small class="p-error" style="font-size: 12px">
									{{
										v$.correctiveAction.$errors[0].$message
									}}
								</small>
							</div>
						</div>
						<div class="mb-5">
							<span class="p-float-label">
								<Textarea
									id="preventiveAction"
									:class="{
										'p-invalid': v$.preventiveAction.$error,
									}"
									:disabled="!isUserSupplier"
									v-model="preventiveAction"
									rows="1"
								/>
								<label for="preventiveAction"
									>Preventive Action</label
								>
							</span>
							<div v-if="v$.preventiveAction.$error" class="mt-1">
								<small class="p-error" style="font-size: 12px">
									{{
										v$.preventiveAction.$errors[0].$message
									}}
								</small>
							</div>
						</div>
					</div>
				</div>

				<!-- Action buttons for create report -->
				<div v-if="!isDetailPage" class="grid">
					<div class="col-12 md:col-3 lg:col-2">
						<Button
							v-if="
								progress &&
								progress.toLowerCase().includes('submit')
							"
							class="p-button-success justify-content-center"
							disabled
						>
							<span class="font-bold uppercase"
								>Submitting...</span
							>
						</Button>
						<Button
							v-else
							class="p-button-success justify-content-center"
							@click="handleSubmit('submit')"
						>
							<span class="font-bold uppercase">Submit</span>
						</Button>
					</div>
					<div class="col-12 md:col-3 lg:col-2">
						<Button
							v-if="
								progress &&
								progress.toLowerCase().includes('save')
							"
							class="p-button-secondary justify-content-center"
							disabled
						>
							<span class="font-bold uppercase">Saving...</span>
						</Button>
						<Button
							v-else
							class="p-button-secondary justify-content-center"
							@click="handleSubmit('save')"
						>
							<span class="font-bold uppercase">Save</span>
						</Button>
					</div>
					<div class="col-12 md:col-3 lg:col-2">
						<Button
							class="p-button-secondary justify-content-center"
							@click="handleClear"
						>
							<span class="font-bold uppercase">Clear</span>
						</Button>
					</div>
				</div>

				<!-- Action buttons for update report -->
				<div v-else>
					<!-- For user purchasing -->
					<div v-if="!isUserSupplier">
						<!-- For all status, except status CLOSED -->
						<div v-if="statusCode !== 'X'" class="grid">
							<div class="col-12 md:col-3 lg:col-2">
								<!-- For status Confirmed -->
								<div v-if="statusCode === 'C'">
									<Button
										v-if="progress"
										class="p-button-success justify-content-center"
										disabled
									>
										<span class="font-bold uppercase"
											>Closing...</span
										>
									</Button>
									<Button
										v-else
										class="p-button-success justify-content-center"
										@click="handleUpdate(props.id, 'X')"
									>
										<span class="font-bold uppercase"
											>Close</span
										>
									</Button>
								</div>
								<!-- For other status -->
								<div v-else>
									<Button
										v-if="progress"
										class="p-button-success justify-content-center"
										disabled
									>
										<span class="font-bold uppercase"
											>Submitting...</span
										>
									</Button>
									<Button
										v-else
										class="p-button-success justify-content-center"
										:disabled="statusCode !== 'D'"
										@click="handleUpdate(props.id, 'S')"
									>
										<span class="font-bold uppercase"
											>Submit</span
										>
									</Button>
								</div>
							</div>
							<div
								v-if="statusCode === 'D'"
								class="col-12 md:col-3 lg:col-2"
							>
								<Button
									v-if="
										progress &&
										progress.toLowerCase().includes('save')
									"
									class="p-button-secondary justify-content-center"
									disabled
								>
									<span class="font-bold uppercase"
										>Saving...</span
									>
								</Button>
								<Button
									v-else
									class="p-button-secondary justify-content-center"
									:disabled="statusCode === 'C'"
									@click="handleUpdate(props.id, statusCode)"
								>
									<span class="font-bold uppercase"
										>Save</span
									>
								</Button>
							</div>
							<div class="col-12 md:col-3 lg:col-2">
								<Button
									class="p-button-secondary justify-content-center"
									@click="
										router.push({ name: 'ListSpeReport' })
									"
								>
									<span class="font-bold uppercase"
										>Back</span
									>
								</Button>
							</div>
						</div>
						<!-- For status CLOSED, just show back button -->
						<div v-else class="grid">
							<div class="col-12 md:col-3 lg:col-2">
								<Button
									class="p-button-secondary justify-content-center"
									@click="
										router.push({ name: 'ListSpeReport' })
									"
								>
									<span class="font-bold uppercase"
										>Back</span
									>
								</Button>
							</div>
						</div>
					</div>
					<!-- For user supplier -->
					<div v-else>
						<!-- For all status, except for status CONFIRMED or CLOSED -->
						<div
							v-if="statusCode !== 'C' && statusCode !== 'X'"
							class="grid"
						>
							<div class="col-12 md:col-3 lg:col-2">
								<Button
									v-if="progress"
									class="p-button-success justify-content-center"
									disabled
								>
									<span class="font-bold uppercase"
										>Submitting...</span
									>
								</Button>
								<!-- Disabled button submit for supplier if status is Poor/Bad and Capa ID not submitted yet -->
								<Button
									v-else
									class="p-button-success justify-content-center"
									:disabled="
										grade &&
										(grade.value === 'B' ||
											grade.value === 'P') &&
										!capaId
									"
									@click="handleUpdate(props.id, 'C')"
								>
									<span class="font-bold uppercase"
										>Submit</span
									>
								</Button>
							</div>
							<div
								v-if="showCapaForm"
								class="col-12 md:col-3 lg:col-2"
							>
								<Button
									v-if="
										progress &&
										progress.toLowerCase().includes('save')
									"
									class="p-button-secondary justify-content-center"
									disabled
								>
									<span class="font-bold uppercase"
										>Saving...</span
									>
								</Button>
								<Button
									v-else-if="!capaId"
									class="p-button-secondary justify-content-center"
									@click="handleSaveCapa"
								>
									<span class="font-bold uppercase"
										>Save CAPA</span
									>
								</Button>
								<Button
									v-else
									class="p-button-secondary justify-content-center"
									@click="handleUpdateCapa()"
								>
									<span class="font-bold uppercase"
										>Save CAPA</span
									>
								</Button>
							</div>
							<div
								v-if="showCapaForm"
								class="col-12 md:col-3 lg:col-2"
							>
								<Button
									class="p-button-secondary justify-content-center"
									@click="handleClear"
								>
									<span class="font-bold uppercase"
										>Clear</span
									>
								</Button>
							</div>
						</div>
						<!-- For status CONFIRMED or CLOSED, just show back button -->
						<div v-else>
							<div class="col-12 md:col-3 lg:col-2">
								<Button
									class="p-button-secondary justify-content-center"
									@click="
										router.push({ name: 'ListSpeReport' })
									"
								>
									<span class="font-bold uppercase"
										>Back</span
									>
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, onMounted, defineProps } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import { useVuelidate } from '@vuelidate/core';
import { required, requiredIf, minLength } from '@vuelidate/validators';
import SpeReportService from '../../../service/SpeReportService';
import ProgressModal from '../../components/ProgressModal.vue';
import SupplierDropdown from '../../components/SupplierDropdown.vue';

// State
const currentUser = ref(props.currentUser);
const company = ref(props.company);
const companyName = ref(null);
const vendorId = ref(props.vendorId);
const speNo = ref(null);
const supplier = ref(null);
const supplierList = ref(null);
const grade = ref(null);
const gradeList = ref(null);
const periodList = ref(null);
const period = ref(null);
const statusReport = ref(null);
const statusCode = ref(null);
const file = ref(null);
const fileUpload = ref(null);
const purFilename = ref(null);
const supFilename = ref(null);
const progress = ref(null);
const loadingDownloadPur = ref(null);
const loadingDownloadSup = ref(null);
const capaId = ref(null);
const capaNo = ref(null);
const capaDesc = ref(null);
const rootCause = ref(null);
const correctiveAction = ref(null);
const preventiveAction = ref(null);
const requiredCapaLength = ref(30);

// Composables
const router = useRouter();
const route = useRoute();
const toast = useToast();

// Props
const props = defineProps([
	'title',
	'id',
	'currentUser',
	'company',
	'companyList',
	'vendorId',
]);

// Computed for detail page
const isDetailPage = computed(() => (props.id ? true : false));

// Computed for supplier page
const isUserSupplier = computed(
	() => currentUser.value && currentUser.value.leveltenant === 2
);

// Show file upload for purchasing
const showFileUploadPur = computed(
	() => !isUserSupplier.value && !purFilename.value
);

// Show file upload for supplier
const showFileUploadSup = computed(
	() => isUserSupplier.value && !supFilename.value
);

// Check if capa form is displayed
const showCapaForm = computed(
	() =>
		isDetailPage.value &&
		grade.value &&
		(grade.value.value === 'B' || grade.value.value === 'P')
);

// Vuelidate rules
const validationRules = computed(() => {
	const rules = {
		company: { required },
		supplier: { required },
		period: { required },
		grade: { required },
	};

	// Add rules for file
	if (showFileUploadPur.value || showFileUploadSup.value) {
		rules.file = {
			requiredIfFuction: requiredIf(!showCapaForm.value || capaId.value),
		};
	}

	// Add rules for CAPA form
	if (showCapaForm.value) {
		rules.capaDesc = { requiredIfFuction: requiredIf(isUserSupplier) };
		rules.rootCause = {
			requiredIfFuction: requiredIf(isUserSupplier),
			minLength: minLength(requiredCapaLength.value),
		};
		rules.correctiveAction = {
			requiredIfFuction: requiredIf(isUserSupplier),
			minLength: minLength(requiredCapaLength.value),
		};
		rules.preventiveAction = {
			requiredIfFuction: requiredIf(isUserSupplier),
			minLength: minLength(requiredCapaLength.value),
		};
	}

	return rules;
});

// Validator instance
const v$ = useVuelidate(validationRules, {
	company,
	supplier,
	period,
	grade,
	file,
	capaDesc,
	rootCause,
	correctiveAction,
	preventiveAction,
});

// Mounted hook
onMounted(async () => {
	console.log({ currentUser: currentUser.value });
	console.log({ company: company.value });
	console.log({ vendorId: vendorId.value });

	// Assign company name
	if (!isDetailPage.value) {
		companyName.value = company.value.name;
	}

	// Get supplier list
	const getSupp = getSupplierList();

	// Get grade list
	const getGrades = getGradeList();

	// Run functions
	await Promise.all([getSupp, getGrades]);

	// Get report detail
	if (isDetailPage.value) {
		const res = await getReportDetail(props.id);

		if (res.status === 200) {
			// Get capa detail if grade is Bad/Poor
			if (grade.value.value === 'B' || grade.value.value === 'P') {
				getCapaDetail(props.id);
			}
		}
	}
});

// Formatting date
const formattingDate = (date, withTime) => {
	const theDate = new Date(date);
	const strDate =
		theDate.getFullYear() +
		'-' +
		(theDate.getMonth() + 1) +
		'-' +
		theDate.getDate();
	const strTime =
		theDate.getHours() +
		':' +
		theDate.getMinutes() +
		':' +
		theDate.getSeconds();

	if (withTime) {
		return `${strDate} ${strTime}`;
	} else {
		return `${strDate}`;
	}
};

// Select file attachment
const onSelectFile = (e) => {
	file.value = e.files[0];
};

// Remove file attachment
const onRemoveFile = (e) => {
	if (!e.files.length) {
		file.value = null;
	}
};

// Get supplier list
const getSupplierList = async () => {
	const payload = {
		dbInstance: company.value.db_instance,
		orgId: company.value.value,
	};

	try {
		const res = await SpeReportService.getSupplierList(payload);

		if (res.status === 200) {
			supplierList.value = res.data.data;
		}
	} catch (err) {
		console.log(err);
	}
};

// Get grade list
const getGradeList = async () => {
	try {
		const params = 'GRADESPEREPORT';

		const res = await SpeReportService.getGradeList(params);

		console.log({ grades: res });

		if (res.status === 200) {
			gradeList.value = res.data.data;
		}
	} catch (err) {
		console.log(err);
	}
};

// Generate period options
const getPeriodOptions = () => {
	const currDate = new Date();
	const currYear = currDate.getFullYear();
	const currSemester = currDate.getMonth() + 1 <= 6 ? 1 : 2;
	const lastSemeter = 2; // Last semester always 2 (S1 & S2)
	const totalSemeter = currSemester + lastSemeter;

	// Create array of total semester
	const semesterArr = Array(totalSemeter)
		.fill(0)
		.map((el, index) => {
			const semesterNum = (index + 1) % 2 === 0 ? 2 : 1;
			const semesterYear = index + 1 <= 2 ? currYear - 1 : currYear;

			return {
				semesterNum,
				semesterYear,
				semesterName: `S${semesterNum} ${semesterYear}`,
			};
		});

	// Assign period list
	periodList.value = semesterArr;

	return semesterArr;
};

// Get report detail
const getReportDetail = async (id) => {
	try {
		// Call get period options
		getPeriodOptions();

		// Payload
		const payload = {
			dbInstance: route.query.i,
			orgId: route.query.v,
			vendorId: null,
			levelTenant: currentUser.value.leveltenant,
		};

		if (currentUser.value.leveltenant === 2) {
			payload.vendorId = vendorId.value;
		}

		const res = await SpeReportService.getReportDetail(id, payload);
		console.log({ detailRes: res });

		if (res.status === 200) {
			const report = res.data.data;

			// Assign report values
			company.value = props.companyList.find(
				(comp) =>
					parseInt(comp.value) === report.org_id &&
					comp.db_instance === report.db_instance
			);
			companyName.value = company.value.name;
			speNo.value = report.spe_no;
			supplier.value = supplierList.value.find(
				(supp) => supp.vendor_id === report.vendor_id
			);
			grade.value = gradeList.value.find(
				(grd) => grd.value === report.grade
			);
			statusReport.value = report.status_name;
			statusCode.value = report.status;
			period.value = {
				semesterName: report.period_name,
				semesterNum: report.period_semester,
				semesterYear: report.period_year,
			};

			// Assign doc report values
			purFilename.value = report.pur_filename;
			supFilename.value = report.sup_filename;
		}

		return res;
	} catch (err) {
		console.log(err);
		if (err.response.status === 404) {
			toast.add({
				severity: 'error',
				summary: 'Report is not found',
				life: 3000,
			});
			return;
		}
		toast.add({
			severity: 'error',
			summary: 'Server error',
			life: 3000,
		});
	}
};

// Get CAPA detail
const getCapaDetail = async (speId) => {
	try {
		const res = await SpeReportService.getCapaDetail(speId);

		// Assign values
		if (res.status === 200 && res.data.data) {
			const capaDetail = res.data.data;
			capaId.value = capaDetail.capa_id;
			capaNo.value = capaDetail.capa_no;
			capaDesc.value = capaDetail.description;
			rootCause.value = capaDetail.root_cause;
			correctiveAction.value = capaDetail.corrective_action;
			preventiveAction.value = capaDetail.preventive_action;
		}
	} catch (err) {
		console.log(err);
		toast.add({
			severity: 'error',
			summary: 'CAPA detail is not found',
			life: 3000,
		});
	}
};

// Submit SPE Report
const handleSubmit = async (type) => {
	// Validate required fields
	const isFormValid = await v$.value.$validate();
	if (!isFormValid) {
		// Check error message
		const errMessage =
			v$.value.$errors.length &&
			v$.value.$errors[0].$validator === 'required'
				? 'All fields are required'
				: 'All fields are required';

		toast.add({
			severity: 'error',
			summary: errMessage,
			life: 3000,
		});
		return;
	}

	// Add file payload
	const formData = new FormData();
	formData.append('file', file.value);

	// Add body payload header
	const payload = {
		submitPayload: {
			SPE_NO: null,
			ORG_ID: company.value.value,
			DB_INSTANCE: company.value.db_instance,
			VENDOR_ID: supplier.value.vendor_id,
			PERIOD_NAME: period.value.semesterName,
			PERIOD_YEAR: period.value.semesterYear,
			PERIOD_SEMESTER: period.value.semesterNum,
			GRADE: grade.value.value,
			STATUS: type === 'save' ? 'D' : 'S', // status D for Draft or S for Submitted
			CREATED_BY: currentUser.value.id,
			CREATION_DATE: formattingDate(new Date(), true),
			LAST_UPDATED_BY: currentUser.value.id,
			LAST_UPDATE_DATE: formattingDate(new Date(), true),
		},
		emailPayload: {
			module: 'spereport',
			companyname: company.value.name,
			vendorname: supplier.value.vendor_name,
			period: period.value.semesterName,
			grade: grade.value.name,
		},
	};

	// Wrap payload with formData
	const jsonPayload = JSON.stringify(payload);
	formData.append('payload', jsonPayload);

	try {
		progress.value =
			type === 'save'
				? 'Save a report in progress. Please wait...'
				: 'Submit a report in progress. Please wait...';

		const res = await SpeReportService.submitReport(formData);

		console.log({ submitRes: res });

		if (res.status === 201) {
			progress.value = null;
			file.value = null;
			fileUpload.value.clear();
			v$.value.$reset();

			// Set success state for toast in local storage
			sessionStorage.setItem('successSpeReport', type);

			// Push to detail page
			const reportId = res.data.data;
			router.push({
				name: 'DetailSpeReport',
				params: { id: reportId },
				query: { v: company.value.value, i: company.value.db_instance },
			});
		}
		progress.value = null;
	} catch (err) {
		console.log(err.message);
		progress.value = null;

		if (err.response.data.message.includes('Error sending email')) {
			toast.add({
				severity: 'error',
				summary: err.response.data.message,
				life: 3000,
			});
			return;
		}

		if (err.message.includes('Network Error')) {
			console.log('network error');
			toast.add({
				severity: 'error',
				summary: 'Network Error',
				detail: 'Connection Down. Please check your submission status.',
			});
			return;
		}

		if (err.response.status === 400 || err.response.status === 403) {
			toast.add({
				severity: 'error',
				summary: err.response.data.message,
				life: 3000,
			});
			return;
		}

		toast.add({
			severity: 'error',
			summary: 'Server Error: Unsuccessfully save report',
			life: 3000,
		});
	}
};

// Update SPE Report
const handleUpdate = async (id, status) => {
	// Validate required fields
	const isFormValid = await v$.value.$validate();
	if (!isFormValid) {
		// Check error message
		const errMessage =
			v$.value.$errors.length &&
			v$.value.$errors[0].$validator === 'required'
				? 'All fields are required'
				: 'All fields are required';

		toast.add({
			severity: 'error',
			summary: errMessage,
			life: 3000,
		});
		return;
	}

	// Add file payload
	const formData = new FormData();
	formData.append('file', file.value);

	// Add body payload header
	const payload = {
		savePayload: {
			VENDOR_ID: supplier.value.vendor_id,
			PERIOD_NAME: period.value.semesterName,
			PERIOD_YEAR: period.value.semesterYear,
			PERIOD_SEMESTER: period.value.semesterNum,
			GRADE: grade.value.value,
			STATUS: status,
			LAST_UPDATED_BY: currentUser.value.id,
			LAST_UPDATE_DATE: formattingDate(new Date(), true),
		},
		emailPayload: {
			module: 'spereport',
			companyname: company.value.name,
			vendorname: supplier.value.vendor_name,
			period: period.value.semesterName,
			grade: grade.value.name,
			statusReport: status,
			idUser: currentUser.value.id, // For email to purchasing
			sendpodata: true, // Just for email condition to purchasing
		},
	};

	// Wrap body payload with formData
	const jsonPayload = JSON.stringify(payload);
	formData.append('payload', jsonPayload);

	try {
		progress.value = 'Save a report in progress. Please wait...';

		const res = await SpeReportService.updateReport(id, formData);

		console.log({ updateRes: res });

		if (res.status === 200) {
			progress.value = null;
			v$.value.$reset();

			if (file.value) {
				file.value = null;
				fileUpload.value.clear();
			}

			// Show toast
			toast.add({
				severity: 'success',
				summary: 'Successfully save report',
				life: 3000,
			});

			// Get report detail
			await getReportDetail(props.id);

			// Show toast
			toast.add({
				severity: 'success',
				summary: `Status change to ${statusReport.value}`,
				life: 3000,
			});

			return;
		}
		progress.value = null;
	} catch (err) {
		console.log(err);
		progress.value = null;

		if (err.response.data.message.includes('Error sending email')) {
			toast.add({
				severity: 'error',
				summary: err.response.data.message,
				life: 3000,
			});
			return;
		}

		if (err.message.includes('Network Error')) {
			console.log('network error');
			toast.add({
				severity: 'error',
				summary: 'Network Error',
				detail: 'Connection Down. Please check your submission status.',
			});
			return;
		}

		if (
			err.response.status === 400 ||
			err.response.status === 403 ||
			err.response.status === 404
		) {
			toast.add({
				severity: 'error',
				summary: err.response.data.message,
				life: 3000,
			});
			return;
		}

		toast.add({
			severity: 'error',
			summary: 'Server Error: Unsuccessfully save report',
			life: 3000,
		});
	}
};

// Save Capa for Supplier
const handleSaveCapa = async () => {
	// Validate required fields
	const isFormValid = await v$.value.$validate();
	if (!isFormValid) {
		// Check error message
		const errMessage =
			v$.value.$errors.length &&
			v$.value.$errors[0].$validator === 'required'
				? 'All fields are required'
				: v$.value.$errors[0].$message;

		toast.add({
			severity: 'error',
			summary: errMessage,
			life: 3000,
		});
		return;
	}

	const payload = {
		SPE_ID: props.id,
		DESCRIPTION: capaDesc.value,
		ROOT_CAUSE: rootCause.value,
		CORRECTIVE_ACTION: correctiveAction.value,
		PREVENTIVE_ACTION: preventiveAction.value,
		CREATED_BY: currentUser.value.id,
		CREATION_DATE: formattingDate(new Date(), true),
		LAST_UPDATED_BY: currentUser.value.id,
		LAST_UPDATE_DATE: formattingDate(new Date(), true),
	};

	// console.log({ payloadCapa: payload });

	try {
		progress.value = 'Save a CAPA report in progress. Please wait...';

		const res = await SpeReportService.submitCapa(payload);

		console.log({ capares: res });

		if (res.status === 201) {
			progress.value = null;
			v$.value.$reset();

			// Show toast
			toast.add({
				severity: 'success',
				summary: 'Successfully save CAPA for this report',
				life: 3000,
			});

			getCapaDetail(props.id);

			return;
		}
		progress.value = null;
	} catch (err) {
		console.log(err.message);
		progress.value = null;

		if (err.message.includes('Network Error')) {
			console.log('network error');
			toast.add({
				severity: 'error',
				summary: 'Network Error',
				detail: 'Connection Down. Please check your submission status.',
			});
			return;
		}

		if (err.response.status === 400 || err.response.status === 403) {
			toast.add({
				severity: 'error',
				summary: err.response.data.message,
				life: 3000,
			});
			return;
		}

		toast.add({
			severity: 'error',
			summary: 'Server Error: Unsuccessfully save CAPA report',
			life: 3000,
		});
	}
};

// Update Capa for Supplier
const handleUpdateCapa = async () => {
	// Reset errors
	v$.value.$reset();

	// Validate required fields
	if (
		!capaDesc.value ||
		!rootCause.value ||
		!correctiveAction.value ||
		!preventiveAction.value
	) {
		if (!capaDesc.value) {
			v$.value.capaDesc.$touch();
		}
		if (!rootCause.value) {
			v$.value.rootCause.$touch();
		}
		if (!correctiveAction.value) {
			v$.value.correctiveAction.$touch();
		}
		if (!preventiveAction.value) {
			v$.value.preventiveAction.$touch();
		}

		toast.add({
			severity: 'error',
			summary: v$.value.$errors[0].$message,
			life: 3000,
		});

		return;
	}

	// Validate minimun length
	if (
		(rootCause.value &&
			rootCause.value.length < requiredCapaLength.value) ||
		(correctiveAction.value &&
			correctiveAction.value.length < requiredCapaLength.value) ||
		(preventiveAction.value &&
			preventiveAction.value.length < requiredCapaLength.value)
	) {
		if (
			rootCause.value &&
			rootCause.value.length < requiredCapaLength.value
		) {
			v$.value.rootCause.$touch();
		}
		if (
			correctiveAction.value &&
			correctiveAction.value.length < requiredCapaLength.value
		) {
			v$.value.correctiveAction.$touch();
		}
		if (
			preventiveAction.value &&
			preventiveAction.value.length < requiredCapaLength.value
		) {
			v$.value.preventiveAction.$touch();
		}

		toast.add({
			severity: 'error',
			summary: v$.value.$errors[0].$message,
			life: 3000,
		});

		return;
	}

	// Payload
	const payload = {
		DESCRIPTION: capaDesc.value,
		ROOT_CAUSE: rootCause.value,
		CORRECTIVE_ACTION: correctiveAction.value,
		PREVENTIVE_ACTION: preventiveAction.value,
		LAST_UPDATED_BY: currentUser.value.id,
		LAST_UPDATE_DATE: formattingDate(new Date(), true),
	};

	// console.log({ payloadUpdateCapa: payload });

	try {
		progress.value = 'Save a CAPA report in progress. Please wait...';

		const res = await SpeReportService.updateCapa(capaId.value, payload);

		console.log({ updateCapaRes: res });

		if (res.status === 200) {
			progress.value = null;
			v$.value.$reset();

			// Show toast
			toast.add({
				severity: 'success',
				summary: 'Successfully save CAPA for this report',
				life: 3000,
			});

			getCapaDetail(props.id);

			return;
		}
		progress.value = null;
	} catch (err) {
		console.log(err.message);
		progress.value = null;

		if (err.message.includes('Network Error')) {
			console.log('network error');
			toast.add({
				severity: 'error',
				summary: 'Network Error',
				detail: 'Connection Down. Please check your submission status.',
			});
			return;
		}

		if (
			err.response.status === 400 ||
			err.response.status === 403 ||
			err.response.status === 404
		) {
			toast.add({
				severity: 'error',
				summary: err.response.data.message,
				life: 3000,
			});
			return;
		}

		toast.add({
			severity: 'error',
			summary: 'Server Error: Unsuccessfully save CAPA report',
			life: 3000,
		});
	}
};

// Handle clear form
const handleClear = () => {
	// Clear capa form
	capaDesc.value = null;
	rootCause.value = null;
	correctiveAction.value = null;
	preventiveAction.value = null;

	// Clear spe form for user purchasing
	if (currentUser.value.leveltenant === 1) {
		supplier.value = null;
		grade.value = null;
		period.value = null;
	}
};

// Download purchasing file report
const downloadPurFile = async () => {
	try {
		loadingDownloadPur.value = true;
		const levelTenant = currentUser.value.leveltenant;
		const res = await SpeReportService.downloadPurFile(props.id, {
			levelTenant,
		});

		const linkSource = `data:application/pdf;base64,${res.data.data}`;
		const downloadLink = document.createElement('a');
		const fileName = purFilename.value;
		downloadLink.href = linkSource;
		downloadLink.download = fileName;
		downloadLink.click();
		loadingDownloadPur.value = false;
	} catch (err) {
		console.log(err);
		loadingDownloadPur.value = false;
		toast.add({
			severity: 'error',
			summary: 'Server error: Download file is failed',
			life: 3000,
		});
	}
};

// Download supplier file report
const downloadSupFile = async () => {
	try {
		loadingDownloadSup.value = true;
		const levelTenant = currentUser.value.leveltenant;
		const res = await SpeReportService.downloadSupFile(props.id, {
			levelTenant,
		});

		const linkSource = `data:application/pdf;base64,${res.data.data}`;
		const downloadLink = document.createElement('a');
		const fileName = supFilename.value;
		downloadLink.href = linkSource;
		downloadLink.download = fileName;
		downloadLink.click();
		loadingDownloadSup.value = false;
	} catch (err) {
		console.log(err);
		loadingDownloadSup.value = false;
		toast.add({
			severity: 'error',
			summary: 'Server error: Download file is failed',
			life: 3000,
		});
	}
};
</script>

<style scoped></style>
