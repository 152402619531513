<template>
	<DataTable
		id="tableInvoicePaymentSchedule"
		:value="batchList"
		:lazy="true"
		:paginator="true"
		:rows="10"
		:loading="loading"
		:totalRecords="totalData"
		@page="onPage($event)"
		@sort="onSort($event)"
		:rowsPerPageOptions="[10, 20, 30]"
		responsiveLayout="scroll"
		:scrollable="true"
		scrollHeight="600px"
		scrollDirection="both"
		:sortable="true"
		:rowId="null"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #header>
			<div>
				<span class="p-input-icon-left">
					<i class="pi pi-search" />
					<InputText
						v-model="searchReportPayment"
						v-tooltip.right="'Please Press Enter to Search'"
						placeholder="Search Invoice Payment"
						@keyup.enter="populateTable()"
						@page="onPage($event)"
					/>
				</span>
			</div>
		</template>
		<template #empty>Invoice Payment Not found</template>
		<template #loading>Loading data, please wait...</template>

		<Column header="Invoice Num" field="INVOICE_NUM" style="width: 16%">
		</Column>
		<Column
			header="Invoice Amount"
			field="INVOICE_AMOUNT"
			style="width: 12%"
		>
		</Column>
		<Column header="Invoice Date" field="INVOICE_DATE" style="width: 12%">
		</Column>
		<Column header="Description" field="DESCRIPTION" style="width: 12%">
		</Column>
		<Column header="Amount Paid" field="AMOUNT_PAID" style="width: 12%">
		</Column>
		<Column header="Terms Date" field="TERMS_DATE" style="width: 12%">
		</Column>
		<Column
			header="Payment Schedule"
			field="PAYMENT_SCHEDULE"
			style="width: 12%"
		>
		</Column>
		<Column header="Invoice Type " field="INVOICE_TYPE" style="width: 12%">
		</Column>
	</DataTable>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import PaymentService from '../../../service/ReportPayment';
import AuthService from '@/service/AuthService';
import PoFulfillment from '@/service/PoFulfillment';
import LovService from '@/service/LovService';

const company = ref();
const supplier = ref();

onMounted(async () => {
	company.value = props.company;
	const info = await AuthService.whoAmi();
	if (info.status === 200) {
		levelTenant.value = info.data.data.leveltenant;
		if (levelTenant.value !== 2) {
			loading.value = true;
			supplier.value = props.supplier;
			// loading.value = true
			await populateTable();
		} else {
			if (company.value && !supplier.value) {
				const param = {
					db_instance: props.company.db_instance,
					org_id: props.company.value,
				};
				const respVendor = await PoFulfillment.getSuppBindComp(
					info.data.data.id
				);
				const vendorName = respVendor.data.data[0][0].vendorname;
				const respSuppName = await LovService.getSupplierName(param);
				if (respSuppName.status === 200) {
					const suppList = respSuppName.data.data;
					for (const s of suppList) {
						if (s.vendor_name === vendorName) {
							supplier.value = s;
							break;
						}
					}
				}
				await populateTable();
			}
			// loading.value = true
		}
	}
});
// eslint-disable-next-line no-undef
const props = defineProps({
	dateFrom: {
		type: Object,
	},
	dateTo: {
		type: String,
	},
	invoiceTo: {
		type: String,
	},
	invoiceFrom: {
		type: String,
	},
	type: {
		type: String,
	},
	company: {
		type: Object,
	},
	supplier: {
		type: Object,
	},
});

const batchList = ref();
// const totalData = ref(0);
const loading = ref(false);
const page = ref(1);
const rowsPerPage = ref(10);
const sortColumn = ref();
const sortType = ref();
const levelTenant = ref();
const tableData = ref([]);
// const batchList = ref();
const totalData = ref(0);
const searchReportPayment = ref();

const populateTable = async () => {
	loading.value = true;
	//
	if (props.company !== null && props.company !== undefined) {
		const payload = {
			org_id: company.value.value ? company.value.value : null,
			db_instance: company.value.db_instance,
			suppValue: supplier.value ? supplier.value.vendor_id : null,
			dateFrom: props.dateFrom
				? formatDateDexa(new Date(props.dateFrom))
				: null,
			dateTo: props.dateTo
				? formatDateDexa(new Date(props.dateTo).setHours(23, 59, 59))
				: null,
			invoiceTo: props.invoiceTo ? props.invoiceTo.INVOICE_NUM : null,
			invoiceFrom: props.invoiceFrom
				? props.invoiceFrom.INVOICE_NUM
				: null,
			search: searchReportPayment.value
				? searchReportPayment.value
				: null,
			type: props.type ? props.type.name : null,
			page: page.value,
			rows: rowsPerPage.value,
		};

		tableData.value = await PaymentService.getReportPaymentSchedule(
			payload
		);

		batchList.value = tableData.value.data.data.data;
		//
		totalData.value = tableData.value.data.data.totalData[0].totalData;
		//
	}
	loading.value = false;
	for (var date of batchList.value) {
		date.TERMS_DATE = dateFormat(date.TERMS_DATE);
		date.PAYMENT_SCHEDULE = dateFormat(date.PAYMENT_SCHEDULE);
		date.INVOICE_AMOUNT = formatCurrency(date.INVOICE_AMOUNT);
	}
};

const onPage = async (event) => {
	// loading.value = true
	page.value = event.page + 1;
	rowsPerPage.value = event.rows;
	await populateTable();
};

const onSort = async (event) => {
	// loading.value = true
	page.value = 1;
	rowsPerPage.value = event.rows;
	sortColumn.value = event.sortField;
	sortType.value = event.sortOrder;
	await populateTable();
};
function formatDateDexa(d) {
	var date = new Date(d);
	var respondDate =
		('00' + (date.getMonth() + 1)).slice(-2) +
		'/' +
		('00' + date.getDate()).slice(-2) +
		'/' +
		date.getFullYear() +
		' ' +
		('00' + date.getHours()).slice(-2) +
		':' +
		('00' + date.getMinutes()).slice(-2) +
		':' +
		('00' + date.getSeconds()).slice(-2);

	return respondDate;
}

function dateFormat(d) {
	var monthShortNames = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];
	var t = new Date(d);
	return (
		t.getDate() +
		' ' +
		monthShortNames[t.getMonth()] +
		' ' +
		t.getFullYear() +
		' '
	);
}
const formatCurrency = (value) => {
	if (value) return value.toLocaleString('id-ID');
	return;
};
</script>
<style scoped></style>
