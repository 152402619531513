<template>
<!-- eslint-disable -->
  <DataTable
      :value="tableList"
      :paginator="true"
      :rows="10"
      :loading="loading"
      :rowsPerPageOptions="[10, 20, 30]"
      @page="onPage($event)"
      responsiveLayout="scroll"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
  >
    <template #empty>No Invoice found</template>
    <template #loading>Loading data</template>
    <Column header="Vendor" headerStyle="min-width:15rem;">
      <template #body="slotProps">
        <AutoComplete
          v-model="slotProps.data.vendor_name"
          :suggestions="filteredVendor"
          @complete="searchVendors($event)"
          @item-select="getVendor(slotProps, slotProps.index + page)"
          :dropdown="true"
          field="vendor_name"
          forceSelection
          :disabled="!props.disabled"
        >
        </AutoComplete>
      </template>
    </Column>
    <Column header="No Faktur Pajak" headerStyle="min-width:10rem;" >
      <template #body="slotProps">
        <InputText 
          id="inputmask"
          type="text"
          v-model="slotProps.data.TAX_NO"
          :disabled="!props.disabled"
          :class="{'p-invalid': tableList[slotProps.index + page].TAX_VUELIDATE}"
        />
        <div v-if="(tableList[slotProps.index + page].TAX_VUELIDATE)">
          <small class="p-error">{{'Tax is empty'}}</small>
        </div>
      </template>
    </Column>
    <Column header="Tanggal Faktur Pajak" headerStyle="min-width:10rem;">
      <template #body="slotProps">
         <Calendar :showIcon="true" :showButtonBar="true" id="dateformat"
          dateFormat="dd M yy"
          style="text-align: center" v-model="slotProps.data.TAX_DATE"
          :disabled="!props.disabled"
          :class="{'p-invalid': tableList[slotProps.index + page].TAX_DATE_VUELIDATE}">
          >
          </Calendar>
        <div v-if="(tableList[slotProps.index + page].TAX_DATE_VUELIDATE)">
          <small class="p-error">{{'Tax Date is empty'}}</small>
        </div>
      </template>
    </Column>
    <Column header="Nilai Pajak" headerStyle="min-width:10rem;">
      <template #body="slotProps">
        <InputNumber
          id="locale-german"
          v-model="slotProps.data.AMOUNT"
          :minFractionDigits="2"
          locale="de-DE"
          :disabled="!props.disabled"
          :min="1"
          :class="{'p-invalid': tableList[slotProps.index + page].AMOUNT_VUELIDATE}"
        />
        <div v-if="(tableList[slotProps.index + page].AMOUNT_VUELIDATE)">
          <small class="p-error">{{'Amount Reimburse is empty'}}</small>
        </div>
      </template>
    </Column>
    <Column header="Account" headerStyle="min-width:10rem;">
      <template #body="slotProps">
        <AutoComplete
          v-model="slotProps.data.ACCOUNT"
          field="account"
          :suggestions="filteredAccount"
          :dropdown="true"
          @complete="searchAccount($event)"
          @item-select="getAccountId($event, slotProps.index + page)"
          :disabled="!props.disabled"
          :class="{'p-invalid': tableList[slotProps.index + page].ACCOUNT_VUELIDATE}"
          forceSelection
        />
        <div v-if="(tableList[slotProps.index + page].ACCOUNT_VUELIDATE)">
          <small class="p-error">{{'Account is empty'}}</small>
        </div>
      </template>
    </Column>
    <Column header="Group Name" headerStyle="min-width:10rem;">
      <template #body="slotProps">
        <div class="grid formgrid">
            <div class="col-10 mb-0 lg:col-10 lg:mb-0">
              <AutoComplete
                  v-model="slotProps.data.GROUP_NAME"
                  :suggestions="filteredGroup"
                  @complete="searchGroup($event)"
                  @item-select="getGroupValue($event, slotProps.index + page)"
                  :dropdown="true"
                  field="NAME"
                  forceSelection
                  :disabled="!props.disabled"
                  :class="{'p-invalid': tableList[slotProps.index + page].GROUP_NAME_VUELIDATE}"
                >
              </AutoComplete>
              <div v-if="(tableList[slotProps.index + page].GROUP_NAME_VUELIDATE)">
                <small class="p-error">{{'Group Name is empty'}}</small>
              </div>
            </div>
            <div class="col-1 mb-0 lg:col-1 lg:mb-0">
              <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-text"
                  @click="deleteLine(slotProps.data)"
                  v-tooltip="'Delete'"
                  :disabled="!props.disabled"
              />
            </div>
        </div>
      </template>
    </Column>
  </DataTable>

  <Dialog header="Delete Reimburse" v-model:visible="deleteReimburseValue" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
      <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="vendorValue">Are you sure you want to delete Reimburse for Vendor <b>{{vendorValue.vendor_name}}</b> from Database?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteReimburseValue = false"/>
                <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteReimburse" />
            </template>
  </Dialog>

</template>

<script setup>
import {onMounted, ref, defineEmits, watch} from "vue";
import AuthService from "@/service/AuthService";
import PoFulfillment from "@/service/PoFulfillment";
import SearchInvoiceProxyNonPO from "../../../service/InvoiceProxyService"
import LovService from "@/service/LovService";
import { useToast } from 'primevue/usetoast';

const company = ref()
const supplier = ref()
// const idValue = ref()
const tableList = ref()
const page = ref(0)
const line = ref({});
const filteredAccount = ref();
const accountData = ref();
const emit = defineEmits('tableList')


onMounted(async () => {
  company.value = props.company;
  // 
  const info = await AuthService.whoAmi();
  if (info.status === 200) {
    levelTenant.value = info.data.data.leveltenant;
    if (levelTenant.value !== 2) {
      loading.value = true
      supplier.value = props.supplier;
    //   loading.value = true
      await populateTable();
    } else {
      if (company.value && !supplier.value) {
        const param = {
          db_instance: props.company.db_instance,
          org_id: props.company.value
        }
        const respVendor = await PoFulfillment.getSuppBindComp(info.data.data.id);
        const vendorName = respVendor.data.data[0][0].vendorname;
        const respSuppName = await LovService.getSupplierName(param);
        if (respSuppName.status === 200) {
          const suppList = respSuppName.data.data;
          for (const s of suppList) {
            if (s.vendor_name === vendorName) {
              supplier.value = s;
              break;
            }
          }
        }
        await populateTable()
      }
      // loading.value = true

    }
  }
})
// eslint-disable-next-line no-undef
const props = defineProps({
  data: {
    type : Object
  },
  line: {
    type : Object
  },
  vendor_id : {
    type : Object
  },
  vendor_name : {
    type : Object
  },
  disabled: {
    type : Boolean
  },
  company: {
    type: Object
  },
  cost_center: {
    type: Object
  },
  submit: {
    type : Boolean
  },
  tableVuelidate:{
    type : Object
  },
  coa_id: {
    type : Object
  },
  org_id : {
    type : Object
  },
  db_instance : {
    type : Object
  }
})

const toast = useToast();
const loading = ref(false);
const levelTenant = ref();
const filteredVendor = ref();
const vendor = ref();
const filteredGroup = ref();
const group = ref();
const vendorValue = ref()
const deleteReimburseValue = ref(false)

watch(() => props.line, (newValue) => {
  // 
  if (newValue > 0 ){
    addLine()
  }
   // Do something with the updated value.
});


watch(() => props.tableVuelidate , (newValue) => {
  for (const i in tableList.value){
  if (props.tableVuelidate !== null){
    if (newValue[i]['EINVOICE_REIMBURSMENT_ID']){
      if (newValue[i].TAX_NO[0] !== undefined){
        tableList.value[i].TAX_VUELIDATE = true
      } else if (newValue[i].TAX_NO[0] == undefined) {
        tableList.value[i].TAX_VUELIDATE = false
      } 
    } else {
      tableList.value[i].TAX_VUELIDATE = false
    } 
    } else {
      tableList.value[i].TAX_VUELIDATE = false
    }
  }
})

watch(() => props.tableVuelidate , (newValue) => {
  for (const i in tableList.value){
  if (props.tableVuelidate !== null){
    if (newValue[i]['EINVOICE_REIMBURSMENT_ID']){
      if (newValue[i].TAX_DATE[0] !== undefined){
        tableList.value[i].TAX_DATE_VUELIDATE = true
      } else if (newValue[i].TAX_DATE[0] == undefined) {
        tableList.value[i].TAX_DATE_VUELIDATE = false
      } 
    } else {
      tableList.value[i].TAX_DATE_VUELIDATE = false
    } 
    } else {
      tableList.value[i].TAX_DATE_VUELIDATE = false
    }
  }
})

watch(() => props.tableVuelidate , (newValue) => {
  for (const i in tableList.value){
  if (props.tableVuelidate !== null){
    if (newValue[i]['EINVOICE_REIMBURSMENT_ID']){
      if (newValue[i].AMOUNT[0] !== undefined){
        tableList.value[i].AMOUNT_VUELIDATE = true
      } else if (newValue[i].AMOUNT[0] == undefined) {
        tableList.value[i].AMOUNT_VUELIDATE = false
      } 
    } else {
      tableList.value[i].AMOUNT_VUELIDATE = false
    } 
    } else {
      tableList.value[i].AMOUNT_VUELIDATE = false
    }
  }
})

watch(() => props.tableVuelidate , (newValue) => {
  
  for (const i in tableList.value){
  
  if (props.tableVuelidate !== null){
    
    if (newValue[i]['EINVOICE_REIMBURSMENT_ID']){
      if (newValue[i].GROUP_NAME[0] !== undefined){
        tableList.value[i].GROUP_NAME_VUELIDATE = true
      } else if (newValue[i].GROUP_NAME[0] == undefined) {
        tableList.value[i].GROUP_NAME_VUELIDATE = false
      } 
    } else {
      tableList.value[i].GROUP_NAME_VUELIDATE = false
    } 
    } else if (props.tableVuelidate === null) {
      
      tableList.value[i].GROUP_NAME_VUELIDATE = false
    }
  }
})

watch(() => props.tableVuelidate , (newValue) => {
  for (const i in tableList.value){
  if (props.tableVuelidate !== null){
      
    if (newValue[i]['EINVOICE_REIMBURSMENT_ID']){
      // 
      if (newValue[i].ACCOUNT[0] !== undefined){
        tableList.value[i].ACCOUNT_VUELIDATE = true
      } else if (newValue[i].ACCOUNT[0] == undefined) {
        tableList.value[i].ACCOUNT_VUELIDATE = false
      } 
    } else {
      tableList.value[i].ACCOUNT_VUELIDATE = false
    } 
    } else {
      tableList.value[i].ACCOUNT_VUELIDATE = false
    }
  }
})

watch(() => props.submit, (newValue) => {
  if (newValue !== null){
    for (const i in tableList.value){
      tableList.value[i].RESPONSE = false
      tableList.value[i].VALIDATE_ITEM = false
    }
  }
})

watch(() => props.cost_center, (newValue) => {
  if (newValue){
    getAccount()
  }
});

const populateTable = async () => {
  loading.value = true
  // 
  if (props.data !== null ) {
    // 
    const payload = { id : props.data?props.data:null}
    const data = await SearchInvoiceProxyNonPO.getDetailTableReimburse(payload)
    // 
    if (data.data.data.data.length === 0){
      tableList.value = []
    } else {
      tableList.value = data.data.data.data
      await tableList.value.forEach(element => {
        element.TAX_DATE = new Date(element.TAX_DATE)
        element.VENDOR_VUELIDATE = false
        element.TAX_VUELIDATE = false
        element.TAX_DATE_VUELIDATE = false
        element.AMOUNT_VUELIDATE = false
        element.GROUP_NAME_VUELIDATE = false
        element.ACCOUNT_VUELIDATE = false
      });
    }
  await getVendors()
  await getGroup()
  await getAccount()
  emit('tableList', tableList)
  }
  loading.value = false;
}

const onPage = async (event) => {
  page.value = event.page * event.rows
}

const addLine = async () => {
  
  if (props.disabled){
    const data = await SearchInvoiceProxyNonPO.getListDetailReimburse()
    // 
    if (data.status === 200){
      // let index
      // if (tableList.value === undefined){
      //   index = 1
      // } else {
      //   index = tableList.value.length
      // }
      line.value.EINVOICE_REIMBURSMENT_ID = data.data.data.data[0].ID + tableList.value.length
      line.value.TAX_NO = null
      line.value.COA_ID = props.coa_id.COA_ID
      line.value.ACCOUNT = props.coa_id.ACCOUNT
      line.value.GROUP_NAME = null
      line.value.AMOUNT = null
      line.value.TAX_DATE = null
      line.value.vendor_id = null
      line.value.vendor_name = null
      line.value.ISNEW = true;
      tableList.value.push(line.value)
      
      toast.add({severity:'success', summary: 'Line Create', detail: 'Line Successfully Created', life: 3000});
      line.value = {}
      emit('tableList', tableList)
    }
    else {
      toast.add({severity:'error', summary: 'Something Wrong', detail: 'Disconnected', life: 3000});
    }
  }
  else{
    toast.add({severity:'error', summary: 'Disabled', detail: "Can't add line", life: 3000});
  }
}

const deleteLine = async (rowIndex) => {
    const payload = { idheader : parseInt(props.data) ,iddetail : rowIndex.EINVOICE_REIMBURSMENT_ID, value : false}
    const data = await SearchInvoiceProxyNonPO.deleteReimburse(payload)
    if (data.data.data.data.length !== 0){
      vendorValue.value = rowIndex
      deleteReimburseValue.value = true
    }
    else{
      tableList.value = tableList.value.filter(val => val.EINVOICE_REIMBURSMENT_ID !== rowIndex.EINVOICE_REIMBURSMENT_ID)
      toast.add({severity:'error', summary: 'Line Delete', detail: 'Line Successfully Deleted', life: 3000})
      emit('tableList', tableList)
    }
  }

const deleteReimburse = async () => {
    // 
    const payload = { idheader : parseInt(props.data) ,iddetail : vendorValue.value.EINVOICE_REIMBURSMENT_ID, value : true}
    const data = await SearchInvoiceProxyNonPO.deleteReimburse(payload)
    if (data.status === 200){
      deleteReimburseValue.value = false
      tableList.value = tableList.value.filter(val => val.EINVOICE_REIMBURSMENT_ID !== vendorValue.value.EINVOICE_REIMBURSMENT_ID)
      toast.add({severity:'error', summary: 'Line Delete', detail: 'Line Successfully Deleted', life: 3000})
      emit('tableList', tableList)
    }
  }

const getVendors = async () => {
  const payload = {db_instance : props.db_instance, org_id : props.org_id}
  const data = await SearchInvoiceProxyNonPO.getVendors(payload)
  if (data.status === 200){
    vendor.value = data.data.data.data
    vendor.value.forEach(element => {
      if (element.vendor_name === null) {
        element.vendor_name = ' '
      } 
    });
  }
}

const searchVendors = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredVendor.value = [...vendor.value]
    } else {
      filteredVendor.value = vendor.value.filter((comp) => {
        return comp.vendor_name.toLowerCase().match(event.query.toLowerCase());
      })
    }
  }, 250);
}

const getGroup = async () => {
  const payload = {db_instance : props.db_instance}
  const data = await SearchInvoiceProxyNonPO.getGroupName(payload)
  if (data.status === 200){
    group.value = data.data.data.data
    // 
  }
}

const getVendor = (data, index) => {
  const vendor_name = data.data.vendor_name
  tableList.value[index].vendor_name = vendor_name.vendor_name
  tableList.value[index].vendor_id = vendor_name.vendor_id
}

const getGroupValue = (event, index) => {
  tableList.value[index].GROUP_NAME = event.value.NAME
}

const searchGroup = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredGroup.value = [...group.value]
    } else {
      filteredGroup.value = group.value.filter((comp) => {
        return comp.NAME.toLowerCase().match(event.query.toLowerCase());
      })
    }
  }, 250);
}

const getAccount = async () => {
  // 
  const payload = {
    orgId: props.org_id,
    dbInstance: props.db_instance,
    cost_center: props.cost_center.SEGMENT4
  };
  // 
  const res = await SearchInvoiceProxyNonPO.accountPic(payload);
  // 
  if (res.status === 200) {
    // 
    accountData.value = res.data.data.data;
  }
};

const getAccountId = async (event, index) => {
  tableList.value[index].ACCOUNT = event.value.account
  tableList.value[index].COA_ID = event.value.code_combination_id

  emit('tableList', tableList)
}

const searchAccount = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredAccount.value = [...accountData.value];
    } else {
      filteredAccount.value = accountData.value.filter((val) => {
        if (val.account === null || val.account === undefined) {
          return val;
        }
        return val.account.toLowerCase().match(event.query.toLowerCase());
      });
    }
  }, 250);
};


</script>

<style scoped>

</style>