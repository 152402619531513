<template>
  <DataTable
    :value="tableList"
    :paginator="true"
    :rows="10"
    :rowsPerPageOptions="[10, 20, 30]"
    responsiveLayout="scroll"
    @page="onPage($event)"
    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
  >
    <template #empty>No Invoice found</template>
    <template #loading>Loading data</template>
    <Column header="Vendor" headerStyle="min-width:15rem;">
      <template #body="slotProps">
        <AutoComplete
          v-model="slotProps.data.vendor_name"
          :suggestions="filteredVendor"
          @complete="searchVendors($event)"
          @item-select="getVendor(slotProps, slotProps.index + page)"
          :dropdown="true"
          field="vendor_name"
          optionValue="vendor_id"
          forceSelection
          :disabled="props.disabled"
        >
        </AutoComplete>
      </template>
    </Column>
    <Column header="No Faktur Pajak" headerStyle="min-width:10rem;">
      <template #body="slotProps">
        <InputText
          id="inputmask"
          type="text"
          v-model="slotProps.data.TAX_NO"
          :disabled="props.disabled"
          @input="onChangeTaxNo($event, slotProps.index + page)"
        />
      </template>
    </Column>
    <Column header="Tanggal Faktur Pajak" headerStyle="min-width:10rem;">
      <template #body="slotProps">
        <Calendar
          :showIcon="true"
          :showButtonBar="true"
          id="dateformat"
          dateFormat="dd M yy"
          style="text-align: center"
          v-model="slotProps.data.TAX_DATE"
          :disabled="props.disabled"
          @input="onChangeTaxDate($event, slotProps.index + page)"
        >
        </Calendar>
      </template>
    </Column>
    <Column header="Nilai Pajak" headerStyle="min-width:10rem;">
      <template #body="slotProps">
        <InputNumber
          id="locale-german"
          min="0"
          v-model="slotProps.data.AMOUNT"
          :minFractionDigits="2"
          locale="de-DE"
          :disabled="props.disabled"
          @input="onChangeTaxAmount($event, slotProps.index + page)"
        />
      </template>
    </Column>
    <Column header="Account" headerStyle="min-width:15rem;">
      <template #body="slotProps">
        <!-- <Dropdown
          :editable="true"
          v-model="slotProps.data.ACCOUNT"
          @change="onChangeCOA($event, slotProps.index)"
          :options="getCOA"
          optionLabel="ACCOUNT"
          optionValue="CODE_COMBINATION_ID"
          style="width: 100%"
          :disabled="props.disabled"
          forceSelection
        /> -->
        <AutoComplete
          :suggestions="filteredCoa"
          @complete="searchAccount($event)"
          :dropdown="true"
          field="ACCOUNT"
          :placeholder="placeholder"
          v-model="slotProps.data.ACCOUNT"
          style="width: 100%"
          :disabled="props.disabled"
          @item-select="onChangeCOA($event, slotProps.index + page)"
          forceSelection
        >
          <!-- <template #item="slotProp">
            {{ slotProp.item.CONCATENATED_SEGMENTS }}
          </template> -->
        </AutoComplete>
      </template>
    </Column>
    <Column header="Group Name" headerStyle="min-width:10rem;">
      <template #body="slotProps">
        <div class="grid formgrid">
          <div class="col-10 mb-0 lg:col-10 lg:mb-0">
            <AutoComplete
              v-model="slotProps.data.GROUP_NAME"
              :suggestions="filteredGroup"
              @complete="searchGroup($event)"
              @item-select="getGroupValue($event, slotProps.index + page)"
              :dropdown="true"
              field="name"
              forceSelection
              :disabled="props.disabled"
            >
            </AutoComplete>
          </div>
          <div class="col-1 mb-0 lg:col-1 lg:mb-0">
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-text"
              @click="deleteLine(slotProps.data, slotProps.index + page)"
              v-tooltip="'Delete'"
              :disabled="props.disabled"
            />
          </div>
        </div>
      </template>
    </Column>
  </DataTable>

  <Dialog
    header="Delete Reimburse"
    v-model:visible="deleteReimburseValue"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span v-if="vendorValue"
        >Are you sure you want to delete Reimburse for Vendor
        <b>{{ vendorValue.vendor_name }}</b
        >?</span
      >
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="deleteReimburseValue = false"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        class="p-button-text"
        @click="deleteReimburse"
      />
    </template>
  </Dialog>
</template>

<script setup>
import { onMounted, ref, defineEmits, watch } from "vue";
import AuthService from "@/service/AuthService";
// import PoFulfillment from "@/service/PoFulfillment";
import {filterAuthByVendorname} from "@/helper/filterAuthByVendorname";
import ListInvoiceNonPo from "@/service/ListInvoiceNonPo";
// import invoiceNonPOservice from "@/service/InvoiceNonPOservice";
import invoiceNonPOservice from "@/service/InvoiceNonPOservice";
// import LovService from "@/service/LovService";
import { useToast } from "primevue/usetoast";

const company = ref();
const supplier = ref();
// const idValue = ref()
const tableList = ref();
const page = ref(0);
const line = ref({});
const emit = defineEmits("tableList");
const getCOA = ref([]);
const filteredCoa = ref([]);

onMounted(async () => {
  company.value = props.company;
  const info = await AuthService.whoAmi();
  if (info.status === 200) {
    levelTenant.value = info.data.data.leveltenant;
    if (levelTenant.value !== 2) {
      loading.value = true;
      supplier.value = props.supplier;
      //   loading.value = true
      await populateTable();
    } else {
      if (company.value && !supplier.value) {
        const param = {
          db_instance: props.company.db_instance,
          org_id: props.company.value,
        };
        
        const checkAuth = await filterAuthByVendorname({
          info_id: info.data.data.id,
          dbInstance: param.db_instance,
          value: param.org_id
        });

        

        supplier.value = checkAuth.supplier;
        await populateTable();
      }
      // loading.value = true
    }
  }
});
// eslint-disable-next-line no-undef
const props = defineProps({
  data: {
    type: Object,
  },
  line: {
    type: Object,
  },
  vendor_id: {
    type: Object,
  },
  disabled: {
    type: Boolean,
  },
  company: {
    type: Object,
  },
  dataReimbursement: {
    type: Object,
  },
  reimburseCategory: {
    type: Object,
  },
  costCenter: {
    type: String,
  },
});

const toast = useToast();
const loading = ref(false);
const levelTenant = ref();
const filteredVendor = ref();
const vendor = ref();
const filteredGroup = ref();
const group = ref();
const vendorValue = ref();
const deleteReimburseValue = ref(false);

watch(
  () => props.line,
  (newValue) => {
    if (newValue > 0) {
      addLine();
    }
    // Do something with the updated value.
  }
);

const populateTable = async () => {
  loading.value = true;
  if (props.dataReimbursement !== null) {
    await getVendors();
    await getGroup();
    await getDropdownCOA();
    // const payload = { id: props.data ? props.data : null };
    // const data = await ListInvoiceNonPo.getDetailTableReimburse(payload);
    // tableList.value = data.data.data.data;
    tableList.value = props.dataReimbursement;
    tableList.value.forEach((element, index) => {
      element.TAX_DATE = new Date(element.TAX_DATE);

      for (const vendorData of vendor.value) {
        if (vendorData.vendor_id === element.VENDOR_ID) {
          tableList.value[index].VENDOR_NAME = vendorData.vendor_name;
          tableList.value[index].vendor_name = vendorData.vendor_name;
          tableList.value[index].VENDOR_ID = vendorData.vendor_id;
        }
      }

      for (const groupData of group.value) {
        if (groupData.name === element.GROUP_NAME) {
          tableList.value[index].GROUP_NAME = groupData.name;
        }
      }

      for (const coaData of getCOA.value) {
        if (element.COA_ID == coaData.CODE_COMBINATION_ID) {
          tableList.value[index].CODE_COMBINATION_ID =
            element.CODE_COMBINATION_ID;
          tableList.value[index].ACCOUNT = coaData.ACCOUNT;
        }
      }
    });

    emit("tableList", tableList);
  }
  loading.value = false;
};

const onPage = async (event) => {
  page.value = event.page * event.rows;
};

const addLine = async () => {
  if (!props.disabled) {
    // const index = tableList.value.length - 1;
    // line.value.EINVOICE_REIMBURSMENT_ID =
    //   tableList.value[index].EINVOICE_REIMBURSMENT_ID + 1;
    line.value.EINVOICE_REIMBURSMENT_ID = 0;
    line.value.COA_ID = props.reimburseCategory.COA_ID;
    line.value.ACCOUNT = props.reimburseCategory.ACCOUNT;
    tableList.value.push(line.value);
    emit("tableList", tableList);
    toast.add({
      severity: "success",
      summary: "Line Create",
      detail: "Line Successfully Created",
      life: 3000,
    });
    line.value = {};
  } else {
    toast.add({
      severity: "error",
      summary: "Disabled",
      detail: "Can't add line",
      life: 3000,
    });
  }
};

const deleteLine = async (rowIndex, index) => {
  //   const payload = {
  //     idheader: parseInt(props.data),
  //     iddetail: rowIndex.EINVOICE_REIMBURSMENT_ID,
  //     value: false,
  //   };
  //   const data = await ListInvoiceNonPo.deleteReimburse(payload);
  //   if (data.data.data.data.length !== 0) {
  //     vendorValue.value = rowIndex;
  //     deleteReimburseValue.value = true;
  //   } else {
  //     tableList.value = tableList.value.filter(
  //       (val) =>
  //         val.EINVOICE_REIMBURSMENT_ID !== rowIndex.EINVOICE_REIMBURSMENT_ID
  //     );
  //     toast.add({
  //       severity: "error",
  //       summary: "Line Delete",
  //       detail: "Line Successfully Deleted",
  //       life: 3000,
  //     });
  //   }

  // tableList.value = tableList.value.filter(
  //   (val) => val.EINVOICE_REIMBURSMENT_ID !== rowIndex.EINVOICE_REIMBURSMENT_ID
  // );

  tableList.value = tableList.value.filter((val, idx) => idx !== index);
  emit("tableList", tableList);
  toast.add({
    severity: "error",
    summary: "Line Delete",
    detail: "Line Successfully Deleted",
    life: 3000,
  });
};

const deleteReimburse = async () => {
  const payload = {
    idheader: parseInt(props.data),
    iddetail: vendorValue.value.EINVOICE_REIMBURSMENT_ID,
    value: true,
  };
  const data = await ListInvoiceNonPo.deleteReimburse(payload);
  if (data.status === 200) {
    deleteReimburseValue.value = false;
    tableList.value = tableList.value.filter(
      (val) =>
        val.EINVOICE_REIMBURSMENT_ID !==
        vendorValue.value.EINVOICE_REIMBURSMENT_ID
    );
    toast.add({
      severity: "error",
      summary: "Line Delete",
      detail: "Line Successfully Deleted",
      life: 3000,
    });
  }
};

const getVendors = async () => {
  //   const payload = {
  //     db_instance: props.company.db_instance,
  //     org_id: props.company.value,
  //   };
  //   const data = await ListInvoiceNonPo.getVendors(payload);
  //   if (data.status === 200) {
  //     vendor.value = data.data.data.data;
  //     vendor.value.forEach((element) => {
  //       if (element.vendor_name === null) {
  //         element.vendor_name = " ";
  //       }
  //     });
  //   }
  const result = await invoiceNonPOservice.getListVendorFin(
    props.company.value,
    props.company.db_instance
  );
  vendor.value = result.data.data;
};

const searchVendors = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredVendor.value = [...vendor.value];
    } else {
      filteredVendor.value = vendor.value.filter((comp) => {
        return comp.vendor_name.toLowerCase().match(event.query.toLowerCase());
      });
    }
  }, 250);
};

const getGroup = async () => {
  //   const payload = { db_instance: props.company.db_instance };
  //   const data = await ListInvoiceNonPo.getGroupName(payload);
  //   if (data.status === 200) {
  //     group.value = data.data.data.data;
  //   }
  const result = await invoiceNonPOservice.getListGroup(
    "DXG_REIMBURST_GROUP",
    props.company.db_instance
  );
  group.value = result.data.data;
};

const getVendor = (data, index) => {
  const vendor = data.data.vendor_name;
  tableList.value[index].VENDOR_NAME = vendor.vendor_name;
  tableList.value[index].VENDOR_ID = vendor.vendor_id;
  tableList.value[index].CHANGE = true;
};

const getDropdownCOA = async () => {
  const result = await invoiceNonPOservice.getListCoa(
    props.company.value,
    props.company.db_instance,
    props.costCenter
  );
  getCOA.value = result.data.data;
};

const searchAccount = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredCoa.value = [...getCOA.value];
    } else {
      filteredCoa.value = getCOA.value.filter((coa) => {
        // return coa.ACCOUNT.toLowerCase().match(event.query.toLowerCase());
        return coa.ACCOUNT.toLowerCase().includes(event.query.toLowerCase());
      });
    }
  }, 250);
};

const onChangeCOA = async (event, index) => {
  let coaData = null;
  for (const coa of getCOA.value) {
    if (coa.CODE_COMBINATION_ID == event.value.CODE_COMBINATION_ID) {
      coaData = coa;
    }
    // if (coa.CODE_COMBINATION_ID == event.value) {
    //   coaData = coa;
    // }
  }
  if (coaData) {
    tableList.value[index].ACCOUNT = coaData.ACCOUNT;
    tableList.value[index].CODE_COMBINATION_ID = coaData.CODE_COMBINATION_ID;
    tableList.value[index].COA_ID = coaData.CODE_COMBINATION_ID;
    tableList.value[index].CHANGE = true;
    emit("tableList", tableList);
  } else {
    toast.add({
      severity: "error",
      summary: "Data not Found",
      detail: "Data not Found",
      life: 3000,
    });
  }
};

const onChangeTaxNo = (event, index) => {
  tableList.value[index].TAX_NO = event.value;
  tableList.value[index].CHANGE = true;
  emit("tableList", tableList);
};

const onChangeTaxDate = (event, index) => {
  tableList.value[index].TAX_DATE = event.value;
  tableList.value[index].CHANGE = true;
  emit("tableList", tableList);
};

const onChangeTaxAmount = (event, index) => {
  tableList.value[index].AMOUNT = event.value;
  tableList.value[index].CHANGE = true;
  emit("tableList", tableList);
};

const getGroupValue = (event, index) => {
  tableList.value[index].GROUP_NAME = event.value.name;
  tableList.value[index].CHANGE = true;
};

const searchGroup = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredGroup.value = [...group.value];
    } else {
      filteredGroup.value = group.value.filter((comp) => {
        return comp.name.toLowerCase().match(event.query.toLowerCase());
      });
    }
  }, 250);
};
</script>

<style scoped></style>
