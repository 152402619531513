import supplierMasterApi from "./api/SpbSupManApi"
class supplierMaster {
    async getAllData(payload) {
        return supplierMasterApi.post("/suppliermaster/getalldata", payload);
    }
    async getDataAllDataInformation(payload) {
        return supplierMasterApi.post(
            "/suppliermaster/alldatainformation",
            payload
        );
    }
    async getDataCommunicationInformation(payload) {
        return supplierMasterApi.post(
            "/suppliermaster/communicationinformation",
            payload
        );
    }
    async getDetailLegalInformation(payload) {
        return supplierMasterApi.post(
            "/suppliermaster/getdetaillegalinformation",
            payload
        );
    }
    async getVatNum(payload) {
        return supplierMasterApi.post("/suppliermaster/getvatnum", payload);
    }
    async updateLegalInformation(payload) {
        return supplierMasterApi.post(
            "/suppliermaster/updateLegalInformation",
            payload
        );
    }
    async createLegalInformation(payload) {
        return supplierMasterApi.post(
            "/suppliermaster/createLegalInformation",
            payload
        );
    }
    async deleteLegalInformation(payload) {
        return supplierMasterApi.post(
            "/suppliermaster/deleteLegalInformation",
            payload
        );
    }
    async getAttachmentFile(legalId, levelTenant) {
        const res = supplierMasterApi.get(
            `/suppliermaster/attachmentfile/${legalId}/${levelTenant}`
        );
        return res;
    }
}

export default new supplierMaster();