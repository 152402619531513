<template>
	<DataTable
		:value="masterVendor"
		:lazy="true"
		:paginator="true"
		:rows="10"
		:loading="loading"
		:totalRecords="totalData"
		@page="onPage($event)"
		@sort="onSort($event)"
		:rowsPerPageOptions="[10, 20, 30]"
		responsiveLayout="scroll"
		:scrollable="true"
		scrollHeight="600px"
		scrollDirection="both"
		:sortable="true"
		:rowId="null"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #header>
			<div>
				<span class="p-input-icon-left">
					<i class="pi pi-search" />
					<InputText
						v-model="search"
						v-tooltip.right="'Please Press Enter to Search'"
						placeholder="Search Data"
						@keyup.enter="populateTable()"
						@page="onPage($event)"
					/>
				</span>
			</div>
		</template>
		<template #empty>Data Not found</template>
		<template #loading>Loading data, please wait...</template>

		<Column header="Company" field="COMPANY" style="width: 25%"> </Column>
		<Column header="Category" field="CATEGORY_NAME" style="width: 20%">
		</Column>
		<Column header="vendor" field="vendor_name" style="width: 20%">
		</Column>
		<Column header="User Finance" field="user_name" style="width: 20%">
		</Column>
		<Column header="Status" style="width: 10%">
			<template #body="{ data }">
				<Button
					v-if="data.status === 'ACTIVE'"
					v-tooltip="'Active'"
					class="p-button-rounded p-button-text p-button-lg p-button-success"
					icon="pi pi-check-circle"
				/>
				<Button
					v-else-if="data.status == NULL"
					class="p-button-rounded p-button-text p-button-lg"
					label=""
				/>
				<Button
					v-else
					v-tooltip="'Inactive'"
					class="p-button-rounded p-button-text p-button-lg"
					icon="pi pi-times-circle"
				/>
			</template>
		</Column>

		<Column headerStyle="width:4rem" header="Detail" selectionMode="single">
			<template #body="slotProps">
				<Button
					icon="pi pi-eye"
					class="p-button-rounded p-button-text"
					@click="editChildren(slotProps.data)"
				/>
			</template>
		</Column>
	</DataTable>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import VendorServices from '../../../service/masterVendorService';
import AuthService from '@/service/AuthService';
import PoFulfillment from '@/service/PoFulfillment';
import LovService from '@/service/LovService';

const company = ref();
const supplier = ref();

onMounted(async () => {
	company.value = props.company;
	const info = await AuthService.whoAmi();
	if (info.status === 200) {
		levelTenant.value = info.data.data.leveltenant;
		if (levelTenant.value !== 2) {
			loading.value = true;
			supplier.value = props.supplier;
			// loading.value = true
			await populateTable();
		} else {
			if (company.value && !supplier.value) {
				const param = {
					db_instance: props.company.db_instance,
					org_id: props.company.value,
				};
				const respVendor = await PoFulfillment.getSuppBindComp(
					info.data.data.id
				);
				const vendorName = respVendor.data.data[0][0].vendorname;
				const respSuppName = await LovService.getSupplierName(param);
				if (respSuppName.status === 200) {
					const suppList = respSuppName.data.data;
					for (const s of suppList) {
						if (s.vendor_name === vendorName) {
							supplier.value = s;
							break;
						}
					}
				}
				await populateTable();
			}
			// loading.value = true
		}
	}
});
// eslint-disable-next-line no-undef
const props = defineProps({
	company: {
		type: Object,
	},
	supplier: {
		type: Object,
	},
});

const masterVendor = ref();
// const totalData = ref(0);
const loading = ref(true);
const page = ref(1);
const rowsPerPage = ref(10);
const sortColumn = ref();
const sortType = ref();
const levelTenant = ref();
const tableData = ref([]);
// const batchList = ref();
const totalData = ref(0);
const search = ref();
const router = useRouter();

const populateTable = async () => {
	loading.value = true;
	if (props.company !== null && props.company !== undefined) {
		const payload = {
			org_id: company.value ? company.value.value : null,
			db_instance: company.value ? company.value.db_instance : null,
			suppValue: supplier.value ? supplier.value.vendor_id : null,
			search: search.value ? search.value : null,
			page: page.value,
			rows: rowsPerPage.value,
		};
		tableData.value = await VendorServices.getAllData(payload);
		masterVendor.value = tableData.value.data.data.data;
		totalData.value = tableData.value.data.data.totalData[0].totalData;
	}

	loading.value = false;
};

const editChildren = async (rowIndex) => {
	router.push({
		name: 'masterVendorDetail',
		params: { maping_id: rowIndex.MAPING_ID },
		query: { v: props.company.value, i: props.company.db_instance },
	});
};

const onPage = async (event) => {
	// loading.value = true
	console.log('even onpage', event);
	page.value = event.page + 1;
	rowsPerPage.value = event.rows;
	await populateTable();
};

const onSort = async (event) => {
	// loading.value = true
	page.value = 1;
	rowsPerPage.value = event.rows;
	sortColumn.value = event.sortField;
	sortType.value = event.sortOrder;
	await populateTable();
};
</script>
<style scoped></style>
