<template>
	<div class="col-12 lg:col-4">
		<div class="flex align-items-center">
			<span class="font-bold mr-3">Preparer</span>
			<AutoComplete
				class="w-full"
				v-model="selectedPreparer"
				:suggestions="filteredPreparer"
				@complete="searchPreparer($event)"
				field="user_name"
				:dropdown="true"
				forceSelection
			/>
		</div>
	</div>
</template>

<script>
import SubmitPoService from '../../service/SubmitPOService';

export default {
	name: 'Preparer',
  props: ['dbInstance'],
	data() {
		return {
			selectedPreparer: null,
			filteredPreparer: null,
		};
	},
	async created() {
		this.preparers = await SubmitPoService.getPreparers((this.dbInstance === undefined) ? 'COMET' : this.dbInstance);
	},
	methods: {
		searchPreparer(event) {
			this.selectedPreparer = event.query;
			this.filteredPreparer = this.preparers.filter((preparer) => {
				return preparer.user_name
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
			// 
		},
	},
};
</script>

<style scoped></style>
