<template>
  <AutoComplete :suggestions="filteredVendorSite" @complete="searchVendorSite($event)" 
  :dropdown="true" field="vendor_site_code" v-model="vendorSiteValue"  
  :disabled="props.category === CategoryEnum.Prepayment"
 forceSelection>
    <template #item="slotProp">
      {{ slotProp.item.vendor_site_code }}
    </template>
  </AutoComplete>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect, watch} from "vue";
import AuthService from "../../../service/AuthService";
import vendorServices from "../../../service/masterVendorService";
import { CategoryEnum } from "../../../constants/CategoryEnum";

// eslint-disable-next-line no-undef
const filteredVendorSite = ref([]);
const vendorSite = ref([]);
const vendorSiteValue = ref();
const emit = defineEmits(['remitVendorSite'])

// eslint-disable-next-line no-undef
const props = defineProps({
  company: { type: Object },
  db_instance: {type: Object},
  vendor_id: {type: Object},
  vendorSiteId : { type : Object},
  category: { type: String }
});

onMounted(() => {
    populateData()
})

watchEffect(() => 
  emit('remitVendorSite',vendorSiteValue),
)

watch(
  () => props.vendor_id,
  (newValue) => {
   
    if(typeof newValue === 'object' && !Array.isArray(newValue)) {
      populateData()
    }
    } 
);


const populateData = () => {
  AuthService.whoAmi().then(async (response) => {
    let payload
    if(props.company !== null && props.db_instance !== null && props.vendor_id !== null) {
      payload = {
      org_id: props.company.value, db_instance: props.db_instance.db_instance, vendor_id: props.vendor_id.vendor_id ? props.vendor_id.vendor_id : props.vendor_id
    }
    if (response.status === 200) {
        const data = await vendorServices.getVendorSite(payload)
        vendorSite.value = data.data.data.data

        for (const s of vendorSite.value) {
              if (s.vendor_site_id === props.vendorSiteId) {
                vendorSiteValue.value = s;
                break;
              }
              else{
                vendorSiteValue.value = null;
              }
              
            }
      }
    } else {
      vendorSite.value ='';
    }
  })
}

const searchVendorSite = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredVendorSite.value = [...vendorSite.value];
    } else {
      filteredVendorSite.value = vendorSite.value.filter((comp) => {
        return comp.vendor_site_code.toLowerCase().match(event.query.toLowerCase());
      });
    }
  }, 250);
};
</script>

<style scoped></style>
