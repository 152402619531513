<template>
	<!-- eslint-disable -->
	<Toast />
	<div class="grid">
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<!-- Search -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Master Vendor</div>
				<div class="content-body">
					<div class="col-12 p-fluid">
						<form class="grid formgrid">
							<div class="col-12 mb-5 lg:col-4">
								<CompaniesDropdown
									v-model="company"
									class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
								/>
							</div>
							<div
								class="col-12 mb-5 lg:col-4"
								v-if="!isSupplierpage"
							>
								<SupplierDropdown
									v-model="supplier"
									v-if="
										!(userInfo
											? userInfo.leveltenant === 2
											: false)
									"
									:company="company ? company : null"
									:key="supplierReload"
								/>
							</div>

							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<span class="p-float-label">
									<Category
										v-model="categoryDrop"
										:company="company ? company : null"
										:supplier="supplier ? supplier : null"
										:key="categoryReload"
										class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
									/>
									<label>Category</label>
								</span>
								<small class="p-error">{{ generateError('category') }}</small>
							</div>

							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<span class="p-float-label">
									<UserFinance
										v-model="fndUser"
										:company="company ? company : null"
										:key="userFinanceReload"
										@remitUserFinance="getRemitUserFinance"
										class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
										:category="categoryDrop"
									/>
									<label>User Finance</label>
									<small class="p-error">{{ generateError('fndUser') }}</small>
								</span>
							</div>

							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<span class="p-float-label">
									<CostCenter
										v-model="costCenterDrop"
										:company="company ? company : null"
										:supplier="supplier ? supplier : null"
										:key="costCenterReload"
										class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
										:category="categoryDrop&&categoryDrop.CATEGORY_NAME"
									/>
									<label> Cost Center</label>
									<small class="p-error">{{ generateError('costcenter') }}</small>
								</span>
							</div>

							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<span class="p-float-label">
									<Vendor
										v-if="company"
										v-model="vendroAuto"
										:company="company"
										:db_instance="
											company ? company.db_instance : null
										"
										:key="vendorReload"
										class="p-float-label"
										:category="categoryDrop&&categoryDrop.CATEGORY_NAME"
									/>
									<InputText v-else disabled />
									<label>Vendor Name</label>
									<small class="p-error">{{ generateError('vendroAuto') }}</small>
								</span>
							</div>

							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<span class="p-float-label">
									<VendorSite
										v-model="vendorSiteDrop"
										:company="company ? company : null"
										:db_instance="company ? company : null"
										:vendor_id="
											vendroAuto ? vendroAuto : null
										"
										:key="vendorSiteReload"
										:category="categoryDrop&&categoryDrop.CATEGORY_NAME"
										class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
									/>
									<label>Vendor Site</label>
									<small class="p-error">{{ generateError('vendorSiteDrop') }}</small>
								</span>
							</div>

							<!--REKOMENDATOR-->
							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<div class="p-float-label p-input-icon-right">
									<InputText
										id="email"
										v-model="rekomendator"
									/>
									<label for="email">Rekomendator 1</label>
									<small class="p-error">{{ generateError('rekomendator') }}</small>
								</div>
							</div>


							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<div class="p-float-label p-input-icon-right">
									<InputText
										id="email"
										v-model="rekomendatorTwo"
									/>
									<label for="email">Rekomendator 2</label>
								</div>
							</div>


							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<div class="p-float-label p-input-icon-right">
									<InputText
										id="email"
										v-model="rekomendatorThree"
									/>
									<label for="email">Rekomendator 3</label>
									
								</div>
							</div>
							<!--END REKOMENDATOR-->

							<!--VALUE-->
							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<div class="p-float-label p-input-icon-right">
									<InputNumber
										class="col-number"
										:min="0"
										mode="decimal"
										locale="en-US"
										:minFractionDigits="2"
										:maxFractionDigits="2"
										style="text-align: right"
										v-model="rangeValue"
									/>
									<label for="email">Value</label>
									<small class="p-error">{{ generateError('value') }}</small>
								</div>
							</div>
							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<div class="p-float-label p-input-icon-right">
									<InputNumber
										class="col-number"
										:min="0"
										mode="decimal"
										locale="en-US"
										:minFractionDigits="2"
										:maxFractionDigits="2"
										style="text-align: right"
										v-model="rangeValueTwo"
									/>
									<label for="email">Value</label>
									<small class="p-error">{{ generateError('valueTwo') }}</small>
								</div>
							</div>
							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<div class="p-float-label p-input-icon-right">
									<InputNumber
										class="col-number"
										:min="0"
										mode="decimal"
										locale="en-US"
										:minFractionDigits="2"
										:maxFractionDigits="2"
										style="text-align: right"
										v-model="rangeValueThree"
									/>
									<label for="email">Value</label>
									<small class="p-error">{{ generateError('valueThree') }}</small>
								</div>
							</div>
							<!--END VALUE-->

							<div class="mr-3 ml-2">
								<Button
									@click="create()"
									class="p-button-success uppercase"
									label="Create"
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<!-- Search results -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search Result</div>
				<div class="content-body">
					<div class="mt-4">
						<TableMasterVendorView
							:column="column ? column.col : null"
							:company="company ? company : null"
							:supplier="supplier ? supplier : null"
							:key="reload"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref, watch} from 'vue';
import { useToast } from 'primevue/usetoast';
import AuthService from '../../service/AuthService';
import LovService from '../../service/LovService';
import PoFulfillment from '../../service/PoFulfillment';
import SimpleCrypto from 'simple-crypto-js';
import CompaniesDropdown from '@/components/components/CompaniesDropdown';
import SupplierDropdown from '@/components/components/SupplierDropdown';
import UserFinance from '../../components/mastervendor/components/UserFinance';
import CostCenter from './components/CostCenter.vue';
import VendorSite from './components/VendorSite.vue';
// import Rekomendator from "./components/Rekomendator.vue";
import Vendor from './components/Vendor.vue';
import TableMasterVendorView from './components/TableMasterVendorView.vue';
import Category from './components/Category.vue';
import VendorServices from '../../service/masterVendorService';

const breadcrumbHome = { icon: 'pi pi-home', to: '/dashboard' };
const breadcrumbItems = [
	{
		label: 'Master Vendor',
		to: '/mgm/master/vendor',
	},
];

const company = ref();
const remitUserFinance = ref(null);
const supplier = ref();
const userInfo = ref();
const isSupplierpage = ref(true);
const supplierReload = ref(0);
const categoryReload = ref(0);
const vendorReload = ref(0);
const userFinanceReload = ref(0);
const costCenterReload = ref(0);
const vendorSiteReload = ref(0);
const costCenterDrop = ref(0);
const categoryDrop = ref();
const vendorSiteDrop = ref(null);
const rekomendator = ref(null);
const vendroAuto = ref(null);
const reload = ref(0);
const fndUser = ref();
const idUser = ref(null);
const progress = ref(false);
const rekomendatorTwo = ref(null);
const rekomendatorThree = ref(null);
const error = ref([])
const rangeValue = ref(null)
const rangeValueTwo = ref(null)
const rangeValueThree = ref(null)

const toast = useToast();



watch(
	() => company.value,
	(newValue) => {
		if (typeof newValue === 'object' && !Array.isArray(newValue)) {
			fndUser.value = null;
			categoryDrop.value = null;
			supplierReload.value++;
			rekomendator.value = null;
			vendorSiteDrop.value = null;
			vendroAuto.value = null;
			costCenterDrop.value = null;
			vendorReload.value++;
			categoryReload.value++;
			userFinanceReload.value++;
			reload.value++;
			costCenterReload.value++;
			vendorSiteReload.value++;
		}
	}
);

watch(
	() => vendroAuto.value,
	(newValue) => {
		if (typeof newValue === 'object' && !Array.isArray(newValue)) {
			vendorSiteReload.value++;
		}
	}
);

onMounted(() => {
	AuthService.whoAmi().then(async (response) => {
		if (response.status === 200) {
			userInfo.value = response.data.data;
			idUser.value = response.data.data.id;
			if (userInfo.value.leveltenant === 2) {
				//get company
				isSupplierpage.value = true;
				const respCbN = await LovService.getCompanyByName(
					userCompany()
				);
				company.value = respCbN.data.data[0];
				//get supplier
				const param = {
					db_instance: company.value.db_instance,
					org_id: company.value.value,
				};

				const respVendor = await PoFulfillment.getSuppBindComp(
					response.data.data.id
				);
				const vendorName = respVendor.data.data[0][0].vendorname;
				const respSuppName = await LovService.getSupplierName(param);
				if (respSuppName.status === 200) {
					const suppList = respSuppName.data.data;
					for (const s of suppList) {
						if (s.vendor_name === vendorName) {
							supplier.value = s;
							break;
						}
					}
					// userSupplier.value = supplier.value
				}

				// getUserFinance();
			} else {
				// isSupplierpage.value = false;
				const respCbN = await LovService.getCompanyByName(
					userCompany()
				);
				company.value = respCbN.data.data[0];

				// getUserFinance();
			}
		}
	});
});

const generateError = (type) => {
	if(!error.value) return null;

	console.log(error.value, "ER VAL")

	const find = error.value.find((item) => item.name === type)

	if(!find) return null

	return find.message
}

const generateRules = async () => {

	if(!categoryDrop.value || !categoryDrop.value.CATEGORY_NAME.toLocaleUpperCase().includes(('Prepayment').toLocaleUpperCase())){
		if(!categoryDrop.value){
			error.value.push({
				id:1,
				message: 'Category is empty',
				name: 'category'
			});
		}else{
			const index = error.value.findIndex((item) => item.name === 'category');
			error.value.splice(index,1);
		}

		if(!fndUser.value){
			error.value.push({
				id:2,
				message: 'User Finance is empty',
				name: 'fndUser'
			});
		}else{
			const index = error.value.findIndex((item) => item.name === 'fndUser');
			error.value.splice(index,1);
		}

		if(!costCenterDrop.value){
			error.value.push({
				id:3,
				message: 'Cost Center is empty',
				name: 'costcenter'
			});
		}else{
			const index = error.value.findIndex((item) => item.name === 'costcenter');
			error.value.splice(index,1);
		}

		if(!vendroAuto.value){
			error.value.push({
				id:4,
				message: 'Vendor Name is empty',
				name: 'vendroAuto'
			});
		}else{
			const index = error.value.findIndex((item) => item.name === 'vendroAuto');
			if(index !== -1) error.value.splice(index,1);
		}

		if(!vendorSiteDrop.value){
			error.value.push({
				id:5,
				message: 'Vendor Site is empty',
				name: 'vendorSiteDrop'
			});
		}else{
			const index = error.value.findIndex((item) => item.name === 'vendorSiteDrop');
			error.value.splice(index,1);
		}

		console.log(error.value[0], "ER VALUE")
		console.log("long error::", error.value.length)

		if(rekomendator.value === null){
			console.log(rekomendator.value, "REK WALUE")
			error.value.push({
				id:6,
				message: 'Rekomendator is empty',
				name: 'rekomendator'
			});
		}else{
			const index = error.value.findIndex((item) => item.name === 'rekomendator');
			if(index !== -1 ) error.value.splice(index,1);
		}

		console.log(error.value[0], "ER VALUE 1")
		console.log("long error 1::", error.value.length)
		
		if(!rekomendatorTwo.value){
			error.value.push({
				id:6,
				message: 'Rekomendator is empty',
				name: 'rekomendatorTwo'
			});
		}else{
			const index = error.value.findIndex((item) => item.name === 'rekomendatorTwo');
			error.value.splice(index,1);
		}
		
		console.log(error.value[0], "ER VALUE 2")
		console.log("long error 2::", error.value.length)

		if(rangeValueTwo.value && (rangeValue.value >= rangeValueTwo.value)){
			error.value.push({
				id: Math.floor(Math.random() * 10000),
				message: "Value Rekomendator is Empty",
				name: 'value'
			})
		}else{
			const index = error.value.findIndex((item) => item.name === 'valueTwo');
			error.value.splice(index,1);
		}

		if(rangeValueThree.value && (rangeValueTwo.value >= rangeValueThree.value)){
			error.value.push({
				id: Math.floor(Math.random() * 10000),
				message: "Value Rekomendator is Empty",
				name: 'value'
			})
		}else{
			const index = error.value.findIndex((item) => item.name === 'valueTwo');
			error.value.splice(index,1);
		}

	

		if(error.value.length > 0) return 500;

		return 200
	}else{
		error.value = [];
		
		if(rekomendator.value === null){
			error.value.push({
				id:6,
				message: 'Rekomendator is empty',
				name: 'rekomendator'
			});
		}else{
			const index = error.value.findIndex((item) => item.name === 'rekomendator');
			error.value.splice(index,1);
		}

		if(rangeValueTwo.value && (rangeValue.value >= rangeValueTwo.value)){
			error.value.push({
				id: Math.floor(Math.random() * 10000),
				message: "Value Rekomendator is Empty",
				name: 'value'
			})
		}else{
			const index = error.value.findIndex((item) => item.name === 'valueTwo');
			error.value.splice(index,1);
		}

		if(rangeValueThree.value && (rangeValueTwo.value >= rangeValueThree.value)){
			error.value.push({
				id: Math.floor(Math.random() * 10000),
				message: "Value Rekomendator is Empty",
				name: 'value'
			})
		}else{
			const index = error.value.findIndex((item) => item.name === 'valueTwo');
			error.value.splice(index,1);
		}



		if(error.value.length > 0) return 500

		return 200
	
	}
}

const create = async () => {

	const resp = await generateRules();
	
	console.log(resp, "RESP")

	if(resp === 500){
		toast.add({
			severity: 'error',
			summary: 'Please fill all required fields',
			life: 3000,
		});
		return;
	}

	if (error.value.length > 0) {
		toast.add({
			severity: 'error',
			summary: 'Please fill all required fields',
			life: 3000,
		});
		return;
	}

	let payload = {};
	let type = ''

	if(categoryDrop.value.CATEGORY_NAME.toLocaleUpperCase().includes(("Prepayment").toLocaleUpperCase())){
		type = 'PREPAYMENT'
		payload = {
			db_instance: company.value.db_instance,
			org_id: company.value.value,
			categoryDrop: categoryDrop.value.CATEGORY_ID,
			rekomendator: JSON.stringify([
				{
					rekomendator: rekomendator.value,
					value: rangeValue.value
				},
				{
					rekomendator: rekomendatorTwo.value,
					value: rangeValueTwo.value
				},
				{
					rekomendator: rekomendatorThree.value,
					value: rangeValueThree.value
				}
			]),
			fndUser: remitUserFinance.value.user_id,
			idUser: remitUserFinance.value.user_id,
			createdBy: idUser.value,
			creationDate: formattingDate(new Date(), true),
		}
		console.log(remitUserFinance.value.user_id, "USER VALUE")
	}else{
		type = 'NON PREPAYMENT'
		payload = {
			db_instance: company.value.db_instance,
			org_id: company.value.value,
			categoryDrop: categoryDrop.value.CATEGORY_ID,
			costCenterDrop: costCenterDrop.value.SEGMENT4,
			vendorSiteDrop: vendorSiteDrop.value.vendor_site_id,
			rekomendator: JSON.stringify([
				{
					rekomendator: rekomendator.value,
					value: rangeValue.value
				},
				{
					rekomendator: rekomendatorTwo.value,
					value: rangeValueTwo.value
				},
				{
					rekomendator: rekomendatorThree.value,
					value: rangeValueThree.value
				}
			]),
			vendroAuto: vendroAuto.value.vendor_id,
			fndUser: fndUser.value.user_id,
			idUser: idUser.value,
			createdBy: idUser.value,
			creationDate: formattingDate(new Date(), true),
		};
	}

	progress.value = true;

	const create = await VendorServices.create({type, payload});

	location.reload();

	if (create.status === 200 || create.status.toLocaleUpperCase().includes("SUCCESS")) {
		toast.add({
			severity: 'success',
			summary: 'Create data success',
			life: 3000,
		});
		progress.value = false;
	} else {
		toast.add({
			severity: 'error',
			summary: 'Create Error',
			life: 3000,
		});
	}
};

const formattingDate = (date) => {
	const theDate = new Date(date);
	const strDate =
		theDate.getFullYear() +
		'-' +
		(theDate.getMonth() + 1) +
		'-' +
		theDate.getDate();

	return `${strDate}`;
};

const userCompany = () => {
	const secretKey = 'some-unique-key';
	const simpleCrypto = new SimpleCrypto(secretKey);
	return simpleCrypto.decrypt(sessionStorage.getItem('dropdownCompany'));
};

const getRemitUserFinance = async (value) => {
	console.log(value.value, "VALUE")
	remitUserFinance.value = value.value
} 
</script>
