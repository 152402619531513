<template>
	<div class="grid list-invoice">
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<!-- Search input -->
		<div class="col-12 p-fluid mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search</div>
				<div v-if="currentUser" class="content-body">
					<div class="grid">
						<div class="col-12 md:col-6 lg:col-3 mb-4">
							<span class="p-float-label">
								<AutoComplete
									id="company"
									:class="
										currentUser.leveltenant === 1 &&
										'input-disabled'
									"
									:disabled="
										!companyList ||
										currentUser.leveltenant === 1
									"
									v-model="company"
									:suggestions="filteredCompany"
									@complete="searchCompany($event)"
									:dropdown="true"
									field="name"
									forceSelection
								/>
								<label for="company">Company</label>
							</span>
						</div>
						<div class="col-12 md:col-6 lg:col-3 mb-4">
							<span class="p-float-label">
								<Dropdown
									id="status"
									:options="listStatus"
									optionLabel="value"
									v-model="status"
								/>
								<label for="status">Status</label>
							</span>
						</div>
						<div class="col-12 md:col-6 lg:col-3 mb-4">
							<span class="p-float-label">
								<AutoComplete
									id="invoiceNo"
									forceSelection
									:disabled="!invoiceNoList"
									:suggestions="filteredInvoiceNo"
									field="invoice_no"
									v-model="invoiceNo"
									@complete="searchInvoiceNo($event)"
								/>
								<label for="invoiceNo">Invoice No</label>
							</span>
						</div>
						<!-- Show dropdown supplier if user is Finance -->
						<div
							v-if="currentUser.leveltenant === 1"
							class="col-12 md:col-6 lg:col-3"
						>
							<span class="p-float-label">
								<AutoComplete
									id="supplier"
									:disabled="!supplierList"
									v-model="supplier"
									:suggestions="filteredSupplier"
									@complete="searchSupplier($event)"
									:dropdown="true"
									field="vendor_name"
									forceSelection
								/>
								<label for="supplier">Supplier</label>
							</span>
						</div>

						<!-- Search Buttons -->
						<div class="col-12 lg:col-4">
							<Button
								v-if="!isLoading"
								class="p-button-success w-auto mr-2"
								:disabled="disableSearchButton"
								@click="handleSearch"
							>
								<span class="font-bold uppercase">Search</span>
							</Button>
							<Button
								v-else
								class="p-button-success w-auto mr-2"
								disabled
							>
								<span class="font-bold uppercase"
									>Searching...</span
								>
							</Button>
							<Button
								class="w-auto p-button-secondary"
								@click="handleClear"
							>
								<span class="font-bold uppercase">Clear</span>
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Search results -->
		<div class="col-12 p-fluid mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search Results</div>
				<div class="content-body">
					<div class="grid">
						<div class="col-12">
							<DataTable
								dataKey="id"
								responsiveLayout="scroll"
								ref="dt"
								:lazy="true"
								:paginator="true"
								:rows="5"
								:rowsPerPageOptions="[5, 10, 20, 50]"
								:loading="isLoading"
								:totalRecords="totalRecords"
								:value="listInvoice"
								:scrollable="true"
								scrollHeight="600px"
								scrollDirection="both"
								paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
								currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
								@page="handlePagination($event)"
								@sort="onSort($event)"
								removableSort
							>
								<!-- Results Data -->
								<Column
									header="Invoice No"
									field="invoice_no"
									:sortable="true"
									style="width: 20%"
								/>
								<!-- <Column header="Batch No" field="batch_no">
									<template #body="{ data }">
										{{ data.batch_no || '-' }}
									</template>
								</Column> -->
								<Column
									header="Invoice Amount"
									style="width: 15%"
								>
									<template #body="{ data }">
										{{
											new Intl.NumberFormat(
												"en-US"
											).format(data.invoice_amount)
										}}
									</template>
								</Column>
								<Column
									header="Invoice Date"
									style="width: 15%"
									field="invoice_date"
									:sortable="true"
								>
									<template #body="{ data }">
										{{ formatDate(data.invoice_date) }}
									</template>
								</Column>
                                <Column
									header="Invoice Type"
									style="width: 15%"
									field="invoice_type"
									
								>
									<template #body="{ data }">
										{{ data.invoice_type }}
									</template>
								</Column>
								<Column
									header="Payment Method"
									field="payment_method_lookup_code"
									style="width: 9%"
								>
									<template #body="{ data }">
										{{
											data.payment_method_lookup_code ||
											"-"
										}}
									</template>
								</Column>
								<Column
									header="Service Amount"
									style="width: 15%"
								>
									<template #body="{ data }">
										{{
											new Intl.NumberFormat(
												"en-US"
											).format(data.service_amount)
										}}
									</template>
								</Column>
								<Column header="Status" style="width: 15%">
									<template #body="{ data }">
										<Chip
											class="custom-chip"
											:class="`chip-${data.status.toLowerCase()}`"
										>
											{{ data.status }}
										</Chip>
									</template>
								</Column>
								<Column
									header="Tax No"
									field="tax_no"
									style="width: 20%"
								/>
								<Column header="Tax Amount" style="width: 15%">
									<template #body="{ data }">
										{{
											new Intl.NumberFormat(
												"en-US"
											).format(data.tax_amount)
										}}
									</template>
								</Column>
								<Column
									header="Tax Date"
									:sortable="true"
									field="tax_date"
									style="width: 15%"
								>
									<template #body="{ data }">
										{{ formatDate(data.tax_date) }}
									</template>
								</Column>
								<Column
									header="Total Amount"
									style="width: 20%"
								>
									<template #body="{ data }">
										{{
											new Intl.NumberFormat(
												"en-US"
											).format(data.total_amount)
										}}
									</template>
								</Column>
								<Column
									header="Actions"
									style="width: 9%"
									alignFrozen="right"
									frozen
								>
									<template #body="{ data }">
										<Button
											icon="pi pi-eye"
											class="p-button-rounded p-button-text"
											@click="handleDetail(data)"
										/>
									</template>
								</Column>

								<!-- Empty statement -->
								<template #empty> No data found. </template>
							</DataTable>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref, watch, computed, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import SimpleCrypto from "simple-crypto-js";
import AuthService from "../../../service/AuthService";
import LovService from "../../../service/LovService";
import PlanningOrderService from "../../../service/PlanningOrderService";
import PoFulfillment from "../../../service/PoFulfillment";
import InvoicePOService from "../../../service/InvoicePOService";
import { PoTypeEnum,InvoiceTypeEnum } from "../../../constants/POTypeEnum";


// Router
const router = useRouter();
const route = useRoute();

// State
const currentUser = ref(null);
const company = ref(null);
const vendorId = ref(null);
const vendorName = ref(null);
const invoiceNo = ref(null);
const companyList = ref(null);
const invoiceNoList = ref(null);
const filteredCompany = ref(null);
const filteredInvoiceNo = ref(null);
const listStatus = ref(null);
const status = ref(null);
const supplier = ref(null);
const supplierList = ref(null);
const filteredSupplier = ref(null);
const listInvoice = ref(null);
const totalRecords = ref(0);
const isLoading = ref(false);
const lazyParams = ref(null);
const dt = ref(null);
const sortTable = ref(null);
const sortOrder = ref(null);
const search = ref(0);
const breadcrumbHome = ref({ icon: "pi pi-home", to: "/dashboard" });
const breadcrumbItems = ref([
	{
		label: "List Invoice PO",
		to: route.fullPath,
	},
]);

// Computed prop
const disableSearchButton = computed(() => {
	if (currentUser.value.leveltenant === 1) {
		return !company.value;
	} else {
		return !company.value
	}
});

// Mounted
onMounted(async () => {
	console.log("List invoice page");

	const resAuth = await AuthService.whoAmi();
	currentUser.value = resAuth.data.data;

	// Check if user is not supplier nor finance nor admin
	if (
		currentUser.value.leveltenant !== 1
	) {
		// Redirect to homepage if user is purchasing and not admin
		router.push("/dashboard");
		return;
	}

	// Set lazy params
	lazyParams.value = {
		first: 0,
		page: 0,
		rows: dt.value.rows,
	};

	if (currentUser.value.leveltenant === 1) {
		
		await getCompany();
		
	} else {
		// Get company
		await getCompany();
	}

	// Call get status list
	getStatusInvoice();

	// Call get invoice numbers


	// Call supplier list if user is Finance
	// if (currentUser.value.leveltenant === 1) {
	// 	getSupplierList();
	// }
});

// Unmounted
onUnmounted(() => {
	unwatch();
});

// Watch selected company change (for supplier users)
const unwatch = watch(company, async (newCompany, oldCompany) => {
	// Watch if old company is not null
	if (oldCompany) {
		// Reset other input fields
		status.value = null;
		invoiceNo.value = null;

		vendorId.value = null; // Reset old vendorId

		// Get new vendorId for new company
		const res = await PlanningOrderService.getSuppValue(
			{
				vendorName: vendorName.value,
			},
			company.value.db_instance
		);
		vendorId.value = res.data.data[0].vendor_id;
		console.log(newCompany, "NEW COMPANY")
		// Get new invoice numbers
		if (newCompany.db_instance && newCompany.value) {
			getInvoiceNumbers(
				newCompany.db_instance,
				newCompany.value,
				vendorId.value
			);
		}
	}
});

// Formatting date
const formatDate = (date) => {
	return new Intl.DateTimeFormat("id-ID", {
		year: "numeric",
		month: "short",
		day: "numeric",
	}).format(new Date(date));
};

// Get company list
const getCompany = async () => {
	if (currentUser.value.userid === "dexaadmin") {
		// If user is admin
		const companyRes = LovService.getValueType("COMPANY");
		companyList.value = companyRes.data.data;
	} else if (currentUser.value.leveltenant === 3) {
		// If user is supplier
		let payload = currentUser.value.id;
		const companySuppRes = await PoFulfillment.getCompanySupp(payload);

		try {
			const companySupp = companySuppRes.data.data;
			const companyValues = await Promise.all(
				companySupp.map(async (element) => {
					const res = await LovService.getCompanyByName(
						element.company
					);

					const secretKey = "some-unique-key";
					const simpleCrypto = new SimpleCrypto(secretKey);
					const decipherText = simpleCrypto.decrypt(
						sessionStorage.getItem("dropdownCompany")
					);
					console.log(res.data.data[0].name , decipherText, "TEST DEXT")
					if (res.data.data[0].name === decipherText) {
						company.value = res.data.data[0];
						await getInvoiceNumbers(
							company.value.db_instance,
							company.value.value,
							null
						);
					}

					return res.data.data[0];
				})
			);

			companyList.value = companyValues;
		} catch (e) {
			console.log(e);
			if (e !== "Break") throw e;
		}
	} else if (currentUser.value.leveltenant === 1) {
		// If user is Finance
		const res = await LovService.getcompanydb(currentUser.value.bioaddress);
		companyList.value = res.data.data;
		console.log(companyList.value, "COMP VAL")
		try {
			const secretKey = "some-unique-key";
			const simpleCrypto = new SimpleCrypto(secretKey);
			const decipherText = simpleCrypto.decrypt(
				sessionStorage.getItem("dropdownCompany")
			);

			companyList.value.forEach((element) => {
				if (element.name === decipherText) {
					company.value = element;
				}
			});
			console.log("SINII YA")
			const payload = {
				db_instance: company.value.db_instance,
				org_id: company.value.value
			}
			
			
			await getInvoiceNumbers(
				company.value.db_instance,
				company.value.value,
				null
			);
			

			const supp = await LovService.getSupplierName(payload);

			supplierList.value = supp.data.data;
			console.log(supp.data.data, "D")

		} catch (e) {
			if (e !== "Break") throw e;
		}
	}
};


watch(supplier, async () => {
	await getInvoiceNumbers(
		company.value.db_instance,
		company.value.value,
		supplier.value.value
	)
})

// Get invoice numbers
const getInvoiceNumbers = async (dbInstance, orgId, vendorId) => {
	console.log("INI YA")
	const query = {
		dbInstance,
		orgId,
		type: PoTypeEnum.Prepayment
	};

	// If user is supplier then add vendorId as params
	if (vendorId) {
		query.vendorId = vendorId;
	}


	const res = await InvoicePOService.getInvoiceNumbers(query);
	invoiceNoList.value = res.data.data;
};

// Get status invoice list
const getStatusInvoice = async () => {
	try {
		const res = await InvoicePOService.getStatusInvoice();
		if (res.status === 200) {
			listStatus.value = res.data.data;
		}
	} catch (err) {
		console.log(err);
	}
};

// Get supplier list
// const getSupplierList = async () => {
// 	const payload = {
// 		db_instance: company.value.db_instance,
// 		org_id: company.value.value,
// 	};

// 	const res = await LovService.getSupplierName(payload);

// 	const type = typeof res.data.data;
// 	if (type !== "string") {
// 		supplierList.value = res.data.data.filter((item) => item.vendor_name);
// 	}
// };

// Search company on autocomplete
const searchCompany = (event) => {
	if (!event.query.trim().length) {
		filteredCompany.value = [...companyList.value];
	} else {
		filteredCompany.value = companyList.value.filter((item) => {
			return item.name.toLowerCase().match(event.query.toLowerCase());
		});
	}
};

// Search company on autocomplete
const searchSupplier = (event) => {
	if (!event.query.trim().length) {
		filteredSupplier.value = [...supplierList.value];
	} else {
		filteredSupplier.value = supplierList.value.filter((item) => {
			return (
				item.vendor_name &&
				item.vendor_name.toLowerCase().match(event.query.toLowerCase())
			);
		});
	}
};

// Search invoice numbers on autocomplete
const searchInvoiceNo = (event) => {
	if (!event.query.trim().length) {
		filteredInvoiceNo.value = [...invoiceNoList.value];
	} else {
		filteredInvoiceNo.value = invoiceNoList.value.filter((item) => {
			return item.invoice_no
				.toLowerCase()
				.match(event.query.toLowerCase());
		});
	}
};

// Handle pagination
const handlePagination = (event) => {
	lazyParams.value = event;
	handleSearch();
};

// Search invoice
const handleSearch = async () => {
	// Body payload
	vendorId.value = supplier.value ? supplier.value.value : null;

	console.log(supplier.value, "TEST DATA")
	const payload = {
		levelTenant: currentUser.value.leveltenant,
		dbInstance: company.value.db_instance,
		orgId: company.value.value,
		vendorId: vendorId.value,
		status: status.value?.value || null,
		supplier: supplier.value?.vendor_id || null,
		invoiceNo: invoiceNo.value?.invoice_no || null,
		sort: sortTable.value ? sortTable.value : null,
		sortOrder: sortOrder.value ? sortOrder.value : null,
        invoiceType: PoTypeEnum.Prepayment
	};

	// Query params
	const params = {
		query: JSON.stringify({
			limit: lazyParams.value.rows,
			page: lazyParams.value.page + 1,
		}),
	};

	try {
		isLoading.value = true;
		const res = await InvoicePOService.getListInvoicePrepayment(params, payload);

		if (res.status === 200) {
			listInvoice.value = res.data.data.map((item) => ({
				...item,
				id: item.einvoice_id,
			}));
			totalRecords.value = res.data.count;
			search.value++;
		}

		isLoading.value = false;
	} catch (err) {
		console.log(err);
		isLoading.value = false;
	}
};

const onSort = async (event) => {
	sortTable.value = event.sortField;
	sortOrder.value = event.sortOrder;
	event.page = 0;
	lazyParams.value = event;

	if (search.value > 0) {
		await handleSearch();
	}
};

// Clear search
const handleClear = () => {
	status.value = null;
	invoiceNo.value = null;
	supplier.value = null;
	listInvoice.value = null;
	totalRecords.value = 0;
};

// Handle click detail
const handleDetail = (data) => {
	if(data.invoice_type === InvoiceTypeEnum.Procurement){
		router.push({
			name: "CreateEinvoiceProcurement",
			params: { id: data.id },
			query: { v: company.value.value, i: company.value.db_instance },
		});
		return;
	}

	if(data.invoice_type === InvoiceTypeEnum.Prepayment || data.invoice_type === InvoiceTypeEnum.PrepaymentProxy){
		router.push({
			name: "CreateEinvoicePrepaymentPur",
			params: { id: data.id },
			query: { v: company.value.value, i: company.value.db_instance },
		});
		return;
	}

	router.push({
		name: "DetailListInvoicePO",
		params: { id: data.id },
		query: { v: company.value.value, i: company.value.db_instance },
	});
};
</script>

<style scoped>
.input-disabled {
	color: #000 !important;
}

.p-chip.custom-chip {
	padding: 4px 12px;
	border-radius: 8px;
	font-size: 12px;
	font-weight: 600;
}

.chip-open {
	background: #0ea5e9;
	color: #fff;
}
.chip-rejected {
	background: var(--primary-color);
	color: #fff;
}
.chip-review {
	background: #f59e0b;
	color: #fff;
}
.chip-posted {
	background: #10b981;
	color: #fff;
}
.chip-processed {
	background: #2563eb;
	color: #fff;
}
</style>
