<template>
    <div class="grid">
        <div class="col-12">
            <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
        </div>

        <div>
            
        </div>
    </div>
</template>

<script setup>
    import { useRoute } from 'vue-router';
    import {ref} from "vue";

    const route = useRoute();
    const breadcrumbHome = ref({ icon: "pi pi-home", to: "/dashboard" });
        const breadcrumbItems = ref([
            {
                label: "Search Pending PO",
                to: '/mgm/monitor/searchinvoice',
            },
            {
                label: "Detail Invoice Procurement",
                to: route.fullPath,
            },
        ]);
</script>