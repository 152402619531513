<template>
    <span class="p-float-label">
        <AutoComplete :suggestions="filteredBatch" @complete="searchPph($event)" :disabled="disabledDropdown" :dropdown="true" field="WHT_CODE" v-model="pphCodeValue" @input="$emit('pphCode', $event)" forceSelection>
        <template #item="slotProp">
        {{ slotProp.item.WHT_CODE }}
        </template>
    </AutoComplete>
        <label for="autocomplete">PPH Code</label>
    </span>
  
</template>

<script setup>
import {onMounted, ref, watchEffect, defineEmits} from 'vue'
import AuthService from '../../../service/AuthService';
import InvoiceService from "../../../service/InvoiceSearchWPO"

// eslint-disable-next-line no-undef
const props = defineProps({
  db_instance: {type: Object},
  pph_code: {type: Object},
  org_id: {type: Object},
  disabled: {type: Boolean}
})

// eslint-disable-next-line no-undef
const filteredBatch = ref([]);
const pphCode = ref([]);
const pphCodeValue = ref();
const disabledDropdown = ref(props.disabled)
const emit = defineEmits(['pphCode'])

onMounted(() => {
  populateData()
})

watchEffect(() => 
  // 
  emit('pphCode',pphCodeValue)
)

const populateData = () => {
if (!disabledDropdown.value){
  AuthService.whoAmi().then(async (response) => {
    // 
    if (response.status === 200){
      const payload = { db_instance : props.db_instance, org_id : props.org_id}
      const data = await InvoiceService.getPphCode(payload)
      // 
      pphCode.value = data.data.data.data
      // 
      for (const s of pphCode.value) {
              if (s.WHT_CODE === props.pph_code) {
                pphCodeValue.value = s;
                break;
              }
              else{
                pphCodeValue.value = null;
              }
            }
    }
  })
}
      
}


const searchPph = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredBatch.value = [...pphCode.value]
    } else {
      filteredBatch.value = pphCode.value.filter((comp) => {
        return comp.WHT_CODE.toLowerCase().match(event.query.toLowerCase());
      })
    }
  }, 250);
}
</script>

<style scoped>

</style>