import CryptoJS from "crypto-js";

const secretKey = "D3x4M3d1C@!!!";

const encryptForm = (data) => {
  const metadata = {
    'request-id': Math.floor(Math.random() * 100000000),
    'random-number': Math.floor(Math.random() * 100000000),
    timestamp: Math.floor(Date.now() / 1000),
  }

  const keyObj = Object.keys(data).map((Q) => Q);

  let strSignature = '';

  console.log(keyObj)

  console.log(data, 'DATA')

  for(const key of keyObj){
    if(typeof data[key] === 'undefined') continue;
    strSignature += data[key] + `|`;
  }

  console.log("SIGN SIGNATURE", strSignature)

  const signature = CryptoJS.HmacSHA256(strSignature, secretKey).toString(CryptoJS.enc.Hex);

  const finalPayload = {
    ...data,
    signature: signature,
    metadata: {
      ...metadata
    }
  }

  return finalPayload;
};

const encryptQuery = (query) => {
  let data = '';

  const metadata = {
    'request-id': Math.floor(Math.random() * 100000000),
    'random-number': Math.floor(Math.random() * 100000000),
    timestamp: Math.floor(Date.now() / 1000),
  }

  const keyObj = Object.keys(query).map((Q) => Q);

  let strSignature = '';

  for(const key of keyObj){
    strSignature += data[key] + `|`;
  }


  
  const signature = CryptoJS.HmacSHA256(strSignature, secretKey).toString(CryptoJS.enc.Hex);

  const finalPayload = {
    signature: signature,
    metadata: {
      ...metadata
    }
  }

  return finalPayload;
};

export { encryptForm, encryptQuery };
