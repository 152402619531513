<template>
    <div class="grid">
        <div class="col-12 p-fluid">
            <div class="col-12 mb-5">
                <h4>Planning Order</h4>
                <hr /><br>
                <p><strong>Planning Order #:</strong> {{planningOrderNum}}, {{planningOrderDate}}</p>

				<DataTable :value="planningOrderTable" v-model:expandedRows="expandedRows" 
					@rowExpand="onRowExpand" @rowCollapse="onRowCollapse" :loading="loadingTable">
					<!-- <template #header>
						<div class="table-header-container">
							<Button icon="pi pi-plus" label="Expand All" @click="expandAll" class="mr-2" />
							<Button icon="pi pi-minus" label="Collapse All" @click="collapseAll" />
						</div>
					</template> -->
					<Column :expander="true" headerStyle="width: 3rem" />
					<Column field="po_number" header="PO Number"></Column>
					<template #expansion="slotProps">
						<div class="orders-subtable">
							<DataTable :value="slotProps.data.children">
								<template #empty> Data Detail Empty </template>
								<Column header="Line" headerStyle="min-width:5rem;">
								<template #body="slotProps">
											{{ slotProps.index + 1 }}
								</template>
									</Column>
								<Column field="item_num" header="Item Number" headerStyle="min-width:8rem;"></Column>
								<Column field="item_desc" header="Item Description" headerStyle="min-width:15rem;"></Column>
								<Column field="qty" header="Qty" headerStyle="min-width:8rem;" style="text-align: center">
								<template #body="{data}">
										{{formatCurrency(data.qty)}}
									</template>
								</Column>
								<Column field="rate_currency" header="Rate Currency" headerStyle="min-width:8rem;">
								</Column>
								<Column field="deal_currency" header="Deal Currency" headerStyle="min-width:8rem;">
								</Column>
								<Column field="uom" header="UOM" headerStyle="min-width:8rem;">
								</Column>              
								<Column field="location" header="Location" headerStyle="min-width:10rem;">
								</Column>
								<Column field="arrival_at_site" header="Arrival at Site" headerStyle="min-width:8rem;"
									style="text-align: center">
								</Column>
								<Column field="note_to_supplier" header="Note to Supplier" headerStyle="min-width:10rem;">
								</Column>
								<Column field="currency" header="Currency" headerStyle="min-width:8rem;" style="text-align: center">
								</Column>
								<Column header="Confirm Price" headerStyle="min-width:8rem;"
									style="text-align: center">
									<template #body="{data}">
										{{formatCurrency(data.confirm_price)}}
									</template>
								</Column>
								<Column header="Confirm Qty" headerStyle="min-width:8rem;"
									style="text-align: center">
									<template #body="{data}">
										{{formatCurrency(data.confirm_qty)}}
									</template>
								</Column>
								<Column field="confirm_arrival_at_site" header="Confirm Arrival at Site" headerStyle="min-width:8rem;"
									style="text-align: center">
								</Column>
								<Column field="freight" header="Freight" headerStyle="min-width:10rem;">
								</Column>
								<Column field="top" header="ToP" headerStyle="min-width:10rem;">
								</Column>
								<Column field="incoterm" header="Incoterm" headerStyle="min-width:8rem;">
								</Column>
								<Column field="po_number" header="PO Number" headerStyle="min-width:8rem;">
								</Column>
							</DataTable>
						</div>
					</template>
				</DataTable>


                <!-- <DataTable :value="planningOrderTable" dataKey="pr_line_num" v-model:filters="filters"
                    v-model:selection="selectedPo" removableSort :loading="loading" showGridlines>
                    <Column header="Line" headerStyle="min-width:5rem;">
					<template #body="slotProps">
								{{ slotProps.index + 1 }}
					</template>
						</Column>
                    <Column field="item_num" header="Item Number" headerStyle="min-width:8rem;"></Column>
                    <Column field="item_desc" header="Item Description" headerStyle="min-width:15rem;"></Column>
                    <Column field="qty" header="Qty" headerStyle="min-width:8rem;" style="text-align: center">
                     <template #body="{data}">
                            {{formatCurrency(data.qty)}}
                        </template>
					</Column>
                    <Column field="uom" header="UOM" headerStyle="min-width:8rem;">
                    </Column>              
                    <Column field="location" header="Location" headerStyle="min-width:10rem;">
                    </Column>
                    <Column field="arrival_at_site" header="Arrival at Site" headerStyle="min-width:8rem;"
                        style="text-align: center">
                    </Column>
                    <Column field="note_to_supplier" header="Note to Supplier" headerStyle="min-width:10rem;">
                    </Column>
                    <Column field="currency" header="Currency" headerStyle="min-width:8rem;" style="text-align: center">
                    </Column>
                    <Column header="Confirm Price" headerStyle="min-width:8rem;"
                        style="text-align: center">
						<template #body="{data}">
                            {{formatCurrency(data.confirm_price)}}
                        </template>
                    </Column>
                    <Column header="Confirm Qty" headerStyle="min-width:8rem;"
                        style="text-align: center">
						<template #body="{data}">
                            {{formatCurrency(data.confirm_qty)}}
                        </template>
                    </Column>
                    <Column field="confirm_arrival_at_site" header="Confirm Arrival at Site" headerStyle="min-width:8rem;"
                        style="text-align: center">
                    </Column>
                    <Column field="freight" header="Freight" headerStyle="min-width:10rem;">
                    </Column>
                    <Column field="top" header="ToP" headerStyle="min-width:10rem;">
                    </Column>
                    <Column field="incoterm" header="Incoterm" headerStyle="min-width:8rem;">
                    </Column>
                    <Column field="po_number" header="PO Number" headerStyle="min-width:8rem;">
                    </Column>
                </DataTable> -->

                <br>
                <p>Date: {{currentDate}}</p>
            </div>

			<div class="p-fluid grid formgrid" v-if="!hideButton">
				<div class="col-6 mb-2 ml-2 lg:col-1 lg:mb-0">
					<Button
						type="button"
						@click="closeWindow()"
						label="Close Window"
						class="p-button-secondary"
					/>
				</div>
				<div class="col-6 mb-2 lg:col-1 lg:mb-0">
					<Button
						type="button"
						@click="exportExcel()"
						label="Export to Excel"
						class="p-button-success"
					/>
				</div>
				<div class="col-6 mb-2 lg:col-1 lg:mb-0">
					<Button
						type="button"
						@click="printNow()"
						label="Print"
						class="p-button-primary"
					/>
				</div>
				<div class="col-6 mb-2 lg:col-2 lg:mb-0">
                    <Button type="button" @click="expandAll()" label="Expand All" class="p-button-info" />
                </div>
			</div>
		</div>
	</div>
	<div>
		
	</div>
</template>

<script>
/* eslint-disable */
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import PlanningOrder from '../service/PlanningOrderService';
import { reactive, ref, toRef, unref } from '@vue/reactivity';
import AuthService from '../service/AuthService';
import XLSX from 'xlsx';
export default {
	name: 'planningorder-list',
	data() {
		return {
			idValue: sessionStorage.getItem('printPoId'),
			idDetail: null,
			isiDetail: null,
			processIsiDetail: null,
			planningOrderNum: null,
			planningOrderDate: null,
			supplier: null,
			respondBy: null,
			revisition: null,
			planningOrderStatus: null,
			respondDate: null,
			location: null,
			isiLocation: null,
			filteredLocation: null,
			displayResponsive: false,
			planningOrderTable: null,
			selectedPo: null,
			termsTable: null,
			poType: null,
			isiPoType: null,
			filteredPoType: null,
			selectedPoType: null,
			uom: null,
			currenciesList: null,
			isiUom: null,
			freight: null,
			isiFreight: null,
			tablepopuppo: null,
			arrivalDate: null,
			top: null,
			isiTop: null,
			incoterm: null,
			isiIncoterm: null,
			status: null,
			changeStatus: null,
			unholdStatus: null,
			changeHold: false,
			changeUnhold: false,
			popUpPo: null,
			filteredPopUpPo: null,
			poNumber: null,
			qty: null,
			submitpo: false,
			isInvalid: true,
			displayValidasi: false,
			displayPilih: false,
			something: null,
			hideButton: false,
			currentDate: new Date(),
			combinedDate: null,
			expandedRows: [],
			planningOrderTableExcel : null,
			loadingTable: false,
			breadcrumbHome: { icon: 'pi pi-home', to: '/' },
			breadcrumbItems: [
				{ label: 'Planning Order', to: '/mgm/settings/planningorder' },
				{ label: 'Detail Planning Order' },
			],
		};
	},
	created() {
	    var today = new Date();
	    var dd = String(today.getDate()).padStart(2, '0');
	    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
	    var yyyy = today.getFullYear();
	    this.combinedDate = dd + mm + yyyy;

	    document.title = "Planning Order" + '_' + this.combinedDate

	    if (this.idValue != 0) {
	        AuthService.whoAmi().then(response => {
	            if (response.status === 200) {
	                const payload = {idValue : this.idValue};
				this.respondBy = response.data.data.userid;
				this.loadingTable = true
	                PlanningOrder.getIdDetail(payload).then(async (response) => {
	                    this.isiDetail = await response.data.data
	                    this.planningOrderNum = await response.data.data[0].plan_order_num
	                    this.supplier = await response.data.data[0].vendor_name
	                    // this.respondBy = response.data.data[0].response_by
	                    this.revisition = await response.data.data[0].revisition
	                    this.planningOrderStatus = await response.data.data[0].status
	                    if (response.data.data[0].status === 'HOLD') {
	                        this.changeHold = true;
	                        this.changeUnhold = false;
	                        this.submitpo = false;
	                    }
	                    if (response.data.data[0].status !== 'HOLD') {
	                        this.changeHold = false;
	                        this.changeUnhold = true;
	                        this.submitpo = true;
	                    }
	                    let poDate = await response.data.data[0].plan_order_date;
	                    let poDateProcessA = poDate.substring(0, 2);
	                    let poDateProcessB = poDate.substring(2, 5);
	                    let poDateProcessC = poDate.substring(6, 11);
	                    let combinedPoDate = new Date(poDate);
	                    this.planningOrderDate = (combinedPoDate.getDate() + '-' + combinedPoDate.toString().substr(4, 3) + '-' + combinedPoDate.getFullYear()).toUpperCase();
	                    if (this.planningOrderNum !== null) {
	                        const payload = response.data.data[0].plan_order_num
	                        PlanningOrder.printPlannoSupp(payload).then(async (response) => {
	                            // let getDistinctLine = null
	                            // let newSetArray = []
	                            // response.data.data.forEach((element) => {
	                            //     if (getDistinctLine !== element.plan_order_line_id) {
	                            //         newSetArray.push(element)
	                            //         getDistinctLine = element.plan_order_line_id
	                            //     }
	                            // })
	                            // this.planningOrderTable = [...new Set(newSetArray)]
								if (response.status === 200){ 
									let line_seq = 1
									this.planningOrderTable = await response.data.data
									this.planningOrderTableExcel = await response.data.dataExcel
									for (const i of await this.planningOrderTable) {
										for (const id of await i.children){
											id.line = line_seq
											line_seq ++
											id.arrival_at_site = new Date(id.arrival_at_site).toLocaleDateString('id-ID', {
												day: 'numeric',
												month: 'short',
												year: 'numeric',
											})
											if (id.arrival_at_site == '1 Jan 1970') {
												id.arrival_at_site = ''
											} else {
												id.arrival_at_site = id.arrival_at_site
											}
											id.confirm_arrival_at_site = new Date(id.confirm_arrival_at_site).toLocaleDateString('id-ID', {
												day: 'numeric',
												month: 'short',
												year: 'numeric',
											})
											if (id.confirm_arrival_at_site == '1 Jan 1970') {
												id.confirm_arrival_at_site = ''
											}
											this.loadingTable = false
										}
										
									}
								} else {
									this.planningOrderTable = []
                                    this.planningOrderTableExcel = []
								}
								
	                        })
	                    }

	                })
	            }

	        })
	    }
	},
	mounted() {
		// PlanningOrder.getAllCurrencies().then(value => {
		//     this.currenciesList = value.data.data;
		// });
	},
	computed: {
		// respondDate(){
		//     const date = new Date()
		//     date.setDate(date.respondBy()+ 1)
		//     return date
		// }
	},
	methods: {
		formatCurrency(value) {
			if (value) return value.toLocaleString('id-ID');
			return;
		},
        printNow() {
            this.hideButton = true
            setTimeout(this.actualPrintNow, 300)
            
        },
        actualPrintNow() {
            if (this.hideButton === true) {
                window.print()
                if (document.hasFocus()) {
                    this.hideButton = false
                }
            }
        },
        closeWindow() {
            window.close()
        },
		expandAll() {
            this.expandedRows = this.planningOrderTable.filter((p) => p.po_number);
            this.$toast.add({severity: 'success', summary: 'All Rows Expanded', life: 3000});
        },
        exportExcel() {
            var filename = this.planningOrderNum + '_' + this.combinedDate + '.xlsx';
            var data = this.planningOrderTableExcel;
            var ws = XLSX.utils.json_to_sheet(data);
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Planning Order");
            XLSX.writeFile(wb,filename);
        },
		
		}    
	}

</script>
