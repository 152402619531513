<template>
    <!-- eslint-disable -->
    <div class="grid">
        <div class="col-12 p-fluid">
            <div class="card">
                <div class="grid formgrid" style="margin: 1em 0 0 0;">
                    <div class="col-12 mb-2 lg:col-3 lg:mb-0">
                        <span class="p-float-label">
                            <!-- <Dropdown id="dropdown" style="width: 100%;" :options="suppliers" v-model="selectedSupplier"
                                optionLabel=""></Dropdown> -->
                            <AutoComplete v-model="selectedSupplier" :suggestions="filteredSupplier"
                                @complete="searchSupplier($event)" :dropdown="true" field="" forceSelection>
                                <template #item="slotProps">
                                    <div class="country-item">
                                        <div class="ml-2">{{slotProps.item}}</div>
                                    </div>
                                </template>
                            </AutoComplete>
                            <label for="dropdown">Supplier</label>
                        </span>
                    </div>
                    <div class="col-12 mb-5 lg:col-2 lg:mb-0">
                        <span class="p-float-label">
                            <Dropdown id="dropdown" style="width: 100%;" :options="statuses" v-model="selectedStatus"
                                optionLabel=""></Dropdown>
                            <label for="dropdown">Status</label>
                        </span>
                    </div>
                    <div class="col-12 mb-2 lg:col-1 lg:mb-0">
                        <Button label="Go" @click="searchFilter()"></Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <DataTable :value="filtered? filtered : capas" v-model:selection="selectedCapas" dataKey="id" :paginator="true"
        :rows="5" v-model:filters="filters2" resizableColumns="true" :reorderableColumns="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5,10,25]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        responsiveLayout="scroll">
        <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field" :sortable="true" />
    </DataTable>
</template>

<script>
 /* eslint-disable */
import CapaService from '../../service/CapaService'

export default {
 data() {
     return {
        columns: null,
        filtered: null,
        capas: null,
		capa: {},
		selectedCapas: null,
        selectedSupplier: {},
        filteredSupplier: null,
        selectedStatus: {},
        suppliers: [],
        statuses: ['ALL','Open','Closed'],
        }
    },

	created() {
        this.columns = [
            {field: 'finding', header: 'Supplier'},
            {field: 'so_filename', header: 'Document No'},
            {field: 'finding_date', header: 'Document Date'},
            {field: 'so_mime_type', header: 'Subject'},
            {field: 'respond_date', header: 'Response Date'},
            {field: 'respond', header: 'Response'},
            {field: 'status', header: 'Status'},
        ]
	},
	mounted() {
        this.selectedSupplier = 'ALL';
        this.selectedStatus = 'ALL';
        CapaService.getAllCapa().then((response) => {
              if (response.status == 200) {
                
                this.suppliers = [];
                this.suppliers.push('ALL');
                response.data.data.forEach((element) => {
                    this.suppliers.push(element.finding);
                })

                this.capas = response.data.data;
              } else {
                this.capas = [];
              }
        });
	},
    methods: {
        searchFilter() {
            this.filtered = this.searchAll;
        },
        searchSupplier(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredSupplier = [...this.suppliers];
                }
                else {
                    this.filteredSupplier = this.suppliers.filter((item) => {
                        return item.toLowerCase().match(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
    },
    computed: {
        searchAll() {
            console.clear()
            if (this.selectedSupplier === 'ALL' && this.selectedStatus === 'ALL') {
                this.filtered = null;
            } else if (this.selectedSupplier === 'ALL' || this.selectedStatus === 'ALL') {
                return this.capas.filter(item => {
                    return (item.finding.match(this.selectedSupplier) || item.status.match(this.selectedStatus))
                })
            } else {
                return this.capas.filter(item => {
                    return (item.finding.match(this.selectedSupplier) && item.status.match(this.selectedStatus))
                })
            }
        }
    }
}
</script>