<template>
	<!-- eslint-disable -->
	<Toast />
	<div class="Grid">
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<!-- Search -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search INVOICE</div>
				<div class="content-body">
					<div class="col-12 p-fluid">
						<div class="grid formgrid" style="margin: 1em 0 0 0">
							<div class="col-12 mb-5 lg:col-3">
								<CompaniesDropdown
									v-model="company"
									class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
									:disabled="
										!(userInfo
											? userInfo.leveltenant === 2
											: false)
									"
								/>
							</div>
							<div
								v-if="!isSupplierpage"
								class="col-12 mb-5 lg:col-3"
							>
								<SupplierDropdown
									v-if="
										!(userInfo
											? userInfo.leveltenant === 2
											: false)
									"
									v-model="supplier"
									:company="company ? company : null"
									class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
									:key="supplierReload"
								/>
							</div>
							<div class="col-12 mb-5 lg:col-3 lg:mb-5">
								<InvoiceNumber
									v-model="invoiceNumber"
									:company="company ? company : null"
									:supplier="supplier ? supplier : null"
									:status="status ? status : null"
									class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
									:key="invoiceNumberReload"
								/>
							</div>
							<div class="col-12 mb-5 lg:col-3 lg:mb-5">
								<Status
									v-model="status"
									:company="company ? company : null"
									:supplier="supplier ? supplier : null"
									:invoiceNumber="
										invoiceNumber ? invoiceNumber : null
									"
									class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
									:key="statusReload"
								/>
							</div>
							<div class="mr-3 ml-2">
								<Button
									@click="handleSearch()"
									class="p-button-success uppercase"
									label="search"
									:disabled="
										loading ||
										!company ||
										(isSupplierpage && !supplier)
									"
								/>
							</div>
							<div>
								<Button
									@click="clearFilter()"
									class="p-button-secondary uppercase"
									label="clear"
								></Button>
							</div>
							<div>
								<Button
									@click="handleExport()"
									class="p-button-danger uppercase ml-3"
									label="Export"
								></Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Search results -->
		<div class="col-12">
			<div class="content-wrapper">
				<div class="content-header">Search Result</div>
				<div class="content-body">
					<div class="mt-4">
						<TableInvoiceNonPO
							:company="company ? company : null"
							:supplier="supplier ? supplier : null"
							:status="status ? status : null"
							:invoiceNumber="
								invoiceNumber ? invoiceNumber : null
							"
							:key="reloadTable"
							@loading="getLoading"
						></TableInvoiceNonPO>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import TableInvoiceNonPO from '@/components/listinvoicenonpo/components/TableSearchInvoiceNonPO';
import AuthService from '../../service/AuthService';
import LovService from '../../service/LovService';
import SimpleCrypto from 'simple-crypto-js';
import CompaniesDropdown from '@/components/components/CompaniesDropdown';
import SupplierDropdown from '@/components/components/SupplierDropdown';
import Status from '@/components/listinvoicenonpo/components/Status';
import InvoiceNumber from '@/components/listinvoicenonpo/components/InvoiceNumber';
import PoFulfillment from '../../service/PoFulfillment';
import ListInvoicenonPO from '../../service/ListInvoiceNonPo';
import XLSX from "xlsx"

export default {
	name: 'listInvoiceNonPo',
	components: {
		CompaniesDropdown,
		SupplierDropdown,
		TableInvoiceNonPO,
		Status,
		InvoiceNumber,
	},
	data() {
		return {
			loadingExport: false,
			isSupplierpage: false,
			first: 0,
			rows: 10,
			rowId: null,
			selectedPo: null,
			loading: false,
			lazyParams: {},
			selectLazyParams: {},
			company: null,
			supplier: null,
			supplierName: null,
			isEmpty: null,
			reload: 0,
			invoiceNumber: null,
			status: null,
			isButtonSearchDisable: null,
			userInfo: null,
			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [
				{
					label: 'List Invoice Non PO',
					to: '/mgm/monitor/listinvoicenonpo',
				},
			],
			supplierReload: ref(0),
			statusReload: ref(0),
			invoiceNumberReload: ref(0),
			reloadTable: ref(0),
		};
	},
	watch: {
		async company(newQuestion) {

			console.log("NEW QUESTION", newQuestion)

			if (
				typeof newQuestion === 'object' &&
				!Array.isArray(newQuestion)
			) {
				this.supplierReload++;
				this.status = null;
				this.invoiceNumber = null;
				if (this.userInfo.leveltenant !== 2) {
					this.supplier = null;
					this.invoiceNumberReload++;
					this.statusReload++;
				} else {
					this.supplier = null; // Reset old supplier
					console.log("MASUK HEHE")
					// Get new supplier/vendorId for new company
					const param = {
						db_instance: newQuestion.db_instance,
						org_id: newQuestion.value,
					};

					const respSuppName = await LovService.getSupplierName(
						param
					);
					if (respSuppName.status === 200) {
						const suppList = respSuppName.data.data;
						for (const s of suppList) {
							if (s.vendor_name === this.supplierName) {
								this.supplier = s;
								break;
							}
						}
						if (this.supplier === null) {
							this.$router.push('/dashboard');
							window.alert(
								'Connection Unstable, ID not Recognize'
							);
						}
					}

					this.invoiceNumberReload++;
					this.statusReload++;
					this.reloadTable++;
				}
			}
		},
		supplier(newQuestion) {
			// if (this.company !== null){
			if (this.supplier && this.supplier.length === 0) {
				this.invoiceNumberReload++;
				this.statusReload++;
			} else if (
				typeof newQuestion === 'object' &&
				!Array.isArray(newQuestion)
			) {
				this.status = null;
				this.invoiceNumber = null;
				if (this.supplier !== null) {
					if (this.userInfo.leveltenant === 2) {
						this.invoiceNumberReload++;
						this.statusReload++;
					} else {
						this.invoiceNumberReload++;
						this.statusReload++;
					}
				}

				//  else {
				//   this.supplierReload++;
				//   this.invoiceNumberReload++;
				//   this.statusReload++;
				// }
			}
		},

		status(newQuestion) {
			if (
				typeof newQuestion === 'object' &&
				!Array.isArray(newQuestion)
			) {
				if (this.status !== null) {
					this.invoiceNumberReload++;
				} else {
					this.invoiceNumberReload++;
				}
			}
		},
		invoiceNumber(newQuestion) {
			if (
				typeof newQuestion === 'object' &&
				!Array.isArray(newQuestion)
			) {
				if (this.invoiceNumber !== null) {
					this.statusReload++;
				} else {
					this.statusReload++;
				}
			}
		},
		// loading(){
		//   if
		// }
	},

	computed: {},

	created() {
		AuthService.whoAmi().then(async (response) => {
			function userCompany() {
				const secretKey = 'some-unique-key';
				const simpleCrypto = new SimpleCrypto(secretKey);
				return simpleCrypto.decrypt(
					sessionStorage.getItem('dropdownCompany')
				);
			}

			if (response.status === 200) {
				this.userInfo = response.data.data;
				if (this.userInfo.leveltenant === 2) {
					//get company
					this.isSupplierpage = true;
					const respCbN = await LovService.getCompanyByName(
						userCompany()
					);
					this.company = respCbN.data.data[0];
					//get supplier
					const param = {
						db_instance: this.company.db_instance,
						org_id: this.company.value,
					};
					const respVendor = await PoFulfillment.getSuppBindComp(
						response.data.data.id
					);
					const vendorName = respVendor.data.data[0][0].vendorname;
					this.supplierName = vendorName;
					const respSuppName = await LovService.getSupplierName(
						param
					);
					if (respSuppName.status === 200) {
						const suppList = respSuppName.data.data;
						for (const s of suppList) {
							if (s.vendor_name === vendorName) {
								this.supplier = s;
								break;
							}
						}
						if (this.supplier === null) {
							this.$router.push('/dashboard');
							window.alert(
								'Connection Unstable, ID not Recognize'
							);
						}
					}
				} else {
					const respCbN = await LovService.getCompanyByName(
						userCompany()
					);
					this.company = respCbN.data.data[0];
					// const response = await LovService.getcompanydb(this.userInfo.bioaddress);
					// const companies = response.data.data;
					// for (const c of companies) {
					//   if (parseInt(c.value) === this.userInfo.idsubtenant) {
					//     this.company = c;
					//     break;
					//   }
					// }
				}
			}
		});
	},
	async mounted() {
		this.isEmpty = 'Please Search for Data';
		// this.loading = true;
		this.lazyParams = {
			first: 0,
			rows: this.rows,
			sortField: null,
			sortOrder: null,
		};
	},

	methods: {
		getLoading(value) {
			this.loading = value.value;
		},
		onPage(event) {
			this.lazyParams = event;
			this.handleSearchPo(false);
		},
		onSort(event) {
			this.lazyParams = event;
			this.handleSearchPo(false);
		},

		formatDateDexa(date) {
			var date = new Date(date);
			var respondDate =
				('00' + (date.getMonth() + 1)).slice(-2) +
				'/' +
				('00' + date.getDate()).slice(-2) +
				'/' +
				date.getFullYear() +
				' ' +
				('00' + date.getHours()).slice(-2) +
				':' +
				('00' + date.getMinutes()).slice(-2) +
				':' +
				('00' + date.getSeconds()).slice(-2);
			return respondDate;
		},
		handleSearch() {
			this.reloadTable++;
		},
		clearFilter() {
			this.status = null;
			this.invoiceNumber = null;
			this.statusReload++;
			this.invoiceNumberReload++;
			if (this.userInfo.leveltenant !== 2) {
				this.supplier = '';
				// this.supplierReload++
			}
		},
		async handleExport(){
			this.loadingExport = true;
			try {
				const payload = {
					db_instance: this.company
						? this.company.db_instance
						: null,
					org_id: this.company ? this.company.value : null,
					suppValue: this.supplier ? this.supplier.vendor_id : null,
					status: this.status ? this.status.STATUS : null,
					invoice_no: this.invoiceNumber
						? this.invoiceNumber.INVOICE_NO
						: null,
					search: null,
				};

				const res = await ListInvoicenonPO.getExport(
					payload
				);



				const dataS = res.data.data.data;

				let today = new Date();
				let dd = String(today.getDate()).padStart(2, '0');
				let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
				let yyyy = today.getFullYear();
				let combinedDate = dd + mm + yyyy;
				let filename = 'List Invoice Non PO' + combinedDate + '.xlsx';
				let ws = XLSX.utils.json_to_sheet(dataS);
				let wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, 'My Worksheet');
				XLSX.writeFile(wb, filename);


				this.loadingExport = false;
			} catch (error) {
				this.loadingExport = false;
				// toast.add({
				//   severity: "error",
				//   summary: "Failed to export",
				//   detail: "Something issues on the server",
				//   life: 3000,
				// });
			}
			
		}
	},
};
</script>
<style scoped lang="scss"></style>
