export const formatRounding = (n) => {
    const h = +('1'.padEnd(2 + 1, '0')) // 10 or 100 or 1000 or etc
    return parseFloat(Math.round(n * h) / h)
}

export const formatCeil = (n,x) => {
    const y = 1
    const z = x + y
    const h = +('1'.padEnd(z, '0')) // 10 or 100 or 1000 or etc
    return parseFloat(Math.ceil(n * h) / h)
}

export const formatFloor = (n,x) => {
    const y = 1
    const z = x + y
    const h = +('1'.padEnd(z, '0')) // 10 or 100 or 1000 or etc
    return parseFloat(Math.floor(n * h) / h)
}