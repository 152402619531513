<template>
  <AutoComplete
      :suggestions="filteredCurrencies"
      @complete="searchCategories($event)"
      :dropdown="true"
      field="currency_code"
      forceSelection
  >
    <template #item="slotProp">
      {{ slotProp.item.currency_code }}
    </template>
  </AutoComplete>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {getCurrencies} from "@/service/InvoiceProxyNonPOServices";

// eslint-disable-next-line no-undef
const props = defineProps({
  company: {
    type: Object
  }
})

const filteredCurrencies= ref([]);
const currencies = ref([]);

onMounted(async () => {
  if (props.company) {
    currencies.value = await getCurrencies(props.company.db_instance)
  }

})


const searchCategories = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredCurrencies.value = [...currencies.value];
    } else {
      filteredCurrencies.value = currencies.value.filter((comp) => {
        return comp.currency_code.toLowerCase().match(event.query.toLowerCase());
      });
    }
  }, 250);
};
</script>
