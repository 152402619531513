<template>
	<AutoComplete
		:suggestions="filteredBatchDropdown"
		@complete="searchBatch($event)"
		:dropdown="true"
		field="batch_no"
		:placeholder="placeholder"
		forceSelection
	>
		<template #item="slotProp">
			{{ slotProp.item.batch_no }}
		</template>
	</AutoComplete>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import AuthService from '../../../service/AuthService';
import CreateBatchServices from '../../../service/CreateBatchServices';

const isiBatchNumber = ref([]);
const filteredBatchDropdown = ref([]);
const placeholder = ref('Select a Batch Number');

// eslint-disable-next-line no-undef
const props = defineProps({
	company: {
		type: Object,
	},
	supplier: {
		type: Object,
	},
});

onMounted(() => {
	populateBatch();
});

const populateBatch = async () => {
	AuthService.whoAmi().then(async (response) => {
		if ((await response.data.data.leveltenant) === 2) {
			let payload;
			// console.log("props company", props.company, props.supplier);
			if (props.company !== null && props.supplier !== null) {
				payload = {
					org_id: parseInt(props.company.value),
					db_instance: props.company.db_instance,
					suppVal: props.supplier.vendor_id,
				};
				if (response.status === 200) {
					const res = await CreateBatchServices.getBatchNo(payload);
					isiBatchNumber.value = res.data.data;
					// console.log("value batchNumber", isiBatchNumber.value);
				}
			}
		} else if (response.data.data.leveltenant === 3) {
			let payload;
			if (props.company !== null && props.supplier !== null) {
				payload = {
					org_id: parseInt(props.company.value),
					db_instance: props.company.db_instance,
					suppVal: props.supplier.vendor_id,
				};
				if (response.status === 200) {
					const respond = await CreateBatchServices.getBatchNoPIC(
						payload
					);
					if (respond.status === 200) {
						isiBatchNumber.value = respond.data.data[0];
					}
				}
			} else if (props.company !== null) {
				payload = {
					org_id: parseInt(props.company.value),
					db_instance: props.company.db_instance,
					idUser: response.data.data.id,
				};
				if (response.status === 200) {
					const respond = await CreateBatchServices.getBatchNoPIC(
						payload
					);
					if (respond.status === 200) {
						isiBatchNumber.value = respond.data.data[0];
						// placeholder.value = 'No data found'
					}
				}
			}
		}
	});
};

const searchBatch = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredBatchDropdown.value = [...isiBatchNumber.value];
		} else {
			filteredBatchDropdown.value = isiBatchNumber.value.filter(
				(item) => {
					return item.batch_no
						.toLowerCase()
						.match(event.query.toLowerCase());
				}
			);
		}
	}, 100);
};
</script>
