<template>
  <div class="grid">
    <Toast />
    <ProgressModal :progress="progress">
      <template #message>
        Your Request in progress.<br />Please wait...
      </template>
    </ProgressModal>
    <div class="col-12">
      <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
    </div>
    <div class="col-12">
      <div class="content-wrapper mb-4">
        <div class="content-header">General Information</div>
        <div class="content-body">
          <div class="flex flex-row justify-content-between">
            <div class="col flex-column align-item-center log:col-auto">
              <div class="grid">
                <div class="col-12 md:col-12 lg:col-4">
                  <span class="p-float-label">
                    <SupplierDropdown
                      v-model="supplier"
                      class="w-full mb-2 lg:mr-3 lg:mb-0"
                      :company="company ? company : null"
                      :key="supplierReload"
                      :disabled="true"
                    />
                    <label for="inputtext">Supplier</label>
                  </span>
                </div>
                <div class="col-12 md:col-12 lg:col-4">
                  <span class="p-float-label">
                    <InputText
                      v-model="documentNo"
                      class="w-full mb-2 lg:mb-0"
                      placeholder="Document Number"
                      :disabled="true"
                    />
                    <label for="inputtext">Document No</label>
                  </span>
                </div>
                <div class="col-12 md:col-12 lg:col-4">
                  <span class="p-float-label">
                    <DONumberDropdown
                      v-model="doNumber"
                      class="w-full mb-2 lg:mr-4 lg:mb-0"
                      :disabled="true"
                    />
                    <label for="inputtext">PO Number</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-wrapper mb-4">
        <div class="content-header">Finding Information</div>
        <div class="content-body">
          <div class="flex flex-row justify-content-between mb-4">
            <div class="col flex-column align-item-center lg:col-auto">
              <div class="grid">
                <div class="col-12 md:col-12 lg:col-4">
                  <span class="p-float-label">
                    <Calendar
                      v-model="auditDate"
                      dateFormat="d-M-yy"
                      :showIcon="true"
                      :show-time="true"
                      class="w-full mb-3 lg:mb-0"
                      placeholder="DR/Audit Date"
                      :disabled="id !== '0'"
                    />
                    <label for="inputtext">DR/Audit Date</label>
                  </span>
                </div>
                <div class="col-12 md:col-12 lg:col-4">
                  <!-- <ButtonDownloadFile :link="SOFileLink" :label="soFilename" /> -->
                  <Button
                    v-if="!loadingSoFile"
                    class="button-download font-normal"
                    :label="soFilename"
                    icon="pi pi-download"
                    @click="onDownload()"
                  />
                  <Button
                    v-if="loadingSoFile"
                    class="button-download font-normal"
                    :label="`${soFilename} is downloading`"
                    icon="pi pi-download"
                    :disabled="true"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-12 md:col-12 lg:col-4">
                  <span class="p-float-label mt-4">
                    <Textarea
                      v-model="finding"
                      rows="4"
                      placeholder="Finding"
                      class="w-full mb-3 lg:col-auto lg:mr-3 lg:mb-0"
                      disabled
                    />
                    <label for="inputtext">Subject</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-wrapper mb-4">
        <div class="content-header">Response Information</div>
        <div class="content-body">
          <div class="flex flex-row justify-content-between">
            <div class="col flex-column align-item-center lg:col-auto">
              <div class="grid">
                <div class="col-12 md:col-6 lg:col-4">
                  <span class="p-float-label">
                    <Textarea
                      v-model="respond"
                      rows="4"
                      placeholder="Finding"
                      class="w-full mb-3 lg:col-auto lg:mr-3 lg:mb-0"
                      disabled
                    />
                    <label for="inputtext">Response</label>
                  </span>
                </div>
                <div class="col-12 md:col-12 lg:col-4">
                  <!-- <ButtonDownloadFile :link="SPFileLink" :label="spFilename" /> -->
                  <Button
                    v-if="!loadingSpFile"
                    class="button-download font-normal"
                    :label="spFilename"
                    icon="pi pi-download"
                    @click="onDownloadSpFile()"
                  />
                  <Button
                    v-if="loadingSpFile"
                    class="button-download font-normal"
                    :label="`${spFilename} is downloading`"
                    icon="pi pi-download"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-wrapper mb-4">
        <div class="content-header">Remarks</div>
        <div class="content-body">
          <!--          <div class="flex flex-row justify-content-between mb-4">-->
          <!--            <div class="col flex-column align-item-center log:col-auto">-->
          <Suspense>
            <Remarks
              :id="id"
              :user="userInfo"
              :key="remarksReload"
              :disable="status === 'C'"
            />
          </Suspense>
          <!--            </div>-->
          <!---->
          <!--          </div>-->
          <!-- <div v-if="status === 'C' ? true : false">
            <Button
              label="Print Page"
              @click="handlePrintCAPA()"
              class="p-button-secondary w-full mb-3 lg:w-auto lg:mr-3 lg:mb-0 lg:ml-2"
              icon="pi pi-print "
              :disabled="disableButton"
            />
          </div> -->
        </div>
      </div>
      <div class="content-wrapper mb-4">
        <div class="content-body">
          <div v-if="status !== 'C'">
            <Button
              label="Back"
              @click="back()"
              class="p-button-secondary w-full mb-3 lg:w-auto lg:mr-3 lg:mb-0"
            />
            <Button
              label="Close"
              @click="close()"
              class="p-button-primary w-full mb-3 lg:w-auto lg:mr-3 lg:mb-0"
              :disabled="disableButton"
            />
          </div>
          <div v-if="status === 'C'">
            <Button
              label="Print Page"
              @click="handlePrintCAPA()"
              class="p-button-secondary w-full mb-3 lg:w-auto lg:mr-3 lg:mb-0 lg:ml-2"
              icon="pi pi-print "
              :disabled="disableButton"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// import {useToast} from "primevue/usetoast";
import { ref, watch } from "vue";
import AuthService from "@/service/AuthService";
import { useRoute, useRouter } from "vue-router";
import SimpleCrypto from "simple-crypto-js";
// import {createCAPA, getCAPA, updateCAPA} from "@/service/CAPAServices";
import { useToast } from "primevue/usetoast";
import { getCAPA, updateCAPA, getSOfile } from "@/service/CAPAServices";
import LovService from "@/service/LovService";

// import ButtonDownloadFile from "./components/ButtonDownloadFile.vue";
import ProgressModal from "../components/ProgressModal.vue";
import DONumberDropdown from "./components/DONumberDropdown.vue";
import SupplierDropdown from "../createbatch/components/SupplierDropdown.vue";
import Remarks from "@/components/capa/components/Remarks";
import { getSPfile } from "../../service/CAPAServices";
import { UrlTab } from "../../constants/UrlTab";

const breadcrumbHome = { icon: "pi pi-home", to: "/dashboard" };
const breadcrumbItems = ref([]);
const supplier = ref(null);
const documentNo = ref();
const doNumber = ref();
const finding = ref();
const toast = useToast();
const soFilename = ref();
const spFilename = ref();
const respond = ref();
const remarks = ref();
// const capa = ref();
const userInfo = ref();
const route = useRoute();
const router = useRouter();
const id = ref(route.params.id);
const SOFileLink = ref();
const SPFileLink = ref();
const progress = ref(false);
const company = ref();
const dbInstance = ref();
const supplierReload = ref(0);
const auditDate = ref();
const status = ref();
const disableButton = ref(true);
const remarksReload = ref();
const soFileBuffer = ref();
const spFileBuffer = ref();
const loadingSoFile = ref(false);
const loadingSpFile = ref(false);
// const capa = ref();

watch([id, userInfo], () => {
  remarksReload.value++;
});

AuthService.whoAmi().then(async (response) => {
  if (response.status === 200) {
    const map = new Map();
    map.set("label", "Purchasing Update CAPA");
    map.set("to", "/mgm/master/capa/editcapapur/" + id.value);
    const breadcrumbItem = Object.fromEntries(map);
    breadcrumbItems.value[0] = breadcrumbItem;
    userInfo.value = response.data.data;
    const respCbN = await LovService.getCompanyByName(userCompany());
    company.value = respCbN.data.data[0];
    dbInstance.value = company.value.db_instance;
    supplierReload.value++;
    await getData();
    disableButton.value = false;
  }
});

const userCompany = () => {
  const secretKey = "some-unique-key";
  const simpleCrypto = new SimpleCrypto(secretKey);
  return simpleCrypto.decrypt(sessionStorage.getItem("dropdownCompany"));
};

const getData = async () => {
  const resultData = await getCAPA(id.value);
  const result = resultData.data;
  SOFileLink.value = resultData.SOFileLink;
  SPFileLink.value = resultData.SPFileLink;
  // capa.value = result[0];
  const type = typeof result;
  if (type === "object") {
    documentNo.value = result[0].DOC_NUMBER;
    finding.value = result[0].FINDING;
    doNumber.value = result[0].PO_NUMBER;
    soFilename.value = result[0].SO_FILENAME
      ? result[0].SO_FILENAME
      : "no file attached";
    respond.value = result[0].RESPOND;
    spFilename.value = result[0].SP_FILENAME;
    remarks.value = result[0].REMARKS;
    status.value = result[0].STATUS;
    let option = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    auditDate.value = new Date(result[0].FINDING_DATE).toLocaleDateString(
      "id-ID",
      option
    );
    const payload = {
      db_instance: company.value.db_instance,
      org_id: company.value.value,
    };
    LovService.getSupplierName(payload).then((response) => {
      const type = typeof response.data.data;
      if (type === "string") {
        console.error("No supplier found");
      } else {
        const suppliers = response.data.data;
        suppliers.forEach((supp) => {
          if (supp.vendor_id === result[0].VENDOR_ID) {
            supplier.value = supp;
          }
        });
        if (supplier.value === null) {
          router.push('/dashboard')
          window.alert("Connection Unstable, ID not Recognize")
        }
      }
    });
  } else {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: `${result}`,
      life: 3000,
    });
  }
};

// const update = async () => {
//   progress.value = true;
//   const param = {
//     remarks: remarks.value,
//     last_updated_by: userInfo.value.id,
//   };
//   const result = await updateCAPA(id.value, param);
//   if (result.toUpperCase().includes("SUCCESS")) {
//     toast.add({
//       severity: "info",
//       summary: "Success",
//       detail: "CAPA is updated",
//       life: 3000,
//     });
//     progress.value = false;
//     setTimeout(() => {
//       location.reload();
//     }, 1500);
//   }
//   else if (result.includes("Fail")){
//           toast.add({
//               severity: "error",
//               summary: "Can't encrypt PDF",
//               detail: "PDF already has a password",
//               life: 3000,
//             });
//           progress.value = false;
//           }
//  else {
//     toast.add({
//       severity: "error",
//       summary: "Failed",
//       detail: "Unable to update CAPA",
//       life: 3000,
//     });
//     progress.value = false;
//   }
// };

const close = async () => {
  progress.value = true;
  const param = {
    remarks: remarks.value,
    status: "C",
    last_updated_by: userInfo.value.id,
  };
  const result = await updateCAPA(id.value, param);
  if (result.toUpperCase().includes("SUCCESS")) {
    toast.add({
      severity: "info",
      summary: "Success",
      detail: "CAPA is closed",
      life: 3000,
    });
    progress.value = false;
    setTimeout(() => {
      location.reload();
    }, 1500);
  } else {
    toast.add({
      severity: "error",
      summary: "Failed",
      detail: "Unable to close CAPA",
      life: 3000,
    });
  }
};

const back = () => {
  router.back();
};

const handlePrintCAPA = () => {
  let url = UrlTab() + '/printcapa';
  
  // const payload = {
  //   capaId: id.value,
  // };
  sessionStorage.setItem("printCapaId", id.value);
  window.open(url, "Print", "height=720,width=640");
};

const onDownload = async () => {
  try {
    loadingSoFile.value = true;
    const leveltenant = sessionStorage.getItem("leveltenant");
    const resSoFile = await getSOfile(id.value, leveltenant);
    soFileBuffer.value = resSoFile;
  } catch (error) {
    loadingSoFile.value = false;
    toast.add({
      severity: "error",
      summary: "Connection Unstable !",
      life: 3000,
    });
    throw new Error("Error Downloading File");
  }

  const linkSource = `data:application/pdf;base64,${soFileBuffer.value}`;
  const downloadLink = document.createElement("a");
  const fileName = soFilename.value;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
  loadingSoFile.value = false;
};

const onDownloadSpFile = async () => {
  try {
    loadingSpFile.value = true;
    const leveltenant = sessionStorage.getItem("leveltenant");
    const resSpFile = await getSPfile(id.value, leveltenant);
    spFileBuffer.value = resSpFile;
  } catch (error) {
    loadingSpFile.value = false;
    toast.add({
      severity: "error",
      summary: "Connection Unstable !",
      life: 3000,
    });
    throw new Error("Error Downloading File");
  }

  const linkSource = `data:application/pdf;base64,${spFileBuffer.value}`;
  const downloadLink = document.createElement("a");
  const fileName = spFilename.value;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
  loadingSpFile.value = false;
};
</script>

<style scoped></style>
