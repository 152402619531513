<template>
	<div class="grid">
		<ProgressModal :progress="progress">
			<template #message>
				Save data in progress.<br />Please wait...
			</template>
		</ProgressModal>
		<Toast />

		<!-- Breadcrumb -->
		<div v-if="idValue != 0" class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<div v-if="idValue == 0" class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItemsif" />
		</div>

		<!-- Input Search -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Purchasing Information</div>
				<div class="content-body">
					<div class="grid">
						<form
                        @submit.prevent="handleSubmit(!v$.$invalid)"
                        class="p-fluid"
						>
							<div
								class="grid formgrid"
							>
								<div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
									<!-- <span
										style="color: #000; font-size: 15px;"
										class="font-semibold"
										>Item Number :</span
									> -->
									<div
										class="flex align-items-center mb-4"
									>
										<span
											style="color: #000;display: flex; justify-content: flex-end"
											class="font-semibold col-2 lg:col-1  "
											>Supplier Name :</span
										>
										<Supplier :company="company?company:null" :vendorId="vendor_id?vendor_id:null" :levelTenant="levelTenant?levelTenant:1" :key="reload" :disabled="status" @supplier="getSupplier"/>
									</div>
									<div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-right:300px;" >
										<label
											v-if="
												(v$.supplierName.$invalid &&
													submitted) ||
												v$.supplierName.$pending
													.$response
											"
											class="p-error"
											>{{
												v$.supplierName.required.$message.replace(
													'Value',
													'Supplier Name'
												)
											}}</label>
									</div>
									
								</div>
								<div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
									<div
										class="flex align-items-center justify-content-between mb-4"
									>
										<span
											style="color: #000;display: flex; justify-content: flex-end"
											class="font-semibold col-2 lg:col-1 "
											>Item Number :</span
										>
										<InputText
											id="username"
											type="text"
											v-model="
												itemNumberFront
											"
											
											:class="{
													'p-invalid':
														v$.itemNumber.$invalid && submitted,
												}"
											disabled
										/>
										<span class="p-float-label col-5 lg:col-9" >
											<Button icon="pi pi-search" class="p-button-rounded p-button-info" @click="findItemNumber()" :disabled="levelTenant === 2 || status !== null" />
										</span>
									</div>
									<div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-right:300px;" >
										<label
											v-if="
												(v$.itemNumber.$invalid &&
													submitted) ||
												v$.itemNumber.$pending
													.$response
											"
											class="p-error"
											>{{
												v$.itemNumber.required.$message.replace(
													'Value',
													'Item Number'
												)
											}}</label>
									</div>
								</div>
								<div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
									<!-- <span
										style="color: #000; font-size: 15px;"
										class="font-semibold"
										>Item Number :</span
									> -->
									<div
										class="flex align-items-left mb-4"
									>
										<span
											style="color: #000;display: flex; justify-content: flex-end"
											class="font-semibold col-2 lg:col-1 "
											>Item Description :</span
										>
										<div style="margin-top:-1px">
											<!-- <InputText
											id="username"
											type="text"
											v-model="
												description
											"
											:disabled="levelTenant === 2 || status !== 'CONFIRMED' && status !== null" 
											/> -->

											<Textarea id="username"
											type="text"
											v-model="description"
											rows="4" cols="60" disabled />
										</div>
									</div>
								</div>
								<div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
									<!-- <span
										style="color: #000; font-size: 15px;"
										class="font-semibold"
										>Item Number :</span
									> -->
									<div
										class="flex align-items-left mb-4"
									>
										<span
											style="color: #000;display: flex; justify-content: flex-end"
											class="font-semibold col-2 lg:col-1  "
											>Spec Number :</span
										>
										<div style="margin-top:-8px">
										<InputText
										id="username"
										type="text"
										:maxlength="30"
										v-model="
											v$.specNumber.$model
											"
										:disabled="levelTenant === 2 || status !== null"
										:class="{
													'p-invalid':
														v$.specNumber.$invalid && submitted,
												}"
										/>
										</div>
										
									</div>
									<div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-right:300px;" >
										<label
											v-if="
												(v$.specNumber.$invalid &&
													submitted) ||
												v$.specNumber.$pending
													.$response
											"
											class="p-error"
											>{{
												v$.specNumber.required.$message.replace(
													'Value',
													'Spec Number'
												)
											}}</label>
									</div>
								</div>
								<div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
									<!-- <span
										style="color: #000; font-size: 15px;"
										class="font-semibold"
										>Item Number :</span
									> -->
									<div
										class="flex align-items-left mb-4"
									>
										<span
											style="color: #000;display: flex; justify-content: flex-end"
											class="font-semibold col-2 lg:col-1  "
											>Spec Version :</span
										>
										<div style="margin-top:-8px">
										<InputNumber :useGrouping='false'
										v-model="v$.specVersion.$model" :disabled="levelTenant === 2 || status !== null" :class="{'p-invalid': v$.specVersion.$invalid && submitted}  "
										/>
										<!-- <InputText
										id="username"
										type="text"
										v-model="
											v$.specVersion.$model
											"
										:disabled="levelTenant === 2 || status !== 'CONFIRMED' && status !== null"
										:class="{
													'p-invalid':
														v$.specVersion.$invalid && submitted,
												}"
										/> -->
										</div>
									</div>
									<div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-right:300px;" >
										<label
											v-if="
												(v$.specVersion.$invalid &&
													submitted) ||
												v$.specVersion.$pending
													.$response
											"
											class="p-error"
											>{{
												v$.specVersion.required.$message.replace(
													'Value',
													'Spec Version'
												)
											}}</label>
									</div>
								</div>
								<div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
									<!-- <span
										style="color: #000; font-size: 15px;"
										class="font-semibold"
										>Item Number :</span
									> -->
									<div
										class="flex align-items-left mb-4"
									>
										<span
											style="color: #000;display: flex; justify-content: flex-end"
											class="font-semibold col-2 lg:col-1 "
											>Effective Date :</span
										>
										<div style="margin-top:-8px;margin-left:-8px" class="col-4 lg:col-2">
											<Calendar inputId="buttonbar" v-model="v$.effectiveDate.$model" dateFormat="dd M yy" 
											:showIcon="true" :showButtonBar="true" :disabled="levelTenant === 2 || status !== null" 
											:class="{
													'p-invalid':
														v$.effectiveDate.$invalid && submitted,
												}"/>
										</div>
									</div>
									<div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-right:300px;" >
										<label
											v-if="
												(v$.effectiveDate.$invalid &&
													submitted) ||
												v$.effectiveDate.$pending
													.$response
											"
											class="p-error"
											>{{
												v$.effectiveDate.required.$message.replace(
													'Value',
													'Effective Date'
												)
											}}</label>
									</div>
								</div>
								<div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
									<!-- <span
										style="color: #000; font-size: 15px;"
										class="font-semibold"
										>Item Number :</span
									> -->
									<div
										class="flex align-items-left mb-4"
									>
										<span
											style="color: #000;display: flex; justify-content: flex-end"
											class="font-semibold col-2 lg:col-1 "
											>Upload Date :</span
										>
										<div style="margin-top:-8px;margin-left:-8px" class="col-4 lg:col-2">
											<Calendar inputId="buttonbar" v-model="v$.uploadDate.$model" dateFormat="dd M yy" 
											:showIcon="true" :showButtonBar="true" :disabled="levelTenant === 2 || status !== null"
											:class="{
													'p-invalid':
														v$.uploadDate.$invalid && submitted,
												}"/>
										</div>
									</div>
									<div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-right:300px;" >
										<label
											v-if="
												(v$.uploadDate.$invalid &&
													submitted) ||
												v$.uploadDate.$pending
													.$response
											"
											class="p-error"
											>{{
												v$.uploadDate.required.$message.replace(
													'Value',
													'Upload Date'
												)
											}}</label>
									</div>
								</div>
								<div v-if="attachmentName !== null" class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
									<!-- <span
										style="color: #000; font-size: 15px;"
										class="font-semibold"
										>Item Number :</span
									> -->
									<div
										class="flex align-items-center mb-4"
									>
										<span
											style="color: #000;display: flex; justify-content: flex-end"
											class="font-semibold col-2 lg:col-1 "
											>File :</span
										>
										<div style="margin-left:-8px" class="col-2 lg:col-2">
											<!-- <a
												class="button-download p-button"
												:href="attachmentUrl"
											>
												<span style="margin:auto" class="font-bold" >
													<i class="pi pi-download mr-2" />
													{{ attachmentName }}
												</span>
											</a> -->
											<Button
												v-if="!loadingSoFile"
												class="button-download font-normal"
												:label="attachmentName"
												icon="pi pi-download"
												@click="onDownload()"
											/>
											<Button
												v-if="loadingSoFile"
												class="button-download font-normal"
												:label="`${attachmentName} is downloading`"
												icon="pi pi-download"
												:disabled="true"
											/>
										</div>
										<div class="col-1 lg:col-1">
											<Button
											:class="uppercase"
											icon="pi pi-times"
											class="p-button-rounded p-button-danger p-button-text"
											@click="deleteFilePur()"
											:disabled="levelTenant !== 1 || status === 'CONFIRMED'"
											>
											</Button>
										</div>
										
									</div>
								</div>
								<div v-if="attachmentName === null && levelTenant === 1" class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
									<!-- <span
										style="color: #000; font-size: 15px;"
										class="font-semibold"
										>Item Number :</span
									> -->
									<div
										class="flex align-items-left mb-4"
									>
										<span
											style="color: #000;display: flex; justify-content: flex-end"
											class="font-semibold col-2 lg:col-1 "
											>Upload File :</span
										>
										<div style="margin-top: -15px;margin-left:-8px" class="col-6 lg:col-3">
											<FileUpload
												id="uploadFile"
												mode="advanced"
												@select="onSelectFile"
												chooseLabel="Upload File"
												:multiple="false"
												:customUpload="true"
												accept=".pdf"
												:showUploadButton="false"
												:showCancelButton="false"
												:fileLimit="1"
												:maxFileSize="5000000"
												:disabled="levelTenant === 2"
											>
												<template #empty>
												<p>No file selected. (Max file 5MB)</p>
												</template>
											</FileUpload>
										</div>
									</div>
								</div>
								</div>
								</form>
						
					</div>
					<form
							@submit.prevent="handleSubmit(!v$.$invalid)"
							class="p-fluid"
							v-if="pageAdd"
						>
							<div class="p-fluid grid formgrid">
								<div class="col-6 mb-2 lg:col-1 lg:mb-0">
									<Button
										:class="uppercase"
										class="p-button-secondary"
										label="Back"
										@click="button('B')"
									>
									</Button>
								</div>
								<div
									class="col-6 mb-2 lg:col-1 lg:mb-0"
									v-if="createDisabled"
								>
									<Button
										type="submit"
										label="Save"
										@click="button('S')"
										class="w-full p-button-success"
									/>
								</div>
								<div
									class="col-6 mb-2 lg:col-1 lg:mb-0"
									v-if="createDisabled "
								>
									<Button
										type="submit"
										label="Save"
										@click="button('S')"
										class="w-full p-button-success"
									/>
								</div>
								<div
									class="col-6 mb-2 lg:col-1 lg:mb-0"
									v-if="!createDisabled"
								>
									<Button
										type="confirm"
										label="Create"
										@click="button('C')"
										class="p-button-info"
									/>
								</div>
								
							</div>
						</form>
						<div v-if="status === 'OPEN' && levelTenant === 1" class="col-6 mb-2 lg:col-1 lg:mb-0">
							<Button
								:class="uppercase"
								class="p-button-secondary"
								label="Back"
								@click="button('B')"
							>
							</Button>
						</div>
				</div>
			</div>
		</div>

		<!-- Supplier Information -->
		<div class="col-12 mb-4" >
			<div class="content-wrapper" v-if="(!pageAdd && levelTenant === 2 && status === 'OPEN' || status === 'CONFIRMED' || status === 'CANCELLED' ) || (levelTenant === 1 && status !== 'OPEN' && status !== null) ">
				<div class="content-header">Supplier Information</div>
				<div class="content-body">
					<div class="grid">
						<form
                        @submit.prevent="handleSubmit(!v$.$invalid)"
                        class="p-fluid"
						>
							<div
								class="grid formgrid"
							>
								<div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
									<!-- <span
										style="color: #000; font-size: 15px;"
										class="font-semibold"
										>Item Number :</span
									> -->
									<div
										class="flex align-items-center justify-content-between mb-4"
									>
										<span
											style="color: #000;display: flex; justify-content: flex-end"
											class="font-semibold col-2 lg:col-1  "
											>Confirm By :</span
										>
										<InputText
										id="username"
										type="text"
										v-model="
											confirmBy
											"
										:disabled="levelTenant !== 2 || status !== 'OPEN'"
										/>
									</div>
									<div v-if="levelTenant === 2" class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-right:300px;" >
										<label
											v-if="
												(v$.confirmBy.$invalid &&
													submitted) ||
												v$.confirmBy.$pending
													.$response
											"
											class="p-error"
											>{{
												v$.confirmBy.required.$message.replace(
													'Value',
													'Confirm By'
												)
											}}</label>
									</div>
								</div>
								<div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
									<!-- <span
										style="color: #000; font-size: 15px;"
										class="font-semibold"
										>Item Number :</span
									> -->
									<div
										class="flex align-items-left mb-4"
									>
										<span
											style="color: #000;display: flex; justify-content: flex-end"
											class="font-semibold col-2 lg:col-1 "
											>Confirm Date :</span
										>
										<div style="margin-top:-8px;margin-left:-8px" class="col-4 lg:col-2">
											<Calendar inputId="buttonbar" v-model="confirmDate" dateFormat="dd M yy" 
											:showIcon="true" :showButtonBar="true" :disabled="levelTenant !== 2 || status !== 'OPEN'" />
										</div>
									</div>
									<div v-if="levelTenant === 2" class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-right:300px;" >
										<label
											v-if="
												(v$.confirmDate.$invalid &&
													submitted) ||
												v$.confirmDate.$pending
													.$response
											"
											class="p-error"
											>{{
												v$.confirmDate.required.$message.replace(
													'Value',
													'Confirm Date'
												)
											}}</label>
									</div>
								</div>
								<div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
									<!-- <span
										style="color: #000; font-size: 15px;"
										class="font-semibold"
										>Item Number :</span
									> -->
									<div
										class="flex align-items-left mb-4"
									>
										<span
											style="color: #000;display: flex; justify-content: flex-end"
											class="font-semibold col-2 lg:col-1 "
											>Confirmation Option :</span
										>
										<div class="col-6 mb-2 lg:col-1 lg:mb-0">
											<RadioButton inputId="button1" value="manual" v-model="radioButton" :disabled="levelTenant !== 2 || status !== 'OPEN'"/>
											<label for="button1">Manual</label>
										</div>
										<div class="col-6 mb-2 lg:col-1 lg:mb-0">
											<RadioButton inputId="button2" value="digital" v-model="radioButton" :disabled="levelTenant !== 2 || status !== 'OPEN'"/>
											<label for="button2">Digital</label>
										</div>
										
									</div>
								</div>
								<div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px" v-if="radioButton === 'digital'">
									<!-- <span
										style="color: #000; font-size: 15px;"
										class="font-semibold"
										>Item Number :</span
									> -->
									<div
										class="flex align-items-center justify-content-between mb-4"
									>
										<span
											style="color: #000;display: flex; justify-content: flex-end"
											class="font-semibold col-2 lg:col-1  "
											>Confirm Note :</span
										>
										<Textarea v-model="confirmNote" :autoResize="true" rows="5" cols="100" :disabled="levelTenant !== 2 || status !== 'OPEN'"/>
										<div>
											<div v-if="levelTenant === 2" class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-right:300px;" >
											<label
												v-if="
													(v$.confirmNote.$invalid &&
														submitted) ||
													v$.confirmNote.$pending
														.$response
												"
												class="p-error"
												>{{
													v$.confirmNote.required.$message.replace(
														'Value',
														'Confirm Note'
													)
												}}</label>
											</div>
										</div>
										
										<!-- <span class="p-float-label col-8 lg:col-10" >
											<Button icon="pi pi-search" class="p-button-rounded p-button-info" @click="findItemNumber()" />
										</span> -->
									</div>
								</div>
								<div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px" v-if="radioButton === 'manual' && attachmentNameSuppp !== null">
									<!-- <span
										style="color: #000; font-size: 15px;"
										class="font-semibold"
										>Item Number :</span
									> -->
									<div v-if="attachmentNameSupp !== null"
										class="flex align-items-center mb-4"
									>
										<span
											style="color: #000;display: flex; justify-content: flex-end"
											class="font-semibold col-2 lg:col-1 "
											>File :</span
										>
										<div style="margin-left:-8px" class="col-2 lg:col-2">
											<Button
												v-if="!loadingSoFileSupp"
												class="button-download font-normal"
												:label="attachmentNameSupp"
												icon="pi pi-download"
												@click="onDownloadSupp()"
											/>
											<Button
												v-if="loadingSoFileSupp"
												class="button-download font-normal"
												:label="`${attachmentNameSupp} is downloading`"
												icon="pi pi-download"
												:disabled="true"
											/>
										</div>
										<div class="col-1 lg:col-1">
											<Button
											:class="uppercase"
											icon="pi pi-times"
											class="p-button-rounded p-button-danger p-button-text"
											@click="deleteFileSupp()"
											:disabled="levelTenant !== 2"
											>
											</Button>
										</div>
										
									</div>
								</div>
								<div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px" v-if="radioButton === 'manual'">
									<!-- <span
										style="color: #000; font-size: 15px;"
										class="font-semibold"
										>Item Number :</span
									> -->
									<div v-if="levelTenant === 2 && attachmentNameSupp === null"
										class="flex align-items-left mb-4"
									>
										<span
											style="color: #000;display: flex; justify-content: flex-end"
											class="font-semibold col-2 lg:col-1 "
											>Upload File :</span
										>
										<div style="margin-top: -15px;margin-left:-8px" class="col-6 lg:col-3">
											<FileUpload
												id="uploadFile"
												mode="advanced"
												@select="onSelectFileSupp"
												chooseLabel="Upload File"
												:multiple="false"
												:customUpload="true"
												accept=".pdf"
												:showUploadButton="false"
												:showCancelButton="false"
												:fileLimit="1"
												:maxFileSize="5000000"
												:disabled="levelTenant !== 2"
											>
												<template #empty>
												<p>No file selected. (Max file 5MB)</p>
												</template>
											</FileUpload>
										</div>
									</div>
								</div>
								</div>
								</form>
					</div>
					<form
							@submit.prevent="handleSubmit(!v$.$invalid)"
							class="p-fluid"
						>
							<div class="p-fluid grid formgrid">
								<div class="col-6 mb-2 lg:col-1 lg:mb-0">
									<Button
										:class="uppercase"
										class="p-button-secondary"
										label="Back"
										@click="button('B')"
									>
									</Button>
								</div>
								<div
									class="col-6 mb-2 lg:col-1 lg:mb-0"
									v-if="!createDisabled && status === 'OPEN' "
								>
									<Button
										type="submit"
										label="Save"
										@click="button('S')"
										class="w-full p-button-success"
										:disabled="levelTenant === 2 && status !== 'OPEN' || buttonSaveDisabled"
									/>
								</div>
								<div
									class="col-6 mb-2 lg:col-1 lg:mb-0"
									v-if="!createDisabled && levelTenant === 1 && status === 'CONFIRMED'"
								>
									<Button
										type="submit"
										label="Closed Spec"
										@click="button('SC')"
										class="w-full p-button-info"
										:disabled="buttonSaveDisabled"
									/>
								</div>
								<div
									class="col-6 mb-2 lg:col-1 lg:mb-0"
									v-if="!createDisabled && levelTenant === 1 && status === 'CONFIRMED'"
								>
									<Button
										type="submit"
										label="Cancel Spec"
										@click="button('R')"
										class="w-full p-button-danger"
										:disabled="buttonSaveDisabled"
									/>
								</div>
								<div
									class="col-6 mb-2 lg:col-2 lg:mb-0"
									v-if="!createDisabled && levelTenant === 1 && status === 'CONFIRMED'"
								>
									<Button
										label="View History"
										@click="viewHistory()"
										class="w-full p-button-help"
										:disabled="buttonSaveDisabled"
									/>
								</div>
								<div
									class="col-6 mb-2 lg:col-1 lg:mb-0"
									v-if="!createDisabled && levelTenant === 2 && status === 'OPEN'"
								>
									<Button
										type="submit"
										label="Submit Conf"
										@click="button('ST')"
										class="w-full p-button-info"
										:disabled="buttonSaveDisabled"
									/>
								</div>
								<div
									class="col-6 mb-2 lg:col-1 lg:mb-0"
									v-if="createDisabled"
								>
									<Button
										type="confirm"
										label="Create"
										@click="button('R')"
										class="p-button-info"
										:disabled="buttonSaveDisabled"
									/>
								</div>
								
							</div>
						</form>
				</div>
				
			</div>
			
		</div>
	</div>

	<Dialog
		header="Item Number"
		v-model:visible="dialog"
		:breakpoints="{ '1080px': '160vw', '1080px': '180vw' }"
		:style="{ width: '50vw' }"
		:modal="true"
	>
		<div
			class="col-12 mb-12 md:col-12 lg:col-12 lg:mb-12"
			style="margin-top: 20px"
		>
			<!-- <InputText id="username" type="text" v-model="v$.refNumber.$model" :class="{'p-invalid':v$.refNumber.$invalid && submitted}" :maxlength="50"/> -->
			<ItemNumberEdit :company="company?company:null" :key="reload" :item_number="itemNumberFront?itemNumberFront:null" :idValue="idValue?idValue:0" @itemNumber="getItemNumber"/>
		</div>
	</Dialog>

	<Dialog
		header="View History"
		v-model:visible="dialogHistory"
		:breakpoints="{ '1080px': '160vw', '1080px': '180vw' }"
		:style="{ width: '50vw' }"
		:modal="true"
	>
		<div
			class="col-12 mb-12 md:col-12 lg:col-12 lg:mb-12"
			style="margin-top: 20px"
		>
			<ViewHistory :company="company?company:null" :key="reload" :item_num="itemNumberFront?itemNumberFront:null" :vendor_id="vendor_id?vendor_id:null" />
		</div>
	</Dialog>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import AuthService from "../../service/AuthService";
import SimpleCrypto from 'simple-crypto-js';
import LovService from '../../service/LovService';
import PoFulfillment from '../../service/PoFulfillment';
import { useRoute, useRouter } from "vue-router";
import { required } from '@vuelidate/validators';
import { useToast } from 'primevue/usetoast';
import { useVuelidate } from '@vuelidate/core';
import SpecSrc from '../../service/SpecSrc'
import Supplier from './components/SupplierEdit.vue'
import ItemNumberEdit from './components/ItemNumberEdit.vue'
import ProgressModal from '../components/ProgressModal.vue';
import ViewHistory from './components/ViewHistoryEdit.vue'
// import InvoiceService from '../../service/InvoiceSearchWPO';

// eslint-disable-next-line no-undef
const routerPage = useRouter();
const company = ref();
const vendor_id = ref();
const itemNumberFront = ref();
const itemNumberId = ref();
const supplierName = ref(null);
const specNumber = ref();
const specVersion = ref();
const description = ref(null);
const userInfo = ref();
const supplier = ref(null);
const isSupplierpage = ref(false);
const levelTenant = ref(0);
const labelEdit = ref("Add Spec SRC")
const toast = useToast();
const submitted = ref(false);
const effectiveDate = ref();
const uploadDate = ref();
const savedFile = ref(null);
const savedFileSupp = ref(null);
const fileData = ref(null);
const fileDataSupp = ref(null);
const progress = ref(false);
const confirmNote = ref();
const router = useRoute();
const radioButton = ref('manual');
const confirm_type = ref();
const itemNumber = ref();
const buttonValue = ref();
const idValue = ref(router.params.id);
const pageAdd = ref(true)
const fileName = ref();
const fileNameSupp = ref();
const fileNameOld = ref(null);
const fileNameSuppOld = ref(null);
const reload = ref(0);
const dialog = ref(false);
const dialogHistory = ref(false);
const attachmentName = ref(null);
const attachmentUrl = ref();
const attachmentNameSupp = ref();
const attachmentUrlSupp = ref();
const confirmBy = ref();
const confirmDate = ref();
const soFileBuffer = ref();
const status = ref(null);
const buttonSaveDisabled = ref(true);
const loadingFilePur = ref(false);
const loadingSoFile = ref(false);
const loadingSoFileSupp = ref(false);
const breadcrumbHome = { icon: "pi pi-home", to: "/" };
const breadcrumbItems = [
  { label: "Spec SRC", to: "/mgm/settings/specsrc" },
  { label: "Edit Spec SRC", to: router.fullPath }
];
const breadcrumbItemsif = [
  { label: "Spec SRC", to: "/mgm/settings/specsrc" },
  { label: "Add Spec SRC", to: router.fullPath }
];

const validationRules = computed(() => {
	const rules = {
		itemNumber: {
			required
		}, 
		supplierName : {
			required
		},
		specNumber : {
			required
		},
		specVersion : {
			required
		},
		effectiveDate : {
			required
		},
		uploadDate : {
			required
		}
	};

	if (levelTenant.value === 2) {
		rules.confirmBy = {required}
		rules.confirmDate = {required}
		rules.radioButton = {required}
	}
	if (radioButton.value === 'digital'){
		rules.confirmNote = {required}
	}
	return rules;
});

const v$ = useVuelidate(validationRules, {
    itemNumber, supplierName, specNumber,
	specVersion, effectiveDate, uploadDate, 
	confirmBy, confirmDate, radioButton, confirmNote
});

onMounted(() => {
	populateData()
	
})

watch(
  () => supplierName.value,
  (newValue) => {
    if (newValue === undefined) {
		// console.log('masuk sini')
		buttonSaveDisabled.value = true} 
	else if (newValue !== null) {
		// console.log('masuk sini udah ada')
		buttonSaveDisabled.value = false}
  }
);


const populateData = async () => {
	checkTenant();
	if (idValue.value > 0){
		const payload = { id : idValue.value}
		const data = await SpecSrc.getDetailData(payload)
		if (data == undefined || data.status === null){
              setTimeout( async () => {
                await populateData()
              }, 1000);
            }
		else if (data.status === 200){
			vendor_id.value = data.data.data[0].vendor_id
			itemNumber.value = data.data.data[0].item_number
			itemNumberId.value = data.data.data[0].item_id
			itemNumberFront.value = data.data.data[0].item_number
			description.value = data.data.data[0].item_desc
			specNumber.value = data.data.data[0].spec_src_no
			specVersion.value = data.data.data[0].spec_ver
			effectiveDate.value = new Date(data.data.data[0].effective_date)
			uploadDate.value = new Date(data.data.data[0].upload_date)
			pageAdd.value = false
			status.value = data.data.data[0].status
			confirmNote.value = data.data.data[0].confirm_note
			confirm_type.value = data.data.data[0].confirm_type
			if (confirm_type.value === 'Y'){
				radioButton.value = 'digital'
			} else {
				radioButton.value = 'manual'
			}
			labelEdit.value = 'Edit Spec SRC'
			if (!pageAdd.value && data.data.data[0].confirm_date !== null){
				confirmBy.value = data.data.data[0].confirm_by
				confirmDate.value = new Date(data.data.data[0].confirm_date)
			}
			// 
			reload.value++
			getAttachmentFile()
			getAttachmentFileSupp()
		} else {
			vendor_id.value = null
			itemNumber.value = null
			itemNumberFront.value = null
			description.value = null
			specNumber.value = null
			specVersion.value = null
			effectiveDate.value = null
			uploadDate.value = null
			savedFile.value = null;
			savedFileSupp.value = null;
		}
	} else if (idValue.value === 0) {
		// pageAdd.value = true
		vendor_id.value = null
		itemNumber.value = null
		itemNumberFront.value = null
		description.value = null
		specNumber.value = null
		specVersion.value = null
		effectiveDate.value = null
		uploadDate.value = null
		savedFile.value = null;
		savedFileSupp.value = null;
		labelEdit.value = 'Add Spec SRC'
		attachmentName.value = null
		reload.value++
	}

}

const checkTenant = async () => {
	AuthService.whoAmi().then(async (response) => {
		const userCompany = () => {
			const secretKey = 'some-unique-key';
			const simpleCrypto = new SimpleCrypto(secretKey);
			return simpleCrypto.decrypt(
				sessionStorage.getItem('dropdownCompany')
			);
		};
		if (response.status === 200) {
			userInfo.value = response.data.data;
			levelTenant.value = response.data.data.leveltenant;
			if (userInfo.value.leveltenant === 2) {
				//get company
                isSupplierpage.value = true;
				const respCbN = await LovService.getCompanyByName(
					userCompany()
				);
				company.value = respCbN.data.data[0];
				//get supplier
				// 
				const param = {
					db_instance: company.value.db_instance,
					org_id: company.value.value,
				};

				const respVendor = await PoFulfillment.getSuppBindComp(
					response.data.data.id
				);
				// 
				const vendorName = respVendor.data.data[0][0].vendorname;
				const respSuppName = await LovService.getSupplierName(param);
				if (respSuppName.status === 200) {
					//   
					const suppList = respSuppName.data.data;
					for (const s of suppList) {
						if (s.vendor_name === vendorName) {
							supplier.value = s;
							break;
						}
					}
					if (supplier.value === null) {
						routerPage.push('/dashboard')
						window.alert("Connection Unstable, ID not Recognize")
					}
				}
				// 
			} else {
				const respCbN = await LovService.getCompanyByName(
					userCompany()
				);
				company.value = respCbN.data.data[0];
				// 
			}
		}


	});
};

const getSupplier = async (event) => {
	supplierName.value = await event.value
}

const getItemNumber = async (event) => {
	console.log(event)
	if (event.value !== null){
		itemNumber.value = event.item_num
		itemNumberFront.value = event?event.item_num:null
		description.value = event.description
		dialog.value = false
	}
}

const onSelectFile = (res) => {
	fileData.value = res.files[0];
};

const onSelectFileSupp = (res) => {
	fileDataSupp.value = res.files[0];
};


const button = async (status) => {
	if (status === 'B'){
		routerPage.push({name : "SpecSRC"});
	} else {
		buttonValue.value = status;
	}
}

const saveAndCreate = async (id, payload, summary) => {
	try{
		const create = await SpecSrc.saveAndCreateData(
						parseInt(id),
						payload);
			console.log(create)
			if (create == undefined || create.status === null){
				toast.add({
					severity: 'error',
					summary: 'Please try again',
					life: 3000,
				});
				progress.value = false
				submitted.value = false;
            //   setTimeout( async () => {
            //     await saveAndCreate(id, payload, summary)
            //   }, 1000);
            }
			if (create.status === 200 && summary === 'Created'){
				labelEdit.value = 'Edit Spec SRC'
				routerPage.push({
					name: "EditSpecSRC",
					params: {id: create.data.id},
				});
				progress.value = false
				idValue.value = await create.data.id
				await populateData()
				await getAttachmentFile()
				toast.add({
					severity: 'success',
					summary: 'Data '+ summary +'',
					life: 3000,
				});
			} else if(create.status === 200 ){
				toast.add({
					severity: 'success',
					summary: 'Data '+ summary +'',
					life: 3000,
				});
				if (levelTenant.value === 1){
					await populateData()
					await getAttachmentFile()
				} else if (levelTenant.value === 2){
					await populateData()
					await getAttachmentFileSupp()
				}
				progress.value = false
				submitted.value = false;	
			}
			else if(create.status === 400){
				toast.add({
					severity: 'error',
					summary: 'Error PDF',
					life: 3000,
				});
				progress.value = false
			} else if(create.status === 404){
				toast.add({
					severity: 'error',
					summary: 'Error With Decrypt PDF',
					life: 3000,
				});
				progress.value = false
			} else if (create.status === 500 || create.status === 501) {
				toast.add({
					severity: 'error',
					summary: 'Something Wrong',
					life: 3000,
				});
				progress.value = false
			}
			progress.value = false
			submitted.value = false;
	}catch(e){
		console.log(e)
		toast.add({
			severity: 'error',
			summary: 'Please try again',
			life: 3000,
		});
		progress.value = false
		submitted.value = false;
	}
	
}

const handleSubmit = async (isFormValid) => {
    submitted.value = true;
    // 
    if (!isFormValid) {
        toast.add({
            severity: 'error',
            summary: 'All Fields are Required',
            life: 3000,
        });
        return;
    } 
	if (savedFile.value === null && fileData.value === null && levelTenant.value == 1){
		toast.add({
            severity: 'error',
            summary: 'File is Empty',
            life: 3000,
        });
		return;             
	}
	if (savedFileSupp.value === null && fileDataSupp.value === null && levelTenant.value == 2 && radioButton.value === 'manual'){
		toast.add({
            severity: 'error',
            summary: 'File is Empty',
            life: 3000,
        });
		return;             
	}
	else if (buttonValue.value === 'C'){
		// try{
			progress.value = true;
			const formData = new FormData();
			formData.append('file', fileData.value);
			const payload = {
				email: {
					module: "specsrc",
					idmodule: 2,
					sbjmessage:
						"Submit Spec SRC # from ",
					notif_type: "Purchasing",
					usersenderid: userInfo.value.id,
					sender: userInfo.value.bioemailactive,
					statusNotif: "Submit",
					dbInstance: company.value.db_instance,
					companyname: company.value.name,
					vendorname: supplierName.value.vendor_name,
					},
				db_instance : company.value.db_instance,
				condition : 'CREATE',
				company : company.value,
				supplier_name : supplierName.value,
				vendor_id : supplierName.value.vendor_id,
				item_number : itemNumber.value,
				item_number_id : itemNumberId.value?itemNumberId.value:null,
				item_description : description.value?description.value:'',
				spec_number : specNumber.value,
				spec_version : specVersion.value,
				effective_date : formatDateDexa(effectiveDate.value),
				upload_date : formatDateDexa(uploadDate.value),
				fileNameOld: fileNameOld.value ? fileNameOld.value : null,
				status : 'OPEN',
				date : formatDateDexa(new Date()),
				user_id : userInfo.value.id,
			}
			const jsonpayload = JSON.stringify(payload);
			formData.append('payload', jsonpayload);
			// 
			submitted.value = false;
			try {
				await saveAndCreate(idValue.value, formData, 'Created')
			} catch (e) {
				console.log(e)
				await saveAndCreate(idValue.value, formData, 'Created')
			}
			// const create = await SpecSrc.saveAndCreateData(
			// 			parseInt(idValue.value),
			// 			formData
			// 		);
			// if (create.status === 200){
			// 	// toast.add({
			// 	// 	severity: 'success',
			// 	// 	summary: 'Data Created',
			// 	// 	life: 3000,
			// 	// });
			// 	labelEdit.value = 'Edit Spec SRC'
			// 	routerPage.push({
			// 		name: "EditSpecSRC",
			// 		params: {id: create.data.id},
			// 	});
			// 	progress.value = false
			// 	idValue.value = create.data.id
			// 	await populateData()
			// 	await getAttachmentFile()
			// 	toast.add({
			// 		severity: 'success',
			// 		summary: 'Data Created',
			// 		life: 3000,
			// 	});
			// } else {
			// 	progress.value = false
			// 	toast.add({
			// 		severity: 'error',
			// 		summary: 'Something Wrong',
			// 		life: 3000,
			// 	});
			// 	submitted.value = false;
			// }

		// } catch (e) {
		// 	toast.add({
		// 		severity: 'error',
		// 		summary: 'Disconnected',
		// 		detail: 'internet is unstable',
		// 		life: 3000,
		// 	});
		// 	location.reload()
		// }
		
	} else if (buttonValue.value === 'S'){
		// try {
			progress.value = true;
			const formData = new FormData();
			let payload = null
			if (levelTenant.value === 1){
				formData.append('file', fileData.value);
				payload = {
					condition : 'SAVE',
					id : idValue.value,
					supplier_name : supplierName.value?supplierName.value:null,
					item_number : itemNumber.value?itemNumber.value:null,
					item_number_id : itemNumber.value.item_id?itemNumber.value.item_id:null,
					vendor_id : supplierName.value.vendor_id?supplierName.value.vendor_id:null,
					item_description : description.value?description.value:'',
					spec_number : specNumber.value?specNumber.value:null,
					spec_version : specVersion.value?specVersion.value:null,
					effective_date : effectiveDate.value?formatDateDexa(effectiveDate.value):new Date(),
					upload_date : uploadDate.value?formatDateDexa(uploadDate.value):new Date(),
					fileNameOld: fileNameOld.value ? fileNameOld.value : null,
					status : status.value,
					levelTenant : parseInt(sessionStorage.getItem("leveltenant")),
					date : new Date(),
					user_id : userInfo.value.id,
				}
			} else if (levelTenant.value === 2){
				formData.append('file', fileDataSupp.value);
				payload = {
					condition : 'SAVE',
					vendor_id : supplierName.value.vendor_id?supplierName.value.vendor_id:null,
					id : idValue.value,
					confirm_note : confirmNote.value?confirmNote.value:'',
					confirm_by: confirmBy.value ? confirmBy.value : null,
					confirm_date: confirmDate.value ? formatDateDexa(confirmDate.value) : null,
					confirm_option: radioButton.value,
					fileNameOld: fileNameSuppOld.value ? fileNameSuppOld.value : null,
					status : status.value,
					date : new Date(),
					levelTenant : parseInt(sessionStorage.getItem("leveltenant")),
					user_id : userInfo.value.id,
				}
			}
			const jsonpayload = JSON.stringify(payload);
			formData.append('payload', jsonpayload);
			submitted.value = false;

			try {
				await saveAndCreate(idValue.value, formData, "Saved")
			} catch (e) {
				console.log(e)
				await saveAndCreate(idValue.value, formData, "Saved")
			}

			// const save = await SpecSrc.saveAndCreateData(parseInt(idValue.value),formData, "Saved");
			// if (save.status === 200){
			// 	toast.add({
			// 		severity: 'success',
			// 		summary: 'Data Saved',
			// 		life: 3000,
			// 	});
			// 	if (levelTenant.value === 1){
			// 		await getAttachmentFile()
			// 	} else if (levelTenant.value === 2){
			// 		await getAttachmentFileSupp()

			// 	}
			// } else if(save.status === 400){
			// 	toast.add({
			// 		severity: 'error',
			// 		summary: 'Error PDF',
			// 		life: 3000,
			// 	});
			// } else if(save.status === 404){
			// 	toast.add({
			// 		severity: 'error',
			// 		summary: 'Error With Decrypt PDF',
			// 		life: 3000,
			// 	});
			// } else {
			// 	toast.add({
			// 		severity: 'error',
			// 		summary: 'Something Wrong',
			// 		life: 3000,
			// 	});
			// }
			// progress.value = false
			// submitted.value = false;	
		// } catch (e) {
		// 	toast.add({
		// 		severity: 'error',
		// 		summary: 'Disconnected',
		// 		detail: 'internet is unstable',
		// 		life: 3000,
		// 	});
		// 	location.reload()
		// }
		
	} else if (buttonValue.value === 'ST'){
		// try {
		progress.value = true;
		const formData = new FormData();
		formData.append('file', fileDataSupp.value);
		const payload = {
			email: {
					module: "specsrc",
					idmodule: 2,
					sbjmessage:
						"Submit Spec SRC # from ",
					notif_type: "Supplier",
					usersenderid: userInfo.value.id,
					sender: userInfo.value.bioemailactive,
					statusNotif: "Submit",
					dbInstance: company.value.db_instance,
					companyname: company.value.name,
					vendorname: supplierName.value.vendor_name,
					},
			condition : 'SUBMIT CONF',
			supplier_name : supplierName.value?supplierName.value:null,
			item_number : itemNumber.value?itemNumber.value:null,
			item_description : description.value?description.value:'',
			spec_number : specNumber.value?specNumber.value:null,
			spec_version : specVersion.value?specVersion.value:null,
			effective_date : effectiveDate.value?formatDateDexa(effectiveDate.value):new Date(),
			upload_date : uploadDate.value?formatDateDexa(uploadDate.value):new Date(),
			vendor_id : supplierName.value.vendor_id?supplierName.value.vendor_id:null,
			id : idValue.value,
			confirm_note : confirmNote.value?confirmNote.value:'',
			confirm_by: confirmBy.value ? confirmBy.value : null,
			confirm_date:  confirmDate.value ? formatDateDexa(confirmDate.value) : null,
			confirm_option: radioButton.value,
			fileNameOld: fileNameSuppOld.value ? fileNameSuppOld.value : null,
			status : 'CONFIRMED',
			date : new Date(),
			levelTenant : parseInt(sessionStorage.getItem("leveltenant")),
			user_id : userInfo.value.id,
		}
		const jsonpayload = JSON.stringify(payload);
		formData.append('payload', jsonpayload);
		// 
		submitted.value = false;

		try {
			await saveAndCreate(idValue.value, formData, "Confirmed")
		} catch (e) {
			console.log(e)
			await saveAndCreate(idValue.value, formData, "Confirmed")
		}

		// const submit = await SpecSrc.saveAndCreateData(
		// 			parseInt(idValue.value),
		// 			formData, "Submitted"
		// 		);
		// if (submit.status === 200){
		// 		toast.add({
		// 			severity: 'success',
		// 			summary: 'Data Submit Success',
		// 			life: 3000,
		// 		});
		// 		if (levelTenant.value === 1){
		// 			await populateData()
		// 			await getAttachmentFile()
		// 		} else if (levelTenant.value === 2){
		// 			await populateData()
		// 			await getAttachmentFileSupp()
		// 		}
		// 	} else if(submit.status === 400){
		// 		toast.add({
		// 			severity: 'error',
		// 			summary: 'Error PDF',
		// 			life: 3000,
		// 		});
		// 	} else if(submit.status === 404){
		// 		toast.add({
		// 			severity: 'error',
		// 			summary: 'Error With Decrypt PDF',
		// 			life: 3000,
		// 		});
		// 	} else {
		// 		toast.add({
		// 			severity: 'error',
		// 			summary: 'Something Wrong',
		// 			life: 3000,
		// 		});
		// 	}
		// 	progress.value = false
		// 	submitted.value = false;
		// } catch (e) {
		// 	toast.add({
		// 		severity: 'error',
		// 		summary: 'Disconnected',
		// 		detail: 'internet is unstable',
		// 		life: 3000,
		// 	});
		// 	location.reload()
		// }
	} else if (buttonValue.value === 'SC'){
		// try {
			progress.value = true;
		const formData = new FormData();
		formData.append('file', fileData.value);
		const payload = {
			condition : 'SAVE',
			supplier_name : supplierName.value?supplierName.value:null,
			item_number : itemNumber.value?itemNumber.value:null,
			item_number_id : itemNumber.value.item_id?itemNumber.value.item_id:null,
			item_description : description.value?description.value:'',
			spec_number : specNumber.value?specNumber.value:null,
			spec_version : specVersion.value?specVersion.value:null,
			effective_date : effectiveDate.value?formatDateDexa(effectiveDate.value):new Date(),
			upload_date : uploadDate.value? formatDateDexa(uploadDate.value):new Date(),
			vendor_id : supplierName.value.vendor_id?supplierName.value.vendor_id:null,
			id : idValue.value,
			confirm_by: confirmBy.value ? confirmBy.value : null,
			confirm_date: confirmDate.value ? formatDateDexa(confirmDate.value) : null,
			confirm_option: radioButton.value,
			fileNameOld: fileNameOld.value ? fileNameOld.value : null,
			status : 'CLOSED',
			date : new Date(),
			levelTenant : parseInt(sessionStorage.getItem("leveltenant")),
			user_id : userInfo.value.id,
		}
		const jsonpayload = JSON.stringify(payload);
		formData.append('payload', jsonpayload);
		// 
		submitted.value = false;

		try {
			await saveAndCreate(idValue.value, formData, "Submitted")
		} catch (e) {
			console.log(e)
			await saveAndCreate(idValue.value, formData, "Submitted")
		}

		// const submit = await SpecSrc.saveAndCreateData(
		// 			parseInt(idValue.value),
		// 			formData, "Submit"
		// 		);
		// if (submit.status === 200){
		// 		toast.add({
		// 			severity: 'success',
		// 			summary: 'Data Saved',
		// 			life: 3000,
		// 		});
		// 		if (levelTenant.value === 1){
		// 			await getAttachmentFile()
		// 			await populateData()
		// 		} else if (levelTenant.value === 2){
		// 			await getAttachmentFileSupp()
		// 			await populateData()
		// 		}
		// 	} else if(submit.status === 400){
		// 		toast.add({
		// 			severity: 'error',
		// 			summary: 'Error PDF',
		// 			life: 3000,
		// 		});
		// 	} else if(submit.status === 404){
		// 		toast.add({
		// 			severity: 'error',
		// 			summary: 'Error With Decrypt PDF',
		// 			life: 3000,
		// 		});
		// 	} else {
		// 		toast.add({
		// 			severity: 'error',
		// 			summary: 'Something Wrong',
		// 			life: 3000,
		// 		});
		// 	}
		// 	progress.value = false
		// 	submitted.value = false;
		// } catch (e) {
		// 	toast.add({
		// 		severity: 'error',
		// 		summary: 'Disconnected',
		// 		detail: 'internet is unstable',
		// 		life: 3000,
		// 	});
		// 	location.reload()
		// }
	} else if (buttonValue.value === 'R'){
		// try {
		progress.value = true;
		const formData = new FormData();
		formData.append('file', fileData.value);
		const payload = {
			email: {
					module: "specsrc",
					idmodule: 2,
					sbjmessage:
						"Submit Spec SRC # from ",
					notif_type: "PurchasingCancel",
					usersenderid: userInfo.value.id,
					sender: userInfo.value.bioemailactive,
					statusNotif: "Cancel",
					dbInstance: company.value.db_instance,
					companyname: company.value.name,
					vendorname: supplierName.value.vendor_name,
					},
			condition : 'CANCELLED',
			supplier_name : supplierName.value?supplierName.value:null,
			item_number : itemNumber.value?itemNumber.value:null,
			item_number_id : itemNumber.value.item_id?itemNumber.value.item_id:null,
			item_description : description.value?description.value:'',
			spec_number : specNumber.value?specNumber.value:null,
			spec_version : specVersion.value?specVersion.value:null,
			effective_date : effectiveDate.value?formatDateDexa(effectiveDate.value):new Date(),
			upload_date : uploadDate.value?formatDateDexa(uploadDate.value):new Date(),
			vendor_id : supplierName.value.vendor_id?supplierName.value.vendor_id:null,
			id : idValue.value,
			confirm_by: confirmBy.value ? confirmBy.value : null,
			confirm_date: confirmDate.value ? formatDateDexa(confirmDate.value) : null,
			confirm_option: radioButton.value,
			fileNameOld: fileNameOld.value ? fileNameOld.value : null,
			status : 'CANCELLED',
			date : new Date(),
			levelTenant : parseInt(sessionStorage.getItem("leveltenant")),
			user_id : userInfo.value.id,
		}
		const jsonpayload = JSON.stringify(payload);
		formData.append('payload', jsonpayload);
		// 
		submitted.value = false;

		try {
			await saveAndCreate(idValue.value, formData, "Cancelled")
		} catch (e) {
			console.log(e)
			await saveAndCreate(idValue.value, formData, "Cancelled")
		}

		// const submit = await SpecSrc.saveAndCreateData(
		// 			parseInt(idValue.value),
		// 			formData, "Cancelled"
		// 		);
		// if (submit.status === 200){
		// 		toast.add({
		// 			severity: 'success',
		// 			summary: 'Data Saved',
		// 			life: 3000,
		// 		});
		// 		if (levelTenant.value === 1){
		// 			await getAttachmentFile()
		// 			await populateData()
		// 		} else if (levelTenant.value === 2){
		// 			await getAttachmentFileSupp()
		// 			await populateData()
		// 		}
		// 	} else if(submit.status === 400){
		// 		toast.add({
		// 			severity: 'error',
		// 			summary: 'Error PDF',
		// 			life: 3000,
		// 		});
		// 	} else if(submit.status === 404){
		// 		toast.add({
		// 			severity: 'error',
		// 			summary: 'Error With Decrypt PDF',
		// 			life: 3000,
		// 		});
		// 	} else {
		// 		toast.add({
		// 			severity: 'error',
		// 			summary: 'Something Wrong',
		// 			life: 3000,
		// 		});
		// 	}
		// 	progress.value = false
		// 	submitted.value = false;
		// } 
		// catch (e) {
		// 	toast.add({
		// 		severity: 'error',
		// 		summary: 'Disconnected',
		// 		detail: 'internet is unstable',
		// 		life: 3000,
		// 	});
		// 	location.reload()
		// }
		
	}
};

const getAttachmentFile = async () => {
	const res = await SpecSrc.getAttachmentFile(idValue.value);
	if (res == undefined || res.status === null){
              setTimeout( async () => {
                await getAttachmentFile()
              }, 1000);
            }
	else if (res.status === 200) {
		if (res.data.data.spec_file_name == null) {
			savedFile.value = null;
			attachmentUrl.value = null;
			attachmentName.value = null;
			fileName.value = null;
		} else {
			savedFile.value = await res.data.data;
			attachmentUrl.value = await res.data.data.url;
			attachmentName.value = await res.data.data.spec_file_name;
			fileName.value = await res.data.data.spec_file;
			fileNameOld.value = await res.data.data.spec_file;
			// 
		}
	} else {
		toast.add({
			severity: 'Error File',
			summary: 'Unable to Download File',
			life: 3000,
		});
	}
};

const getAttachmentFileSupp = async () => {
	const res = await SpecSrc.getAttachmentFileSupp(idValue.value);
	if (res == undefined || res.status === null){
              setTimeout( async () => {
                await getAttachmentFileSupp()
              }, 1000);
            }
	else if (res.status === 200) {
		if (res.data.data.conf_spec_file_name === null) {
			savedFileSupp.value = null;
			attachmentUrlSupp.value = null;
			attachmentNameSupp.value = null;
			fileNameSupp.value = null;
		} else {
			savedFileSupp.value = await res.data.data;
			attachmentUrlSupp.value = await res.data.data.url;
			attachmentNameSupp.value = await res.data.data.conf_spec_file_name;
			fileNameSupp.value = await res.data.data.conf_spec_file;
			fileNameSuppOld.value = await res.data.data.conf_spec_file;
			// 
		}
	} else {
		toast.add({
			severity: 'Error File',
			summary: 'Unable to Download File',
			life: 3000,
		});
	}
};

const findItemNumber = () => {
	dialog.value = true;
};

const viewHistory = () => {
	dialogHistory.value = true;
};

const deleteFilePur = () => {
	attachmentName.value = null;
}

const deleteFileSupp = () => {
	attachmentNameSupp.value = null;
}

const onDownload = async () => {
      loadingFilePur.value = true;
      const payload = {
        id: parseInt(idValue.value),
        orgId : parseInt(company.value.value),
        leveltenant: parseInt(sessionStorage.getItem("leveltenant")),
        vendorId : vendor_id.value,
      }; 
      try {
		const response = await SpecSrc.downloadFileForPur(payload);
		if (response == undefined || response.status === null){
			setTimeout( async () => {
                await onDownload() 
			}, 1000);
		}
		else if (response.status === 200) {
			soFileBuffer.value = response.data.data;
		} else {
			toast.add({
			severity: "error",
			summary: "Connection Unstable !",
			life: 3000,
			});
			loadingFilePur.value = false;
			throw Error("Error downloading file");
		}
		} catch (error) {
			toast.add({
			severity: "error",
			summary: "Connection Unstable !",
			life: 3000,
			});
			loadingFilePur.value = false;
			throw Error("Error downloading file");
		}
      
      const linkSource = `data:application/pdf;base64,${soFileBuffer.value}`;
      const downloadLink = document.createElement("a");
      const fileName = attachmentName.value;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      loadingFilePur.value = false;
    }

const onDownloadSupp = async () => {
      loadingFilePur.value = true;
      const payload = {
        id: parseInt(idValue.value),
        orgId : parseInt(company.value.value),
        leveltenant: parseInt(sessionStorage.getItem("leveltenant")),
        vendorId : vendor_id.value,
      }; 
      try {
		const response = await SpecSrc.downloadFileForSupp(payload);
	if (response == undefined || response.status === null){
			setTimeout( async () => {
			await onDownloadSupp()
			}, 1000);
		}
      if (response.status === 200) {
        soFileBuffer.value = response.data.data;
      } else {
        toast.add({
          severity: "error",
          summary: "Connection Unstable !",
          life: 3000,
        });
        loadingFilePur.value = false;
        throw Error("Error downloading file");
      }

      } catch (error) {
        toast.add({
          severity: "error",
          summary: "Connection Unstable !",
          life: 3000,
        });
        loadingFilePur.value = false;
        throw Error("Error downloading file");
      }
      
      const linkSource = `data:application/pdf;base64,${soFileBuffer.value}`;
      const downloadLink = document.createElement("a");
      const fileName = attachmentNameSupp.value;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      loadingFilePur.value = false;
    }


function formatDateDexa(datee) {
	var date = new Date(datee);
	var respondDate =
		('00' + (date.getMonth() + 1)).slice(-2) +
		'/' +
		('00' + date.getDate()).slice(-2) +
		'/' +
		date.getFullYear() +
		' ' +
		('00' + date.getHours()).slice(-2) +
		':' +
		('00' + date.getMinutes()).slice(-2) +
		':' +
		('00' + date.getSeconds()).slice(-2);
	return respondDate.replace('-', '');
}

</script>
