<template>
  <div class="grid">
    <ProgressModal :progress="progress">
      <template #message>
        <strong>{{ modalText }}</strong>
        <br />Reloading page...
      </template>
    </ProgressModal>
    <Toast />
    <div class="col-12">
      <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
    </div>
    <div class="col-12">
      <div class="card">
        <!-- <h5>User</h5> -->
        <div class="flex flex-row justify-content-between">
          <div class="col flex-column align-item-center log:col-auto">
            <span>
              <DropdownIncoterm
                @passFOB="showFOB"
                v-model="incoterm"
                :drop-incoterm="dropIncoterm"
                :key="incotermComponent"
                class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
              />
            </span>
            <!-- <span>
              <Button label="Search" class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0" @click="searchButton" />
            </span> -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-4">
      <div class="card">
        <div class="flex flex-row justify-content-between">
          <div class="col flex-column align-item-center log:col-auto">
            <span>
              <Button
                :label="editBtn"
                :icon="editIcon"
                class="p-button-primary"
                @click="addRow()"
                :disabled="!addRowBtn"
              />
              <!-- <Button :label="addEdit" :icon="addEditIcon" class="p-button-primary" @click="addRow()" :disabled="!addRowBtn" /> -->
            </span>
            <span style="float: right;">
              <Button
                :label="cancelRefresh"
                class="p-button-secondary mr-3 uppercase"
                @click="cancelEdit()"
                :disabled="!addRowBtn"
              />
              <Button
                label="Delete"
                class="p-button-secondary mr-3 uppercase"
                @click="deleteData()"
                :disabled="buttonOff"
              />
              <Button
                label="Save"
                class="mr-3 uppercase"
                @click="saveData()"
                :disabled="buttonOff"
              />
            </span>
          </div>
        </div>
        <div style="text-align: right;"></div>
        <br />
        <DocumentTable
          :new-search="newSearch"
          :latest-row="latestRow"
          :new-row="newRow"
          @rowInsert="rowInsert"
          @addMode="addMode"
          @passRows="getRows"
          @passRowsB="getRowsB"
          :column="column ? column.col : null"
          :columnSearch="columnSearch"
          :company="company ? company : null"
          :supplier="supplier ? supplier : null"
          :key="reload"
          :inputError="inputError"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref } from "vue";
import ProgressModal from "@/components/components/ProgressModal.vue";
import DropdownIncoterm from "@/components/documentsetup/components/dropdownIncoterm";
import DocumentTable from "@/components/documentsetup/components/documentTable";
// import { deleteRows } from "@/service/DocsetupService";
import { updateTable, deleteRows } from "@/service/DocsetupService";
import { useToast } from "primevue/usetoast";

const breadcrumbHome = {
  icon: "pi pi-home",
  to: "/dashboard",
};
const breadcrumbItems = [
  {
    label: "Document Setup",
    to: "/mgm/settings/docsetuplist",
  },
];

let addRowBtn = ref(false);
const addModeStatus = ref();
const cancelRefresh = ref("Refresh");
const buttonOff = ref();
const dropIncoterm = ref();
const newSearch = ref();
const newRow = ref(0);
const addModeCancel = ref(true);
const incoterm = ref();
const incotermComponent = ref(0);
const column = ref();
const columnSearch = ref();
const reload = ref(0);
const toast = useToast();
const toastText = ref();
const toastTextErr = ref();
const payloadSave = ref([]);
const latestRow = ref([]);
const progress = ref(false);
const modalText = ref();
const editBtn = ref("Edit");
const editIcon = ref("pi pi-pencil");
const refreshNewSearch = ref();
const inputError = ref(null);

onMounted(async () => {
  console.clear();
  buttonOff.value = true;
  addModeStatus.value = 0;
});
const getRows = async (rows) => {
  payloadSave.value = rows;
  dropIncoterm.value = false;
  toastText.value = "Entering Add/Edit Mode";
  buttonOff.value = false;
  if (payloadSave.value !== [] && addModeStatus.value !== 1) {
    addModeStatus.value = 1;
    await toastInfo();
  }
};
const getRowsB = async (rows) => {
  payloadSave.value = rows;
  if (payloadSave.value.length === 0) {
    buttonOff.value = true;
    payloadSave.value = [];
  }
};
const showFOB = async (fob) => {
  newSearch.value = fob.ASN_FOB;
  addRowBtn.value = true;
  refreshNewSearch.value = fob.ASN_FOB;
};
const addMode = async () => {
};
const rowInsert = async () => {
};
const addRow = async () => {
  editBtn.value = "Add Row";
  editIcon.value = "pi pi-plus";
  cancelRefresh.value = "Cancel";
  buttonOff.value = true;
  addModeCancel.value = false;
  dropIncoterm.value = false;
  addModeStatus.value = 1;
  newRow.value++;
  if (payloadSave.value.length === 0) {
    toastText.value = "New row added";
    await toastInfo();
  }
};
const saveData = async () => {
  // Reset input error
  inputError.value = null;

  let rowExist = sessionStorage.getItem("selectedRowsDocSetup");
  if (rowExist === "[]") {
    toastText.value = "Please Select a Row";
    await toastInfo();
  } else {
    let newData = [];
    let oldData = [];
    var regExp = /[a-zA-Z0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    for (const a in payloadSave.value) {
      // const lineCheck = (payloadSave.value[a].ASN_LINE_NUM).toString().replace(/ /g,'')
      // const descCheck = (payloadSave.value[a].ASN_DOC_DESC).toString().replace(/ /g,'')
      // if(lineCheck === ''|| descCheck === '') {
      //   alert('Line or Description cannot be blank')
      //   break
      // }
      let gG = payloadSave.value[a].ASN_DOC_DESC;
      const result = await stringChecking(gG);
      if (result.alter !== null) {
        payloadSave.value[a].ASN_DOC_DESC = result.alter;
      }
      if (result.status === 200) {
        if (payloadSave.value[a].ASN_SETUP_ID !== null) {
          oldData.push(JSON.parse(JSON.stringify(payloadSave.value[a])));
        } else if (
          regExp.test(payloadSave.value[a].ASN_DOC_DESC) === true &&
          payloadSave.value[a].ASN_SETUP_ID === null
        ) {
          newData.push(JSON.parse(JSON.stringify(payloadSave.value[a])));
        } else {
          // delete payloadSave.value[a];
          if (
            payloadSave.value.some(
              (item) => !item.ASN_LINE_NUM || !item.ASN_DOC_DESC
            )
          ) {
            inputError.value = payloadSave.value
              .filter((item) => !item.ASN_LINE_NUM || !item.ASN_DOC_DESC)
              .map((item) => {
                const err = {
                  id: item.id,
                  lineError: null,
                  descriptionError: null,
                };

                if (!item.ASN_LINE_NUM) {
                  err.lineError = "Please enter a line number";
                }

                if (!item.ASN_DOC_DESC) {
                  err.descriptionError = "Please enter a description";
                }

                return err;
              });


            toast.add({
              severity: "error",
              summary: "Required fields cannot be empty",
              life: 3000,
            });

            return;
          }
          payloadSave.value.splice([a], 2);
        }
      }
    }

    // Validate if required fields is empty

    //   let arrayCheckBlank = []
    //   let checkBlank = {
    //     desc: /\s/.test(arr[a].ASN_DOC_DESC),
    //     line: /\s/.test(arr[a].ASN_LINE_NUM)
    const parseOld = JSON.parse(JSON.stringify(oldData));
    const parseNew = JSON.parse(JSON.stringify(newData));
    for (const i in parseOld) {
      if (parseOld[i].NEED_ORI === "No") {
        parseOld[i].NEED_ORI = "N";
      } else if (parseOld[i].NEED_ORI === "Yes") {
        parseOld[i].NEED_ORI = "Y";
      }
      if (parseOld[i].ACTIVE === "No") {
        parseOld[i].ACTIVE = "N";
      } else if (parseOld[i].ACTIVE === "Yes") {
        parseOld[i].ACTIVE = "Y";
      }
    }
    for (const i in parseNew) {
      if (parseNew[i].NEED_ORI === "No") {
        parseNew[i].NEED_ORI = "N";
      } else if (parseNew[i].NEED_ORI === "Yes") {
        parseNew[i].NEED_ORI = "Y";
      }
      if (parseNew[i].ACTIVE === "No") {
        parseNew[i].ACTIVE = "N";
      } else if (parseNew[i].ACTIVE === "Yes") {
        parseNew[i].ACTIVE = "Y";
      }
    }
    const savePayload = {
      old: parseOld,
      new: parseNew,
    };
    console.info("update row data:: ", parseOld);
    console.info("insert new data:: ", parseNew);
    console.info("processed Data:: ", savePayload);
    const resp = await updateTable(savePayload);
    // let resp = {
    //   status: 200
    // }
    if (resp.status === 200) {
      modalText.value = "Selected rows successfully saved!";
      progress.value = true;
      sessionStorage.removeItem("selectedRowsDocSetup");
      // toast.add({
      //   severity: "success",
      //   summary: "Data successfully saved",
      //   life: 3000,
      // });
      // incotermComponent.value++
      buttonOff.value = true;
      dropIncoterm.value = true;
      addModeStatus.value = 0;
      // incoterm.value = null
      addRowBtn.value = false;
      setTimeout(() => {
        location.reload();
      }, 2000);
    }
  }
};
const stringChecking = async (gG) => {
  var specialChar = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g;
  let newGg = null;
  if (specialChar.test(gG) === true) {
    newGg = gG.replace(specialChar, "");
  }
  const result = {
    status: 200,
    alter: newGg,
  };
  return result;
};
const deleteData = async () => {
  let rowExist = sessionStorage.getItem("selectedRowsDocSetup");
  if (rowExist === "[]") {
    toastText.value = "Please Select a Row";
    await toastInfo();
  } else {
    let thisRow = payloadSave.value;
    for (const i in thisRow) {
      let arrayLatestRow = [];
      if (thisRow[i].draftNewRow === 1 && rowExist !== null) {
        toastText.value = "Rows Deleted";
        arrayLatestRow.push(thisRow[i].id);
        // sessionStorage.removeItem('selectedRowsDocSetup')
        setTimeout(() => {
          latestRow.value = arrayLatestRow;
        }, 50);
      } else {
        const resp = await deleteRows(payloadSave.value);
        if (resp.status === 200) {
          modalText.value = "Pre-existing rows included for deletion.";
          progress.value = true;
          sessionStorage.removeItem("selectedRowsDocSetup");
          buttonOff.value = true;
          dropIncoterm.value = true;
          addModeStatus.value = 0;
          // incoterm.value = null
          addRowBtn.value = false;
          // console.clear()
          setTimeout(() => {
            location.reload();
            // newSearch.value = filter.data[0].ASN_FOB
          }, 2500);
        } else {
          toastTextErr = "Connection error";
          await toastErr();
        }
      }
    }
    await toastInfo();
  }
};
const cancelEdit = async () => {
  newSearch.value = null;
  editBtn.value = "Edit";
  editIcon.value = "pi pi-pencil";
  sessionStorage.removeItem("selectedRowsDocSetup");
  newRow.value = 0;
  incoterm.value = null;
  dropIncoterm.value = true;
  addModeCancel.value = true;
  cancelRefresh.value = "Refresh";
  addRowBtn.value = false;
  buttonOff.value = true;
  addModeStatus.value = 0;
  if (payloadSave.value.length > 0) {
    toastText.value = "Cancel Changes";
    await toastInfo();
  }
  incotermComponent.value++;
  reload.value++;
};
const toastInfo = async () => {
  toast.add({
    severity: "info",
    summary: toastText.value,
    life: 3000,
  });
};
const toastErr = async () => {
  toast.add({
    severity: "info",
    summary: toastTextErr.value,
    life: 3000,
  });
};
</script>
