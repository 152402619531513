<template>
	<div class="grid">
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<!-- Content -->
		<CreateInvoiceWPOPrepaymentPur
			v-if="currentUser"
			title="Create E-Invoice"
			:currentUser="currentUser"
			:id="id"
		/>
	</div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute, } from 'vue-router';
import AuthService from '../../service/AuthService';
import CreateInvoiceWPOPrepaymentPur from '../components/CreateInvoiceWPOPrepaymentPur.vue';


// const router = useRouter();
const route = useRoute();

const currentUser = ref(null);
const breadcrumbHome = ref({ icon: 'pi pi-home', to: '/dashboard' });
const breadcrumbItems = ref([
	{
		label: 'Create Invoice with PO Prepayment (Purchasing)',
		to: route.fullPath,
	},
]);
const id = ref(null);

onMounted(async () => {
	const resAuth = await AuthService.whoAmi();
	currentUser.value = resAuth.data.data;

	if(route.query.v){
		id.value = route.params.id
	}
	// Check if user is not supplier nor admin
	// if (
	// 	currentUser.value.leveltenant !== 2 &&
	// 	currentUser.value.userid !== 'dexaadmin'
	// ) {
	// 	// Redirect to homepage if user is purchasing and not admin
	// 	router.push('/dashboard');
	// }
});
</script>
