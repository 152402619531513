<template>
  <AutoComplete
    :suggestions="filteredSuppliers"
    @complete="searchSupplier($event)"
    :dropdown="true"
    field="vendor_name"
    :placeholder="placeholder"
    forceSelection
  >
    <template #item="slotProp">
      {{ slotProp.item.vendor_name }}
    </template>
  </AutoComplete>
</template>

<script setup>
import { onMounted, ref } from "vue";
import AuthService from "@/service/AuthService";
// import LovService from "@/service/LovService";
import CreateBatchServices from "../../../service/CreateBatchServices";

// eslint-disable-next-line no-undef
const props = defineProps({
  company: {
    type: Object,
  },
});
// eslint-disable-next-line no-undef
const filteredSuppliers = ref([]);
const suppliers = ref([]);
const placeholder = ref("Select a supplier");

onMounted(async () => {
  populate();
});

const populate = () => {
  AuthService.whoAmi().then(async (response) => {
    if (response.data.data.leveltenant === 3 || props.company) {
      const payload = {
        idUser: response.data.data.id,
        companyname: props.company ? props.company.name : null
      };
      if (response.status === 200) {
       await CreateBatchServices.getListSupplier(payload).then((response) => {
          const type = typeof response.data.data;
          if (type === "string") {
            placeholder.value = "No supplier found";
          } else {
            suppliers.value = response.data.data;
          }
        });
      }
    }
  });
};

const searchSupplier = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredSuppliers.value = [...suppliers.value];
    } else {
      filteredSuppliers.value = suppliers.value.filter((supp) => {
        if (supp.vendor_name === null || supp.vendor_name === undefined) {
          return supp;
        }
        return supp.vendor_name
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};
</script>
