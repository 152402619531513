<template>
	<span class="p-float-label">
		<AutoComplete
			:suggestions="filteredCat"
			@complete="searchCat($event)"
			:dropdown="true"
			field="category_detail"
			v-model="detailCatValue"
			forceSelection
		>
			<template #item="slotProp">
				{{ slotProp.item.category_detail }}
			</template>
		</AutoComplete>
		<label for="autocomplete">Detail Category</label>
	</span>
</template>

<script setup>
import {
	onMounted,
	ref,
	defineEmits,
	defineExpose,
	watch,
	watchEffect,
} from 'vue';
import AuthService from '../../../service/AuthService';
import SearchInvoiceProxyNonPO from '../../../service/InvoiceProxyService';

// eslint-disable-next-line no-undef
const props = defineProps({
	company: { type: Object },
	data: { type: Object },
	db_instance: { type: Object },
	org_id: { type: Object },
	ref_num: { type: Object },
	categoryId: { type: Object },
	disabled: {
		type: Boolean,
	},
	supplier: { type: Object },
});

// eslint-disable-next-line no-undef
const filteredCat = ref([]);
const detailCat = ref([]);
const detailCatValue = ref();
const forwarder = ref(true);
const emit = defineEmits(['detailCat']);

onMounted(() => {
	populateData();
});

watch(
	() => props.db_instance,
	(newValue) => {
		

		// 
		if (newValue !== null) {
			// if (!forwarder.value){
			populateData();
			// }
		}
	}
);

watchEffect(() =>
	// 
	emit('detailCat', detailCatValue)
);

const populateData = () => {
	AuthService.whoAmi().then(async (response) => {
		// 
		if (response.status === 200) {
			if (props.categoryId !== null) {
				const payload = {
					categoryId: props.categoryId ? props.categoryId : null,
					dbInstance: props.db_instance ? props.db_instance : null,
					orgId: props.org_id ? props.org_id : null,
					vendorId: props.supplier ? props.supplier : null,
				};
				if (response.status === 200) {
					const data = await SearchInvoiceProxyNonPO.getDetailCat(
						payload
					);
					// 
					data.data.data.data.categoryHeader.forEach((element) => {
						if (
							element.category_name
								.toLowerCase()
								.includes('forwarder')
						) {
							forwarder.value = false;
						}
					});
					// 
					if (forwarder.value) {
						detailCat.value = await data.data.data.data
							.categoryDetail;
					} else {
						const payloadSK = {
							categoryId: props.categoryId
								? props.categoryId
								: null,
							dbInstance: props.db_instance
								? props.db_instance
								: null,
							orgId: props.org_id ? props.org_id : null,
							skNumber: props.ref_num ? props.ref_num : null,
						};
						const res =
							await SearchInvoiceProxyNonPO.getDetailCatSK(
								payloadSK
							);
						// 
						detailCat.value = await res.data.data.data;
					}
					emit('detailCat', detailCatValue);
				}
			}
		}
	});
};

defineExpose({
	populateData,
});

const searchCat = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredCat.value = [...detailCat.value];
		} else {
			filteredCat.value = detailCat.value.filter((comp) => {
				return comp.category_detail
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
		}
	}, 250);
};
</script>

<style scoped></style>
