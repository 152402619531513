<template>
  <AutoComplete
    :suggestions="filteredBillBank"
    @complete="searcSupplierSite($event)"
    :dropdown="true"
    field="TRANSFER_TO"
    :placeholder="placeholder"
    v-model="billBank"
    forceSelection
  >
    <template #item="slotProp">
      {{ slotProp.item.TRANSFER_TO }}
    </template>
  </AutoComplete>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect } from "vue";
//   import LovService from "@/service/LovService";
//   import SwithCompany from "@/service/SwithCompany";
// import AuthService from "@/service/AuthService";
import invoiceNonPOservice from "@/service/InvoiceNonPOservice";

// eslint-disable-next-line no-undef
const filteredBillBank = ref([]);
const billBanks = ref([]);
const billBank = ref();
const placeholder = ref("Select a bill bank");

const emit = defineEmits(["billBank"]);

watchEffect(() => emit("billBank", billBank));
// eslint-disable-next-line no-undef
const props = defineProps({
  company: {
    type: Object,
  },
  vendorId: {
    type: String,
  },
  extBankAccountId: {
    type: String,
  },
});

onMounted(async () => {
  try {
    // const response = await AuthService.whoAmi();
    // const user = response.data.data;
    if (props.company && props.vendorId) {
      const result = await invoiceNonPOservice.getListBank(
        props.company.value,
        props.company.db_instance,
        props.vendorId
      );
      billBanks.value = result.data.data;
      if (props.extBankAccountId) {
        for (const billBankData of billBanks.value) {
          if (billBankData.EXT_BANK_ACCOUNT_ID == props.extBankAccountId) {
            billBank.value = billBankData;
          }
        }
      }
    }

    // eslint-disable-next-line no-empty
  } catch (e) {
    throw new Error(e);
  }
});

const searcSupplierSite = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredBillBank.value = [...billBanks.value];
    } else {
      filteredBillBank.value = billBanks.value.filter((bank) => {
        return bank.TRANSFER_TO.toLowerCase().match(
          event.query.toLowerCase()
        );
      });
    }
  }, 250);
};
</script>
