<template>
	<div>
		<Toast />
		<Card>
			<template #title>
				<span class="text-xl">Notification</span>
			</template>
			<template #content>
				<div class="flex justify-content-between">
					<!-- Notif module -->
					<div>
						<p class="font-bold mb-0 mb-4">Status</p>
						<!-- <p v-for="name in notificationModulesName" :key="name">
							{{ name }}
						</p> -->
						<p class="mb-3">Planning Order</p>
						<p class="mb-3">Purchase Order</p>
						<p class="mb-3">Shipment Notice</p>
						<p class="mb-3">CAPA</p>
						<p class="mb-0">Supplier Master</p>
					</div>

					<!-- Open -->
					<div>
						<p class="font-bold mb-0 mb-4 text-green-600">Open</p>
						<!-- This have to following the order of modules name -->
						<p>{{ notificationOpenByPlanningOrder }}</p>
						<p>{{ notificationOpenByPurchaseOrder }}</p>
						<p>{{ notificationOpenByShipmentNotice }}</p>
						<p>{{ notificationOpenByCAPA }}</p>
						<p>{{ notificationOpenBySupplierMaster }}</p>
					</div>

					<!-- Close -->
					<div>
						<p class="font-bold mb-0 mb-4 text-pink-600">Close</p>
						<!-- This have to following the order of modules name -->
						<p>{{ notificationClosedByPlanningOrder }}</p>
						<p>{{ notificationClosedByPurchaseOrder }}</p>
						<p>{{ notificationClosedByShipmentNotice }}</p>
						<p>{{ notificationClosedByCAPA }}</p>
						<p>{{ notificationClosedBySupplierMaster }}</p>
					</div>
				</div>
			</template>

			<template #footer>
				<Button
					label="Link"
					class="p-button-link font-bold"
					@click="openModal"
				>
					<span class="mr-2">See details</span>
					<i class="pi pi-arrow-right"></i>
				</Button>
			</template>
		</Card>

		<!-- Modal -->
		<Dialog
			header="Notification List"
			v-model:visible="displayModal"
			:style="{ width: '80%' }"
			:modal="true"
		>
			<!-- <p class="m-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> -->
			<div class="grid" style="margin-top: 0.5em">
				<div class="col-12 p-fluid">
					<div class="grid formgrid" style="margin: 2em 0 0 0">
						<div class="col-12 mb-5 lg:col-2 lg:mb-0">
							<span class="p-float-label">
								<Dropdown
									id="dropdown"
									:options="isiCompany"
									v-model="company"
									optionLabel="name"
								>
								</Dropdown>
								<label for="dropdown">Company</label>
							</span>
						</div>
						<div class="col-12 mb-5 lg:col-3 lg:mb-0">
							<span class="p-float-label">
								<Dropdown
									id="dropdown"
									:options="modules"
									v-model="search"
									optionLabel="name"
								>
								</Dropdown>
								<label for="dropdown">Module</label>
							</span>
						</div>
						<div class="col-12 mb-2 lg:col-2 lg:mb-0">
							<span class="p-float-label">
								<Dropdown
									id="dropdown"
									style="width: 100%"
									:options="statusOptions"
									v-model="searchStatus"
									optionLabel="name"
								></Dropdown>
								<label for="dropdown">Status</label>
							</span>
						</div>
						<div
							class="col-12 mb-5 lg:col-1 lg:mb-0 call-button-container-100"
						>
							<Button
								label="Go"
								@click="getCompanyPur()"
							></Button>
						</div>
						<div
							class="col-12 mb-5 lg:col-1 lg:mb-0 call-button-container-100"
						>
							<Button
								label="Export"
								class="p-button-warning"
								@click="exportExcel()"
							></Button>
						</div>
					</div>
				</div>
				<DataTable
					:value="filtered ? filtered : notificationsdata"
					v-model:selection="selectedNotifications"
					dataKey="id"
					:paginator="true"
					:rows="5"
					v-model:filters="filters"
					resizableColumns="true"
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
					:rowsPerPageOptions="[5, 10, 25]"
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
					responsiveLayout="stack"
					breakpoint="960px"
					:style="{ width: '100%', padding: '8px' }"
				>
					<template #empty>No notifications found.</template>
					<Column
						v-for="col of notifColumns"
						:field="col.field"
						:header="col.header"
						:key="col.field"
						:sortable="true"
					/>
					<Column header="View">
						<template #body="slotProps">
							<Button
								icon="pi pi-eye"
								class="p-button-rounded p-button mr-2"
								@click="viewDetail(slotProps.data)"
							/>
						</template>
					</Column>
				</DataTable>
			</div>
			<template #footer>
				<!-- <Button label="No" icon="pi pi-times" @click="closeModal" class="p-button-text"/>
                <Button label="Yes" icon="pi pi-check" @click="closeModal" autofocus /> -->
			</template>
		</Dialog>
	</div>
</template>

<script>
// import NotificationsModal from '../modals/NotificationsModal.vue';
import NotificationService from '../../../service/NotificationService';
import AuthService from '../../../service/AuthService';
import LovService from '../../../service/LovService';
// import PoFulfillment from '../../../service/PoFulfillment';
import SimpleCrypto from 'simple-crypto-js';
import XLSX from 'xlsx';

export default {
	components: {},
	props: {
		notifications: Array,
	},
	data() {
		return {
			displayModal: false,
			modules: [
				{
					name: 'ALL',
				},
				{
					name: 'Planning Order',
				},
				{
					name: 'Purchase Order',
				},
				{
					name: 'CAPA',
				},
				{
					name: 'Shipment Notice',
				},
			],
			statusOptions: [
				{
					name: 'ALL',
				},
				{
					name: 'Open',
				},
				{
					name: 'Close',
				},
			],
			filtered: null,
			search: {},
			searchStatus: {},
			notifColumns: [],
			notificationsdata: null,
			notification: {},
			selectedNotifications: null,
			company: null,
			isiCompany: null,
			currentTenantLvl: null,
		};
	},
	created() {
		this.notifColumns = [
			{
				field: 'modulename',
				header: 'Module',
			},
			{
				field: 'sbjmessage',
				header: 'Subject',
			},
			{
				field: 'receiveddate',
				header: 'Received Date',
			},
			{
				field: 'status',
				header: 'Status',
			},
		];
		this.getCompany();
	},
	mounted() {
		this.search.name = 'ALL';
		this.searchStatus.name = 'ALL';
		NotificationService.getAllNotifications().then((response) => {
			if (response.status == 200) {
				this.notificationsdata = response.data.data[0];
			} else {
				this.notificationsdata = [];
			}
		});
	},
	methods: {
		exportExcel() {
			let data = this.filtered ? this.filtered : this.notificationsdata;
			if (data.length !== 0) {
				let today = new Date();
				let dd = String(today.getDate()).padStart(2, '0');
				let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
				let yyyy = today.getFullYear();
				let combinedDate = dd + mm + yyyy;
				let filename = 'Notification List_' + combinedDate + '.xlsx';
				let ws = XLSX.utils.json_to_sheet(data);
				let wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, 'My Worksheet');
				XLSX.writeFile(wb, filename);
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Data is Empty',
					life: 3000,
				});
			}
		},
		getCompany() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					this.currentTenantLvl = value.data.data.leveltenant;
					if (value.data.data.leveltenant == 1) {
						if (value.data.data.userid == 'dexaadmin') {
							LovService.getValueType('COMPANY').then(
								(response) => {
									this.isiCompany = response.data.data;
								}
							);
						} else {
							console.log('User', value.data.data);
							let payload = value.data.data.bioaddress;
							LovService.getcompanydb(payload).then(
								(response) => {
									// this.isiCompany = response.data.data
									// let payload = {isi : response.data.data[0].company}
									// PlanningOrder.getCompanyId(payload).then(response => {
									this.isiCompany = response.data.data;
									try {
										const secretKey = 'some-unique-key';
										const simpleCrypto = new SimpleCrypto(
											secretKey
										);
										const decipherText =
											simpleCrypto.decrypt(
												sessionStorage.getItem(
													'dropdownCompany'
												)
											);

										this.isiCompany.forEach((element) => {
											if (element.name === decipherText) {
												this.company = element;
												this.getCompanyPur();
												throw 'Break';
											}
										});
									} catch (e) {
										if (e !== 'Break') throw e;
									}
									// })
								}
							);
						}
					} else if (value.data.data.leveltenant == 2) {
						let payload = value.data.data.id;
						// PlanningOrder.getCompanySupp(payload).then(
						// 	(response) => {
						// 		let company = [
						// 			{ name: response.data.data[0].company },
						// 		];
						// 		this.isiCompany = company;
						// 	}
						// );
						NotificationService.getCompanySupp(payload).then(
							(response) => {
								let payload = response.data.data;
								let companyList = [];
								try {
									payload.forEach((element) => {
										LovService.getCompanyByName(
											element.company
										).then((response) => {
											companyList.push(
												response.data.data[0]
											);

											const secretKey = 'some-unique-key';
											const simpleCrypto =
												new SimpleCrypto(secretKey);
											const decipherText =
												simpleCrypto.decrypt(
													sessionStorage.getItem(
														'dropdownCompany'
													)
												);
											// let companyList = JSON.parse(sessionStorage.getItem('list'))
											// this.isiCompany = companyList;

											if (
												response.data.data[0].name ===
												decipherText
											) {
												this.company =
													response.data.data[0];
												// this.searchTableNested();
												this.getCompanyPur();
											}
										});
									});
									this.isiCompany = companyList;
								} catch (e) {
									if (e !== 'Break') throw e;
								}
							}
						);
					}
				}
			});
		},
		openModal() {
			this.displayModal = true;
		},
		searchFilter() {
			// this.filtered = this.searchAll();
		},
		getCompanyPur() {
			if (this.company != null) {
				let payload = {
					org_id: this.company.value,
					db_instance: this.company.db_instance,
					module: this.search.name,
					status: this.searchStatus.name,
					leveltenant: 'Purchasing',
				};
				NotificationService.getPurNotificationsSearch(payload).then(
					(response) => {
						if (response.status == 200) {
							this.notificationsdata = response.data.data[0];
						} else {
							this.notificationsdata = [];
						}
					}
				);
			}
		},
		searchAll() {
			if (
				this.search.name === 'ALL' &&
				this.searchStatus.name === 'ALL'
			) {
				this.filtered = null;
			} else if (
				// this.company.name !== null ||
				this.search.name === 'ALL' ||
				this.searchStatus.name === 'ALL'
			) {
				return this.notifications.filter((item) => {
					return (
						item.modulename.match(this.search.name) ||
						item.status.match(this.searchStatus.name)
					);
				});
			} else {
				return this.notifications.filter((item) => {
					return (
						item.modulename.match(this.search.name) &&
						item.status.match(this.searchStatus.name)
					);
				});
			}
		},
		viewDetail(data) {
			let moduletype = data.modulename.toString();
			if (this.currentTenantLvl == 1) {
				if (moduletype == 'Planning Order') {
					this.$router.push({
						name: 'plannodetailpur',
						params: {
							nameModule: data.modulename,
							subjectMessage: data.sbjmessage,
						},
					});
				} else if (moduletype == 'Purchase Order') {
					this.$router.push({
						name: 'podetailpur',
						params: {
							nameModule: data.modulename,
							subjectMessage: data.sbjmessage,
						},
					});
				} else if (moduletype == 'Shipment Notice') {
					this.$router.push({
						name: 'shipmentdetail',
						params: {
							nameModule: data.modulename,
							subjectMessage: data.sbjmessage,
						},
					});
				}
			} else if (this.currentTenantLvl == 2) {
				if (moduletype == 'Planning Order') {
					this.$router.push({
						name: 'plannodetail',
						params: {
							nameModule: data.modulename,
							subjectMessage: data.sbjmessage,
						},
					});
				} else if (moduletype == 'Purchase Order') {
					this.$router.push({
						name: 'podetail',
						params: {
							nameModule: data.modulename,
							subjectMessage: data.sbjmessage,
						},
					});
				}
			}
			if (moduletype == 'CAPA') {
				this.$router.push({
					name: 'capadetailpur',
					params: {
						nameModule: data.modulename,
						subjectMessage: data.sbjmessage,
					},
				});
			}
		},
		closeModal() {
			this.displayModal = false;
		},
		viewPODetail() {
			this.$router.push('/dashboard/notifdetails/podetail');
		},
		viewCapaDetail() {
			this.$router.push('/dashboard/notifdetails/capadetail');
		},
		viewShipmentDetail() {
			this.$router.push('/dashboard/notifdetails/shipmentdetail');
		},
	},
	computed: {
		notificationModulesName() {
			const modulesName = this.notifications.map(
				(notif) => notif.modulename
			);

			const modulesSet = new Set();

			modulesName.forEach((name) => modulesSet.add(name));

			return modulesSet;
		},
		// Open
		notificationOpenByPlanningOrder() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Planning Order' &&
					notif.status === 'Open'
			);
			return notifs.length;
		},
		notificationOpenByPurchaseOrder() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Purchase Order' &&
					notif.status === 'Open'
			);
			return notifs.length;
		},
		notificationOpenByShipmentNotice() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Shipment Notice' &&
					notif.status === 'Open'
			);
			return notifs.length;
		},
		notificationOpenByCAPA() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'CAPA' && notif.status === 'Open'
			);
			return notifs.length;
		},
		notificationOpenBySupplierMaster() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Supplier Master' &&
					notif.status === 'Open'
			);
			return notifs.length;
		},
		// Closed
		notificationClosedByPlanningOrder() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Planning Order' &&
					notif.status === 'Close'
			);
			return notifs.length;
		},
		notificationClosedByPurchaseOrder() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Purchase Order' &&
					notif.status === 'Close'
			);
			return notifs.length;
		},
		notificationClosedByShipmentNotice() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Shipment Notice' &&
					notif.status === 'Close'
			);
			return notifs.length;
		},
		notificationClosedByCAPA() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'CAPA' && notif.status === 'Close'
			);
			return notifs.length;
		},
		notificationClosedBySupplierMaster() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Supplier Master' &&
					notif.status === 'Close'
			);
			return notifs.length;
		},
	},
};
</script>
