import spbKkt from "./api/SpbKktApi";
class UserService {
    retriveProfile(id) {
        const url = 'adm/profile/' + id;
        return spbKkt.get(url);
      }
    
      deleteSession(id) {
        const url = 'adm/profile/deletesession/' + id;
        return spbKkt.get(url);
      }

      putPassword(payload) {
        const url = 'adm/profile/changepassword';
        return spbKkt.put(url, payload);
      }
    
      insertByAdmin(payload) {
        const url = 'adm/umanager/insertbyadmin';
        return spbKkt.post(url, payload);
      }
    
      insertBySuper(payload) {
        const url = 'adm/umanager/insertbysuper';
        return spbKkt.post(url, payload);
      }
    
      updatebySuper(payload) {
        const url = 'adm/umanager/updatebysupperuser';
        return spbKkt.post(url, payload);
      }
      updatebyAdmin(payload) {
        const url = 'adm/umanager/updatebyadmin';
        return spbKkt.post(url, payload);
      }
      updatebyAdminActive(payload) {
        const url = 'adm/umanager/updatebyadminactive';
        return spbKkt.post(url, payload);
      }
      retriveusersPic() {
        const url = 'adm/umanager/retriveuserspic';
        return spbKkt.get(url);
      }
      retriveUsers() {
        const url = 'adm/umanager/retriveusersadmin';
        return spbKkt.get(url);
      }
      retriveEmployeeByCompanyName(payload) {
        const url = 'adm/umanager/retriveemployeebycompany/'+payload;
        return spbKkt.get(url);
      }
    
      retriveallemployee() {
        const url = 'adm/umanager/retriveallemployee/';
        return spbKkt.get(url);
      }
      retriveCompaniesByiduser(payload) {
        const url = 'adm/umanager/retrivecompaniesbyuser/'+payload;
        return spbKkt.get(url);
      }
      retriveUsersById(id) {
        const url = 'adm/umanager/retriveusersbyid/' + id;
        return spbKkt.get(url);
      }
      isuserexist(username) {
        const url = 'adm/umanager/isuserexist/' + username;
        return spbKkt.get(url);
      }
    
      deleteUser(payload) {
        const url = `adm/umanager/deletebysuper/${payload.user.id} `;
        return spbKkt.get(url);
      }
      deleteUserByAdmin(payload) {
        const url = `adm/umanager/deletebyadmin/${payload.user.id} `;
        return spbKkt.get(url);
      }
    
      getAllTenant(){
        const url = `adm/tenants/getAllTenants`;
        return spbKkt.get(url);
      }

      getTenantDbInstance(db_instance){
        const url = '/adm/tenants/getTenantDbInstance/'+db_instance;
        return spbKkt.get(url);
      }

      getSupplierName(){
        const url = '/adm/tenants/getSupplierName/';
        return spbKkt.post(url);
      }
      
    

}
export default new UserService();
