<template>
  <AutoComplete
    :suggestions="filteredBatchNumber"
    @complete="searchBatchArea($event)"
    field="batch_no"
    :placeholder="placeholder"
  >
  </AutoComplete>
</template>
<script setup>
import { ref } from "vue";
// import AuthService from "../../../service/AuthService";
// import CreateBatchServices from "../../../service/CreateBatchServices";

// const isiBatchNumber = ref([]);
// const filteredBatchNumber = ref([]);
const placeholder = ref("Batch Number");

// // eslint-disable-next-line no-undef
// const props = defineProps({
//   company: {
//     type: Object,
//   },
//   supplier: {
//     type: Object,
//   },
// });

// watch(async () => {
//   if (
//     (props.company !== null && props.company !== undefined) ||
//     (props.supplier !== null && props.supplier !== undefined)
//   ) {
//     const payload = {
//       org_id: props.company.value,
//       suppVal: props.supplier? props.supplier.vendor_id.toString() : null,
//     };
//     await AuthService.whoAmi().then((value) => {
//       if (value.status === 200) {
//         CreateBatchServices.getBatchNo(payload).then((response) => {
//           const type = typeof response.data.data[0];
//           if (type === "string") {
//             placeholder.value = "No supplier found";
//           } else {
//             isiBatchNumber.value = response.data.data[0];
//           }
//         });
//       }
//     });
//   }
// });


// const searchBatchArea = (event) => {
//   setTimeout(() => {
//     if (!event.query.trim().length) {
//       filteredBatchNumber.value = [...isiBatchNumber.value];
//     } else {
//       filteredBatchNumber.value = isiBatchNumber.value.filter((item) => {
//         if (item.batch_no === null || item.batch_no === undefined) {
//           return item;
//         }
//         return item.batch_no.toLowerCase().match(event.query.toLowerCase());
//       });
//     }
//   }, 100);
// };
</script>