<template>
  <AutoComplete
    :suggestions="filteredTerms"
    @complete="searchTerms($event)"
    :dropdown="true"
    field="term_name"
    forceSelection
  >
    <template #item="slotProp">
      {{ slotProp.item.term_name }}
    </template>
  </AutoComplete>
</template>

<script setup>
import { onMounted, ref } from "vue";
const filteredTerms = ref([]);
const terms = ref([]);

onMounted(async () => {
  try {
    terms.value = [{term_id: 10006, term_name: 'CASH'}]
  } catch (e) {
    throw new Error(e);
  }
});

const searchTerms = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredTerms.value = [...terms.value];
    } else {
      filteredTerms.value = terms.value.filter((term) => {
        return term.term_name
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};
</script>
