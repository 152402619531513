<template>
    <div>
    <AutoComplete :suggestions="filteredSupp" @complete="searchSupplier($event)" :dropdown="true" @item-select="getValue($event)" field="vendor_name" v-model="supplierValue" :disabled="loading || props.levelTenant == 2 || props.disabled !== null" forceSelection>
        <template #item="slotProp">
        {{ slotProp.item.vendor_name }}
        </template>
    </AutoComplete>
    </div>
</template>

<script setup>
import {ref, watch, watchEffect, defineEmits, onMounted} from 'vue'
import AuthService from '../../../service/AuthService';
import SpecSrc from '../../../service/SpecSrc';

// eslint-disable-next-line no-undef
const props = defineProps({
    company: {
        type: Object
    },
    vendorId: {
      type: Object
    },
    levelTenant: {
      type: Number
    },
    disabled: {
      type: Boolean
    }
});

// eslint-disable-next-line no-undef
const supplier = ref();
const supplierList = ref([]);
const filteredSupp = ref([]);
const supplierValue = ref();
const loading = ref(false);
const emit = defineEmits(['supplier'])

watchEffect(() => {
    emit("supplier", supplier)
})

watch(
  () => props.company,
  () => {
    if (props.company.db_instance !== null) {
      populateData()
      console.log('isi props,',props)
    }
  }
);

onMounted( async ()=>{
    await populateData()
})

const populateData = async () => {
//   
  AuthService.whoAmi().then(async (response) => {
    loading.value = true
    if (response.status === 200){
        if (props.company !== null ){
            const payload = {
                db_instance : props.company.db_instance?props.company.db_instance:null,
                vendor_id : props.vendorId?props.vendorId:null
            }
            console.log('tes payload',payload)
            const res = await SpecSrc.getSupplier(payload)
            console.log('res supplier', res)
            if (res == undefined || res.status === null){
              setTimeout( async () => {
                console.log('halo')
                await populateData()
              }, 1000);
            }
            else if (res.status === 200 && props.vendorId === null){
              supplierList.value = await res.data.data
              loading.value = false
            } else if (res.status === 200 && props.vendorId !== null){
              // console.log('isi data',res.data.data[0])
              supplierValue.value = await res.data.data[0];
              supplier.value = await res.data.data[0];
              emit("supplier", supplier)
            }
            else {
              supplierValue.value = null;
              supplier.value = null;
              supplierList.value = { vendor_name : 'Not Found'}
              loading.value = false
            } 
            
            // 
        }
    } else { 
      supplierList.value = { vendor_name : 'Not Found'}
      loading.value = false
    }
    
  })
}

const searchSupplier = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredSupp.value = [...supplierList.value];
    } else {
      filteredSupp.value = supplierList.value.filter((comp) => {
        return comp.vendor_name.toLowerCase().match(event.query.toLowerCase());
      });
    }
  }, 250);
};

const getValue = (event) => {
  supplierValue.value = event.value.vendor_name
  supplier.value = event.value
  emit("supplier", supplier)
}
</script>