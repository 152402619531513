<template>
    <span class="p-float-label">
        <AutoComplete :suggestions="filteredCat" @complete="searchCat($event)" :dropdown="true" field="category_detail" v-model="detailCatValue" :disabled="props.disabled || props.level_tenant !== 2" forceSelection>
        <template #item="slotProp">
        {{ slotProp.item.category_detail }}
        </template>
    </AutoComplete>
        <label for="autocomplete">Detail Category</label>
    </span>
</template>

<script setup>
import {onMounted, ref, defineEmits, watch} from 'vue'
import AuthService from '../../../service/AuthService';
import ListInvoiceNonPo from "../../../service/ListInvoiceNonPo"
// eslint-disable-next-line no-undef
const props = defineProps({
  company: {type: Object},
  data: {type: Object},
  ref_num:{type: Object},
  categoryId:{type: Object},
  disabled: {
    type : Boolean
  },
  supplier: { type: Object},
  level_tenant: { type: Object}
})
// eslint-disable-next-line no-undef
const filteredCat = ref([]);
const detailCat = ref([]);
const detailCatValue = ref();
const forwarder = ref(true)
const emit = defineEmits(['detailCat'])
onMounted(() => {
  populateData()
})
watch(() => props.ref_num, (newValue) => {
  if (newValue !== null ){
    if (!forwarder.value){
      populateData()
    }
  }
});
// watchEffect(() => 
//   emit('detailCat',detailCatValue)
// )
const populateData = () => {
  AuthService.whoAmi().then(async (response) => {
    if (response.status === 200){
      if (props.db_instance !== null){
        const payload = {
              categoryId: props.categoryId?props.categoryId:null,
              dbInstance: props.company.db_instance?props.company.db_instance:null,
              orgId: props.company.value?props.company.value:null,
              vendorId: props.supplier?props.supplier:null
            };
          if (response.status === 200){
            const data = await ListInvoiceNonPo.getDetailCat(payload)
            data.data.data.data.categoryHeader.forEach(element => {
              if (element.category_name.toLowerCase().includes('forwarder')){
                forwarder.value = false
              }
            });
            if (forwarder.value){
              detailCat.value = data.data.data.data.categoryDetail
            } 
            else {
              const payloadSK = { 
              categoryId: props.categoryId?props.categoryId:null,
              dbInstance: props.company.db_instance?props.company.db_instance:null,
              orgId: props.company.value?props.company.value:null,
              skNumber: props.ref_num?props.ref_num:null
              }
              const res = await ListInvoiceNonPo.getDetailCatSK(payloadSK)
              detailCat.value = res.data.data.data
            }
            emit('detailCat',detailCatValue)
          }
    }
   
    }
    
  })
}
const searchCat = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredCat.value = [...detailCat.value]
    } else {
      filteredCat.value = detailCat.value.filter((comp) => {
        return comp.category_detail.toLowerCase().match(event.query.toLowerCase());
      })
    }
  }, 250);
}
</script>

<style scoped>
</style>