<template>
	<!-- eslint-disable -->
	<Toast />
	<div class="Grid">
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<!-- Search -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Report Invoice Payment</div>
				<div class="content-body">
					<div class="col-12 p-fluid">
						<div class="grid formgrid" style="margin: 1em 0 0 0">
							<div class="col-12 mb-5 lg:col-4">
								<CompaniesDropdown
									v-model="company"
									class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
									:disabled="
										!(userInfo
											? userInfo.leveltenant === 2
											: false)
									"
								/>
							</div>
							<div
								class="col-12 mb-5 lg:col-4"
								v-if="!isSupplierpage"
							>
								<SupplierDropdown
									v-model="supplier"
									v-if="
										!(userInfo
											? userInfo.leveltenant === 2
											: false)
									"
									:company="company ? company : null"
									:key="supplierReload"
								/>
							</div>
							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<span class="p-float-label">
									<Calendar
										:showIcon="true"
										:showButtonBar="true"
										dateFormat="dd M yy"
										v-model="dateFrom"
									></Calendar>
									<label for="inputtext">Date From</label>
								</span>
							</div>
							<div class="col-12 mb-5 lg:col-4">
								<span class="p-float-label">
									<Calendar
										:showIcon="true"
										:showButtonBar="true"
										dateFormat="dd M yy"
										v-model="dateTo"
									></Calendar>
									<label for="inputtext">Date To</label>
								</span>
							</div>

							<div class="col-12 mb-5 lg:col-4">
								<span class="p-float-label">
									<AutoComplete
										v-model="type"
										:suggestions="filteredInvoiceType"
										@complete="searchCountry($event)"
										field="PPR_NUMBER"
										dropdown
										forceSelection
										@change="handleChange($event)"
									>
										<template #item="slotProps">
											<div class="country-item">
												<div class="ml-2">
													{{ slotProps.item.PPR_NUMBER }}
												</div>
											</div>
										</template>
									</AutoComplete>
									<label for="inputtext">PPR Number</label>
								</span>
							</div>

							<div class="mr-3 ml-2">
								<Button
									@click="handleSearch(true)"
									class="p-button-success uppercase"
									label="search"
									:disabled="
										userInfo
											? userInfo.leveltenant === 2 &&
											  isSupplierAction
											: false
									"
								/>
							</div>
							<div class="mr-3 ml-2">
								<Button
									icon="pi pi-file"
									class="p-button-secondary"
									label="Export"
									@click="exportToExcel"
									style="float: right"
									:disabled="
										userInfo
											? userInfo.leveltenant === 2 &&
											  isSupplierAction
											: false
									"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Search results -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search Result</div>
				<div class="content-body">
					<div class="mt-4">
						<TableInvoicePaymentReport
							:column="column ? column.col : null"
							:company="company ? company : null"
							:supplier="supplier ? supplier : null"
							:dateFrom="dateFrom ? dateFrom : null"
							:dateTo="dateTo ? dateTo : null"
							:type="type ? type.PPR_NUMBER : null"
							:key="reload"
						></TableInvoicePaymentReport>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref, watchEffect } from 'vue';
import AuthService from '../../service/AuthService';
import LovService from '../../service/LovService';
// import PoFulfillment from "../../service/PoFulfillment";
import SimpleCrypto from 'simple-crypto-js';
import paymentService from '../../service/ReportPayment';
import CompaniesDropdown from '@/components/components/CompaniesDropdown';
import SupplierDropdown from '@/components/components/SupplierDropdown';
import XLSX from 'xlsx';
import { useToast } from 'primevue/usetoast';
import { useRouter } from 'vue-router';
import TableInvoicePaymentReport from './components/TableInvoicePaymentReport.vue';
import ReportPayment from '../../service/ReportPayment';

const router = useRouter();
const breadcrumbHome = { icon: 'pi pi-home', to: '/' };
const breadcrumbItems = [
	{
		label: 'Report Payment',
		to: '/batchList',
	},
];
const company = ref();
const supplier = ref(null);
const userInfo = ref();
const isSupplierpage = ref(true);
const supplierReload = ref(0);
const reload = ref(0);
const dateFrom = ref();
const dateTo = ref();
const type = ref(null);
const toast = useToast();
const filteredInvoiceType = ref();
const invoiceType = ref();
const isSupplierAction = ref(false);
const pprNumberList = ref(null);

watchEffect(async () => {
	if (company.value !== null) {
		dateTo.value = null;
		dateFrom.value = null;
		type.value = null;
		supplierReload.value++;
		// const invoice =  invoiceToReload.value++;
		//
		//
	}
});

onMounted(() => {
	AuthService.whoAmi().then(async (response) => {
		if (response.status === 200) {
			userInfo.value = response.data.data;
			if (userInfo.value.leveltenant === 2) {
				//get company
				isSupplierpage.value = true;
				isSupplierAction.value = true;

				const respCbN = await LovService.getCompanyByName(
					userCompany()
				);
				company.value = respCbN.data.data[0];
				//get supplier
				const param = {
					db_instance: company.value.db_instance,
					org_id: company.value.value,
				};

				const respVendor = await LovService.getSuppBindComp(
					response.data.data.id
				);

				// const vendorName = respVendor.data.data[0][0].vendorname;
				const vendorId = respVendor.data.data[0].vendor_id;
				console.log('cek vendor id', vendorId);
				const respSuppName = await LovService.getSupplierName(param);
				if (respSuppName.status === 200) {
					const suppList = respSuppName.data.data;
					for (const s of suppList) {
						if (s.vendor_id === vendorId) {
							supplier.value = s;
							isSupplierAction.value = false;
							break;
						}
					}
					if (supplier.value === null) {
						router.push('/dashboard');
						window.alert('Connection Unstable, ID not Recognize');
					}
				}
				invoiceType.value = [
					{
						name: 'INVOICE PO',
						code: 'INVOICE PO',
					},
					{
						name: 'INVOICE NON PO',
						code: 'INVOICE NON PO',
					},
					{
						name: 'INVOICE PROXY',
						code: 'INVOICE PROXY',
					},
				];

				const payloadPPR = {
					vendor_id:vendorId,
					org_id: company.value.value,
					db_instance: company.value.db_instance
				}

				const resultPPR = await ReportPayment.pprNumberList(payloadPPR);
				console.log('Result PPR',resultPPR.data.data)
				pprNumberList.value = resultPPR.data.data;

			} else {
				isSupplierpage.value = false;

				const respCbN = await LovService.getCompanyByName(
					userCompany()
				);
				company.value = respCbN.data.data[0];
				invoiceType.value = [
					{
						name: 'INVOICE PO',
						code: 'INVOICE PO',
					},
					{
						name: 'INVOICE NON PO',
						code: 'INVOICE NON PO',
					},
					{
						name: 'INVOICE PROXY',
						code: 'INVOICE PROXY',
					},
				];
			}
		}
	});
});

const handleSearch = () => {
	if (!company.value) {
		toast.add({
			severity: 'error',
			summary: 'Please select Company',
			life: 3000,
		});
		return;
	}
	reload.value++;
};

const userCompany = () => {
	const secretKey = 'some-unique-key';
	const simpleCrypto = new SimpleCrypto(secretKey);
	return simpleCrypto.decrypt(sessionStorage.getItem('dropdownCompany'));
};


function formatDateFilter(d) {
	var date = new Date(d);
	var respondDate =
		date.getFullYear() + '-' +
		('00' + (date.getMonth() + 1)).slice(-2) +
		'-' +
		('00' + date.getDate()).slice(-2) +
		' ' +
		('00' + date.getHours()).slice(-2) +
		':' +
		('00' + date.getMinutes()).slice(-2) +
		':' +
		('00' + date.getSeconds()).slice(-2);

	return respondDate;
}


const exportToExcel = async () => {
	if (!company.value) {
		toast.add({
			severity: 'error',
			summary: 'Please select Company',
			life: 3000,
		});
		return;
	}
	await AuthService.whoAmi().then((value) => {
		if (value.status === 200) {
			setTimeout(async () => {
				const payload = {
					org_id: company.value.value ? company.value.value : null,
					db_instance: company.value.db_instance,
					suppValue: supplier.value ? supplier.value.vendor_id : null,
					dateFrom: dateFrom.value
						? formatDateFilter(new Date(dateFrom.value))
						: null,
					dateTo: dateTo.value
						? formatDateFilter(new Date(dateTo.value).setHours(23, 59, 59))
						: null,
					ppr_number: type.value ? type.value : null,
				};
				console.log('cek payload ', payload);
				await paymentService
					.getPaymentReporttoExcel(payload)
					.then((respond) => {
						if (respond.data.data.length !== 0) {
							let convertDate = respond.data.data

							for (var ast of convertDate) {
								const payDate = ast.PAYMENT_DATE;
								const exchangeDate  =  ast.EXCHANGE_DATE
								let changeDate = new Date(payDate);
								let changeDateExchange = new Date(exchangeDate);

								ast.PAYMENT_DATE = (
									changeDate.getDate() +
									'-' +
									changeDate.toString().substring(4, 7) +
									'-' +
									changeDate.getFullYear()
								).toUpperCase();

									ast.EXCHANGE_DATE = (
									changeDateExchange.getDate() +
									'-' +
									changeDateExchange.toString().substring(4, 7) +
									'-' +
									changeDateExchange.getFullYear()
								).toUpperCase();

							}

							let today = new Date();
							let dd = String(today.getDate()).padStart(2, '0');
							let mm = String(today.getMonth() + 1).padStart(
								2,
								'0'
							); //January is 0!
							let yyyy = today.getFullYear();
							let combinedDate = dd + mm + yyyy;
							let filename =
								'Report Invoice Payment _' +
								combinedDate +
								'.xlsx';
							let data = respond.data.data
							let ws = XLSX.utils.json_to_sheet(data);
							let wb = XLSX.utils.book_new();
							XLSX.utils.book_append_sheet(
								wb,
								ws,
								'Report Payment'
							);
							XLSX.writeFile(wb, filename);
						} else {
							toast.add({
								severity: 'error',
								summary: 'Data is Empty',
								life: 3000,
							});
						}
					});
			}, 250);
		}
	});
};

const searchCountry = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredInvoiceType.value = [...pprNumberList.value];
		} else {
			filteredInvoiceType.value = pprNumberList.value.filter((country) => {
				return country.PPR_NUMBER
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
		}
	}, 250);
};

// function formatDateDexa(d) {
// 	var date = new Date(d);
// 	var respondDate =
// 		('00' + (date.getMonth() + 1)).slice(-2) +
// 		'/' +
// 		('00' + date.getDate()).slice(-2) +
// 		'/' +
// 		date.getFullYear() +
// 		' ' +
// 		('00' + date.getHours()).slice(-2) +
// 		':' +
// 		('00' + date.getMinutes()).slice(-2) +
// 		':' +
// 		('00' + date.getSeconds()).slice(-2);

// 	return respondDate;
// }

</script>
