import SpbReportApi from "./api/SpbReportApi";

class receipt {
  async getvendorbytenant(userId, companyName, dbInstance, orgId, levelTenant) {
    return SpbReportApi.get(
      `spb/receipt/getvendorbytenant/${userId}/${companyName}/${dbInstance}/${orgId}/${levelTenant}`
    );
  }

  async getlistponumber(vendorId, dbInstance, orgId, levelTenant) {
    return SpbReportApi.get(
      `spb/receipt/getlistponumber/${vendorId}/${dbInstance}/${orgId}/${levelTenant}`
    );
  }

  async getlistreceipt(payload) {
    return SpbReportApi.post(`spb/receipt/getlistreceipt`, payload);
  }

  async getdownloaddata(payload) {
    return SpbReportApi.post(`spb/receipt/getdownloaddata`, payload);
  }
}

export default new receipt();
