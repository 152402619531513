/* eslint-disable */
import spbOrder from "./api/SpbOrderApi";
import spbAdmin from "./api/SpbAdminApi";
import spbKkt from "./api/SpbKktApi";
class CreatePoService {
  async getActive() {
    const url = "spb/createpo/getlov/company";
    return spbOrder.get(url);
  }

  async getLastPoNum(org_id) {
    const url = "spb/createpo/getlastponum/" + org_id;
    return spbOrder.get(url);
  }

  async getLastPlannoId() {
    const url = "spb/createpo/getlastplannoid";
    return spbOrder.get(url);
  }

  async getLastPlannoLineId() {
    const url = "spb/createpo/getlastplannolineid";
    return spbOrder.get(url);
  }

  async getSupplier() {
    const url = "spb/createpo/getlov/1";
    return spbOrder.get(url);
  }

  async getAlllov() {
    const url = "spb/createpo/getlov";
    return spbOrder.get(url);
  }

  async getAllData1() {
    const url = "spb/createpo/getlov";
    return spbOrder.get(url);
  }

  async getValueAllLov(type) {
    const url = "spb/createpo/getlov/" + type;
    return spbOrder.get(url);
  }

  async getAllData() {
    const url = "spb/lovroute/getalldata";
    return spbAdmin.get(url);
  }

  async getValue(type) {
    const url = "spb/lovroute/getValue/" + type;
    return spbAdmin.get(url);
  }

  async getAllTable() {
    const url = "spb/createpo/gettable";
    return spbOrder.get(url);
  }

  async getCountAll() {
    const url = "spb/createpo/getcountall";
    return spbOrder.get(url);
  }

  async getPageTable(first, last) {
    let payload = { first, last };
    const url = "spb/createpo/gettablepage";
    return spbOrder.post(url, payload);
  }

  async getPageTableFirst(first, last) {
    let payload = { first, last };
    const url = "spb/createpo/gettablepagefirst";
    return spbOrder.post(url, payload);
  }

  async getAllCurrencies(db_instance) {
    const url = "spb/createpo/getallcurrencies/" + db_instance;
    return spbOrder.get(url);
  }

  async getAllUom(db_instance) {
    const url = "spb/createpo/getalluom/" + db_instance;
    return spbOrder.get(url);
  }

  async searchCompanyPrNum(payload) {
    // let payload = {org_id, db_instance, first, last};
    const url = `spb/createpo/searchcompanyprnum`;
    return spbOrder.post(url, payload);
  }

  async searchCompany(payload) {
    // let payload = {org_id, db_instance, first, last};
    const url = `spb/createpo/searchcompany`;
    return spbOrder.post(url, payload);
  }

  async countComSupp(payload) {
    // let payload = {org_id, db_instance, first, last};
    const url = `spb/createpo/countcomsupp`;
    return spbOrder.post(url, payload);
  }

  async searchSupplierPrNum(payload) {
    // let payload = {org_id, db_instance, first, last};
    const url = `spb/createpo/searchsupplierprnum`;
    return spbOrder.post(url, payload);
  }

  async searchSupplierJitPrNum(payload) {
    // let payload = {org_id, db_instance, first, last};
    const url = `spb/createpo/searchsupplierjitprnum`;
    return spbOrder.post(url, payload);
  }

  async searchSupplier(payload) {
    // let payload = {org_id, db_instance, first, last};
    const url = `spb/createpo/searchsupplier`;
    return spbOrder.post(url, payload);
  }

  async countSupplier(payload) {
    // let payload = {org_id, db_instance, first, last};
    const url = `spb/createpo/countsupplier`;
    return spbOrder.post(url, payload);
  }

  async searchSupplierJit(payload) {
    // let payload = {org_id, db_instance, first, last};
    const url = `spb/createpo/searchsupplierjit`;
    return spbOrder.post(url, payload);
  }

  async countSupplierJit(payload) {
    // let payload = {org_id, db_instance, first, last};
    const url = `spb/createpo/countsupplierjit`;
    return spbOrder.post(url, payload);
  }

  async searchCommJitPrNum(payload) {
    // let payload = {org_id, db_instance, first, last};
    const url = `spb/createpo/searchcommjitprnum`;
    return spbOrder.post(url, payload);
  }

  async searchAll(payload) {
    // let payload = {org_id, db_instance, first, last};
    const url = `spb/createpo/searchall`;
    return spbOrder.post(url, payload);
  }

  async countAll(payload) {
    // let payload = {org_id, db_instance, first, last};
    const url = `spb/createpo/countall`;
    return spbOrder.post(url, payload);
  }

  async searchAllPrNum(payload) {
    // let payload = {org_id, db_instance, first, last};
    const url = `spb/createpo/searchallprnum`;
    return spbOrder.post(url, payload);
  }

  async searchComSupJit(payload) {
    // let payload = {org_id, db_instance, first, last};
    const url = `spb/createpo/searchComSupJit`;
    return spbOrder.post(url, payload);
  }

  async countComSupJit(payload) {
    // let payload = {org_id, db_instance, first, last};
    const url = `spb/createpo/countComSupJit`;
    return spbOrder.post(url, payload);
  }

  async searchComSupJitPrNum(payload) {
    // let payload = {org_id, db_instance, first, last};
    const url = `spb/createpo/searchComSupJitPrNum`;
    return spbOrder.post(url, payload);
  }

  async searchCommJit(payload) {
    // let payload = {org_id, db_instance, first, last};
    const url = `spb/createpo/searchcommjit`;
    return spbOrder.post(url, payload);
  }

  async countCommJit(payload) {
    // let payload = {org_id, db_instance, first, last};
    const url = `spb/createpo/countcommjit`;
    return spbOrder.post(url, payload);
  }

  async searchCommPr(payload) {
    // let payload = {org_id, db_instance, first, last};
    const url = `spb/createpo/searchcommpr`;
    return spbOrder.post(url, payload);
  }

  async countCommPr(payload) {
    // let payload = {org_id, db_instance, first, last};
    const url = `spb/createpo/countcommpr`;
    return spbOrder.post(url, payload);
  }

  async getAllTablePR() {
    const url = "spb/createpo/gettable";
    return spbOrder.get(url);
  }

  async getSupplier1(db_instance) {
    const url = "spb/createpo/gettable/" + db_instance;
    return spbOrder.get(url);
  }

  async getPrnumber(pr_num) {
    const url = "spb/createpo/gettablepr/" + pr_num;
    return spbOrder.get(url);
  }

  async getAllPrnumber() {
    const url = "spb/createpo/getalltablepr";
    return spbOrder.get(url);
  }

  async getCommPrnumber(orgId) {
    const url = `spb/createpo/gettablecommpr/${orgId}`;
    return spbOrder.get(url);
  }

  async deleteSaveDraft(payload) {
    const url = `spb/createpo/deletedraft`;
    return spbOrder.post(url, payload);
  }

  async pushDraft(payload) {
    const url = "spb/createpo/pushdraft/";
    return spbOrder.post(url, payload);
  }

  async saveDraft(payload) {
    const url = "spb/createpo/updatedraft/";
    return spbOrder.post(url, payload);
  }

  async pushSubmitHeader(payload) {
    const url = "spb/createpo/pushsubmitheader/";
    return spbOrder.post(url, payload);
  }

  async checkConversion(payload) {
    const url = "spb/createpo/checkconversion/";
    return spbOrder.post(url, payload);
  }

  async pushSubmitDetail(payloads) {
    const url = "spb/createpo/pushsubmitdetail/";
    return spbOrder.post(url, payloads);
  }

  async searchPr(payload) {
    const url = `spb/createpo/getsearchvalue/${payload.user.pr_num}`;
    return spbOrder.get(url, payload);
  }

  async getDraft(payload) {
    const url = `spb/createpo/getdraft`;
    return spbOrder.get(url, payload);
  }

  async getOpReq(payload) {
    const url = `spb/createpo/getopreq`;
    return spbOrder.get(url, payload);
  }

  async getDraftPr(payload) {
    const url = `spb/createpo/getdraftpr`;
    return spbOrder.post(url, payload);
  }

  async getDraftComSupp(payload) {
    const url = `spb/createpo/getdraftcomsupp`;
    return spbOrder.post(url, payload);
  }

  async getDraftComSuppPr(payload) {
    const url = `spb/createpo/getdraftcomsupppr`;
    return spbOrder.post(url, payload);
  }

  async getDraftComSuppJit(payload) {
    const url = `spb/createpo/getdraftcomsuppjit`;
    return spbOrder.post(url, payload);
  }

  async getDraftComSuppPrJit(payload) {
    const url = `spb/createpo/getdraftcomsuppprjit`;
    return spbOrder.post(url, payload);
  }

  async getDraftJit(payload) {
    const url = `spb/createpo/getdraftjit`;
    return spbOrder.post(url, payload);
  }

  async getTablePr(payload) {
    const url = `spb/createpo/gettablepr`;
    return spbOrder.post(url, payload);
  }

  async getTableComSupp(payload) {
    const url = `spb/createpo/gettablecomsupp`;
    return spbOrder.get(url, payload);
  }

  async searchJit(payload) {
    const url = `spb/createpo/searchjit`;
    return spbOrder.post(url, payload);
  }

  async searchJitPrNum(payload) {
    const url = `spb/createpo/searchjitprnum`;
    return spbOrder.post(url, payload);
  }

  async countTableJitAll(payload) {
    const url = `spb/createpo/counttablejitall`;
    return spbOrder.post(url, payload);
  }

  async searchPrNum(payload) {
    const url = `spb/createpo/searchprnum`;
    return spbOrder.post(url, payload);
  }

  async countTablePrNum(payload) {
    const url = `spb/createpo/counttableprnumall`;
    return spbOrder.post(url, payload);
  }

  async searchJitPrNumCom(payload) {
    const url = `spb/createpo/searchJitPrNumCom`;
    return spbOrder.post(url, payload);
  }

  async countTableJitPrNum(payload) {
    const url = `spb/createpo/countTableJitPrNum`;
    return spbOrder.post(url, payload);
  }

  async searchComJitPrNum(payload) {
    const url = `spb/createpo/searchComJitPrNum`;
    return spbOrder.post(url, payload);
  }

  async countTableComJitPrNum(payload) {
    const url = `spb/createpo/countTableComJitPrNum`;
    return spbOrder.post(url, payload);
  }

  async getLastSeq(id) {
    const url = `spb/createpo/getlastseq/` + id;
    return spbOrder.get(url);
  }

  async checkPoNum(payload) {
    const url = `spb/createpo/checkponum`;
    return spbOrder.post(url, payload);
  }

  async getLastAllSeq(id) {
    const url = `spb/createpo/getlastallseq/` + id;
    return spbOrder.get(url);
  }

  async insertLastAllSeq(payload) {
    const url = `spb/createpo/pushlastseq`;
    return spbOrder.post(url, payload);
  }

  async getPlannoRecipientMail(data) {
    return spbAdmin.post("spb/notification/getPlannoRecipientMail", data);
  }

  async sendPONotif(payload) {
    const url = `spb/notification/sendPONotification`;
    return spbAdmin.post(url, payload);
  }
  async sendPOEmail(data) {
    // const url = `adm/send/emailPlanno`;
    // return spbKkt.post(url, data);
    const queryParams = data
      ? Object.keys(data)
          .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(data[k]))
          .join("&")
      : "";
    return spbKkt.post("adm/send/emailPlanno", data);
  }

  async getRecipientMail(data) {
    return spbKkt.post("adm/tenants/getCPORecipientMail", data);
  }

  async getGBPA(data) {
    return spbOrder.post("/spb/gbpa/find-gbpa", data);
  }
}
export default new CreatePoService();
