import spbAdmin from './api/SpbAdminApi';
class MasterCategory {
	async getTableData(payload) {
		return spbAdmin.post('spb/master/retrivecategory', payload);
	}
	async getCoA(payload) {
		return spbAdmin.post('spb/master/retriveacoa', payload);
	}
	async getCategory(payload) {
		return spbAdmin.post('spb/master/retriveacategory', payload);
	}
	async CreateCategory(payload) {
		return spbAdmin.post('spb/master/createcategory', payload);
	}
	async UpdateCategoryDetail(categoryId, payload) {
		return spbAdmin.put(
			`spb/master/updatecategorydetail/${categoryId}`,
			payload
		);
	}
	async retriveCategoryById(id) {
		return spbAdmin.get(`spb/master/retriveacategorybyid/${id}`);
	}
}
export default new MasterCategory();
