import { encryptForm } from '../helper/encryptForm';
import spbinvoice from './api/SpbInvoiceApi';

class InvoicePOService {
	getListPo(params, payload) {
		const queryParams = params
			? Object.keys(params)
					.map(
						(k) =>
							encodeURIComponent(k) +
							'=' +
							encodeURIComponent(params[k])
					)
					.join('&')
			: '';
		return spbinvoice.post(`/invoicepo/listpo?${queryParams}`, payload);
	}

	getListPoStandard(params, payload) {
		const queryParams = params
			? Object.keys(params)
					.map(
						(k) =>
							encodeURIComponent(k) +
							'=' +
							encodeURIComponent(params[k])
					)
					.join('&')
			: '';
		return spbinvoice.post(`/invoicepo/listpostandard?${queryParams}`, payload);
	}

	getListPoPrepayment(params, payload) {
		const queryParams = params
			? Object.keys(params)
					.map(
						(k) =>
							encodeURIComponent(k) +
							'=' +
							encodeURIComponent(params[k])
					)
					.join('&')
			: '';
		return spbinvoice.post(`/invoicepo/listpoprepayment?${queryParams}`, payload);
	}

	getListPendingPo(payload) {
		return spbinvoice.post(`/invoicepo/listpendingpo`, payload);
	}

	getPoNumbers(payload) {
		return spbinvoice.post(`/invoicepo/ponumbers`, payload);
	}

	getBatchNumbers(payload) {
		return spbinvoice.post(`/invoicepo/batchnumbers`, payload);
	}

	getListBank(payload) {
		return spbinvoice.post(`/invoicepo/listbank`, payload);
	}

	submitInvoice(payload) {
		return spbinvoice.post(`/invoicepo/saveinvoice`, payload);
	}

	addToBatch(payload) {
		return spbinvoice.post(`/invoicepo/addtobatch`, payload);
	}

	getStatusInvoice() {
		return spbinvoice.get(`/invoicepo/statusinvoice`);
	}

	getInvoiceNumbers({ dbInstance, orgId, vendorId, type }) {
		let query = {
			dbInstance, orgId, type
		}

		if (vendorId) {
			query = {...query, vendorId}
		}

		return spbinvoice.post(`/invoicepo/invoicenumbers`, query);
	}

	getListInvoice(params, payload) {
		const queryParams = params
			? Object.keys(params)
					.map(
						(k) =>
							encodeURIComponent(k) +
							'=' +
							encodeURIComponent(params[k])
					)
					.join('&')
			: '';
		return spbinvoice.post(
			`/invoicepo/listinvoice?${queryParams}`,
			encryptForm(payload)
		);
	}

	getListInvoiceProxy(params, payload) {
		const queryParams = params
			? Object.keys(params)
					.map(
						(k) =>
							encodeURIComponent(k) +
							'=' +
							encodeURIComponent(params[k])
					)
					.join('&')
			: '';

		return spbinvoice.post(
			`/invoicepo/listinvoiceproxy?${queryParams}`,
			payload
		);
	}

	getInvoiceNumbersProxy({ dbInstance, orgId, vendorId, type }) {
		let query = {
			dbInstance, orgId, type
		}

		if (vendorId) {
			query = {...query, vendorId}
		}

		return spbinvoice.post(`/invoicepo/invoicenumbersproxy`, query);
	}

	getListInvoicePrepayment(params, payload) {
		const queryParams = params
			? Object.keys(params)
					.map(
						(k) =>
							encodeURIComponent(k) +
							'=' +
							encodeURIComponent(params[k])
					)
					.join('&')
			: '';

		return spbinvoice.post(
			`/invoicepo/listinvoiceprepayment?${queryParams}`,
			payload
		);
	}

	getInvoiceDetail(id, payload) {
		return spbinvoice.post(`/invoicepo/detailinvoice/${id}`, encryptForm(payload));
	}

	getInvoiceDetailPrepayment(id,payload){
		return spbinvoice.post(`/invoicepo/detailinvoiceprepayment/${id}`, payload);
	}

	getAttachmentFile(invoiceId) {
		return spbinvoice.get(`/invoicepo/attachmentfile/${invoiceId}`);
	}
	getFileBase64(invoiceId, leveltenant, vendorId) {
		return spbinvoice.get(`/invoicepo/getfilebase64/${invoiceId}/${leveltenant}/${vendorId}`);
	}

	updateInvoice(id, payload) {
		return spbinvoice.put(`/invoicepo/updateinvoice/${id}`, payload);
	}

	printBatchPur(payload) {
        return spbinvoice.post('/invoicepo/printinvoice', payload);
    }

	getTotalPPN(payload) {
		return spbinvoice.post('/invoicepo/totalppn', payload);
	}

	getLastInvoice(params, payload) {
		const queryParams = params
			? Object.keys(params)
					.map(
						(k) =>
							encodeURIComponent(k) +
							'=' +
							encodeURIComponent(params[k])
					)
					.join('&')
			: '';

		return spbinvoice.post(
			`/invoicepo/lastinvoice?${queryParams}`,
			payload
		);
	}

	
}

export default new InvoicePOService();
