<template>
  <div class="grid">
    <Toast />
    <ProgressModal :progress="progress">
      <template #message>
        Your Request in progress.<br />Please wait...
      </template>
    </ProgressModal>
    <div class="col-12">
      <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
    </div>
    <div class="col-12">
      <div class="content-wrapper mb-4">
        <div class="content-header">General Information</div>
        <div class="content-body">
          <div class="flex flex-row justify-content-between">
            <div class="col flex-column align-item-center log:col-auto">
              <div class="grid">
                <div class="col-12 md:col-12 lg:col-4">
                  <span class="p-float-label">
                    <InputText
                      v-model="documentNo"
                      class="w-full mb-2 lg:mb-0"
                      placeholder="Document Number"
                      :disabled="true"
                    />
                    <label for="inputtext">Document No</label>
                  </span>
                </div>
                <div class="col-12 md:col-12 lg:col-4">
                  <span class="p-float-label">
                    <DONumberDropdown
                      v-model="doNumber"
                      class="w-full mb-2 lg:mr-4 lg:mb-0"
                      :disabled="true"
                    />
                    <label for="inputtext">PO Number</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-wrapper mb-4">
        <div class="content-header">Finding Information</div>
        <div class="content-body">
          <div class="flex flex-row justify-content-between mb-4">
            <div class="col flex-column align-item-center lg:col-auto">
              <div class="grid">
                <div class="col-12 md:col-12 lg:col-4">
                  <span class="p-float-label">
                    <Calendar
                      v-model="auditDate"
                      dateFormat="d-M-yy"
                      :showIcon="true"
                      :show-time="true"
                      class="w-full mb-3 lg:mb-0"
                      placeholder="DR/Audit Date"
                      :disabled="capaId !== '0'"
                    />
                    <label for="inputtext">DR/Audit Date</label>
                  </span>
                </div>
                <div class="col-12 md:col-12 lg:col-4">
                  <!-- <ButtonDownloadFile :link="SOFileLink" :label="soFilename" /> -->
                  <Button
                    v-if="!loadingSoFile"
                    class="button-download font-normal"
                    :label="soFilename"
                    icon="pi pi-download"
                    @click="onDownload()"
                  />
                  <Button
                    v-if="loadingSoFile"
                    class="button-download font-normal"
                    :label="`${soFilename} is downloading`"
                    icon="pi pi-download"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-12 md:col-12 lg:col-4">
                  <span class="p-float-label mt-4">
                    <Textarea
                      v-model="finding"
                      rows="9"
                      placeholder="Finding"
                      class="w-full mb-3 lg:col-auto lg:mr-3 lg:mb-0"
                      disabled
                    />
                    <label for="inputtext">Subject</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-wrapper mb-4">
        <div class="content-header">Response Information</div>
        <div class="content-body">
          <div class="flex flex-row justify-content-between mb-4">
            <div class="col flex-column align-item-center lg:col-auto">
              <div class="grid">
                <div class="col-12 md:col-12 lg:col-4">
                  <span class="p-float-label">
                    <Textarea
                      v-model="response"
                      rows="9"
                      class="w-full mb-3 lg:col-auto lg:mr-3 lg:mb-0"
                      :disabled="disableForm"
                    />
                    <label for="inputtext">Response</label>
                  </span>
                </div>
                <div class="col-12 md:col-12 lg:col-4" v-if="status === 'C'">
                  <ButtonDownloadFile :link="SPFileLink" :label="spFilename" />
                </div>
              </div>
              <div
                class="grid"
                v-if="!(spFilename === undefined || spFilename === null)"
              >
                <div
                  class="col-12 md:col-12 lg:col-4 mt-4"
                  v-if="status !== 'C'"
                >
                  <ButtonDownloadFile :link="SPFileLink" :label="spFilename" />
                </div>
              </div>
              <div class="grid" v-if="showButtonAction">
                <div class="col-12 md:col-12 lg:col-12">
                  <FileUpload @select="onSelect" @remove="onRemoveFile" />
                </div>
              </div>
              <div div class="grid ml-2 mt-4">
                <div v-if="showButtonAction">
                  <Button
                    label="Update"
                    @click="update()"
                    class="p-button-primary w-full mb-3 lg:w-auto lg:mr-3 lg:mb-0"
                    :disabled="disableButton"
                  />
                </div>
                <div v-if="status !== 'C'">
                  <Button
                    label="Back"
                    @click="back()"
                    class="p-button-success w-full mb-3 lg:w-auto lg:mr-3 lg:mb-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-wrapper mb-4" v-if="status === 'C'">
        <div class="content-body">
          <div class="flex flex-row justify-content-between mb-4">
            <div class="col flex-column align-item-center log:col-auto">
              <div div class="grid ml-2 mt-4">
                <div v-if="status === 'C'">
                  <Button
                    label="Back"
                    @click="back()"
                    class="p-button-success w-full mb-3 lg:w-auto lg:mr-3 lg:mb-0"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- <div v-if="status === 'C' ? true : false">
            <Button
              label="Print Page"
              @click="handlePrintCAPA()"
              class="p-button-secondary w-full mb-3 lg:w-auto lg:mr-3 lg:mb-0 lg:ml-2"
              icon="pi pi-print "
              :disabled="disableButton"
            />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// import {useToast} from "primevue/usetoast";
import { ref } from "vue";
import AuthService from "@/service/AuthService";
import { useRoute, useRouter } from "vue-router";
// import {createCAPA, getCAPA, updateCAPA} from "@/service/CAPAServices";
import { useToast } from "primevue/usetoast";
import { getCAPASupp, updateCAPA, getSOfile } from "@/service/CAPAServices";
import ButtonDownloadFile from "./components/ButtonDownloadFile.vue";
import FileUpload from "./components/FileUpload.vue";
import ProgressModal from "../components/ProgressModal.vue";
import DONumberDropdown from "./components/DONumberDropdown.vue";
import LovService from "@/service/LovService";
import SimpleCrypto from "simple-crypto-js";

const breadcrumbHome = { icon: "pi pi-home", to: "/dashboard" };
const breadcrumbItems = ref([]);
const documentNo = ref();
const doNumber = ref();
const finding = ref();
const response = ref();
const toast = useToast();
// const capa = ref();
const userInfo = ref();
const route = useRoute();
const router = useRouter();
const id = ref(route.params.id);
const SOFileLink = ref();
const SPFileLink = ref();
const soFilename = ref();
const spFilename = ref();
const spFile = ref();
const file = ref();
const vendorId = ref();
const orgId = ref();
const progress = ref(false);
const auditDate = ref();
const status = ref();
const emailSender = ref();
const disableButton = ref(true);
const remarks = ref();
const showButtonAction = ref(false);
const disableForm = ref(false);
const soFileBuffer = ref();
const loadingSoFile = ref(false);
// const capa = ref();

AuthService.whoAmi().then(async (response) => {
  if (response.status === 200) {
    const map = new Map();
    map.set("label", "Supplier Update CAPA");
    map.set("to", "/mgm/master/capa/editcapasupp/" + id.value);
    const breadcrumbItem = Object.fromEntries(map);
    breadcrumbItems.value[0] = breadcrumbItem;
    userInfo.value = response.data.data;
    emailSender.value = response.data.data.bioemailactive;
    const vendorId = sessionStorage.getItem("vendorId");
    await getData(vendorId);
    disableButton.value = false;
  }
});

const getData = async (vendorIdSession) => {
  // const result = await getCAPA(id.value)
  try {
    const resultData = await getCAPASupp(id.value, vendorIdSession);
    const result = resultData.data;
    SOFileLink.value = resultData.SOFileLink;
    SPFileLink.value = resultData.SPFileLink;
    // capa.value = result[0];
    const type = typeof result;
    if (type === "object") {
      documentNo.value = result[0].DOC_NUMBER;
      finding.value = result[0].FINDING;
      doNumber.value = result[0].PO_NUMBER;
      soFilename.value = result[0].SO_FILENAME
        ? result[0].SO_FILENAME
        : "no file attached";
      spFilename.value = result[0].SP_FILENAME;
      spFile.value = result[0].SP_FILE;
      vendorId.value = result[0].VENDOR_ID;
      response.value = result[0].RESPOND;
      status.value = result[0].STATUS;
      orgId.value = result[0].ORG_ID;
      remarks.value = result[0].REMARKS;
      let option = {
        day: "numeric",
        month: "short",
        year: "numeric",
      };
      auditDate.value = new Date(result[0].FINDING_DATE).toLocaleDateString(
        "id-ID",
        option
      );

      if (status.value === "O") {
        showButtonAction.value = true;
        disableForm.value = false;
      } else {
        showButtonAction.value = false;
        disableForm.value = true;
      }
    } else {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: `${result}`,
        life: 3000,
      });
    }
  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Failed",
      detail: "CAPA NOT FOUND",
      life: 3000,
    });
    setTimeout(() => {
      router.push("/dashboard");
    }, 1500);
  }
};

// const handleClickUpload = () => {}
const onSelect = (event) => {
  file.value = event.files[0];
  return file.value;
};
const onRemoveFile = (event) => {
  if (!event.files.length) {
    file.value = null;
  }
};

const update = async () => {
  progress.value = true;
  // const param = {
  //   respond: response.value,
  //   sp_filename: 'test',
  //   last_updated_by: userInfo.value.id,
  //   status: 'O',
  // }
  // const result = await updateCAPA(id.value,param);

  let errors = [];

  if (!response.value) {
    errors.push("Response is Empty");
  }

  if (spFilename.value) {
    // oke
  } else {
    if (file.value) {
      // oke
    } else {
      // not oke
      errors.push("File is Empty");
    }
  }

  if (errors.length === 0) {
    try {
      let formData = new FormData();
      formData.append("respond", response.value);
      formData.append("last_updated_by", userInfo.value.id);
      formData.append("status", "O");
      if (file.value) {
        formData.append("file", file.value);
        formData.append("vendor_id", vendorId.value);
        formData.append("sp_file", spFile.value);
        if (spFile.value === null || spFile.value === undefined) {
          formData.append("sp_filename", "new");
          formData.append("sp_mime_type", "new");
        } else {
          formData.append("sp_filename", "update");
          formData.append("sp_mime_type", "update");
        }
      }
      let statusNotif;
      if (status.value === "O") {
        statusNotif = "Open";
      } else if (status.value === "C") {
        statusNotif = "Close";
      }
      const secretKey = "some-unique-key";
      const simpleCrypto = new SimpleCrypto(secretKey);
      const decryptComp = simpleCrypto.decrypt(
        sessionStorage.getItem("dropdownCompany")
      );
      const fetchDbInstance = await LovService.getCompanyByName(decryptComp);
      if (!spFilename.value) {
        const emailData = {
          module: "respondCAPA",
          idmodule: "2",
          idUser: userInfo.value.id,
          docNo: documentNo.value,
          sbjmessage: "CAPA # " + documentNo.value + " has been responded by ",
          usersenderid: userInfo.value.id,
          notif_type: "Purchasing",
          sender: userInfo.value.bioemailactive,
          orgIdComp: orgId.value,
          fileNamePur: soFilename.value,
          fileName: file.value ? file.value.name : null,
          subjectCAPA: finding.value,
          findingDate: auditDate.value,
          status: statusNotif,
          dbInstance: fetchDbInstance.data.data[0].db_instance,
          response: response.value,
          responseDate: new Date(),
          companyname: fetchDbInstance.data.data[0].name,
          vendorId: vendorId.value
        };
        formData.append("emailPayload", JSON.stringify(emailData));
      }

      const result = await updateCAPA(id.value, formData);
    console.log('stop', result)
      if (result.toUpperCase().includes("SUCCESS")) {
        toast.add({
          severity: "info",
          summary: "Success",
          detail: "CAPA is updated",
          life: 3000,
        });
        progress.value = false;
        setTimeout(() => {
          location.reload();
        }, 1500);
      } else if (result.includes("Fail")) {
        toast.add({
          severity: "error",
          summary: "Can't encrypt PDF",
          detail: "PDF already has a password",
          life: 3000,
        });
        progress.value = false;
      } else if (result.includes("Send")) {
        toast.add({
          severity: "error",
          summary: "Send Email Error",
          detail: "Unable to Send Email",
          life: 3000,
        });
        progress.value = false;
      } 
      else {
        toast.add({
          severity: "error",
          summary: "Failed",
          detail: "Unable to update CAPA",
          life: 3000,
        });
        progress.value = false;
      }
    } 
    catch (error) {
      toast.add({
        severity: "error",
        summary: "Failed",
        detail: "Something Wrong, Unable to update CAPA",
        life: 3000,
      });
      progress.value = false;
    }
  } else {
    for (const err of errors) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: err,
        life: 3000,
      });
      // messageError = messageError.concat(err + " n");
      progress.value = false;
    }
  }
};

const back = () => {
  // route-1;
  router.back();
};

const onDownload = async () => {
  try {
    loadingSoFile.value = true;
    const leveltenant = sessionStorage.getItem("leveltenant");
    const resSoFile = await getSOfile(id.value, leveltenant);
    soFileBuffer.value = resSoFile;
  } catch (error) {
    loadingSoFile.value = false;
    toast.add({
      severity: "error",
      summary: "Connection Unstable !",
      life: 3000,
    });
    throw new Error("Error Downloading File");
  }

  const linkSource = `data:application/pdf;base64,${soFileBuffer.value}`;
  const downloadLink = document.createElement("a");
  const fileName = soFilename.value;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
  loadingSoFile.value = false;
};
</script>

<style scoped></style>
