<template>
  <AutoComplete
    :suggestions="filteredSupplier"
    @complete="searchSupplierSite($event)"
    :dropdown="true"
    field="vendor_name"
    v-model="supplier"
    forceSelection
  >
    <template #item="slotProp">
      {{ slotProp.item.vendor_name }}
    </template>
  </AutoComplete>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect } from "vue";
import PoHistoryService from "../../../service/PoHistoryService";

// eslint-disable-next-line no-undef
const filteredSupplier = ref([]);
const supplierList = ref([]);
const supplier = ref();

const emit = defineEmits(["supplier", "supplierList"]);

watchEffect(() => [emit("supplier", supplier)]);
// eslint-disable-next-line no-undef
const props = defineProps({
  company: {
    type: Object,
  },
  userId: {
    type: String,
  },
  levelTenant: {
    type: String,
  },
});

onMounted(async () => {
  try {
    // const response = await AuthService.whoAmi();
    // const user = response.data.data;
    // console.log("User Supplier SIte ::", user);
    console.log("props Supplier Dropdown ::", props);
    if ((await props.company) && props.userId && props.levelTenant) {
      const result = await PoHistoryService.getvendorbytenant(
        props.userId,
        props.company.name,
        props.company.db_instance,
        props.company.value,
        props.levelTenant
      );
      console.log("Result Supplier ::", result);
      supplierList.value = result.data;
      emit("supplierList", supplierList);
    }

    // eslint-disable-next-line no-empty
  } catch (e) {
    throw new Error(e);
  }
});

const searchSupplierSite = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredSupplier.value = [...supplierList.value];
    } else {
      filteredSupplier.value = supplierList.value.filter((supp) => {
        if (supp.vendor_name === null || supp.vendor_name === undefined) {
          return supp;
        }
        return supp.vendor_name
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};
</script>
