export const formattingDate = (date, withTime) => {
	const theDate = new Date(date);
	const strDate =
		theDate.getFullYear() +
		'-' +
		(theDate.getMonth() + 1) +
		'-' +
		theDate.getDate();
	const strTime =
		theDate.getHours() +
		':' +
		theDate.getMinutes() +
		':' +
		theDate.getSeconds();

	if (withTime) {
		return `${strDate} ${strTime}`;
	} else {
		return `${strDate}`;
	}
};