<template>
    <!-- <AutoComplete
      v-model="poNumber"
      :suggestions="filteredPoNumber"
      :virtualScrollerOptions="{
        itemSize: 30,
      }"
      @complete="searchPoNumber($event)"
      field="po_number"
      forceSelection
    >
    </AutoComplete> -->
    <InputText 
      type="text" 
      v-model="documentNumber" 
      @input="documentNumberInput()"
      placeholder="Input a document number"
    />
</template>

<script setup>
import {onMounted, ref} from 'vue'
const documentNumber = ref();

onMounted(() => {
  // documentNumber.value 
})


const documentNumberInput = () => {
  setTimeout(() => {
    return documentNumber;
  }, 250);
}
</script>

<style scoped>

</style>