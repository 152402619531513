/* eslint-disable */
import spbOrder from './api/SpbOrderApi';
import spbAdmin from './api/SpbAdminApi';
import spbKkt from './api/SpbKktApi';
class SubmitPOService {
	async getAllPoNum() {
		return spbOrder.get('spb/submitpo/getallponum');
	}
	async getPoNum(DB_INSTANCE) {
		return spbOrder.post('spb/submitpo/getPoNum/', DB_INSTANCE);
	}
	async getPo(po_header_id, rev_num) {
		const revNum = JSON.stringify(rev_num);
		return spbOrder.get(
			`spb/submitpo/getPo/${po_header_id}?rev_num=${revNum}`
		);
	}
	async getPoList(po_header_id, rev_num) {
		const revNum = JSON.stringify(rev_num);
		return spbOrder.get(
			`spb/submitpo/getPoList/${po_header_id}?rev_num=${revNum}`
		);
	}
	async getPreparers(db_instance) {
		const response = await spbOrder.get(
			`/spb/users/preparers/${db_instance}`
		);
		return response.data;
	}
	async sendPoFile(payload) {
		return spbOrder.post('spb/submitpo/uploadfile', payload);
	}
	async sendPoFileBAST(payload) {
		return spbOrder.post('spb/submitpo/uploadfilebast', payload);
	}
	async submitPoHeader(payload) {
		return spbOrder.post('spb/submitpo/submitPoHeader', payload);
	}
	async submitPoDetail(payload) {
		return spbOrder.post('spb/submitpo/submitPoDetail', payload);
	}
	async getPoTerm(po_header_id, rev_num) {
		const revNum = JSON.stringify(rev_num);
		return spbOrder.get(
			`spb/submitpo/getPoTerm/${po_header_id}?rev_num=${revNum}`
		);
	}
	async sendPoTerms(payload) {
		return spbOrder.post('spb/submitpo/submitPoTerm', payload);
	}
	async submitPoTerm(payload) {
		return spbOrder.post('spb/submitpo/submitPoTerm', payload);
	}
	async sendPoDoc(payload) {
		return spbOrder.post('spb/submitpo/submitPoDoc', payload);
	}
	async submitPoDoc(payload) {
		return spbOrder.post('spb/submitpo/submitPoDoc', payload);
	}
	async removePoHeader(payload) {
		return spbOrder.delete(`spb/submitpo/removePoHeader/${payload}`);
	}
	async getPurchaseRecipientMail(data) {
		return spbAdmin.post('spb/notification/getPlannoRecipientMail', data);
	}
	async getRecipientMail(data) {
		return spbKkt.post('adm/tenants/getCPORecipientMail', data);
	}
	async getPreparerMail(data) {
		return spbAdmin.post('spb/notification/getPreparerMail', data);
	}
	async sendNotifPurchaseOrder(payload) {
		const url = `spb/notification/sendsubmitPOnotifsupp`;
		return spbAdmin.post(url, payload);
	}
	async sendPOEmail(data) {
		return spbKkt.post('adm/send/emailPurchaseOrderToSupp', data);
	}
	downloadFilePur(payload) {
		return spbOrder.post(
			'/spb/spb_pofulfillment/downloadfilepur', payload
		);
	}
	getVendorId(payload) {
		return spbOrder.get(
			'/spb/spb_pofulfillment/getvendorid/'+ payload
		);
	}
	submitApi(payload) {
		return spbOrder.post(
			'/spb/spb_pofulfillment/submitpodataapi', payload
		);
	}
}
export default new SubmitPOService();
