import { encryptForm } from "../helper/encryptForm";
import batchListApi from "./api/SpbBindBatchApi";
class batchList {
  async getStatuswComp(payload) {
    return batchListApi.post("spb/batchList/statuswComp", payload);
  }
  async getLovStatus(payload) {
    return batchListApi.post("spb/batchList/getStatus", payload);
  }
  async getBatchListByColumn(
    column,
    value,
    page,
    limit,
    sortColumn,
    sortType,
    company,
    supplier
  ) {
    const sort = sortType === 1 ? "ASC" : "DESC";
    let result;
    if (sortColumn === undefined) {
      result = await batchListApi.get(
        `/spb/batchList/batchList/data/${column}/${value}?page=${page}&limit=${limit}&company=${company}&supplier=${supplier}`
      );
    } else {
      result = await batchListApi.get(
        `/spb/batchList/batchList/data${column}/${value}?page=${page}&limit=${limit}&sortColumn=${sortColumn}&sortType=${sort}&company=${company}&supplier=${supplier}`
      );
    }
    return result.data;
  }

  async getBatchListByColumnLength(column, value, company, supplier) {
    const result = await batchListApi.get(
      `/spb/batchList/batchList/${column}/${value}/length?company=${company}&supplier=${supplier}`
    );
    return result.data.length;
  }

  // async  getBatchListAll  (page, limit, sortColumn, sortType) {
  //     const sort = sortType === 1 ? 'ASC':'DESC';
  //     let result
  //     if (sortColumn === undefined) {
  //        result = await batchListApi.get(`/spb/batchList/batchList/data?page=${page}&limit=${limit}`)
  //     } else {
  //        result = await batchListApi.get(`/spb/batchList/batchList/data?page=${page}&limit=${limit}&sortColumn=${sortColumn}&sortType=${sort}`)
  //     }
  //     return result.data;
  // }

  async getBatchListAllLength() {
    const result = await batchListApi.get(`/spb/batchList/batchList/length`);
    return result.data.length;
  }
  async getDetailBatchListView(payload) {
    const result = await batchListApi.post(
      `/spb/batchList/detailBatchList/` + payload
    );
    return result.data.length;
  }
  async getBatchListAll(payload) {
    return batchListApi.post(`/spb/batchList/searchBatchTable/`, encryptForm(payload));
  }
  async getBatchList(batch_id) {
    return batchListApi.get(`/spb/batchList/detailBatchList/${batch_id}`);
  }
  async getBatchListSupp(batch_id, vendorId) {
    return batchListApi.get(
      `/spb/batchList/detailBatchListSupp?id=${batch_id}&vendorId=${vendorId}`
    );
  }
  // async  getReportPayment (payload) {
  //     return batchListApi.post(`/spb/batchList/searchReportPayment/` , payload)
  // }
  // async  getReportPaymenttoExcel (payload) {
  //     return batchListApi.post(`/spb/batchList/getDataExportToExcel/` , payload)
  // }
  // async getInvoiceNum(payload){
  //     return batchListApi.post('spb/batchList/getInvoiceNum', payload);
  // }

  // async getInvoiceNumComp(payload){
  //     return batchListApi.post('spb/batchList/getInvoiceNumComp', payload);
  // }

  // async  getReportPaymentSchedule (payload) {
  //     return batchListApi.post(`/spb/batchList/searchReportPaymentSchedule/` , payload)
  // }

  // async  getReportPaymentShceduletoExcel (payload) {
  //     return batchListApi.post(`/spb/batchList/exportReportPaymentSchedule/` , payload)
  // }
}

export default new batchList();
