<template>
	<div class="grid">
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<div class="col-12 p-fluid">
			<div class="card">
				<div class="grid formgrid" style="margin: 1em 0 0 0">
					<div class="col-12 mb-5 lg:col-4 lg:mb-5">
						<span class="p-float-label">
							<AutoComplete
								v-model="company"
								:suggestions="filteredCompany"
								@complete="searchCompany($event)"
								:dropdown="true"
								field="name"
								forceSelection
								:disabled="!isSupplierPage"
							>
								<template #item="slotProps">
									<div class="supplier-item">
										<div class="ml-2">
											{{ slotProps.item.name }}
										</div>
									</div>
								</template>
							</AutoComplete>
							<label for="inputtext">Company</label>
						</span>
					</div>
					<div
						v-if="!isSupplierPage"
						class="col-12 mb-5 lg:col-4 lg:mb-5"
					>
						<span class="p-float-label">
							<AutoComplete
								v-model="supplier"
								:suggestions="filteredSupplier"
								@complete="searchSupplier($event)"
								:dropdown="true"
								field="vendor_name"
								:disabled="companyValue"
								forceSelection
							>
								<template #item="slotProps">
									<div class="supplier-item">
										<div class="ml-2">
											{{ slotProps.item.vendor_name }}
										</div>
									</div>
								</template>
							</AutoComplete>
							<label for="inputtext">Supplier</label>
						</span>
					</div>
					<div class="col-12 mb-5 lg:col-4 lg:mb-5">
						<span class="p-float-label">
							<AutoComplete
								v-model="planningOrder"
								:suggestions="filteredPlanningOrder"
								@complete="searchPoNumber($event)"
								field="plan_order_num"
								:disabled="buttonDisable"
								forceSelection
							>
							</AutoComplete>
							<label for="inputtext">Planning Order No</label>
						</span>
					</div>
					<div class="col-12 mb-5 lg:col-4 lg:mb-5">
						<span class="p-float-label">
							<AutoComplete
								v-model="lov"
								:suggestions="filteredLov"
								@complete="searchLov($event)"
								:dropdown="true"
								field="name"
								:disabled="buttonDisable"
								forceSelection
							>
								<template #item="slotProps">
									<div class="lov-item">
										<div class="ml-2">
											{{ slotProps.item.name }}
										</div>
									</div>
								</template>
							</AutoComplete>
							<label for="inputtext">Status</label>
						</span>
					</div>
					<div
						class="col-12 mb-5 lg:col-4 lg:mb-5"
						v-if="!isSupplierPage"
					>
						<span class="p-float-label">
							<AutoComplete
								v-model="preparer"
								:suggestions="filteredPreparer"
								:disabled="preparerButton"
								@complete="searchPreparer($event)"
								:dropdown="true"
								field="user_name"
								forceSelection
							>
								<template #item="slotProps">
									<div class="preparer-item">
										<div class="ml-2">
											{{ slotProps.item.user_name }}
										</div>
									</div>
								</template>
							</AutoComplete>
							<label for="inputtext">Preparer</label>
						</span>
					</div>
					<div class="col-12 mb-2 lg:col-2 xl:col-1 lg:mb-5">
						<Button
							label="Go"
							@click="searchTableNested()"
							:disabled="this.buttonDisable"
						></Button>
					</div>
					<div class="col-12 mb-2 lg:col-2 xl:col-1 lg:mb-5">
						<Button
							class="p-button-secondary"
							label="Clear"
							@click="clearFilter()"
						></Button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- <div> {{sessionStorage.companydb}} </div> -->

	<div class="card">
		<DataTable
			class="fixed-scroll-datatable"
			:value="nested"
			:lazy="true"
			:paginator="true"
			:rows="rows"
			v-model:expandedRows="expandedRows"
			ref="dt"
			dataKey="id"
			v-model:filters="filters"
			:totalRecords="totalRecords"
			:loading="loading"
			@page="onPage($event)"
			@sort="onSort($event)"
			removableSort
			:rowsPerPageOptions="[10, 20, 30]"
			responsiveLayout="scroll"
			:scrollable="true"
			scrollHeight="600px"
			:v-model="first"
		>
			<template #empty> {{ isEmpty }} </template>
			<Column :expander="true" style="width: 100px; flex: 0 0 0" />
			<Column
				field="po_num"
				header="Planning Order No"
				:sortable="true"
			></Column>
			<template #expansion="slotProps">
				<div class="orders-subtable">
					<DataTable
						:value="slotProps.data.children"
						removableSort
						responsiveLayout="scroll"
						:scrollable="true"
						scrollHeight="600px"
						scrollDirection="both"
					>
						<template #empty> Data Detail Empty </template>
						<Column header="Line" style="width: 50px" frozen>
							<template #body="slotProps">
								{{ slotProps.index + 1 }}
							</template>
						</Column>
						<!-- <Column field="id" header="Line" :sortable="true" style="text-align: center"></Column> -->
						<Column
							field="itemnum"
							header="Item Number"
							:sortable="true"
							style="width: 15%"
						></Column>
						<Column
							field="itemdesc"
							header="Item Description"
							:sortable="true"
							style="width: 30%"
						></Column>
						<Column
							field="uom"
							header="UOM"
							:sortable="true"
							style="width: 12%"
						></Column>
						<Column
							field="ship"
							header="Location"
							:sortable="true"
							style="width: 15%"
						></Column>
						<Column
							field="currency"
							header="Currency"
							:sortable="true"
							style="width: 12%; justify-content: center"
						></Column>
						<!-- <Column field="cprice" header="Confirm Price" :sortable="true" style="text-align: center"></Column> -->
						<Column
							field="cprice"
							header="Confirm Price"
							dataType="numeric"
							style="width: 15%"
						>
							<template #body="{ data }">
								{{ formatCurrency(data.cprice) }}
							</template>
						</Column>
						<!-- <Column field="cqty" header="Confirm Qty" :sortable="true" style="text-align: center"></Column> -->
						<Column
							field="cqty"
							header="Confirm Qty"
							dataType="numeric"
							style="width: 12%"
						>
							<template #body="{ data }">
								{{ formatCurrency(data.cqty) }}
							</template>
						</Column>
						<Column
							sortField="cdate_sort"
							field="cdate"
							header="Confirm Arrival at Site"
							:sortable="true"
							style="width: 12%"
						>
							<!-- <template #body="slotProps">
								{{
										slotProps.data.cdate
								}}
							</template> -->
						</Column>
						<Column
							field="prnum"
							header="PR Number"
							:sortable="true"
							style="width: 15%"
						></Column>
						<Column
							field="ponum"
							header="PO Number"
							:sortable="true"
							style="width: 20%"
						></Column>
						<Column
							field="status"
							header="Line Status"
							:sortable="true"
							style="width: 12%"
						></Column>
						<Column
							field="jit_status"
							header="JIT Status"
							:sortable="true"
							style="width: 12%"
						></Column>
						<Column
							header="Detail"
							selectionMode="single"
							style="width: 12%"
							alignFrozen="right"
							frozen
						>
							<template #body="slotProps">
								<Button
									icon="pi pi-search"
									class="p-button-rounded p-button-text"
									@click="editChildren(slotProps.data)"
								/>
							</template>
						</Column>
					</DataTable>
				</div>
			</template>
		</DataTable>
	</div>
</template>

<script>
/* eslint-disable */
// import {FilterMatchMode,FilterOperator} from 'primevue/api';
import PlanningOrder from '../../service/PlanningOrderService';
// import { reactive, ref, toRef, unref } from '@vue/reactivity';
// import CustomerService from '../../service/CustomerService';
// import PlanningOrderService from '../../service/PlanningOrderService';
// import { watch } from '@vue/runtime-core';
import AuthService from '../../service/AuthService';
import LovService from '../../service/LovService';
import SimpleCrypto from 'simple-crypto-js';
export default {
	name: 'planningorder-list',
	data() {
		return {
			expandedRowGroups: null,
			isSupplierPage: false,
			first: 0,
			rowId: null,
			expandedRows: [],
			expandedRowGroups: [],
			nested: [],
			rows: 10,
			nestedchildren: [],
			groupdata: null,
			selectedChildren: null,
			loading: false,
			lazyParams: {},
			selectLazyParams: {},
			totalRecords: 0,
			totalRecordsGrup: 0,
			tabelexpand: null,
			company: null,
			companyValue: true,
			filteredCompany: null,
			isiCompany: null,
			supplier: null,
			isisupplier: null,
			filteredSupplier: null,
			selectedSupplier: null,
			supplierparse: null,
			search: null,
			filtered: null,
			preparer: null,
			isipreparer: null,
			filteredPreparer: null,
			preparerparse: null,
			selectedPreparer: null,
			lov: null,
			isilov: null,
			filteredLov: null,
			lovparse: null,
			selectedLov: null,
			planningOrder: null,
			isiPlanningOrder: null,
			filteredPlanningOrder: null,
			selectedPlanningOrder: null,
			button: 0,
			buttonDisable: true,
			buttonSearch: true,
			rowgroup: null,
			testname: null,
			onStorageUpdate: null,
			saveDb: null,
			preparerButton: true,
			suppBindToComp: [],
			poValue: null,
			isEmpty: null,
			suppName: null,
			// isSupplierPage:false,
			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [
				{ label: 'Planning Order', to: '/mgm/settings/planningorder' },
			],
		};
	},
	watch: {
		company(newQuestion) {
			if (this.company != null) {
				this.planningOrder = null;
				this.lov = null;
				this.preparer = null;
				this.companyValue = false;
				this.preparerButton = false;
				if (
					typeof newQuestion === 'object' &&
					!Array.isArray(newQuestion) &&
					newQuestion !== null
				) {
					this.companyValue = false;
					this.saveDb = this.company.db_instance;
					sessionStorage.setItem('companydb', this.saveDb);
					if (sessionStorage.leveltenant == 1) {
						this.getValueCompany(newQuestion.name);
						this.getPlenno(newQuestion.name);
						this.GetPreparer();
						this.buttonDisable = false;
					} else {
						this.getValueCompany(newQuestion.name);
					}
				}
			}
		},
		supplier(newQuestion) {
			if (
				this.planningOrder == null &&
				this.lov == null &&
				this.preparer == null
			) {
				this.lov = null;
				this.preparer = null;
				this.buttonSearch = false;
				if (
					typeof newQuestion === 'object' &&
					!Array.isArray(newQuestion) &&
					newQuestion !== null
				) {
					this.getValueSup(newQuestion.name);
					this.buttonDisable = false;
				}
			}
		},
		planningOrder(newQuestion) {
			if (this.supplier == null) {
				if (
					typeof newQuestion === 'object' &&
					!Array.isArray(newQuestion) &&
					newQuestion !== null
				) {
					this.getValuePoNum();
					this.buttonDisable = false;
				}
			}
		},
		lov(newQuestion) {
			// if (this.supplier == null && this.planningOrder == null && this.preparer == null) {
			this.buttonDisable = false;
			// }
		},
		preparer(newQuestion) {
			// if (this.supplier == null && this.planningOrder == null && this.lov == null) {
			this.buttonDisable = false;
			// }
		},
		poValue(newQuestion) {
			if (this.poValue != null) {
				this.getValueSup(newQuestion);
			}
		},
	},
	created() {
		sessionStorage.removeItem('tableSession');
		if (sessionStorage.leveltenant == 2) {
			this.getValueSup();
			let idUser = sessionStorage.getItem('idUser').toString();
			console.log('id', idUser);
			LovService.getSuppBindComp(idUser).then((response) => {
				this.suppBindToComp.push(response.data.data[0].vendor_id);
			});
		}
	},
	async mounted() {
		// CEK DULU TIPE USERNYA (purchasing/supplier/admin,dll)
		AuthService.whoAmi().then((resp) => {
			if (resp.status === 200) {
				this.getCompany();
				this.GetPreparer();
				this.GetLOV();
				if (resp.data.data.leveltenant == 2) {
					this.isSupplierPage = true;
				}
				this.isEmpty = 'Please Search for Data';
			}
		});
		// this.loading = true;
		this.lazyParams = {
			first: 0,
			sortField: null,
			sortOrder: null,
			rows: this.rows,
		};
		this.selectLazyParams = {
			first: 0,
			sortField: null,
			sortOrder: null,
			rows: this.rows,
		};
		if (sessionStorage.companydb) {
			this.testname = sessionStorage.companydb;
		}
	},
	computed: {
		searchUser() {
			const searchLc = this.search.toLowerCase();
			return this.nested.filter((item) => {
				return item.po_num.toLowerCase().includes(searchLc);
			});
		},
	},
	methods: {
		formatCurrency(value) {
			if (value) return value.toLocaleString('id-ID');
			return;
		},
		GetTableNested() {
			this.rows = 10;
			this.loading = true;
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					setTimeout(() => {
						PlanningOrder.GetTableNested({
							lazyEvent: JSON.stringify(this.lazyParams),
						}).then((response) => {
							this.nested = response.data.nestedData;
							this.totalRecords = response.data.totalRecords;
							this.loading = false;
							for (let id of this.nested) {
								for (let index of id.children) {
									index.cdate_sort = new Date(
										index.cdate
									).getTime();
									// index.cdate = id.confirm_ship_date?this.dateFormat2(id.confirm_ship_date):'';
									index.cdate = new Date(index.cdate);
									if (index.cdate == 'Invalid Date') {
										index.cdate = '';
									}
								}
							}
							// }
						});
					}, Math.random() * 1000 + 250);
				}
			});
		},
		async searchTableNested() {
			sessionStorage.setItem('mailToCompany', this.company.name);
			this.expandedRows = null;
			this.rows = 10;
			this.button = 1;
			this.loading = true;
			let supplierSearch = this.supplier;
			this.buttonDisable = true;
			if (this.supplier !== null) {
				supplierSearch = this.supplier.vendor_id;
			}
			let poSearch = this.planningOrder;
			if (this.planningOrder !== null) {
				poSearch = this.planningOrder.plan_order_num;
			}
			let statusSearch = this.lov;
			if (this.lov !== null) {
				statusSearch = this.lov.value;
			}
			let preparerSearch = this.preparer;
			if (this.preparer !== null) {
				preparerSearch = this.preparer.employee_id;
			}
			let org_id = this.company;
			if (this.company !== null) {
				if (sessionStorage.leveltenant == 1) {
					org_id = JSON.parse(this.company.value);
				}
				// else {
				//     org_id = this.company.id
				// }
				if (sessionStorage.leveltenant == 2) {
					org_id = this.company.value;
				}
			}
			const db_instance = this.company.db_instance
				? this.company.db_instance
				: null;
			const payload = {
				supplierSearch,
				poSearch,
				statusSearch,
				preparerSearch,
				org_id,
				db_instance,
			};
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					setTimeout(() => {
						PlanningOrder.getSearchTableNested({
							...this.selectLazyParams,
							...payload
						}).then((respond) => {
							if (respond.data.status == 200) {
								this.nested = respond.data.header;
								this.totalRecords = respond.data.totalRecords;
								this.loading = false;
								for (let id of this.nested) {
									for (let index of id.children) {
										if (
											index.cdate == null ||
											index.cdate == 'Invalid Date'
										) {
											index.cdate = '';
										} else {
											index.cdate_sort = new Date(
												index.cdate
											).getTime();
											index.cdate = new Date(
												index.cdate
											).toLocaleDateString('id-ID', {
												year: 'numeric',
												month: 'short',
												day: 'numeric',
											});
										}
									}
								}
								this.buttonDisable = false;
							} else if (respond.data.status == 202) {
								this.nested = null;
								this.totalRecords = null;
								this.isEmpty = 'No Data Found';
								this.loading = false;
								this.buttonDisable = false;
							}
						});
					}, Math.random() * 1000 + 250);
				}
			});
		},
		getCompany() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					if (value.data.data.leveltenant == 1) {
						if (value.data.data.userid == 'dexaadmin') {
							LovService.getValueType('COMPANY').then(
								(response) => {
									this.isiCompany = response.data.data;
								}
							);
						} else {
							let payload = value.data.data.bioaddress;
							LovService.getcompanydb(payload).then(
								(response) => {
									// this.isiCompany = response.data.data
									// let payload = {isi : response.data.data[0].company}
									// PlanningOrder.getCompanyId(payload).then(response => {
									this.isiCompany = response.data.data;
									try {
										const secretKey = 'some-unique-key';
										const simpleCrypto = new SimpleCrypto(
											secretKey
										);
										const decipherText =
											simpleCrypto.decrypt(
												sessionStorage.getItem(
													'dropdownCompany'
												)
											);
										this.isiCompany.forEach((element) => {
											if (element.name === decipherText) {
												this.company = element;
												// this.searchTableNested();
												throw 'Break';
											}
										});
									} catch (e) {
										if (e !== 'Break') throw e;
									}
								}
							);
						}
					} else if (value.data.data.leveltenant == 2) {
						let payload = value.data.data.id;
						// PlanningOrder.getCompanySupp(payload).then(
						// 	(response) => {
						// 		let company = [
						// 			{ name: response.data.data[0].company },
						// 		];
						// 		this.isiCompany = company;
						// 	}
						// );
						PlanningOrder.getCompanySupp(payload).then(
							(response) => {
								let payload = response.data.data;
								let companyList = [];
								try {
									payload.forEach((element) => {
										LovService.getCompanyByName(
											element.company
										).then((response) => {
											companyList.push(
												response.data.data[0]
											);
											const secretKey = 'some-unique-key';
											const simpleCrypto =
												new SimpleCrypto(secretKey);
											const decipherText =
												simpleCrypto.decrypt(
													sessionStorage.getItem(
														'dropdownCompany'
													)
												);
											// let companyList = JSON.parse(sessionStorage.getItem('list'))
											// this.isiCompany = companyList;
											if (
												response.data.data[0].name ===
												decipherText
											) {
												this.company =
													response.data.data[0];
												// this.searchTableNested();
												this.getValueSup();
											}
										});
									});
									this.isiCompany = companyList;
								} catch (e) {
									if (e !== 'Break') throw e;
								}
							}
						);
					}
				}
			});
		},
		getValueCompany() {
			let payload = {
				db_instance: this.company.db_instance,
				org_id: this.company.value,
			};
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					LovService.getSupplierName(payload).then((response) => {
						this.isisupplier = response.data.data;
						if (sessionStorage.getItem('leveltenant') == 2) {
							try {
								this.isisupplier.forEach((element) => {
									// console.log('isi supp bind',this.suppBindToComp)
									if (
										element.vendor_id ===
										this.suppBindToComp[0]
									) {
										this.supplier = element;
										this.buttonDisable = false;
										throw 'Break';
									}
									if (
										this.suppBindToComp[0] === [] ||
										this.suppBindToComp[0] === 0
									) {
										this.$router.push('/dashboard');
										window.alert(
											'Connection Unstable, ID not Recognize'
										);
									}
								});
							} catch (e) {
								if (e !== 'Break') throw e;
							}
						}
					});
				}
			});
		},
		async getPlenno() {
			const payload = {
				org_id: this.company.value,
				db_instance: this.company.db_instance,
			};
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					PlanningOrder.getSearchDropdownComp(payload).then(
						(response) => {
							this.isiPlanningOrder = response.data.data;
						}
					);
				}
			});
		},
		getValueSup() {
			this.buttonDisable = AuthService.whoAmi().then((value) => {
				if (value.data.data.leveltenant == 1) {
					const payload = {
						supValue: this.supplier.vendor_id,
						org_id: this.company.value,
						db_instance: this.company.db_instance,
					};
					PlanningOrder.getSearchDropdownSupp(payload).then(
						(response) => {
							this.isiPlanningOrder = response.data.data;
						}
					);
				} else {
					const payload = {
						vendorName: this.poValue,
					};
					PlanningOrder.getSuppValue(payload).then((response) => {
						this.suppName = response.data.data;
					});
					const payloadSupp = {
						supValue: this.suppBindToComp[0],
						org_id: this.company.value,
						db_instance: this.company.db_instance,
					};
					PlanningOrder.getSearchDropdownSupp(payloadSupp).then(
						(response) => {
							this.isiPlanningOrder = response.data.data;
						}
					);
				}
			});
		},
		// getValueSup() {
		// 	AuthService.whoAmi().then((value) => {
		// 		if (value.data.data.leveltenant == 1) {
		// 				const payload = {
		// 				supValue: this.supplier.vendor_id,
		// 				org_id: this.company.value,
		// 			};
		// 			PlanningOrder.getSearchDropdownSupp(payload).then(
		// 				(response) => {
		// 					this.isiPlanningOrder = response.data.data;
		// 				}
		// 			);
		// 		}
		// 		else {
		// 			const secretKey = 'some-unique-key';
		// 			const simpleCrypto = new SimpleCrypto(secretKey);
		// 			const decipherText = simpleCrypto.decrypt( sessionStorage.getItem ( 'dropdownCompany'
		// 										)
		// 									);
		// 				const payload = {
		// 				supValue: this.poValue,
		// 				org_id: decipherText,
		// 			};
		// 			PlanningOrder.getSearchDropdownSupp(payload).then(
		// 				(response) => {
		// 					this.isiPlanningOrder = response.data.data;
		// 				}
		// 			);
		// 		}
		// 	});
		// },
		getPoNumber() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					PlanningOrder.getPoNumber().then((response) => {
						this.isiPlanningOrder = response.data.data;
					});
				}
			});
		},
		getValuePoNum() {
			const payload = { poValue: this.planningOrder.plan_order_num };
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					PlanningOrder.getSearchDropdownPoNum(payload).then(
						(response) => {
							this.isisupplier = response.data.data;
						}
					);
				}
			});
		},
		GetLOV() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					PlanningOrder.GetLovStatus().then((response) => {
						this.isilov = response.data.data;
					});
				}
			});
		},
		GetPreparer() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					let payload = { isi: sessionStorage.companydb };
					PlanningOrder.GetPreparer(payload).then((response) => {
						this.isipreparer = response.data.data;
					});
				}
			});
		},
		getPlanningOrder() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					PlanningOrder.getPlanningOrder().then((response) => {
						this.isipreparer = response.data.data;
					});
				}
			});
		},
		searchSupplier(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredSupplier = [...this.isisupplier];
				} else {
					this.filteredSupplier = this.isisupplier.filter((item) => {
						if (item.vendor_name !== null) {
							return item.vendor_name
								.toLowerCase()
								.match(event.query.toLowerCase());
						}
					});
				}
			}, 250);
		},
		async searchCompany(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredCompany = [...this.isiCompany];
				} else {
					this.filteredCompany = this.isiCompany.filter((item) => {
						return item.name
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		searchPoNumber(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredPlanningOrder = [...this.isiPlanningOrder];
				} else {
					this.filteredPlanningOrder = this.isiPlanningOrder.filter(
						(item) => {
							if (item.vendor_name !== null) {
								return item.plan_order_num
									.toLowerCase()
									.match(event.query.toLowerCase());
							}
						}
					);
				}
			}, 250);
		},
		searchLov(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredLov = [...this.isilov];
				} else {
					this.filteredLov = this.isilov.filter((item) => {
						return item.name
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		searchPreparer(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredPreparer = [...this.isipreparer];
				} else {
					this.filteredPreparer = this.isipreparer.filter((item) => {
						return item.user_name
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		// onPage(event) {
		//     this.GetTableNested();
		//     switch(true){
		//     //     //nggak cari apa-apa
		//     //     case this.supplier === null && this.planningOrder === null && this.lov === null && this.preparer === null && this.button === 0 :
		//     //         this.lazyParams = event;
		//     //         this.GetTableNested();
		//     //     break;
		//          //semuanya
		//         case this.company !== null || this.supplier !== null || this.planningOrder !== null || this.lov !== null || this.preparer !== null && this.button === 1 :
		//             this.selectLazyParams = event;
		//             this.searchTableNested();
		//         break;
		//     }
		// },
		onSort(event) {
			if (this.button !== 0) {
				switch (true) {
					case this.supplier === null &&
						this.planningOrder === null &&
						this.lov === null &&
						this.preparer === null:
						this.lazyParams = event;
						// this.GetTableNested();
						break;
					case this.company !== null ||
						this.supplier !== null ||
						this.planningOrder !== null ||
						this.lov !== null ||
						(this.preparer !== null && this.button === 1):
						this.selectLazyParams = event;
						this.searchTableNested();
						break;
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Search Data First',
					life: 3000,
				});
			}
		},
		editChildren(rowIndex) {
			this.rowId = rowIndex;
			if (sessionStorage.leveltenant == 2) {
				this.$router.push({
					name: 'planningOrderDetailSupp',
					params: { id: this.rowId.id },
				});
			} else {
				this.$router.push({
					name: 'planningOrderDetail',
					params: { id: this.rowId.id },
				});
				// this.$router.push({name: 'planningOrderDetail', params: { id: this.rowId.plan_order_id, po_num : this.rowId.plan_order_num }});
			}
		},
		expandAll() {
			this.expandedRowGroups = this.rowgroup.filter(
				(p) => p.plan_order_num
			);
			this.expandedRows = this.nested.filter((p) => p.po_num);
			this.$toast.add({
				severity: 'success',
				summary: 'All Rows Expanded',
				life: 3000,
			});
		},
		testSession() {
			if (localStorage.companydb !== null) {
				// this.testname = sessionStorage.companydb;
			}
		},
		onPage(event) {
			switch (true) {
				//nggak cari apa-apa
				case this.supplier === null &&
					this.planningOrder === null &&
					this.lov === null &&
					this.preparer === null &&
					this.button === 0:
					this.lazyParams = event;
					// this.GetTableNested();
					break;
				//semuanya
				case this.company !== null ||
					this.supplier !== null ||
					this.planningOrder !== null ||
					this.lov !== null ||
					(this.preparer !== null && this.button === 1):
					this.selectLazyParams = event;
					this.searchTableNested();
					break;
			}
		},
		clearFilter() {
			this.buttonSearch = true;
			this.expandedRows = null;
			this.planningOrder = null;
			if (sessionStorage.leveltenant == 2) {
			} else {
				this.supplier = null;

				if (sessionStorage.idUser == 2) {
				}
			}
			this.lov = null;
			this.preparer = null;
			this.GetPreparer();
			this.GetLOV();
			this.getPoNumber();
			this.getValueCompany();
		},
		// editChildren(rowIndex) {
		// 	this.rowId = rowIndex;
		// 	if (sessionStorage.leveltenant == 2) {
		// 		this.$router.push({
		// 			name: 'planningOrderDetailSupp',
		// 			params: { id: this.rowId.id },
		// 		});
		// 	} else {
		// 		this.$router.push({
		// 			name: 'planningOrderDetail',
		// 			params: { id: this.rowId.id },
		// 		});
		// 	}
		// },
		initFilters() {
			this.filters = {
				ship: { value: null, matchMode: FilterMatchMode.CONTAINS },
			};
		},
	},
};
</script>
<style scoped lang="scss">
// ::selection{
//     background-color: rgba(0,0,0,.15) !important;
// }
p-selectable-row p-highlight {
	background-color: rgba(0, 0, 0, 0.15) !important;
}
// input{
//     text-align: right;
// }
</style>
