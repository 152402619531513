<template>
	<Card>
		<template #title>
			<p
				class="text-xl"
				v-if="currentUser && currentUser.leveltenant === 2"
			>
				Top 5 Ordered Item Number
			</p>
			<p class="text-xl" v-else>Top 5 Planning Order Supplier</p>
		</template>
		<template #content>
			<div class="grid" v-if="items && !isLoading">
				<!-- Actual chart -->
				<div class="col-12 lg:col-6">
					<Chart
						type="doughnut"
						:data="chartData"
						:options="chartOptions"
						width="100%"
						height="100%"
					/>
				</div>
				<!-- Chart labels and details -->
				<div class="col-12 lg:col-6 pl-6">
					<div class="flex flex-column flex-wrap">
						<!-- Label 1 -->
						<div class="flex align-items-center mb-4">
							<Badge class="chart-badge chart-badge-1" />
							<div class="ml-3">
								<span class="block mb-1">
									{{ items[0].name }}
								</span>
								<span class="block">
									<span class="font-bold">
										{{
											Math.ceil(
												items[0].percentage * 100
											)
										}}%
									</span>
									<span class="text-sm">
										({{ items[0].count }} PO)
									</span>
								</span>
							</div>
						</div>
						<!-- Label 2 -->
						<div class="flex align-items-center mb-4">
							<Badge class="chart-badge chart-badge-2" />
							<div class="ml-3">
								<span class="block mb-1">
									{{ items[1].name }}
								</span>
								<span class="block">
									<span class="font-bold">
										{{
											Math.ceil(
												items[1].percentage * 100
											)
										}}%
									</span>
									<span class="text-sm">
										({{ items[1].count }} PO)
									</span>
								</span>
							</div>
						</div>
						<!-- Label 3 -->
						<div class="flex align-items-center mb-4">
							<Badge class="chart-badge chart-badge-3" />
							<div class="ml-3">
								<span class="block mb-1">
									{{ items[2].name }}
								</span>
								<span class="block">
									<span class="font-bold">
										{{
											Math.ceil(
												items[2].percentage * 100
											)
										}}%
									</span>
									<span class="text-sm">
										({{ items[2].count }} PO)
									</span>
								</span>
							</div>
						</div>
						<!-- Label 4 -->
						<div class="flex align-items-center mb-4">
							<Badge class="chart-badge chart-badge-4" />
							<div class="ml-3">
								<span class="block mb-1">
									{{ items[3].name }}
								</span>
								<span class="block">
									<span class="font-bold">
										{{
											Math.ceil(
												items[3].percentage * 100
											)
										}}%
									</span>
									<span class="text-sm">
										({{ items[3].count }} PO)
									</span>
								</span>
							</div>
						</div>
						<!-- Label 5 -->
						<div class="flex align-items-center">
							<Badge class="chart-badge chart-badge-5" />
							<div class="ml-3">
								<span class="block mb-1">
									{{ items[4].name }}
								</span>
								<span class="block">
									<span class="font-bold">
										{{
											Math.ceil(
												items[4].percentage * 100
											)
										}}%
									</span>
									<span class="text-sm">
										({{ items[4].count }} PO)
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Loading -->
			<div class="flex items-center justify-center" v-else>
				<ProgressSpinner style="width: 50px" />
			</div>
		</template>
		<!-- <template #footer>
			<router-link class="font-bold" to="#">
				<span class="mr-2">See details</span>
				<i class="pi pi-arrow-right"></i>
			</router-link>
		</template> -->
	</Card>
</template>

<script>
import PlanningOrderService from '../../../service/PlanningOrderService';
import LovService from '../../../service/LovService';

export default {
	name: 'MostOrderedChart',
	props: ['currentUser'],
	data() {
		return {
			chartData: null,
			items: null,
			chartOptions: null,
			isLoading: null,
		};
	},
	created() {
		if (this.currentUser && this.currentUser.leveltenant === 2) {
			this.getMostItems(this.currentUser.id);
		} else {
			this.getMostSuppliers();
		}
	},
	methods: {
		async getMostItems(userId) {
			this.isLoading = true;
			let id = sessionStorage.getItem("vendorId");
			id = id ? id : sessionStorage.getItem("dataVendorId");
			const res = await PlanningOrderService.getMostOrderedItems(userId,id);
			this.items = res.data.data;
			this.setChartData(res.data.data);
			this.isLoading = false;
		},
		async getMostSuppliers() {
			this.isLoading = true;

			const respCompany = await LovService.getCompanyByName(
				sessionStorage.getItem('mailToCompany')
			);

			const org_id = respCompany.data.data[0].value;
			const db_instance = respCompany.data.data[0].db_instance

			console.log("RESP COMPANY", respCompany)

			const res = await PlanningOrderService.getMostOrderedSuppliers({
				org_id,
				db_instance
			});
			this.items = res.data.data;
			this.setChartData(res.data.data);
			this.isLoading = false;
		},
		setChartData(theData) {
			// Set chart options
			(this.chartOptions = {
				responsive: true,
				plugins: {
					tooltitp: {
						callbacks: {
							label: function (context) {
								let label = context.dataset.label || '';

								if (label) {
									label += ': ';
								}
								if (context.parsed.y !== null) {
									label += new Intl.NumberFormat('en-US', {
										style: 'currency',
										currency: 'USD',
									}).format(context.parsed.y);
								}
								return label;
							},
						},
					},
				},
			}),
				// Set to chartData
				(this.chartData = {
					datasets: [
						{
							data: [
								theData[0].count,
								theData[1].count,
								theData[2].count,
								theData[3].count,
								theData[4].count,
							],
							backgroundColor: [
								'#850600',
								'#DA3831',
								'#FF6D67',
								'#E16F6A',
								'#FFA09C',
							],
							hoverBackgroundColor: [
								'#850600',
								'#DA3831',
								'#FF6D67',
								'#E16F6A',
								'#FFA09C',
							],
						},
					],
				});
		},
	},
};
</script>

<style scoped>
.chart-badge {
	min-width: 0.75rem !important;
	height: 0.75rem !important;
}

.chart-badge.chart-badge-1 {
	background: #850600 !important;
}

.chart-badge.chart-badge-2 {
	background: #da3831 !important;
}

.chart-badge.chart-badge-3 {
	background: #ff6d67 !important;
}

.chart-badge.chart-badge-4 {
	background: #e16f6a !important;
}

.chart-badge.chart-badge-5 {
	background: #ffa09c !important;
}
</style>
