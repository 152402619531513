<template>
	<AutoComplete
		v-model="selectedCategory"
		:suggestions="filteredCategory"
		@complete="searchCategory($event)"
		:dropdown="true"
		:disabled="!category.length"
		field="CATEGORY_NAME"
		forceSelection
	/>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import AuthService from '../../../service/AuthService';
import MasterCategory from '../../../service/MasterCategory';

// eslint-disable-next-line no-undef
const props = defineProps({
	company: { type: Object },
});
// // eslint-disable-next-line no-undef
const filteredCategory = ref([]);
const category = ref([]);

onMounted(() => {
	populateData();
});

const populateData = () => {
	AuthService.whoAmi().then(async (response) => {
		if (response.status === 200) {
			let payload;
			if (props.company !== null) {
				payload = {
					db_instance: props.company.db_instance,
					org_id: props.company.value,
				};
				const res = await MasterCategory.getCategory(payload);

				if (res.status === 200) {
					category.value = res.data.data;
				}
			} else {
				category.value = [];
			}
		}
	});
};

const searchCategory = (event) => {
	if (!event.query.trim().length) {
		filteredCategory.value = [...category.value];
	} else {
		filteredCategory.value = category.value.filter((comp) => {
			return comp.CATEGORY_NAME.toLowerCase().match(
				event.query.toLowerCase()
			);
		});
	}
};
</script>

<style scoped></style>
