const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
// const rounded = (round) => {
//     return Math.round(Math.random() * (round ? round * 10 : 10));
// }

const ptList = [
    "NITYO INFOTECH, PT",
    "TATARASA PRIMATAMA, PT",
    "INDOGRAVURE, PT",
    "MENJANGAN SAKTI, PT",
    "Bahtera Sentra Niagatama, PT",
    "MEGASETIA AGUNG KIMIA, PT",
    "ESPERA SATYA, PT",
    "SATYA SAMITRA NIAGATAMA, PT.",
    "AVESTA CONTINENTAL PACK, PT",
    "MIDEXDHARMA AGUNG, PT",
    "KATI KARTIKA MURNI, PT",
    "ADHIJAYA SATMIKA NUSANTARA, PT",
    "UNIJAYA PRATAMA, PT",
    "UTAMA ADHIKREASI, PT",
    "BRENNTAG, PT",
    "SIGNA HUSADA, PT",
    "PRAMBANAN KENCANA, PT",
    "SINAR JAKARTA PRINT, PT",
    "TIGAKA DISTRINDO PERKASA, PT",
    "PERUSAHAAN DAGANG DAN INDUSTRI WARIS, PT",
    "MEGA PUTRA, PT",
    "PENJALINDO NUSANTARA, PT",
    "EXTRUPACK, PT",
    "DNP INDONESIA, PT",
    "KONVERTA MITRA ABADI, PT",
    "MEGAH SEJAHTERA SCIENTIFIC, PT",
    "GLOBAL DEWATA INTERNUSA, PT",
    "HARPA SEKAWAN, PT",
    "Endress+Hauser Indonesia, PT",
    "ENAM DUNIA WISATA, PT",
    "SMAILING TOURS & TRAVEL SERVICE, PT",
    "7 STAR LOGISTICS, PT",
    "JEBEA CARLOS, PT",
    "DSV TRANSPORT INDONESIA, PT",
    "ALDMIC INDONESIA, PT",
    "DARYA-VARIA LABORATORIA TBK, PT",
    "LABEL INDONESIA, PT",
    "RADIO PTPN RASITANIA",
    "DHL GLOBAL FORWARDING INDONESIA, PT.",
    "CARDINDO CITRABUANA, PT",
    "JASA ANGKASA SEMESTA TBK, PT",
    "Schenker Petrolog Utama, PT",
    "PUNINAR YUSEN LOGISTICS INDONESIA, PT",
    "HANKYU HANSHIN EXPRESS INDONESIA, PT",
    "GARUDA INDONESIA, PT",
    "SANGGRAHA PROPERTI MANAJEMEN, PT",
    "PRIMA INTERNATIONAL CARGO,PT",
    "MASAJI TATANAN CONTAINER,PT",
    "PHARMA TEST INDONESIA, PT",
    "AIRIN, PT",
    "TRANSPORINDO LIMA PERKASA, PT",
    "CEVA FREIGHT INDONESIA, PT",
    "SHIPCO TRANSPORT INDONESIA, PT",
    "ANGKASA PURA SOLUSI, PT",
    "LAUTAN TIRTA TRANSPORTAMA, PT",
    "MULTI TERMINAL INDONESIA, PT",
    "MUSTIKA ALAM LESTARI, PT",
    "PELABUHAN INDONESIA II (PERSERO), PT",
    "VARUNA LINTAS SARANA LOGISTIK, PT",
    "KLINE MOBARU DIAMOND INDONESIA, PT",
    "SARANA PUBLIC LOGISTICS, PT",
    "LOGISTIC SOLUTION INDONESIA, PT",
    "PELANGI INTERNATIONAL LOGISTICS, PT",
    "AGILITY INTERNATIONAL, PT",
    "DUNIA EXPRESS TRANSINDO, PT",
    "BHUM MULIA PRIMA, PT",
    "RICAKUSUMA LESTARI ABADI, PT",
    "WIDI MEGA LOGISTIC, PT",
    "SEACON TERMINAL, PT",
    "LOGWIN AIR & OCEAN INDONESIA, PT",
    "SENTRA SINAR BARU, PT",
    "GHITA AVIA TRANS, PT",
    "GLORIUS INTERBUANA, PT",
    "JUPITER LOGISTICS INDONESIA, PT",
    "BERDIKARI LOGISTIK INDONESIA, PT",
    "GFC INDONESIA TERMINAL, PT",
    "FREIGHT LINER INDONESIA, PT",
    "IPC TERMINAL PETIKEMAS, PT",
    "PRIMANATA JASA PERSADA, PT",
    "MOL LOGISTICS INDONESIA, PT",
    "DEPO LAUTAN NUSANTARA, PT",
    "APL CO. PTE LTD.",
    "LOGISTIC INTERNATIONAL CARGO, PT",
    "YUSEN LOGISTICS INDONESIA, PT",
    "SOLUSI EXPRESS LOGISTICS, PT",
    "SINERGI SUKSES LOGISTIK, PT",
    "DHARMA YUDHA PRATAMA, PT",
    "DEXTER EUREKATAMA, PT",
    "MITRA ADIRA UTAMA,PT",
    "SINAR BARU LOGISTIK, PT",
    "PERIGI RAJA TERPADU, PT",
    "AGILITY, PT",
    "BANGUNDESA LOGISTINDO, PT",
    "ANGKASA PURA KARGO, PT",
    "PERUSAHAAN PELAYARAN NUSANTARA PANURJWAN,PT",
    "HYPER MEGA SHIPPING, PT",
    "GAPURA RAYA, PT",
    "DFDS TRANSPORT INDONESIA, PT",
    "SEGARA PACIFIC MAJU, PT",
    "GATEWAY CONTAINER LINE, PT",
    "INDOMARSAN UTAMA INTERNATIONAL, PT",
    "LESCHACO LOGISTIC INDONESIA, PT.",
    "WIRA MITRA PRIMA, PT",
    "BAHARI CAHAYA RAYA INDONESIA, PT",
    "PELOPOR PRATAMA LANCAR ABADI, PT.",
    "GLOBAL TERMINAL MARUNDA, PT",
    "SURYA KENCANA GEMILANG, PT",
    "PRATHAMA LINE LOGISTICS, PT",
    "CONTAINER MARITIME ACTIVITIES, PT",
    "SEACON LOGISTIK, PT",
    "BOLLORE LOGISTICS INDONESIA, PT",
    "UNEX RAJAWALI INDONESIA, PT",
    "NEW PRIOK CONTAINER TERMINAL OKE, PT",
    "NAKU FREIGHT INDONESIA, PT",
    "MACOLINE INDONESIA, PT",
    "DYNAMIC CONTAINER LINES, PT",
    "DUNIA LOGISTIK, PT",
    "QUAD KONTENA LOGISTICS, PT",
    "DEPO PETIKEMAS EXPRESSINDO, PT",
    "DELTA KONTAINER DEPOT, PT",
    "YANG MING SHIPPING INDONESIA, PT",
    "DAYA ANUGERAH SEMESTA, PT",
    "WAHANA DATARINDO SEMPURNA, PT",
    "JEBSEN JESSEN INGREDIENTS INDONESIA, PT",
    "GLOBAL CHEMINDO MEGATRADING, PT",
    "ARISTOCRAT CIPTA MANDIRI, PT",
    "FARMARINDO JAYA, PT",
    "JAYATAMA SELARAS, PT.",
    "KANGAR CONSOLIDATED INDUSTRIES, PT",
    "MERINDO MAKMUR, PT",
    "MASTER LABEL, PT",
    "SRI AMAN CORPORINDO, PT",
    "INDESSO NIAGATAMA, PT",
    "ALFORM INDONESIA, PT",
    "NARDA TITA, PT",
    "SCHOTT IGAR GLASS, PT",
    "SINAR GOLDSINDO, PT",
    "UNIFLEX KEMASINDAH, PT",
    "TOTAL PACK INDONESIA, PT",
    "KINAS GLOBAL INDONUSA, PT",
    "INDUSTRI PEMBUNGKUS INTERNASIONAL, PT",
    "EKACITTA DIAN PERSADA, PT",
    "SATYAMITRA LABELJAYA PRATAMA, PT",
    "ALTINEX, PT",
    "TITIAN ABADI LESTARI, PT",
    "IMPACT INDONESIA, PT",
    "BERCA SCHINDLER LIFTS, PT.",
    "ELFRIDA PLASTIK INDUSTRI, PT",
    "ROYAL SULTAN AGUNG, PT.",
    "DWI MITRA TEKNINDO, PT",
    "IMCD INDONESIA, PT",
    "AGARINDO BIOLOGICAL COMPANY, PT",
    "INDO ACIDATAMA, PT",
    "PRISMAS JAMINTARA, PT",
    "APLIKANUSA LINTASARTA, PT",
    "OPTUS SOLUTION, PT",
    "NASIONAL SARANA, PT",
    "ELECTRONIC DATA INTERCHANGE INDONESIA, PT",
    "IFORTE SOLUSI INFOTEK, PT",
    "GRAND ZURI CIKARANG, PT",
    "INDOTAMA ARKA MEDIA, PT",
    "DUTA SARANA INOVASI, PT",
    "GUNDALA BIMA KARYA, PT",
    "BETA PHARMACON, PT",
    "TIRTA VARIA INTIPRATAMA, PT",
    "TELEKOMUNIKASI INDONESIA TBK, PT",
    "HOSANNA FREIGHT & LOGISTICS PTE LTD",
    "FEDEX EXPRESS INTERNATIONAL, PT",
    "INTISUMBER HASIL SEMPURNA GLOBAL, PT",
    "MANGGALA KIAT ANANDA, PT",
    "PHINISI SULAWESI, PT",
    "TELKOM INDONESIA (PERSERO) Tbk, PT",
    "Supra Primatama Nusantara, PT",
    "PT SOLUSINDO BINTANG PRATAMA",
    "TRANSFREIGHT LOGISTICS, PT",
    "GIOVANNA LOGISTICS, PT",
    "STUDIO ILMI INDONESIA, PT",
    "NUSANTARA COMPNET INTGRT, PT",
    "CYBERINDO ADITAMA, PT",
    "Ibra Syawal Purnama, PT",
    "GRAND ZURI,  PT",
    "TG SUMBARINDO WISATA, PT",
    "INTI NYA TEKNOLOGI, PT",
    "TREES SOLUTIONS, PT",
    "KARLIN MASTRINDO, PT",
    "LINK NET Tbk, PT.",
    "XL AXIATA TBK., PT",
    "GEODIS WILSON INDONESIA, PT",
    "PRIMA HIJAU LESTARI, PT",
    "GRAB TEKNOLOGI INDONESIA, PT",
    "TRIDAYA SINERGI MANDIRI .PT",
    "IndoInternet .PT",
    "NUSANTARA DIGITAL KONSULTAN, PT",
    "PT. IDX CONSULTING",
    "ANUGRAH ARGON MEDICA, PT",
    "TECHSCAPE DATA CENTER, PT",
    "BLUE BIRD TAKSI, PT",
    "POLARISWEB, PT",
    "TRIPUTRA HOTEL INDONESIA PT",
    "LINTAS MEDIA SOLUTION, PT",
    "PUSAT DIGITAL INDONESIA, PT",
    "SARANA TITAN MANUNGGAL, PT",
    "BLUE BIRD TAXI, PT",
    "KALYANA ADIKARYA ABADI, PT",
    "DRS INNOVATIONS INTERNATIONAL, PT",
    "PT ANDARA PANGAN SELARAS",
    "SOLUSI ANDALAN TERDEPAN, PT",
    "INNOVATION HEALTHCARE INDONESIA, PT",
    "EQUILAB INTERNATIONAL, PT",
    "DATA MEGA SOLUSI, PT",
    "ANDALIRA CIPTA ABADI, PT",
    "LABODIA PRIMA, PT",
    "KARYA DAYA SYAFARMASI, PT",
    "DKSH Indonesia, PT",
    "AZELIS INDONESIA DISTRIBUSI  PT",
    "INDOMOBIL PRIMA ENERGI, PT",
    "ARIANTO DARMAWAN, PT",
    "FESTO, PT",
    "SALAM JAYA UNITED, PT",
    "JAYA TEKNIK INDONESIA, PT",
    "BERKAT NIAGA DUNIA, PT",
    "DATASCRIP, PT",
    "NILA MERKINDO UTAMA, PT",
    "MARGAMAS CITRATAMA, PT",
    "DEKA ADHINUSA, PT",
    "CAHAYA LESTARI SRIWIJAYA, PT",
    "ARTHA INTI MANUNGGAL, PT",
    "DELTASINDO RAYA SEJAHTERA, PT",
    "ESTU KARYA UTAMA GRAFIKA, PT",
    "ERA BARU AKURASINDO, PT",
    "JAYA SAKTI INTERNUSA, PT",
    "SIMENTEKNINDO, PT",
    "INTIKOM BERLIAN MUSTIKA, PT",
    "BANGUN PERSADA TEHNIK ENERGY, PT",
    "ARZYFA RAJA INDOTEKNIK, PT",
    "DEXA ENERGY, PT",
    "INNOVASI SARANA GRAFINDO, PT",
    "MULTIPRIMA INDOSEJAHTERA, PT",
    "Doxa Manggalya Utama, PT",
    "DIAN WAHYU PRATAMA, PT",
    "ITS SCIENCE INDONESIA, PT",
    "AIR LIQUIDE INDONESIA ,PT",
    "HENSAN BERSAMA SUKSES, PT",
    "OMEGA INTERBUANA CHEMINDO, PT",
    "PAPANDAYAN COCOA INDUSTRIES, PT",
    "ATLAS COPCO INDONESIA, PT",
    "TIGA SRIKANDI JAYA, PT",
    "Indo Kompresigma, PT",
    "GELORA MANDIRI TEHNIK, PT",
    "TUPPERWARE INDONESIA, PT",
    "Global Satria Aji. PT",
    "Hilab Sciencetama.PT",
    "BERKAT AIR HIDUP ABADI, PT",
    "BAHTERA ADI JAYA, PT",
    "ALTRAK 1978, PT",
    "AKHDAN GLOVAC SEJATI, PT",
    "MERCK CHEMICALS AND LIFE SCIENCES (MCLS), PT",
    "GENETIKA SCIENCE INDONESIA, PT",
    "EGAMEKINKA PRATAMA, PT.",
    "LESTARI MITRA NIAGA, PT",
    "CRODA TRADING INDONESIA, PT",
    "Omni Kemas Industry, PT",
    "KAWAN LAMA SEJAHTERA, PT",
    "GREEN INNOVATION SEJATI, PT",
    "ESAJAYA SERASI, PT",
    "BTREE FIRST INDONESIA, PT",
    "CHEMCO PRIMA MANDIRI, PT",
    "MANDIRI SUKSES SENTOSA, PT",
    "HARTECH INDONESIA, PT",
    "GANDASARI EKASATYA, PT",
    "DBFF BOTON INDONESIA, PT",
    "UNITED CHEMICALS INTER ANEKA, PT",
    "Gapa Citra Mandiri, PT",
    "TRANSINDOTAMA SINAR PERKASA, PT",
    "IMAS ASRI MULIA, PT",
    "JOTAM, PT",
    "AIS MANDIRI SEJAHTERA, PT",
    "USAHA SAUDARA MANDIRI, PT",
    "MULTI DATA PALEMBANG, PT",
    "INDO MANDIRI SENTOSA, PT",
    "ADHITYA ANDREBINA AGUNG, PT",
    "KRISBOW INDONESIA, PT",
    "MISUMI INDONESIA, PT",
    "Tulip Promo Karya, PT",
    "ABADI MITRA TEKNOLOGI, PT",
    "DIAN CIPTA PERKASA, PT.",
    "JAKEKA TARA PROMOSI, PT",
    "BUMI WIRASTARAYA SEJAHTERA, PT",
    "MYKRON FILTERS PRODUK, PT",
    "SUPARMA, PT",
    "Genecraft Labs, PT",
    "ANTA TIRTA KIRANA, PT",
    "KURNIA MITRA SINERGI,PT",
    "MULTIREDJEKI KITA, PT",
    "ASIAN BEARINDO SEJAHTERA, PT",
    "NET PLASTIC PACKAGING, PT",
    "KINGLAB INDONESIA, PT",
    "BINA SAN PRIMA, PT",
    "DWITA MEKATAMA, PT",
    "Tritunggal Artha Makmur.PT",
    "ACG Indonesia, PT",
    "VANADIA UTAMA, PT",
    "INFINITI BIOANALITIKA SOLUSINDO, PT",
    "WIRATAMA LUSINDO, PT",
    "WINIHARTO, PT",
    "METROHM INDONESIA, PT",
    "MUGI, PT",
    "TIGARAKSA SATRIA TBK, PT",
    "TATA WARNA MANDIRI, PT",
    "SAINTIFIK INDONESIA, PT",
    "KABULINCO JAYA, PT",
    "SENTRA ANEKA FILTERASI, PT",
    "PT. Maja Bintang Indonesia",
    "PETRO ARTHA INDO, PT",
    "ANDALAN INTI REKATAMA, PT",
    "METTLER TOLEDO INDONESIA, PT",
    "BERKAH JAYA SENTOSA,PT",
    "TRIKARYA HATOKI SUKSES MANDIRI, PT",
    "MANE INDONESIA, PT",
    "JAYA NIAGA SEMESTA, PT",
    "ELMECON MULTIKENCANA, PT",
    "ERA MITRA PERDANA, PT",
    "SINAR MUTIARA CAKRABUANA, PT",
    "VISION SCIENTIFIC, PT",
    "INDOCORR PACKAGING CIKARANG, PT",
    "SOLUSINDO LIJAYA PRATAMA, PT",
    "GLORY KATRI PUTERA, PT",
    "LANCAR JAYA GLOBALINDO, PT",
    "HALDIN PACIFIC SEMESTA, PT",
    "SARANA INDRA MANDIRI, PT",
    "CATUR DAKWAH CRANE FARMASI, PT",
    "LABORINDO SARANA, PT",
    "METRINDO SUPRA SINATRIA, PT",
    "PATOCHEMI MURNI ADITAMA, PT",
    "REL-ION STERILIZATION SERVICES, PT",
    "SURYA BUANA NUSANTARA, PT",
    "Esa Buana Husada, PT",
    "FANAH JAYA MAINDO, PT",
    "MASTER CIPTA SENTOSA, PT",
    "NOVA CHEMIE UTAMA, PT",
    "MAXIAIR INDOSURYA, PT.",
    "TUV NORD INDONESIA,PT",
    "SORINI AGRO ASIA CORPORINDO, PT"
]

export const ptListMapper = () => {
    const resultMapper = [];
    for(const a in ptList){
        const obj = {
            id: a,
            name: ptList[a]
        }

        resultMapper.push(obj)
    }

    return resultMapper;
}

const STATUS = ["POSTED", "NEW", "PROCESSED"]

export const fakeDataTable = (total,company) => {
    const resultTable = [];
    for(let i=0; i<total; i++){
        const date = new Date();
        date.setDate(date.getDate() - Math.floor(Math.random() * 31));
        const obj = {
            ID: i,
            COMPANY: company,
            SUPPLIER: ptList[Math.floor(Math.random() * ptList.length)],
            INVOICE_NO: fakeInvoiceNo(1)[0].INVOICE_NO,
            DATE: date,
            AMOUNT: 1000000,
            STATUS: STATUS[Math.floor(Math.random() * STATUS.length)]
        }
        resultTable.push(obj)
    }

    return resultTable;
}

export const fakeInvoiceNo = (total) => {
    let listResult = [];
    let charactersLength = characters.length;
    for(let i=0; i<total; i++){
        let invoiceNo = '';
        for(let x=1;x<=21;x++){
            if(x % 4 === 0 && x <=12){
                invoiceNo += "/"
            }else{
                invoiceNo += characters.charAt(Math.floor(Math.random() * charactersLength))
            }
        }
        const obj = {
            ID: i,
            INVOICE_NO: invoiceNo
        }

        listResult.push(obj);
    }

    return listResult
}

export const fakeTable = (company) => {
    try {
        const listData = [];

        for(let i=0;i<5;i++){
            const date = new Date();

            date.setDate(date.getDate() - Math.floor(Math.random() * 200));

            const date1 = new Date();

            date1.setDate(date1.getDate() - Math.floor(Math.random() * 200));

            let obj = {
                id: i,
                company: company,
                supplier: ptList[Math.floor(Math.random() * ptList.length)],
                invoice_no: fakeInvoiceNo(1)[0].INVOICE_NO,
                invoice_date: date,
                invoice_amount: Math.floor(Math.random() * 100000000),
                payment_method: 'CHECK',
                service_amount: Math.floor(Math.random() * 100000000),
                status: STATUS[Math.floor(Math.random() * STATUS.length)],
                tax_no: fakeTaxNo(),
                tax_amount: Math.floor(Math.random() * 100000000),
                tax_date: date1,
            }

            obj['total_amount'] = obj['service_amount'] + obj['tax_amount']

            listData.push(obj);
        }

        return listData
    } catch (error) {
        console.log(error)
    }
}

const fakeTaxNo = () => {
    let char = '';

    for(let i=1;i<=21;i++){
        if (i === 4) char += '.'
        else if (i === 8) char += '-'
        else if (i === 11) char += '.'
        else char += Math.floor(Math.random() * 9);
    }

    return char;
}




