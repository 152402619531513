<template>
	<div v-if="authLogin">
		<router-link to="/dashboard"></router-link>
	</div>

	<div
		v-else
		class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
	>
		<div
			class="grid justify-content-center p-2 lg:p-0"
			style="min-width: 80%"
		>
			<div
				class="col-12 xl:col-6"
				style="
					border-radius: 56px;
					padding: 0.3rem;
					background: linear-gradient(
						180deg,
						var(--primary-color),
						rgba(33, 150, 243, 0) 30%
					);
				"
			>
				<div
					class="h-full w-full m-0 py-7 px-4"
					style="
						border-radius: 53px;
						background: linear-gradient(
							180deg,
							var(--surface-50) 38.9%,
							var(--surface-0)
						);
					"
				>
					<div class="text-center mb-5">
						<img
							:src="'layout/images/DXG Supplier Portal dan Billing Invoice 03-06.png'"
							alt="Image"
							class="mb-5"
							style="width: 181px; height: 100px"
						/>
						<div class="text-900 text-3xl font-medium mb-3"></div>
						<span v-if="!isHidden" class="text-600 font-medium"
							>Sign in to continue</span
						>
					</div>

					<div v-if="!isHidden" class="w-full md:w-10 mx-auto">
						<label
							for="userName"
							class="block text-900 text-xl font-medium mb-2"
							>User Name</label
						>
						<InputText
							id="userName"
							v-model="userName"
							type="text"
							class="w-full mb-3"
							placeholder="User Name"
							style="padding: 1rem"
							@keydown.enter="showCompanyDropdown()"
						/>

						<label
							for="password1"
							class="block text-900 font-medium text-xl mb-2"
							>Password</label
						>
						<Password
							id="password1"
							v-model="password"
							placeholder="Password"
							:toggleMask="true"
							:feedback="false"
							class="w-full mb-3"
							inputClass="w-full"
							inputStyle="padding:1rem"
							@keydown.enter="showCompanyDropdown()"
						></Password>
						<router-link to="/forgotpass" class="text-blue-500"
							>Forgot Password</router-link
						>
						<Button
							label="Sign In"
							class="w-full p-3 mt-4 text-xl"
							@click="showCompanyDropdown()"
						></Button>
					</div>
					<div v-if="isHidden" class="w-full md:w-10 mx-auto">
						<label
							for="userName"
							class="block text-900 text-xl font-medium mb-2"
							>Select Company</label
						>
						<!-- <Dropdown
							v-if="isSupp"
							id="state"
							v-model="selectedCompany"
							:options="companies"
							optionLabel="tnname"
							class="w-full mb-3"
							placeholder="Select One"
						/> -->
                        <AutoComplete
                            v-if="isSupp"
                            v-model="selectedCompany"
                            :suggestions="filteredCompany"
                            @complete="searchCompanySupp($event)"
                            :dropdown="true"
                            field="tnname"
                            forceSelection
                            :disabled="!companies"
                            class="w-full mb-3"
                        >
                        <template #item="slotProps">
                                <div class="supplier-item">
                                    <div class="ml-2">
                                        {{ slotProps.item.tnname }}
                                    </div>
                                </div>
                            </template>
                        </AutoComplete>
						<!-- <Dropdown
							v-if="!isSupp"
							id="state"
							v-model="selectedCompany"
							:options="companies"
							optionLabel="name"
							class="w-full mb-3"
							placeholder="Select One"
						/> -->
                        <AutoComplete
                            v-if="!isSupp"
                            v-model="selectedCompany"
                            :suggestions="filteredCompany"
                            @complete="searchCompany($event)"
                            :dropdown="true"
                            field="name"
                            forceSelection
                            :disabled="!companies"
                            class="w-full mb-3"
                        >
                            <template #item="slotProps">
                                <div class="supplier-item">
                                    <div class="ml-2">
                                        {{ slotProps.item.name }}
                                    </div>
                                </div>
                            </template>
                        </AutoComplete>

						<Button
							label="Logout"
							class="w-3 p-3 mt-4 text-xl p-button-warning"
							@click="signOut()"
						></Button>
						<Button
							label="Go To Dashboard"
							class="w-8 p-3 mt-4 ml-3 text-xl"
							@click="doLogin()"
						></Button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
import LoginService from '../service/LoginService';
import AuthService from '../service/AuthService';
import TenantService from '../service/TenantmanagerService';
import SwitchCompany from '../service/SwithCompany';
import SimpleCrypto from 'simple-crypto-js';
import LovService from '../service/LovService';
import packageJson from '../../package.json';
export default {
    data() {
        return {
            userName: '',
            password: '',
            checked: false,
            isHidden: false,
            selectedCompany: null,
            companies: [],
            authLogin: false,
            dbInstance: null,
            isSupp: null,
            mode: null,
            filteredCompany:null
        }
    },
    methods: {
        async showCompanyDropdown() {

            const data = {
                credential: this.userName,
                secret: this.password,
                appname: 'spb',
                version: packageJson.version,
            };
            const value = await LoginService.signViaAdminSc(data)
            console.log("showCompanyDropdown value ", value)
            if (value === undefined) {
                window.alert('Incorrect Password')
                sessionStorage.clear()
                console.clear()
                window.location.reload(true);
            } else if ((value.data.data).includes("Login Attempt is more than 3 times")) {
                window.alert('Your account have been locked.')
                sessionStorage.clear()
            }

            //

            if (value.data.status === 200) {
                // window.confirm("Please enter a valid credential");
                sessionStorage.setItem('authKey', value.data.data);
				AuthService.setAuthKey(value.data.data);
				if (value.data.tokenId === "XXXXXX") {
                    

                    const compres = await TenantService.retriveTenantByNamein(value.data.companylist)
                    this.companies = [];
                    
                    for (var i of compres.data.data) {
                        this.companies.push(i);
                    }
                    sessionStorage.setItem('list', JSON.stringify(compres.data.data));
                    // sessionStorage.setItem("loggedIn", true);
                    this.selectedCompany = this.companies[0]
                    const dataVerify = {
                        credential: this.userName,
                        secret: this.password,
                        appname: 'spb',
                        tnid: this.companies[0].id,

                    };
                    // 
                    const values = await LoginService.signViaCompanySc(dataVerify)
                    
                    if (values === undefined) {
                        window.alert('Incorrect Password')
                        sessionStorage.clear()
                    } else if ((values.data.data).includes("Login Attempt is more than 3 times")) {
                        window.alert('Your account have been locked.')
                        sessionStorage.clear()
                    } else {
                        this.isHidden = true;
                        this.isSupp = true;
                    }
                    // sessionStorage.setItem('company',JSON.stringify(compres.data.data))


                } else if (this.userName === 'dexaadmin') {
                    this.$router.push('/dashboard');
                } else {
                    const response = await AuthService.whoAmi()
                    if (response.status === 200) {

                        const value = await LovService.getcompanydb(response.data.data.bioaddress)

                        let modifyItem = [];

                        for(const item of value.data.data){
                            if(item.name.includes("ANUGRAH ARGON") && Number(item.id) !== 999) continue;
                            modifyItem.push(item);
                        }

                        this.companies = modifyItem;
                        this.companies.forEach(element => {
                            // 
                            if (element.value == response.data.data.idsubtenant) {
                                this.selectedCompany = element;
                            }
                        });
                        this.isHidden = true;
                        this.isSupp = false;
                    }
                    // this.$router.push('/dashboard');
                }
            }
        },
        async doLogin() {
            if (this.isSupp) {

                const data = {
                    credential: this.userName,
                    secret: this.password,
                    appname: 'spb',
                    tnid: this.selectedCompany.id,
                    // version: parseFloat(sessionStorage.getItem('fe-version'))
                };

                const value = await LoginService.signViaCompanySc(data)
                // 
                // if (value.data.status === 400){
                //     window.location.reload()
                // }
                if (this.selectedCompany != null && value.data.status === 200) {
                    sessionStorage.setItem("authKey", value.data.data)
                    
                    AuthService.setIsLogin(true)
                    const secretKey = "some-unique-key"
                    const simpleCrypto = new SimpleCrypto(secretKey)
                    const plainText = this.selectedCompany.tnname

                    const cipherText = simpleCrypto.encrypt(plainText)
                    sessionStorage.setItem("dropdownCompany", cipherText)
                    let payload = {
                        dbValue: this.selectedCompany.tnname
                    }
                    // 
                    const response = await SwitchCompany.getDbInstance(payload)
                    this.dbInstance = response.data.data

                    
                    sessionStorage.setItem('companydb', this.dbInstance[0].db_instance);
                   
                    AuthService.whoAmi().then((resp) => {
                        // if (resp.status === 200) {
                        if (resp.data.data.leveltenant === 2) {
                            // 
                            let idUser = resp.data.data.id
                            // 
                            LovService.getSuppBindComp(idUser).then((response) => {
                                // 
                                // 
                                sessionStorage.setItem('vendorId', response.data.data[0].vendor_id);
                                sessionStorage.setItem('dataVendorId', response.data.data[0].vendor_id);
                            });
                        }else{
                            sessionStorage.setItem('mailToCompany', this.selectedCompany.tnname);
                        }
                    });



                    this.$router.push('/dashboard');
                } else {
                    window.alert("Please select a company");
                }
            } else {
                const secretKey = "some-unique-key"
                const simpleCrypto = new SimpleCrypto(secretKey)
                const plainText = this.selectedCompany.name
                const cipherText = simpleCrypto.encrypt(plainText)
                sessionStorage.setItem("dropdownCompany", cipherText)
                sessionStorage.setItem("mailToCompany", this.selectedCompany.name)
                sessionStorage.setItem("companydb", this.selectedCompany.db_instance)
                AuthService.setIsLogin(true)
                this.$router.push('/dashboard');
            }
        },

        async signOut() {
            let payload = {
                appid: 4,
                appname: 'spb'
            };
            const response = await AuthService.signOutApp(payload)

            AuthService.loggedOut();

            this.isHidden = false;
            AuthService.setIsLogin(false)

            // this.$localStorage.clear('authKey');
            sessionStorage.clear();
            this.userName = null;
            this.password = null;
            this.selectedCompany = null
            console.clear()
        },

        async searchCompany(event) {
            // console.log('isi company', this.isSupp)
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredCompany = [...this.companies];
				} else {
					this.filteredCompany = this.companies.filter((item) => {
						return item.name
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
        async searchCompanySupp(event) {
            // console.log('isi company', this.companies)
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredCompany = [...this.companies];
				} else {
					this.filteredCompany = this.companies.filter((item) => {
						return item.tnname
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
    },
    computed: {
        logoColor() {

            if (this.$appState.darkTheme) return 'white';
            return 'dark';

        }
    },
    mounted() {
        if (sessionStorage.getItem('loggedIn') === 'false') {
            alert('Session has expired\nPlease login again')
            sessionStorage.removeItem('authKey');
            sessionStorage.removeItem('companydb');
            sessionStorage.removeItem('dropdownCompany');
            sessionStorage.removeItem('idUser');
            sessionStorage.removeItem('leveltenant');
            sessionStorage.removeItem('list');
            sessionStorage.removeItem('vendorId');
            sessionStorage.removeItem('mailToCompany');
            sessionStorage.removeItem('loggedIn');
        }
    },
    created() {
        if (sessionStorage.getItem('tknfrgt')) {
            this.$router.push('/resetpassword');
        }
    },
};
</script>

<style scoped>
.pi-eye {
	transform: scale(1.6);
	margin-right: 1rem;
}

.pi-eye-slash {
	transform: scale(1.6);
	margin-right: 1rem;
}
</style>
