<template>
	<div class="grid">
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search</div>
				<div class="content-body">
					<div class="col-12 p-fluid">
						<div class="grid formgrid" style="margin: 1em 0 0 0">
							<div class="col-12 mb-5 lg:col-3">
								<CompanyDropdown v-model="company" disabled />
							</div>
							<div class="col-12 mb-5 lg:col-3">
								<span class="p-input-icon-left w-full">
									<i class="pi pi-search" />
									<InputText
										v-model="searchGlobal"
										class="w-full pi"
										style="color: #000"
										placeholder="Search Item Number, Description, etc "
									>
									</InputText>
								</span>
							</div>
							<div class="mr-3 ml-2">
								<Button
									@click="handleSearch()"
									class="p-button-success uppercase"
									label="search"
									:disabled="loading"
								/>
							</div>
							<div class="mr-3 ml-2">
								<Button
									icon="pi pi-file"
									class="p-button-secondary"
									label="Export"
									@click="exportExcell"
									style="float: right"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- search result -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search Results</div>
				<div class="content-body">
					<div class="mb-4 text-right">
						<Button
							@click="create()"
							class="p-button-success uppercase"
							icon="pi pi-plus"
							label="create"
							:disabled="loading"
						/>
					</div>
					<div class="mt-3">
						<TableSpecAndQuotation
							:searchGlobal="searchGlobal ? searchGlobal : null"
							:key="reloadTable"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import PoFulfillment from '../../service/PoFulfillment';
import LovService from '../../service/LovService';
import SimpleCrypto from 'simple-crypto-js';
import AuthService from '../../service/AuthService';
import CompanyDropdown from './components/Company.vue';
import TableSpecAndQuotation from './components/TableSpecAndQuotation.vue';
import SpecAndQuotation from '../../service/SpecAndQuotation';
import XLSX from 'xlsx';
import { useToast } from 'primevue/usetoast';

const breadcrumbHome = { icon: 'pi pi-home', to: '/dashboard' };
const breadcrumbItems = [
	{
		label: 'Spec & Quotation',
		to: '/mgm/settings/specquotelist',
	},
];

const searchGlobal = ref();
const company = ref();
const supplier = ref(null);
const supplierReload = ref(0);
const userInfo = ref();
const router = useRouter();
const reloadTable = ref(0);
const toast = useToast();

watch(
	() => company.value,
	() => {
		supplierReload.value++;
	}
);

onMounted(() => {
	AuthService.whoAmi().then(async (response) => {
		const userCompany = () => {
			const secretKey = 'some-unique-key';
			const simpleCrypto = new SimpleCrypto(secretKey);
			return simpleCrypto.decrypt(
				sessionStorage.getItem('dropdownCompany')
			);
		};
		if (response.status === 200) {
			userInfo.value = response.data.data;
			if (userInfo.value.leveltenant === 2) {
				//get company
				const respCbN = await LovService.getCompanyByName(
					userCompany()
				);
				company.value = respCbN.data.data[0];
				//get supplier

				const param = {
					db_instance: company.value.db_instance,
					org_id: company.value.value,
				};

				const respVendor = await PoFulfillment.getSuppBindComp(
					response.data.data.id
				);

				const vendorName = respVendor.data.data[0][0].vendorname;
				const respSuppName = await LovService.getSupplierName(param);
				if (respSuppName.status === 200) {
					const suppList = respSuppName.data.data;
					for (const s of suppList) {
						if (s.vendor_name === vendorName) {
							supplier.value = s;
							break;
						}
					}
					if (supplier.value === null) {
						router.push('/dashboard');
						window.alert('Connection Unstable, ID not Recognize');
					}
				}
			} else {
				const respCbN = await LovService.getCompanyByName(
					userCompany()
				);
				company.value = respCbN.data.data[0];
			}
		}
	});
});

//export to excell
const exportExcell = async () => {
	await AuthService.whoAmi().then((value) => {
		if (value.status === 200) {
			setTimeout(async () => {
				const payload = {
					searchKeyword: searchGlobal.value
						? searchGlobal.value
						: null,
					org_id: parseInt(company.value.value),
					db_instance: company.value.db_instance,
				};
				await SpecAndQuotation.getSearchExcell(payload).then(
					(respond) => {
						//
						if (respond.length !== 0) {
							let today = new Date();
							let dd = String(today.getDate()).padStart(2, '0');
							let mm = String(today.getMonth() + 1).padStart(
								2,
								'0'
							); //January is 0!
							let yyyy = today.getFullYear();
							let combinedDate = dd + mm + yyyy;
							let filename =
								'Spec_And_Quotation_' + combinedDate + '.xlsx';
							let data = respond.data.data.data;

							let ws = XLSX.utils.json_to_sheet(data);
							let wb = XLSX.utils.book_new();
							XLSX.utils.book_append_sheet(
								wb,
								ws,
								'My Worksheet'
							);
							XLSX.writeFile(wb, filename);
						} else {
							toast.add({
								severity: 'error',
								summary: 'Data is Empty',
								life: 3000,
							});
						}
					}
				);
			}, 250);
		}
	});
};

//move to create page
const create = async () => {
	router.push({
		name: 'DetailSpecQuotation',
		params: { id: 0 },
		query: { v: company.value.value, i: company.value.db_instance },
	});
};

//handle search
const handleSearch = async () => {
	reloadTable.value++;
};
</script>
