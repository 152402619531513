<template>
  <div class="grid find-to-modal">
    <Toast />

    <!-- Content -->
    <div class="col-12 mb-2">
      <div class="content-wrapper">
        <div class="content-header">List of Items</div>
        <div class="content-body">
          <!-- Table content -->
          <div class="mb-6">
            <DataTable
              dataKey="ITEM_ID"
              responsiveLayout="scroll"
              selectionMode="single"
              :lazy="true"
              :paginator="true"
              :rows="5"
              @page="onPage($event)"
              :rowsPerPageOptions="[5, 10, 20, 50]"
              :metaKeySelection="false"
              :loading="loading"
              :totalRecords="totalRecords"
              :value="filteredTravelOrders"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              v-model:selection="selectedTravelOrder"
              @row-select="handleRowSelect"
            >
              <!-- Search input -->
              <template #header>
                <div>
                  <span class="p-input-icon-left w-full">
                    <i class="pi pi-search" />
                    <InputText
                      placeholder="Search"
                      style="width: 100%"
                      type="text"
                      v-model="searchKeyword"
                      @keyup.enter="populateTableItem()"
                      @page="onPage($event)"
                    />
                  </span>
                </div>
              </template>

              <!-- Results Data -->
              <Column header="Item Number" field="ITEM_NUMBER"> </Column>
              <Column header="Description" field="ITEM_DESC"> </Column>
              <Column header="UOM" field="UOM"> </Column>
              <!-- Empty statement -->
              <template #empty>No Data found</template>
              <template #loading>Loading data</template>
            </DataTable>
          </div>

          <div class="col-4">
            <Button class="p-button-secondary" @click="$emit('on-close')">
              <span class="uppercase font-bold">Close</span>
            </Button>
            <Button
              icon="pi pi-file"
              class="p-button-primary ml-3"
              label="Export"
              @click="exportExcell"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, onMounted } from "vue";
import SpecAndQuotation from "../../../service/SpecAndQuotation";
import AuthService from "../../../service/AuthService";
import LovService from "../../../service/LovService";
import SimpleCrypto from "simple-crypto-js";
import { useToast } from "primevue/usetoast";
import XLSX from "xlsx";

// Props
const emit = defineEmits(["on-add", "res"]);
// State
// const travelOrders = ref(null);
const filteredTravelOrders = ref(null);
const selectedTravelOrder = ref(null);
const searchKeyword = ref(null);
const loading = ref(false);
const totalRecords = ref(0);
const page = ref(1);
const rowsPerPage = ref(5);
const company = ref();
const toast = useToast();

//onMounted table
onMounted(() => {
  AuthService.whoAmi().then(async (response) => {
    if (response.status === 200) {
      const userCompany = () => {
        const secretKey = "some-unique-key";
        const simpleCrypto = new SimpleCrypto(secretKey);
        return simpleCrypto.decrypt(sessionStorage.getItem("dropdownCompany"));
      };
      const respCbN = await LovService.getCompanyByName(userCompany());
      company.value = respCbN.data.data[0];

      await populateTableItem();
    }
  });
});

//populate table
const populateTableItem = async () => {
  loading.value = true;
  if (company.value !== null) {
    const payload = {
      searchKeyword: searchKeyword.value,
      db_instance: company.value.db_instance,
      page: page.value,
      rows: rowsPerPage.value,
    };

    const res = await SpecAndQuotation.listItems(payload);
    
    if (res.status === 200) {
      filteredTravelOrders.value = res.data.data.data;
      totalRecords.value = res.data.data.totalData[0].totalData;
      loading.value = false;
    } else {
      loading.value = false;
    }
  }
};

//swith page
const onPage = async (event) => {
  loading.value = true;
  page.value = event.page + 1;
  rowsPerPage.value = event.rows;
  await populateTableItem();
};

// Handle row select
const handleRowSelect = async () => {
  try {
    let payload = {
      ITEM_NUMBER : selectedTravelOrder.value.ITEM_NUMBER,
      ORG_ID : company.value.value,
      DB_INSTANCE: company.value.db_instance
    };
     const res =  await SpecAndQuotation.itemExist(payload);

     if (res.data.data.statusCode === 203) {
        // emit("on-add", null);
        toast.add({
          severity: "error",
          summary: "Item Number Already Exist",
          life: 3000,
        });
     } else {
       emit("on-add", selectedTravelOrder.value);
     }
  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Network Error",
      life: 3000,
    });
  }
};

//export to excell
const exportExcell = async () => {
  await AuthService.whoAmi().then((value) => {
    if (value.status === 200) {
      setTimeout(async () => {
        const payload = {
          searchKeyword: searchKeyword.value ? searchKeyword.value : null,
          db_instance: company.value.db_instance,
        };
        await SpecAndQuotation.popUpToExcell(payload).then((respond) => {
          // 
          if (respond.length !== 0) {
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, "0");
            let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            let yyyy = today.getFullYear();
            let combinedDate = dd + mm + yyyy;
            let filename =
              "Spec_Item_Master" + combinedDate + ".xlsx";
            let data = respond.data.data.data;
            
            let ws = XLSX.utils.json_to_sheet(data);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "My Worksheet");
            XLSX.writeFile(wb, filename);
          } else {
            toast.add({
              severity: "error",
              summary: "Data is Empty",
              life: 3000,
            });
          }
        });
      }, 250);
    }
  });
};
</script>