<template>
    <span class="p-float-label">
        <AutoComplete :suggestions="filteredBank" @complete="searchBatch($event)" :dropdown="true" field="TRANSFER_TO" v-model="remitBankValue" :disabled="props.disabled || props.level_tenant !== 2" forceSelection>
        <template #item="slotProp">
        {{ slotProp.item.TRANSFER_TO }}
        </template>
    </AutoComplete>
        <label for="autocomplete">Bank</label>
    </span>
</template>

<script setup>
import {onMounted, ref, defineEmits} from 'vue'
import AuthService from '../../../service/AuthService';
import InvoiceService from "../../../service/InvoiceSearchWPO"
// eslint-disable-next-line no-undef
const props = defineProps({
  db_instance: {type: Object},
  bankId: {type: Object},
  org_id: {type: Object},
  vendor_id: {type: Object},
  disabled: {type: Boolean},
  level_tenant: { type: Object}
})
// eslint-disable-next-line no-undef
const filteredBank = ref([]);
const remitBank = ref([]);
const remitBankValue = ref();
const emit = defineEmits(['remit'])
onMounted(() => {
  populateData()
})
// watchEffect(() => 
//   emit('remit',remitBankValue)
// )
const populateData = () => {
  AuthService.whoAmi().then(async (response) => {
    const payload = { db_instance : props.db_instance, org_id : props.org_id, vendor_id : props.vendor_id }
      if (response.status === 200){
      const data = await InvoiceService.getRemitBank(payload)
      remitBank.value = data.data.data.data
      for (const s of remitBank.value) {
              if (s.EXT_BANK_ACCOUNT_ID === props.bankId) {
                remitBankValue.value = s;
                emit('remit',remitBankValue)
                break;
              }
              else{
                remitBankValue.value = null;
                emit('remit',remitBankValue)
              }
            }
    }
    
    
  })
}
const searchBatch = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredBank.value = [...remitBank.value]
    } else {
      filteredBank.value = remitBank.value.filter((comp) => {
        return comp.TRANSFER_TO.toLowerCase().match(event.query.toLowerCase());
      })
    }
  }, 250);
}
</script>

<style scoped>
</style>