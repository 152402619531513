<template>
	<div class="col-12 p-fluid mb-4 list-invoice list-inv-proxy">
		<Toast />

		<div class="mb-3">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<!-- Progress modal -->
		<ProgressModal :progress="progress">
			<template #message>
				Saving invoice in progress. Please wait...
			</template>
		</ProgressModal>

		<div class="content-wrapper">
			<div class="content-header">Detail E-Invoice</div>
			<div v-if="currentUser" class="content-body">
				<div class="grid">
					<!-- Dropdown company -->
					<div
						class="col-12 p-0 pb-2 mb-6 border-bottom-1 border-gray-200"
					>
						<div class="col-12 md:col-6 lg:col-4">
							<div class="mb-5">
								<span class="p-float-label">
									<AutoComplete
										id="company"
										:class="{
											'p-invalid': v$.company.$error,
										}"
										:dropdown="true"
										:suggestions="filteredCompany"
										field="name"
										forceSelection
										disabled
										v-model="company"
										@complete="searchCompany($event)"
									>
										<template #item="{ item }">
											<div>
												{{ item.name }}
											</div>
										</template>
									</AutoComplete>
									<label for="company">Company</label>
								</span>
								<div v-if="v$.company.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.company.$errors[0].$message }}
									</small>
								</div>
							</div>
						</div>
					</div>

					<!-- Header Form -->
					<div
						class="col-12 grid p-0 pb-2 mb-4 border-bottom-1 border-gray-200"
					>
						<!-- Col 1 -->
						<div class="col-12 md:col-6 lg:col-4">
                            <div class="mb-5">
								<span class="p-float-label">
									<AutoComplete
										v-model="receivedType"
										:suggestions="filteredReceivedType"
										:dropdown="true"
										field="TYPE_NAME"
										@complete="searchReceivedType($event)"
										@item-select="onSelectReceived($event)"
										forceSelection
									>
										<template #item="slotProp">
										{{ slotProp.item.TYPE_NAME }}
										</template>
									</AutoComplete>
									<label for="receivedType"
                                        >Received Type</label>
                                </span>
								<div v-if="v$.invoiceNo.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.invoiceNo.$errors[0].$message }}
									</small>
								</div>
								<div v-if="errorExisting" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										Invoice Number already exist
									</small>
								</div>
							</div>

                            <div class="mb-5" v-if="receivedType && receivedType.TYPE_VALUE == 'panitia'">
                                <span class="p-float-label">
									<AutoComplete
										v-model="typePenagihan"
										:suggestions="filteredTypeForm"
										:dropdown="true"
										field="TYPE"
										@complete="searchTypeForm($event)"
										forceSelection
										@item-select="onSelectTipeInvoice($event)"
									>
										<template #item="slotProp">
										{{ slotProp.item.TYPE }}
										</template>
									</AutoComplete>
									<label for="typePenagihan"
                                        >Tipe Invoice</label>
                                </span>
								<div v-if="v$.invoiceNo.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.invoiceNo.$errors[0].$message }}
									</small>
								</div>
								<div v-if="errorExisting" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										Invoice Number already exist
									</small>
								</div>
							</div>

							<div class="mb-5" v-if="typePenagihan && typePenagihan.TYPE == 'Realization'">
                                <span class="p-float-label">
									<AutoComplete
										:dropdown="true"
										v-model="invPrepaymentNo"
										:suggestions="filteredInvPrepaymentNo"
										field="INVOICE_NO"
										@complete="searchInvoicePrepaymentNo($event)"
										forceSelection
										@item-select="onChangeInvPrepaymentNo($event)"
									>
										<template #item="slotProp">
										{{ slotProp.item.INVOICE_NO }}
										</template>
									</AutoComplete>
									<label for="invPrepaymentNo"
                                        >No Invoice Prepayment</label>
                                </span>
								<div v-if="v$.invoiceNo.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.invoiceNo.$errors[0].$message }}
									</small>
								</div>
								<div v-if="errorExisting" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										Invoice Number already exist
									</small>
								</div>
							</div>

                            <div class="mb-5">
								<span class="p-float-label">
                                    <AutoComplete
                                        :suggestions="filteredSTKNumber"
                                        @complete="searchSTKNumber($event)"
                                        :dropdown="true"
                                        v-model="stkNumber"
										:disabled="(!company || !supplierName || !receivedType) || (typePenagihan && typePenagihan.TYPE == 'Realization')"
                                        field="kiCode"
                                        @item-select="selectedSTKNumber($event)"
                                        forceSelection
                                    >
                                        <template #item="slotProp">
                                        {{ slotProp.item.kiCode }}
                                        </template>
                                    </AutoComplete>
									<label for="invoiceNo">Event PM Number</label>
								</span>
								<div v-if="v$.invoiceNo.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.invoiceNo.$errors[0].$message }}
									</small>
								</div>
								<div v-if="errorExisting" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										Invoice Number already exist
									</small>
								</div>
							</div>
                            
							<div class="mb-5">
								<span class="p-float-label">
									<InputText
										id="invoiceNo"
										:class="{
											'p-invalid':
												v$.invoiceNo.$error ||
												errorExisting,
										}"
										v-model="invoiceNo"
									/>
									<label for="invoiceNo">Invoice No</label>
								</span>
								<div v-if="v$.invoiceNo.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.invoiceNo.$errors[0].$message }}
									</small>
								</div>
								<div v-if="errorExisting" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										Invoice Number already exist
									</small>
								</div>
							</div>
							<div class="mb-5">
								<span class="p-float-label">
									<Calendar
										id="invoiceDate"
										:class="{
											'p-invalid': v$.invoiceDate.$error,
										}"
										:showIcon="true"
										dateFormat="d-M-yy"
										v-model="invoiceDate"
										@date-select="handleSelectInvoiceDate"
									/>
									<label for="invoiceDate"
										>Invoice Date</label
									>
								</span>
								<div v-if="v$.invoiceDate.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.invoiceDate.$errors[0].$message }}
									</small>
								</div>
							</div>
							<div class="mb-2">
								<span class="p-float-label">
									<Textarea
										id="description"
										:class="{
											'p-invalid': v$.description.$error,
										}"
										rows="5"
										v-model="description"
										:maxlength="230"
									/>
									<label for="description">Description</label>
								</span>
								<div v-if="v$.description.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.description.$errors[0].$message }}
									</small>
								</div>
							</div>
							<div class="mb-5">
								<label
									style="
										display: inline-block;
										font-size: 12px;
										padding-left: 0.75rem;
										margin-bottom: 4px;
										color: #6c757d;
									"
									>Attachment</label
								>
								<div v-if="savedFile" class="flex">
									<!-- <a
										class="button-download p-button"
										:href="savedFile.url"
									>
										<span class="font-bold">
											<i class="pi pi-download mr-2" />
											{{ savedFile.filename }}
										</span>
									</a> -->
									<Button
										v-if="!loadingDownladFile"
										class="button-download font-normal"
										:label="savedFile.filename"
										icon="pi pi-download"
										@click="onDownload()"
									/>
									<Button
										v-if="loadingDownladFile"
										class="button-download font-normal"
										:label="`${savedFile.filename} is downloading`"
										icon="pi pi-download"
										:disabled="true"
									/>
									<Button
										v-if="status === 'DRAFT' && isUserPic"
										type="button"
										class="p-button-rounded p-button-text ml-2"
										icon="pi pi-trash"
										@click="handleDeleteFile"
									/>
								</div>
								<div v-else>
									<FileUpload
										class="w-full"
										ref="fileUpload"
										mode="advanced"
										:class="{
											'error-file': v$.file.$error,
										}"
										style="justify-content: center"
										chooseLabel="Upload File"
										fileLimit="1"
										accept="application/pdf"
										:maxFileSize="5000000"
										:showUploadButton="false"
										:showCancelButton="false"
										:customUpload="true"
										@select="onSelectFile"
										@remove="onRemoveFile"
									>
										<template #content="{ files }">
											{{ files }}
										</template>
									</FileUpload>
									<div
										v-if="v$.file.$error"
										style="transform: translateY(-38px)"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{ v$.file.$errors[0].$message }}
										</small>
									</div>
								</div>
							</div>
						</div>
						<!-- Col 2 -->
						<div class="col-12 md:col-6 lg:col-4">
							<div class="mb-5">
								<span class="p-float-label">
									<InputText
										id="taxInvoiceNo"
										:class="{
											'p-invalid': v$.taxInvoiceNo.$error,
										}"
										v-model="taxInvoiceNo"
									/>
									<label for="taxInvoiceNo"
										>Tax Invoice No</label
									>
								</span>
								<div v-if="v$.taxInvoiceNo.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{
											v$.taxInvoiceNo.$errors[0].$message
										}}
									</small>
								</div>
							</div>
							<div class="mb-5">
								<span class="p-float-label">
									<Calendar
										id="taxInvoiceDate"
										:class="{
											'p-invalid':
												v$.taxInvoiceDate.$error,
										}"
										dateFormat="d-M-yy"
										:showIcon="true"
										v-model="taxInvoiceDate"
									/>
									<label for="taxInvoiceDate"
										>Tax Invoice Date</label
									>
								</span>
								<div
									v-if="v$.taxInvoiceDate.$error"
									class="mt-1"
								>
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{
											v$.taxInvoiceDate.$errors[0]
												.$message
										}}
									</small>
								</div>
							</div>
							<div class="mb-5">
								<span class="p-float-label">
									<AutoComplete
										forceSelection
										field="name"
										:class="{
											'p-invalid': v$.bank.$error,
										}"
										:disabled="!bankList"
										:dropdown="true"
										:suggestions="filteredBanks"
										v-model="bank"
										@complete="searchBank($event)"
									/>
									<label for="bank">Bank</label>
								</span>
								<div v-if="v$.bank.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.bank.$errors[0].$message }}
									</small>
								</div>
							</div>
							<div class="mb-5">
								<span class="p-float-label">
									<InputText
										id="ppsNo"
										v-model="ppsNo"
									/>
									<label for="ppsNo">PP MS Number</label>
								</span>
							</div>
							<div class="mb-5">
								<span class="p-float-label">
									<AutoComplete
										forceSelection
										field="currency_code"
										:class="{
											'p-invalid': v$.currency.$error,
										}"
										:disabled="!currencyList"
										:dropdown="true"
										:suggestions="filteredCurrencies"
										v-model="currency"
										@complete="searchCurrency($event)"
									/>
									<label>Currency</label>
								</span>
								<div v-if="v$.currency.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.currency.$errors[0].$message }}
									</small>
								</div>
							</div>
						</div>
						<!-- Col 3 -->
						<div class="col-12 md:col-6 lg:col-4">
							<div class="mb-5">
								<span class="p-float-label">
									<InputText
										class="statusInvoice"
										disabled
										v-model="status"
									/>
									<label for="statusInvoice">Status</label>
								</span>
							</div>
							<div class="mb-5">
								<span class="p-float-label">
									<Textarea
										v-model="rejectReason"
										rows="5"
										class="w-full"
										disabled
									/>
									<label>Reject Reason</label>
								</span>
							</div>
							<div class="mb-5">
								<span class="p-float-label">
									<Textarea
										v-model="recommendatorReason"
										rows="5"
										class="w-full"
										disabled
									/>
									<label>Recommendator Reason</label>
								</span>
							</div>
						</div>
					</div>

					<!-- Detail Form -->
					<div class="col-12 grid">
						<div class="col-12 md:col-6 lg:col-8 mb-3">
							
						</div>

						<!-- Summary invoice -->
						<div class="col-12 md:col-6 lg:col-4 mb-3">
							<div class="form-card">
								<div
									class="flex align-items-center justify-content-between mb-4"
								>
									<span
										style="color: #000"
										class="font-semibold"
										>Total Line</span
									>
									<span class="font-bold">
										{{
											new Intl.NumberFormat('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}).format(summary.totalLine)
										}}
									</span>
								</div>
								<div
									class="flex align-items-center justify-content-between mb-4"
								>
									<span
										style="color: #000"
										class="font-semibold"
										>Total PPn</span
									>
									<span class="font-bold">
										{{
											new Intl.NumberFormat('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}).format(summary.totalPpn)
										}}
									</span>
								</div>
								<div
									class="flex align-items-center justify-content-between"
								>
									<span
										style="color: #000"
										class="font-semibold"
										>Total Invoice</span
									>
									<span class="font-bold">
										{{
											new Intl.NumberFormat('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}).format(summary.totalInvoice)
										}}
									</span>
								</div>
							</div>
						</div>

						<!-- Category table -->
						<div class="col-12 mb-8">
							<DataTable
								:value="selectedCategory"
								responsiveLayout="scroll"
								:scrollable="true"
								scrollHeight="600px"
								scrollDirection="both"
							>
								<Column header="Description" style="width:30%">
									<template #body="{ data, index }">
										<InputText
											type="text"
											style="color: #000 !important; width:100%"
											:class="{
												'p-invalid':
													v$.selectedCategory
														.$error &&
													v$.selectedCategory
														.$errors[0].$response
														.$data[index]
														.description.$error,
											}"
											:disabled="isForwarder"
											v-model="data.description"
										/>
										<div
											v-if="
												v$.selectedCategory.$error &&
												v$.selectedCategory.$errors[0]
													.$response.$data[index]
													.description.$error
											"
											class="mt-1"
										>
											<small
												class="p-error"
												style="font-size: 12px"
											>
												{{
													v$.selectedCategory
														.$error &&
													v$.selectedCategory
														.$errors[0].$response
														.$errors[index]
														.description[0].$message
												}}
											</small>
										</div>
									</template>
								</Column>
								<Column header="Amount" style="width:15%">
									<template #body="{ data, index }">
										<InputNumber
											class="col-number"
											:class="{
												'p-invalid':
													v$.selectedCategory
														.$error &&
													v$.selectedCategory
														.$errors[0].$response
														.$data[index].amount
														.$error,
											}"
											:min="0"
											mode="decimal"
											locale="en-US"
											:minFractionDigits="2"
											:maxFractionDigits="2"
											style="text-align: right"
											v-model="data.amount"
										/>
										<div
											v-if="
												v$.selectedCategory.$error &&
												v$.selectedCategory.$errors[0]
													.$response.$data[index]
													.amount.$error
											"
											class="mt-1"
										>
											<small
												class="p-error"
												style="font-size: 12px"
											>
												{{
													v$.selectedCategory
														.$error &&
													v$.selectedCategory
														.$errors[0].$response
														.$errors[index]
														.amount[0].$message
												}}
											</small>
										</div>
									</template>
								</Column>
								<Column header="VAT" style="width:15%">
									<template #body="{ data }">
										<Dropdown
											class="w-full"
											:options="vatRates"
											optionLabel="vat_code"
											placeholder="Select VAT"
											v-model="data.vat"
										/>
									</template>
								</Column>
								<Column header="Account" style="width: 30%">
									<template #body="slotProps">
										<AutoComplete
											v-model="slotProps.data.account"
											field="account"
											style="width:100%"
											:suggestions="filteredAccount"
											:dropdown="true"
											@complete="searchAccount($event)"
											@item-select="getAccountId($event, slotProps.index)"
											forceSelection
										/>
									</template>
								</Column>
								<Column header="Account SL" style="width: 30%" v-if="typePenagihan && typePenagihan.TYPE == 'Prepayment'">
									<template #body="slotProps">
										<AutoComplete
											v-model="slotProps.data.account_sl"
											field="account"
											style="width:100%"
											:suggestions="filteredAccount"
											:dropdown="true"
											@complete="searchAccount($event)"
											@item-select="getAccountIdSL($event, slotProps.index)"
											forceSelection
										/>
									</template>
								</Column>
								<Column header="Ref Number" style="width: 25%">
									<template #body="{ data }">
										<div class="p-inputgroup">
											<InputText
												disabled
												placeholder="Ref Number"
												v-model="
													data.travelOrder
														.travelOrderNo
												"
											/>
										</div>
									</template>
								</Column>
								<Column v-if="(typePenagihan && typePenagihan.TYPE == 'Realization' && status == 'DRAFT')"
									alignFrozen="right"
									frozen
									header="Action" 
								>
									<template #body="{ index }">
										<Button
											icon="pi pi-trash"
											class="p-button-rounded p-button-text"
											@click="handleDeleteCategory(index)"
										/>
									</template>
								</Column>

								<!-- Empty state -->
								<template #empty>
									<div>No item.</div>
								</template>
							</DataTable>
						</div>

					</div>
				</div>

				<!-- Action buttons -->
				<div class="grid">
					<div
						v-if="status === 'DRAFT' && isUserPic"
						class="col-12 md:col-6 lg:col-3"
					>
						<Button
							v-if="!progress"
							class="p-button-success justify-content-center"
							:disabled="!invoiceNo"
							@click="handleSave"
						>
							<span class="font-bold uppercase">Save</span>
						</Button>
						<Button
							v-else
							class="p-button-success justify-content-center"
							disabled
						>
							<span class="font-bold uppercase">Saving...</span>
						</Button>
					</div>
					<div class="col-12 md:col-6 lg:col-3">
						<Button
							class="p-button-secondary justify-content-center"
							@click="$router.push({ name: 'ListInvoiceProxy' })"
						>
							<span class="font-bold uppercase">Back</span>
						</Button>
					</div>
				</div>

				<!-- Find SK Modal -->
				<Dialog
					header="Select SK"
					:draggable="false"
					:breakpoints="{ '960px': '75vw', '640px': '100vw' }"
					:style="{ width: '25vw', overflow: 'hidden' }"
					:modal="true"
					:dismissableMask="true"
					v-model:visible="displaySKModal"
				>
					<div class="pt-4">
						<FindSKModal
							:dbInstance="company.db_instance"
							:vendorId="vendorId"
							@on-close="displaySKModal = false"
							@on-save="addSKNumber"
						/>
					</div>
				</Dialog>

				<!-- Find Travel Order Modal -->
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, watch, onMounted, defineProps } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import { useToast } from 'primevue/usetoast';
import AuthService from '../../service/AuthService';
import InvoicePOService from '../../service/InvoicePOService';
import InvoiceCreateNonPOService from '../../service/InvoiceCreateNonPO';
import InvoiceListProxyService from '../../service/InvoiceListProxyService';
import ProgressModal from '../components/ProgressModal.vue';
import FindSKModal from '../components/FindSKModal.vue';
import {
	getDetailCostCenter,
	getInvoiceNumberPrepayment,
	getEinvoiceID,
	getInvoiceDetailPrepayment,
	getDetailCOAID, getDataSP1KI,getCOASLDetail,findCoaByNumber
} from '../../service/InvoiceProxyNonPOServices';
import SearchInvoiceProxyNonPO from '../../service/InvoiceProxyService';

// Vue router
const router = useRouter();
const route = useRoute();

// State
const ppsNo = ref(null);
const currentUser = ref(null);
const company = ref(null);
const vendorId = ref(null);
const categoryId = ref(null);
const supplierName = ref(null);
const invoiceNo = ref(null);
const invoiceDate = ref(null);
const description = ref(null);
const bank = ref(null);
const bankAccId = ref(null);
const taxInvoiceNo = ref(null);
const taxInvoiceDate = ref(null);
const status = ref(null);
const rejectReason = ref(null);
const recommendatorReason = ref(null);
const file = ref(null);
const fileUpload = ref(null);
const savedFile = ref(null);
const deletedFile = ref(null);
const skNumber = ref(null);
const bankList = ref(null);
const filteredBanks = ref(null);
const companyList = ref(null);
const filteredCompany = ref(null);
const currency = ref(null);
const currencyList = ref(null);
const filteredCurrencies = ref(null);
const categoryHeader = ref(null);
const categoryDetail = ref(null);
const category = ref(null);
const selectedCategory = ref([]);
const removedLines = ref([]);
const vatRates = ref(null);
const reimburseItems = ref([]);
const removedReimburseItems = ref([]);
const vendors = ref(null);

const reimburstGroups = ref(null);
const progress = ref(false);
const loading = ref(false);
const displaySKModal = ref(false);
const isWarningShown = ref(false);
const errorExisting = ref(false);
const fileBuffer = ref();
const loadingDownladFile = ref(false);
const breadcrumbHome = ref({ icon: 'pi pi-home', to: '/dashboard' });
const breadcrumbItems = ref([
	{
		label: 'Detail List Invoice Proxy',
		to: route.fullPath,
	},
]);
const stkNumber = ref(null);
const filteredSTKNumber = ref([]);
const stkNumberList = ref([]);
const receivedType = ref(null);
const receivedTypeFromDB = ref(null);
const filteredReceivedType = ref([]);
const receivedTypeList = ref([
	{ID:3, TYPE_VALUE: '', TYPE_NAME: '- No Select -'},
	{ID:1, TYPE_VALUE: 'panitia', TYPE_NAME: 'PANITIA'},
	{ID:2, TYPE_VALUE: 'vendor', TYPE_NAME: 'VENDOR'}
]);
const vendorSiteId = ref(null)

const filteredTypeForm = ref([]);
const typeForm = ref([
	{ID:1, TYPE: 'Prepayment'},
	{ID:2, TYPE: 'Realization'},
	{ID:3, TYPE: 'Realization Without Prepayment'}
])
const typePenagihan = ref(null);
const costCenter = ref(null);
const selectedCategoryFromDB = ref([]);

const noInvPrepaymentList = ref([]);
const filteredInvPrepaymentNo = ref([]);
const invPrepaymentNo = ref(null);
const accountData = ref(null);
const filteredAccount = ref([]);




watch(() => receivedType.value, async (text) => {
    if(text.TYPE_NAME !== receivedTypeFromDB.value.TYPE_NAME){
        await fetchSTKNumber({
            vendor_id: vendorId.value,
            vendor_site_id: vendorSiteId.value,
            received_type: text.TYPE_VALUE
        });
        stkNumber.value = null;
        typePenagihan.value = null;
		selectedCategory.value = [];
		invPrepaymentNo.value = null;
    }
})



// Props
const props = defineProps(['id']);

// Check if supplier is forwarder
const isForwarder = computed(
	() =>
		categoryHeader.value &&
		categoryHeader.value.category_name.toLowerCase().includes('forwarder')
);

// Check if user logged in is PIC
const isUserPic = computed(
	() => currentUser.value && currentUser.value.leveltenant === 3
);

// Custom validator
const cannotZero = (value) => value !== 0;
const requireFile = () => !savedFile.value && !file.value;

// Vuelidate rules
const validationRules = computed(() => {
	const rules = {
		company: { required },
		invoiceNo: { required },
		invoiceDate: { required },
		description: { required },
		taxInvoiceNo: { required },
		taxInvoiceDate: { required },
		bank: { required },
		currency: { required },
		file: { requiredIfFunction: requiredIf(requireFile) },
		selectedCategory: {
			$each: helpers.forEach({
				description: { required },
				amount: {
					required,
					cannotZero: helpers.withMessage(
						'The amount value must be greater than zero',
						cannotZero
					),
				},
			}),
		},
		reimburseItems: {
			$each: helpers.forEach({
				taxNo: { required },
				taxDate: { required },
				taxAmount: {
					required,
					cannotZero: helpers.withMessage(
						'The amount value must be greater than zero',
						cannotZero
					),
				},
				group: { required },
			}),
		},
	};

	if (isForwarder.value) {
		rules.skNumber = { required };
	}

	return rules;
});

// Validator instance
const v$ = useVuelidate(validationRules, {
	company,
	invoiceNo,
	invoiceDate,
	description,
	taxInvoiceNo,
	taxInvoiceDate,
	skNumber,
	bank,
	currency,
	file,
	selectedCategory,
	reimburseItems,
});

// Toast intance
const toast = useToast();

const onSelectReceived = (event) => {
	if(event.value.TYPE_VALUE == ''){
		receivedType.value = null
	}
}


const searchInvoicePrepaymentNo = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredInvPrepaymentNo.value = [...noInvPrepaymentList.value];
    } else {
      filteredInvPrepaymentNo.value = noInvPrepaymentList.value.filter((comp) => {
        return comp.INVOICE_NO
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};


const fetchSTKNumber = async (props) => {
    try {

	const responseData = await getDataSP1KI({
      company_code: 'dxm',
      vendor_id:props.vendor_id ?props.vendor_id.vendor_id : '',
      type_value: props.received_type ? props.received_type : '',
      vendor_site_id: props.vendor_site_id ? props.vendor_site_id : ''
    })


    let valueResp = responseData.data.data.data;


    stkNumberList.value = valueResp;
    // eslint-disable-next-line no-empty
    } catch (e) {}
}


// Summary invoice
const summary = computed(() => {
	const totalLine =
		(selectedCategory.value.length &&
			selectedCategory.value.reduce((accumulator, obj) => {
				return accumulator + obj.amount;
			}, 0)) ||
		0;

	const totalPpn =
		(selectedCategory.value.length &&
			selectedCategory.value
				.map((el) => {
					return el.vat && el.vat.value
						? el.vat.value * el.amount
						: 0;
				})
				.reduce((prevValue, currValue) => prevValue + currValue, 0)) ||
		0;

	const totalInvoice = totalLine + totalPpn;

	return {
		totalLine,
		totalPpn,
		totalInvoice,
	};
});

// Show reimburse table
// const showReimburseTable = computed(() =>
// 	selectedCategory.value.some((cat) =>
// 		cat.description.toLowerCase().includes('reimburstment')
// 	)
// );

// Mounted hook
onMounted(async () => {
	// Get current user and company list
	const getUser = getCurrentUser();
	const getComp = getCompany();

	await Promise.all([getUser, getComp]);

	// Get invoice detail
	await getInvoiceDetail(props.id);

	// Get category, supplier name, list bank, vat, reimburst group
	const getCategory = getCategoryVendor();
	const getSuppName = getSupplierName();
	const getBank = getListBank();
	const getCurrencies = getCurrencyList();
	const getVat = getVatRate();
	const getVendors = getListVendor();
	const getGroups = getReimburstGroups();

	await Promise.all([
		getSuppName,
		getBank,
		getCurrencies,
		getCategory,
		getVat,
		getVendors,
		getGroups,
	]);
});


// Watch bank list if on detail invoice page
watch(bankList, (newBankList) => {
	bank.value = newBankList.find((item) => item.id === bankAccId.value);
});

// Watch vat rates
watch(vatRates, (newVatRates) => {
	// Populate vat rate data when first load
	selectedCategory.value =
		selectedCategory.value.length &&
		selectedCategory.value.map((cat) => {
			if (cat.vat && typeof cat.vat === 'number') {
				cat.vat = newVatRates.find(
					(vat) => vat.tax_rate_id === cat.vat
				);
			}

			return cat;
		});
});

// Get company
const getCompany = async () => {
	try {
		const res = await InvoiceListProxyService.getCompanyList();

		companyList.value = res.data.data;
	} catch (err) {
		console.log(err);
	}
};

// Get current user
const getCurrentUser = async () => {
	const resAuth = await AuthService.whoAmi();
	currentUser.value = resAuth.data.data;

	// Check if user is not PIC nor finance nor admin
	if (
		currentUser.value.leveltenant !== 3 &&
		currentUser.value.leveltenant !== 1 &&
		currentUser.value.userid !== 'dexaadmin'
	) {
		// Redirect to homepage
		router.push('/dashboard');
		return;
	}
};

// Get invoice detail
const getInvoiceDetail = async (id) => {
	try {

		selectedCategory.value = [];

		loading.value = true;

		const query = route.query;

		const payload = {
			dbInstance: query.i,
			orgId: query.v,
			userId: isUserPic.value ? currentUser.value.id : null,
		};

		const res = await InvoiceListProxyService.getInvoiceDetail(id, payload);

		if (res.status === 200) {
			const invoiceHeader = res.data.data.header;
			const invoiceDetails = res.data.data.details;
			const invoiceFile = res.data.data.file;

			// Assign invoice header
			categoryId.value = invoiceHeader.category_id;
			invoiceNo.value = invoiceHeader.invoice_no;
			invoiceDate.value = new Date(invoiceHeader.invoice_date);
			description.value = invoiceHeader.description;
			taxInvoiceNo.value = invoiceHeader.tax_no;
			taxInvoiceDate.value = new Date(invoiceHeader.tax_date);
			skNumber.value = invoiceHeader.reference_number;
			status.value = invoiceHeader.status;
			if(status.value === 'REVIEW'){
				var rekomendator = await invoiceHeader.REKOMENDATOR.substring(0, invoiceHeader.REKOMENDATOR.lastIndexOf('@') + 0);
				rekomendator = await rekomendator.replace(/\./g,' ').replace(/_/g,' ').toUpperCase();
				status.value = invoiceHeader.status + ' (Waiting Approval : ' + rekomendator + ')'
			}
			if (status.value === 'REJECT'){
				var rekomendatorreject = await invoiceHeader.REKOMENDATOR.substring(0, invoiceHeader.REKOMENDATOR.lastIndexOf('@') + 0); 
				rekomendatorreject = await rekomendatorreject.replace(/\./g, ' ').replace(/_/g, ' ').toUpperCase();
				status.value = invoiceHeader.status + ' (Rejected By : ' + rekomendatorreject + ')';
				}
			rejectReason.value = invoiceHeader.reason;
			recommendatorReason.value = invoiceHeader.recommendator_reason;
			bankAccId.value = invoiceHeader.external_bank_account_id;
			currency.value = invoiceHeader.currency_code;
			vendorId.value = invoiceHeader.vendor_id;
            stkNumber.value = {kiCode: invoiceHeader.NO_KI};
            typePenagihan.value = typeForm.value.find((Q) => Q.TYPE == invoiceHeader.BILLING_CATEGORY)
            receivedType.value = receivedTypeList.value.find((Q) => Q.TYPE_VALUE == invoiceHeader.RECEIVED_TYPE);
            receivedTypeFromDB.value = receivedTypeList.value.find((Q) => Q.TYPE_VALUE == invoiceHeader.RECEIVED_TYPE);
			company.value = companyList.value.find(
				(item) =>
					item.db_instance == invoiceHeader.db_instance &&
					parseInt(item.value) === invoiceHeader.org_id
			);
			costCenter.value = {segment4: invoiceHeader.COST_CENTER}
			ppsNo.value = invoiceHeader.NO_PPS;


			const responseEINVOICE_ID = await getEinvoiceID({
				org_id: invoiceHeader.org_id,
				vendor_id: invoiceHeader.vendor_id,
				invoice_no: invoiceHeader.NO_INVOICE_PREPAYMENT
			});


			invPrepaymentNo.value = responseEINVOICE_ID.data.data

			// Mapping selected currency to currency list
			if (currencyList.value) {
				currency.value = currencyList.value.find(
					(curr) => curr.currency_code === currency.value
				);
			}

			// Assign invoice detail
			for(const item of invoiceDetails){
				const remarksArr = item.remarks && item.remarks.split('#');
				const respData = await getDetailCOAID({coa_id: item.account_id});
				const respSL = item.coa_sl ? await getDetailCOAID({coa_id: item.coa_sl}) : null;
				
				const obj = {
					...item,
					account_id: item.account_id,
					description: item.item_description,
					amount: item.amount,
					vat: item.vat_code_id,
					account: respData.data.data ? respData.data.data['ACCOUNT'] : null,
					account_sl: respSL ? respSL.data.data['ACCOUNT'] : null,
					travelOrder: {
						travelOrderNo: remarksArr ? remarksArr[0] : null,
						guestName: remarksArr ? remarksArr[1] : null,
						startDate: remarksArr ? remarksArr[2] : null,
						endDate: remarksArr ? remarksArr[3] : null,
					},
				};
				selectedCategory.value.push(obj);
			}

			for(const item of invoiceDetails){
				const remarksArr = item.remarks && item.remarks.split('#');
				const respData = await getDetailCOAID({coa_id: item.account_id});
				const respSL = item.coa_sl ? await getDetailCOAID({coa_id: item.coa_sl}) : null;
				const obj = {
					...item,
					account_id: item.account_id,
					description: item.item_description,
					amount: item.amount,
					vat: item.vat_code_id,
					account: respData.data.data ? respData.data.data['ACCOUNT'] : null,
					account_sl: respSL ? respSL.data.data['ACCOUNT'] : null,
					travelOrder: {
						travelOrderNo: remarksArr ? remarksArr[0] : null,
						guestName: remarksArr ? remarksArr[1] : null,
						startDate: remarksArr ? remarksArr[2] : null,
						endDate: remarksArr ? remarksArr[3] : null,
					},
				};
				selectedCategoryFromDB.value.push(obj);
			}

			// Mapping selected vat rate if there is vatRates list
			if (vatRates.value) {
				selectedCategory.value = selectedCategory.value.map((item) => {
					if (item.vat) {
						item.vat = vatRates.value.find(
							(vat) => vat.tax_rate_id === item.vat
						);
					}

					return item;
				});
			}


			// Assign invoice file
			savedFile.value = invoiceFile;
			await getFileBase64(id);

            vendorSiteId.value = invoiceHeader.vendor_site_id

            await fetchSTKNumber({
                vendor_id: invoiceHeader.vendor_id,
                vendor_site_id: invoiceHeader.vendor_site_id,
                org_id: invoiceHeader.org_id,
                received_type: invoiceHeader.RECEIVED_TYPE
            });
		}

		loading.value = false;
	} catch (err) {
		console.log('error',err);
		loading.value = false;
		toast.add({
			severity: 'error',
			summary: 'Invoice data is not found',
			life: 3000,
		});
	}
};

// Get Supplier Name
const getSupplierName = async () => {
	const payload = {
		dbInstance: company.value.db_instance,
		vendorId: vendorId.value,
	};

	try {
		const res = await InvoiceCreateNonPOService.getSupplierName(payload);

		if (res.status === 200) {
			supplierName.value = res.data.data;
		}
	} catch (err) {
		console.log(err);
	}
};

// Get bank account list
const getListBank = async () => {
	const payload = {
		dbInstance: company.value.db_instance,
		orgId: company.value.value,
		vendorId: vendorId.value,
	};

	const res = await InvoicePOService.getListBank(payload);

	if (res.status === 200) {
		bankList.value = res.data.data.map((item) => {
			return {
				id: item.ext_bank_account_id,
				name: item.transfer_to,
			};
		});
	}
};

// Get line categories
const getCategoryVendor = async () => {
	const payload = {
		dbInstance: company.value.db_instance,
		orgId: company.value.value,
		categoryId: categoryId.value,
	};

	const res = await InvoiceListProxyService.getCategory(payload);

	if (res.status === 200) {
		categoryHeader.value = res.data.data.categoryHeader;

		if (!isForwarder.value) {
			categoryDetail.value = res.data.data.categoryDetail;
		} else {
			categoryDetail.value = [];
		}
	}
};


watch(() => typePenagihan.value, async (text) => {
	if(text['TYPE'] == 'Realization'){
		const respData = await getInvoiceNumberPrepayment({
			org_id: company.value.value,
			vendor_id: vendorId.value,
		});
		noInvPrepaymentList.value = respData.data.data;
	}
})

const onSelectTipeInvoice = () => {
	selectedCategory.value = [];
	invPrepaymentNo.value = null;
	stkNumber.value = null;
	description.value = null;
}

const onChangeInvPrepaymentNo = async (data) => {
	selectedCategory.value = [];
	const responseInv = await getInvoiceDetailPrepayment({einvoice_id: data.value.EINVOICE_ID});
	const header = responseInv.data.data.respHeader[0];
	for(const q of responseInv.data.data.respDetail){
		const coaIDValue = await getDetailCOAID({coa_id: q.COA_SL});
		let vatData = null;
		
		for (const vat of vatRates.value) {
			if (vat.tax_rate_id == q['VAT_CODE_ID']) {
				vatData = vat;
			}
		}

		const spliter = coaIDValue.data.data['ACCOUNT'].split("");
		spliter.splice(spliter.length - 1, 1, "0");

		const coaFull = spliter.join("");

		const resultCOA = await findCoaByNumber({ACCOUNT: coaFull});
		const coaID = resultCOA.data.data.CODE_COMBINATION_ID;
		const ACCOUNTDATA = resultCOA.data.data.ACCOUNT;

		let obj = {
			description: q.ITEM_DESCRIPTION,
			amount: q.AMOUNT,
			vat: null,
			account_id: coaID,
			account: ACCOUNTDATA,
			travelOrder: {
				travelOrderNo: header['NO_KI'],
				guestName: null,
				startDate: null,
				endDate: null,
			},
			coa_sl: coaID,
			account_sl: ACCOUNTDATA
		}
		
		if (vatData) {
			obj.percentage_rate = vatData.percentage_rate;
			obj.vat_code = vatData.vat_code;
			obj.tax_rate_id = vatData.tax_rate_id;
			obj.vat = vatData;
		}

		selectedCategory.value.push(obj);
	}
	currency.value = {currency_code: header['CURRENCY_CODE']}
	costCenter.value = {segment4: header['COST_CENTER']}
	stkNumber.value = {kiCode: header['NO_KI']}
	description.value = header['DESCRIPTION']
	ppsNo.value = header['NO_PPS']
	taxInvoiceNo.value = header['TAX_NO']
}


// Get VAT rate options
const getVatRate = async () => {
	const payload = {
		dbInstance: company.value.db_instance,
		invoiceDate:
			invoiceDate.value && formattingDate(new Date(invoiceDate.value)),
	};

	const res = await InvoiceCreateNonPOService.getVatRate(payload);

	if (res.status === 200) {
		vatRates.value = res.data.data.map((item) => ({
			...item,
			value: Math.round((item.percentage_rate / 100) * 1000) / 1000,
		}));

		// Push no select option
		vatRates.value = [
			{ vat_code: '-No Select-', value: null },
			...vatRates.value,
		];
	}
};

// Get list vendor
const getListVendor = async () => {
	try {
		const res = await InvoiceCreateNonPOService.getListVendor({
			dbInstance: company.value.db_instance,
		});

		if (res.status === 200) {
			vendors.value = res.data.data;

			// Mapping vendor name on table raimbursemnet
			reimburseItems.value = reimburseItems.value.map((item) => {
				if (item.vendor) {
					const vendor = vendors.value.find(
						(vendor) => vendor.vendor_id === item.vendor
					);
					return {
						...item,
						vendor,
					};
				}

				return item;
			});
		}
	} catch (err) {
		console.log(err);
	}
};

// Get reimburst groups options
const getReimburstGroups = async () => {
	const payload = {
		dbInstance: company.value.db_instance,
		type: 'DXG_REIMBURST_GROUP',
	};

	try {
		const res = await InvoiceCreateNonPOService.getReimburstGroups(payload);

		if (res.status === 200) {
			reimburstGroups.value = res.data.data;

			// Mapping selected group dropdown on table raimbursemnet
			reimburseItems.value = reimburseItems.value.map((item) => {
				const group = reimburstGroups.value.find(
					(grp) => grp.name === item.group
				);

				return {
					...item,
					group,
				};
			});
		}
	} catch (err) {
		console.log(err);
	}
};

// Get currency list
const getCurrencyList = async () => {
	try {
		const res = await InvoiceListProxyService.getCurrencyList({
			dbInstance: company.value.db_instance,
		});

		if (res.status === 200) {
			currencyList.value = res.data.data;

			// Mapping selected currency
			currency.value = currencyList.value.find(
				(curr) => curr.currency_code === currency.value
			);
		}
	} catch (err) {
		console.log(err);
	}
};

// Search company on autocomplete
const searchCompany = (event) => {
	if (!event.query.trim().length) {
		filteredCompany.value = [...companyList.value];
	} else {
		filteredCompany.value = companyList.value.filter((item) => {
			return item.name.toLowerCase().match(event.query.toLowerCase());
		});
	}
};

// Search bank on autocomplete
const searchBank = (event) => {
	if (!event.query.trim().length) {
		filteredBanks.value = [...bankList.value];
	} else {
		filteredBanks.value = bankList.value.filter((bank) => {
			return bank.name.toLowerCase().match(event.query.toLowerCase());
		});
	}
};

// Search currency on autocomplete
const searchCurrency = (event) => {
	if (!event.query.trim().length) {
		filteredCurrencies.value = [...currencyList.value];
	} else {
		filteredCurrencies.value = currencyList.value.filter((curr) => {
			return curr.currency_code
				.toLowerCase()
				.match(event.query.toLowerCase());
		});
	}
};

// Search category on autocomplete


// Search vendors on autocomplete

// Add SK Number
const addSKNumber = (data) => {
	skNumber.value = data.sk_number;
	displaySKModal.value = false;
	isWarningShown.value = false;

	if (isForwarder.value) {
		// Add removed details payload
		removedLines.value = [...removedLines.value, ...selectedCategory.value];
		removedReimburseItems.value = [
			...removedReimburseItems.value,
			...reimburseItems.value,
		];

		// Add po number to description field
		description.value = data.po_number;

		// Reset fields
		selectedCategory.value = [];
		category.value = null;
		reimburseItems.value = [];
	}
};

// Delete attachment file
const handleDeleteFile = () => {
	deletedFile.value = savedFile.value;
	savedFile.value = null;
};

// Select file upload
const onSelectFile = (e) => {
	file.value = e.files[0];
};

// Remove file upload
const onRemoveFile = (e) => {
	if (!e.files.length) {
		file.value = null;
	}
};

// React to select invoice date event
const handleSelectInvoiceDate = () => {
	// Reset vat options and selected vat
	vatRates.value = null;
	selectedCategory.value = selectedCategory.value.map((item) => ({
		...item,
		vat: null,
	}));
	getVatRate();
};

// Add line category

// Delete line category

const handleDeleteCategory = (deletedIndex) => {
	if(selectedCategory.value.length > 1){
		selectedCategory.value.splice(deletedIndex,1)
		return
	}

	toast.add({
		severity: 'error',
		summary: 'Cannot Deleted All Line',
		life: 3000,
	});
};





// Formatting date
const formattingDate = (date, withTime) => {
	const theDate = new Date(date);
	const strDate =
		theDate.getFullYear() +
		'-' +
		(theDate.getMonth() + 1) +
		'-' +
		theDate.getDate();
	const strTime =
		theDate.getHours() +
		':' +
		theDate.getMinutes() +
		':' +
		theDate.getSeconds();

	if (withTime) {
		return `${strDate} ${strTime}`;
	} else {
		return `${strDate}`;
	}
};

// Save new invoice
const handleSave = async () => {
	// Reset validation state
	v$.value.$reset();
	errorExisting.value = false;

	// Validate required fields
	const isFormValid = await v$.value.$validate();
	if (!isFormValid) {
		let errMessage;

		if (v$.value.$errors[0].$validator === 'required') {
			errMessage = 'Please fill all required fields';
		} else {
			if (Array.isArray(v$.value.$errors[0].$message)) {
				errMessage = v$.value.$errors[0].$message.find(
					(err) => err.length
				)[0];
			} else {
				errMessage = v$.value.$errors[0].$message;
			}
		}

		toast.add({
			severity: 'error',
			summary: errMessage,
			life: 3000,
		});
		return;
	}

	// Validate if there is no details
	if (!selectedCategory.value.length) {
		toast.add({
			severity: 'error',
			summary: 'Please add invoice details',
			life: 3000,
		});
		return;
	}

	const formData = new FormData();

	// New file payload
	if (!savedFile.value && file.value) {
		formData.append('file', file.value);
	}

	// Add current payload for header and details
	const payload = {
		header: {
			EINVOICE_ID: props.id,
			INVOICE_NO: invoiceNo.value,
			INVOICE_DATE: formattingDate(invoiceDate.value),
			TAX_NO: taxInvoiceNo.value,
			TAX_DATE: formattingDate(taxInvoiceDate.value),
			DESCRIPTION: description.value,
			CURRENCY_CODE: currency.value.currency_code,
			VENDOR_ID: vendorId.value,
			INVOICE_AMOUNT: summary.value.totalLine,
			TAX_AMOUNT: summary.value.totalPpn,
			TOTAL_AMOUNT: summary.value.totalInvoice,
			LAST_UPDATE_DATE: formattingDate(new Date(), true),
			LAST_UPDATED_BY: currentUser.value.id,
			EXTERNAL_BANK_ACCOUNT_ID: bank.value.id,
			REFERENCE_NUMBER: skNumber.value,
			NO_PPS: ppsNo.value,
            COST_CENTER: costCenter.value.segment4,
            NO_KI: stkNumber.value.kiCode,
            RECEIVED_TYPE: receivedType.value.TYPE_VALUE,
            BILLING_CATEGORY: receivedType.value.TYPE_VALUE == 'panitia' ? typePenagihan.value.TYPE : null,
			NO_INVOICE_PREPAYMENT: invPrepaymentNo.value ? invPrepaymentNo.value.INVOICE_NO : null
		},
		details: [],
		newDetails: [],
		removedDetails: [],
		reimburseItems: [],
		newReimburseItems: [],
		removedReimburseItems: [],
		deletedFile: null,
	};

	console.log(selectedCategory.value, 'SEL AFTER SEL')

	// New details payload
	payload.newDetails = selectedCategory.value
			.map((item, index) => {
				
				const remarksString =
					item.travelOrder.travelOrderNo

				// Calculate line num of invoice item
				const lineNum =
					payload.details.length +
					index +
					1 -
					payload.removedDetails.length;

				return {
					EINVOICE_ID: props.id,
					LINE_NUM: lineNum,
					ITEM_DESCRIPTION: item.description,
					AMOUNT: item.amount,
					ORGANIZATION_ID: company.value.value,
					VAT_CODE:
						item.vat && item.vat.value ? item.vat.vat_code : null,
					CREATION_DATE: formattingDate(new Date(), true),
					CREATED_BY: currentUser.value.id,
					LAST_UPDATE_DATE: formattingDate(new Date(), true),
					LAST_UPDATED_BY: currentUser.value.id,
					REMARKS: remarksString,
					CURRENCY_CODE: currency.value.currency_code,
					REVISION_NUM: 0,
					CATEGORY_DETAIL_ID: item.category_detail_id,
					COA_ID: item.account_id,
					COA_SL: item.coa_sl,
					VAT_CODE_ID:
						item.vat && item.vat.value
							? item.vat.tax_rate_id
							: null,
				};
			});

	// Removed details payload
    payload.removedDetails = selectedCategoryFromDB.value;


	// Add deleted file payload
	if (deletedFile.value && file.value) {
		payload.deletedFile = deletedFile.value;
	}

	// Wrap payload with formData
	const jsonPayload = JSON.stringify(payload);

	formData.append('payload', jsonPayload);

	try {
		progress.value = true;




		const res = await InvoiceListProxyService.updateInvoice(
			props.id,
			formData
		);

		if (res.status === 200) {
			progress.value = false;
			invoiceNo.value = res.data.data.invoice_no;
			deletedFile.value = null;
			removedLines.value = [];
			removedReimburseItems.value = [];
			v$.value.$reset();

			if (file.value) {
				file.value = null;
				fileUpload.value.clear();
			}

			// Re-assign saved invoice details
			getInvoiceDetail(props.id);

			toast.add({
				severity: 'success',
				summary: 'Success',
				detail: 'Successfully update invoice',
				life: 3000,
			});

			return;
		}
		progress.value = false;
	} catch (err) {
		console.log(err.message);
		progress.value = false;

		if (err.message.includes('Network Error')) {
			console.log('network error');
			toast.add({
				severity: 'error',
				summary: 'Network Error',
				detail: 'Connection Down. Please check your invoice status on List Invoice Non PO menu.',
			});
			return;
		}

		if (err.response.status === 400 || err.response.status === 403) {
			if (err.response.data.message.includes('exist')) {
				errorExisting.value = true;
			}
			toast.add({
				severity: 'error',
				summary: 'Error',
				detail: err.response.data.message,
				life: 3000,
			});
			return;
		}

		toast.add({
			severity: 'error',
			summary: 'Server Error',
			detail: 'Unsuccessfully save new invoice',
			life: 3000,
		});
	}
};

const getFileBase64 = async (invoiceId) => {
	try {
		const leveltenant = sessionStorage.getItem('leveltenant');
		const res = await InvoiceListProxyService.getFileBase64(
			invoiceId,
			leveltenant,
			vendorId.value
		);

		if (res.status === 200) {
			fileBuffer.value = res.data.data;
		}
	} catch (err) {
		console.log(err);
	}
};

const searchTypeForm = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredTypeForm.value = [...typeForm.value];
    } else {
      filteredTypeForm.value = typeForm.value.filter((comp) => {
        return comp.TYPE
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};

const selectedSTKNumber = async (event) => {
	description.value = event.value.eventName;
	const dataBase = event.value.row
    selectedCategory.value = [];

	let coa_sl;

	if(typePenagihan.value && typePenagihan.value.TYPE == 'Prepayment'){
		const result = await getCOASLDetail({COST_CENTER: dataBase[0].segment3});
		coa_sl = result.data.data;
	}

	for(const map of dataBase){
		costCenter.value = {
			segment4: map.segment3
		}
		const concated = `${map.segment1}-DOM-${map.segment3}-${map.segment4}-${map.segment5}-INA-000000`
		let responseData;

		if(!concated.includes('null')){
			responseData = await getDetailCostCenter({
				company_code: map.segment1,
				account: concated
			})
		}

		let obj;

		if(typePenagihan.value && typePenagihan.value.TYPE == 'Prepayment'){
			obj = {
				description: (map.accountName + " - " + event.value.eventName).substring(0,240),
				amount: map.accountValue,
				vat: null,
				coa_sl: responseData.data.data.CODE_COMBINATION_ID,
				account_sl: responseData.data.data.CODE_COMBINATION_ID ? concated : null,
				account_id: coa_sl ? coa_sl['CODE_COMBINATION_ID'] : null,
				account: coa_sl ? coa_sl['ACCOUNT'] : null,
				travelOrder: {
					travelOrderNo: map.refNumber,
					guestName: null,
					startDate: null,
					endDate: null,
				},
			}
		}else{
			obj = {
				description: (map.accountName + " - " + event.value.eventName).substring(0,240),
				amount: map.accountValue,
				vat: null,
				account_id: responseData.data.data.CODE_COMBINATION_ID,
				account: responseData.data.data.CODE_COMBINATION_ID ? concated : null,
				account_sl: responseData.data.data.CODE_COMBINATION_ID,
				coa_sl: responseData.data.data.CODE_COMBINATION_ID ? concated : null,
				travelOrder: {
					travelOrderNo: map.refNumber,
					guestName: null,
					startDate: null,
					endDate: null,
				},
			}
		}

		selectedCategory.value.push(obj);
	}

	console.log(selectedCategory.value, 'SEL CATEGORY VAL')
}

const searchReceivedType = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredReceivedType.value = [...receivedTypeList.value];
    } else {
      filteredReceivedType.value = receivedTypeList.value.filter((comp) => {
        return comp.TYPE_NAME
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};

watch(()=>costCenter.value, async () => {
	await getAccount();
})

const getAccount = async () => {
	//
	if (
		costCenter.value.segment4
	) {
		const payload = {
			orgId: company.value.value,
			dbInstance: 'COMET',
			cost_center: costCenter.value.segment4,
		};
		const res = await SearchInvoiceProxyNonPO.accountPic(payload);
		if (res.status === 200) {
			accountData.value = res.data.data.data;
		}
	}
};

const getAccountId = async (event, index) => {
	selectedCategory.value[index].account =  event.value ? event.value.account : null;
	selectedCategory.value[index].account_id = event.value ? event.value.code_combination_id : null;	
};

const getAccountIdSL = async (event, index) => {
	selectedCategory.value[index].account_sl =  event.value ? event.value.account : null;
	selectedCategory.value[index].coa_sl = event.value ? event.value.code_combination_id : null;	
};



const searchAccount = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredAccount.value = [...accountData.value];
		} else {
			filteredAccount.value = accountData.value.filter((val) => {
				if (val.account === null || val.account === undefined) {
					return val;
				}
				return val.account
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
		}
	}, 250);
}

const searchSTKNumber = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredSTKNumber.value = [...stkNumberList.value];
    } else {
      filteredSTKNumber.value = stkNumberList.value.filter((comp) => {
        return comp.STK_NO
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};

watch(() => selectedCategory.value, (text) => {console.log('SEL CAT', text)})

const onDownload = async () => {
	try {
		loadingDownladFile.value = true;
		const leveltenant = sessionStorage.getItem('leveltenant');
		const res = await InvoiceListProxyService.getFileBase64(
			props.id,
			leveltenant,
			vendorId.value
		);

		if (res.status === 200) {
			fileBuffer.value = res.data.data;
		}
	} catch (err) {
		loadingDownladFile.value = false;
		toast.add({
			severity: 'error',
			summary: 'Connection Unstable !',
			life: 3000,
		});
		throw Error('Error downloading file');
	}
	const linkSource = `data:application/pdf;base64,${fileBuffer.value}`;
	const downloadLink = document.createElement('a');
	const fileName = savedFile.value.filename;
	downloadLink.href = linkSource;
	downloadLink.download = fileName;
	downloadLink.click();
	loadingDownladFile.value = false;
};
</script>

<style scoped>
.form-card {
	padding: 24px;
	border: 1px solid #e0e0e0;
	border-radius: 16px;
}
</style>
