<template>
  <AutoComplete
    :suggestions="filteredCostCenter"
    @complete="searcSupplierSite($event)"
    :dropdown="true"
    field="SEGMENT4"
    :placeholder="placeholder"
    v-model="costCenter"
    forceSelection
  >
    <template #item="slotProp">
      {{ slotProp.item.SEGMENT4 }}
    </template>
  </AutoComplete>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect } from "vue";
//   import LovService from "@/service/LovService";
//   import SwithCompany from "@/service/SwithCompany";
// import AuthService from "@/service/AuthService";
import invoiceNonPOservice from "@/service/InvoiceNonPOservice";

// eslint-disable-next-line no-undef
const filteredCostCenter = ref([]);
const costCenterList = ref([]);
const costCenter = ref();
const placeholder = ref("Select a cost center");

const emit = defineEmits(["costCenter"]);

watchEffect(() => emit("costCenter", costCenter));
// eslint-disable-next-line no-undef
const props = defineProps({
  company: {
    type: Object,
  },
  costCenterValue: {
    type: String,
  },
});

onMounted(async () => {
  try {
    // const response = await AuthService.whoAmi();
    // const user = response.data.data;
    if (props.company) {
      const result = await invoiceNonPOservice.getListCostCenter(
        props.company.value,
        props.company.db_instance
      );
      costCenterList.value = result.data.data;
      if (props.costCenterValue) {
        for (const costCenterData of costCenterList.value) {
          if (costCenterData.SEGMENT4 == props.costCenterValue) {
            costCenter.value = costCenterData;
          }
        }
      }
    }

    // eslint-disable-next-line no-empty
  } catch (e) {
    throw new Error(e);
  }
});

const searcSupplierSite = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredCostCenter.value = [...costCenterList.value];
    } else {
      filteredCostCenter.value = costCenterList.value.filter((costCenter) => {
        return costCenter.SEGMENT4.toLowerCase().match(
          event.query.toLowerCase()
        );
      });
    }
  }, 250);
};
</script>
