<template>
	<DataTable
		:value="capaList"
		:lazy="true"
		:paginator="true"
		:rows="10"
		:loading="loading"
		:totalRecords="totalData"
		@page="onPage($event)"
		@sort="onSort($event)"
		:rowsPerPageOptions="[10, 20, 30]"
		responsiveLayout="scroll"
		scrollHeight="600px"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #empty>{{ searchMessage }}</template>
		<template #loading>Loading data, please wait...</template>
		<Column header="Company" field="COMPANY_NAME" :sortable="true">
		</Column>
		<Column
			v-if="!isSupplierPage"
			header="Supplier"
			field="VENDOR_NAME"
			:sortable="true"
		>
		</Column>
		<Column header="Document No" field="DOC_NUMBER" :sortable="true">
		</Column>
		<Column header="Document Date" field="CREATION_DATE" :sortable="true">
			<template #body="{ data }">
				{{ convertDate(data.CREATION_DATE) }}
			</template>
		</Column>
		<Column header="Subject" field="FINDING" :sortable="true"> </Column>
		<Column header="Respond Date" field="RESPOND_DATE" :sortable="true">
			<template #body="{ data }">
				{{ convertDate(data.RESPOND_DATE) }}
			</template>
		</Column>
		<Column header="Response" field="RESPOND" :sortable="true"> </Column>
		<Column header="Status" field="STATUS" :sortable="true">
			<template #body="{ data }">
				{{ convertStatus(data.STATUS) }}
			</template>
		</Column>
		<Column header="Actions">
			<template #body="{ data }">
				<Button
					icon="pi pi-pencil"
					class="p-button-rounded p-button-text"
					@click="handleEdit(data)"
				/>
			</template>
		</Column>
	</DataTable>
</template>

<script setup>
import {
	// onCreated,
	onMounted,
	ref,
	watch,
} from 'vue';
import { searchlistcapa } from '@/service/CAPAServices';
import AuthService from '@/service/AuthService';
// import PoFulfillment from "@/service/PoFulfillment";
// import LovService from "@/service/LovService";
import { useRouter } from 'vue-router';

// eslint-disable-next-line no-undef
const props = defineProps({
	company: {
		type: Object,
	},
	supplier: {
		type: Object,
	},
	documentNumber: {
		type: String,
	},
	status: {
		type: String,
	},
	preparer: {
		type: Object,
	},
	searchAction: {
		type: Boolean,
	},
	resetSearch: {
		type: Boolean,
	},
});

const company = ref();
const supplier = ref();
const capaList = ref();
const totalData = ref(0);
const totalPage = ref(0);
const loading = ref(false);
const page = ref(1);
// const rowsPerPage = ref(10)
// const sortColumn = ref()
// const sortType = ref()
const limitPerPage = ref(10);
const sortField = ref();
const sortOrder = ref();
const levelTenant = ref();
const isSupplierPage = ref();
const route = useRouter();
const searchMessage = ref('Click search for find data capa');
watch(
	() => props.resetSearch,
	async () => {
		await resetTable();
	}
);
onMounted(async () => {
	company.value = props.company;
	const info = await AuthService.whoAmi();
	if (info.status === 200) {
		levelTenant.value = info.data.data.leveltenant;
		loading.value = true;
		if (levelTenant.value !== 2) {
			supplier.value = props.supplier;
			isSupplierPage.value = false;
			// loading.value = true
			await populateTable();
		} else {
			isSupplierPage.value = true;
			supplier.value = props.supplier;
			await populateTable();
		}
	}
});

const populateTable = async () => {
	// loading.value = true
	try {
		if (props.searchAction) {
			let resultSearch = await searchlistcapa(
				page.value,
				limitPerPage.value,
				sortField.value,
				sortOrder.value,
				company.value ? company.value.value : null,
				company.value ? company.value.db_instance : null,
				props.supplier ? props.supplier.vendor_id : null,
				// props.company.value, props.supplier,
				props.documentNumber,
				props.status ? props.status.col : null,
				props.preparer ? props.preparer.user_id : null
			);
			capaList.value = resultSearch.data.data;
			totalData.value = resultSearch.data.totalData;
			totalPage.value = resultSearch.data.totalPage;
			if (resultSearch.data.data.length === 0) {
				searchMessage.value = 'No Data Found';
			}
		}
	} catch (error) {
		searchMessage.value =
			'Something Wrong, Please Click Button Search Again';
		loading.value = false;
	}

	loading.value = false;
};

const onPage = async (event) => {
	// loading.value = true
	page.value = event.page + 1;
	limitPerPage.value = event.rows;
	await populateTable();
};

const onSort = async (event) => {
	// loading.value = true
	page.value = 1;
	limitPerPage.value = event.rows;
	sortField.value = event.sortField;
	sortOrder.value = event.sortOrder;
	await populateTable();
};

const convertStatus = (data) => {
	if (data === 'O') {
		return 'Open';
	} else if (data === 'C') {
		return 'Closed';
	} else if (data === 'X') {
		return 'Cancel';
	} else {
		return '';
	}
};

const convertDate = (data) => {
	let convertedDate = null;
	let option = {
		day: 'numeric',
		month: 'short',
		year: 'numeric',
	};
	convertedDate = new Date(data).toLocaleDateString('id-ID', option);
	if (convertedDate == 'Invalid Date' || convertedDate == '1 Jan 1970') {
		convertedDate = '';
	}
	return convertedDate;
};

const handleEdit = (data) => {
	if (!isSupplierPage.value) {
		//purchasing
		if (data.RESPOND === null) {
			route.push({
				name: 'AddCapa',
				params: {
					id: data.CAPA_ID,
				},
			});
		} else {
			route.push({
				name: 'UpdateCapaPur',
				params: {
					id: data.CAPA_ID,
				},
			});
		}
	} else {
		if (data.RESPOND === null) {
			route.push({
				name: 'UpdateCapaSupp',
				params: {
					id: data.CAPA_ID,
				},
			});
		} else {
			route.push({
				name: 'UpdateCapaPur',
				params: {
					id: data.CAPA_ID,
				},
			});
		}
	}
};

const resetTable = async () => {
	capaList.value = null;
};
</script>
