<template>
	<DataTable
		id="datatable-search-inv-non-po"
		:value="tableList"
		:paginator="true"
		:rows="10"
		:rowsPerPageOptions="[10, 20, 30]"
		responsiveLayout="scroll"
		:scrollable="true"
		scrollHeight="600px"
		scrollDirection="both"
		@page="onPage($event)"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #empty>No Invoice found</template>
		<template #loading>Loading data</template>
		<Column header="Description" style="width: 15%">
			<template #body="slotProps">
				<InputText
					id="inputtext"
					class="w-full"
					type="text"
					v-model="slotProps.data.ITEM_DESCRIPTION"
					:disabled="props.disabled || isForwarder"
				/>
			</template>
		</Column>
		<Column header="Amount" style="width: 15%">
			<template #body="slotProps">
				<InputNumber
					id="locale-german"
					class="w-full"
					v-model="slotProps.data.AMOUNT"
					min="0"
					:minFractionDigits="2"
					locale="de-DE"
					:disabled="props.disabled"
					@input="onChangeText($event, slotProps.index + page)"
					style="text-align: right"
				/>
			</template>
		</Column>
		<Column header="VAT" style="width: 15%">
			<template #body="slotProps">
				<Dropdown
					class="w-full"
					:editable="true"
					v-model="slotProps.data.VAT_CODE"
					@change="onChangeVAT($event, slotProps.index + page)"
					:options="getVAT"
					optionLabel="VAT_CODE"
					optionValue="TAX_RATE_ID"
					style="width: 100%"
					:disabled="props.disabled"
					forceSelection
				/>
			</template>
		</Column>
		<Column header="WHT" style="width: 15%">
			<template #body="slotProps">
				<Dropdown
					class="w-full"
					:editable="true"
					v-model="slotProps.data.WHT_CODE"
					@change="onChangeWHT($event, slotProps.index + page)"
					:options="getWHT"
					optionLabel="WHT_CODE"
					optionValue="WHT_CODE_ID"
					style="width: 100%"
					:disabled="props.disabled"
					forceSelection
				/>
			</template>
		</Column>
		<Column header="Account" style="width: 15%">
			<template #body="slotProps">
				<!-- <Dropdown
          :editable="true"
          v-model="slotProps.data.ACCOUNT"
          @change="onChangeCOA($event, slotProps.index)"
          :options="getCOA"
          optionLabel="ACCOUNT"
          optionValue="CODE_COMBINATION_ID"
          style="width: 100%"
          :disabled="props.disabled"
          forceSelection
        /> -->
				<AutoComplete
					class="w-full"
					:suggestions="filteredCoa"
					@complete="searchAccount($event)"
					:dropdown="true"
					field="ACCOUNT"
					:placeholder="placeholder"
					v-model="slotProps.data.ACCOUNT"
					style="width: 100%"
					:disabled="props.disabled"
					@item-select="onChangeCOA($event, slotProps.index + page)"
					forceSelection
				>
					<!-- <template #item="slotProp">
            {{ slotProp.item.CONCATENATED_SEGMENTS }}
          </template> -->
				</AutoComplete>
			</template>
		</Column>
		<Column header="Ref Number" style="width: 30%">
			<template #body="slotProps">
				<div class="grid formgrid">
					<div class="col-10 mb-0 lg:col-10 lg:mb-0">
						<InputText
							id="REMARKS"
							v-if="slotProps.data.travelOrder"
							placeholder="Ref Number"
							disabled
							v-model="slotProps.data.travelOrder.travelOrderNo"
						/>
						<InputText
							id="REMARKS"
							v-else
							placeholder="Ref Number"
							disabled
							v-model="slotProps.data.REMARKS"
						/>
						<Button
							icon="pi pi-search"
							class="p-button-secondary"
							@click="openModal(slotProps.index + page)"
						/>
					</div>
					<div class="col-1 mb-0 lg:col-1 lg:mb-0">
						<Button
							icon="pi pi-trash"
							class="p-button-rounded p-button-text"
							@click="
								deleteLine(
									slotProps.data,
									slotProps.index + page
								)
							"
							v-tooltip="'Delete'"
							:disabled="props.disabled"
						/>
					</div>
				</div>
			</template>
		</Column>
	</DataTable>
	<Dialog
		header="Ref Number"
		v-model:visible="displayModal"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '50vw' }"
		:modal="true"
		:dismissableMask="true"
		:draggable="false"
	>
		<div class="pt-3">
			<FindTOModal
				:supplierName="supplierInInvoiceHeader"
				@on-close="closeTOModal"
				@on-add="handleAddTO"
			/>
		</div>
	</Dialog>
	<Dialog
		header="Delete Reimburse"
		v-model:visible="deleteLineValue"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '50vw' }"
		:modal="true"
	>
		<div class="confirmation-content">
			<i
				class="pi pi-exclamation-triangle mr-3"
				style="font-size: 2rem"
			/>
			<span v-if="tableLineValue"
				>Are you sure you want to delete Line for
				<b>{{ tableLineValue.ITEM_DESCRIPTION }}</b
				>?</span
			>
		</div>
		<template #footer>
			<Button
				label="No"
				icon="pi pi-times"
				class="p-button-text"
				@click="deleteLineValue = false"
			/>
			<Button
				label="Yes"
				icon="pi pi-check"
				class="p-button-text"
				@click="deleteTableLine"
			/>
		</template>
	</Dialog>
</template>

<script setup>
import { onMounted, ref, defineEmits, watch } from 'vue';
import AuthService from '@/service/AuthService';
import {filterAuthByVendorname} from "@/helper/filterAuthByVendorname"
import { useToast } from 'primevue/usetoast';
import ListInvoiceNonPo from '@/service/ListInvoiceNonPo';
import invoiceNonPOservice from '@/service/InvoiceNonPOservice';
import FindTOModal from '../../../components/FindTOModal.vue';

const company = ref();
const supplier = ref();
// const idValue = ref()
const tableList = ref();
const page = ref(0);
const line = ref({});
const emit = defineEmits('tableList');
const displayModal = ref(false);
const getVAT = ref([]);
const getWHT = ref();
const getCOA = ref([]);
const filteredCoa = ref([]);
const listCategoryDetail = ref();
// const filteredVAT = ref();
const travelOrderIndex = ref(null);

onMounted(async () => {
	company.value = props.company;
	const info = await AuthService.whoAmi();
	if (info.status === 200) {
		levelTenant.value = info.data.data.leveltenant;
		if (levelTenant.value !== 2) {
			loading.value = true;
			supplier.value = props.supplier;
			//   loading.value = true
			await populateTable();
		} else {
			if (company.value && !supplier.value) {
				const param = {
					db_instance: props.company.db_instance,
					org_id: props.company.value,
				};
				
				const checkAuth = await filterAuthByVendorname({
					info_id: info.data.data.id,
					dbInstance: param.db_instance,
					value: param.org_id
				});

				// if(checkAuth.supplier === null) router.push("/dashboard");

				supplier.value = checkAuth.supplier;
				await populateTable();
			}
			// loading.value = true
		}
	}
});
// eslint-disable-next-line no-undef
const props = defineProps({
	company: {
		type: Object,
	},
	data: {
		type: Object,
	},
	line: {
		type: Object,
	},
	vendor_id: {
		type: Object,
	},
	org_id: {
		type: Object,
	},
	db_instance: {
		type: Object,
	},
	disabled: {
		type: Boolean,
	},
	categoryHeader: {
		type: Object,
	},
	detailCat: {
		type: Object,
	},
	dataLine: {
		type: Object,
	},
	supplierInInvoiceHeader: {
		type: String,
	},
	invoiceDate: {
		type: Object,
	},
	costCenter: {
		type: String,
	},
});

const toast = useToast();
const loading = ref(false);
const levelTenant = ref();
// const payload = ref();
const deleteLineValue = ref();
const tableLineValue = ref();
// const index = ref()

watch(
	() => props.line,
	(newValue) => {
		if (newValue > 0) {
			addLine();
		}
	}
);

const populateTable = async () => {
	loading.value = true;
	if (props.dataLine !== null) {
		await getDropdownVat();
		await getDropdownWHT();
		await getDropdownCOA();
		await getListCategoryDetail();
		tableList.value = props.dataLine;

		await tableList.value.forEach(async (element, index) => {
			let isVatNotExist = true;
			for (const vatData of await getVAT.value) {
				if (
					(await element.VAT_CODE_ID) == (await vatData.TAX_RATE_ID)
				) {
					tableList.value[index].PERCENTAGE_RATE =
						await vatData.PERCENTAGE_RATE;
					tableList.value[index].VAT_CODE = await vatData.VAT_CODE;
					tableList.value[index].VAT_CODE_ID =
						await vatData.TAX_RATE_ID;
					isVatNotExist = false;
				}
			}

			if (isVatNotExist) {
				tableList.value[index].PERCENTAGE_RATE = null;
				tableList.value[index].VAT_CODE = '- No Select -';
				tableList.value[index].VAT_CODE_ID = null;
			}

			for (const whtData of await getWHT.value) {
				if (
					(await element.WHT_CODE_ID) == (await whtData.WHT_CODE_ID)
				) {
					tableList.value[index].WHT_CODE = await whtData.WHT_CODE;
					tableList.value[index].WHT_RATE = await whtData.WHT_RATE;
					tableList.value[index].WHT_CODE_ID =
						await whtData.WHT_CODE_ID;
				}
			}

			for (const coaData of await getCOA.value) {
				if (
					(await element.COA_ID) ==
					(await coaData.CODE_COMBINATION_ID)
				) {
					tableList.value[index].CODE_COMBINATION_ID =
						await coaData.CODE_COMBINATION_ID;
					tableList.value[index].ACCOUNT = await coaData.ACCOUNT;
				}
			}

			for (const categoryDetail of await listCategoryDetail.value) {
				if (
					(await element.CATEGORY_DETAIL_ID) ==
					(await categoryDetail.category_detail_id)
				) {
					tableList.value[index].CATEGORY_DETAIL =
						await categoryDetail.category_detail;
				}
			}
		});

		emit('tableList', tableList);
	}
	loading.value = false;
};

const onPage = async (event) => {
	page.value = event.page * event.rows;
};

const addLine = async () => {
	if (!props.disabled) {
		// const index = tableList.value.length - 1;
		line.value.EINVOICE_LINE_ID = 0;
		line.value.ITEM_DESCRIPTION = await props.detailCat.category_detail;
		line.value.CATEGORY_DETAIL = await props.detailCat.category_detail;
		line.value.CATEGORY_DETAIL_ID = await props.detailCat
			.category_detail_id;
		line.value.ORGANIZATION_ID = await props.org_id;
		line.value.AMOUNT = 0;
		line.value.TAX_RATE_ID = null;
		line.value.VAT_CODE = null;
		line.value.PERCENTAGE_RATE = null;
		line.value.WHT_CODE_ID = null;
		line.value.WHT_CODE = null;
		line.value.COA_ID = await props.detailCat.account_id;
		let coa = getCOA.value.filter(
			(val) => val.CODE_COMBINATION_ID == props.detailCat.account_id
		);
		if (coa.length > 0) {
			line.value.ACCOUNT = await coa[0].ACCOUNT;
		} else {
			const coaData = await invoiceNonPOservice.getCoaById(
				await props.detailCat.account_id
			);
			line.value.ACCOUNT = await coaData.data.data[0];
		}

		tableList.value.push(line.value);
		emit('tableList', tableList);
		toast.add({
			severity: 'success',
			summary: 'Line Create',
			detail: 'Line Successfully Created',
			life: 3000,
		});
		line.value = {};
	} else {
		toast.add({
			severity: 'error',
			summary: 'Disabled',
			detail: "Can't add line",
			life: 3000,
		});
	}
};

const deleteLine = async (rowIndex, index) => {
	// tableList.value = tableList.value.filter(
	//   (val) => val.EINVOICE_LINE_ID !== rowIndex.EINVOICE_LINE_ID
	// );

	tableList.value = tableList.value.filter((val, idx) => idx !== index);

	emit('tableList', tableList);

	//   const payload = {
	//     idheader: parseInt(props.data),
	//     iddetail: rowIndex.EINVOICE_LINE_ID,
	//     value: false,
	//   };
	//   const data = await ListInvoiceNonPo.deleteLine(payload);
	//   if (data.data.data.data.length !== 0) {
	//     tableLineValue.value = rowIndex;
	//     deleteLineValue.value = true;
	//   } else {
	//     tableList.value = tableList.value.filter(
	//       (val) => val.EINVOICE_LINE_ID !== rowIndex.EINVOICE_LINE_ID
	//     );
	//     toast.add({
	//       severity: "error",
	//       summary: "Line Delete",
	//       detail: "Line Successfully Deleted",
	//       life: 3000,
	//     });
	//   }
};

const deleteTableLine = async () => {
	const payload = {
		idheader: parseInt(props.data),
		iddetail: tableLineValue.value.EINVOICE_LINE_ID,
		value: true,
	};
	const data = await ListInvoiceNonPo.deleteLine(payload);
	if (data.status === 200) {
		deleteLineValue.value = false;
		tableList.value = tableList.value.filter(
			(val) =>
				val.EINVOICE_LINE_ID !== tableLineValue.value.EINVOICE_LINE_ID
		);
		toast.add({
			severity: 'error',
			summary: 'Line Delete',
			detail: 'Line Successfully Deleted',
			life: 3000,
		});
	}
};

// const isForwarder = computed(
//   () =>
//     props.categoryHeader &&
//     props.categoryHeader.category_name.toLowerCase().includes("forwarder")
// );

const getDropdownVat = async () => {
	const paramRet = 'RATE';
	const payload = {
		paramRet: paramRet,
		dbInstance: props.company.db_instance,
		invoiceDate: props.invoiceDate,
	};
	const result = await invoiceNonPOservice.getListVat(payload);
	getVAT.value = result.data.data;
	getVAT.value.push({
		value: null,
		TAX_RATE_ID: null,
		VAT_CODE: '- No Select -',
		PERCENTAGE_RATE: null,
	});
};

const getDropdownWHT = async () => {
	const result = await invoiceNonPOservice.getListWht(
		props.company.value,
		props.company.db_instance
	);

	getWHT.value = result.data.data;
	getWHT.value.push({
		value: null,
		WHT_CODE_ID: null,
		WHT_CODE: '- No Select -',
	});
};

const getDropdownCOA = async () => {
	const result = await invoiceNonPOservice.getListCoa(
		props.org_id,
		props.db_instance,
		props.costCenter
	);
	getCOA.value = result.data.data;
};

const getListCategoryDetail = async () => {
	const result = await invoiceNonPOservice.getListCategoryDetail(
		props.company.value,
		props.company.db_instance,
		props.categoryHeader
	);
	listCategoryDetail.value = result.data.data;
};

const onChangeWHT = async (event, index) => {
	if (
		tableList.value[index].AMOUNT === undefined ||
		tableList.value[index].AMOUNT === null
	) {
		tableList.value[index].WHT_CODE = null;
		toast.add({
			severity: 'error',
			summary: 'Empty Amount',
			detail: 'Empty Amount',
			life: 3000,
		});
	} else {
		let whtData = null;
		for (const wht of getWHT.value) {
			if (wht.WHT_CODE_ID == event.value) {
				whtData = wht;
			}
		}
		if (whtData) {
			tableList.value[index].WHT_RATE = whtData.WHT_RATE;
			tableList.value[index].WHT_CODE = whtData.WHT_CODE;
			tableList.value[index].WHT_CODE_ID = whtData.WHT_CODE_ID;
			tableList.value[index].CHANGE = true;
			emit('tableList', tableList);
		} else {
			toast.add({
				severity: 'error',
				summary: 'Data not Found',
				detail: 'Data not Found',
				life: 3000,
			});
		}
	}
};

const searchAccount = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredCoa.value = [...getCOA.value];
    } else {
      filteredCoa.value = getCOA.value.filter((coa) => {
        // return coa.ACCOUNT.toLowerCase().match(event.query.toLowerCase());
        return coa.ACCOUNT.toLowerCase().includes(event.query.toLowerCase());
      });
    }
  }, 250);
};

const onChangeCOA = async (event, index) => {
	let coaData = null;
	for (const coa of getCOA.value) {
		if (coa.CODE_COMBINATION_ID == event.value.CODE_COMBINATION_ID) {
			coaData = coa;
		}
		// if (coa.CODE_COMBINATION_ID == event.value) {
		//   coaData = coa;
		// }
	}
	if (coaData) {
		tableList.value[index].ACCOUNT = coaData.ACCOUNT;
		tableList.value[index].CODE_COMBINATION_ID =
			coaData.CODE_COMBINATION_ID;
		tableList.value[index].COA_ID = coaData.CODE_COMBINATION_ID;
		tableList.value[index].CHANGE = true;
		emit('tableList', tableList);
	} else {
		toast.add({
			severity: 'error',
			summary: 'Data not Found',
			detail: 'Data not Found',
			life: 3000,
		});
	}
};

const onChangeVAT = async (event, index) => {
	if (
		tableList.value[index].AMOUNT === undefined ||
		tableList.value[index].AMOUNT === null
	) {
		tableList.value[index].VAT_CODE = null;
		toast.add({
			severity: 'error',
			summary: 'Empty Amount',
			detail: 'Empty Amount',
			life: 3000,
		});
	} else {
		let vatData = null;
		for (const vat of getVAT.value) {
			if (vat.TAX_RATE_ID == event.value) {
				vatData = vat;
			}
		}
		if (vatData) {
			tableList.value[index].PERCENTAGE_RATE = vatData.PERCENTAGE_RATE;
			tableList.value[index].VAT_CODE = vatData.VAT_CODE;
			tableList.value[index].VAT_CODE_ID = vatData.TAX_RATE_ID;
			tableList.value[index].CHANGE = true;
			emit('tableList', tableList);
		} else {
			toast.add({
				severity: 'error',
				summary: 'Data not Found',
				detail: 'Data not Found',
				life: 3000,
			});
		}
	}
};

const onChangeText = (event, index) => {
	tableList.value[index].AMOUNT = event.value;
	tableList.value[index].CHANGE = true;
	emit('tableList', tableList);
};

const openModal = (index) => {
	displayModal.value = true;
	travelOrderIndex.value = index;
};

const closeTOModal = () => {
	displayModal.value = false;
	travelOrderIndex.value = null;
};

const handleAddTO = (selectedTO) => {
	tableList.value[travelOrderIndex.value].travelOrder = {
		travelOrderNo: selectedTO.travel_order_no,
		guestName: selectedTO.guest_name,
		startDate: selectedTO.start_date,
		endDate: selectedTO.end_date,
	};
	tableList.value[travelOrderIndex.value].CHANGE = true;
	travelOrderIndex.value = null;
	displayModal.value = false;
};
</script>

<style scoped>
#locale-german {
	width: 100% !important;
}
</style>
