<!--<template>-->
    <template>
      <div class="Grid">

        <Toast />

        <div class="col-12">
          <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
        </div>

        <!--  Search  -->
        <div class="col-12">
          <div class="card">
            <div class="flex flex-row justify-content-between">
              <div class="col flex-column align-item-center log:col-auto">
                <span>
                    <CompaniesDropdown v-model="company" class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0" :disabled="!(userInfo?userInfo.leveltenant === 2:false)"/>
                    <SupplierDropdown v-if="!(userInfo?userInfo.leveltenant === 2:false)" v-model="supplier" :company="company?company:null"
                                    class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0" :key="supplierReload"/>
                    <ColumnsDropdown v-model="column" class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"/>
                    <SearchInput v-model="columnSearch" :inputType="column? column.type: ''" :key="searchReload"
                                 class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"/>
                </span>
                <span>
                    <Button label="Go" class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0" @click="goClick"/>
                    <Button label="Clear" class="p-button-info w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0" @click="clearClick"/>
                </span>
                <Button icon="pi pi-file" class="p-button-secondary" label="Export" @click="exportToExcel" :disabled='loading' style="float: right;"/>
              </div>
            </div>
          </div>

        </div>

        <div class="col-12 mb-4">
          <div class="card">
            <DetailReport :column="column?column.col:null" :columnSearch="columnSearch" :company="company?company:null"
                          :supplier="supplier?supplier:null" :key="reload"></DetailReport>
          </div>

        </div>
      </div>
    </template>


<script setup>
  import {ref, watch} from 'vue';
  import ColumnsDropdown from "@/components/trackingpo/components/ColumnsDropdown";
  import { useToast } from "primevue/usetoast";
  import DetailReport from '@/components/trackingpo/components/DetailReport';
  import SearchInput from "@/components/components/SearchInput";
  import XLSX from 'xlsx'
  import AuthService from '../../service/AuthService';
  import { getTrackingPOByExcell } from "@/service/TrackingPOReport";
  import CompaniesDropdown from "../components/CompaniesDropdown";
  import SupplierDropdown from "@/components/components/SupplierDropdown";
  import SimpleCrypto from "simple-crypto-js";
  import {filterAuthByVendorname} from "@/helper/filterAuthByVendorname";
  import LovService from "@/service/LovService";
  import { useRouter } from "vue-router";


  const breadcrumbHome = { icon: "pi pi-home", to: "/" };
  const breadcrumbItems = [
    {
      label: "Tracking PO Report",
      to: "/trackingporeport",
    },
  ];
  const company = ref();
  const column = ref();
  const columnSearch = ref();
  const toast = useToast();
  const reload = ref(0);
  const page = ref(1);
  const searchReload = ref(0);
  const supplier = ref(null);
  const supplierReload = ref(0);
  const userInfo = ref();
  const router = useRouter();

  watch(() => company.value, () => {
    supplier.value = null;
    supplierReload.value++;
  })

  AuthService.whoAmi().then(async (response) => {
    if (response.status === 200) {
      userInfo.value = response.data.data;

      if (userInfo.value.leveltenant === 2) {
        //get company
        const respCbN = await LovService.getCompanyByName(userCompany());
        company.value = respCbN.data.data[0];
        //get supplier
        const param = {
          db_instance: company.value.db_instance,
          org_id: company.value.value
        }
          
        const checkAuth = await filterAuthByVendorname({
          info_id: userInfo.value.id,
          dbInstance: param.db_instance,
          value: param.org_id
        });

        if(checkAuth.supplier === null) router.push("/dashboard");

        supplier.value = checkAuth.supplier;
      } else {
        // const response = await LovService.getcompanydb(userInfo.value.bioaddress);
        // const companies = response.data.data;
        // for (const c of companies) {
        //   if (parseInt(c.value) === userInfo.value.idsubtenant) {
        //     company.value = c;
        //     break;
        //   }
        // }
        const respCbN = await LovService.getCompanyByName(userCompany());
        company.value = respCbN.data.data[0];
      }
      // reload.value++;
    }
  })

// const exportTo = async () => {
//     let paramsPrintROO =
//         company.value.id
//       sessionStorage.setItem("paramsPrintROO", JSON.stringify(paramsPrintROO));
// }

 const exportToExcel = async () => {
     await AuthService.whoAmi().then((value) => {
       if (value.status === 200) {
         setTimeout(async () => {
          let columnValue = column.value? column.value.col : null;
          let columnSearchValue = columnSearch.value? columnSearch.value : undefined;
          let companyValue = company.value? company.value.value : null;
          let supplierValue = supplier.value? supplier.value.vendor_id : null;
           await getTrackingPOByExcell(
             columnValue,
             columnSearchValue,
             companyValue,
             supplierValue
           ).then((respond) => {
             if (respond.length !== 0) {
               for (var ast of respond) {
                 const arrival = ast.EST_GOOD_READINESS;
                 let atSite = new Date(arrival);
                 const needByDate = ast.ARRIVAL_AT_SITE;
                 let byDate = new Date(needByDate);
                 const manufacture = ast.EST_DELIVERY;
                 let manuf = new Date(manufacture);
                 const supp = ast.EST_ARRIVAL_AT_SITE;
                 let essSupp = new Date(supp);
                 const ship = ast.DELIVERY_DATE;
                 let shipDate = new Date(ship);
                 ast.EST_GOOD_READINESS = (
                   atSite.getDate() +
                   "-" +
                   atSite.toString().substring(4, 7) +
                   "-" +
                   atSite.getFullYear()
                 ).toUpperCase();
                 ast.ARRIVAL_AT_SITE = (
                   byDate.getDate() +
                   "-" +
                   byDate.toString().substring(4, 7) +
                   "-" +
                   byDate.getFullYear()
                 ).toUpperCase();
                 ast.EST_DELIVERY = (
                   manuf.getDate() +
                   "-" +
                   manuf.toString().substring(4, 7) +
                   "-" +
                   manuf.getFullYear()
                 ).toUpperCase();
                 ast.EST_ARRIVAL_AT_SITE = (
                   essSupp.getDate() +
                   "-" +
                   essSupp.toString().substring(4, 7) +
                   "-" +
                   essSupp.getFullYear()
                 ).toUpperCase();
                 ast.DELIVERY_DATE = (
                   shipDate.getDate() +
                   "-" +
                   shipDate.toString().substring(4, 7) +
                   "-" +
                   shipDate.getFullYear()
                 ).toUpperCase();
               }
               let today = new Date();
               let dd = String(today.getDate()).padStart(2, "0");
               let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
               let yyyy = today.getFullYear();
               let combinedDate = dd + mm + yyyy;
               let filename = "Invoice Report _" + combinedDate + ".xlsx";
               let data = respond;
               let ws = XLSX.utils.json_to_sheet(data);
               let wb = XLSX.utils.book_new();
               XLSX.utils.book_append_sheet(wb, ws, "Invoice Report");
               XLSX.writeFile(wb, filename);
             } else {
               toast.add({
                 severity: "error",
                 summary: "Data is Empty",
                 life: 3000,
               });
             }
           });
         }, 250);
       }
     });
   };


  const goClick = async () => {
    if ((column.value === undefined &&  (columnSearch.value === undefined || columnSearch.value === ''))) {
      reload.value++;
    } else if ((column.value === undefined &&  (columnSearch.value !== undefined || columnSearch.value !== '')) ||
        (column.value !== undefined &&  (columnSearch.value === undefined || columnSearch.value === ''))) {
      toast.add({severity:'info', summary: 'Column criteria cannot empty and value empty', life: 3000});
    } else {
      reload.value++;
    }
  }

  const clearClick = () => {
    if ((column.value !== undefined && column.value !== null)
        && (columnSearch.value !== undefined && columnSearch.value  !== null)) {
      page.value = 1;
    }
    column.value = null;
    columnSearch.value = null;
    if (userInfo.value.leveltenant === 2) {
      company.value = null;
    } else {
      supplier.value = null;
    }

    reload.value++;
  }

  const userCompany = () => {
    const secretKey = 'some-unique-key';
    const simpleCrypto = new SimpleCrypto(secretKey);
    return simpleCrypto.decrypt(sessionStorage.getItem('dropdownCompany'));
  }

</script>

<style scoped>

</style>