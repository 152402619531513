<template>
    
    <AutoComplete :suggestions="filteredUom" @complete="searchBatch($event)" :dropdown="true" field="TRANSFER_TO" v-model="uomValue" :disabled="props.disabled" @item-select="getData($event)" forceSelection>
      <template #item="slotProp">
      {{ slotProp.item.unit_of_measure }}
      </template>
    </AutoComplete>
       
    
</template>

<script setup>
import {onMounted, ref, watchEffect ,defineEmits} from 'vue'
import AuthService from '../../../service/AuthService';
import ItemJit from '../../../service/ItemJit';
import {useToast} from 'primevue/usetoast';

// eslint-disable-next-line no-undef
const props = defineProps({
  id: {type: Object},
  disabled: {type: Boolean},
  uomId: {type: Object},
  orgId: {type: Object}
})

// eslint-disable-next-line no-undef
const uom = ref();
const filteredUom = ref();
const uomValue = ref();
const uomValueSend = ref();
const toast = useToast();
const emit = defineEmits(['uom'])

onMounted(() => {
  populateData()
})

watchEffect(() => 
  emit('uom',uomValue)
)

const populateData = () => {
  AuthService.whoAmi().then(async (response) => {
    if (props.orgId !== null && response.status === 200){
      const payload = {orgId : props.orgId}
      const data = await ItemJit.getUoMDB(payload)
      if (data.status === 200){
        uom.value = data.data.data.data
        if (props.uomId !== null){
          for (const s of uom.value) {
            if (s.uom_code === props.uomId) {
              uomValue.value = s.unit_of_measure;
              uomValueSend.value = s;
              emit('uom',uomValueSend)
              break;
            }
            else{
              uomValue.value = null;
            }
          }
        }
      } else {
        toast.add({
            severity: 'error',
            summary: 'UoM Not Found',
            life: 3000,
        });
      }
      
    }
    
    
    
  })
}

const getData = (event) => {
  uomValue.value = event.value.unit_of_measure
  uomValueSend.value = event
  emit('uom',uomValueSend)
}

const searchBatch = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredUom.value = [...uom.value]
    } else {
      filteredUom.value = uom.value.filter((comp) => {
        return comp.unit_of_measure.toLowerCase().match(event.query.toLowerCase());
      })
    }
  }, 250);
}
</script>

<style scoped>

</style>