import paymentApi from './api/SpbReportApi';

class Requisition {
	async getRequisitionList(params, payload) {
		const queryParams = params
			? Object.keys(params)
					.map(
						(k) =>
							encodeURIComponent(k) +
							'=' +
							encodeURIComponent(params[k])
					)
					.join('&')
			: '';

		return paymentApi.post(
			`/spb/requisition/requisitionlist?${queryParams}`,
			payload
		);
	}

	async exportRequisitionList(payload) {
		return paymentApi.post(`/spb/requisition/export`, payload);
	}
}

export default new Requisition();
