import spborder from "./api/SpbOrderApi";
import spbadmin from "./api/SpbAdminApi";
import { encryptForm } from "../helper/encryptForm";
class PlanningOrder {
  getTableSuppData;
  getTableData() {
    return spborder.get("/spb/spb_pofulfillment/tableopenreq");
  }
  getTableDataSupp(payload) {
    return spborder.post("/spb/spb_pofulfillment/tableopenreqsup", payload);
  }
  // GetTableNested(params) {
  // 	const queryParams = params
  // 		? Object.keys(params)
  // 				.map(
  // 					(k) =>
  // 						encodeURIComponent(k) +
  // 						'=' +
  // 						encodeURIComponent(params[k])
  // 				)
  // 				.join('&')
  // 		: '';
  // 	return spborder.post(
  // 		'/spb/spb_pofulfillment/getnestedtable?' + queryParams
  // 	);
  // }
  getSearchTableNested(params) {
   
    return spborder.post(
      "/spb/spb_pofulfillment/searchnestedtable", encryptForm(params)
    );
  }
  GetPreparer(payload) {
    return spborder.post("/spb/spb_pofulfillment/getpreparer", payload);
  }
  GetSupplier() {
    return spborder.post("/spb/spb_pofulfillment/getsupplier");
  }
  getCompany() {
    return spborder.post("/spb/spb_pofulfillment/getcompany");
  }
  getCompanyId(id) {
    return spborder.post("/spb/spb_pofulfillment/getcompanysupp", id);
  }
  getSearchDropdownCompany(payload) {
    return spborder.post("/spb/spb_pofulfillment/getsupplier", payload);
  }
  getSearchDropdownSupp(payload) {
    return spborder.post("/spb/spb_pofulfillment/getsupsearch", payload);
  }
  getSearchDropdownComp(payload) {
    return spborder.post("/spb/spb_pofulfillment/getsupcomp", payload);
  }
  getPoNumber() {
    return spborder.post("/spb/spb_pofulfillment/getponumber");
  }
  getSearchDropdownPoNum(payload) {
    // 
    return spborder.post("/spb/spb_pofulfillment/getposearch", payload);
  }
  //dipake
  GetLovStatus() {
    return spborder.post("/spb/spb_pofulfillment/getstatuspofulfillment");
  }

  GetBASTStatus() {
    return spborder.post("/spb/spb_pofulfillment/getstatusbast");
  }

  GetLovStatusLoe() {
    return spborder.post("/spb/spb_pofulfillment/getstatuspofulfillmentloe");
  }
  //dipake
  getTableSearch(data) {
    return spborder.post(`/spb/spb_pofulfillment/tableopenreq/` + data);
  }
  getGroup() {
    return spborder.post(`/spb/spb_pofulfillment/getrowgroup`);
  }
  getCompanySupp(payload) {
    return spbadmin.get("/spb/usermapping/getusermappingsbyiduser/" + payload);
  }

  ////// INI EDIT

  getIdDetail(payload) {
    // 
    return spborder.post(`/spb/spb_pofulfillment/getiddetail/` + payload);
  }
  getLocation(payload) {
    return spborder.post(`/spb/spb_pofulfillment/getlocation/` + payload);
  }
  getTableDetailPo(payload) {
    // 
    return spborder.post("/spb/spb_pofulfillment/gettabledetailpo/" + payload);
  }

  // LOE
  getLoe(payload) {
    return spborder.post("/spb/spb_pofulfillment/getLoe", payload);
  }

  // Ini service detail yang baru
  getDetailHeader(payload) {
    return spborder.post(`/spb/spb_pofulfillment/getdetailheader`, encryptForm(payload));
  }
  getDetailItems(payload) {
    return spborder.post(`/spb/spb_pofulfillment/getdetailitems/` + payload);
  }
  getDetailTerms(payload) {
    return spborder.post(`/spb/spb_pofulfillment/getdetailterms/` + payload);
  }
  getDetailFiles(payload) {
    return spborder.post(`/spb/spb_pofulfillment/getdetailfiles/` + payload);
  }

  getPoType() {
    return spborder.post(`/spb/spb_pofulfillment/getpotype`);
  }
  getTerms(payload) {
    return spborder.post(
      `/spb/spb_pofulfillment/gettabledetailterms/` + payload
    );
  }
  getTermsSupp(payload) {
    return spborder.post(
      `/spb/spb_pofulfillment/gettabledetailtermssupp/` + payload
    );
  }
  getUom(payload) {
    return spborder.post(`/spb/spb_pofulfillment/getuom`, payload);
  }
  getAllCurrencies(payload) {
    const url = "spb/createpo/getallcurrencies/" + payload;
    return spborder.get(url);
  }
  getFreight(payload) {
    return spborder.post(`/spb/spb_pofulfillment/getfreight`, payload);
  }
  getTablePoNumber() {
    return spborder.post(`/spb/spb_pofulfillment/gettableponumber`);
  }
  getTop(payload) {
    return spborder.post(`/spb/spb_pofulfillment/gettop`, payload);
  }
  getIncoterm(payload) {
    return spborder.post(`/spb/spb_pofulfillment/getincoterm`, payload);
  }
  changeStatus(payload) {
    const url = "/spb/spb_pofulfillment/changestatus";
    return spborder.post(url, payload);
  }
  notAcceptStatus(payload) {
    // 
    return spborder.post("/spb/spb_pofulfillment/notacceptstatus", payload);
  }
  saveOption(payload) {
    return spborder.post("/spb/spb_pofulfillment/savesubmitpo", payload);
  }
  saveOptionSupp(payload) {
    return spborder.post("/spb/spb_pofulfillment/savesubmitposupp", payload);
  }
  getSuppBindComp(idUser) {
    return spborder.post("spb/spb_pofulfillment/getsuppbindcomp/" + idUser);
  }
  getSuppBindCompProc(idUser) {
    return spbadmin.post("spb/proc/getsuppbindcomp/" + idUser);
  }
  submitPo(payload) {
    return spborder.post("/spb/spb_pofulfillment/submitpo", payload);
  }
  confirmSupp(payload) {
    return spborder.post("/spb/spb_pofulfillment/confirmSupp", payload);
  }
  submitApi(payload) {
    return spborder.post("/spb/spb_pofulfillment/submitapi", payload);
  }
  uploadFile(payload) {
    return spborder.post("/spb/spb_pofulfillment/uploadfile", payload);
  }
  uploadFilePur(payload) {
    return spborder.post("/spb/spb_pofulfillment/uploadfileinsertpur", payload);
  }
  uploadFileSupp(payload) {
    return spborder.post(
      "/spb/spb_pofulfillment/uploadfileinsertsupp",
      payload
    );
  }
  uploadFileUpdatePur(payload) {
    return spborder.post("/spb/spb_pofulfillment/uploadfileupdatepur", payload);
  }
  uploadFileUpdateSupp(payload) {
    return spborder.post(
      "/spb/spb_pofulfillment/uploadfileupdatesupp",
      payload
    );
  }
  downloadFilePur(payload) {
    return spborder.post("/spb/spb_pofulfillment/downloadfilepur", payload);
  }
  downloadFilePurWithTenant(payload) {
    const { poHeaderId, revisionNum, leveltenant, vendorId } = payload
    return spborder.get(
      `/spb/spb_pofulfillment/downloadfilePurWithTenant/${revisionNum}/${poHeaderId}/${leveltenant}/${vendorId}`
    );
  }
  downloadFileSuppWithTenant(payload) {
    const { poHeaderId, revisionNum, leveltenant, vendorId } = payload
    return spborder.get(
      `/spb/spb_pofulfillment/downloadfileSuppWithTenant/${revisionNum}/${poHeaderId}/${leveltenant}/${vendorId}`,
    );
  }
  async downloadFileSupp(payload) {
    return spborder.post("/spb/spb_pofulfillment/downloadfilesupp", payload);
  }
  removeFilePur(payload) {
    return spborder.post("/spb/spb_pofulfillment/removefilepur", payload);
  }
  removeFileSupp(payload) {
    return spborder.post("/spb/spb_pofulfillment/removefilesupp", payload);
  }
  savePo(payload) {
    return spborder.post("/spb/spb_pofulfillment/update", payload);
  }
  async getSuppValueBU(payload) {
    return spborder.post("/spb/spb_planningorder/getvaluesupp", payload);
  }
  async getSuppValue(payload) {
    return spbadmin.post("/spb/proc/getvaluesupp", payload);
  }
  // getTableGroup(params) {
  //   let queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
  //   return spborder.get("/spb/spb_pofulfillment/getgrup?" + queryParams);
  // }
  // GetTableSearch(data) {
  //   return spborder.get("/spb/spb_pofulfillment/get/"+ data)
  // }
  // get(id) {
  //   return spborder.get(`/tutorials/${id}`);
  // }
  // signViaAdminSc(data) {
  //   return spborder.post("adm/auth/signviaadminsc", data);
  // }
  // update(id, data) {
  //   return spborder.put(`/tutorials/${id}`, data);
  // }
  // delete(id) {
  //   return spborder.delete(`/tutorials/${id}`);
  // }
  // deleteAll() {
  //   return spborder.delete(`/tutorials`);
  // }
  // findByTitle(title) {
  //   return spborder.get(`/tutorials?title=${title}`);
  // }
}
export default new PlanningOrder();
