<template>
      <AutoComplete :suggestions="filteredCurrency" @complete="searcCurrency($event)" :dropdown="true" field="currency_code" v-model="currency" forceSelection>
        <template #item="slotProp">
        {{ slotProp.item.currency_code }}
        </template>
    </AutoComplete>
</template>

<script setup>
import {onMounted, ref, watchEffect, defineEmits, watch} from 'vue'
import AuthService from '../../../service/AuthService';
import SearchInvoiceProxyNonPO from "../../../service/InvoiceProxyService";

// eslint-disable-next-line no-undef
const props = defineProps({
  company: {
    type: Object,
  },
  db_instance:{
    type: Object,
  },
  currencyValue: {
    type: String,
  },
  org_id : {
    type: Object,
  }
});

// eslint-disable-next-line no-undef
const filteredCurrency = ref([]);
const currencyList = ref([]);
const currency = ref();
const emit = defineEmits(["currency"]);

onMounted(() => {
  populateData()
})

watch(() => props.currencyValue, (newValue) => {
  // 
  if (newValue !== null ){
    // if (!forwarder.value){
      populateData()
    // }
  }
});

watchEffect(() => 
  // 
  emit("currency", currency)
)

const populateData = () => {
  AuthService.whoAmi().then(async (response) => {
    // 
      if (response.status === 200){
      if (props.currencyValue !== null) {
        // 
        const payload = {db_instance : props.db_instance}
        // 
        const data = await SearchInvoiceProxyNonPO.getListCurrency(payload);
        // 
        if (data.status === 200){
          currencyList.value = data.data.data.data;
          if (currencyList.value) {
            for (const currencyData of currencyList.value) {
              if (currencyData.currency_code === props.currencyValue) {
                currency.value = currencyData;
                // 
              }
            }
          } 
        } else {
          currencyList.value = null;
        }
      }
    }
   
    
  })
}


const searcCurrency = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredCurrency.value = [...currencyList.value];
    } else {
      filteredCurrency.value = currencyList.value.filter((currency) => {
        return currency.currency_code.toLowerCase().match(
          event.query.toLowerCase()
        );
      });
    }
  }, 250);
};
</script>

<style scoped>

</style>