import spbAdmin from "./api/SpbAdminApi";
class LovService {
  
    async getAllData() {
        return spbAdmin.get('spb/lovroute/getalldata');
    }
    
    async insertValue(payload) {
        return spbAdmin.post('spb/lovroute/insertvalue/', payload);
    }
    
    async getDetailValue(name) {
        return spbAdmin.get('spb/lovroute/getdetailvalue/' + name);
    }
    
    async updateValue(payload) {
        return spbAdmin.post('spb/lovroute/updatevalue/', payload);
      }

    async getCompanyDropdown() {
        return spbAdmin.get('spb/notification/getCompanyDropdown');
    }
  
    async getValueType(type){
        return spbAdmin.get('spb/lovroute/getvalue/' + type);
    }

    async getcompanydb(type){
      return spbAdmin.get('spb/lovroute/getcompanydb/' + type);
    }

    async getcompanydbinternal(){
      return spbAdmin.get('spb/lovroute/getcompanydbinternal/');
    }
        
    async getManufacturerId(id){
      const url = 'spb/lovroute/getmanufacturerid/'+id;
      return spbAdmin.get(url);
    }
  
    async getManufacturerVendor(vendor_id){
      const url = 'spb/lovroute/getmanufacturervendor/'+vendor_id;
      return spbAdmin.get(url);
    }

    async getManufacturer(){
      const url = 'spb/lovroute/getmanufacturer';
      return spbAdmin.get(url);
    }
    
    async getUom(db_instance) {
      const url = 'spb/lovroute/getuom/'+db_instance;
      return spbAdmin.get(url);
    }

    async getTop(db_instance) {
      const url = 'spb/lovroute/gettop/'+db_instance;
      return spbAdmin.get(url);
    }
  
    async getFob(db_instance) {
      const url = 'spb/lovroute/getfob/'+db_instance;
      return spbAdmin.get(url);
    }

    async getFreight(db_instance) {
      const url = 'spb/lovroute/getfreight/'+db_instance;
      return spbAdmin.get(url);
    }

    async getCurrency(db_instance) {
      const url = 'spb/lovroute/getcurrency/'+db_instance;
      return spbAdmin.get(url);
    }
  
    async getValue(type) {
      const url = 'spb/lovroute/getValue/' + type;
      return spbAdmin.get(url);
    }
  
    async getDbName(name) {
      const url = 'spb/lovroute/getdbname/' + name;
      return spbAdmin.get(url);
    }
  
    async getSupplierName(payload) {
      const url = 'spb/lovroute/getsuppname/';
      return spbAdmin.post(url, payload);
    }
    
    async getSupplierId(name) {
      const url = 'spb/lovroute/getsuppid/' + name;
      return spbAdmin.post(url);
    }
    async getCompanyFromUsermapping(id) {
      const url = 'spb/lovroute/getcompanyfromusermapping/' + id;
      return spbAdmin.get(url);
    }
    async deleteData(name) {
      const url = `spb/lovroute/deleteData/`+ name;
      return spbAdmin.get(url);
    }
    async getCompanyByName(name) {
      const url = `spb/lovroute/getcompanybyname/`+ name;
      return spbAdmin.get(url);
    }
    async getType(){
      const url = `spb/lovroute/getType`;
      return spbAdmin.get(url);
    }
    async getManufacturerAsl(asl_id){
      const url = 'spb/lovroute/getmanufacturerasl/'+asl_id;
      return spbAdmin.get(url);
    }
    getSuppBindComp(idUser) {
      return spbAdmin.post('spb/lovroute/getsuppbindcomp/' + idUser +'/'+ sessionStorage.getItem('companydb'));
    }
    async findAAMCompany(){
      const url = 'spb/aamcomp/getaamcompany';
      return spbAdmin.get(url);
    }

}
export default new LovService();