<template>
	<DataTable
		:value="communicationInformation"
		:lazy="true"
		:rows="5"
		:loading="loading"
		:totalRecords="totalData"
		:paginator="true"
		@page="onPage($event)"
		@sort="onSort($event)"
		:rowsPerPageOptions="[5, 10, 15]"
		responsiveLayout="scroll"
		:scrollable="true"
		scrollHeight="600px"
		:sortable="true"
		:rowId="null"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #empty>Data Not found</template>
		<template #loading>Loading data, please wait...</template>
		<Column header="Phone Area" field="AREA_CODE" style="width: 9%">
		</Column>
		<Column header="Phone" field="PHONE" style="width: 9%"> </Column>
		<Column header="Fax Area" field="FAX_AREA_CODE" style="width: 9%">
		</Column>
		<Column header="Fax" field="FAX" style="width: 9%"> </Column>
		<Column header="Telex" field="TELEX" style="width: 9%"> </Column>
		<Column header="Email Address" field="EMAIL_ADDRESS" style="width: 9%">
		</Column>
	</DataTable>
</template>

<script setup>
import { onMounted, ref } from 'vue';
// import VendorServices from "../../../service/masterVendorService";
import AuthService from '@/service/AuthService';
import PoFulfillment from '@/service/PoFulfillment';
import LovService from '@/service/LovService';
import SupplierMaster from '../../../service/SupplierMasterServices';

const company = ref();
const supplier = ref();

onMounted(async () => {
	company.value = props.company;
	const info = await AuthService.whoAmi();
	if (info.status === 200) {
		levelTenant.value = info.data.data.leveltenant;
		if (levelTenant.value !== 2) {
			loading.value = true;
			supplier.value = props.supplier;
			// loading.value = true
			await populateTable();
		} else {
			if (company.value && !supplier.value) {
				const param = {
					db_instance: props.company.db_instance,
					org_id: props.company.value,
				};
				const respVendor = await PoFulfillment.getSuppBindComp(
					info.data.data.id
				);
				const vendorName = respVendor.data.data[0][0].vendorname;
				const respSuppName = await LovService.getSupplierName(param);
				if (respSuppName.status === 200) {
					const suppList = respSuppName.data.data;
					for (const s of suppList) {
						if (s.vendor_name === vendorName) {
							supplier.value = s;
							break;
						}
					}
				}
				await populateTable();
			}
			// loading.value = true
		}
	}
});
// eslint-disable-next-line no-undef
const props = defineProps({
	company: {
		type: Object,
	},
	supplier: {
		type: Object,
	},
});

// const masterVendor = ref();
// const totalData = ref(0);

const loading = ref(false);
const page = ref(1);
const rowsPerPage = ref(10);
const sortColumn = ref();
const sortType = ref();
const levelTenant = ref();
const tableData = ref([]);
const communicationInformation = ref();
const totalData = ref(0);

const populateTable = async () => {
	loading.value = false;

	if (props.company !== null && props.company !== undefined) {
		const payload = {
			suppValue: supplier.value ? supplier.value.vendor_id : null,
			dbInstance: company.value ? company.value.db_instance : null,
			page: page.value,
			rows: rowsPerPage.value,
		};

		tableData.value = await SupplierMaster.getDataAllDataInformation(
			payload
		);

		communicationInformation.value = tableData.value.data.data.data;

		totalData.value = tableData.value.data.data.totalData[0].totalData;
		//
		//
	}

	loading.value = false;
};

const onPage = async (event) => {
	// loading.value = true
	page.value = event.page + 1;
	rowsPerPage.value = event.rows;
	await populateTable();
};

const onSort = async (event) => {
	// loading.value = true
	page.value = 1;
	rowsPerPage.value = event.rows;
	sortColumn.value = event.sortField;
	sortType.value = event.sortOrder;
	await populateTable();
};
</script>
<style scoped></style>
