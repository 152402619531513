<template>
  <!-- eslint-disable -->
  <Toast />

  <ProgressModal :progress="progress">
    <template #message> Update in progress. Please wait... </template>
  </ProgressModal>

  <div class="Grid">
    <div class="col-12 mb-4">
      <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
    </div>

    <div class="col-12 mb-4">
      <div class="content-wrapper">
        <div class="content-header">Legal Information</div>
        <div class="content-body">
          <div class="col-12 p-fluid">
            <div class="grid formgrid" style="margin: 1em 0 0 0">
              <div class="col-12 md:col-4 p-fluid">
                <span class="p-float-label">
                  <InputText
                    v-model="documentName"
                    class="w-full"
                    style="color: #000"
                  />

                  <small v-if="v$.documentName.$error" class="p-error">{{
                    v$.documentName.$errors[0].$message.replace(
                      "Value",
                      "Document Name"
                    )
                  }}</small>
                  <label for="inputtext">Document Name</label>
                </span>
              </div>
              <div class="col-12 md:col-4 p-fluid mb-3">
                <span class="p-float-label">
                  <InputText
                    v-model="documentNo"
                    class="w-full"
                    style="color: #000"
                  />
                  <small v-if="v$.documentNo.$error" class="p-error">{{
                    v$.documentNo.$errors[0].$message.replace(
                      "Value",
                      "Document No"
                    )
                  }}</small>
                  <label>Document No</label>
                </span>
              </div>
              <div class="col-12 md:col-4 p-fluid mb-6">
                <div v-if="soFileName" class="flex">
                  <Button
                    :label="soFileName"
                    icon="pi pi-download"
                    class="button-download font-normal"
                    @click="onDownload()"
                  />
                  <Button
                    type="button"
                    class="p-button-rounded p-button-text ml-2"
                    icon="pi pi-trash"
                    @click="handleDeleteFile"
                  />
                </div>
                <div v-else>
                  <FileUpload
                    ref="fileUpload"
                    mode="advanced"
                    class="w-full"
                    chooseLabel="Upload File"
                    fileLimit="1"
                    accept="application/pdf"
                    :maxFileSize="5000000"
                    :showUploadButton="false"
                    :showCancelButton="false"
                    :customUpload="true"
                    @select="onSelectFile"
                    @remove="onRemoveFile"
                  />
                </div>
              </div>
              <div class="col-12 md:col-4 p-fluid mb-8">
                <span class="p-float-label">
                  <Calendar
                    class="w-full lg:mb-0"
                    :showIcon="true"
                    :showButtonBar="true"
                    dateFormat="d M yy"
                    v-model="validFromDate"
                  ></Calendar>
                  <label for="inputtext">Valid From Date </label>
                  <small v-if="v$.validFromDate.$error" class="p-error">{{
                    v$.validFromDate.$errors[0].$message.replace(
                      "Value",
                      "Valid From Date"
                    )
                  }}</small>
                </span>
              </div>
              <div class="col-12 md:col-4 p-fluid mb-8">
                <span class="p-float-label">
                  <Calendar
                    class="w-full lg:mb-0"
                    :showIcon="true"
                    :showButtonBar="true"
                    dateFormat="d M yy"
                    v-model="validToDate"
                  ></Calendar>
                  <label for="inputtext">Valid To Date </label>
                </span>
              </div>

              <div class="col-12 md:col-2 mb-2 lg:col-2 lg:mb-0">
                <Button
                  class="p-button-secondary uppercase w-full"
                  label="Back"
                  @click="back()"
                >
                </Button>
              </div>
              <div v-if="legal_id == 0" class="col-12 md:col-2 mb-2 lg:col-2">
                <Button
                  :disabled="status === 'berhasil'"
                  type="submit"
                  class="p-button-success uppercase w-full"
                  label="Create"
                  @click="create()"
                />
              </div>
              <div v-if="legal_id != 0" class="col-12 md:col-2 mb-2 lg:col-2">
                <Button
                  type="submit"
                  class="p-button-success uppercase w-full"
                  label="Save"
                  @click="update()"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, computed } from "vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import ProgressModal from "../../components/ProgressModal.vue";
import { useRoute, useRouter } from "vue-router";
import AuthService from "../../../service/AuthService";
import LovService from "../../../service/LovService";
// import PoFulfillment from "../../../service/PoFulfillment";
import SimpleCrypto from "simple-crypto-js";
import SupplierMaster from "../../../service/SupplierMasterServices";

const breadcrumbHome = { icon: "pi pi-home", to: "/" };
const breadcrumbItems = [
  { label: "Supplier Master", to: "/mgm/master/suppliermaster" },
  { label: "Detail Supplier Master" },
];
const company = ref();
const supplier = ref();
const userInfo = ref();
const idUser = ref();
const vendorId = ref();
const levelTenant = ref();
const isSupplierpage = ref(true);
const route = useRoute();
const legal_id = ref(route.params.id);
const documentName = ref();
const documentNo = ref();
const validFromDate = ref();
const validToDate = ref();
const toast = useToast();
const router = useRouter();
const file = ref(null);
const fileUpload = ref(null);
const soFileBuffer = ref();
const soFileName = ref();
const deletedFile = ref();
const legalFile = ref();
const status = ref();
const progress = ref(false);
const itemExisting = ref();

const validationRules = computed(() => {
  const rules = {
    documentName: { required },
    documentNo: { required },
    validFromDate: { required },
  };
  return rules;
});

const v$ = useVuelidate(validationRules, {
  documentName,
  documentNo,
  validFromDate,
});

onMounted(() => {
  AuthService.whoAmi().then(async (response) => {
    if (response.status === 200) {
      userInfo.value = response.data.data;
      idUser.value = response.data.data.id;

      if (userInfo.value.leveltenant === 2) {
        //get company
        isSupplierpage.value = true;
        const respCbN = await LovService.getCompanyByName(userCompany());
        company.value = respCbN.data.data[0];
        //get supplier
        
        const param = {
          db_instance: company.value.db_instance,
          org_id: company.value.value,
        };

        const respVendor = await LovService.getSuppBindComp(
          response.data.data.id
        );
        
        // const vendorName = respVendor.data.data[0][0].vendorname;
        const vendorId = respVendor.data.data[0].vendor_id;
        console.log('cek vendorId',vendorId)

        const respSuppName = await LovService.getSupplierName(param);
        if (respSuppName.status === 200) {
          
          const suppList = respSuppName.data.data;
          for (const s of suppList) {
            if (s.vendor_id === vendorId) {
              supplier.value = s;
              break;
            }
          }
          // 
        }
      } else {
        // await getData();
        const respCbN = await LovService.getCompanyByName(company.value);
        company.value = respCbN.data.data[0];
      }
    }

    userInfo.value = response.data.data;
    levelTenant.value = userInfo.value.leveltenant;

    if (response.status === 200) {
      const paylaod = { legal_id: legal_id.value };
      const result = await SupplierMaster.getDetailLegalInformation(paylaod);
      

      documentName.value = result.data.data.data[0].LEGAL_NAME;
      documentNo.value = result.data.data.data[0].LEGAL_NO;
      validFromDate.value = result.data.data.data[0].EFF_START_DATE;
      validToDate.value = result.data.data.data[0].EFF_END_DATE;
      vendorId.value = result.data.data.data[0].VENDOR_ID;
      soFileName.value = result.data.data.data[0].FILENAME;
      legalFile.value = result.data.data.data[0].LEGAL_FILE;
      itemExisting.value = result.data.data.data[0].LEGAL_NO;

      // await getFileAttachment()
    }
  });
});

// Select file attachment
const onSelectFile = (e) => {
  file.value = e.files[0];
  
};

// Remove file attachment
const onRemoveFile = (e) => {
  if (!e.files.length) {
    file.value = null;
  }
};

const create = async () => {
  const isFormValid = await v$.value.$validate();

  if (!isFormValid) {
    toast.add({
      severity: "error",
      summary: "Please fill all required fields",
      life: 3000,
    });
    return;
  }

  if (!file.value) {
    // 
    toast.add({
      severity: "error",
      summary: "Please select file to upload",
      life: 3000,
    });
    return;
  }
  const routeVendor = route.query;
  

  // Add file payload
  const formData = new FormData();
  formData.append("file", file.value);

  let payload;
  if (levelTenant.value === 2) {
    payload = {
      LEGAL_NAME: documentName.value,
      LEGAL_NO: documentNo.value,
      EFF_START_DATE: formattingDate(validFromDate.value),
      EFF_END_DATE: formattingDate(validToDate.value),
      VENDOR_ID: parseInt(routeVendor.i),
      CREATION_DATE: formattingDateDexa(new Date(), true),
      CREATED_BY: idUser.value,
      VENDOR_NAME: supplier.value.vendor_name,
      COMPANY_NAME: company.value.name,
      LEVELTENANT: levelTenant.value,
      DB_INSTANCE: company.value.db_instance,
    };
  } else {
    payload = {
      LEGAL_NAME: documentName.value,
      LEGAL_NO: documentNo.value,
      EFF_START_DATE: formattingDate(validFromDate.value),
      EFF_END_DATE: formattingDate(validToDate.value),
      VENDOR_ID: parseInt(routeVendor.i),
      CREATION_DATE: formattingDateDexa(new Date(), true),
      CREATED_BY: idUser.value,
      LEVELTENANT: levelTenant.value,
      DB_INSTANCE: company.value ? company.value.db_instance : null,
    };
  }

  

  // Wrap payload with formData
  const jsonPayload = JSON.stringify(payload);
  formData.append("payload", jsonPayload);

  try {
    const create = await SupplierMaster.createLegalInformation(formData);
    if (create.status === 200) {
      (status.value = "berhasil"), 
      toast.add({
        severity: "success",
        summary: `Successfully Create Legal Document`,
        life: 3000,
      });
    }
    if (create.status === 204) {
      toast.add({
        severity: "error",
        summary: `Unable to Send Email`,
        life: 3000,
      });
    }
  } catch (error) {
    // 
    if (error.message.includes("Network Error")) {
      
      toast.add({
        severity: "error",
        summary: "Network Error",
        detail: "Connection Down. Please check your legal doc  on list .",
      });
      return;
    }
    if (error.response.status === 403) {
      toast.add({
        severity: "error",
        summary: `Legal Document already exist`,
        life: 5000,
      });
    } 
    else {
      toast.add({
        severity: "error",
        summary: "Please remove password from the file",
        life: 3000,
      });
    }
  }
};

const update = async () => {
  const isFormValid = await v$.value.$validate();
  

  if (!isFormValid) {
    toast.add({
      severity: "error",
      summary: "Please fill all required fields",
      life: 3000,
    });
    return;
  }
  if (!file.value && deletedFile.value) {
    // 
    toast.add({
      severity: "error",
      summary: "Please select file to upload",
      life: 3000,
    });
    return;
  }

  progress.value = true;

  const formData = new FormData();
  formData.append("file", file.value);

  const payload = {
    documentName: documentName.value,
    documentNo: documentNo.value,
    validFromDate: formattingDate(validFromDate.value),
    validToDate: formattingDate(validToDate.value),
    filename: soFileName.value,
    vendorId: vendorId.value,
    legal_id: parseInt(legal_id.value),
    legalFile: legalFile.value,
    createdBy: idUser.value,
    last_update_date: formattingDateDexa(new Date(), true),
    existing: itemExisting.value,
  };
  

  // Wrap payload with formData
  const jsonPayload = JSON.stringify(payload);
  formData.append("payload", jsonPayload);

  try {
    const update = await SupplierMaster.updateLegalInformation(formData);
    

    if (update.status === 200) {
      
      
      if(file.value) {
         soFileName.value = file.value.name ;
      }
      itemExisting.value = documentNo.value
      progress.value = false;
      toast.add({
        severity: "success",
        summary: "Update data success",
        life: 5000,
      });
    }
  } catch (error) {
    progress.value = false;
    if (error.response.status === 400) {
      toast.add({
        severity: "error",
        summary: `Please remove password from the file`,
        life: 3000,
      });
    }
    if (error.response.status === 403) {
      toast.add({
        severity: "error",
        summary: `Legal Document already exist`,
        life: 3000,
      });
    } else {
      toast.add({
        severity: "error",
        summary: `Update data Error`,
        life: 3000,
      });
    }
  }
};

const handleDeleteFile = () => {
  deletedFile.value = soFileBuffer.value;
  soFileName.value = null;
};

const onDownload = async () => {
  try {
    const res = await SupplierMaster.getAttachmentFile(
      legal_id.value,
      levelTenant.value
    );
    
    if (res.status === 200) {
      soFileBuffer.value = res.data.data.data.fileBuffer;
    }
  } catch (error) {
    throw new Error(error);
  }
  const linkSource = `data:application/pdf;base64,${soFileBuffer.value}`;
  const downloadLink = document.createElement("a");
  const fileName = soFileName.value;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

const back = async () => {
  router.push({
    name: "SupplierMaster",
  });
};
const formattingDate = (d) => {
  var date = new Date(d);
  var respondDate =
    date.getFullYear() +
    "-" +
    ("00" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("00" + date.getDate()).slice(-2);
  return respondDate;
};

// function dateFormat(d) {
//   var monthShortNames = [
//     "Jan",
//     "Feb",
//     "Mar",
//     "Apr",
//     "May",
//     "Jun",
//     "Jul",
//     "Aug",
//     "Sep",
//     "Oct",
//     "Nov",
//     "Dec",
//   ];
//   var t = new Date(d);
//   return (
//     t.getDate() +
//     " " +
//     monthShortNames[t.getMonth()] +
//     " " +
//     t.getFullYear() +
//     " "
//   );
// }
//format dete dexa
const formattingDateDexa = (date, withTime) => {
  const theDate = new Date(date);
  const strDate =
    theDate.getFullYear() +
    "-" +
    (theDate.getMonth() + 1) +
    "-" +
    theDate.getDate();
  const strTime =
    theDate.getHours() +
    ":" +
    theDate.getMinutes() +
    ":" +
    theDate.getSeconds();
  if (withTime) {
    return `${strDate} ${strTime}`;
  } else {
    return `${strDate}`;
  }
};
const userCompany = () => {
  const secretKey = "some-unique-key";
  const simpleCrypto = new SimpleCrypto(secretKey);
  return simpleCrypto.decrypt(sessionStorage.getItem("dropdownCompany"));
};
</script>

<style scoped lang="scss">
.form-card {
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
}
</style>
