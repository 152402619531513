<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <div>
        <span style="font-size: 12px;">Welcome</span>,<br /><span
          style="font-size: 13px; font-weight: 700;"
          >{{ username }}</span
        >
      </div>
      <br />
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
      <AppFooter />
    </div>

    <!--AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" @change-theme="changeTheme" /-->
    <transition name="layout-mask">
      <div
        class="layout-mask p-component-overlay"
        v-if="mobileMenuActive"
      ></div>
    </transition>
  </div>

  <Dialog
    header="Confirmation"
    v-model:visible="displayConfirmation"
    :style="{ width: '350px' }"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem;" />
      <span>Session Expired</span>
    </div>
    <template #footer>
      <Button
        label="Close"
        icon="pi pi-times"
        @click="closeConfirmation"
        class="p-button-text"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
/* eslint-disable */
import AppTopBar from "./AppTopbar.vue";
import AppMenu from "./AppMenu.vue";
// import AppConfig from './AppConfig.vue';
import AppFooter from "./AppFooter.vue";
import AuthService from "./service/AuthService";
// import DefaultMenu from './app/_files/appadmin.json';
// import axios from 'axios';

export default {
  emits: ["change-theme"],
  data() {
    return {
      layoutMode: "static",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: this.menu,
      username: null,
      displayConfirmation: false,
      detect: null,
      // menu : this.$localStorage.get('sideMenu'),
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  created() {
    //
  },
  async mounted() {
    // if (sessionStorage.getItem('fe-version') === null)
    // AuthService.whoAmi()
    let currentURL = window.location.href.toString();
    let splitName = currentURL.split("/");
    let cutURL = splitName[splitName.length - 1];
    if (currentURL.includes("resetpassword")) {
      switch (window.mode) {
        case "development":
          sessionStorage.setItem("tknfrgt", cutURL);
          this.$router.push("/resetpassword");
          break;
        case "production":
          sessionStorage.setItem("tknfrgt", cutURL);
          this.$router.push("/resetpassword");
          break;
        case "uat":
          sessionStorage.setItem("tknfrgt", cutURL);
          this.$router.push("/resetpassword");
          break;
        default:
          cutURL = currentURL.substring(38);
          sessionStorage.setItem("tknfrgt", cutURL);
          this.$router.push("/resetpassword");
      }
      // 
    } else {
       try {
        const response = await AuthService.whoAmi();        
        if (
          response.data.exp == false &&
          (sessionStorage.authKey != null ||
            response.data.data.sidemenus != null ||
            response.data.data.fullname != null)
        ) {
          this.username = response.data.data.fullname;

          setTimeout(this.checkSidemenu(), 50);
          sessionStorage.setItem("leveltenant", response.data.data.leveltenant);
          sessionStorage.setItem("idUser", response.data.data.id);
        } else {
          this.displayConfirmation = true;
          sessionStorage.clear("authKey");
          this.$router.push("/login");
        }
       } catch (e){
          // 
            let payload = {
            appid: 4,
            appname: "spb",
          };
          // sessionStorage.clear();
          await AuthService.signOutApp(payload);
          // 
          // location.reload()
          await AuthService.loggedOut();
          await this.$router.push("/login");
          await sessionStorage.setItem("loggedIn", false);
          await console.clear();
          // window.location.reload(true)
        }
      
    }
  },
  methods: {
    async checkSidemenu() {
      // 
      const response = await AuthService.whoAmi();
      this.menu = response.data.data.sidemenus;
      
      if (this.menu === undefined) {
        let payload = {
          appid: 4,
          appname: "spb",
        };
        sessionStorage.clear();
        await AuthService.signOutApp(payload);
        // 
        // location.reload()
        await AuthService.loggedOut();
        await sessionStorage.setItem("loggedIn", false);
        await console.clear();
        await this.$router.push("/login");
        window.location.reload(true)
      }
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
      }

      return true;
    },
    changeTheme(event) {
      this.$emit("change-theme", event);
    },
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
    logo() {
      return this.$appState.darkTheme
        ? "images/logo-white.svg"
        : "images/logo.svg";
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    // 'AppConfig': AppConfig,
    AppFooter: AppFooter,
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
