import { encryptForm } from "../helper/encryptForm";
import SpbBindBatchApi from "./api/SpbBindBatchApi";
class CreateBatch{
    getBatchNo(payload) {
        return SpbBindBatchApi.post('/spb/createbatch/dropdownbatch', payload);
    }
    getBatchNoPIC(payload) {
        return SpbBindBatchApi.post('/spb/createbatch/dropdownbatchPic', payload);
    }
	printBatchBarcode(payload) {
        return SpbBindBatchApi.post('/spb/createbatch/printbatch', payload);
    }
	printBatch(payload) {
        return SpbBindBatchApi.post('/spb/createbatch/printbatchbarang', payload);
    }
    getSuppBindComp(idUser) {
		return SpbBindBatchApi.post('spb/createbatch/getsuppbindcomp/' + idUser);
	}
	getLastAllSeq(id) {
		return SpbBindBatchApi.get(`spb/createbatch/getlastallseq/` + id)
	}
	getListSupplier(payload) {
		return SpbBindBatchApi.post('spb/createbatch/getSupplier', payload)
	}
	submitBatch(payload) {
		return SpbBindBatchApi.post(`/spb/createbatch/submitbatch`, payload);
	}
    getListInvoice(params, payload) {
		const queryParams = params
			? Object.keys(params)
					.map(
						(k) =>
							encodeURIComponent(k) +
							'=' +
							encodeURIComponent(params[k])
					)
					.join('&')
			: '';
		return SpbBindBatchApi.post(
			`/spb/createbatch/getbatch?${queryParams}`,
			encryptForm(payload)
		);
	}   
}

export default new CreateBatch();

