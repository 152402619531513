<template>
	<div class="grid">
		<div class="content-wrapper">
			<div class="content-body">
				<DataTable
					:value="batchlist"
					:lazy="true"
					:paginator="true"
					:rows="rows"
					:rowsPerPageOptions="[5, 15, 25]"
					removableSort
					:metaKeySelection="false"
					:loading="loading"
					:totalRecords="totalRecords"
					ref="dt"
					dataKey="id"
					paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
					responsiveLayout="scroll"
					:scrollable="true"
					scrollHeight="600px"
					selectionMode="multiple"
					v-model:selection="selectedPo"
					@page="onPage($event)"
				>
					<template #header>
						<span class="p-input-icon-left" style="width: 100%">
							<i class="pi pi-search" />
							<InputText
								placeholder="Search"
								style="width: 100%"
								type="text"
								v-model="searchKeyword"
								@keyup.enter="populateTable()"
								@page="onPage($event)"
							/>
						</span>
					</template>
					<template #empty> {{ isEmpty }} </template>
					<Column
						class="call-button-container"
						header="Invoice No"
						field="INVOICE_NO"
					>
					</Column>
					<Column
						class="call-button-container"
						header="Invoice Amount"
						field="INVOICE_AMOUNT"
					>
						<template #body="{ data }">
							{{ formatCurrency(data.INVOICE_AMOUNT) }}
						</template>
					</Column>
					<Column
						class="call-button-container"
						header="Tax Amount"
						field="TAX_AMOUNT"
					>
						<template #body="{ data }">
							{{ formatCurrency(data.TAX_AMOUNT) }}
						</template>
					</Column>
					<Column
						class="call-button-container"
						header="Total Amount"
						field="TOTAL_AMOUNT"
					>
						<template #body="{ data }">
							{{ formatCurrency(data.TOTAL_AMOUNT) }}
						</template>
					</Column>
				</DataTable>
			</div>
			<div class="col-12 mb-5 lg:col-4 lg:mb-5">
				<Button
					@click="handleAdd()"
					class="p-button-success uppercase mx-3"
					label="add/update"
				/>
				<Button
					@click="closeBasic"
					class="p-button-secondary uppercase"
					label="close"
				/>
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref, defineEmits } from 'vue';
import CreateBatchServices from '../../../service/CreateBatchServices';
import AuthService from '@/service/AuthService';
import {filterAuthByVendorname} from "@/helper/filterAuthByVendorname";
// import { FilterMatchMode } from "primevue/api";
import { useRouter } from 'vue-router';

const company = ref();
const supplier = ref(null);
const router = useRouter();

onMounted(async () => {
	company.value = props.company;
	isEmpty.value = 'Please wait for Data';
	const info = await AuthService.whoAmi();
	if (info.status === 200) {
		levelTenant.value = info.data.data.leveltenant;
		if (levelTenant.value !== 2) {
			loading.value = true;
			supplier.value = props.supplier;
			// loading.value = true
			await populateTable();
		} else {
			if (company.value && !supplier.value) {
				const param = {
					db_instance: props.company.db_instance,
					org_id: props.company.value,
				};
			
				const checkAuth = await filterAuthByVendorname({
					info_id: info.data.data.id,
					dbInstance: param.db_instance,
					value: param.org_id
				});

				if(checkAuth.supplier === null) router.push("/dashboard");

				supplier.value = checkAuth.supplier;
				await populateTable();
			}
			// loading.value = true
		}
	}
});
// eslint-disable-next-line no-undef
const props = defineProps({
	company: {
		type: Object,
	},
	supplier: {
		type: Object,
	},
	closeBasic: {
		type: String,
	},
});

const batchlist = ref();
// const totalData = ref(0);
const loading = ref(false);
const levelTenant = ref();
const rows = ref(5);
const payloadSearch = ref();
const searchKeyword = ref();
const totalRecords = ref(0);
const selectedPo = ref([]);
const lazyParams = ref({
	first: 0,
	rows: rows.value,
	sortField: null,
	sortOrder: null,
});
const isEmpty = ref();

const populateTable = async () => {
	loading.value = true;
	selectedPo.value = null;
	if (
		(props.column !== null && props.column !== undefined) ||
		props.company ||
		props.supplier
	) {
		const queriesToBe = {
			limitPage: lazyParams.value.rows.toString(),
			page: lazyParams.value.first.toString(),
			sortField: lazyParams.value.sortField,
			sortOrder: lazyParams.value.sortOrder,
		};
		const queries = {
			query: JSON.stringify(queriesToBe),
		};
		payloadSearch.value = {
			orgId: props.company.value,
			vendorId:
				(props.supplier?.vendor_id.toString() ?? props.supplier) ||
				null,
			dbInstance: props.company.db_instance,
			searchKeyword: searchKeyword.value,
		};
		const res = await CreateBatchServices.getListInvoice(
			queries,
			payloadSearch.value
		);
		emit('response', res);
		if (res.status === 200 && res.data.status === 200) {
			// console.log("response keyword", res.data);
			// let number = 0;
			const dataResp = res.data.data;
			batchlist.value = dataResp;
			// for (var s of dataResp) {
			//   number++;
			//   s.id = number;
			// }
			totalRecords.value = res.data.totalData;
		} else if (res.status === 202 && res.data.status === 202) {
			batchlist.value = null;
			selectedPo.value = null;
			totalRecords.value = 0;
			isEmpty.value = 'No Results Found';
		}
	}
	loading.value = false;
};

const emit = defineEmits(['onAdd, response']);

const onPage = async (event) => {
	// loading.value = true
	lazyParams.value = event;
	await populateTable();
};

const formatCurrency = (value) => {
	if (value) return value.toLocaleString('id-ID');
	return;
};

// const onSort = async (event) => {
//   // loading.value = true
//   lazyParams.value = event;
//   await populateTable();
// };

// const onRowSelect = async () => {
// };

const handleAdd = () => {
	const items = selectedPo.value;
	emit('onAdd', items);
};
</script>
