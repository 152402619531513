import spbAdmin from "./api/SpbAdminApi";
class NotificationService {
  async getAllNotificationsSupp(vendorId) {
    return spbAdmin.get(`spb/notification/getnotifbytenantsupp/${vendorId}`);
  }
  async getAllNotificationsPur(payload) {
    return spbAdmin.post("spb/notification/getnotifbytenantpur/", payload);
  }
  async getAllNotifications() {
    return spbAdmin.get("spb/notification/getnotifbytenant");
  }
  async getCompanyCurrentSupp(payload) {
    return spbAdmin.post("spb/notification/getcompanycurrentsupp/", payload);
  }
  async getCurrentSupplier(payload) {
    return spbAdmin.post("spb/notification/getsuppbindcomp/", payload);
  }
  async getPurNotifications(payload) {
    return spbAdmin.post("spb/notification/getnotifbypur", payload);
  }
  async getPurNotificationsSearch(payload) {
    return spbAdmin.post("spb/notification/getnotifbypursearch", payload);
  }
  async getNotifDetailPlanno(poNum) {
    const url = "spb/notification/notifdetailplanno/" + poNum;
    // const url = 'spb/notification/notifdetailplanno', poNum;
    return spbAdmin.post(url);
  }
  async getNotifDetailPlannoRecipient(payload) {
    return spbAdmin.post(
      "spb/notification/notifdetailplannorecipient/",
      payload
    );
  }
  async getNotifDetailPlannoSender(params) {
    const queryParams = params
      ? Object.keys(params)
          .map(
            (k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k])
          )
          .join("&")
      : "";
    return spbAdmin.post(
      "spb/notification/notifdetailplannosender?" + queryParams
    );
  }
  async getNotifDetailPurchaseOrder(poNum) {
    return spbAdmin.post("spb/notification/notifdetailpurchaseorder/" + poNum);
  }
  async getNotifDetailShipmentNotice(doNum) {
    return spbAdmin.post("spb/notification/notifdetailshipmentnotice/" + doNum);
  }
  async getNotifDetailCAPA(docNum) {
    return spbAdmin.post("spb/notification/notifdetailcapa/" + docNum);
  }
  async getCompanySupp(payload) {
    return spbAdmin.get("/spb/usermapping/getusermappingsbyiduser/" + payload);
  }
  async getOpenPOTotal(payload) {
    return spbAdmin.post("spb/notification/getOpenPOTotal/", payload);
  }
  async getCAPATotal(payload) {
    return spbAdmin.post("spb/notification/getCAPATotal/", payload);
  }
  async getShipmentTotal(payload) {
    return spbAdmin.post("spb/notification/getShipmentTotal/", payload);
  }
}
export default new NotificationService();
