<template>
  <DataTable
    :value="poList"
    :paginator="true"
    :rows="10"
    :loading="loading"
    :totalRecords="totalData"
    @page="onPage($event)"
    @sort="onSort($event)"
    :rowsPerPageOptions="[10, 20, 30]"
    responsiveLayout="scroll"
    :sortable="true"
    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
  >
    <template #empty>No PO found</template>
    <template #loading>Loading data, please wait...</template>
    <Column header="Vendor Name" field="VENDOR_NAME" :sortable="true"></Column>
    <Column header="PO Number" field="SEGMENT1" :sortable="true"> </Column>
    <Column header="Line" field="LINE_NUM"> </Column>
    <Column header="Type PO" field="TYPE_PO"> </Column>
    <Column header="Item Number" field="ITEM_NUMBER"> </Column>
    <Column header="Item Description" field="ITEM_DESCRIPTION"> </Column>
    <Column header="UOM" field="UOM"> </Column>
    <Column header="Price" field="QUANTITY"> </Column>
    <Column header="Arrival at Site" field="NEED_BY_DATE">
      <template #body="{ data }">
        {{
          new Date(data.NEED_BY_DATE).toLocaleDateString("id-ID", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })
        }}
      </template>
    </Column>
    <Column header="Est. Good Readiness" field="ESTIMATE_COMPLATION">
      <template #body="{ data }">
        {{
          new Date(data.ESTIMATE_COMPLATION).toLocaleDateString("id-ID", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })
        }}
      </template>
    </Column>
    <Column header="Est. Delivery" field="ESTIMATE_MANUFACTURER">
      <template #body="{ data }">
        {{
          new Date(data.ESTIMATE_MANUFACTURER).toLocaleDateString("id-ID", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })
        }}
      </template>
    </Column>
    <Column header="Est. Arrival at Site" field="ESTIMATE_SUPPLIER">
      <template #body="{ data }">
        {{
          new Date(data.ESTIMATE_SUPPLIER).toLocaleDateString("id-ID", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })
        }}
      </template>
    </Column>
    <Column header="Delivery Date" field="SHIPPED_DATE">
      <template #body="{ data }">
        {{
          new Date(data.SHIPPED_DATE).toLocaleDateString("id-ID", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })
        }}
      </template>
    </Column>
    <Column header="DO Number" field="SHIPMENT_NUMBER"> </Column>
    <Column header="Quantity Shipped" field="QUANTITY_SHIPPED"> </Column>
  </DataTable>
  <div class="col-6 mb-2 lg:col-1 lg:mb-0">
    <Button
      type="button"
      @click="exportExcel"
      label="Export to Excel"
      class="p-button-success"
    />
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import XLSX from "xlsx";
import {
  getTrackingPOAll,
  getTrackingPOAllLength,
  getTrackingPOByColumn,
  getTrackingPOByColumnLength,
} from "@/service/TrackingPOReport";
// eslint-disable-next-line no-undef
const props = defineProps({
  column: {
    type: String,
  },
  columnSearch: {
    type: String,
    default: "",
  },
});

const poList = ref();
const totalData = ref(0);
const loading = ref(false);
const page = ref(1);
const rowsPerPage = ref(10);
const sortColumn = ref();
const sortType = ref();

onMounted(async () => {
  loading.value = true;
  await populateTable();
});

const onPage = async (event) => {
  // loading.value = true
  page.value = event.page + 1;
  rowsPerPage.value = event.rows;
  await populateTable();
};

const onSort = async (event) => {
  // loading.value = true
  page.value = 1;
  rowsPerPage.value = event.rows;
  sortColumn.value = event.sortField;
  sortType.value = event.sortOrder;
  await populateTable();
};

const populateTable = async () => {
  if (props.column !== null && props.column !== undefined) {
    poList.value = await getTrackingPOByColumn(
      props.column,
      props.columnSearch,
      page.value,
      10
    );
    totalData.value = await getTrackingPOByColumnLength(
      props.column,
      props.columnSearch
    );
  } else {
    poList.value = await getTrackingPOAll(page.value, 10);
    totalData.value = await getTrackingPOAllLength();
    for (var ast of poList.value) {
      const arrival = ast.ESTIMATE_COMPLATION;
      let atSite = new Date(arrival);
      const needByDate = ast.NEED_BY_DATE;
      let byDate = new Date(needByDate);
      const manufacture = ast.ESTIMATE_MANUFACTURER;
      let manuf = new Date(manufacture);
      const supp = ast.ESTIMATE_SUPPLIER;
      let essSupp = new Date(supp);
      const ship = ast.SHIPPED_DATE;
      let shipDate = new Date(ship);
      const prom = ast.PROMISED_DATE;
      let promiseDate = new Date(prom);
      ast.ESTIMATE_COMPLATION = (
        atSite.getDate() +
        "-" +
        atSite.toString().substring(4, 7) +
        "-" +
        atSite.getFullYear()
      ).toUpperCase();
      ast.NEED_BY_DATE = (
        byDate.getDate() +
        "-" +
        byDate.toString().substring(4, 7) +
        "-" +
        byDate.getFullYear()
      ).toUpperCase();
      ast.ESTIMATE_MANUFACTURER = (
        manuf.getDate() +
        "-" +
        manuf.toString().substring(4, 7) +
        "-" +
        manuf.getFullYear()
      ).toUpperCase();
      ast.ESTIMATE_SUPPLIER = (
        essSupp.getDate() +
        "-" +
        essSupp.toString().substring(4, 7) +
        "-" +
        essSupp.getFullYear()
      ).toUpperCase();
      ast.SHIPPED_DATE = (
        shipDate.getDate() +
        "-" +
        shipDate.toString().substring(4, 7) +
        "-" +
        shipDate.getFullYear()
      ).toUpperCase();
      ast.PROMISED_DATE = (
        promiseDate.getDate() +
        "-" +
        promiseDate.toString().substring(4, 7) +
        "-" +
        promiseDate.getFullYear()
      ).toUpperCase();
    }
  }
  loading.value = false;
};

// const onPage = (event) => {
//   
//   
// }

function exportExcel() {
  let filename = "Tracking PO" + "_" + ".xlsx";
  let data = poList.value;
  let ws = XLSX.utils.json_to_sheet(data);
  let wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Tracking PO");
  XLSX.writeFile(wb, filename);
}
</script>

<style scoped>
</style>