import SpbReportApi from "./api/SpbReportApi";

class ReturnService {
  async getvendorbytenant(userId, companyName, dbInstance, orgId, levelTenant) {
    return SpbReportApi.get(
      `spb/return/getvendorbytenant/${userId}/${companyName}/${dbInstance}/${orgId}/${levelTenant}`
    );
  }

  async getlistponumber(vendorId, dbInstance, orgId, levelTenant) {
    return SpbReportApi.get(
      `spb/return/getlistponumber/${vendorId}/${dbInstance}/${orgId}/${levelTenant}`
    );
  }

  async getlistreturn(payload) {
    return SpbReportApi.post(`spb/return/getlistreturn`, payload);
  }

  async getdownloaddata(payload) {
    return SpbReportApi.post(`spb/return/getdownloaddata`, payload);
  }
}

export default new ReturnService();
