<template>
	<div class="grid find-po-modal">
		<Toast />

		<!-- Content -->
		<div class="col-12 mb-2">
			<div class="content-wrapper">
				<div class="content-header">Find PO</div>
				<div class="content-body">
					<!-- Search input -->
					<div class="grid">
						<div class="col-auto mb-8">
							<Button
								class="p-button-success mr-2"
								@click="handleSearch"
							>
								<span class="uppercase font-bold">Search</span>
							</Button>
						</div>
					</div>

					<!-- Table result -->
					<div class="mb-5">
						<DataTable
							dataKey="line_num"
							responsiveLayout="scroll"
							selectionMode="multiple"
							ref="dt"
							:lazy="true"
							:paginator="true"
							:rows="5"
							:rowsPerPageOptions="[5, 10, 20, 50]"
							:metaKeySelection="false"
							:loading="isLoading"
							:totalRecords="totalRecords"
							:value="poItems"
							paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
							v-model:selection="selectedPo"
							@page="onPage($event)"
						>
							<!-- Search input -->
							<template #header>
								<div>
									<span class="p-input-icon-left w-full">
										<i class="pi pi-search" />
										<InputText
											class="w-full"
											type="text"
											placeholder="Search"
											:disabled="!poNumbers"
											v-model="searchKeyword"
											@keyup.enter="handleSearch"
											@page="onPage($event)"
										/>
									</span>
								</div>
							</template>

							<!-- Results Data -->
							<Column header="PO Number" field="po_number">
							</Column>
							<Column header="Total Amount" field="total_amount">
								<template #body="{ data }">
									{{
										Number.parseFloat(data.total_amount)
											.toFixed(2)
											.replace(
												/\B(?=(\d{3})+(?!\d))/g,
												','
											)
									}}
								</template>
							</Column>
							<Column header="Prepayment No" field="prepayment_no">
							</Column>
							<Column header="Percentage" field="percentage">
							</Column>
							<Column header="Amount">
								<template #body="{ data }">
									{{
										Number.parseFloat(data.amount)
											.toFixed(2)
											.replace(
												/\B(?=(\d{3})+(?!\d))/g,
												','
											)
									}}
								</template>
							</Column>

							<!-- Empty statement -->
							<template #empty> No data found. </template>
						</DataTable>
					</div>
					<div>
						<Button
							class="p-button-success mr-2"
							:disabled="!selectedPo.length"
							@click="handleAdd"
						>
							<span class="uppercase font-bold">Add</span>
						</Button>
						<Button
							class="p-button-secondary"
							@click="$emit('onClose')"
						>
							<span class="uppercase font-bold">Close</span>
						</Button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, defineEmits, defineProps, onMounted } from 'vue';
// import InvoicePOService from '../../service/InvoicePOService';
import PendingPOService from '../../service/PendingPOService';

// Props
const props = defineProps(['dbInstance', 'orgId', 'vendorId', 'banList', 'prepaymentFlag', 'poNumber']);

// Define emits
const emit = defineEmits(['onAdd']);

// State
const searchKeyword = ref(null);
const poNumbers = ref(null);
const poItems = ref(null);
const selectedPo = ref([]);
const isLoading = ref(false);
const totalRecords = ref(0);
const lazyParams = ref(null);
const dt = ref(null);

// Computed format date

// Mounted hook
onMounted(async () => {
	// const { dbInstance, orgId, vendorId } = props;

	lazyParams.value = {
		first: 0,
		page: 0,
		rows: dt.value.rows,
	};

	// const payload = {
	// 	dbInstance,
	// 	orgId,
	// 	vendorId,
	// };

	// const res = await InvoicePOService.getPoNumbers(payload);
	// poNumbers.value = res.data.data;
});

// Handle search PO
const handleSearch = async () => {
	isLoading.value = true;

	const { dbInstance, orgId, vendorId } = props;
	
	const payload = {
		poNumber: props.poNumber,
		dbInstance,
		orgId,
		vendorId,
		limit: lazyParams.value.rows,
		page: lazyParams.value.page + 1,
		prepayment_flag: props.prepaymentFlag
	};


	const res = await PendingPOService.getListFindPoPrepayment(payload);

	if (res.status === 200) {
		poItems.value = res.data.data.filter((Q) => {
			const is_true = props.banList.some((y) => y.po_number === Q.po_number && y.prepayment_no === Q.prepayment_no);
			if(is_true === true){
				return;
			}
			return Q;
		})

		totalRecords.value = res.data.count;
		isLoading.value = false;
	}
};

// Search PO Number

// Handle pagination
const onPage = (event) => {
	lazyParams.value = event;
	handleSearch();
};

// Reset search input

// Add selected PO
const handleAdd = async () => {
	const items = selectedPo.value;
	emit('onAdd', items);
};
</script>
