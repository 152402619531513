<template>
	<DataTable
		:value="batchListDetail"
		responsiveLayout="scroll"
		:sortable="true"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
		:scrollable="true"
		scrollHeight="600px"
	>
		<template #empty>No PO found</template>
		<template #loading>Loading data, please wait...</template>

		<Column header="Invoice No" field="INVOICE_NO"> </Column>
		<Column header="Invoice Date" field="INVOICE_DATE"> </Column>
		<Column header="Invoice Amount" field="INVOICE_AMOUNT">
			<template #body="{ data }">
				{{ formatCurrency(data.INVOICE_AMOUNT) }}
			</template>
		</Column>
		<Column header="Tax No" field="TAX_NO"> </Column>
		<Column header="Tax Date" field="TAX_DATE"> </Column>
		<Column header="Tax Amount" field="TAX_AMOUNT">
			<template #body="{ data }">
				{{ formatCurrency(data.TAX_AMOUNT) }}
			</template>
		</Column>
		<Column header="Status" field="STATUSINVOICE"> </Column>
	</DataTable>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import AuthService from '@/service/AuthService';
import BatchListService from '../../../service/batchList';

const userInfo = ref();
const route = useRoute();
const batch_id = ref(route.params.batch_id);
const batchListDetail = ref(0);

AuthService.whoAmi().then(async (response) => {
	if (response.status === 200) {
		userInfo.value = response.data.data;
		// await getData()
		const result = await BatchListService.getBatchList(batch_id.value);
		if (result.data.data.status !== 'Error'){
			batchListDetail.value = result.data.data.data;
			for (var id of batchListDetail.value) {
				id.INVOICE_DATE = dateFormat(id.INVOICE_DATE);
				id.TAX_DATE = dateFormat(id.TAX_DATE);
			}
		} else {
			batchListDetail.value = []
		}
		
	}
	function dateFormat(d) {
		var monthShortNames = [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec',
		];
		var t = new Date(d);
		return (
			t.getDate() +
			' ' +
			monthShortNames[t.getMonth()] +
			' ' +
			t.getFullYear() +
			' '
		);
	}
});
const formatCurrency = (value) => {
	if (value) return value.toLocaleString('id-ID');
	return;
};
</script>

<style scoped></style>
