import SpbInvoiceNonPO from "./api/SpbInvoiceApi";

class InvoiceNonPOSearch{
    async getBatchNumberwComp(payload){
        return SpbInvoiceNonPO.post('spb/invoicenonpo/batchNumberwComp', payload);
    }
    async getBatchNumberwCompnInvoice(payload){
        return SpbInvoiceNonPO.post('spb/invoicenonpo/batchNumberwCompnInvoice', payload);
    }
    async getBatchNumberwCompnSupp(payload){
        return SpbInvoiceNonPO.post('spb/invoicenonpo/batchNumberwCompnSupp', payload);
    }
    async getBatchNumberwCompnInvoiceno(payload){
        return SpbInvoiceNonPO.post('spb/invoicenonpo/batchNumberwCompnInvoiceno', payload);
    }
    async getBatchNumberwCompPIC(payload){
        return SpbInvoiceNonPO.post('spb/invoicenonpo/batchNumberwCompPic', payload);
    }
    async getBatchNumberwCompnInvoicePIC(payload){
        return SpbInvoiceNonPO.post('spb/invoicenonpo/batchNumberwCompnInvoicePic', payload);
    }
    async getBatchNumberwCompnSuppPIC(payload){
        return SpbInvoiceNonPO.post('spb/invoicenonpo/batchNumberwCompnSuppPic', payload);
    }
    async getBatchNumberwCompnInvoicenoPIC(payload){
        return SpbInvoiceNonPO.post('spb/invoicenonpo/batchNumberwCompnInvoicenoPic', payload);
    }
    async getInvoiceNumberwComp(payload){
        return SpbInvoiceNonPO.post('spb/invoicenonpo/invoiceNumberwComp', payload);
    }
    async getInvoiceNumberwCompnInvoice(payload){
        return SpbInvoiceNonPO.post('spb/invoicenonpo/invoiceNumberwCompnInvoice', payload);
    }
    async getInvoiceNumberwCompnSupp(payload){
        return SpbInvoiceNonPO.post('spb/invoicenonpo/invoiceNumberwCompnSupp', payload);
    }
    async getInvoiceNumberwCompnInvoiceno(payload){
        return SpbInvoiceNonPO.post('spb/invoicenonpo/invoiceNumberwCompnInvoiceno', payload);
    }
    async getInvoiceNumberwCompPic(payload){
        return SpbInvoiceNonPO.post('spb/invoicenonpo/invoiceNumberwCompPIC', payload);
    }
    async getInvoiceNumberwCompnInvoicePic(payload){
        return SpbInvoiceNonPO.post('spb/invoicenonpo/invoiceNumberwCompnInvoicePIC', payload);
    }
    async getInvoiceNumberwCompnSuppPic(payload){
        return SpbInvoiceNonPO.post('spb/invoicenonpo/invoiceNumberwCompnSuppPIC', payload);
    }
    async getInvoiceNumberwCompnInvoicenoPic(payload){
        return SpbInvoiceNonPO.post('spb/invoicenonpo/invoiceNumberwCompnInvoicenoPIC', payload);
    }
    async getTableInvoiceNonPO(payload){
        return SpbInvoiceNonPO.post('spb/invoicenonpo/searchInvoiceNonPO', payload);
    }
    async getTableInvoiceNonPOpic(payload){
        return SpbInvoiceNonPO.post('spb/invoicenonpo/searchInvoiceNonPOpic', payload);
    }
}

export default new InvoiceNonPOSearch();