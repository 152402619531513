<template>
	<Toast />

	<div class="grid">
		<ProgressModal :progress="progress">
			<template #message>
				Submit batch in progress. Please wait...
			</template>
		</ProgressModal>
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<div class="lg:col-8 sm:col-12">
			<div class="content-wrapper">
				<div class="content-header uppercase">Batch E-Invoice</div>
				<div class="content-body">
					<!-- isi konten -->
					<div class="col-12 p-fluid">
						<div class="grid formgrid" style="margin: 1em 0 0 0">
							<div class="col-12 mb-5 lg:col-4">
								<!-- <CompanyDropdown
									v-model="company"
									:disabled="disableComp"
								/> -->
								<!-- Change to global companies drodown -->
								<CompaniesDropdown
									v-model="company"
									:disabled="disableComp"
									@item-select="onCompanySelect($event)"
								/>
							</div>
							<div
								class="col-12 mb-5 lg:col-4"
								v-if="
									!(userInfo
										? userInfo.leveltenant === 2
										: false)
								"
							>
								<SupplierDropdown
									v-model="supplier"
									:company="company ? company : null"
									:key="supplierReload"
								/>
							</div>
							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<BatchNumber
									v-model="batchNumberAutoCom"
									:company="company ? company : null"
									:supplier="supplier ? supplier : null"
									:disabled="batchDisable"
									:key="batchInpReload"
								/>
							</div>
							<div class="col-12">
								<p>
									Total:
									{{
										summary.totalInvoice &&
										Number.parseFloat(summary.totalInvoice)
											.toFixed(2)
											.replace(
												/\B(?=(\d{3})+(?!\d))/g,
												','
											)
									}}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="lg:col-4 sm:col-12">
			<div class="content-wrapper">
				<div class="content-header uppercase">Print Batch</div>
				<div class="content-body">
					<!-- isi konten -->
					<div class="col-12 p-fluid">
						<div class="grid formgrid" style="margin: 1em 0 0 0">
							<div class="col-12 mb-5 lg:col-12">
								<BatchNumberDropdown
									v-model="batchdropdown"
									:company="company ? company : null"
									:supplier="supplier ? supplier : null"
									:disabled="disablevalue"
									:key="batchReload"
								/>
							</div>
							<div class="col-12 mb-5 lg:col-12 lg:mb-5">
								<Button
									@click="printBatch()"
									class="p-button-secondary uppercase"
									label="Print Batch"
									:disabled="disablePrint"
								/>
							</div>
							<div class="col-12 mb-5 lg:col-12 lg:mb-5">
								<Button
									@click="printBatchBarang()"
									class="p-button-success uppercase"
									label="print penerimaan barang"
									:disabled="disablePrint"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="lg:col-8 md:col-12 sm:col-12 content-search">
			<div class="card">
				<div class="col-12">
					<DataTable
						:value="selectedBatch"
						responsiveLayout="scroll"
						:scrollable="true"
						scrollHeight="600px"
					>
						<template #empty> {{ isEmpty }} </template>
						<Column
							class="call-button-container"
							header="Invoice Number"
							field="INVOICE_NO"
						></Column>
						<Column
							class="call-button-container"
							header="Amount"
							field="TOTAL_AMOUNT"
						>
							<template #body="{ data }">
								{{
									Number.parseFloat(data.TOTAL_AMOUNT)
										.toFixed(2)
										.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
								}}
							</template>
						</Column>
						<Column
							header="Actions"
							style="min-width: 3rem"
							bodyStyle="text-align: center"
						>
							<template #body="{ data }">
								<Button
									icon="pi pi-trash"
									class="p-button-rounded p-button-text"
									@click="handleDelete(data.id)"
								/>
							</template>
						</Column>
					</DataTable>
				</div>
				<div class="col-12 p-fluid">
					<div class="grid formgrid" style="margin: 1em 0 0 0">
						<div class="col-12 mb-5 lg:col-4 lg:mb-5">
							<Button
								@click="handleClickModal"
								class="p-button-secondary uppercase"
								label="add invoice"
								:disabled="disableAdd"
							/>
						</div>
						<div class="col-12 mb-5 lg:col-4 lg:mb-5">
							<Button
								@click="handleSubmit()"
								class="p-button-success uppercase"
								label="submit"
							/>
						</div>
						<div class="col-12 mb-5 lg:col-4 lg:mb-5">
							<Button
								@click="handleReset()"
								class="p-button-secondary uppercase"
								label="reset"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<Dialog
		header="Find Invoice"
		:breakpoints="{ '960px': '75vw', '640px': '100vw' }"
		:style="{ width: '60vw', overflow: 'hidden' }"
		:modal="true"
		:draggable="false"
		:dismissableMask="true"
		v-model:visible="modal"
	>
		<div class="pt-3">
			<BatchList
				:closeBasic="closeBasic"
				:company="company ? company : null"
				:supplier="supplier ? supplier : null"
				:key="reload"
				@on-add="handleAddPo"
				@response="responseTable"
			/>
		</div>
	</Dialog>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
// import CompanyDropdown from './components/Company.vue';
import CompaniesDropdown from '../components/CompaniesDropdown.vue';
import BatchList from './components/BatchList.vue';
import BatchNumber from './components/BatchNumber.vue';
import AuthService from '../../service/AuthService';
import SimpleCrypto from 'simple-crypto-js';
import ProgressModal from '../../components/components/ProgressModal.vue';
import {filterAuthByVendorname} from "@/helper/filterAuthByVendorname"; 
import LovService from '@/service/LovService';
import BatchNumberDropdown from './components/BatchNumberDrop.vue';
import CreateBatchServices from '../../service/CreateBatchServices';
import SupplierDropdown from './components/SupplierDropdown.vue';
import { useToast } from 'primevue/usetoast';
import { useRouter } from 'vue-router';
import { UrlTab } from '../../constants/UrlTab';

const breadcrumbHome = { icon: 'pi pi-home', to: '/dashboard' };
const breadcrumbItems = [
	{
		label: 'Create Batch',
		to: '/mgm/monitor/batchcreate',
	},
];
const company = ref();
const batchNumberAutoCom = ref();
const batchdropdown = ref();
const supplier = ref(null);
const supplierName = ref(null);
const userInfo = ref();
const reload = ref(0);
const batchReload = ref(0);
const modal = ref(false);
const supplierReload = ref(0);
const batchInpReload = ref(0);
const disablevalue = ref(true);
const selectedBatch = ref([]);
const isEmpty = ref();
const toast = useToast();
const batchDisable = ref(true);
// const batchId = ref();
// const valueTable = ref();
const disableAdd = ref(false);
const progress = ref(false);
const disableComp = ref(false);
const router = useRouter();
const disablePrint = ref(true);

watch(
	() => company.value,
	() => {
		if (userInfo.value.leveltenant !== 2) {
			supplier.value = null;
		}
		supplierReload.value++;
		selectedBatch.value = [];
		batchdropdown.value = null;
		// batchReload.value++;
	}
);

watch(
	() => company.value && supplier.value,
	() => {
		selectedBatch.value = [];
		batchdropdown.value = null;
		batchReload.value++;
	}
);

watch(
	() => batchdropdown.value !== null && batchdropdown.value !== '',
	() => (disablePrint.value = false)
);

AuthService.whoAmi().then(async (response) => {
	if (response.status === 200) {
		userInfo.value = response.data.data;
		isEmpty.value = 'No selected item';
		disableAdd.value = true;

		if (userInfo.value.leveltenant === 2) {
			disableComp.value = false;
			//get company
			const respCbN = await LovService.getCompanyByName(userCompany());
			company.value = respCbN.data.data[0];
			//get supplier
			const param = {
				db_instance: company.value.db_instance,
				org_id: company.value.value,
			};

			const checkAuth = await filterAuthByVendorname({
				info_id: userInfo.value.id,
				dbInstance: param.db_instance,
				value: param.org_id
			});

			if(checkAuth.supplier === null) router.push("/dashboard");

			supplier.value = checkAuth.supplier;
			disablevalue.value = false;
			disableAdd.value = false;
			disablePrint.value = false;
						
		} else {
			disablevalue.value = false;
			disableComp.value = true;
			disableAdd.value = false;
			const respCbN = await LovService.getCompanyByName(userCompany());
			company.value = respCbN.data.data[0];
		}
	}
});

// const responseTable = (value) => {
//   valueTable.value = value.data.data;
// };

const onCompanySelect = async (e) => {
	disableAdd.value = true;
	disablevalue.value = true;
	disablePrint.value = true;

	//get supplier
	const param = {
		db_instance: e.value.db_instance,
		org_id: e.value.value,
	};

	const respSuppName = await LovService.getSupplierName(param);
	if (respSuppName.status === 200) {
		const suppList = respSuppName.data.data;

		for (const s of suppList) {
			if (s.vendor_name === supplierName.value) {
				disableAdd.value = false;
				disablevalue.value = false;
				disablePrint.value = false;
				supplier.value = s;
				break;
			}
		}
		if (supplier.value === null) {
			router.push('/dashboard');
			window.alert('Connection Unstable, ID not Recognize');
		}
	}
};

const handleAddPo = (items) => {
	const newItems = items.filter(
		(item) => !selectedBatch.value.some((val) => val.id === item.id)
	);
	selectedBatch.value = [...selectedBatch.value, ...newItems];
	modal.value = false;
};

const handleDelete = (id) => {
	selectedBatch.value = selectedBatch.value.filter((po) => po.id !== id);
};

const handleClickModal = () => {
	if (userInfo.value.leveltenant === 3) {
		if (supplier.value === null) {
			toast.add({
				severity: 'info',
				summary: 'Please select supplier',
				life: 3000,
			});
			return;
		}
	}
	modal.value = true;
};

const closeBasic = () => {
	modal.value = false;
};

const handleReset = (id) => {
	// console.log("reset");
	batchNumberAutoCom.value = null;
	if (userInfo.value.leveltenant !== 2) {
		supplier.value = null;
	}
	batchdropdown.value = null;
	selectedBatch.value = selectedBatch.value.filter((po) => po.id == id);
	batchReload.value++;
};

const userCompany = () => {
	const secretKey = 'some-unique-key';
	const simpleCrypto = new SimpleCrypto(secretKey);
	return simpleCrypto.decrypt(sessionStorage.getItem('dropdownCompany'));
};

const printBatch = () => {
	if (batchdropdown.value !== null) {
		let url = UrlTab() + "/print"
		let paramsPrintROO = {
			org_id: company.value.value,
			db_instance: company.value.db_instance,
			vendor_id: supplier.value ? supplier.value.vendor_id : null,
			batch_no: batchdropdown.value.batch_no,
		};

		console.log('paramsPrintBatch::', paramsPrintROO);
		sessionStorage.setItem(
			'paramsPrintROO',
			JSON.stringify(paramsPrintROO)
		);
		window.open(url, 'Print', 'height=720,width=1280');
	} else {
		toast.add({
			severity: 'info',
			summary: 'Please select batch number',
			life: 3000,
		});
	}
};

const printBatchBarang = () => {
	if (batchdropdown.value !== null) {
		let url = UrlTab() + "/printbatch";
		let paramsPrintROO = {
			org_id: company.value.value,
			vendor_id: supplier.value ? supplier.value.vendor_id : null,
			db_instance: company.value.db_instance,
			batch_no: batchdropdown.value.batch_no,
		};

		console.log('paramsPrintBatch::', paramsPrintROO);
		sessionStorage.setItem(
			'paramsPrintROO',
			JSON.stringify(paramsPrintROO)
		);
		window.open(url, 'Print', 'height=720,width=1280');
	} else {
		toast.add({
			severity: 'info',
			summary: 'Please select batch number',
			life: 3000,
		});
	}
};

// const formatCurrency = (value) => {
//   if (value) return value.toLocaleString("id-ID");
//   return;
// };

const formattingDate = (date, withTime) => {
	const theDate = new Date(date);
	const strDate =
		theDate.getFullYear() +
		'-' +
		(theDate.getMonth() + 1) +
		'-' +
		theDate.getDate();
	const strTime =
		theDate.getHours() +
		':' +
		theDate.getMinutes() +
		':' +
		theDate.getSeconds();
	if (withTime) {
		return `${strDate} ${strTime}`;
	} else {
		return `${strDate}`;
	}
};

const idUser = ref();

const summary = computed(() => {
	const totalInvoice =
		(selectedBatch.value &&
			selectedBatch.value.reduce((accumulator, obj) => {
				return accumulator + obj.TOTAL_AMOUNT;
			}, 0)) ||
		0;

	return {
		totalInvoice,
	};
});

const handleSubmit = async (id) => {
	if (selectedBatch.value.length > 0) {
		await AuthService.whoAmi().then(async (respond) => {
			if (respond.status === 200) {
				if (
					userInfo.value.leveltenant === 2 ||
					userInfo.value.leveltenant === 3
				) {
					idUser.value = respond.data.data.id;
					await CreateBatchServices.getLastAllSeq(
						company.value.value
					).then(async (resp) => {
						const sequenceNo = resp.data.data[0][0].seq + 1;
						const userId = respond.data.data.userid;
						let d = new Date();
						const formatDate = new Date(
							d.getFullYear(),
							d.getMonth() + 1
						)
							.toISOString()
							.slice(2, 7)
							.replace('-', '');

						let payload = {
							header: {
								BATCH_NO: userId + formatDate + sequenceNo,
								AMOUNT: summary.value.totalInvoice,
								ORG_ID: company.value.value,
								STATUS: 'OPEN',
								VENDOR_ID: supplier.value
									? supplier.value.vendor_id
									: 0,
								CREATION_DATE: formattingDate(new Date(), true),
								CREATED_BY: respond.data.data.id,
								LAST_UPDATE_DATE: formattingDate(
									new Date(),
									true
								),
								LAST_UPDATED_BY: respond.data.data.id,
								DB_INSTANCE: company.value.db_instance,
							},
							details: [],
							seqNum: [],
							emailBatch: [],
							poNum: [],
						};

						const details = selectedBatch.value.map((item) => {
							return {
								LINE_NUM: selectedBatch.value.length,
								EINVOICE_ID: item.EINVOICE_ID,
								AMOUNT: summary.value.totalInvoice,
								CREATION_DATE: formattingDate(new Date(), true),
								CREATED_BY: respond.data.data.id,
								LAST_UPDATE_DATE: formattingDate(
									new Date(),
									true
								),
								LAST_UPDATED_BY: respond.data.data.id,
							};
						});
						payload.details = details;
						const sequence = {
							ORG_ID: company.value.value,
							SEQ_NUM: sequenceNo,
						};
						payload.seqNum = sequence;

						const payloadEmail = {
							idUser: idUser.value,
							companyname: company.value.name,
							sendpodata: selectedBatch.value,
						};
						payload.emailBatch = payloadEmail;

						const payloadPo = selectedBatch.value.map((elem) => {
							return elem.children.map((item) => item.PO_NUMBER);
						});

						payload.poNum = payloadPo;

						payload.vendorName = supplier.value
							? supplier.value.vendor_name
							: null;

						try {
							progress.value = true;
							const res = await CreateBatchServices.submitBatch(
								payload
							);
							const newBatch = userId + formatDate + sequenceNo;
							if (res.status === 201) {
								toast.add({
									severity: 'success',
									summary: `Successfully create new batch ${newBatch}`,
								});
								progress.value = false;
								selectedBatch.value =
									selectedBatch.value.filter(
										(po) => po.id == id
									);
								batchNumberAutoCom.value = newBatch;
								batchReload.value++;
								// setTimeout(location.reload(), 2000);
							}
						} catch (error) {
							progress.value = false;
							if (error.response.status === 400) {
								const existNo =
									userId + formatDate + sequenceNo;
								toast.add({
									severity: 'error',
									summary: `Batch Number ${existNo} already exist`,
									life: 5000,
								});
							} else {
								toast.add({
									severity: 'error',
									summary: 'Submit Batch Error',
									life: 3000,
								});
							}
						}
					});
				}
			}
		});
	} else {
		toast.add({
			severity: 'error',
			summary: 'Data Empty',
			life: 3000,
		});
	}
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 992px) {
	.content-search {
		margin-top: -110px;
	}
}
</style>
