<template>
	<DataTable
		:value="legalInformation"
		:lazy="true"
		:rows="5"
		:loading="loading"
		:totalRecords="totalData"
		:paginator="true"
		@page="onPage($event)"
		@sort="onSort($event)"
		:rowsPerPageOptions="[5, 10, 15]"
		responsiveLayout="scroll"
		:scrollable="true"
		scrollHeight="600px"
		:sortable="true"
		:rowId="null"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #empty>Data Not found</template>
		<template #loading>Loading data, please wait...</template>
		<Column header="Document Name" field="LEGAL_NAME" style="width: 9%">
		</Column>
		<Column header="Document No" field="LEGAL_NO" style="width: 9%">
		</Column>
		<Column
			header="Valid From Date"
			field="EFF_START_DATE"
			style="width: 9%"
		>
		</Column>
		<Column header="Valid To Date" field="EFF_END_DATE" style="width: 9%">
		</Column>
		<Column
			class="call-button-container"
			header="Actions"
			selectionMode="single"
			style="width: 9%"
		>
			<template #body="slotProps">
				<Button
					v-if="!(levelTenant === 1)"
					icon="pi pi-eye"
					class="p-button-rounded p-button-text"
					@click="editChildren(slotProps.data)"
					v-tooltip="'Detail'"
				/>
				<Button
					v-if="!(levelTenant === 1)"
					icon="pi pi-trash"
					class="p-button-rounded p-button-text"
					@click="handleDelete(slotProps.data)"
				/>
				<Button
					icon="pi pi-download"
					class="p-button-rounded p-button-text"
					@click="getFileAttachment(slotProps.data)"
				/>
			</template>
		</Column>
	</DataTable>

	<Dialog
		header="Delete Reimburse"
		v-model:visible="deleteLegalValue"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '50vw' }"
		:modal="true"
	>
		<div class="confirmation-content">
			<i
				class="pi pi-exclamation-triangle mr-3"
				style="font-size: 2rem"
			/>
			<span v-if="vendorValue"
				>Are you sure you want to delete Reimburse for Vendor
				<b>{{ props.supplier.vendor_name }}</b> from Database?</span
			>
		</div>
		<template #footer>
			<Button
				label="No"
				icon="pi pi-times"
				class="p-button-text"
				@click="deleteLegalValue = false"
			/>
			<Button
				label="Yes"
				icon="pi pi-check"
				class="p-button-text"
				@click="deleteLegal"
			/>
		</template>
	</Dialog>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import AuthService from '@/service/AuthService';
import PoFulfillment from '@/service/PoFulfillment';
import LovService from '@/service/LovService';
import SupplierMaster from '../../../service/SupplierMasterServices';
import { useToast } from 'primevue/usetoast';

const company = ref();
const supplier = ref();

onMounted(async () => {
	company.value = props.company;
	const info = await AuthService.whoAmi();
	if (info.status === 200) {
		levelTenant.value = info.data.data.leveltenant;

		if (levelTenant.value !== 2) {
			loading.value = true;
			supplier.value = props.supplier;
			// loading.value = true
			await populateTable();
		} else {
			if (company.value && !supplier.value) {
				const param = {
					db_instance: props.company.db_instance,
					org_id: props.company.value,
				};
				const respVendor = await PoFulfillment.getSuppBindComp(
					info.data.data.id
				);
				const vendorName = respVendor.data.data[0][0].vendorname;
				const respSuppName = await LovService.getSupplierName(param);
				if (respSuppName.status === 200) {
					const suppList = respSuppName.data.data;
					for (const s of suppList) {
						if (s.vendor_name === vendorName) {
							supplier.value = s;
							break;
						}
					}
				}
				await populateTable();
			}
			// loading.value = true
		}
	}
});
// eslint-disable-next-line no-undef
const props = defineProps({
	company: {
		type: Object,
	},
	supplier: {
		type: Object,
	},
});
//

const loading = ref(false);
const page = ref(1);
const rowsPerPage = ref(10);
const sortColumn = ref();
const sortType = ref();
const levelTenant = ref();
const tableData = ref([]);
const legalInformation = ref();
const totalData = ref(0);
const router = useRouter();
const toast = useToast();
const deleteLegalValue = ref(false);
const vendorValue = ref();

const populateTable = async () => {
	loading.value = false;

	if (props.company !== null && props.company !== undefined) {
		const payload = {
			suppValue: supplier.value ? supplier.value.vendor_id : null,
			dbInstance: company.value ? company.value.db_instance : null,
			page: page.value,
			rows: rowsPerPage.value,
		};

		tableData.value = await SupplierMaster.getAllData(payload);

		legalInformation.value = tableData.value.data.data.data;

		totalData.value = tableData.value.data.data.totalData[0].totalData;

		//

		// for (var date of legalInformation.value) {
		//     date.EFF_START_DATE = dateFormat(date.EFF_START_DATE);
		//     date.EFF_END_DATE = dateFormat(date.EFF_END_DATE);
		// }

		// for (var date of legalInformation.value) {
		//   let dateLegal = date.EFF_END_DATE
		//   if(dateLegal === null) {
		//     date.EFF_END_DATE = ''
		//   }
		//     date.EFF_START_DATE = dateFormat(date.EFF_START_DATE);
		//     date.EFF_END_DATE = dateFormat(date.EFF_END_DATE);
		// }
	}
	loading.value = false;
};

const onPage = async (event) => {
	// loading.value = true
	page.value = event.page + 1;
	rowsPerPage.value = event.rows;
	await populateTable();
};

const onSort = async (event) => {
	// loading.value = true
	page.value = 1;
	rowsPerPage.value = event.rows;
	sortColumn.value = event.sortField;
	sortType.value = event.sortOrder;
	await populateTable();
};

const editChildren = async (rowIndex) => {
	router.push({
		name: 'detailsuppliermaster',
		params: { id: rowIndex.LEGAL_ID },
		query: { v: props.company.value, i: props.supplier.vendor_id },
	});
};
const handleDelete = async (rowIndex) => {
	//
	const payload = { legal_id: rowIndex.LEGAL_ID, value: false };
	//
	const data = await SupplierMaster.deleteLegalInformation(payload);

	if (data.data.data.data.length !== 0) {
		vendorValue.value = rowIndex;

		deleteLegalValue.value = true;
	} else {
		legalInformation.value = legalInformation.value.filter(
			(item, index) => index !== rowIndex
		);
		toast.add({
			severity: 'error',
			summary: 'Line Delete',
			detail: 'Line Successfully Deleted',
			life: 3000,
		});
	}
};

const deleteLegal = async () => {
	//
	const payload = { legal_id: vendorValue.value.LEGAL_ID, value: true };

	const data = await SupplierMaster.deleteLegalInformation(payload);
	if (data.status === 200) {
		deleteLegalValue.value = false;
		legalInformation.value = legalInformation.value.filter(
			(val) => val.LEGAL_ID !== vendorValue.value.LEGAL_ID
		);
		// legalInformation.value = legalInformation.value.filter((item, index) => index !== rowIndex);
		toast.add({
			severity: 'error',
			summary: 'Line Delete',
			detail: 'Line Successfully Deleted',
			life: 3000,
		});
		totalData.value = totalData.value - 1;
		await populateTable();
	}
};

const getFileAttachment = async (rowIndex) => {
	try {
		const res = await SupplierMaster.getAttachmentFile(
			rowIndex.LEGAL_ID,
			levelTenant.value
		);

		if (res.status === 200) {
			const linkSource = `data:application/pdf;base64,${res.data.data.data.fileBuffer}`;
			const downloadLink = document.createElement('a');
			const fileName = res.data.data.data.fileName;
			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.click();

			// saved.value = res.data.data.data;
			// savedRetur.value = res.data.data.dataRetur
		}
	} catch (error) {
		toast.add({
			severity: 'error',
			summary: 'File is Empty',
			life: 3000,
		});
		throw new Error(error);
	}
};

// function dateFormat(d) {
//   var monthShortNames = [
//     "Jan",
//     "Feb",
//     "Mar",
//     "Apr",
//     "May",
//     "Jun",
//     "Jul",
//     "Aug",
//     "Sep",
//     "Oct",
//     "Nov",
//     "Dec",
//   ];
//   var t = new Date(d);
//   return (
//     t.getDate() +
//     " " +
//     monthShortNames[t.getMonth()] +
//     " " +
//     t.getFullYear() +
//     " "
//   );
// }
</script>
<style scoped></style>
