<template>
  <!-- eslint-disable -->
  <Toast />
  <div class="Grid">
    <div class="col-12">
      <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
    </div>
    <!-- Search -->
    <div class="col-12 mb-4">
      <div class="content-wrapper">
        <div class="content-header">Search Item Jit</div>
        <div class="content-body">
          <div class="col-12 p-fluid">
            <div class="grid formgrid" style="margin: 1em 0 0 0">
            <div class="col-2 lg:col-1">
                <SplitButton icon="pi pi-search" :model="items" class="p-button-text mb-2"></SplitButton>
            </div>
              <div class="col-10 mb-5 lg:col-3">
                  <span class="p-float-label">
                    <InputText
                        id="inputtext"
                        type="text"
                        v-model="searchValue" 
                    />
                    <label for="inputtext">{{search}}</label>
                </span>
              </div>
              <div class="mr-3 ml-2">
                <Button
                  @click="handleSearch()"
                  class="p-button-success uppercase"
                  label="search"
                  :disabled="loading"
                />
              </div>
              <div>
                <Button
                  @click="clearFilter()"
                  class="p-button-secondary uppercase"
                  label="clear"
                ></Button>
              </div>
              <div class="mr-3 ml-2">
                <Button
                  @click="createItemJit()"
                  class="p-button-secondary uppercase"
                  label="Create"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Search results -->
    <div class="col-12">
      <div class="content-wrapper">
        <div class="content-header">Search Result</div>
        <div class="content-body">
          <div class="mt-4">
            <TablePOInterface :search="searchValue?searchValue:null" :choose="valueChoose?valueChoose:null"
			:key="reloadTable"></TablePOInterface>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue-demi";
import AuthService from "../../service/AuthService";
import { useToast } from 'primevue/usetoast';
import SimpleCrypto from 'simple-crypto-js';
import LovService from '../../service/LovService';
import { filterAuthByVendorname } from "@/helper/filterAuthByVendorname";
import { useRouter } from "vue-router";
import TablePOInterface from '../itemjit/components/TableItemJit.vue'

// eslint-disable-next-line no-undef
const company = ref();
const userInfo = ref();
const levelTenant = ref();
const supplier = ref(null);
const isSupplierpage = ref(false);
const reloadTable = ref(0);
const search = ref('All Columns');
const searchValue = ref();
const router = useRouter();
const valueChoose = ref(null);
const breadcrumbHome = { icon: "pi pi-home", to: "/" };
const breadcrumbItems = [
  { label: "Search Item Jit", to: "./trxcostlist" },
];
// Toast intance
const toast = useToast();
const items = ref([
            {
                label: 'All Columns',
                command: () => {
                    toast.add({severity:'success', summary:'Change Search', detail:'Search for All Columns', life: 3000});
                    search.value = 'All Columns'
                    valueChoose.value = null
                }
            },
            {
                label: 'Item Number',
                command: () => {
                    toast.add({severity:'success', summary:'Change Search', detail:'Search for Item Number Columns', life: 3000});
                    search.value = 'Item Number'
                    valueChoose.value = 'itemnumber'
                }
            },
            {
                label: 'Description',
                command: () => {
                    toast.add({severity:'success', summary:'Change Search', detail:'Search for Description Columns', life: 3000});
                    search.value = 'Description'
                    valueChoose.value = 'description'
                }
            },
            {   label: 'UoM',
                command: () => {
                    toast.add({severity:'success', summary:'Change Search', detail:'Search for UoM Columns', life: 3000});
                    search.value = 'UoM'
                    valueChoose.value = 'uom'
				}
            },
            {   label: 'Pack Size',
                command: () => {
                    toast.add({severity:'success', summary:'Change Search', detail:'Search for Pack Size Columns', life: 3000});
                    search.value = 'Pack Size'
                    valueChoose.value = 'packsize'
				}
            },
            {   label: 'Category',
                command: () => {
                    toast.add({severity:'success', summary:'Change Search', detail:'Search for Category Columns', life: 3000});
                    search.value = 'Category'
                    valueChoose.value = 'category'
				}
            },
            {   label: 'Vendor Name',
                command: () => {
                    toast.add({severity:'success', summary:'Change Search', detail:'Search for Vendor Name Columns', life: 3000});
                    search.value = 'Vendor Name'
                    valueChoose.value = 'vendorname'
				}
            },
            {   label: 'Rop Vendor',
                command: () => {
                    toast.add({severity:'success', summary:'Change Search', detail:'Search for Rop Vendor Columns', life: 3000});
                    search.value = 'ROP Vendor'
                    valueChoose.value = 'ropvendor'
				}
            },
            {   label: 'Item Type',
                command: () => {
                    toast.add({severity:'success', summary:'Change Search', detail:'Search for Item Type Columns', life: 3000});
                    search.value = 'Item Type'
                    valueChoose.value = 'itemtype'
				}
            },
        ]);

onMounted(() => {
    populateData();
})

const populateData = () => {
    AuthService.whoAmi().then(async (response) => {
        if (response.status === 200){
            checkTenant()
            reloadTable.value++
        }
    })
}

const checkTenant = async () => {
	AuthService.whoAmi().then(async (response) => {
		const userCompany = () => {
			const secretKey = 'some-unique-key';
			const simpleCrypto = new SimpleCrypto(secretKey);
			return simpleCrypto.decrypt(
				sessionStorage.getItem('dropdownCompany')
			);
		};
		if (response.status === 200) {
			userInfo.value = response.data.data;
			levelTenant.value = response.data.data.leveltenant;
			if (userInfo.value.leveltenant === 2) {
				//get company
                isSupplierpage.value = true;
				const respCbN = await LovService.getCompanyByName(
					userCompany()
				);
				company.value = respCbN.data.data[0];
				//get supplier
				const param = {
					db_instance: company.value.db_instance,
					org_id: company.value.value,
				};

				const checkAuth = await filterAuthByVendorname({
          info_id: userInfo.value.id,
          dbInstance: param.db_instance,
          value: param.org_id
        });

        if(checkAuth.supplier === null) router.push("/dashboard");

        supplier.value = checkAuth.supplier;
			} else {
				const respCbN = await LovService.getCompanyByName(
					userCompany()
				);
				company.value = respCbN.data.data[0];
			}
		}
	});
};

const handleSearch = async () => {
    reloadTable.value++
}

const clearFilter = async () => {
    searchValue.value = null
    reloadTable.value++
}

const createItemJit = () => {
	router.push({
		name: "DetailItemJit",
		params: {
		id: 0,
		},
	});
}

</script>