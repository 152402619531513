<template>
    <div>
    <AutoComplete :suggestions="filteredItemNumber" @complete="searchItemNum($event)" :dropdown="true" field="item_number" v-model="itemNumber" placeholder="Item Number" :disabled="loading" forceSelection>
        <template #item="slotProp">
        {{ slotProp.item.item_number }}
        </template>
    </AutoComplete>
    </div>
</template>

<script setup>
import {onMounted, ref, watchEffect, defineEmits} from 'vue'
import AuthService from '../../../service/AuthService';
import SpecSrc from '../../../service/SpecSrc';

// eslint-disable-next-line no-undef
const props = defineProps({
    company: {
        type: Object
    },
    supplier: {
        type: Object
    },
    status: {
        type: Object
    },
    item_desc: {
        type: Object
    },
    preparer: {
        type: Object
    },
});

// eslint-disable-next-line no-undef
const itemNumber = ref();
const itemNumberList = ref();
const filteredItemNumber = ref();
const loading = ref(false);
const emit = defineEmits(['itemNumber'])

watchEffect(() => {
    emit("itemNumber", itemNumber)
})


onMounted( async ()=>{
   await populateData()
})

const populateData = async () => {
  AuthService.whoAmi().then(async (response) => {
    loading.value = true
    if (response.status === 200){
        if (props.company !== null){
            const payload = {
                db_instance : props.company?props.company.db_instance:'',
                org_id : props.company?props.company.value:'',
                vendor_id : props.supplier?props.supplier.vendor_id:'',
                status : props.status?props.status.status:'',
                item_desc : props.itemDesc?props.itemDesc.item_desc:'',
                }
            const res = await SpecSrc.getItemNuber(payload)
            if (res == undefined || res.status === null){
              setTimeout( async () => {
                await populateData()
              }, 1000);
            } else if (res.status === 200) {
              itemNumberList.value = res.data.data;
              loading.value = false;
            } else { 
              itemNumberList.value = {item_number : 'Not Found'};
              loading.value = false;
            }
        }
    } else {
      itemNumberList.value = {item_number : 'Not Found'}
      loading.value = false
    }
    
  })
}

const searchItemNum = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredItemNumber.value = [...itemNumberList.value];
    } else {
      filteredItemNumber.value = itemNumberList.value.filter((comp) => {
        return comp.item_number
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};

</script>