<template>
	<div class="grid">
		<div class="col-12 p-fluid">
			<div v-if="isSupplier" class="col-12 mb-5">
				<h4>Register Outstanding Order</h4>
				<hr />
				<p>Date: {{currentDate}}</p>
				<br>
				<!-- <p><strong>Planning Order #:</strong> {{planningOrderNum}}, {{planningOrderDate}}</p> -->
				<DataTable :value="rooTable" dataKey="pr_line_num" v-model:filters="filters"
					v-model:selection="selectedRoo" removableSort :loading="loading" showGridlines>
					<Column class="call-button-container" header="PO Number" field="SEGMENT1"></Column>
					<Column class="call-button-container" header="Line" field="LINE_NUM"></Column>
					<Column class="call-button-container" header="Item Number" field="ITEM_NUMBER"></Column>
					<Column class="call-button-container" header="Item Description" field="ITEM_DESCRIPTION"></Column>
					<Column class="call-button-container" header="UOM" field="UOM"></Column>
					<Column class="call-button-container" header="Qty Order" field="QUANTITY"></Column>
					<Column class="call-button-container" header="Qty Open" field="QUANTITY_OPEN"></Column>
					<Column class="call-button-container" header="Arrival at Site" field="PROMISED_DATE"></Column>
					<Column class="call-button-container" header="Est. Arrival at Site" field="ESTIMATE_SUPPLIER_DATE"></Column>
					<Column class="call-button-container" header="Reason for not Ontime" field="REMARKS">
					</Column>
					<Column class="call-button-container" header="Ship To" field="SHIP_TO_NAME">
					</Column>
					<Column class="call-button-container" header="L/O/E" field="LOE"></Column>
					<Column class="call-button-container" header="Last Confirm Time" field="CONFIRM_DATE"></Column>
				</DataTable>
				<br>				
			</div>
			<div v-if="!isSupplier" class="col-12 mb-5">
				<h4>Register Outstanding Order</h4>
				<hr />
				<p>Date: {{currentDate}}</p>
				<br>
				<!-- <p><strong>Planning Order #:</strong> {{planningOrderNum}}, {{planningOrderDate}}</p> -->
				<DataTable :value="rooTable" dataKey="pr_line_num" v-model:filters="filters"
					v-model:selection="selectedRoo" removableSort :loading="loading" showGridlines>
					<Column class="call-button-container" header="PO Number" field="SEGMENT1"></Column>
					<Column class="call-button-container" header="Supplier" field="VENDOR_NAME"></Column>
					<Column class="call-button-container" header="Line" field="LINE_NUM"></Column>
					<Column class="call-button-container" header="Item Number" field="ITEM_NUMBER"></Column>
					<Column class="call-button-container" header="Item Description" field="ITEM_DESCRIPTION"></Column>
					<Column class="call-button-container" header="UOM" field="UOM"></Column>
					<Column class="call-button-container" header="Qty Order" field="QUANTITY"></Column>
					<Column class="call-button-container" header="Qty Open" field="QUANTITY_OPEN"></Column>
					<!-- <Column class="call-button-container" header="Arrival at Site" field="ARRIVAL_AT_SITE"></Column>
					<Column class="call-button-container" header="Est. Arrival at Site" field="EST_ARRIVAL_AT_SITE"></Column>
					<Column class="call-button-container" header="Reason for not Ontime" field="REASON_FOR_NOT_ONTIME">
					</Column> -->
					<Column class="call-button-container" header="Ship To" field="SHIP_TO_NAME">
					</Column>
					<Column class="call-button-container" header="L/O/E" field="LOE"></Column>
					<!-- <Column class="call-button-container" header="Last Confirm Time" field="LAST_CONFIRM_TIME"></Column> -->
				</DataTable>
				<br>
			</div>

			<div class="p-fluid grid formgrid" v-if="!hideButton">
				<div class="col-6 mb-2 ml-2 lg:col-1 lg:mb-0">
					<Button type="button" @click="closeWindow()" label="Close Window" class="p-button-secondary" />
				</div>
				<div class="col-6 mb-2 lg:col-1 lg:mb-0">
					<Button type="button" @click="exportExcel()" label="Export to Excel" class="p-button-success" />
				</div>
				<div class="col-6 mb-2 lg:col-1 lg:mb-0">
					<Button type="button" @click="printNow()" label="Print" class="p-button-primary" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	/* eslint-disable */
	import Roo from "../service/RooServices"
	import AuthService from '../service/AuthService'
	import XLSX from 'xlsx'
	export default {
	name: 'planningorder-list',
	data() {
		return {
			rooTable: null,
			paramsPrint: sessionStorage.getItem('paramsPrintROO'),
			currentDate: new Date(),
			hideButton: false,
			isSupplier: true,
			modifiedData: [],
		};
	},
	created() {
		let today = new Date();
		let dd = String(today.getDate()).padStart(2, '0');
		let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		let yyyy = today.getFullYear();
		this.combinedDate = dd + mm + yyyy;

		document.title = "Register Outstanding Order" + '_' + this.combinedDate

		AuthService.whoAmi().then(response => {
			if (response.status === 200) {
				// response.data.data.leveltenant = 1
				// Roo.printROO(this.paramsPrint).then(respond => {
				// 	let respData = respond.data.data[0]
				// 	console.log(respond.dataspData, 'DATA')
				// 	// for (const i in respData) {
				// 	// 	if(response.data.data.leveltenant == 2) {
				// 	// 		delete respData[i].SUPPLIER
				// 	// 	}
				// 	// 	// let arrivalSite = respData[i].ARRIVAL_AT_SITE
				// 	// 	// let estArrivalSite = new Date(respData[i].EST_ARRIVAL_AT_SITE)
				// 	// 	// let confirmDate = respData[i].LAST_CONFIRM_TIME
				// 	// 	// let convArrivSite = new Date(arrivalSite)
				// 	// 	// let convconfirmDate = new Date(confirmDate)
				// 	// 	// respData[i].ARRIVAL_AT_SITE = (convArrivSite.getDate() + '-' + convArrivSite.toString().substring(4, 7) + '-' + convArrivSite.getFullYear()).toUpperCase()
				// 	// 	// respData[i].EST_ARRIVAL_AT_SITE = (estArrivalSite.getDate() + '-' + estArrivalSite.toString().substring(4, 7) + '-' + estArrivalSite.getFullYear()).toUpperCase()
				// 	// 	// respData[i].QUANTITY = respData[i].QUANTITY
				// 	// 	// if(respData[i].QUANTITY_OPEN !== null) {
				// 	// 	// 	respData[i].QUANTITY_OPEN = respData[i].QUANTITY_OPEN
				// 	// 	// }
				// 	// 	// // respData[i].LAST_CONFIRM_TIME = (convconfirmDate.getDate() + '-' + convconfirmDate.toString().substring(4, 7) + '-' + convconfirmDate.getFullYear()).toUpperCase()
				// 	// 	// if(respData[i].REASON_FOR_NOT_ONTIME === null || respData[i].REASON_FOR_NOT_ONTIME === 'null') {
				// 	// 	// 	respData[i].REASON_FOR_NOT_ONTIME = ''
				// 	// 	// }
				// 	// }
				// 	this.rooTable = respData
				// 	if(this.rooTable.length < 1) {
				// 		window.alert('No data found')
				// 		window.close()
				// 	} 
				// })

				Roo.printROO(this.paramsPrint).then(respond => {
					let respData = respond.data.data
					let arrayData = [];
					for (const po of respData) {
						// if(response.data.data.leveltenant == 2) {
						// 	delete respData[i].SUPPLIER
						// }

						po.PROMISED_DATE = new Date(
							po.PROMISED_DATE
						).toLocaleDateString('id-ID', {
							year: 'numeric',
							month: 'short',
							day: 'numeric',
						});

						po.ESTIMATE_SUPPLIER_DATE = new Date(
							po.ESTIMATE_SUPPLIER_DATE
						).toLocaleDateString('id-ID', {
							year: 'numeric',
							month: 'short',
							day: 'numeric',
						});

						po.CONFIRM_DATE = po.CONFIRM_DATE == null ? '-' : new Date(
							po.CONFIRM_DATE
						).toLocaleDateString('id-ID', {
							year: 'numeric',
							month: 'short',
							day: 'numeric',
						});
						
						const obj = {
							'PO Number': po.SEGMENT1,
							'Line': po.LINE_NUM,
							'Item Number': po.ITEM_NUMBER,
							'Item Description': po.ITEM_DESCRIPTION,
							'UOM': po.UOM,
							'Qty Order': po.QUANTITY,
							'Qty Open': po.QUANTITY_OPEN,
							'Arrival at Site': po.PROMISED_DATE,
							'Est. Arrival at Site': po.ESTIMATE_SUPPLIER_DATE,
							'Reason for not Ontime': po.REMARKS,
							'Ship To': po.SHIP_TO_NAME,
							'L/O/E': po.LOE,
							'Last Confirm Time': po.CONFIRM_DATE
						};

						arrayData.push(obj);
					}



					this.modifiedData = arrayData;
					this.rooTable = respData

					// if(this.rooTable.length < 1) {
					// 	window.alert('No data found')
					// 	window.close()
					// } 
				})

				
				if (response.data.data.leveltenant == 1) {
					this.isSupplier = false
				} else {

				}
			}

		})
	},
	mounted() {
		// PlanningOrder.getAllCurrencies().then(value => {
		//     this.currenciesList = value.data.data;
		// });
	},
	computed: {
		// respondDate(){
		//     const date = new Date()
		//     date.setDate(date.respondBy()+ 1)
		//     return date
		// }
	},
	methods: {
		formatCurrency(value) {
			if (value) return value.toLocaleString('id-ID');
			return;
		},
		printNow() {
			this.hideButton = true
			setTimeout(this.actualPrintNow, 300)

		},
		actualPrintNow() {
			if (this.hideButton === true) {
				window.print()
				if (document.hasFocus()) {
					this.hideButton = false
				}
			}
		},
		closeWindow() {
			window.close()
		},
		exportExcel() {
			let filename = "Register Outstanding Order" + '_' + this.combinedDate + '.xlsx';
			let data = this.modifiedData;
			let ws = XLSX.utils.json_to_sheet(data);
			let wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "Register Outstanding Order");
			XLSX.writeFile(wb, filename);
		},

	}
}

</script>