<template>
	<DataTable
		:value="batchList"
		:lazy="true"
		:paginator="true"
		:rows="10"
		:loading="loading"
		:totalRecords="totalData"
		:scrollable="true"
		scrollHeight="600px"
		scrollDirection="both"
		@page="onPage($event)"
		@sort="onSort($event)"
		:rowsPerPageOptions="[10, 20, 30]"
		responsiveLayout="scroll"
		:sortable="true"
		:rowId="null"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #header>
			<div>
				<span class="p-input-icon-left">
					<i class="pi pi-search" />
					<InputText
						v-model="searchReportPayment"
						v-tooltip.right="'Please Press Enter to Search'"
						placeholder="Search Invoice Payment"
						@keyup.enter="populateTable()"
						@page="onPage($event)"
					/>
				</span>
			</div>
		</template>
		<template #empty>Invoice Payment Not found</template>
		<template #loading>Loading data, please wait...</template>
		<Column header="Beneficiary Name" field="BENEFICARY_NAME" style="width:20%;"> </Column>
		<Column header="Invoice Amount" field="INVOICE_AMOUNT" style="width:10%;">
			<template #body="{ data }">
				{{ formatCurrency(data.INVOICE_AMOUNT) }}
			</template>
		</Column>
		<Column header="Description" field="DESCRIPTION"  style="width:25%;"> </Column>
		<Column header="Bank Name" field="BANK_NAME"  style="width:10%;"> </Column>
		<Column header="Bank Acc Num" field="BANK_ACCOUNT_NUMBER"  style="width:15%;"> </Column>
		<Column header="Currency " field="CURRENCY_CODE" style="width:10%;"> </Column>
		<Column header="Payment Date " field="PAYMENT_DATE"  style="width:10%;"> </Column>
		<Column header="Payment Number" field="PAYMENT_NUMBER"  style="width:10%;"> </Column>
		<Column header="Invoice Date" field="INVOICE_DATE"  style="width:10%;"> </Column>
		<Column header="Payment Date" field="PAYMENT_DATE"  style="width:10%;"> </Column>
		<Column header="Invoice Number" field="INVOICE_NUMBER"  style="width:15%;"> </Column>
	</DataTable>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import paymentService from '../../../service/ReportPayment';
import AuthService from '@/service/AuthService';
import PoFulfillment from '@/service/PoFulfillment';
import LovService from '@/service/LovService';

const company = ref();
const supplier = ref();

onMounted(async () => {
	company.value = props.company;
	const info = await AuthService.whoAmi();
	if (info.status === 200) {
		levelTenant.value = info.data.data.leveltenant;
		if (levelTenant.value !== 2) {
			loading.value = true;
			supplier.value = props.supplier;
			// loading.value = true
			await populateTable();
			for (var id of batchList.value) {
				id.PAYMENT_DATE = dateFormat(id.PAYMENT_DATE);
				id.INVOICE_DATE = dateFormat(id.INVOICE_DATE);
			}
		} else {
			if (company.value && !supplier.value) {
				const param = {
					db_instance: props.company.db_instance,
					org_id: props.company.value,
				};
				const respVendor = await PoFulfillment.getSuppBindComp(
					info.data.data.id
				);
				const vendorName = respVendor.data.data[0][0].vendorname;
				const respSuppName = await LovService.getSupplierName(param);
				if (respSuppName.status === 200) {
					const suppList = respSuppName.data.data;
					for (const s of suppList) {
						if (s.vendor_name === vendorName) {
							supplier.value = s;
							break;
						}
					}
				}
				await populateTable();
				for (var d of batchList.value) {
					d.PAYMENT_DATE = dateFormat(d.PAYMENT_DATE);
					d.INVOICE_DATE = dateFormat(d.INVOICE_DATE);
				}
			}
			// loading.value = true
		}
	}
});
// eslint-disable-next-line no-undef
const props = defineProps({
	dateFrom: {
		type: Object,
	},
	dateTo: {
		type: String,
	},
	company: {
		type: Object,
	},
	supplier: {
		type: Object,
	},
	type: {
		type: String,
	},
});

const batchList = ref();
// const totalData = ref(0);
const loading = ref(false);
const page = ref(1);
const rowsPerPage = ref(10);
const sortColumn = ref();
const sortType = ref();
const levelTenant = ref();
const tableData = ref([]);
// const batchList = ref();
const totalData = ref(0);
const searchReportPayment = ref();

const populateTable = async () => {
	loading.value = true;

	if (props.company !== null && props.company !== undefined) {
		const payload = {
			org_id: company.value.value ? company.value.value : null,
			db_instance: company.value.db_instance,
			suppValue: supplier.value ? supplier.value.vendor_id : null,
			dateFrom: props.dateFrom
				? formatDateFilter(new Date(props.dateFrom))
				: null,
			dateTo: props.dateTo
				? formatDateFilter(new Date(props.dateTo).setHours(23, 59, 59))
				: null,
			search: searchReportPayment.value
				? searchReportPayment.value
				: null,
			ppr_number: props.type ? props.type : null,
			page: page.value,
			rows: rowsPerPage.value,
		};

		tableData.value = await paymentService.paymentReport(payload);
		batchList.value = tableData.value.data.data.data;
		totalData.value = tableData.value.data.data.totalData;
	}

	loading.value = false;
	for (var date of batchList.value) {
		date.AMOUNT = formatCurrency(date.AMOUNT);
	}
};

const onPage = async (event) => {
	// loading.value = true
	page.value = event.page + 1;
	rowsPerPage.value = event.rows;
	await populateTable();
};

const onSort = async (event) => {
	// loading.value = true
	page.value = 1;
	rowsPerPage.value = event.rows;
	sortColumn.value = event.sortField;
	sortType.value = event.sortOrder;
	await populateTable();
};
// function formatDateDexa(d) {
// 	var date = new Date(d);
// 	var respondDate =
// 		('00' + (date.getMonth() + 1)).slice(-2) +
// 		'/' +
// 		('00' + date.getDate()).slice(-2) +
// 		'/' +
// 		date.getFullYear() +
// 		' ' +
// 		('00' + date.getHours()).slice(-2) +
// 		':' +
// 		('00' + date.getMinutes()).slice(-2) +
// 		':' +
// 		('00' + date.getSeconds()).slice(-2);

// 	return respondDate;
// }

function formatDateFilter(d) {
	var date = new Date(d);
	var respondDate =
		date.getFullYear() + '-' +
		('00' + (date.getMonth() + 1)).slice(-2) +
		'-' +
		('00' + date.getDate()).slice(-2) +
		' ' +
		('00' + date.getHours()).slice(-2) +
		':' +
		('00' + date.getMinutes()).slice(-2) +
		':' +
		('00' + date.getSeconds()).slice(-2);

	return respondDate;
}

function dateFormat(d) {
	var monthShortNames = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];
	var t = new Date(d);
	return (
		t.getDate() +
		' ' +
		monthShortNames[t.getMonth()] +
		' ' +
		t.getFullYear() +
		' '
	);
}

const formatCurrency = (value) => {
	if (value) return value.toLocaleString('id-ID');
	return;
};
</script>
<style scoped></style>
