<template>
	<div>
		<Card>
			<template #title>
				<span class="text-xl">Top 10 Latest Receipts</span>
			</template>

			<template #content>
				<DataTable
					:value="receipts"
					responsiveLayout="scroll"
					:loading="isLoading"
				>
					<Column field="PO_NUMBER" header="PO Number"></Column>
					<Column field="ITEM_NUMBER" header="Item Number"></Column>
					<Column
						field="TRANSACTION_DATE"
						header="Transaction Date"
					></Column>
				</DataTable>
			</template>
		</Card>
	</div>
</template>

<script>
import InvoiceSearchWPO from '../../service/InvoiceSearchWPO'
export default {
	data() {
		return {
			receipts: [],
			isLoading: true
		};
	},
	created() {
		setTimeout(()=>{
			InvoiceSearchWPO.getLastReceipt(sessionStorage.getItem('vendorId')).then(resp=>{
				if(resp.data.status == 200){
					this.receipts = resp.data.data
					this.isLoading= false;
				}
				for (var d of this.receipts) {
					d.TRANSACTION_DATE= this.dateFormat(d.TRANSACTION_DATE);
				}
			})
		}, 2000)
	},

	methods: {
		dateFormat(d) {
			var monthShortNames = [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec',
			];
			var t = new Date(d);
			return (
				t.getDate() +
				'-' +
				monthShortNames[t.getMonth()] +
				'-' +
				t.getFullYear() +
				''
			);
		},
	}
};
</script>
