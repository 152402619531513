<template>
  <Card>
    <template #title>
      <span class="text-xl">Open PO</span>
    </template>
    <template #content>
      <div class="flex justify-content-between">
        <!-- Status -->
        <div>
          <p class="font-bold mb-0 mb-4">Status</p>
          <p class="mb-3">New</p>
          <p class="mb-3">Confirmed</p>
          <p class="mb-3">Not Confirm</p>
          <p class="mb-3">Cancelled</p>
          <p class="mb-0">Close</p>
        </div>

        <!-- Total -->
        <div>
          <p class="font-bold mb-0 mb-4">Total</p>
          <!-- This have to following the order of modules name -->
          <p class="mb-3">{{ statusNew }}</p>
          <p class="mb-3">{{ statusConfirm }}</p>
          <p class="mb-3">{{ statusNotConfirm }}</p>
          <p class="mb-3">{{ statusCancel }}</p>
          <p class="mb-3">{{ statusClose }}</p>
        </div>
      </div>
    </template>
    <template #footer>
      <Button label="Link" class="p-button-link font-bold" @click="openModal()">
        <span class="mr-2">See details</span>
        <i class="pi pi-arrow-right"></i>
      </Button>
    </template>
  </Card>

  <!-- Modal -->
  <Dialog
    header="Open PO"
    v-model:visible="displayModal"
    :style="{ width: '90vw' }"
    :modal="true"
  >
    <div class="grid" :key="renderKey">
      <Toast />
      <div class="col-12 p-fluid">
        <div class="card">
          <div class="grid formgrid" style="margin: 1em 0 0 0;">
            <div class="col-12 mb-5 lg:col-3 lg:mb-5">
              <span class="p-float-label">
                <AutoComplete
                  v-model="company"
                  :suggestions="filteredCompany"
                  @complete="searchCompany($event)"
                  :dropdown="true"
                  field="name"
                  forceSelection
                  :disabled="!isSupplierPage && this.buttonDisable"
                >
                  <template #item="slotProps">
                    <div class="supplier-item">
                      <div class="ml-2">
                        {{ slotProps.item.name }}
                      </div>
                    </div>
                  </template>
                </AutoComplete>
                <label for="inputtext">Company</label>
              </span>
            </div>
            <div v-if="!isSupplierPage" class="col-12 mb-5 lg:col-3 lg:mb-5">
              <span class="p-float-label">
                <AutoComplete
                  v-model="supplier"
                  :suggestions="filteredSupplier"
                  @complete="searchSupplier($event)"
                  :dropdown="true"
                  field="vendor_name"
                  :disabled="companyValue"
                  forceSelection
                >
                  <template #item="slotProps">
                    <div class="supplier-item">
                      <div class="ml-2">
                        {{ slotProps.item.vendor_name }}
                      </div>
                    </div>
                  </template>
                </AutoComplete>
                <label for="inputtext">Supplier</label>
              </span>
            </div>
            <div class="col-12 mb-5 lg:col-3 lg:mb-5">
              <span class="p-float-label">
                <AutoComplete
                  v-model="planningOrder"
                  :suggestions="filteredPlanningOrder"
                  @complete="searchPoNumber($event)"
                  field="segment1"
                  forceSelection
                  :disabled="this.buttonDisable"
                >
                </AutoComplete>
                <label for="inputtext">PO Number</label>
              </span>
            </div>
            <div class="col-12 mb-5 lg:col-3 lg:mb-5">
              <span class="p-float-label">
                <AutoComplete
                  v-model="lov"
                  :suggestions="filteredLov"
                  @complete="searchLov($event)"
                  :dropdown="true"
                  field="name"
                  forceSelection
                  :disabled="this.buttonDisable"
                >
                  <template #item="slotProps">
                    <div class="lov-item">
                      <div class="ml-2">
                        {{ slotProps.item.name }}
                      </div>
                    </div>
                  </template>
                </AutoComplete>
                <label for="inputtext">Status</label>
              </span>
            </div>
            <div class="col-12 mb-2 lg:col-2 xl:col-1 lg:mb-5">
              <Button
                label="Go"
                @click="searchTableNested()"
                :disabled="this.buttonDisable"
              ></Button>
            </div>
            <div class="col-12 mb-2 lg:col-2 xl:col-1 lg:mb-5">
              <Button
                class="p-button-secondary"
                label="Clear"
                @click="clearFilter"
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <DataTable
        :value="nested"
        :lazy="true"
        :paginator="true"
        :rows="rows"
        v-model:expandedRows="expandedRows"
        ref="dt"
        dataKey="po_num"
        :totalRecords="totalRecords"
        :loading="loading"
        @page="onPage($event)"
        @sort="onSort($event)"
        removableSort
        :rowsPerPageOptions="[10, 20, 30]"
        responsiveLayout="scroll"
        :v-model="first"
      >
        <template #empty> {{ isEmpty }} </template>
        <Column :expander="true" headerStyle="width: 3rem" />
        <Column field="po_num" header="PO Number" :sortable="true"></Column>
        <template #expansion="slotProps">
          <div class="orders-subtable">
            <DataTable
              :value="slotProps.data.children"
              dataKey="itemnum"
              removableSort
              responsiveLayout="scroll"
            >
              <template #empty> {{ isEmpty }} </template>
              <Column header="Line" headerStyle="width:3em">
                <template #body="slotProps">
                  {{ slotProps.index + 1 }}
                </template>
              </Column>
              <!-- <Column field="id" header="Line" :sortable="true" style="text-align: center"></Column> -->
              <Column
                field="itemnum"
                header="Item Number"
                :sortable="true"
              ></Column>
              <Column
                field="itemdesc"
                header="Item Description"
                :sortable="true"
              ></Column>
              <Column
                field="uom"
                header="UOM"
                :sortable="true"
                style="text-align: center;"
              ></Column>
              <Column
                field="currency"
                header="Currency"
                :sortable="true"
                style="text-align: center;"
              >
              </Column>
              <Column
                header="Unit Price"
                :sortable="true"
                dataType="numeric"
                style="text-align: center;"
                ><template #body="{ data }">
                  {{ formatCurrency(data.uprice) }}
                </template>
              </Column>
              <!-- <Column
							field="uprice"
							header="Uwdawdasdawe"
							:sortable="true"
							style="text-align: center"
						></Column> -->
              <Column
                field="QUANTITY"
                header="Quantity"
                :sortable="true"
                style="text-align: center;"
              >
              </Column>
              <Column
                header="Amount"
                :sortable="true"
                dataType="numeric"
                headerStyle="min-width:3rem;"
                style="text-align: center;"
              >
                <template #body="{ data }">
                  {{ formatCurrency(data.amount) }}
                </template></Column
              >
              <Column
                field="arrival"
                header="Arrival at Site"
                :sortable="true"
                style="text-align: center;"
              >
                <!-- <template #body="slotProps">
								{{
									new Date(
										slotProps.data.arrival
									).toLocaleString('id-ID', {
										day: 'numeric',
										month: 'short',
										year: 'numeric',
										hour: '2-digit',
										minute: '2-digit',
										second: '2-digit',
									})
								}}
							</template> -->
                <!-- <template #body="slotProps">
								{{ slotProps.data.arrival }}
							</template> -->
              </Column>
              <Column
                header="Est. Arrival at Site"
                :sortable="true"
                style="text-align: center;"
              >
                <template #body="slotProps">
                  <!-- {{
										new Date(
										slotProps.data.estarrival
									).toLocaleString('id-ID', {
										day: 'numeric',
										month: 'short',
										year: 'numeric',
										hour: '2-digit',
										minute: '2-digit',
										second: '2-digit',
									})
								}} -->

                  {{ slotProps.data.estarrival }}
                </template>
              </Column>
              <Column
                field="remarks"
                header="Note to Supplier"
                :sortable="true"
                style="text-align: center;"
              ></Column>
              <Column
                field="CONFIRM_DATE"
                header="Confirm Date"
                :sortable="true"
                style="text-align: center;"
              >
                <!-- <template #body="slotProps">
								{{
									new Date(
										slotProps.data.creation
									).toLocaleString('id-ID', {
										day: 'numeric',
										month: 'short',
										year: 'numeric',
									})
								}}
								{{ slotProps.data.shipment }}
							</template> -->
              </Column>
              <Column
                field="loe"
                header="L/O/E"
                :sortable="true"
                style="text-align: center;"
              ></Column>
              <Column
                field="status"
                header="Status"
                :sortable="true"
                style="text-align: center;"
              >
              </Column>

              <Column
                headerStyle="width:4rem"
                header="View"
                selectionMode="single"
              >
                <template #body="slotProps">
                  <Button
                    icon="pi pi-search"
                    class="p-button-rounded p-button-text"
                    @click="editChildren(slotProps.data)"
                  />
                </template>
              </Column>
            </DataTable>
          </div>
        </template>
      </DataTable>
    </div>
  </Dialog>
</template>
<script>
/* eslint-disable */
import AuthService from "../../../service/AuthService";
import NotificationService from "../../../service/NotificationService";
import LovService from "../../../service/LovService";
import SimpleCrypto from "simple-crypto-js";
import PoFulfillment from "../../../service/PoFulfillment";
export default {
  //  name: "poFulfillment-list",
  name: "planningorder-list",
  data() {
    return {
      displayModal: false,
      levelTenant: null,
      expandedRowGroups: null,
      isSupplierPage: false,
      first: 0,
      rowId: null,
      expandedRows: [],
      expandedRowGroups: [],
      nested: [],
      rows: 10,
      nestedchildren: [],
      groupdata: null,
      selectedChildren: null,
      loading: false,
      lazyParams: {},
      selectLazyParams: {},
      totalRecords: 0,
      totalRecordsGrup: 0,
      tabelexpand: null,
      company: null,
      companyValue: true,
      filteredCompany: null,
      isiCompany: null,
      supplier: null,
      isisupplier: null,
      filteredSupplier: null,
      selectedSupplier: null,
      supplierparse: null,
      search: null,
      filtered: null,
      preparer: null,
      isipreparer: null,
      filteredPreparer: null,
      preparerparse: null,
      selectedPreparer: null,
      lov: null,
      isilov: null,
      filteredLov: null,
      lovparse: null,
      selectedLov: null,
      planningOrder: null,
      isiPlanningOrder: null,
      filteredPlanningOrder: null,
      selectedPlanningOrder: null,
      loe: null,
      isiLoe: null,
      filteredLoe: null,
      button: 0,
      buttonDisable: true,
      buttonSearch: true,
      rowgroup: null,
      testname: null,
      onStorageUpdate: null,
      saveDb: null,
      preparerButton: true,
      suppBindToComp: null,
      isEmpty: null,
      orgId: null,
      companyDash: null,
      vendorId: null,
      statusNew: 0,
      statusConfirm: 0,
      statusNotConfirm: 0,
      statusCancel: 0,
      statusClose: 0,
      breadcrumbHome: { icon: "pi pi-home", to: "/" },
      breadcrumbItems: [
        { label: "Po Fulfillment", to: "/mgm/settings/sysparamslist" },
      ],
    };
  },
  watch: {
    company(newQuestion) {
      if (this.company != null) {
        sessionStorage.setItem("mailToCompany", this.company.name);
        this.planningOrder = null;
        this.lov = null;
        this.preparer = null;
        this.companyValue = false;
        this.preparerButton = false;
        if (
          typeof newQuestion === "object" &&
          !Array.isArray(newQuestion) &&
          newQuestion !== null
        ) {
          // this.getPlenno(newQuestion.name);
          this.companyValue = false;
          this.saveDb = this.company.db_instance;
          sessionStorage.setItem("companydb", this.saveDb);
          this.GetPreparer();
          this.getPlenno();
          if (sessionStorage.leveltenant == 2) {
            console.log('masuk comapny')
            this.getValueCompany(newQuestion.name);
          } else {
            this.buttonDisable = false;
            this.getValueCompany(newQuestion.name);
          }
        }
      }
    },
    supplier(newQuestion) {
      if (
        this.planningOrder == null &&
        this.lov == null &&
        this.preparer == null
      ) {
        this.lov = null;
        this.preparer = null;
        this.buttonSearch = false;
        if (
          typeof newQuestion === "object" &&
          !Array.isArray(newQuestion) &&
          newQuestion !== null
        ) {
          this.getValueSup(newQuestion.name);
          this.buttonDisable = false;
        }
      }
    },
    planningOrder(newQuestion) {
      if (this.supplier == null) {
        if (
          typeof newQuestion === "object" &&
          !Array.isArray(newQuestion) &&
          newQuestion !== null
        ) {
          // this.getValuePoNum(newQuestion.name);
          this.buttonDisable = false;
        }
      }
    },
    lov(newQuestion) {
      // if (this.supplier == null && this.planningOrder == null && this.preparer == null) {
      this.buttonDisable = false;
      // }
    },
    preparer(newQuestion) {
      // if (this.supplier == null && this.planningOrder == null && this.lov == null) {
      this.buttonDisable = false;
      // }
    },
    displayModal(newQuestion) {
      if (newQuestion === false){
        this.nested = null;
        this.totalRecords = null;
      }
    }
  },
  async created() {
    const resp = await AuthService.whoAmi();
    if (resp.data.data.leveltenant == 2) {
      const response = await LovService.getSuppBindComp(resp.data.data.id);
      this.vendorId = response.data.data[0].vendor_id;
      await this.OpenPOThisMonth();
    } else {
      const secretKey = "some-unique-key";
      const simpleCrypto = new SimpleCrypto(secretKey);
      this.companyDash = simpleCrypto.decrypt(
        sessionStorage.getItem("dropdownCompany")
      );
      await this.OpenPOThisMonth();
    }
  },
  mounted() {
    this.initOpenPo();
  },
  computed: {
    searchUser() {
      const searchLc = this.search.toLowerCase();
      return this.nested.filter((item) => {
        return item.po_num.toLowerCase().includes(searchLc);
      });
    },
  },
  methods: {
    getStatusNumbers() {
      NotificationService.getOpenPOTotal(this.suppName).then((res) => {});
    },
    openModal() {
      this.initOpenPo();
      // if (resp.data.data.leveltenant == 1) {
      this.displayModal = true;
      // } else {
      // window.alert('Work in progress')
      // }
    },
    async OpenPOThisMonth() {
      const secretKey = "some-unique-key";
      const simpleCrypto = new SimpleCrypto(secretKey);
      const decipherText = simpleCrypto.decrypt(
        sessionStorage.getItem("dropdownCompany")
      );
      let payload = {
        vendorId: this.vendorId,
        company: decipherText,
      };
      const res = await NotificationService.getOpenPOTotal(payload);
      this.statusNew = res.data.data[1][0].status_new;
      this.statusConfirm = res.data.data[2][0].status_confirm;
      this.statusNotConfirm = res.data.data[3][0].status_noconfirm;
      this.statusCancel = res.data.data[4][0].status_cancel;
      this.statusClose = res.data.data[5][0].status_close;
    },
    initOpenPo() {
      AuthService.whoAmi().then((resp) => {
        if (resp.status === 200) {
          this.getCompany();
          // this.GetPreparer();
          this.GetLOV();
          this.getPoNumber();
          this.GetLOVLoe();
          this.nested = null;
          this.totalRecords = null;
          if (resp.data.data.leveltenant == 2) {
            this.isSupplierPage = true;
            this.getValueCompany();
          }
          // this.isEmpty = 'Please Search for Data'
        }
      });
      this.isEmpty = "Please Search for Data";
      // this.loading = true;
      this.lazyParams = {
        first: 0,
        sortField: null,
        sortOrder: null,
        rows: this.rows,
      };
      this.selectLazyParams = {
        first: 0,
        sortField: null,
        sortOrder: null,
        rows: this.rows,
      };
      if (sessionStorage.companydb) {
        this.testname = sessionStorage.companydb;
      }
    },
    formatCurrency(value) {
      if (value) return value.toLocaleString("id-ID");
      return;
    },
    GetTableNested() {
      this.rows = 10;
      this.loading = true;
      AuthService.whoAmi().then((value) => {
        if (value.status === 200) {
          setTimeout(() => {
            PoFulfillment.GetTableNested({
              lazyEvent: JSON.stringify(this.lazyParams),
            }).then((response) => {
              this.nested = respond.data.header;
              this.totalRecords = response.data.totalRecords;
              this.loading = false;
              for (let id of this.nested) {
                for (let index of id.children) {
                }
              }

            });
          }, Math.random() * 1000 + 250);
        }
      });
    },

    async searchTableNested() {
      this.expandedRows = null;
      this.rows = 10;
      this.button = 1;
      this.loading = true;
      let supplierSearch = this.supplier;
      if (this.supplier !== null) {
        supplierSearch = this.supplier.vendor_id;
      }
      let poSearch = this.planningOrder;
      if (this.planningOrder !== null) {
        poSearch = this.planningOrder.segment1;
      }
      let statusSearch = this.lov;
      if (this.lov !== null) {
        statusSearch = this.lov.value;
      }
      let loeStatus = this.loe;
      if (this.loe !== null) {
        loeStatus = this.loe.value;
      }
      let preparerSearch = this.preparer;
      if (this.preparer !== null) {
        preparerSearch = this.preparer.employee_id;
      }
      let org_id = this.company;
      let db_instance = '';
      if (this.company !== null) {
        db_instance = this.company.db_instance;
        if (sessionStorage.leveltenant == 1) {
          org_id = this.company.value;
        }
        if (sessionStorage.leveltenant == 2) {
          org_id = this.company.value;
        }
      }
      const payload = {
        supplierSearch,
        poSearch,
        statusSearch,
        loeStatus,
        preparerSearch,
        org_id,
        db_instance
      };
      this.buttonDisable = true;
      AuthService.whoAmi().then((value) => {
        if (value.status === 200) {
          setTimeout(() => {
            PoFulfillment.getSearchTableNested({
              lazyEvent: JSON.stringify(this.selectLazyParams),
              payload: JSON.stringify(payload),
            }).then((respond) => {
              if (respond.data.status == 200) {
                this.nested = respond.data.header;
                this.totalRecords = respond.data.totalRecords;
                this.loading = false;
                this.buttonDisable = false;
                for (let id of this.nested) {
                  for (let index of id.children) {
                    var option = {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    };
                    index.arrival = new Date(index.arrival).toLocaleDateString(
                      "id-ID",
                      option
                    );
                    if (index.arrival == "Invalid Date") {
                      index.arrival = "";
                    }
                    index.estarrival = new Date(
                      index.estarrival
                    ).toLocaleDateString("id-ID", option);
                    if (index.estarrival == "Invalid Date") {
                      index.estarrival = "";
                    }
                    index.shipment = new Date(
                      index.shipment
                    ).toLocaleDateString("id-ID", option);
                    if (index.shipment == "Invalid Date") {
                      index.shipment = "";
                    }
                    index.creation = new Date(
                      index.creation
                    ).toLocaleDateString("id-ID", option);
                    if (index.creation == "Invalid Date") {
                      index.creation = "";
                    }
                    index.CONFIRM_DATE = new Date(
                      index.CONFIRM_DATE
                    ).toLocaleDateString("id-ID", option);
                    if (index.CONFIRM_DATE == "Invalid Date") {
                      index.CONFIRM_DATE = "";
                    }
                    index.QUANTITY = index.QUANTITY.toLocaleString("id-ID");
                    if (
                      index.remarks === null ||
                      index.remarks.includes("null")
                    ) {
                      index.remarks = "";
                    }

                  }
                }
              } else {
                this.nested = null;
                this.totalRecords = null;
                this.isEmpty = "No Data Found";
                this.loading = false;
                this.buttonDisable = false;
              }
            });
          }, Math.random() * 1000 + 250);
        }
      });
    },
    getCompany() {
      AuthService.whoAmi().then((value) => {
        if (value.status === 200) {
          if (value.data.data.leveltenant == 1) {
            if (value.data.data.userid == "dexaadmin") {
              LovService.getValueType("COMPANY").then((response) => {
                this.isiCompany = response.data.data;
              });
            } else {
              let payload = value.data.data.bioaddress;
              LovService.getcompanydb(payload).then((response) => {  // this.isiCompany = response.data.data
                // let payload = {isi : response.data.data[0].company}
                // PlanningOrder.getCompanyId(payload).then(response => {
                this.isiCompany = response.data.data;
                try {
                  const secretKey = "some-unique-key";
                  const simpleCrypto = new SimpleCrypto(secretKey);
                  const decipherText = simpleCrypto.decrypt(
                    sessionStorage.getItem("dropdownCompany")
                  );

                  this.isiCompany.forEach((element) => {
                    if (element.name === decipherText) {
                      this.company = element;
                      // this.searchTableNested();
                      throw "Break";
                    }
                  });
                } catch (e) {
                  if (e !== "Break") throw e;
                }
                // })
              });
            }
          } else if (value.data.data.leveltenant == 2) {
            let payload = value.data.data.id;
            // PlanningOrder.getCompanySupp(payload).then(
            // 	(response) => {
            // 		let company = [
            // 			{ name: response.data.data[0].company },
            // 		];
            // 		this.isiCompany = company;
            // 	}
            // );
            PoFulfillment.getCompanySupp(payload).then((response) => {
              let payload = response.data.data;
              let companyList = [];
              try {
                payload.forEach((element) => {
                  LovService.getCompanyByName(element.company).then(
                    (respond) => {
                      companyList.push(respond.data.data[0]);

                      const secretKey = "some-unique-key";
                      const simpleCrypto = new SimpleCrypto(secretKey);
                      const decipherText = simpleCrypto.decrypt(
                        sessionStorage.getItem("dropdownCompany")
                      );
                      // let companyList = JSON.parse(sessionStorage.getItem('list'))
                      // this.isiCompany = companyList;

                      if (respond.data.data[0].name == decipherText) {
                        this.company = respond.data.data[0];
                        // this.searchTableNested();
                      }
                    }
                  );
                });
                this.isiCompany = companyList;
              } catch (e) {
                if (e !== "Break") throw e;
              }
            });
          }
        }
      });
    },
    getValueCompany() {
      let payload = {
        db_instance: sessionStorage.companydb,
        org_id: this.company.value,
      };
      AuthService.whoAmi().then((value) => {
        if (value.status === 200) {
          LovService.getSupplierName(payload).then((response) => {
            this.isisupplier = response.data.data;
            if (sessionStorage.getItem("leveltenant") == 2) {
              try {
                this.isisupplier.forEach((element) => {
                  if (
                    element.vendor_id === this.vendorId &&
                    element.vendor_id !== null
                  ) {
                    this.supplier = element;
                    // this.searchTableNested();
                    
                    this.buttonDisable = false;
                    throw "Break";
                  }
                });
              } catch (e) {
                if (e !== "Break") throw e;
              }
            }
          });
        }
      });
    },
    async getPlenno() {
      const payload = {
        org_id: this.company.value,
      };
      AuthService.whoAmi().then((value) => {
        if (value.status === 200) {
          PoFulfillment.getSearchDropdownComp(payload).then((response) => {
            this.isiPlanningOrder = response.data.data;
          });
        }
      });
    },
    getValueSup() {
      const payload = {
        supValue: this.supplier.vendor_id,
        org_id: this.company.value,
      };
      AuthService.whoAmi().then((value) => {
        if (value.status === 200) {
          PoFulfillment.getSearchDropdownSupp(payload).then((response) => {
            this.isiPlanningOrder = response.data.data;
          });
        }
      });
    },
    getPoNumber() {
      AuthService.whoAmi().then((value) => {
        if (value.status === 200) {
          PoFulfillment.getPoNumber().then((response) => {
            this.isiPlanningOrder = response.data.data;
          });
        }
      });
    },
    getValuePoNum() {
      const payload = {
        poValue: this.planningOrder.plan_order_num,
      };
      AuthService.whoAmi().then((value) => {
        if (value.status === 200) {
          PoFulfillment.getSearchDropdownPoNum(payload).then((response) => {
            this.isisupplier = response.data.data;
          });
        }
      });
    },
    GetLOV() {
      AuthService.whoAmi().then((value) => {
        if (value.status === 200) {
          PoFulfillment.GetLovStatus().then((response) => {
            this.isilov = response.data.data;
            this.isilov.splice(3, 2);
          });
        }
      });
    },
    GetLOVLoe() {
      AuthService.whoAmi().then((value) => {
        if (value.status === 200) {
          PoFulfillment.GetLovStatusLoe().then((response) => {
            this.isiLoe = response.data.data;
          });
        }
      });
    },
    GetPreparer() {
      AuthService.whoAmi().then((value) => {
        if (value.status === 200) {
          let payload = {
            isi: sessionStorage.companydb,
          };
          PoFulfillment.GetPreparer(payload).then((response) => {
            this.isipreparer = response.data.data;
          });
        }
      });
    },
    getPlanningOrder() {
      AuthService.whoAmi().then((value) => {
        if (value.status === 200) {
          PoFulfillment.getPlanningOrder().then((response) => {
            this.isipreparer = response.data.data;
          });
        }
      });
    },
    searchSupplier(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredSupplier = [...this.isisupplier];
        } else {
          this.filteredSupplier = this.isisupplier.filter((item) => {
            if (item.vendor_name !== null) {
              return item.vendor_name
                .toLowerCase()
                .match(event.query.toLowerCase());
            }
          });
        }
      }, 250);
    },
    async searchCompany(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredCompany = [...this.isiCompany];
        } else {
          this.filteredCompany = this.isiCompany.filter((item) => {
            return item.name.toLowerCase().match(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchPoNumber(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredPlanningOrder = [...this.isiPlanningOrder];
        } else {
          this.filteredPlanningOrder = this.isiPlanningOrder.filter((item) => {
            if (item.segment1 !== null) {
              return item.segment1
                .toLowerCase()
                .match(event.query.toLowerCase());
            }
          });
        }
      }, 250);
    },
    searchLov(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredLov = [...this.isilov];
        } else {
          this.filteredLov = this.isilov.filter((item) => {
            return item.name.toLowerCase().match(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchLoe(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredLoe = [...this.isiLoe];
        } else {
          this.filteredLoe = this.isiLoe.filter((item) => {
            return item.name.toLowerCase().match(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchPreparer(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredPreparer = [...this.isipreparer];
        } else {
          this.filteredPreparer = this.isipreparer.filter((item) => {
            return item.user_name
              .toLowerCase()
              .match(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    onSort(event) {
      switch (true) {
        case this.supplier === null &&
          this.planningOrder === null &&
          this.lov === null &&
          this.preparer === null:
          this.lazyParams = event;
          // this.GetTableNested();
          break;
        case this.company !== null ||
          this.supplier !== null ||
          this.planningOrder !== null ||
          this.lov !== null ||
          (this.preparer !== null && this.button === 1):
          this.selectLazyParams = event;
          this.searchTableNested();
          break;
      }
    },
    editChildren(rowIndex) {
      this.rowId = rowIndex;
      if (sessionStorage.leveltenant == 2) {
        this.$router.push({
          name: "DetailpoFulfillmentSupp",
          params: {
            id: this.rowId.po_key,
          },
        });
      } else {
        this.$router.push({
          name: "DetailpoFulfillment",
          params: {
            id: this.rowId.po_key,
          },
        });
      }
    },
    onPage(event) {
      switch (true) {
        //nggak cari apa-apa
        case this.supplier === null &&
          this.planningOrder === null &&
          this.lov === null &&
          this.preparer === null &&
          this.button === 0:
          this.lazyParams = event;
          this.GetTableNested();
          break;
        //semuanya
        case this.company !== null ||
          this.supplier !== null ||
          this.planningOrder !== null ||
          this.lov !== null ||
          (this.preparer !== null && this.button === 1):
          this.selectLazyParams = event;
          this.searchTableNested();
          break;
      }
    },
    clearFilter() {
      // this.buttonSearch = true;
      AuthService.whoAmi().then((response) => {
        this.nested = null;
        if (response.data.data.leveltenant == 1) {
          if (response.data.data.userid == "dexaadmin") {
            this.expandedRows = null;
            this.planningOrder = null;
            this.supplier = null;
            this.company = null;
            this.buttonDisable = true;
            this.GetTableNested();
            this.lov = null;
            this.loe = null;
            this.preparer = null;
            this.button = 0;
            this.companyValue = true;
            this.preparerButton = true;
          } else {
            this.expandedRows = null;
            this.planningOrder = null;
            this.supplier = null;
            this.lov = null;
            this.loe = null;
            this.preparer = null;
            this.button = 0;
            this.getPlenno();
            // this.searchTableNested();
          }
        }
        if (response.data.data.leveltenant == 2) {
          this.expandedRows = null;
          this.planningOrder = null;
          // this.supplier = null;
          this.lov = null;
          this.loe = null;
          this.preparer = null;
          this.button = 0;
          // this.searchTableNested();
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
// ::selection{
//     background-color: rgba(0,0,0,.15) !important;
// }

p-selectable-row p-highlight {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

// input{
//     text-align: right;
// }
</style>
