<template>
  <div class="grid">
    <Toast />
    <ProgressModal :progress="progress">
      <template #message>
        Your Request in progress.<br />Please wait...
      </template>
    </ProgressModal>
    <div class="col-12">
      <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
    </div>
    <div class="col-12">
      <BlockUI :blocked="loadingData">
        <div class="content-wrapper mb-4">
          <div class="content-header">E-Invoice Summary</div>
          <div class="content-body">
            <div class="grid">
              <div class="col-12 md:col-12 lg:col-6">
                <div class="grid">
                  <div class="col-6">
                    <p id="label-key">Company</p>
                  </div>
                  <div class="col-6 col-auto ml-auto">
                    <p id="label-value">
                      {{ companyInInvoiceHeader ? companyInInvoiceHeader : "" }}
                    </p>
                  </div>
                </div>
                <div class="grid mb-5">
                  <div class="col-6">
                    <p id="label-key">Supplier</p>
                  </div>
                  <div class="col-6 col-auto ml-auto">
                    <p id="label-value">
                      {{
                        supplierInInvoiceHeader ? supplierInInvoiceHeader : ""
                      }}
                    </p>
                  </div>
                </div>
                <!-- <div class="mb-6" v-if="company && vendorId"> -->
                <div class="mb-6">
                  <span class="p-float-label">
                    <DropdownSupplierSite
                      class="w-full"
                      placeholder=" "
                      :disabled="isDisabled"
                      :dropdown="true"
                      :company="company"
                      :vendorId="vendorId"
                      :vendorSiteId="vendorSiteId"
                      :key="supplierSiteReload"
                      @item-select="getSupplierSite($event)"
                    />
                    <label for="inputtext">Supplier Site</label>
                  </span>
                </div>
                <div class="mb-6">
                  <hr />
                </div>
                <div class="mb-5">
                  <span class="p-float-label">
                    <InputText
                      :disabled="isDisabled"
                      v-model="invoiceNumber"
                      class="w-full mb-2 lg:mb-0"
                    />
                    <label for="inputtext">Invoice Number</label>
                  </span>
                </div>
                <div class="grid mb-5">
                  <div class="col-6">
                    <span class="p-float-label">
                      <Calendar
                        :disabled="isDisabled"
                        v-model="invoiceDate"
                        dateFormat="d-M-yy"
                        :showIcon="true"
                        class="w-full mb-3 lg:mb-0"
                      />
                      <label for="inputtext">Invoice Date</label>
                    </span>
                  </div>
                  <div class="col-6">
                    <span class="p-float-label">
                      <Calendar
                        :disabled="isDisabled"
                        v-model="glDate"
                        dateFormat="d-M-yy"
                        :showIcon="true"
                        class="w-full mb-3 lg:mb-0"
                      />
                      <label for="inputtext">GL Date</label>
                    </span>
                  </div>
                </div>
                <div class="mb-5">
                  <span class="p-float-label">
                    <Textarea
                      :disabled="isDisabled"
                      v-model="descriptionHeader"
                      rows="6"
                      class="w-full mb-3 lg:col-auto lg:mr-3 lg:mb-0"
                    />
                    <label for="inputtext">Description</label>
                  </span>
                </div>
                <div class="grid mb-5">
                  <div class="col-6">
                    <span class="p-float-label">
                      <InputText
                        :disabled="isDisabled"
                        v-model="taxInvoiceNo"
                        class="w-full mb-2 lg:mb-0"
                        @change="handleChangeTax($event)"
                      />
                      <label for="inputtext">Tax Invoice No</label>
                    </span>
                  </div>
                  <div class="col-6">
                    <span class="p-float-label">
                      <Calendar
                        :disabled="isDisabled"
                        v-model="taxInvoiceDate"
                        dateFormat="d-M-yy"
                        :showIcon="true"
                        class="w-full mb-3 lg:mb-0"
                      />
                      <label for="inputtext">Tax Invoice Date</label>
                    </span>
                  </div>
                </div>
                <div class="mb-5">
                  <span class="p-float-label">
                    <label for="FileUpload">Attachment</label>
                  </span>
                  <br />
                  <!-- <ButtonDownloadFile
                    v-if="documentFilename"
                    :link="documentLink"
                    :label="documentFilename"
                  /> -->
                  <Button
                    v-if="!loadingDownladFile && documentFilename"
                    class="button-download font-normal"
                    :label="documentFilename"
                    icon="pi pi-download"
                    @click="onDownload()"
                  />
                  <Button
                    v-if="loadingDownladFile"
                    class="button-download font-normal"
                    :label="`${documentFilename} is downloading`"
                    icon="pi pi-download"
                    :disabled="true"
                  />
                  <FileUpload
                    ref="fileUpload"
                    mode="advanced"
                    chooseLabel="Upload File"
                    fileLimit="1"
                    accept="application/pdf"
                    :maxFileSize="5000000"
                    :showUploadButton="false"
                    :showCancelButton="false"
                    :customUpload="true"
                    @select="onSelect"
                    @remove="onRemoveFile"
                    :disabled="isDisabled"
                  />
                </div>
              </div>
              <div class="col-12 md:col-12 lg:col-6">
                <div class="grid">
                  <div class="col-6">
                    <p id="label-key">Status</p>
                  </div>
                  <div class="col-6 col-auto ml-auto">
                    <p id="label-value">{{ status }}</p>
                  </div>
                </div>
                <div class="grid">
                  <div class="col-6">
                    <p id="label-key">Invoice Amount</p>
                  </div>
                  <div class="col-6 col-auto ml-auto">
                    <p id="label-value">
                      {{
                        totalLine
                          ? Number.parseFloat(totalLine)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : ""
                      }}
                    </p>
                  </div>
                </div>
                <div class="grid">
                  <div class="col-6">
                    <p id="label-key">Tax Amount</p>
                  </div>
                  <div class="col-6 col-auto ml-auto">
                    <p id="label-value">
                      {{
                        totalPPN
                          ? Number.parseFloat(totalPPN)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : ""
                      }}
                    </p>
                  </div>
                </div>
                <div class="grid">
                  <div class="col-6">
                    <p id="label-key">Total</p>
                  </div>
                  <div class="col-6 col-auto ml-auto">
                    <p id="label-value">
                      {{
                        totalInvoice
                          ? Number.parseFloat(totalInvoice)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : ""
                      }}
                    </p>
                  </div>
                </div>
                <div class="grid mb-3">
                  <div class="col-6">
                    <p id="label-key">Currency</p>
                  </div>
                  <div class="col-6 col-auto ml-auto">
                    <p id="label-value">{{ currency }}</p>
                  </div>
                </div>
                <div class="grid mb-4">
                  <!-- <div class="col-6" v-if="company && vendorId"> -->
                  <div class="col-6">
                    <span class="p-float-label">
                      <DropdownBillBank
                        :disabled="isDisabled"
                        class="w-full"
                        :dropdown="true"
                        placeholder=" "
                        :company="company"
                        :vendorId="vendorId"
                        :extBankAccountId="extBankAccountId"
                        :key="billBankReload"
                        @item-select="getRemitBank($event)"
                      />
                      <label for="inputtext">Remit to Bank Account</label>
                    </span>
                  </div>
                  <div class="col-6" v-if="company && vendorId">
                    <span class="p-float-label">
                      <DropdownPaymentMethod
                        :disabled="isDisabled"
                        class="w-full"
                        :dropdown="true"
                        placeholder=" "
                        :company="company"
                        :vendorId="vendorId"
                        :paymentMethod="paymentMethodCode"
                        :key="payMethodReload"
                        @item-select="getPaymentMethod($event)"
                      />
                      <label for="inputtext">Payment Method</label>
                    </span>
                  </div>
                </div>
                <div class="grid mb-4">
                  <div class="col-6" v-if="company && vendorId">
                    <span class="p-float-label">
                      <DropdownTerms
                        :disabled="isDisabled"
                        class="w-full"
                        :dropdown="true"
                        placeholder=" "
                        :company="company"
                        :vendorId="vendorId"
                        :termsId="termsId"
                        :key="termsReload"
                        @item-select="getTerms($event)"
                      />
                      <label for="inputtext">Terms</label>
                    </span>
                  </div>
                  <div class="col-6" v-if="company">
                    <span class="p-float-label">
                      <DropdownPaygroup
                        :disabled="isDisabled"
                        class="w-full"
                        :dropdown="true"
                        placeholder=" "
                        :company="company"
                        :key="payGroupReload"
                        @item-select="getPayGroup($event)"
                      />
                      <label for="inputtext">Pay Group</label>
                    </span>
                  </div>
                </div>
                <div class="mb-4">
                  <hr />
                </div>
                <div class="grid mb-5">
                  <div class="col-12 md:col-12 lg:col-9">
                    <span class="p-float-label">
                      <AutoComplete
                        v-model="referenceNumber"
                        class="w-full"
                        :dropdown="true"
                        placeholder=" "
                        :disabled="true"
                        forceSelection
                      >
                      </AutoComplete>
                      <label for="inputtext">Reference Number</label>
                    </span>
                  </div>
                  <div class="col-12 md:col-12 lg:col-3">
                    <Button
                      :disabled="true"
                      label="Find SK"
                      @click="update()"
                      class="p-button-secondary w-full mb-3 lg:w-full lg:mr-3 lg:mb-0"
                    />
                  </div>
                </div>
                <div class="mb-5">
                  <span class="p-float-label">
                    <DropdownCostCenter
                      :disabled="isDisabled"
                      class="w-full"
                      :dropdown="true"
                      placeholder=" "
                      :company="company"
                      :costCenterValue="costCenterCode"
                      :key="costCenterReload"
                      @item-select="getCostCenter($event)"
                    />
                    <label for="inputtext">Cost Center</label>
                  </span>
                </div>
                <div class="mb-5">
                  <span class="p-float-label">
                    <Textarea
                      :disabled="isDisabled"
                      v-model="rejectReason"
                      rows="6"
                      class="w-full mb-3 lg:col-auto lg:mr-3 lg:mb-0"
                    />
                    <label for="inputtext">Reject Reason</label>
                  </span>
                </div>
                <div class="mb-5">
                  <span class="p-float-label">
                    <Textarea
                      :disabled="true"
                      v-model="recomendatorReason"
                      rows="6"
                      class="w-full mb-3 lg:col-auto lg:mr-3 lg:mb-0"
                    />
                    <label for="inputtext">Recomendator Reason</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="content-header">Line</div>
          <div class="content-body">
            <div class="grid mb-3">
              <div class="col-3">
                <span class="p-float-label">
                  <!-- <AutoComplete
                    :disabled="isDisabled"
                    id="detailCategory"
                    class="w-full"
                    :suggestions="filteredCategory"
                    :dropdown="true"
                    field="category_detail"
                    forceSelection
                    v-model="category"
                    @complete="searchCategory($event)"
                    :key="categoryReload"
                  /> -->
                  <DropdownCategory
                    :db_instance="
                      dbInstanceInvoiceHeader ? dbInstanceInvoiceHeader : null
                    "
                    :categoryId="categorIdHeader ? categorIdHeader : null"
                    :company="company ? company : null"
                    :ref_num="referenceNumber ? referenceNumber : null"
                    :data="id ? id : null"
                    :supplier="vendorId ? vendorId : null"
                    @detailCategory="getDetailCat"
                    :disabled="displayStatus ? displayStatus : null"
                    :key="categoryReload"
                    v-model="detailCat"
                  />

                  <!-- <label for="detailCategory">Detail Category</label> -->
                </span>
              </div>
              <div class="col-auto ml-auto">
                <Button
                  class="p-button-success"
                  :disabled="isDisabled"
                  @click="addLine"
                >
                  <span class="uppercase font-bold"
                    ><i class="pi pi-plus" /> Add LINE</span
                  >
                </Button>
              </div>
            </div>
            <TableLineDetail
              :data="id ? id : null"
              :db_instance="
                dbInstanceInvoiceHeader ? dbInstanceInvoiceHeader : null
              "
              :org_id="orgIdInvoiceHeader ? orgIdInvoiceHeader : null"
              :line="line ? line : null"
              :company="company ? company : null"
              :vendor_id="vendorId ? vendorId : null"
              :supplierInInvoiceHeader="
                supplierInInvoiceHeader ? supplierInInvoiceHeader : null
              "
              :categoryHeader="categorIdHeader ? categorIdHeader : null"
              :detailCat="category ? category : null"
              :disabled="isDisabled ? isDisabled : null"
              :dataLine="dataLine ? dataLine : null"
              :invoiceDate="invoiceDate ? invoiceDate : new Date()"
              :costCenter="costCenterCode ? costCenterCode : null"
              :key="reloadTable"
              @tableList="getTableList"
            ></TableLineDetail>
          </div>
          <div v-if="!reimburseDiv" class="content-header">Reimburse</div>
          <div v-if="!reimburseDiv" class="content-body">
            <div class="grid mb-3">
              <div class="col-auto ml-auto">
                <Button
                  class="p-button-success"
                  :disabled="isDisabled"
                  @click="addLineReimburse"
                >
                  <span class="uppercase font-bold">Add REIMBURSE</span>
                </Button>
              </div>
            </div>
            <TableReimbursement
              v-if="!reimburseDiv && reimburseCategory"
              :reimburseCategory="reimburseCategory ? reimburseCategory : null"
              :company="company ? company : null"
              :data="id ? id : null"
              :line="lineReimburse ? lineReimburse : null"
              :dataReimbursement="dataReimbursement ? dataReimbursement : null"
              :costCenter="costCenterCode ? costCenterCode : null"
              :key="reloadTable"
              :disabled="isDisabled ? isDisabled : null"
              @tableList="getTableListReimburse"
            ></TableReimbursement>
          </div>
          <div class="content-body">
            <div class="grid">
              <div class="col-6 mb-2 lg:col-3 lg:mb-0">
                <Button
                  :disabled="isDisabled"
                  type="submit"
                  label="Save"
                  @click="save()"
                  class="w-full p-button-success"
                />
              </div>

              <div class="col-6 mb-2 lg:col-3 lg:mb-0">
                <Button
                  :disabled="isDisabled"
                  type="confirm"
                  label="Submit"
                  @click="submit()"
                  class="w-full p-button-warning"
                />
              </div>
              <div class="col-6 mb-2 lg:col-2 lg:mb-0">
                <Button
                  :disabled="isDisabled"
                  class="w-full p-button-error"
                  type="reject"
                  label="Reject"
                  @click="reject()"
                />
              </div>
              <div class="col-6 mb-2 lg:col-2 lg:mb-0">
                <Button
                  :disabled="isDisabled"
                  :class="uppercase"
                  class="w-full p-button-secondary"
                  label="Cancel"
                  @click="back()"
                >
                </Button>
              </div>
              <div class="col-6 mb-2 lg:col-2 lg:mb-0">
                <Button
                  :disabled="isDisabled"
                  :class="uppercase"
                  class="w-full p-button-info"
                  label="Download"
                  @click="exportToExcel"
                >
                </Button>
              </div>
            </div>
          </div>
        </div>
      </BlockUI>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import AuthService from "@/service/AuthService";
import { useRoute, useRouter } from "vue-router";
import SimpleCrypto from "simple-crypto-js";
import ProgressModal from "../../components/ProgressModal.vue";
import { useToast } from "primevue/usetoast";

import invoiceNonPOservice from "@/service/InvoiceNonPOservice";
import LovService from "@/service/LovService";
// import InvoiceCreateNonPOService from "../../service/InvoiceCreateNonPO";

import DropdownSupplierSite from "./components/DropdownSupplierSite.vue";
// import DropdownVAT from "./components/DropdownVAT.vue";
// import DropdownWHT from "./components/DropdownWHT.vue";
import DropdownBillBank from "./components/DropdownBillBank.vue";
import DropdownPaymentMethod from "./components/DropdownPaymentMethod.vue";
import DropdownTerms from "./components/DropdownTerms.vue";
import DropdownCostCenter from "./components/DropdownCostCenter.vue";
// import DropdownVendorReimburse from "./components/DropdownVendorReimburse.vue";
// import DropdownCoa from "./components/DropdownCoa.vue";
// import DropdownGroup from "./components/DropdownGroup.vue";
import TableLineDetail from "./components/TableLineDetail.vue";
import TableReimbursement from "./components/TableReimbursement.vue";
// import ButtonDownloadFile from "../../components/ButtonDownloadFile.vue";
import XLSX from "xlsx";
import DropdownPaygroup from "./components/DropdownPaygroup.vue";
import DropdownCategory from "./components/DropdownCategory.vue";

const breadcrumbHome = { icon: "pi pi-home", to: "/dashboard" };
const breadcrumbItems = ref([]);
const toast = useToast();
const router = useRouter();
const userInfo = ref();
const company = ref();
const dbInstance = ref();
// const supplier = ref();
const supplierReload = ref(0);
const disableButton = ref(true);
const route = useRoute();
// const router = useRouter();
const id = ref(route.params.id);
const status = ref();
const supplierSite = ref();
// const supplierSiteList = ref([]);
const invoiceAmount = ref();
const taxAmount = ref();
const totalAmount = ref();
const currency = ref();
const invoiceNumber = ref();
const invoiceDate = ref();
const glDate = ref();
const descriptionHeader = ref();
const taxInvoiceNo = ref();
const taxInvoiceDate = ref();
const remitToBankAccount = ref();
const extBankAccountId = ref();
const paymentMethod = ref();
const paymentMethodCode = ref();
const terms = ref();
const termsId = ref();
const referenceNumber = ref();
const costCenter = ref();
const costCenterCode = ref();
const rejectReason = ref();
const companyInInvoiceHeader = ref();
const supplierInInvoiceHeader = ref();
const vendorId = ref();
const vendorSiteId = ref();
const documentFilename = ref();
const documentLink = ref();
const categoryHeader = ref(null);
// const categoryDetail = ref(null);
const category = ref(null);
// const filteredCategory = ref(null);
const dataLine = ref(0);
const dataReimbursement = ref(0);
const supplierSiteReload = ref(0);
const billBankReload = ref(0);
const payMethodReload = ref(0);
const termsReload = ref(0);
const costCenterReload = ref(0);
const loadingData = ref(true);
const table = ref([]);
const totalLine = ref(0);
const totalPPN = ref();
const reimburseDiv = ref();
const totalInvoice = ref();
const line = ref(0);
const lineReimburse = ref(0);
const reloadTable = ref(0);
// const displayStatus = ref(false);
const tableReimburse = ref([]);
const categoryReload = ref(0);
const file = ref();
const progress = ref(false);
const isDisabled = ref(true);
const mapingId = ref();
const categorIdHeader = ref();
const emailSender = ref();
const payGroupReload = ref();
const payGroup = ref();
const reimburseCategory = ref();
const orgIdInvoiceHeader = ref();
const dbInstanceInvoiceHeader = ref();
const recomendatorReason = ref();
const detailCat = ref();
const acctsCodeCombinationId = ref();
const fileBuffer = ref();
const loadingDownladFile = ref(false);

watch(
  () => [
    company.value,
    vendorId.value,
    costCenterCode.value,
    dataLine.value,
    dataReimbursement.value,
    invoiceDate.value,
  ],
  () => {
    supplierSiteReload.value++;
    billBankReload.value++;
    costCenterReload.value++;
    reloadTable.value++;
    categoryReload.value++;
  }
);

onMounted(async () => {
  // Pre-fetch company and vendor
  // const getCategory = getCategoryVendor();
  // await Promise.all([getCategory]);
});

AuthService.whoAmi().then(async (response) => {
  if (response.status === 200) {
    const map = new Map();
    map.set("label", "Detail Invoice Non PO");
    map.set("to", "/mgm/monitor/detailInvoiceNonPOFinance/" + id.value);
    const breadcrumbItem = Object.fromEntries(map);
    breadcrumbItems.value[0] = breadcrumbItem;
    userInfo.value = response.data.data;
    emailSender.value = response.data.data.bioemailactive;
    // supplierInInvoiceHeader.value = response.data.data;
    const respCbN = await LovService.getCompanyByName(userCompany());
    company.value = respCbN.data.data[0];
    dbInstance.value = company.value.db_instance;
    supplierReload.value++;
    await getData();
    // await getCategoryVendor();
    disableButton.value = false;
  }
});

const userCompany = () => {
  const secretKey = "some-unique-key";
  const simpleCrypto = new SimpleCrypto(secretKey);
  return simpleCrypto.decrypt(sessionStorage.getItem("dropdownCompany"));
};

const getData = async () => {
  try {
    const resultData = await invoiceNonPOservice.getInvoiceNonPOById(id.value);
    const result = resultData.data;
    const resHaeder = result.dataHeader[0][0];
    const resDetail = result.dataDetail;
    const resReimbursement = result.dataReimbursement;
    const resDoc = result.dataDoc;
    // invoiceData.value = result[0];
    const type = typeof result;
    if (type === "object") {
      vendorSiteId.value = resHaeder.VENDOR_SITE_ID;
      vendorId.value = resHaeder.VENDOR_ID;
      invoiceAmount.value = resHaeder.INVOICE_AMOUNT;
      taxAmount.value = resHaeder.TAX_AMOUNT;
      totalAmount.value = resHaeder.TOTAL_AMOUNT;
      currency.value = resHaeder.CURRENCY_CODE;
      invoiceNumber.value = resHaeder.INVOICE_NO;
      invoiceDate.value = new Date(resHaeder.INVOICE_DATE);
      glDate.value = resHaeder.GL_DATE
        ? new Date(resHaeder.GL_DATE)
        : new Date();
      descriptionHeader.value = resHaeder.DESCRIPTION;
      taxInvoiceNo.value = resHaeder.TAX_NO;
      taxInvoiceDate.value = new Date(resHaeder.TAX_DATE);
      extBankAccountId.value = resHaeder.EXTERNAL_BANK_ACCOUNT_ID;
      paymentMethodCode.value = resHaeder.PAYMENT_METHOD_LOOKUP_CODE;
      termsId.value = resHaeder.TERMS_ID;
      referenceNumber.value = resHaeder.REFERENCE_NUMBER;
      costCenterCode.value = resHaeder.COST_CENTER;
      rejectReason.value = resHaeder.REASON;
      status.value = resHaeder.STATUS;
      if (status.value.toUpperCase() === "PROCESSED") {
        isDisabled.value = false;
      }
      acctsCodeCombinationId.value = resHaeder.ACCTS_CODE_COMBINATION_ID;
      mapingId.value = resHaeder.MAPING_ID;
      categorIdHeader.value = resHaeder.CATEGORY_ID;
      companyInInvoiceHeader.value = resHaeder.COMPANY_NAME;
      orgIdInvoiceHeader.value = resHaeder.ORG_ID;
      dbInstanceInvoiceHeader.value = resHaeder.DB_INSTANCE;
      supplierInInvoiceHeader.value = resHaeder.VENDOR_NAME;
      recomendatorReason.value = resHaeder.RECOMMENDATOR_REASON;

      dataLine.value = resDetail;
      dataReimbursement.value = resReimbursement;
      if (resReimbursement.length > 0) {
        reimburseDiv.value = false;
      } else {
        reimburseDiv.value = true;
      }
      documentFilename.value = resDoc.filename;
      documentLink.value = resDoc.url;
      await getCategoryById(categorIdHeader.value);
      // await getFileBase64(id.value);
    } else {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: `${result}`,
        life: 3000,
      });
    }
  } catch (error) {
    loadingData.value = false;
    throw new Error(error);
  }
  loadingData.value = false;
};

const getCategoryById = async (categoryId) => {
  const res = await invoiceNonPOservice.getcategorybyid(categoryId);
  categoryHeader.value = res.data.data[0];
};
// const getCategoryVendor = async () => {
//   if (company.value && vendorId.value) {
//     const payload = {
//       dbInstance: company.value.db_instance,
//       orgId: company.value.value,
//       vendorId: vendorId.value,
//     };

//     const res = await invoiceNonPOservice.getCategoryVendor(payload);


//     if (res.status === 200) {
//       categoryHeader.value = res.data.data.categoryHeader;

//       if (!isForwarder.value) {
//         categoryDetail.value = res.data.data.categoryDetail;
//       } else {
//         categoryDetail.value = [];
//       }
//       // reimburseCategory.value = categorIdHeader.value.filter((category) => {
//       //   category.category_name.toLowerCase().includes("REIMBURSTMENT")
//       // })
//     }
//   }
// };

// const searchCategory = async (event) => {
//   // isWarningShown.value = false;

//   if (categoryHeader.value) {
//     if (isForwarder.value) {
//       // Check if SK Number is selected
//       if (!referenceNumber.value) {
//         // isWarningShown.value = true;
//         filteredCategory.value = [];
//         return;
//       }

//       // Fetch category detail based on selected SK Number
//       const payload = {
//         categoryId: categoryHeader.value.category_id,
//         dbInstance: company.value.db_instance,
//         orgId: company.value.value,
//         skNumber: referenceNumber.value,
//       };
//       const res = await invoiceNonPOservice.getAvailableCategoryDetails(
//         payload
//       );
//       const categories = res.data.data;

//       if (!event.query.trim().length) {
//         filteredCategory.value = [...categories];
//       } else {
//         filteredCategory.value = categories.filter((category) => {
//           return category.category_detail
//             .toLowerCase()
//             .match(event.query.toLowerCase());
//         });
//       }
//     } else {
//       if (!event.query.trim().length) {
//         filteredCategory.value = [...categoryDetail.value];
//       } else {
//         filteredCategory.value = categoryDetail.value.filter((category) => {
//           return category.category_detail
//             .toLowerCase()
//             .match(event.query.toLowerCase());
//         });
//       }
//     }
//   }
// };

// const isForwarder = computed(
//   () =>
//     categoryHeader.value &&
//     categoryHeader.value.category_name.toLowerCase().includes("forwarder")
// );

const getDetailCat = (event) => {
  detailCat.value = event.value;
  category.value = event.value;
};

const getSupplierSite = (event) => {
  supplierSite.value = event.value;
  supplierSite.value.VENDOR_SITE_ID = event.value.vendor_site_id;
  supplierSite.value.CHANGE = true;
};

const getRemitBank = (event) => {
  remitToBankAccount.value = event.value;
};

const getPaymentMethod = (event) => {
  paymentMethod.value = event.value;
};

const getTerms = (event) => {
  terms.value = event.value;
};

const getPayGroup = (event) => {
  payGroup.value = event.value;
};

const getCostCenter = (event) => {
  costCenter.value = event.value;
  costCenterCode.value = event.value.SEGMENT4;
};

const getTableList = async (res) => {
  table.value = res.value;
  totalLine.value = 0;
  totalPPN.value = 0;
  totalInvoice.value = 0;
  let isReimburseExist = false;
  table.value.forEach((element) => {
    totalLine.value = totalLine.value + element.AMOUNT;
    let ppnPerLine = 0;
    if (element.PERCENTAGE_RATE) {
      // totalPPN.value = (totalLine.value * element.PERCENTAGE_RATE) / 100;
      ppnPerLine = (element.AMOUNT * element.PERCENTAGE_RATE) / 100;
    } else {
      ppnPerLine = 0;
    }
    if (
      element.ITEM_DESCRIPTION.toUpperCase().includes("REIMBURSEMENT") ||
      element.ITEM_DESCRIPTION.toUpperCase().includes("REIMBURSTMENT")
    ) {
      isReimburseExist = true;
      reimburseCategory.value = element;
      // } else if (element.PERCENTAGE_RATE === null) {
      //   totalPPN.value = 0;
    }
    if (element.PERCENTAGE_RATE === undefined) {
      totalPPN.value = taxAmount.value;
    } else {
      totalPPN.value = totalPPN.value + ppnPerLine;
    }
    console.log("total ppn", totalPPN.value);
  });
  totalInvoice.value = totalLine.value + totalPPN.value;
  if (isReimburseExist) {
    reimburseDiv.value = false;
  } else {
    reimburseDiv.value = true;
    tableReimburse.value = [];
  }
};

const addLine = () => {
  if (category.value) {
    line.value++;
  } else {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Please Choose Category",
      life: 3000,
    });
    progress.value = false;
  }
};

const addLineReimburse = () => {
  lineReimburse.value++;
};

const getTableListReimburse = (res) => {
  tableReimburse.value = res.value;
};

const onSelect = (event) => {
  file.value = event.files[0];
  return file.value;
};
const onRemoveFile = (event) => {
  if (!event.files.length) {
    file.value = null;
  }
};

const save = async () => {
  progress.value = true;
  let errors = [];
  const dateOptions = { year: "numeric", month: "short", day: "numeric" };

  if (!invoiceNumber.value) {
    errors.push("Invoice Number is Empty");
  }
  if (!invoiceDate.value) {
    errors.push("Invoice Date is Not Selected");
  }
  if (!glDate.value) {
    errors.push("GL Date is Not Selected");
  }
  if (!taxInvoiceNo.value) {
    errors.push("Tax Invoice No is Empty");
  }
  if (!taxInvoiceDate.value) {
    errors.push("Tax Invoice Date is Not Selected");
  }
  if (!descriptionHeader.value) {
    errors.push("Description is Empty");
  }
  if (!supplierSite.value) {
    if (!vendorSiteId.value) {
      errors.push("Supplier Site is Not Selected");
    }
  }
  if (!paymentMethod.value) {
    if (!paymentMethodCode.value) {
      errors.push("Payment Method is Not Selected");
    }
  }
  if (!terms.value) {
    if (!termsId.value) {
      errors.push("Terms is Not Selected");
    }
  }
  if (!remitToBankAccount.value) {
    if (!extBankAccountId.value) {
      errors.push("Remit To Bank Account is Not Selected");
    }
  }
  if (!costCenter.value) {
    if (!costCenterCode.value) {
      errors.push("Cost Center is Not Selected");
    }
  }

  if (table.value) {
    table.value.forEach((element, index) => {
      if (!element.ITEM_DESCRIPTION) {
        errors.push(`Description in data line ${index + 1} is Empty`);
      }
      if (!element.AMOUNT) {
        errors.push(`Amount in data line ${index + 1} is Empty`);
      }
      if (!element.COA_ID) {
        errors.push(`Account in data line ${index + 1} is Empty`);
      }
    });
  }

  if (tableReimburse.value.length > 0) {
    tableReimburse.value.forEach((element, index) => {
      // if (!element.VENDOR_ID) {
      //   errors.push(
      //     `Vendor in data reimbursement ${index + 1} is Not Selected`
      //   );
      // }
      if (!element.TAX_NO) {
        errors.push(
          `No. Faktur Pajak in data reimbursement ${index + 1} is Empty`
        );
      }
      if (!element.TAX_DATE) {
        errors.push(
          `Tgl Faktur Pajak in data reimbursement ${index + 1} is Not Selected`
        );
      }
      if (!element.AMOUNT) {
        errors.push(`Nilai Pajak in data reimbursement ${index + 1} is Empty`);
      }
      if (!element.COA_ID) {
        errors.push(`Account in data reimbursement ${index + 1} is Empty`);
      }
      if (!element.GROUP_NAME) {
        errors.push(`Group in data reimbursement ${index + 1} is Not Selected`);
      }
    });
  }

  const payload = {
    header: {
      EINVOICE_ID: parseInt(id.value),
      INVOICE_NO: invoiceNumber.value,
      INVOICE_DATE: formattingDate(invoiceDate.value),
      REVISION_NUM: 0,
      TAX_NO: taxInvoiceNo.value,
      TAX_DATE: formattingDate(taxInvoiceDate.value),
      DESCRIPTION: descriptionHeader.value,
      CURRENCY_CODE: currency.value,
      VENDOR_ID: vendorId.value,
      INVOICE_AMOUNT: totalLine.value,
      TAX_AMOUNT: totalPPN.value,
      TOTAL_AMOUNT: totalInvoice.value,
      ORG_ID: parseInt(company.value.value),
      STATUS: status.value,
      LAST_UPDATE_DATE: formattingDate(new Date(), true),
      LAST_UPDATED_BY: userInfo.value.id,
      PAYMENT_METHOD_LOOKUP_CODE: paymentMethod.value
        ? paymentMethod.value.PAYMENT_METHOD
        : paymentMethodCode.value,
      GL_DATE: formattingDate(glDate.value),
      TERMS_ID: terms.value ? terms.value.TERM_ID : termsId.value,
      REASON: rejectReason.value ? rejectReason.value : null,
      ACCTS_CODE_COMBINATION_ID: acctsCodeCombinationId.value,
      EXTERNAL_BANK_ACCOUNT_ID: remitToBankAccount.value
        ? remitToBankAccount.value.EXT_BANK_ACCOUNT_ID
        : extBankAccountId.value,
      // INVOICE_TYPE: "INOICE NON PO",
      CATEGORY_ID: parseInt(categorIdHeader.value),
      MAPING_ID: mapingId.value,
      // REFERENCE_NUMBER: refNumber.value,
      VENDOR_SITE_ID: supplierSite.value
        ? supplierSite.value.VENDOR_SITE_ID
        : vendorSiteId.value,
      COST_CENTER: costCenter.value
        ? costCenter.value.SEGMENT4
        : costCenterCode.value,
      DB_INSTANCE: company.value.db_instance,
    },

    editDetails: [],
    newDetails: [],
    removedDetails: [],
    editReimburse: [],
    newReimburse: [],
    removedReimburse: [],
    countDetails: dataLine.value.length,
    category: categoryHeader.value,
    payGroup: payGroup.value,
  };

  let indexInExistingLine = [];
  await table.value.forEach((item, index) => {
    if (item.EINVOICE_LINE_ID === 0) {
      const data = {
        EINVOICE_ID: parseInt(id.value),
        LINE_NUM: index + 1,
        // ITEM_ID: item.CATEGORY_DETAIL_ID,
        ITEM_DESCRIPTION: item.ITEM_DESCRIPTION,
        AMOUNT: item.AMOUNT,
        ORGANIZATION_ID: item.ORGANIZATION_ID,
        VAT_CODE: item.VAT_CODE,
        CREATED_BY: userInfo.value.id,
        CREATION_DATE: formattingDate(new Date()),
        LAST_UPDATE_DATE: formattingDate(new Date(), true),
        LAST_UPDATED_BY: userInfo.value.id,
        CURRENCY_CODE: item.CURRENCY_CODE,
        REVISION_NUM: 0,
        VENDOR_ID: item.VENDOR_ID,
        VENDOR_SITE_ID: item.VENDOR_SITE_ID,
        CATEGORY_DETAIL_ID: item.CATEGORY_DETAIL_ID,
        WHT_CODE: item.WHT_CODE,
        COA_ID: item.COA_ID,
        VAT_CODE_ID: item.VAT_CODE_ID,
        WHT_CODE_ID: item.WHT_CODE_ID,
        REMARKS: item.travelOrder
          ? `${item.travelOrder.travelOrderNo}#${
              item.travelOrder.guestName
            }#${new Intl.DateTimeFormat("en-US", dateOptions).format(
              new Date(item.travelOrder.startDate)
            )}#${new Intl.DateTimeFormat("en-US", dateOptions).format(
              new Date(item.travelOrder.endDate)
            )}`
          : item.REMARKS,
      };
      payload.newDetails.push(data);
    } else {
      let tempIndex = 0;
      for (const dataTable of dataLine.value) {
        if (
          item.EINVOICE_LINE_ID === dataTable.EINVOICE_LINE_ID &&
          item.CHANGE === true
        ) {
          indexInExistingLine.push(tempIndex);
          const data = {
            EINVOICE_LINE_ID: item.EINVOICE_LINE_ID,
            EINVOICE_ID: parseInt(id.value),
            LINE_NUM: index + 1,
            // ITEM_ID: item.CATEGORY_DETAIL_ID,
            ITEM_DESCRIPTION: item.ITEM_DESCRIPTION,
            AMOUNT: item.AMOUNT,
            ORGANIZATION_ID: item.ORGANIZATION_ID,
            VAT_CODE: item.VAT_CODE,
            LAST_UPDATE_DATE: formattingDate(new Date(), true),
            LAST_UPDATED_BY: userInfo.value.id,
            CURRENCY_CODE: item.CURRENCY_CODE,
            REVISION_NUM: 0,
            VENDOR_ID: item.VENDOR_ID,
            VENDOR_SITE_ID: item.VENDOR_SITE_ID,
            CATEGORY_DETAIL_ID: item.CATEGORY_DETAIL_ID,
            WHT_CODE: item.WHT_CODE,
            COA_ID: item.COA_ID,
            VAT_CODE_ID: item.VAT_CODE_ID,
            WHT_CODE_ID: item.WHT_CODE_ID,
            REMARKS: item.travelOrder
              ? `${item.travelOrder.travelOrderNo}#${
                  item.travelOrder.guestName
                }#${new Intl.DateTimeFormat("en-US", dateOptions).format(
                  new Date(item.travelOrder.startDate)
                )}#${new Intl.DateTimeFormat("en-US", dateOptions).format(
                  new Date(item.travelOrder.endDate)
                )}`
              : item.REMARKS,
          };
          payload.editDetails.push(data);
        }
        tempIndex++;
      }
    }
  });

  payload.removedDetails = dataLine.value.filter(
    (item) =>
      !table.value.some((val) => val.EINVOICE_LINE_ID === item.EINVOICE_LINE_ID)
  );

  let indexInExistingReimburse = [];
  tableReimburse.value.forEach((element) => {
    if (element.EINVOICE_REIMBURSMENT_ID === 0) {
      let data = {
        EINVOICE_ID: parseInt(id.value),
        VENDOR_ID: element.VENDOR_ID,
        TAX_NO: element.TAX_NO,
        TAX_DATE: formattingDate(element.TAX_DATE),
        AMOUNT: element.AMOUNT,
        COA_ID: element.COA_ID,
        ORG_ID: element.ORG_ID,
        CREATED_BY: userInfo.value.id,
        CREATION_DATE: formattingDate(new Date()),
        LAST_UPDATED_BY: userInfo.value.id,
        LAST_UPDATE_DATE: formattingDate(new Date()),
        GROUP_NAME: element.GROUP_NAME,
      };
      payload.newReimburse.push(data);
    } else {
      let tempIndex = 0;
      for (const reimburse of dataReimbursement.value) {
        if (
          reimburse.EINVOICE_REIMBURSMENT_ID ===
          element.EINVOICE_REIMBURSMENT_ID
        ) {
          indexInExistingReimburse.push(tempIndex);
          let data = {
            EINVOICE_REIMBURSMENT_ID: element.EINVOICE_REIMBURSMENT_ID,
            EINVOICE_ID: parseInt(id.value),
            VENDOR_ID: element.VENDOR_ID,
            TAX_NO: element.TAX_NO,
            TAX_DATE: formattingDate(element.TAX_DATE),
            AMOUNT: element.AMOUNT,
            COA_ID: element.COA_ID,
            ORG_ID: element.ORG_ID,
            LAST_UPDATED_BY: userInfo.value.id,
            LAST_UPDATE_DATE: formattingDate(new Date()),
            GROUP_NAME: element.GROUP_NAME,
          };
          payload.editReimburse.push(data);
        }
        tempIndex++;
      }
    }
  });

  payload.removedReimburse = dataReimbursement.value.filter(
    (item) =>
      !tableReimburse.value.some(
        (val) => val.EINVOICE_REIMBURSMENT_ID === item.EINVOICE_REIMBURSMENT_ID
      )
  );


  const jsonPayload = JSON.stringify(payload);
  let formData = new FormData();
  formData.append("payload", jsonPayload);
  formData.append("file", file.value);

  if (errors.length === 0) {
    try {
      const result = await invoiceNonPOservice.updateInvoiceFin(
        id.value,
        formData
      );
      console.log("res ::", result);
      toast.add({
        severity: "info",
        summary: "Success",
        detail: "Invoice Non PO is Successfully updated",
        life: 3000,
      });
      setTimeout(() => {
        location.reload();
      }, 1500);
    } catch (error) {
      console.log("Error Add :: ", error);
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Something Wrong, Unable to Save Data",
        life: 3000,
      });
      progress.value = false;
    }
  } else {
    for (const err of errors) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: err,
        life: 3000,
      });
    }
  }
  progress.value = false;
};

const handleChangeTax = (event) => {
	const {value} = event.target;
	taxInvoiceNo.value = value.replace(/[a-zA-Z]/g,"").substring(0,20);
}


const reject = async () => {
  progress.value = true;
  let errors = [];
  if (!rejectReason.value) {
    errors.push("Reject Reason/Reason for Rejection is Empty");
  }

  const payload = {
    header: {
      INVOICE_NO: invoiceNumber.value,
      INVOICE_DATE: formattingDate(invoiceDate.value),
      REVISION_NUM: 0,
      TAX_NO: taxInvoiceNo.value,
      TAX_DATE: formattingDate(taxInvoiceDate.value),
      DESCRIPTION: descriptionHeader.value,
      CURRENCY_CODE: currency.value,
      VENDOR_ID: vendorId.value,
      INVOICE_AMOUNT: totalLine.value,
      TAX_AMOUNT: totalPPN.value,
      TOTAL_AMOUNT: totalInvoice.value,
      ORG_ID: parseInt(company.value.value),
      STATUS: "DRAFT",
      LAST_UPDATE_DATE: formattingDate(new Date(), true),
      LAST_UPDATED_BY: userInfo.value.id,
      PAYMENT_METHOD_LOOKUP_CODE: paymentMethod.value
        ? paymentMethod.value.PAYMENT_METHOD
        : null,
      GL_DATE: formattingDate(glDate.value),
      TERMS_ID: terms.value ? terms.value.TERM_ID : null,
      REASON: rejectReason.value ? rejectReason.value : null,
      // ACCTS_CODE_COMBINATION_ID: "",
      EXTERNAL_BANK_ACCOUNT_ID: remitToBankAccount.value
        ? remitToBankAccount.value.EXT_BANK_ACCOUNT_ID
        : null,
      VENDOR_SITE_ID: supplierSite.value
        ? supplierSite.value.VENDOR_SITE_ID
        : null,
      COST_CENTER: costCenter.value ? costCenter.value.SEGMENT4 : null,
      DB_INSTANCE: company.value.db_instance,
    },
    email: {
      module: "searchinvoicenonpo",
      idmodule: 2,
      sbjmessage: "Reject Invoice Non Po # " + invoiceNumber.value + " from ",
      notif_type: "Supplier",
      usersenderid: userInfo.value.id,
      sender: emailSender.value,
      statusNotif: "Reject",
      dbInstance: dbInstance.value,
      companyname: companyInInvoiceHeader.value,
      vendorname: supplierInInvoiceHeader.value,
    },
  };

  if (errors.length === 0) {
    try {
      const result = await invoiceNonPOservice.rejectInvoiceFin(
        id.value,
        payload
      );
      console.log("res ::", result);
      toast.add({
        severity: "info",
        summary: "Success",
        detail: "Invoice Non PO is Rejected",
        life: 3000,
      });
      setTimeout(() => {
        router.back();
      }, 1500);
    } catch (error) {
      console.log("Error Add :: ", error);
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Something Wrong, Unable to Save Data",
        life: 3000,
      });
      progress.value = false;
    }
  } else {
    for (const err of errors) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: err,
        life: 3000,
      });
    }
  }
  progress.value = false;
};

const submit = async () => {
  progress.value = true;
  let errors = [];
  const dateOptions = { year: "numeric", month: "short", day: "numeric" };

  if (!invoiceNumber.value) {
    errors.push("Invoice Number is Empty");
  }
  if (!invoiceDate.value) {
    errors.push("Invoice Date is Not Selected");
  }
  if (!glDate.value) {
    errors.push("GL Date is Not Selected");
  }
  if (!taxInvoiceNo.value) {
    errors.push("Tax Invoice No is Empty");
  }
  if (!taxInvoiceDate.value) {
    errors.push("Tax Invoice Date is Not Selected");
  }
  if (!descriptionHeader.value) {
    errors.push("Description is Empty");
  }
  if (!supplierSite.value) {
    if (!vendorSiteId.value) {
      errors.push("Supplier Site is Not Selected");
    }
  }
  if (!paymentMethod.value) {
    if (!paymentMethodCode.value) {
      errors.push("Payment Method is Not Selected");
    }
  }
  if (!terms.value) {
    if (!termsId.value) {
      errors.push("Terms is Not Selected");
    }
  }
  if (!payGroup.value) {
    errors.push("Pay Group is Not Selected");
  }
  if (!remitToBankAccount.value) {
    if (!extBankAccountId.value) {
      errors.push("Remit To Bank Account is Not Selected");
    }
  }
  if (!costCenter.value) {
    if (!costCenterCode.value) {
      errors.push("Cost Center is Not Selected");
    }
  }

  if (table.value.length > 0) {
    table.value.forEach((element, index) => {
      if (!element.ITEM_DESCRIPTION) {
        errors.push(`Description in data line ${index + 1} is Empty`);
      }
      if (!element.AMOUNT) {
        errors.push(`Amount in data line ${index + 1} is Empty`);
      }
      if (!element.COA_ID) {
        errors.push(`Account in data line ${index + 1} is Empty`);
      }
    });
  }

  if (tableReimburse.value.length > 0) {
    tableReimburse.value.forEach((element, index) => {
      // if (!element.VENDOR_ID) {
      //   errors.push(
      //     `Vendor in data reimbursement ${index + 1} is Not Selected`
      //   );
      // }
      if (!element.TAX_NO) {
        errors.push(
          `No. Faktur Pajak in data reimbursement ${index + 1} is Empty`
        );
      }
      if (!element.TAX_DATE) {
        errors.push(
          `Tgl Faktur Pajak in data reimbursement ${index + 1} is Not Selected`
        );
      }
      if (!element.AMOUNT) {
        errors.push(`Nilai Pajak in data reimbursement ${index + 1} is Empty`);
      }
      if (!element.COA_ID) {
        errors.push(`Account in data reimbursement ${index + 1} is Empty`);
      }
      if (!element.GROUP_NAME) {
        errors.push(`Group in data reimbursement ${index + 1} is Not Selected`);
      }
    });
  }

  const payload = {
    header: {
      EINVOICE_ID: parseInt(id.value),
      INVOICE_NO: invoiceNumber.value,
      INVOICE_DATE: formattingDate(invoiceDate.value),
      REVISION_NUM: 0,
      TAX_NO: taxInvoiceNo.value,
      TAX_DATE: formattingDate(taxInvoiceDate.value),
      DESCRIPTION: descriptionHeader.value,
      CURRENCY_CODE: currency.value,
      VENDOR_ID: vendorId.value,
      INVOICE_AMOUNT: totalLine.value,
      TAX_AMOUNT: totalPPN.value,
      TOTAL_AMOUNT: totalInvoice.value,
      ORG_ID: parseInt(company.value.value),
      STATUS: "POSTED",
      LAST_UPDATE_DATE: formattingDate(new Date(), true),
      LAST_UPDATED_BY: userInfo.value.id,
      PAYMENT_METHOD_LOOKUP_CODE: paymentMethod.value
        ? paymentMethod.value.PAYMENT_METHOD
        : paymentMethodCode.value,
      GL_DATE: formattingDate(glDate.value),
      TERMS_ID: terms.value ? terms.value.TERM_ID : termsId.value,
      REASON: rejectReason.value ? rejectReason.value : null,
      ACCTS_CODE_COMBINATION_ID: acctsCodeCombinationId.value,
      EXTERNAL_BANK_ACCOUNT_ID: remitToBankAccount.value
        ? remitToBankAccount.value.EXT_BANK_ACCOUNT_ID
        : extBankAccountId.value,
      // INVOICE_TYPE: "INOICE NON PO",
      CATEGORY_ID: categorIdHeader.value,
      MAPING_ID: mapingId.value,
      REFERENCE_NUMBER: referenceNumber.value,
      VENDOR_SITE_ID: supplierSite.value
        ? supplierSite.value.VENDOR_SITE_ID
        : vendorSiteId.value,
      COST_CENTER: costCenter.value
        ? costCenter.value.SEGMENT4
        : costCenterCode.value,
      DB_INSTANCE: company.value.db_instance,
    },

    editDetails: [],
    newDetails: [],
    removedDetails: [],
    editReimburse: [],
    newReimburse: [],
    removedReimburse: [],
    countDetails: dataLine.value.length,
    category: categoryHeader.value,
    payGroup: payGroup.value,
    email: {
      module: "searchinvoicenonpo",
      idmodule: 2,
      sbjmessage: "Submit Invoice Non Po # " + invoiceNumber.value + " from ",
      notif_type: "Supplier",
      usersenderid: userInfo.value.id,
      sender: emailSender.value,
      statusNotif: "Submit",
      dbInstance: dbInstance.value,
      companyname: companyInInvoiceHeader.value,
      vendorname: supplierInInvoiceHeader.value,
    },
  };

  let indexInExistingLine = [];
  await table.value.forEach((item, index) => {
    if (item.EINVOICE_LINE_ID === 0) {
      const data = {
        EINVOICE_ID: parseInt(id.value),
        LINE_NUM: index + 1,
        // ITEM_ID: item.CATEGORY_DETAIL_ID,
        ITEM_DESCRIPTION: item.ITEM_DESCRIPTION,
        AMOUNT: item.AMOUNT,
        ORGANIZATION_ID: item.ORGANIZATION_ID,
        VAT_CODE: item.VAT_CODE,
        CREATED_BY: userInfo.value.id,
        CREATION_DATE: formattingDate(new Date()),
        LAST_UPDATE_DATE: formattingDate(new Date(), true),
        LAST_UPDATED_BY: userInfo.value.id,
        CURRENCY_CODE: item.CURRENCY_CODE,
        REVISION_NUM: 0,
        VENDOR_ID: item.VENDOR_ID,
        VENDOR_SITE_ID: item.VENDOR_SITE_ID,
        CATEGORY_DETAIL_ID: item.CATEGORY_DETAIL_ID,
        CATEGORY_DETAIL: item.CATEGORY_DETAIL,
        WHT_CODE: item.WHT_CODE,
        COA_ID: item.COA_ID,
        VAT_CODE_ID: item.VAT_CODE_ID,
        WHT_CODE_ID: item.WHT_CODE_ID,
        REMARKS: item.travelOrder
          ? `${item.travelOrder.travelOrderNo}#${
              item.travelOrder.guestName
            }#${new Intl.DateTimeFormat("en-US", dateOptions).format(
              new Date(item.travelOrder.startDate)
            )}#${new Intl.DateTimeFormat("en-US", dateOptions).format(
              new Date(item.travelOrder.endDate)
            )}`
          : item.REMARKS,
      };
      payload.newDetails.push(data);
    } else {
      let tempIndex = 0;
      for (const dataTable of dataLine.value) {
        if (item.EINVOICE_LINE_ID === dataTable.EINVOICE_LINE_ID) {
          indexInExistingLine.push(tempIndex);
          const data = {
            EINVOICE_LINE_ID: item.EINVOICE_LINE_ID,
            EINVOICE_ID: parseInt(id.value),
            LINE_NUM: index + 1,
            // ITEM_ID: item.CATEGORY_DETAIL_ID,
            ITEM_DESCRIPTION: item.ITEM_DESCRIPTION,
            AMOUNT: item.AMOUNT,
            ORGANIZATION_ID: item.ORGANIZATION_ID,
            VAT_CODE: item.VAT_CODE,
            LAST_UPDATE_DATE: formattingDate(new Date(), true),
            LAST_UPDATED_BY: userInfo.value.id,
            CURRENCY_CODE: item.CURRENCY_CODE,
            REVISION_NUM: 0,
            VENDOR_ID: item.VENDOR_ID,
            VENDOR_SITE_ID: item.VENDOR_SITE_ID,
            CATEGORY_DETAIL_ID: item.CATEGORY_DETAIL_ID,
            CATEGORY_DETAIL: item.CATEGORY_DETAIL,
            WHT_CODE: item.WHT_CODE,
            COA_ID: item.COA_ID,
            VAT_CODE_ID: item.VAT_CODE_ID,
            WHT_CODE_ID: item.WHT_CODE_ID,
            REMARKS: item.travelOrder
              ? `${item.travelOrder.travelOrderNo}#${
                  item.travelOrder.guestName
                }#${new Intl.DateTimeFormat("en-US", dateOptions).format(
                  new Date(item.travelOrder.startDate)
                )}#${new Intl.DateTimeFormat("en-US", dateOptions).format(
                  new Date(item.travelOrder.endDate)
                )}`
              : item.REMARKS,
          };
          payload.editDetails.push(data);
        }
        tempIndex++;
      }
    }
  });

  payload.removedDetails = dataLine.value.filter(
    (item) =>
      !table.value.some((val) => val.EINVOICE_LINE_ID === item.EINVOICE_LINE_ID)
  );

  let indexInExistingReimburse = [];
  tableReimburse.value.forEach((element) => {
    if (element.EINVOICE_REIMBURSMENT_ID === 0) {
      let data = {
        EINVOICE_ID: parseInt(id.value),
        VENDOR_ID: element.VENDOR_ID,
        TAX_NO: element.TAX_NO,
        TAX_DATE: formattingDate(element.TAX_DATE),
        AMOUNT: element.AMOUNT,
        COA_ID: element.COA_ID,
        ORG_ID: element.ORG_ID,
        CREATED_BY: userInfo.value.id,
        CREATION_DATE: formattingDate(new Date()),
        LAST_UPDATED_BY: userInfo.value.id,
        LAST_UPDATE_DATE: formattingDate(new Date()),
        GROUP_NAME: element.GROUP_NAME,
      };
      payload.newReimburse.push(data);
    } else {
      let tempIndex = 0;
      for (const reimburse of dataReimbursement.value) {
        if (
          reimburse.EINVOICE_REIMBURSMENT_ID ===
          element.EINVOICE_REIMBURSMENT_ID
        ) {
          indexInExistingReimburse.push(tempIndex);
          let data = {
            EINVOICE_REIMBURSMENT_ID: element.EINVOICE_REIMBURSMENT_ID,
            EINVOICE_ID: parseInt(id.value),
            VENDOR_ID: element.VENDOR_ID,
            TAX_NO: element.TAX_NO,
            TAX_DATE: formattingDate(element.TAX_DATE),
            AMOUNT: element.AMOUNT,
            COA_ID: element.COA_ID,
            ORG_ID: element.ORG_ID,
            LAST_UPDATED_BY: userInfo.value.id,
            LAST_UPDATE_DATE: formattingDate(new Date()),
            GROUP_NAME: element.GROUP_NAME,
          };
          payload.editReimburse.push(data);
        }
        tempIndex++;
      }
    }
  });

  payload.removedReimburse = dataReimbursement.value.filter(
    (item) =>
      !tableReimburse.value.some(
        (val) => val.EINVOICE_REIMBURSMENT_ID === item.EINVOICE_REIMBURSMENT_ID
      )
  );


  const jsonPayload = JSON.stringify(payload);
  let formData = new FormData();
  formData.append("payload", jsonPayload);
  formData.append("file", file.value);

  if (errors.length === 0) {
    try {
      const result = await invoiceNonPOservice.submitInvoiceNonPo(
        id.value,
        formData
      );
      console.log("res ::", result);
      toast.add({
        severity: "info",
        summary: "Success",
        detail: "Invoice Non PO is Successfully updated",
        life: 3000,
      });
      setTimeout(() => {
        router.back();
      }, 1500);
    } catch (error) {
      console.log("Error Add :: ", error);
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Something Wrong, Unable to Save Data",
        life: 3000,
      });
      progress.value = false;
    }
  } else {
    for (const err of errors) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: err,
        life: 3000,
      });
    }
  }
  progress.value = false;
};

const back = () => {
  router.back();
};

//format dete dexa
const formattingDate = (date, withTime) => {
  const theDate = new Date(date);
  const strDate =
    theDate.getFullYear() +
    "-" +
    (theDate.getMonth() + 1) +
    "-" +
    theDate.getDate();
  const strTime =
    theDate.getHours() +
    ":" +
    theDate.getMinutes() +
    ":" +
    theDate.getSeconds();
  if (withTime) {
    return `${strDate} ${strTime}`;
  } else {
    return `${strDate}`;
  }
};

const exportToExcel = async () => {
  await AuthService.whoAmi().then((value) => {
    if (value.status === 200) {
      setTimeout(async () => {
        let biggerTable = true;
        if (table.value.length >= tableReimburse.value.length) {
          biggerTable = true;
        } else {
          biggerTable = false;
        }
        const payload = {
          invoice_id: parseInt(id.value),
          table: biggerTable,
        };
        await invoiceNonPOservice.exportinvoice(payload).then((respond) => {
          if (respond.length !== 0) {
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, "0");
            let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            let yyyy = today.getFullYear();
            let combinedDate = dd + mm + yyyy;
            let filename =
              "Template Download Invoice _" + combinedDate + ".xlsx";
            let data = respond.data.data.data;
            let ws = XLSX.utils.json_to_sheet(data);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "My Worksheet");
            XLSX.writeFile(wb, filename);
          } else {
            toast.add({
              severity: "error",
              summary: "Data is Empty",
              life: 3000,
            });
          }
        });
      }, 250);
    }
  });
};

// const getFileBase64 = async (invoiceId) => {
//   try {
//     const leveltenant = sessionStorage.getItem("leveltenant");
//     const res = await invoiceNonPOservice.getFileBase64(
//       invoiceId,
//       leveltenant,
//       vendorId.value
//     );
//     

//     if (res.status === 200) {
//       fileBuffer.value = res.data.data;
//     }
//   } catch (err) {
//     
//   }
// };

const onDownload = async () => {
  try {
    loadingDownladFile.value = true;
    const leveltenant = sessionStorage.getItem("leveltenant");
    const res = await invoiceNonPOservice.getFileBase64(
      id.value,
      leveltenant,
      vendorId.value
    );

    if (res.status === 200) {
      fileBuffer.value = res.data.data;
    }
  } catch (err) {
    loadingDownladFile.value = false;
    toast.add({
      severity: "error",
      summary: "Connection Unstable !",
      life: 3000,
    });
    throw Error("Error downloading file");
  }
  const linkSource = `data:application/pdf;base64,${fileBuffer.value}`;
  const downloadLink = document.createElement("a");
  const fileName = documentFilename.value;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
  loadingDownladFile.value = false;
};
</script>

<style scoped>
#label-key {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 36px;
  /* identical to box height, or 257% */

  color: #000000;
}

#label-value {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 36px;
  /* identical to box height, or 257% */
  align-items: center;
  text-align: right;

  /* Gray 2 */

  color: #4f4f4f;
}
</style>
