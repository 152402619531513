import spbOrderApi from "@/service/api/SpbOrderApi";
import spbAdminApi from "@/service/api/SpbAdminApi";

const getCompanies = async () => {

    const result = await spbOrderApi.get('/spb/dropdowns/companies');

    return result.data;
}
const getIncotermList = async () => {

    const result = await spbAdminApi.get('/spb/docsetup/incotermlist');

    return result.data;
}
const getDocSetupTable = async () => {

    const result = await spbAdminApi.get('/spb/docsetup/docsetuptable');

    return result.data;
}
const updateTable = async (payload) => {

    const result = await spbAdminApi.post('/spb/docsetup/updatetable', payload);

    return result.data;
}
const searchDocSetup = async (payload) => {

    const result = await spbAdminApi.post('/spb/docsetup/searchdocsetup', payload);

    return result.data;
}
const deleteRows = async (payload) => {

    const result = await spbAdminApi.post('/spb/docsetup/deleterow', payload);

    return result.data;
}


export {
    getCompanies,
    getIncotermList,
    getDocSetupTable,
    updateTable,
    searchDocSetup,
    deleteRows
}