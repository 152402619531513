<template>
	<DataTable
		:value="debitList"
		:lazy="true"
		:paginator="true"
		:rows="5"
		:loading="loading"
		:totalRecords="totalData"
		@page="onPage($event)"
		@sort="onSort($event)"
		:rowsPerPageOptions="[5, 10, 15, 25]"
		responsiveLayout="scroll"
		:scrollable="true"
		scrollHeight="600px"
		scrollDirection="both"
		:sortable="true"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #header>
			<div>
				<span class="p-input-icon-left" style="width: 100%">
					<i class="pi pi-search" />
					<InputText
						v-model="invoiceFilter"
						style="width: 100%"
						placeholder="Search"
						@keydown.enter="keydown($event)"
					/>
				</span>
			</div>
		</template>
		<template #empty>No data found</template>
		<template #loading>Loading data</template>
		<Column header="Company" field="COMPANY_NAME" style="width: 20%">
		</Column>
		<Column header="Supplier" field="VENDOR_NAME" style="width: 20%">
		</Column>
		<Column header="Debit Note No" field="DN_NO" style="width: 20%">
		</Column>
		<Column header="Debit Note Date" field="DN_DATE" style="width: 15%">
			<template #body="{ data }">
				{{
					new Date(data.DN_DATE).toLocaleDateString('id-ID', {
						year: 'numeric',
						month: 'short',
						day: 'numeric',
					})
				}}
			</template>
		</Column>
		<Column header="Ex Tax No" field="EXTAX_NO" style="width: 20%">
		</Column>
		<Column header="Ex Tax Date" field="EXTAX_DATE" style="width: 15%">
			<template #body="{ data }">
				{{
					new Date(data.EXTAX_DATE).toLocaleDateString('id-ID', {
						year: 'numeric',
						month: 'short',
						day: 'numeric',
					})
				}}
			</template>
		</Column>
		<Column header="Amount" field="TOTAL_AMOUNT" style="width: 20%">
		</Column>
		<Column
			class="call-button-container"
			header="Actions"
			selectionMode="single"
			style="width: 10%"
			alignFrozen="right"
			frozen
		>
			<template #body="slotProps">
				<Button
					icon="pi pi-eye"
					class="p-button-rounded p-button-text"
					@click="editChildren(slotProps.data)"
					v-tooltip="'Detail'"
				/>
			</template>
		</Column>
	</DataTable>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import AuthService from '@/service/AuthService';
// import LovService from "@/service/LovService";
// import PoFulfillment from "@/service/PoFulfillment";
import ListNotaDebit from '../../../service/ListNotaDebit';

const debitList = ref([]);
const company = ref();
const supplier = ref();

onMounted(async () => {
	company.value = props.company;
	const info = await AuthService.whoAmi();
	if (info.status === 200) {
		levelTenant.value = info.data.data.leveltenant;
		if (levelTenant.value === 1) {
			// loading.value = true
			supplier.value = props.supplier;
			await populateTable();
		} else if (levelTenant.value === 2) {
			await populateTable();
		}
	}
});

// eslint-disable-next-line no-undef
const props = defineProps({
	company: {
		type: Object,
	},
	supplier: {
		type: Object,
	},
	debitFrom: {
		type: Object,
	},
	debitTo: {
		type: Object,
	},
	dnNumber: {
		type: Object,
	},
});

const invoiceList = ref();
const totalData = ref(0);
const loading = ref(false);
const page = ref(1);
const rowsPerPage = ref(5);
const levelTenant = ref();
const invoiceFilter = ref();
const router = useRouter();
const emit = defineEmits(['result']);
const search = ref();

watchEffect(() => emit('result', invoiceList));

const populateTable = async () => {
	if (props.company !== null && props.company !== undefined) {
		const payload = {
			fromDate: props.debitFrom ? dateFormat2(props.debitFrom) : null,
			toDate: props.debitTo ? dateFormat2(props.debitTo) : null,
			org_id: company.value ? company.value.value : null,
			vendor_id: props.supplier ? props.supplier.vendor_id : null,
			searchKeyword: invoiceFilter.value,
			page: page.value,
			rows: rowsPerPage.value,
			debitNo: props.dnNumber ? props.dnNumber.dn_no : null,
			db_instance: company.value ? company.value.db_instance : null,
		};
		loading.value = true;
		const res = await ListNotaDebit.getTableNota(payload);
		if (res.status == 200) {
			debitList.value = res.data.data.data;
			totalData.value = res.data.data.totalData[0].totalData;
			loading.value = false;
		}
	}
};
// loading.value = false;

function dateFormat2(d) {
	var t = new Date(d);
	var month = t.getMonth() + 1;
	return t.getFullYear() + '-' + month + '-' + t.getDate();
}

const onPage = async (event) => {
	loading.value = true;
	page.value = event.page + 1;
	rowsPerPage.value = event.rows;
	await populateTable();
};

const editChildren = async (rowIndex) => {
	router.push({
		name: 'DetailListNota',
		params: {
			id: rowIndex.id,
		},
	});
};

const keydown = async (event) => {
	search.value = event;
	if (search.value !== null) {
		await populateTable();
	}
};
</script>
<style scoped></style>
