<template>
    <AutoComplete :suggestions="filteredDOs" @complete="searchDOs($event)" :dropdown="true" field="shipment_number"
                  :placeholder="placeholder"
                  forceSelection>
      <template #item="slotProp">
        {{ slotProp.item.shipment_number }}
      </template>
    </AutoComplete>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import { getDONumbers, getDONumbersByPart } from '@/service/CAPAServices';

const filteredDOs = ref([]);
const doNumbers = ref([]);
const placeholder = ref('Search DO Number');

onMounted(async() => {
  await populate()
})

const populate = async () => {
  doNumbers.value = await getDONumbers()
  doNumbers.value = [{ shipment_number: '--first 10 rows--'}, ...doNumbers.value]
}

const searchDOs = async (event) => {
  setTimeout(async () => {

    if (!event.query.trim().length) {
      filteredDOs.value = [...doNumbers.value]
    } else {
      doNumbers.value = await getDONumbersByPart(event.query.toLowerCase());
      filteredDOs.value = doNumbers.value;
    }
  }, 500);
}


</script>

<style scoped>

</style>