<template>
	<DataTable
		:value="invoiceList"
		:lazy="true"
		:paginator="true"
		:rows="5"
		:loading="loading"
		:totalRecords="totalData"
		@page="onPage($event)"
		@sort="onSort($event)"
		:rowsPerPageOptions="[5, 10, 15, 25]"
		responsiveLayout="scroll"
		:scrollable="true"
		scrollHeight="600px"
		scrollDirection="both"
		:sortable="true"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #header>
			<div>
				<span class="p-input-icon-left" style="width: 100%">
					<i class="pi pi-search" />
					<InputText
						v-model="invoiceFilter"
						style="width: 100%"
						placeholder="Search Invoice"
						@keydown.enter="keydown($event)"
					/>
				</span>
			</div>
		</template>
		<template #empty>No Invoice found</template>
		<template #loading>Loading data</template>
		<Column header="Company" field="NAME" style="width: 15%"> </Column>
		<Column header="Supplier" field="vendor_name" style="width: 15%">
		</Column>
		<Column header="Invoice No" field="INVOICE_NO" style="width: 25%">
		</Column>
		<Column
			header="Date"
			field="INVOICE_DATE"
			:sortable="true"
			style="width: 15%"
		>
		</Column>
		<Column header="Amount" field="TOTAL_AMOUNT" style="width: 15%">
		</Column>
		<Column header="Status" style="width: 15%">
			<template #body="{ data }">
				<Chip
					class="custom-chip"
					:class="`chip-${data.STATUS.toLowerCase()}`"
				>
					{{ data.STATUS }}
				</Chip>
			</template>
		</Column>
		<Column
			class="call-button-container"
			header="Actions"
			selectionMode="single"
			style="width: 10%; justify-content: center"
			alignFrozen="right"
			frozen
		>
			<template #body="slotProps">
				<Button
					icon="pi pi-eye"
					class="p-button-rounded p-button-text"
					@click="editChildren(slotProps.data)"
					v-tooltip="'Detail'"
				/>
			</template>
		</Column>
	</DataTable>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import AuthService from '@/service/AuthService';
import LovService from '@/service/LovService';
import PoFulfillment from '@/service/PoFulfillment';
import InvoiceNonPOservice from '../../../service/InvoiceNonPOservices';

const company = ref();
const supplier = ref();
const idUser = ref();
const lazyParams = ref(null);

onMounted(async () => {
	company.value = props.company;
	const info = await AuthService.whoAmi();

	lazyParams.value = {
		sortField: null,
		sortOrder: null,
	};

	if (info.status === 200) {
		levelTenant.value = info.data.data.leveltenant;
		idUser.value = info.data.data.id;
		if (levelTenant.value) {
			// loading.value = true
			supplier.value = props.supplier;
			await populateTable();
		} else if (levelTenant.value === 3) {
			if (company.value && !supplier.value) {
				const param = {
					db_instance: props.company.db_instance,
					org_id: props.company.value,
				};
				const respVendor = await PoFulfillment.getSuppBindComp(
					info.data.data.id
				);
				const vendorName = respVendor.data.data[0][0].vendorname;
				const respSuppName = await LovService.getSupplierName(param);
				if (respSuppName.status === 200) {
					const suppList = respSuppName.data.data;
					for (const s of suppList) {
						if (s.vendor_name === vendorName) {
							supplier.value = s;
							break;
						}
					}
				}
				// await populateTable();
			}
			// loading.value = true
		}
	}
});
// eslint-disable-next-line no-undef
const props = defineProps({
	company: {
		type: Object,
	},
	supplier: {
		type: Object,
	},
	batchdropdown: {
		type: Object,
	},
	invoiceDropdown: {
		type: Object,
	},
});

const tableData = ref([]);
const invoiceList = ref();
const totalData = ref(0);
const loading = ref(false);
const page = ref(1);
const rowsPerPage = ref(5);
const levelTenant = ref();
const invoiceFilter = ref();
const router = useRouter();
const emit = defineEmits(['result']);
const search = ref();

watchEffect(() =>
	//
	emit('result', invoiceList)
);

const populateTable = async () => {
	//
	console.log(props.company, "COMPANY PROPS")
	if (props.company !== null && props.company !== undefined) {
		const payload = {
			org_id: props.company ? props.company.value : null,
			suppValue: props.supplier ? props.supplier.vendor_id : null,
			db_instance: props.company ? props.company.db_instance : null,
			searchKeyword: invoiceFilter.value,
			invoice_no: props.invoiceDropdown
				? props.invoiceDropdown.INVOICE_NO
				: null,
			batch_no: props.batchdropdown ? props.batchdropdown.BATCH_NO : null,
			userId: idUser.value,
			companyname: props.company ? props.company.name : null,
			page: page.value,
			rows: rowsPerPage.value,
			sort: lazyParams.value.sortField,
			sortOrder: lazyParams.value.sortOrder,
		};
		if (levelTenant.value === 1) {
			loading.value = true;
			tableData.value = await InvoiceNonPOservice.getTableInvoiceNonPO(
				payload
			);

			if (tableData.value.status) {
				invoiceList.value = tableData.value.data.data.data;
				totalData.value =
					tableData.value.data.data.totalData[0].totalData;
				for (var id of invoiceList.value) {
					id.INVOICE_DATE = dateFormat2(id.INVOICE_DATE);
				}
				loading.value = false;
			} else {
				loading.value = false;
			}
		} else if (levelTenant.value === 3) {
			loading.value = true;
			tableData.value = await InvoiceNonPOservice.getTableInvoiceNonPOpic(
				payload
			);

			if (tableData.value.status) {
				invoiceList.value = tableData.value.data.data.data;
				totalData.value =
					tableData.value.data.data.totalData[0].totalData;
				for (var c of invoiceList.value) {
					c.INVOICE_DATE = dateFormat2(c.INVOICE_DATE);
				}
				loading.value = false;
			} else {
				loading.value = false;
			}
		}
	}
	// loading.value = false;
};

function dateFormat2(d) {
	var monthShortNames = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];
	var t = new Date(d);
	return (
		t.getDate() +
		' ' +
		monthShortNames[t.getMonth()] +
		' ' +
		t.getFullYear() +
		' '
	);
}

const onPage = async (event) => {
	loading.value = true;
	//
	page.value = event.page + 1;
	rowsPerPage.value = event.rows;
	await populateTable();
};

const onSort = async (event) => {
	loading.value = true;

	page.value = 1;
	lazyParams.value = event;

	await populateTable();
};

const editChildren = async (rowIndex) => {
	if (levelTenant.value === 3) {
		router.push({
			name: 'DetailInvoiceNonPOpic',
			params: {
				id: rowIndex.id,
			},
		});
	} else {
		router.push({
			name: 'detailInvoiceNonPOFinance',
			params: {
				id: rowIndex.id,
			},
		});
	}
};

const keydown = async (event) => {
	//
	//
	search.value = event;
	if (search.value !== null) {
		await populateTable();
	}
};
</script>

<style scoped>
.input-disabled {
	color: #000 !important;
}

.p-chip.custom-chip {
	padding: 4px 12px;
	border-radius: 8px;
	font-size: 12px;
	font-weight: 600;
}

.chip-open {
	background: #0ea5e9;
	color: #fff;
}
.chip-rejected {
	background: var(--primary-color);
	color: #fff;
}
.chip-review {
	background: #f59e0b;
	color: #fff;
}
.chip-posted {
	background: #10b981;
	color: #fff;
}
.chip-processed {
	background: #2563eb;
	color: #fff;
}
</style>
