<template>
	<!-- eslint-disable -->
	<DataTable
		:value="tableList"
		:paginator="true"
		:rows="10"
		:loading="loading"
		:rowsPerPageOptions="[10, 20, 30]"
		responsiveLayout="scroll"
		:scrollable="true"
		scrollHeight="600px"
		@page="onPage($event)"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #empty>No Invoice found</template>
		<template #loading>Loading data</template>
		<Column header="Description" headerStyle="min-width:10rem;">
			<template #body="slotProps">
				<InputText
					id="inputtext"
					type="text"
					v-model="slotProps.data.ITEM_DESCRIPTION"
					:class="{
						'p-invalid': tableList[slotProps.index].VALIDATE_ITEM,
					}"
					disabled
				/>
				<div v-if="tableList[slotProps.index].VALIDATE_ITEM">
					<small class="p-error">{{
						'Item Description is empty'
					}}</small>
				</div>
			</template>
		</Column>
		<Column header="Amount" headerStyle="min-width:10rem;">
			<template #body="slotProps">
				<InputNumber
					id="locale-german"
					v-model="slotProps.data.AMOUNT"
					:minFractionDigits="2"
					locale="de-DE"
					:disabled="props.disabled || pageDisabled"
					:min="1"
					@input="onChangeText($event, slotProps.index)"
					:class="{
						'p-invalid': tableList[page + slotProps.index].RESPONSE,
					}"
				/>
				<div v-if="tableList[page + slotProps.index].RESPONSE">
					<small class="p-error">{{ 'Amount is empty' }}</small>
				</div>
			</template>
		</Column>
		<Column header="VAT" headerStyle="min-width:10rem;">
			<template #body="slotProps">
				<!-- <h4>halo</h4>
        <AutoComplete
								v-model="slotProps.data.VAT_CODE"
								:suggestions="filteredVAT"
								@complete="searchVAT($event)"
								:dropdown="true"
								field="VAT_CODE"
								forceSelection
                :disabled="props.disabled"
                @onChange="onChange($event)"
							>
        </AutoComplete> -->
				<Dropdown
					:editable="true"
					v-model="slotProps.data.VAT_CODE"
					@change="onChange($event, slotProps.index)"
					:options="getVAT"
					optionLabel="VAT_CODE"
					optionValue="TAX_RATE_ID"
					style="width: 100%"
					:disabled="props.disabled || pageDisabled"
				/>
			</template>
		</Column>
		<Column header="Ref Number" headerStyle="min-width:30rem;">
			<template #body="slotProps">
				<div class="grid formgrid">
					<div class="col-10 mb-0 lg:col-10 lg:mb-0">
						<InputText
							id="inputtext"
							type="text"
							v-model="slotProps.data.REMARKS"
							disabled
						/>
					</div>
					<div class="col-1 mb-0 lg:col-1 lg:mb-0">
						<Button
							icon="pi pi-search"
							class="p-button-rounded p-button-text"
							@click="refPopup(slotProps.index)"
							v-tooltip="'Ref Number'"
							:disabled="props.disabled || pageDisabled"
						/>
					</div>
					<div class="col-1 mb-0 lg:col-1 lg:mb-0">
						<Button
							icon="pi pi-trash"
							class="p-button-rounded p-button-text"
							@click="deleteLine(slotProps.data)"
							v-tooltip="'Delete'"
							:disabled="props.disabled || pageDisabled"
						/>
					</div>
				</div>
			</template>
		</Column>
	</DataTable>
	<Dialog
		header="Ref Number"
		v-model:visible="displayModal"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '50vw' }"
		:modal="true"
	>
		<div class="col-12 p-fluid mb-4">
			<DataTable
				:value="tableListPopUp"
				responsiveLayout="scroll"
				selectionMode="single"
				:paginator="true"
				:metaKeySelection="false"
				:rows="5"
				:rowsPerPageOptions="[5, 10, 20, 50]"
				:loading="!tableListPopUp"
				paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
				currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
				dataKey="id"
				@rowSelect="onRowSelect"
			>
				<template #header>
					<div>
						<span class="p-input-icon-left" style="col-12">
							<i class="pi pi-search" />
							<InputText
								v-model="invoiceFilter"
								placeholder="Search"
								@keydown.enter="keydown($event)"
							/>
						</span>
					</div>
				</template>
				<template #empty>No Invoice found</template>
				<template #loading>Loading data</template>
				<Column header="No Travel Order" field="travel_order_no">
				</Column>
				<Column header="Name" field="guest_name"> </Column>
				<Column header="Start Date" field="start_date">
					<!-- <template #body="slotProps">
              {{
              new Date(slotProps.data.start_date).toLocaleDateString('id-ID', { day: 'numeric', month: 'short', year: 'numeric'}) }}
            </template> -->
				</Column>
				<Column header="End Date" field="end_date">
					<!-- <template #body="slotProps">
              {{
              new Date(slotProps.data.end_date).toLocaleDateString('id-ID', { day: 'numeric', month: 'short', year: 'numeric'}) }}
            </template> -->
				</Column>
			</DataTable>
		</div>
		<template #footer>
			<Button
				label="No"
				icon="pi pi-times"
				@click="closeModal"
				class="p-button-text"
			/>
			<Button
				label="Yes"
				icon="pi pi-check"
				@click="closeModal"
				autofocus
			/>
		</template>
	</Dialog>

	<Dialog
		header="Delete Reimburse"
		v-model:visible="deleteLineValue"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '50vw' }"
		:modal="true"
	>
		<div class="confirmation-content">
			<i
				class="pi pi-exclamation-triangle mr-3"
				style="font-size: 2rem"
			/>
			<span v-if="tableLineValue"
				>Are you sure you want to delete Line for
				<b>{{ tableLineValue.ITEM_DESCRIPTION }}</b> from
				Database?</span
			>
		</div>
		<template #footer>
			<Button
				label="No"
				icon="pi pi-times"
				class="p-button-text"
				@click="deleteLineValue = false"
			/>
			<Button
				label="Yes"
				icon="pi pi-check"
				class="p-button-text"
				@click="deleteTableLine"
			/>
		</template>
	</Dialog>
</template>

<script setup>
import { onMounted, ref, defineEmits, watch } from 'vue';
import AuthService from '@/service/AuthService';
import PoFulfillment from '@/service/PoFulfillment';
import LovService from '@/service/LovService';
import { useToast } from 'primevue/usetoast';
import ListInvoiceNonPo from '../../../service/ListInvoiceNonPo';
import { useRouter } from 'vue-router';

const router = useRouter();
const company = ref();
const supplier = ref(null);
// const idValue = ref()

const tableList = ref();
const page = ref(0);
const line = ref({});
const emit = defineEmits(['tableList', 'onDeleteReimburse']);
const displayModal = ref(false);
const getVAT = ref();
const tableListPopUp = ref();
const pageDisabled = ref(false);
// const filteredVAT = ref();
onMounted(async () => {
	company.value = props.company;
	const info = await AuthService.whoAmi();
	if (info.status === 200) {
		levelTenant.value = info.data.data.leveltenant;
		if (levelTenant.value !== 2) {
			pageDisabled.value = true;
			loading.value = true;
			supplier.value = props.supplier;
			await populateTable();
		} else {
			const param = {
				db_instance: props.company.db_instance,
				org_id: props.company.value,
			};
			const respVendor = await PoFulfillment.getSuppBindComp(
				info.data.data.id
			);
			const vendorName = respVendor.data.data[0][0].vendorname;
			const respSuppName = await LovService.getSupplierName(param);
			if (respSuppName.status === 200) {
				const suppList = respSuppName.data.data;
				for (const s of suppList) {
					if (s.vendor_name === vendorName) {
						supplier.value = s;
						break;
					}
				}
				if (supplier.value === null) {
					router.push('/dashboard');
					window.alert('Connection Unstable, ID not Recognize');
				}
			}
			await populateTable();
			// loading.value = true
		}
	}
});
// eslint-disable-next-line no-undef
const props = defineProps({
	company: {
		type: Object,
	},
	data: {
		type: Object,
	},
	line: {
		type: Object,
	},
	vendor_id: {
		type: Object,
	},
	org_id: {
		type: Object,
	},
	db_instance: {
		type: Object,
	},
	disabled: {
		type: Boolean,
	},
	detailCategory: {
		type: Object,
	},
	vendor_name: {
		type: Object,
	},
	invoice_date: {
		type: Date,
	},
	submit: {
		type: Boolean,
	},
	tableVuelidate: {
		type: Object,
	},
});
const toast = useToast();
const loading = ref(false);
const levelTenant = ref();
const payload = ref();
const deleteLineValue = ref();
const tableLineValue = ref();
const valuePopUP = ref();
// const index = ref()
watch(
	() => props.line,
	(newValue) => {
		if (newValue > 0) {
			addLine();
		}
	}
);
watch(
	() => props.invoice_date,
	(newValue) => {
		if (newValue) {
			getDropdownVat();
		}
	}
);
watch(
	() => props.tableVuelidate,
	(newValue) => {
		if (props.tableVuelidate !== null) {
			for (const i in tableList.value) {
				if (newValue[0]['EINVOICE_LINE_ID']) {
					if (newValue[i].AMOUNT[0] !== undefined) {
						tableList.value[i].RESPONSE = true;
					} else if (newValue[i].AMOUNT[0] === undefined) {
						tableList.value[i].RESPONSE = false;
					}
				} else {
					tableList.value[i].RESPONSE = false;
				}
			}
		}
	}
);
watch(
	() => props.tableVuelidate,
	(newValue) => {
		if (props.tableVuelidate !== null) {
			for (const i in tableList.value) {
				if (newValue[0]['EINVOICE_LINE_ID']) {
					tableList.value[i].VALIDATE_ITEM = false;
				} else {
					if (newValue[i].ITEM_DESCRIPTION[0] != undefined) {
						tableList.value[i].VALIDATE_ITEM = true;
					} else if (newValue[i].ITEM_DESCRIPTION[0] == undefined) {
						tableList.value[i].VALIDATE_ITEM = false;
					}
				}
			}
		}
	}
);
watch(
	() => props.submit,
	(newValue) => {
		if (newValue !== null) {
			for (const i in tableList.value) {
				tableList.value[i].RESPONSE = false;
				tableList.value[i].VALIDATE_ITEM = false;
			}
		}
	}
);
// watch(() => tableList.value, (newValue) => {
// })
const populateTable = async () => {
	loading.value = true;
	if (props.data !== null) {
		getDropdownVat();
		payload.value = {
			id: props.data ? props.data : null,
		};
		const data = await ListInvoiceNonPo.getDetailDataTable(payload.value);
		tableList.value = data.data.data.data;
		// console.log('table list', tableList.value)
		console.log('props', props);
		tableList.value.forEach((element) => {
			element.RESPONSE = false;
			element.VALIDATE_ITEM = false;
		});
		emit('tableList', tableList);
	}
	loading.value = false;
};
const populateTablePopUp = async () => {
	loading.value = true;
	if (props.supplier !== null) {
		const payload = {
			supplierName: props.vendor_name,
		};
		const data = await ListInvoiceNonPo.getDetailDataTravelOrder(payload);
		data.data.data.forEach((element) => {
			element.start_date = new Date(
				element.start_date
			).toLocaleDateString('en-US', {
				day: 'numeric',
				month: 'short',
				year: 'numeric',
			});
			element.end_date = new Date(element.end_date).toLocaleDateString(
				'en-US',
				{ day: 'numeric', month: 'short', year: 'numeric' }
			);
		});
		tableListPopUp.value = data.data.data;
	}
	loading.value = false;
};
const onPage = async (event) => {
	page.value = event.page * event.rows;
	// console.log('isi props', page.value);
};
const addLine = async () => {
	// console.log('porops', props.detailCategory.account_id)
	if (!props.disabled) {
		const data = await ListInvoiceNonPo.getListDetailCat(payload);
		if (data.status === 200) {
			let index;
			if (tableList.value.length === 0) {
				index = 1;
			} else {
				index = tableList.value.length - 1;
			}
			line.value.EINVOICE_LINE_ID = data.data.data.data[0].ID + index;
			// line.value.PO_LINE_ID = tableList.value.length
			line.value.ITEM_DESCRIPTION = props.detailCategory.category_detail;
			line.value.CATEGORY_DETAIL_ID =
				props.detailCategory.category_detail_id;
			line.value.COA_ID = props.detailCategory.account_id;
			line.value.AMOUNT = null;
			line.value.PERCENTAGE_RATE = null;
			line.value.ISNEW = true;
			let add = true;

			console.log(tableList.value, 'VAL')
			console.log(line.value, 'LINE VAL')

			tableList.value.forEach((element) => {
				if (
					element.ITEM_DESCRIPTION === line.value.ITEM_DESCRIPTION &&
					!props.forwarder
				) {
					toast.add({
						severity: 'error',
						summary: 'Cannot Add Line',
						detail: 'Category is already in Table',
						life: 3000,
					});
					throw add;
				} else {
					add = false;
				}
			});


			if (add) {
				toast.add({
					severity: 'error',
					summary: 'Cannot Add Line',
					detail: 'Category is already in Table',
					life: 3000,
				});
			} else {
				tableList.value.push(line.value);
				toast.add({
					severity: 'success',
					summary: 'Line Create',
					detail: 'Line Successfully Created',
					life: 3000,
				});
				line.value = {};
				emit('tableList', tableList);
			}
		} else {
			toast.add({
				severity: 'error',
				summary: 'Something Wrong',
				detail: 'Disconnected',
				life: 3000,
			});
		}
	} else {
		toast.add({
			severity: 'error',
			summary: 'Disabled',
			detail: "Can't add line",
			life: 3000,
		});
	}
};
const deleteLine = async (rowIndex) => {
	const payload = {
		idheader: parseInt(props.data),
		iddetail: rowIndex.EINVOICE_LINE_ID,
		value: false,
	};
	const data = await ListInvoiceNonPo.deleteLine(payload);
	console.log('show something', data, rowIndex.EINVOICE_LINE_ID);
	if (data.data.data.data.length !== 0) {
		tableLineValue.value = rowIndex;
		deleteLineValue.value = true;
		emit('tableList', tableList);
	} else {
		tableList.value = tableList.value.filter(
			(val) => val.EINVOICE_LINE_ID !== rowIndex.EINVOICE_LINE_ID
		);
		toast.add({
			severity: 'error',
			summary: 'Line Delete',
			detail: 'Line Successfully Deleted',
			life: 3000,
		});
		emit('tableList', tableList);

		// Delete reimburse table if deleted category is 'REIMBURSTMENT'
		if (rowIndex.ITEM_DESCRIPTION.includes('REIMBURSTMENT')) {
			emit('onDeleteReimburse', { value: false });
		}
	}
};
const deleteTableLine = async () => {
	const payload = {
		idheader: parseInt(props.data),
		iddetail: tableLineValue.value.EINVOICE_LINE_ID,
		value: true,
	};
	const data = await ListInvoiceNonPo.deleteLine(payload);
	if (data.status === 200) {
		deleteLineValue.value = false;
		tableList.value = tableList.value.filter(
			(val) =>
				val.EINVOICE_LINE_ID !== tableLineValue.value.EINVOICE_LINE_ID
		);
		toast.add({
			severity: 'error',
			summary: 'Line Delete',
			detail: 'Line Successfully Deleted',
			life: 3000,
		});
		emit('tableList', tableList);
		if (tableLineValue.value.ITEM_DESCRIPTION.includes('REIMBURSTMENT')) {
			emit('onDeleteReimburse', { value: true });
		}
	}
};

const refPopup = async (rowIndex) => {
	valuePopUP.value = rowIndex;
	displayModal.value = true;
	populateTablePopUp();
};

const closeModal = async () => {
	displayModal.value = false;
};
const getDropdownVat = async () => {
	const payload = {
		db_instance: props.db_instance,
		invoice_date: props.invoice_date,
	};
	const data = await ListInvoiceNonPo.getVAT(payload);
	getVAT.value = data.data.data.data;
	getVAT.value.push({
		PERCENTAGE_RATE: null,
		VAT_CODE: 'No Select',
		TAX_RATE_ID: null,
	});
};

// const searchVAT = (event) => {
//   setTimeout(() => {
//     if (!event.query.trim().length) {
//       filteredVAT.value = [...getVAT.value]
//     } else {
//       filteredVAT.value = getVAT.value.filter((comp) => {
//         return comp.VAT_COD.toLowerCase().match(event.query.toLowerCase());
//       })
//     }
//   }, 250);
// }
const onChange = async (event, index) => {
	if (
		tableList.value[page.value + index].AMOUNT === undefined ||
		tableList.value[page.value + index].AMOUNT === null
	) {
		tableList.value[page.value + index].VAT_CODE = null;
		toast.add({
			severity: 'error',
			summary: 'Empty Amount',
			detail: 'Empty Amount',
			life: 3000,
		});
	} else {
		if (event.value === null) {
			tableList.value[page.value + index].PERCENTAGE_RATE = null;
			tableList.value[page.value + index].VAT_CODE = null;
			tableList.value[page.value + index].VAT_CODE_ID = null;
			emit('tableList', tableList);
			console.log('isi table', tableList.value);
		} else {
			const payload = { id: event.value };
			const data = await ListInvoiceNonPo.getVATID(payload);
			if (data.status === 200) {
				tableList.value[page.value + index].PERCENTAGE_RATE =
					data.data.data.data[0].PERCENTAGE_RATE;
				(tableList.value[page.value + index].VAT_CODE =
					data.data.data.data[0].VAT_CODE),
					(tableList.value[page.value + index].VAT_CODE_ID =
						data.data.data.data[0].TAX_RATE_ID);
				emit('tableList', tableList);
				console.log('isi table', tableList.value);
			} else {
				toast.add({
					severity: 'error',
					summary: 'Data not Found',
					detail: 'Data not Found',
					life: 3000,
				});
			}
		}
	}
};
const onChangeText = (event, index) => {
	// console.log('index', index)
	if (tableList.value[page.value + index].PERCENTAGE_RATE !== null) {
		tableList.value[page.value + index].AMOUNT = event.value;
		emit('tableList', tableList);
	} else {
		tableList.value[page.value + index].AMOUNT = event.value;
		emit('tableList', tableList);
	}
};
const onRowSelect = (event) => {
	tableList.value[valuePopUP.value].REMARKS =
		event.data.travel_order_no +
		'#' +
		event.data.guest_name +
		'#' +
		event.data.start_date +
		'#' +
		event.data.end_date;
	displayModal.value = false;
};
// const onSort = async (event) => {
//   // loading.value = true
//   page.value = 1
//   rowsPerPage.value = event.rows;
//   sortColumn.value = event.sortField;
//   sortType.value = event.sortOrder;
//   await populateTable()
// }
</script>

<style scoped></style>
