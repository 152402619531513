<template>
  <div class="grid">
    <Toast />
    <div class="col-12 p-fluid">
      <div class="card">
        <div class="grid mb-4">
          <div class="col-11 md:col-11 lg:col-11">
            <div class="img-din">
              <h2 class="">CAPA</h2>
              <p>{{ dateNow }}</p>
            </div>
          </div>
          <div class="col-1 md:col-1 lg:col-1" v-if="!hideButton">
            <Button
              @click="closeWindow()"
              class="p-button-text p-button-plain w-full mb-3 lg:w-auto lg:mr-3 lg:mb-0"
              icon="pi pi-times "
            />
          </div>
        </div>
        <div class="content-wrapper">
          <div class="content-header">General Information</div>
          <div class="content">
            <div class="flex flex-row justify-content-between ml-3">
              <div class="col flex-column align-item-center log:col-auto">
                <div class="grid">
                  <div class="col-2 md:col-2 lg:col-2">
                    <label for="inputtext"
                      ><strong>Supplier Name</strong></label
                    >
                  </div>
                  <div class="col-8 md:col-8 lg:col-8">
                    <label class="strong" for="inputtext"
                      >: {{ vendorName }}</label
                    >
                  </div>
                </div>
                <div class="grid">
                  <div class="col-2 md:col-2 lg:col-2">
                    <label for="inputtext"><strong>Document No</strong></label>
                  </div>
                  <div class="col-8 md:col-8 lg:col-8">
                    <label class="strong" for="inputtext"
                      >: {{ documentNo }}</label
                    >
                  </div>
                </div>
                <div class="grid">
                  <div class="col-2 md:col-2 lg:col-2">
                    <label for="inputtext"><strong>DO Number</strong></label>
                  </div>
                  <div class="col-8 md:col-8 lg:col-8">
                    <label class="strong" for="inputtext"
                      >: {{ doNumber }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-wrapper">
          <div class="content-header">Finding Information</div>
          <div class="">
            <div class="flex flex-row justify-content-between ml-3">
              <div class="col flex-column align-item-center log:col-auto">
                <div class="grid">
                  <div class="col-2 md:col-2 lg:col-2">
                    <label for="inputtext"
                      ><strong>DR/Audit Date</strong></label
                    >
                  </div>
                  <div class="col-8 md:col-8 lg:col-8">
                    <label class="strong" for="inputtext"
                      >: {{ auditDate }}</label
                    >
                  </div>
                </div>
                <div class="grid">
                  <div class="col-2 md:col-2 lg:col-2">
                    <label for="inputtext"><strong>File</strong></label>
                  </div>
                  <div class="col-8 md:col-8 lg:col-8">
                    <label class="strong" for="inputtext"
                      >: {{ soFilename }}</label
                    >
                  </div>
                </div>
                <div class="grid">
                  <div class="col-2 md:col-2 lg:col-2">
                    <label for="inputtext"><strong>Subject</strong></label>
                  </div>
                  <div class="col-8 md:col-8 lg:col-8">
                    <label class="strong" for="inputtext"
                      >: {{ finding }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-wrapper">
          <div class="content-header">Response Information</div>
          <div class="">
            <div class="flex flex-row justify-content-between ml-3">
              <div class="col flex-column align-item-center log:col-auto">
                <div class="grid">
                  <div class="col-2 md:col-2 lg:col-2">
                    <label for="inputtext"
                      ><strong>Response File</strong></label
                    >
                  </div>
                  <div class="col-8 md:col-8 lg:col-8">
                    <label class="strong" for="inputtext"
                      >: {{ spFilename }}</label
                    >
                  </div>
                </div>
                <div class="grid">
                  <div class="col-2 md:col-2 lg:col-2">
                    <label for="inputtext"><strong>Response</strong></label>
                  </div>
                  <div class="col-8 md:col-8 lg:col-8">
                    <label class="strong" for="inputtext"
                      >: {{ respond }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-wrapper">
          <div class="content-header">Remarks</div>
          <div class="">
            <div class="flex flex-row justify-content-between ml-3 mb-4">
              <div class="col flex-column align-item-center log:col-auto">
                <div class="grid">
                  <div class="col-2 md:col-2 lg:col-2">
                    <label for="inputtext"><strong>Remarks</strong></label>
                  </div>
                  <div class="col-8 md:col-8 lg:col-8">
                    <label class="strong" for="inputtext"
                      >: {{ remarks }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-row justify-content-end">
              <div class="col flex-column align-item-right log:col-auto">
                <div class="grid" v-if="!hideButton">
                  <div class="col-3 md:col-3 lg:col-2 col-auto ml-auto">
                    <Button
                      label="Print CAPA"
                      @click="printNow()"
                      class="p-button-secondary w-full mb-3 lg:w-auto"
                      icon="pi pi-print "
                    />
                  </div>
                  <div class="col-3 md:col-3 lg:col-2">
                    <Button
                      label="Close"
                      @click="closeWindow()"
                      class="p-button-warning w-full mb-3 lg:w-auto"
                      icon="pi pi-times "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useToast } from "primevue/usetoast";
import SimpleCrypto from "simple-crypto-js";

import AuthService from "@/service/AuthService";
import { getCAPA } from "@/service/CAPAServices";
import LovService from "@/service/LovService";

const userInfo = ref();
const supplier = ref();
const documentNo = ref();
const doNumber = ref();
const finding = ref();
const toast = useToast();
const soFilename = ref();
const spFilename = ref();
const respond = ref();
const remarks = ref();
// const capa = ref();
const SOFileLink = ref();
const SPFileLink = ref();
const company = ref();
const dbInstance = ref();
const auditDate = ref();
const status = ref();
const hideButton = ref(false);
const dateNow = ref();
const vendorName = ref();

AuthService.whoAmi().then(async (response) => {
  if (response.status === 200) {
    userInfo.value = response.data.data;
    const respCbN = await LovService.getCompanyByName(userCompany());
    company.value = respCbN.data.data[0];
    dbInstance.value = company.value.db_instance;
    await getData();
    await DateNow();
  }
});

const DateNow = async () => {
  let option = {
    weekday: "long",
    day: "numeric",
    month: "short",
    year: "numeric",
    // hour: "numeric",
    // minute: "numeric",
  };
  // dateNow.value = new Date().toLocaleDateString("id-ID", option);
  dateNow.value = new Date().toLocaleString("en-US", option);
};

const userCompany = () => {
  const secretKey = "some-unique-key";
  const simpleCrypto = new SimpleCrypto(secretKey);
  return simpleCrypto.decrypt(sessionStorage.getItem("dropdownCompany"));
};

const getData = async () => {
  const id = sessionStorage.getItem("printCapaId");
  const resultData = await getCAPA(id);
  
  const result = resultData.data;
  
  SOFileLink.value = resultData.SOFileLink;
  SPFileLink.value = resultData.SPFileLink;
  // capa.value = result[0];
  const type = typeof result;
  if (type === "object") {
    documentNo.value = result[0].DOC_NUMBER;
    finding.value = result[0].FINDING;
    doNumber.value = result[0].DO_NUMBER;
    soFilename.value = result[0].SO_FILENAME
      ? result[0].SO_FILENAME
      : "no file attached";
    respond.value = result[0].RESPOND;
    spFilename.value = result[0].SP_FILENAME;
    remarks.value = result[0].REMARKS;
    status.value = result[0].STATUS;
    let option = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    auditDate.value = new Date(result[0].FINDING_DATE).toLocaleDateString(
      "id-ID",
      option
    );
    const payload = {
      db_instance: company.value.db_instance,
      org_id: company.value.value,
    };
    LovService.getSupplierName(payload).then((response) => {
      const type = typeof response.data.data;
      if (type === "string") {
        console.error("No supplier found");
      } else {
        const suppliers = response.data.data;
        suppliers.forEach((supp) => {
          if (supp.vendor_id === result[0].VENDOR_ID) {
            supplier.value = supp;
            vendorName.value = supp.vendor_name;
          }
        });
      }
    });
  } else {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: `${result}`,
      life: 3000,
    });
  }
};

const closeWindow = () => {
  window.close();
};

const printNow = () => {
  hideButton.value = true;
  setTimeout(actualPrintNow, 300);
};
const actualPrintNow = () => {
  if (hideButton.value === true) {
    window.print();
    if (document.hasFocus()) {
      hideButton.value = false;
    }
  }
};
</script>
