<template>
    <div class="grid">
        <div class="col-12 mb-5">
            <div class="content-wrapper">
                <div class="content-header">
                    search invoice w/po
                </div>

                <div class="content-body">
                    <div class="grid">
                        <div class="col-12 lg:col-3">
                            <span class="p-float-label">
                                <AutoComplete 
                                    id="company"
                                    :dropdown="true"
                                    placeholder="Company" 
                                    class="w-full"
                                    v-model="company"
                                    :disabled="true"
                                />
                                <label for="company">Company</label>
                            </span>
                        </div>

                        <div class="col-12 lg:col-3">
                            <span class="p-float-label">
                                <AutoComplete 
                                    id="status-list"
                                    :suggestions="filteredStatusList"
                                    :dropdown="true"
                                    class="w-full"
                                    field="name"
                                    v-model="status"
                                    @complete="searchStatusList($event)"
                                />
                                <label for="status-list">Status</label>
                            </span>
                        </div>

                        <div class="col-12 lg:col-3">
                            <div class="p-inputgroup">
                                <div class="p-float-label">
                                    <AutoComplete 
                                        id="status-list"
                                        :suggestions="filteredInvoiceNoList"
                                        class="w-full"
                                        field="INVOICE_NO"
                                        v-model="invoiceNo"
                                        @complete="searchInvoiceNo($event)"
                                    />
                                    <label for="status-list">Invoice No</label>
                                </div>
                            </div>     
                        </div>

                        <div class="col-12 lg:col-3">
                            <span v-if="isVendor === false" class="p-float-label">
                                <AutoComplete 
                                    id="supplier-list"
                                    :suggestions="filteredSupplierList"
                                    :dropdown="true"
                                    class="w-full"
                                    field="name"
                                    v-model="supplier"
                                    @complete="searchSupplier($event)"
                                />
                                <label for="supplier-list">Supplier</label>
                            </span>
                        </div>

                        <div class="col-12 lg:col-3">
                            <div class="grid">
                                <div class="col-12 lg:col-6">
                                    <Button class="p-button-success w-full">
                                        <span class="font-bold w-full">SEARCH</span>
                                    </Button>
                                </div>
                                <div class="col-12 lg:col-6">
                                    <Button class="p-button-secondary w-full">
                                        <span class="font-bold w-full">CLEAR</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 mb-5">
            <div class="content-wrapper">
                <div class="content-header">
                    Search result
                </div>
                <div class="content-body">
                    <DataTable
                        class="w-full"
                        :value="tableList"
                        scrollHeight="600px"
                        scrollDirection="both"
                        responsiveLayout="scroll"
                        :scrollable="true"
                    >
                        <Column header="Company" style="width:15%;" field="company">
                            <template #body="{data}">
                                <span>{{ data.company }}</span>
                            </template>
                        </Column>
                        <Column v-if="isVendor === false" header="Supplier" style="width:20%;" field="supplier">
                            <template #body="{data}">
                                <span>{{ data.supplier }}</span>
                            </template>
                        </Column>
                        <Column header="Invoice No" style="width:15%;" field="invoice_no">
                            <template #body="{data}">
                                <span>{{ data.invoice_no }}</span>
                            </template>
                        </Column>
                        <Column header="Invoice Date" style="width:10%;" field="invoice_date">
                            <template #body="{data}">
                                <span>{{ formatDate(data.invoice_date) }}</span>
                            </template>
                        </Column>
                        <Column header="Invoice Amount" style="width:15%;" field="invoice_amount">
                            <template #body="{data}">
                                <span>{{ formatCurrency(data.invoice_amount) }}</span>
                            </template>
                        </Column>
                        <Column header="Payment Method" style="width:12%;" field="payment_method">
                            <template #body="{data}">
                                <span>{{ (data.payment_method) }}</span>
                            </template>
                        </Column>
                        <Column header="Service Amount" style="width:15%;" field="service_amount">
                            <template #body="{data}">
                                <span>{{ formatCurrency(data.service_amount) }}</span>
                            </template>
                        </Column>
                        <Column header="Status" style="width:15%;" field="status">
                            <template #body="{data}">
                                <Chip
                                    class="custom-chip"
                                    :class="`chip-${data.status.toLowerCase()}`"
                                >
                                    {{ data.status }}
                                </Chip>
                            </template>
                        </Column>
                        <Column header="Tax No" style="width:15%;" field="tax_no">
                            <template #body="{data}">
                                <span>{{ (data.tax_no) }}</span>
                            </template>
                        </Column>
                        <Column header="Tax Amount" style="width:15%;" field="tax_amount">
                            <template #body="{data}">
                                <span>{{ formatCurrency(data.tax_amount) }}</span>
                            </template>
                        </Column>
                        <Column header="Tax Date" style="width:15%;" field="tax_date" :sortable="true">
                            <template #body="{data}">
                                <span>{{ formatDate(data.tax_date) }}</span>
                            </template>
                        </Column>
                        <Column header="Total Amount" style="width:15%;" field="total_amount">
                            <template #body="{data}">
                                <span>{{ formatCurrency(data.total_amount) }}</span>
                            </template>
                        </Column>

                        <Column
                            header="Actions"
                            style="width: 10%"
                            alignFrozen="right"
                            frozen
                        >
                            <template #body="{data}">
                                <Button
                                    icon="pi pi-eye"
                                    class="p-button-rounded p-button-text"
                                    @click="handleClick(data.id, data.po_type)"
                                />
                            </template>
                        </Column>

                        <template #empty>
                            Data Not Found
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>


    </div>
</template>

<script setup>
    import {onMounted, ref} from 'vue'
    import AuthService from '../../service/AuthService';
    import LovService from '../../service/LovService';
    import SimpleCrypto from 'simple-crypto-js';
    import { fakeInvoiceNo, ptListMapper, fakeTable } from '../searchinvoicepo/fakeInvoice';
    import { formatDate } from '../../helper/formatDate';
import { formatCurrency } from '../../helper/formatCurrency';

    const tableList = ref([]);

    const company = ref()
    const companies = ref();

    const status = ref();
    const statusList = ref([
        {id:1, name: 'NEW'},
        {id:2, name: 'PROCESSED'},
        {id:3, name: 'POSTED'},
        {id:4, name: 'DRAFT'}
    ]);
    const filteredStatusList = ref([]);

    const invoiceNo = ref();
    const invocieNoList = ref([]);
    const filteredInvoiceNoList = ref([]);

    
    const supplier = ref();
    const supplierList = ref([]);
    const filteredSupplierList = ref([]);

    const isVendor = ref(true);


    AuthService.whoAmi().then(async () => {
        function userCompany() {
            const secretKey = 'some-unique-key';
            const simpleCrypto = new SimpleCrypto(secretKey);
            return simpleCrypto.decrypt(
                sessionStorage.getItem('dropdownCompany')
            );
        }
        const respCbN = await LovService.getCompanyByName(
            userCompany()
        );
        companies.value = respCbN.data.data[0];
        company.value = respCbN.data.data[0].name

        tableList.value = fakeTable(respCbN.data.data[0].name);
    });


    onMounted(() => {

        console.log("TENANT LEVEL")
        invocieNoList.value = fakeInvoiceNo(10)
        supplierList.value = ptListMapper();

        console.log(sessionStorage.getItem('leveltenant'), "LEVELL")
        isVendor.value = Number(sessionStorage.getItem("leveltenant")) === 2 ? true : false;
        console.log(isVendor.value, "VALUE VEN")
    })

    const searchStatusList = (event) => {
        filteredStatusList.value = statusList.value.filter((item) => item.name.toLowerCase().includes(
            event.query.toLowerCase()
        ))
    }

    const searchInvoiceNo = (event) => {
        filteredInvoiceNoList.value = invocieNoList.value.filter((item) => item.INVOICE_NO.toLowerCase().includes(
            event.query.toLowerCase()
        ))
    }

    const searchSupplier = (event) => {
        filteredSupplierList.value = supplierList.value.filter((item) => item.name.toLowerCase().includes(
            event.query.toLowerCase()
        ))
    }


</script>

<style scoped>
    .p-chip.custom-chip {
        padding: 4px 12px;
        border-radius: 8px;
        font-size: 12px;
        font-weight: 600;
    }

    .chip-open {
        background: #0ea5e9;
        color: #fff;
    }
    .chip-rejected {
        background: var(--primary-color);
        color: #fff;
    }
    .chip-review {
        background: #f59e0b;
        color: #fff;
    }
    .chip-posted {
        background: #10b981;
        color: #fff;
    }
    .chip-processed {
        background: #2563eb;
        color: #fff;
    }
</style>
