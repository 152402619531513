<template>
    <div v-if="!groupStatus">
            <Message severity="error">Selected Module Required</Message>
    </div>
    <div v-if="!nameStatus">
            <Message severity="error">Group Name Required</Message>
    </div>
    <div class="card">
        <div style="padding-left: 6%">
            <span class="ml-2" >
                <InputText id="groupName" type="text" v-model="groupName" placeholder="Group Name" />
            </span>
            <span class="ml-2" >
                <InputText id="description" type="text" v-model="description" placeholder="Description" onkeydown="this.style.width = (this.value.length + 12 ) + 'ch';" />
            </span>
        </div>
    </div>

    

    <div v-if="!showMenu">
        <div class="card">
        
            <PickList v-model="groupList"  listStyle="height:342px" dataKey="id">
                <template #sourceheader>
                    Module
                </template>
                <template #targetheader>
                    Selected Module
                </template>
                <template #item="slotProps">
                    <div class="product-item">
                        <div class="pi pi-folder" >
                        </div>
                        <div class="product-list-detail">
                            <h6 class="mb-2">{{slotProps.item.modulename}}</h6>
                        </div>
                    </div>
                </template>
            </PickList>
            
        </div>
        <div  style="text-align:right;">
                    <Button type="button" label="Next >" icon="pi pi-plus" :loading="loading[0]" @click="getMenu()" />
        </div>
    </div>

    <div v-if="showMenu">
        <div class="card" >
            <PickList v-model="menuList"  listStyle="height:342px" dataKey="id">
                <template #sourceheader>
                    All Menus
                </template>
                <template #targetheader>
                    Selected Menu(s)
                </template>
                <template #item="slotProps">
                    <div class="product-item">
                        <div class="pi pi-folder" >
                        </div>
                        <div class="product-list-detail">
                            <h6 class="mb-2">{{slotProps.item.menuName}}</h6>
                            <i class="pi pi-tag product-category-icon"></i>
                            <span class="product-category">{{slotProps.item.moduleName}}</span>
                        </div>
                    </div>
                </template>
            </PickList>
        </div>
        <div  style="text-align:right;">
                    <Button type="button" label="Submit" icon="pi pi-plus" :loading="loading[0]" @click="submit(0)" />
            </div>
    </div>
</template>

<script>
import ApplicationGroupService from '../../../service/ApplicationGroupService';
import AuthService from '../../../service/AuthService';

export default {
    data() {
        return {
            groupList: null,
            menuList: null,
            idValue: null,
            userInfo: null,
            tokenID: null,
            groupService: null,
            apps: null,
            groupName: null,
            description: null,
            loading: [false],
            showMenu: false,
            groupStatus: true,
            nameStatus: true,
            groupId: null,
        }
    },
    created() {
        AuthService.whoAmi().then((value) => {
            this.userInfo = value.data;
            this.tokenID = value.data.tokenId;
            this.apps = this.userInfo.data.apps;
            this.idValue= this.$route.params.id;
            if(this.idValue != "add"){
                this.groupId = this.idValue;
                
                this.groupId = this.idValue || '';
                ApplicationGroupService
                    .getGroupDetail(this.groupId)
                    .then((value) => {
                    const groupObj = value.data.data.result;
                    this.groupName = groupObj.group.groupname;
                    this.description = groupObj.group.description;
                    let groupModule = groupObj.modules;
                    if(value.status == 200){
                    ApplicationGroupService
                        .getAllModules(this.apps[0].idapp)
                        .then((modulesresult) => {
                        let groupListTmp = modulesresult.data.data;
                        groupModule.forEach((elementdst) => {
                            var ItemIndex = groupListTmp.findIndex(
                            (b) => b.id === elementdst.id
                            );
                            groupListTmp.splice(ItemIndex, 1);
                        });
                        this.groupList = [groupListTmp, groupModule];
                        });
                    }
                });           
            }else{
                ApplicationGroupService
                    .getAllModules(this.apps[0].idapp)
                    .then((modulesresult) => {
                    this.groupList = [modulesresult.data.data, []];
                });
            }
        })
    },
    methods:{
        getChecked(){
            if((this.groupList[1].length === 0)){
                this.groupStatus = false;
            } else {
                this.groupStatus = true;
            }

            if(this.groupName === null){
                this.nameStatus = false;
            } else {
                this.nameStatus = true;
            }

        },
        getMenu(){
            this.getChecked();
            if(this.groupStatus === true && this.nameStatus === true){
                this.showMenu = true;
                let menusTmp = [];
                let menus= [];
                let selectedMenu= [];
                ApplicationGroupService.menus = [];
                for(let module of this.groupList[1] ){

                    if(this.idValue === "add" || this.groupId === null){
                        ApplicationGroupService.getAllMenuByModuleId(module.id).then(async (value) => {
                            let menusobj = value.data.data.menus;

                            await menusobj.map((jsobj) => {
                                menusTmp.push(jsobj);
                            });

                            await menusTmp.map(async (menut) => {
                                let data={};
                                let acl={};
                                
                                data.menuId=menut.id;
                                data.menuName=menut.title;
                                // data.moduleId = menut.idmodule;value.data
                                data.moduleId=module.id;
                                // data.moduleName = menu.modulename;value.data
                                data.moduleName=module.modulename;
                                acl={
                                    read: 1,
                                    create: 1,
                                    update: 1,
                                    delete: 1,
                                };
                                data.acl=acl;
                                menusTmp=[];
                                menus.push(data);
                                ApplicationGroupService.menus.push(data);
                            });
                            this.menuList = [ApplicationGroupService.menus, []];
                            if(this.groupName == null){
                                this.checked = false;
                            }
                        })
                    } else {
                       ApplicationGroupService.getAllMenuByModuleIdAndGroupId(module.id, this.groupId).then(async (value) => {
                            
                            let menusobj = value.data.data.menus;

                            await menusobj.map((jsobj) => {
                                menusTmp.push(jsobj);
                            });

                            await menusTmp.map(async (menut) => {
                                let data={};
                                let acl={};
                                
                                data.menuId=menut.id;
                                data.menuName=menut.title;
                                // data.moduleId = menut.idmodule;value.data
                                data.moduleId=module.id;
                                // data.moduleName = menu.modulename;value.data
                                data.moduleName=module.modulename;
                                acl={
                                    read: 1,
                                    create: 1,
                                    update: 1,
                                    delete: 1,
                                };
                                data.acl=acl;
                                if(menut.fread === 1){
                                    selectedMenu.push(data);
                                } else {
                                    menus.push(data);
                                }
                                menusTmp=[];
                                ApplicationGroupService.menus.push(data);
                            
                            });
                            
                            this.menuList = [menus, selectedMenu];
                            if(this.groupName == null){
                            this.checked = false;
                            }
                        }) 
                    }
                    
                }   
            }
            
        },
        submit(index){
            this.loading[index] = true;
            setTimeout(() => this.loading[index] = false, 1000);
            this.$router.push({name: 'groupListDetails', params: { id: this.idValue }});
            let reqData = {};
            // await this.setupModuleReq();
            // reqData.
            // let group = {"groupName": this.orgForm.get('organizationname').value, "groupType":1};
            let group = { groupName: this.groupName, groupType: 1, description: this.description };
            ApplicationGroupService.groupSetupData.group = group;
            reqData.group = ApplicationGroupService.groupSetupData.group;
            reqData.modules = this.groupList[1];
            reqData.menus = this.menuList[1];
            ApplicationGroupService.reqGroup = reqData;
            ApplicationGroupService.haveModuleAndMenus = true;
            
            if(this.idValue === "add"){
                ApplicationGroupService
                .regisGroup(ApplicationGroupService.reqGroup)
                .then((result) => {
                    if (result.status === 200) {
                        ApplicationGroupService.modules = [];
                        ApplicationGroupService.reqGroup = [];
                        this.$router.go(-1);
                    }
                });
            }else{
                ApplicationGroupService.reqGroup.group.groupId = this.groupId;
                ApplicationGroupService
                    .editGroup(ApplicationGroupService.reqGroup)
                    .then((result) => {
                    if (result.status === 200) {
                        ApplicationGroupService.modules = [];
                        ApplicationGroupService.reqGroup = [];
                        this.$router.go(-1);
                    }
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.product-item {
	display: flex;
	align-items: center;
	padding: .5rem;
	width: 100%;

	img {
		width: 75px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        margin-right: 1rem;
	}

	.product-list-detail {
		flex: 1 1 0;
	}

	.product-list-action {
		display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .product-category-icon {
        vertical-align: middle;
        margin-right: .5rem;
        font-size: .875rem;
    }

    .product-category {
        vertical-align: middle;
        line-height: 1;
        font-size: .875rem;
    }
}

@media screen and (max-width: 576px) {
    .product-item {
        flex-wrap: wrap;

        .image-container {
            width: 100%;
            text-align: center;
        }

        img {
            margin: 0 0 1rem 0;
            width: 100px;
        }
    }
}
</style>