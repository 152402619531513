<template>
	<div class="Grid">
		<Toast />

		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<!--  Search  -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search Invoice Proxy Non PO</div>
				<div class="content-body">
					<div class="col-12 p-fluid">
						<div class="grid formgrid" style="margin: 1em 0 0 0">
							<div class="col-3 mb-5 lg:lcol-3">
								<span class="p-float-label">
									<CompaniesDropdown
										v-model="company"
										@itemSelect="onSelectCompany($event)"
										class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
										:disabled="true"
									/>
									<!-- <label for="inputtext">Select a company</label> -->
								</span>
							</div>
							<div class="col-3 mb-5 lg:lcol-3">
								<span class="p-float-label">
									<SupplierNonPoDropdown
										:disabled="
											userInfo
												? userInfo.leveltenant === 2
												: false
										"
										v-model="supplier"
										:company="company ? company : null"
										:userId="userId ? userId : null"
										:levelTenant="
											levelTenant ? levelTenant : null
										"
										@itemSelect="onSelectSupplier($event)"
										class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
										:key="supplierReload"
									/>
									<!-- <label for="inputtext">Select a company</label> -->
								</span>
							</div>
							<div class="col-3 mb-5 lg:lcol-3">
								<span class="p-float-label">
									<DropdownBatchNumber
										v-model="batchNumber"
										:company="company ? company : null"
										:vendorId="vendorId ? vendorId : null"
										:invoiceNumber="
											invoiceNumber ? invoiceNumber : null
										"
										:statusInvoice="
											statusInvoice ? statusInvoice : null
										"
										:levelTenant="
											levelTenant ? levelTenant : null
										"
										@itemSelect="
											onSelectBatchNumber($event)
										"
										:key="batchNumberReload"
									></DropdownBatchNumber>
									<!-- <label for="inputtext">Status</label> -->
								</span>
							</div>
							<div class="col-3 mb-5 lg:lcol-3">
								<span class="p-float-label">
									<DropdownInvoiceNumber
										class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
										v-model="invoiceNumber"
										:company="company ? company : null"
										:vendorId="vendorId ? vendorId : null"
										:batchNumber="
											batchNumber ? batchNumber : null
										"
										:statusInvoice="
											statusInvoice ? statusInvoice : null
										"
										:levelTenant="
											levelTenant ? levelTenant : null
										"
										@itemSelect="
											onSelectInvoiceNumber($event)
										"
										:key="invoiceNumberReload"
									></DropdownInvoiceNumber>
									<!-- <label for="inputtext">Status</label> -->
								</span>
							</div>
							<div class="col-1">
								<Button
									label="Search"
									class="p-button-success uppercase"
									@click="goClick"
									:disabled="disabledButton"
								/>
							</div>
							<div class="col-1">
								<Button
									label="Clear"
									class="p-button-secondary uppercase"
									@click="clearClick"
									:resetSearch="true"
									:disabled="disabledButton"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search Result</div>
				<div class="content-body">
					<div class="grid">
						<div class="col-12">
							<TableInvoiceProxy
								:company="company ? company : null"
								:vendorId="vendorId ? vendorId : null"
								:batchNumber="batchNumber ? batchNumber : null"
								:invoiceNumber="
									invoiceNumber ? invoiceNumber : null
								"
								:statusInvoice="
									statusInvoice ? statusInvoice : null
								"
								:searchAction="
									searchAction ? searchAction : null
								"
								:key="tableReload"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
// import router from "@/router";
import { ref, watch } from 'vue';
import SimpleCrypto from 'simple-crypto-js';

import AuthService from '@/service/AuthService';
import LovService from '@/service/LovService';
import invoiceProxy from '../../service/InvoiceProxyService';
// import PoFulfillment from "@/service/PoFulfillment";

import CompaniesDropdown from '../components/CompaniesDropdown.vue';
// import SupplierDropdown from "../components/SupplierDropdown.vue";
import DropdownBatchNumber from './components/DropdownBatchNumber.vue';
import DropdownInvoiceNumber from './components/DropdownInvoiceNumber.vue';
import TableInvoiceProxy from './components/TableInvoiceProxy.vue';
import SupplierNonPoDropdown from './components/SupplierNonPoDropdown.vue';

const breadcrumbHome = { icon: 'pi pi-home', to: '/dashboard' };
const breadcrumbItems = [
	{ label: 'SEARCH INVOICE PROXY', to: './searchinvoicenonproxy' },
];
const company = ref();
const supplier = ref();
const supplierReload = ref(0);
const userInfo = ref();
const levelTenant = ref();
const vendorId = ref();
const invoiceNumber = ref();
const invoiceNumberReload = ref(0);
const statusInvoice = ref();
const batchNumber = ref();
const batchNumberReload = ref(0);
const tableReload = ref(0);
const searchAction = ref(false);
const supplierList = ref([]);

const userId = ref();

const userCompany = () => {
	const secretKey = 'some-unique-key';
	const simpleCrypto = new SimpleCrypto(secretKey);
	return simpleCrypto.decrypt(sessionStorage.getItem('dropdownCompany'));
};

watch(
	() => company.value,
	() => {
		// supplier.value = null;
		supplierReload.value++;
		batchNumberReload.value++;
		invoiceNumberReload.value++;
		tableReload.value++;
	}
);

watch(
	() => company.value && vendorId.value,
	() => {
		batchNumberReload.value++;
		invoiceNumberReload.value++;
	}
);

watch(
	() => company.value && batchNumber.value,
	() => {
		invoiceNumberReload.value++;
	}
);

// watch(
//   () => company.value && invoiceNumber.value,
//   () => {
//     batchNumberReload.value++;
//   }
// );

watch(
	() => company.value && supplier.value && batchNumber.value,
	() => {
		invoiceNumberReload.value++;
	}
);

// watch(
//   () => company.value && supplier.value && invoiceNumber.value,
//   () => {
//     batchNumberReload.value++;
//   }
// );

AuthService.whoAmi().then(async (response) => {
	userInfo.value = response.data.data;
	userId.value = response.data.data.id;
	if (response.status === 200) {
		levelTenant.value = response.data.data.leveltenant;
		const respCbN = await LovService.getCompanyByName(userCompany());

		const companyTemp = respCbN.data.data[0];

		console.log(respCbN.data, "TEMP")

		company.value = companyTemp;

		if (levelTenant.value == 3) {
			statusInvoice.value = 'OPEN';
			const result = await invoiceProxy.getvendorbytenant(
				userId.value,
				company.value.name,
				company.value.db_instance,
				company.value.value,
				levelTenant.value
			);

			for (const supp of result.data) {
				supplierList.value.push(supp.vendor_id);
			}
			if (supplierList.value.length > 0) {
				vendorId.value = supplierList.value;
			}
		} else {
			statusInvoice.value = 'PROCESSED';
		}

		// const param = {
		//   db_instance: companyTemp.db_instance,
		//   org_id: companyTemp.value,
		// };
		// const respVendor = await PoFulfillment.getSuppBindComp(userInfo.value.id);
		//
		// const vendorName = respVendor.data.data[0][0].vendorname;
		// const respSuppName = await LovService.getSupplierName(param);
		// if (respSuppName.status === 200) {
		//   const suppList = respSuppName.data.data;
		//   for (const s of suppList) {
		//     if (s.vendor_name === vendorName) {
		//       supplier.value = s;
		//       break;
		//     }
		//   }
		// }
	}
});

const onSelectCompany = (event) => {
	company.value = event.value;
	supplier.value = null;
	vendorId.value = null;
	batchNumber.value = null;
	invoiceNumber.value = null;
};

const onSelectSupplier = (event) => {
	supplier.value = event.value;
	vendorId.value = event.value.vendor_id;
	batchNumber.value = null;
	invoiceNumber.value = null;
};

const onSelectBatchNumber = (event) => {
	batchNumber.value = event.value.BATCH_NO;
	invoiceNumber.value = null;
};

const onSelectInvoiceNumber = (event) => {
	invoiceNumber.value = event.value.INVOICE_NO;
};

const goClick = async () => {
	tableReload.value++;
	searchAction.value = true;
	// if ((column.value === undefined &&  (columnSearch.value === undefined || columnSearch.value === ''))) {
	//     reload.value++;
	//   } else if ((column.value === undefined &&  (columnSearch.value !== undefined || columnSearch.value !== '')) ||
	//       (column.value !== undefined &&  (columnSearch.value === undefined || columnSearch.value === ''))) {
	//     toast.add({severity:'info', summary: 'Kolom kriteria dan nilai kosong', detail:'Pilih kolom kriteria dan isi nilai untuk kolom sebelum tekan tombol Go', life: 3000});
	//   } else {
	//     reload.value++;
	// }
};
const clearClick = async () => {
	searchAction.value = false;
	invoiceNumber.value = null;
	batchNumber.value = null;

	supplier.value = null;
	if (levelTenant.value === 3) {
		if (supplierList.value.length > 0) {
			vendorId.value = supplierList.value;
		}
	} else {
		vendorId.value = null;
	}

	tableReload.value++;
};
</script>

<style scoped></style>
