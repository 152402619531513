import SpbSuppMan from "./api/SpbSupManApi";

class SpecAndQuotation {
    async getListVendor(payload) {
        return SpbSuppMan.post(`specQuot/listvendor`, payload);
    }
    async getDataTable(payload) {
        return SpbSuppMan.post(`specQuot/searchSpecQuotation`, payload);
    }
    async getDataUom(payload) {
        return SpbSuppMan.post(`specQuot/listuom`, payload);
    }
    async getDataSpecQuotation(id) {
        return SpbSuppMan.get("specQuot/" + id);
    }
    async listItems(payload) {
        return SpbSuppMan.post(`specQuot/listItems`, payload);
    }
    async itemExist(payload) {
        return SpbSuppMan.post(`specQuot/itemExist`, payload);
    }
    async handleSave(payload) {
        return SpbSuppMan.post(`specQuot/create`, payload);
    }
    async updateSpecAndQuotation(id, payload) {
        return SpbSuppMan.post(
          `specQuot/updatespec/${id}`,
          payload
        );
    }
    async getAttachmentFile(id) {
        return SpbSuppMan.get(`/specQuot/attachmentfile/${id}`);
    }
    async getSearchExcell(payload) {
        return SpbSuppMan.post(`/specQuot/searchSpecQuotationExcell`, payload);
    }
    async popUpToExcell(payload) {
        return SpbSuppMan.post(`/specQuot/itemPopUpExcell`, payload);
    }
}

export default new SpecAndQuotation();
