<template>
	<div class="grid">
		<Toast />

		<ConfirmDialog />

		<!-- Content -->
		<div class="col-12 mb-2" v-if="poItems">
			<div class="content-wrapper">
				<div class="content-header">Shipment Information</div>
				<div class="content-body">
					<!-- DO Number -->
					<p
						class="text-base text-black-alpha-90 mb-6"
						v-if="doNumber"
					>
						<span class="mr-2">DO Number:</span>
						<span class="font-bold">{{ doNumber }}</span>
					</p>

					<!-- Input field -->
					<div class="grid align-items-center">
						<div class="col-12 md:col-6 lg:col-4">
							<span class="p-float-label">
								<Dropdown
									class="w-full"
									:options="documentTypes"
									optionLabel="asn_doc_desc"
									v-model="selectedDocType"
								/>
								<label for="docType"
									>Select Document Type</label
								>
							</span>
						</div>
						<div>
							<Button
								class="p-button-success px-5 ml-3"
								:loading="isLoading"
								:disabled="!selectedFile || !selectedDocType"
								@click="submitDocument"
							>
								<span class="uppercase font-bold">Save</span>
							</Button>
						</div>
					</div>

					<div class="mb-6 mt-2">
						<FileUpload
							ref="fileUpload"
							mode="advanced"
							chooseLabel="Upload Document"
							fileLimit="1"
							accept="application/pdf"
							:showUploadButton="false"
							:showCancelButton="false"
							:customUpload="true"
							:maxFileSize="5000000"
							@select="onSelectFile"
							@remove="onRemoveFile"
						/>
					</div>

					<!-- Table result -->
					<div class="mb-4">
						<!-- Loading status -->
						<div
							class="mb-3 flex align-items-center justify-content-start"
							v-if="isLoading"
						>
							<p class="mb-0 mr-3">{{ loadingMessage }}</p>
							<span>
								<ProgressSpinner
									style="width: 20px; height: 20px"
									strokeWidth="6"
								/>
							</span>
						</div>

						<!-- Table Doc -->
						<DataTable
							responsiveLayout="scroll"
							:value="documents"
							dataKey="no"
							editMode="row"
							v-model:editingRows="editingRows"
							@row-edit-save="onRowEditSave"
							@row-edit-cancel="onRowEditCancel"
						>
							<Column header="No" field="no"></Column>
							<Column
								header="Document Type"
								field="doc_type_name"
							>
							</Column>
							<Column header="File" field="filename">
								<template #editor="{ data }">
									<span
										class="inline-block text-gray-600 mb-3"
										>{{ data.filename }}</span
									>
									
									<FileUpload
										ref="updatedFileUpload"
										class="p-button-sm"
										mode="basic"
										chooseLabel="Upload New Document"
										uploadIcon="pi pi-times"
										fileLimit="1"
										accept="application/pdf"
										@select="onSelectUpdatedFile"
										:maxFileSize="5000000"
										:disabled="isFileUploadDisabled"
										:class="{
											isDisabled: isFileUploadDisabled,
										}"
									/>
								</template>
							</Column>
							<Column
								header="Upload Date"
								field="creation_date"
							></Column>
							<Column
								:rowEditor="true"
								style="min-width: 3rem"
								bodyStyle="text-align: center"
							>
							</Column>
							<Column
								style="min-width: 3rem"
								bodyStyle="text-align: center"
							>
								<template #body="slotProps">
									<Button
										class="p-button-text p-button-rounded"
										icon="pi pi-trash"
										@click="
											deleteDocument(
												slotProps.data.asn_doc_id,
												slotProps.data
													.shipment_header_id
											)
										"
									/>
								</template>
							</Column>

							<!-- Empty statement -->
							<template #empty> No documents found. </template>
						</DataTable>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CreateShipmentService from '../../../service/CreateShipmentService';

export default {
	name: 'UploadDocument',
	props: ['shipmentHeaderId', 'doNumber', 'poItems', 'parentDocuments'],
	data() {
		return {
			currentUserId: null,
			documents: null,
			documentTypes: null,
			editingRows: null,
			selectedDocType: null,
			selectedFile: null,
			updatedDoc: null,
			isLoading: null,
			loadingMessage: null,
			isFileUploadDisabled: null,
		};
	},
	async created() {
		this.levelTenant = sessionStorage.getItem("leveltenant");
		if (this.levelTenant == 2) {
			this.vendorId = sessionStorage.getItem("vendorId");
		}
		this.currentUserId = sessionStorage.getItem('idUser');

		await this.getDcoumentTypes();
		// await this.getDocuments();

		// Manipulate documents state
		let number = 0;

		this.documents =
			this.parentDocuments &&
			this.parentDocuments.map((doc) => {
				number++;

				const creationDate = new Date(doc.CREATION_DATE).toLocaleString(
					'id-ID',
					{
						day: 'numeric',
						month: 'short',
						year: 'numeric',
					}
				);

				const docTypeName = this.documentTypes.find(
					(type) => type.asn_doc_type === doc.ASN_DOC_TYPE
				)['asn_doc_desc'];

				return {
					no: number,
					asn_doc_id: doc.ASN_DOC_ID,
					asn_doc_type: doc.ASN_DOC_TYPE,
					shipment_header_id: doc.SHIPMENT_HEADER_ID,
					creation_date: creationDate,
					filename: doc.FILENAME,
					remark: doc.REMARK,
					validity: doc.VALIDITY,
					doc_type_name: docTypeName,
				};
			});
	},
	computed: {
		disableFileUpload() {
			return false;
		},
	},
	methods: {
		async getDcoumentTypes() {
			const fobList = this.poItems.map((item) => item.fob);
			const uniqueFob = fobList.filter(
				(value, index, self) => self.indexOf(value) === index
			);

			try {
				const res = await CreateShipmentService.getDcoumentTypes({
					fobList: uniqueFob,
				});
				if (res.status !== 200) {
					throw new Error('Failed get list of document types');
				}

				this.documentTypes = res.data.data;
			} catch (err) {
				console.log(err);
			}
		},
		deleteDocument(docId, headerId) {
			const params = {
				headerId,
				docId,
			};

			this.$confirm.require({
				message: 'Are you sure you want to delete this document?',
				header: 'Confirmation',
				icon: 'pi pi-exclamation-triangle',
				accept: async () => {
					const res = await CreateShipmentService.removeFileDocument(
						params
					);

					if (res.status === 200) {
						const updatedDocs = this.documents.filter(
							(doc) => doc.asn_doc_id !== docId
						);

						let number = 0;
						this.documents = updatedDocs.map((doc) => {
							number++;
							return { ...doc, no: number };
						});

						this.$toast.add({
							severity: 'success',
							summary: 'Document was successfully removed',
							life: 3000,
						});
						// location.reload();

						// Update document state without reload page
						this.$emit('onDelete', params.docId);
					}
				},
			});
		},
		async onRowEditSave(event) {
			const { data, index } = event;

			// Check if there is document selected
			if (this.$refs.updatedFileUpload.files[0]) {
				// File query params
				const params = {
					headerId: data.shipment_header_id,
					docId: data.asn_doc_id,
				};

				// Body Payloadc
				const formData = new FormData();
				formData.append('file', this.$refs.updatedFileUpload.files[0]);
				formData.append('userId', this.currentUserId);
				formData.append('asnDocType', data.asn_doc_type);
				formData.append('validity', data.validity);
				formData.append('remark', data.remark);
				formData.append('password', this.vendorId);

				// Update document
				try {
					this.isLoading = true;
					this.loadingMessage = 'Updating document...';

					const res = await CreateShipmentService.updateFileDocument(
						params,
						formData
					);

					if (res.status != 200) {
						throw Error('Error: Could not update document file');
					}

					this.documents[index]['filename'] = res.data.data.fileName;
					this.isLoading = false;
					this.loadingMessage = null;
					this.isFileUploadDisabled = false;

					this.$toast.add({
						severity: 'success',
						summary: 'Document was successfully updated',
						life: 3000,
					});

					// Update document state on parent component
					this.$emit('onUpdate', {
						id: params.docId,
						filename: res.data.data.fileName,
					});
				} catch (err) {
					console.log(err);
					this.isLoading = false;
					this.loadingMessage = null;
					this.isFileUploadDisabled = false;
				}
			}
		},
		onRowEditCancel() {
			console.log('Cancelled');
			this.isFileUploadDisabled = false;
		},
		onSelectUpdatedFile() {
			this.isFileUploadDisabled = true;
		},
		onSelectFile(e) {
			this.selectedFile = e.files[0];
		},
		onRemoveFile(e) {
			console.log(e.files);
			if (!e.files.length) {
				this.selectedFile = null;
			}
		},
		async submitDocument() {
			let formData = new FormData();
			formData.append('file', this.selectedFile);
			formData.append('headerId', this.shipmentHeaderId);
			formData.append('userId', this.currentUserId);
			formData.append('asnDocType', this.selectedDocType.asn_doc_type);
			formData.append('validity', 0);
			formData.append('remark', '');
			formData.append('password', this.vendorId);
			// Uploading file
			this.isLoading = true;
			this.loadingMessage = 'Saving document...';

			try {
				const res = await CreateShipmentService.sendFileDocument(
					formData
				);

				if (res.status === 200) {
					// Add new doc to table
					const number = this.documents.length + 1;
					const docTypeName = this.documentTypes.find(
						(type) => type.asn_doc_type === res.data.data.docType
					)['asn_doc_desc'];
					const creationDate = 
						res.data.data.uploadDate.split(' ')[0]

					const newDoc = {
						no: number,
						asn_doc_id: res.data.data.asnDocId,
						shipment_header_id: res.data.data.shipmentHeaderId,
						asn_doc_type: res.data.data.docType,
						creation_date: creationDate,
						filename: res.data.data.fileName,
						doc_type_name: docTypeName,
					};
					this.documents = [...this.documents, newDoc];

					// Clear fields
					this.selectedDocType = null;
					this.selectedFile = null;
					this.isLoading = false;
					this.loadingMessage = null;
					this.$refs.fileUpload.clear();
					this.$toast.add({
						severity: 'success',
						summary:
							'Shipment Notice document was successfully uploaded',
						life: 3000,
					});

					// location.reload();

					// Update document state on parent component without reloading page
					this.$emit('onSubmit', {
						...newDoc,
						mime_type: res.data.data.mimeType,
					});
				}
			} catch (err) {
				console.log(err);
				this.isLoading = false;
				this.loadingMessage = null;
			}
		},
	},
};
</script>
