<template>
    <FileUpload
        id="uploadFile"
        ref="inputFile"
        chooseLabel="Choose File"
        uploadLabel="Upload File"
        fileLimit="1"
        @select="onSelect"
        :customUpload="true"
        :showUploadButton="false"
        :showCancelButton="false"
        @change="previewFiles"
        :maxFileSize="5000000"
        accept=".pdf"
    >
        <template #empty>
            <p>No file selected.</p>
        </template>
    </FileUpload>
</template>

<script setup>
import {onMounted, ref} from 'vue'

const fileUploaded = ref();
const file = ref();
const files = ref();

onMounted(() => {
    
})

const onSelect = (event) => {
    fileUploaded.value = null;
    file.value = event.files[0];
    return file.value;
    // setTimeout(() => {
    //     return file.value;
    //     // if (!event.query.trim().length) {
    //     // } else {
    //     // }
    // }, 200)
}

const previewFiles = () => {
    files.value = 1;
}

</script>

<style scoped>

</style>
