<template>
	<!-- Create Input Form -->
	<div class="col-12 p-fluid mb-4 create-invoice-po">
		<Toast />

		<!-- Progress modal -->
		<ProgressModal :progress="progress">
			<template #message>
				Saving invoice in progress. Please wait...
			</template>
		</ProgressModal>

		<div class="content-wrapper">
			<div class="content-header">{{ title }}</div>
			<div class="content-body">
				<!-- Input Form -->
				<div class="grid mb-8">
					<!-- Col 1 -->
					<div class="col-12 md:col-6 lg:col-4">
						<!-- Dropdown company if page is create invoice -->
						<div v-if="!props.id" class="mb-5">
							<span class="p-float-label">
								<AutoComplete
									id="company"
									:disabled="!companyList"
									:class="{
										'p-invalid': inputError.some(
											(err) => err.name === 'company'
										),
									}"
									v-model="company"
									:suggestions="filteredCompany"
									@complete="searchCompany($event)"
									:dropdown="true"
									field="name"
									forceSelection
								>
									<template #item="{ item }">
										<div>
											{{ item.name }}
										</div>
									</template>
								</AutoComplete>
								<label for="company">Company</label>
							</span>
							<div
								class="mt-1"
								v-if="
									inputError.some(
										(err) => err.name === 'company'
									)
								"
							>
								<small class="p-error" style="font-size: 12px">
									{{
										inputError.find(
											(err) => err.name === 'company'
										)['msg']
									}}
								</small>
							</div>
						</div>
						<div class="mb-5">
							<span class="p-float-label">
								<InputText
									id="invoiceNo"
									:class="{
										'p-invalid': inputError.some(
											(err) => err.name === 'invoiceNo'
										),
									}"
									v-model="invoiceNo"
								/>
								<label for="invoiceNo">Invoice No</label>
							</span>
							<div
								class="mt-1"
								v-if="
									inputError.some(
										(err) => err.name === 'invoiceNo'
									)
								"
							>
								<small class="p-error" style="font-size: 12px">
									{{
										inputError.find(
											(err) => err.name === 'invoiceNo'
										)['msg']
									}}
								</small>
							</div>
						</div>
						<div class="mb-5">
							<span class="p-float-label">
								<Calendar
									id="invoiceDate"
									:class="{
										'p-invalid': inputError.some(
											(err) => err.name === 'invoiceDate'
										),
									}"
									v-model="invoiceDate"
									dateFormat="d-M-yy"
									:showIcon="true"
								/>
								<label for="invoiceDate">Invoice Date</label>
							</span>
							<div
								class="mt-1"
								v-if="
									inputError.some(
										(err) => err.name === 'invoiceDate'
									)
								"
							>
								<small class="p-error" style="font-size: 12px">
									{{
										inputError.find(
											(err) => err.name === 'invoiceDate'
										)['msg']
									}}
								</small>
							</div>
						</div>

						<!-- Company field if page is detail invoice -->
						<div v-if="props.id" class="mb-5">
							<span class="p-float-label">
								<InputText
									id="disabledCompany"
									disabled
									v-model="disabledCompany"
								/>
								<label for="disabledCompany">Company</label>
							</span>
						</div>

						<div class="mb-5">
							<span class="p-float-label">
								<Textarea
									id="description"
									:class="{
										'p-invalid': inputError.some(
											(err) => err.name === 'desc'
										),
									}"
									rows="5"
									v-model="description"
									:maxlength="230"
								/>
								<label for="description">Description</label>
							</span>
							<div
								class="mt-1"
								v-if="
									inputError.some(
										(err) => err.name === 'desc'
									)
								"
							>
								<small class="p-error" style="font-size: 12px">
									{{
										inputError.find(
											(err) => err.name === 'desc'
										)['msg']
									}}
								</small>
							</div>
						</div>
						<div class="mb-5">
							<span class="p-float-label">
								<AutoComplete
									forceSelection
									field="name"
									:class="{
										'p-invalid': inputError.some(
											(err) => err.name === 'bank'
										),
									}"
									:disabled="!bankList"
									:dropdown="true"
									:suggestions="filteredBanks"
									v-model="bank"
									@complete="searchBank($event)"
								/>
								<label for="bank">Bank</label>
							</span>
							<div
								class="mt-1"
								v-if="
									inputError.some(
										(err) => err.name === 'bank'
									)
								"
							>
								<small class="p-error" style="font-size: 12px">
									{{
										inputError.find(
											(err) => err.name === 'bank'
										)['msg']
									}}
								</small>
							</div>
						</div>
					</div>
					<!-- Col 2 -->
					<div class="col-12 md:col-6 lg:col-4">
						<div class="mb-5">
							<span class="p-float-label">
								<InputText
									id="taxInvoiceNo"
									@change="handleChangeTax($event)"
									:class="{
										'p-invalid': inputError.some(
											(err) => err.name === 'taxInvoiceNo'
										),
									}"
									v-model="taxInvoiceNo"
								/>
								<label for="taxInvoiceNo">Tax Invoice No</label>
							</span>
							<div
								class="mt-1"
								v-if="
									inputError.some(
										(err) => err.name === 'taxInvoiceNo'
									)
								"
							>
								<small class="p-error" style="font-size: 12px">
									{{
										inputError.find(
											(err) => err.name === 'taxInvoiceNo'
										)['msg']
									}}
								</small>
							</div>
						</div>
						<div class="mb-5">
							<span class="p-float-label">
								<Calendar
									id="taxInvoiceDate"
									:class="{
										'p-invalid': inputError.some(
											(err) =>
												err.name === 'taxInvoiceDate'
										),
									}"
									dateFormat="d-M-yy"
									:showIcon="true"
									v-model="taxInvoiceDate"
								/>
								<label for="taxInvoiceDate"
									>Tax Invoice Date</label
								>
							</span>
							<div
								class="mt-1"
								v-if="
									inputError.some(
										(err) => err.name === 'taxInvoiceDate'
									)
								"
							>
								<small class="p-error" style="font-size: 12px">
									{{
										inputError.find(
											(err) =>
												err.name === 'taxInvoiceDate'
										)['msg']
									}}
								</small>
							</div>
						</div>
						<div class="mb-5">
							<span class="p-float-label">
								<InputText
									class="statusInvoice"
									disabled
									v-model="status"
								/>
								<label for="statusInvoice">Status</label>
							</span>
						</div>
						<div class="mb-5">
							<FileUpload
								ref="fileUpload"
								mode="advanced"
								:class="{
									'error-file': inputError.some(
										(err) => err.name === 'file'
									),
								}"
								chooseLabel="Upload File"
								fileLimit="1"
								accept="application/pdf"
								:maxFileSize="5000000"
								:showUploadButton="false"
								:showCancelButton="false"
								:customUpload="true"
								@select="onSelectFile"
								@remove="onRemoveFile"
							/>
						</div>
					</div>
					<!-- Col 3 -->
					<div class="col-12 md:col-6 lg:col-4">
						<!-- Summary card -->
						<div class="form-card mb-5">
							<div
								class="flex align-items-center justify-content-between mb-4"
							>
								<span style="color: #000" class="font-semibold"
									>INVOICE AMOUNT</span
								>
								<span class="font-bold">
									{{
										totalDPP.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
									}}
								</span>
							</div>
							<div
								class="flex align-items-center justify-content-between mb-4"
							>
								<span style="color: #000" class="font-semibold"
									>TAX AMOUNT</span
								>
								<span class="font-bold">
									{{
										totalPPN.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
									}}
								</span>
							</div>
							<div
								class="flex align-items-center justify-content-between mb-4"
							>
								<span style="color: #000" class="font-semibold"
									>TOTAL</span
								>
								<span class="font-bold">
									{{
										totalInvoice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
									}}
								</span>
							</div>
							<div
								class="flex align-items-center justify-content-between mb-4"
							>
								<span style="color: #000" class="font-semibold"
									>CURRENCY</span
								>
								<span class="font-bold">
									{{
										currency
									}}
								</span>
							</div>
						</div>
						<div v-if="props.id && status === InvoiceStatusEnum.Reject" class="mb-5">
							<p class="mr-2 font-semibold" style="color: #000">
								Reject Reason:
							</p>
							<p class="ml-4">
								{{ rejectReason || '-' }}
							</p>
						</div>
					</div>
				</div>

				<!-- Attachments -->
				<div v-if="props.id || invoiceId" class="content-wrapper mb-8">
					<div
						class="content-header text-base font-bold"
						style="color: #555 !important"
					>
						Attachment(s)
					</div>
					<div class="content-body">
						<!-- Input Form -->
						<div class="grid">
							<div class="col-auto">
								<div v-if="savedFile" class="flex">
									<!-- <a
										class="button-download p-button"
										:href="savedFile.url"
									>
										<span class="font-bold">
											<i class="pi pi-download mr-2" />
											{{ savedFile.filename }}
										</span>
									</a> -->
									<Button
										v-if="!loadingDownladFile"
										class="button-download font-normal"
										:label="savedFile.filename"
										icon="pi pi-download"
										@click="onDownload()"
									/>
									<Button
										v-if="loadingDownladFile"
										class="button-download font-normal"
										:label="`${savedFile.filename} is downloading`"
										icon="pi pi-download"
										:disabled="true"
									/>
									<Button
										v-if="status === 'DRAFT' && !invoiceId"
										type="button"
										class="p-button-rounded p-button-text ml-2"
										icon="pi pi-trash"
										@click="handleDeleteFile"
									/>
								</div>
								<div v-else>&dash;</div>
							</div>
						</div>
					</div>
				</div>

				<!-- List PO -->
				<div class="grid mb-6">
					<div
						v-if="!status"
						class="col-auto ml-auto mb-3"
					>
						<Button
							class="p-button-success"
							:disabled="!company || !vendorId"
							@click="displayModal = true"
						>
							<span class="uppercase font-bold"
								>Search Pending Prepayment</span
							>
						</Button>
					</div>
					<div class="col-12">
						<DataTable
							:value="selectedPo"
							:scrollable="true"
							scrollHeight="600px"
							scrollDirection="both"
							responsiveLayout="scroll"
						>
							<Column header="No" style="width: 8%">
								<template #body="{ index }">
									{{ index + 1 }}
								</template>
							</Column>
							<Column
								header="PO Number"
								field="po_number"
								style="width: 15%"
							></Column>
							<Column
								header="Total Amount"
								field="total_amount"
								style="width: 20%"
							>
								<template #body="{data}">
									<span>
										{{
											formattingRound(data.total_amount)
											.toFixed(2)
											.replace(
												/\B(?=(\d{3})+(?!\d))/g,
												','
											)
										}}
									</span>
								</template>
							</Column>
							<Column
								header="Prepayment No"
								field="prepayment_no"
								style="width: 20%"
							></Column>
							<Column header="Percentage" style="width: 15%">
								<template #body="{ data }">
									<span>
										{{ data.percentage }} %
									</span>
								</template>
							</Column>
							<Column header="Amount" style="width: 15%">
								<template #body="{ data }">
									{{
										formattingRound(data.amount)
										.toFixed(2)
											.replace(
												/\B(?=(\d{3})+(?!\d))/g,
												','
											)
									}}
								</template>
							</Column>
							<Column header="VAT" style="width: 15%">
								<template #body="slotProps">
									<AutoComplete 
										forceSelection
										:dropdown="true"
										:suggestions="filteredVAT"
										@complete="searchVAT($event)"
										v-model="slotProps.data.vat_code"
										field="VAT_CODE"
										@change="onChangeVATValue(slotProps.index)"
										@item-select="onChangeVAT($event, slotProps.index)"
									/>
								</template>
							</Column>
							<Column header="WHT" style="width: 15%">
								<template #body="slotProps">
									<AutoComplete 
										forceSelection
										:dropdown="true"
										:suggestions="filteredWHT"
										@complete="searchWHT($event)"
										v-model="slotProps.data.wht_code"
										@change="onChangeWHTValue(slotProps.index)"
										field="WHT_CODE"
										@item-select="onChangeWHT($event, slotProps.index)"
									/>
								</template>
							</Column>
							<Column
								v-if="
									!props.id ||
									(props.id && status === 'DRAFT')
								"
								header="Actions"
								alignFrozen="right"
								frozen
								style="width: 9%"
							>
								<template #body="{ data }">
									<Button
										icon="pi pi-trash"
										:disabled="status"
										class="p-button-rounded p-button-text"
										@click="handleDelete(data)"
									/>
								</template>
							</Column>

							<!-- Empty state -->
							<template #empty> No selected item. </template>
						</DataTable>
					</div>
				</div>

				<!-- Action buttons -->
				<div>
					<!-- Create invoice buttons -->
					<div v-if="!status" class="grid">
						<div class="col-12 md:col-6 lg:col-3">
							<Button
								v-if="!progress"
								class="p-button-success justify-content-center"
								:disabled="
									!company ||
									!vendorId
								"
								@click="handleSave"
							>
								<span class="font-bold uppercase">Save</span>
							</Button>
							<Button
								v-else
								class="p-button-success justify-content-center"
								disabled
							>
								<span class="font-bold uppercase"
									>Saving...</span
								>
							</Button>
						</div>
						<div
							class="col-12 md:col-6 lg:col-3"
						>
							<Button
								class="p-button-info justify-content-center"
								@click="handleSubmit"
							>
								<span class="font-bold uppercase"
									>Submit</span
								>
							</Button>
						</div>
						<!-- <div class="col-12 md:col-6 lg:col-3">
							<Button
								class="p-button-danger justify-content-center"
								@click="handlePrintCreateInvoice"
							>
								<span class="font-bold uppercase"
									>Print Goods Receipt Note</span
								>
							</Button>
						</div>	 -->
						<div class="col-12 md:col-6 lg:col-3">
							<Button
								class="justify-content-center p-button-secondary"
								@click="$router.push({ name: 'SearchPendingPOSupp' })"
								><span
									class="font-bold uppercase text-black-alpha-90"
									:class="status === 'DRAFT' && 'underline'"
									>Back</span
								></Button
							>
						</div>
					</div>

					<!-- Detail invoice buttons -->
					<div
						v-if="status"
						class="grid justify-content-end"
					>
						<div
							v-if="status === 'DRAFT'"
							class="col-12 md:col-6 lg:col-3"
						>
							<Button
								v-if="!progress"
								class="p-button-success justify-content-center"
								@click="handleUpdate('S')"
							>
								<span class="font-bold uppercase">Save</span>
							</Button>
							<Button
								v-else
								class="p-button-success justify-content-center"
								disabled
							>
								<span class="font-bold uppercase"
									>Saving...</span
								>
							</Button>
						</div>
						<div
							v-if="status === 'DRAFT'"
							class="col-12 md:col-6 lg:col-3"
						>
							<Button
								class="p-button-info justify-content-center"
								@click="handleSubmit"
							>
								<span class="font-bold uppercase"
									>Submit</span
								>
							</Button>
						</div>
						<div 
							v-if="!isHaveBatch || isHaveBatch.length == 0"
							class="col-12 md:col-6 lg:col-3">
							<Button
								class="p-button-warning justify-content-center"
								@click="handleBatch"
							>
								<span class="font-bold uppercase"
									>Add to Batch</span
								>
							</Button>
						</div>	
						<!-- <div class="col-12 md:col-6 lg:col-3">
							<Button
								class="p-button-danger justify-content-center"
								@click="handlePrintDetailInvoice"
							>
								<span class="font-bold uppercase"
									>Print Goods Receipt Note</span
								>
							</Button>
						</div>		 -->
						<div class="col-12 md:col-6 lg:col-3">
							<Button
								class="justify-content-center p-button-secondary"
								@click="$router.push({ name: 'ListInvoicePO' })"
								><span
									class="font-bold uppercase text-black-alpha-90"
									:class="status === 'DRAFT' && 'underline'"
									>Back</span
								></Button
							>
						</div>
					</div>
				</div>

				<!-- Find PO Modal -->
				<Dialog
					header=" "
					:draggable="false"
					:breakpoints="{ '960px': '75vw', '640px': '100vw' }"
					:style="{ width: '75vw', overflow: 'hidden' }"
					:modal="true"
					:dismissableMask="true"
					v-model:visible="displayModal"
					:poNumber="route.params.id"
				>
					<div class="pt-3">
						<FindPOModalPrepayment
							:dbInstance="
								props.id ? $route.query.i : company.db_instance
							"
							:orgId="props.id ? $route.query.v : company.value"
							:vendorId="vendorId"
							@on-add="handleAddPo"
							@on-close="displayModal = false"
                            :banList="selectedPo"
							:prepaymentFlag="prepayment_flag"
							:poNumber="route.params.id"
						/>
					</div>
				</Dialog>

				<!-- Add To Batch Modal -->
				<Dialog
					header="Add To Batch"
					:draggable="false"
					:breakpoints="{ '960px': '75vw', '640px': '100vw' }"
					:style="{ width: '25vw', overflow: 'hidden' }"
					:modal="true"
					:dismissableMask="true"
					v-model:visible="displayModalBatch"
				>
					<div class="pt-3">
						<AddToBatchModal
							:dbInstance="company.db_instance"
							:orgId="company.value"
							:vendorId="vendorId"
							@on-close="displayModalBatch = false"
							@on-save="handleAddToBatch"
						/>
					</div>
				</Dialog>
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed, onMounted, ref, watch, defineProps, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import SimpleCrypto from 'simple-crypto-js';
import LovService from '../../service/LovService';
import PoFulfillment from '../../service/PoFulfillment';
import FindPOModalPrepayment from '../components/FindPOModalPrepayment.vue';
import ProgressModal from '../components/ProgressModal.vue';
import AddToBatchModal from '../components/AddToBatchModal';
import InvoicePOService from '../../service/InvoicePOService';
import InvoiceNonPOservice from '../../service/InvoiceNonPOservice';
import PendingPOService from '../../service/PendingPOService';
import { InvoiceStatusEnum } from '../../constants/InvoiceStatusEnum';
import { PICEnum, InvoiceTypeEnum } from "../../constants/POTypeEnum";
import Swal from "sweetalert2";
import { formatRounding, formatCeil, formatFloor } from '../../helper/formatRounding';

// State
const invoiceId = ref(null);
const currentUser = ref(props.currentUser);
const company = ref(null);
const invoiceNo = ref(null);
const invoiceDate = ref(null);
const description = ref(null);
const bank = ref(null);
const taxInvoiceNo = ref(null);
const taxInvoiceDate = ref(null);
const status = ref(null);
const file = ref(null);
const fileUpload = ref(null);
const isPphChecked = ref(null);
const pphValue = ref(null);
const filteredBanks = ref(null);
const vendorId = ref(null);
const vendorName = ref(null);
const companyList = ref(null);
const bankList = ref(null);
const filteredCompany = ref(null);
const displayModal = ref(false);
const displayModalBatch = ref(false);
const isHaveBatch = ref([]);
const selectedPo = ref([]);
const inputError = ref([]);
const progress = ref(false);
const loading = ref(false);
const disabledCompany = ref(null);
const rejectReason = ref(null);
const savedInvoiceDetails = ref(null);
const savedFile = ref(null);
const deletedFile = ref(null);
const totalPPN = ref(0);
const totalDPP = ref(0);
const invoiceAmount = ref(null);
const taxAmount = ref(null);
const totalAmount = ref(null);
const disabledButtonSave = ref(false);
const fileBuffer = ref();
const loadingDownladFile = ref(false);
const totalInvoice = ref(0);
const prepayment_flag = ref('');
const getVAT = ref([]);
const filteredVAT = ref([]);
const filteredWHT = ref([]);
const getWHT = ref();
const headerId = ref(null);
const currency = ref(null);
const rounder = ref();

const router = useRouter();
const route = useRoute();

const toast = useToast();

const props = defineProps(['title', 'id', 'currentUser']);

// Summary invoice
const summary = computed(() => {
	const totalPph = (isPphChecked.value && pphValue.value) || 0;

	const totalInvoice = totalDPP.value + totalPPN.value;

	return {
		totalPph,
		totalInvoice,
	};
});

// Mounted hook
onMounted(async () => {
	// Pre-fetch company and vendor
	if (currentUser.value.leveltenant === 2) {
		// const companyRes = getCompany();
		// const vendorRes = getVendorId();

		// await Promise.all([companyRes, vendorRes]);

		await getCompany();
		await getVendorId();	
	} else {
		let payload = {po_number: route.params.id}

		if(route.query.v){
			payload.type = PICEnum.DetailPrepayment
		}else{
			payload.type = PICEnum.Prepayment
		}

		const res = await PendingPOService.getVendorForPic(payload)
		vendorName.value = res.data.data.vendor_name
		vendorId.value = res.data.data.vendor_id
		await getCompany();
	}

	// Call detail invoice if in detail page
	if (route.query.v) {
		await getListBank(route.query.v, route.query.i, vendorId.value);
		await getInvoiceDetail(props.id);
		await getDropdownVat();
		// await getDropdownWHT();
		return;
	}

	// Get list bank
	await getListBank(
		company.value.value,
		company.value.db_instance,
		vendorId.value
	);

	await getDetailPendingPo();
	await getDropdownVat();
	await getDropdownWHT();
});

const getDropdownVat = async () => {
	const paramRet = 'RATE';
	const payload = {
		paramRet: paramRet,
		dbInstance: sessionStorage.companydb,
		invoiceDate: invoiceDate.value,
	};
	const result = await InvoiceNonPOservice.getListVat(payload);
	const noSelect = {
			PARAM_RET: "",
            PERCENTAGE_RATE: 0,
            TAX_RATE_ID: 0,
            TAX_RATE_CODE: null,
            EFFECTIVE_FROM: "1998-12-31T17:00:00.000Z",
            EFFECTIVE_TO: null,
            ACTIVE_FLAG: "Y",
            ATTRIBUTE1: "SUPPLIER PORTAL",
            VAT_CODE: "- No Select -"
		}

		getVAT.value = [noSelect, ...result.data.data];
		

	selectedPo.value.forEach((item) => {
		const getValue = getVAT.value.find((Q) => Q.TAX_RATE_CODE === item.vat_code || Q.VAT_CODE === item.vat_code);
		console.log(getValue, getVAT.value, item.vat_code, "INI YA")
		item.vat_code = getValue ? getValue.VAT_CODE : null
		item.vat_code_id = getValue ? getValue.TAX_RATE_ID : null
		item.percentage_rate = getValue ? getValue.PERCENTAGE_RATE : null
		item.vat_current = getValue ? getValue.VAT_CODE : null
		return item;
	})

	await getTotalPPN()
};

const getDetailPendingPo = async () => {
	try {
		console.log(props.id, "PROP ID")
		const payload = {
            dbInstance: company.value.db_instance,
            orgId: company.value.value,
            vendorId: vendorId.value,
            poNumber: route.params.id,
        }

		const result = await PendingPOService.getDetailPrepayment(payload);
		selectedPo.value = result.data.data;
		currency.value = selectedPo.value[0].currency_code;
		if(result.data.data){
			prepayment_flag.value = 'Y'
		}

		const rounding = await PendingPOService.getRounded({
			vendor_id: vendorId.value,
			curr_code: currency.value,
			db_instance: company.value.db_instance
		});

		rounder.value = rounding.data.data;
		
	} catch (error) {
		console.log(error);
	}
}

// Unmounted hook
onUnmounted(() => {
	unwatchCompany();
	unwatchSelectedPo();
	unwatchInvoiceDate();
});


watch(company, () => {
	handleReset()
})


watch(displayModalBatch, () => {
	console.log("DISPLAY MODEL", displayModalBatch.value)
})

const handleBatch = () => {
	displayModalBatch.value = !displayModalBatch.value
}

// Watch company selected
const unwatchCompany = watch(company, async (newCompany, oldCompany) => {
	if (oldCompany) {
		bankList.value = [];

		vendorId.value = null; // Reset old vendorId

		// Get new vendorId for new company
		const res = await PendingPOService.getSupplier(
			{
				vendor: vendorName.value,
				dbInstance: company.value.db_instance
			}
		);

		vendorId.value = res.data.data[0].vendor_id;

		await getListBank(
			newCompany.value,
			newCompany.db_instance,
			vendorId.value
		);
	}
});

// Watch selected PO to get total PPN
const unwatchSelectedPo = watch(selectedPo, async () => {
	if (selectedPo.value.length) {
		disabledButtonSave.value = true;
		await getTotalPPN();
		disabledButtonSave.value = false;
	} else {
		totalPPN.value = 0;
		totalDPP.value = 0;
	}
});

// Watch invoice date to get total PPN
const unwatchInvoiceDate = watch(invoiceDate, async () => {
	if (invoiceDate.value && selectedPo.value.length) {
		disabledButtonSave.value = true;
		await getTotalPPN();
		await getDropdownVat();
		disabledButtonSave.value = false;
	}
});

// Get total PPN
const getTotalPPN = async () => {

	const res = await PendingPOService.getTotalPPNPrepayment({
		orgId: company.value.value,
		vendorId: vendorId.value,
		dbInstance: company.value.db_instance,
		invoiceDate: invoiceDate.value,
		rows: selectedPo.value,
	})

	totalPPN.value = res.data.data[0].total_ppn
	totalDPP.value = res.data.data[0].total_dpp
	totalInvoice.value = totalPPN.value + totalDPP.value
	disabledButtonSave.value = false;
};


const formattingRound = (value) => {
	if(!rounder.value) return formatRounding(value);
	if(rounder.value.rounded.rounding_rule_code === "UP") return formatCeil(value,rounder.value.precision)
	if(rounder.value.rounded.rounding_rule_code === "DOWN") return formatFloor(value,rounder.value.precision)

	return formatRounding(value)
}

// Formatting date
const formattingDate = (date, withTime) => {
	const theDate = new Date(date);
	const strDate =
		theDate.getFullYear() +
		'-' +
		(theDate.getMonth() + 1) +
		'-' +
		theDate.getDate();
	const strTime =
		theDate.getHours() +
		':' +
		theDate.getMinutes() +
		':' +
		theDate.getSeconds();

	if (withTime) {
		return `${strDate} ${strTime}`;
	} else {
		return `${strDate}`;
	}
};

// Get company list
const getCompany = async () => {
	if (currentUser.value.userid === 'dexaadmin') {
		// If user is admin
		const companyRes = LovService.getValueType('COMPANY');
		companyList.value = companyRes.data.data;
	} else if (currentUser.value.leveltenant === 2 || currentUser.value.leveltenant === 3) {
		// If user is supplier
		let payload = currentUser.value.id;
		const companySuppRes = await PoFulfillment.getCompanySupp(payload);

		try {
			const companySupp = companySuppRes.data.data;
			const companyValues = await Promise.all(
				companySupp.map(async (element) => {
					const res = await LovService.getCompanyByName(
						element.company
					);

					const secretKey = 'some-unique-key';
					const simpleCrypto = new SimpleCrypto(secretKey);
					const decipherText = simpleCrypto.decrypt(
						sessionStorage.getItem('dropdownCompany')
					);

					if (res.data.data[0].name === decipherText) {
						company.value = res.data.data[0];
					}

					await getDropdownWHT();
					return res.data.data[0];
				})
			);

			companyList.value = companyValues;
		} catch (e) {
			console.log(e);
			if (e !== 'Break') throw e;
		}
	} else if (currentUser.value.leveltenant === 1) {

		// If user is Finance
		const res = await LovService.getcompanydb(currentUser.value.bioaddress);
		companyList.value = res.data.data;
	
		try {
			const secretKey = 'some-unique-key';
			const simpleCrypto = new SimpleCrypto(secretKey);
			const decipherText = simpleCrypto.decrypt(
				sessionStorage.getItem('dropdownCompany')
			);
			companyList.value.forEach((element) => {
				if (element.name === decipherText) {
					company.value = element;
					throw 'Break';
				}
			});
		} catch (e) {
			if (e !== 'Break') throw e;
		}
	}
};

// Get VendorId of logged in user
const getVendorId = async () => {
	const resVendorName = await PoFulfillment.getSuppBindCompProc(
		currentUser.value.id
	);
	vendorName.value = resVendorName.data.data[0][0].vendorname;
	const payloadSuppVal = {
		vendor: resVendorName.data.data[0][0].vendorname,
		dbInstance: company.value.db_instance
	};

	const resVendorId = await PendingPOService.getSupplier(
		payloadSuppVal
	);


	vendorId.value = resVendorId.data.data[0].vendor_id;
};

// Get bank account list
const getListBank = async (orgId, dbInstance, vendorId) => {
	const payload = {
		orgId,
		dbInstance,
		vendorId,
	};

	const res = await InvoicePOService.getListBank(payload);

	if (res.status === 200) {
		bankList.value = res.data.data.map((item) => {
			return {
				id: item.ext_bank_account_id,
				name: item.transfer_to,
			};
		});
	}
};

// Search company on autocomplete
const searchCompany = (event) => {
	if (!event.query.trim().length) {
		filteredCompany.value = [...companyList.value];
	} else {
		filteredCompany.value = companyList.value.filter((item) => {
			return item.name.toLowerCase().match(event.query.toLowerCase());
		});
	}
};

// Search bank on autocomplete
const searchBank = (event) => {
	if (!event.query.trim().length) {
		filteredBanks.value = [...bankList.value];
	} else {
		filteredBanks.value = bankList.value.filter((bank) => {
			return bank.name.toLowerCase().match(event.query.toLowerCase());
		});
	}
};


// Add selected PO
const handleAddPo = (items) => {
	const newItems = items.filter(
		(item) => !selectedPo.value.some((val) => val.po_number === item.po_number && val.prepayment_no === items.prepayment_no)
	);
	selectedPo.value = [...selectedPo.value, ...newItems];
	displayModal.value = false;
};

// Delete selected PO
const handleDelete = async (id) => {

	console.log(id,"ID", selectedPo.value.filter((po) => po.line_num !== id.line_num))
	selectedPo.value = selectedPo.value.filter((po) => po.line_num !== id.line_num)
};

// Select file attachment
const onSelectFile = (e) => {
	file.value = e.files[0];
};

// Remove file attachment
const onRemoveFile = (e) => {
	if (!e.files.length) {
		file.value = null;
	}
};

// Get attachment file
const getAttachmentFile = async (invoiceId) => {
	try {
		const res = await InvoicePOService.getAttachmentFile(invoiceId);

		if (res.status === 200) {
			savedFile.value = res.data.data;
		}
	} catch (err) {
		console.log(err);
	}
};

// const getFileBase64 = async (invoiceId) => {
// 	try {
// 		const leveltenant = sessionStorage.getItem('leveltenant')
// 		const res = await InvoicePOService.getFileBase64(invoiceId, leveltenant, vendorId.value);

// 		if (res.status === 200) {
// 			fileBuffer.value = res.data.data;
// 		}
// 	} catch (err) {
// 	}
// };

// Delete attach file
const handleDeleteFile = () => {
	deletedFile.value = savedFile.value;
	savedFile.value = null;
};

// Get invoice detail
const getInvoiceDetail = async (id) => {
	try {
		loading.value = true;

		const query = route.query;

		const payload = {
			dbInstance: query.i,
			orgId: query.v ? query.v : company.value.value,
			vendorId: vendorId.value,
			levelTenant: currentUser.value.leveltenant,
		};
		

		const res = await InvoicePOService.getInvoiceDetailPrepayment(id, payload);
	
		if (res.status === 200) {
			const invoiceHeader = res.data.data.header;
			const invoiceDetails = res.data.data.details;
			const invoiceFile = res.data.data.file;

			// Assign invoice header
			invoiceNo.value = invoiceHeader.invoice_no;
			invoiceDate.value = new Date(invoiceHeader.invoice_date);
			description.value = invoiceHeader.description;
			taxInvoiceNo.value = invoiceHeader.tax_no;
			taxInvoiceDate.value = new Date(invoiceHeader.tax_date);
			status.value = invoiceHeader.status;
			bank.value = bankList.value.find(
				(item) => item.id === invoiceHeader.external_bank_account_id
			);
			currency.value = invoiceHeader.currency_code;
			rejectReason.value = invoiceHeader.reason;
			disabledCompany.value = companyList.value.find(
				(item) => parseInt(item.value) === invoiceHeader.org_id
			)['name'];
			vendorId.value = invoiceHeader.vendor_id;
			// await getFileBase64(id);
			// Assign pph amount
			if (invoiceHeader.service_amount) {
				isPphChecked.value = true;
				pphValue.value = invoiceHeader.service_amount;
			}
			prepayment_flag.value = invoiceHeader.prepayment_flag

			// Assign total invoice amount if detail is open or above
			if (status.value !== 'DRAFT') {
				invoiceAmount.value = invoiceHeader.invoice_amount;
				taxAmount.value = invoiceHeader.tax_amount;
				totalAmount.value = invoiceHeader.total_amount;
			}

			// Assign invoice details
			selectedPo.value = invoiceDetails.map((item) => {
				return {
					...item,
					id: item.transaction_id,
				};
			});
			savedInvoiceDetails.value = invoiceDetails;

			const rounding = await PendingPOService.getRounded({
				vendor_id: invoiceHeader.vendor_id,
				curr_code: invoiceHeader.currency_code,
				db_instance: company.value.db_instance
			});

			const respBatch = await PendingPOService.findIsBatch({
				EINVOICE_ID: props.id,
				VENDOR_ID: vendorId.value,
				DB_INSTANCE: company.value.db_instance
			});

			isHaveBatch.value = respBatch.data.data;

			rounder.value = rounding.data.data;


			// Assign invoice file
			savedFile.value = invoiceFile;
		}

		loading.value = false;
	} catch (err) {
		console.log(err);
		loading.value = false;
		toast.add({
			severity: 'error',
			summary: 'Invoice data is not found',
			life: 3000,
		});
	}
};

const onChangeVATValue = async (index) => {
	
	selectedPo.value[index].percentage_rate = null;
	selectedPo.value[index].vat_code = null;
	selectedPo.value[index].vat_code_id = null;
	selectedPo.value[index].tax_rate_code = null;
	selectedPo.value[index].change = true;
	await getTotalPPN();

};

const onChangeWHTValue = async (event, index) => {
	selectedPo.value[index].wht_code = null;
};

const validation = async () => {
	inputError.value = [];

	if(selectedPo.value.length < 1){
		toast.add({
			severity: 'error',
			summary: 'Belum memilih line',
			life: 3000,
		});
		return false;
	}

	console.log(selectedPo.value, "VAL")

	if(selectedPo.value.length > 1){
		toast.add({
			severity: 'error',
			summary: 'Tidak boleh input lebih dari 1 line',
			life: 3000,
		});
		return false;
	}
	
	if(!status.value){
		const resultMinimum = await PendingPOService.checkMinimumLine({
			po_number: !status.value ? route.params.id : selectedPo.value[0].po_number
		})
		const findMinimum = selectedPo.value.find((Q) => Q.line_num > resultMinimum.data.data.line_num);

		if(findMinimum){
			toast.add({
				severity: 'error',
				summary: 'Lakukan submit dari line terkecil',
				life: 3000,
			});
			return false;
		}
	}

	// Validation required input field
	if (
		!company.value ||
		!invoiceNo.value ||
		!invoiceDate.value ||
		!description.value ||
		!bank.value ||
		!taxInvoiceNo.value ||
		!taxInvoiceDate.value
	) {
		console.log('Some fields are required');
		toast.add({
			severity: 'error',
			summary: 'Some fields are required',
			life: 3000,
		});

		if (!company.value) {
			inputError.value.push({
				name: 'company',
				msg: 'This field is required',
			});
		}
		if (!invoiceNo.value) {
			inputError.value.push({
				name: 'invoiceNo',
				msg: 'This field is required',
			});
		}
		if (!invoiceDate.value) {
			inputError.value.push({
				name: 'invoiceDate',
				msg: 'This field is required',
			});
		}
		if (!description.value) {
			inputError.value.push({
				name: 'desc',
				msg: 'This field is required',
			});
		}
		if (!bank.value) {
			inputError.value.push({
				name: 'bank',
				msg: 'This field is required',
			});
		}
		if (!taxInvoiceNo.value) {
			inputError.value.push({
				name: 'taxInvoiceNo',
				msg: 'This field is required',
			});
		}
		if (!taxInvoiceDate.value) {
			inputError.value.push({
				name: 'taxInvoiceDate',
				msg: 'This field is required',
			});
		}

		return false;
	}

	// Validation attachment file
	if (!savedFile.value && !file.value) {
		console.log('Please select file to upload');
		toast.add({
			severity: 'error',
			summary: 'Please select file to upload',
			life: 3000,
		});
		inputError.value.push({
			name: 'file',
			msg: 'This field is required',
		});
		return false;
	}

	// Validation select PO
	if (!selectedPo.value.length) {
		console.log('Please select PO for creating invoice');
		toast.add({
			severity: 'error',
			summary: 'Please select PO for creating an invoice',
			life: 3000,
		});
		return false;
	}

	// Validation on total amount
	if (!totalDPP.value || totalDPP.value === 0) {
		toast.add({
			severity: 'error',
			summary: 'Invoice amount must be greater than zero',
			life: 3000,
		});
		return false;
	}

	return true;
}


// Save invoice
const handleSave = async () => {
	const result = await validation();

	if(result === false){
		return{
			status: 400,
			message: 'failed'
		}
	}

	// Add file payload
	const formData = new FormData();
	formData.append('file', file.value);

	// Add body payload
	const payload = {
		header: {
			INVOICE_NO: invoiceNo.value,
			INVOICE_DATE: formattingDate(invoiceDate.value),
			REVISION_NUM: 0,
			TAX_NO: taxInvoiceNo.value,
			TAX_DATE: formattingDate(taxInvoiceDate.value),
			DESCRIPTION: description.value,
			// CURRENCY_CODE: selectedPo.value[0].currency_code,
			VENDOR_ID: vendorId.value,
			INVOICE_AMOUNT: totalDPP.value,
			TAX_AMOUNT: totalPPN.value,
			TOTAL_AMOUNT: totalInvoice.value,
			SERVICE_AMOUNT: summary.value.totalPph,
			CURRENCY_CODE: selectedPo.value[0].currency_code,
			VENDOR_SITE_ID: selectedPo.value[0].vendor_site_id,
			// WHT_CODE: ,
			// WHT_RATE: ,
			// ATTRIBUTE1: ,
			ORG_ID: parseInt(company.value.value),
			STATUS: sessionStorage.leveltenant == 2 ? 'DRAFT' : 'OPEN',
			CREATION_DATE: formattingDate(new Date(), true),
			CREATED_BY: currentUser.value.id,
			LAST_UPDATE_DATE: formattingDate(new Date(), true),
			LAST_UPDATED_BY: currentUser.value.id,
			// PAYMENT_METHOD_LOOKUP_CODE: ,
			// GL_DATE: ,
			// TERMS_ID: ,
			// REASON: ,
			// ACCTS_CODE_COMBINATION_ID: ,
			EXTERNAL_BANK_ACCOUNT_ID: bank.value.id,
			INVOICE_TYPE: sessionStorage.leveltenant == 2 
				? InvoiceTypeEnum.Prepayment : InvoiceTypeEnum.PrepaymentProxy,
			// CATEGORY_ID: ,
			// MAPING_ID: ,
			// REFERENCE_NUMBER: ,
			// VENDOR_SITE_ID: ,
			// COST_CENTER: ,
			DB_INSTANCE: company.value.db_instance,
			PREPAYMENT_FLAG: prepayment_flag.value,
			RATE_TYPE: selectedPo.value[0].rate_type
		},
		details: [],
	};

	// Add detail payload
	const details = selectedPo.value.map((item) => {
		return {
			// EINVOICE_ID: ,
			PO_HEADER_ID: item.po_header_id,
			// PO_LINE_ID: item.po_line_id,
			PO_NUMBER: item.po_number,
			LINE_NUM: item.line_num,
			// ITEM_ID: item.item_id,
			// ITEM_NUMBER: item.item_number,
			// ITEM_DESCRIPTION: item.item_description,
			// UNIT_MEAS_LOOKUP_CODE: item.unit_meas_lookup_code,
			UNIT_PRICE: item.unit_price,
			// UNIT_PRICE_SUP: ,
			// QUANTITY: item.quantity_received,
			// NOTE_TO_VENDOR: ,
			ORGANIZATION_ID: item.org_id,
			// LINE_LOCATION_ID: item.line_location_id,
			// VAT_CODE: item.vat_code,
			// RECEIPT_NUM: item.receipt_num,
			// PACKING_SLIP: item.packing_slip === 'NA' ? null : item.packing_slip,
			// DISTRIBUTION_NUM: item.distribution_num,
			// PO_DISTRIBUTION_ID: item.po_distribution_id,
			// SHIPMENT_NUM: item.shipment_num,
			CREATION_DATE: formattingDate(new Date(), true),
			CREATED_BY: currentUser.value.id,
			LAST_UPDATE_DATE: formattingDate(new Date(), true),
			LAST_UPDATED_BY: currentUser.value.id,
			// REMARKS: item,
			// ATTRIBUTE1: item,
			CURRENCY_CODE: item.currency_code,
			REVISION_NUM: item.revision,
			VENDOR_ID: item.vendor_id,
			PREPAYMENT_NO: item.prepayment_no,
			TOTAL_PPN: item.total_ppn,
			AMOUNT: item.amount,
			TOTAL_AMOUNT: item.total_amount,
			PERCENTAGE: item.percentage,
			VAT_CODE: item.vat_code ? item.vat_code : null,
			VAT_CODE_ID: item.vat_code_id ? item.vat_code_id: null,
			WHT_CODE: item.wht_code ? item.wht_code.WHT_CODE : null,
			PERCENTAGE_RATE: item.percentage_rate ? item.percentage_rate : null,
			COA_ID: item.coa_id ? item.coa_id : null
			// VENDOR_SITE_ID: item.vendor_site_id,
			// SHIPMENT_LINE_ID: item.shipment_line_id,
			// SHIPMENT_HEADER_ID: item.shipment_header_id,
			// RCV_TRANSACTION_ID: item.transaction_id,
			// CATEGORY_DETAIL_ID:,
			// WHT_CODE: item,
			// COA_ID: item,
			// VAT_CODE_ID: item,
			// WHT_CODE_ID: item,
			// GROUP_NAME: item,
		};
	});
	
	payload.details = details;

	// Wrap body payload with formData
	const jsonPayload = JSON.stringify(payload);
	formData.append('payload', jsonPayload);

	try {
		progress.value = true;
		console.log(formData, "PAYLOAD")
		const res = await PendingPOService.saveInvoiceStandart(formData);

		if (res.status == 200) {
			progress.value = false;
			invoiceId.value = res.data.data.einvoice_id;
			invoiceNo.value = res.data.data.invoice_no;
			status.value = 'DRAFT';
			file.value = null;
			fileUpload.value.clear();
			headerId.value = res.data.data.einvoice_id
			getAttachmentFile(invoiceId.value);
			// await getFileBase64(invoiceId.value);

			toast.add({
				severity: 'success',
				summary: 'Successfully save new invoice',
				life: 3000,
			});

			await getInvoiceDetail(headerId.value);

			setTimeout(() => {
				router.push({
					name: 'CreateEinvoicePrepayment',
					params: {
						id: headerId.value
					},
					query: {
						v: company.value.value,
						i: company.value.db_instance
					}
				});
			},1000)

			progress.value = false;
			return{
				status: 200,
				message: 'success'
			}
		}
		progress.value = false;
		
		return{
			status: 200,
			message: 'success'
		}
	} catch (err) {
		console.log(err);
		progress.value = false;
		if (err.response.status === 400) {
			if (err.response.data.message.includes('exist')) {
				inputError.value.push({
					name: 'invoiceNo',
					msg: err.response.data.message,
				});
			}
			toast.add({
				severity: 'error',
				summary: err.response.data.message,
				life: 3000,
			});

			return{
				status: 400,
				message: 'failed'
			}
		}
		toast.add({
			severity: 'error',
			summary: err.response.data.message ?? 'Server error: Unsuccessfully save invoice',
			life: 3000,
		});

		return{
			status: 500,
			message: 'failed'
		}
	}
};

// Update invoice
const handleUpdate = async (typeUpdate) => {
	const result = await validation();
	if(result === false){
		return{
			status: 400,
			message: 'failed'
		}
	}

	// Add file payload
	const formData = new FormData();
	formData.append('file', file.value);

	// Add body payload
	const payload = {
		header: {
			EINVOICE_ID: parseInt(route.params.id),
			INVOICE_NO: invoiceNo.value,
			INVOICE_DATE: formattingDate(invoiceDate.value),
			REVISION_NUM: 0,
			TAX_NO: taxInvoiceNo.value,
			TAX_DATE: formattingDate(taxInvoiceDate.value),
			DESCRIPTION: description.value,
			INVOICE_AMOUNT: totalDPP.value,
			TAX_AMOUNT: totalPPN.value,
			TOTAL_AMOUNT: summary.value.totalInvoice,
			SERVICE_AMOUNT: summary.value.totalPph,
			LAST_UPDATE_DATE: formattingDate(new Date(), true),
			LAST_UPDATED_BY: currentUser.value.id,
			EXTERNAL_BANK_ACCOUNT_ID: bank.value.id,
			VENDOR_ID: vendorId.value,
			INVOICE_TYPE: sessionStorage.leveltenant == 2 
				? InvoiceTypeEnum.Prepayment : InvoiceTypeEnum.PrepaymentProxy,
		},
		newDetails: [],
		removedDetails: [],
		countDetails: selectedPo.value.length,
	};

	// Add new details payload
	payload.newDetails = selectedPo.value
		.filter(
			(item) =>
				!savedInvoiceDetails.value.some(
					(val) => val.po_number === item.po_number
				)
		)
		.map((item) => {
			return {
				EINVOICE_ID: parseInt(route.params.id),
				PO_HEADER_ID: item.po_header_id,
				PO_NUMBER: item.po_number,
				LINE_NUM: item.line_num,
				ORGANIZATION_ID: item.org_id,
				CREATION_DATE: formattingDate(new Date(), true),
				CREATED_BY: currentUser.value.id,
				LAST_UPDATE_DATE: formattingDate(new Date(), true),
				LAST_UPDATED_BY: currentUser.value.id,
				CURRENCY_CODE: item.currency_code,
				REVISION_NUM: item.revision,
				VENDOR_ID: item.vendor_id,
				PERCENTAGE: item.percentage,
				WHT_CODE: item.wht_code ? item.wht_code.WHT_CODE : null,
				VAT_CODE: item.vat_code ? item.vat_code : null,
				VAT_CODE_ID: item.vat_code_id ? item.vat_code_id: null,
				PERCENTAGE_RATE: item.percentage_rate ? item.percentage_rate : null,
				PREPAYMENT_NO: item.prepayment_no,
				TOTAL_PPN: item.total_ppn,
				AMOUNT: item.amount,
				TOTAL_AMOUNT: item.total_amount,
				COA_ID: item.COA_ID ? item.COA_ID : null
			};
		});

	payload.details = selectedPo.value
		.filter(
			(item) =>
				savedInvoiceDetails.value.some(
					(val) => val.transaction_id === item.id
				)
		)
		.map((item) => {
			return {
				EINVOICE_ID: parseInt(route.params.id),
				EINVOICE_LINE_ID: Number(item.einvoice_line_id),
				PO_HEADER_ID: item.po_header_id,
				PO_LINE_ID: item.po_line_id,
				PO_NUMBER: item.po_number,
				LINE_NUM: item.line_num,
				ITEM_ID: item.item_id,
				ITEM_NUMBER: item.item_number,
				WHT_CODE: item.wht_code ? item.wht_code.WHT_CODE : null,
				ITEM_DESCRIPTION: item.item_description,
				UNIT_MEAS_LOOKUP_CODE: item.unit_meas_lookup_code,
				UNIT_PRICE: item.unit_price,
				QUANTITY: item.quantity_received,
				AMOUNT: item.total_dpp,
				ORGANIZATION_ID: item.org_id,
				LINE_LOCATION_ID: item.line_location_id,
				RECEIPT_NUM: item.receipt_num,
				PACKING_SLIP:
					item.packing_slip === 'NA' ? null : item.packing_slip,
				DISTRIBUTION_NUM: item.distribution_num,
				PO_DISTRIBUTION_ID: item.po_distribution_id,
				SHIPMENT_NUM: item.shipment_num,
				CREATION_DATE: formattingDate(new Date(), true),
				CREATED_BY: currentUser.value.id,
				LAST_UPDATE_DATE: formattingDate(new Date(), true),
				LAST_UPDATED_BY: currentUser.value.id,
				CURRENCY_CODE: item.currency_code,
				REVISION_NUM: item.revision_num,
				VENDOR_ID: item.vendor_id,
				PERCENTAGE: item.percentage,
				VENDOR_SITE_ID: item.vendor_site_id,
				SHIPMENT_LINE_ID: item.shipment_line_id,
				SHIPMENT_HEADER_ID: item.shipment_header_id,
				RCV_TRANSACTION_ID: item.transaction_id,
				VAT_CODE: item.vat_code ? item.vat_code : null,
				VAT_CODE_ID: item.vat_code_id ? item.vat_code_id: null,
				PERCENTAGE_RATE: item.percentage_rate ? item.percentage_rate : null,
			};
	});


	// Add payload removed details
	payload.removedDetails = savedInvoiceDetails.value
		.filter(
			(item) =>
				!selectedPo.value.some((val) => val.po_number === item.po_number)
		)
		.map((item) => ({
			PO_NUMBER: item.po_number,
			PREPAYMENT_NO: item.prepayment_no,
		}));

	// Deleted payload
	if (deletedFile.value) {
		payload.deletedFile = deletedFile.value;
	} else {
		payload.deletedFile = savedFile.value;
	}

	// Wrap body payload with formData
	const jsonPayload = JSON.stringify(payload);
	formData.append('payload', jsonPayload);

	try {
		progress.value = true;

		const res = await InvoicePOService.updateInvoice(route.params.id, formData);

		if (res.status == 200) {
			fileUpload.value.clear();
			file.value = null;
			deletedFile.value = null;

			// Re-assign saved invoice details
			await getInvoiceDetail(route.params.id);

			getAttachmentFile(route.params.id);
			// await getFileBase64(props.id);
			console.log(typeUpdate, "TYPE")
			if(typeUpdate === 'S'){
				toast.add({
					severity: 'success',
					summary: 'Successfully edit invoice',
					life: 3000,
				});
			}
			progress.value = false;
			return{
				status: 200,
				message: 'success'
			}
		}
		progress.value = false;
	} catch (err) {
		console.log(err);
		progress.value = false;

		if (err.response.status === 400) {
			if (err.response.data.message.includes('exist')) {
				inputError.value.push({
					name: 'invoiceNo',
					msg: err.response.data.message,
				});
			}

			toast.add({
				severity: 'error',
				summary: err.response.data.message,
				life: 3000,
			});

			return{
				status: 400,
				message: 'failed'
			}
		}

		toast.add({
			severity: 'error',
			summary: err.response.data.message ?? 'Server error: Unsuccessfully save invoice',
			life: 3000,
		});

		return{
			status: 400,
			message: 'failed'
		}
	}
};

// Add invoice to batch
const handleAddToBatch = async (batchId) => {
	const payload = {
		batch_id: batchId,
		einvoice_id: props.id,
		amount: summary.value.totalInvoice,
		creation_date: formattingDate(new Date(), true),
		created_by: currentUser.value.id,
		last_update_date: formattingDate(new Date(), true),
		last_updated_by: currentUser.value.id,
	};

	try {
		progress.value = true;
		const res = await InvoicePOService.addToBatch(payload);

		if (res.status === 201) {
			displayModalBatch.value = false;
			status.value !== 'DRAFT' ? console.log("OK") : status.value === 'OPEN';
			toast.add({
				severity: 'success',
				summary: 'Successfully add invoice to batch',
				life: 3000,
			});
			await getInvoiceDetail(props.id);
		}
		progress.value = false;
	} catch (err) {
		console.log(err);
		progress.value = false;
		toast.add({
			severity: 'error',
			summary: 'Server error: Unsuccessfully add to batch',
			life: 3000,
		});
	}
};

// Reset form invoice
const handleReset = () => {
	invoiceNo.value = null;
	invoiceDate.value = null;
	description.value = null;
	bank.value = null;
	taxInvoiceNo.value = null;
	taxInvoiceDate.value = null;
	file.value = null;
	isPphChecked.value = null;
	pphValue.value = null;
	selectedPo.value = [];
	inputError.value = [];
	status.value = null;
	invoiceId.value = null;

	fileUpload.value.clear();
};

// Print on create invoice page
// const handlePrintDetailInvoice = () => {
// 	let url;
// 	switch (window.mode) {
// 		case 'development':
// 			url = 'https://spb.isupplier-portal.com/dev/#/printinvoicelist';
// 			break;
// 		case 'uat':
// 			url = 'https://spb.isupplier-portal.com/uat/#/printinvoicelist';
// 			break;
// 		case 'production':
// 			url = 'https://spb.isupplier-portal.com/#/printinvoicelist';
// 			break;
// 		default:
// 			url = 'http://localhost:8080/#/printinvoicelist';
// 	}
// 	const payload = {
// 		org_id: route.query.v,
// 		db_instance: route.query.i,
// 		invoice_id: props.id,
// 	};

// 	if (currentUser.value.leveltenant === 2) {
// 		payload.vendor_id = vendorId.value;
// 	}

// 	sessionStorage.setItem('paramsinvoice', JSON.stringify(payload));
// 	window.open(url, 'Print', 'height=720,width=1280');
// };

const handleSubmit = async () => {
	try {
		const resultVal = await validation();
		if(resultVal === false){
			return;
		}

		let resp;
		
		if(!status.value){
			resp = await handleSave();
		}else{
			resp = await handleUpdate('SU');
		}


		console.log(resp, "RESP")

		if(resp.status !== 200) return;

		const result = await PendingPOService.submitInvoicePrepayment({
			id: headerId.value ? headerId.value : Number(route.params.id),
			user_id: Number(sessionStorage.idUser),
			companyname: sessionStorage.mailToCompany,
			vendorname: vendorName.value,
			invoice_no: invoiceNo.value,
			invoice_type: sessionStorage.leveltenant == 2 
				? InvoiceTypeEnum.Prepayment : InvoiceTypeEnum.PrepaymentProxy,
			prepayment_flag: prepayment_flag.value,
			vendorId: vendorId.value,
			line: selectedPo.value,
			org_id: company.value.value,
			db_instance: company.value.db_instance
		});

		if(result.status === 200){
			
			status.value = 'OPEN';

			toast.add({
				severity: 'success',
				summary: 'Success to submit Invoice',
				life: 3000,
			});

			await getInvoiceDetail(headerId.value ? headerId.value : route.params.id);


			Swal.fire({
				title: "Success To Submit Invoice",
				text: "Please send invoice to dexa immediately!",
				icon: "success"
			});


			return;
		
		}
		
	} catch (error) {
		console.log(error)
		toast.add({
			severity: 'error',
			summary: 'Failed to submit Invoice',
			life: 3000,
		});
	}
}

const onDownload = async () => {
	try {
		loadingDownladFile.value = true;

		const leveltenant = sessionStorage.getItem('leveltenant');
		let id = route.params.id;
		if (!id) {
			id = invoiceId.value;
		}

		const res = await InvoicePOService.getFileBase64(
			id,
			leveltenant,
			vendorId.value
		);

		if (res.status === 200) {
			fileBuffer.value = res.data.data;
		}
	} catch (err) {
		loadingDownladFile.value = false;
		toast.add({
			severity: 'error',
			summary: 'Connection Unstable !',
			life: 3000,
		});
		throw Error('Error downloading file');
	}
	const linkSource = `data:application/pdf;base64,${fileBuffer.value}`;
	const downloadLink = document.createElement('a');
	const fileName = savedFile.value.filename;
	downloadLink.href = linkSource;
	downloadLink.download = fileName;
	downloadLink.click();
	loadingDownladFile.value = false;
};

const handleChangeTax = (event) => {
	const {value} = event.target;
	taxInvoiceNo.value = value.replace(/[a-zA-Z]/g,"").substring(0,20);
}

const onChangeVAT = async (event, index) => {
	//----VALIDATION FROM----
	const splitz = event.value.VAT_CODE.split(" ")
	let rule = new Array();
	if(splitz.includes("EXCL")){
		selectedPo.value.forEach((item) => {
			const sp = typeof(item.vat_code) === 'string' ? 
				item.vat_code.split(" ") : []
			if(sp.includes("INCL") === true){
				rule.push(item);
				const vatData = getVAT.value.find((Q) => Q.VAT_CODE === selectedPo.value[index].vat_current);
				
				selectedPo.value[index].percentage_rate = vatData.PERCENTAGE_RATE;
				selectedPo.value[index].vat_code = vatData.VAT_CODE;
				selectedPo.value[index].vat_code_id = vatData.TAX_RATE_ID;
				selectedPo.value[index].tax_rate_code = vatData.TAX_RATE_CODE;
				return;
			}
		});
		
		if(rule.length > 0) {
	
			return
		}
	}else if(splitz.includes("INCL")){
		selectedPo.value.forEach((item) => {
			const sp = typeof(item.vat_code) === 'string' ? 
				item.vat_code.split(" ") : item.vat_code.VAT_CODE.split(" ")
			const isTrue = item.vat_code !== null && sp.includes("INCL") == false;
			if(isTrue){
				rule.push(item);
				console.log(selectedPo.value[index].vat_current, "VAT CURRENT")
				// const vatData = getVAT.value.find((item) => item.TAX_RATE_ID === selectedPo.value[index].vat_current);
				const vatData = getVAT.value.find((Q) => Q.VAT_CODE === selectedPo.value[index].vat_current);
				selectedPo.value[index].percentage_rate = vatData.PERCENTAGE_RATE;
				selectedPo.value[index].vat_code = vatData.VAT_CODE;
				selectedPo.value[index].vat_code_id = vatData.TAX_RATE_ID;
				selectedPo.value[index].tax_rate_code = vatData.TAX_RATE_CODE;
				return;
			}
		});

	
		if(rule.length > 0) {
			toast.add({
				severity: 'error',
				summary: 'Cannot choose different type of VAT Code',
				life: 3000,
			});
			return
		}
	}
	//---VALIDATION END---
		
	if (
		selectedPo.value[index].total_amount === undefined 
	) {
		selectedPo.value[index].vat_code = null;
	} else {
		const vatData = getVAT.value.find((item) => item.TAX_RATE_ID === event.value.TAX_RATE_ID);

		if (vatData) {
			selectedPo.value[index].percentage_rate = vatData.PERCENTAGE_RATE;
			selectedPo.value[index].vat_code = vatData.VAT_CODE;
			selectedPo.value[index].vat_code_id = vatData.TAX_RATE_ID;
			selectedPo.value[index].tax_rate_code = vatData.TAX_RATE_CODE;
			selectedPo.value[index].change = true;
			await getTotalPPN();
		}
	}
};

	const getDropdownWHT = async () => {
		if(!company.value) return;
		
		const result = await InvoiceNonPOservice.getListWht(
			company.value.value,
			company.value.db_instance
		);

		const noSelect = {
			WHT_RATE: 0,
			WHT_CODE_ID: 0,
			WHT_CODE: "- No Select -"
		}
		getWHT.value = [noSelect, ...result.data.data];
			
	};

	const searchVAT = (event) => {
		if (!event.query.trim().length) {
			filteredVAT.value = [...getVAT.value]
		}else {
			filteredVAT.value = getVAT.value.filter((val) => val.VAT_CODE.toLowerCase().includes(event.query.toLowerCase()));
		} 
	}

	const searchWHT = (event) => {
		if (!event.query.trim().length) {
			filteredWHT.value = [...getWHT.value]
		}else {
			filteredWHT.value = getWHT.value.filter((val) => val.WHT_CODE.toLowerCase().includes(event.query.toLowerCase()));
		} 
	}

	const onChangeWHT = async (event, index) => {
		if (
			selectedPo.value[index].AMOUNT === undefined ||
			selectedPo.value[index].AMOUNT === null
		) {
			selectedPo.value[index].WHT_CODE = null;
		} else {
			let whtData = getWHT.value.find((item) => item.WHT_CODE_ID === event.value.WHT_CODE_ID);
			if (whtData) {
				selectedPo.value[index].wht_code = whtData.WHT_CODE;
			}
		}
	};

</script>

<style scoped>
.form-card {
	padding: 24px;
	border: 1px solid #e0e0e0;
	border-radius: 16px;
}

input.statusInvoice,
input#disabledCompany {
	color: #000 !important;
}
</style>
