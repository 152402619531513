<template>
	<!-- eslint-disable -->
	<DataTable
		:value="tableList"
		:lazy="true"
		:paginator="true"
		:rows="10"
		:totalRecords="totalData"
		:loading="loading"
		:rowsPerPageOptions="[10, 20, 30]"
		responsiveLayout="scroll"
		@page="onPage($event)"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #empty>{{ empty }}</template>
		<template #loading>Loading data</template>
		<Column header="Item Number" field="item_number" headerStyle="min-width:5rem;">
		</Column>
		<Column header="Description" field="item_desc" headerStyle="min-width:10rem;">
		</Column>
		<Column header="UoM" field="uom" headerStyle="min-width:4rem;">
		</Column>
		<Column header="Pack Size" field="pack_size" headerStyle="min-width:10rem;">
		</Column>
		<Column header="Category" field="category" headerStyle="min-width:10rem;">
		</Column>
		<Column header="Vendor Name" field="vendor_name" headerStyle="min-width:10rem;">
		</Column>
		<Column header="ROP Vendor" field="rop_vendor" headerStyle="min-width:5rem;">
		</Column>
		<Column header="Item Type" field="item_type" headerStyle="min-width:10rem;">
		</Column>
		<Column
        class="call-button-container"
        header="Actions"
        selectionMode="single"
        >
            <template #body="slotProps">
                <Button
                    icon="pi pi-eye"
                    class="p-button-rounded p-button-text"
                    @click="editChildren(slotProps.data)"
                    v-tooltip="'Detail'"
                />
            </template>
    </Column>
	</DataTable>
</template>

<script setup>
import { onMounted, ref} from 'vue';
import AuthService from '../../../service/AuthService';
import ItemJit from '../../../service/ItemJit';
import { useRouter } from "vue-router";


// eslint-disable-next-line no-undef
const props = defineProps({
	search: {
		type: Object,
	},
	choose: {
		type: Object,
	}
});

onMounted(async () => {
	populateTable()
});


const loading = ref(false);
const page = ref(1)
const rowsPerPage = ref(10)
const tableList = ref();
const totalData = ref(0);
const empty = ref('Search for Data')
const router = useRouter();
// const index = ref()

	
const populateTable = async () => {
	AuthService.whoAmi().then(async (response) => {
		if (response.status === 200){
			loading.value = true;
			const payload = { search : props.search ? props.search : null, 
			choose : props.choose ? props.choose : null,
			page : page.value, 
			rows : rowsPerPage.value}
			const data = await ItemJit.getTable(payload)
			loading.value = true
			if (data.data.data.totalData[0].totalData > 0){
				tableList.value = data.data.data.data
				totalData.value = data.data.data.totalData[0].totalData
			} else {
				tableList.value = []
				totalData.value = []
				empty.value = 'Data not Found'
			}
		loading.value = false;
		} else {
			window.alert('Session is expired')
		}
	})
};

const onPage = async (event) => {
  loading.value = true
  page.value = event.page + 1;
  rowsPerPage.value = event.rows;
  await populateTable();
}

const editChildren = (rowIndex) => {
	router.push({
		name: "DetailItemJit",
		params: {
		id: rowIndex.item_id,
		},
	});
}


</script>

<style scoped></style>
