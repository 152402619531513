<template>
    <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
            <div class="col-12 xl:col-6" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);">
                <div class="h-full w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                    <div class="text-center mb-5">
                        <!-- <img :src="'http://localhost:8080/layout/images/DXG Supplier Portal dan Billing Invoice 03-06.png'" alt="Image" class="mb-5" style="width:181px; height:100px;"> -->
                        <img :src="'https://spb.isupplier-portal.com/layout/images/DXG Supplier Portal dan Billing Invoice 03-06.png'" alt="Image" class="mb-5" style="width:181px; height:100px;">
                        <div class="text-900 text-3xl font-medium mb-3"></div>
                        <span class="text-600 font-medium">Reset Your Password</span>
                    </div>
                
                    <div class="w-full md:w-10 mx-auto">
                        <label for="email" class="block text-900 text-xl font-medium mb-2">New Password</label>
                        <!-- <InputText id="newpw" v-model="newpassword" type="password" class="w-full mb-3" placeholder="Insert your new password" style="padding:1rem;"/> -->
                        <Password id="newpw" v-model="newpassword" placeholder="Type your new password" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem" @keydown.enter="resetNow()"></Password>
                        <label for="email" class="block text-900 text-xl font-medium mb-2">Confirm Password</label>
                        <!-- <InputText id="confirmpw" v-model="confirmpassword" type="password" class="w-full mb-3" placeholder="Confirm your password" style="padding:1rem;"/> -->
                        <Password id="confirmpw" v-model="confirmpassword" placeholder="Confirm your password" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem" @keydown.enter="resetNow()"></Password>
                        <Button label="Reset Password" class="w-12 p-3 mt-4 ml-3 text-xl" @click="resetNow()"></button>
                        <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id">{{msg.content}}</Message>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import { UrlTab } from '../constants/UrlTab';
import ForgotPasswordService from '../service/ForgotPasswordService'
export default {
    data() {
        return {
            newpassword: null,
            confirmpassword: null,
            id: null
        }
    },

    created() {
        if(!sessionStorage.getItem('tknfrgt')) {
            this.redirectLogin()
        }
    },
    methods: {
        async resetNow() {
            let payload;
            const spliter = sessionStorage.getItem('tknfrgt')
            payload = {
                password: this.newpassword,
                confirmPassword: this.confirmpassword,
                id: spliter,
            };
            if (this.newpassword === this.confirmpassword) {
                const resp = await ForgotPasswordService.resetPass(payload)
                if (resp.status === 200) {
                    window.alert('Password successfully changed');
                        sessionStorage.clear();
                        this.redirectLogin()
                } else if (resp.status === 201) {
                    window.alert('Link has expired');
                        sessionStorage.clear();
                        this.redirectLogin()
                }
            } else {
                window.alert('Password does not match');
            }
        },
        async redirectLogin() {
            let url = UrlTab() + "/login";
            window.location.replace(url);
        }
    }
}
</script>