<template>
	<div>
		<Toast />
		<Card>
			<template #title>
				<span class="text-xl">Notification</span>
			</template>
			<template #content>
				<div class="flex justify-content-between">
					<!-- Notif module -->
					<div>
						<p class="font-bold mb-0 mb-4">Status</p>
						<!-- <p v-for="name in notificationModulesName" :key="name">
							{{ name }}
						</p> -->
						<p class="mb-3">Planning Order</p>
						<p class="mb-3">Purchase Order</p>
						<p class="mb-3">Shipment Notice</p>
						<p class="mb-3">CAPA</p>
						<p class="mb-0">Supplier Master</p>
					</div>

					<!-- Open -->
					<div>
						<p class="font-bold mb-0 mb-4 text-green-600">Open</p>
						<!-- This have to following the order of modules name -->
						<p>{{ notificationOpenByPlanningOrder }}</p>
						<p>{{ notificationOpenByPurchaseOrder }}</p>
						<p>{{ notificationOpenByShipmentNotice }}</p>
						<p>{{ notificationOpenByCAPA }}</p>
						<p>{{ notificationOpenBySupplierMaster }}</p>
					</div>

					<!-- Close -->
					<div>
						<p class="font-bold mb-0 mb-4 text-pink-600">Close</p>
						<!-- This have to following the order of modules name -->
						<p>{{ notificationClosedByPlanningOrder }}</p>
						<p>{{ notificationClosedByPurchaseOrder }}</p>
						<p>{{ notificationClosedByShipmentNotice }}</p>
						<p>{{ notificationClosedByCAPA }}</p>
						<p>{{ notificationClosedBySupplierMaster }}</p>
					</div>
				</div>
			</template>

			<template #footer>
				<Button
					label="Link"
					class="p-button-link font-bold"
					@click="openModal"
				>
					<span class="mr-2">See details</span>
					<i class="pi pi-arrow-right"></i>
				</Button>
			</template>
		</Card>

		<!-- Modal -->
		<Dialog
			header="Notification List"
			v-model:visible="displayModal"
			:style="{ width: '80%' }"
			:modal="true"
		>
			<div class="grid" style="margin-top: 0.5em">
				<div class="col-12 p-fluid">
					<div class="grid formgrid" style="margin: 1em 0 0 0">
						<div
							v-if="isSupplier"
							class="col-12 mb-5 lg:col-3 lg:mb-0"
						>
							<span class="p-float-label">
								<Dropdown
									id="dropdown"
									:options="isiCompany"
									v-model="company"
								>
								</Dropdown>
								<label for="dropdown">Company</label>
							</span>
						</div>
						<div
							v-if="!isSupplier"
							class="col-12 mb-5 lg:col-3 lg:mb-0"
						>
							<span class="p-float-label">
								<Dropdown
									id="dropdown"
									:options="isiCompany"
									v-model="company"
									optionLabel="name"
								>
								</Dropdown>
								<label for="dropdown">Company</label>
							</span>
						</div>
						<div class="col-12 mb-5 lg:col-3 lg:mb-0">
							<span class="p-float-label">
								<Dropdown
									id="dropdown"
									:options="modules"
									v-model="search"
									optionLabel="name"
								>
								</Dropdown>
								<label for="dropdown">Module</label>
							</span>
						</div>
						<div class="col-12 mb-2 lg:col-2 lg:mb-0">
							<span class="p-float-label">
								<Dropdown
									id="dropdown"
									style="width: 100%"
									:options="statusOptions"
									v-model="searchStatus"
									optionLabel="name"
								></Dropdown>
								<label for="dropdown">Status</label>
							</span>
						</div>
						<div
							class="col-12 mb-5 lg:col-1 lg:mb-0 call-button-container-100"
						>
							<Button label="Go" @click="searchFilter()"></Button>
						</div>
						<div
							class="col-12 mb-5 lg:col-1 lg:mb-0 call-button-container-100"
						>
							<Button
								label="Export"
								class="p-button-warning"
								@click="exportExcel()"
							></Button>
						</div>
					</div>
				</div>
				<DataTable
					:value="filtered ? filtered : notificationsdata"
					v-model:selection="selectedNotifications"
					dataKey="id"
					:paginator="true"
					:rows="5"
					v-model:filters="filters"
					resizableColumns="true"
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
					:rowsPerPageOptions="[5, 10, 25]"
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
					responsiveLayout="stack"
					breakpoint="960px"
					:style="{ width: '100%', padding: '8px' }"
				>
					<template #empty>No notifications found.</template>
					<Column
						v-for="col of notifColumns"
						:field="col.field"
						:header="col.header"
						:key="col.field"
						:sortable="true"
					/>
					<Column header="View">
						<template #body="slotProps">
							<Button
								icon="pi pi-eye"
								class="p-button-rounded p-button mr-2"
								@click="viewDetail(slotProps.data)"
							/>
						</template>
					</Column>
				</DataTable>
			</div>
			<template #footer>
				<!-- <Button label="No" icon="pi pi-times" @click="closeModal" class="p-button-text"/>
                <Button label="Yes" icon="pi pi-check" @click="closeModal" autofocus /> -->
			</template>
		</Dialog>
	</div>
</template>

<script>
/* eslint-disable */
// import NotificationsModal from '../modals/NotificationsModal.vue';
import NotificationService from '../../../service/NotificationService';
import AuthService from '../../../service/AuthService';
import LovService from '../../../service/LovService';
import PoFulfillment from '../../../service/PoFulfillment';
import SimpleCrypto from 'simple-crypto-js';
import XLSX from 'xlsx';

export default {
	components: {},
	props: {
		notifications: Array,
	},
	data() {
		return {
			displayModal: false,
			modules: [
				{
					name: 'ALL',
				},
				{
					name: 'Planning Order',
				},
				{
					name: 'Purchase Order',
				},
				{
					name: 'Shipment Notice',
				},
				{
					name: 'CAPA',
				},
				{
					name: 'Supplier Master',
				},
			],
			statusOptions: [
				{
					name: 'ALL',
				},
				{
					name: 'Open',
				},
				{
					name: 'Close',
				},
			],
			filtered: null,
			search: {},
			searchStatus: {},
			notifColumns: [],
			notificationsdata: null,
			filterForSupp: [],
			notification: {},
			selectedNotifications: null,
			company: null,
			isiCompany: null,
			currentTenantLvl: null,
			isSupplier: false,
			currentVendor: null,
		};
	},
	created() {
		this.notifColumns = [
			{
				field: 'modulename',
				header: 'Module',
			},
			{
				field: 'sbjmessage',
				header: 'Subject',
			},
			{
				field: 'receiveddate',
				header: 'Received Date',
			},
			{
				field: 'status',
				header: 'Status',
			},
		];
		// this.getCompany();
	},
	async mounted() {
		this.search.name = 'ALL';
		this.searchStatus.name = 'ALL';
		AuthService.whoAmi().then(async (value) => {
			this.currentTenantLvl = value.data.data.leveltenant;

			if (this.currentTenantLvl == 2) {
				const secretKey = 'some-unique-key';
				const simpleCrypto = new SimpleCrypto(secretKey);
				const decipherText = simpleCrypto.decrypt(
					sessionStorage.getItem('dropdownCompany')
				);
				this.company = decipherText;
				this.isSupplier = true;
				const payload = {
					idusrkkt: value.data.data.id,
					bioaddress: value.data.data.bioaddress,
				};
				NotificationService.getCompanyCurrentSupp(payload).then(
					async (response) => {
						// this.isiCompany = response.data.data[0]
						let isiCompanyArray = [];
						isiCompanyArray.push('ALL');
						response.data.data[0].forEach((element) => {
							isiCompanyArray.push(element.companyname);
						});
						this.isiCompany = [...new Set(isiCompanyArray)];
					}
				);
				NotificationService.getCurrentSupplier(payload).then(
					async (response) => {
						this.currentVendor =
							response.data.data[0][0].vendorname;
						// await new Promise(resolve => setTimeout(resolve, 750));
						// const payloadB = {
						// 	vendorname: this.currentVendor
						// }
						const vendorId = sessionStorage.getItem('vendorId');
						NotificationService.getAllNotificationsSupp(
							vendorId
						).then(async (respond) => {
							console.log({ resNotifSuppDetail: respond });
							if (respond.status == 200) {
								respond.data.data[0].forEach((element) => {
									if (element.notif_type === 'Supplier') {
										this.filterForSupp.push(element);
									}
								});
								this.notificationsdata = this.filterForSupp;

								const notifData = this.notificationsdata.filter(
									(val) =>
										!val.sbjmessage.includes(this.company)
								);
								console.log({
									notifData,
								});
								this.searchFilter();
							} else {
								this.notificationsdata = [];
							}
						});
					}
				);
			} else if (this.currentTenantLvl == 1) {
				this.getCompany();
				await new Promise((resolve) => setTimeout(resolve, 800));
				const passDBInstance = {
					bioaddress: value.data.data.bioaddress,
				};
				NotificationService.getAllNotificationsPur(passDBInstance).then(
					async (response) => {
						if (response.status == 200) {
							response.data.data[0].forEach((element) => {
								if (element.notif_type !== 'Supplier') {
									this.filterForSupp.push(element);
								}
							});
							this.notificationsdata = this.filterForSupp;
							let filterForPur = [];
							this.notificationsdata.forEach((element) => {
								let includedCompany =
									element.sbjmessage.includes(
										this.company.name
									);

								if (includedCompany === false) {
									filterForPur.push(element);
								}
							});
							this.notificationsdata = filterForPur;
						} else {
							this.notificationsdata = [];
						}
					}
				);
			}
		});
		// await new Promise(resolve => setTimeout(resolve, 750));
	},
	methods: {
		exportExcel() {
			let data = this.filtered ? this.filtered : this.notificationsdata;
			if (data.length !== 0) {
				let today = new Date();
				let dd = String(today.getDate()).padStart(2, '0');
				let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
				let yyyy = today.getFullYear();
				let combinedDate = dd + mm + yyyy;
				let filename = 'User List_' + combinedDate + '.xlsx';
				let ws = XLSX.utils.json_to_sheet(data);
				let wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, 'My Worksheet');
				XLSX.writeFile(wb, filename);
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Data is Empty',
					life: 3000,
				});
			}
		},
		getCompany() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					if (value.data.data.leveltenant == 1) {
						if (value.data.data.userid == 'dexaadmin') {
							LovService.getValueType('COMPANY').then(
								(response) => {
									this.isiCompany = response.data.data;
								}
							);
						} else {
							let payload = value.data.data.bioaddress;
							LovService.getcompanydb(payload).then(
								(response) => {
									// this.isiCompany = response.data.data
									// let payload = {isi : response.data.data[0].company}
									// PlanningOrder.getCompanyId(payload).then(response => {
									this.isiCompany = response.data.data;
									try {
										const secretKey = 'some-unique-key';
										const simpleCrypto = new SimpleCrypto(
											secretKey
										);
										const decipherText =
											simpleCrypto.decrypt(
												sessionStorage.getItem(
													'dropdownCompany'
												)
											);

										this.isiCompany.forEach((element) => {
											if (element.name === decipherText) {
												this.company = element;
												this.getCompanyPur();
												throw 'Break';
											}
										});
									} catch (e) {
										if (e !== 'Break') throw e;
									}
									// })
								}
							);
						}
					} else if (value.data.data.leveltenant == 2) {
						let payload = value.data.data.id;
						// PlanningOrder.getCompanySupp(payload).then(
						// 	(response) => {
						// 		let company = [
						// 			{ name: response.data.data[0].company },
						// 		];
						// 		this.isiCompany = company;
						// 	}
						// );
						PlanningOrder.getCompanySupp(payload).then(
							(response) => {
								let payload = response.data.data;
								let companyList = [];
								try {
									payload.forEach((element) => {
										LovService.getCompanyByName(
											element.company
										).then((response) => {
											companyList.push(
												response.data.data[0]
											);

											const secretKey = 'some-unique-key';
											const simpleCrypto =
												new SimpleCrypto(secretKey);
											const decipherText =
												simpleCrypto.decrypt(
													sessionStorage.getItem(
														'dropdownCompany'
													)
												);
											// let companyList = JSON.parse(sessionStorage.getItem('list'))
											// this.isiCompany = companyList;

											if (
												response.data.data[0].name ===
												decipherText
											) {
												this.company =
													response.data.data[0];
												// this.searchTableNested();
												this.getCompanyPur();
											}
										});
									});
									this.isiCompany = companyList;
								} catch (e) {
									if (e !== 'Break') throw e;
								}
							}
						);
					}
				}
			});
		},
		openModal() {
			this.displayModal = true;
		},
		searchFilter() {
			if (this.currentTenantLvl == 2) {
				this.filtered = this.searchAll();
			} else if (this.currentTenantLvl == 1) {
				// this.filtered = this.searchAllPur()
				this.filtered = this.getCompany();
			}
		},
		getCompanyPur() {
			if (this.company != null) {
				let payload = {
					org_id: this.company.value,
					db_instance: this.company.db_instance,
					module: this.search.name,
					status: this.searchStatus.name,
				};
				NotificationService.getPurNotificationsSearch(payload).then(
					(response) => {
						if (response.status == 200) {
							this.notificationsdata = response.data.data[0];
						} else {
							this.notificationsdata = [];
						}
					}
				);
			}
		},
		searchAll() {
			if (this.company !== null && this.company !== 'ALL') {
				this.notificationsdata = this.filterForSupp;
				let filterSearchSupp = [];
				this.notificationsdata.forEach((element) => {
					let includedCompany = element.sbjmessage.includes(
						this.company
					);

					if (includedCompany === true) {
						filterSearchSupp.push(element);
					}
				});
				this.notificationsdata = filterSearchSupp;
				console.log({
					notifdatasearch: this.notificationsdata,
				});
			} else if (this.company === 'ALL') {
				this.notificationsdata = this.filterForSupp;
			}
			if (
				this.search.name === 'ALL' &&
				this.searchStatus.name === 'ALL'
			) {
				this.filtered = null;
			} else if (
				this.search.name === 'ALL' ||
				this.searchStatus.name === 'ALL'
			) {
				return this.notificationsdata.filter((item) => {
					return (
						item.modulename.match(this.search.name) ||
						item.status.match(this.searchStatus.name)
					);
				});
			} else {
				return this.notificationsdata.filter((item) => {
					return (
						item.modulename.match(this.search.name) &&
						item.status.match(this.searchStatus.name)
					);
				});
			}
		},
		searchAllPur() {
			this.notificationsdata = this.filterForSupp;
			let filterSearchSupp = [];
			this.notificationsdata.forEach((element) => {
				let includedCompany = element.sbjmessage.includes(
					this.company.name
				);

				if (includedCompany === false) {
					filterSearchSupp.push(element);
				}
			});
			this.notificationsdata = filterSearchSupp;
			if (
				this.search.name === 'ALL' &&
				this.searchStatus.name === 'ALL'
			) {
				this.filtered = null;
			} else if (
				this.search.name === 'ALL' ||
				this.searchStatus.name === 'ALL'
			) {
				return this.notificationsdata.filter((item) => {
					return (
						item.modulename.match(this.search.name) ||
						item.status.match(this.searchStatus.name)
					);
				});
			} else {
				return this.notificationsdata.filter((item) => {
					return (
						item.modulename.match(this.search.name) &&
						item.status.match(this.searchStatus.name)
					);
				});
			}
		},
		viewDetail(data) {
			let moduletype = data.modulename.toString();
			if (moduletype == 'Planning Order' && this.currentTenantLvl == 2) {
				this.$router.push({
					name: 'plannodetail',
					params: {
						nameModule: data.modulename,
						subjectMessage: data.sbjmessage,
					},
				});
			} else if (
				moduletype == 'Planning Order' &&
				this.currentTenantLvl == 1
			) {
				this.$router.push({
					name: 'plannodetailpur',
					params: {
						nameModule: data.modulename,
						subjectMessage: data.sbjmessage,
					},
				});
				// this.displayModal = true;
			}
			if (moduletype == 'Purchase Order' && this.currentTenantLvl == 2) {
				this.$router.push({
					name: 'podetail',
					params: {
						nameModule: data.modulename,
						subjectMessage: data.sbjmessage,
					},
				});
			} else if (
				moduletype == 'Purchase Order' &&
				this.currentTenantLvl == 1
			) {
				this.$router.push({
					name: 'podetailpur',
					params: {
						nameModule: data.modulename,
						subjectMessage: data.sbjmessage,
					},
				});
			}
			if (moduletype == 'Shipment Notice') {
				this.$router.push({
					name: 'shipmentdetail',
					params: {
						nameModule: data.modulename,
						subjectMessage: data.sbjmessage,
					},
				});
			}
			if (moduletype == 'CAPA') {
				this.$router.push({
					name: 'capadetail',
					params: {
						nameModule: data.modulename,
						subjectMessage: data.sbjmessage,
					},
				});
			}
		},
		closeModal() {
			this.displayModal = false;
		},
		viewPODetail() {
			this.$router.push('/dashboard/notifdetails/podetail');
		},
		viewCapaDetail() {
			this.$router.push('/dashboard/notifdetails/capadetail');
		},
		viewShipmentDetail() {
			this.$router.push('/dashboard/notifdetails/shipmentdetail');
		},
	},
	computed: {
		notificationModulesName() {
			const modulesName = this.notifications.map(
				(notif) => notif.modulename
			);

			const modulesSet = new Set();

			modulesName.forEach((name) => modulesSet.add(name));

			return modulesSet;
		},
		// Open
		notificationOpenByPlanningOrder() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Planning Order' &&
					notif.status === 'Open'
			);
			return notifs.length;
		},
		notificationOpenByPurchaseOrder() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Purchase Order' &&
					notif.status === 'Open'
			);
			return notifs.length;
		},
		notificationOpenByShipmentNotice() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Shipment Notice' &&
					notif.status === 'Open'
			);
			return notifs.length;
		},
		notificationOpenByCAPA() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'CAPA' && notif.status === 'Open'
			);
			return notifs.length;
		},
		notificationOpenBySupplierMaster() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Supplier Master' &&
					notif.status === 'Open'
			);
			return notifs.length;
		},
		// Closed
		notificationClosedByPlanningOrder() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Planning Order' &&
					notif.status === 'Close'
			);
			return notifs.length;
		},
		notificationClosedByPurchaseOrder() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Purchase Order' &&
					notif.status === 'Close'
			);
			return notifs.length;
		},
		notificationClosedByShipmentNotice() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Shipment Notice' &&
					notif.status === 'Close'
			);
			return notifs.length;
		},
		notificationClosedByCAPA() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'CAPA' && notif.status === 'Close'
			);
			return notifs.length;
		},
		notificationClosedBySupplierMaster() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Supplier Master' &&
					notif.status === 'Close'
			);
			return notifs.length;
		},
	},
};
</script>
