<template>
	<!-- eslint-disable -->
	<div class="grid">
		<Toast />
		<ProgressModal :progress="progress">
			<template #message>
				Update invoice in progress. Please wait...
			</template>
		</ProgressModal>
		<ProgressModal :progress="progressReject">
			<template #message>
				Reject invoice in progress. Please wait...
			</template>
		</ProgressModal>
		<ProgressModal :progress="progressSubmit">
			<template #message> Please wait... </template>
		</ProgressModal>
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<div class="col-12">
			<div class="content-wrapper mb-4">
				<div class="content-header">E-Invoice Summary</div>
				<div class="content-body">
					<div class="grid">
						<div class="col-12 md:col-12 lg:col-6">
							<div class="grid">
								<div class="col-6">
									<p id="label-key">Company</p>
								</div>
								<div class="col-6 col-auto ml-auto">
									<p id="label-value">
										{{ companyInInvoiceHeader }}
									</p>
								</div>
							</div>
							<div class="grid mb-5">
								<div class="col-6">
									<p id="label-key">Supplier</p>
								</div>
								<div class="col-6 col-auto ml-auto">
									<p id="label-value">
										{{ supplierInInvoiceHeader }}
									</p>
								</div>
							</div>
							<div class="mb-6">
								<span class="p-float-label">
									<AutoComplete
										v-model="supplierSites"
										:disabled="!supplierSiteList"
										:suggestions="filteredSuppSite"
										class="w-full input-disabled"
										id="suppliersite"
										:dropdown="true"
										@complete="searchSuppSite($event)"
										field="suppliersite"
										:key="supplierSiteReload"
										placeholder=" "
										forceSelection
										:class="{
											'p-invalid':
												v$.supplierSites.$error,
										}"
									>
									</AutoComplete>
									<label for="inputtext">Supplier Site</label>
								</span>
								<small
									v-if="v$.supplierSites.$error"
									class="p-error"
									>{{
										v$.supplierSites.$errors[0].$message.replace(
											'Value',
											'Supplier Site'
										)
									}}</small
								>
							</div>
							<div class="mb-6">
								<hr />
							</div>
							<div class="mb-5">
								<span class="p-float-label">
									<InputText
										v-model="invoiceNumber"
										class="w-full mb-2 lg:mb-0"
										:class="{
											'p-invalid':
												v$.invoiceNumber.$error,
										}"
									/>
									<label for="inputtext"
										>Invoice Number</label
									>
								</span>
								<small
									v-if="v$.invoiceNumber.$error"
									class="p-error"
									>{{
										v$.invoiceNumber.$errors[0].$message.replace(
											'Value',
											'Invoice Number'
										)
									}}</small
								>
							</div>
							<div class="grid mb-5">
								<div class="col-6">
									<span class="p-float-label">
										<Calendar
											v-model="invoiceDate"
											dateFormat="d-M-yy"
											:showIcon="true"
											class="w-full mb-3 lg:mb-0"
											:class="{
												'p-invalid':
													v$.invoiceDate.$error,
											}"
										/>
										<label for="inputtext"
											>Invoice Date</label
										>
									</span>
									<small
										v-if="v$.invoiceDate.$error"
										class="p-error"
										>{{
											v$.invoiceDate.$errors[0].$message.replace(
												'Value',
												'Tax Invoice Date'
											)
										}}</small
									>
								</div>
								<div class="col-6">
									<span class="p-float-label">
										<Calendar
											v-model="glDate"
											dateFormat="d-M-yy"
											:showIcon="true"
											class="w-full mb-3 lg:mb-0"
											:class="{
												'p-invalid': v$.glDate.$error,
											}"
										/>
										<label for="inputtext">GL Date</label>
									</span>
									<small
										v-if="v$.glDate.$error"
										class="p-error"
										>{{
											v$.glDate.$errors[0].$message.replace(
												'Value',
												'GL Date'
											)
										}}</small
									>
								</div>
							</div>
							<div class="mb-5">
								<span class="p-float-label">
									<Textarea
										v-model="descriptionHeader"
										rows="6"
										class="w-full mb-3 lg:col-auto lg:mr-3 lg:mb-0"
										:class="{
											'p-invalid':
												v$.descriptionHeader.$error,
										}"
									/>
									<label for="inputtext">Description</label>
								</span>
								<small
									v-if="v$.descriptionHeader.$error"
									class="p-error"
									>{{
										v$.descriptionHeader.$errors[0].$message.replace(
											'Value',
											'Description'
										)
									}}</small
								>
							</div>
							<div class="grid mb-4">
								<div class="col-6">
									<span class="p-float-label">
										<InputText
											id="inputmask"
											v-model="taxInvoiceNo"
											class="w-full mb-2 lg:mb-0"
											:class="{
												'p-invalid':
													v$.taxInvoiceNo.$error,
											}"
											@change="handleChangeTax($event)"
											:disabled="!invoiceDate"
										/>
										<label for="username">
											Tax Invoice No.
										</label>
									</span>
									<small
										v-if="v$.taxInvoiceNo.$error"
										class="p-error"
										>{{
											v$.taxInvoiceNo.$errors[0].$message.replace(
												'Value',
												'Tax Invoice No'
											)
										}}</small
									>
								</div>
								<div class="col-6">
									<span class="p-float-label">
										<Calendar
											v-model="taxInvoiceDate"
											dateFormat="d-M-yy"
											:showIcon="true"
											class="w-full mb-3 lg:mb-0"
											:class="{
												'p-invalid':
													v$.taxInvoiceDate.$error,
											}"
										/>
										<label for="inputtext"
											>Tax Invoice Date</label
										>
									</span>
									<small
										v-if="v$.taxInvoiceDate.$error"
										class="p-error"
										>{{
											v$.taxInvoiceDate.$errors[0].$message.replace(
												'Value',
												'Tax Invoice Date'
											)
										}}</small
									>
								</div>
							</div>
							<div>
								<div v-if="saved" class="grid formgrid">
									<div class="col-10 lg:col-10">
										<!-- <a class="button-download p-button" :href="saved.url">
                      <span style="margin: auto" class="font-bold">
                        <i class="pi pi-download mr-2" />
                        {{ saved.filename }}
                      </span>
                    </a> -->
										<Button
											v-if="!loadingDownladFile"
											class="button-download font-normal"
											:label="saved.filename"
											icon="pi pi-download"
											@click="onDownload()"
										/>
										<Button
											v-if="loadingDownladFile"
											class="button-download font-normal"
											:label="`${saved.filename} is downloading`"
											icon="pi pi-download"
											:disabled="true"
										/>
									</div>
									<div class="col-10 lg:col-2">
										<Button
											:class="uppercase"
											icon="pi pi-times"
											class="p-button-rounded p-button-danger p-button-text"
											@click="deleteFile()"
										>
										</Button>
									</div>
								</div>
								<br />
								<div>
									<FileUpload
										ref="fileUpload"
										mode="advanced"
										chooseLabel="Upload File"
										fileLimit="1"
										accept="application/pdf"
										:maxFileSize="5000000"
										:showUploadButton="false"
										:showCancelButton="false"
										:customUpload="true"
										@select="onSelectFile"
										@remove="onRemoveFile"
									>
										<template #empty>
											<p>
												No file selected. (Max file 5MB)
											</p>
										</template>
									</FileUpload>
								</div>
							</div>
							<div class="mt-5"></div>
							<div class="grid">
								<div class="col-12 md:col-12 mt-5 lg:col-9">
									<span class="p-float-label">
										<AutoComplete
											v-model="categoryDetail"
											:suggestions="filteredCategory"
											class="w-full"
											:dropdown="true"
											@complete="searchCategory($event)"
											field="category_detail"
											placeholder=" "
											id="value-category"
											forceSelection
										>
										</AutoComplete>
										<label for="value-category"
											>- Select Detail Category -</label
										>
									</span>
								</div>
							</div>
						</div>
						<div class="col-12 md:col-12 lg:col-6">
							<div class="grid">
								<div class="col-6">
									<p id="label-key">Status</p>
								</div>
								<div class="col-6 col-auto ml-auto">
									<p id="label-value">{{ status }}</p>
								</div>
							</div>
							<div class="grid">
								<div class="col-6">
									<p id="label-key">Invoice Amount</p>
								</div>
								<div class="col-6 col-auto ml-auto">
									<p id="label-value">
										{{
											new Intl.NumberFormat('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}).format(summary.invoiceAmmount)
										}}
									</p>
								</div>
							</div>
							<div class="grid">
								<div class="col-6">
									<p id="label-key">Tax Amount</p>
								</div>
								<div class="col-6 col-auto ml-auto">
									<p id="label-value">
										{{
											new Intl.NumberFormat('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}).format(summary.taxAmmount)
										}}
									</p>
								</div>
							</div>
							<div class="grid">
								<div class="col-6">
									<p id="label-key">Total</p>
								</div>
								<div class="col-6 col-auto ml-auto">
									<p id="label-value">
										{{
											new Intl.NumberFormat('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}).format(summary.totalInvoice)
										}}
									</p>
								</div>
							</div>
							<div class="grid mb-3">
								<div class="col-6">
									<p id="label-key">Currency</p>
								</div>
								<div class="col-6 col-auto ml-auto">
									<p id="label-value">{{ currency }}</p>
								</div>
							</div>
							<div class="grid mb-4">
								<div class="col-6">
									<span class="p-float-label">
										<AutoComplete
											v-model="remitToBankAccount"
											:suggestions="filteredBanks"
											class="w-full input-disabled"
											:disabled="!bankList"
											forceSelection
											:dropdown="true"
											@complete="searchBank($event)"
											field="name"
											placeholder=" "
											:class="{
												'p-invalid':
													v$.remitToBankAccount
														.$error,
											}"
										>
										</AutoComplete>
										<label for="inputtext"
											>Remit to Bank Account</label
										>
									</span>
									<small
										v-if="v$.remitToBankAccount.$error"
										class="p-error"
										>{{
											v$.remitToBankAccount.$errors[0].$message.replace(
												'Value',
												'Remit to Bank Account'
											)
										}}</small
									>
								</div>
								<div class="col-6">
									<span class="p-float-label">
										<AutoComplete
											v-model="paymentMethod"
											class="w-full input-disabled"
											:suggestions="filteredPayment"
											forceSelection
											:disabled="!paymentList"
											field="PAYMENT_METHOD"
											@complete="searchPayment($event)"
											:dropdown="true"
											placeholder=" "
											:class="{
												'p-invalid':
													v$.paymentMethod.$error,
											}"
										>
										</AutoComplete>
										<label for="inputtext"
											>Payment Method</label
										>
									</span>
									<small
										v-if="v$.paymentMethod.$error"
										class="p-error"
										>{{
											v$.paymentMethod.$errors[0].$message.replace(
												'Value',
												'Payment Method'
											)
										}}</small
									>
								</div>
							</div>
							<div class="grid mb-4">
								<div class="col-6">
									<span class="p-float-label">
										<AutoComplete
											v-model="terms"
											class="w-full input-disabled"
											:suggestions="filteredTerm"
											forceSelection
											:disabled="!listTerm"
											:dropdown="true"
											field="TERM_NAME"
											@complete="searchTerm($event)"
											placeholder=" "
											:class="{
												'p-invalid': v$.terms.$error,
											}"
										>
										</AutoComplete>
										<label for="inputtext">Terms</label>
									</span>
									<small
										v-if="v$.terms.$error"
										class="p-error"
										>{{
											v$.terms.$errors[0].$message.replace(
												'Value',
												'Terms'
											)
										}}</small
									>
								</div>
							</div>
							<div class="mb-4">
								<hr />
							</div>
							<div class="grid mb-5">
								<div class="col-12 md:col-12 lg:col-9">
									<span class="p-float-label">
										<InputText
											v-model="referenceNumber"
											class="w-full"
											style="color: #000"
											placeholder=" "
											disabled
										>
										</InputText>
										<label for="inputtext"
											>Reference Number</label
										>
									</span>
								</div>
								<div class="col-12 md:col-12 lg:col-3">
									<Button
										label="Find SK"
										@click="handleModal()"
										class="p-button-secondary w-full mb-3 lg:w-full lg:mr-3 lg:mb-0"
									/>
								</div>
							</div>
							<div class="mb-5">
								<span class="p-float-label">
									<AutoComplete
										v-model="costCenter"
										:suggestions="filteredCC"
										class="w-full input-disabled"
										forceSelection
										:disabled="!listCostCenter"
										:dropdown="true"
										@complete="searchCostCenter($event)"
										field="SEGMENT4"
										placeholder=" "
										:class="{
											'p-invalid': v$.costCenter.$error,
										}"
									>
									</AutoComplete>
									<label for="inputtext">Cost Center</label>
								</span>
								<small
									v-if="v$.costCenter.$error"
									class="p-error"
									>{{
										v$.costCenter.$errors[0].$message.replace(
											'Value',
											'COST CENTER'
										)
									}}</small
								>
							</div>
							<div class="mb-4">
								<span class="p-float-label">
									<Textarea
										v-model="rejectReason"
										rows="6"
										class="w-full mb-3 lg:col-auto lg:mr-3 lg:mb-0"
									/>
									<label for="inputtext">Reject Reason</label>
								</span>
							</div>
							<div class="mb-4">
								<span class="p-float-label">
									<Textarea
										v-model="rejectReasonRecommendator"
										rows="6"
										disabled
										class="w-full mb-3 lg:col-auto lg:mr-3 lg:mb-0"
									/>
									<label for="inputtext"
										>Approve / Reject Reason</label
									>
								</span>
							</div>
							<div
								style="display: flex; justify-content: flex-end"
							>
								<Button
									icon="pi pi-plus"
									label="add line"
									:disabled="!categoryDetail"
									class="uppercase p-button-success"
									@click="addRow()"
									v-if="status === 'OPEN'"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="content-header">Line</div>
				<div class="content-body">
					<DataTable
						:value="dataLine"
						responsiveLayout="scroll"
						:scrollable="true"
						scrollHeight="600px"
						:key="dataLineReload"
						:loading="loadingDataTable"
						scrollDirection="both"
					>
						<Column header="Description" style="width: 20%">
							<template #body="{ data, index }">
								<InputText
									v-model="data.ITEM_DESCRIPTION"
									type="text"
									class="p-column-filter"
									placeholder="Description"
									:disabled="isForwarder"
									:class="{
										'p-invalid':
											v$.dataLine.$error &&
											v$.dataLine.$errors[0].$response
												.$data[index].ITEM_DESCRIPTION
												.$error,
									}"
								/>
								<div
									v-if="
										v$.dataLine.$error &&
										v$.dataLine.$errors[0].$response.$data[
											index
										].ITEM_DESCRIPTION.$error
									"
									class="mt-1"
								>
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{
											v$.dataLine.$error &&
											v$.dataLine.$errors[0].$response.$errors[
												index
											].ITEM_DESCRIPTION[0].$message.replace(
												'Value',
												'Description'
											)
										}}
									</small>
								</div>
							</template>
						</Column>
						<Column header="Amount" style="width: 20%">
							<template #body="{ data, index }">
								<InputNumber
									v-model="data.AMOUNT"
									:min="0"
									mode="decimal"
									locale="en-US"
									:minFractionDigits="2"
									:maxFractionDigits="2"
									:class="{
										'p-invalid':
											v$.dataLine.$error &&
											v$.dataLine.$errors[0].$response
												.$data[index].AMOUNT.$error,
									}"
								/>
								<div
									v-if="
										v$.dataLine.$error &&
										v$.dataLine.$errors[0].$response.$data[
											index
										].AMOUNT.$error
									"
									class="mt-1"
								>
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{
											v$.dataLine.$error &&
											v$.dataLine.$errors[0].$response.$errors[
												index
											].AMOUNT[0].$message.replace(
												'Value',
												'Amount'
											)
										}}
									</small>
								</div>
							</template>
						</Column>
						<Column header="VAT" style="width: 15%">
							<template #body="{ data }">
								<Dropdown
									:options="vatRates"
									optionLabel="VAT_CODE"
									v-model="data.vat"
									placeholder="Select VAT"
								/>
							</template>
						</Column>
						<Column header="WHT" style="width: 15%">
							<template #body="{ data }">
								<Dropdown
									:options="whtRates"
									optionLabel="WHT_CODE"
									v-model="data.wht"
									placeholder="Select WHT"
								/>
							</template>
						</Column>
						<Column header="Account" style="width: 20%">
							<template #body="{ data, index }">
								<AutoComplete
									v-model="data.acc"
									:class="{
										'p-invalid':
											v$.dataLine.$error &&
											v$.dataLine.$errors[0].$response
												.$data[index].acc.$error,
									}"
									type="text"
									class="p-column-filter"
									placeholder="Account"
									field="account"
									:dropdown="true"
									:suggestions="filteredAccount"
									@complete="searchAccount($event)"
									forceSelection
								/>
								<div
									v-if="
										v$.dataLine.$error &&
										v$.dataLine.$errors[0].$response.$data[
											index
										].acc.$error
									"
									class="mt-1"
								>
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{
											v$.dataLine.$error &&
											v$.dataLine.$errors[0].$response.$errors[
												index
											].acc[0].$message.replace(
												'Value',
												'Account'
											)
										}}
									</small>
								</div>
							</template>
						</Column>
						<Column header="Ref Number" style="width: 20%">
							<template #body="{ data, index }">
								<div class="p-inputgroup">
									<InputText
										id="REMARKS"
										v-if="data.travelOrder"
										placeholder="Ref Number"
										disabled
										v-model="data.travelOrder.travelOrderNo"
									/>
									<InputText
										id="REMARKS"
										v-else
										placeholder="Ref Number"
										disabled
										v-model="data.REMARKS"
									/>
									<Button
										icon="pi pi-search"
										class="p-button-secondary"
										@click="openModal(index)"
									/>
								</div>
							</template>
						</Column>
						<Column style="width: 5%" alignFrozen="right" frozen>
							<template #body="{ index }">
								<Button
									icon="pi pi-trash"
									class="p-button-rounded p-button-text"
									@click="handleDelete(index)"
									
								/>
							</template>
						</Column>

						<!-- Empty state -->
						<template #empty>
							<div>No item.</div>
						</template>
					</DataTable>
				</div>
				<div v-if="showReimburse">
					<div class="content-header">Reimburse</div>
					<div class="content-body">
						<div
							style="
								display: flex;
								justify-content: flex-end;
								margin-bottom: 40px;
							"
						>
							<Button
								icon="pi pi-plus"
								label="add line"
								class="uppercase p-button-success"
								@click="addRowReimburse()"
								v-if="status === 'OPEN'"
							/>
						</div>
						<DataTable
							:value="reimburse"
							responsiveLayout="scroll"
							:scrollable="true"
							scrollHeight="600px"
							:loading="loadingDataTable"
							scrollDirection="both"
						>
							<template #empty>
								<div>Data Not Found</div>
							</template>
							<Column header="Vendor" style="width: 20%">
								<template #body="{ data }">
									<AutoComplete
										v-model="data.dataVendor"
										type="text"
										class="p-column-filter"
										placeholder="Vendor"
										field="vendor_name"
										:suggestions="filteredVendors"
										@complete="searchVendors($event)"
										forceSelection
									/>
								</template>
							</Column>
							<Column header="No Faktur Pajak" style="width: 20%">
								<template #body="{ data, index }">
									<InputText
										id="inputtext"
										v-model="data.TAX_NO"
										type="text"
										class="p-column-filter"
										placeholder="No Faktur Pajak"
										:class="{
											'p-invalid':
												v$.reimburse.$error &&
												v$.reimburse.$errors[0]
													.$response.$data[index]
													.TAX_NO.$error,
										}"
									/>
									<div
										v-if="
											v$.reimburse.$error &&
											v$.reimburse.$errors[0].$response
												.$data[index].TAX_NO.$error
										"
										class="mt-1"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.reimburse.$error &&
												v$.reimburse.$errors[0].$response.$errors[
													index
												].TAX_NO[0].$message.replace(
													'Value',
													'Tax No'
												)
											}}
										</small>
									</div>
								</template>
							</Column>
							<Column header="Tanggal Faktur Pajak" style="width: 15%">
								<template #body="{ data, index }">
									<Calendar
										v-model="data.TAX_DATE"
										dateFormat="d-M-yy"
										:showIcon="true"
										class="w-full mb-3 lg:mb-0"
										placeholder="Tanggal Faktur Pajak"
										:class="{
											'p-invalid':
												v$.reimburse.$error &&
												v$.reimburse.$errors[0]
													.$response.$data[index]
													.TAX_DATE.$error,
										}"
									/>
									<div
										v-if="
											v$.reimburse.$error &&
											v$.reimburse.$errors[0].$response
												.$data[index].TAX_DATE.$error
										"
										class="mt-1"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.reimburse.$error &&
												v$.reimburse.$errors[0].$response.$errors[
													index
												].TAX_DATE[0].$message.replace(
													'Value',
													'Date'
												)
											}}
										</small>
									</div>
								</template>
							</Column>
							<Column header="Nilai Pajak" style="width: 20%">
								<template #body="{ data, index }">
									<InputNumber
										v-model="data.AMOUNT"
										:min="0"
										mode="decimal"
										locale="en-US"
										:minFractionDigits="2"
										:maxFractionDigits="2"
										:class="{
											'p-invalid':
												v$.reimburse.$error &&
												v$.reimburse.$errors[0]
													.$response.$data[index]
													.AMOUNT.$error,
										}"
									/>
									<div
										v-if="
											v$.reimburse.$error &&
											v$.reimburse.$errors[0].$response
												.$data[index].AMOUNT.$error
										"
										class="mt-1"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.reimburse.$error &&
												v$.reimburse.$errors[0].$response.$errors[
													index
												].AMOUNT[0].$message.replace(
													'Value',
													'Amount'
												)
											}}
										</small>
									</div>
								</template>
							</Column>
							<Column header="Account" style="width: 20%">
								<template #body="{ data, index }">
									<AutoComplete
										v-model="data.accReimburse"
										type="text"
										class="p-column-filter"
										placeholder="Account"
										field="account"
										:suggestions="filteredAccount"
										:dropdown="true"
										@complete="searchAccount($event)"
										forceSelection
										:class="{
											'p-invalid':
												v$.reimburse.$error &&
												v$.reimburse.$errors[0]
													.$response.$data[index]
													.accReimburse.$error,
										}"
									/>
									<div
										v-if="
											v$.reimburse.$error &&
											v$.reimburse.$errors[0].$response
												.$data[index].accReimburse
												.$error
										"
										class="mt-1"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.reimburse.$error &&
												v$.reimburse.$errors[0].$response.$errors[
													index
												].accReimburse[0].$message.replace(
													'Value',
													'Account'
												)
											}}
										</small>
									</div>
								</template>
							</Column>
							<Column header="Group" style="width: 20%">
								<template #body="{ data, index }">
									<Dropdown
										:options="reimburstGroups"
										optionLabel="name"
										placeholder="Select Group"
										v-model="data.group"
										:class="{
											'p-invalid':
												v$.reimburse.$error &&
												v$.reimburse.$errors[0]
													.$response.$data[index]
													.group.$error,
										}"
									/>
									<div
										v-if="
											v$.reimburse.$error &&
											v$.reimburse.$errors[0].$response
												.$data[index].group.$error
										"
										class="mt-1"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.reimburse.$error &&
												v$.reimburse.$errors[0].$response.$errors[
													index
												].group[0].$message.replace(
													'Value',
													'group'
												)
											}}
										</small>
									</div>
								</template>
							</Column>
							<Column style="width: 5%" alignFrozen="right" frozen>
								<template #body="{ index }">
									<Button
										icon="pi pi-trash"
										class="p-button-rounded p-button-text"
										@click="handleDeleteReimburse(index)"
									/>
								</template>
							</Column>
						</DataTable>
					</div>
				</div>
				<div class="content-body">
					<div class="grid">
						<div class="p-fluid col-12 md:col-12 lg:col-2">
							<Button
								label="Save"
								class="mr-5 uppercase p-button-success"
								@click="save()"
								v-if="status === 'OPEN'"
								:disabled="loadingButton === true"
							/>
						</div>
						<div class="p-fluid col-12 lg:col-3">
							<Button
								label="submit to rekomendator"
								class="mr-5 uppercase p-button-info"
								@click="handleSubmit()"
								v-if="status === 'OPEN'"
								:disabled="loadingButton === true"
							/>
						</div>
						<div class="p-fluid col-12 md:col-12 lg:col-2">
							<Button
								label="reject"
								class="mr-5 uppercase p-button-danger"
								@click="handleReject()"
								v-if="status === 'OPEN'"
								:disabled="loadingButton === true"
							/>
						</div>
						<div class="p-fluid col-12 md:col-12 lg:col-2">
							<Button
								label="cancel"
								class="mr-5 uppercase p-button-secondary"
								@click="cancel()"
								:disabled="loadingButton === true"
							/>
						</div>
						<div class="p-fluid col-12 md:col-12 lg:col-3">
							<Button
								label="download"
								class="mr-5 uppercase p-button-warning"
								@click="exportExcell()"
								v-if="status === 'OPEN' || 'REVIEW'"
								:disabled="loadingButton === true"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Dialog
			header="Ref Number"
			v-model:visible="dialog"
			:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
			:style="{ width: '16vw' }"
			:modal="true"
		>
			<div
				class="col-12 mb-12 md:col-12 lg:col-12 lg:mb-12"
				style="margin-top: 20px"
			>
				<AutoComplete
					:suggestions="filteredRefNumber"
					@complete="searchRefNumber($event)"
					:dropdown="true"
					v-model="refNumber"
					field="sk_number"
					placeholder="Choose SK Number"
					:disabled="!status.toLowerCase().includes('draft')"
					forceSelection
				>
					<template #item="slotProp">
						{{ slotProp.item.sk_number }}
					</template>
				</AutoComplete>
			</div>
			<template #footer>
				<Button
					label="Yes"
					:disabled="!status.toLowerCase().includes('draft')"
					icon="pi pi-check"
					@click="confirm"
					autofocus
				/>
				<Button
					label="No"
					icon="pi pi-times"
					@click="closeModal"
					class="p-button-text"
				/>
			</template>
		</Dialog>
		<Dialog
			header="Ref Number"
			v-model:visible="displayModal"
			:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
			:style="{ width: '50vw' }"
			:modal="true"
			:dismissableMask="true"
			:draggable="false"
		>
			<div class="pt-3">
				<travelOrder
					:supplierName="supplierInInvoiceHeader"
					@on-close="closeTOModal"
					@on-add="handleAddTO"
					@res="response"
				/>
			</div>
		</Dialog>
	</div>
</template>

<script setup>
import { ref, watch, computed, onUnmounted, onMounted } from 'vue';
import AuthService from '@/service/AuthService';
import {
	useRoute,
	useRouter,
	// useRouter
} from 'vue-router';
import SimpleCrypto from 'simple-crypto-js';
import ProgressModal from '../../components/ProgressModal.vue';
import invoiceNonPOservice from '@/service/InvoiceNonPOservice';
import LovService from '@/service/LovService';
import InvoiceNonPOservice from '../../../service/InvoiceNonPOservice';
import { useToast } from 'primevue/usetoast';
import travelOrder from '../components/TravelOrder.vue';
import { required, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import XLSX from 'xlsx';
import ListInvoiceNonPo from '../../../service/InvoiceNonPOservice';

const breadcrumbHome = { icon: 'pi pi-home', to: '/dashboard' };
const breadcrumbItems = ref([]);
const userInfo = ref();
const company = ref();
const dbInstance = ref();
const loadingButton = ref(false);
// const supplier = ref();
const supplierReload = ref(0);
const disableButton = ref(true);
const route = useRoute();
const router = useRouter();
// const router = useRouter();
const id = ref(route.params.id);
const status = ref();
const supplierSites = ref();
const invoiceAmount = ref();
const taxAmount = ref();
const totalAmount = ref();
const currency = ref();
const invoiceNumber = ref();
const invoiceDate = ref();
const glDate = ref();
const descriptionHeader = ref();
const taxInvoiceNo = ref();
const taxInvoiceDate = ref();
const remitToBankAccount = ref();
const paymentMethod = ref();
const terms = ref();
const referenceNumber = ref();
const costCenter = ref();
const rejectReason = ref();
const companyInInvoiceHeader = ref();
const supplierInInvoiceHeader = ref();
// const descriptionLine = ref();
// const amountLine = ref();
// const vatLine = ref();
// const accountLine = ref();
// const whtLine = ref();
const dataLine = ref([]);
const vendorId = ref();
const idVendorSite = ref();
const categoryId = ref();
const reimburse = ref([]);
const categoryDetail = ref();
const toast = useToast();
const refNumber = ref();
const dialog = ref(false);
const displayModal = ref(false);
const travelOrderIndex = ref(null);
const mapingId = ref();
const termsId = ref();
const dataHead = ref();
const bankId = ref();
const savedInvoiceDetails = ref();
const savedReimburseDetails = ref();
const file = ref();
const fileUpload = ref(null);
const orgId = ref();
const progress = ref(false);
const progressReject = ref(false);
const saved = ref(null);
// const reloadTable = ref(0);
const backUpDesc = ref();
const userBy = ref();
const supplierSiteReload = ref(0);
const dataLineReload = ref(0);
const dbInstances = ref();
const loadingData = ref(true);
const loadingDataTable = ref(false);
const rejectReasonRecommendator = ref();
const isSave = ref(true);
const fileBuffer = ref();
const loadingDownladFile = ref(false);

watch(
	() => [company.value, vendorId.value, dataLine.value, reimburse.value],
	() => {
		dataLineReload.value++;
	}
);

onMounted(() => {
	AuthService.whoAmi().then(async (response) => {
		userBy.value = response.data.data;
		const map = new Map();
		map.set('label', 'Detail Invoice Non PO');
		map.set(
			'to',
			'/mgm/monitor/searchinvoicenonpo/detailnonpo/' + id.value
		);
		const breadcrumbItem = Object.fromEntries(map);
		breadcrumbItems.value[0] = breadcrumbItem;
		userInfo.value = response.data.data;
		const respCbN = await LovService.getCompanyByName(userCompany());
		company.value = respCbN.data.data[0];
		dbInstance.value = company.value.db_instance;
		supplierReload.value++;

		await Promise.all([getData()]);

		disableButton.value = false;
		loadingData.value = false;
	});
});

const userCompany = () => {
	const secretKey = 'some-unique-key';
	const simpleCrypto = new SimpleCrypto(secretKey);
	return simpleCrypto.decrypt(sessionStorage.getItem('dropdownCompany'));
};

const getData = async () => {
	//
	try {
		loadingDataTable.value = true;
		loadingButton.value = true;
		const resultData = await invoiceNonPOservice.getInvoiceNonPOById(
			id.value
		);

		if (resultData.status === 200) {
			

			const result = resultData.data;

			// invoiceData.value = result[0];
			const type = typeof result;
			if (type === 'object') {
				// let option = {
				//   day: "numeric",
				//   month: "short",
				//   year: "numeric",
				// };
				invoiceAmount.value = result.dataHeader[0][0].INVOICE_AMOUNT;
				taxAmount.value = result.dataHeader[0][0].TAX_AMOUNT;
				totalAmount.value = result.dataHeader[0][0].TOTAL_AMOUNT;
				currency.value = result.dataHeader[0][0].CURRENCY_CODE;
				invoiceNumber.value = result.dataHeader[0][0].INVOICE_NO;
				invoiceDate.value = new Date(
					result.dataHeader[0][0].INVOICE_DATE
				);
				glDate.value = result.dataHeader[0][0].GL_DATE;
				if (glDate.value !== null) {
					glDate.value = new Date(result.dataHeader[0][0].GL_DATE);
				} else {
					glDate.value = new Date();
				}
				descriptionHeader.value = result.dataHeader[0][0].DESCRIPTION;
				backUpDesc.value = result.dataHeader[0][0].DESCRIPTION;
				taxInvoiceNo.value = result.dataHeader[0][0].TAX_NO;
				taxInvoiceDate.value = new Date(
					result.dataHeader[0][0].TAX_DATE
				);
				paymentMethod.value =
					result.dataHeader[0][0].PAYMENT_METHOD_LOOKUP_CODE;
				referenceNumber.value =
					result.dataHeader[0][0].REFERENCE_NUMBER;
				costCenter.value = result.dataHeader[0][0].COST_CENTER;
				rejectReason.value = result.dataHeader[0][0].REASON;
				status.value = result.dataHeader[0][0].STATUS;
				companyInInvoiceHeader.value =
					result.dataHeader[0][0].COMPANY_NAME;
				supplierInInvoiceHeader.value =
					result.dataHeader[0][0].VENDOR_NAME;
				vendorId.value = result.dataHeader[0][0].VENDOR_ID;
				idVendorSite.value = result.dataHeader[0][0].VENDOR_SITE_ID;
				categoryId.value = result.dataHeader[0][0].CATEGORY_ID;
				mapingId.value = result.dataHeader[0][0].MAPING_ID;
				termsId.value = result.dataHeader[0][0].TERMS_ID;
				refNumber.value = result.dataHeader[0][0].REFERENCE_NUMBER;
				bankId.value = result.dataHeader[0][0].EXTERNAL_BANK_ACCOUNT_ID;
				orgId.value = result.dataHeader[0][0].ORG_ID;
				dbInstances.value = result.dataHeader[0][0].DB_INSTANCE;
				rejectReasonRecommendator.value =
					result.dataHeader[0][0].RECOMMENDATOR_REASON;

				dataLine.value = result.dataDetail;
				dataHead.value = result.dataHeader[0];

				//

				const dataFile = result.dataDoc;

				saved.value = dataFile;

				//assign invoice detail
				const invoiceDetails = dataLine.value;
				dataLine.value = invoiceDetails.map((item) => {
					return {
						...item,
						id: item.EINVOICE_LINE_ID,
					};
				});

				savedInvoiceDetails.value = invoiceDetails;

				reimburse.value = result.dataReimbursement;

				for (const c of reimburse.value) {
					c.TAX_DATE = new Date(c.TAX_DATE);
				}

				//assign reimburse
				const detailReimburse = reimburse.value;

				reimburse.value = detailReimburse.map((item) => {
					return {
						...item,
						id: item.EINVOICE_REIMBURSMENT_ID,
					};
				});
				savedReimburseDetails.value = detailReimburse;

				await Promise.all([
					getAccountExist(),
					getAccount(),
					vatDropdown(),
					whtDropdown(),
					vendorList(),
					getReimburstGroups(),
					getListSupplierSite(),
					getListBank(),
					getPaymentMethod(),
					getSkNumber(),
					getCostCenter(),
					getCategoryVendor(),
					getTerm(),
					getFileAttachment(),
					// getFileBase64(id.value),
				]);

				//assign vat code
				if (vatRates.value) {
					dataLine.value.map((item) => {
						item.vat = vatRates.value.find(
							(element) => element.VAT_CODE == item.VAT_CODE
						);
					});
				}

				//assign wht code
				if (whtRates.value) {
					dataLine.value.map((id) => {
						id.wht = whtRates.value.find(
							(element) => element.WHT_CODE === id.WHT_CODE
						);
					});
				}

				//assign account
				if (listAccount.value) {
					dataLine.value.map((id) => {
						id.acc = listAccount.value.find(
							(element) =>
								element.code_combination_id === id.COA_ID
						);
					});
				}

				//assign vendor
				if (vendors.value) {
					reimburse.value.map((val) => {
						val.dataVendor = vendors.value.find(
							(element) => element.vendor_id === val.VENDOR_ID
						);
					});
				}

				//assign group
				if (reimburstGroups.value) {
					reimburse.value.map((val) => {
						val.group = reimburstGroups.value.find(
							(el) => el.name === val.GROUP_NAME
						);
					});
				}

				//assign account reimburse
				if (listAccount.value) {
					reimburse.value.map((val) => {
						val.accReimburse = listAccount.value.find(
							(element) =>
								element.code_combination_id === val.COA_ID
						);
					});
				}

				//  //assign TO
				//  if(responseTO.value){
				//    dataLine.value.map((id) => {
				//     id.travelOrder = responseTO.value.find(
				//       (element) => element.travel_order_no === id.REMARKS
				//     );
				//   });
				//  }

				//

				//
				loadingButton.value = false
				loadingDataTable.value = false;
			} else {
				toast.add({
					severity: 'error',
					summary: 'Error',
					detail: `${result}`,
					life: 3000,
				});
			}
		}
		loadingButton.value = false
		loadingDataTable.value = false;
	} catch (error) {
		loadingButton.value = false
		loadingDataTable.value = false;
		throw new Error(error);
	}
};

//validation
const validationRules = computed(() => {
	const rules = {
		supplierSites: { required },
		invoiceNumber: { required },
		remitToBankAccount: { required },
		paymentMethod: { required },
		terms: { required },
		descriptionHeader: { required },
		taxInvoiceNo: { required },
		costCenter: { required },
		taxInvoiceDate: { required },
		glDate: { required },
		invoiceDate: { required },
		dataLine: {
			$each: helpers.forEach({
				AMOUNT: { required },
				ITEM_DESCRIPTION: { required },
				acc: { required },
			}),
		},
		reimburse: {
			$each: helpers.forEach({
				TAX_DATE: { required },
				AMOUNT: { required },
				TAX_NO: { required },
				accReimburse: { required },
				group: { required },
			}),
		},
	};
	return rules;
});

const v$ = useVuelidate(validationRules, {
	supplierSites,
	invoiceNumber,
	remitToBankAccount,
	paymentMethod,
	terms,
	descriptionHeader,
	taxInvoiceNo,
	costCenter,
	taxInvoiceDate,
	glDate,
	invoiceDate,
	dataLine,
	reimburse,
});

// Summary invoice
const summary = computed(() => {
	const invoiceAmmount =
		(dataLine.value.length &&
			dataLine.value.reduce((accumulator, obj) => {
				return accumulator + obj.AMOUNT;
			}, 0)) ||
		0;

	const taxAmmount =
		(dataLine.value.length &&
			dataLine.value
				.map((el) => {
					return el.vat && el.vat.value
						? el.vat.value * el.AMOUNT
						: 0;
				})
				.reduce((prevValue, currValue) => prevValue + currValue, 0)) ||
		0;
	const totalInvoice = invoiceAmmount + taxAmmount;
	return {
		invoiceAmmount,
		taxAmmount,
		totalInvoice,
	};
});

//list account
const listAccount = ref();
const accountData = ref();

const getAccount = async () => {
	const payload = {
		orgId: orgId.value,
		dbInstance: dbInstances.value,
		cost_center: costCenter.value.SEGMENT4
			? costCenter.value.SEGMENT4
			: costCenter.value,
	};

	const res = await InvoiceNonPOservice.accountPic(payload);
	//
	if (res.status === 200) {
		accountData.value = res.data.data;
	}
};

const getAccountExist = async () => {
	const payload = {
		orgId: orgId.value,
		dbInstance: dbInstances.value,
	};

	const res = await InvoiceNonPOservice.accountexistPic(payload);
	//
	if (res.status === 200) {
		listAccount.value = res.data.data;
	}
};

//watch if action
onUnmounted(() => {
	unwatchAccount();
});

const unwatchAccount = watch(costCenter, async (newValue) => {
	if (newValue && newValue.SEGMENT4) {
		await getAccount();
	}
});

const filteredAccount = ref([]);
const searchAccount = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredAccount.value = [...accountData.value];
		} else {
			filteredAccount.value = accountData.value.filter((val) => {
				if (val.account === null || val.account === undefined) {
					return val;
				}
				return val.account
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
		}
	}, 250);
};

//get term id
const assignTerm = ref();
const getTermId = async () => {
	const payload = {
		vendor_id: vendorId.value,
	};
	const res = await InvoiceNonPOservice.getTermId(payload);
	try {
		if (res.status === 200) {
			assignTerm.value = res.data.data[0].terms_id;
		}
	} catch (error) {
		console.log(error);
	}
};

const handleModal = () => {
	dialog.value = true;
};

const closeModal = () => {
	dialog.value = false;
};

const confirm = () => {
	dialog.value = false;
	//
	// referenceNumber.value = refNumber.value.sk_number;
	if (
		refNumber.value !== null &&
		catHeader.value.CATEGORY_NAME.toLowerCase().includes('forwarder')
	) {
		if (descriptionHeader.value === null) {
			descriptionHeader.value = '';
			descriptionHeader.value = refNumber.value.po_number;
			referenceNumber.value = refNumber.value.sk_number;
		} else {
			descriptionHeader.value = backUpDesc.value;
			descriptionHeader.value = descriptionHeader.value.concat(
				', ',
				refNumber.value.po_number
			);
			referenceNumber.value = refNumber.value.sk_number;
		}
	} else {
		if (descriptionHeader.value === null) {
			descriptionHeader.value = '';
			referenceNumber.value = refNumber.value.sk_number;
		} else {
			descriptionHeader.value = backUpDesc.value;
			referenceNumber.value = refNumber.value.sk_number;
		}
	}
};
const openModal = (index) => {
	displayModal.value = true;
	travelOrderIndex.value = index;
};

const closeTOModal = () => {
	displayModal.value = false;
	travelOrderIndex.value = null;
};

const responseTO = ref();
const response = (value) => {
	responseTO.value = value;
};

// Handle add TO
const handleAddTO = (selectedTO) => {
	dataLine.value = dataLine.value.map((category, index) => {
		if (index === travelOrderIndex.value) {
			category.travelOrder = {
				travelOrderNo: selectedTO.travel_order_no,
				guestName: selectedTO.guest_name,
				startDate: selectedTO.start_date,
				endDate: selectedTO.end_date,
			};
		}

		return category;
	});

	travelOrderIndex.value = null;
	displayModal.value = false;
};

// Get reimburst groups options
const reimburstGroups = ref();
const getReimburstGroups = async () => {
	const payload = {
		dbInstance: dbInstances.value,
		type: 'DXG_REIMBURST_GROUP',
	};
	try {
		const res = await InvoiceNonPOservice.getReimburstGroups(payload);
		if (res.status === 200) {
			reimburstGroups.value = res.data.data;
		}
	} catch (err) {
		console.log(err);
	}
};

const reffNumber = ref();
const filteredRefNumber = ref([]);

//list find sk
const getSkNumber = async () => {
	AuthService.whoAmi().then(async (response) => {
		if (response.status === 200) {
			if (dbInstances.value !== null) {
				const payload = { db_instance: dbInstances.value };
				//
				const data = await InvoiceNonPOservice.skNumberPIC(payload);
				//
				reffNumber.value = data.data.data;
			}
		}
	});
};
const searchRefNumber = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredRefNumber.value = [...reffNumber.value];
    } else {
      filteredRefNumber.value = reffNumber.value.filter((comp) => {
        return comp.sk_number
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};

const filteredVendors = ref([]);
const vendors = ref();
// Search vendors on autocomplete
const vendorList = async () => {
	const res = await InvoiceNonPOservice.getListVendor({
		dbInstance: dbInstances.value,
	});
	if (res.status === 200) {
		vendors.value = res.data.data;
	}
};

const searchVendors = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredVendors.value = [...vendors.value];
		} else {
			filteredVendors.value = vendors.value.filter((vendor) => {
				return (
					vendor.vendor_name &&
					vendor.vendor_name
						.toLowerCase()
						.match(event.query.toLowerCase())
				);
			});
		}
	}, 250);
};

//list bank
const bankList = ref();

const getListBank = async () => {
	const payload = {
		dbInstance: dbInstances.value,
		orgId: orgId.value,
		vendorId: vendorId.value,
	};
	const res = await InvoiceNonPOservice.getListBankPIC(payload);
	// (res.data.data);
	if (res.status === 200) {
		bankList.value = res.data.data.map((item) => {
			return {
				id: item.ext_bank_account_id,
				name: item.transfer_to,
			};
		});
	}
};

const filteredBanks = ref([]);

const searchBank = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredBanks.value = [...bankList.value];
    } else {
      filteredBanks.value = bankList.value.filter((comp) => {
        return comp.name.toLowerCase().match(event.query.toLowerCase());
      });
    }
  }, 250);
};

//first load page bank list
watch(bankList, (newBankList) => {
	remitToBankAccount.value = newBankList.find(
		(item) => item.id === bankId.value
	);
});

//list supplier site
const supplierSiteList = ref();

const getListSupplierSite = async () => {
	const payload = {
		org_id: orgId.value,
		vendor_id: vendorId.value,
		db_instance: dbInstances.value,
	};
	const res = await InvoiceNonPOservice.getSupplierSitePIC(payload);
	//
	if (res.status === 200) {
		supplierSiteList.value = res.data.data.map((item) => {
			return {
				id: item.vendor_site_id,
				suppliersite: item.vendor_site_code,
			};
		});
	}
};

const filteredSuppSite = ref([]);

const searchSuppSite = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredSuppSite.value = [...supplierSiteList.value];
    } else {
      filteredSuppSite.value = supplierSiteList.value.filter((comp) => {
        return comp.suppliersite
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};

//first load page supplier site list
watch(supplierSiteList, (newSiteSUpp) => {
	supplierSites.value = newSiteSUpp.find(
		(item) => item.id === idVendorSite.value
	);
});

//payment method
const paymentList = ref();

const getPaymentMethod = async () => {
	const payload = {
		db_instance: dbInstances.value,
		vendor_id: vendorId.value,
	};
	const res = await InvoiceNonPOservice.paymentMethodPIC(payload);
	//
	if (res.status === 200) {
		//
		paymentList.value = res.data.data;
		if (paymentList.value) {
			paymentMethod.value = res.data.data[0].PAYMENT_METHOD;
		}
	}
};

const filteredPayment = ref([]);

const searchPayment = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredPayment.value = [...paymentList.value];
    } else {
      filteredPayment.value = paymentList.value.filter((comp) => {
        paymentList.value.filter((item) => {
          if (
            item.PAYMENT_METHOD === null ||
            item.PAYMENT_METHOD === undefined
          ) {
            return item;
          }
          return comp.PAYMENT_METHOD.toLowerCase().match(
            event.query.toLowerCase()
          );
        });
      });
    }
  }, 250);
};

//list wht
const whtRates = ref();

const whtDropdown = async () => {
	const payload = {
		db_instance: dbInstances.value,
		org_id: orgId.value,
	};
	const res = await InvoiceNonPOservice.whtPIC(payload);
	//
	if (res.status === 200) {
		whtRates.value = res.data.data;

		whtRates.value.push({
			WHT_RATE: null,
			WHT_CODE_ID: null,
			WHT_CODE: '- No Select -',
		});
	}
};

//list vat
const vatRates = ref();

const vatDropdown = async () => {
	const payload = {
		db_instance: dbInstances.value,
		invoice_date: invoiceDate.value,
	};
	const res = await InvoiceNonPOservice.vatPIC(payload);

	if (res.status === 200) {
		vatRates.value = res.data.data.map((item) => ({
			...item,
			value: Math.round((item.PERCENTAGE_RATE / 100) * 1000) / 1000,
		}));
		vatRates.value.push({
			value: null,
			TAX_RATE_ID: null,
			VAT_CODE: '- No Select -',
		});
		//
	}
};

onUnmounted(() => {
	unwatchInvoiceDate();
});

// Watch invoice date to get total PPN
const unwatchInvoiceDate = watch(invoiceDate, async () => {
	vatRates.value = null;
	dataLine.value = dataLine.value.map((item) => ({
		...item,
		vat: null,
	}));
	await vatDropdown();
});

//list cost center
const listCostCenter = ref();

const getCostCenter = async () => {
	try {
		const result = await invoiceNonPOservice.getListCostCenter(
			orgId.value,
			dbInstances.value
		);

		if (result.status === 200) {
			listCostCenter.value = result.data.data;
		}
	} catch (error) {
		console.log(error);
	}
};

const filteredCC = ref([]);

const searchCostCenter = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredCC.value = [...listCostCenter.value];
    } else {
      filteredCC.value = listCostCenter.value.filter((comp) => {
        if (comp.SEGMENT4 === null || comp.SEGMENT4 === undefined) {
          return comp;
        }
        return comp.SEGMENT4.toLowerCase().match(
          event.query.toLowerCase()
        );
      });
    }
  }, 250);
};

//list term
const listTerm = ref();

const getTerm = async () => {
	const payload = {
		db_instance: dbInstances.value,
		vendor_id: vendorId.value,
	};
	const res = await InvoiceNonPOservice.getTermPIC(payload);
	//
	if (res.status === 200) {
		//
		listTerm.value = res.data.data;
	}
};

const filteredTerm = ref([]);

const searchTerm = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredTerm.value = [...listTerm.value];
		} else {
			filteredTerm.value = listTerm.value.filter((val) => {
				if (val.TERM_NAME === null || val.TERM_NAME === undefined) {
					return val;
				}
				return val.TERM_NAME.toLowerCase().match(
					event.query.toLowerCase()
				);
			});
		}
	}, 250);
};

//first load page term list
watch(listTerm, async (newTerm) => {
	await getTermId();
	if (assignTerm.value) {
		terms.value = newTerm.find((item) => item.TERM_ID === assignTerm.value);
	} else {
		terms.value = newTerm.find((item) => item.TERM_ID === termsId.value);
	}
});

const showReimburse = computed(() =>
	dataLine.value.find((el) =>
		el.ITEM_DESCRIPTION.toLowerCase().includes('reimburstment')
	)
);

const catHeader = ref();
const catDetail = ref();
const categories = ref();

//detail category
const getCategoryVendor = async () => {
	let idInt = parseInt(categoryId.value);
	// let idMaping = parseInt(mapingId.value);
	const payload = {
		org_id: orgId.value,
		db_instance: dbInstances.value,
		vendor_id: vendorId.value,
		idCateg: idInt,
	};
	const res = await InvoiceNonPOservice.lineCategoryPIC(payload);
	//
	if (res.status === 200) {
		catHeader.value = res.data.data.header;
		if (
			catHeader.value &&
			catHeader.value.CATEGORY_NAME.toLowerCase().includes('forwarder')
		) {
			const payloadSK = {
				categoryId: idInt,
				dbInstance: dbInstances.value,
				orgId: orgId.value,
				skNumber: referenceNumber.value ? referenceNumber.value : null,
			};
			const res = await ListInvoiceNonPo.getDetailCatSK(payloadSK);
			categories.value = res.data.data.data;
		} else {
			const payload = {
				org_id: orgId.value,
				db_instance: dbInstances.value,
				vendor_id: vendorId.value,
				idCateg: idInt,
			};
			const res = await InvoiceNonPOservice.lineCategoryPIC(payload);

			catDetail.value = res.data.data.details;
		}
	}
};

const filteredCategory = ref([]);
const searchCategory = (event) => {
  setTimeout(() => {
    if (categories.value) {
      if (!event.query.trim().length) {
        filteredCategory.value = [...categories.value];
      } else {
        filteredCategory.value = categories.value.filter((comp) => {
          if (
            comp.category_detail === null ||
            comp.category_detail === undefined
          ) {
            return comp;
          }
          return comp.category_detail
            .toLowerCase()
            .match(event.query.toLowerCase());
        });
      }
    } else {
      if (!event.query.trim().length) {
        filteredCategory.value = [...catDetail.value];
      } else {
        filteredCategory.value = catDetail.value.filter((comp) => {
          if (
            comp.category_detail === null ||
            comp.category_detail === undefined
          ) {
            return comp;
          }
          return comp.category_detail
            .toLowerCase()
            .match(event.query.toLowerCase());
        });
      }
    }
  }, 250);
};

const isForwarder = computed(
	() =>
		catHeader.value &&
		catHeader.value.CATEGORY_NAME.toLowerCase().includes('forwarder')
);

const cancel = () => {
	router.back();
};

const handleDelete = (id) => {
	//
	dataLine.value = dataLine.value.filter((item, index) => index !== id);
	isSave.value = true;
};

const handleDeleteReimburse = (id) => {
	//
	reimburse.value = reimburse.value.filter((item, obj) => obj !== id);
	isSave.value = true;
};

//add line
const addRow = () => {
	isSave.value = false;
	if (!categoryDetail.value) {
		return;
	}

	let coa = listAccount.value.filter((val) =>
		savedInvoiceDetails.value.find(
			(item) => val.code_combination_id == item.COA_ID
		)
	);

	if (isForwarder.value) {
		const isAdded = dataLine.value.some(
			(cat) =>
				cat.CATEGORY_DETAIL_ID ===
				categoryDetail.value.category_detail_id
		);
		if (isAdded) {
			categoryDetail.value = null;
			return;
		}
		dataLine.value.push({
			...categoryDetail.value,
			CATEGORY_DETAIL_ID: categoryDetail.value.category_detail_id,
			ITEM_DESCRIPTION: categoryDetail.value.category_detail,
			AMOUNT: 0,
			vat: null,
			wht: null,
			ACCOUNT: coa ? coa[0] : false,
			acc: coa ? coa[0] : null,
			travelOrder: {
				travelOrderNo: null,
				guestName: null,
				startDate: null,
				endDate: null,
			},
		});
	} else {
		dataLine.value.push({
			...categoryDetail.value,
			CATEGORY_DETAIL_ID: categoryDetail.value.CATEGORY_DETAIL_ID,
			ITEM_DESCRIPTION: categoryDetail.value.category_detail,
			AMOUNT: 0,
			vat: null,
			wht: null,
			ACCOUNT: coa ? coa[0] : false,
			acc: coa ? coa[0] : null,
			travelOrder: {
				travelOrderNo: null,
				guestName: null,
				startDate: null,
				endDate: null,
			},
		});
	}

	categoryDetail.value = null;
};

const addRowReimburse = () => {
	isSave.value = false;
	let coa = listAccount.value.filter((val) =>
		savedReimburseDetails.value.find(
			(item) => val.code_combination_id == item.COA_ID
		)
	);

	reimburse.value.push({
		dataVendor: null,
		TAX_NO: null,
		TAX_DATE: null,
		AMOUNT: 0,
		ACCOUNT: coa ? coa[0] : false,
		accReimburse: coa ? coa[0] : null,
		group: null,
	});
};

//get file
const getFileAttachment = async () => {
	const res = await InvoiceNonPOservice.getAttachmentFilePIC(id.value);
	if (res.status === 200) {
		//
		saved.value = res.data.data;
	} else {
		toast.add({
			severity: 'error file',
			summary: 'Enable to Download File',
			life: 3000,
		});
	}
};

// const getFileBase64 = async (invoiceId) => {
//   try {
//     const leveltenant = sessionStorage.getItem("leveltenant");
//     const res = await InvoiceNonPOservice.getFileBase64(
//       invoiceId,
//       leveltenant,
//       vendorId.value
//     );
//

//     if (res.status === 200) {
//       fileBuffer.value = res.data.data;
//     }
//   } catch (err) {
//
//   }
// };

//delete file
const delFile = ref(null);

//onSelect file
const onSelectFile = (e) => {
	//
	file.value = e.files[0];
};

//on Remove FIle
const onRemoveFile = (e) => {
	if (!e.files.length) {
		file.value = null;
	}
};

const deleteFile = () => {
	delFile.value = saved.value;
	saved.value = null;
};

//format dete dexa
const formattingDate = (date, withTime) => {
	const theDate = new Date(date);
	const strDate =
		theDate.getFullYear() +
		'-' +
		(theDate.getMonth() + 1) +
		'-' +
		theDate.getDate();
	const strTime =
		theDate.getHours() +
		':' +
		theDate.getMinutes() +
		':' +
		theDate.getSeconds();
	if (withTime) {
		return `${strDate} ${strTime}`;
	} else {
		return `${strDate}`;
	}
};

const loading = ref(false);
const submitted = ref(false);

//eslint-disable-next-line no-dupe-keys
const save = async () => {
	loading.value = true;
	submitted.value = true;

	const isFormValid = await v$.value.$validate();

	if (!isFormValid) {
		toast.add({
			severity: 'error',
			summary: 'Please fill all required fields',
			life: 3000,
		});
		return;
	}

	if (!dataLine.value || dataLine.value.length === 0) {
		toast.add({
			severity: 'error',
			summary: 'Please add line',
			life: 3000,
		});
		return;
	}

	if (!file.value && delFile.value) {
		//
		toast.add({
			severity: 'error',
			summary: 'Please select file to upload',
			life: 3000,
		});
		return;
	}

	// if (reimburse.value.length === 0 && showReimburse.value) {
	//   //
	//   toast.add({
	//     severity: "error",
	//     summary: "Please add line Reimburse",
	//     life: 3000,
	//   });
	//   return;
	// }

	if (
		!supplierSites.value ||
		!invoiceNumber.value ||
		!remitToBankAccount.value ||
		!paymentMethod.value ||
		!terms.value ||
		!descriptionHeader.value ||
		!taxInvoiceNo.value ||
		!costCenter.value
	) {
		toast.add({
			severity: 'error',
			summary: 'Some fields are required',
			life: 3000,
		});
		return;
	}

	// Add file payload
	const formData = new FormData();
	formData.append('file', file.value);

	const payload = {
		header: {
			EINVOICE_ID: parseInt(id.value),
			INVOICE_NO: invoiceNumber.value,
			INVOICE_DATE: formattingDate(invoiceDate.value),
			REVISION_NUM: 0,
			TAX_NO: taxInvoiceNo.value,
			TAX_DATE: formattingDate(taxInvoiceDate.value),
			DESCRIPTION: descriptionHeader.value,
			CURRENCY_CODE: currency.value,
			VENDOR_ID: vendorId.value,
			INVOICE_AMOUNT: summary.value.invoiceAmmount,
			TAX_AMOUNT: summary.value.taxAmmount,
			TOTAL_AMOUNT: summary.value.totalInvoice,
			// SERVICE_AMOUNT: "",
			// WHT_CODE: "",
			// WHT_RATE: "",
			// ATTRIBUTE1: "",
			ORG_ID: parseInt(orgId.value),
			STATUS: status.value,
			CREATION_DATE: formattingDate(new Date(), true),
			CREATED_BY: userBy.value.id,
			LAST_UPDATE_DATE: formattingDate(new Date(), true),
			LAST_UPDATED_BY: userBy.value.id,
			PAYMENT_METHOD_LOOKUP_CODE: paymentMethod.value.PAYMENT_METHOD
				? paymentMethod.value.PAYMENT_METHOD
				: paymentMethod.value,
			GL_DATE: glDate.value ? formattingDate(glDate.value) : null,
			TERMS_ID: terms.value ? terms.value.TERM_ID : null,
			REASON: rejectReason.value ? rejectReason.value : null,
			ACCTS_CODE_COMBINATION_ID: catHeader.value
				? parseInt(catHeader.value.ACCOUNT_ID)
				: null,
			EXTERNAL_BANK_ACCOUNT_ID: remitToBankAccount.value
				? remitToBankAccount.value.id
				: null,
			INVOICE_TYPE: 'INVOICE NON PO',
			CATEGORY_ID: catHeader.value.CATEGORY_ID,
			MAPING_ID: catHeader.value.MAPING_ID,
			REFERENCE_NUMBER: refNumber.value
				? refNumber.value.sk_number
				: null,
			VENDOR_SITE_ID: supplierSites.value ? supplierSites.value.id : null,
			COST_CENTER: costCenter.value ? costCenter.value.SEGMENT4 : null,
			DB_INSTANCE: company.value.db_instance,
		},
		editDetails: [],
		newDetails: [],
		removedDetails: [],
		editReimburse: [],
		newReimburse: [],
		removedReimburse: [],
		removedalllength: [],
		countDetails: dataLine.value.length,
	};

	//update details
	payload.editDetails = dataLine.value
		.filter((obj) =>
			savedInvoiceDetails.value.some(
				(val) => val.EINVOICE_LINE_ID === obj.id
			)
		)
		.map((item, index) => {
			const dateOptions = {
				year: 'numeric',
				month: 'short',
				day: 'numeric',
			};

			return {
				EINVOICE_LINE_ID: item.EINVOICE_LINE_ID,
				EINVOICE_ID: parseInt(id.value),
				PO_HEADER_ID: item.PO_HEADER_ID,
				PO_LINE_ID: item.PO_LINE_ID,
				PO_NUMBER: item.PO_NUMBER,
				LINE_NUM: index + 1,
				ITEM_ID: item.ITEM_ID,
				ITEM_NUMBER: item.ITEM_NUMBER,
				ITEM_DESCRIPTION: item.ITEM_DESCRIPTION,
				UNIT_MEAS_LOOKUP_CODE: item.UNIT_MEAS_LOOKUP_CODE,
				UNIT_PRICE: item.UNIT_PRICE,
				QUANTITY: item.QUANTITY,
				AMOUNT: item.AMOUNT,
				ORGANIZATION_ID: parseInt(company.value.value),
				LINE_LOCATION_ID: item.LINE_LOCATION_ID,
				VAT_CODE: item.vat
					? item.vat.VAT_CODE.includes('%')
						? item.vat.VAT_CODE
						: null
					: null,
				RECEIPT_NUM: item.RECEIPT_NUM,
				PACKING_SLIP:
					item.PACKING_SLIP === 'NA' ? null : item.PACKING_SLIP,
				DISTRIBUTION_NUM: item.DISTRIBUTION_NUM,
				PO_DISTRIBUTION_ID: item.PO_DISTRIBUTION_ID,
				SHIPMENT_NUM: item.SHIPMENT_NUM,
				CREATION_DATE: formattingDate(new Date(), true),
				CREATED_BY: userBy.value.id,
				LAST_UPDATE_DATE: formattingDate(new Date(), true),
				LAST_UPDATED_BY: userBy.value.id,
				REMARKS:
					item.travelOrder && item.travelOrder.travelOrderNo
						? `${item.travelOrder.travelOrderNo}#${
								item.travelOrder.guestName
						  }#${new Intl.DateTimeFormat(
								'en-US',
								dateOptions
						  ).format(
								new Date(item.travelOrder.startDate)
						  )}#${new Intl.DateTimeFormat(
								'en-US',
								dateOptions
						  ).format(new Date(item.travelOrder.endDate))}`
						: item.REMARKS,
				CURRENCY_CODE: item.CURRENCY_CODE,
				REVISION_NUM: item.REVISION_NUM,
				VENDOR_ID: item.VENDOR_ID,
				VENDOR_SITE_ID: item.VENDOR_SITE_ID,
				SHIPMENT_LINE_ID: item.SHIPMENT_LINE_ID,
				SHIPMENT_HEADER_ID: item.SHIPMENT_HEADER_ID,
				RCV_TRANSACTION_ID: item.RCV_TRANSACTION_ID,
				CATEGORY_DETAIL_ID: item.CATEGORY_DETAIL_ID,
				WHT_CODE: item.wht
					? item.wht.WHT_CODE.includes('%')
						? item.wht.WHT_CODE
						: null
					: null,
				COA_ID: item.acc ? item.acc.code_combination_id : null,
				VAT_CODE_ID: item.vat ? item.vat.TAX_RATE_ID : null,
				WHT_CODE_ID: item.wht ? item.wht.WHT_CODE_ID : null,
				GROUP_NAME: item.GROUP_NAME,
			};
		});

	//new details
	payload.newDetails = dataLine.value
		.filter(
			(item) =>
				!savedInvoiceDetails.value.some(
					(val) => val.EINVOICE_LINE_ID === item.id
				)
		)
		.map((item, index) => {
			const dateOptions = {
				year: 'numeric',
				month: 'short',
				day: 'numeric',
			};

			return {
				EINVOICE_ID: parseInt(id.value),
				// PO_HEADER_ID: item.PO_HEADER_ID,
				// PO_LINE_ID: item.PO_LINE_ID,
				// PO_NUMBER: item.PO_NUMBER,
				LINE_NUM: index + 1,
				// ITEM_ID: item.ITEM_ID,
				// ITEM_NUMBER: item.ITEM_NUMBER,
				ITEM_DESCRIPTION: item.ITEM_DESCRIPTION,
				// UNIT_MEAS_LOOKUP_CODE: item.UNIT_MEAS_LOOKUP_CODE,
				// UNIT_PRICE: item.UNIT_PRICE,
				// QUANTITY: item.QUANTITY,
				AMOUNT: item.AMOUNT,
				ORGANIZATION_ID: orgId.value,
				// LINE_LOCATION_ID: item.LINE_LOCATION_ID,
				VAT_CODE: item.vat
					? item.vat.VAT_CODE.includes('%')
						? item.vat.VAT_CODE
						: null
					: null,
				// RECEIPT_NUM: item.RECEIPT_NUM,
				// PACKING_SLIP: item.PACKING_SLIP === "NA" ? null : item.PACKING_SLIP,
				// DISTRIBUTION_NUM: item.DISTRIBUTION_NUM,
				// PO_DISTRIBUTION_ID: item.PO_DISTRIBUTION_ID,
				// SHIPMENT_NUM: item.SHIPMENT_NUM,
				CREATION_DATE: formattingDate(new Date(), true),
				CREATED_BY: userBy.value.id,
				LAST_UPDATE_DATE: formattingDate(new Date(), true),
				LAST_UPDATED_BY: userBy.value.id,
				REMARKS:
					item.travelOrder && item.travelOrder.travelOrderNo
						? `${item.travelOrder.travelOrderNo}#${
								item.travelOrder.guestName
						  }#${new Intl.DateTimeFormat(
								'en-US',
								dateOptions
						  ).format(
								new Date(item.travelOrder.startDate)
						  )}#${new Intl.DateTimeFormat(
								'en-US',
								dateOptions
						  ).format(new Date(item.travelOrder.endDate))}`
						: item.REMARKS,
				// CURRENCY_CODE: item.CURRENCY_CODE,
				REVISION_NUM: 0,
				// VENDOR_ID: item.VENDOR_ID,
				// VENDOR_SITE_ID: item.VENDOR_SITE_ID,
				// SHIPMENT_LINE_ID: item.SHIPMENT_LINE_ID,
				// SHIPMENT_HEADER_ID: item.SHIPMENT_HEADER_ID,
				// RCV_TRANSACTION_ID: item.RCV_TRANSACTION_ID,
				CATEGORY_DETAIL_ID: item.CATEGORY_DETAIL_ID,
				WHT_CODE: item.wht
					? item.wht.WHT_CODE.includes('%')
						? item.wht.WHT_CODE
						: null
					: null,
				COA_ID: item.acc ? item.acc.code_combination_id : null,
				VAT_CODE_ID: item.vat ? item.vat.TAX_RATE_ID : null,
				WHT_CODE_ID: item.wht ? item.wht.WHT_CODE_ID : null,
				// GROUP_NAME: item.GROUP_NAME,
			};
		});

	payload.removedDetails = savedInvoiceDetails.value
		.filter(
			(item) =>
				!dataLine.value.some((val) => val.id === item.EINVOICE_LINE_ID)
		)
		.map((del) => {
			return {
				EINVOICE_LINE_ID: del.EINVOICE_LINE_ID,
				ITEM_DESCRIPTION: del.ITEM_DESCRIPTION,
			};
		});

	if (reimburse.value.length && showReimburse.value) {
		//update reimburse
		payload.editReimburse = reimburse.value
			.filter((id) =>
				savedReimburseDetails.value.some(
					(value) => value.EINVOICE_REIMBURSMENT_ID === id.id
				)
			)
			.map((obj) => {
				return {
					EINVOICE_REIMBURSMENT_ID: obj.EINVOICE_REIMBURSMENT_ID,
					EINVOICE_ID: parseInt(id.value),
					VENDOR_ID: obj.dataVendor ? obj.dataVendor.vendor_id : null,
					TAX_NO: obj.TAX_NO,
					TAX_DATE: formattingDate(obj.TAX_DATE),
					AMOUNT: obj.AMOUNT,
					COA_ID: obj.accReimburse
						? obj.accReimburse.code_combination_id
						: null,
					ORG_ID: obj.ORG_ID,
					CREATED_BY: userBy.value.id,
					CREATION_DATE: formattingDate(new Date()),
					LAST_UPDATED_BY: userBy.value.id,
					LAST_UPDATE_DATE: formattingDate(new Date()),
					GROUP_NAME: obj.group ? obj.group.name : null,
				};
			});

		//new detail reimburse
		payload.newReimburse = reimburse.value
			.filter(
				(id) =>
					!savedReimburseDetails.value.some(
						(value) => value.EINVOICE_REIMBURSMENT_ID === id.id
					)
			)
			.map((obj) => {
				return {
					EINVOICE_ID: parseInt(id.value),
					VENDOR_ID: obj.dataVendor ? obj.dataVendor.vendor_id : null,
					TAX_NO: obj.TAX_NO,
					TAX_DATE: formattingDate(obj.TAX_DATE),
					AMOUNT: obj.AMOUNT,
					COA_ID: obj.accReimburse
						? obj.accReimburse.code_combination_id
						: null,
					ORG_ID: parseInt(orgId.value),
					CREATED_BY: userBy.value.id,
					CREATION_DATE: formattingDate(new Date()),
					LAST_UPDATED_BY: userBy.value.id,
					LAST_UPDATE_DATE: formattingDate(new Date()),
					GROUP_NAME: obj.group ? obj.group.name : null,
				};
			});

		//payload remove
		payload.removedReimburse = savedReimburseDetails.value
			.filter(
				(elem) =>
					!reimburse.value.some(
						(value) => value.id === elem.EINVOICE_REIMBURSMENT_ID
					)
			)
			.map((obj) => {
				return {
					EINVOICE_REIMBURSMENT_ID: obj.EINVOICE_REIMBURSMENT_ID,
					TAX_NO: obj.TAX_NO,
				};
			});
	}

	if (reimburse.value.length === 0) {
		payload.removedalllength = savedReimburseDetails.value.map((item) => {
			return {
				EINVOICE_REIMBURSMENT_ID: item.EINVOICE_REIMBURSMENT_ID,
				TAX_NO: item.TAX_NO,
			};
		});
	}

	// Deleted payload
	if (delFile.value) {
		payload.delFile = delFile.value;
	} else {
		payload.delFile = saved.value;
	}

	const jsonPayload = JSON.stringify(payload);
	formData.append('payload', jsonPayload);

	try {
		progress.value = true;
		const res = await InvoiceNonPOservice.updateInvoicenonPO(
			parseInt(id.value),
			formData
		);
		//

		if (res.status === 200) {
			fileUpload.value.clear();
			file.value = null;
			delFile.value = null;
			isSave.value = true;
			v$.value.$reset();

			await getFileAttachment();
			await getData();
			// await getFileBase64(id.value);

			toast.add({
				severity: 'success',
				summary: 'Successfully edit invoice',
				life: 3000,
			});
		} else if (res.status === 202) {
			toast.add({
				severity: 'error',
				summary: 'Server error: Unsuccessfully edit invoice',
				life: 3000,
			});
		} else if (res.status === 203) {
			toast.add({
				severity: 'error',
				summary: 'Please remove password from file',
				life: 3000,
			});
		} else if (res.status === 204) {
			toast.add({
				severity: 'error',
				summary: 'Invoice Number already exist',
				life: 3000,
			});
		}
		progress.value = false;
	} catch (err) {
		progress.value = false;

		toast.add({
			severity: 'error',
			summary:
				'Network error: Connection down, please check your invoice status on search invoice menu',
		});
		return;
	}
};

//reject
const handleReject = async () => {
	submitted.value = true;

	if (!rejectReason.value) {
		toast.add({
			severity: 'info',
			summary: 'Please fill Reject Reason',
			life: 3000,
		});
		return;
	}

	if (!invoiceNumber.value) {
		toast.add({
			severity: 'info',
			summary: 'Please fill Invoice Number',
			life: 3000,
		});
		return;
	}

	if (!invoiceDate.value) {
		toast.add({
			severity: 'info',
			summary: 'Please fill Invoice Date',
			life: 3000,
		});
		return;
	}

	const payload = {
		header: {
			STATUS: 'DRAFT',
			REASON: rejectReason.value,
		},
		rejectedInvoice: {
			EINVOICE_ID: parseInt(id.value),
			INVOICE_NO: invoiceNumber.value,
			STATUS: 'DRAFT',
			LAST_UPDATE_DATE: formattingDate(new Date(), true),
			INVOICE_DATE: formattingDate(invoiceDate.value),
		},
		emaildata: {
			module: 'searchinvoicenonpo',
			userid: userBy.value.id,
			status_mail: 'Reject',
			companyname: companyInInvoiceHeader.value,
			reason: rejectReason.value,
			invoiceNumber: invoiceNumber.value,
			vendorname: supplierInInvoiceHeader.value,
			// sender: sender.value,
		},
	};

	try {
		progressReject.value = true;
		const res = await InvoiceNonPOservice.rejectInvoicenonPO(
			parseInt(id.value),
			payload
		);
		//

		if (res.status === 200) {
			//fetching ulang
			await getFileAttachment();
			await getData();
			// await getFileBase64(id.value);

			toast.add({
				severity: 'success',
				summary: 'Successfully reject invoice',
				life: 3000,
			});
		} else if (res.status === 202) {
			toast.add({
				severity: 'error',
				summary: 'Server error: Unsuccessfully reject invoice',
				life: 3000,
			});
		}
		progressReject.value = false;
	} catch (err) {
		progressReject.value = false

		toast.add({
			severity: 'error',
			summary:
				'Network error: Connection down, please check your invoice status on search invoice menu',
		});
		return;
	}
};

const exportExcell = async () => {
	await AuthService.whoAmi().then((value) => {
		if (value.status === 200) {
			setTimeout(async () => {
				let biggerTable = true;
				if (dataLine.value.length >= reimburse.value.length) {
					biggerTable = true;
				} else {
					biggerTable = false;
				}
				const payload = {
					invoice_id: parseInt(id.value),
					table: biggerTable,
				};
				await InvoiceNonPOservice.exportinvoice(payload).then(
					(respond) => {
						//
						if (respond.length !== 0) {
							let today = new Date();
							let dd = String(today.getDate()).padStart(2, '0');
							let mm = String(today.getMonth() + 1).padStart(
								2,
								'0'
							); //January is 0!
							let yyyy = today.getFullYear();
							let combinedDate = dd + mm + yyyy;
							let filename =
								'Template Download Invoice _' +
								combinedDate +
								'.xlsx';
							let data = respond.data.data.data;

							let ws = XLSX.utils.json_to_sheet(data);
							let wb = XLSX.utils.book_new();
							XLSX.utils.book_append_sheet(
								wb,
								ws,
								'My Worksheet'
							);
							XLSX.writeFile(wb, filename);
						} else {
							toast.add({
								severity: 'error',
								summary: 'Data is Empty',
								life: 3000,
							});
						}
					}
				);
			}, 250);
		}
	});
};

const handleChangeTax = (event) => {
	const {value} = event.target;
	taxInvoiceNo.value = value.replace(/[a-zA-Z]/g,"").substring(0,20)
}

const progressSubmit = ref(false);

const handleSubmit = async () => {
	//validation
	if (isSave.value) {
		const isFormValid = await v$.value.$validate();

		if (!isFormValid) {
			toast.add({
				severity: 'error',
				summary: 'Please fill all required fields',
				life: 3000,
			});
			return;
		}

		if (!dataLine.value || dataLine.value.length === 0) {
			toast.add({
				severity: 'error',
				summary: 'Please add line',
				life: 3000,
			});
			return;
		}

		if (!file.value && delFile.value) {
			//
			toast.add({
				severity: 'error',
				summary: 'Please select file to upload',
				life: 3000,
			});
			return;
		}

		if (
			!supplierSites.value ||
			!invoiceNumber.value ||
			!remitToBankAccount.value ||
			!paymentMethod.value ||
			!terms.value ||
			!descriptionHeader.value ||
			!taxInvoiceNo.value ||
			!costCenter.value
		) {
			toast.add({
				severity: 'error',
				summary: 'Some fields are required',
				life: 3000,
			});
			return;
		}

		// Add file payload
		const formData = new FormData();
		formData.append('file', file.value);

		const payloadSave = {
			header: {
				EINVOICE_ID: parseInt(id.value),
				INVOICE_NO: invoiceNumber.value,
				INVOICE_DATE: formattingDate(invoiceDate.value),
				REVISION_NUM: 0,
				TAX_NO: taxInvoiceNo.value,
				TAX_DATE: formattingDate(taxInvoiceDate.value),
				DESCRIPTION: descriptionHeader.value,
				CURRENCY_CODE: currency.value,
				VENDOR_ID: vendorId.value,
				INVOICE_AMOUNT: summary.value.invoiceAmmount,
				TAX_AMOUNT: summary.value.taxAmmount,
				TOTAL_AMOUNT: summary.value.totalInvoice,
				// SERVICE_AMOUNT: "",
				// WHT_CODE: "",
				// WHT_RATE: "",
				// ATTRIBUTE1: "",
				ORG_ID: parseInt(orgId.value),
				// STATUS: status.value,
				SUBMITTED_DATE: formattingDate(new Date(), true),
				CREATION_DATE: formattingDate(new Date(), true),
				CREATED_BY: userBy.value.id,
				LAST_UPDATE_DATE: formattingDate(new Date(), true),
				LAST_UPDATED_BY: userBy.value.id,
				PAYMENT_METHOD_LOOKUP_CODE: paymentMethod.value.PAYMENT_METHOD
					? paymentMethod.value.PAYMENT_METHOD
					: paymentMethod.value,
				GL_DATE: glDate.value ? formattingDate(glDate.value) : null,
				TERMS_ID: terms.value ? terms.value.TERM_ID : null,
				REASON: rejectReason.value ? rejectReason.value : null,
				ACCTS_CODE_COMBINATION_ID: catHeader.value
					? parseInt(catHeader.value.ACCOUNT_ID)
					: null,
				EXTERNAL_BANK_ACCOUNT_ID: remitToBankAccount.value
					? remitToBankAccount.value.id
					: null,
				INVOICE_TYPE: 'INVOICE NON PO',
				// CATEGORY_ID: catHeader.value.CATEGORY_ID,
				// MAPING_ID: catHeader.value.MAPING_ID,
				REFERENCE_NUMBER: refNumber.value
					? refNumber.value.sk_number
					: null,
				VENDOR_SITE_ID: supplierSites.value
					? supplierSites.value.id
					: null,
				COST_CENTER: costCenter.value
					? costCenter.value.SEGMENT4
					: null,
				DB_INSTANCE: company.value.db_instance,
			},
			editDetails: [],
			newDetails: [],
			removedDetails: [],
			editReimburse: [],
			newReimburse: [],
			removedReimburse: [],
			removedalllength: [],
			countDetails: dataLine.value.length,
		};

		//update details
		payloadSave.editDetails = dataLine.value
			.filter((obj) =>
				savedInvoiceDetails.value.some(
					(val) => val.EINVOICE_LINE_ID === obj.id
				)
			)
			.map((item, index) => {
				const dateOptions = {
					year: 'numeric',
					month: 'short',
					day: 'numeric',
				};

				return {
					EINVOICE_LINE_ID: item.EINVOICE_LINE_ID,
					EINVOICE_ID: parseInt(id.value),
					PO_HEADER_ID: item.PO_HEADER_ID,
					PO_LINE_ID: item.PO_LINE_ID,
					PO_NUMBER: item.PO_NUMBER,
					LINE_NUM: index + 1,
					ITEM_ID: item.ITEM_ID,
					ITEM_NUMBER: item.ITEM_NUMBER,
					ITEM_DESCRIPTION: item.ITEM_DESCRIPTION,
					UNIT_MEAS_LOOKUP_CODE: item.UNIT_MEAS_LOOKUP_CODE,
					UNIT_PRICE: item.UNIT_PRICE,
					QUANTITY: item.QUANTITY,
					AMOUNT: item.AMOUNT,
					ORGANIZATION_ID: parseInt(company.value.value),
					LINE_LOCATION_ID: item.LINE_LOCATION_ID,
					VAT_CODE: item.vat
						? item.vat.VAT_CODE.includes('%')
							? item.vat.VAT_CODE
							: null
						: null,
					RECEIPT_NUM: item.RECEIPT_NUM,
					PACKING_SLIP:
						item.PACKING_SLIP === 'NA' ? null : item.PACKING_SLIP,
					DISTRIBUTION_NUM: item.DISTRIBUTION_NUM,
					PO_DISTRIBUTION_ID: item.PO_DISTRIBUTION_ID,
					SHIPMENT_NUM: item.SHIPMENT_NUM,
					CREATION_DATE: formattingDate(new Date(), true),
					CREATED_BY: userBy.value.id,
					LAST_UPDATE_DATE: formattingDate(new Date(), true),
					LAST_UPDATED_BY: userBy.value.id,
					REMARKS:
						item.travelOrder && item.travelOrder.travelOrderNo
							? `${item.travelOrder.travelOrderNo}#${
									item.travelOrder.guestName
							  }#${new Intl.DateTimeFormat(
									'en-US',
									dateOptions
							  ).format(
									new Date(item.travelOrder.startDate)
							  )}#${new Intl.DateTimeFormat(
									'en-US',
									dateOptions
							  ).format(new Date(item.travelOrder.endDate))}`
							: item.REMARKS,
					CURRENCY_CODE: item.CURRENCY_CODE,
					REVISION_NUM: item.REVISION_NUM,
					VENDOR_ID: item.VENDOR_ID,
					VENDOR_SITE_ID: item.VENDOR_SITE_ID,
					SHIPMENT_LINE_ID: item.SHIPMENT_LINE_ID,
					SHIPMENT_HEADER_ID: item.SHIPMENT_HEADER_ID,
					RCV_TRANSACTION_ID: item.RCV_TRANSACTION_ID,
					CATEGORY_DETAIL_ID: item.CATEGORY_DETAIL_ID,
					WHT_CODE: item.wht
						? item.wht.WHT_CODE.includes('%')
							? item.wht.WHT_CODE
							: null
						: null,
					COA_ID: item.acc ? item.acc.code_combination_id : null,
					VAT_CODE_ID: item.vat ? item.vat.TAX_RATE_ID : null,
					WHT_CODE_ID: item.wht ? item.wht.WHT_CODE_ID : null,
					GROUP_NAME: item.GROUP_NAME,
				};
			});

		//new details
		payloadSave.newDetails = dataLine.value
			.filter(
				(item) =>
					!savedInvoiceDetails.value.some(
						(val) => val.EINVOICE_LINE_ID === item.id
					)
			)
			.map((item, index) => {
				const dateOptions = {
					year: 'numeric',
					month: 'short',
					day: 'numeric',
				};

				return {
					EINVOICE_ID: parseInt(id.value),
					// PO_HEADER_ID: item.PO_HEADER_ID,
					// PO_LINE_ID: item.PO_LINE_ID,
					// PO_NUMBER: item.PO_NUMBER,
					LINE_NUM: index + 1,
					// ITEM_ID: item.ITEM_ID,
					// ITEM_NUMBER: item.ITEM_NUMBER,
					ITEM_DESCRIPTION: item.ITEM_DESCRIPTION,
					// UNIT_MEAS_LOOKUP_CODE: item.UNIT_MEAS_LOOKUP_CODE,
					// UNIT_PRICE: item.UNIT_PRICE,
					// QUANTITY: item.QUANTITY,
					AMOUNT: item.AMOUNT,
					ORGANIZATION_ID: orgId.value,
					// LINE_LOCATION_ID: item.LINE_LOCATION_ID,
					VAT_CODE: item.vat
						? item.vat.VAT_CODE.includes('%')
							? item.vat.VAT_CODE
							: null
						: null,
					// RECEIPT_NUM: item.RECEIPT_NUM,
					// PACKING_SLIP: item.PACKING_SLIP === "NA" ? null : item.PACKING_SLIP,
					// DISTRIBUTION_NUM: item.DISTRIBUTION_NUM,
					// PO_DISTRIBUTION_ID: item.PO_DISTRIBUTION_ID,
					// SHIPMENT_NUM: item.SHIPMENT_NUM,
					CREATION_DATE: formattingDate(new Date(), true),
					CREATED_BY: userBy.value.id,
					LAST_UPDATE_DATE: formattingDate(new Date(), true),
					LAST_UPDATED_BY: userBy.value.id,
					REMARKS:
						item.travelOrder && item.travelOrder.travelOrderNo
							? `${item.travelOrder.travelOrderNo}#${
									item.travelOrder.guestName
							  }#${new Intl.DateTimeFormat(
									'en-US',
									dateOptions
							  ).format(
									new Date(item.travelOrder.startDate)
							  )}#${new Intl.DateTimeFormat(
									'en-US',
									dateOptions
							  ).format(new Date(item.travelOrder.endDate))}`
							: item.REMARKS,
					// CURRENCY_CODE: item.CURRENCY_CODE,
					REVISION_NUM: 0,
					// VENDOR_ID: item.VENDOR_ID,
					// VENDOR_SITE_ID: item.VENDOR_SITE_ID,
					// SHIPMENT_LINE_ID: item.SHIPMENT_LINE_ID,
					// SHIPMENT_HEADER_ID: item.SHIPMENT_HEADER_ID,
					// RCV_TRANSACTION_ID: item.RCV_TRANSACTION_ID,
					CATEGORY_DETAIL_ID: item.CATEGORY_DETAIL_ID,
					WHT_CODE: item.wht
						? item.wht.WHT_CODE.includes('%')
							? item.wht.WHT_CODE
							: null
						: null,
					COA_ID: item.acc ? item.acc.code_combination_id : null,
					VAT_CODE_ID: item.vat ? item.vat.TAX_RATE_ID : null,
					WHT_CODE_ID: item.wht ? item.wht.WHT_CODE_ID : null,
					// GROUP_NAME: item.GROUP_NAME,
				};
			});

		payloadSave.removedDetails = savedInvoiceDetails.value
			.filter(
				(item) =>
					!dataLine.value.some(
						(val) => val.id === item.EINVOICE_LINE_ID
					)
			)
			.map((del) => {
				return {
					EINVOICE_LINE_ID: del.EINVOICE_LINE_ID,
					ITEM_DESCRIPTION: del.ITEM_DESCRIPTION,
				};
			});

		if (reimburse.value.length && showReimburse.value) {
			//update reimburse
			payloadSave.editReimburse = reimburse.value
				.filter((id) =>
					savedReimburseDetails.value.some(
						(value) => value.EINVOICE_REIMBURSMENT_ID === id.id
					)
				)
				.map((obj) => {
					return {
						EINVOICE_REIMBURSMENT_ID: obj.EINVOICE_REIMBURSMENT_ID,
						EINVOICE_ID: parseInt(id.value),
						VENDOR_ID: obj.dataVendor
							? obj.dataVendor.vendor_id
							: null,
						TAX_NO: obj.TAX_NO,
						TAX_DATE: formattingDate(obj.TAX_DATE),
						AMOUNT: obj.AMOUNT,
						COA_ID: obj.accReimburse
							? obj.accReimburse.code_combination_id
							: null,
						ORG_ID: obj.ORG_ID,
						CREATED_BY: userBy.value.id,
						CREATION_DATE: formattingDate(new Date()),
						LAST_UPDATED_BY: userBy.value.id,
						LAST_UPDATE_DATE: formattingDate(new Date()),
						GROUP_NAME: obj.group ? obj.group.name : null,
					};
				});

			//new detail reimburse
			payloadSave.newReimburse = reimburse.value
				.filter(
					(id) =>
						!savedReimburseDetails.value.some(
							(value) => value.EINVOICE_REIMBURSMENT_ID === id.id
						)
				)
				.map((obj) => {
					return {
						EINVOICE_ID: parseInt(id.value),
						VENDOR_ID: obj.dataVendor
							? obj.dataVendor.vendor_id
							: null,
						TAX_NO: obj.TAX_NO,
						TAX_DATE: formattingDate(obj.TAX_DATE),
						AMOUNT: obj.AMOUNT,
						COA_ID: obj.accReimburse
							? obj.accReimburse.code_combination_id
							: null,
						ORG_ID: parseInt(orgId.value),
						CREATED_BY: userBy.value.id,
						CREATION_DATE: formattingDate(new Date()),
						LAST_UPDATED_BY: userBy.value.id,
						LAST_UPDATE_DATE: formattingDate(new Date()),
						GROUP_NAME: obj.group ? obj.group.name : null,
					};
				});

			//payload remove
			payloadSave.removedReimburse = savedReimburseDetails.value
				.filter(
					(elem) =>
						!reimburse.value.some(
							(value) =>
								value.id === elem.EINVOICE_REIMBURSMENT_ID
						)
				)
				.map((obj) => {
					return {
						EINVOICE_REIMBURSMENT_ID: obj.EINVOICE_REIMBURSMENT_ID,
						TAX_NO: obj.TAX_NO,
					};
				});
		}

		if (reimburse.value.length === 0) {
			payloadSave.removedalllength = savedReimburseDetails.value.map(
				(item) => {
					return {
						EINVOICE_REIMBURSMENT_ID: item.EINVOICE_REIMBURSMENT_ID,
						TAX_NO: item.TAX_NO,
					};
				}
			);
		}

		// Deleted payload
		if (delFile.value) {
			payloadSave.delFile = delFile.value;
		} else {
			payloadSave.delFile = saved.value;
		}

		const jsonPayload = JSON.stringify(payloadSave);
		formData.append('payload', jsonPayload);

		try {
			progressSubmit.value = true;
			const res = await InvoiceNonPOservice.updateInvoicenonPO(
				parseInt(id.value),
				formData
			);
			//

			if (res.status === 200) {
				fileUpload.value.clear();
				file.value = null;
				delFile.value = null;
				isSave.value = true;
				v$.value.$reset();

				await getData();
				await getFileAttachment();
				// await getFileBase64(id.value);

				const payload = {
					companyName: companyInInvoiceHeader.value,
					vendorName: supplierInInvoiceHeader.value,
					vendorId: vendorId.value,
					inv_amount: summary.value.invoiceAmmount,
					tax_amount: summary.value.taxAmmount,
					total_amount: summary.value.totalInvoice,
					inv_no: invoiceNumber.value,
					tax_no: taxInvoiceNo.value,
					recom_email: catHeader.value.REKOMENDATOR,
					ref_no: referenceNumber.value ? referenceNumber.value : '',
					inv_date: formattingDate(invoiceDate.value),
					tax_date: formattingDate(taxInvoiceDate.value),
					cost_center: costCenter.value.SEGMENT4
						? costCenter.value.SEGMENT4
						: costCenter.value,
					req_email: userBy.value.bioemailactive,
					category_name: catHeader.value.CATEGORY_NAME,
					curr_code: currency.value,
					inv_id: parseInt(id.value),
					user_id: userBy.value.id,
				};

				try {
					progressSubmit.value = true;
					const res = await InvoiceNonPOservice.submitRekomendator(
						payload
					);

					if (res.status === 200) {
						toast.add({
							severity: 'success',
							summary: 'Successfully submit to rekomendator',
							life: 3000,
						});
						progressSubmit.value = false;
						await getData();
					}
					progressSubmit.value = false;
				} catch (error) {
					progressSubmit.value = false;
					toast.add({
						severity: 'error',
						summary: 'Server error: Unsuccessfully submit',
						life: 3000,
					});
					return;
				}
			} else if (res.status === 202) {
				toast.add({
					severity: 'error',
					summary: 'Server error: Unsuccessfully edit invoice',
					life: 3000,
				});
			} else if (res.status === 203) {
				toast.add({
					severity: 'error',
					summary: 'Please remove password from file',
					life: 3000,
				});
			} else if (res.status === 204) {
				toast.add({
					severity: 'error',
					summary: 'Invoice Number already exist',
					life: 3000,
				});
			}
			progressSubmit.value = false;
		} catch (err) {
			progressSubmit.value = false;

			toast.add({
				severity: 'error',
				summary:
					'Network error: Connection down, please check your invoice status on search invoice menu',
			});
			return;
		}
	} else {
		toast.add({
			severity: 'error',
			summary: 'Please save first before submit',
			life: 3000,
		});
	}
};

const onDownload = async () => {
	try {
		loadingDownladFile.value = true;
		const leveltenant = sessionStorage.getItem('leveltenant');
		const res = await InvoiceNonPOservice.getFileBase64(
			id.value,
			leveltenant,
			vendorId.value
		);

		if (res.status === 200) {
			fileBuffer.value = res.data.data;
		}
	} catch (error) {
		loadingDownladFile.value = false;
		toast.add({
			severity: 'error',
			summary: 'Connection Unstable !',
			life: 3000,
		});
		throw Error('Error downloading file');
	}

	const linkSource = `data:application/pdf;base64,${fileBuffer.value}`;
	const downloadLink = document.createElement('a');
	const fileName = saved.value.filename;
	downloadLink.href = linkSource;
	downloadLink.download = fileName;
	downloadLink.click();
	loadingDownladFile.value = false;
};
</script>

<style scoped>
#label-key {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 36px;
	/* identical to box height, or 257% */

	color: #000000;
}

#label-value {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 36px;
	/* identical to box height, or 257% */
	align-items: center;
	text-align: right;

	/* Gray 2 */

	color: #4f4f4f;
}

#line-value {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: bold;
	color: #4f4f4f;
	font-size: 14px;
}

.input-disabled :disabled {
	background: rgb(177, 177, 177) !important;
}
</style>
