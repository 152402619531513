<template>
	<AutoComplete
		:suggestions="filteredCategory"
		@complete="searchRekomendator($event)"
		:dropdown="true"
		field="CATEGORY_NAME"
		v-model="categoryValue"
		:disabled="!category.length"
		forceSelection
	>
		<template #item="slotProp">
			{{ slotProp.item.CATEGORY_NAME }}
		</template>
	</AutoComplete>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect } from 'vue';
import AuthService from '../../../service/AuthService';
import vendorServices from '../../../service/masterVendorService';

// eslint-disable-next-line no-undef
const filteredCategory = ref([]);
const category = ref([]);
const categoryValue = ref();
const emit = defineEmits(['remitCategory']);
// eslint-disable-next-line no-undef
const props = defineProps({
	company: { type: Object },
	supplier: { type: Object },
	categoryId: { type: Object },
});

onMounted(() => {
	populateData();
});

watchEffect(() => emit('remitCategory', categoryValue));

const populateData = () => {
	AuthService.whoAmi().then(async (response) => {
		let payload;
		if (props.company !== null) {
			payload = {
				org_id: props.company.value,
				db_instance: props.company.db_instance,
			};
			if (response.status === 200) {
				const data = await vendorServices.getCategory(payload);
				category.value = data.data.data.data;
				categoryValue.value = data.data.data.data;

				for (const s of category.value) {
					if (s.CATEGORY_ID === props.categoryId) {
						categoryValue.value = s;
						break;
					} else {
						categoryValue.value = null;
					}
				}
			}
		}
	});
};

const searchRekomendator = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredCategory.value = [...category.value];
    } else {
      filteredCategory.value = category.value.filter((comp) => {
        return comp.CATEGORY_NAME.toLowerCase().match(event.query.toLowerCase());
      });
    }
  }, 250);
};
</script>

<style scoped></style>
