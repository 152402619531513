<template>
	<DataTable
		:value="invoiceList"
		:lazy="true"
		:paginator="true"
		:rows="5"
		:loading="loading"
		:totalRecords="totalData"
		@page="onPage($event)"
		@sort="onSort($event)"
		:rowsPerPageOptions="[5, 10, 15, 25]"
		responsiveLayout="scroll"
		:scrollable="true"
		scrollHeight="600px"
		:sortable="true"
		scrollDirection="both"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #header>
			<div>
				<span class="p-input-icon-left" style="width: 100%">
					<i class="pi pi-search" />
					<InputText
						v-model="invoiceFilter"
						style="width: 100%"
						placeholder="Search Invoice"
						@keyup.enter="onEnterSearch($event)"
					/>
				</span>
			</div>
		</template>
		<template #empty>{{ searchMessage }}</template>
		<template #loading>Loading data, please wait...</template>
		<Column header="Company" field="NAME" style="width: 15%"> </Column>
		<Column header="Supplier" field="vendor_name" style="width: 15%">
		</Column>
		<Column header="Invoice No" field="INVOICE_NO" style="width: 25%">
		</Column>
		<Column
			header="Date"
			field="INVOICE_DATE"
			:sortable="true"
			style="width: 15%"
		>
			<template #body="{ data }">
				{{ formattingDate(data.INVOICE_DATE) }}
			</template>
		</Column>
		<Column header="Amount" field="TOTAL_AMOUNT" style="width: 15%">
			<template #body="{ data }">
				{{
					Number.parseFloat(data.TOTAL_AMOUNT)
						.toFixed(2)
						.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
				}}
			</template>
		</Column>
		<Column header="Status" style="width: 15%">
			<template #body="{ data }">
				<Chip
					class="custom-chip"
					:class="`chip-${data.STATUS.toLowerCase()}`"
				>
					{{ data.STATUS }}
				</Chip>
			</template>
		</Column>
		<Column
			class="call-button-container"
			header="Actions"
			selectionMode="single"
			style="width: 15%"
			alignFrozen="right"
			frozen
		>
			<template #body="{ data }">
				<Button
					icon="pi pi-eye"
					class="p-button-rounded p-button-text"
					@click="detail(data)"
					v-tooltip="'Detail'"
				/>
			</template>
		</Column>
	</DataTable>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import AuthService from '@/service/AuthService';
// import LovService from "@/service/LovService";
// import PoFulfillment from "@/service/PoFulfillment";
import invoiceProxyService from '../../../service/InvoiceProxyService';

const company = ref();
// const supplier = ref();

onMounted(async () => {
	company.value = props.company;
	const info = await AuthService.whoAmi();
	if (info.status === 200) {
		levelTenant.value = info.data.data.leveltenant;
		await populateTable();
	}
});
// eslint-disable-next-line no-undef
const props = defineProps({
	company: {
		type: Object,
	},
	vendorId: {
		type: Object,
	},
	batchNumber: {
		type: String,
	},
	invoiceNumber: {
		type: String,
	},
	statusInvoice: {
		type: String,
	},
	searchAction: {
		type: Boolean,
	},
	resetSearch: {
		type: Boolean,
	},
});

// const tableData = ref([]);
const invoiceList = ref();
const totalData = ref(0);
const totalPage = ref(0);
const loading = ref(false);
const page = ref(1);
// const rowsPerPage = ref(5);
const levelTenant = ref();
const invoiceFilter = ref();
const router = useRouter();
const emit = defineEmits(['result']);
const search = ref();
const limitPerPage = ref(10);
const sortField = ref();
const sortOrder = ref();
const searchMessage = ref('Click search for find data Invoice Proxy');

watchEffect(
	() =>
		//
		props.resetSearch,
	async () => {
		await resetTable();
	},
	emit('result', invoiceList)
);

// watch(
//   () => props.resetSearch, async () => {
//     await resetTable()
//   }
// );

const populateTable = async () => {
	//
	loading.value = true;
	if (
		props.searchAction &&
		props.company !== null &&
		props.company !== undefined
	) {
		let resultSearch = await invoiceProxyService.getListInvoiceProxy(
			props.company ? props.company.value : null,
			props.company ? props.company.db_instance : null,
			props.vendorId ? props.vendorId : null,
			props.batchNumber ? props.batchNumber : null,
			props.invoiceNumber ? props.invoiceNumber : null,
			props.statusInvoice ? props.statusInvoice : null,
			page.value ? page.value : null,
			limitPerPage.value ? limitPerPage.value : null,
			sortField.value ? sortField.value : null,
			sortOrder.value ? sortOrder.value : null,
			search.value ? search.value : null
		);

		invoiceList.value = resultSearch.data.data;
		if (resultSearch.data.data.length === 0) {
			searchMessage.value = 'No Data Found';
		}
		totalData.value = resultSearch.data.totalData;
		totalPage.value = resultSearch.data.totalPage;
	}
	loading.value = false;
};

//format dete dexa
const formattingDate = (date, withTime) => {
	const theDate = new Date(date);
	const strDate =
		theDate.getFullYear() +
		'-' +
		(theDate.getMonth() + 1) +
		'-' +
		theDate.getDate();
	const strTime =
		theDate.getHours() +
		':' +
		theDate.getMinutes() +
		':' +
		theDate.getSeconds();
	if (withTime) {
		return `${strDate} ${strTime}`;
	} else {
		return `${strDate}`;
	}
};

const onPage = async (event) => {
	// loading.value = true
	page.value = event.page + 1;
	limitPerPage.value = event.rows;
	await populateTable();
};

const onSort = async (event) => {
	// loading.value = true
	page.value = 1;
	limitPerPage.value = event.rows;
	sortField.value = event.sortField;
	sortOrder.value = event.sortOrder;
	await populateTable();
};

const detail = async (rowIndex) => {
	if(rowIndex.TYPE_FORM == 'SP1'){
		router.push({
			name: 'DetailSearchInvoiceNonProxySP1',
			params: {
				id: rowIndex.EINVOICE_ID,
			},
		});
		return;
	}

	router.push({
		name: 'DetailSearchInvoiceNonProxy',
		params: {
			id: rowIndex.EINVOICE_ID,
		},
	});
};

const onEnterSearch = async (event) => {
	//
	//

	search.value = event.target.value;
	if (search.value !== null) {
		await populateTable();
	}
};

const resetTable = async () => {
	invoiceList.value = null;
};
</script>

<style scoped>
.input-disabled {
	color: #000 !important;
}

.p-chip.custom-chip {
	padding: 4px 12px;
	border-radius: 8px;
	font-size: 12px;
	font-weight: 600;
}

.chip-open {
	background: #0ea5e9;
	color: #fff;
}
.chip-rejected {
	background: var(--primary-color);
	color: #fff;
}
.chip-review {
	background: #f59e0b;
	color: #fff;
}
.chip-posted {
	background: #10b981;
	color: #fff;
}
.chip-processed {
	background: #2563eb;
	color: #fff;
}
</style>
