import spbinvoice from './api/SpbInvoiceApi';

class SearchInvoicePo {
	getSearchInvoicePo(payload) {
		return spbinvoice.post(`/spb/searchinvoice/searchinvoicepo`, payload);
	}
	rejectInvoice(payload) {
		return spbinvoice.post(`/spb/searchinvoice/rejectinvoice`, payload);
	}
	detailCalculate(payload){
		return spbinvoice.post(`/spb/searchinvoice/detailcalculate`, payload);
	}
	detailCalculateProc(payload){
		return spbinvoice.post(`/spb/searchinvoice/detailcalculateproc`, payload);
	}
}

export default new SearchInvoicePo();
