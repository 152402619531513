<template>
  <AutoComplete
    :suggestions="filteredSupplierSite"
    @complete="searcSupplierSite($event)"
    :dropdown="true"
    field="vendor_site_code"
    :placeholder="placeholder"
    forceSelection
    v-model="supplierSite"
  >
    <template #item="slotProp">
      {{ slotProp.item.vendor_site_code }}
    </template>
  </AutoComplete>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect } from "vue";
//   import LovService from "@/service/LovService";
//   import SwithCompany from "@/service/SwithCompany";
// import AuthService from "@/service/AuthService";
import invoiceNonPOservice from "@/service/InvoiceNonPOservice";

// eslint-disable-next-line no-undef
const filteredSupplierSite = ref([]);
const supplierSites = ref([]);
const supplierSite = ref();
const placeholder = ref("Select a supplier site");

const emit = defineEmits(["supplierSite"]);

watchEffect(() => emit("supplierSite", supplierSite));
// eslint-disable-next-line no-undef
const props = defineProps({
  company: {
    type: Object,
  },
  vendorId: {
    type: String,
  },
  vendorSiteId: {
    type: String,
  },
});

onMounted(async () => {
  try {
    // const response = await AuthService.whoAmi();
    // const user = response.data.data;
    if (props.company && props.vendorId) {
      const result = await invoiceNonPOservice.getListPoVendorSite(
        props.company.value,
        props.company.db_instance,
        props.vendorId
      );
      supplierSites.value = result.data.data;
      if (props.vendorSiteId) {
        for (const suppSiteData of supplierSites.value) {
          if (suppSiteData.vendor_site_id == props.vendorSiteId) {
            supplierSite.value = suppSiteData;
          }
        }
      }
    }

    // eslint-disable-next-line no-empty
  } catch (e) {
    throw new Error(e);
  }
});

const searcSupplierSite = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredSupplierSite.value = [...supplierSites.value];
    } else {
      filteredSupplierSite.value = supplierSites.value.filter((supp) => {
        return supp.vendor_site_code
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};
</script>
