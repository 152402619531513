<template>
  <AutoComplete
    :suggestions="filteredSTKNumber"
    @complete="searchSTKNumber($event)"
    :dropdown="true"
    field="kiCode"
    forceSelection
  >
    <template #item="slotProp">
      {{ slotProp.item.kiCode }}
    </template>
  </AutoComplete>
</template>

<script setup>
import { onMounted, ref } from "vue";
import {
  getDataSP1KI
} from '../../../service/InvoiceProxyNonPOServices';

// eslint-disable-next-line no-undef
const filteredSTKNumber = ref([]);
const stkNumbers = ref([]);

// eslint-disable-next-line no-unused-vars,no-undef
const props = defineProps({
  company: {
    type: Object,
  },
  supplier: {
    type: Object,
  },
  receivedType: {
    type: Object
  }
});

console.log("KE RELOAD INI", filteredSTKNumber.value)

onMounted(async () => {
  try {

    const responseData = await getDataSP1KI({
      company_code: 'dxm',
      vendor_id: props.supplier ? props.supplier.vendor_id : '',
      type_value: props.receivedType ? props.receivedType.TYPE_VALUE : '',
      vendor_site_id: props.supplier ? props.supplier.vendor_site_id : ''
    })

    let valueResp = responseData.data.data.data;

    stkNumbers.value = valueResp
    // eslint-disable-next-line no-empty
  } catch (e) {}
});

const searchSTKNumber = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredSTKNumber.value = [...stkNumbers.value];
    } else {
      filteredSTKNumber.value = stkNumbers.value.filter((comp) => {
        return comp.kiCode
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }


  }, 250);
};
</script>
