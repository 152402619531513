<template>
	<div class="grid">
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<div class="col-12">
			<div class="card">
				<div class="flex flex-row justify-content-between">
					<div class="col flex-column align-items-center lg:col-auto">
						<span>
							<CompaniesDropdown v-model="company" class="w-full mb-2 lg:w-auto lg:mr-2 lg:mb-0"
								:disabled="!(userInfo.leveltenant === 2)" />
							<SupplierDropDown v-if="!(userInfo.leveltenant === 2)" v-model="supplier"
								:company="company ? company : null" class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
								:key="supplierReload" />
							<AutoComplete v-model="searchColumn" :suggestions="filteredColumn"
								@complete="searchColumnField($event)" field="name" :dropdown="true"
								placeholder="Select Column" class="w-full mb-2 lg:w-auto lg:mr-2 lg:mb-0"
								forceSelection>
								<template #item="slotProps">
									<div class="country-item">
										<div class="ml-2">
											{{ slotProps.item.name }}
										</div>
									</div>
								</template>
							</AutoComplete>
							<!-- <Dropdown v-model="searchColumn" :options="searchList" optionLabel="name"
                              optionValue="value" placeholder="Select a Column" class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0" style="pl-4"/> -->
							<InputText v-if="!inputStatus" type="text" placeholder="Search" v-model="value"
								class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0" />
							<InputMask v-if="inputStatus" mask="99-99-9999" v-model="value" placeholder="Search"
								slotChar="DD-MM-YYYY" class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0" />
							<!-- <InputMask v-if="inputStatus" mask="DD-MM-YYYY" slot-char="DD-MM-YYYY" placeholder="Search"/> -->
							<!-- <Calendar v-if="inputStatus" placeholder="Search" v-model="value" /> -->
						</span>
						<span>
							<Button label="GO" class="w-full mb-2 lg:w-auto lg:mr-2 lg:mb-0"
								@click="goSearchNestedtable()" :disabled="loading" />
							<Button label="Clear" class="p-button-info w-full mb-3 lg:w-auto lg:mr-3 lg:mb-0"
								@click="clearSearch()" :disabled="loading" />
						</span>
						<Button icon="pi pi-file" label="Export" class="p-button-secondary" @click="handleExport()"
							:disabled="loading" style="float: right" />
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="card">
		<DataTable class="fixed-scroll-datatable" :value="nested" :lazy="true" :paginator="true" :rows="rows"
			v-model:expandedRows="expandedRows" ref="dt" dataKey="po_num" :totalRecords="totalRecords"
			:loading="loading" @page="onPage($event)" @sort="onSort($event)" removableSort
			:rowsPerPageOptions="[10, 20, 30]" responsiveLayout="scroll" :scrollable="true" scrollHeight="600px"
			:v-model="first">
			<template #empty> {{ isEmpty }}</template>
			<Column :expander="true" style="width: 100px; flex: 0 0 0" />
			<Column field="po_num" header="PO Number" :sortable="true"></Column>
			<template #expansion="slotProps">
				<div class="orders-subtable">
					<DataTable :value="slotProps.data.children" dataKey="ITEM_NUMBER" removableSort
						responsiveLayout="scroll" :scrollable="true" scrollHeight="600px" scrollDirection="both">
						<template #empty> {{ isEmpty }}</template>
						<Column header="Line" style="width: 5%" frozen>
							<template #body="slotProps">
								{{ slotProps.index + 1 }}
							</template>
						</Column>
						<!-- <Column field="id" header="Line" :sortable="true" style="text-align: center"></Column> -->
						<Column field="ITEM_NUMBER" header="Item Number" :sortable="true" style="width: 15%"></Column>
						<Column field="MANUFACTURER" header="Manufacturer" :sortable="true" style="width: 21%"></Column>

						<Column field="UOM" header="UOM" :sortable="true" style="width: 20%"></Column>
						<Column field="ITEM_DESCRIPTION" header="Item Description" :sortable="true" style="width: 20%">
						</Column>
						<Column field="CURRENCY_CODE" header="Currency" :sortable="true" style="width: 15%">
						</Column>
						<!-- <Column header="Unit Price" :sortable="true" dataType="numeric" style="width: 15%">
							<template #body="{ data }">
								{{ formatCurrency(data.UNIT_PRICE) }}
							</template>
						</Column> -->
						<!-- <Column
  field="uprice"
  header="Uwdawdasdawe"
  :sortable="true"
  style="text-align: center"
></Column> -->
						<!-- <Column header="Amount" :sortable="true" dataType="numeric" style="width: 15%">
							<template #body="{ data }">
								{{ formatCurrency(data.AMOUNT) }}
							</template>
						</Column> -->
						<Column field="REMARKS" header="Note to Supplier" :sortable="true" style="width: 20%">
						</Column>
						<Column field="ARRIVAL_AT_SITE" header="Arrival at Site" :sortable="true" style="width: 15%">
							<!-- <template #body="slotProps">
  {{ slotProps.data.arrival }}
</template> -->
						</Column>
						<Column field="CREATION_DATE" header="Submit Date" :sortable="true" style="width: 15%">
							<!-- <template #body="slotProps">
  {{
    new Date(
      slotProps.data.creation
    ).toLocaleString('id-ID', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })
  }}
  {{ slotProps.data.shipment }}
</template> -->
						</Column>
						<Column field="STATUS" header="Line Status" :sortable="true" style="width: 15%">
						</Column>
						<!--            <Column field="LOE" header="L/O/E" :sortable="true" style="text-align: center"></Column>-->
						<Column field="QUANTITY" header="Qty Order" :sortable="true" style="width: 15%"></Column>
						<Column field="QUANTITY_OPEN" header="Qty Open" :sortable="true" style="width: 15%"></Column>
						<Column field="QUANTITY_RECEIVED" header="Qty Received" :sortable="true" style="width: 15%">
						</Column>
						<Column field="QUANTITY_RETURN" header="Qty Return" :sortable="true" style="width: 15%">
						</Column>
					</DataTable>
				</div>
			</template>
		</DataTable>
	</div>

	<div>
		<Dialog header="Export Document" :draggable="false" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
			:style="{ width: '45vw', overflow: 'hidden' }" :modal="true" :dismissableMask="true"
			v-model:visible="exportPopup">
			<div class="pt-4">
				<div class="lg:grid grid">
					<div class="lg:col-4 col-12" >
						<span class="p-float-label">
							<Calendar id="fromDate" class="w-full" v-model="fromDate" dateFormat="d-M-yy" :showIcon="true" />
							<label for="fromDate">From Date</label>
						</span>
					</div>
					<div class="lg:col-4 col-12">
						<span class="p-float-label">
							<Calendar id="toDate" class="w-full" v-model="toDate" dateFormat="d-M-yy" :showIcon="true" />
							<label for="toDate">To Date</label>
						</span>
					</div>
					<div class="lg:col-4 col-12">
						<Button label="Export" :disabled="disabledExport" class="w-full" @click="exportData()" icon="pi pi-file" autofocus />
					</div>
				</div>
			</div>
		</Dialog>
	</div>
</template>

<script>
/* eslint-disable */
import AuthService from '../../service/AuthService';
import LovService from '../../service/LovService';
import SimpleCrypto from 'simple-crypto-js';
import PoFulfillment from '../../service/PoFulfillment';
import OutstandingPo from '../../service/RooServices';
import XLSX from 'xlsx';
import CompaniesDropdown from '@/components/components/CompaniesDropdown';
import SupplierDropDown from '@/components/components/SupplierDropdown';


export default {
	//  name: "poFulfillment-list",
	name: 'planningorder-list',
	components: {
		CompaniesDropdown,
		SupplierDropDown,
	},
	data() {
		return {
			disabledExport: false,
			fromDate: null,
			toDate: null,
			exportPopup: false,
			company: null,
			userInfo: null,
			supplier: null,
			supplierReload: 0,
			isSupplierPage: false,
			first: 0,
			rowId: null,
			expandedRows: [],
			expandedRowGroups: [],
			nested: [],
			nestedExport: [],
			rows: 10,
			nestedchildren: [],
			groupdata: null,
			selectedChildren: null,
			loading: false,
			lazyParams: {},
			selectLazyParams: {},
			totalRecords: 0,
			totalRecordsGrup: 0,
			tabelexpand: null,
			filtered: null,
			button: 0,
			buttonDisable: true,
			buttonSearch: true,
			rowgroup: null,
			searchList: [],
			searchColumn: null,
			value: null,
			searchColumnexport: null,
			valueExport: null,
			inputStatus: false,
			searchStatus: false,
			filteredColumn: null,
			// isSupplierPage:false,
			breadcrumbHome: {
				icon: 'pi pi-home',
				to: '/dashboard',
			},
			breadcrumbItems: [
				{
					label: 'Outstanding PO Report',
					to: '/mgm/settings/outstandingporeport',
				},
			],
		};
	},
	watch: {
		company(newQuestion, oldQuestion) {
			if (this.userInfo.leveltenant !== 2) {
				this.supplier = null;
			}
			this.supplierReload++;
			if (oldQuestion) {
				this.supplierReload++;
				this.goSearchNestedtable();
			}
		},
		searchColumn(event) {
			if (
				typeof event === 'object' &&
				!Array.isArray(event) &&
				event != null &&
				event.length > 0
			) {
				this.searchStatus = true;
				if (
					event.name.includes('DATE') ||
					event.name === 'ARRIVAL_AT_SITE'
				) {
					this.inputStatus = true;
				} else {
					this.inputStatus = false;
				}
			} else {
				this.searchStatus = false;
			}
		},
		value(event) {
			if (event != null) {
				this.searchStatus = true;
			} else {
				this.searchStatus = false;
			}
		},
	},
	async created() {
		this.initData(true);
	},
	async mounted() {
		this.isEmpty = 'Please Search for Data';
		// this.loading = true;
		this.lazyParams = {
			first: 0,
			sortField: null,
			sortOrder: null,
			rows: this.rows,
			page: 0,
		};
		this.selectLazyParams = {
			first: 0,
			sortField: null,
			sortOrder: null,
			rows: this.rows,
		};
	},
	computed: {},
	methods: {
		handleExport() {
			this.exportPopup = !this.exportPopup
		},
		async initData(init) {
			this.loading = true;
			AuthService.whoAmi().then(async (response) => {
				function userCompany() {
					const secretKey = 'some-unique-key';
					const simpleCrypto = new SimpleCrypto(secretKey);
					return simpleCrypto.decrypt(
						sessionStorage.getItem('dropdownCompany')
					);
				}
				if (response.status === 200) {
					this.userInfo = response.data.data;
					if (this.userInfo.leveltenant === 2) {
						//get company
						const respCbN = await LovService.getCompanyByName(
							userCompany()
						);
						this.company = respCbN.data.data[0];

						//get supplier
						const param = {
							db_instance: this.company.db_instance,
							org_id: this.company.value,
						};
						const respVendor = await LovService.getSuppBindComp(
							response.data.data.id
						);
						// const vendorName = respVendor.data.data[0][0].vendorname;
						const vendorId = respVendor.data.data[0].vendor_id;
						console.log('cek vendor id', vendorId);
						const respSuppName = await LovService.getSupplierName(
							param
						);
						if (respSuppName.status === 200) {
							const suppList = respSuppName.data.data;
							for (const s of suppList) {
								if (s.vendor_id === vendorId) {
									this.supplier = s;
									console.log(
										'cel supplier list',
										this.supplier
									);
									break;
								}
							}
							if (this.supplier === null) {
								this.$router.push('/dashboard');
								window.alert(
									'Connection Unstable, ID not Recognize'
								);
							}
						}
					} else {
						const respCbN = await LovService.getCompanyByName(
							userCompany()
						);
						this.company = respCbN.data.data[0];
						// const response = await LovService.getcompanydb(this.userInfo.bioaddress);
						// const companies = response.data.data;
						// for (const c of companies) {
						//   if (parseInt(c.value) === this.userInfo.idsubtenant) {
						//     this.company = c;
						//     break;
						//   }
						// }
					}
				}
				if (this.userInfo.leveltenant === 2) {
					await this.searchTableNested(init, this.lazyParams);
				} else {
					await this.searchTableNested(init, this.lazyParams);
					// this.GetTableNested();
				}
			});
		},
		formatCurrency(value) {
			if (value) return value.toLocaleString('id-ID');
			return;
		},
		exportData() {
			if (this.isEmpty !== 'No Data Found') {
				this.buttonDisable = true;
				AuthService.whoAmi().then((value) => {
					this.disabledExport = true;
					if (value.status === 200) {
						setTimeout(() => {
							this.searchColumnexport = this.searchColumn
								? this.searchColumn.value
								: null;
							this.valueExport = this.value;
							const payload = {
								column: this.searchColumn
									? this.searchColumn.value
									: null,
								value: this.value,
								company: this.company
									? this.company.value
									: null,
								dbInstance: this.company
									? this.company.db_instance
									: null,
								supplier: this.supplier
									? this.supplier.vendor_id
									: null,
								fromDate: this.fromDate,
								toDate: this.toDate
							};
							// console.log('payload GO ', payload);
							OutstandingPo.exportData(payload).then(
								(respond) => {
									console.log('cek respon', respond);
									let today = new Date();
									let dd = String(today.getDate()).padStart(
										2,
										'0'
									);
									let mm = String(
										today.getMonth() + 1
									).padStart(2, '0'); //January is 0!
									let yyyy = today.getFullYear();
									let combinedDate = dd + mm + yyyy;
									let filename =
										'Outstanding PO Report _' +
										combinedDate +
										'.xlsx';
									let data = this.getExportData(
										respond.data.exportDetail
									);
									console.log('respond data ', data);
									let ws = XLSX.utils.json_to_sheet(data);
									let wb = XLSX.utils.book_new();
									XLSX.utils.book_append_sheet(
										wb,
										ws,
										'Outstanding PO Report'
									);
									XLSX.writeFile(wb, filename);
									this.disabledExport = false;
								}
							);
						}, 250);
					}
				});
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Data is Empty',
					life: 3000,
				});
				this.loading = false;
			}
		},
		getExportData(exportData) {
			let data = [];
			exportData.forEach((element) => {
				element.forEach((dataDetail) => {
					data.push(dataDetail);
				});
			});
			return data;
		},
		async clearSearch() {
			this.searchColumn = null;
			this.value = null;
			this.searchColumnexport = null;
			this.valueExport = null;

			if (this.userInfo.leveltenant !== 2) {
				this.supplier = null;
				this.GetTableNested();
			} else {
				await this.initData(false);
			}
			this.isEmpty = null;
		},
		replaceString(value) {
			return value.replace(new RegExp('_', 'g'), ' ');
		},
		searchColumnField(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredColumn = [...this.searchList];
				} else {
					this.filteredColumn = this.searchList.filter((company) => {
						if (company.name !== null) {
							return company.name
								.toLowerCase()
								.match(event.query.toLowerCase());
						}
					});
				}
			}, 250);
		},

		GetTableNested() {
			this.rows = 10;
			this.loading = true;
			// AuthService.whoAmi().then((value) => {
			//   if (value.status === 200) {
			OutstandingPo.allnestedtable({
				lazyEvent: JSON.stringify(this.lazyParams),
			}).then((response) => {
				this.nested = response.data.header;
				this.totalRecords = response.data.totalRecords;
				this.loading = false;
			});

			// }
			// });
		},
		async goSearchNestedtable() {
			if (!this.company) {
				this.$toast.add({
					severity: 'error',
					summary: 'Please select company',
					life: 3000,
				});
				return;
			}
			if (
				(this.searchColumn !== null && this.value === null) ||
				(this.searchColumn === null && this.value !== null)
			) {
				this.$toast.add({
					severity: 'error',
					summary: 'Please Select & Fill Search',
					life: 3000,
				});
				this.loading = false;
			} else {
				await this.searchTableNested(false, this.selectLazyParams);
			}
		},

		async searchTableNested(init, event) {
			this.expandedRows = null;
			this.rows = 10;
			this.button = 1;
			this.loading = true;

			this.searchColumnexport = this.searchColumn
				? this.searchColumn.value
				: null;
			this.valueExport = this.value;
			const payload = {
				column: this.searchColumn ? this.searchColumn.value : null,
				value: this.value,
				company: this.company ? this.company.value : null,
				dbInstance: this.company ? this.company.db_instance : null,
				supplier: this.supplier ? this.supplier.vendor_id : null,
			};
			// console.log('payload GO ', payload);
			this.buttonDisable = true;
			// AuthService.whoAmi().then((value) => {
			//     if (value.status === 200) {
			setTimeout(() => {
				console.log(
					'ISI UDAH CARI',
					JSON.stringify(this.selectLazyParams)
				);
				OutstandingPo.getSearchTableNested({
					lazyEvent: JSON.stringify(event),
					payload: JSON.stringify(payload),
				}).then((respond) => {
					// console.log('isi response', respond.data);
					if (respond.data.status === 200) {
						// console.log("detail value ", respond.data.header)
						this.nested = respond.data.resultAll;
						this.totalRecords = respond.data.totalRecords;
						this.loading = false;
						this.buttonDisable = false;
						if (init) {
							this.searchList.push({
								name: 'PO_NUMBER',
								value: 'SEGMENT1',
							});
							for (let id of this.nested) {
								// console.log("id", id.children)
								for (const idx in id.children[0]) {
									// console.log("idx", idx)
									if (idx !== 'po_key' && idx !== 'id')
										this.searchList.push({
											name: this.replaceString(idx),
											value: idx,
										});
								}
								break;
							}
						}
					} else {
						this.nested = null;
						this.totalRecords = null;
						this.isEmpty = 'No Data Found';
						this.loading = false;
						this.buttonDisable = false;
					}
				});
			}, Math.random() * 1000 + 250);
		},
		onSort(event) {
			this.selectLazyParams = event;
			this.searchTableNested(false, event);
		},
		onPage(event) {
			//nggak cari apa-apa
			// if (this.searchColumn != null && this.value != null) {
			this.selectLazyParams = event;

			this.searchTableNested(false, event);
			// } else {
			//   this.selectLazyParams = event;
			//   console.log(
			//       'ISI UDAH CARI else',
			//       JSON.stringify(this.selectLazyParams)
			//   );
			//     this.searchTableNested(false, event);

			// if (this.userInfo.leveltenant === 2) {
			// } else {
			//   this.GetTableNested();
			//   // this.GetTableNested();
			// }

			// }
		},
	},
};
</script>
<style scoped lang="scss">
/*// ::selection{*/
/*//     background-color: rgba(0,0,0,.15) !important;*/
/*// }*/

/*p-selectable-row p-highlight {*/
/*	background-color: rgba(0, 0, 0, 0.15) !important;*/
/*}*/

/*// input{*/
/*//     text-align: right;*/
/*// }*/
</style>
