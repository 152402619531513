export const PoTypeEnum = {
    Procurement: 'PO Service Procurement',
    Prepayment: 'PO Prepayment',
    Standart: 'PO Standard',
}

export const PICEnum = {
    Prepayment: "Prepayment Before Save",
    DetailPrepayment: "Prepayment After Save",
    Standard: "Standard Before Save",
    DetailStandard: "Standard After Save",
    Procurement: "Procurement Before Save",
    DetailProcurement: "Procurement After Save"
}

export const InvoiceTypeEnum = {
    Standard: 'Invoice Standard',
    Prepayment: 'Invoice Prepayment',
    Procurement: 'Invoice Service Procurement',
    StandardProxy: 'Invoice Standard Proxy',
    PrepaymentProxy: 'Invoice Prepayment Proxy',
    ProcurementProxy: 'Invoice Procurement Proxy'
}

export const RateTypeEnum = {
    TaxRate: "Tax Rate",
    User: "User",
    Thousand: "1000"
}
