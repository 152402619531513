<template>
  <!-- eslint-disable -->
  <Toast />
  <div class="grid">
    <ProgressModal :progress="progress">
      <template #message> Create Nota Debit in progress. Please wait... </template>
    </ProgressModal>
    <div class="col-12">
      <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
    </div>
    <!-- Search -->
    <div class="col-12 mb-4">
      <div class="content-wrapper">
          <div class="content-header">Nota Debit</div>
              <div class="content-body">
                  <div class="col-12 p-fluid">
                      <div class="grid formgrid" style="margin: 1em 0 0 0">
                          <div class="col-12 mb-5 lg:col-4">
                              <CompaniesDropdown
                                  v-model="company"
                                  disabled 
                                  class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
                              />
                          </div>
                          <div class="col-12 mb-5 lg:col-4">
                              <SupplierDropdown
                                  v-model="supplier"
                                  :company="company ? company : null"
                                  :key="supplierReload"
                                  class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
                              />
                          </div>
                          <div class="col-12 mb-5 lg:col-4">
                              <span class="p-float-label">
                                  <DebitNote
                                      @remitdebitNote="getRemitDebitNote"
                                      :debitNoteId="noteDebitDrop?noteDebitDrop:null"
                                      :company="company ? company : null"
                                      :supplier="supplier ? supplier : null"
                                      :key="noteDebitReload"
                                      :disabled = "!supplier"
                                      class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
                                  />
                                  <label>Debit Note</label>
                              </span>
                          </div>
                          <div class="mr-3 ml-2">
                              <Button
                              :disabled="!supplier && !remitDebitNote  "
                                  @click="getData()"
                                  class="p-button-success uppercase"
                                  label="FIND"
                                  />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

  <!-- Search results -->
  <div class="col-12 mb-4">
      <div class="content-wrapper">
          <div class="content-body">
              <div class="grid">
                  <div class="col-12 md:col-12 lg:col-6">
                      <div class="grid">
                          <div class="col-6">
                          <p id="label-key">Supplier</p>
                          </div>
                          <div class="col-6 col-auto ml-auto">
                          <p id="label-value">{{ supplierHeader }}</p>
                          </div>
                      </div>

                      <div class="grid">
                          <div class="col-6">
                          <p id="label-key">Debit Note To</p>
                          </div>
                          <div class="col-6 col-auto ml-auto">
                          <p id="label-value">{{ debitNoteToHeader }}</p>
                          </div>
                      </div>

                      <div class="grid">
                          <div class="col-6">
                          <p id="label-key">Debit Note Date</p>
                          </div>
                          <div class="col-6 col-auto ml-auto">
                          <p id="label-value">{{ debitNoteDateHeader }}</p>
                          </div>
                      </div>

                      <div class="grid">
                          <div class="col-6">
                          <p id="label-key">Ex Tax No</p>
                          </div>
                          <div class="col-6 col-auto ml-auto">
                          <p id="label-value">{{ exTaxNoHeader }}</p>
                          </div>
                      </div>

                      <div class="grid">
                          <div class="col-6">
                          <p id="label-key">Ex Tax Date</p>
                          </div>
                          <div class="col-6 col-auto ml-auto">
                          <p id="label-value">{{ exTaxDateHeader }}</p>
                          </div>
                      </div>
                  </div>

                  <div class="col-12 md:col-6">             
                      <div class="grid formgrid" style="margin-top:10px">
                          <div class="col-12 mb-0 lg:col-12 lg:mb-0">
                              <div class="form-card">
                                  <div class="flex align-items-center justify-content-between mb-4">
                                      <span style="color: #000" class="font-semibold"
                                      >Total DPP</span
                                      >
                                      <span class="font-bold">
                                          {{
                                              Number.parseFloat(summary.invoiceAmmount)
                                              .toFixed(2)
                                              .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ','
                                              )
                                                  }}
                                      </span>
                                  </div>
                                      <div class="flex align-items-center justify-content-between mb-4">
                                          <span style="color: #000" class="font-semibold"
                                          >PPn</span
                                          >
                                          <span class="font-bold">
                                              {{
                                                  Number.parseFloat(summary.ppn)
                                                  .toFixed(2)
                                                  .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ','
                                                  )
                                                      }}
                                          </span>
                                      </div>
                                  <div class="flex align-items-center justify-content-between mb-4">
                                      <span style="color: #000" class="font-semibold"
                                      >Total Amount</span
                                      >
                                      <span class="font-bold">
                                          {{
                                              Number.parseFloat(summary.totalInvoices)
                                              .toFixed(2)
                                              .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ','
                                              )
                                                  }}
                                      </span>
                                  </div>
                               </div>
                               <div>
                                  <div class="col-6">
                                    <p id="label-key">Attach Nota Debit</p>
                                  </div>
                                  <FileUpload 
                                    ref="fileUploadDebit"
                                    mode="advanced"
                                    chooseLabel="Upload File"
                                    fileLimit="1"
                                    accept="application/pdf"
                                    :maxFileSize="5000000"
                                    :showUploadButton="false"
                                    :showCancelButton="false"
                                    :customUpload="true"
                                    @select="onSelectFile"
                                    @remove="onRemoveFile"
                                  >
                                  <template #empty>
                                    <p>No file selected. (Max file 5MB)</p>
                                  </template>
                                </FileUpload>
                              </div>

                              <div>
                                  <div class="col-6">
                                    <p id="label-key">Attach Nota Retur</p>
                                  </div>
                                  <FileUpload 
                                    ref="fileUpload"
                                    mode="advanced"
                                    chooseLabel="Upload File"
                                    fileLimit="1"
                                    accept="application/pdf"
                                    :maxFileSize="5000000"
                                    :showUploadButton="false"
                                    :showCancelButton="false"
                                    :customUpload="true"
                                    @select="onSelectFileRetur"
                                    @remove="onRemoveFileRetur"
                                  >
                                  <template #empty>
                                    <p>No file selected. (Max file 5MB)</p>
                                  </template>
                                </FileUpload>
                              </div>
                          </div>
                      </div>                     
                  </div>
              </div>
              <div class="col-12 mb-6">
                <TableNotaDebit
                  :column="column ? column.col : null"
                  :company="company ? company : null"
                  :supplier="supplier ? supplier : null"
                  :debitNoteNo="remitDebitNote?remitDebitNote:null"
                  :dataTable="createNotaDebit"
                  :getNewSupplier = "newSupplier ? newSupplier : null"
                  :getNewDebitNo = "newDebitNo ? newDebitNo : null"
                  @createNotaDebit="getDataTable"
                  :key="reloadTableDetail" />

              </div>  
              <div class="grid">
                <div class="mr-4">
                  <Button
                        :disabled="status === 'BERHASIL'"
                        class="p-button-success justify-content-center"
                        @click="handleCreate"
                  >
                        <span class="font-bold uppercase">Create</span>
                  </Button>
                </div>

                <div >
                      <Button
                          class="p-button-secondary justify-content-center"
                          @click="handleReset"
                      >
                          <span class="font-bold uppercase">Reset</span>
                  </Button>
                </div>
              </div>
          </div>
      </div>
  </div>
</div>
</template>

<script setup>
import { onMounted, ref, watch, computed} from "vue";
import AuthService from "../../service/AuthService";
import LovService from "../../service/LovService";
import SimpleCrypto from "simple-crypto-js";
import CompaniesDropdown from "@/components/components/CompaniesDropdown";
import SupplierDropdown from "./components/SupplierDropdown.vue";
import DebitNote from "./components/DebitNote.vue";
import TableNotaDebit from "./components/TableNotaDebit.vue"
import CreateDebitNote from "../../service/DebitNoteService";
import { useToast } from 'primevue/usetoast';
import ProgressModal from "../../components/components/ProgressModal.vue";






const userInfo = ref();
const company = ref();
const supplier = ref();
const supplierHeader = ref();
const debitNoteToHeader = ref();
const debitNoteDateHeader = ref();
const exTaxNoHeader = ref();
const exTaxDateHeader = ref();
const invoiceAmount = ref('0');
const totalPPN = ref('0');
const totalInvoice = ref('0');
const supplierReload = ref(0);
const noteDebitReload = ref(0);
const reloadTableDetail = ref(false);
const remitDebitNote = ref();
const createNotaDebit = ref([])
const idUser = ref(null);
const dn_date = ref();
const revision_num = ref();
const description = ref();
const currencyCode = ref();
const taxAmount = ref();
const totalAmount = ref();
const attribute = ref();
const invoiceId = ref();
const toast = useToast();
const file = ref(null);
const fileRetur = ref(null);
const fileUpload = ref(null);
const fileUploadDebit = ref(null);
const progress = ref(false);
const status = ref();
const newSupplier = ref()
const newDebitNo  =ref()



const breadcrumbHome = { icon: "pi pi-home", to: "/dashboard" };
const breadcrumbItems = [
  {
    label: "Nota Debit",
    to: "/mgm/monitor/eventlog",
  },
];



watch(
() => company.value,
() => {
  // supplier.value = null;
  supplierHeader.value = null;
  debitNoteToHeader.value = null;
  debitNoteDateHeader.value = null;
  exTaxNoHeader.value = null;
  exTaxDateHeader.value = null;
  invoiceAmount.value = 0;
  totalPPN.value = 0;
  totalInvoice.value = 0;
  supplierReload.value++;
  noteDebitReload.value++;
}
);

watch(
() => supplier.value,
(newValue) => {
    if(typeof newValue === 'object' && !Array.isArray(newValue)) {
      newSupplier.value = newValue
      supplierHeader.value = null;
      debitNoteToHeader.value = null;
      debitNoteDateHeader.value = null;
      exTaxNoHeader.value = null;
      exTaxDateHeader.value = null;
      file.value = null;
      fileUploadDebit.value.clear();
      fileRetur.value = null;
      fileUpload.value.clear();
      invoiceAmount.value = 0;
      totalPPN.value = 0;
      totalInvoice.value = 0;
      noteDebitReload.value++;
      createNotaDebit.value = []
     
      } else {
      newSupplier.value = null
      supplierHeader.value = null;
      debitNoteToHeader.value = null;
      debitNoteDateHeader.value = null;
      exTaxNoHeader.value = null;
      exTaxDateHeader.value = null;
      file.value = null;
      fileUploadDebit.value.clear();
      fileRetur.value = null;
      fileUpload.value.clear();
      invoiceAmount.value = 0;
      totalPPN.value = 0;
      totalInvoice.value = 0;
      noteDebitReload.value++;
      createNotaDebit.value = []
      }
    } 
);

watch(
() => remitDebitNote.value,
(newValue) => {
    if(typeof newValue === 'object' && !Array.isArray(newValue)) {
      newDebitNo.value = newValue
      supplierHeader.value = null;
      debitNoteToHeader.value = null;
      debitNoteDateHeader.value = null;
      exTaxNoHeader.value = null;
      exTaxDateHeader.value = null;
      file.value = null;
      fileUploadDebit.value.clear();
      fileRetur.value = null;
      fileUpload.value.clear();
      invoiceAmount.value = 0;
      totalPPN.value = 0;
      totalInvoice.value = 0;
      createNotaDebit.value = [];
      getDataTable.value = null
        
      } else {
      newSupplier.value = null
      supplierHeader.value = null;
      debitNoteToHeader.value = null;
      debitNoteDateHeader.value = null;
      exTaxNoHeader.value = null;
      exTaxDateHeader.value = null;
      file.value = null;
      fileUploadDebit.value.clear();
      fileRetur.value = null;
      fileUpload.value.clear();
      invoiceAmount.value = 0;
      totalPPN.value = 0;
      totalInvoice.value = 0;
      createNotaDebit.value = [];
      getDataTable.value = null
     
      }
    } 
);

onMounted(() => {
  AuthService.whoAmi().then(async (response) => {
    if (response.status === 200) {
      idUser.value = response.data.data.id
      
      userInfo.value = response.data.data;
      const respCbN = await LovService.getCompanyByName(userCompany());
      company.value = respCbN.data.data[0];

      await Promise.all([getData()]);

    }
  });
});



const userCompany = () => {
  const secretKey = "some-unique-key";
  const simpleCrypto = new SimpleCrypto(secretKey);
  return simpleCrypto.decrypt(sessionStorage.getItem("dropdownCompany"));
};

const getDataTable = (value) => {
  createNotaDebit.value = value.value
}

const getRemitDebitNote = (value) => {
  remitDebitNote.value = value.value;

}
const getData = async () => {
if(remitDebitNote.value === null) {
  toast.add({
  severity: "info",
  summary: `Please select Nota debit`,
  life: 3000,
});

}
try {
  const payload = {
    org_id: company.value ? company.value.value : null,
    supValue: supplier.value ? supplier.value.vendor_id : null,
    debit_note: remitDebitNote.value.INVOICE_ID,
    db_instance: company.value.db_instance
  };

  const resultData = await CreateDebitNote.getHeader(payload);
  // const result = resultData.data;
  // invoiceData.value = result[0];
  const type = typeof resultData;
  if (type === "object") {
    supplierHeader.value = resultData.data.data.data[0].vendor_name
    debitNoteToHeader.value = resultData.data.data.data[0].DN_NO
    debitNoteDateHeader.value = dateFormat(resultData.data.data.data[0].DN_DATE)
    exTaxNoHeader.value = resultData.data.data.data[0].EXTAX_NO
    exTaxDateHeader.value = dateFormat(resultData.data.data.data[0].EXTAX_DATE)
    invoiceAmount.value = resultData.data.data.data[0].INVOICE_AMOUNT
    totalPPN.value = resultData.data.data.data[0].TAX_AMOUNT
    totalInvoice.value = resultData.data.data.data[0].TOTAL_AMOUNT
    dn_date.value = resultData.data.data.data[0].DN_DATE
    revision_num.value = resultData.data.data.data[0].REVISION_NUM
    description.value = resultData.data.data.data[0].DESCRIPTION
    currencyCode.value = resultData.data.data.data[0].CURRENCY_CODE
    taxAmount.value = resultData.data.data.data[0].TAX_AMOUNT
    totalAmount.value = resultData.data.data.data[0].TOTAL_AMOUNT
    attribute.value = resultData.data.data.data[0].ATTRIBUTE1
    invoiceId.value = resultData.data.data.data[0].INVOICE_ID
  } 
  reloadTableDetail.value++;
} catch (error) {
  throw new Error(error);
}
};

const summary  = computed(() => {
  const invoiceAmmount =
    (createNotaDebit.value.length &&
    createNotaDebit.value.reduce((accumulator, obj) => {
        return accumulator + obj.AMOUNT;
      }, 0)) ||
    0;
  const ppn = totalPPN.value;
  const totalInvoices = invoiceAmmount + ppn
  return {
    invoiceAmmount,
    ppn,
    totalInvoices
  }
})

// Select file attachment
const onSelectFile = (e) => {
	file.value = e.files[0];

};
// Remove file attachment
const onRemoveFile = (e) => {
	if (!e.files.length) {
		file.value = null;
	}
};

const onSelectFileRetur = (e) => {
	fileRetur.value = e.files[0];
  
  // 
};

// Remove file attachment
const onRemoveFileRetur = (e) => {
	if (!e.files.length) {
		fileRetur.value = null;
	}
};

const handleCreate = async () => {
    if (!fileRetur.value || !file.value ) {
      if(!fileRetur.value) {
        toast.add({
        severity: "error",
        summary:  "Please Upload Retur File",
        life: 3000,
      });
      return
      }
      if(!file.value) {
      toast.add({
        severity: "error",
        summary: "Please Upload Debit File",
        life: 3000,
      });
      return
    }

  } 
    
  // Add file payload
	const formData = new FormData();
	formData.append('file', file.value);
  formData.append('fileRetur', fileRetur.value);

  const payload = {
    header: {
      DN_NO : remitDebitNote.value.DN_NO,
      DN_DATE :formattingDate(dn_date.value),
      REVISION_NUM : revision_num.value,
      DESCRIPTION : description.value ? description.value : '',
      CURRENCY_CODE : currencyCode.value,
      INVOICE_AMOUNT : summary.value.invoiceAmmount,
      TAX_AMOUNT : taxAmount.value,
      TOTAL_AMOUNT : summary.value.totalInvoices,
      ATTRIBUTE1 : attribute.value,
      EXTAX_NO:  exTaxNoHeader.value,
      EXTAX_DATE: formattingDate(exTaxDateHeader.value),
      ORG_ID: company.value.value,
      CREATION_DATE: formattingDate(new Date(), true),
      LAST_UPDATE_DATE: formattingDate(new Date(), true),
      CREATED_BY:idUser.value,
      LAST_UPDATED_BY:idUser.value,
      VENDOR_ID : supplier.value.vendor_id,
      INVOICE_ID : invoiceId.value,
      DB_INSTANCE : company.value.db_instance,

    },
    payloadEmail : {
      module: "createnotadebit",
      idmodule: 2,
      sbjmessage: "Create Nota Debit # " +  remitDebitNote.value.DN_NO + " from ",
      // notif_type: "Supplier",
      usersenderid: userInfo.value.id,
      sender: userInfo.value.bioemailactive,
      dbInstance: supplier.value.db_instance,
      companyname: company.value.name,
      vendorname: supplier.value.vendor_name,
    },
      

    details: [],
  }
  const details = createNotaDebit.value.map((item) => {
                return {
                  PO_HEADER_ID: item.PO_HEADER_ID,
                  PO_LINE_ID : item.PO_LINE_ID,
                  PO_NUMBER:item.PO_NUMBER,
                  LINE_NUM: item.LINE_NUM,
                  ITEM_ID: item.ITEM_ID,
                  ITEM_NUMBER: item.ITEM_NUMBER,
                  ITEM_DESCRIPTION:item.ITEM_DESCRIPTION,
                  QUANTITY:item.QUANTITY,
                  UNIT_MEAS_LOOKUP_CODE : item.UNIT_MEAS_LOOKUP_CODE,
                  UNIT_PRICE : item.UNIT_PRICE,
                  AMOUNT : item.AMOUNT,
                  NOTE_TO_VENDOR : item.NOTE_TO_VENDOR ? item.NOTE_TO_VENDOR : '',  
                  ORGANIZATION_ID: item.ORGANIZATION_ID,
                  ATTRIBUTE1 : item.ATTRIBUTE1,
                  CURRENCY_CODE : item.CURRENCY_CODE,
                  REVISION_NUM : item.REVISION_NUM,
                  VENDOR_ID : item.VENDOR_ID,
                  VENDOR_SITE_ID : item.VENDOR_SITE_ID,
                  CREATION_DATE: formattingDate(new Date(), true),
                  CREATED_BY:idUser.value,
                  LAST_UPDATE_DATE: formattingDate(new Date(), true),
                  LAST_UPDATED_BY:idUser.value,
                  INVOICE_ID : item.INVOICE_ID,
                  DB_INSTANCE : company.value.db_instance,
                };  
              });
              payload.details = details;
              

           
              // Wrap payload with formData
              const jsonPayload = JSON.stringify(payload);
              formData.append('payload', jsonPayload);

            try {
              progress.value = true
                const res = await CreateDebitNote.create(formData);
               console.log('res', res);
                if (res.status === 200) {
                  progress.value = false
                  status.value = 'BERHASIL'
                  toast.add({
                    severity: "success",
                    summary: `Successfully create debit number`,
                    life: 3000,
                  });
                  
                }
              } catch (error) {
                console.log('cek response', error)
                progress.value = false
                if (error.message.includes('Network Error')) {
                    console.log('network error');
                    toast.add({
                      severity: 'error',
                      summary: 'Network Error',
                      detail: 'Connection Down. Please check your nota debit  on List Nota Debit menu.',
                    });
                    return;
                  }

                 if (error.message.includes('Error encrypt file. Please remove password from the file')) {
                    console.log('Error encrypt file. Please remove password from the file');
                    toast.add({
                      severity: 'error',
                      summary: 'Error File',
                      detail: 'Error encrypt file. Please remove password from the file.',
                    });
                    return;
                  }

                if (error.response.status === 400) {
                  toast.add({
                    severity: "error",
                    summary: `Debit Number already exist`,
                    life: 5000,
                  });
                } 
                if (error.response.data.message.includes('Error sending email')) {
                   toast.add({
                    severity: "error",
                    summary: `Error sending email`,
                    life: 5000,
                  });
                }
                else {
                  toast.add({
                    severity: "error",
                    summary: "Create Debit Number Error",
                    life: 3000,
                  });
                }
              }

};

const handleReset = () => {
	// Reset form
	supplierHeader.value = null;
  debitNoteToHeader.value = null;
  debitNoteDateHeader.value = null;
  exTaxNoHeader.value = null;
  exTaxDateHeader.value = null;
  // remitDebitNote.value = null;
  supplier.value = null;
  invoiceAmount.value = null;
  totalPPN.value = null;
  totalInvoice.value = null;
  file.value = null;
  fileUploadDebit.value.clear();
  fileRetur.value = null;
  fileUpload.value.clear();
  location.reload ();
 
};


function dateFormat(d) {
  var monthShortNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var t = new Date(d);
  return (
    t.getDate() +
    " " +
    monthShortNames[t.getMonth()] +
    " " +
    t.getFullYear() +
    " "
  );
}
const formattingDate = (date) => {
	const theDate = new Date(date);
	const strDate =
		theDate.getFullYear() +
		'-' +
		(theDate.getMonth() + 1) +
		'-' +
		theDate.getDate();

    return `${strDate}`
	
};


</script>

<style scoped>
#label-key {
font-family: "Roboto";
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 36px;
/* identical to box height, or 257% */

color: #000000;
}

.form-card {
padding: 10px;
  border: 1px solid #e0e0e0;
border-radius: 16px;
}

</style>