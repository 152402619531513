<template>
    <span class="p-float-label">
        <AutoComplete :suggestions="filteredCoA" @complete="searchBatch($event)" :dropdown="true" field="CONCATENATED_SEGMENTS" v-model="chargeNumberValue" forceSelection>
        <template #item="slotProp">
        {{ slotProp.item.CONCATENATED_SEGMENTS }}
        </template>
    </AutoComplete>
        <label for="autocomplete">Charge of Account</label>
    </span>
</template>

<script setup>
import {onMounted, ref, defineEmits, watchEffect} from 'vue'
import AuthService from '../../../service/AuthService';
import InvoiceService from "../../../service/InvoiceSearchWPO"

// eslint-disable-next-line no-undef
const props = defineProps({
  db_instance: {type: Object},
  charge_id: {type: Object},
  org_id: {type: Object},
})

// eslint-disable-next-line no-undef
const filteredCoA = ref([]);
const chargeNumber = ref([]);
const chargeNumberValue = ref()
const emit = defineEmits(['CoA'])

onMounted(() => {
  populateData()
})

watchEffect(() => 
  // 
  emit('CoA',chargeNumberValue)
)

const populateData = () => {
  AuthService.whoAmi().then(async (response) => {
    // 
    const payload = { db_instance : props.db_instance, org_id : props.org_id}
      // 
      if (response.status === 200){
      const data = await InvoiceService.getChargeNumber(payload)
      // 
      chargeNumber.value = data.data.data.data
      // 
      for (const s of chargeNumber.value) {
              if (s.CODE_COMBINATION_ID === props.charge_id) {
                chargeNumberValue.value = s;
                break;
              }
              else{
                chargeNumberValue.value = null;
              }
            }
    }
    
  })
}
  


const searchBatch = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredCoA.value = [...chargeNumber.value]
    } else {
      filteredCoA.value = chargeNumber.value.filter((comp) => {
        return comp.CONCATENATED_SEGMENTS.toLowerCase().match(event.query.toLowerCase());
      })
    }
  }, 250);
}
</script>

<style scoped>

</style>