<template>
	<div class="grid spe-report">
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<!-- Form create/update SPE Report -->
		<FormSpeReport
			v-if="isComponentShow"
			title="Detail SPE Report"
			:id="props.id"
			:currentUser="currentUser"
			:company="company"
			:companyList="companyList"
			:vendorId="vendorId"
		/>
	</div>
</template>

<script setup>
import { onMounted, ref, computed, defineProps } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import SimpleCrypto from 'simple-crypto-js';
import AuthService from '../../service/AuthService';
import LovService from '../../service/LovService';
import PoFulfillment from '../../service/PoFulfillment';
import PlanningOrderService from '../../service/PlanningOrderService';
import FormSpeReport from './components/FormSpeReport.vue';

// Composables
const router = useRouter();
const route = useRoute();
const toast = useToast();

// Props
const props = defineProps(['id']);

// State
const currentUser = ref(null);
const company = ref(null);
const companyList = ref(null);
const vendorId = ref(null);
const breadcrumbHome = ref({ icon: 'pi pi-home', to: '/dashboard' });
const breadcrumbItems = ref([
	{
		label: 'SPE Report',
		to: '/mgm/master/spereport',
	},
	{
		label: 'Detail SPE Report',
		to: route.fullPath,
	},
]);

// Computed for checking if FormSpeReport is show
const isComponentShow = computed(() => {
	if (currentUser.value) {
		if (currentUser.value.leveltenant === 1) {
			return company.value && companyList.value;
		} else {
			return company.value && companyList.value && vendorId.value;
		}
	}

	return false;
});

onMounted(async () => {
	// Check current user
	const resAuth = await AuthService.whoAmi();
	currentUser.value = resAuth.data.data;

	// Check if user is not purchasing  nor supplier nor admin
	if (
		currentUser.value.leveltenant !== 1 &&
		currentUser.value.leveltenant !== 2 &&
		currentUser.value.userid !== 'dexaadmin'
	) {
		// Redirect to homepage if user is purchasing and not admin
		router.push('/dashboard');
		return;
	}

	// Show toast for success submission if any
	const successStatus = sessionStorage.getItem('successSpeReport');
	if (successStatus) {
		if (successStatus === 'submit') {
			toast.add({
				severity: 'success',
				summary: 'Successfully submit new report',
				life: 3000,
			});
			toast.add({
				severity: 'success',
				summary: `Status change to SUBMITTED`,
				life: 3000,
			});
		}
		if (successStatus === 'save') {
			toast.add({
				severity: 'success',
				summary: 'Successfully save new report',
				life: 3000,
			});
			toast.add({
				severity: 'success',
				summary: `Status change to DRAFT`,
				life: 3000,
			});
		}

		sessionStorage.removeItem('successSpeReport');
	}

	if (currentUser.value.leveltenant === 2) {
		// Get company and vendor ID if user is supplier
		const companyRes = getCompany();
		const vendorRes = getVendorId();

		await Promise.all([companyRes, vendorRes]);
	} else {
		// Get company
		await getCompany();
	}
});

// Get company list
const getCompany = async () => {
	if (currentUser.value.userid === 'dexaadmin') {
		// If user is admin
		const companyRes = LovService.getValueType('COMPANY');
		companyList.value = companyRes.data.data;
	} else if (currentUser.value.leveltenant === 2) {
		// If user is supplier
		let payload = currentUser.value.id;
		const companySuppRes = await PoFulfillment.getCompanySupp(payload);
		// console.log('Company supp', companySuppRes);

		try {
			const companySupp = companySuppRes.data.data;
			const companyValues = await Promise.all(
				companySupp.map(async (element) => {
					const res = await LovService.getCompanyByName(
						element.company
					);

					const secretKey = 'some-unique-key';
					const simpleCrypto = new SimpleCrypto(secretKey);
					const decipherText = simpleCrypto.decrypt(
						sessionStorage.getItem('dropdownCompany')
					);

					if (res.data.data[0].name === decipherText) {
						company.value = res.data.data[0];
					}

					return res.data.data[0];
				})
			);

			companyList.value = companyValues;
		} catch (e) {
			console.log(e);
			if (e !== 'Break') throw e;
		}
	} else if (currentUser.value.leveltenant === 1) {
		// If user is Finance
		const res = await LovService.getcompanydb(currentUser.value.bioaddress);

		companyList.value = res.data.data;

		try {
			const secretKey = 'some-unique-key';
			const simpleCrypto = new SimpleCrypto(secretKey);
			const decipherText = simpleCrypto.decrypt(
				sessionStorage.getItem('dropdownCompany')
			);
			companyList.value.forEach((element) => {
				if (element.name === decipherText) {
					company.value = element;
					throw 'Break';
				}
			});
		} catch (e) {
			console.log(e);
			if (e !== 'Break') throw e;
		}
	}
};

// Get vendor id of logged in user
const getVendorId = async () => {
	// Get vendor id
	const resVendorName = await PoFulfillment.getSuppBindComp(
		currentUser.value.id
	);
	// console.log('isi res user', resVendorName.data.data[0][0].vendorname);
	const payloadSuppVal = {
		vendorName: resVendorName.data.data[0][0].vendorname,
	};

	const resVendorId = await PlanningOrderService.getSuppValue(payloadSuppVal);
	vendorId.value = resVendorId.data.data[0].vendor_id;
	// console.log('vendor id', vendorId.value);
};
</script>

<style scoped></style>
