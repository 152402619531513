<template>
    <!-- eslint-disable -->
    <div class="grid">
        <div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
        <div class="col-12">
            <div class="card">
                <div class="surface-section">
                    <div class="font-medium text-2xl text-900 mb-3">Notification Detail</div>

                    <ul class="list-none p-0 m-0">
                        <li class="flex align-items-center py-3 px-2 border-top-0 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Module</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{modulename}}</div>
                            <div class="w-6 md:w-2 flex justify-content-end">
                            </div>
                        </li>
                        <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Sender</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{sender}}
                            </div>
                            <div class="w-6 md:w-2 flex justify-content-end">
                            </div>
                        </li>
                        <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Date</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{receivedDate}}</div>
                            <div class="w-6 md:w-2 flex justify-content-end">
                            </div>
                        </li>
                        <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Recipient</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                {{recipient}}
                            </div>
                            <div class="w-6 md:w-2 flex justify-content-end">
                            </div>
                        </li>
                        <li
                            class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Subject</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                {{subject}}</div>
                            <div class="w-6 md:w-2 flex justify-content-end">
                            </div>
                        </li>
                        <li
                            class="flex align-items-center py-3 px-2 border-top-0 border-bottom-0 surface-border flex-wrap">
                            <div class="text-500 w-6 font-medium">Message</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                <br />Dear Supplier,<br /> Please respond our new CAPA document with details as follow:</div>
                            <div class="w-6 md:w-2 flex justify-content-end">
                            </div>
                        </li>
                        <li
                            class="flex justify-content py-3 px-2 border-top-0 border-bottom-0 surface-border flex-wrap">
                            <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1 line-height-3">
                                <strong>Document No :</strong> {{docNum}}<br />
                                <strong>DR/Audit Date :</strong> {{auditDate}}<br />
                                <strong>DR/Audit File :</strong> {{auditFile}}<br />
                                <strong>Subject :</strong> {{finding}}</div>
                            <!-- <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1 line-height-3">
                                <strong>Response Date :</strong> 9 Feb 2022<br />
                                <strong>Response File :</strong> 2201119.pdf<br />
                                <strong>Response :</strong> test update dari supplier </div> -->
                        </li>
                        <li class="flex align-items-center py-3 px-2 border-top-0 border-bottom-0 surface-border flex-wrap">
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                <br />To respond this CAPA, please visit <router-link :to="'/mgm/master/capa/editcapasupp/' + capaId" class="text-blue-500">here</router-link>.<br /><br />
                                Regards,<br />
                                Purchasing Tean</div>
                            <div class="w-6 md:w-2 flex justify-content-end">
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <router-link to="/dashboard"><Button label="Return" class="mr-2 mb-2"/></router-link>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import NotificationService from '../../../service/NotificationService';
export default {
 data() {
     return {
         breadcrumbHome: {icon: 'pi pi-home', to: '/dashboard'},
		 breadcrumbItems: [
		 	{label:'Dashboard', to: '/dashboard'},
		 	{label:'Notification Detail'}
		 ],

         docNum: null,
         auditDate: null,
         auditFile: null,
         finding: null,
         modulename: this.$route.params.nameModule,
         sender: null,
         receivedDate: null,
         recipient: null,
         subject: this.$route.params.subjectMessage,
         capaId: null,
       }
    },

	async created() {
		let getDocNum = this.subject.substring(0,this.subject.indexOf('from'));
	    this.docNum = getDocNum.replace('New CAPA #', '').replace(/ /g,'')
	    this.vendorname = this.docNum
        let getCompName = (this.subject.substring(this.subject.indexOf('from'))).replace(' ','').replace('from','');
        sessionStorage.setItem('mailToCompany', getCompName)
	},
	async mounted() {
        const resp = await NotificationService.getNotifDetailPlannoSender({
	        something: JSON.stringify(this.$route.params.subjectMessage)
	    })
        if (resp.status == 200) {
            this.sender = resp.data.data[0][0].sender
            this.recipient = resp.data.data[0][0].recipient
            this.receivedDate = resp.data.data[0][0].receiveddate
            const response = await NotificationService.getNotifDetailCAPA(this.docNum)
            if (response.status == 200) {
                this.capaId = response.data.data[0].capa_id
                let srcAuditDate = new Date(response.data.data[0].finding_date)
                this.auditDate = (srcAuditDate.getDate() + '-' + srcAuditDate.toString().substring(4, 7) + '-' + srcAuditDate.getFullYear()).toUpperCase()
                this.auditFile = response.data.data[0].so_filename
                this.finding = response.data.data[0].finding
            }
        }
	},
}
</script>
<style scoped>

</style>