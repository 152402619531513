import SpbSupManApi from '@/service/api/SpbSupManApi';
import spbSupManApi from '@/service/api/SpbSupManApi';
import { encryptForm } from '../helper/encryptForm';
// import SpbSupManApiFile from "@/service/api/SpbSupManApiFile";

const getDONumbers = async () => {
	const result = await SpbSupManApi.get('/capa/donumbers');
	if (result.status === 200) {
		return result.data;
	} else {
		alert('Unable Getting Data');
	}
};

const getPONumbers = async (dbInstance, orgId, vendorId) => {
	const result = await SpbSupManApi.get(
		`/capa/ponumbers/${orgId}/${vendorId}/${dbInstance}`
	);
	// console.log(result)
	if (result.status === 200) {
		return result.data;
	} else {
		alert('Unable Getting Data');
	}
};

const getPONumbersByPart = async (dbInstance, orgId, vendorId, part) => {
	const result = await SpbSupManApi.get(
		`/capa/ponumbers/${orgId}/${vendorId}/${dbInstance}/${part}`
	);
	if (result.status === 200) {
		return result.data;
	} else {
		alert('Unable Getting Data');
	}
};

const getDONumbersByPart = async (part) => {
	const result = await SpbSupManApi.get(`/capa/donumbers/${part}`);
	if (result.status === 200) {
		return result.data;
	} else {
		alert('Unable Getting Data');
	}
};

const createCAPA = async (data) => {
	const result = await spbSupManApi.post('/capa/create-capa', data);
	if (result.status === 201) {
		return result.data;
	} else if (result.status === 202) {
		return 'error pdf';
	} else {
		return 'Unable to Create CAPA';
	}
};

const getCAPA = async (id) => {
	const result = await spbSupManApi.get(`/capa/${id}`);
	if (result.status === 200) {
		return result.data;
	} else {
		return 'Unable Getting Data';
	}
};

const getSOfile = async (id, leveltenant) => {
	const result = await spbSupManApi.get(
		`/capa/getsofile/${id}/${leveltenant}`
	);
	if (result.status === 200) {
		return result.data;
	} else {
		return 'Unable Getting Data';
	}
};

const getSPfile = async (id, leveltenant) => {
	const result = await spbSupManApi.get(
		`/capa/getspfile/${id}/${leveltenant}`
	);
	if (result.status === 200) {
		return result.data;
	} else {
		return 'Unable Getting Data';
	}
};

const updateCAPA = async (id, data) => {
	const result = await spbSupManApi.patch(`/capa/${id}`, data);
	if (result.status === 200) {
		return result.data;
	} else if (result.status === 204) {
		result.data = 'Fail to Encrypt';
		return result.data;
	} else if (result.status === 205) {
		result.data = 'Send Email Error';
		return result.data;
	} else {
		return 'Unable Updating Data';
	}
};

const cancelCAPA = async (id, data) => {
	const result = await spbSupManApi.patch(`/capa/${id}`, data);
	if (result.status === 200) {
		return 'CAPA is Cancelled';
	} else {
		return 'Unable to Cancel CAPA';
	}
};

const getRemarks = async (id) => {
	const result = await spbSupManApi.get(`/capa/remark/${id}`);
	if (result.status === 200) {
		return result.data;
	} else {
		return 'Unable Updating Data';
	}
};

const saveRemark = async (capaId, userId, remark) => {
	const data = {
		capaId: capaId,
		userId: userId,
		remark: remark,
	};
	const result = await spbSupManApi.post('/capa/remark', data);
	if (result.status === 200) {
		return result.data;
	} else {
		return 'Unable to save remark';
	}
};

const getCAPASupp = async (id, vendorId) => {
	const result = await spbSupManApi.get(
		`/capa/detailCapaSupp?id=${id}&vendorId=${vendorId}`
	);
	if (result.status === 200) {
		return result.data;
	} else {
		return 'Unable Getting Data';
	}
};

const searchlistcapa = async (
	page,
	limitPage,
	sortField,
	sortOrder,
	company,
	dbInstance,
	supplier,
	documentNumber,
	status,
	preparer
) => {
	 const result = await SpbSupManApi.post('/capa/', encryptForm({  
		page: page,  
		limitPage: limitPage,  
		sortField: sortField,  
		sortOrder: sortOrder,  
		company: company,  
		dbInstance: dbInstance,  
		supplier: supplier,  
		documentNumber: documentNumber,  
		status: status,  
		preparer: preparer  
	}));  
	if (result.status === 200) {
		return result;
	} else {
		return 'Unable to Search Data';
	}
};

// const getMessage = async (payload) => {
// 	return spbSupManApi.post(`/capa/tesForum`, payload);
// };

const sendMessage = async (payload) => {
	return spbSupManApi.post(`/capa/tesupdate forumForum`, payload);
};

export {
	getDONumbers,
	getDONumbersByPart,
	createCAPA,
	getCAPA,
	updateCAPA,
	searchlistcapa,
	cancelCAPA,
	getPONumbers,
	getPONumbersByPart,
	getRemarks,
	saveRemark,
	// getMessage,
	sendMessage,
	getCAPASupp,
	getSOfile,
	getSPfile,
};
