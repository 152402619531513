<template>
    <AutoComplete :suggestions="filteredPOs" @complete="searchPOs($event)" :dropdown="true" field="segment1"
                  :placeholder="placeholder" forceSelection>
      <template #item="slotProp">
        {{ slotProp.item.segment1 }}
      </template>
    </AutoComplete>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {getPONumbers, getPONumbersByPart} from '@/service/CAPAServices';

// eslint-disable-next-line no-undef
const props = defineProps({
  company: {
    type: Object
  },
  supplier: {
    type: Object
  }
})

const filteredPOs = ref([]);
const poNumbers = ref([]);
const placeholder = ref('Search PO Number');

onMounted(async() => {
  if (props.company !== undefined && props.supplier !== undefined)
    await populate()
})

const populate = async () => {
  poNumbers.value = await getPONumbers(props.company.db_instance, props.company.value, props.supplier.vendor_id)
  poNumbers.value = [{ segment1: '--first 10 rows--'}, ...poNumbers.value]
}

const searchPOs = async (event) => {
  setTimeout(async () => {
    if (!event.query.trim().length) {
      filteredPOs.value = [...poNumbers.value]
    } else {
      poNumbers.value = await getPONumbersByPart(props.company.db_instance, props.company.value, props.supplier.vendor_id, event.query.toLowerCase());
      filteredPOs.value = poNumbers.value;
    }
  }, 500);
}


</script>

<style scoped>

</style>