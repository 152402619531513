<template>
    <div>
    <DataTable
        :value="tableList"
        :lazy="true"
        :paginator="true"
        :rows="10"
        :loading="loading"
        :totalRecords="totalData"
        @page="onPage($event)"
        @sort="onSort($event)"
        selectionMode="single"
        @rowSelect="onRowSelect"
        :rowsPerPageOptions="[10, 20, 30]"
        responsiveLayout="scroll"
        :sortable="true"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
    >
      <!-- <template #header>
          <div>
          <span class="p-input-icon-left" style="col-12">
              <i class="pi pi-search" />
              <InputText v-model="itemNumber" placeholder="Search Item Number" @keydown.enter="keydown($event)"/>
          </span>
          </div>
      </template> -->
      <template #empty>No History </template>
    <template #loading>Loading data</template>
      <Column header="Seq No">
        <template #body="slotProps">
          {{ slotProps.index + 1 + paging }}
        </template>
      </Column>
      <Column header="Spec No" field="SPEC_SRC_ID">
      </Column>
      <Column header="Spec Ver" field="spec_ver">
      </Column>
      <Column header="Item Number" field="item_number">
      </Column>
      <Column header="Item Description" field="item_desc">
      </Column>
      <Column header="Spec File">
        <template #body="{ data }">
          <Button v-if="!loadingDownload"
            class="button-download font-normal"
            :label="data.SPEC_FILE_NAME"
            icon="pi pi-download"
            @click="handleFileDownload(data)"
          />
          <Button v-if="loadingDownload"
            class="button-download font-normal"
            :label="`${data.SPEC_FILE_NAME} is downloading`"
            disabled
          />
        </template>
      </Column>
      <Column header="Confirm Spec File">
        <template #body="{ data }">
          <div v-if="data.CONF_SPEC_FILE_NAME !== null">
            <Button v-if="!loadingDownload"
            class="button-download font-normal"
            :label="data.CONF_SPEC_FILE_NAME"
            icon="pi pi-download"
            @click="handleFileDownloadConf(data)"
            />
            <Button v-if="loadingDownload"
              class="button-download font-normal"
              :label="`${data.CONF_SPEC_FILE_NAME} is downloading`"
              disabled
            />
          </div> 
          <div v-if="data.CONF_SPEC_FILE_NAME === null">
            <p>Digital</p>
          </div>         
        </template>
      </Column>
      <Column header="Effective Date" field="effective_date">
      </Column>     
      <Column header="Confirm Date" field="confirm_date">
      </Column>

    </DataTable>
    </div>
</template>

<script setup>
import { onMounted, ref, watch, watchEffect} from 'vue'
import { useToast } from 'primevue/usetoast';
import AuthService from '../../../service/AuthService';
import SpecSrc from '../../../service/SpecSrc';

// eslint-disable-next-line no-undef
const props = defineProps({
    company:{
      type: Object
    },
    vendor_id: {
      type: Object
    },
    item_num:{
      type: Object
    }
});

// eslint-disable-next-line no-undef
const tableList = ref();
const totalData = ref(0);
const page = ref(1);
const paging = ref(0);
const rows = ref(10)
const loading = ref(false);
const loadingFilePur = ref(false)
const soFileBuffer = ref();
const toast = useToast();
const attachmentName = ref(null);
const attachmentNameSupp = ref();
const idValue = ref(0);

watchEffect(() => {
  
})

watch(
  () => props.vendor_id,
  () => {
    if (props.vendor_id !== null) {
      populateData()
    }
  }
);

onMounted(()=>{
    populateData()
})


const populateData = async () => {
  AuthService.whoAmi().then(async (response) => {
    loading.value = true
    if (response.status === 200){
        if (props.vendor_id !== null){
            const payload = {
            db_instance : props.company?props.company.db_instance:null,
            org_id : props.company?props.company.value:null,
            vendor_id : props.vendor_id?props.vendor_id:null,
            item_num : props.item_num?props.item_num:null,
            page : page.value,
            rows : rows.value,
            }
            // console.log('olee',props)
            const res = await SpecSrc.getViewHistory(payload)
            if (res == undefined || res.status === null){
              setTimeout( async () => {
                await populateData()
              }, 1000);
            }
            else if (res.status === 200 ){
              tableList.value = res.data.data
              totalData.value = res.data.totalData[0].totalData
              loading.value = false
              getAttachmentFile()
              getAttachmentFileSupp()
            } else {
              tableList.value = []
              totalData.value = 0
              loading.value = false
            }
            
        }
    } else {
      tableList.value = []
      totalData.value = 0
    }
  })
}

// const keydown = async () => {
//   await populateData()
// }

const onPage = async (event) => {
  rows.value = event.rows
  page.value = event.page + 1
  paging.value = event.page * event.rows
  await populateData()
}

const getAttachmentFile = async () => {
	const res = await SpecSrc.getAttachmentFile(idValue.value);
	console.log('isi file nya', res.data)
	if (res.status === 200) {
		if (res.data.data == null) {
			attachmentName.value = null;
		} else {
			attachmentName.value = res.data.data.spec_file_name;
			// 
		}
	} else {
		toast.add({
			severity: 'Error File',
			summary: 'Unable to Download File',
			life: 3000,
		});
	}
};

const getAttachmentFileSupp = async () => {
	const res = await SpecSrc.getAttachmentFileSupp(idValue.value);
	console.log('isi reas', res.data.data)
	if (res.status === 200) {
		if (res.data.data === null) {
			attachmentNameSupp.value = null;
		} else {
			attachmentNameSupp.value = res.data.data.conf_spec_file_name;
			// 
		}
	} else {
		toast.add({
			severity: 'Error File',
			summary: 'Unable to Download File',
			life: 3000,
		});
	}
};

const handleFileDownload = async (event) => {
  idValue.value = event.SPEC_SRC_ID
  await getAttachmentFile()
  loadingFilePur.value = true;
  const payload = {
    id: parseInt(event.SPEC_SRC_ID),
    orgId : parseInt(event.org_id),
    leveltenant: parseInt(sessionStorage.getItem("leveltenant")),
    vendorId : parseInt(event.vendor_id),
  }; 
  try {
const response = await SpecSrc.downloadFileForPur(payload);
console.log('response',response)
  if (response.status === 200) {
    soFileBuffer.value = response.data.data;
  } else {
    toast.add({
      severity: "error",
      summary: "Connection Unstable !",
      life: 3000,
    });
    loadingFilePur.value = false;
    throw Error("Error downloading file");
  }

  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Connection Unstable !",
      life: 3000,
    });
    loadingFilePur.value = false;
    throw Error("Error downloading file");
  }
  
  const linkSource = `data:application/pdf;base64,${soFileBuffer.value}`;
  const downloadLink = document.createElement("a");
  const fileName = attachmentName.value;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
  loadingFilePur.value = false;
}


const handleFileDownloadConf = async (event) => {
  idValue.value = event.SPEC_SRC_ID
  await getAttachmentFileSupp()
  loadingFilePur.value = true;
  const payload = {
    id: parseInt(event.SPEC_SRC_ID),
    orgId : parseInt(event.org_id),
    leveltenant: parseInt(sessionStorage.getItem("leveltenant")),
    vendorId : parseInt(event.vendor_id),
  }; 
  try {
const response = await SpecSrc.downloadFileForSupp(payload);
console.log('response',response)
  if (response.status === 200) {
    soFileBuffer.value = response.data.data;
  } else {
    toast.add({
      severity: "error",
      summary: "Connection Unstable !",
      life: 3000,
    });
    loadingFilePur.value = false;
    throw Error("Error downloading file");
  }

  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Connection Unstable !",
      life: 3000,
    });
    loadingFilePur.value = false;
    throw Error("Error downloading file");
  }
  
  const linkSource = `data:application/pdf;base64,${soFileBuffer.value}`;
  const downloadLink = document.createElement("a");
  const fileName = attachmentNameSupp.value;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
  loadingFilePur.value = false;
}

</script>