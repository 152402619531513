<template>
    <span class="p-float-label">
        <AutoComplete :suggestions="filteredPayment" 
        @complete="searchPayment($event)" 
        :dropdown="true" field="PAYMENT_METHOD" 
        v-model="paymentMethodValue" forceSelection>
        <template #item="slotProp">
        {{ slotProp.item.PAYMENT_METHOD }}
        </template>
    </AutoComplete>
        <label for="autocomplete">Payment Method</label>
    </span>
  
  
</template>

<script setup>
import {onMounted, ref, watchEffect, defineEmits, watch} from 'vue'
import AuthService from '../../../service/AuthService';
import InvoiceService from "../../../service/InvoiceSearchWPO"

// eslint-disable-next-line no-undef
const props = defineProps({
  db_instance: {type: Object},
  vendor_id: {type: Object},
  payment: {type: Object}
})

// eslint-disable-next-line no-undef
const filteredPayment = ref([]);
const paymentMethod = ref([]);
const paymentMethodValue = ref();
const emit = defineEmits(['payment'])


onMounted(() => {
  populateData()
})

watchEffect(() => 
  // 
  emit('payment',paymentMethodValue)
)

watch(() =>paymentMethodValue.value, (newValue) => {
  if ( typeof newValue === 'object' && !Array.isArray(newValue)){
    paymentMethodValue.value = newValue
  }
   // Do something with the updated value.
});

const populateData = () => {
  AuthService.whoAmi().then(async (response) => {
    // 
    let payload
      payload = { db_instance : props.db_instance, vendor_id : props.vendor_id}
      // 
      if (response.status === 200){
      const data = await InvoiceService.getPaymentMethod(payload)
      // 
      paymentMethod.value = data.data.data.data
      for (const s of paymentMethod.value) {
              if (s.PAYMENT_METHOD === props.payment) {
                paymentMethodValue.value = s;
                break;
              }
              else{
                paymentMethodValue.value = null;
              }
            }
    }
    
  })
}


const searchPayment = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredPayment.value = [...paymentMethod.value]
    } else {
      filteredPayment.value = paymentMethod.value.filter((comp) => {
        return comp.PAYMENT_METHOD.toLowerCase().match(event.query.toLowerCase());
      })
    }
  }, 250);
}
</script>

<style scoped>

</style>