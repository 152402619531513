<template>
	<Card>
		<template #title>
			<span class="text-xl">CAPA</span>
		</template>
		<template #content>
			<div class="flex justify-content-between">
				<!-- Status -->
				<div>
					<p class="font-bold mb-0 mb-4">Status</p>
					<p class="mb-3">Open</p>
					<p class="mb-3">Close</p>
					<p class="mb-3">Cancel</p>
				</div>

				<!-- Total -->
				<div>
					<p class="font-bold mb-0 mb-4">Total</p>
					<!-- This have to following the order of modules name -->
					<p class="mb-3">{{ statusOpen }}</p>
					<p class="mb-3">{{ statusClose }}</p>
					<p class="mb-3">{{ statusCancel }}</p>
				</div>
			</div>
		</template>
		<template #footer>
			<Button
				label="Link"
				class="p-button-link font-bold"
				@click="openModal()"
			>
				<span class="mr-2">See details</span>
				<i class="pi pi-arrow-right"></i>
			</Button>
		</template>
	</Card>
	<Dialog
		header="CAPA"
		v-model:visible="displayModal"
		:style="{ width: '90vw' }"
		:modal="true"
	>
		<div class="Grid">
			<Toast />

			<!--  Search  -->
			<div class="card">
				<div class="col-12 p-fluid">
					<div class="grid formgrid" style="margin: 1em 0 0 0">
						<div class="col-3 mb-5 lg:lcol-3">
							<span class="p-float-label">
								<CompaniesDropdown
									v-model="company"
									class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
									:disabled="
										!(userInfo
											? userInfo.leveltenant === 2
											: false)
									"
								/>
								<!-- <label for="inputtext">Select a company</label> -->
							</span>
						</div>
						<div
							v-if="!isSupplierPage"
							class="col-2 mb-5 lg:lcol-3"
						>
							<span class="p-float-label">
								<SupplierDropdown
									v-if="
										!(userInfo
											? userInfo.leveltenant === 2
											: false)
									"
									v-model="supplier"
									:company="company ? company : null"
									class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
									:key="supplierReload"
								/>
								<!-- <label for="inputtext">Select a company</label> -->
							</span>
						</div>
						<div class="col-2 mb-5 lg:lcol-3">
							<span class="p-float-label">
								<DropdownStatusView
									v-model="status"
								></DropdownStatusView>
								<!-- <label for="inputtext">Status</label> -->
							</span>
						</div>
						<div class="col-1">
							<Button label="Search" @click="goClick" />
						</div>
						<div class="col-1">
							<Button
								label="Clear"
								class="p-button-info"
								@click="clearClick"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="card">
			<TableViewCapa
				:company="company ? company : null"
				:supplier="supplier ? supplier : null"
				:documentNumber="documentNumber ? documentNumber : null"
				:status="status ? status : null"
				:searchAction="searchAction ? searchAction : null"
				:resetSearch="resetSearch"
				:key="reload"
			>
			</TableViewCapa>
		</div>
	</Dialog>
</template>

<script setup>
// Import service
import AuthService from '@/service/AuthService';
import LovService from '@/service/LovService';
import NotificationService from '../../../service/NotificationService';


// Import Component
import CompaniesDropdown from '../../components/CompaniesDropdown.vue';
import SupplierDropdown from '../../components/SupplierDropdown.vue';
import TableViewCapa from '../../capa/components/TableViewCapa.vue';
import DropdownStatusView from '../../capa/components/DropdownStatusView.vue';
// import Component VueJs
// import { useToast } from "primevue/usetoast";
import SimpleCrypto from 'simple-crypto-js';
import { onMounted, ref, watch } from 'vue';
import { UrlTab } from '../../../constants/UrlTab';
import {filterAuthByVendorname} from '@/helper/filterAuthByVendorname'

const displayModal = ref(false);
// const vendorId = ref(null);
const companyDash = ref(null);
const company = ref();
const reload = ref(0);
const isSupplierPage = ref();
const supplier = ref(null);
const supplierReload = ref(0);
const userInfo = ref();
const levelTenant = ref();
const documentNumber = ref();
const status = ref();
const showButtonAdd = ref(false);
const searchAction = ref();
const resetSearch = ref(false);
const disabledButton = ref(true);
const userCompany = () => {
	const secretKey = 'some-unique-key';
	const simpleCrypto = new SimpleCrypto(secretKey);
	return simpleCrypto.decrypt(sessionStorage.getItem('dropdownCompany'));
};
watch(
	() => company.value,
	() => {
		// supplier.value = null;
		supplierReload.value++;
		reload.value++;
	}
);

onMounted(async () => {
	AuthService.whoAmi().then(async (resp) => {
		if (resp.status === 200) {
			userInfo.value = resp.data.data;
			levelTenant.value = resp.data.data.leveltenant;
			const respCbN = await LovService.getCompanyByName(userCompany());
			const companyTemp = respCbN.data.data[0];
			company.value = respCbN.data.data[0];
			if (levelTenant.value !== 2) {
				companyDash.value = userCompany();
				//   company.value = companyTemp;

				isSupplierPage.value = false;
				showButtonAdd.value = true;
				disabledButton.value = false;
				console.log('res halo')
				await OpenCAPAThisMonth();


				// loading.value = true
			} else {
				isSupplierPage.value = true;
				showButtonAdd.value = false;
				try {
					if (companyTemp && !supplier.value) {
						const param = {
							db_instance: companyTemp.db_instance,
							org_id: companyTemp.value,
						};

						const checkAuth = await filterAuthByVendorname({
							info_id: userInfo.value.id,
							dbInstance: param.db_instance,
							value: param.org_id
						});

						if(checkAuth.supplier === null) await signOut();

						supplier.value = checkAuth.supplier;
								
						disabledButton.value = false;
					}
				} catch (error) {
					window.alert('Connection Unstable, ID not Recognize');
					await signOut();
				}

				// loading.value = true
			}
			await OpenCAPAThisMonth();
		}
	});
});

const redirectLogin = async () => {
	const url = UrlTab() + "/login";
	window.location.replace(url);
};
const signOut = async () => {
	let payload = {
		appid: 4,
		appname: 'spb',
	};
	await AuthService.signOutApp(payload);
	await AuthService.loggedOut();
	await redirectLogin();
};

const goClick = async () => {
	resetSearch.value = false;
	reload.value++;
	searchAction.value = true;
};
const clearClick = () => {
	searchAction.value = true;
	documentNumber.value = null;
	status.value = null;
	if (userInfo.value.leveltenant === 2) {
		// company.value = null;
	} else {
		supplier.value = null;
	}

	resetSearch.value = true;
};
const openModal = async () => {
	displayModal.value = true;
};

const statusOpen = ref(0);
const statusClose = ref(0);
const statusCancel = ref(0);

const OpenCAPAThisMonth = async () => {
	const secretKey = 'some-unique-key';
	const simpleCrypto = new SimpleCrypto(secretKey);
	const decipherText = simpleCrypto.decrypt(
		sessionStorage.getItem('dropdownCompany')
	);
	let payload = null
	if (levelTenant.value === 1){
		payload = {
			vendorId: null,
			company: decipherText,
		};
	} else if (levelTenant.value !== 1){
		payload = {
			vendorId: supplier.value.vendor_id?supplier.value.vendor_id:null,
			company: decipherText,
		};
	}
	console.log('res payload', payload)
	const res = await NotificationService.getCAPATotal(payload);
	console.log('res data capa', res.data.data)
	statusOpen.value = res.data.data[1][0].status_open;
	statusClose.value = res.data.data[2][0].status_close;
	statusCancel.value = res.data.data[3][0].status_cancel;
};
</script>

<style scoped></style>
