<template>
	<div class="layout-topbar">
		<button
			class="p-link layout-menu-button layout-topbar-button"
			@click="onMenuToggle"
		>
			<i class="pi pi-bars"></i>
		</button>

		<router-link to="/dashboard" class="layout-topbar-logo">
			<img alt="Logo" :src="topbarImage()" />
			<span>Supplier Portal & Billing</span>
		</router-link>

		<button
			class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{
				selector: '@next',
				enterClass: 'hidden',
				enterActiveClass: 'scalein',
				leaveToClass: 'hidden',
				leaveActiveClass: 'fadeout',
				hideOnOutsideClick: true,
			}"
		>
			<i class="pi pi-ellipsis-v"></i>
		</button>

		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-user"></i>
					<span>Profile</span>
				</button>
			</li>
			<li>
				<button
					class="p-link layout-topbar-button"
					@click="openResponsive"
				>
					<i class="pi pi-building"></i>
					<span>Company</span>
				</button>
			</li>
			<li>
				<button
					class="p-link layout-topbar-button"
					@click="openConfirmation"
				>
					<i class="pi pi-sign-out"></i>
					<span>Sign Out</span>
				</button>
			</li>
		</ul>
	</div>

	<Dialog
		header="Switch Company"
		v-model:visible="displayResponsive"
		:breakpoints="{ '960px': '75vw' }"
		:style="{ width: '25vw' }"
		:modal="true"
	>
		<div class="grid formgrid" style="margin: 2em 0 0 0">
			<!-- <div class="col-12 mb-6 lg:col-6 lg:mb-0">
                       <CompaniesDropdown v-model="company" class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0" :disabled="displayCompany && !company"/>
                    </div> -->
			<div v-if="!isInternal" class="col-12 mb-5 lg:col-12 lg:mb-0">
				<span class="p-float-label">
					<AutoComplete
                            v-model="company"
                            :suggestions="filteredCompany"
                            @complete="searchCompanySupp($event)"
                            :dropdown="true"
                            field="tnname"
							:style="{width: '100%'}"
                            forceSelection
                            :disabled="!companysupplier"
                        >
                        <template #item="slotProps">
                                <div class="supplier-item">
                                    <div class="ml-2">
                                        {{ slotProps.item.tnname }}
                                    </div>
                                </div>
                            </template>
                        </AutoComplete>
					
					<label for="inputtext">Company</label>
				</span>
			</div>
			<div v-if="isInternal" class="col-12 mb-5 lg:col-12 lg:mb-0">
				<span class="p-float-label">
					<!-- <Dropdown
						v-model="company"
						:options="companysupplier"
						optionLabel="name"
					/> -->
					<AutoComplete
                            v-model="company"
                            :suggestions="filteredCompany"
                            @complete="searchCompany($event)"
                            :dropdown="true"
                            field="name"
							
                            forceSelection
                            :disabled="!companysupplier"
                            class="w-full mb-3"
                        >
                            <template #item="slotProps">
                                <div class="supplier-item">
                                    <div class="ml-2">
                                        {{ slotProps.item.name }}
                                    </div>
                                </div>
                            </template>
                        </AutoComplete>
					<label for="inputtext">Company</label>
				</span>
			</div>
		</div>
		<template #footer>
			<Button
				label="Cancel"
				@click="closeResponsive"
				class="p-button-text"
			/>
			<Button label="Switch" @click="switchResponsive" autofocus />
		</template>
	</Dialog>

	<Dialog
		header="Sign Out"
		v-model:visible="displayConfirmation"
		:breakpoints="{ '960px': '75vw' }"
		:style="{ width: '25vw' }"
		:modal="true"
	>
		<div class="confirmation-content">
			<i
				class="pi pi-exclamation-triangle mr-3"
				style="font-size: 2rem"
			/>
			<span>Are you sure you want to proceed?</span>
		</div>
		<template #footer>
			<Button
				label="No"
				icon="pi pi-times"
				@click="closeConfirmation"
				class="p-button-text"
			/>
			<Button label="Yes" icon="pi pi-check" @click="signOut" autofocus />
		</template>
	</Dialog>
</template>

<script>
import AuthService from './service/AuthService';
// import SwitchCompany from './service/SwithCompany';
// import PlanningOrder from './service/PlanningOrderService';
// import CompaniesDropdown from "@/components/components/CompaniesDropdown"
import LovService from './service/LovService';
import SimpleCrypto from 'simple-crypto-js';

export default {
	// components: {
	// 	CompaniesDropdown,
	// },
	data() {
		return {
			displayResponsive: false,
			displayConfirmation: false,
			company: null,
			switchcompany: null,
			isicompany: null,
			isidb: null,
			purchasing: false,
			supplier: false,
			isipayload: null,
			dbInstance: null,
			dbInstanceParse: null,
			dropdownCompany: null,
			companysupplier: null,
			userInfo: null,
			displayCompany: true,
			isInternal: false,
			filteredCompany: null,
		};
	},
	watch: {},
	created() {
		// this.SwitchCompany();
		AuthService.whoAmi().then(async (response) => {
			if (response.data.data.leveltenant === 1) {
				if (response.data.data.userid === 'dexaadmin') {
					const tmp = LovService.getValueType('COMPANY');
					this.companysupplier = tmp.data.data;
				} else {
					this.isInternal = true;
					LovService.getCompanyByName(this.userCompany()).then(
						(response) => {
							console.log('ISI DB', response);
							let dbInstance = response.data.data[0].db_instance
							LovService.getcompanydb(dbInstance).then(
								(responses) => {
									this.companysupplier = responses.data.data;
								}
							);
						}
					);
				}
			} else {
				this.companysupplier = JSON.parse(
					sessionStorage.getItem('list')
				);
			}
		});
	},
	methods: {
		signOut() {
			let payload = { appid: 4, appname: 'spb' };
			AuthService.signOutApp(payload).then((response) => {
				console.log('Process >> ' + 'Sign Out');
				if (response.status === 200) {
					this.$localStorage.clear('authKey');
					sessionStorage.clear('Company');
					AuthService.loggedOut();
					this.$router.push('/');
					console.clear();
				}
			});
		},
		SwitchCompany() {
			// if (sessionStorage.authKey != null){
			if (
				sessionStorage.leveltenant === 2 ||
				sessionStorage.leveltenant === 3
			) {
				this.dropdownCompany = sessionStorage.company;
				sessionStorage.removeItem('company');
				this.companysupplier = JSON.parse(this.dropdownCompany);
				this.supplier = true;
			}
			// }
			// else {
			// }

			// }
		},
		// getDbInstance(){
		// 		const payload = this.switchcompany.tnname
		// 		AuthService.whoAmi().then(value => {
		// 			if (value.status === 200){
		// 				SwitchCompany.getDbInstance(payload)
		// 				.then(response => {
		// 				this.dbInstance = response
		// 			})
		// 			}
		// 		})
		// 	},
		onMenuToggle(event) {
			this.$emit('menu-toggle', event);
		},
		onTopbarMenuToggle(event) {
			this.$emit('topbar-menu-toggle', event);
		},
		topbarImage() {
			return 'layout/images/DXG Supplier Portal dan Billing Invoice 03-06.png';
		},
		userCompany() {
			const secretKey = 'some-unique-key';
			const simpleCrypto = new SimpleCrypto(secretKey);
			return simpleCrypto.decrypt(
				sessionStorage.getItem('dropdownCompany')
			);
		},
		openResponsive() {
			this.displayResponsive = true;
			AuthService.whoAmi().then(async (response) => {
				if (response.status == 200) {
					if (response.data.data.leveltenant === 1) {
						this.userInfo = response.data.data;
						for (const comp of this.companysupplier) {
							if (comp.name === this.userCompany()) {
								this.company = comp;
								break;
							}
						}
					} else {
						this.userInfo = response.data.data;
						for (const comp of this.companysupplier) {
							if (comp.tnname === this.userCompany()) {
								this.company = comp;
								break;
							}
						}
					}
				}
			});
		},
		closeResponsive() {
			this.displayResponsive = false;
			this.company = null;
		},
		switchResponsive() {
			this.displayResponsive = false;
			AuthService.whoAmi().then((value) => {
				if (value.data.data.leveltenant === 1) {
					const secretKey = 'some-unique-key';
					const simpleCrypto = new SimpleCrypto(secretKey);
					const plainText = this.company.name;
					const cipherText = simpleCrypto.encrypt(plainText);
					sessionStorage.setItem('dropdownCompany', cipherText);
					window.location.reload();
				}
				if (
					value.data.data.leveltenant === 2 ||
					this.userInfo.leveltenant === 3
				) {
					const secretKey = 'some-unique-key';
					const simpleCrypto = new SimpleCrypto(secretKey);
					const plainText = this.company.tnname;
					const cipherText = simpleCrypto.encrypt(plainText);
					sessionStorage.setItem('dropdownCompany', cipherText);
					window.location.reload();
				}
			});
			// window.location.reload()
		},
		openConfirmation() {
			this.displayConfirmation = true;
		},
		closeConfirmation() {
			this.displayConfirmation = false;
		},
		async searchCompanySupp(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredCompany = [...this.companysupplier];
				} else {
					this.filteredCompany = this.companysupplier.filter((item) => {
						return item.tnname
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		async searchCompany(event) {
            // console.log('isi company', this.isSupp)
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredCompany = [...this.companysupplier];
				} else {
					this.filteredCompany = this.companysupplier.filter((item) => {
						return item.name
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
	},
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		},
	},
};
</script>
