<template>
	<!-- eslint-disable -->
	<div class="grid">
		<div class="col-12 p-fluid">
			<div class="card">
				<div class="grid formgrid" style="margin: 1em 0 0 0">
					<div class="col-12 mb-5 lg:col-3 lg:mb-0">
						<span class="p-float-label">
							<Dropdown
								id="dropdown"
								:options="modules"
								v-model="search"
								optionLabel="name"
							>
							</Dropdown>
							<label for="dropdown">Module</label>
						</span>
					</div>
					<div class="col-12 mb-2 lg:col-2 lg:mb-0">
						<span class="p-float-label">
							<Dropdown
								id="dropdown"
								style="width: 100%"
								:options="statusOptions"
								v-model="searchStatus"
								optionLabel="name"
							></Dropdown>
							<label for="dropdown">Status</label>
						</span>
					</div>
					<div class="col-12 mb-5 lg:col-1 lg:mb-0">
						<Button label="Go" @click="searchFilter()"></Button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<DataTable
		:value="filtered ? filtered : notifications"
		v-model:selection="selectedNotifications"
		dataKey="id"
		:paginator="true"
		:rows="5"
		v-model:filters="filters"
		resizableColumns="true"
		paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
		:rowsPerPageOptions="[5, 10, 25]"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
		responsiveLayout="scroll"
	>
		<Column
			v-for="col of notifColumns"
			:field="col.field"
			:header="col.header"
			:key="col.field"
			:sortable="true"
		/>
		<Column header="View">
			<template #body="slotProps">
				<Button
					icon="pi pi-eye"
					class="p-button-rounded p-button mr-2"
					@click="viewDetail(slotProps.data)"
				/>
			</template>
		</Column>
	</DataTable>
	<br />
	<Dialog
		header="View Notification Details"
		v-model:visible="displayModal"
		:style="{ width: '18vw' }"
		:modal="true"
	>
		<Button
			label="Purchase Order"
			icon="pi pi-shopping-cart"
			@click="viewPODetail()"
			class="p-button-text"
		/>
		<Button
			label="CAPA"
			icon="pi pi-check-square"
			@click="viewCapaDetail()"
			class="p-button-text"
		/>
		<Button
			label="Shipment Notice"
			icon="pi pi-inbox"
			@click="viewShipmentDetail()"
			class="p-button-text"
		/>
		<template #footer> </template>
	</Dialog>
</template>

<script>
/* eslint-disable */
import NotificationService from '../../service/NotificationService';
// import SwithCompany from '../../service/SwithCompany';
import AuthService from '../../service/AuthService';

export default {
	data() {
		return {
			displayModal: false,
			modules: [
				{
					name: 'ALL',
				},
				{
					name: 'Planning Order',
				},
				{
					name: 'Purchase Order',
				},
				{
					name: 'CAPA',
				},
				{
					name: 'Shipment Notice',
				},
			],
			statusOptions: [
				{
					name: 'ALL',
				},
				{
					name: 'Open',
				},
				{
					name: 'Closed',
				},
			],
			filtered: null,
			search: {},
			searchStatus: {},
			notifColumns: [],
			notifications: null,
			notification: {},
			selectedNotifications: null,
			currentTenantLvl: null,
		};
	},

	created() {
		this.notifColumns = [
			{
				field: 'modulename',
				header: 'Module',
			},
			{
				field: 'sbjmessage',
				header: 'Subject',
			},
			{
				field: 'receiveddate',
				header: 'Received Date',
			},
			{
				field: 'status',
				header: 'Status',
			},
		];
	},
	mounted() {
		AuthService.whoAmi().then((respond) => {
			this.currentTenantLvl = respond.data.data.leveltenant;
			let checkSidemenu = respond.data.data.sidemenus;
			if (checkSidemenu !== undefined) {
				this.search.name = 'ALL';
				this.searchStatus.name = 'ALL';
				NotificationService.getAllNotifications().then((response) => {
					if (response.status == 200) {
						this.notifications = response.data.data[0];
					} else {
						this.notifications = [];
					}
				});
			}
		});
	},
	methods: {
		searchFilter() {
			this.filtered = this.searchAll;
		},
		viewDetail(data) {
			let moduletype = data.modulename.toString();

			if (moduletype == 'Planning Order' && this.currentTenantLvl == 2) {
                this.$router.push({
                    name: 'plannodetail',
                    params: {
                        nameModule: data.modulename,
                        subjectMessage: data.sbjmessage
                    }
                })
            } else if(moduletype == 'Planning Order' && this.currentTenantLvl == 1) {
                this.$router.push({
                    name: 'plannodetailpur',
                    params: {
                        nameModule: data.modulename,
                        subjectMessage: data.sbjmessage
                    }
                })
                // this.displayModal = true;
            } 
            if (moduletype == 'Purchase Order' && this.currentTenantLvl == 2) {
                this.$router.push({
                    name: 'podetail',
                    params: {
                        nameModule: data.modulename,
                        subjectMessage: data.sbjmessage
                    }
                })
            } else if (moduletype == 'Purchase Order' && this.currentTenantLvl == 1) {
                this.$router.push({
                    name: 'podetailpur',
                    params: {
                        nameModule: data.modulename,
                        subjectMessage: data.sbjmessage
                    }
                })
            }
			// this.displayModal = true;
			// }
		},
		closeModal() {
			this.displayModal = false;
		},
		viewPODetail() {
			this.$router.push('/dashboard/notifdetails/podetail');
		},
		viewCapaDetail() {
			this.$router.push('/dashboard/notifdetails/capadetail');
		},
		viewShipmentDetail() {
			this.$router.push('/dashboard/notifdetails/shipmentdetail');
		},
	},
	computed: {
		searchAll() {
			if (
				this.search.name === 'ALL' &&
				this.searchStatus.name === 'ALL'
			) {
				this.filtered = null;
			} else if (
				this.search.name === 'ALL' ||
				this.searchStatus.name === 'ALL'
			) {
				return this.notifications.filter((item) => {
					return (
						item.modulename.match(this.search.name) ||
						item.status.match(this.searchStatus.name)
					);
				});
			} else {
				return this.notifications.filter((item) => {
					return (
						item.modulename.match(this.search.name) &&
						item.status.match(this.searchStatus.name)
					);
				});
			}
		},
	},
};
</script>
<style scoped></style>
