import SpbInvoicApi from "./api/SpbInvoiceApi";

class InvoiceProxy {
  async getListBatchNumber(
    orgId,
    dbInstance,
    vendorId,
    invoiceNumber,
    statusInvoice,
    levelTenant
  ) {
    return SpbInvoicApi.get(
      `spb/invoiceproxy/getlistbatchnumber?orgId=${orgId}&dbInstance=${dbInstance}&vendorId=${vendorId}&invoiceNumber=${invoiceNumber}&statusInvoice=${statusInvoice}&levelTenant=${levelTenant}`
    );
  }
  async getListInvoiceNumber(
    orgId,
    dbInstance,
    vendorId,
    batchNumber,
    statusInvoice,
    levelTenant
  ) {
    return SpbInvoicApi.get(
      `spb/invoiceproxy/getlistinvoicenumber?orgId=${orgId}&dbInstance=${dbInstance}&vendorId=${vendorId}&batchNumber=${batchNumber}&statusInvoice=${statusInvoice}&levelTenant=${levelTenant}`
    );
  }
  async getListInvoiceProxy(
    orgId,
    dbInstance,
    vendorId,
    batchNumber,
    invoiceNumber,
    statusInvoice,
    page,
    limit,
    sortField,
    sortOrder,
    search
  ) {
    return SpbInvoicApi.get(
      `spb/invoiceproxy/getlistinvoiceproxy?page=${page}&limitPage=${limit}&sortField=${sortField}&sortOrder=${sortOrder}&orgId=${orgId}&dbInstance=${dbInstance}&vendorId=${vendorId}&batchNumber=${batchNumber}&invoiceNumber=${invoiceNumber}&statusInvoice=${statusInvoice}&search=${search}`
    );
  }
  async getDetailData(payload) {
    return SpbInvoicApi.post("spb/invoiceproxy/detailheader", payload);
  }
  async getCalculateTotalPrepayment(payload) {
    return SpbInvoicApi.post("spb/invoiceproxy/getCalculateTotalPrepayment", payload);
  }
  async getDetailDataSP1(payload) {
    return SpbInvoicApi.post("spb/invoiceproxy/detailheadersp1", payload);
  }
  async getWHTID(payload) {
    return SpbInvoicApi.post("spb/invoiceproxy/whtdropdownid", payload);
  }
  async getDetailTableReimburse(payload) {
    return SpbInvoicApi.post("spb/invoiceproxy/detailtablereimburse", payload);
  }
  async getDetailDataTable(payload) {
    return SpbInvoicApi.post("spb/invoiceproxy/detailtableline", payload);
  }
  async getListCostCenter(payload) {
    return SpbInvoicApi.post(`spb/invoiceproxy/listcostcenter`, payload);
  }
  async getDetailCat(payload) {
    return SpbInvoicApi.post("spb/invoiceproxy/listcategory", payload);
  }
  async getDetailCatSK(payload) {
    return SpbInvoicApi.post("spb/invoiceproxy/listcategorywsk", payload);
  }
  async getDetailDataTravelOrder(payload) {
    return SpbInvoicApi.post("spb/invoiceproxy/travelorders", payload);
  }
  async getListDetailCat() {
    return SpbInvoicApi.get(`spb/invoiceproxy/getseqdetailcat`);
  }
  async deleteLine(payload) {
    return SpbInvoicApi.post("spb/invoiceproxy/deleteline", payload);
  }
  async getVAT(payload) {
    return SpbInvoicApi.post("spb/invoiceproxy/vatdropdown", payload);
  }
  async getVATID(payload) {
    return SpbInvoicApi.post("spb/invoiceproxy/vatdropdownid", payload);
  }
  async accountPic(payload) {
    return SpbInvoicApi.post("spb/invoiceproxy/accountdetail", payload);
  }
  async whtPIC(payload) {
    return SpbInvoicApi.post("spb/invoiceproxy/whtCodePIC", payload);
  }
  async deleteReimburse(payload) {
    return SpbInvoicApi.post("spb/invoiceproxy/deletereimburse", payload);
  }
  async getVendors(payload) {
    return SpbInvoicApi.post("spb/invoiceproxy/getvendors", payload);
  }
  async getGroupName(payload) {
    return SpbInvoicApi.post("spb/invoiceproxy/getgroupname", payload);
  }
  async getListCurrency(payload) {
    return SpbInvoicApi.post("spb/invoiceproxy/getlistcurrency", payload);
  }
  async getListDetailReimburse() {
    return SpbInvoicApi.get(`spb/invoiceproxy/getseqdetailreimburse`);
  }
  async saveData(id, payload) {
    return SpbInvoicApi.post(`spb/invoiceproxy/savedata/${id}`, payload);
  }

  async saveDataSP1(id, payload) {
    return SpbInvoicApi.post(`spb/invoiceproxy/savedatasp1/${id}`, payload);
  }

  async getTermsBill(payload) {
    return SpbInvoicApi.post("spb/invoiceproxy/termsBill", payload);
  }
  async exportDataDownload(payload) {
    return SpbInvoicApi.post("spb/invoiceproxy/exportdata", payload);
  }
  async getReferenceNumber(payload) {
    return SpbInvoicApi.post("spb/invoiceproxy/referencenumber", payload);
  }

  async getvendorbytenant(userId, companyName, dbInstance, orgId, levelTenant) {
    return SpbInvoicApi.get(
      `spb/invoiceproxy/getvendorbytenant/${userId}/${companyName}/${dbInstance}/${orgId}/${levelTenant}`
    );
  }

  async getFileBase64(invoiceId, leveltenant, vendorId) {
		return SpbInvoicApi.get(`/invoicepo/getfilebase64/${invoiceId}/${leveltenant}/${vendorId}`);
	}
}

export default new InvoiceProxy();
