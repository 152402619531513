<template>
	<div class="grid find-to-modal">
		<Toast />

		<!-- Content -->
		<div class="col-12 mb-2">
			<div class="content-wrapper">
				<div class="content-header">Travel Order</div>
				<div class="content-body">
					<!-- Table content -->
					<div class="mb-6">
						<DataTable
							dataKey="id"
							responsiveLayout="scroll"
							selectionMode="single"
							:paginator="true"
							:rows="5"
							:rowsPerPageOptions="[5, 10, 20, 50]"
							:metaKeySelection="false"
							:loading="loading"
							:totalRecords="totalRecords"
							:value="filteredTravelOrders"
							paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
							v-model:selection="selectedTravelOrder"
							@row-select="handleRowSelect"
						>
							<!-- Search input -->
							<template #header>
								<div>
									<span class="p-input-icon-left w-full">
										<i class="pi pi-search" />
										<InputText
											class="w-full"
											type="text"
											placeholder="Search"
											:disabled="!travelOrders"
											v-model="searchKeyword"
											@keyup.enter="handleSearch"
										/>
									</span>
								</div>
							</template>

							<!-- Results Data -->
							<Column
								header="No Travel Order"
								field="travel_order_no"
							>
							</Column>
							<Column header="Nama Tamu" field="guest_name">
							</Column>
							<Column header="Tanggal Mulai">
								<template #body="{ data }">
									{{
										new Intl.DateTimeFormat('en-US', {
											year: 'numeric',
											month: 'short',
											day: 'numeric',
										}).format(new Date(data.start_date))
									}}
								</template>
							</Column>
							<Column header="Tanggal Akhir">
								<template #body="{ data }">
									{{
										new Intl.DateTimeFormat('en-US', {
											year: 'numeric',
											month: 'short',
											day: 'numeric',
										}).format(new Date(data.end_date))
									}}
								</template>
							</Column>

							<!-- Empty statement -->
							<template #empty> No data found. </template>
						</DataTable>
					</div>

					<Button
						class="p-button-secondary"
						@click="$emit('on-close')"
					>
						<span class="uppercase font-bold">Close</span>
					</Button>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits } from 'vue';
import InvoiceCreateNonPOService from '../../service/InvoiceCreateNonPO';

// Props
const props = defineProps(['supplierName', 'einvoiceId']);

// Emits
const emit = defineEmits(['on-add']);

// State
const travelOrders = ref(null);
const filteredTravelOrders = ref(null);
const selectedTravelOrder = ref(null);
const searchKeyword = ref(null);
const loading = ref(false);
const totalRecords = ref(0);

// Mounted hook
onMounted(() => {
	// Get travel orders list
	getTravelOrders();
});

// Get travel orders
const getTravelOrders = async () => {
	const payload = {
		supplierName: props.supplierName,
		einvoiceId: props.einvoiceId
	};

	try {
		loading.value = true;
		const res = await InvoiceCreateNonPOService.getTravelOrders(payload);

		if (res.status === 200) {
			travelOrders.value = res.data.data;
			filteredTravelOrders.value = travelOrders.value;
		}
		loading.value = false;
	} catch (err) {
		console.log(err);
		loading.value = false;
	}
};

// Handle search Travel Order
const handleSearch = async () => {
	if (searchKeyword.value.trim().length) {
		filteredTravelOrders.value = travelOrders.value.filter((order) => {
			return (
				order.travel_order_no
					.toLowerCase()
					.match(searchKeyword.value.toLowerCase()) ||
				order.guest_name
					.toLowerCase()
					.match(searchKeyword.value.toLowerCase())
			);
		});
	} else {
		filteredTravelOrders.value = travelOrders.value;
	}
};

// Handle row select
const handleRowSelect = () => {
	emit('on-add', selectedTravelOrder.value);
};
</script>

<style scoped>
.content-body {
	padding-bottom: 0 !important;
}
</style>
