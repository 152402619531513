<template>
  <AutoComplete
    :suggestions="filteredBillBanks"
    @complete="searchBillBanks($event)"
    :dropdown="true"
    field="transfer_to"
    forceSelection
  >
    <template #item="slotProp">
      {{ slotProp.item.transfer_to }}
    </template>
  </AutoComplete>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { getBillBanks } from "@/service/InvoiceProxyNonPOServices";

// eslint-disable-next-line no-undef
const filteredBillBanks = ref([]);
const billBanks = ref([]);

// eslint-disable-next-line no-unused-vars,no-undef
const props = defineProps({
  company: {
    type: Object,
  },
  supplier: {
    type: Object,
  },
});




onMounted(async () => {
  try {
    //Query dari backend
    if (props.company && props.supplier) {
      const resBillBank = await getBillBanks(
        props.company.db_instance,
        props.company.value,
        props.supplier.vendor_id
      );
      billBanks.value = resBillBank.data;
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
});

const searchBillBanks = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredBillBanks.value = [...billBanks.value];
    } else {
      filteredBillBanks.value = billBanks.value.filter((comp) => {
        return comp.transfer_to
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};
</script>
