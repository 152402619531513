<template>
  <AutoComplete
    :suggestions="filtered"
    @complete="search($event)"
    :dropdown="true"
    field="INVOICE_NO"
    :placeholder="placeholder"
    v-model="invoiceNo"
    forceSelection
  >
    <template #item="slotProp">
      {{ slotProp.item.INVOICE_NO }}
    </template>
  </AutoComplete>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect } from "vue";
import invoiceProxy from "../../../service/InvoiceProxyService";

const filtered = ref([]);
const invoiceList = ref([]);
const invoiceNo = ref();
const placeholder = ref("Select a Invoice Number");

const emit = defineEmits(["invoiceNo"]);
watchEffect(() => emit("invoiceNo", invoiceNo));

// eslint-disable-next-line no-undef
const props = defineProps({
  company: {
    type: Object,
  },
  vendorId: {
    type: Object,
  },
  batchNumber: {
    type: String,
  },
  statusInvoice: {
    type: String,
  },
  levelTenant: {
    type: String,
  },
});

onMounted(async () => {
  try {
    
    if (props.company) {
      const result = await invoiceProxy.getListInvoiceNumber(
        props.company.value,
        props.company.db_instance,
        props.vendorId,
        props.batchNumber,
        props.statusInvoice,
        props.levelTenant
      );
      // 
      invoiceList.value = result.data.data;
    }

    // eslint-disable-next-line no-empty
  } catch (e) {
    throw new Error(e);
  }
});

const search = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filtered.value = [...invoiceList.value];
    } else {
      filtered.value = invoiceList.value.filter((inv) => {
        // 
        return inv.INVOICE_NO.toLowerCase().match(
          event.query.toLowerCase()
        );
      });
    }
  }, 250);
};
</script>
