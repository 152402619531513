<template>
	<DataTable
		id="tableBatchList"
		:value="batchList"
		:lazy="true"
		:paginator="true"
		:rows="10"
		:loading="loading"
		:totalRecords="totalData"
		@page="onPage($event)"
		@sort="onSort($event)"
		:rowsPerPageOptions="[10, 20, 30]"
		responsiveLayout="scroll"
		:sortable="true"
		:rowId="null"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
		:scrollable="true"
		scrollHeight="600px"
		scrollDirection="both"
	>
		<template #header>
			<div>
				<span class="p-input-icon-left">
					<i class="pi pi-search" />
					<InputText
						v-model="batchFilter"
						placeholder="Search Batch"
						@keyup.enter="populateTable()"
						@page="onPage($event)"
					/>
				</span>
			</div>
		</template>
		<template #empty>Batch Not found</template>
		<template #loading>Loading data, please wait...</template>

		<Column header="Batch No" field="BATCH_NO" style="width: 35%"> </Column>
		<Column
			class="call-button-container"
			header="Batch Amount"
			field="AMOUNT"
			style="width: 35%"
		>
			<template #body="{ data }">
				{{ formatCurrency(data.AMOUNT) }}
			</template>
		</Column>
		<Column header="Status" field="STATUS" style="width: 15%"> </Column>
		<Column header="Detail" selectionMode="single" style="width: 15%">
			<template #body="slotProps">
				<Button
					icon="pi pi-eye"
					class="p-button-rounded p-button-text"
					@click="editChildren(slotProps.data)"
				/>
			</template>
		</Column>
	</DataTable>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import BatchListService from '../../../service/batchList';
import AuthService from '@/service/AuthService';
import {filterAuthByVendorname} from "@/helper/filterAuthByVendorname"

const company = ref();
const supplier = ref(null);
const statusBatchList = ref();

onMounted(async () => {
	company.value = props.company;
	const info = await AuthService.whoAmi();
	if (info.status === 200) {
		levelTenant.value = info.data.data.leveltenant;
		if (levelTenant.value !== 2) {
			loading.value = true;
			supplier.value = props.supplier;
			// loading.value = true
			await populateTable();
		} else {
			if (company.value && !supplier.value) {
				const checkAuth = await filterAuthByVendorname({
					info_id: info.data.data.id,
					dbInstance: props.company.db_instance,
					value: props.company.value
				});

				if(checkAuth.supplier === null) router.push("/dashboard")

				supplier.value = checkAuth.supplier;

				const payload = {
					org_id: company.value.value,
					suppVal: supplier.value
						? supplier.value.vendor_id.toString()
						: null,
				};
				const result = await BatchListService.getLovStatus(payload);
				if (result.status === 200) {
					const statusBatch = result.data.data.data;
					for (var q of statusBatch) {
						if (q.name === statusBatch) {
							statusBatchList.value = q;
						}
					}
				}

				await populateTable();
			}
			// loading.value = true
		}
	}
});
// eslint-disable-next-line no-undef
const props = defineProps({
	statusBatchList: {
		type: String,
	},
	company: {
		type: Object,
	},
	supplier: {
		type: Object,
	},
});

const batchList = ref();
// const totalData = ref(0);
const loading = ref(false);
const page = ref(1);
const rowsPerPage = ref(10);
const sortColumn = ref();
const sortType = ref();
const levelTenant = ref();
const router = useRouter();
const tableData = ref([]);
// const batchList = ref();
const totalData = ref(0);
const batchFilter = ref();

const populateTable = async () => {
	loading.value = true;
	if (props.company !== null && props.company !== undefined) {
		const payload = {
			org_id: company.value ? company.value.value : null,
			suppValue: supplier.value ? supplier.value.vendor_id : null,
			db_instance: company.value ? company.value.db_instance : null,
			status_batch: props.statusBatchList
				? props.statusBatchList.name
				: null,
			search: batchFilter.value ? batchFilter.value : null,
			page: page.value,
			rows: rowsPerPage.value,
		};
		tableData.value = await BatchListService.getBatchListAll(payload);
		batchList.value = tableData.value.data.data.data;
		totalData.value = tableData.value.data.data.totalData[0].totalData;
	}
	loading.value = false;
};

const onPage = async (event) => {
	// loading.value = true
	page.value = event.page + 1;
	rowsPerPage.value = event.rows;
	await populateTable();
};

const onSort = async (event) => {
	// loading.value = true
	page.value = 1;
	rowsPerPage.value = event.rows;
	sortColumn.value = event.sortField;
	sortType.value = event.sortOrder;
	await populateTable();
};
const editChildren = async (rowIndex) => {
	router.push({
		name: 'BatchListDetail',
		params: { batch_id: rowIndex.BATCH_ID },
	});
};
const formatCurrency = (value) => {
	if (value) return value.toLocaleString('id-ID');
	return;
};
</script>
<style scoped></style>
