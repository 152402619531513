<template>
	<!-- eslint-disable -->
	<Toast />
	<div class="Grid">
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<!-- Search -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search</div>
				<div class="content-body">
					<div class="col-12 p-fluid">
						<div class="grid formgrid" style="margin: 1em 0 0 0">
							<div class="col-12 mb-5 lg:col-3">
								<span class="p-float-label">
									<AutoComplete
										v-model="company"
										:suggestions="filteredCompany"
										@complete="searchCompany($event)"
										:dropdown="true"
										field="name"
										forceSelection
										:disabled="!isSupplierpage"
									>
										<template #item="slotProps">
											<div class="supplier-item">
												<div class="ml-2">
													{{ slotProps.item.name }}
												</div>
											</div>
										</template>
									</AutoComplete>
									<label for="inputtext">Company</label>
								</span>
							</div>
							<div
								v-if="!isSupplierpage"
								class="col-12 mb-5 lg:col-3"
							>
								<span class="p-float-label">
									<AutoComplete
										v-model="supplier"
										:suggestions="filteredSupplier"
										@complete="searchSupplier($event)"
										:disabled="companyValue"
										:dropdown="true"
										field="vendor_name"
										forceSelection
									>
										<template #item="slotProps">
											<div class="supplier-item">
												<div class="ml-2">
													{{
														slotProps.item
															.vendor_name
													}}
												</div>
											</div>
										</template>
									</AutoComplete>
									<label for="inputtext">Supplier</label>
								</span>
							</div>
							<div class="col-12 mb-5 lg:col-3 lg:mb-5">
								<span class="p-float-label">
									<AutoComplete
										v-model="planningOrder"
										:suggestions="filteredPlanningOrder"
										@complete="searchPoNumber($event)"
										field="SEGMENT1"
										forceSelection
									>
									</AutoComplete>
									<label for="inputtext"
										>Po Number From</label
									>
								</span>
							</div>
							<div class="col-12 mb-5 lg:col-3 lg:mb-5">
								<span class="p-float-label">
									<AutoComplete
										v-model="planningOrderTo"
										:suggestions="filteredPlanningOrderTo"
										@complete="searchPoNumberTo($event)"
										field="SEGMENT1"
										forceSelection
									/>
									<label for="inputtext">Po Number To</label>
								</span>
							</div>
							<div
								v-if="isSupplierpage"
								class="col-12 mb-5 lg:col-3 lg:mb-5"
							>
								<span class="p-float-label">
									<Calendar
										:showIcon="true"
										:showButtonBar="true"
										dateFormat="dd M yy"
										v-model="dateFrom"
									></Calendar>
									<label for="inputtext">
										Arrival at Site Date From</label
									>
								</span>
							</div>
							<div
								v-if="isSupplierpage"
								class="col-12 mb-5 lg:col-3 lg:mb-5"
							>
								<span class="p-float-label">
									<Calendar
										:showIcon="true"
										:showButtonBar="true"
										dateFormat="dd M yy"
										v-model="dateTo"
									></Calendar>
									<label for="inputtext">
										Arrival at Site Date To</label
									>
								</span>
							</div>
							<div class="mr-3 ml-2">
								<Button
									@click="handleSearchPo(true)"
									class="p-button-success uppercase"
									label="search"
									:disabled="isButtonSearchDisable"
								/>
							</div>
							<div>
								<Button
									@click="clearFilter()"
									class="p-button-secondary uppercase"
									label="clear"
								></Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Search results -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search Result</div>
				<div class="content-body">
					<div style="text-align: right">
						<Button
							icon="pi pi-print"
							label="print page"
							class="p-button-secondary mr-3 uppercase"
							@click="printPage()"
						/>
						<Button
							v-if="!isSupplierpage"
							label="Save"
							class="mr-3 uppercase"
							@click="savePur()"
							:disabled="buttonSave"
						/>
						<Button
							@click="confirm()"
							v-if="isSupplierpage"
							class="p-button-success mr-2 uppercase"
							label="confirm"
							:disabled="confirmButton"
						></Button>
					</div>
					<div class="mt-4">
						<TabView ref="tabview1" v-model:activeIndex="tabName">
							<TabPanel header="PO From Submit">
								<DataTable
									:value="purchaseOrders"
									:lazy="true"
									:paginator="true"
									:rows="rows"
									:rowsPerPageOptions="[10, 20, 50]"
									removableSort
									:metaKeySelection="false"
									:scrollable="true"
									scrollHeight="600px"
									:loading="loading"
									:totalRecords="totalRecords"
									ref="dt"
									dataKey="id"
									paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
									currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
									responsiveLayout="scroll"
									selectionMode="multiple"
									v-model:selection="selectedPo"
									@page="onPage($event)"
									@sort="onSort($event)"
									@row-select="onRowSelect"
								>
									<template #empty> {{ isEmpty }} </template>
									<Column
										v-if="!isSupplierpage"
										class="call-button-container"
										header="Supplier"
										field="VENDOR_NAME"
										:sortable="true"
									></Column>
									<Column
										header="PO Number"
										class="call-button-container"
										field="SEGMENT1"
										:sortable="true"
									>
										<template #body="slotProps">
											<a
												@click="
													goToPOFulfilment(
														slotProps.data
													)
												"
												>{{
													slotProps.data.SEGMENT1
												}}</a
											>
										</template>
									</Column>
									<Column
										class="call-button-container"
										header="Line"
										field="LINE_NUM"
										:sortable="true"
									></Column>
									<Column
										class="call-button-container"
										header="Manufacturer"
										field="MANUFACTURER"
										:sortable="true"
									></Column>
									<Column
										class="call-button-container"
										header="Item Number"
										field="ITEM_NUMBER"
										:sortable="true"
									></Column>
									<Column
										class="call-button-container"
										header="Item Description"
										field="ITEM_DESCRIPTION"
										:sortable="true"
									>
									</Column>
									<Column
										class="call-button-container"
										header="UOM"
										field="UOM"
										:sortable="true"
									>
									</Column>
									<Column
										class="call-button-container"
										header="Qty Order"
										field="QUANTITY"
										:sortable="true"
									></Column>
									<Column
										class="call-button-container"
										header="Qty Open"
										field="QUANTITY_OPEN"
										:sortable="true"
									></Column>
									<Column
										class="call-button-container"
										header="Arrival at Site"
										field="PROMISED_DATE"
										:sortable="true"
									>
									</Column>
									<Column
										v-if="isSupplierpage"
										class="call-button-container"
										header="Est Arrival at Site"
									>
										<template #body="slotProps">
											<Calendar
												:showIcon="true"
												:showButtonBar="true"
												id="dateformat"
												v-model="
													slotProps.data
														.ESTIMATE_SUPPLIER_DATE
												"
												dateFormat="dd-M-yy"
												style="text-align: center"
											/>
										</template>
									</Column>
									<Column
										v-if="!isSupplierpage"
										header="Est. Arrival at Site"
										class="call-button-container"
										field="ESTIMATE_SUPPLIER_DATE"
										:sortable="true"
									>
										<template #body="{ data }">
											{{
												new Date(
													data.ESTIMATE_SUPPLIER_DATE
												).toLocaleDateString('id-ID', {
													year: 'numeric',
													month: 'short',
													day: 'numeric',
												})
											}}
										</template>
									</Column>
									<Column
										class="call-button-container"
										header="L/O/E"
										field="LOE"
										:sortable="true"
									></Column>
									<Column
										v-if="!isSupplierpage"
										class="call-button-container"
										header="Reason for not Ontime"
										field="REMARKS"
										:sortable="true"
										style="width: 600px"
									></Column>
									<Column
										class="call-button-container"
										header="Ship To"
										field="SHIP_TO_NAME"
										:sortable="true"
									></Column>
									<Column
										v-if="isSupplierpage"
										class="call-button-container"
										header="Reason for not Ontime"
										:sortable="true"
										style="width: 600px"
									>
										<template #body="slotProps">
											<!-- <Textarea v-model="slotProps.data.remarks3" :autoResize="true" rows="5" cols="30" /> -->
											<InputText
												type="text"
												v-model="slotProps.data.REMARKS"
											/>
										</template>
									</Column>
									<Column
										v-if="isSupplierpage"
										class="call-button-container"
										header="Remarks"
										field="NOTE_TO_VENDOR"
										:sortable="true"
									></Column>
									<Column
										v-if="!isSupplierpage"
										class="call-button-container"
										header="Remarks"
										:sortable="true"
									>
										<template #body="slotProps">
											<!-- <Textarea v-model="slotProps.data.remarks3" :autoResize="true" rows="5" cols="30" /> -->
											<InputText
												type="text"
												v-model="
													slotProps.data
														.NOTE_TO_VENDOR
												"
											/>
										</template>
									</Column>
									<!-- <Column
										class="call-button-container"
										header="Jit Status"
										field="JIT_FLAG"
										:sortable="true"
									></Column> -->
									<Column
										class="call-button-container"
										header="Last Confirm Time"
										field="CONFIRM_DATE"
										:sortable="true"
									>
									</Column>
								</DataTable>
							</TabPanel>
							<TabPanel
								header="PO From Oracle"
								v-if="!isSupplierpage"
							>
								<DataTable
									:value="oracleTable"
									responsiveLayout="scroll"
									:lazy="true"
									:paginator="true"
									:rows="rowsOracle"
									:rowsPerPageOptions="[10, 20, 50]"
									removableSort
									:metaKeySelection="false"
									:scrollable="true"
									scrollHeight="600px"
									:loading="loading"
									:totalRecords="totalRecordsOracle"
									paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
									currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
									selectionMode="multiple"
									@page="onPage($event)"
									@sort="onSort($event)"
								>
									<Column
										v-for="col of oracleList"
										:field="col.header"
										:header="col.header"
										:key="col.header"
										style="width: 200px"
									></Column>
								</DataTable>
							</TabPanel>
						</TabView>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable */

import AuthService from '../../service/AuthService';
import LovService from '../../service/LovService';
import SimpleCrypto from 'simple-crypto-js';
import Roo from '../../service/RooServices';
import { UrlTab } from '../../constants/UrlTab';
export default {
	name: 'registerOutstandingOrder',
	data() {
		return {
			isSupplierpage: false,
			first: 0,
			rows: 10,
			rowsOracle: 10,
			rowId: null,
			selectedPo: [],
			expandedRows: [],
			purchaseOrders: null,
			nested: [],
			nestedchildren: [],
			groupdata: null,
			selectedChildren: null,
			loading: false,
			lazyParams: {},
			selectLazyParams: {},
			totalRecords: 0,
			totalRecordsOracle: 0,
			totalRecordsGrup: 0,
			isSaveClick: false,
			company: null,
			confirmButton: true,
			companyValue: true,
			filteredCompany: null,
			isiCompany: null,
			supplier: null,
			isisupplier: null,
			filteredSupplier: null,
			selectedSupplier: null,
			supplierparse: null,
			search: null,
			filtered: null,
			button: 0,
			buttonSave: true,
			buttonConfirm: true,
			items: null,
			planningOrder: null,
			planningOrderTo: null,
			filteredPlanningOrderTo: null,
			isiPlanningOrder: null,
			filteredPlanningOrder: null,
			selectedPlanningOrder: null,
			payloadSearch: null,
			saveDb: null,
			idUser: null,
			payloadEmail: null,
			recipientMail: null,
			forEmail: null,
			preparerButton: true,
			draftTableList: [],
			suppBindToComp: [],
			isEmpty: null,
			dateTo: null,
			dateFrom: null,
			oracleTable: null,
			oracleList: [],
			tabName: 0,
			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [
				{
					label: 'Register Outstanding Order',
					to: '/mgm/settings/register',
				},
			],
		};
	},
	watch: {
		selectedPo(event) {
			if (event.length > 0) {
				this.buttonSave = false;
				this.confirmButton = false;
			} else {
				this.buttonSave = true;
				this.confirmButton = true;
			}
		},
		company(newQuestion) {
			this.planningOrder = null;
			this.planningOrderTo = null;
			this.dateFrom = null;
			this.supplier = null;
			this.dateTo = null;
			if (this.company != null) {
				sessionStorage.setItem('mailToCompany', this.company.name);
				this.companyValue = true;

				if (
					typeof newQuestion === 'object' &&
					!Array.isArray(newQuestion) &&
					newQuestion !== null
				) {
					this.getValueCompany(newQuestion.name);
					this.companyValue = false;
					this.getPoNumSupp();

					//
					// sessionStorage.setItem('companydb', this.saveDb);

					if (sessionStorage.leveltenant == 2) {
						if (sessionStorage.leveltenant == 2) {
							this.getValueCompany(newQuestion.name);
						}
					} else {
						this.buttonDisable = false;
					}
				}
			}
		},
	},

	computed: {
		isButtonSearchDisable() {
			if (this.isSupplierpage && !this.supplier) {
				return true;
			} else if (this.isSupplierpage) {
				return (
					!this.supplier &&
					!this.planningOrder &&
					!this.planningOrderTo &&
					!this.dateFrom &&
					!this.dateTo
				);
			} else {
				return (
					!this.company &&
					!this.supplier &&
					!this.planningOrder &&
					!this.planningOrderTo &&
					!this.dateFrom &&
					!this.dateTo
				);
			}
		},
	},

	created() {
		if (sessionStorage.leveltenant == 2) {
			AuthService.whoAmi().then((resp) => {
				this.idUser = resp.data.data.id;
				Roo.getSuppBindComp(resp.data.data.id).then((response) => {
					this.suppBindToComp.push(
						response.data.data[0][0].vendorname
					);
					this.suppName = response.data.data[0][0].vendorid;
					console.log('cek vendorid', this.suppName);

					// const payload = {
					//   vendorName: response.data.data[0][0].vendorname,
					// };

					// Roo.getSuppValue(payload).then((respond) => {
					//   this.suppName = respond.data.data[0].vendor_id;

					// });
				});
			});
		}
	},
	async mounted() {
		AuthService.whoAmi().then((resp) => {
			if (resp.status === 200) {
				this.getCompany();
				this.getPoNumber();
				if (resp.data.data.leveltenant == 2) {
					this.isSupplierpage = true;
					this.getValueCompany();
				}
			}
		});
		this.isEmpty = 'Please Search for Data';
		// this.loading = true;
		this.lazyParams = {
			first: 0,
			rows: this.rows,
			sortField: null,
			sortOrder: null,
		};
		this.selectLazyParams = {
			first: 0,
			sortField: null,
			sortOrder: null,
			rows: this.rows,
		};
	},
	handleSave() {
		const selectedPoToAdd = this.selectedPo;
		sessionStorage.setItem(
			'poForCreateShipment',
			JSON.stringify(selectedPoToAdd)
		);
	},

	methods: {
		printPage() {
			let url = UrlTab() + "/printroo";
			let paramsPrintROO = {
				orgId: this.company.value,
				vendorId: (this.supplier?.vendor_id ?? this.supplier) || null,
				dbInstance: this.company.db_instance,
				poNumberFrom:
					(this.planningOrder?.PO_KEY_ID ?? this.planningOrder) ||
					null,
				poNumberTo:
					(this.planningOrderTo?.PO_KEY_ID ?? this.planningOrderTo) ||
					null,
				dateFrom: this.dateFrom || null,
				dateTo: this.dateTo || null
			};

			sessionStorage.setItem(
				'paramsPrintROO',
				JSON.stringify(paramsPrintROO)
			);
			window.open(url, 'Print', 'height=720,width=1280');
		},

		// handleSave() {
		//   const selectedPoToAdd = this.selectedPo;

		//   sessionStorage.setItem(
		//     "poForCreateShipment",
		//     JSON.stringify(selectedPoToAdd)
		//   );
		// },

		onPage(event) {
			if (this.tabName == 0) {
				this.lazyParams = event;
				this.handleSearchPo(false);
			} else if (this.tabName == 1) {
				this.handleSearchPoOracle(event);
			}
		},
		onSort(event) {
			if (this.tabName == 0) {
				this.lazyParams = event;
				this.handleSearchPo(false);
			} else if (this.tabName == 1) {
				this.handleSearchPoOracle(event);
			}
		},
		onRowSelect() {
			if (this.selectedPo.length == 0) {
				this.buttonDisable = true;
			} else {
				this.buttonDisable = false;
			}
		},
		tableFromOracle(queries, payloadSearch) {
			Roo.oracleData(queries, payloadSearch).then((resp) => {
				if (resp.data.status === 200) {
					this.oracleTable = resp.data.data;
					this.totalRecordsOracle = resp.data.totalData;
					this.oracleList = [];
					for (let idx of Object.keys(this.oracleTable[0])) {
						//
						this.oracleList.push({
							header: idx,
							field: idx,
						});
					}
				} else {
					this.oracleTable = [];
					this.totalRecordsOracle = 0;
					this.oracleList = [];
				}
			});
		},
		async handleSearchPo(buttonStatus) {
			this.loading = true;
			this.selectedPo = false;
			this.loading = true;
			const queriesToBe = {
				page: this.lazyParams.first,
				limitPage: this.lazyParams.rows,
				sortField: this.lazyParams.sortField,
				sortOrder: this.lazyParams.sortOrder,
			};

			
			// Check if payload is change, then reset pagination
			if (this.payloadSearch) {
				if (
					this.payloadSearch.orgId !== this.company.value ||
					this.payloadSearch.vendorId !==
						(this.supplier?.vendorId ?? '') ||
					this.payloadSearch.dbInstance !==
						this.supplier.dbInstance ||
					this.payloadSearch.poNumberFrom !==
						(this.poNumberFrom?.SEGMENT1 ?? '') ||
					this.payloadSearch.poNumberTo !==
						(this.poNumberTo?.SEGMENT1 ?? '') ||
					this.payloadSearch.dateFrom !== (this.dateFrom ?? '') ||
					this.payloadSearch.dateTo !== (this.dateTo ?? '')
					// this.payloadSearch.itemDescription !== (this.itemDescription ?? "")
				) {
					// Reset pagination
					queriesToBe.page;
				}
			}
			const queries = {
				query: JSON.stringify(queriesToBe),
			};
			// Set payload
			this.payloadSearch = {
				orgId: this.company.value,
				vendorId: (this.supplier?.vendor_id ?? this.supplier) || null,
				dbInstance: this.company.db_instance,
				poNumberFrom: this.planningOrder?.PO_KEY_ID || null,
				poNumberTo: this.planningOrderTo?.PO_KEY_ID || null,
				dateFrom: this.dateFrom || null,
				dateTo: this.dateTo || null,
				...queriesToBe
			};

			if (buttonStatus) {
				this.handleSearchPoOracle(this.lazyParams);
			}

			console.log(this.planningOrder?.PO_KEY_ID, 'PLANNING ORDER')
			console.log(this.payloadSearch, "PAY SEARCH")

			// console.log(this.payloadSearch)
			const res = await Roo.getListPo(this.payloadSearch);

			if (res.status === 200 && res.data.status === 200) {
				let number = 0;
				this.purchaseOrders = res.data.data.map((po) => {
					number++;
					po.PROMISED_DATE_LOE = po.PROMISED_DATE;
					po.PROMISED_DATE = new Date(
						po.PROMISED_DATE
					).toLocaleDateString('id-ID', {
						year: 'numeric',
						month: 'short',
						day: 'numeric',
					});
					po.CONFIRM_DATE = new Date(
						po.CONFIRM_DATE
					).toLocaleDateString('id-ID', {
						year: 'numeric',
						month: 'short',
						day: 'numeric',
					});
					po.ESTIMATE_SUPPLIER_DATE = new Date(
						po.ESTIMATE_SUPPLIER_DATE
					);
					// po.ESTIMATE_SUPPLIER_DATE = new Date(
					//   po.ESTIMATE_SUPPLIER_DATE
					// ).toLocaleDateString("id-ID", {
					//   year: "numeric",
					//   month: "short",
					//   day: "numeric",
					// });
					if (po.PROMISED_DATE === '1 Jan 1970') {
						po.PROMISED_DATE = '';
					}
					if (po.ESTIMATE_SUPPLIER_DATE === '1 Jan 1970') {
						po.ESTIMATE_SUPPLIER_DATE = '';
					}
					if (po.CONFIRM_DATE === '1 Jan 1970') {
						po.CONFIRM_DATE = '';
					}
					if (po.REMARKS === 'null' || po.REMARKS === null) {
						po.REMARKS = '';
					}
					return {
						id: number,
						...po,
					};
				});
				this.totalRecords = res.data.totalData;
			} else if (res.data.status === 202) {
				this.purchaseOrders = null;
				this.totalRecords = 0;
				this.isEmpty = 'No Data Found';
			}
			this.loading = false;
		},

		async handleSearchPoOracle(event) {
			this.loading = true;
			this.selectedPo = false;
			const queriesToBe = {
				page: event.first,
				limitPage: event.rows,
				sortField: event.sortField,
				sortOrder: event.sortOrder,
			};
			// Check if payload is change, then reset pagination
			if (this.payloadSearch) {
				if (
					this.payloadSearch.orgId !== this.company.value ||
					this.payloadSearch.vendorId !==
						(this.supplier?.vendorId ?? '') ||
					this.payloadSearch.dbInstance !==
						this.supplier.dbInstance ||
					this.payloadSearch.poNumberFrom !==
						(this.poNumberFrom?.segment1 ?? '') ||
					this.payloadSearch.poNumberTo !==
						(this.poNumberTo?.segment1 ?? '') ||
					this.payloadSearch.dateFrom !== (this.dateFrom ?? '') ||
					this.payloadSearch.dateTo !== (this.dateTo ?? '')
					// this.payloadSearch.itemDescription !== (this.itemDescription ?? "")
				) {
					// Reset pagination
					// queriesToBe.page = 1;
				}
			}
			const queries = {
				query: JSON.stringify(queriesToBe),
			};
			// Set payload
			this.payloadSearch = {
				orgId: this.company.value,
				vendorId: (this.supplier?.vendor_id ?? this.supplier) || null,
				dbInstance: this.company.db_instance,
				poNumberFrom:this.planningOrder?.PO_KEY_ID ?? null,
				poNumberTo:this.planningOrderTo?.PO_KEY_ID ?? null,
				dateFrom: this.dateFrom || null,
				dateTo: this.dateTo || null,
				...queriesToBe
				// itemDescription: this.itemDescription || "",
			};

			this.tableFromOracle(queries, this.payloadSearch);
			this.loading = false;
		},

		getCompany() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					if (value.data.data.leveltenant == 1) {
						if (value.data.data.userid == 'dexaadmin') {
							LovService.getValueType('COMPANY').then(
								(response) => {
									this.isiCompany = response.data.data;
									// console.log("dawdsdaw",this.isiCompany)
								}
							);
						} else {
							console.log('User', value.data.data);
							let payload = value.data.data.bioaddress;
							LovService.getcompanydb(payload).then((response) => {
									// this.isiCompany = response.data.data
									// console.log("ISI COMPANY", this.isiCompany)
									// let payload = {isi : response.data.data[0].company}
									// console.log("com[panmuy kirim", response.data.data)
									// PlanningOrder.getCompanyId(payload).then(response => {
									this.isiCompany = response.data.data;
									console.log('dawdawdaw', this.isiCompany);
									try {
										const secretKey = 'some-unique-key';
										const simpleCrypto = new SimpleCrypto(
											secretKey
										);
										const decipherText =
											simpleCrypto.decrypt(
												sessionStorage.getItem(
													'dropdownCompany'
												)
											);

										this.isiCompany.forEach((element) => {
											if (element.name === decipherText) {
												this.company = element;
												// this.searchTableNested();
												throw 'Break';
											}
										});
									} catch (e) {
										if (e !== 'Break') throw e;
									}

									// })
								}
							);
						}
					} else if (value.data.data.leveltenant == 2) {
						let payload = value.data.data.id;
						// PlanningOrder.getCompanySupp(payload).then(
						// 	(response) => {
						//
						// 		let company = [
						// 			{ name: response.data.data[0].company },
						// 		];
						// 		this.isiCompany = company;
						// 	}
						// );
						Roo.getCompanySupp(payload).then((response) => {
							let payload = response.data.data;
							let companyList = [];

							try {
								payload.forEach((element) => {
									LovService.getCompanyByName(
										element.company
									).then((response) => {
										companyList.push(response.data.data[0]);

										const secretKey = 'some-unique-key';
										const simpleCrypto = new SimpleCrypto(
											secretKey
										);
										const decipherText =
											simpleCrypto.decrypt(
												sessionStorage.getItem(
													'dropdownCompany'
												)
											);
										// let companyList = JSON.parse(sessionStorage.getItem('list'))
										// this.isiCompany = companyList;

										if (
											response.data.data[0].name ===
											decipherText
										) {
											this.company =
												response.data.data[0];
											// this.searchTableNested();
										}
									});
								});
								this.isiCompany = companyList;
							} catch (e) {
								if (e !== 'Break') throw e;
							}

							//
						});
					}
				}
			});
		},
		getValueCompany() {
			let payload = {
				db_instance: this.company.db_instance,
				org_id: this.company.value,
			};
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					LovService.getSupplierName(payload).then((response) => {
						this.isisupplier = response.data.data;

						//
						if (sessionStorage.getItem('leveltenant') == 2) {
							try {
								this.isisupplier.forEach((element) => {
									if (
										element.vendor_name ===
											this.suppBindToComp[0].toString() &&
										element.vendor_name !== null
									) {
										this.supplier = element;
										// this.searchTableNested();
										throw 'Break';
									}
								});
							} catch (e) {
								if (e !== 'Break') throw e;
							}
							//
						}
					});
				}
			});
		},

		searchCompany(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredCompany = [...this.isiCompany];
				} else {
					this.filteredCompany = this.isiCompany.filter((item) => {
						return item.name
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		onPage(event) {
			console.log(event, "EVENT")
			this.lazyParams = event;
			this.handleSearchPo(false);
			//
		},
		onSort(event) {
			this.lazyParams = event;
			this.handleSearchPo(false);
		},
		onRowSelect() {},

		// sendNotifToPur() {
		//   // NGIRIM EMAIL
		//   Roo.sendPOEmail(this.forEmail).then((response) => {
		//     if (response.data.status == 200) {
		//       // location.reload();
		//     }
		//   });
		// },
		async getEmailToPur() {
			// DAPETIN EMAIL RECIPIENT/COMPANY
			const data = {
				module: 'ROOToPur',
				idUser: this.idUser,
				companyname: this.company.name,
				payloadEmail: this.payloadEmail,
			};

			const respond = await Roo.getRecipientMail(data);
			if (respond.data.status == 200) {
				this.dbInstance = respond.data.data[0][0]['DB_INSTANCE'];
				var tampungEmail = [];
				// var getOrgId = null;
				var suppliername = null;
				respond.data.data[0].forEach((element) => {
					tampungEmail.push(element.targetemailgroup);
					// if (getOrgId === null) {
					// 	getOrgId = element.id;
					suppliername = element.vendorname;
					// }
				});
				let recipientMail = tampungEmail
					.toString()
					.replace(/"/g, '')
					.replace(/[\[\]']+/g, '')
					.replace(/ /g, '')
					.replace(/,/g, ', ');
				this.vendorname = suppliername;
				// this.orgIdNotif = getOrgId;
				//

				// this.forEmail = {
				//   payloadEmail: this.payloadEmail,
				//   emails: recipientMail,
				// };
				// this.sendNotifToPur();
				return respond;
			}
		},

		async searchSupplier(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredSupplier = [...this.isisupplier];
				} else {
					this.filteredSupplier = this.isisupplier.filter((item) => {
						//
						if (item.vendor_name !== null) {
							return item.vendor_name
								.toLowerCase()
								.match(event.query.toLowerCase());
						}
					});
				}
			}, 250);
		},

		async searchPoNumber(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredPlanningOrder = [...this.searchPoNumber];
				} else {
					this.filteredPlanningOrder = this.isiPlanningOrder.filter(
						(item) => {
							if (item.SEGMENT1 !== null) {
								return item.SEGMENT1
									.toLowerCase()
									.match(event.query.toLowerCase());
							}
						}
					);
				}
			}, 250);
		},
		async searchPoNumberTo(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredPlanningOrderTo = [...this.isiPlanningOrder];
				} else {
					this.filteredPlanningOrderTo = this.isiPlanningOrder.filter(
						(item) => {
							if (item.SEGMENT1 !== null) {
								return item.SEGMENT1
									.toLowerCase()
									.match(event.query.toLowerCase());
							}
						}
					);
				}
			}, 250);
		},

		getPoNumber() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					const payload = {
						orgId: this.company.value,
						vendorId: (this.supplier?.vendor_id ?? this.supplier) || null,
						dbInstance: this.company.db_instance,
					}
					// Roo.getPoNumberList(payload).then((response) => {
					// 	this.isiPlanningOrder = response.data.data;
					// });
				}
			});
		},

		goToPOFulfilment(rowIndex) {
			this.rowId = rowIndex;
			if (sessionStorage.leveltenant === 2) {
				this.$router.push({
					name: 'DetailShipmentNoticeSupp',
					params: { id: this.rowId.PO_KEY_ID },
				});
			} else {
				this.$router.push({
					name: 'DetailpoFulfillment',
					params: { id: this.rowId.PO_KEY_ID },
				});
			}
		},

		getPoNumSupp() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					let vendorId = '';
					let payload;

					if(sessionStorage.leveltenant == 2){
						vendorId = sessionStorage.dataVendorId;
						payload = {
							orgId: this.company.value,
							vendorId: vendorId,
							dbInstance: this.company.db_instance,
						}
					}else{
						payload = {
							orgId: this.company.value,
							vendorId: (this.supplier?.vendor_id ?? this.supplier) || null,
							dbInstance: this.company.db_instance,
						}
					}

					Roo.getPoNumberList(payload).then((response) => {
						this.isiPlanningOrder = response.data.data;
					});
				}
			});
		},
		//method get company by supplier
		getPoNumSuplier() {
			const payload = {
				supValue: this.supplier.vendor_id,
				org_id: this.company.value,
			};
			//
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					Roo.getSearchDropdownSupp(payload).then((response) => {
						//
						this.isiPlanningOrder = response.data.data;
					});
				}
			});
		},

		formatDateDexa(date) {
			var date = new Date(date);
			var respondDate =
				('00' + (date.getMonth() + 1)).slice(-2) +
				'/' +
				('00' + date.getDate()).slice(-2) +
				'/' +
				date.getFullYear() +
				' ' +
				('00' + date.getHours()).slice(-2) +
				':' +
				('00' + date.getMinutes()).slice(-2) +
				':' +
				('00' + date.getSeconds()).slice(-2);
			return respondDate;
		},
		getLoeValue(leo) {
			switch (leo) {
				case 'L':
					leo = 'Late';
					break;
				case 'O':
					leo = 'Ontime';
					break;
				case 'E':
					leo = 'Early';
					break;
				default:
			}
			return leo;
		},

		confirm() {
			AuthService.whoAmi().then(async (response) => {
				if (this.selectedPo.length == 0) {
					this.$toast.add({
						severity: 'warn',
						summary: 'PO Not Selected',
						detail: 'Please select PO',
						life: 3000,
					});
				} else {
					var datesave = this.formatDateDexa(new Date()); //variabel yang akan di gunakan di BE , new date() ngambil date pada saat di klik
					for (var id of this.selectedPo) {
						// pengaturan date dari dexa
						id.ESTIMATE_SUPPLIER_DATE = this.formatDateDexa(
							id.ESTIMATE_SUPPLIER_DATE
						);
						id.CONFIRM_DATE = this.formatDateDexa(new Date());

						if (id.REMARKS == 'null' || id.REMARKS == null) {
							id.REMARKS = '';
						}
						const resLoe = await Roo.getLoe({
							statDay: id.STAT_DAY,
							promisedDate: id.PROMISED_DATE_LOE,
							estSupplierDate: id.ESTIMATE_SUPPLIER_DATE,
						});
						id.LOE = resLoe.data.data.codeLoe;
						//
					}

					let payload = {
						//object Assign
						id: response.data.data.id,
						detail: this.selectedPo,
						date: datesave,
						module: 'ROOToPur',
						idUser: this.idUser,
						companyname: this.company.name,
						EMAIL_RECIPIENT: null,
					};
					// this.payloadEmail = {
					//   id: response.data.data.id,
					//   detail: this.selectedPo,
					//   date: datesave,
					// };

					//
					//

					Roo.confirmButton(payload).then(async (res) => {
						if (res.status === 200) {
							for (var id of this.selectedPo) {
								//format date yang di tampilkan di FE
								id.ESTIMATE_SUPPLIER_DATE = new Date(
									id.ESTIMATE_SUPPLIER_DATE
								);

								id.CONFIRM_DATE = new Date(
									id.CONFIRM_DATE
								).toLocaleDateString('id-ID', {
									year: 'numeric',
									month: 'short',
									day: 'numeric',
								});

								if (
									id.REMARKS == 'null' ||
									id.REMARKS == null
								) {
									id.REMARKS = '';
								}
								id.LOE = this.getLoeValue(id.LOE);
							}

							// const respond = await this.getEmailToPur();
							// if (respond.status === 200) {
							// window.alert("Supplier Successfully Notified");
							//
							this.$toast.add({
								severity: 'success',
								summary: 'Data Submitted',
								detail: 'Data Updated',
								life: 3000,
							});
							this.selectedPo = [];
							// }
						}
					});
				}
			});
		},

		savePur() {
			AuthService.whoAmi().then((response) => {
				if (this.selectedPo !== null || this.selectedPo.length > 0) {
					var datesave = this.formatDateDexa(new Date());
					for (var id of this.selectedPo) {
						if (
							id.NOTE_TO_VENDOR == 'null' ||
							id.NOTE_TO_VENDOR == null
						) {
							id.NOTE_TO_VENDOR = '';
						}
					}
					let payload = {
						id: response.data.data.id,
						detail: this.selectedPo,
						date: datesave,
					};

					Roo.saveButton(payload).then((res) => {
						if (res.status === 200) {
							for (var id of this.selectedPo) {
								if (
									id.NOTE_TO_VENDOR == 'null' ||
									id.NOTE_TO_VENDOR == null
								) {
									id.NOTE_TO_VENDOR = '';
								}
							}
							this.$toast.add({
								severity: 'success',
								summary: 'Status Change',
								detail: 'Data Saved',
								life: 3000,
							});
						} else {
							this.$toast.add({
								severity: 'warning',
								summary: 'Error Connection',
								detail: 'Please Check Your Connection',
								life: 3000,
							});
						}
					});
				} else {
					this.$toast.add({
						severity: 'warning',
						summary: 'PO Not Selected',
						detail: 'Please Selected PO',
						life: 3000,
					});
				}
			});
		},

		clearFilter() {
			// this.buttonSearch = true;
			AuthService.whoAmi().then((response) => {
				if (response.data.data.leveltenant == 1) {
					if (response.data.data.userid == 'dexaadmin') {
						this.planningOrder = null;
						this.planningOrderTo = null;
						this.supplier = null;
						this.purchaseOrders = null;
						this.oracleTable = null;
					} else {
						this.supplier = null;
						this.planningOrder = null;
						this.planningOrderTo = null;
						this.purchaseOrders = null;
						this.oracleTable = null;
						// this.searchTableNested();
					}
				}
				if (response.data.data.leveltenant == 2) {
					this.dateFrom = null;
					this.dateTo = null;
					this.planningOrder = null;
					this.planningOrderTo = null;
					this.purchaseOrders = null;
					this.oracleTable = null;
					// this.searchTableNested();
				}
			});
		},
	},
};
</script>
<style scoped lang="scss"></style>
