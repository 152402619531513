import spbInvoiceApi from "@/service/api/SpbInvoiceApi";

const getDetailCategories = async (dbInstance, orgId) => {
  const result = await spbInvoiceApi.get(
    `/spb/invoiceproxy/nonpo/detailcategories/${dbInstance}/${orgId}`
  );
  return result.data;
};

const getBillBanks = async (dbInstance, orgId, vendorId) => {
  const result = await spbInvoiceApi.get(
    `/spb/invoiceproxy/nonpo/billbanks/${dbInstance}/${orgId}/${vendorId}`
  );
  return result.data;
};

const getSTKNumber = async (dbInstance, orgId, vendorId) => {
  
  const payload = {
    db_instance: dbInstance,
    org_id: orgId,
    vendor_id: vendorId
  }

  const result = await spbInvoiceApi.post(
    `/spb/invoiceproxy/nonpo/stknumber`, payload
  );
  return result.data;
};

const getCurrencies = async (dbInstance) => {
  const result = await spbInvoiceApi.get(
    `/spb/invoiceproxy/nonpo/currencies/${dbInstance}`
  );
  return result.data;
};

const getPaymentMethods = async (dbInstance, vendorId) => {
  const result = await spbInvoiceApi.get(
    `/spb/invoiceproxy/nonpo/paymentmethods/${dbInstance}/${vendorId}`
  );
  return result.data;
};

const getWHTList = async (dbInstance, orgId) => {
  const result = await spbInvoiceApi.get(
    `/spb/invoiceproxy/nonpo/wht/${dbInstance}/${orgId}`
  );
  return result.data;
};

// const getVATList = async (dbInstance, param, invoiceDate) => {
//   const result = await spbInvoiceApi.get(
//     `/spb/invoiceproxy/nonpo/vat/${dbInstance}/${param}/${invoiceDate}`
//   );
//   return result.data;
// };

const getVATList = async (payload) => {
  const result = await spbInvoiceApi.post(
    `/spb/invoiceproxy/nonpo/vatlist`,
    payload
  );
  return result.data;
};

const getCostcenter = async (dbInstance, orgId) => {
  const result = await spbInvoiceApi.get(
    `/spb/invoiceproxy/nonpo/costcenter/${dbInstance}/${orgId}`
  );
  return result.data;
};

const getCategoryVendorService = async (payload) => {
  return spbInvoiceApi.post(`/spb/invoiceproxy/nonpo/categoryvendor`, payload);
};

const getAvailableCategoryDetails = async (payload) => {
  return spbInvoiceApi.post(
    `/spb/invoiceproxy/nonpo/availablecategorydetails`,
    payload
  );
};

const getSKNumbers = async (payload) => {
  return spbInvoiceApi.post(`/spb/invoiceproxy/nonpo/sknumbers`, payload);
};

const getListCoa = async (orgId, dbInstance, costCenter) => {
  return spbInvoiceApi.get(
    `spb/invoiceproxy/nonpo/coa/${dbInstance}/${orgId}/${costCenter}`
  );
};

const getListTerm = async (dbInstance, vendorId) => {
  return spbInvoiceApi.get(
    `spb/invoiceproxy/nonpo/terms/${dbInstance}/${vendorId}`
  );
};

const getListGroup = async (dbInstance) => {
  return spbInvoiceApi.get(`spb/invoiceproxy/nonpo/group/${dbInstance}`);
};

const getListSupplierSite = async (userId, dbInstance, orgId, companyName) => {
  return spbInvoiceApi.get(
    `spb/invoiceproxy/nonpo/supplierlist/${userId}/${dbInstance}/${orgId}/${companyName}`
  );
};

const getAllSupplier = async (dbInstance) => {
  return spbInvoiceApi.get(`spb/invoiceproxy/nonpo/allsupplier/${dbInstance}`);
};

const saveInvoice = async (payload) => {
  return spbInvoiceApi.post(`spb/invoiceproxy/nonpo/create`, payload);
};

const saveInvoiceSP1 = async (payload) => {
  return spbInvoiceApi.post(`spb/invoiceproxy/nonpo/createsp1`, payload);
};

const getCCOwner = async (payload) => {
  return spbInvoiceApi.post(`spb/searchinvoice/searchccowner`, payload)
}

const getDetailCostCenter = async (payload) => {
  return spbInvoiceApi.post(`spb/invoiceproxy/nonpo/getDetailCostCenter`, payload)
}

const getCOASLDetail = async (payload) => {
  return spbInvoiceApi.post(`spb/invoiceproxy/nonpo/getCOASLDetail`, payload)
}

const getInvoiceNumberPrepayment = async (payload) => {
  return spbInvoiceApi.post(`spb/invoiceproxy/getInvoiceNumberPrepayment`, payload)
}

const getInvoiceDetailPrepayment = async (payload) => {
  return spbInvoiceApi.post(`spb/invoiceproxy/getInvoiceDetailPrepayment`, payload)
}

const getFilterKINumber = async (payload) => {
  return spbInvoiceApi.post(`spb/invoiceproxy/getFilterKINumber`, payload)
}


const getDataSP1KI = async (payload) => {
  return spbInvoiceApi.post(`spb/invoiceproxy/getDataSP1KI`, payload)
}

const getDetailCOAID = async (payload) => {
  return spbInvoiceApi.post(`spb/invoiceproxy/getDetailCOAID`, payload)
}

const findCoaByNumber = async (payload) => {
  return spbInvoiceApi.post(`spb/invoiceproxy/findCoaByNumber`, payload)
}

const getEinvoiceID = async (payload) => {
  return spbInvoiceApi.post(`spb/invoiceproxy/getEinvoiceID`, payload)
}

export {
  getDetailCategories,
  getBillBanks,
  getCurrencies,
  getPaymentMethods,
  getWHTList,
  getVATList,
  getCostcenter,
  getCategoryVendorService,
  getAvailableCategoryDetails,
  getSKNumbers,
  getListCoa,
  getListTerm,
  getListGroup,
  getListSupplierSite,
  saveInvoice,
  saveInvoiceSP1,
  getAllSupplier,
  getCCOwner,
  getSTKNumber,
  getDetailCostCenter,
  getInvoiceNumberPrepayment,
  getInvoiceDetailPrepayment,
  getFilterKINumber,
  getDetailCOAID,
  getEinvoiceID,
  getDataSP1KI,
  getCOASLDetail,
  findCoaByNumber
};
