<template>
	<!-- eslint-disable -->
	<div class="grid">
		<ProgressModal :progress="progress">
			<template #message>
				Save data in progress.<br />Please wait...
			</template>
		</ProgressModal>
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<!-- Shipment Info -->
		<div class="col-12 p-fluid mb-4">
			<div class="content-wrapper">
				<form
					@submit.prevent="handleSubmit(!v$.$invalid)"
					class="p-fluid"
				>
					<div class="content-header">Detail List Invoice Non PO</div>
					<div class="content-body">
						<div class="grid formgrid">
							<div class="col-6 mb-0 lg:col-2 lg:mb-0">
								<CompaniesDropdown
									v-model="company"
									class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
									disabled
								/>
							</div>
							<!-- <div class="col-6 mb-0 lg:col-2 lg:mb-0">
                            <Supplier :db_instance="db_instance?db_instance:null" :bankId="companyToBankAccount?companyToBankAccount:null"
                            :org_id="org_id?org_id:null" :vendor_id="vendor_id?vendor_id:null" @company="getcompany" :key="reload" 
                            v-model="v$.company.$model" :class="{'p-invalid':v$.company.$invalid && submitted}"
                            />
                            <small v-if="(v$.company.$invalid && submitted) || v$.company.$pending.$response" class="p-error">{{v$.company.required.$message.replace('Value', 'company Value')}}</small>
                        </div> -->
						</div>
						<hr />
						<div
							class="p-fluid grid formgrid"
							style="margin-top: 30px"
						>
							<div class="col-12 mb-4 md:col-6 lg:col-4 lg:mb-5">
								<span class="p-float-label">
									<span class="p-float-label">
										<InputText
											id="username"
											type="text"
											v-model="v$.invoiceNumber.$model"
											:class="{
												'p-invalid':
													v$.invoiceNumber.$invalid &&
													submitted,
											}"
											:disabled="
												this.displayStatus ||
												this.userInfo.leveltenant !== 2
											"
										/>
										<label for="username"
											>Invoice Number</label
										>
									</span>
									<small
										v-if="
											(v$.invoiceNumber.$invalid &&
												submitted) ||
											v$.invoiceNumber.$pending.$response
										"
										class="p-error"
										>{{
											v$.invoiceNumber.required.$message.replace(
												'Value',
												'Invoice Number Value'
											)
										}}</small
									>
								</span>
							</div>
							<div class="col-12 mb-4 md:col-6 lg:col-4 lg:mb-5">
								<span class="p-float-label">
									<InputText
										v-if="invoiceDate && invoiceDate.getFullYear() <= 2024"
										id="inputmask"
										type="text"
										v-model="v$.taxInvoiceNumber.$model"
										:class="{
											'p-invalid':
												v$.taxInvoiceNumber.$invalid &&
												submitted,
										}"
										:disabled="
											this.displayStatus ||
											this.userInfo.leveltenant !== 2
										"
									/>
									<label for="inputtext"
										>Tax Invoice No</label
									>
								</span>
								<small
									v-if="
										(v$.taxInvoiceNumber.$invalid &&
											submitted) ||
										v$.taxInvoiceNumber.$pending.$response
									"
									class="p-error"
									>{{
										v$.taxInvoiceNumber.required.$message.replace(
											'Value',
											'Tax Invoice Number Value'
										)
									}}</small
								>
							</div>
							<div class="col-12 mb-4 md:col-6 lg:col-4 lg:mb-5">
								<span class="p-float-label">
									<InputText
										id="inputtext"
										type="text"
										v-model="v$.status.$model"
										:class="{
											'p-invalid':
												v$.status.$invalid && submitted,
										}"
										disabled
									/>
									<label for="inputtext">Status</label>
								</span>
								<small
									v-if="
										(v$.status.$invalid && submitted) ||
										v$.status.$pending.$response
									"
									class="p-error"
									>{{
										v$.status.required.$message.replace(
											'Value',
											'Status Value'
										)
									}}</small
								>
							</div>
							<div class="col-12 mb-4 md:col-6 lg:col-4 lg:mb-5">
								<span class="p-float-label">
									<Calendar
										:showIcon="true"
										:showButtonBar="true"
										id="dateformat"
										dateFormat="dd M yy"
										style="text-align: center"
										v-model="v$.invoiceDate.$model"
										:class="{
											'p-invalid':
												v$.taxInvoiceDate.$invalid &&
												submitted,
										}"
										:disabled="
											this.displayStatus ||
											this.userInfo.leveltenant !== 2
										"
									></Calendar>
									<label for="inputtext">Invoice Date</label>
								</span>
								<small
									v-if="
										(v$.invoiceDate.$invalid &&
											submitted) ||
										v$.invoiceDate.$pending.$response
									"
									class="p-error"
									>{{
										v$.invoiceDate.required.$message.replace(
											'Value',
											'Invoice Date Value'
										)
									}}</small
								>
							</div>
							<div class="col-12 mb-4 md:col-6 lg:col-4 lg:mb-5">
								<span class="p-float-label">
									<Calendar
										:showIcon="true"
										:showButtonBar="true"
										id="dateformat"
										dateFormat="dd M yy"
										style="text-align: center"
										v-model="v$.taxInvoiceDate.$model"
										:class="{
											'p-invalid':
												v$.taxInvoiceDate.$invalid &&
												submitted,
										}"
										:disabled="
											this.displayStatus ||
											this.userInfo.leveltenant !== 2
										"
									></Calendar>
									<label for="inputtext"
										>Tax Invoice Date</label
									>
								</span>
								<small
									v-if="
										(v$.taxInvoiceDate.$invalid &&
											submitted) ||
										v$.taxInvoiceDate.$pending.$response
									"
									class="p-error"
									>{{
										v$.taxInvoiceDate.required.$message.replace(
											'Value',
											'Tax Invoice Date Value'
										)
									}}</small
								>
							</div>
							<div class="col-6 mb-4 md:col-3 lg:col-2 lg:mb-5">
								<span class="p-float-label">
									<InputText
										id="inputtext"
										type="text"
										v-model="v$.refNumber.$model"
										:class="{
											'p-invalid':
												v$.refNumber.$invalid &&
												submitted,
										}"
										disabled
									/>
									<label for="inputtext"
										>Reference Number</label
									>
								</span>
								<small
									v-if="
										(v$.refNumber.$invalid && submitted) ||
										v$.refNumber.$pending.$response
									"
									class="p-error"
									>{{
										v$.refNumber.required.$message.replace(
											'Value',
											'Reference Number Value'
										)
									}}</small
								>
							</div>
							<div class="col-6 mb-4 md:col-3 lg:col-2 lg:mb-5">
								<Button
									:class="uppercase"
									class="p-button-secondary"
									label="Find SK"
									@click="findSK()"
									:disabled="
										this.displayStatus ||
										this.userInfo.leveltenant !== 2 ||
										!vendorId
									"
								>
								</Button>
							</div>
							<div class="col-12 mb-4 md:col-6 lg:col-4 lg:mb-5">
								<span class="p-float-label">
									<Textarea
										id="inputtext"
										rows="5"
										cols="30"
										type="text"
										v-model="v$.description.$model"
										:class="{
											'p-invalid':
												v$.description.$invalid &&
												submitted,
										}"
										:disabled="
											this.displayStatus ||
											this.userInfo.leveltenant !== 2
										"
									/>

									<label for="inputtext">Description</label>
								</span>
								<small
									v-if="
										(v$.description.$invalid &&
											submitted) ||
										v$.description.$pending.$response
									"
									class="p-error"
									>{{
										v$.description.required.$message.replace(
											'Value',
											'Description Value'
										)
									}}</small
								>
							</div>
							<div class="col-12 mb-4 md:col-6 lg:col-4 lg:mb-5">
								<Bank
									:db_instance="
										db_instance ? db_instance : null
									"
									:bankId="
										companyToBankAccount
											? companyToBankAccount
											: null
									"
									@remit="getBank"
									:org_id="org_id ? org_id : null"
									:vendor_id="vendorId ? vendorId : null"
									:key="reload"
									:disabled="
										displayStatus ? displayStatus : null
									"
									:level_tenant="
										userInfo ? userInfo.leveltenant : null
									"
									v-model="v$.bank.$model"
									:class="{
										'p-invalid':
											v$.bank.$invalid && submitted,
									}"
								/>
								<small
									v-if="
										(v$.bank.$invalid && submitted) ||
										v$.bank.$pending.$response
									"
									class="p-error"
									>{{
										v$.bank.required.$message.replace(
											'Value',
											'Bank Value'
										)
									}}</small
								>
							</div>
							<div class="col-12 mb-4 md:col-6 lg:col-4 lg:mb-5">
								<span class="p-float-label">
									<Textarea
										id="inputtext"
										rows="5"
										cols="30"
										type="text"
										v-model="v$.reject.$model"
										:class="{
											'p-invalid':
												v$.reject.$invalid && submitted,
										}"
										:disabled="
											this.displayStatus ||
											this.userInfo.leveltenant !== 2
										"
									/>
									<label for="inputtext">Reject Reason</label>
								</span>
								<small
									v-if="
										(v$.reject.$invalid && submitted) ||
										v$.reject.$pending.$response
									"
									class="p-error"
									>{{ 'Reject Value is Required' }}</small
								>
							</div>
						</div>
						<div
							class="grid formgrid"
							style="display: flex; justify-content: flex-end"
						>
							<div
								class="col-6 mb-4 lg:col-8 lg:mb-8"
								style="margin-left: 10px"
							>
								<span
									style="color: #000; font-size: 18px"
									class="font-semibold"
									>Attachment :</span
								>
								<div v-if="savedFile" class="grid formgrid">
									<div class="col-4 mb-2 lg:col-4 lg:mb-8">
										<!-- <a
                                        class="button-download p-button"
                                        :href="attachmentUrl"
                                    >
                                        <span style="margin:auto" class="font-bold" >
                                            <i class="pi pi-download mr-2" />
                                            {{ attachmentName }}
                                        </span>
                                    </a> -->
										<Button
											v-if="!loadingdownloadFile"
											class="button-download font-normal"
											:label="attachmentName"
											icon="pi pi-download"
											@click="onDownload()"
										/>
										<Button
											v-if="loadingdownloadFile"
											class="button-download font-normal"
											:label="`${attachmentName} is downloading`"
											icon="pi pi-download"
											:disabled="true"
										/>
									</div>
									<div class="col-2 mb-2 lg:col-2 lg:mb-4">
										<Button
											:class="uppercase"
											icon="pi pi-times"
											class="p-button-rounded p-button-danger p-button-text"
											@click="deleteFile()"
										>
										</Button>
									</div>
								</div>
							</div>

							<div class="col-12 mb-4 md:col-6 lg:col-4 lg:mb-5">
								<span class="p-float-label">
									<Textarea
										id="inputtext"
										rows="5"
										cols="30"
										type="text"
										v-model="approval"
										disabled
									/>
									<label for="inputtext"
										>Reject/Approval Reason</label
									>
								</span>
							</div>
						</div>

						<div></div>

						<div
							class="p-fluid grid formgrid"
							style="margin-top: 30px"
						>
							<!-- <div class="col-2 mb-1 lg:col-1 lg:mb-1" style="margin-left=10px" >
                        <span style="color: #000;font-size:18px" class="font-semibold">Attachment :</span>
                    </div> -->

							<!-- <div v-if="savedFile" class="col-2 mb-2 lg:col-1 lg:mb-1" style="margin-right=10px" >
                        <span style="color: #000;font-size:20px" class="font-semibold">File :</span>
                    </div> -->
							<!-- <div v-if="savedFile" class="grid formgrid">
                            <div class="col-10 mb-5 lg:col-10 lg:mb-5">
                                <a
                                    class="button-download p-button"
                                    :href="attachmentUrl"
                                >
                                    <span style="margin:auto" class="font-bold" >
                                        <i class="pi pi-download mr-2" />
                                        {{ attachmentName }}
                                    </span>
                                </a>
                            </div>
                            <div class="col-10 mb-5 lg:col-2 lg:mb-5">
                                <Button
                                :class="uppercase"
                                icon="pi pi-times"
                                class="p-button-rounded p-button-danger p-button-text"
                                @click="deleteFile()"
                                >
                                </Button>
                            </div>	
                    </div>  -->
							<div v-if="!savedFile">
								<FileUpload
									id="uploadFile"
									mode="advanced"
									@select="onSelectFile"
									chooseLabel="Upload File"
									:multiple="false"
									:customUpload="true"
									accept=".pdf"
									:showUploadButton="false"
									:showCancelButton="false"
									:fileLimit="1"
									:maxFileSize="5000000"
									:disabled="
										this.displayStatus ||
										this.userInfo.leveltenant !== 2
									"
								>
									<template #empty>
										<p>No file selected. (Max file 5MB)</p>
									</template>
								</FileUpload>
							</div>
						</div>

						<!-- <div style="display: flex; justify-content: flex-end; margin-top:-70px"> -->

						<!-- </div> -->
						<hr />

						<div class="grid">
							<div class="col-12 md:col-6 p-fluid">
								<div
									class="col-2 mb-1 lg:col-1 lg:mb-1"
									style="margin-right: 10px"
								>
									<span
										style="color: #000; font-size: 20px"
										class="font-semibold"
										>Line</span
									>
								</div>
								<!-- <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid"> -->
								<div
									class="grid formgrid"
									style="margin-top: 20px"
								>
									<div class="col-8 mb-5 lg:col-6 lg:mb-5">
										<DetailCat
											v-if="
												company &&
												db_instance &&
												vendorId
											"
											:db_instance="
												db_instance ? db_instance : null
											"
											:categoryId="
												category ? category : null
											"
											:company="company ? company : null"
											:ref_num="
												refNumber ? refNumber : null
											"
											:data="idValue ? idValue : null"
											:level_tenant="
												this.userInfo.leveltenant
											"
											:supplier="
												vendorId ? vendorId : null
											"
											@detailCat="getDetailCat"
											:disabled="
												displayStatus
													? displayStatus
													: null
											"
											:key="reload"
											v-model="detailCat"
										/>
										<!-- <small v-if="(v$.detailCat.$invalid && submitted) || v$.detailCat.$pending.$response" class="p-error">{{'Detail Category is required'}}</small> -->
									</div>
									<div class="col-4 mb-5 lg:col-3 lg:mb-5">
										<Button
											label="Add Line"
											@click="addLine"
											class="p-button-success"
											:disabled="this.leveltenant !== 2"
										/>
									</div>
								</div>
								<!-- </form> -->
							</div>

							<div class="col-12 md:col-6">
								<div
									class="grid formgrid"
									style="margin-top: 10px"
								>
									<div class="col-12 mb-0 lg:col-12 lg:mb-0">
										<div class="form-card">
											<div
												class="flex align-items-center justify-content-between mb-4"
											>
												<span
													style="color: #000"
													class="font-semibold"
													>Total Line</span
												>
												<span class="font-bold">
													{{
														Number.parseFloat(
															this.totalLine
														)
															.toFixed(2)
															.replace(
																/\B(?=(\d{3})+(?!\d))/g,
																','
															)
													}}
												</span>
											</div>
											<div
												class="flex align-items-center justify-content-between mb-4"
											>
												<span
													style="color: #000"
													class="font-semibold"
													>Total PPn</span
												>
												<span class="font-bold">
													{{
														Number.parseFloat(
															this.totalPPN
														)
															.toFixed(2)
															.replace(
																/\B(?=(\d{3})+(?!\d))/g,
																','
															)
													}}
												</span>
											</div>
											<div
												class="flex align-items-center justify-content-between mb-4"
											>
												<span
													style="color: #000"
													class="font-semibold"
													>Total Invoice</span
												>
												<span class="font-bold">
													{{
														Number.parseFloat(
															this.totalInvoice
														)
															.toFixed(2)
															.replace(
																/\B(?=(\d{3})+(?!\d))/g,
																','
															)
													}}
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<DetailTable
									v-if="company && vendorId"
									:data="idValue ? idValue : null"
									:company="company ? company : null"
									:db_instance="
										db_instance ? db_instance : null
									"
									:line="line ? line : null"
									:org_id="org_id ? org_id : null"
									:vendor_id="vendorId ? vendorId : null"
									:vendor_name="
										vendor_name ? vendor_name : null
									"
									:detailCategory="
										detailCat ? detailCat : null
									"
									:invoice_date="
										invoiceDate ? invoiceDate : null
									"
									:disabled="
										displayStatus ? displayStatus : null
									"
									:submit="submitted ? submitted : false"
									:tableVuelidate="
										tableVuelidate ? tableVuelidate : null
									"
									:key="reloadTable"
									@tableList="getTableList"
									@onDeleteReimburse="handleDeleteReimburse"
								></DetailTable>
							</div>
							<div v-if="!reimburseDiv" class="col-12">
								<div
									class="grid formgrid"
									style="margin-top: 20px"
								>
									<div class="col-8 mb-4 lg:col-10 lg:mb-4">
										<span
											style="
												color: #000;
												font-size: 20px;
												margin-left: 5px;
											"
											class="font-semibold"
											>Reimburse</span
										>
									</div>
									<div class="col-4 mb-4 lg:col-2 lg:mb-4">
										<Button
											label="Add Line"
											@click="addLineReimburse"
											class="p-button-success"
											:disabled="this.leveltenant !== 2"
										/>
									</div>
								</div>
								<DetailReimburse
									v-if="!reimburseDiv"
									:company="company ? company : null"
									:data="idValue ? idValue : null"
									:line="lineReimburse ? lineReimburse : null"
									:vendor_id="vendorId ? vendorId : null"
									:vendor_name="
										vendor_name ? vendor_name : null
									"
									:key="reloadTable"
									:disabled="
										displayStatus ? displayStatus : null
									"
									:submit="submitted ? submitted : false"
									:coa_id="this.reimburseData"
									:tableVuelidate="
										tableReimburseVuelidate
											? tableReimburseVuelidate
											: null
									"
									@tableList="getTableListReimburse"
								></DetailReimburse>
							</div>
						</div>
						<!-- <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid"> -->
						<div class="p-fluid grid formgrid">
							<div class="col-6 mb-2 lg:col-1 lg:mb-0">
								<Button
									:class="uppercase"
									class="p-button-secondary"
									label="Back"
									@click="button('B')"
								>
								</Button>
							</div>
							<div class="col-6 mb-2 lg:col-1 lg:mb-0">
								<Button
									:class="uppercase"
									class="p-button-info"
									label="Download"
									@click="button('D')"
								>
								</Button>
							</div>
							<div
								v-if="!displayStatus"
								class="col-6 mb-2 lg:col-1 lg:mb-0"
							>
								<div v-if="this.userInfo.leveltenant === 2">
									<Button
										type="submit"
										label="Save"
										@click="button('S')"
										class="p-button-success"
									/>
								</div>
							</div>

							<!-- <div
                            v-if="!displayStatus" class="col-6 mb-2 lg:col-1 lg:mb-0"
                        > -->
							<!-- <div v-if="this.userInfo.leveltenant === 2"> -->
							<!-- <Button class="p-button-error" type="reject" label="Reject" @click="button('R')" /> -->
							<!-- </div> -->
							<!-- </div> -->
							<!-- <div
                            v-if="!displayStatus" class="col-6 mb-2 lg:col-1 lg:mb-0"
                        > 
                        <div v-if="this.userInfo.leveltenant === 1">
                            <Button class="p-button-info" label="Submit" @click="button('ST')" />
                        </div>
                        </div> -->
						</div>
						<!-- </form> -->
					</div>
				</form>
			</div>
		</div>
	</div>

	<Dialog
		header="Ref Number"
		v-model:visible="dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '15vw' }"
		:modal="true"
	>
		<div
			class="col-12 mb-12 md:col-12 lg:col-12 lg:mb-12"
			style="margin-top: 20px"
		>
			<!-- <InputText id="username" type="text" v-model="v$.refNumber.$model" :class="{'p-invalid':v$.refNumber.$invalid && submitted}" :maxlength="50"/> -->
			<RefNumber
				:db_instance="
					this.company.db_instance ? this.company.db_instance : null
				"
				:vendor_id="vendorId"
				:key="reload"
				:disabled="displayStatus ? displayStatus : null"
				@refNumber="getRefValue"
				v-model="v$.refNumberPopUp.$model"
				:class="{ 'p-invalid': v$.refNumber.$invalid && submitted }"
			/>
			<small
				v-if="
					(v$.refNumber.$invalid && submitted) ||
					v$.refNumber.$pending.$response
				"
				class="p-error"
				>{{
					v$.refNumber.required.$message.replace(
						'Value',
						'Reference Number Value'
					)
				}}</small
			>
		</div>
		<template #footer>
			<Button
				label="No"
				icon="pi pi-times"
				@click="closeModal"
				class="p-button-text"
			/>
			<Button
				label="Yes"
				icon="pi pi-check"
				@click="yesModal"
				autofocus
			/>
		</template>
	</Dialog>
</template>

<script>
/* eslint-disable */
import AuthService from '../../../service/AuthService';
import CompaniesDropdown from '@/components/components/CompaniesDropdown';
import DetailCat from '@/components/listinvoicenonpo/components/DetailCat';
import Bank from '@/components/listinvoicenonpo/components/Bank';
import RefNumber from '@/components/listinvoicenonpo/components/RefNumber';
import DetailTable from '@/components/listinvoicenonpo/components/DetailTableListInvoiceNonPO';
import DetailReimburse from '@/components/listinvoicenonpo/components/DetailTableListInvoiceReimburseNonPO';
import InvoiceService from '../../../service/InvoiceSearchWPO';
import ProgressModal from '../../components/ProgressModal.vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import ListInvoiceNonPo from '../../../service/ListInvoiceNonPo';
import LovService from '../../../service/LovService';
import SimpleCrypto from 'simple-crypto-js';
import PoFulfillment from '../../../service/PoFulfillment';
import SearchInvoiceProxyNonPO from '../../../service/InvoiceProxyService';
import XLSX from 'xlsx';

export default {
	setup: () => ({ v$: useVuelidate() }),
	name: 'DetailListInvoiceNonPo',
	components: {
		DetailCat,
		ProgressModal,
		DetailTable,
		DetailReimburse,
		Bank,
		RefNumber,
		CompaniesDropdown,
	},
	data() {
		return {
			idValue: this.$route.params.id,
			company: null,
			supplier: null,
			status: null,
			invoiceNumber: null,
			invoiceDate: null,
			taxInvoiceDate: null,
			taxInvoiceNumber: null,
			document: null,
			reloadTable: null,
			checkboxValue: false,
			disabledDropdown: true,
			flag_pph: null,
			companyToBankAccount: null,
			db_instance: null,
			org_id: null,
			ext_account: null,
			displayStatus: true,
			category: null,
			pphCodeValue: null,
			CoAValue: null,
			description: null,
			progress: false,
			userId: null,
			table: null,
			tableVuelidate: null,
			tableReimburse: null,
			tableReimburseVuelidate: null,
			sender: null,
			leveltenant: null,
			vendorId: null,
			vendor_name: null,
			inputErrorArray: [],
			batch_no: null,
			fileData: null,
			savedFile: null,
			disabledDropdowntrue: true,
			attachmentName: null,
			displayFile: true,
			submitted: false,
			attachmentUrl: null,
			name: null,
			fileName: null,
			buttonValue: null,
			rejectvalue: false,
			statusButton: true,
			bank: null,
			reject: null,
			approval: null,
			refNumber: null,
			refNumberPopUp: null,
			dialog: false,
			line: null,
			userInfo: null,
			backUpDesc: null,
			isSupplierpage: false,
			lineReimburse: null,
			vendorSiteId: null,
			detailCat: null,
			totalLine: null,
			totalPPN: null,
			totalInvoice: null,
			reimburseDiv: true,
			reimburseData: null,
			currency: null,
			terms: null,
			glDate: null,
			payment: null,
			bank: null,
			ccid: null,
			cost_center: null,
			taxAmount: null,
			maping_id: null,
			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [
				{
					label: 'List Invoice Non PO',
					to: '/mgm/monitor/listinvoicenonpo',
				},
				{
					label: 'Detail Invoice Invoice Non PO',
					to: this.$route.path,
				},
			],
			fileBuffer: null,
			loadingdownloadFile: false,
		};
	},
	validations() {
		const rules = {
			invoiceNumber: {
				required,
			},
			invoiceNumber: {
				required,
			},
			invoiceDate: {
				required,
			},
			taxInvoiceNumber: {
				required,
			},
			description: {
				required,
			},
			status: {
				required,
			},
			taxInvoiceDate: {
				required,
			},
			bank: {
				required,
			},
			refNumber: {
				required: requiredIf(this.buttonValue === 'C'),
			},
			refNumberPopUp: {
				required: requiredIf(this.buttonValue === 'C'),
			},
			reject: {
				required: requiredIf(this.buttonValue === 'R'),
			},
			table: {
				$each: helpers.forEach({
					AMOUNT: { required },
					ITEM_DESCRIPTION: { required },
				}),
			},
		};

		if (!this.reimburseDiv) {
			rules.tableReimburse = {
				$each: helpers.forEach({
					TAX_NO: { required },
					TAX_DATE: { required },
					AMOUNT: { required },
					GROUP_NAME: { required },
				}),
			};
		}
		return rules;
	},
	watch: {
		// detailCat(){
		//     if (this.detailCat !== null){
		//         if (this.detailCat.category_detail.includes('REIMBURSTMENT')){
		//         }
		//     }
		// },
		invoiceNumber() {
			if (this.invoiceNumber !== null) {
				this.invoiceNumber = this.invoiceNumber.replace(/ /g, '');
			}
		},
	},
	async created() {
		AuthService.whoAmi().then(async (resp) => {
			function userCompany() {
				const secretKey = 'some-unique-key';
				const simpleCrypto = new SimpleCrypto(secretKey);
				return simpleCrypto.decrypt(
					sessionStorage.getItem('dropdownCompany')
				);
			}

			if (resp.status === 200) {
				this.userInfo = resp.data.data;
				if (this.userInfo.leveltenant === 2) {
					//get company
					this.levelTenant = true;
					this.isSupplierpage = true;

					// const respCbN = await LovService.getCompanyByName(
					// 	userCompany()
					// );
					// this.company = respCbN.data.data[0];
					// const param = {
					// 	db_instance: this.company.db_instance,
					// 	org_id: this.company.value,
					// };

					const getCompaniesName = PoFulfillment.getCompanySupp(
						resp.data.data.id
					);

					const getVendorsName = PoFulfillment.getSuppBindComp(
						resp.data.data.id
					);

					const [companySuppRes, respVendor] = await Promise.all([
						getCompaniesName,
						getVendorsName,
					]);

					// Get company
					const companySupp = companySuppRes.data.data;
					const companyList = (
						await Promise.all(
							companySupp.map((element) => {
								const res = LovService.getCompanyByName(
									element.company
								);

								return res;
							})
						)
					).map((item) => item.data.data[0]);

					this.company = companyList.find(
						(comp) =>
							comp.value === this.$route.query.v &&
							comp.db_instance === this.$route.query.i
					);

					// Get supplier
					const param = {
						db_instance: this.company.db_instance,
						org_id: this.company.value,
					};
					const vendorName = respVendor.data.data[0][0].vendorname;
					const respSuppName = await LovService.getSupplierName(
						param
					);
					if (respSuppName.status === 200) {
						const suppList = respSuppName.data.data;
						for (const s of suppList) {
							if (s.vendor_name === vendorName) {
								this.supplier = s;
								break;
							}
						}
						if (this.supplier === null) {
							this.$router.push('/dashboard');
							window.alert(
								'Connection Unstable, ID not Recognize'
							);
						}
					}
				} else {
					const respCbN = await LovService.getCompanyByName(
						userCompany()
					);
					this.company = respCbN.data.data[0];
				}
			}
			this.userId = resp.data.data.id;
			this.sender = resp.data.data.bioemailactive;
			this.leveltenant = resp.data.data.leveltenant;
			if (resp.status === 200) {
				const payload = { id: this.idValue };
				const respond = await ListInvoiceNonPo.getDetailData(payload);
				this.db_instance = respond.data.data.data[0].DB_INSTANCE;
				this.invoiceDate = new Date(
					respond.data.data.data[0].INVOICE_DATE
				);
				this.vendorId = respond.data.data.data[0].VENDOR_ID;
				this.vendorSiteId = respond.data.data.data[0].vendor_site_id;
				this.currency = respond.data.data.data[0].CURRENCY;
				this.terms = respond.data.data.data[0].TERM_ID;
				this.glDate = new Date(respond.data.data.data[0].GL_DATE);
				this.payment =
					respond.data.data.data[0].PAYMENT_METHOD_LOOKUP_CODE;
				this.bank = respond.data.data.data[0].EXTERNAL_BANK_ACCOUNT_ID;
				this.taxInvoiceNumber = respond.data.data.data[0].TAX_NO;
				this.taxInvoiceDate = new Date(
					respond.data.data.data[0].TAX_DATE
				);
				this.ccid = respond.data.data.data[0].ACCOUNT_ID;
				this.category = respond.data.data.data[0].CATEGORY_ID;
				this.maping_id = respond.data.data.data[0].MAPING_ID;
				this.cost_center = respond.data.data.data[0].COST_CENTER;
				this.taxAmount = respond.data.data.data[0].TOTAL_AMOUNT;
				this.status = respond.data.data.data[0].STATUS;
				if (this.status === 'REVIEW') {
					var rekomendator =
						await respond.data.data.data[0].REKOMENDATOR.substring(
							0,
							respond.data.data.data[0].REKOMENDATOR.lastIndexOf(
								'@'
							) + 0
						);
					rekomendator = await rekomendator
						.replace(/\./g, ' ')
						.replace(/_/g, ' ')
						.toUpperCase();
					this.status =
						respond.data.data.data[0].STATUS +
						' (Waiting Approval : ' +
						rekomendator +
						')';
				}
				if (this.status === 'REJECT'){
					var rekomendator =
						await respond.data.data.data[0].REKOMENDATOR.substring(
							0,
							respond.data.data.data[0].REKOMENDATOR.lastIndexOf(
								'@'
							) + 0
						);
					rekomendator = await rekomendator
						.replace(/\./g, ' ')
						.replace(/_/g, ' ')
						.toUpperCase();
					this.status =
						respond.data.data.data[0].STATUS +
						' (Rejected By : ' +
						rekomendator +
						')';
				}
				this.invoiceNumber = respond.data.data.data[0].INVOICE_NO;
				this.companyToBankAccount =
					respond.data.data.data[0].EXTERNAL_BANK_ACCOUNT_ID;
				this.refNumber = respond.data.data.data[0].REFERENCE_NUMBER;
				if (this.refNumber === 'null') {
					this.refNumber = '';
				}
				this.refNumberPopUp =
					respond.data.data.data[0].REFERENCE_NUMBER;
				this.org_id = respond.data.data.data[0].ORG_ID;
				this.description = respond.data.data.data[0].DESCRIPTION;
				this.backUpDesc = respond.data.data.data[0].DESCRIPTION;
				this.reject = respond.data.data.data[0].REASON;
				this.approval = respond.data.data.data[0].RECOMMENDATOR_REASON;
				this.reload = 1;
				this.reloadTable = 1;
				// this.reloadPPH++
				this.getAttachmentFile();
				this.statusValue(this.status);
				this.getSuppName();
				// await this.getFileBase64(this.idValue)
			} else {
				alert('User session expired');
			}
		});
	},
	computed: {},
	methods: {
		async statusValue(status) {
			if (status === 'DRAFT' || status === 'REJECTED') {
				if (this.userInfo.leveltenant === 2) {
					this.displayStatus = false;
					this.statusButton = false;
				} else {
					this.displayStatus = true;
					this.statusButton = true;
				}
			} else if (status === 'OPEN') {
				if (this.userInfo.leveltenant === 3) {
					this.displayStatus = false;
					this.statusButton = false;
				} else {
					this.statusButton = true;
					this.displayStatus = true;
				}
			} else if (status === 'REVIEW') {
				if (this.userInfo.leveltenant === 3) {
					this.displayStatus = false;
					this.statusButton = false;
				} else {
					this.statusButton = true;
					this.displayStatus = true;
				}
			} else if (status === 'PROCESSED') {
				if (this.userInfo.leveltenant === 1) {
					this.displayStatus = false;
					this.statusButton = false;
				} else {
					this.statusButton = true;
					this.displayStatus = true;
				}
			} else {
				this.statusButton = true;
				this.displayStatus = true;
			}
		},
		formatDateDexa(date) {
			var date = new Date(date);
			var respondDate =
				('00' + (date.getMonth() + 1)).slice(-2) +
				'/' +
				('00' + date.getDate()).slice(-2) +
				'/' +
				date.getFullYear() +
				' ' +
				('00' + date.getHours()).slice(-2) +
				':' +
				('00' + date.getMinutes()).slice(-2) +
				':' +
				('00' + date.getSeconds()).slice(-2);
			return respondDate.replace('-', '');
		},
		// async getcompany (value) {
		//     this.company = value
		// },
		async getDetailCat(value) {
			this.detailCat = value;
		},
		async getBank(value) {
			this.bank = value;
		},
		async getTableList(value) {
			this.table = value;
			let dataplus = 0;
			let ppn = 0;
			this.totalPPN = 0;
			this.table.forEach((element) => {
				dataplus = dataplus + element.AMOUNT;
				if (element.PERCENTAGE_RATE !== null) {
					ppn = (element.AMOUNT * element.PERCENTAGE_RATE) / 100;
				} else if (element.PERCENTAGE_RATE === null) {
					ppn = 0;
				}
				if (
					element.ITEM_DESCRIPTION.includes('REIMBURSTMENT') ||
					element.ITEM_DESCRIPTION.includes('Reimburstment') ||
					element.ITEM_DESCRIPTION.includes('reimburstment')
				) {
					this.reimburseDiv = false;
					this.reimburseData = element;
					console.log('isi element', element);
				}
				this.totalLine = dataplus;
				this.totalPPN = this.totalPPN + ppn;
				this.totalInvoice = this.totalLine + this.totalPPN;
			});
		},
		async getTableListReimburse(value) {
			this.tableReimburse = value;
		},
		async getSuppName() {
			const payload = { id: this.vendorId };
			const data = await ListInvoiceNonPo.getSupplierName(payload);
			this.vendor_name = data.data.data.data[0].vendor_name;
		},
		async deleteFile() {
			this.savedFile = null;
			this.attachmentUrl = null;
			this.attachmentName = null;
			this.fileBuffer = null;
		},
		async findSK() {
			this.dialog = true;
		},
		async closeModal() {
			this.dialog = false;
		},
		async yesModal() {
			this.dialog = false;
			if (this.refNumberPopUp !== null) {
				if (this.description === null) {
					this.description = '';
					this.description = this.refNumberPopUp.po_number;
					this.refNumber = this.refNumberPopUp.sk_number;
				} else {
					this.description = this.backUpDesc;
					this.description = this.description.concat(
						', ',
						this.refNumberPopUp.po_number
					);
					this.refNumber = this.refNumberPopUp.sk_number;
				}
			} else {
				if (this.description === null) {
					this.description = '';
					this.description = this.refNumber.po_number;
					this.refNumber = this.refNumber.sk_number;
				} else {
					this.description = this.backUpDesc;
					this.description = this.description.concat(
						', ',
						this.refNumber.po_number
					);
					this.refNumber = this.refNumber.sk_number;
				}
			}
		},
		async getAttachmentFile() {
			const res = await InvoiceService.getAttachmentFile(this.idValue);
			if (res.status === 200) {
				if (res.data.data === null) {
					this.savedFile = null;
					this.attachmentUrl = null;
					this.attachmentName = null;
					this.fileName = null;
				} else {
					this.savedFile = res.data.data;
					this.attachmentUrl = res.data.data.url;
					this.attachmentName = res.data.data.filename;
					this.fileName = res.data.data.einvoice_file;
				}
			} else {
				this.$toast.add({
					severity: 'Error File',
					summary: 'Unable to Download File',
					life: 3000,
				});
			}
		},
		async onSelectFile(e) {
			this.fileData = e.files[0];
		},
		async button(status) {
			if (status === 'B') {
				this.$router.go(-1);
			} else if (status === 'D') {
				let biggerTable = true;
				let dataReimburse = this.tableReimburse
					? this.tableReimburse
					: 0;
				if (
					this.table.length >= dataReimburse
						? dataReimburse.length
						: 0
				) {
					biggerTable = true;
				} else if (
					this.table.length <= dataReimburse
						? dataReimburse.length
						: 0
				) {
					biggerTable = false;
				}
				const payload = {
					id: parseInt(this.idValue),
					table: biggerTable,
				};
				const res = await ListInvoiceNonPo.exportDataDownload(payload);
				if (res.status === 200) {
					const today = new Date();
					const dd = String(today.getDate()).padStart(2, '0');
					const mm = String(today.getMonth() + 1).padStart(2, '0');
					const yyyy = today.getFullYear();
					const combinedDate = dd + mm + yyyy;
					const invoice = res.data.data.data[0].INVOICE_NO;
					const filename =
						'InvoicenonPO _' +
						invoice +
						'_' +
						combinedDate +
						'.xlsx';
					const data = res.data.data.data;
					const ws = XLSX.utils.json_to_sheet(data);
					const wb = XLSX.utils.book_new();
					XLSX.utils.book_append_sheet(wb, ws, 'My Worksheet');
					XLSX.writeFile(wb, filename);
				} else {
					toast.add({
						severity: 'error',
						summary: 'Download Error',
						life: 3000,
					});
				}
			} else {
				this.buttonValue = status;
			}
		},
		async handleSubmit(isFormValid) {
			this.submitted = true;
			if (!isFormValid) {
				this.$toast.add({
					severity: 'error',
					summary: 'All Fields are Required',
					life: 3000,
				});
				// console.log('v',this.v$)
				this.tableVuelidate = this.v$.table.$silentErrors[0]
					? this.v$.table.$silentErrors[0].$response.$errors
					: null;
				this.tableReimburseVuelidate = this.v$.tableReimburse
					.$silentErrors[0]
					? this.v$.tableReimburse.$silentErrors[0].$response.$errors
					: null;
				return;
			}
			if (this.savedFile === null && this.fileData === null) {
				this.$toast.add({
					severity: 'error',
					summary: 'File is empty',
					life: 3000,
				});
				return;
			}
			// this.submitted = false
			if (this.buttonValue === 'S') {
				this.progress = true;
				const status = 'DRAFT';
				const formData = new FormData();
				formData.append('file', this.fileData);
				const payload = {
					id: this.idValue,
					org_id: this.org_id,
					invoice_number: this.invoiceNumber,
					tax_invoice: this.taxInvoiceNumber,
					status: status,
					invoice_date: this.formatDateDexa(this.invoiceDate),
					tax_date: this.formatDateDexa(this.taxInvoiceDate),
					ref_number: this.refNumber ? this.refNumber : '',
					description: this.description,
					date: new Date(),
					bank_id: this.bank ? this.bank.EXT_BANK_ACCOUNT_ID : null,
					total_line: this.totalLine,
					total_ppn: this.totalPPN,
					total: this.totalInvoice,
					reject: this.reject ? this.reject : '',
					approval: this.approval ? this.approval : '',
					id: parseInt(this.idValue),
					userid: this.userId,
					fileNameOld: this.fileName ? this.fileName : null,
					vendor_id: this.vendorId,
					table: this.table ? this.table : null,
					reimburse: this.tableReimburse ? this.tableReimburse : null,
				};
				const jsonpayload = JSON.stringify(payload);
				formData.append('payload', jsonpayload);
				const save = await ListInvoiceNonPo.saveData(
					parseInt(this.idValue),
					formData
				);
				if (save.status === 200) {
					this.$toast.add({
						severity: 'success',
						summary: 'Data Saved',
						life: 3000,
					});
					this.getAttachmentFile();
					// await this.getFileBase64(this.idValue)
					this.statusValue(this.status);
					this.submitted = false;
					this.progress = false;
					window.location.reload();
				} else if (save.status === 203) {
					this.$toast.add({
						severity: 'error',
						summary: 'PDF Already had Password',
						life: 3000,
					});
					this.submitted = false;
					this.progress = false;
				} else if (save.status === 204) {
					this.$toast.add({
						severity: 'error',
						summary: 'File Not Found',
						life: 3000,
					});
					this.submitted = false;
					this.progress = false;
				} else if (save.status === 444) {
					this.$toast.add({
						severity: 'error',
						summary: 'Disconnected',
						detail: 'internet is unstable',
						life: 3000,
					});
					this.submitted = false;
					this.progress = false;
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Data Error',
						life: 3000,
					});
					this.submitted = false;
					this.progress = false;
				}
				this.submitted = false;
				this.progress = false;
			} else if (this.buttonValue === 'R') {
				this.progress = true;
				const status = 'DRAFT';
				const formData = new FormData();
				formData.append('file', this.fileData);
				const payload = {
					module: 'searchinvoicenonpo',
					sender: this.sender,
					recipient: null,
					status_mail: 'Reject',
					companyname: this.company ? this.company.name : '',
					vendorname: this.vendor_name ? this.vendor_name : '',
					db_instance: this.db_instance ? this.db_instance : null,
					invoice_number: this.invoiceNumber
						? this.invoiceNumber
						: '',
					invoice_date: this.formatDateDexa(this.invoiceDate),
					vendor_id: this.vendorId ? this.vendorId : 0,
					vendor_site_id: this.vendorSiteId ? this.vendorSiteId : 0,
					currency: this.currency ? this.currency : '',
					term_id: this.terms ? this.terms : 0,
					hdr_gl_date: this.formatDateDexa(this.glDate),
					payment_method: this.payment ? this.payment : '',
					bank_id: this.bank ? this.bank.EXT_BANK_ACCOUNT_ID : 0,
					tax_invoice: this.taxInvoiceNumber
						? this.taxInvoiceNumber
						: '',
					tax_date: this.formatDateDexa(this.taxInvoiceDate),
					ccid_header: this.ccid ? this.ccid : 0,
					category_id: this.category ? this.category : 0,
					mapping_id: this.mapping_id ? this.mapping_id : 0,
					ref_number: this.refNumber ? this.refNumber : '',
					cost_center: this.cost_center ? this.cost_center : '',
					tax_amount: this.taxAmount ? this.taxAmount : '',
					paygroup: null,
					hdr_desc: this.description ? this.description : '',
					org_id: this.org_id,
					invoice_number: this.invoiceNumber,
					status: status,
					description: this.description,
					date: new Date(),
					total_line: this.totalLine,
					total_ppn: this.totalPPN,
					total: this.totalInvoice,
					reject: this.reject ? this.reject : '',
					approval: this.approval ? this.approval : '',
					id: parseInt(this.idValue),
					userid: this.userId,
					fileNameOld: this.fileName ? this.fileName : null,
					table: this.table ? this.table : null,
					reimburse: this.tableReimburse ? this.tableReimburse : null,
				};
				const jsonpayload = JSON.stringify(payload);
				formData.append('payload', jsonpayload);
				const save = await ListInvoiceNonPo.saveData(
					parseInt(this.idValue),
					formData
				);
				if (save.status === 200) {
					this.$toast.add({
						severity: 'success',
						summary: 'Data Saved',
						life: 3000,
					});
					this.getAttachmentFile();
					// await this.getFileBase64(this.idValue)
					this.statusValue(status);
					this.submitted = false;
				} else if (save.status === 203) {
					this.$toast.add({
						severity: 'error',
						summary: 'PDF Already had Password',
						life: 3000,
					});
					this.submitted = false;
				} else if (save.status === 204) {
					this.$toast.add({
						severity: 'error',
						summary: 'File Not Found',
						life: 3000,
					});
					this.submitted = false;
				} else if (save.status === 444) {
					this.$toast.add({
						severity: 'error',
						summary: 'Disconnected',
						detail: 'internet is unstable',
						life: 3000,
					});
					this.submitted = false;
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Data Error',
						life: 3000,
					});
					this.submitted = false;
				}
				this.submitted = false;
				this.progress = false;
			} else if (this.buttonValue === 'C') {
				this.progress = true;
				const status = 'REVIEW';
				const formData = new FormData();
				formData.append('file', this.fileData);
				const payload = {
					id: this.idValue,
					org_id: this.org_id,
					invoice_number: this.invoiceNumber,
					tax_invoice: this.taxInvoiceNumber,
					status: status,
					invoice_date: this.formatDateDexa(this.invoiceDate),
					tax_date: this.formatDateDexa(this.taxInvoiceDate),
					ref_number: this.refNumber ? this.refNumber : '',
					description: this.description,
					date: new Date(),
					bank_id: this.bank ? this.bank.EXT_BANK_ACCOUNT_ID : null,
					total_line: this.totalLine,
					total_ppn: this.totalPPN,
					total: this.totalInvoice,
					reject: this.reject ? this.reject : '',
					approval: this.approval ? this.approval : '',
					id: parseInt(this.idValue),
					userid: this.userId,
					fileNameOld: this.fileName ? this.fileName : null,
					vendor_id: this.vendorId,
					table: this.table ? this.table : null,
					reimburse: this.tableReimburse ? this.tableReimburse : null,
				};
				const jsonpayload = JSON.stringify(payload);
				formData.append('payload', jsonpayload);
				const save = await ListInvoiceNonPo.saveData(
					parseInt(this.idValue),
					formData
				);
				if (save.status === 200) {
					this.$toast.add({
						severity: 'success',
						summary: 'Data Saved',
						life: 3000,
					});
					this.getAttachmentFile();
					// await this.getFileBase64(this.idValue)
					this.statusValue(status);
					this.submitted = false;
				} else if (save.status === 203) {
					this.$toast.add({
						severity: 'error',
						summary: 'PDF Already had Password',
						life: 3000,
					});
					this.submitted = false;
				} else if (save.status === 204) {
					this.$toast.add({
						severity: 'error',
						summary: 'File Not Found',
						life: 3000,
					});
					this.submitted = false;
				} else if (save.status === 444) {
					this.$toast.add({
						severity: 'error',
						summary: 'Disconnected',
						detail: 'internet is unstable',
						life: 3000,
					});
					this.submitted = false;
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Data Error',
						life: 3000,
					});
					this.submitted = false;
				}
				this.submitted = false;
				this.progress = false;
			} else if (this.buttonValue === 'ST') {
				this.progress = true;
				const status = 'POSTED';
				const formData = new FormData();
				formData.append('file', this.fileData);
				const payload = {
					module: 'invoiceNonPo',
					sender: this.sender,
					recipient: null,
					status_mail: 'POSTED',
					companyname: this.company ? this.company.name : '',
					vendorname: this.vendor_name ? this.vendor_name : '',
					db_instance: this.db_instance ? this.db_instance : null,
					invoice_number: this.invoiceNumber
						? this.invoiceNumber
						: '',
					invoice_date: this.formatDateDexa(this.invoiceDate),
					vendor_id: this.vendorId ? this.vendorId : 0,
					vendor_site_id: this.vendorSiteId ? this.vendorSiteId : 0,
					currency: this.currency ? this.currency : '',
					term_id: this.terms ? this.terms : 0,
					hdr_gl_date: this.formatDateDexa(this.glDate),
					payment_method: this.payment ? this.payment : '',
					bank_id: this.bank ? this.bank.EXT_BANK_ACCOUNT_ID : 0,
					tax_invoice: this.taxInvoiceNumber
						? this.taxInvoiceNumber
						: '',
					tax_date: this.formatDateDexa(this.taxInvoiceDate),
					ccid_header: this.ccid ? this.ccid : 0,
					category_id: this.category ? this.category : 0,
					mapping_id: this.mapping_id ? this.mapping_id : 0,
					ref_number: this.refNumber ? this.refNumber : '',
					cost_center: this.cost_center ? this.cost_center : '',
					tax_amount: this.taxAmount ? this.taxAmount : '',
					paygroup: null,
					hdr_desc: this.description ? this.description : '',
					org_id: this.org_id,
					invoice_number: this.invoiceNumber,
					status: status,
					description: this.description,
					date: new Date(),
					total_line: this.totalLine,
					total_ppn: this.totalPPN,
					total: this.totalInvoice,
					reject: this.reject ? this.reject : '',
					approval: this.approval ? this.approval : '',
					id: parseInt(this.idValue),
					userid: this.userId,
					fileNameOld: this.fileName ? this.fileName : null,
					table: this.table ? this.table : null,
					reimburse: this.tableReimburse ? this.tableReimburse : null,
				};
				const jsonpayload = JSON.stringify(payload);
				formData.append('payload', jsonpayload);
				const save = await ListInvoiceNonPo.saveData(
					parseInt(this.idValue),
					formData
				);
				if (save.status === 200) {
					this.$toast.add({
						severity: 'success',
						summary: 'Data Saved',
						life: 3000,
					});
					this.getAttachmentFile();
					// await this.getFileBase64(this.idValue)
					this.statusValue(status);
					this.submitted = false;
				} else if (save.status === 203) {
					this.$toast.add({
						severity: 'error',
						summary: 'PDF Already had Password',
						life: 3000,
					});
					this.submitted = false;
				} else if (save.status === 204) {
					this.$toast.add({
						severity: 'error',
						summary: 'File Not Found',
						life: 3000,
					});
					this.submitted = false;
				} else if (save.status === 444) {
					this.$toast.add({
						severity: 'error',
						summary: 'Disconnected',
						detail: 'internet is unstable',
						life: 3000,
					});
					this.submitted = false;
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Data Error',
						life: 3000,
					});
					this.submitted = false;
				}
				this.submitted = false;
				this.progress = false;
			}
		},

		async addLine() {
			this.line++;
			if (this.detailCat === undefined || this.detailCat === null) {
				this.$toast.add({
					severity: 'error',
					summary: 'Category Empty',
					life: 3000,
				});
			} else if (
				this.detailCat.category_detail.includes('REIMBURSTMENT')
			) {
				this.reimburseDiv = false;
			}
		},
		async addLineReimburse() {
			this.lineReimburse++;
		},
		// async getFileBase64(invoiceId) {
		//     try {
		//         const leveltenant = sessionStorage.getItem('leveltenant')
		//         const res = await ListInvoiceNonPo.getFileBase64(invoiceId, leveltenant, this.vendorId);

		//         if (res.status === 200) {
		//             this.fileBuffer = res.data.data;
		//         }
		//     } catch (err) {
		//
		//     }
		// },
		async handleDeleteReimburse(value) {
			// Check if reimburse category is not exist
			if (
				!this.table.some(
					(item) =>
						item.ITEM_DESCRIPTION.toLowerCase() === 'reimburstment'
				)
			) {
				// Delete ttable reimbursement
				if (value) {
					await Promise.all(
						this.tableReimburse.map(async (item) => {
							const payload = {
								idheader: parseInt(this.idValue),
								iddetail: item.EINVOICE_REIMBURSMENT_ID,
								value: false,
							};
							const res =
								await SearchInvoiceProxyNonPO.deleteReimburse(
									payload
								);

							if (res.data.data.data.length !== 0) {
								payload.value = true;
								await SearchInvoiceProxyNonPO.deleteReimburse(
									payload
								);
							}
						})
					);
				} else {
					this.tableReimburse = null;
				}
				this.reimburseDiv = true;
			}
		},

		async onDownload() {
			try {
				this.loadingdownloadFile = true;
				const leveltenant = sessionStorage.getItem('leveltenant');
				const res = await ListInvoiceNonPo.getFileBase64(
					this.idValue,
					leveltenant,
					this.vendorId
				);

				if (res.status === 200) {
					this.fileBuffer = res.data.data;
				}
			} catch (err) {
				this.loadingdownloadFile = false;
				this.$toast.add({
					severity: 'error',
					summary: 'Connection Unstable !',
					life: 3000,
				});
				throw Error('Error downloading file');
			}
			const linkSource = `data:application/pdf;base64,${this.fileBuffer}`;
			const downloadLink = document.createElement('a');
			const fileName = this.attachmentName;
			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.click();
			this.loadingdownloadFile = false;
		},
	},
};
</script>
<style scoped>
.input-number,
:disabled {
	background: #a19f9f !important;
}
.Calendar,
:disabled {
	background: #a19f9f !important;
}
/* input.dropdown {
	color: #000 !important;
} */
.form-card {
	padding: 10px;
	border: 1px solid #e0e0e0;
	border-radius: 16px;
}
</style>
