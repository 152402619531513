<template>
	<AutoComplete
		:suggestions="filteredCostCenter"
		@complete="searchInvoice($event)"
		:dropdown="true"
		field="SEGMENT4"
		v-model="costCenterValue"
		:disabled="!costCenter.length || props.category === CategoryEnum.Prepayment"
		forceSelection
	>
		<template #item="slotProp">
			{{ slotProp.item.SEGMENT4 }}
		</template>
	</AutoComplete>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect } from 'vue';
import AuthService from '../../../service/AuthService';
import masterVendorService from '../../../service/masterVendorService';
import { CategoryEnum } from '../../../constants/CategoryEnum';

// eslint-disable-next-line no-undef
const filteredCostCenter = ref([]);
const costCenter = ref([]);
const costCenterValue = ref();
const emit = defineEmits(['remitCostCenter']);

// eslint-disable-next-line no-undef
const props = defineProps({
	company: { type: Object },
	supplier: { type: Object },
	costCenterId: { type: Object },
	category: {type: String}
});

onMounted(() => {
	populateData();
});

watchEffect(() => emit('remitCostCenter', costCenterValue));

const populateData = () => {
	AuthService.whoAmi().then(async (response) => {
		let payload;
		if (props.company !== null) {
			payload = {
				org_id: props.company.value,
				db_instance: props.company.db_instance,
			};

			if (response.status === 200) {
				const data = await masterVendorService.getCostCenter(payload);

				costCenter.value = data.data.data.data;
				costCenterValue.value = data.data.data.data;
				for (const s of costCenter.value) {
					if (s.SEGMENT4 === props.costCenterId) {
						costCenterValue.value = s;
						break;
					} else {
						costCenterValue.value = null;
					}
				}
			}
		}
	});
};

const searchInvoice = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredCostCenter.value = [...costCenter.value];
    } else {
      filteredCostCenter.value = costCenter.value.filter((comp) => {
        return comp.SEGMENT4.toLowerCase().match(event.query.toLowerCase());
      });
    }
  }, 250);
};
</script>

<style scoped></style>
