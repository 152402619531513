<template>
  <AutoComplete
    :suggestions="filteredBatch"
    @complete="search($event)"
    :dropdown="true"
    field="BATCH_NO"
    :placeholder="placeholder"
    v-model="batchNo"
    forceSelection
  >
    <template #item="slotProp">
      {{ slotProp.item.BATCH_NO }}
    </template>
  </AutoComplete>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect } from "vue";
import invoiceProxy from "../../../service/InvoiceProxyService";

const filteredBatch = ref([]);
const batchList = ref([]);
const batchNo = ref();
const placeholder = ref("Select a Batch Number");

const emit = defineEmits(["batchNo"]);
watchEffect(() => emit("batchNo", batchNo));

// eslint-disable-next-line no-undef
const props = defineProps({
  company: {
    type: Object,
  },
  vendorId: {
    type: Object,
  },
  invoiceNumber: {
    type: String,
  },
  statusInvoice: {
    type: String,
  },
  levelTenant: {
    type: String,
  },
});

onMounted(async () => {
  try {
    // 
    if (props.company) {
      const result = await invoiceProxy.getListBatchNumber(
        props.company.value,
        props.company.db_instance,
        props.vendorId,
        props.invoiceNumber,
        props.statusInvoice,
        props.levelTenant
      );
      // 
      batchList.value = result.data.data;
    }

    // eslint-disable-next-line no-empty
  } catch (e) {
    throw new Error(e);
  }
});

const search = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredBatch.value = [...batchList.value];
    } else {
      filteredBatch.value = batchList.value.filter((batch) => {
        
        return batch.BATCH_NO.toLowerCase().match(
          event.query.toLowerCase()
        );
      });
    }
  }, 250);
};
</script>
