import axios from "axios";
import packageJson from "../../../package.json";
/* eslint-disable */
const spbInvoiceApi = () => {
  const hostname = window.location.hostname;
  console.log("HOSTNAME::", hostname);
  let url = "";

  if (hostname.includes("dev")) url = "https://devinvoice.isupplier-portal.com";
  else if (hostname.includes("149")) url = "http://149.129.241.185:31015";
  else if (hostname.includes("spb"))
    url = "https://zinvoice.isupplier-portal.com";
  else url = "http://localhost:3015";

  const defaultOptions = {
    baseURL: url,
    headers: {
      "Content-type": "application/json",
      "Cache-Control": "no-cache, must-revalidate",
      "Access-Control-Allow-Origin": "*",
    },
  };

  let instance = axios.create(defaultOptions);

  instance.interceptors.request.use(function (config) {
    let token = sessionStorage.authKey;
    if (token != null) {
      token = token.replaceAll('"', "");
    }
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    let fe_version = config.headers["fe-version"] || packageJson.version;
    if (
      fe_version !== sessionStorage.getItem("fe-version") &&
      (config.method == "post" || config.method == "get")
    ) {
      sessionStorage.setItem("fe-version", fe_version);
      window.location.reload(true); // For new version, simply reload on any get
    }
    return config;
  });

  return instance;
};

export default spbInvoiceApi();
