<template>
	<div v-if="allowedDelete">
		<Message severity="error" :life="3000">Cannot delete group</Message>
	</div>
	<div class="grid">
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<div class="col-12">
			<div class="card">
				<div>
					<ConfirmPopup></ConfirmPopup>
					<ConfirmPopup group="demo">
						<template #message="slotProps">
							<div class="flex p-4">
								<i
									:class="slotProps.message.icon"
									style="font-size: 1.5rem"
								></i>
								<p class="pl-2">
									{{ slotProps.message.message }}
								</p>
							</div>
						</template>
					</ConfirmPopup>
					<Toast />
					<div v-if="deleteStatus">
						<Message severity="success">Deleted Group</Message>
					</div>
					<div>
						<DataTable
							:value="groupList"
							editMode="row"
							dataKey="id"
							v-model:editingRows="editingRows"
							@row-edit-save="onRowEditSave"
							responsiveLayout="scroll"
						>
							<Column
								field="groupname"
								header="Group Name"
								style="width: 30%"
							>
								<template #editor="{ data, field }">
									<InputText
										v-model="data[field]"
										autofocus
									/>
								</template>
							</Column>

							<Column
								field="description"
								header="Description"
								style="width: 30%"
							>
								<template #editor="{ data, field }">
									<InputText
										v-model="data[field]"
										autofocus
									/>
								</template>
							</Column>

							<Column
								field="active"
								header="Active"
								style="width: 20%"
							>
								<template #body="slotProps">
									<span
										v-if="slotProps.data.active"
										class="pi pi-check"
									/>
									<span v-else class="pi pi-times" />
								</template>
							</Column>
							<Column
								field="created_at"
								header="Created At"
								style="width: 40%"
							>
								<template #editor="{ data, field }">
									<InputText v-model="data[field]" />
								</template>
							</Column>
							<Column header="Action" :style="{ width: '150px' }">
								<template #body="slotProps">
									<Button
										icon="pi pi-pencil"
										class="p-button-rounded p-button-warning mr-2"
										@click="editGroup(slotProps.data)"
									/>
									<Button
										icon="pi pi-trash"
										class="p-button-rounded p-button-danger mt-2"
										@click="
											deleteGroup(slotProps.data, $event)
										"
									/>
								</template>
							</Column>
						</DataTable>
					</div>
				</div>
			</div>
			<div style="text-align: right">
				<Button
					type="button"
					label="Add Group"
					icon="pi pi-plus"
					:loading="loading[0]"
					@click="addData(0)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import ApplicationGroupService from '../../service/ApplicationGroupService';
import AuthService from '../../service/AuthService';

export default {
	data() {
		return {
			editingRows: [],
			columns: null,
			groupList: null,
			dataId: null,
			deleteStatus: false,
			allowedDelete: false,
			loading: [false],
			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [
				{ label: 'Admin', to: '/dashboard' },
				{ label: 'User Privilage', to: '/mgm/acl/grouplist' },
			],
		};
	},
	applicationGroupService: null,
	created() {
		AuthService.whoAmi().then((value) => {
			if (value.status === 200) {
				ApplicationGroupService.getAllGroup().then((value) => {
					this.groupList = value.data.data.userGroup;
				});
			}
		});
	},
	methods: {
		deleteGroup(group, event) {
			this.$confirm.require({
				target: event.currentTarget,
				message: 'Are you sure you want to proceed?',
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					ApplicationGroupService.getAllowedDelete(group.id).then(
						async (resp) => {
							if (resp.data.status === 202) {
								const payload = { group };
								ApplicationGroupService.deleteGroup(
									payload
								).then((resp) => {
									if (resp.status === 200) {
										this.deleteStatus = true;
										window.location.reload();
										this.$toast.add({
											severity: 'info',
											summary: 'Confirmed',
											detail: 'Group Delete',
											life: 3000,
										});
									}
								});
							} else {
								this.$toast.add({
									severity: 'error',
									summary: 'Rejected',
									detail: 'Cannot Delete Group, Group Used By Another User',
									life: 3000,
								});
							}
						}
					);
				},
				reject: () => {
					this.$toast.add({
						severity: 'error',
						summary: 'Rejected',
						detail: 'Cancel',
						life: 3000,
					});
				},
			});
		},
		addData(index) {
			this.loading[index] = true;
			setTimeout(() => (this.loading[index] = false), 1000);
			this.$router.push({
				name: 'groupListDetails',
				params: { id: 'add' },
			});
		},
		editGroup(rowData) {
			this.dataId = rowData;
			// this.$router.push({ name: 'groupdetail', params: { dataId: rowData.id }});
			this.$router.push({
				name: 'groupListDetails',
				params: { id: rowData.id },
			});
		},
	},
	mounted() {},
};
</script>

<style lang="scss" scoped>
::v-deep(.editable-cells-table td.p-cell-editing) {
	padding-top: 0;
	padding-bottom: 0;
}
</style>
