<template>
  <AutoComplete
    :suggestions="filteredIncoterms"
    v-model="selectedIncoterm"
    @complete="searchIncoterm($event)"
    :dropdown="true"
    field="ASN_FOB"
    placeholder="Select Incoterm"
    :disabled="dropdownIncoterm"
    forceSelection
  >
    <template #item="slotProp">
      {{ slotProp.item.ASN_FOB }}
    </template>
  </AutoComplete>
</template>

<script setup>
import { defineEmits, watch, onMounted, ref } from "vue";
import { getIncotermList } from "@/service/DocsetupService";
import AuthService from "@/service/AuthService";

// eslint-disable-next-line no-undef
const filteredIncoterms = ref([]);
const incoterms = ref([]);
const selectedIncoterm = ref();
const dropdownIncoterm = ref(false);

// eslint-disable-next-line no-undef
const props = defineProps({
  dropIncoterm: {
    type: String,
  },
});
watch(
  () => props.dropIncoterm,
  async () => {
    if (props.dropIncoterm === true) {
      dropdownIncoterm.value = false;
    } else {
      dropdownIncoterm.value = true;
    }
  }
);
// eslint-disable-next-line
onMounted(async () => {
  try {
    const auth = await AuthService.whoAmi();
    if (auth.status === 200) {
      const incotermList = await getIncotermList();
      incotermList.forEach((el) => {
        incoterms.value.push(el);
      });
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
});
const emit = defineEmits(["passFOB"]);
watch(
  () => selectedIncoterm.value,
  () => {
    emit("passFOB", selectedIncoterm.value);
  }
);

const searchIncoterm = async (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredIncoterms.value = [...incoterms.value];
    } else {
      filteredIncoterms.value = incoterms.value.filter((term) => {
        return term.ASN_FOB.toLowerCase().match(event.query.toLowerCase());
      });
    }
  }, 250);
};
</script>
