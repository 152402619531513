<template>
	<!-- eslint-disable -->
	<Toast />
	<div class="Grid">
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<!-- Search -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search Batch</div>
				<div class="content-body">
					<div class="col-12 p-fluid">
						<div class="grid formgrid" style="margin: 1em 0 0 0">
							<div class="col-12 mb-5 lg:col-3">
								<CompaniesDropdown
									v-model="company"
									class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
									:disabled="
										!(userInfo
											? userInfo.leveltenant === 2
											: false)
									"
								/>
							</div>
							<div class="col-12 mb-5 lg:col-3 lg:mb-5">
								<StatusDropdown
									v-model="statusBatchList"
									:company="company ? company : null"
									:supplier="supplier ? supplier : null"
									class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
									:key="statusReload"
								/>
							</div>
							<div v-if="!isSupplierpage">
								<SupplierDropdown
									v-model="supplier"
									v-if="
										!(userInfo
											? userInfo.leveltenant === 2
											: false)
									"
									style="display: none"
									:company="company ? company : null"
									:key="supplierReload"
								/>
							</div>
							<div class="mr-3 ml-2">
								<Button
									@click="handleSearch(true)"
									class="p-button-success uppercase"
									label="search"
									:disabled="!company"
								/>
							</div>
							<div></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Search results -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search Result</div>
				<div class="content-body">
					<div class="mt-4">
						<DetailBatch
							:column="column ? column.col : null"
							:company="company ? company : null"
							:supplier="supplier ? supplier : null"
							:statusBatchList="
								statusBatchList ? statusBatchList : null
							"
							:key="reload"
						></DetailBatch>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, watch } from 'vue';
import DetailBatch from '../batchList/components/DetailBatch';
import AuthService from '../../service/AuthService';
import LovService from '../../service/LovService';
// import PoFulfillment from "../../service/PoFulfillment";
import BatchListService from '../../service/batchList';
import SimpleCrypto from 'simple-crypto-js';
import CompaniesDropdown from '@/components/components/CompaniesDropdown';
import SupplierDropdown from '@/components/components/SupplierDropdown';
import StatusDropdown from '../../components/batchList/components/StatusDropdown';
import { useRouter } from 'vue-router';

const breadcrumbHome = { icon: 'pi pi-home', to: '/dashboard' };
const breadcrumbItems = [
	{
		label: 'Batch List',
		to: '/mgm/monitor/batchlist',
	},
];
const company = ref();
const supplier = ref(null);
const statusBatchList = ref();
const userInfo = ref();
const supplierReload = ref(0);
const statusReload = ref(0);
const reload = ref(0);
const router = useRouter();

watch(
	() => company.value,
	() => {
		supplier.value = null;
		supplierReload.value++;
	},
	() => {
		statusBatchList.value = null;
		statusReload.value++;
	}
);

AuthService.whoAmi().then(async (response) => {
	if (response.status === 200) {
		userInfo.value = response.data.data;
		if (userInfo.value.leveltenant === 2) {
			//get company
			const respCbN = await LovService.getCompanyByName(userCompany());
			company.value = respCbN.data.data[0];
			//get supplier
			const param = {
				db_instance: company.value.db_instance,
				org_id: company.value.value,
			};

			const respVendor = await LovService.getSuppBindComp(
				response.data.data.id
			);
			console.log('respVendor', respVendor);
			// const vendorName = respVendor.data.data[0][0].vendorname;
			const vendorid = respVendor.data.data[0].vendor_id;
			console.log('vendorId', vendorid);
			const respSuppName = await LovService.getSupplierName(param);
			console.log('respSuppName', respSuppName);

			if (respSuppName.status === 200) {
				const suppList = respSuppName.data.data;
				// const suppList = [];
				for (const s of suppList) {
					// console.log('supplits', suppList)
					if (s.vendor_id === vendorid) {
						supplier.value = s;
						console.log('supplier.value', supplier.value);
						break;
					}
				}
				if (supplier.value === null) {
					router.push('/dashboard');
					window.alert('Connection Unstable, ID not Recognize');
				}
			}
			const payload = {
				org_id: company.value.value,
				suppVal: supplier.value
					? supplier.value.vendor_id.toString()
					: null,
			};
			const result = await BatchListService.getLovStatus(payload);
			if (result.status === 200) {
				const statusBatch = result.data.data.data;
				for (var q of statusBatch) {
					if (q.name === statusBatch) {
						statusBatchList.value = q;
					}
				}
			}

			// const payload = {
			//   org_id: company.value.value,
			//   suppVal: supplier.value? supplier.value.vendor_id.toString() : null,
			// };
			// const ress = await BatchListService.getLovStatus(payload);
			// if (ress.status === 200) {
			//   const respone = ress.data.data[0];
			//   // if (respone.length > 0) {
			//   //   disablevalue.value = false
			//   // }
			//   for (var q of respone) {
			//     if (q.name === respone) {
			//       statusBatchList.value = q;
			//       break;
			//     }
			//   }
			// }
		} else {
			const respCbN = await LovService.getCompanyByName(userCompany());
			company.value = respCbN.data.data[0];
		}
	}
});

const handleSearch = () => {
	reload.value++;
};

const userCompany = () => {
	const secretKey = 'some-unique-key';
	const simpleCrypto = new SimpleCrypto(secretKey);
	return simpleCrypto.decrypt(sessionStorage.getItem('dropdownCompany'));
};
</script>
