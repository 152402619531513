import PoFulfillment from "../service/PoFulfillment"
import LovService from "../service/LovService"

export async function filterAuthByVendorname(post){
    
    try {
        const {dbInstance, value, info_id} = post 
        let supplier = null;

        const param = {
            db_instance: dbInstance,
            org_id: value,
        };
        const respVendor = await PoFulfillment.getSuppBindComp(
           info_id
        );
        const vendorName = respVendor.data.data[0][0].vendorname;
        const respSuppName = await LovService.getSupplierName(param);
        if (respSuppName.status === 200) {
            const suppList = respSuppName.data.data;
            for (const s of suppList) {
                if (s.vendor_name === vendorName) {
                    supplier = s;
                    break;
                }
            }
            if (supplier === null) {
                window.alert('Connection Unstable, ID not Recognize');
            }
        }
        return {supplier}
    } catch (error) {
        return {supplier:null}
    }
}