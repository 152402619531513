import spbinvoice from './api/SpbInvoiceApi';

class InvoiceListProxyService {
	getCompanyList() {
		return spbinvoice.get(`/spb/listinvoiceproxy/companylist`);
	}

	getSupplierList(payload) {
		return spbinvoice.post(`/spb/listinvoiceproxy/supplierlist`, payload);
	}

	getCurrencyList(payload) {
		return spbinvoice.post(`/spb/listinvoiceproxy/currencylist`, payload);
	}

	getListInvoice(params, payload) {
		const queryParams = params
			? Object.keys(params)
					.map(
						(k) =>
							encodeURIComponent(k) +
							'=' +
							encodeURIComponent(params[k])
					)
					.join('&')
			: '';

		return spbinvoice.post(
			`/spb/listinvoiceproxy/listinvoice?${queryParams}`,
			payload
		);
	}

	getExportPDF(payload) {
		return spbinvoice.post(
			`/spb/listinvoiceproxy/exportPdf`,
			payload
		);
	}

	getInvoiceDetail(id, payload) {
		return spbinvoice.post(
			`/spb/listinvoiceproxy/invoicedetail/${id}`,
			payload
		);
	}

	getCategory(payload) {
		return spbinvoice.post(`/spb/listinvoiceproxy/category`, payload);
	}

	getAvailableCategoryDetails(payload) {
		return spbinvoice.post(
			`/spb/listinvoiceproxy/availablecategorydetails`,
			payload
		);
	}

	updateInvoice(id, payload) {
		return spbinvoice.put(
			`/spb/listinvoiceproxy/updateinvoice/${id}`,
			payload
		);
	}

	getFileBase64(invoiceId, leveltenant, vendorId) {
		return spbinvoice.get(`/invoicepo/getfilebase64/${invoiceId}/${leveltenant}/${vendorId}`);
	}
}

export default new InvoiceListProxyService();
