<template>
  <div class="grid">
    <div class="col-12">
      <div class="col-12 p-fluid">
        <div class="col-12 mb-5 formatText">
          <div class="img-din">
            <img
            alt="Logo"
            :src="imgDinamic"
            style="width: 130px; object-fit: cover;"
            v-if="isImage"
          />
          </div>       
          <h2>Goods Receipt Note</h2>
          <p>Invoice No: {{ titleInv }}</p>
          <div style="display: flex; justify-content: space-between">
            <p>Supplier: {{ titleSupp }}</p>
            <p>Print: {{ this.formatDateDexa(new Date()) }}</p>
          </div>
          <br />
          <DataTable
            :value="rooTable"
            dataKey="pr_line_num"
            v-model:filters="filters"
            v-model:selection="selectedRoo"
            removableSort
            :loading="loading"
            showGridlines
          >
            <Column
              class="call-button-container"
              header="No BAST"
              field="bast_number"
            ></Column>
            <Column
              class="call-button-container"
              header="PO Number"
              field="po_number"
            ></Column>
            <Column
              class="call-button-container"
              header="PO Line"
              field="line_num"
            ></Column>
            <Column
              class="call-button-container"
              header="Termin"
              field="termin"
            ></Column>
            <Column
              class="call-button-container"
              header="Qty"
              field="quantity"
            ></Column>
            <Column 
              class="call-button-container" 
              header="Price" 
              field="price"
            ></Column>
            <Column header="Amount" field="amount"></Column>
            <Column header="Qty Progress" field="quantity_wo"></Column>
            <Column header="Confirm Amount" field="confirm_amount"></Column>
            <Column header="Confirm Progress (%)" field="confirm_progress"></Column>
          </DataTable>
          <br />
        </div>

        <div class="p-fluid grid formgrid" v-if="!hideButton">
          <div class="col-6 mb-2 lg:col-1 lg:mb-0">
            <Button
              type="button"
              @click="printNow()"
              label="Print"
              class="p-button-primary"
            />
          </div>
          <div class="col-6 mb-2 ml-2 lg:col-1 lg:mb-0">
            <Button
              type="button"
              @click="closeWindow()"
              label="Close"
              class="p-button-secondary"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import AuthService from "../service/AuthService";
import InvoicePOService from "../service/InvoicePOService";

export default {
  data() {
    return {
      rooTable: null,
      titleSupp: null,
      titleInv: null,
      batchNum: null,
      paramsPrint: JSON.parse(sessionStorage.getItem("paramscreateinvoice")),
      currentDate: new Date(),
      hideButton: false,
      isSupplier: true,
      imgDinamic: null,
      company: null,
      isImage: false,
    };
  },
  created() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();
    let hh = today.getHours();
    let m = today.getMinutes();
    let d = today.getSeconds();
    this.combinedDate = yyyy + mm + dd + hh + m + d;

    document.title = "good-receipt" + "-" + this.combinedDate;

    AuthService.whoAmi().then(async(response) => {
      if (response.status === 200) {
        // response.data.data.leveltenant = 1
        await InvoicePOService.printBatchPur(this.paramsPrint).then((respond) => {
          let supp = respond.data.data.data[0].VENDOR_NAME;
          let noInvoice = this.paramsPrint.invoice_no;
          this.company = respond.data.data.data[0].ORGANIZATION_ID;
        
          if (this.company !== null || this.company !== 'null' || this.company !== undefined) {
            if (this.company === 81) {
              this.isImage = true;
              this.imgDinamic = "layout/images/company logo/ferron.jpeg";
            } else if (this.company === 82) {
              this.isImage = true;
              this.imgDinamic = "layout/images/company logo/dexa medica.png";
            } else if (this.company === 1212) {
              this.isImage = true;
              this.imgDinamic = "layout/images/company logo/beta pharmacon.png";
            } else if (this.company === 772) {
              this.isImage = true;
              this.imgDinamic = "layout/images/company logo/equilab.png";
            } else if (this.company === 972) {
              this.isImage = true;
              this.imgDinamic = "layout/images/company logo/fonko.png";
            }
          } else{
            this.isImage = false;
          };
          this.titleInv = noInvoice;
          this.titleSupp = supp;
          this.rooTable = this.paramsPrint.details;
          if (this.rooTable.length == 0) {
            window.alert("No data found");
            window.close();
          }
        });
        if (response.data.data.leveltenant == 1) {
          this.isSupplier = false;
        } else {
        }
      }
    });
  },
  methods: {
    closeWindow() {
      window.close();
    },
    printNow() {
      this.hideButton = true;
      setTimeout(this.actualPrintNow, 300);
    },
    formatDateDexa(date) {
      var date = new Date(date);
      var respondDate =
        ("00" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        ("00" + date.getDate()).slice(-2) +
        "/" +
        date.getFullYear() +
        " " +
        ("00" + date.getHours()).slice(-2) +
        ":" +
        ("00" + date.getMinutes()).slice(-2) +
        ":" +
        ("00" + date.getSeconds()).slice(-2);
      return respondDate;
    },
    actualPrintNow() {
      if (this.hideButton === true) {
        window.print();
        if (document.hasFocus()) {
          this.hideButton = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.formatText p {
  font-size: 16px;
}
</style>