import { encryptForm } from '../helper/encryptForm';
import spbinvoice from './api/SpbInvoiceApi';

class PendingPOService {

	getListPendingPo(payload) {
		return spbinvoice.post(`/spb/pendingpo/listpendingpo`, encryptForm(payload));
	}

	getDetailPendingPo(payload) {
		return spbinvoice.post(`/spb/pendingpo/getdetailpendingpo`, encryptForm(payload));
	}

	getPONumberList(payload) {
		return spbinvoice.post(`/spb/pendingpo/getponumberlist`, encryptForm(payload));
	}

	saveInvoiceStandart(payload){
		return spbinvoice.post(`/spb/pendingpo/saveinvoicestandart`, payload);
	}

	getBASTNumberList(payload) {
		return spbinvoice.post(`/spb/pendingpo/getlistbastnumber`, encryptForm(payload));
	}

	getBASTDetailList(payload) {
		return spbinvoice.post(`/spb/pendingpo/getlistbastdetail`, encryptForm(payload));
	}

	submitInvoice(payload){
		return spbinvoice.post(`/spb/pendingpo/submitinvoice`, payload)
	}

	submitInvoicePrepayment(payload){
		return spbinvoice.post(`/spb/pendingpo/submitinvoiceprepayment`, payload)
	}

	getDetailPrepayment(payload){
		return spbinvoice.post(`/spb/pendingpo/getdetailprepayment`, encryptForm(payload));
	}

	getListFindPoPrepayment(payload){
		return spbinvoice.post(`/spb/pendingpo/getlistfindpoprepayment`, encryptForm(payload));
	}

	rejectInvoicePur(payload){
		return spbinvoice.post(`/spb/pendingpo/rejectinvoicepur`, payload);
	}

	getTotalPPN(payload){
		return spbinvoice.post(`/spb/pendingpo/gettotalppn`, encryptForm(payload));
	}

	getTotalPPNProc(payload){
		return spbinvoice.post(`/spb/pendingpo/gettotalppnproc`, encryptForm(payload));
	}

	getTotalPPNPrepayment(payload){
		return spbinvoice.post(`/spb/pendingpo/gettotalppnprepayment`, encryptForm(payload));
	}

	getSupplier(payload){
		return spbinvoice.post(`/spb/pendingpo/getsupplier`, encryptForm(payload));
	}

	submitToRekomendatorPrepayment(payload){
		return spbinvoice.post(`/spb/pendingpo/submittorekomendatorprepayment`, encryptForm(payload));
	}

	getVendorName(payload){
		return spbinvoice.post(`/spb/pendingpo/getvendorname`, encryptForm(payload));
	}

	checkMinimumLine(payload){
		return spbinvoice.post(`/spb/pendingpo/checkminimumline`, encryptForm(payload));
	}

	getListInvoiceNumber(payload){
		return spbinvoice.post(`/spb/pendingpo/getlistinvoicenumber`, encryptForm(payload));
	}

	downloadUrl(payload){
		return spbinvoice.post(`/spb/pendingpo/downloadinvoice`, payload);
	}

	getVendorForPic(payload){
		return spbinvoice.post(`/spb/pendingpo/getvendorforpic`, encryptForm(payload));
	}

	getPoType(payload){
		return spbinvoice.post(`/spb/pendingpo/getpotype`, encryptForm(payload));
	}

	getSupplierName(payload){
		return spbinvoice.post(`/spb/pendingpo/getsuppliername`, encryptForm(payload));
	}

	getRounded(payload){
		return spbinvoice.post(`/spb/pendingpo/roundingvalidate`, encryptForm(payload));
	}

	findIsBatch(payload){
		return spbinvoice.post(`/spb/pendingpo/findinvoice`, encryptForm(payload));
	}

	getPOInvoiceList(payload){
		return spbinvoice.post(`/spb/pendingpo/getPOInvoiceList`, payload)
	}

	getPOInvoiceListVendor(payload){
		return spbinvoice.post(`/spb/pendingpo/getPOInvoiceListVendor`, payload);
	}

	findCompanyById(payload){
		return spbinvoice.post(`/spb/pendingpo/findcompanybyid`, payload);
	}
}

export default new PendingPOService();
