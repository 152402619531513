<template>
  <AutoComplete
    :suggestions="filteredUserFinance" @complete="searchUserFinance($event)" 
    :dropdown="true" field="user_name" v-model="userFinanceValue" 
    :disabled="!userFinance.length || props.category === CategoryEnum.Prepayment" forceSelection>
    <template #item="slotProp">
      {{ slotProp.item.user_name }}
    </template>
  </AutoComplete>
</template>

<script setup>
import { onMounted, ref,defineEmits,watchEffect, watch} from "vue";
import AuthService from "../../../service/AuthService";
import vendorServices from "../../../service/masterVendorService";
import { CategoryEnum } from "../../../constants/CategoryEnum";

// eslint-disable-next-line no-undef
const filteredUserFinance = ref([]);
const userFinance = ref([]);
const userFinanceValue = ref();
const emit = defineEmits(['remitUserFinance'])
// eslint-disable-next-line no-undef
const props = defineProps({
  company: { type: Object },
  supplier: { type: Object },
  userFinanceId:{type: Object},
  category: {type: Object},
});

watch(() => props.category, () => {
  populateData()
})

onMounted(() => {
    populateData()
})

watchEffect(() => 
  emit('remitUserFinance',userFinanceValue),
)


watch(() => userFinanceValue.value, () => {
  emit('remitUserFinance',userFinanceValue)
})


const populateData = () => {
  AuthService.whoAmi().then(async (response) => {
    let payload
    if(props.company !== null) {
      payload = {
      org_id: props.company.value,
      db_instance:  props.company.db_instance,
    }
    if (response.status === 200) {
        console.log(props.category, "PROPS CATEGORY")
        const data = props.category.CATEGORY_NAME === 'PREPAYMENT' ? 
         await vendorServices.getUserPurchasing(payload) : await vendorServices.getUserFinance(payload)
        
        userFinance.value = data.data.data.data
        userFinanceValue.value = data.data.data.data
        for (const s of userFinance.value) {
              if (s.user_id === props.userFinanceId) {
                userFinanceValue.value = s;
                break;
              }
              else{
                userFinanceValue.value = null;
              }
              
            }
      }
    } 
  })
}

const searchUserFinance = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredUserFinance.value = [...userFinance.value];
    } else {
      filteredUserFinance.value = userFinance.value.filter((comp) => {
        return comp.user_name.toLowerCase().match(event.query.toLowerCase());
      });
    }
  }, 250);
};
</script>

<style scoped></style>
