<template>
    <div class="grid">
        <div class="col-12">
            <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
        </div>

        <div class="col-12 mb-4">
            <div class="content-wrapper">
                <div class="content-header">
                    Search
                </div>
                <div class="content-body">
                    <div class="grid w-full">

                        <div class="lg:col-3 col-12 mb-4">
                            <span class="p-float-label">
                                <AutoComplete 
                                    id="company"
                                    :dropdown="true"
                                    placeholder="Company" 
                                    class="w-full"
                                    v-model="company"
                                    :disabled="true"
                                />
                                <label for="company">Company</label>
                            </span>
                        </div>

                        <div class="lg:col-3 col-12 mb-4">
                            <span class="p-float-label">
                                <AutoComplete 
                                    id="statusInvoice"
                                    class="w-full"
                                    v-model="status"
                                    :suggestions="filteredStatus"
                                    @complete="searchStatus($event)"
                                    :dropdown="true"
                                    field="name"
                                    forceSelection
                                >
                                    <template #item="{ item }">
                                        <div>
                                            {{ item.name }}
                                        </div>
                                    </template>
                                </AutoComplete>
                                <label for="statusInvoice">Status</label>
                            </span>
                        </div>
                        <div class="lg:col-3 col-12 mb-4">
                            <div class="p-inputgroup">
                                <div class="p-float-label">
                                    <AutoComplete
                                        id="invoiceNumber"
                                        :suggestions="filteredInvoiceNo"
                                        @complete="searchInvoiceNo($event)"
                                        field="INVOICE_NO"
                                        v-model="invoiceNo"
                                        forceSelection
                                        class="w-full"
                                        
                                    />
                                    <label for="invoiceNumber">Invoice No</label>
                                </div>
                            </div>
                        </div>
                        <div class="lg:col-3 col-12 mb-4">
                            <span class="p-float-label">
                                <AutoComplete 
                                    id="supplierDD"
                                    :suggestions="filteredSupplier"
                                    :dropdown="true"
                                    class="w-full"
                                    field="name"
                                    v-model="supplier"
                                    @complete="searchSupplier($event)"
                                />
                                <label for="supplierDD">Supplier</label>
                            </span>
                        </div>
                        <div class="lg:col-3 col-12">
                            <div class="grid">
                                <div class="col-12 lg:col-6">
                                    <Button class="p-button-success w-full">
                                        <span class="font-bold text-center w-full">SEARCH</span>
                                    </Button>
                                    
                                </div>
                                <div class="col-12 lg:col-6">
                                    <Button class="p-button-secondary w-full">
                                        <span class="font-bold text-center w-full">CLEAR</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 mb-4">
            <div class="content-wrapper">
                <div class="content-header">
                    Search Result
                </div>
                <div class="content-body">
                    <DataTable
                        :value="tableList"
                        dataKey="ID"
                        :scrollable="true"
                        :rows="5"
                        :rowsPerPageOptions="[5, 10, 20, 50]"
                        :paginator="true"
                        scrollHeight="600px"
                        scrollDirection="both"
                        responsiveLayout="scroll"
                        ref="dt"
                    >
                        <template #header>
                            <div>
                                <span class="p-input-icon-left" style="width: 100%">
                                    <i class="pi pi-search" />
                                    <InputText
                                        v-model="invoiceFilter"
                                        style="width: 100%"
                                        placeholder="Search Invoice"
                                        @keyup.enter="onEnterSearch($event)"
                                    />
                                </span>
                            </div>
                        </template>

                        <Column header="Company" field="COMPANY" style="width:20%;">
                            <template #body="{data}">
                                {{ data.COMPANY }}
                            </template>
                        </Column>
                        <Column header="Supplier" field="SUPPLIER" style="width:25%;">
                            <template #body="{data}">
                                {{ data.SUPPLIER }}
                            </template>
                        </Column>
                        <Column header="Invoice No" field="INVOICE_NO" style="width:20%;">
                            <template #body="{data}">
                                {{ data.INVOICE_NO }}
                            </template>
                        </Column>
                        <Column header="Date" field="DATE" :sortable="true" style="width:20%;">
                            <template #body="{data}">
                                {{ data.DATE }}
                            </template>
                        </Column>
                        <Column header="Amount" field="AMOUNT" style="width:20%;">
                            <template #body="{data}">
                                {{ data.AMOUNT }}
                            </template>
                        </Column>
                        <Column header="Status" field="STATUS" style="width:20%;">
                            <template #body="{ data }">
                                <Chip
                                    class="custom-chip"
                                    :class="`chip-${data.STATUS.toLowerCase()}`"
                                >
                                    {{ data.STATUS }}
                                </Chip>
                            </template>
                        </Column>

                        <Column
                            header="Actions"
                            style="width: 10%"
                            alignFrozen="right"
                            frozen
                        >
                            <template #body="{data}">
                                <Button
                                    icon="pi pi-eye"
                                    class="p-button-rounded p-button-text"
                                    @click="handleClick(data.id, data.po_type)"
                                />
                            </template>
                        </Column>

                        <template #empty>
                            Data Not Found
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>


    </div>
</template>

<script setup>
    import {onMounted, ref, watch} from "vue";
    import { useRoute } from "vue-router";
    import AuthService from "../../service/AuthService";
    import LovService from "../../service/LovService";
    import SimpleCrypto from "simple-crypto-js";
    import {fakeInvoiceNo, ptListMapper, fakeDataTable} from "./fakeInvoice";
    import {formatDate} from "../../helper/formatDate";
    import {formatCurrency} from "../../helper/formatCurrency"
    
    const route = useRoute();
    const breadcrumbHome = ref({ icon: "pi pi-home", to: "/dashboard" });
    const breadcrumbItems = ref([
        {
            label: "Search Invoice W/PO",
            to: route.fullPath,
        },
    ]);


    //company
    const company = ref();
    const companies = ref();

    //status
    const statusList = ref([]);
    const filteredStatus = ref([]);
    const status = ref();

    //list invoice
    const filteredInvoiceNo = ref([]);
    const listInvoiceNo = ref([]);
    const invoiceNo = ref();

    //list supplier
    const filteredSupplier = ref([]);
    const listSupplier = ref([]);
    const supplier = ref();

    //list table
    const tableList = ref([]);

    onMounted(() => {
        listInvoiceNo.value = fakeInvoiceNo(30);
        statusList.value = [
            {id: 1, name: "NEW"},
            {id: 2, name: "PROCESSED"},
            {id: 3, name: "PORSTED"}
        ];
        listSupplier.value = ptListMapper()
    })

    watch(company,(item) =>{
        tableList.value = fakeDataTable(30,item).map((Q) => {
            return{...Q, AMOUNT: formatCurrency(Q.AMOUNT), DATE: formatDate(Q.DATE)}
        });
    })

    AuthService.whoAmi().then(async () => {
        function userCompany() {
            const secretKey = 'some-unique-key';
            const simpleCrypto = new SimpleCrypto(secretKey);
            return simpleCrypto.decrypt(
                sessionStorage.getItem('dropdownCompany')
            );
        }
        const respCbN = await LovService.getCompanyByName(
            userCompany()
        );
        companies.value = respCbN.data.data[0];
        company.value = respCbN.data.data[0].name
    });



    const searchStatus = async (event) => {
        filteredStatus.value = statusList.value.filter((item) => 
            item.name.toLowerCase().includes(
                event.query.toLowerCase()
            )
        )
    }

    const searchInvoiceNo = async (event) => {
        filteredInvoiceNo.value = listInvoiceNo.value.filter((item) => 
            item.INVOICE_NO.toLowerCase().includes(
                event.query.toLowerCase()
            )
        )
    }

    const searchSupplier = async (event) => {
        filteredSupplier.value = listSupplier.value.filter((item) => 
            item.name.toLowerCase().includes(
                event.query.toLowerCase()
            )
        )
    }
</script>

<style scoped>
    .p-chip.custom-chip {
        padding: 4px 12px;
        border-radius: 8px;
        font-size: 12px;
        font-weight: 600;
    }

    .chip-open {
        background: #0ea5e9;
        color: #fff;
    }
    .chip-rejected {
        background: var(--primary-color);
        color: #fff;
    }
    .chip-review {
        background: #f59e0b;
        color: #fff;
    }
    .chip-posted {
        background: #10b981;
        color: #fff;
    }
    .chip-processed {
        background: #2563eb;
        color: #fff;
    }
</style>

