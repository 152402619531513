<template>
	<AutoComplete
		:suggestions="filteredRefNumber"
		@complete="searchRefNumber($event)"
		:dropdown="true"
		v-model="refValue"
		field="sk_number"
		placeholder="Reference Number"
		forceSelection
	>
		<template #item="slotProp">
			{{ slotProp.item.sk_number }}
		</template>
	</AutoComplete>
</template>

<script setup>
import { onMounted, ref, defineEmits, watch } from 'vue';
import AuthService from '../../../service/AuthService';
import ListInvoiceNonPo from '../../../service/ListInvoiceNonPo';
// eslint-disable-next-line no-undef
const props = defineProps({
	db_instance: { type: Object },
	vendor_id: { type: Number },
});
// eslint-disable-next-line no-undef
const filteredRefNumber = ref([]);
const refNumber = ref([]);
const refValue = ref();
const emit = defineEmits(['refNumber']);
onMounted(() => {
	populateData();
});
watch(
	() => refValue.value,
	(newValue) => {
		emit('refNumber', newValue);
	}
);
// watchEffect(() =>
//   emit('refNumber',refValue.value)
// )
const populateData = () => {
	AuthService.whoAmi().then(async (response) => {
		if (response.status === 200) {
			if (props.db_instance !== null) {
				const payload = {
					db_instance: props.db_instance ? props.db_instance : null,
					vendor_id: props.vendor_id,
				};
				const data = await ListInvoiceNonPo.getReferenceNumber(payload);
				refNumber.value = data.data.data.data;
			}
		}
	});
};
const searchRefNumber = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredRefNumber.value = [...refNumber.value];
		} else {
			filteredRefNumber.value = refNumber.value.filter((comp) => {
				return comp.sk_number
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
		}
	}, 250);
};
</script>

<style scoped></style>
