import SpbAdminApi from "./api/SpbAdminApi";
import spborder from "./api/SpbOrderApi";
class SwitchCompany {
    //Ngambil data Company dari LOV
    getCompany() {
      return spborder.get("/spb/company/getcompany");
    }
    getDbInstance(payload) {
      return spborder.post("spb/company/getdbinstance", payload)
    }
    getCompanyPayload(payload) {
      return SpbAdminApi.get("/spb/usermapping/getusermappingsbyiduser/"+ payload)
    }
  }
  export default new SwitchCompany();