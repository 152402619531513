<template>
	<div class="grid spe-report">
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<!-- Form create/update SPE Report -->
		<FormSpeReport
			v-if="currentUser && company"
			title="Create SPE Report"
			:currentUser="currentUser"
			:company="company"
			:vendorId="vendorId"
		/>
	</div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import SimpleCrypto from 'simple-crypto-js';
import AuthService from '../../service/AuthService';
import LovService from '../../service/LovService';
import PoFulfillment from '../../service/PoFulfillment';
import FormSpeReport from './components/FormSpeReport.vue';

// Router
const router = useRouter();
const route = useRoute();

// State
const currentUser = ref(null);
const company = ref(null);
const companyList = ref(null);
const breadcrumbHome = ref({ icon: 'pi pi-home', to: '/dashboard' });
const breadcrumbItems = ref([
	{
		label: 'SPE Report',
		to: '/mgm/master/spereport',
	},
	{
		label: 'Create SPE Report',
		to: route.fullPath,
	},
]);

onMounted(async () => {
	// Check current user
	const resAuth = await AuthService.whoAmi();
	currentUser.value = resAuth.data.data;

	// Check if user is not purchasing nor admin
	if (
		currentUser.value.leveltenant !== 1 &&
		currentUser.value.userid !== 'dexaadmin'
	) {
		// Redirect to homepage if user is purchasing and not admin
		router.push('/dashboard');
		return;
	}

	// Get company
	await getCompany();
});

// Get company list
const getCompany = async () => {
	if (currentUser.value.userid === 'dexaadmin') {
		// If user is admin
		const companyRes = LovService.getValueType('COMPANY');
		companyList.value = companyRes.data.data;
	} else if (currentUser.value.leveltenant === 2) {
		// If user is supplier
		let payload = currentUser.value.id;
		const companySuppRes = await PoFulfillment.getCompanySupp(payload);
		// console.log('Company supp', companySuppRes);

		try {
			const companySupp = companySuppRes.data.data;
			const companyValues = await Promise.all(
				companySupp.map(async (element) => {
					const res = await LovService.getCompanyByName(
						element.company
					);

					const secretKey = 'some-unique-key';
					const simpleCrypto = new SimpleCrypto(secretKey);
					const decipherText = simpleCrypto.decrypt(
						sessionStorage.getItem('dropdownCompany')
					);

					if (res.data.data[0].name === decipherText) {
						company.value = res.data.data[0];
					}

					return res.data.data[0];
				})
			);

			companyList.value = companyValues;
		} catch (e) {
			console.log(e);
			if (e !== 'Break') throw e;
		}
	} else if (currentUser.value.leveltenant === 1) {
		// If user is Finance
		const res = await LovService.getcompanydb(currentUser.value.bioaddress);

		companyList.value = res.data.data;

		try {
			const secretKey = 'some-unique-key';
			const simpleCrypto = new SimpleCrypto(secretKey);
			const decipherText = simpleCrypto.decrypt(
				sessionStorage.getItem('dropdownCompany')
			);
			companyList.value.forEach((element) => {
				if (element.name === decipherText) {
					company.value = element;
					throw 'Break';
				}
			});
		} catch (e) {
			console.log(e);
			if (e !== 'Break') throw e;
		}
	}
};
</script>

<style scoped></style>
