<template>
	<div class="grid">
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<div class="col-12 p-fluid">
			<div class="card">
				<div class="grid formgrid" style="margin: 1em 0 0 0">
					<div class="col-12 mb-5 lg:col-3 lg:mb-5">
						<span class="p-float-label">
							<AutoComplete
								v-model="company"
								:suggestions="filteredCompany"
								@complete="searchCompany($event)"
								field="name"
								:disabled="buttonDisable || !companysupplier"
								forceSelection
								:dropdown="true"
							>
							</AutoComplete>
							<label for="inputtext">Company</label>
						</span>
					</div>
					<div class="col-12 mb-5 lg:col-3 lg:mb-5">
						<span class="p-float-label">
							<AutoComplete
								v-model="noBAST"
								:suggestions="filteredListNo"
								@complete="searchBAST($event)"
								field="BAST_NUMBER"
								:disabled="buttonDisable || !listNo"
								forceSelection
							>
							</AutoComplete>
							<label for="inputtext">BAST No</label>
						</span>
					</div>
					<div class="col-12 mb-5 lg:col-3 lg:mb-5">
						<span class="p-float-label">
							<AutoComplete
								v-model="lov"
								:suggestions="filteredLov"
								@complete="searchLov($event)"
								:dropdown="true"
								field="name"
								:disabled="buttonDisable || !isilov"
								forceSelection
							>
								<template #item="slotProps">
									<div class="lov-item">
										<div class="ml-2">
											{{ slotProps.item.name }}
										</div>
									</div>
								</template>
							</AutoComplete>
							<label for="inputtext">Status</label>
						</span>
					</div>
					<div
						class="col-12 mb-5 lg:col-3 lg:mb-5"
						v-if="!isSupplierPage"
					>
						<span class="p-float-label">
							<AutoComplete
								v-model="supplier"
								:suggestions="filteredSupplier"
								@complete="searchSupplier($event)"
								:dropdown="true"
								field="vendor_name"
								:disabled="buttonDisable"
								forceSelection
							>
								<template #item="slotProps">
									<div class="lov-item">
										<div class="ml-2">
											{{ slotProps.item.vendor_name }}
										</div>
									</div>
								</template>
							</AutoComplete>
							<label for="inputtext">Supplier</label>
						</span>
					</div>
					<div v-if="isSupplierPage" class="col-12 mb-2 lg:col-2 xl:col-1 lg:mb-5">
						<Button
							label="Go"
							@click="searchTableNested()"
							:disabled="this.buttonDisable || !this.supplier"
						></Button>
					</div>
					<div v-if="!isSupplierPage" class="col-12 mb-2 lg:col-2 xl:col-1 lg:mb-5">
						<Button
							label="Go"
							@click="searchTableNested()"
							:disabled="this.buttonDisable || !this.supplierList"
						></Button>
					</div>
					<div class="col-12 mb-2 lg:col-2 xl:col-1 lg:mb-5">
						<Button
							class="p-button-secondary"
							label="Clear"
							@click="clearFilter"
						></Button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="card">
		<DataTable
			class="fixed-scroll-datatable"
			:value="nested"
			:lazy="true"
			:paginator="true"
			:rows="rows"
			v-model:expandedRows="expandedRows"
			ref="dt"
			dataKey="BAST_NUMBER"
			:totalRecords="totalRecords"
			:loading="loading"
			@page="onPage($event)"
			@sort="onSort($event)"
			removableSort
			responsiveLayout="scroll"
			:rowsPerPageOptions="[10, 20, 30]"
			:v-model="first"
		>
			<template #empty> {{ isEmpty }} </template>
			<Column :expander="true" style="width: 50px; flex: 0 0 0" />
			<Column field="BAST_NUMBER" header="BAST Number" :sortable="true"></Column>
			<template #expansion="slotProps">
				<div class="orders-subtable">
					<DataTable
						:value="slotProps.data.children"
						removableSort
						responsiveLayout="scroll"
						:scrollable="true"
						scrollHeight="600px"
						scrollDirection="both"
						:loading="loading"
					>
						<template #empty> {{ isEmpty }} </template>
						<Column header="Line" style="width: 10%" frozen>
							<template #body="slotProps">
								{{ slotProps.index + 1 }}
							</template>
						</Column>
						<!-- <Column field="id" header="Line" :sortable="true" style="text-align: center"></Column> -->
						<Column
							field="ITEM_DESCRIPTION"
							header="Description"
							:sortable="true"
							style="width: 30%"
						></Column>
						<Column
							field="TERMIN"
							header="Termin"
							:sortable="true"
							style="width: 20%; justify-content: center"
						></Column>
						<Column
							header="QUANTITY"
							dataType="numeric"
							style="width: 20%"
							><template #body="{ data }">
								{{ data.QUANTITY }}
							</template>
						</Column>
						<Column
							header="AMOUNT"
							dataType="numeric"
							style="width: 20%"
						>
							<template #body="{ data }">
								{{ formatCurrency(data.AMOUNT) }}
							</template></Column
						>

						<Column
							header="View"
							selectionMode="single"
							style="width: 9%"
							alignFrozen="right"
							frozen
						>
							<template #body="{data}">
								<Button
									icon="pi pi-search"
									class="p-button-rounded p-button-text"
									@click="editChildren(data)"
								/>
							</template>
						</Column>
					</DataTable>
				</div>
			</template>
		</DataTable>
	</div>
</template>

<script>
/* eslint-disable */
import AuthService from '../../../service/AuthService';
import LovService from '../../../service/LovService';
import SimpleCrypto from 'simple-crypto-js';
import PoFulfillment from '../../../service/PoFulfillment';
import BASTService from '../../../service/BASTService';
export default {
	//  name: "poFulfillment-list",
	name: 'planningorder-list',
	data() {
		return {
			expandedRowGroups: null,
			isSupplierPage: false,
			first: 0,
			rowId: null,
			expandedRows: [],
			expandedRowGroups: [],
			nested: [],
			rows: 10,
			nestedchildren: [],
			groupdata: null,
			selectedChildren: null,
			loading: false,
			lazyParams: {},
			selectLazyParams: {},
			totalRecords: 0,
			totalRecordsGrup: 0,
			tabelexpand: null,
			company: null,
			companyValue: true,
			filteredCompany: null,
			isiCompany: null,
			supplier: null,
			isisupplier: null,
			filteredSupplier: null,
			selectedSupplier: null,
			supplierparse: null,
			search: null,
			filtered: null,
			preparer: null,
			isipreparer: null,
			filteredPreparer: null,
			preparerparse: null,
			selectedPreparer: null,
			lov: null,
			isilov: null,
			filteredLov: null,
			lovparse: null,
			selectedLov: null,
			planningOrder: null,
			isiPlanningOrder: null,
			filteredPlanningOrder: null,
			selectedPlanningOrder: null,
			loe: null,
			isiLoe: null,
			supplierList: null,
			filteredLoe: null,
			button: 0,
			buttonDisable: false,
			buttonSearch: false,
			rowgroup: null,
			testname: null,
			onStorageUpdate: null,
			saveDb: null,
			preparerButton: true,
			suppBindToComp: [],
			isEmpty: null,
			suppName: null,
			// isSupplierPage:false,
			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [
				{ label: 'List BAST', to: '/mgm/settings/listbast' },
			],
			listNo: null,
			filteredListNo: null,
			noBAST: null,
			companysupplier: null,
			filteredCompany: null,
			userLevel: null
		};
	},
	watch: {
		expandedRows(text){
			console.log(text, "TEXT")
		},
		company(newQuestion) {
			if (this.company != null) {
				sessionStorage.setItem('mailToCompany', this.company.name);
				this.planningOrder = null;
				this.lov = null;
				this.preparer = null;
				this.companyValue = false;
				this.preparerButton = false;
				if (
					typeof newQuestion === 'object' &&
					!Array.isArray(newQuestion) &&
					newQuestion !== null
				) {
					// this.getPlenno(newQuestion.name);
					this.companyValue = false;
					this.saveDb = this.company.db_instance;
					//
					sessionStorage.setItem('companydb', this.saveDb);

					//					

					if (this.userLevel == 1) {
						this.getValueCompany(newQuestion.name);
						this.getSuppName(newQuestion);
						this.getBastNo(newQuestion);
						this.buttonDisable = false;
					} else {
						this.getValueCompany(newQuestion.name);
						console.log(this.supplier, "TEST AJA")
						this.getBastNo(newQuestion);
					}
				}
			}
		},
		supplier(newQuestion) {
			if (
				this.planningOrder == null &&
				this.lov == null &&
				this.preparer == null
			) {
				this.lov = null;
				this.preparer = null;
				this.buttonSearch = false;
				this.getBastNo(newQuestion);
				if (
					typeof newQuestion === 'object' &&
					!Array.isArray(newQuestion) &&
					newQuestion !== null
				) {
				
					//
					this.buttonDisable = false;
				}
			}
		},
		lov(newQuestion) {
			this.buttonDisable = false;
		},
		preparer(newQuestion) {
			this.buttonDisable = false;
		},
	},
	async mounted() {
		AuthService.whoAmi().then(async (resp) => {
			if (resp.status === 200) {
				this.userLevel = resp.data.data.leveltenant

				if (this.userLevel == 2) {
					AuthService.whoAmi().then((resp) => {
						PoFulfillment.getSuppBindCompProc(resp.data.data.id).then(
							(response) => {
								this.suppBindToComp.push(
									response.data.data[0][0].vendorname
								);
							}
						);
					});
				}

				this.getCompany();
				this.GetLOV();
				// if (resp.data.data.leveltenant == 1) {
				// 	this.getPoNumber();
				// }
				if (resp.data.data.leveltenant == 2) {
					this.isSupplierPage = true;
					// this.getPoNumber();
					// this.getBastNo();
				}
				// this.isEmpty = 'Please Search for Data'
			}
		});
		this.GetLOV();
		this.isEmpty = 'Please Search for Data';
		// this.loading = true;
		this.lazyParams = {
			first: 0,
			sortField: null,
			sortOrder: null,
			rows: this.rows,
		};
		this.selectLazyParams = {
			first: 0,
			sortField: null,
			sortOrder: null,
			rows: this.rows,
		};
		if (sessionStorage.companydb) {
			this.testname = sessionStorage.companydb;
		}
	},
	computed: {
		searchUser() {
			const searchLc = this.search.toLowerCase();
			return this.nested.filter((item) => {
				return item.po_num.toLowerCase().includes(searchLc);
			});
		},
	},
	methods: {
		clearFilter(){
			this.noBAST = null,
			this.lov = null

			if(this.userLevel != 2) this.supplier = null

			this.searchTableNested();
		},	
		async getBastNo(text){
			let payload = {}

			if(this.userLevel === 2){
				payload = {
					db_instance: this.company.db_instance,
					org_id: this.company.value,
					vendor_id: sessionStorage.vendorId
				}
				const result = await BASTService.getListBastNo(payload)
				this.listNo = result.data.data;
			}else{
				payload = {
					db_instance: this.company.db_instance,
					org_id: this.company.value,
					vendor_id: this.supplier ? this.supplier.vendor_id : null
				}
				const result = await BASTService.getListBastNo(payload)
				this.listNo = result.data.data;
			}
		
		},
		getSuppName(text) {
			
			console.log(text, "KESINI COY")
			let payload = {
				db_instance: text.db_instance,
				org_id: text.value,
			};
			if(!this.supplierList){
				LovService.getSupplierName(payload).then((orgall) => {
					this.supplierList = orgall.data.data;
				});
			}
		},
		searchSupplier(event) {
			if (!event.query.trim().length) {
				this.filteredSupplier = [...this.supplierList];
				
			} else {
				this.filteredSupplier = this.supplierList.filter((item) => {
					return item.vendor_name
						.toLowerCase()
						.match(event.query.toLowerCase());
				});
			}
		},
		searchLov(event) {
			if (!event.query.trim().length) {
				this.filteredLov = [...this.isilov];
				console.log(this.filteredLov, "LOV")
			} else {
				this.filteredLov = this.isilov.filter((item) => {
					return item.name
						.toLowerCase()
						.match(event.query.toLowerCase());
				});
			}
		},
		searchBAST(event) {
			if (!event.query.trim().length) {
				this.filteredListNo = [...this.listNo];
			} else {
				this.filteredListNo = this.listNo.filter((item) => {
					return item.BAST_NUMBER
						.toLowerCase()
						.match(event.query.toLowerCase());
				});
			}
		},
		searchCompany(event) {
			if (!event.query.trim().length) {
				this.filteredCompany = [...this.companysupplier];
			} else {
				this.filteredCompany = this.companysupplier.filter((item) => {
					return item.name
						.toLowerCase()
						.match(event.query.toLowerCase());
				});
			}
		},
		GetLOV() {
			PoFulfillment.GetBASTStatus().then((response) => {
				this.isilov = response.data.data;
			});
		},
		onPage(event) {
			switch (true) {
				//nggak cari apa-apa
				case this.supplier === null &&
					this.planningOrder === null &&
					this.lov === null &&
					this.preparer === null &&
					this.button === 0:
					this.lazyParams = event;
					this.searchTableNested();
					break;
				//semuanya
				case this.company !== null ||
					this.supplier !== null ||
					this.planningOrder !== null ||
					this.lov !== null ||
					(this.preparer !== null && this.button === 1):
					this.selectLazyParams = event;
					this.searchTableNested();
					break;
			}
		},
		onSort(event) {
			console.log("EVENT", event)
			switch (true) {
				case this.supplier === null &&
					this.planningOrder === null &&
					this.lov === null &&
					this.preparer === null:
					this.lazyParams = event;
					// this.GetTableNested();
					break;
				case this.company !== null ||
					this.supplier !== null ||
					this.planningOrder !== null ||
					this.lov !== null ||
					(this.preparer !== null && this.button === 1):
					this.selectLazyParams = event;
					
					break;
			}
		},
		formatCurrency(value) {
			if (value) return value.toLocaleString('id-ID');
			return;
		},
		editChildren(rowIndex) {
			this.$router.push({
				name: 'EditBAST',
				params: { id: rowIndex.BAST_HEADER_ID },
			});
		},
		GetTableNested() {
			console.log("GET TABLE NESTED")
			this.rows = 10;
			this.loading = true;
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					setTimeout(() => {
						PoFulfillment.GetTableNested({
							lazyEvent: JSON.stringify(this.lazyParams),
						}).then((response) => {
							this.nested = response.data.dataQuery;
							this.totalRecords = response.data.totalRecords[0][0].TOTAL_RECORD;
							
					

							//
						});
					}, Math.random() * 1000 + 250);
				}
			});
			this.loading = false;
		},

		async searchTableNested() {
			this.expandedRows = null;
			this.loading = true;
			try {
			
				const resp = await BASTService.getListBAST({
					...this.lazyParams,
					...this.selectLazyParams,
					leveltenant: this.userLevel,
					STATUS: this.lov ? this.lov.value : '',
					dbInstance: this.company.db_instance,
					bast_number: this.noBAST ? this.noBAST.BAST_NUMBER : null,
					orgId: this.company.value,
					vendor_id: this.supplier ? this.supplier.vendor_id : null
				});
			
				this.nested = resp.data.data.dataQuery
				this.totalRecords = resp.data.data.totalRecords[0][0].TOTAL_RECORD
				this.loading = false;
			} catch (error) {
				console.log(error)
				this.loading = false;
			}
		},
		getCompany() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					if (value.data.data.leveltenant == 1) {
						if (value.data.data.userid == 'dexaadmin') {
							LovService.getValueType('COMPANY').then(
								(response) => {
									this.isiCompany = response.data.data;
									//
								}
							);
						} else {
							console.log("KESINI KAH?")
							let payload = value.data.data.bioaddress;
							const secretKey = 'some-unique-key';
							const simpleCrypto = new SimpleCrypto(
								secretKey
							);
							const decipherText =
								simpleCrypto.decrypt(
									sessionStorage.getItem(
										'dropdownCompany'
									)
								);
							BASTService.getDetailCompany({company_name: decipherText})
							.then(
							(response) => {
								console.log("TEST", response.data)
								this.company = response.data.data;
								//
							}
						);
						}
					} else {
						console.log("KESANA KAH?")
						let payload = value.data.data.id;
						const secretKey = 'some-unique-key';
						const simpleCrypto = new SimpleCrypto(
							secretKey
						);
						const decipherText =
							simpleCrypto.decrypt(
								sessionStorage.getItem(
									'dropdownCompany'
								)
							);
						BASTService.getDetailCompany({company_name: decipherText})
							.then(
							(response) => {
								this.company = response.data.data;
								LovService.getcompanydb(this.company.db_instance).then(
									(responses) => {
										this.companysupplier = responses.data.data;
									}
								);
							}
						);
					}
				}
			});
		},
		getValueCompany() {
			let payload = {
				db_instance: this.company ? this.company.db_instance : null,
				org_id: this.company ?  this.company.value : null,
			};
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					LovService.getSupplierName(payload).then( async (response) => {
						this.isisupplier = response.data.data;

						//
						if (sessionStorage.getItem('leveltenant') == 2) {
							console.log("DISINI YA?")
							try {
								this.isisupplier.forEach((element) => {
									if (
										element.vendor_name ===
											this.suppBindToComp[0].toString() &&
										element.vendor_name !== null
									) {
										this.supplier = element;
										this.buttonDisable = false;
									}
								});
							} catch (e) {
								if (e !== 'Break') throw e;
							}
							//
						}
					});
				}
			});
		},
	},
};
</script>
<style scoped lang="scss">
// ::selection{
//     background-color: rgba(0,0,0,.15) !important;
// }

p-selectable-row p-highlight {
	background-color: rgba(0, 0, 0, 0.15) !important;
}

// input{
//     text-align: right;
// }
</style>
