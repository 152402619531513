<template>
  <Landing v-if="$route.path === '/landing'" @change-theme="changeTheme" />
  <Login v-else-if="$route.path === '/login'" />
  <ForgotPassword v-else-if="$route.path === '/forgotpass'" />
  <ResetPassword v-else-if="$route.path === '/resetpassword'" />
  <PrintPOSupp v-else-if="$route.path === '/printposupp'" />
  <PrintPOPur v-else-if="$route.path === '/printpopur'" />
  <PrintROO v-else-if="$route.path === '/printroo'" />
  <PrintBatch v-else-if="$route.path === '/printbatch'" />
  <PrintBatchBarcode v-else-if="$route.path === '/print'" />
  <PrintTrackingPo v-else-if="$route.path === '/printtrackingpo'" />
  <PrintInvoiceList v-else-if="$route.path === '/printinvoicelist'" />
  <PrintCreateInvoice v-else-if="$route.path === '/printcreateinvoice'" />
  <PrintCreateInvoiceProc v-else-if="$route.path === '/printcreateinvoiceproc'" />
  <PrintCapa v-else-if="$route.path === '/printcapa'" />
  <Error v-else-if="$route.path === '/error'" />
  <NotFound v-else-if="$route.path === '/notfound'" />
  <Access v-else-if="$route.path === '/access'" />
  <App v-else @change-theme="changeTheme" />
</template>

<script>
import EventBus from "./AppEventBus";
import App from "./App";
import Landing from "./pages/LandingDemo";
import PrintTrackingPo from "./pages/PrintTrackingPo.vue";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword.vue";
import ResetPassword from "./pages/ResetPassword.vue";
import PrintPOSupp from "./pages/PrintPlanningOrderSupp.vue";
import PrintPOPur from "./pages/PrintPlanningOrderPur.vue";
import PrintROO from "./pages/PrintROO.vue";
import Error from "./pages/Error";
import NotFound from "./pages/NotFound";
import Access from "./pages/Access";
import PrintBatch from "./pages/PrintBatch.vue";
import PrintBatchBarcode from "./pages/PrintBatchBarcode.vue";
import PrintInvoiceList from "./pages/PrintInvoiceList.vue";
import PrintCreateInvoice from "./pages/PrintCreateInvoice.vue";
import PrintCreateInvoiceProc from "./pages/PrintCreateInvoiceProc.vue";
import PrintCapa from "./pages/PrintCAPA.vue";

export default {
  methods: {
    changeTheme(event) {
      let themeElement = document.getElementById("theme-link");
      themeElement.setAttribute(
        "href",
        themeElement
          .getAttribute("href")
          .replace(this.$appState.theme, event.theme)
      );
      this.$appState.theme = event.theme;
      this.$appState.darkTheme = event.dark;
      EventBus.emit("change-theme", event);

      if (event.theme.startsWith("md")) {
        this.$primevue.config.ripple = true;
      }
    },
  },
  components: {
    App,
    Landing,
    Login,
    ForgotPassword,
    ResetPassword,
    PrintPOSupp,
    PrintPOPur,
    PrintROO,
    PrintBatch,
    PrintBatchBarcode,
    PrintInvoiceList,
    PrintCreateInvoice,
    PrintCreateInvoiceProc,
    Error,
    NotFound,
    Access,
    PrintTrackingPo,
    PrintCapa,
  },
};
</script>
