<template>
	<!-- eslint-disable -->
	<div class="grid">
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<div class="col-12">
			<div class="content-wrapper mb-4">
				<div class="content-header">DETAIL NOTA DEBIT</div>
				<div class="content-body">
					<div class="grid">
						<div class="col-12 md:col-12 lg:col-6">
							<div class="grid">
								<div class="col-6">
									<p id="label-key">Supplier</p>
								</div>
								<div class="col-6 col-auto ml-auto">
									<p id="label-value">{{ supplierHeader }}</p>
								</div>
							</div>
							<div class="grid">
								<div class="col-6">
									<p id="label-key">Debit Note To</p>
								</div>
								<div class="col-6 col-auto ml-auto">
									<p id="label-value">
										{{ debitNoteHeader }}
									</p>
								</div>
							</div>
							<div class="grid">
								<div class="col-6">
									<p id="label-key">Debit Note Date</p>
								</div>
								<div class="col-6 col-auto ml-auto">
									<p id="label-value">{{ debitNoteDate }}</p>
								</div>
							</div>
							<div class="grid">
								<div class="col-6">
									<p id="label-key">Ex Tax No</p>
								</div>
								<div class="col-6 col-auto ml-auto">
									<p id="label-value">{{ exTaxNoHeader }}</p>
								</div>
							</div>
							<div class="grid">
								<div class="col-6">
									<p id="label-key">Ex Tax Date</p>
								</div>
								<div class="col-6 col-auto ml-auto">
									<p id="label-value">
										{{ exTaxDateHeader }}
									</p>
								</div>
							</div>
						</div>
						<div class="col-12 md:col-12 lg:col-6">
							<div class="grid form-card mb-5">
								<div class="col-12">
									<div class="grid">
										<div class="col-6">
											<p id="label-key-right">
												Total DPP
											</p>
										</div>
										<div class="col-6 col-auto ml-auto">
											<p id="label-value-right">
												{{
													new Intl.NumberFormat(
														'en-US',
														{
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														}
													).format(totalDpp)
												}}
											</p>
										</div>
									</div>
									<div class="grid">
										<div class="col-6">
											<p id="label-key-right">PPn</p>
										</div>
										<div class="col-6 col-auto ml-auto">
											<p id="label-value-right">
												{{
													new Intl.NumberFormat(
														'en-US',
														{
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														}
													).format(ppnHeader)
												}}
											</p>
										</div>
									</div>
									<div class="grid">
										<div class="col-6">
											<p id="label-key-right">
												Total Amount
											</p>
										</div>
										<div class="col-6 col-auto ml-auto">
											<p id="label-value-right">
												{{
													new Intl.NumberFormat(
														'en-US',
														{
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														}
													).format(totalAmount)
												}}
											</p>
										</div>
									</div>
								</div>
							</div>
							<div v-if="saved" class="grid formgrid mb-4">
								<div class="col-10 lg:col-10">
									<p><b> Nota Debit </b></p>
									<!-- <a class="button-download p-button" :href="saved.url">
                                        <span style="margin: auto" class="font-bold">
                                            <i class="pi pi-download mr-2" />
                                            {{ saved.FILENAME }}
                                        </span>
                                    </a> -->
									<Button
										v-if="!disabledDownloadDebit"
										class="button-download font-normal"
										:label="saved.FILENAME"
										icon="pi pi-download"
										@click="onDownloadDebit()"
									/>
									<Button
										v-if="disabledDownloadDebit"
										class="button-download font-normal"
										:label="`${saved.FILENAME} is downloading`"
										icon="pi pi-download"
										:disabled="true"
									/>
								</div>
							</div>
							<div v-if="savedRetur" class="grid formgrid">
								<div class="col-10 lg:col-10">
									<p><b> Nota Retur </b></p>
									<!-- <a class="button-download p-button" :href="savedRetur.url">
                                        <span style="margin: auto" class="font-bold">
                                            <i class="pi pi-download mr-2" />
                                            {{ savedRetur.FILENAME }}
                                        </span>
                                    </a> -->
									<Button
										v-if="!disabledDownloadReturn"
										class="button-download font-normal"
										:label="savedRetur.FILENAME"
										icon="pi pi-download"
										@click="onDownloadReturn()"
									/>
									<Button
										v-if="disabledDownloadReturn"
										class="button-download font-normal"
										:label="`${savedRetur.FILENAME} is downloading`"
										icon="pi pi-download"
										:disabled="true"
									/>
								</div>
							</div>
						</div>
						<div class="col-12 mt-5">
							<DataTable
								:value="detailList"
								responsiveLayout="scroll"
								:scrollable="true"
								scrollHeight="600px"
							>
								<template #empty>No data found</template>
								<template #loading>Loading data</template>
								<Column>
									<template #body="slotProps">
										{{ slotProps.index + 1 }}
									</template>
								</Column>
								<Column
									header="PO Number"
									field="PO_NUMBER"
								></Column>
								<Column
									header="Description"
									field="ITEM_DESCRIPTION"
								></Column>
								<Column header="QTY" field="QUANTITY"></Column>
								<Column
									header="UOM"
									field="UNIT_MEAS_LOOKUP_CODE"
								></Column>
								<Column
									header="Unit Price"
									field="UNIT_PRICE"
								></Column>
								<Column header="Amount" field="AMOUNT"></Column>
							</DataTable>
						</div>
						<div class="col-12 md:col-3 mt-4">
							<span class="p-float-label">
								<Button
									label="back"
									class="uppercase p-button-secondary"
									@click="back()"
								/>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AuthService from '../../../service/AuthService';
import ListNotaDebit from '../../../service/ListNotaDebit';

const toast = useToast();
const supplierHeader = ref();
const route = useRoute();
const router = useRouter();
const debitNoteHeader = ref();
const debitNoteDate = ref();
const exTaxNoHeader = ref();
const exTaxDateHeader = ref();
const detailList = ref([]);
const totalDpp = ref();
const ppnHeader = ref();
const totalAmount = ref();
const id = ref(route.params.id);
const invoiceId = ref();
const saved = ref();
const savedRetur = ref();
const debitFileBuffer = ref();
const returnFileBuffer = ref();
const disabledDownloadDebit = ref();
const disabledDownloadReturn = ref();

const breadcrumbHome = { icon: 'pi pi-home', to: '/dashboard' };
const breadcrumbItems = [
	{
		label: 'Detail Nota Debit',
		to: '/mgm/monitor/eventloglist/detail/' + id.value,
	},
];

onMounted(() => {
	AuthService.whoAmi().then(async (response) => {
		if (response.status === 200) {
			await getData();
			await getFileAttachment();
			// await getFileBuffer();
		}
	});
});

const getData = async () => {
	const resultData = await ListNotaDebit.getDetail(id.value);

	const result = resultData.data;

	const type = typeof result;
	if (type === 'object') {
		debitNoteHeader.value = result.dataHeader[0].DN_NO;
		debitNoteDate.value = dateFormat2(result.dataHeader[0].DN_DATE);
		exTaxNoHeader.value = result.dataHeader[0].EXTAX_NO;
		exTaxDateHeader.value = dateFormat2(result.dataHeader[0].EXTAX_DATE);
		supplierHeader.value = result.dataHeader[0].VENDOR_NAME;
		totalDpp.value = result.dataHeader[0].INVOICE_AMOUNT;
		ppnHeader.value = result.dataHeader[0].TAX_AMOUNT;
		totalAmount.value = result.dataHeader[0].TOTAL_AMOUNT;
		invoiceId.value = result.dataHeader[0].INVOICE_ID;

		detailList.value = result.dataDetail;
	}
};

const getFileAttachment = async () => {
	try {
		const res = await ListNotaDebit.getAttachmentFilePIC(invoiceId.value);
		if (res.status === 200) {
			saved.value = res.data.data.data;
			savedRetur.value = res.data.data.dataRetur;
		}
	} catch (error) {
		throw new Error(error);
	}
};

// const getFileBuffer = async () => {
//     disabledDownloadDebit.value = true;
//     disabledDownloadReturn.value = true;
//     const leveltenant = sessionStorage.getItem('leveltenant')
//     const resDebitFile = await ListNotaDebit.getDebitFile(invoiceId.value, leveltenant);
//     const resReturnFile = await ListNotaDebit.getReturnFile(invoiceId.value, leveltenant);

//     debitFileBuffer.value = resDebitFile.data.data.data;
//     returnFileBuffer.value = resReturnFile.data.data.data;

//     disabledDownloadDebit.value = false;
//     disabledDownloadReturn.value = false;
// };

const onDownloadDebit = async () => {
	try {
		disabledDownloadDebit.value = true;
		const leveltenant = sessionStorage.getItem('leveltenant');
		const resDebitFile = await ListNotaDebit.getDebitFile(
			invoiceId.value,
			leveltenant
		);

		debitFileBuffer.value = resDebitFile.data.data.data;
	} catch (error) {
		disabledDownloadDebit.value = false;
		toast.add({
			severity: 'error',
			summary: 'Connection Unstable !',
			life: 3000,
		});
		throw Error('Error downloading file');
	}

	const linkSource = `data:application/pdf;base64,${debitFileBuffer.value}`;
	const downloadLink = document.createElement('a');
	const fileName = saved.value.FILENAME;
	downloadLink.href = linkSource;
	downloadLink.download = fileName;
	downloadLink.click();
	disabledDownloadDebit.value = false;
};

const onDownloadReturn = async () => {
	try {
		disabledDownloadReturn.value = true;
		const leveltenant = sessionStorage.getItem('leveltenant');
		const resReturnFile = await ListNotaDebit.getReturnFile(
			invoiceId.value,
			leveltenant
		);

		returnFileBuffer.value = resReturnFile.data.data.data;
	} catch (error) {
		disabledDownloadReturn.value = false;
		toast.add({
			severity: 'error',
			summary: 'Connection Unstable !',
			life: 3000,
		});
		throw Error('Error downloading file');
	}

	const linkSource = `data:application/pdf;base64,${returnFileBuffer.value}`;
	const downloadLink = document.createElement('a');
	const fileName = savedRetur.value.FILENAME;
	downloadLink.href = linkSource;
	downloadLink.download = fileName;
	downloadLink.click();
	disabledDownloadReturn.value = false;
};

function dateFormat2(d) {
	var monthShortNames = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];
	var t = new Date(d);
	return (
		t.getDate() +
		'-' +
		monthShortNames[t.getMonth()] +
		'-' +
		t.getFullYear() +
		''
	);
}

const back = () => {
	router.back();
};
</script>

<style scoped>
#label-key {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 36px;
	/* identical to box height, or 257% */

	color: #000000;
}

#label-value {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 36px;
	/* identical to box height, or 257% */
	align-items: center;

	/* Gray 2 */

	color: #4f4f4f;
}

#label-key-right {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 36px;
	/* identical to box height, or 257% */

	color: #000000;
}

#label-value-right {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 36px;
	/* identical to box height, or 257% */
	align-items: center;
	text-align: right;

	/* Gray 2 */

	color: #4f4f4f;
}

#line-value {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: bold;
	color: #4f4f4f;
	font-size: 14px;
}

.form-card {
	padding: 10px;
	border: 1px solid #e0e0e0;
	border-radius: 16px;
}
</style>
