<template>
	<div class="grid">
		<ProgressModal :progress="progress">
			<template #message>
				Save data in progress.<br />Please wait...
			</template>
		</ProgressModal>
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<!-- Shipment Info -->
		<div class="col-12 p-fluid mb-4">
			<div class="content-wrapper">
				<div class="content-header">E-Invoice Summary</div>
				<div class="content-body">
					<div class="grid">
						<div class="col-12 md:col-6 p-fluid">
							<div class="grid formgrid">
								<div class="col-12 mb-0 lg:col-12 lg:mb-0">
									<div class="form-card">
										<div
											class="flex align-items-center justify-content-between mb-4"
										>
											<span
												style="color: #000"
												class="font-semibold"
												>COMPANY</span
											>
											<span class="font-bold">
												{{ company_name }}
											</span>
										</div>
										<div
											class="flex align-items-center justify-content-between mb-5"
										>
											<span
												style="color: #000"
												class="font-semibold"
												>SUPPLIER</span
											>
											<span class="font-bold">
												{{ supplier }}
											</span>
										</div>
										<div class="mb-4">
											<!-- <span class="p-float-label">
                                        <AutoComplete
                                        v-model="supplierSites"
                                        :disabled="!supplierSiteList"
                                        :suggestions="filteredSuppSite"
                                        class="w-full input-disabled"
                                        id="suppliersite"
                                        :dropdown="true"
                                        @complete="searchSuppSite($event)"
                                        field="suppliersite"
                                        :key="supplierSiteReload"
                                        placeholder=" "
                                        forceSelection
                                        >
                                        </AutoComplete>
                                        <label for="inputtext">Supplier Site</label>
                                    </span> -->
											<SupplierSite
												:db_instance="
													db_instance
														? db_instance
														: null
												"
												:org_id="org_id ? org_id : null"
												:vendor_id="
													vendorId ? vendorId : null
												"
												:supplier_Site="
													supplier_site
														? supplier_site
														: null
												"
												@supplierSite="getSupplierSite"
												:key="reload"
												:class="{
													'p-invalid':
														v$.supplierSiteValue
															.$invalid &&
														submitted,
												}"
											/>
											<small
												v-if="
													(v$.supplierSiteValue
														.$invalid &&
														submitted) ||
													v$.supplierSiteValue
														.$pending.$response
												"
												class="p-error"
												>{{
													v$.supplierSiteValue.required.$message.replace(
														'Value',
														'Supplier Site'
													)
												}}</small
											>
										</div>
									</div>
								</div>
							</div>
							<form
								@submit.prevent="handleSubmit(!v$.$invalid)"
								class="p-fluid"
							>
								<div
									class="grid formgrid"
									style="margin-top: 30px"
								>
									<div class="col-12 mb-5 lg:col-6 lg:mb-5">
										<span class="p-float-label">
											<InputText
												id="username"
												type="text"
												v-model="
													v$.invoiceNumber.$model
												"
												:class="{
													'p-invalid':
														v$.invoiceNumber
															.$invalid &&
														submitted,
												}"
												:maxlength="50"
											/>
											<label for="username"
												>Invoice Number</label
											>
										</span>
										<small
											v-if="
												(v$.invoiceNumber.$invalid &&
													submitted) ||
												v$.invoiceNumber.$pending
													.$response
											"
											class="p-error"
											>{{
												v$.invoiceNumber.required.$message.replace(
													'Value',
													'Invoice Number'
												)
											}}</small
										>
									</div>
									<div class="col-12 mb-5 lg:col-6 lg:mb-5">
										<span class="p-float-label">
											<Calendar
												:showIcon="true"
												:showButtonBar="true"
												id="dateformat"
												dateFormat="dd M yy"
												style="text-align: center"
												v-model="v$.invoiceDate.$model"
												:class="{
													'p-invalid':
														v$.invoiceDate
															.$invalid &&
														submitted,
												}"
											></Calendar>
											<label for="username"
												>Invoice Date</label
											>
										</span>
										<small
											v-if="
												(v$.invoiceDate.$invalid &&
													submitted) ||
												v$.invoiceDate.$pending
													.$response
											"
											class="p-error"
											>{{
												v$.invoiceDate.required.$message.replace(
													'Value',
													'Invoice Date'
												)
											}}</small
										>
									</div>
									<div class="col-12 mb-5 lg:col-6 lg:mb-5">
										<span class="p-float-label">
											<Calendar
												:showIcon="true"
												:showButtonBar="true"
												id="dateformat"
												dateFormat="dd M yy"
												style="text-align: center"
												v-model="v$.glDate.$model"
												:class="{
													'p-invalid':
														v$.glDate.$invalid &&
														submitted,
												}"
											></Calendar>
											<label for="username"
												>GL Date Description</label
											>
										</span>
										<small
											v-if="
												(v$.glDate.$invalid &&
													submitted) ||
												v$.glDate.$pending.$response
											"
											class="p-error"
											>{{
												v$.glDate.required.$message.replace(
													'Value',
													'GL Date'
												)
											}}</small
										>
									</div>
								</div>
							</form>

							<div class="grid formgrid">
								<div class="col-12 mb-5 lg:col-12 lg:mb-5">
									<span class="p-float-label">
										<Textarea
											v-model="description"
											rows="5"
											cols="30"
										/>
										<label for="username"
											>Description</label
										>
									</span>
								</div>
							</div>

							<hr />
							<div class="grid formgrid" style="margin-top: 30px">
								<div class="col-12 mb-5 lg:col-6 lg:mb-5">
									<span class="p-float-label">
										<InputText
											id="inputmask"
											v-model="v$.taxInvoice.$model"
											:class="{
												'p-invalid':
													v$.taxInvoice.$invalid &&
													submitted,
											}"
											@change="handleChangeTax($event)"
											:disabled="!invoiceDate"
										/>
										<label for="username"
											>Tax Invoice No.</label
										>
									</span>
									<small
										v-if="
											(v$.taxInvoice.$invalid &&
												submitted) ||
											v$.taxInvoice.$pending.$response
										"
										class="p-error"
										>{{
											v$.taxInvoice.required.$message.replace(
												'Value',
												'Tax Invoice'
											)
										}}</small
									>
								</div>
								<div class="col-12 mb-5 lg:col-6 lg:mb-5">
									<span class="p-float-label">
										<Calendar
											:showIcon="true"
											:showButtonBar="true"
											id="dateformat"
											dateFormat="dd M yy"
											style="text-align: center"
											v-model="v$.taxInvoiceDate.$model"
											:class="{
												'p-invalid':
													v$.taxInvoiceDate
														.$invalid && submitted,
											}"
										></Calendar>
										<label for="username"
											>Tax Invoice Date</label
										>
									</span>
									<small
										v-if="
											(v$.taxInvoiceDate.$invalid &&
												submitted) ||
											v$.taxInvoiceDate.$pending.$response
										"
										class="p-error"
										>{{
											v$.taxInvoiceDate.required.$message.replace(
												'Value',
												'Tax Invoice Date'
											)
										}}</small
									>
								</div>
								<div
									class="col-2 mb-1 lg:col-1 lg:mb-1"
									style="margin-right=10px"
								>
									<span
										style="color: #000; font-size: 20px"
										class="font-semibold"
										>File :</span
									>
								</div>
								<div class="grid formgrid">
									<div
										v-if="savedFile"
										class="col-10 mb-5 lg:col-10 lg:mb-5"
									>
										<!-- <a
											class="button-download p-button"
											:href="attachmentUrl"
										>
											<span
												style="margin: auto"
												class="font-bold"
											>
												<i
													class="pi pi-download mr-2"
												/>
												{{ attachmentName }}
											</span>
										</a> -->
										<Button
											v-if="!loadingDownloadFile"
											class="button-download font-normal"
											:label="attachmentName"
											icon="pi pi-download"
											@click="onDownload()"
										/>
										<Button
											v-if="loadingDownloadFile"
											class="button-download font-normal"
											:label="`${attachmentName} is downloading`"
											icon="pi pi-download"
											:disabled="true"
										/>
									</div>
									<div
										v-if="savedFile && !disabledDiv"
										class="col-1 mb-1 lg:col-1 lg:mb-1"
									>
										<Button
											type="button"
											class="p-button-rounded p-button-text ml-2"
											icon="pi pi-trash"
											@click="handleDeleteFile"
										/>
									</div>
									<div
										v-if="!savedFile"
										class="col-12 mb-5 lg:col-12 lg:mb-5"
									>
										<FileUpload
											id="uploadFile"
											mode="advanced"
											@select="onSelectFile"
											chooseLabel="Upload File"
											:multiple="false"
											:customUpload="true"
											accept=".pdf"
											:showUploadButton="false"
											:showCancelButton="false"
											:fileLimit="1"
											:maxFileSize="5000000"
											:disabled="displayStatus"
										>
											<template #empty>
												<p>
													No file selected. (Max file
													5MB)
												</p>
											</template>
										</FileUpload>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 md:col-6">
							<div class="grid formgrid">
								<div class="col-12 mb-5 lg:col-12 lg:mb-5">
									<div class="form-card">
										<div
											class="flex align-items-center justify-content-between mb-4"
										>
											<span
												style="color: #000"
												class="font-semibold"
												>STATUS</span
											>
											<span class="font-bold">
												{{ status }}
											</span>
										</div>
										<div
											class="flex align-items-center justify-content-between mb-4"
										>
											<span
												style="color: #000"
												class="font-semibold"
												>INVOICE AMOUNT</span
											>
											<span class="font-bold">
												{{
													Number.parseFloat(totalLine)
														.toFixed(2)
														.replace(
															/\B(?=(\d{3})+(?!\d))/g,
															','
														)
												}}
											</span>
										</div>
										<div
											class="flex align-items-center justify-content-between mb-4"
										>
											<span
												style="color: #000"
												class="font-semibold"
												>TAX AMOUNT</span
											>
											<span class="font-bold">
												{{
													Number.parseFloat(totalPPN)
														.toFixed(2)
														.replace(
															/\B(?=(\d{3})+(?!\d))/g,
															','
														)
												}}
											</span>
										</div>
										<div
											class="flex align-items-center justify-content-between mb-4"
										>
											<span
												style="color: #000"
												class="font-semibold"
												>TOTAL</span
											>
											<span class="font-bold">
												{{
													Number.parseFloat(
														totalInvoice
													)
														.toFixed(2)
														.replace(
															/\B(?=(\d{3})+(?!\d))/g,
															','
														)
												}}
											</span>
										</div>
										<div
											v-if="currencyBoolean"
											class="flex align-items-center justify-content-between mb-4"
										>
											<span
												style="color: #000"
												class="font-semibold"
												>CURRENCY</span
											>
											<span class="font-bold">
												{{ currency }}
											</span>
										</div>
										<div
											v-if="!currencyBoolean"
											class="flex align-items-center justify-content-between mb-4"
										>
											<span
												style="color: #000"
												class="font-semibold"
												>CURRENCY</span
											>
											<div>
												<Currency
													:db_instance="
														db_instance
															? db_instance
															: null
													"
													:currencyValue="
														currency
															? currency
															: null
													"
													:org_id="
														org_id ? org_id : null
													"
													@currency="getCurrency"
													:key="reload"
												/>
											</div>
										</div>
									</div>
								</div>
								<div class="col-12 mb-5 lg:col-6 lg:mb-5">
									<RemitToBankAccount
										:db_instance="
											db_instance ? db_instance : null
										"
										:bankId="
											remitToBankAccount
												? remitToBankAccount
												: null
										"
										:org_id="org_id ? org_id : null"
										:vendor_id="vendorId ? vendorId : null"
										@remit="getRemit"
										:key="reload"
										v-model="v$.remit.$model"
										:class="{
											'p-invalid':
												v$.remit.$invalid && submitted,
										}"
									/>
									<small
										v-if="
											(v$.remit.$invalid && submitted) ||
											v$.remit.$pending.$response
										"
										class="p-error"
										>{{
											v$.remit.required.$message.replace(
												'Value',
												'Remit To Bank'
											)
										}}</small
									>
								</div>
								<div class="col-12 mb-5 lg:col-6 lg:mb-5">
									<PaymentMethod
										:db_instance="
											db_instance ? db_instance : null
										"
										:vendor_id="vendorId ? vendorId : null"
										:payment="payment ? payment : null"
										@payment="getPayment"
										:key="reload"
										v-model="v$.paymentValue.$model"
										:class="{
											'p-invalid':
												v$.paymentValue.$invalid &&
												submitted,
										}"
									/>
									<small
										v-if="
											(v$.paymentValue.$invalid &&
												submitted) ||
											v$.paymentValue.$pending.$response
										"
										class="p-error"
										>{{
											v$.paymentValue.required.$message.replace(
												'Value',
												'Payment Method'
											)
										}}</small
									>
								</div>
								<div class="col-12 mb-5 lg:col-6 lg:mb-5">
									<Terms
										:db_instance="
											db_instance ? db_instance : null
										"
										:vendor_id="vendorId ? vendorId : null"
										:terms_idValue="
											terms_idValue ? terms_idValue : null
										"
										:terms_id="terms ? terms : null"
										@terms="getTerms"
										:key="reload"
										v-model="v$.termsValue.$model"
										:class="{
											'p-invalid':
												v$.termsValue.$invalid &&
												submitted,
										}"
									/>
									<small
										v-if="
											(v$.termsValue.$invalid &&
												submitted) ||
											v$.termsValue.$pending.$response
										"
										class="p-error"
										>{{
											v$.termsValue.required.$message.replace(
												'Value',
												'Terms'
											)
										}}</small
									>
								</div>
								<div
									v-if="status === 'PROCESSED'"
									class="col-12 mb-5 lg:col-6 lg:mb-5"
								>
									<Paygroup
										:db_instance="
											db_instance ? db_instance : null
										"
										:vendor_id="vendorId ? vendorId : null"
										@paygroup="getPayGroup"
										:key="reload"
										v-model="v$.paygroupValue.$model"
										:class="{
											'p-invalid':
												v$.paygroupValue.$invalid &&
												submitted,
										}"
									/>
									<small
										v-if="
											(v$.paygroupValue.$invalid &&
												submitted) ||
											v$.paygroupValue.$pending.$response
										"
										class="p-error"
										>{{
											v$.paygroupValue.required.$message.replace(
												'Value',
												'Paygroup'
											)
										}}</small
									>
								</div>
							</div>

							<hr />
							<div class="grid formgrid">
								<div class="col-8 mb-5 lg:col-8 lg:mb-5">
									<span class="p-float-label">
										<InputText
											id="inputtext"
											type="text"
											v-model="referenceNumber"
										/>
										<label for="inputtext"
											>Reference Number</label
										>
									</span>
								</div>
								<div class="col-4 mb-5 lg:col-4 lg:mb-5">
									<Button
										label="Find SK"
										@click="findSK()"
										class="p-button-secondary w-full mb-3 lg:w-full lg:mr-3 lg:mb-0"
									/>
								</div>
								<div class="col-12 mb-5 lg:col-6 lg:mb-5">
									<span class="p-float-label">
										<AutoComplete
											:suggestions="filteredCostCenter"
											@complete="
												searcSupplierSite($event)
											"
											:dropdown="true"
											field="SEGMENT4"
											v-model="v$.costCenter.$model"
											:class="{
												'p-invalid':
													v$.costCenter.$invalid &&
													submitted,
											}"
											forceSelection
										>
											<template #item="slotProp">
												{{ slotProp.item.SEGMENT4 }}
											</template>
										</AutoComplete>
										<label for="autocomplete"
											>Cost Center</label
										>
									</span>
									<small
										v-if="
											(v$.costCenter.$invalid &&
												submitted) ||
											v$.costCenter.$pending.$response
										"
										class="p-error"
										>{{
											v$.costCenter.required.$message.replace(
												'Value',
												'Cost Center'
											)
										}}</small
									>
								</div>
								<div class="col-12 mb-5 lg:col-6 lg:mb-5">
									<span class="p-float-label">
										<InputText
											id="inputtext"
											type="text"
											v-model="categoryValue"
											disabled
										>
										</InputText>
										<label for="inputtext">Category</label>
									</span>
								</div>
								<div class="col-12 mb-5 lg:col-12 lg:mb-5">
									<span class="p-float-label">
										<Textarea
											v-model="v$.reject.$model"
											rows="5"
											cols="30"
											:class="{
												'p-invalid':
													v$.reject.$invalid &&
													submitted,
											}"
										/>
										<label for="username"
											>Reject Reason</label
										>
									</span>
									<small
										v-if="
											(v$.reject.$invalid && submitted) ||
											v$.reject.$pending.$response
										"
										class="p-error"
										>{{ 'Reject Value is Required' }}</small
									>
								</div>
								<div class="col-12 mb-5 lg:col-12 lg:mb-5">
									<span class="p-float-label">
										<Textarea
											id="inputtext"
											rows="5"
											cols="30"
											type="text"
											v-model="approval"
											disabled
										/>
										<label for="inputtext"
											>Reject/Approval Reason</label
										>
									</span>
								</div>
							</div>
						</div>
						<div class="grid col-12">
							<div class="col-12 md:col-6 p-fluid">
								<div class="col-6 mb-0 lg:col-6 lg:mb-0">
									<Message severity="info">{{
										messagesForwarder
									}}</Message>
								</div>
								<div
									class="col-2 mb-1 lg:col-1 lg:mb-1"
									style="margin-right=10px"
								>
									<span
										style="color: #000; font-size: 20px"
										class="font-semibold"
										>Line</span
									>
								</div>
								<!-- <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid"> -->
								<div
									class="grid formgrid"
									style="margin-top: 20px"
								>
									<div class="col-8 mb-5 lg:col-6 lg:mb-5">
										<DetailCat
											:db_instance="
												db_instance ? db_instance : null
											"
											:categoryId="
												category ? category : null
											"
											:company="company ? company : null"
											:ref_num="
												referenceNumber
													? referenceNumber
													: null
											"
											:data="idValue ? idValue : null"
											:org_id="org_id ? org_id : null"
											:supplier="
												vendorId ? vendorId : null
											"
											@detailCat="getDetailCat"
											:disabled="
												displayStatus
													? displayStatus
													: null
											"
											:key="reload"
											ref="detailCatRef"
											v-model="detailCat"
										/>
										<!-- <small v-if="(v$.detailCat.$invalid && submitted) || v$.detailCat.$pending.$response" class="p-error">{{'Detail Category is required'}}</small> -->
									</div>
									<div class="col-4 mb-5 lg:col-3 lg:mb-5">
										<Button
											label="Add Line"
											@click="addLine"
											class="p-button-success"
										/>
									</div>
								</div>

								<!-- </form> -->
							</div>

							<div class="col-12 md:col-6">
								<div
									class="grid formgrid"
									style="margin-top: 10px"
								></div>
							</div>
							<div class="col-12">
								<DetailTable
									:data="idValue ? idValue : null"
									:company="company ? company : null"
									:db_instance="
										db_instance ? db_instance : null
									"
									:line="line ? line : null"
									:cost_center="
										costCenter ? costCenter : null
									"
									:org_id="org_id ? org_id : null"
									:vendor_id="vendorId ? vendorId : null"
									:vendor_name="supplier ? supplier : null"
									:detailCat="
										detailCatValue ? detailCatValue : null
									"
									:invoice_date="
										invoiceDate ? invoiceDate : null
									"
									:disabled="displayStatus"
									:submit="submitted ? submitted : false"
									:forwarder="
										forwarderDiv ? forwarderDiv : null
									"
									:tableVuelidate="
										tableVuelidate ? tableVuelidate : null
									"
									:key="reloadCatDetail"
									@tableList="getTableList"
									@reloadDetailCat="reloadCat"
									@onDeleteReimburse="handleDeleteReimburse"
								></DetailTable>
							</div>
							<div v-if="!reimburseDiv" class="col-12">
								<div
									class="grid formgrid"
									style="margin-top: 20px"
								>
									<div class="col-8 mb-4 lg:col-10 lg:mb-4">
										<span
											style="
												color: #000;
												font-size: 20px;
												margin-left: 5px;
											"
											class="font-semibold"
											>Reimburse</span
										>
									</div>
									<div class="col-4 mb-4 lg:col-2 lg:mb-4">
										<Button
											label="Add Line"
											@click="addLineReimburse"
											class="p-button-success"
										/>
									</div>
								</div>
								<DetailReimburse
									v-if="!reimburseDiv"
									:company="company ? company : null"
									:data="idValue ? idValue : null"
									:line="lineReimburse ? lineReimburse : null"
									:vendor_id="vendorId ? vendorId : null"
									:vendor_name="
										vendor_name ? vendor_name : null
									"
									:key="reloadTable"
									:disabled="displayStatus"
									:cost_center="
										costCenter ? costCenter : null
									"
									@tableList="getTableListReimburse"
									:submit="submitted ? submitted : false"
									:coa_id="
										reimburseData ? reimburseData : null
									"
									:org_id="org_id ? org_id : null"
									:db_instance="
										db_instance ? db_instance : null
									"
									:tableVuelidate="
										tableReimburseVuelidate
											? tableReimburseVuelidate
											: null
									"
								></DetailReimburse>
							</div>
						</div>
					</div>
					<form
						@submit.prevent="handleSubmit(!v$.$invalid)"
						class="p-fluid"
					>
						<div class="p-fluid grid formgrid">
							<div class="col-6 mb-2 lg:col-1 lg:mb-0">
								<Button
									:class="uppercase"
									class="p-button-secondary"
									label="Back"
									@click="button('B')"
								>
								</Button>
							</div>
							<div class="col-6 mb-2 lg:col-1 lg:mb-0">
								<Button
									:class="uppercase"
									class="p-button-info"
									label="Download"
									@click="button('D')"
								>
								</Button>
							</div>
							<div
								v-if="disabledDiv"
								class="col-6 mb-2 lg:col-1 lg:mb-0"
							>
								<Button
									type="submit"
									label="Save"
									@click="button('S')"
									class="w-full p-button-success"
								/>
							</div>
							<div
								v-if="levelTenant === 1 && disabledDiv"
								class="col-6 mb-2 lg:col-1 lg:mb-0"
							>
								<Button
									type="confirm"
									label="Submit"
									@click="button('C')"
									class="p-button-warning"
								/>
							</div>
							<div
								v-if="levelTenant === 3 && disabledDiv"
								class="col-6 mb-2 lg:col-2 lg:mb-0"
							>
								<Button
									type="confirm"
									label="Submit to Recomendator"
									@click="button('ST')"
									class="p-button-warning"
									:disabled="disabledRec"
								/>
							</div>
							<div
								v-if="disabledDiv"
								class="col-6 mb-2 lg:col-1 lg:mb-0"
							>
								<Button
									class="p-button-error"
									type="reject"
									label="Reject"
									@click="button('R')"
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>

	<Dialog
		header="Ref Number"
		v-model:visible="dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '15vw' }"
		:modal="true"
	>
		<div
			class="col-12 mb-12 md:col-12 lg:col-12 lg:mb-12"
			style="margin-top: 20px"
		>
			<!-- <InputText id="username" type="text" v-model="v$.refNumber.$model" :class="{'p-invalid':v$.refNumber.$invalid && submitted}" :maxlength="50"/> -->
			<RefNumber
				:db_instance="db_instance ? db_instance : null"
				:key="reload"
				:disabled="displayStatus ? displayStatus : null"
				:vendor_id="vendorId ? vendorId : null"
				@refNumber="getRefValue"
				v-model="refNumberPopUp"
			/>
		</div>
		<template #footer>
			<Button
				label="No"
				icon="pi pi-times"
				@click="closeModal"
				class="p-button-text"
			/>
			<Button
				label="Yes"
				icon="pi pi-check"
				@click="yesModal"
				autofocus
			/>
		</template>
	</Dialog>
</template>

<script setup>
// import AuthService from '../../../service/AuthService';
import SupplierSite from '@/components/searchinvoiceproxy/components/SupplierSite';
import RemitToBankAccount from '@/components/searchinvoiceproxy/components/RemitToBankAccount';
import PaymentMethod from '@/components/searchinvoiceproxy/components/PaymentMethod';
import Terms from '@/components/searchinvoiceproxy/components/Terms';
import Paygroup from '@/components/searchinvoiceproxy/components/Paygroup';
// import CostCenter from '@/components/searchinvoiceproxy/components/CostCenter'
import DetailTable from '@/components/searchinvoiceproxy/components/DetailTableListInvoiceProxyNonPO';
import DetailReimburse from '@/components/searchinvoiceproxy/components/DetailTableListInvoiceReimburseProxyNonPO';
import DetailCat from '@/components/searchinvoiceproxy/components/DetailCat';
import RefNumber from '@/components/searchinvoiceproxy/components/RefNumber';
import Currency from '@/components/searchinvoiceproxy/components/Currency';
import AuthService from '../../../service/AuthService';
import SimpleCrypto from 'simple-crypto-js';
import PoFulfillment from '../../../service/PoFulfillment';
import LovService from '../../../service/LovService';
import { useToast } from 'primevue/usetoast';
import InvoiceService from '../../../service/InvoiceSearchWPO';
import ProgressModal from '../../components/ProgressModal.vue';
import { computed, onMounted, ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { useRoute, useRouter } from 'vue-router';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import SearchInvoiceProxyNonPO from '../../../service/InvoiceProxyService';
import XLSX from 'xlsx';

// eslint-disable-next-line no-undef
const router = useRoute();
const routerPage = useRouter();
const idValue = ref(router.params.id);
const company = ref();
const company_name = ref();
const supplier = ref();
const vendorId = ref();
const db_instance = ref();
const invoiceNumber = ref();
const invoiceDate = ref();
const glDate = ref();
const description = ref();
const remitToBankAccount = ref();
const remit = ref();
const payment = ref();
const paymentValue = ref();
const terms = ref();
const termsValue = ref();
const referenceNumber = ref();
const cost_center = ref();
const status = ref();
const currency = ref();
const reason = ref();
const org_id = ref();
const reload = ref(0);
const reloadTable = ref(0);
const reloadCatDetail = ref(0);
const taxInvoice = ref();
const taxInvoiceDate = ref();
const supplier_site = ref();
const supplierSiteValue = ref();
const userInfo = ref();
const category = ref();
const detailCatValue = ref();
const reimburseDiv = ref(true);
const line = ref(0);
const lineReimburse = ref(0);
const toast = useToast();
const buttonValue = ref();
const submitted = ref();
const savedFile = ref();
const attachmentUrl = ref();
const attachmentName = ref();
const fileName = ref();
const costCenter = ref();
const dialog = ref();
const refNumberPopUp = ref();
const backUpDesc = ref();
const table = ref();
const totalPPN = ref();
const reimburseData = ref();
const totalLine = ref();
const totalInvoice = ref();
const tableReimburse = ref();
const messagesForwarder = ref('Not Forwarder');
const progress = ref(false);
// const attachmentUrl = ref();
// const attachmentName = ref();
// const fileName = ref();
const fileData = ref(null);
const filteredCostCenter = ref([]);
const costCenterList = ref([]);
const categoryValue = ref();
const approval = ref();
const tableVuelidate = ref();
const tableReimburseVuelidate = ref();
const currencyBoolean = ref();
const currencyDropdown = ref();
const submitEmail = ref();
const forwarderDiv = ref(true);
const terms_idValue = ref(false);
const reject = ref();
const disabledDiv = ref(true);
const disabledRec = ref(true);
const displayStatus = ref(false);
const levelTenant = ref();
const account = ref();
const mapingId = ref();
const paygroupValue = ref();
const biggerTable = ref();
const detailCatRef = ref(null);
const created_by = ref(null)
const fileBuffer = ref();
const loadingDownloadFile = ref(false);

const breadcrumbHome = ref({
	icon: 'pi pi-home',
	to: '/dashboard',
});
const breadcrumbItems = ref([
	{
		label: 'Search Invoice Proxy Non Po',
		to: '/mgm/monitor/searchinvoicenonproxy',
	},
	{
		label: 'Detail Search Invoice Proxy Non Po',
		to: router.fullPath,
	},
]);

const validationRules = computed(() => {
	const rules = {
		invoiceNumber: {
			required,
		},
		supplierSiteValue: {
			required,
		},
		invoiceDate: {
			required,
		},
		glDate: {
			required,
		},
		taxInvoice: {
			required,
		},
		taxInvoiceDate: {
			required,
		},
		remit: {
			required,
		},
		paymentValue: {
			required,
		},
		termsValue: {
			required,
		},
		costCenter: {
			required,
		},
		reject: {
			required: requiredIf(buttonValue.value === 'R'),
		},
		paygroupValue: {
			required: requiredIf(buttonValue.value === 'C'),
		},
		table: {
			$each: helpers.forEach({
				AMOUNT: {
					required,
				},
				ACCOUNT: {
					required,
				},
			}),
		},
	};
	if (tableReimburse.value) {
		rules.tableReimburse = {
			$each: helpers.forEach({
				TAX_NO: {
					required,
				},
				TAX_DATE: {
					required,
				},
				AMOUNT: {
					required,
				},
				GROUP_NAME: {
					required,
				},
				ACCOUNT: {
					required,
				},
			}),
		};
	}
	return rules;
});

const v$ = useVuelidate(validationRules, {
	invoiceNumber,
	supplierSiteValue,
	invoiceDate,
	glDate,
	remit,
	paymentValue,
	termsValue,
	required,
	taxInvoice,
	taxInvoiceDate,
	reject,
	paygroupValue,
	costCenter,
	table,
	tableReimburse,
});

onMounted(() => {
	populateData();
});

const checkCurrency = () => {
	if (userInfo.value.leveltenant == 1) {
		currencyBoolean.value = false;
	} else {
		currencyBoolean.value = true;
	}
};

function formatDateDexa(datee) {
	var date = new Date(datee);
	var respondDate =
		('00' + (date.getMonth() + 1)).slice(-2) +
		'/' +
		('00' + date.getDate()).slice(-2) +
		'/' +
		date.getFullYear() +
		' ' +
		('00' + date.getHours()).slice(-2) +
		':' +
		('00' + date.getMinutes()).slice(-2) +
		':' +
		('00' + date.getSeconds()).slice(-2);
	return respondDate.replace('-', '');
}

const formattingDate = (date, withTime) => {
	const theDate = new Date(date);
	const strDate =
		theDate.getFullYear() +
		'-' +
		(theDate.getMonth() + 1) +
		'-' +
		theDate.getDate();
	const strTime =
		theDate.getHours() +
		':' +
		theDate.getMinutes() +
		':' +
		theDate.getSeconds();
	if (withTime) {
		return `${strDate} ${strTime}`;
	} else {
		return `${strDate}`;
	}
};

const populateData = () => {
	AuthService.whoAmi().then(async (response) => {
		if (response.status === 200) {
			checkTenant();
			const payload = {
				id: idValue.value,
			};
			//
			const respond = await SearchInvoiceProxyNonPO.getDetailData(
				payload
			);

			console.log(respond.data, "RESPOND DATA")
			//
			company_name.value = respond.data.data.data[0].NAME;
			supplier_site.value = respond.data.data.data[0].VENDOR_SITE_ID;
			db_instance.value = respond.data.data.data[0].DB_INSTANCE;
			categoryValue.value = respond.data.data.data[0].CATEGORY_NAME;
			if (respond.data.data.data[0].CATEGORY_NAME === 'FORWARDER') {
				forwarderDiv.value = false;
				messagesForwarder.value = 'Forwarder';
			}
			org_id.value = respond.data.data.data[0].ORG_ID;
			vendorId.value = respond.data.data.data[0].VENDOR_ID;
			supplier.value = respond.data.data.data[0].vendor_name;
			invoiceNumber.value = respond.data.data.data[0].INVOICE_NO;
			invoiceDate.value = new Date(
				respond.data.data.data[0].INVOICE_DATE
			);
			glDate.value = new Date(respond.data.data.data[0].GL_DATE);
			description.value = respond.data.data.data[0].DESCRIPTION;
			backUpDesc.value = respond.data.data.data[0].DESCRIPTION;
			remitToBankAccount.value =
				respond.data.data.data[0].EXTERNAL_BANK_ACCOUNT_ID;
			payment.value =
				respond.data.data.data[0].PAYMENT_METHOD_LOOKUP_CODE;
			terms.value = respond.data.data.data[0].TERMS_ID;
			if (
				respond.data.data.data[0].TERMS_ID === null ||
				respond.data.data.data[0].TERMS_ID === 0
			) {
				terms_idValue.value = true;
			}
			category.value = respond.data.data.data[0].CATEGORY_ID;
			referenceNumber.value = respond.data.data.data[0].REFERENCE_NUMBER;
			refNumberPopUp.value = respond.data.data.data[0].REFERENCE_NUMBER;
			cost_center.value = respond.data.data.data[0].COST_CENTER;
			status.value = respond.data.data.data[0].STATUS;
			currency.value = respond.data.data.data[0].CURRENCY_CODE;
			reason.value = respond.data.data.data[0].REASON;
			taxInvoice.value = respond.data.data.data[0].TAX_NO;
			submitEmail.value = respond.data.data.data[0].CC_OWNER ? respond.data.data.data[0].CC_OWNER : respond.data.data.data[0].REKOMENDATOR;
			taxInvoiceDate.value = new Date(
				respond.data.data.data[0].INVOICE_DATE
			);
			approval.value = respond.data.data.data[0].RECOMMENDATOR_REASON;
			reject.value = respond.data.data.data[0].REASON;
			account.value = respond.data.data.data[0].ACCTS_CODE_COMBINATION_ID;
			mapingId.value = respond.data.data.data[0].MAPING_ID;
			created_by.value = respond.data.data.data[0].CREATED_BY;
			reload.value++;
			reloadTable.value++;
			reloadCatDetail.value++;
			await getDataCostCenter();
			await getAttachmentFile();
			// await getFileBase64(idValue.value)
			checkCurrency();

			checkStatus(status.value);
		}
	});
};

const checkTenant = async () => {
	AuthService.whoAmi().then(async (response) => {
		const userCompany = () => {
			const secretKey = 'some-unique-key';
			const simpleCrypto = new SimpleCrypto(secretKey);
			return simpleCrypto.decrypt(
				sessionStorage.getItem('dropdownCompany')
			);
		};
		if (response.status === 200) {
			userInfo.value = response.data.data;
			levelTenant.value = response.data.data.leveltenant;
			if (userInfo.value.leveltenant === 2) {
				//get company
				const respCbN = await LovService.getCompanyByName(
					userCompany()
				);
				company.value = respCbN.data.data[0];
				//get supplier
				//
				const param = {
					db_instance: company.value.db_instance,
					org_id: company.value.value,
				};

				const respVendor = await PoFulfillment.getSuppBindComp(
					response.data.data.id
				);
				//
				const vendorName = respVendor.data.data[0][0].vendorname;
				const respSuppName = await LovService.getSupplierName(param);
				if (respSuppName.status === 200) {
					//
					const suppList = respSuppName.data.data;
					for (const s of suppList) {
						if (s.vendor_name === vendorName) {
							supplier.value = s;
							break;
						}
					}
				}
				//
			} else {
				const respCbN = await LovService.getCompanyByName(
					userCompany()
				);
				company.value = respCbN.data.data[0];
				//
			}
		}
	});
};

const reloadCat = async (res) => {
	//
	if (res.value > 0) {
		reloadCatDetail.value++;
	}
};

const checkStatus = (status) => {
	if (status === 'OPEN') {
		if (userInfo.value.leveltenant === 1) {
			disabledDiv.value = false;
			disabledRec.value = true;
			displayStatus.value = false;
		} else if (userInfo.value.leveltenant === 3) {
			disabledRec.value = false;
			disabledDiv.value = true;
			displayStatus.value = true;
		}
	} else if (status === 'PROCESSED') {
		if (userInfo.value.leveltenant === 1) {
			disabledRec.value = true;
			disabledDiv.value = true;
			displayStatus.value = true;
		} else if (userInfo.value.leveltenant === 3) {
			disabledRec.value = true;
			disabledDiv.value = false;
			displayStatus.value = false;
		}
	} else {
		if (userInfo.value.leveltenant === 1) {
			disabledDiv.value = false;
			disabledRec.value = true;
			displayStatus.value = false;
		} else if (userInfo.value.leveltenant === 3) {
			disabledRec.value = true;
			disabledDiv.value = false;
			displayStatus.value = false;
		}
	}
};

const getDetailCat = async (res) => {
	detailCatValue.value = res.value;
};

const getSupplierSite = (res) => {
	if (typeof res.value === 'object') {
		supplierSiteValue.value = res.value;
	}
};

// const getCostCenter = (res) => {
//     if (typeof res.value === 'object'){
//         costCenter.value = res.value
//
//     }
// }

const getDataCostCenter = async () => {
	if (org_id.value !== null) {
		const payload = {
			org_id: org_id.value,
			db_instance: db_instance.value,
		};
		const data = await SearchInvoiceProxyNonPO.getListCostCenter(payload);
		//
		if (data.status === 200) {
			costCenterList.value = data.data.data.data;
			if (costCenterList.value) {
				for (const costCenterData of costCenterList.value) {
					if (costCenterData.SEGMENT4 === cost_center.value) {
						costCenter.value = costCenterData;
						//
					}
				}
			}
		} else {
			costCenterList.value = { SEGMENT4: 'Not Found' };
		}
	}
};

const searcSupplierSite = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredCostCenter.value = [...costCenterList.value];
		} else {
			filteredCostCenter.value = costCenterList.value.filter(
				(costCenter) => {
					return costCenter.SEGMENT4.toLowerCase().match(
						event.query.toLowerCase()
					);
				}
			);
		}
	}, 250);
};

const getTableList = async (res) => {
	table.value = res.value;
	let dataplus = 0;
	let ppn = 0;
	totalPPN.value = 0;
	table.value.forEach((element) => {
		dataplus = dataplus + element.AMOUNT;
		//
		if (element.PERCENTAGE_RATE !== null) {
			ppn = (element.AMOUNT * element.PERCENTAGE_RATE) / 100;
			//
		} else if (element.PERCENTAGE_RATE === null) {
			ppn = 0;
			//
		}
		if (
			element.ITEM_DESCRIPTION.includes('REIMBURSTMENT') ||
			element.ITEM_DESCRIPTION.includes('Reimburstment') ||
			element.ITEM_DESCRIPTION.includes('reimburstment')
		) {
			reimburseDiv.value = false;
			reimburseData.value = element;
			//
		}

		totalLine.value = dataplus;
		totalPPN.value = totalPPN.value + ppn;
		//
		totalInvoice.value = totalLine.value + totalPPN.value;
	});

	// Populate data from DetailCat component
	detailCatRef.value.populateData();
};

const addLine = () => {
	line.value++;
	if (detailCatValue.value === undefined || detailCatValue.value === null) {
		toast.add({
			severity: 'error',
			summary: 'Category Empty',
			life: 3000,
		});
	} else if (detailCatValue.value.category_detail.includes('REIMBURSTMENT')) {
		reimburseDiv.value = false;
	}
};

const addLineReimburse = () => {
	lineReimburse.value++;
};

const handleDeleteReimburse = async (value) => {
	// Check if reimburse category is not exist
	if (
		!table.value.some(
			(item) => item.ITEM_DESCRIPTION.toLowerCase() === 'reimburstment'
		)
	) {
		// Delete ttable reimbursement
		if (value) {
			await Promise.all(
				tableReimburse.value.map(async (item) => {
					const payload = {
						idheader: parseInt(idValue.value),
						iddetail: item.EINVOICE_REIMBURSMENT_ID,
						value: false,
					};
					const res = await SearchInvoiceProxyNonPO.deleteReimburse(
						payload
					);

					if (res.data.data.data.length !== 0) {
						payload.value = true;
						await SearchInvoiceProxyNonPO.deleteReimburse(payload);
					}
				})
			);
		} else {
			tableReimburse.value = null;
		}

		reimburseDiv.value = true;
	}
};

const findSK = () => {
	dialog.value = true;
};

const closeModal = () => {
	dialog.value = false;
};

const getRemit = (res) => {
	remit.value = res.value;
};

const getCurrency = (res) => {
	currencyDropdown.value = res.value;
};

const getPayment = (res) => {
	paymentValue.value = res.value;
};

const getTerms = (res) => {
	termsValue.value = res.value;
};

const getPayGroup = (res) => {
	paygroupValue.value = res.value;
};

const onSelectFile = (res) => {
	fileData.value = res.files[0];
};

const getTableListReimburse = (res) => {
	tableReimburse.value = res.value;
};

const handleDeleteFile = () => {
	savedFile.value = null;
	attachmentUrl.value = null;
	attachmentName.value = null;
	//
};

const yesModal = () => {
	dialog.value = false;
	if (refNumberPopUp.value !== null) {
		if (description.value === null) {
			description.value = '';
			description.value = refNumberPopUp.value.po_number;
			referenceNumber.value = refNumberPopUp.value.sk_number;
		} else {
			//
			description.value = backUpDesc.value;
			//
			description.value = description.value.concat(
				', ',
				refNumberPopUp.value.po_number
			);
			referenceNumber.value = refNumberPopUp.value.sk_number;
			//
		}
	} else {
		if (description.value === null) {
			description.value = '';
			description.value = refNumberPopUp.value.po_number;
			referenceNumber.value = refNumberPopUp.value.sk_number;
		} else {
			description.value = backUpDesc.value;
			description.value = description.value.concat(
				', ',
				refNumberPopUp.value.po_number
			);
			referenceNumber.value = refNumberPopUp.value.sk_number;
		}
	}
};

const getAttachmentFile = async () => {
	const res = await InvoiceService.getAttachmentFile(idValue.value);
	//
	if (res.status === 200) {
		if (res.data.data === null) {
			savedFile.value = null;
			attachmentUrl.value = null;
			attachmentName.value = null;
			fileName.value = null;
		} else {
			savedFile.value = res.data.data;
			attachmentUrl.value = res.data.data.url;
			attachmentName.value = res.data.data.filename;
			fileName.value = res.data.data.einvoice_file;
			//
		}
	} else {
		toast.add({
			severity: 'Error File',
			summary: 'Unable to Download File',
			life: 3000,
		});
	}
};

const button = async (status) => {
	if (status === 'B') {
		routerPage.back();
	} else if (status === 'D') {
		let dataReimburse = tableReimburse.value
			? tableReimburse.value.length
			: 0;
		let dataDetail = table.value.length;
		//
		//
		if (dataDetail > dataReimburse) {
			biggerTable.value = true;
		} else if (dataDetail < dataReimburse) {
			biggerTable.value = false;
		}
		//
		const payload = {
			id: parseInt(idValue.value),
			table: biggerTable.value,
		};
		//
		const res = await SearchInvoiceProxyNonPO.exportDataDownload(payload);
		//
		if (res.status === 200) {
			const today = new Date();
			const dd = String(today.getDate()).padStart(2, '0');
			const mm = String(today.getMonth() + 1).padStart(2, '0');
			const yyyy = today.getFullYear();
			const combinedDate = dd + mm + yyyy;
			const invoice = res.data.data.data[0].INVOICE_NO;
			const filename =
				'InvoiceProxynonPO _' + invoice + '_' + combinedDate + '.xlsx';
			const data = res.data.data.data;
			const ws = XLSX.utils.json_to_sheet(data);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, 'My Worksheet');
			XLSX.writeFile(wb, filename);
		} else {
			toast.add({
				severity: 'error',
				summary: 'Download Error',
				life: 3000,
			});
		}
	} else {
		buttonValue.value = status;
	}
};

const handleChangeTax = (event) => {
	const {value} = event.target;
	taxInvoice.value = value.replace(/[a-zA-Z]/g,"").substring(0,20)
}

const handleSubmit = async (isFormValid) => {
	submitted.value = true;
	//
	//
	if (!isFormValid) {
		toast.add({
			severity: 'error',
			summary: 'All Fields are Required',
			life: 3000,
		});
		tableVuelidate.value = v$.value.table.$silentErrors[0]
			? v$.value.table.$silentErrors[0].$response.$errors
			: null;
		console.log(tableVuelidate.value, "HELL")
		tableReimburseVuelidate.value = v$.value.tableReimburse.$silentErrors[0]
			? v$.value.tableReimburse.$silentErrors[0].$response.$errors
			: null;
		//
		//
		return;
	} else if (savedFile.value === null && fileData.value === null) {
		toast.add({
			severity: 'error',
			summary: 'File is empty',
			life: 3000,
		});
		return;
	} else if (buttonValue.value === 'S') {
		try {
			progress.value = true;
			const statusValue = status.value;
			//
			const formData = new FormData();
			formData.append('file', fileData.value);
			const payload = {
				status_mail: 'SAVE',
				org_id: org_id.value,
				invoice_number: invoiceNumber.value,
				tax_invoice: taxInvoice.value,
				status: statusValue,
				invoice_date: formatDateDexa(invoiceDate.value),
				tax_date: formatDateDexa(taxInvoiceDate.value),
				supplier_site: supplierSiteValue.value.vendor_site_id,
				gl_date: formatDateDexa(glDate.value),
				payment_method: paymentValue.value.PAYMENT_METHOD,
				terms: termsValue.value.TERM_ID,
				cost_center: costCenter.value.SEGMENT4,
				ref_number: referenceNumber.value ? referenceNumber.value : '',
				description: description.value,
				date: formatDateDexa(new Date()),
				bank_id: remit.value ? remit.value.EXT_BANK_ACCOUNT_ID : null,
				total_line: totalLine.value,
				total_ppn: totalPPN.value,
				total: totalInvoice.value,
				reject: '',
				currency: currencyDropdown.value
					? currencyDropdown.value.currency_code
					: currency.value,
				approval: approval.value ? approval.value : '',
				id: parseInt(idValue.value),
				userid: userInfo.value.id,
				fileNameOld: fileName.value ? fileName.value : null,
				vendor_id: vendorId.value,
				table: table.value ? table.value : null,
				reimburse: tableReimburse.value ? tableReimburse.value : null,
			};
			const jsonpayload = JSON.stringify(payload);
			formData.append('payload', jsonpayload);
			//
			submitted.value = false;
			const save = await SearchInvoiceProxyNonPO.saveData(
				parseInt(idValue.value),
				formData
			);
			//
			if (save.status === 200) {
				toast.add({
					severity: 'success',
					summary: 'Data Saved',
					life: 3000,
				});
				await getAttachmentFile();
				setTimeout(() => {window.location.reload();}, 500)
				reload.value++;
				disabledDiv.value = true;
				// submitted.value = false
			} else if (save.status === 203) {
				toast.add({
					severity: 'error',
					summary: 'PDF Already had Password',
					life: 3000,
				});
				// submitted.value = false
			} else if (save.status === 204) {
				toast.add({
					severity: 'error',
					summary: 'File Not Found',
					life: 3000,
				});
				// submitted.value = false
			} else if (save.status === 444) {
				toast.add({
					severity: 'error',
					summary: 'Disconnected',
					detail: 'internet is unstable',
					life: 3000,
				});
				// submitted.value = false
			} else {
				toast.add({
					severity: 'error',
					summary: 'Data Error',
					life: 3000,
				});
			}
			//
			submitted.value = false;
			progress.value = false;
			tableVuelidate.value = null;
			tableReimburseVuelidate.value = null;
		} catch {
			toast.add({
				severity: 'error',
				summary: 'Disconnected',
				detail: 'internet is unstable',
				life: 3000,
			});
			// location.reload();
		}
	} else if (buttonValue.value === 'R') {
		try {
			progress.value = true;
			const statusValue = 'DRAFT';
			const formData = new FormData();
			formData.append('file', fileData.value);
			const payload = {
				email: {
					module: 'searchinvoicenonpo',
					idmodule: 2,
					sbjmessage:
						'Reject Invoice Non Po # ' +
						invoiceNumber.value +
						' from ',
					notif_type: 'Supplier',
					usersenderid: userInfo.value.id,
					sender: userInfo.value.bioemailactive,
					statusNotif: 'Reject',
					dbInstance: db_instance.value,
					companyname: company_name.value,
					vendorname: supplier.value,
					user_email: created_by.value
				},
				status_mail: 'REJECT',
				org_id: org_id.value,
				invoice_number: invoiceNumber.value,
				tax_invoice: taxInvoice.value,
				status: statusValue,
				invoice_date: formatDateDexa(invoiceDate.value),
				tax_date: formatDateDexa(taxInvoiceDate.value),
				supplier_site: supplierSiteValue.value.vendor_site_id,
				gl_date: formatDateDexa(glDate.value),
				payment_method: paymentValue.value.PAYMENT_METHOD,
				terms: termsValue.value.TERM_ID,
				cost_center: costCenter.value.SEGMENT4,
				ref_number: referenceNumber.value ? referenceNumber.value : '',
				description: description.value,
				date: formatDateDexa(new Date()),
				bank_id: remit.value ? remit.value.EXT_BANK_ACCOUNT_ID : null,
				total_line: totalLine.value,
				total_ppn: totalPPN.value,
				total: totalInvoice.value,
				reject: reject.value ? reject.value : '',
				currency: currencyDropdown.value
					? currencyDropdown.value.currency_code
					: currency.value,
				approval: approval.value ? approval.value : '',
				id: parseInt(idValue.value),
				userid: userInfo.value.id,
				fileNameOld: fileName.value ? fileName.value : null,
				vendor_id: vendorId.value,
				table: table.value ? table.value : null,
				reimburse: tableReimburse.value ? tableReimburse.value : null,
			};
			const jsonpayload = JSON.stringify(payload);
			formData.append('payload', jsonpayload);
			//
			submitted.value = false;
			const save = await SearchInvoiceProxyNonPO.saveData(
				parseInt(idValue.value),
				formData
			);
			//
			if (save.data.reject === 501) {
				toast.add({
					severity: 'error',
					summary: 'Reject Error',
					life: 3000,
				});
				// submitted.value = false
			} else if (save.status === 202) {
				toast.add({
					severity: 'error',
					summary: 'Opps Something Wrong',
					life: 3000,
				});
				// submitted.value = false
			} else if (save.status === 203) {
				toast.add({
					severity: 'error',
					summary: 'PDF Already had Password',
					life: 3000,
				});
				// submitted.value = false
			} else if (save.status === 204) {
				toast.add({
					severity: 'error',
					summary: 'File Not Found',
					life: 3000,
				});
				// submitted.value = false
			} else if (save.status === 444) {
				toast.add({
					severity: 'error',
					summary: 'Disconnected',
					detail: 'internet is unstable',
					life: 3000,
				});
				// submitted.value = false
			} else {
				toast.add({
					severity: 'success',
					summary: 'Reject Success',
					life: 3000,
				});
				await getAttachmentFile();
				status.value = 'DRAFT';
				checkStatus('DRAFT');
			}
			//
			submitted.value = false;
			progress.value = false;
			tableVuelidate.value = null;
			tableReimburseVuelidate.value = null;
		} catch (err) {
			console.log(err);

			submitted.value = false;
			progress.value = false;

			if (err.response.data.data.includes('Error sending emai')) {
				toast.add({
					severity: 'error',
					summary: err.response.data.data,
					life: 3000,
				});
			}

			toast.add({
				severity: 'error',
				summary: 'Unsuccessfully reject invoice',
				life: 3000,
			});
			// location.reload();
		}
	} else if (buttonValue.value === 'C') {
		try {
			progress.value = true;
			const statusValue = 'POSTED';
			const formData = new FormData();
			formData.append('file', fileData.value);
			const payload = {
				email: {
					module: 'searchinvoicenonpo',
					idmodule: 2,
					sbjmessage:
						'Submit Invoice Non Po # ' +
						invoiceNumber.value +
						' from ',
					notif_type: 'Supplier',
					usersenderid: userInfo.value.id,
					sender: userInfo.value.bioemailactive,
					statusNotif: 'Submit',
					dbInstance: db_instance.value,
					companyname: company_name.value,
					vendorname: supplier.value,
				},
				status_mail: 'SUBMIT',
				category: categoryValue.value ? categoryValue.value : null,
				category_id: category.value ? category.value : null,
				org_id: org_id.value,
				invoice_number: invoiceNumber.value,
				tax_invoice: taxInvoice.value,
				status: statusValue,
				invoice_date: formatDateDexa(invoiceDate.value),
				tax_date: formatDateDexa(taxInvoiceDate.value),
				supplier_site: supplierSiteValue.value.vendor_site_id,
				gl_date: formatDateDexa(glDate.value),
				payment_method: paymentValue.value.PAYMENT_METHOD,
				mapping_id: mapingId.value ? mapingId.value : 0,
				paygroup: paygroupValue.value ? paygroupValue.value : null,
				terms: termsValue.value.TERM_ID,
				cost_center: costCenter.value.SEGMENT4,
				ref_number: referenceNumber.value ? referenceNumber.value : '',
				description: description.value,
				date: formatDateDexa(new Date()),
				bank_id: remit.value ? remit.value.EXT_BANK_ACCOUNT_ID : null,
				ccid_header: account.value ? account.value : null,
				total_line: totalLine.value,
				total_ppn: totalPPN.value,
				total: totalInvoice.value,
				reject: '',
				currency: currencyDropdown.value
					? currencyDropdown.value.currency_code
					: currency.value,
				approval: approval.value ? approval.value : '',
				id: parseInt(idValue.value),
				userid: userInfo.value.id,
				fileNameOld: fileName.value ? fileName.value : null,
				vendor_id: vendorId.value,
				table: table.value ? table.value : null,
				reimburse: tableReimburse.value ? tableReimburse.value : null,
			};
			const jsonpayload = JSON.stringify(payload);
			formData.append('payload', jsonpayload);
			//
			submitted.value = false;
			const save = await SearchInvoiceProxyNonPO.saveData(
				parseInt(idValue.value),
				formData
			);
			// Non Akfit untuk sementara
			if (save.data.submit.status === 501) {
				toast.add({
					severity: 'error',
					summary: 'Submit Error',
					life: 3000,
				});
				// submitted.value = false
			} else if (save.data.submit.status === 500) {
				toast.add({
					severity: 'error',
					summary: 'Email is Not Found',
					detail: 'Unable to Send Mail',
					life: 3000,
				});
				// submitted.value = false
			} else if (save.status === 203) {
				toast.add({
					severity: 'error',
					summary: 'PDF Already had Password',
					life: 3000,
				});
				// submitted.value = false
			} else if (save.status === 204) {
				toast.add({
					severity: 'error',
					summary: 'File Not Found',
					life: 3000,
				});
				// submitted.value = false
			} else if (save.status === 444) {
				toast.add({
					severity: 'error',
					summary: 'Disconnected',
					detail: 'internet is unstable',
					life: 3000,
				});
				// submitted.value = false
			} else {
				toast.add({
					severity: 'success',
					summary: 'Submit Success',
					life: 3000,
				});
				await getAttachmentFile();
				status.value = 'POSTED';
				checkStatus('POSTED');
				reject.value = '';
			}
			tableVuelidate.value = null;
			tableReimburseVuelidate.value = null;
			submitted.value = false;
			progress.value = false;
			await getAttachmentFile();
			reject.value = '';
		} catch {
			toast.add({
				severity: 'error',
				summary: 'Disconnected',
				detail: 'internet is unstable',
				life: 3000,
			});
			// location.reload();
		}
	} else if (buttonValue.value === 'ST') {
		try {
			// toast.add({severity:'info', summary: 'Under Construction', detail:'Submit to Recomendator is Under Construction', life: 3000});
			progress.value = true;
			const statusValue = 'REVIEW';
			const formData = new FormData();
			formData.append('file', fileData.value);
			const payload = {
				bitrix: {
					companyname: company_name.value,
					vendorname: supplier.value,
					vendorId: vendorId.value,
					inv_amount: totalLine.value,
					tax_amount: totalPPN.value,
					total_amount: totalInvoice.value,
					inv_no: invoiceNumber.value,
					tax_no: taxInvoice.value,
					recom_email: submitEmail.value ? submitEmail.value : null,
					ref_no: referenceNumber.value,
					inv_date: formattingDate(invoiceDate.value),
					tax_date: formattingDate(taxInvoiceDate.value),
					cost_center: costCenter.value
						? costCenter.value.SEGMENT4
						: '',
					req_email: userInfo.value.bioemailactive,
					category_name: categoryValue.value
						? categoryValue.value
						: null,
					curr_code: currencyDropdown.value
						? currencyDropdown.value.currency_code
						: currency.value,
					inv_id: parseInt(idValue.value),
					user_id: userInfo.value.id,
				},
				status_mail: 'SUBMIT TO RECOMENDATOR',
				org_id: org_id.value,
				invoice_number: invoiceNumber.value,
				tax_invoice: taxInvoice.value,
				status: statusValue,
				invoice_date: formatDateDexa(invoiceDate.value),
				tax_date: formatDateDexa(taxInvoiceDate.value),
				supplier_site: supplierSiteValue.value.vendor_site_id,
				gl_date: formatDateDexa(glDate.value),
				payment_method: paymentValue.value.PAYMENT_METHOD,
				terms: termsValue.value.TERM_ID,
				cost_center: costCenter.value.SEGMENT4,
				ref_number: referenceNumber.value ? referenceNumber.value : '',
				description: description.value,
				date: formatDateDexa(new Date()),
				bank_id: remit.value ? remit.value.EXT_BANK_ACCOUNT_ID : null,
				total_line: totalLine.value,
				total_ppn: totalPPN.value,
				total: totalInvoice.value,
				reject: '',
				currency: currencyDropdown.value
					? currencyDropdown.value.currency_code
					: currency.value,
				approval: approval.value ? approval.value : '',
				id: parseInt(idValue.value),
				userid: userInfo.value.id,
				fileNameOld: fileName.value ? fileName.value : null,
				vendor_id: vendorId.value,
				submitted_date: formatDateDexa(new Date()),
				table: table.value ? table.value : null,
				reimburse: tableReimburse.value ? tableReimburse.value : null,
			};
			const jsonpayload = JSON.stringify(payload);
			
			formData.append('payload', jsonpayload);
			//
			submitted.value = false;
			const save = await SearchInvoiceProxyNonPO.saveData(
				parseInt(idValue.value),
				formData
			);

			if (save.data.recomendator.status === 501) {
				toast.add({
					severity: 'error',
					summary: 'Submit To Recomendator Error',
					life: 3000,
				});
				// submitted.value = false
			} else if (save.status === 203) {
				toast.add({
					severity: 'error',
					summary: 'PDF Already had Password',
					detail: 'Email is not found',
					life: 3000,
				});
				// submitted.value = false
			} else if (save.status === 204) {
				toast.add({
					severity: 'error',
					summary: 'File Not Found',
					life: 3000,
				});
				// submitted.value = false
			} else if (save.status === 444) {
				toast.add({
					severity: 'error',
					summary: 'Disconnected',
					detail: 'internet is unstable',
					life: 3000,
				});
				// submitted.value = false
			} else {
				toast.add({
					severity: 'success',
					summary: 'Submit To Recomendator Success',
					life: 3000,
				});
				await getAttachmentFile();
				status.value = 'REVIEW';
				checkStatus('REVIEW');
				// submitted.value = false
				//
			}
			//
			submitted.value = false;
			tableVuelidate.value = null;
			tableReimburseVuelidate.value = null;
			progress.value = false;
		} catch {
			toast.add({
				severity: 'error',
				summary: 'Disconnected',
				detail: 'internet is unstable',
				life: 3000,
			});
			// location.reload();
		}
	}
};

// const getFileBase64 = async (invoiceId) => {
// 	try {
// 		const leveltenant = sessionStorage.getItem('leveltenant')
// 		const res = await SearchInvoiceProxyNonPO.getFileBase64(invoiceId, leveltenant, vendorId.value);
//

// 		if (res.status === 200) {
// 			fileBuffer.value = res.data.data;
// 		}
// 	} catch (err) {
//
// 	}
// };

const onDownload = async () => {
	try {
		loadingDownloadFile.value = true;
		const leveltenant = sessionStorage.getItem('leveltenant');
		const res = await SearchInvoiceProxyNonPO.getFileBase64(
			idValue.value,
			leveltenant,
			vendorId.value
		);

		if (res.status === 200) {
			fileBuffer.value = res.data.data;
		}
	} catch (error) {
		loadingDownloadFile.value = false;
		toast.add({
			severity: 'error',
			summary: 'Connection Unstable !',
			life: 3000,
		});
		throw new Error('Error Downloading File');
	}

	const linkSource = `data:application/pdf;base64,${fileBuffer.value}`;
	const downloadLink = document.createElement('a');
	const fileName = attachmentName.value;
	downloadLink.href = linkSource;
	downloadLink.download = fileName;
	downloadLink.click();
	loadingDownloadFile.value = false;
};
</script>

<style scoped>
.input-number,
:disabled {
	background: #a19f9f !important;
}

/* input.dropdown {
	color: #000 !important;
} */
.form-card {
	padding: 10px;
	border: 1px solid #e0e0e0;
	border-radius: 16px;
}
</style>
