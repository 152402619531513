<template>
	<AutoComplete
		v-model="invoiceNum"
		:suggestions="filteredPoNumber"
		@complete="searchPoNumber($event)"
		:dropdown="true"
		field="INVOICE_NO"
		placeholder="Invoice Number"
		:disabled="poNumber.length < 1"
		forceSelection
	>
		<template #item="slotProp">
			{{ slotProp.item.INVOICE_NO }}
		</template>
	</AutoComplete>
</template>

<script setup>
import { onMounted, ref, watch, defineEmits } from 'vue';
import AuthService from '../../../service/AuthService';
import PendingPOService from '../../../service/PendingPOService';

// eslint-disable-next-line no-undef
const props = defineProps({
	company: { type: Object },
	supplier: { type: Object },
	batchNumber: { type: Object },
});
// eslint-disable-next-line no-undef
const filteredPoNumber = ref([]);
const poNumber = ref([]);
const invoiceNum = ref(null);
const emit = defineEmits('tableList')

onMounted(() => {
	populateData();
});

watch(invoiceNum, () => {
	emit('invoiceNum', invoiceNum)
})

const populateData = () => {
	AuthService.whoAmi().then(async (response) => {
		let payload;
		if (
			props.company !== null &&
			props.supplier !== null
		) {
			payload = {
				orgId: props.company.value,
				dbInstance: props.company.db_instance,
				vendorId: props.supplier.vendor_id,
			};
			//
			if (response.status === 200) {
				const data = await PendingPOService.getListInvoiceNumber(payload)
				poNumber.value = data.data.data;
				// console.log('isi po numbe batch supp', poNumber.value);
			}
		}else{
            payload = {
				orgId: props.company.value,
				dbInstance: props.company.db_instance,
			};
			//
			if (response.status === 200) {
				const data = await PendingPOService.getListInvoiceNumber(payload)
				poNumber.value = data.data.data;
				// console.log('isi po numbe batch supp', poNumber.value);
			}
        } 
	});
};

const searchPoNumber = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			//
			//
			filteredPoNumber.value = [...poNumber.value];
		} else {
			filteredPoNumber.value = poNumber.value.filter((comp) => {
				return comp.INVOICE_NO
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
		}
		//
	}, 250);
};
</script>

<style scoped></style>
