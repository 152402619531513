<template>
	<div class="grid find-sk-modal">
		<Toast />

		<!-- Content -->
		<div class="col-12 mb-2">
			<!-- Select SK Number  -->
			<div class="mb-6">
				<span class="p-float-label">
					<AutoComplete
						id="skNumber"
						class="w-full"
						:disabled="!skNumberList"
						:suggestions="filteredSKNumbers"
						:dropdown="true"
						field="sk_number"
						forceSelection
						v-model="skNumber"
						@complete="searchSKNumber($event)"
					/>
					<label for="skNumber">Choose SK Number</label>
				</span>
			</div>

			<!-- Action buttons -->
			<div class="grid">
				<div class="col-12 md:col-6">
					<Button
						v-if="!loading"
						class="w-full justify-content-center text-center p-button-success mr-2"
						:disabled="!skNumber"
						@click="handleSave"
					>
						<span class="uppercase font-bold">Save</span>
					</Button>
					<Button
						v-else
						class="w-full justify-content-center text-center p-button-success mr-2"
						disabled
					>
						<span class="uppercase font-bold">Saving...</span>
					</Button>
				</div>
				<div class="col-12 md:col-6">
					<Button
						class="w-full justify-content-center text-center p-button-secondary"
						@click="$emit('on-close')"
					>
						<span class="uppercase font-bold">Cancel</span>
					</Button>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref, defineProps, defineEmits } from 'vue';
import InvoiceCreateNonPOService from '../../service/InvoiceCreateNonPO';

const props = defineProps(['dbInstance', 'vendorId']);
const emit = defineEmits(['on-save']);

// State
const skNumber = ref(null);
const skNumberList = ref(null);
const filteredSKNumbers = ref(null);
const loading = ref(false);

onMounted(() => {
	getSKNumbers();
});

// Get SK Numbers
const getSKNumbers = async () => {
	const payload = {
		dbInstance: props.dbInstance,
		vendorId: props.vendorId,
	};

	const res = await InvoiceCreateNonPOService.getSKNumbers(payload);

	if (res.status === 200) {
		skNumberList.value = res.data.data;
	}
};

// Search SK Number on autocomplete
const searchSKNumber = (event) => {
	if (!event.query.trim().length) {
		filteredSKNumbers.value = [...skNumberList.value];
	} else {
		filteredSKNumbers.value = skNumberList.value.filter((item) => {
			return item.sk_number
				.toLowerCase()
				.match(event.query.toLowerCase());
		});
	}
};

// Save selected SK Number
const handleSave = () => {
	emit('on-save', skNumber.value);
};
</script>
