<template>
	<AutoComplete
		:suggestions="filteredCompanies"
		@complete="searchCompany($event)"
		:dropdown="true"
		field="name"
		placeholder="Select a company"
		:disabled="disabledButton"
		forceSelection
	>
		<template #item="slotProp">
			{{ slotProp.item.name }}
		</template>
	</AutoComplete>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import LovService from '@/service/LovService';
import SwithCompany from '@/service/SwithCompany';
import AuthService from '@/service/AuthService';

// eslint-disable-next-line no-undef
const filteredCompanies = ref([]);
const companies = ref([]);
const disabledButton = ref(true);

onMounted(async () => {
	try {
		const response = await AuthService.whoAmi();
		const user = response.data.data;
		if (user.leveltenant === 1) {
			if (user.userid === 'dexaadmin') {
				const tmp = await LovService.getValueType('COMPANY');
				companies.value = tmp.data.data;
				disabledButton.value = false;
			} else {
				const response = await LovService.getcompanydb(
					response.data.data.bioaddress
				);
				companies.value = response.data.data;
				disabledButton.value = false;
			}
		} else if (user.leveltenant === 2 || user.leveltenant === 3) {
			const userId = user.id;
			const response = await SwithCompany.getCompanyPayload(userId);
			if (response.status === 200) {
				const companyList = response.data.data;
				let compList = [];
				for (const c of companyList) {
					const resp = await LovService.getCompanyByName(c.company);
					const element = resp.data.data[0];
					if (
						!compList.find(
							(e) =>
								e.value === element.value &&
								e.db_instance === element.db_instance
						)
					) {
						compList.push(element);
						break;
					}
				}
				companies.value = await compList;
				disabledButton.value = false;
			}
		}
		// eslint-disable-next-line no-empty
	} catch (e) {}
});

const searchCompany = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredCompanies.value = [...companies.value];
    } else {
      filteredCompanies.value = companies.value.filter((comp) => {
        return comp.name.toLowerCase().match(event.query.toLowerCase());
      });
    }
  }, 250);
};
</script>
