import spbOrderApi from "@/service/api/SpbOrderApi";
import { encryptForm } from "../helper/encryptForm";

const getCompanies = async () => {

    const result = await spbOrderApi.get('/spb/dropdowns/companies');

    return result.data;
}

//done
const getTrackingPOByColumn = async (column, value, page, limit, sortColumn, sortType, company, supplier, dbInstance) => {
    const sort = sortType === 1 ? 'ASC':'DESC';
    let result
    if (sortColumn === undefined) {
      const params = {
        column,
        value,
        page,
        limit,
        company,
        supplier,
        dbInstance,
      };
      result = await spbOrderApi.post(`/spb/reports/trackingpo/data/filter`, encryptForm(params))
    } else {
      const params = {
        column,
        value,
        page,
        limit,
        sortColumn,
        sortType: sort,
        company,
        supplier,
        dbInstance,
      };
      result = await spbOrderApi.post(`/spb/reports/trackingpo/data/filter`, encryptForm(params))
    }
    return result.data;

}

//Jumlah data berdasarkan kriteria (done)
const getTrackingPOByColumnLength = async (column, value, company, supplier,dbInstance) => {
    const params = {
      column,value,company,supplier,dbInstance
    }
    const result = await spbOrderApi.post(`/spb/reports/trackingpo/length`, encryptForm(params))
    return result.data.length;
}


//done
const getTrackingPOByExcell = async (column, value, company, supplier,dbInstance, sortColumn, sortType ) => {
  const sort = sortType === 1 ? 'ASC':'DESC';
  let result
  if (sortColumn === undefined) {
    const params = {
      column,
      value,
      company,
      supplier,
      dbInstance,
    };
    result = await spbOrderApi.post(`/spb/reports/trackingpo/export`, encryptForm(params));
  } else {
    const params = {
      column,
      value,
      sortColumn,
      sortType: sort,
      company,
      supplier,
      dbInstance,
    };
    result = await spbOrderApi.post(`/spb/reports/trackingpo/export`, encryptForm(params));
  }
  return result.data;
}

//done
const getTrackingPOAll = async (page, limit, sortColumn, sortType) => {
    const sort = sortType === 1 ? 'ASC':'DESC';
    let result
    if (sortColumn === undefined) {
      const params ={
        page,limit
      }
       result = await spbOrderApi.post(`/spb/reports/trackingpo/data`,encryptForm(params))
    } else {
      const params ={
        page,limit,sort
      }
       result = await spbOrderApi.post(`/spb/reports/trackingpo/data`,encryptForm(params));
    }
    return result.data;
}

//Jumlah Data tanpa kriteria
const getTrackingPOAllLength = async () => {
    const result = await spbOrderApi.get(`/spb/reports/trackingpo/length`)
    return result.data.length;
}

export {
    getCompanies,
    getTrackingPOAll,
    getTrackingPOByColumn,
    getTrackingPOAllLength,
    getTrackingPOByColumnLength,
    getTrackingPOByExcell
}