<template>
	<AutoComplete
		:suggestions="filtereddebitNote"
		@complete="searchinvoiceNumber($event)"
		:dropdown="true"
		field="DN_NO"
		v-model="debitNoteValue"
		forceSelection
	>
		<template #item="slotProp">
			{{ slotProp.item.DN_NO }}
		</template>
	</AutoComplete>
</template>

<script setup>
import { onMounted, ref, watchEffect, defineEmits } from 'vue';
import AuthService from '../../../service/AuthService';
import CreateDebitNote from '../../../service/DebitNoteService';
// eslint-disable-next-line no-undef
const props = defineProps({
	company: { type: Object },
	supplier: { type: Object },
	debitNoteId: { type: Object },
});
// console.log('props', props)
// eslint-disable-next-line no-undef
const filtereddebitNote = ref([]);
const debitNote = ref([]);
const debitNoteValue = ref();
const emit = defineEmits(['remitdebitNote']);
// const disable = ref(false)
onMounted(() => {
	populateData();
});

watchEffect(() => emit('remitdebitNote', debitNoteValue));
const populateData = () => {
	AuthService.whoAmi().then(async (response) => {
		if (response.status === 200) {
			// disable.value = true
			let payload;
			if (props.company !== null && props.supplier !== null) {
				payload = {
					org_id: props.company ? props.company.value : null,
					supValue: props.supplier.vendor_id
						? props.supplier.vendor_id
						: null,
					db_instance: props.company.db_instance,
				};
				console.log('masuk sini', payload);
				const data = await CreateDebitNote.getDebitNote(payload);
				debitNote.value = data.data.data.data;

				for (const s of debitNote.value) {
					if (s.INVOICE_ID === props.debitNoteId) {
						debitNoteValue.value = s;
						break;
					} else {
						debitNoteValue.value = null;
					}
				}
			} else if (props.company !== null) {
				payload = {
					org_id: props.company ? props.company.value : null,
					supValue: props.supplier ? props.supplier.vendor_id : null,
				};
				const data = await CreateDebitNote.getDebitNote(payload);
				debitNote.value = data.data.data.data;
			}
		}
	});
};
const searchinvoiceNumber = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filtereddebitNote.value = [...debitNote.value];
		} else {
			filtereddebitNote.value = debitNote.value.filter((comp) => {
				return comp.DN_NO.toLowerCase().match(
					event.query.toLowerCase()
				);
			});
		}
	}, 250);
};
</script>

<style scoped></style>
