<template>
	<DataTable
		:value="poList"
		:lazy="true"
		:paginator="true"
		:rows="10"
		:loading="loading"
		:totalRecords="totalData"
		@page="onPage($event)"
		@sort="onSort($event)"
		:rowsPerPageOptions="[10, 20, 30]"
		responsiveLayout="scroll"
		:scrollable="true"
		scrollHeight="600px"
		scrollDirection="both"
		:sortable="true"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #empty>No PO found</template>
		<template #loading>Loading data, please wait...</template>
		<Column header="Company Name" field="COMPANY" style="width: 15%">
		</Column>
		<Column header="Supplier Name" field="VENDOR_NAME" style="width: 15%">
		</Column>
		<Column header="PO Number" field="SEGMENT1" style="width: 15%">
		</Column>
		<Column header="Line" field="LINE_NUM" style="width: 9%"> </Column>
		<Column header="Type PO" field="TYPE_PO" style="width: 9%"> </Column>
		<Column header="Item Number" field="ITEM_NUMBER" style="width: 15%">
		</Column>
		<Column
			header="Item Description"
			field="ITEM_DESCRIPTION"
			style="width: 20%"
		>
		</Column>
		<Column header="UOM" field="UOM" style="width: 9%"> </Column>
		<!-- <Column header="Unit Price" field="UNIT_PRICE" style="width: 9%">
		</Column> -->
		<Column header="Quantity" field="QUANTITY" style="width: 9%"> </Column>
		<Column header="Arrival at Site" field="NEED_BY_DATE" style="width: 9%">
			<template #body="{ data }">
				{{
					new Date(data.NEED_BY_DATE).toLocaleDateString('id-ID', {
						year: 'numeric',
						month: 'short',
						day: 'numeric',
					})
				}}
			</template>
		</Column>
		<Column
			header="Est. Good Readiness"
			field="ESTIMATE_COMPLATION"
			style="width: 9%"
		>
			<template #body="{ data }">
				{{
					new Date(data.ESTIMATE_COMPLATION).toLocaleDateString(
						'id-ID',
						{
							year: 'numeric',
							month: 'short',
							day: 'numeric',
						}
					)
				}}
			</template>
		</Column>
		<Column
			header="Est. Delivery"
			field="ESTIMATE_MANUFACTURER"
			style="width: 9%"
		>
			<template #body="{ data }">
				{{
					new Date(data.ESTIMATE_MANUFACTURER).toLocaleDateString(
						'id-ID',
						{
							year: 'numeric',
							month: 'short',
							day: 'numeric',
						}
					)
				}}
			</template>
		</Column>
		<Column
			header="Est. Arrival at Site"
			field="ESTIMATE_SUPPLIER"
			style="width: 9%"
		>
			<template #body="{ data }">
				{{
					new Date(data.ESTIMATE_SUPPLIER).toLocaleDateString(
						'id-ID',
						{
							year: 'numeric',
							month: 'short',
							day: 'numeric',
						}
					)
				}}
			</template>
		</Column>
		<Column header="Delivery Date" field="SHIPPED_DATE" style="width: 9%">
			<template #body="{ data }">
				{{
					new Date(data.SHIPPED_DATE).toLocaleDateString('id-ID', {
						year: 'numeric',
						month: 'short',
						day: 'numeric',
					})
				}}
			</template>
		</Column>
		<Column header="DO Number" field="SHIPMENT_NUMBER" style="width: 9%">
		</Column>
		<Column
			header="Quantity Shipped"
			field="QUANTITY_SHIPPED"
			style="width: 9%"
		>
		</Column>
	</DataTable>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import {
	getTrackingPOAll,
	getTrackingPOAllLength,
	getTrackingPOByColumn,
	getTrackingPOByColumnLength,
} from '@/service/TrackingPOReport';
import AuthService from '@/service/AuthService';
import PoFulfillment from '@/service/PoFulfillment';
import LovService from '@/service/LovService';

const company = ref();
const supplier = ref();

onMounted(async () => {
	company.value = props.company;
	const info = await AuthService.whoAmi();
	if (info.status === 200) {
		levelTenant.value = info.data.data.leveltenant;
		if (levelTenant.value !== 2) {
			loading.value = true;
			supplier.value = props.supplier;
			// loading.value = true
			await populateTable();
		} else {
			if (company.value && !supplier.value) {
				loading.value = true;
				const param = {
					db_instance: props.company.db_instance,
					org_id: props.company.value,
				};
				const respVendor = await PoFulfillment.getSuppBindComp(
					info.data.data.id
				);
				const vendorName = respVendor.data.data[0][0].vendorname;
				const respSuppName = await LovService.getSupplierName(param);
				if (respSuppName.status === 200) {
					const suppList = respSuppName.data.data;
					for (const s of suppList) {
						if (s.vendor_name === vendorName) {
							supplier.value = s;
							break;
						}
					}
				}
				await populateTable();
			}
			// loading.value = true
		}
	}
});
// eslint-disable-next-line no-undef
const props = defineProps({
	column: {
		type: String,
	},
	columnSearch: {
		type: String,
	},
	company: {
		type: Object,
	},
	supplier: {
		type: Object,
	},
});

const poList = ref();
const totalData = ref(0);
const loading = ref(false);
const page = ref(1);
const rowsPerPage = ref(10);
const sortColumn = ref();
const sortType = ref();
const levelTenant = ref();

const populateTable = async () => {
	loading.value = true;
	//
	if (
		(props.column !== null && props.column !== undefined) ||
		props.company ||
		props.supplier
	) {
		//
		poList.value = await getTrackingPOByColumn(
			props.column,
			props.columnSearch,
			page.value,
			rowsPerPage.value,
			sortColumn.value,
			sortType.value,
			company.value ? company.value.value : null,
			supplier.value ? supplier.value.vendor_id : null,
			company.value ? company.value.db_instance : null
		);
		totalData.value = await getTrackingPOByColumnLength(
			props.column,
			props.columnSearch,
			company.value ? company.value.value : null,
			supplier.value ? supplier.value.vendor_id : null,
			company.value ? company.value.db_instance : null
		);
		// poList.value = await getTrackingPOByColumn(props.column, props.columnSearch,
		//                       page.value, rowsPerPage.value, sortColumn.value, sortType.value,
		//               props.company?props.company.value:null, props.supplier?props.supplier.vendor_id:null)
		// totalData.value = await getTrackingPOByColumnLength(props.column, props.columnSearch,
		//               props.company?props.company.value:null, props.supplier?props.supplier.vendor_id:null)
	} else {
		//
		poList.value = await getTrackingPOAll(
			page.value,
			rowsPerPage.value,
			sortColumn.value,
			sortType.value
		);
		totalData.value = await getTrackingPOAllLength();
	}
	loading.value = false;
};

const onPage = async (event) => {
	// loading.value = true
	page.value = event.page + 1;
	rowsPerPage.value = event.rows;
	await populateTable();
};

const onSort = async (event) => {
	// loading.value = true
	page.value = 1;
	rowsPerPage.value = event.rows;
	sortColumn.value = event.sortField;
	sortType.value = event.sortOrder;
	await populateTable();
};
</script>

<style scoped></style>
