<template>
	<div class="grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		<ConfirmDialog group="positionDialog"></ConfirmDialog>
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<div class="col-12 p-fluid">
			<div class="card">
				<div
					class="card"
					style="
						background-color: #da3831;
						height: 2em;
						margin: 0 0 2em 0;
					"
				>
					<h5 style="color: white; margin-top: -10px;">
						Add/Edit Shipment Notice
					</h5>
				</div>
                <div>
                    <h5 style="color: black; margin: 0 0 2em 0;">
						Shipment Information
					</h5>
                </div>
				<div class="p-fluid grid formgrid">
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								type="text"
								v-model="doNumber"
								v-bind:disabled="doNumber"
							/>
							<label for="inputtext">Do Number</label>
						</span>
					</div>
                    <div class="col-12 mb-4 lg:col-6 lg:mb-5" v-if="statusDiv">
						<span class="p-float-label">
							<!-- <InputText id="inputtext" type="text" v-model="CreateShipmentServiceDate" v-bind:disabled="CreateShipmentServiceDate"/> -->
							<InputText
								id="inputtext"
								type="text"
								v-model="statusShipment"
								v-bind:disabled="statusShipment"
							/>
							<label for="inputtext">Status</label>
						</span>
					</div>
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<!-- <InputText id="inputtext" type="text" v-model="CreateShipmentServiceDate" v-bind:disabled="CreateShipmentServiceDate"/> -->
							<InputText
								id="inputtext"
								type="text"
								v-model="deliveryDate"
								v-bind:disabled="deliveryDate"
							/>
							<label for="inputtext">Delivery Date</label>
						</span>
					</div>
				</div>
                <div>
                    <h5 style="color: black; margin: 0 0 2em 0;">
						Freight Information
					</h5>
                </div>
                <div class="p-fluid grid formgrid">
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								type="text"
								v-model="freight"
								v-bind:disabled="freight"
							/>
							<label for="inputtext">Freight Carrier</label>
						</span>
					</div>
                    <div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<!-- <InputText id="inputtext" type="text" v-model="CreateShipmentServiceDate" v-bind:disabled="CreateShipmentServiceDate"/> -->
							<InputText
								id="inputtext"
								type="text"
								v-model="awbNumber"
								v-bind:disabled="awbNumber"
							/>
							<label for="inputtext">AWB/BL Number</label>
						</span>
					</div>
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
                            <Textarea id="inputtext"
								type="text"
								v-model="comments"
								:autoResize="true" rows="5" cols="30" />

							<label for="inputtext">Comment</label>
						</span>
					</div>
				</div>
        <div
        class="card"
        style="
            background-color: #da3831;
            height: 2em;
            margin: 0 0 2em 0;
        "
    >
        <h5 style="color: white; margin-top: -10px">
            Attach Document
        </h5>
        </div>
        <div class="col-12 mb-5">
					<DataTable
						:value="CreateShipmentServiceTable"
						dataKey="pr_line_num"
						:paginator="true"
						:rows="5"
						removableSort
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
						:rowsPerPageOptions="[5, 10, 25]"
						:loading="loading"
						selectionMode="multiple"
						:metaKeySelection="false"
						@rowSelect="onRowSelect"
						@rowUnselect="onRowUnselect"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
						responsiveLayout="scroll"
					>
						<!-- <Column selectionMode="multiple" headerStyle="width: 3em"></Column> -->
						<Column
							field="pr_line_num"
							header="No"
							headerStyle="min-width:5rem;"
						></Column>
						<Column
							field="item_num"
							header="Document"
							headerStyle="min-width:12rem;"
						></Column>
						<Column
							field="item_desc"
							header="File"
							headerStyle="min-width:20rem;"
						></Column>
						<Column
							field="uom"
							header="Need Original"
							headerStyle="min-width:8rem;"
						>
						</Column>
						<Column
							field="plan_qty"
							header="Validate"
							dataType="numeric"
							headerStyle="min-width:8rem;"
							style="text-align: center" 
						>
							<template #body="{ data }">
								{{ formatCurrency(data.plan_qty) }}
							</template>
						</Column>
					</DataTable>
				</div>
			</div>
		</div>
        
        
		<div class="col-12 p-fluid">
			<div class="card">
            <div
            class="card"
            style="
                background-color: #da3831;
                height: 2em;
                margin: 0 0 2em 0;
            "
        >
            <h5 style="color: white; margin-top: -10px">
                Items
            </h5>
            </div>
				<div class="col-12 mb-5">
					<DataTable
						:value="createShipmentTable"
						dataKey="PO_KEY_ID"
						:paginator="true"
						:rows="5"
						removableSort
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
						:rowsPerPageOptions="[5, 10, 25]"
						:loading="loading"
						selectionMode="multiple"
						:metaKeySelection="false"
						@rowSelect="onRowSelect"
						@rowUnselect="onRowUnselect"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
						responsiveLayout="scroll"
					>
						<!-- <Column selectionMode="multiple" headerStyle="width: 3em"></Column> -->
						<Column
							field="SEGMENT1"
							header="PO Number"
							:sortable="true"
							headerStyle="min-width:5rem;"
						></Column>
						<Column
							field="PO_LINE_ID"
							header="Line"
							:sortable="true"
							headerStyle="min-width:12rem;"
						></Column>
						<Column
							field="ITEM_ID"
							header="Item Number"
							:sortable="true"
							headerStyle="min-width:20rem;"
						></Column>
						<Column
							field="NEED_BY_DATE"
							header="Arrival at Site Date"
							headerStyle="min-width:8rem;"
						>
						</Column>
						<Column
							field="QUANTITY"
							header="Qty Order"
							dataType="numeric"
							headerStyle="min-width:8rem;"
							style="text-align: center" 
						>
							<template #body="{ data }">
								{{ formatCurrency(data.QUANTITY) }}
							</template>
						</Column>
						<Column
							field="UOM"
							header="UOM"
							:sortable="true"
							headerStyle="min-width:10rem;"
						>
						</Column>
						<Column
							field="SUBMIT_STATUS"
							header="Qty Shipped"
							:sortable="true"
							headerStyle="min-width:10rem;"
						>
						</Column>
					</DataTable>
				</div>

				<div class="p-fluid grid formgrid">
					<div class="col-6 mb-2 lg:col-1 lg:mb-0">
						<Button
							type="button"
							label="Back"
							class="p-button-secondary"
							@click="back()"
						/>
					</div>
					<div class="col-6 mb-2 lg:col-1 lg:mb-0">
						<Button type="button" label="Save" @click="save()" :disabled="this.button"/>
					</div>
					<div class="col-6 mb-2 lg:col-1 lg:mb-0">
						<Button
							type="button"
							label="Submit"
							class="p-button-success"
							@click="confirm()" :disabled="this.button"
						/>
					</div>
					<!-- <div class="col-6 mb-2 lg:col-1 lg:mb-0">
                    <Button type="button" label="Not Accept" class="p-button-danger" @click="notAccept()"/>
                </div> -->
				</div>
			</div>
		</div>
	</div>

</template>

<script>
/* eslint-disable */
import CreateShipmentService from '../../../service/CreateShipmentService';
import AuthService from '../../../service/AuthService';
// import SimpleCrypto from 'simple-crypto-js';
export default {
	name: 'CreateShipmentService-list',
	data() {
		return {
			idValue: this.$route.params.id,
			company: sessionStorage.getItem('mailToCompany'),
			idDetail: null,
			doNumber: null,
			statusShipment: null,
			awbNumber: null,
			deliveryDate: null,
			respondDate: null,
			displayResponsive: false,
			selectedPo: null,
			selectedPoType: null,
			currenciesList: null,
			freight: null,
			arrivalDate: null,
			status: null,
			popUpPo: null,
			filteredPopUpPo: null,
			sender: null,
			usersenderid: null,
			vendorId: null,
			dbInstance: null,
			recipientMail: null,
			vendorname: null,
			orgIdNotif: null,
			disabledOption: [],
			button : false,
			statusDiv: false,
			comments: null,
			createShipmentTable: null,
			monthNames : ["January", "February", "March", "April", "May", "June",
			"July", "August", "September", "October", "November", "December"
			],
			monthShortNames : ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
			"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
			],
			breadcrumbHome: { icon: 'pi pi-home', to: '/' },
			breadcrumbItems: [
				{ label: 'Planning Order', to: '/mgm/settings/CreateShipmentService' },
				{ label: 'Detail Planning Order' },
			],
		};
	},
	created() {
		AuthService.whoAmi().then(response => {
			if (response.status == 200){
				let payload = this.idValue
				CreateShipmentService.getIdDetailShipment(payload).then(response => {
					// this.doNumber = response.data.data[0].
					this.deliveryDate = response.data.data[0].DELIVERY_INVOICE_DATE
					this.statusShipment = response.data.data[0].STATUS
					this.freight = response.data.data[0].FREIGHT_CARRIER
					this.comments = response.data.data[0].COMMENTS
					this.createShipmentTable = response.data.data
					this.statusShipment = response.data.data[0].STATUS
					if (this.statusShipment == 'Draft'){
						this.statusDiv = true
					}
				})
			}
		})
		// 	const secretKey = 'some-unique-key';
		// 	const simpleCrypto = new SimpleCrypto(secretKey);
		// 	this.recipient = simpleCrypto.decrypt(
		// 		sessionStorage.getItem('dropdownCompany')
		// 	);
		// 	// 
		// 	if (this.idValue != 0) {
		// 		sessionStorage.setItem('printPoId', this.idValue);
		// 		AuthService.whoAmi().then((response) => {
		// 			this.sender = response.data.data.bioemailactive;
		// 			// this.dbInstance = response.data.data.bioaddress;
		// 			this.usersenderid = response.data.data.id;
		// 			
		// 			if (response.status === 200) {
		// 				// 
		// 				const payload = this.idValue;
		// 				this.respondBy = response.data.data.userid;
		// 				CreateShipmentService.getIdDetail(payload).then((response) => {
		// 					// 
		// 					this.isiDetail = response.data.data;
		// 					// 
		// 					this.CreateShipmentServiceNum =
		// 						response.data.data[0].plan_order_num;
		// 					// let getMustRespDate = response.data.data[0].response_date;
		// 					// let dateProcessA = getMustRespDate.substring(0,2);
		// 					// let dateProcessB = getMustRespDate.substring(2,5);
		// 					// let dateProcessC = getMustRespDate.substring(6,11);
		// 					// let combineMustRespDate = dateProcessA + '/' + dateProcessB + '/' + dateProcessC;
		// 					// let newDateA = new Date(combineMustRespDate.substring(0,10));
		// 					// this.respondDate = newDateA.getDate()+'-'+newDateA.toString().substr(4,3).toUpperCase()+'-'+newDateA.getFullYear();
		// 					this.respondDate = this.formatDate(
		// 						new Date(response.data.data[0].response_date)
		// 					)[0];
		// 					this.supplier = response.data.data[0].vendor_name;
		// 					// this.respondBy = response.data.data[0].response_by
		// 					// this.revisition = response.data.data[0].revisition;
		// 					if(response.data.data[0].revisition != null){
		// 						this.revisition = response.data.data[0].revisition;
		// 					} else {
		// 						this.revisition = 0;
		// 					}
		// 					this.CreateShipmentServiceStatus = response.data.data[0].status;
		// 					if (response.data.data[0].status === 'HOLD') {
		// 						// 
		// 						this.changeHold = true;
		// 						this.changeUnhold = false;
		// 						this.submitpo = false;
		// 					}
		// 					if (response.data.data[0].status !== 'HOLD') {
		// 						// 
		// 						this.changeHold = false;
		// 						this.changeUnhold = true;
		// 						this.submitpo = true;
		// 					}
		// 					// let dateResp = response.data.data[0].plan_order_date;
		// 					// let dateRespProcessA = dateResp.substring(0,2);
		// 					// let dateRespProcessB = dateResp.substring(2,5);
		// 					// let dateRespProcessC = dateResp.substring(6,11);
		// 					// let combinedDateResp = dateRespProcessA + '/' + dateRespProcessB + '/' + dateRespProcessC;
		// 					// let newDate = new Date(combinedDateResp.substring(0,10));
		// 					// this.dateRespConvert = newDate.getDate()+'-'+newDate.toString().substr(4,3)+'-'+newDate.getFullYear();
		// 					this.CreateShipmentServiceDate = this.formatDate(
		// 						new Date(response.data.data[0].plan_order_date)
		// 					)[0];
		// 					if (this.CreateShipmentServiceNum !== null) {
		// 						const payload =
		// 							response.data.data[0].plan_order_num;
		// 						CreateShipmentService.getTableDetailPo(payload).then(
		// 							(response) => {
		// 								this.CreateShipmentServiceTable = response.data.data;
		// 								this.vendorId = response.data.data[0]['vendor_id']
		// 								this.orgIdNotif = response.data.data[0]['org_id']
		// 								
		// 								// await new Promise(resolve => setTimeout(resolve, 500));
		// 								
		// 								
		// 								for (var id in this.CreateShipmentServiceTable){
		// 									if ( this.CreateShipmentServiceTable[id].name == 'TO BE PROCESSED' || this.CreateShipmentServiceTable[id].name == 'COMPLETED'){
		// 										this.disabledOption[id] = true
		// 									}
		// 									else {
		// 										this.disabledOption[id] = false
		// 									}
											
		// 								}
		// 							}
		// 						);
								
		// 						CreateShipmentService.getTermsSupp(payload).then(
		// 							(respond) => {
										
		// 								let getDistinctLine = null
		// 								let newSetArray = []
		// 								respond.data.data.forEach((element) => {
		// 									if (getDistinctLine !== element.plan_order_line_id) {
		// 										newSetArray.push(element)
		// 										getDistinctLine = element.plan_order_line_id
		// 									}
		// 								})
		// 								this.termsTable = [...new Set(newSetArray)]

		// 								for (let id of this.termsTable) {
		// 									
		// 									if ( id.need_by_date != null ){
		// 										id.need_by_date = new Date(id.need_by_date)
		// 										
		// 										if (id.need_by_date == 'Invalid Date'){
		// 											id.need_by_date = ''
		// 										}
		// 										// id.confirm_ship_date = new Date(id.confirm_ship_date)
		// 										id.confirm_ship_date = this.dateFormat2(new Date(id.confirm_ship_date))
		// 										if ( id.confirm_ship_date == '1 Jan 1970 7:0:0'){
		// 											id.confirm_ship_date = new Date()
		// 										}
		// 										
		// 										// 
		// 									}
		// 									else {
		// 										id.confirm_ship_date = new Date()
		// 									}
											
		// 									// 
		// 								}
		// 								// let date = new Date(response.data.data.confirm_ship_date)
		// 								
		// 							}
		// 						);
		// 					}
		// 				});
		// 			}
		// 		});
		// }
		// this.getTablePoNumber();
	},
	watch: {

	},
	mounted() {
		// let payload = sessionStorage.companydb;
		// CreateShipmentService.getAllCurrencies(payload).then((value) => {
		// 	this.currenciesList = value.data.data;
		// });
		// DAPETIN EMAIL RECIPIENT/COMPANY
		// const data = {
		// 	idUser: sessionStorage.getItem('idUser'),
		// 	companyname: this.company,
		// };
		// CreateShipmentService.getRecipientMail(data).then((respond) => {
		// 	this.dbInstance = respond.data.data[0][0]['DB_INSTANCE']
		// 	var tampungEmail = [];
		// 	var getOrgId = null;
		// 	var suppliername = null;
		// 	respond.data.data[0].forEach((element) => {
		// 		tampungEmail.push(element.targetemailgroup);
		// 		if (getOrgId === null) {
		// 			getOrgId = element.id;
		// 			suppliername = element.vendorname;
		// 		}
		// 	});
		// 	this.recipientMail = tampungEmail
		// 		.toString()
		// 		.replace(/"/g, '')
		// 		.replace(/[\[\]']+/g, '')
		// 		.replace(/ /g, '')
		// 		.replace(/,/g, ', ');
		// 	this.vendorname = suppliername;
		// 	this.orgIdNotif = getOrgId;
		// });
	},
	computed: {
		
	},
	methods: {
		formatCurrency(value) {
			if (value) return value.toLocaleString('id-ID');
			return;
		},
		pad2(n) {
			return (n < 10 ? '0' : '') + n;
		},
		dateFormat2(d) {
		var t = new Date(d);
		return t.getDate() + ' ' + this.monthShortNames[t.getMonth()] + ' ' + t.getFullYear() + ' ' + t.getHours() + ':' + t.getMinutes() + ':' + t.getSeconds();
		},
		formatDate(date) {
			var month = this.pad2(date.getMonth() + 1); //months (0-11)
			var day = this.pad2(date.getDate()); //day (1-31)
			var year = date.getFullYear();
			var combinedNeedDate = new Date(month + '/' + day + '/' + year);
			let formattedDate = [];
			formattedDate[0] =
				combinedNeedDate.getDate() +
				'-' +
				combinedNeedDate.toString().substr(4, 3) +
				'-' +
				combinedNeedDate.getFullYear();
			formattedDate[1] =
				combinedNeedDate.getMonth() +
				1 +
				'/' +
				combinedNeedDate.getDate() +
				'/' +
				combinedNeedDate.getFullYear();
			return formattedDate;
		},
		AllTheSame(array) {
			var first = array[0];
			return array.every(function(element) {
				return element.name === first.name;
			});
		},
		 formatDateSend(date){
			var d = new Date(date),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();

			if (month.length < 2) 
				month = '0' + month;
			if (day.length < 2) 
				day = '0' + day;

			return [year, month, day].join('-');
			},
		formatDateDexa(date){
			var date = new Date(date);
			var respondDate =
					('00' + (date.getMonth() + 1)).slice(-2) +
					'/' +
					('00' + date.getDate()).slice(-2) +
					'/' +
					date.getFullYear() +
					' ' +
					('00' + date.getHours()).slice(-2) +
					':' +
					('00' + date.getMinutes()).slice(-2) +
					':' +
					('00' + date.getSeconds()).slice(-2);
			return respondDate
		},
		back() {
			this.$router.push({ name: 'CreateShipmentNotice' });
		},
		save() {
				AuthService.whoAmi().then((response) => {
					if (response.status === 200){
						let payload = {}
					}
				});
			}
		},
		confirm() {
				AuthService.whoAmi().then((response) => {

					
							// KIRIM NOTIFIKASI KE DASHBOARD
							// const payload1 = {
							// 	idmodule: '1',
							// 	sbjmessage: 'Planning Order #' +
							// 		this.CreateShipmentServiceNum.toString() +
							// 		' has been confirmed by ' +
							// 		this.vendorname.toString(),
							// 	receiveddate: new Date(),
							// 	status: 'Close',
							// 	notif_type: 'Purchasing',
							// 	sender: this.sender.toString(),
							// 	recipient: this.recipientMail.toString(),
							// 	dbInstance: this.dbInstance,
							// 	orgIdComp: this.orgIdNotif,
							// 	usersenderid: this.vendorId,
							// 	// userreceiveid: this.usersenderid,
							// };
							// CreateShipmentService.sendPONotifSupp(payload1).then((value) => {
							// 	if (value.status === 200) {
							// 		// this.recipientMail = [];
							// 		window.alert('Purchasing Successfully Notified');
							// 		// console.clear();

							// 	}
							// });
							// //NGIRIM EMAIL
							// CreateShipmentService.sendPOEmail({
							// 	sendpodata: JSON.stringify(payload.isi),
							// }).then(() => {
							// });
							// this.$router.push({
							// 	name: 'CreateShipmentNotice'
							// });

				});
			},
};
</script>
