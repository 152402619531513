<template>
  <ProgressModal :progress="progress">
    <template #message> Submit in progress.<br />Please wait... </template>
  </ProgressModal>
  <Toast />
  <div class="card pt-6">
    <div class="grid">
      <div class="col-12 mb-3 lg:col-4">
        <span class="">
          <AutoComplete class="w-full" v-model="selectedCompany" :suggestions="filteredCompany"
            @complete="searchCountry($event)" :dropdown="true" field="name" placeholder="Select a Company"
            forceSelection disabled>
            <template #item="slotProps">
              <div class="country-item">
                <div class="ml-2">
                  {{ slotProps.item.name }}
                </div>
              </div>
            </template>
          </AutoComplete>
        </span>
      </div>

      <div class="col-12 mb-3 lg:col-4">
        <span class="">
          <AutoComplete class="w-full" v-model="selectedSupplier" :suggestions="filteredSupplier"
            @complete="searchSupp($event)" :dropdown="true" field="vendor_name" placeholder="Select a Supplier"
            :disabled="suppStatus" forceSelection>
            <template #item="slotProps">
              <div class="country-item pl-2">
                <div class="mr-2">
                  {{ slotProps.item.vendor_name }}
                </div>
              </div>
            </template>
          </AutoComplete>
        </span>
      </div>

      <div class="col-12 lg:col-4 mb-3 lg:mb-0">
        <span class="">
          <AutoComplete class="w-full input-full" v-model="selectedPrNum" :suggestions="filteredPrNum" :disabled="!selectedSupplier"
            @complete="searchPrNum($event)" field="pr_num" placeholder="Select a Pr Num" forceSelection>
          </AutoComplete>
        </span>
      </div>

      <div class="col-12 lg:col-4 mb-3 lg:mb-0">
        <span class="">
          <Dropdown class="w-full" v-model="selectedJit" :options="jitList" optionLabel="name" optionValue="value"
            placeholder="Select a JIT" />
        </span>
      </div>

      <div class="col flex-column align-items-center lg:col-auto lg:flex-row">
        <Button class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0" label="Search" icon="pi pi-search"
          :disabled="searchButtonStatus" @click="search()" />
        <Button class="p-button-secondary w-full lg:w-auto" label="Refresh" icon="pi pi-refresh" @click="reset()"
          @page="onPage($event)" />
      </div>

    </div>
  </div>
  <div class="card">
    <DataTable :value="reqTableList" :paginator="true" :rows="10"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :lazy="true"
      :rowsPerPageOptions="[10, 20, 50]" responsiveLayout="scroll" :totalRecords="totalData"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" @page="onPage($event)"
      v-model:selection="selectedReq" selectionMode="multiple" dataKey="id" :metaKeySelection="false" :loading="loading"
      :scrollable="true" scrollHeight="600px" v-model:first="first">
      <template #header>
        <div class="table-header-container">
          <h5 class="{color: black}">OPEN REQUSITION</h5>
        </div>
      </template>
      <Column class="call-button-container" header="Company" field="company"></Column>
      <Column class="call-button-container" header="Supplier" field="vendor_name"></Column>
      <Column class="call-button-container" header="PR Number" field="pr_num"></Column>
      <Column class="call-button-container" header="Line" field="pr_line_num"></Column>
      <Column class="call-button-container" header="Item Number" field="item_num"></Column>
      <Column class="call-button-container" header="Item Description" field="item_desc"></Column>
      <Column class="call-button-container" header="Qty" field="pr_qty"></Column>
      <Column class="call-button-container" header="UOM" field="plan_uom"></Column>
      <Column class="call-button-container" header="Currency" field="currency"></Column>
      <Column class="call-button-container" header="Need By Date" field="need_by_date">
      </Column>
      <Column class="call-button-container" header="Ship to" field="deliver_to_code"></Column>
      <Column class="call-button-container" header="JIT" field="jit_flag">
        <template #body="{ data }">
          {{ formatJit(data) }}
        </template>
        <!-- <template #body="{slotProps}">
					<label>
						{{slotProps.data.jit_flag}}
					</label>
				</template> -->
      </Column>
      <Column class="call-button-container" header="Manufacturer" field="manufacturer_description"></Column>
    </DataTable>
    <div style="text-align: right" class="pl-8 pt-3">
      <span class="pl-2">
        <Button label="Save As Draft" icon="pi pi-plus" :disabled="draftButtonStatus" @click="pushDraftB()" />
      </span>
      <div v-if="saveDraftStatus">
        <Message severity="error">Please Select Company And Supplier First</Message>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="pt-4">
      <div class="p-fluid grid">
        <div class="field col-12 md:col-4 pt-2">
          <span class="p-float-label pl-2">
            <InputText id="ponum" type="text" v-model="poDraftNum" disabled />
            <label for="ponum">Planning Order No</label>
          </span>
        </div>
        <div class="field col-12 md:col-4">
          <span class="p-float-label pl-2">
            <Calendar id="podate" v-model="poDraftDate" :showIcon="true" dateFormat="dd-M-yy" />
            <label for="podate">Planning Order Date</label>
          </span>
        </div>
      </div>
      <div class="p-fluid grid">
        <div class="field col-12 md:col-4">
          <span class="p-float-label pl-2">
            <Dropdown id="dropdown" v-model="poDraftType" :options="poTypeList" optionLabel="name"
              optionValue="value" />
            <label for="dropdown">Planning Order Type</label>
          </span>
        </div>
        <div class="field col-12 md:col-4">
          <span class="p-float-label pl-2">
            <Calendar id="mustdate" v-model="draftMustDate" :showIcon="true" dateFormat="dd-M-yy" />
            <label for="mustdate">Must Response Date</label>
          </span>
        </div>
      </div>
    </div>
    <DataTable :value="draftTableList" responsiveLayout="scroll" :loading="loading" :scrollable="true"
      scrollHeight="600px" scrollDirection="both">
      <template #header>
        <div class="table-header-container">
          <h5 class="{color: black}">Draft</h5>
        </div>
      </template>
      <Column class="call-button-container" header="Company" field="company" style="width: 6%"></Column>
      <Column class="call-button-container" header="Supplier" field="vendor_name" style="width: 6%"></Column>
      <Column class="call-button-container" header="PR Number" field="pr_num_and_line" style="width: 6%"></Column>
      <Column class="call-button-container" header="Item Number" field="item_num" style="width: 6%"></Column>
      <Column class="call-button-container" header="Item Description" field="item_desc" style="width: 10%"></Column>
      <!----YANG LAGI DI DEVELOP-->
      <Column class="call-button-container" header="No GBPA" field="gbpa_no" style="width: 10%">
        <template #body="props">
          <AutoComplete v-model="props.data.gbpa_no" dropdown @item-select="check($event, props.index)"
            :suggestions="filterGBPAVal" @complete="searchGBPA($event, props.data.item_id)" field="PO_NUM" />
        </template>
      </Column>
      <Column class="call-button-container centered-input" header="Qty" field="pr_qty" style="width: 6%">
        <template #body="slotProps">
          <InputNumber id="locale-german" v-model="slotProps.data.pr_qty"
            v-tooltip.right="`Original QTY: ${slotProps.data.ori_qty}`" locale="de-DE" :minFractionDigits="2" />
        </template>
      </Column>
      <Column header="UOM" field="plan_uom" style="width: 6%">
        <template #body="slotProps">
          <Dropdown v-model="slotProps.data.plan_uom" @change="onChange($event, slotProps.index)" :options="uomList"
            optionLabel="unit_of_measure" optionValue="unit_of_measure" style="width: 100%" />
        </template>
      </Column>
      <Column class="call-button-container" header="Currency" field="currency" style="width: 6%">
        <template #body="slotProps">
          <Dropdown v-model="slotProps.data.currency" :disabled="slotProps.data.gbpa_no && slotProps.data.gbpa_no !== '-No Select-'"
            :options="currenciesList" optionLabel="currency_code" optionValue="currency_code" style="width: 100%" />
        </template>
      </Column>
      <Column class="call-button-container" header="Price" field="plan_price" style="text-align: right; width: 7%">
        <template #body="slotProps">
          <InputNumber :disabled="slotProps.data.gbpa_no && slotProps.data.gbpa_no !== '-No Select-'" id="locale-german"
            v-model="slotProps.data.plan_price" locale="de-DE" :minFractionDigits="2" />
        </template>
      </Column>
      <Column class="call-button-container" header="Budget" field="budget_price" style="width: 5%"></Column>
      <Column class="call-button-container" header="Rate Currency" field="rate_currency"
        style="text-align: right; width: 7%">
        <template #body="slotProps">
          <InputNumber id="locale-german" :disabled="slotProps.data.gbpa_no && slotProps.data.gbpa_no !== '-No Select-'"
            v-model="slotProps.data.rate_currency" locale="de-DE" :minFractionDigits="2" />
        </template>
      </Column>
      <Column class="call-button-container" header="Deal Currency" field="deal_currency" style="width: 6%">
        <template #body="slotProps">
          <Dropdown :disabled="slotProps.data.gbpa_no && slotProps.data.gbpa_no !== '-No Select-'" v-model="slotProps.data.deal_currency"
            :options="currenciesList" optionLabel="currency_code" optionValue="currency_code" style="width: 100%" />
        </template>
      </Column>
      <!-- <Column class="call-button-container" header="Original Quantity" field="original_quantity" style="width: 6%">
        <template #body="slotProps">
          {{ slotProps.data.original_quantity ?? 0 }}
        </template>
      </Column> -->
      <Column class="call-button-container" header="Arrival at Site" field="need_by_date" style="width: 6%">
        <template #body="slotProps">
          <Calendar id="needByDate" v-model="slotProps.data.need_by_date" :style="{ width: '150px' }" :showIcon="true"
            dateFormat="dd-M-yy" />
          <!-- <Calendar :style="{width:'150px'}" id="buttonbar" v-model="slotProps.data.need_by_date" :showTime="true" :showSeconds="true" /> -->
        </template>
      </Column>
      <Column class="call-button-container" header="Ship To" field="deliver_to_code" style="width: 6%"></Column>
      <Column class="call-button-container-200" header="Manufacturer" field="manufacturer_id" style="width: 6%">
        <template #body="slotProps">
          <InputText type="type" v-model="slotProps.data.manufacturer_name" :style="{ width: '100px' }" disabled />
          <Button icon="pi pi-external-link" @click="
    openMaximizable(
      slotProps.data.manufacturer_id,
      slotProps.index,
      slotProps.data.asl_id
    )
    " />
        </template>
      </Column>
      <Column class="call-button-container" header="ToP" field="top" style="width: 6%">
        <template #body="slotProps">
          <Dropdown v-model="slotProps.data.terms_id" :options="topValue" optionLabel="name" optionValue="term_id" />
        </template>
      </Column>
      <Column class="call-button-container" header="Incoterm" field="fob" style="width: 6%">
        <template #body="slotProps">
          <Dropdown v-model="slotProps.data.fob" :options="fobList" optionLabel="name" optionValue="value" />
        </template>
      </Column>
      <Column class="call-button-container" header="Freight" field="freight_terms" style="width: 5%">
        <template #body="slotProps">
          <Dropdown v-model="slotProps.data.freight_terms" :options="freightList" optionLabel="name"
            optionValue="value" />
        </template>
      </Column>
      <Column class="call-button-container" header="Note To Supplier" field="note_to_supplier" style="width: 10%">
        <template #body="slotProps">
          <!-- <InputText type="text" v-model="slotProps.data.note_to_supplier" /> -->
          <Textarea v-model="slotProps.data.note_to_supplier" :autoResize="true" rows="2" :maxlength="100" cols="30" />
        </template>
      </Column>
      <Column class="call-button-container" header="Action" alignFrozen="right" frozen>
        <template #body="slotProps">
          <!-- <InputText type="text" v-model="slotProps.data.note_to_supplier" /> -->
          <Button icon="pi pi-trash" class="p-button-rounded p-button-danger mt-2"
            @click="deleteConfirmation(slotProps)" />
        </template>
      </Column>
    </DataTable>
    <div>
      <div style="text-align: right" class="pl-8 pt-3">
        <span class="pl-2">
          <Button label="Save" :disabled="saveButtonStatus" @click="saveDraft()" />
        </span>
        <span class="pl-2">
          <Button label="Submit" :disabled="submitButtonStatus" @click="pushSubmit()" />
        </span>
      </div>
    </div>
  </div>

  <Dialog header="Header" v-model:visible="displayMaximizable" :style="{ width: '80%' }" :maximizable="true"
    :modal="true">
    <DataTable :value="manufacturerAslList" v-model:selection="selectedManufaturer" dataKey="manufacturer_id"
      responsiveLayout="scroll" :scrollable="true" scrollHeight="flex">
      <Column selectionMode="single" headerStyle="width: 3em"></Column>
      <Column field="manufacturer_name" header="Name"></Column>
      <Column field="description" header="Description"></Column>
      <Column field="attribute1" header="Address"></Column>
    </DataTable>

    <template #footer>
      <Button label="Yes" icon="pi pi-check" @click="closeMaximizable" autofocus />
    </template>
  </Dialog>
</template>

<script>
/* eslint-disable */
import LovService from "../../service/LovService";
import CreatePoService from "../../service/CreatePoService";
import AuthService from "../../service/AuthService";
import SimpleCrypto from "simple-crypto-js";
import ProgressModal from "../components/ProgressModal.vue";
import { FunctionCreatePO } from "./functionCreatePO";

export default {
  components: { ProgressModal },
  data() {
    return {
      filterGBPAVal: null,
      gbpaNo: null,
      cost: 0,
      formatLang: "en-EN",
      formatStyle: "currency",
      formatCurrency: "USD",
      first: 0,
      reqTableList: null,
      companyList: null,
      fobList: null,
      freightList: null,
      currenciesList: null,
      uomList: null,
      poTypeList: null,
      filteredCompany: null,
      selectedCompany: null,
      supplierList: null,
      filteredSupplier: null,
      selectedSupplier: null,
      dbName: String,
      jitList: null,
      selectedJit: null,
      prNumList: [],
      manufacturerList: null,
      selectedPrNum: null,
      filteredPrNum: null,
      totalTableRecords: 1000,
      suppStatus: true,
      commStatus: false,
      loading: false,
      selectedReq: null,
      selectedManufaturer: null,
      searchButtonStatus: true,
      submitButtonStatus: true,
      saveButtonStatus: true,
      draftButtonStatus: true,
      saveDraftStatus: false,
      draftTableList: [],
      displayMaximizable: false,
      poDraftNum: null,
      poDraftType: null,
      poDraftDate: null,
      draftMustDate: null,
      clickStatus: false,
      manufacturerIdRow: null,
      manufacturerId: null,
      eventPage: null,
      pushStatus: false,
      priceList: {},
      poMaker: null,
      topValue: [],
      senderNotif: null,
      recipientEmail: null,
      payloadEmail: null,
      userReceiveId: null, //dari tabel spb_is_user bukan spb_usermapping atau cdid_tenant_user
      userSenderId: null,
      manufacturerAslList: null,
      pushSave: false,
      progress: false,
      totalData: null,
    };
  },

  watch: {
    selectedCompany(event) {
      this.selectedSupplier = null;
      this.selectedJit = null;
      this.selectedPrNum = null;
      if (
        typeof event === "object" &&
        !Array.isArray(event) &&
        event !== null
      ) {
        this.searchButtonStatus = false;
        this.getSuppName(event);
        this.getPrNumCom(event.name);
        this.suppStatus = false;
      } else {
        this.suppStatus = true;
        this.searchButtonStatus = true;
      }
    },
    selectedSupplier(event) {
      this.selectedJit = null;
      this.selectedPrNum = null;
      if (
        typeof event === "object" &&
        !Array.isArray(event) &&
        event !== null
      ) {
        this.searchButtonStatus = false;
        this.getPrNumSup();
      } else {
        // this.searchButtonStatus = true;
        this.draftButtonStatus = true;
      }
    },
    selectedJit() {
      if (this.selectedSupplier != null) {
        this.searchButtonStatus = false;
      } else {
        this.draftButtonStatus = true;
      }
    },
    selectedPrNum(event) {
      if (event !== null) {
        this.searchButtonStatus = false;
        if (this.selectedSupplier == null) {
          this.draftButtonStatus = false;
        }
      } else {
        // this.searchButtonStatus = true;
      }
    },
    draftTableList: {
      handler(newValue) {
        if (this.draftTableList != null) {
          this.submitButtonStatus = false;
          this.saveButtonStatus = false;
        } else if (
          this.draftTableList != null &&
          this.draftTableList.isArray() &&
          this.draftTableList.length > 0
        ) {
          this.submitButtonStatus = false;
          this.saveButtonStatus = false;
        } else {
          this.submitButtonStatus = true;
          this.saveButtonStatus = true;
        }
      },
    },
  },
  CreatePoService: null,
  created() {
    this.first = 0;
    this.loading = true;
    this.reqTableList = null;
    this.selectedCompany = null;
    this.selectedSupplier = null;
    this.selectedJit = null;
    this.selectedPrNum = null;
    this.searchButtonStatus = true;

    AuthService.whoAmi().then((resp) => {
      if (resp.status === 200) {
        // CEK EMAIL USERNYA DULU
        this.senderNotif = resp.data.data.bioemailactive;

        if (resp.data.data.userid == "dexaadmin") {
          CreatePoService.getCountAll().then((value) => {
            if (value.status === 200) {
              this.totalData = value.data.data[0].CNT;
              LovService.getValueType("COMPANY").then((value) => {
                if (value.status === 200) {
                  this.companyList = value.data.data;
                  CreatePoService.getPageTableFirst(0, 10).then((value) => {
                    // let reqTableListTmp = value.data.data[0];
                    if (value.data.status === 200) {
                      let reqTableListTmp = value.data.data[0];

                      for (const tableList of Object.keys(reqTableListTmp)) {
                        reqTableListTmp[tableList]["need_by_date"] =
                          this.formatDate(
                            new Date(reqTableListTmp[tableList]["need_by_date"])
                          )[0];
                      }
         
                      this.reqTableList = reqTableListTmp;
                      this.loading = false;
                    }
                  });
                }
              });
            }
          });
        } else {
          LovService.getcompanydb(resp.data.data.bioaddress).then((value) => {
            this.loading = true;
            this.companyList = value.data.data;
            try {
              const secretKey = "some-unique-key";
              const simpleCrypto = new SimpleCrypto(secretKey);
              const decipherText = simpleCrypto.decrypt(
                sessionStorage.getItem("dropdownCompany")
              );
              this.companyList.forEach((element) => {
                if (element.name === decipherText) {
                  this.selectedCompany = element;
                  let payload = {
                    org_id: this.selectedCompany.value,
                    db_instance: this.selectedCompany.db_instance,
                    first: 0,
                    last: 10,
                  };
                  this.setCompany(payload, true);
                  // this.loading = false
                  throw "Break";
                }
              });
            } catch (e) {
              if (e !== "Break") throw e;
            }
          });
        }

        // LovService.getValueType('FOB').then(value => {
        //     this.fobList = value.data.data;
        // });

        // LovService.getValueType('FREIGHT TERMS').then(value => {
        //     this.freightList = value.data.data;
        // });

        LovService.getValueType("TYPE").then((value) => {
          this.jitList = value.data.data;
        });

        LovService.getValueType("PO TYPE").then((value) => {
          this.poTypeList = value.data.data;
          this.poDraftType = this.poTypeList[0].name;
        });

        // CreatePoService.getAllCurrencies().then(value => {
        //     this.currenciesList = value.data.data;
        // });

        // CreatePoService.getAllUom().then(value => {
        //     this.uomList = value.data.data;
        // });

        CreatePoService.getAllPrnumber().then((value) => {
          this.prNumList = value.data.data;
        });
        this.loading = false;
      }
    });
  },
  methods: {
    check(event, idx) {
      let top = this.topValue.find(
        (x) => x.term_id === event["value"]["TERMS_ID"]
      );

      this.draftTableList[idx]['gbpa_no'] = event.value["PO_NUM"];
      this.draftTableList[idx]["currency"] = event.value["CURRENCY_CODE"];
      this.draftTableList[idx]["plan_price"] = event.value["UNIT_PRICE"];
      this.draftTableList[idx]["gbpa_header_id"] = event.value["GBPA_HEADER_ID"];
      this.draftTableList[idx]["gbpa_line_id"] = event.value["GBPA_LINE_ID"];
      this.draftTableList[idx]["deal_currency"] = event.value["CURRENCY_CODE"];
      this.draftTableList[idx]["rate_currency"] = event.value["ATTRIBUTE9"];
      this.draftTableList[idx]["original_quantity"] = event.value["ATTRIBUTE4"];

      if(top){
        this.draftTableList[idx]["top_name"] = top.name;
        this.draftTableList[idx]["terms_id"] = top.term_id;
      }

      console.log("TERMS ID", event)
      console.log("TOP VALUE", top);
      console.log("TABLE LIST", this.draftTableList)
    },
    searchGBPA(event, item) {
      if (!event.query.length) {
        this.filterGBPAVal = [...this.filterGBPA(item)];
      } else {
        this.filterGBPAVal = this.filterGBPA(item).filter((Q) => {
          return Q.PO_NUM.toLowerCase().match(event.query.toLowerCase());
        });
      }
    },
    filterGBPA(item) {
      const filteredData = this.gbpaNo.filter((Q) => Q["ITEM_ID"] === item);
      const resultGBPA = [{
        UNIT_PRICE: 0,
        ATTRIBUTE9: "",
        CURRENCY_CODE: "",
        PO_NUM: "-No Select-",
        ITEM_ID: 0
      }, ...filteredData]
      return resultGBPA;
    },
    onChange(event, index) {
      this.loading = true;
      let payload = {
        item_id: this.draftTableList[index].item_id,
        uom: this.draftTableList[index].uom,
        plan_uom: event.value,
        pr_qty: this.draftTableList[index].pr_qty,
      };
      CreatePoService.checkConversion(payload).then((value) => {
        // this.prNumList = value.data.data;
        if (value.data.data[0][0].conversion > 0) {
          this.draftTableList[index].uom = event.value;
          this.draftTableList[index].pr_qty = value.data.data[0][0].conversion;
        } else {
          alert("Conversion Not Found");
          // this.draftTableList[index].pr_qty = this.draftTableList[index].ori_qty
        }
      });
      this.loading = false;
    },
    pad2(n) {
      return (n < 10 ? "0" : "") + n;
    },
    formatDate(date) {
      var month = this.pad2(date.getMonth() + 1); //months (0-11)
      var day = this.pad2(date.getDate()); //day (1-31)
      var year;
      if (date.getFullYear() < 0) {
        year = date.getFullYear() * -1;
      } else {
        year = date.getFullYear();
      }
      var combinedNeedDate = new Date(month + "/" + day + "/" + year);
      let formattedDate = [];
      formattedDate[0] =
        combinedNeedDate.getDate() +
        "-" +
        combinedNeedDate.toString().substr(4, 3) +
        "-" +
        combinedNeedDate.getFullYear();
      formattedDate[1] =
        combinedNeedDate.getMonth() +
        1 +
        "/" +
        combinedNeedDate.getDate() +
        "/" +
        combinedNeedDate.getFullYear();

      return formattedDate;
    },
    formatDateDexa(d) {
      var date = new Date(d);
      var respondDate =
        ("00" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        ("00" + date.getDate()).slice(-2) +
        "/" +
        date.getFullYear() +
        " " +
        ("00" + date.getHours()).slice(-2) +
        ":" +
        ("00" + date.getMinutes()).slice(-2) +
        ":" +
        ("00" + date.getSeconds()).slice(-2);

      return respondDate;
    },
    reset() {
      this.first = 0;
      this.loading = true;
      this.reqTableList = null;
      this.selectedSupplier = null;
      this.selectedJit = null;
      this.selectedPrNum = null;
      this.searchButtonStatus = true;
      this.draftTableList = [];


      CreatePoService.getCountAll().then((value) => {
        if (value.status === 200) {
          this.reqTableList = Array.from({
            length: value.data.data[0].CNT,
          });
          LovService.getValueType("COMPANY").then((value) => {
            this.companyList = value.data.data;
          });

          LovService.getValueType("TYPE").then((value) => {
            this.jitList = value.data.data;
          });
          let payload = {
            org_id: this.selectedCompany.value,
            db_instance: this.selectedCompany.db_instance,
            first: 0,
            last: 10,
          };
          this.setCompany(payload, true);
          this.searchButtonStatus = false;
          this.loading = false;
        }
      });
    },
    setCompany(payload) {

      CreatePoService.countComSupp(payload).then((value) => {
        if (value.status === 200) {
          this.totalData = value.data.data[0][0].CNT
        }
      });

      CreatePoService.searchCompany(payload).then((value) => {
        let reqTableListTmp = value.data.data[0];
        if (value.data.status === 200) {

          for (const tableList of reqTableListTmp) {
            for (const company of this.companyList) {
              tableList["need_by_date"] = this.formatDate(
                new Date(tableList["need_by_date"])
              )[0];
              if (tableList["currency"] == "null") {
                tableList["currency"] = "";
              }

              if (
                tableList["org_id"] == company["value"] &&
                tableList["db_instance"] == company["db_instance"]
              ) {
                tableList["company"] = company["name"];
              }
            }
          }


          this.reqTableList = reqTableListTmp


          this.commStatus = true;
          CreatePoService.searchCompanyPrNum(payload).then((value) => {
            this.prNumList = value.data.data[0];
          });

        }
      });


      if (!payload.supplier_id) {
        this.loading = false
        return
      }

      payload["vendor_id"] = payload.supplier_id.vendor_id
      payload["vendor_name"] = payload.supplier_id.vendor_name

      delete payload.supplier_id;

      CreatePoService.getDraftComSupp(payload).then((resp) => {
        if (resp.status === 200) {
          resp.data.data.forEach((element) => {
            if (element["note_to_supplier"] == "null") {
              element["note_to_supplier"] = "";
            }
          });
          this.draftTableList = resp.data.data;

          if (this.userReceiveId === null) {
            this.userReceiveId = this.draftTableList[0].vendor_id;
          }
          this.draftTableList.forEach((element) => {
            if (element["need_by_date"] != null) {
              element["need_by_date"] = new Date(element["need_by_date"]);
            }

            element["pr_num_and_line"] =
              element["pr_num"] + "/" + element["pr_line_num"];
            let top = this.topValue.find(
              (x) => x.term_id === element["terms_id"]
            );
            // let tops = this.topValue.indexOf(top);
            // element["top_name"] = tops.name
            element["top_name"] = this.topValue.find(
              (value) => value?.term_id == element["terms_id"]
            )?.name;
            LovService.getManufacturerAsl(element["asl_id"]).then((value) => {
              if (
                value.data != null &&
                value.status === 200 &&
                value.data.status === 200
              ) {
                this.loading = true;
                if (value.data.data.length > 0) {
                  LovService.getManufacturerId(element["manufacturer_id"]).then(
                    (resp) => {
                      if (resp.status === 200) {
                        element["manufacturer_name"] =
                          resp.data.data[0].manufacturer_name;
                        element["manufacturer_address"] =
                          resp.data.data[0].attribute1;
                        element["manufacturer_description"] =
                          resp.data.data[0].description;
                      }
                    }
                  );
                }
              }
              this.loading = false;
            });
          });
          this.priceList = { ...this.draftTableList };
        }
      });

      this.loading = false;
    },
    setSupplier(payload) {
      this.loading = true;
      CreatePoService.countSupplier(payload).then((value) => {
        var length = value.data.data[0][0].CNT;
        if (value.status === 200 && length > 10) {
          this.reqTableList = Array.from({ length: length });
          CreatePoService.searchSupplier(payload).then((value) => {
            let reqTableListTmp = value.data.data[0].map((item, index) => {
              return { id: index, ...item };
            });

            if (value.data.status === 200) {
              for (const tableList of reqTableListTmp) {
                tableList["need_by_date"] = this.formatDate(
                  new Date(tableList["need_by_date"])
                )[0];
                if (tableList["currency"] == "null") {
                  tableList["currency"] = "";
                }
                // LovService.getManufacturerId(
                // 	tableList['manufacturer_id']
                // ).then((resp) => {
                // 	if (resp.status === 200) {
                // 		tableList['manufacturer_name'] =
                // 			resp.data.data[0].manufacturer_name;
                // 		tableList['manufacturer_address'] =
                // 			resp.data.data[0].attribute1;
                // 		tableList['manufacturer_description'] =
                // 			resp.data.data[0].description;
                // 	}
                // });
                for (const company of this.companyList) {
                  if (
                    tableList["org_id"] == company["value"] &&
                    tableList["db_instance"] == company["db_instance"]
                  ) {
                    tableList["company"] = company["name"];
                  }
                }
              }
              if (
                this.eventPage != null &&
                this.eventPage.first >= payload.first
              ) {
                Array.prototype.splice.apply(
                  this.reqTableList,
                  [payload.first, reqTableListTmp.length].concat(
                    reqTableListTmp
                  )
                );
              } else {
                Array.prototype.splice.apply(
                  this.reqTableList,
                  [0, reqTableListTmp.length].concat(reqTableListTmp)
                );
              }
              CreatePoService.searchSupplierPrNum(payload).then((value) => {
                this.prNumList = value.data.data[0];
              });
            }
          });
          this.loading = false;
        } else if (value.data.status === 200) {
          CreatePoService.searchSupplier(payload).then((value) => {
            this.reqTableList = value.data.data[0];
            console.log("reqTableList", this.reqTableList);
            for (const tableList of this.reqTableList) {
              tableList["need_by_date"] = this.formatDate(
                new Date(tableList["need_by_date"])
              )[0];
              if (tableList["currency"] == "null") {
                tableList["currency"] = "";
              }
              // LovService.getManufacturerId(
              // 	tableList['manufacturer_id']
              // ).then((resp) => {
              // 	if (resp.status === 200) {
              // 		tableList['manufacturer_name'] =
              // 			resp.data.data[0].manufacturer_name;
              // 		tableList['manufacturer_address'] =
              // 			resp.data.data[0].attribute1;
              // 		tableList['manufacturer_description'] =
              // 			resp.data.data[0].description;
              // 	}
              // });
              for (const company of this.companyList) {
                if (
                  tableList["org_id"] == company["value"] &&
                  tableList["db_instance"] == company["db_instance"]
                ) {
                  tableList["company"] = company["name"];
                }
              }
            }
            CreatePoService.searchSupplierPrNum(payload).then((value) => {
              this.prNumList = value.data.data[0];
            });
            this.loading = false;
          });
        }
      });


    },
    setSupplierJit(payload) {
      CreatePoService.countSupplierJit(payload).then((value) => {
        var length = value.data.data[0][0].CNT;

        if (value.status === 200 && length > 10) {
          this.reqTableList = Array.from({ length: length });
          CreatePoService.searchSupplierJit(payload).then((value) => {
            let reqTableListTmp = value.data.data[0];

            if (value.data.status === 200) {
              for (const tableList of reqTableListTmp) {
                tableList["need_by_date"] = this.formatDate(
                  new Date(tableList["need_by_date"])
                )[0];
                if (tableList["currency"] == "null") {
                  tableList["currency"] = "";
                }
                // LovService.getManufacturerId(
                // 	tableList['manufacturer_id']
                // ).then((resp) => {
                // 	if (resp.status === 200) {
                // 		tableList['manufacturer_name'] =
                // 			resp.data.data[0].manufacturer_name;
                // 		tableList['manufacturer_address'] =
                // 			resp.data.data[0].attribute1;
                // 		tableList['manufacturer_description'] =
                // 			resp.data.data[0].description;
                // 	}
                // });
                for (const company of this.companyList) {
                  if (
                    tableList["org_id"] == company["value"] &&
                    tableList["db_instance"] == company["db_instance"]
                  ) {
                    tableList["company"] = company["name"];
                  }
                }
              }
              if (
                this.eventPage != null &&
                this.eventPage.first >= payload.first
              ) {
                Array.prototype.splice.apply(
                  this.reqTableList,
                  [payload.first, reqTableListTmp.length].concat(
                    reqTableListTmp
                  )
                );
              } else {
                Array.prototype.splice.apply(
                  this.reqTableList,
                  [0, reqTableListTmp.length].concat(reqTableListTmp)
                );
              }
              CreatePoService.searchSupplierJitPrNum(payload).then((value) => {
                this.prNumList = value.data.data[0];
              });
            }
          });
          this.loading = false;
        } else if (value.data.status === 200) {
          CreatePoService.searchSupplierJit(payload).then((value) => {
            this.reqTableList = value.data.data[0];

            for (const tableList of this.reqTableList) {
              tableList["need_by_date"] = this.formatDate(
                new Date(tableList["need_by_date"])
              )[0];
              if (tableList["currency"] == "null") {
                tableList["currency"] = "";
              }
              // LovService.getManufacturerId(
              // 	tableList['manufacturer_id']
              // ).then((resp) => {
              // 	if (resp.status === 200) {
              // 		tableList['manufacturer_name'] =
              // 			resp.data.data[0].manufacturer_name;
              // 		tableList['manufacturer_address'] =
              // 			resp.data.data[0].attribute1;
              // 		tableList['manufacturer_description'] =
              // 			resp.data.data[0].description;
              // 	}
              // });
              for (const company of this.companyList) {
                if (
                  tableList["org_id"] == company["value"] &&
                  tableList["db_instance"] == company["db_instance"]
                ) {
                  tableList["company"] = company["name"];
                }
              }
            }
            CreatePoService.searchSupplierJitPrNum(payload).then((value) => {
              this.prNumList = value.data.data[0];
            });
            this.loading = false;
          });
        }
      });

      CreatePoService.getDraftComSuppJit(payload).then((resp) => {
        if (resp.status === 200) {
          resp.data.data.forEach((element) => {
            if (element["note_to_supplier"] == "null") {
              element["note_to_supplier"] = "";
            }
          });
          this.draftTableList = resp.data.data
          if (this.userReceiveId === null) {
            this.userReceiveId = this.draftTableList[0].vendor_id;
          }
          this.draftTableList.forEach((element) => {
            // element['plan_price'] = element['plan_price']
            // 	.toString()
            // 	.replace(/\D/g, '')
            // 	.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            // element['ori_qty'] = element['pr_qty']
            element["need_by_date"] = this.formatDate(
              new Date(element["need_by_date"])
            )[0];
            element["pr_num_and_line"] =
              element["pr_num"] + "/" + element["pr_line_num"];
            let top = this.topValue.find(
              (x) => x.term_id === element["terms_id"]
            );
            // let tops = this.topValue.indexOf(top);
            // element["top_name"] = tops.name
            element["top_name"] = this.topValue.find(
              (value) => value?.term_id == element["terms_id"]
            )?.name;
            LovService.getManufacturerAsl(element["asl_id"]).then((value) => {
              if (
                value.data != null &&
                value.status === 200 &&
                value.data.status === 200
              ) {
                if (value.data.data.length > 0) {
                  LovService.getManufacturerId(element["manufacturer_id"]).then(
                    (resp) => {
                      if (resp.status === 200) {
                        element["manufacturer_name"] =
                          resp.data.data[0].manufacturer_name;
                        element["manufacturer_address"] =
                          resp.data.data[0].attribute1;
                        element["manufacturer_description"] =
                          resp.data.data[0].description;
                      }
                    }
                  );
                }
              }
            });
          });

          this.priceList = { ...this.draftTableList };
        }
      });
    },
    searchAll(payload) {
      CreatePoService.countAll(payload).then((value) => {
        var length = value.data.data[0][0].CNT;
        if (value.status === 200 && length > 10) {
          this.reqTableList = Array.from({ length: length });
          CreatePoService.searchAll(payload).then((value) => {
            let reqTableListTmp = value.data.data[0];

            if (value.data.status === 200) {
              for (const tableList of reqTableListTmp) {
                tableList["need_by_date"] = this.formatDate(
                  new Date(tableList["need_by_date"])
                )[0];
                if (tableList["currency"] == "null") {
                  tableList["currency"] = "";
                }
                // LovService.getManufacturerId(
                // 	element['manufacturer_id']
                // ).then((resp) => {
                // 	if (resp.status === 200) {
                // 		tableList['manufacturer_name'] =
                // 			resp.data.data[0].manufacturer_name;
                // 		tableList['manufacturer_address'] =
                // 			resp.data.data[0].attribute1;
                // 		tableList['manufacturer_description'] =
                // 			resp.data.data[0].description;
                // 	}
                // });
                for (const company of this.companyList) {
                  if (
                    tableList["org_id"] == company["value"] &&
                    tableList["db_instance"] == company["db_instance"]
                  ) {
                    tableList["company"] = company["name"];
                  }
                }
              }
              if (
                this.eventPage != null &&
                this.eventPage.first >= payload.first
              ) {
                Array.prototype.splice.apply(
                  this.reqTableList,
                  [payload.first, reqTableListTmp.length].concat(
                    reqTableListTmp
                  )
                );
              } else {
                Array.prototype.splice.apply(
                  this.reqTableList,
                  [0, reqTableListTmp.length].concat(reqTableListTmp)
                );
              }
              CreatePoService.searchAllPrNum(payload).then((value) => {
                this.prNumList = value.data.data[0];
              });
            }
          });
          this.loading = false;
        } else if (value.data.status === 200) {
          CreatePoService.searchAll(payload).then((value) => {
            this.reqTableList = value.data.data[0];

            for (const tableList of this.reqTableList) {
              tableList["need_by_date"] = this.formatDate(
                new Date(tableList["need_by_date"])
              )[0];
              if (tableList["currency"] == "null") {
                tableList["currency"] = "";
              }
              // LovService.getManufacturerId(
              // 	tableList['manufacturer_id']
              // ).then((resp) => {
              // 	if (resp.status === 200) {
              // 		tableList['manufacturer_name'] =
              // 			resp.data.data[0].manufacturer_name;
              // 		tableList['manufacturer_address'] =
              // 			resp.data.data[0].attribute1;
              // 		tableList['manufacturer_description'] =
              // 			resp.data.data[0].description;
              // 	}
              // });
              for (const company of this.companyList) {
                if (
                  tableList["org_id"] == company["value"] &&
                  tableList["db_instance"] == company["db_instance"]
                ) {
                  tableList["company"] = company["name"];
                }
              }
            }
            CreatePoService.searchAllPrNum(payload).then((value) => {
              this.prNumList = value.data.data[0];
            });
            this.loading = false;
          });
        }
      });

      CreatePoService.getDraftComSuppPrJit(payload).then((resp) => {
        if (resp.status === 200) {
          resp.data.data.forEach((element) => {
            if (element["note_to_supplier"] == "null") {
              element["note_to_supplier"] = "";
            }
          });
          this.draftTableList = resp.data.data;
          if (this.userReceiveId === null) {
            this.userReceiveId = this.draftTableList[0].vendor_id;
          }
          this.draftTableList.forEach((element) => {
            // element['plan_price'] = element['plan_price']
            // 	.toString()
            // 	.replace(/\D/g, '')
            // 	.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            // element['ori_qty'] = element['pr_qty']
            element["need_by_date"] = this.formatDate(
              new Date(element["need_by_date"])
            )[0];
            element["pr_num_and_line"] =
              element["pr_num"] + "/" + element["pr_line_num"];
            let top = this.topValue.find(
              (x) => x.term_id === element["terms_id"]
            );
            // let tops = this.topValue.indexOf(top);
            // element["top_name"] = tops.name
            element["top_name"] = this.topValue.find(
              (value) => value?.term_id == element["terms_id"]
            )?.name;
            LovService.getManufacturerAsl(element["asl_id"]).then((value) => {
              if (
                value.data != null &&
                value.status === 200 &&
                value.data.status === 200
              ) {
                if (value.data.data.length > 0) {
                  LovService.getManufacturerId(element["manufacturer_id"]).then(
                    (resp) => {
                      if (resp.status === 200) {
                        element["manufacturer_name"] =
                          resp.data.data[0].manufacturer_name;
                        element["manufacturer_address"] =
                          resp.data.data[0].attribute1;
                        element["manufacturer_description"] =
                          resp.data.data[0].description;
                      }
                    }
                  );
                }
              }
            });
          });

          this.priceList = { ...this.draftTableList };
        }
      });
    },
    setComSupJit(payload) {
      CreatePoService.countComSupJit(payload).then((value) => {
        var length = value.data.data[0][0].CNT;
        if (value.status === 200 && length > 10) {
          this.reqTableList = Array.from({ length: length });
          CreatePoService.searchComSupJit(payload).then((value) => {
            let reqTableListTmp = value.data.data[0];

            if (value.data.status === 200) {
              for (const tableList of reqTableListTmp) {
                tableList["need_by_date"] = this.formatDate(
                  new Date(tableList["need_by_date"])
                )[0];
                if (tableList["currency"] == "null") {
                  tableList["currency"] = "";
                }
                // LovService.getManufacturerId(
                // 	tableList['manufacturer_id']
                // ).then((resp) => {
                // 	if (resp.status === 200) {
                // 		tableList['manufacturer_name'] =
                // 			resp.data.data[0].manufacturer_name;
                // 		tableList['manufacturer_address'] =
                // 			resp.data.data[0].attribute1;
                // 		tableList['manufacturer_description'] =
                // 			resp.data.data[0].description;
                // 	}
                // });
                for (const company of this.companyList) {
                  if (
                    tableList["org_id"] == company["value"] &&
                    tableList["db_instance"] == company["db_instance"]
                  ) {
                    tableList["company"] = company["name"];
                  }
                }
              }
              if (
                this.eventPage != null &&
                this.eventPage.first >= payload.first
              ) {
                Array.prototype.splice.apply(
                  this.reqTableList,
                  [payload.first, reqTableListTmp.length].concat(
                    reqTableListTmp
                  )
                );
              } else {
                Array.prototype.splice.apply(
                  this.reqTableList,
                  [0, reqTableListTmp.length].concat(reqTableListTmp)
                );
              }
            }
          });
          this.loading = false;
        } else if (value.data.status === 200) {
          CreatePoService.searchComSupJit(payload).then((value) => {
            this.reqTableList = value.data.data[0];

            for (const tableList of this.reqTableList) {
              tableList["need_by_date"] = this.formatDate(
                new Date(tableList["need_by_date"])
              )[0];
              if (tableList["currency"] == "null") {
                tableList["currency"] = "";
              }
              // LovService.getManufacturerId(
              // 	tableList['manufacturer_id']
              // ).then((resp) => {
              // 	if (resp.status === 200) {
              // 		tableList['manufacturer_name'] =
              // 			resp.data.data[0].manufacturer_name;
              // 		tableList['manufacturer_address'] =
              // 			resp.data.data[0].attribute1;
              // 		tableList['manufacturer_description'] =
              // 			resp.data.data[0].description;
              // 	}
              // });
              for (const company of this.companyList) {
                if (
                  tableList["org_id"] == company["value"] &&
                  tableList["db_instance"] == company["db_instance"]
                ) {
                  tableList["company"] = company["name"];
                }
              }
            }

            this.loading = false;
          });
        }
      });

      CreatePoService.getDraftComSuppPr(payload).then((resp) => {
        if (resp.status === 200) {
          resp.data.data.forEach((element) => {
            if (element["note_to_supplier"] == "null") {
              element["note_to_supplier"] = "";
            }
          });
          this.draftTableList = resp.data.data;
          if (this.userReceiveId === null) {
            this.userReceiveId = this.draftTableList[0].vendor_id;
          }
          this.draftTableList.forEach((element) => {
            // if (element['plan_price'] != null) {
            // 	element['plan_price'] = element['plan_price']
            // 	.toString()
            // 	.replace(/\D/g, '')
            // 	.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            // }
            // element['ori_qty'] = element['pr_qty']
            element["need_by_date"] = this.formatDate(
              new Date(element["need_by_date"])
            )[0];
            element["pr_num_and_line"] =
              element["pr_num"] + "/" + element["pr_line_num"];
            let top = this.topValue.find(
              (x) => x.term_id === element["terms_id"]
            );
            // let tops = this.topValue.indexOf(top);
            // element["top_name"] = tops.name
            element["top_name"] = this.topValue.find(
              (value) => value?.term_id == element["terms_id"]
            )?.name;
            LovService.getManufacturerAsl(element["asl_id"]).then((value) => {
              if (
                value.data != null &&
                value.status === 200 &&
                value.data.status === 200
              ) {
                if (value.data.data.length > 0) {
                  LovService.getManufacturerId(element["manufacturer_id"]).then(
                    (resp) => {
                      if (resp.status === 200) {
                        element["manufacturer_name"] =
                          resp.data.data[0].manufacturer_name;
                        element["manufacturer_address"] =
                          resp.data.data[0].attribute1;
                        element["manufacturer_description"] =
                          resp.data.data[0].description;
                      }
                    }
                  );
                }
              }
            });
          });

          this.priceList = { ...this.draftTableList };
        }
      });
    },
    setCommPr(payload) {
      CreatePoService.countCommPr(payload).then((value) => {
        var length = value.data.data[0][0].CNT;
        if (value.status === 200 && length > 10) {
          this.reqTableList = Array.from({ length: length });
          CreatePoService.searchCommPr(payload).then((value) => {
            let reqTableListTmp = value.data.data[0];

            if (value.data.status === 200) {
              for (const tableList of reqTableListTmp) {
                tableList["need_by_date"] = this.formatDate(
                  new Date(tableList["need_by_date"])
                )[0];
                if (tableList["currency"] == "null") {
                  tableList["currency"] = "";
                }
                // LovService.getManufacturerId(
                // 	tableList['manufacturer_id']
                // ).then((resp) => {
                // 	if (resp.status === 200) {
                // 		tableList['manufacturer_name'] =
                // 			resp.data.data[0].manufacturer_name;
                // 		tableList['manufacturer_address'] =
                // 			resp.data.data[0].attribute1;
                // 		tableList['manufacturer_description'] =
                // 			resp.data.data[0].description;
                // 	}
                // });
                for (const company of this.companyList) {
                  if (
                    tableList["org_id"] == company["value"] &&
                    tableList["db_instance"] == company["db_instance"]
                  ) {
                    tableList["company"] = company["name"];
                  }
                }
              }
              if (
                this.eventPage != null &&
                this.eventPage.first >= payload.first
              ) {
                Array.prototype.splice.apply(
                  this.reqTableList,
                  [payload.first, reqTableListTmp.length].concat(
                    reqTableListTmp
                  )
                );
              } else {
                Array.prototype.splice.apply(
                  this.reqTableList,
                  [0, reqTableListTmp.length].concat(reqTableListTmp)
                );
              }
            }
            this.loading = false;
          });
        } else if (value.data.status === 200) {
          CreatePoService.searchCommPr(payload).then((value) => {
            this.reqTableList = value.data.data[0];

            for (const tableList of this.reqTableList) {
              tableList["need_by_date"] = this.formatDate(
                new Date(tableList["need_by_date"])
              )[0];
              if (tableList["currency"] == "null") {
                tableList["currency"] = "";
              }
              for (const company of this.companyList) {
                if (
                  tableList["org_id"] == company["value"] &&
                  tableList["db_instance"] == company["db_instance"]
                ) {
                  tableList["company"] = company["name"];
                }
              }
            }
            this.loading = false;
          });
        }
      });
    },
    setCommJit(payload) {
      CreatePoService.countCommJit(payload).then((value) => {
        var length = value.data.data[0][0].CNT;
        if (value.status === 200 && length > 10) {
          this.reqTableList = Array.from({ length: length });
          CreatePoService.searchCommJit(payload).then((value) => {
            let reqTableListTmp = value.data.data[0];

            if (value.data.status === 200) {
              for (const tableList of reqTableListTmp) {
                tableList["need_by_date"] = this.formatDate(
                  new Date(tableList["need_by_date"])
                )[0];
                if (tableList["currency"] == "null") {
                  tableList["currency"] = "";
                }
                // LovService.getManufacturerId(
                // 	tableList['manufacturer_id']
                // ).then((resp) => {
                // 	if (resp.status === 200) {
                // 		tableList['manufacturer_name'] =
                // 			resp.data.data[0].manufacturer_name;
                // 		tableList['manufacturer_address'] =
                // 			resp.data.data[0].attribute1;
                // 		tableList['manufacturer_description'] =
                // 			resp.data.data[0].description;
                // 	}
                // });
                for (const company of this.companyList) {
                  if (
                    tableList["org_id"] == company["value"] &&
                    tableList["db_instance"] == company["db_instance"]
                  ) {
                    tableList["company"] = company["name"];
                  }
                }
              }
              if (
                this.eventPage != null &&
                this.eventPage.first >= payload.first
              ) {
                Array.prototype.splice.apply(
                  this.reqTableList,
                  [payload.first, reqTableListTmp.length].concat(
                    reqTableListTmp
                  )
                );
              } else {
                Array.prototype.splice.apply(
                  this.reqTableList,
                  [0, reqTableListTmp.length].concat(reqTableListTmp)
                );
              }
            }
          });
          this.loading = false;
        } else if (value.data.status === 200) {
          CreatePoService.searchCommJit(payload).then((value) => {
            this.reqTableList = value.data.data[0];

            for (const tableList of this.reqTableList) {
              tableList["need_by_date"] = this.formatDate(
                new Date(tableList["need_by_date"])
              )[0];
              if (tableList["currency"] == "null") {
                tableList["currency"] = "";
              }
              // LovService.getManufacturerId(
              // 	tableList['manufacturer_id']
              // ).then((resp) => {
              // 	if (resp.status === 200) {
              // 		tableList['manufacturer_name'] =
              // 			resp.data.data[0].manufacturer_name;
              // 		tableList['manufacturer_address'] =
              // 			resp.data.data[0].attribute1;
              // 		tableList['manufacturer_description'] =
              // 			resp.data.data[0].description;
              // 	}
              // });
              for (const company of this.companyList) {
                if (
                  tableList["org_id"] == company["value"] &&
                  tableList["db_instance"] == company["db_instance"]
                ) {
                  tableList["company"] = company["name"];
                }
              }
            }

            this.loading = false;
          });
        }
        CreatePoService.searchCommJitPrNum(payload).then((value) => {
          this.prNumList = value.data.data[0];
        });
      });
    },
    setJit(payload) {
      var length;
      CreatePoService.countTableJitAll(payload).then((value) => {
        if (value.status === 200) {
          length = value.data.data[0][0].CNT;
          this.reqTableList = Array.from({
            length: value.data.data[0][0].CNT,
          });
        }
      });
      CreatePoService.searchJit(payload).then((value) => {
        let reqTableListTmp = value.data.data[0];
        if (value.data.status === 200 && length > 10) {
          for (const tableList of reqTableListTmp) {
            tableList["need_by_date"] = this.formatDate(
              new Date(tableList["need_by_date"])
            )[0];
            if (tableList["currency"] == "null") {
              tableList["currency"] = "";
            }
            // LovService.getManufacturerId(
            // 	tableList['manufacturer_id']
            // ).then((resp) => {
            // 	if (resp.status === 200) {
            // 		tableList['manufacturer_name'] =
            // 			resp.data.data[0].manufacturer_name;
            // 		tableList['manufacturer_address'] =
            // 			resp.data.data[0].attribute1;
            // 		tableList['manufacturer_description'] =
            // 			resp.data.data[0].description;
            // 	}
            // });
            for (const company of this.companyList) {
              if (
                tableList["org_id"] == company["value"] &&
                tableList["db_instance"] == company["db_instance"]
              ) {
                tableList["company"] = company["name"];
              }
            }
          }
          if (this.eventPage != null && this.eventPage.first >= payload.first) {
            Array.prototype.splice.apply(
              this.reqTableList,
              [payload.first, reqTableListTmp.length].concat(reqTableListTmp)
            );
          } else {
            Array.prototype.splice.apply(
              this.reqTableList,
              [0, reqTableListTmp.length].concat(reqTableListTmp)
            );
          }
          CreatePoService.searchJitPrNum(payload).then((value) => {
            this.prNumList = value.data.data[0];
          });
        } else if (value.data.status === 200) {
          CreatePoService.searchJit(payload).then((value) => {
            this.reqTableList = value.data.data[0];

            for (const tableList of this.reqTableList) {
              tableList["need_by_date"] = this.formatDate(
                new Date(tableList["need_by_date"])
              )[0];
              if (tableList["currency"] == "null") {
                tableList["currency"] = "";
              }
              // LovService.getManufacturerId(
              // 	tableList['manufacturer_id']
              // ).then((resp) => {
              // 	if (resp.status === 200) {
              // 		tableList['manufacturer_name'] =
              // 			resp.data.data[0].manufacturer_name;
              // 		tableList['manufacturer_address'] =
              // 			resp.data.data[0].attribute1;
              // 		tableList['manufacturer_description'] =
              // 			resp.data.data[0].description;
              // 	}
              // });
              for (const company of this.companyList) {
                if (
                  tableList["org_id"] == company["value"] &&
                  tableList["db_instance"] == company["db_instance"]
                ) {
                  tableList["company"] = company["name"];
                }
              }
            }

            this.loading = false;
          });
        }
        CreatePoService.searchCommJitPrNum(payload).then((value) => {
          this.prNumList = value.data.data[0];
        });
      });

      this.loading = false;
    },
    setPrNum(payload) {
      var length;
      CreatePoService.countTablePrNum(payload).then((value) => {
        if (value.status === 200) {
          length = value.data.data[0][0].CNT;
          this.reqTableList = Array.from({
            length: value.data.data[0][0].CNT,
          });
        }
      });
      CreatePoService.searchPrNum(payload).then((value) => {
        let reqTableListTmp = value.data.data[0];
        if (value.data.status === 200 && length > 10) {
          for (const tableList of reqTableListTmp) {
            tableList["need_by_date"] = this.formatDate(
              new Date(tableList["need_by_date"])
            )[0];
            if (tableList["currency"] == "null") {
              tableList["currency"] = "";
            }
            // LovService.getManufacturerId(
            // 	tableList['manufacturer_id']
            // ).then((resp) => {
            // 	if (resp.status === 200) {
            // 		tableList['manufacturer_name'] =
            // 			resp.data.data[0].manufacturer_name;
            // 		tableList['manufacturer_address'] =
            // 			resp.data.data[0].attribute1;
            // 		tableList['manufacturer_description'] =
            // 			resp.data.data[0].description;
            // 	}
            // });
            for (const company of this.companyList) {
              if (
                tableList["org_id"] == company["value"] &&
                tableList["db_instance"] == company["db_instance"]
              ) {
                tableList["company"] = company["name"];
              }
            }
          }
          if (this.eventPage != null && this.eventPage.first >= payload.first) {
            Array.prototype.splice.apply(
              this.reqTableList,
              [payload.first, reqTableListTmp.length].concat(reqTableListTmp)
            );
          } else {
            Array.prototype.splice.apply(
              this.reqTableList,
              [0, reqTableListTmp.length].concat(reqTableListTmp)
            );
          }
        } else if (value.data.status === 200) {
          CreatePoService.searchPrNum(payload).then((value) => {
            this.reqTableList = value.data.data[0];

            for (const tableList of this.reqTableList) {
              tableList["need_by_date"] = this.formatDate(
                new Date(tableList["need_by_date"])
              )[0];
              if (tableList["currency"] == "null") {
                tableList["currency"] = "";
              }
              for (const company of this.companyList) {
                if (
                  tableList["org_id"] == company["value"] &&
                  tableList["db_instance"] == company["db_instance"]
                ) {
                  tableList["company"] = company["name"];
                }
              }
            }

            this.loading = false;
          });
        }
      });

      this.loading = false;
    },
    setJitPrNum(payload) {
      var length;
      CreatePoService.countTableJitPrNum(payload).then((value) => {
        if (value.status === 200) {
          length = value.data.data[0][0].CNT;
          this.reqTableList = Array.from({
            length: value.data.data[0][0].CNT,
          });
        }
      });
      CreatePoService.searchJitPrNumCom(payload).then((value) => {
        let reqTableListTmp = value.data.data[0];
        if (value.data.status === 200 && length > 10) {
          for (const tableList of reqTableListTmp) {
            tableList["need_by_date"] = this.formatDate(
              new Date(tableList["need_by_date"])
            )[0];
            if (tableList["currency"] == "null") {
              tableList["currency"] = "";
            }
            // LovService.getManufacturerId(
            // 	tableList['manufacturer_id']
            // ).then((resp) => {
            // 	if (resp.status === 200) {
            // 		tableList['manufacturer_name'] =
            // 			resp.data.data[0].manufacturer_name;
            // 		tableList['manufacturer_address'] =
            // 			resp.data.data[0].attribute1;
            // 		tableList['manufacturer_description'] =
            // 			resp.data.data[0].description;
            // 	}
            // });
            for (const company of this.companyList) {
              if (
                tableList["org_id"] == company["value"] &&
                tableList["db_instance"] == company["db_instance"]
              ) {
                tableList["company"] = company["name"];
              }
            }
          }
          if (this.eventPage != null && this.eventPage.first >= payload.first) {
            Array.prototype.splice.apply(
              this.reqTableList,
              [payload.first, reqTableListTmp.length].concat(reqTableListTmp)
            );
          } else {
            Array.prototype.splice.apply(
              this.reqTableList,
              [0, reqTableListTmp.length].concat(reqTableListTmp)
            );
          }
        } else if (value.data.status === 200) {
          CreatePoService.searchJitPrNumCom(payload).then((value) => {
            this.reqTableList = value.data.data[0];

            for (const tableList of this.reqTableList) {
              tableList["need_by_date"] = this.formatDate(
                new Date(tableList["need_by_date"])
              )[0];
              if (tableList["currency"] == "null") {
                tableList["currency"] = "";
              }
              for (const company of this.companyList) {
                if (
                  tableList["org_id"] == company["value"] &&
                  tableList["db_instance"] == company["db_instance"]
                ) {
                  tableList["company"] = company["name"];
                }
              }
            }

            this.loading = false;
          });
        }
      });

      this.loading = false;
    },
    setComJitPrNum(payload) {
      var length;
      CreatePoService.countTableComJitPrNum(payload).then((value) => {
        if (value.status === 200) {
          length = value.data.data[0][0].CNT;
          this.reqTableList = Array.from({
            length: value.data.data[0][0].CNT,
          });
        }
      });
      CreatePoService.searchComJitPrNum(payload).then((value) => {
        let reqTableListTmp = value.data.data[0];
        if (value.data.status === 200 && length > 10) {
          for (const tableList of reqTableListTmp) {
            tableList["need_by_date"] = this.formatDate(
              new Date(tableList["need_by_date"])
            )[0];
            if (tableList["currency"] == "null") {
              tableList["currency"] = "";
            }
            // LovService.getManufacturerId(
            // 	tableList['manufacturer_id']
            // ).then((resp) => {
            // 	if (resp.status === 200) {
            // 		tableList['manufacturer_name'] =
            // 			resp.data.data[0].manufacturer_name;
            // 		tableList['manufacturer_address'] =
            // 			resp.data.data[0].attribute1;
            // 		tableList['manufacturer_description'] =
            // 			resp.data.data[0].description;
            // 	}
            // });
            for (const company of this.companyList) {
              if (
                tableList["org_id"] == company["value"] &&
                tableList["db_instance"] == company["db_instance"]
              ) {
                tableList["company"] = company["name"];
              }
            }
          }
          if (this.eventPage != null && this.eventPage.first >= payload.first) {
            Array.prototype.splice.apply(
              this.reqTableList,
              [payload.first, reqTableListTmp.length].concat(reqTableListTmp)
            );
          } else {
            Array.prototype.splice.apply(
              this.reqTableList,
              [0, reqTableListTmp.length].concat(reqTableListTmp)
            );
          }
        } else if (value.data.status === 200) {
          CreatePoService.searchComJitPrNum(payload).then((value) => {
            this.reqTableList = value.data.data[0];

            for (const tableList of this.reqTableList) {
              tableList["need_by_date"] = this.formatDate(
                new Date(tableList["need_by_date"])
              )[0];
              if (tableList["currency"] == "null") {
                tableList["currency"] = "";
              }
              for (const company of this.companyList) {
                if (
                  tableList["org_id"] == company["value"] &&
                  tableList["db_instance"] == company["db_instance"]
                ) {
                  tableList["company"] = company["name"];
                }
              }
            }

            this.loading = false;
          });
        }
      });

      this.loading = false;
    },
    search() {
      // this.loading = true;
      this.first = 0;
      this.draftButtonStatus = false;
      this.draftTableList = null;
      this.selectedReq = null;
      LovService.getTop(this.selectedCompany.db_instance).then((resp) => {
        if (resp.data.status === 200) {
          this.topValue = resp.data.data;
        }
      });
      LovService.getFob(this.selectedCompany.db_instance).then((resp) => {
        if (resp.data.status === 200) {
          this.fobList = resp.data.data;
        }
      });
      LovService.getFreight(this.selectedCompany.db_instance).then((resp) => {
        if (resp.data.status === 200) {
          this.freightList = resp.data.data;
        }
      });
      CreatePoService.getAllCurrencies(this.selectedCompany.db_instance).then(
        (value) => {
          this.currenciesList = value.data.data;
        }
      );

      CreatePoService.getAllUom(this.selectedCompany.db_instance).then(
        (value) => {
          if (value.data.status === 200) {
            this.uomList = value.data.data;
          }
        }
      );

      //develop func GBPA
      if (this.selectedSupplier) {
        CreatePoService.getGBPA({
          vendor_id: this.selectedSupplier.vendor_id,
          db_instance: this.selectedCompany.db_instance,
        }).then((value) => {
          if (value.data.status === 200) {
            console.log(value.data, "VAL DAT");
            this.gbpaNo = value.data.data;
          }
        });
      }


      if (this.selectedSupplier) {
        this.userReceiveId = this.selectedSupplier.vendor_id;
        this.getManufacturerVendor(this.selectedSupplier.vendor_id);
      }

      this.loading = true;

      let payload = {
        org_id: this.selectedCompany.value,
        db_instance: this.selectedCompany.db_instance,
        first: 0,
        last: 10,
        jit: this.selectedJit,
        supplier_id: this.selectedSupplier,
        pr_num: this.selectedPrNum
      };

      this.setCompany(payload, true);
      this.generateDraftHead();

      this.loading = false;
    },
    onPage(event) {
      this.eventPage = event;
      this.loading = true;
      let payload = {
        org_id: this.selectedCompany.value,
        db_instance: this.selectedCompany.db_instance,
        supplier_id: this.selectedSupplier,
        pr_num: this.selectedPrNum,
        first: event.first,
        last: event.rows,
      };
      this.setCompany(payload);
    },
    searchCountry(event) {
      if (!event.query.length) {
        this.filteredCompany = [...this.companyList];
      } else {
        this.filteredCompany = this.companyList.filter((company) => {
          return company.name.toLowerCase().match(event.query.toLowerCase());
        });
      }
    },
    searchSupp(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredSupplier = [...this.supplierList];
        } else {
          this.filteredSupplier = this.supplierList.filter((supplier) => {
            if (supplier.vendor_name !== null) {
              return supplier.vendor_name
                .toLowerCase()
                .match(event.query.toLowerCase());
            }
          });
        }
      }, 250);
    },
    searchPrNum(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredPrNum = [...this.prNumList];
        } else {
          this.filteredPrNum = this.prNumList.filter((prNum) => {
            return prNum.pr_num
              .toString()
              .toLowerCase()
              .match(event.query.toLowerCase());
          });
          if (this.filteredPrNum.length <= 0) {
            this.filteredPrNum = event;
            this.selectedPrNum = {
              pr_num: event.query.toLowerCase(),
            };
          }
        }
      }, 250);
    },
    // getSupp(event){
    //     LovService.getDbName(event).then((orgall) => {
    //         if(orgall.data.status === 200) {
    //             this.dbName = JSON.stringify(orgall.data.data[0]["db_instance"]);
    //             this.dbName = this.dbName.replace(/"/g, '');
    //             this.getSuppName(this.dbName);
    //         }
    //     });
    // },
    getSuppName(event) {
      let payload = {
        db_instance: event.db_instance,
        org_id: event.value,
      };
      LovService.getSupplierName(payload).then((orgall) => {
        this.supplierList = orgall.data.data;
      });
    },

    getPrNumCom() {
      CreatePoService.getCommPrnumber(this.selectedCompany.value).then(
        (value) => {
          this.prNumList = value.data.data;
        }
      );
    },
    getPrNumSup() {
      let payloadMore = {
        org_id: this.selectedCompany.value,
        db_instance: this.selectedCompany.db_instance,
        vendor_name: this.selectedSupplier.vendor_name,
        vendor_id: this.selectedSupplier.vendor_id,
        first: 0,
        last: 10,
      };
      CreatePoService.searchSupplierPrNum(payloadMore).then((value) => {
        this.prNumList = value.data.data[0];
      });
    },
    deleteItem() {
      for (var i = 0; i < this.selectedReq.length; i++) {
        const index = this.reqTableList.indexOf(this.selectedReq[i]);
        this.reqTableList.splice(index, 1);
      }
    },
    deleteConfirmation(slotProps) {
      CreatePoService.deleteSaveDraft(slotProps.data).then((resp) => {
        if (resp.status == 200) {
          let addData = resp.data.data[0];
          addData["company"] = this.selectedCompany.name;
          this.reqTableList.push(addData);
          this.draftTableList.splice(slotProps.index, 1);
        }
      });

      // Array.prototype.push.apply(this.reqTableList,slotProps.data)

      // this.draftTableList = [...this.reqTableList,...this.draftTableList]
    },
    pushDraftB() {
      AuthService.whoAmi().then(async (resp) => {
        if (resp.status === 200) {
          if (
            this.selectedCompany !== null &&
            this.selectedSupplier !== null &&
            this.selectedReq !== null &&
            !this.clickStatus
          ) {
            console.log("save as draft");
            this.saveDraftStatus = false;
            this.clickStatus = true;
            LovService.getDetailValue(this.selectedCompany.name).then(
              (value) => {
                if (value.status === 200) {
                  this.generateDraftHead();
                }
              }
            );

            let select = FunctionCreatePO.pushDraftB(
              this.selectedReq,
              this.topValue
            );

            let payload = { select };

            CreatePoService.pushDraft(payload).then((resp) => {
              if (resp.status === 200) {
                for (const reqData of select) {
                  reqData["need_by_date"] = new Date(
                    this.formatDate(new Date(reqData["need_by_date"]))[0]
                  );

                  if (reqData["note_to_supplier"] == "null") {
                    reqData["note_to_supplier"] = "";
                  }
                }
                if (this.draftTableList !== null) {
                  Array.prototype.push.apply(this.draftTableList, select);
                  // this.draftTableList = [...this.draftTableList,...select]
                } else {
                  this.draftTableList = select;
                }

                this.deleteItem();
                select = null;
                this.selectedReq = null;
                this.priceList = { ...this.draftTableList };
              }
              // this.display = false;/,
            });
            this.clickStatus = false;
          } else {
            this.saveDraftStatus = true;
          }
        }
      });
    },
    saveDraft() {
      if (!this.pushSave) {
        this.saveButtonStatus = true;
        this.pushSave = true;

        const payload = this.draftTableList.map((item) => ({
          ...item,
          need_by_date: this.formatDate(new Date(item["need_by_date"]))[1],
        }));

        // console.log('cek date', this.draftTableList);

        CreatePoService.saveDraft(payload).then((resp) => {
          if (resp.status === 200) {
            this.$toast.add({
              severity: "success",
              summary: "Save Draft",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Draft Not Save, Please Check Your Connection",
              life: 3000,
            });
          }
          this.saveButtonStatus = false;
          this.pushSave = false;
        });
      }
    },
    addZeroValue(value, limit) {
      while (value.length < limit) {
        value = "0" + value;
      }
      return value;
    },
    generateDraftHead() {
      CreatePoService.getLastSeq(this.selectedCompany.value).then((resp) => {
        var planningOrderNoSeq;
        planningOrderNoSeq = (resp.data.data[0][0].seq + 1).toString();

        this.poMaker = [];
        this.poMaker.push(this.selectedCompany);
        // this.poDraftNum = this.selectedCompany.code+"22"+planningOrderNoSeq;

        var date = new Date();
        this.poDraftNum =
          this.selectedCompany.code +
          "" +
          this.addZeroValue(new String(date.getMonth() + 1), 2) +
          "" +
          new String(date.getFullYear()).slice(-2) +
          "" +
          this.addZeroValue(planningOrderNoSeq, 6);

        this.poDraftDate = new Date();

        this.draftMustDate = new Date(
          new Date().getTime() + 2 * 24 * 60 * 60 * 1000
        );
      });
    },
    pushSubmit() {
      this.progress = true;
      this.submitButtonStatus = true;
      this.saveButtonStatus = true;
      var pushSubmit = true;
      this.pushStatus = true;
      let user = this.draftTableList;
      var lineId;
      this.takePOsender = [];
      this.poMaker.forEach((element) => {
        this.takePOsender.push(element.name);
      });
      AuthService.whoAmi().then((resp) => {
        if (resp.status === 200) {
          this.userSenderId = resp.data.data.id;
          let payload = {
            po_number: this.poDraftNum,
            org_id: this.selectedCompany.value,
          };
          if (this.selectedCompany !== null && this.selectedSupplier !== null) {
            CreatePoService.checkPoNum(payload).then((response) => {
              if (response.data.status == 202) {
                CreatePoService.getLastPlannoLineId().then(async (orgall) => {
                  lineId = orgall.data.data[0].plan_order_line_id;
                  if (orgall.status === 200 && user != null) {
                    CreatePoService.getLastPlannoId().then(async (orgall) => {
                      for (let list of user) {
                        list["creation_date"] = this.formatDateDexa(new Date());
                        list["plan_order_id"] =
                          orgall.data.data[0]["plan_order_id"] + 1;
                        list["plan_order_num"] = this.poDraftNum;
                        list["created_by"] = sessionStorage.getItem("idUser");
                        list["plan_order_line_id"] = lineId++;
                        list["plan_order_date"] = this.formatDate(
                          new Date(this.poDraftDate)
                        )[1];
                        list["po_type"] = this.poDraftType;
                        list["response_date"] = this.formatDate(
                          new Date(this.draftMustDate)
                        )[1];
                        // if(list['need_by_date'] instanceof Date){
                        // list['need_by_date'] = list['need_by_date'].toLocaleDateString('en-US');
                        list["need_by_date"] = this.formatDate(
                          new Date(list["need_by_date"])
                        )[1];
                        list["status"] = "D";
                        list["plan_qty"] = list["pr_qty"];
                        list["confirm_price"] = list["plan_price"];
                        list["confirm_qty"] = list["pr_qty"];
                        delete list["ori_qty"];
                        if (list["manufacturer_name"] == null) {
                          pushSubmit = false;
                        }

                        // list['terms_id'] = list['top_name']
                        // }
                      }

                      if (payload !== null) {
                        this.payloadEmail = this.draftTableList;
                      }

                      if (pushSubmit) {
                        this.submitButtonStatus = false;
                        const dataEmail = {
                          module: "createpo",
                          companyname: this.selectedCompany.name,
                          vendorname: this.selectedSupplier.vendor_name,
                          sender: this.senderNotif,
                          user: this.payloadEmail,
                          idmodule: "1",
                          sbjmessage:
                            "New Planning Order #" + this.poDraftNum + " by ",
                          // receiveddate: new Date(),
                          status: "Open",
                          notif_type: "Supplier",
                          sender: this.senderNotif,
                          recipient: null,
                          usersenderid: this.userSenderId,
                          userreceiveid: this.userReceiveId,
                          dbInstance: this.selectedCompany.db_instance,
                          orgIdComp: this.selectedCompany.id,
                        };
                        const payload = {
                          headerList: user,
                          detailList: user,
                          data: user,
                          org_id: this.selectedCompany.value,
                          yearSeq: new Date().getFullYear(),
                          dataemail: dataEmail,
                          // datanotif : dataNotif
                        };
                        CreatePoService.pushSubmitDetail(payload).then(
                          async (resp) => {
                            if (resp.status === 200) {
                              this.progress = false;
                              this.draftTableList = [];
                              this.recipientEmail = null;
                              this.$toast.add({
                                severity: "success",
                                summary: "Supplier successfully notified!",
                                life: 3000,
                              });
                            } else {
                              this.progress = false;
                              this.$toast.add({
                                severity: "error",
                                summary: "Failed submit data",
                                life: 3000,
                              });
                            }
                          }
                        );
                      } else {
                        this.progress = false;
                        alert("Please Choose Manufacturer");
                        this.submitButtonStatus = false;
                        this.saveButtonStatus = false;
                      }
                    });
                  }
                });
              } else if (response.data.status == 200) {
                console.log(
                  "PO Number Already Exist, Auto generating new PO Number"
                );
                alert("PO Number Already exist, Auto generating new PO Number");
                CreatePoService.getLastPoNum(this.selectedCompany.value).then(
                  (resp) => {
                    if (resp.data.status === 200) {
                      let planno = resp.data.data[0].plan_order_num;
                      var planningOrderNoSeq;
                      planningOrderNoSeq = (
                        +planno.substring(planno.length - 3) + 1
                      ).toString();

                      this.poMaker = [];
                      this.poMaker.push(this.selectedCompany);
                      // this.poDraftNum = this.selectedCompany.code+"22"+planningOrderNoSeq;

                      var date = new Date();
                      this.poDraftNum =
                        this.selectedCompany.code +
                        "" +
                        this.addZeroValue(new String(date.getMonth() + 1), 2) +
                        "" +
                        new String(date.getFullYear()).slice(-2) +
                        "" +
                        this.addZeroValue(planningOrderNoSeq, 6);

                      this.poDraftDate = new Date();

                      this.draftMustDate = new Date(
                        new Date().getTime() + 2 * 24 * 60 * 60 * 1000
                      );
                      this.progress = false;
                      this.submitButtonStatus = false;
                      this.saveButtonStatus = false;
                      alert("Please click Submit again");
                    } else {
                      this.progress = false;
                      console.log("Error, Connection unstable");
                      alert("Error, Connection unstable");
                      this.submitButtonStatus = false;
                      this.saveButtonStatus = false;
                    }
                  }
                );
              } else if (response.data.status !== 200) {
                this.progress = false;
                console.log("Error, Connection unstable");
                alert("Error, Connection unstable");
                this.submitButtonStatus = false;
                this.saveButtonStatus = false;
              }
            });
          } else {
            this.progress = false;
            alert("Please Select Company and Supplier");
          }
        }
      });
    },
    getManufacturerVendor(vendor_id) {
      LovService.getManufacturerVendor(vendor_id).then((value) => {
        if (value.data.status === 200) {
          this.manufacturerList = value.data.data;
        } else {
          this.manufacturerList = [];
        }
      });
    },
    getManValue(index) {
      LovService.getManufacturerId(index).then((resp) => {
        if (resp.status === 200) {
          return resp.data.data[0].manufacturer_name;
        }
      });
    },
    openMaximizable(index, id, asl_id) {
      this.manufacturerIdRow = id;
      this.manufacturerId = index;
      this.manufacturerAslList = [];
      LovService.getManufacturerAsl(asl_id).then((value) => {
        if (value.data.status === 200) {
          this.manufacturerAslList = value.data.data;
          if (this.manufacturerAslList.length > 0) {
            LovService.getManufacturerId(index).then((resp) => {
              if (resp.status === 200) {
                this.selectedManufaturer = resp.data.data[0];
              }
            });
          }
        } else {
          this.manufacturerList = []
        }
      });

      this.displayMaximizable = true;
    },
    closeMaximizable() {
      if (this.selectedManufaturer != null) {
        this.draftTableList[this.manufacturerIdRow].manufacturer_id =
          this.selectedManufaturer.manufacturer_id;
        this.draftTableList[this.manufacturerIdRow].manufacturer_name =
          this.selectedManufaturer.manufacturer_name;
        this.draftTableList[this.manufacturerIdRow].manufacturer_address =
          this.selectedManufaturer.attribute1;
        this.draftTableList[this.manufacturerIdRow].manufacturer_description =
          this.selectedManufaturer.description;
        this.displayMaximizable = false;
      }
    },
    async processNotifEmail() {
      const data = {
        module: "createpo",
        companyname: this.selectedCompany.name,
        vendorname: this.selectedSupplier.vendor_name,
        sender: this.senderNotif,
        user: this.payloadEmail,
      };
      const resval = await CreatePoService.getPlannoRecipientMail(data);
      if (resval.data.status === 200) {
        // KIRIM NOTIFIKASI KE DASHBOARD
        const dataNotif = {
          idmodule: "1",
          sbjmessage:
            "New Planning Order #" +
            this.poDraftNum +
            " by " +
            this.takePOsender,
          // receiveddate: new Date(),
          status: "Open",
          notif_type: "Supplier",
          sender: this.senderNotif,
          recipient: resval.data.emails,
          usersenderid: this.userSenderId,
          userreceiveid: this.userReceiveId,
          db_instance: this.selectedCompany.db_instance,
          org_id: this.selectedCompany.id,
        };
        const value = await CreatePoService.sendPONotif(dataNotif);
        if (value.status === 200) {
          this.recipientEmail = null;
          this.$toast.add({
            severity: "success",
            summary: "Supplier successfully notified!",
            life: 3000,
          });
        }
      }
    },
    formatJit(data) {
      if (
        data !== null &&
        (data.jit_flag !== null || data.jit_flag !== undefined)
      ) {
        if (data.jit_flag === "Y") {
          return "JIT";
        } else if (data.jit_flag === "N") {
          return "NON JIT";
        }
      } else {
        return "";
      }
    },
  },
};
</script>
