<template>
	<Toast />
	<DataTable
		:value="tableList"
		:lazy="true"
		:paginator="true"
		:rows="10"
		:loading="loading"
		:totalRecords="totalData"
		@page="onPage($event)"
		@sort="onSort($event)"
		:rowsPerPageOptions="[10, 20, 30]"
		responsiveLayout="scroll"
		:scrollable="true"
		scrollHeight="600px"
		scrollDirection="both"
		:sortable="true"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #empty>No SRC found</template>
		<template #loading>Loading data</template>
		<Column header="Company" field="NAME" style="width: 15%"> </Column>
		<Column header="Supplier" field="vendor_name" style="width: 15%">
		</Column>
		<Column header="Item Number" field="item_number" style="width: 15%">
		</Column>
		<Column header="Item Description" field="item_desc" style="width: 25%">
		</Column>
		<Column header="Spec Version" field="spec_ver" style="width: 15%">
		</Column>
		<Column
			header="Effective Date"
			field="effective_date"
			style="width: 15%"
		>
		</Column>
		<Column header="Confirm Date" field="confirm_date" style="width: 15%">
		</Column>
		<Column header="Status" field="status" style="width: 15%"> </Column>
		<Column
			class="call-button-container"
			header="Actions"
			selectionMode="single"
			style="width: 15%"  alignFrozen="right" frozen
		>
			<template #body="slotProps">
				<Button
					icon="pi pi-eye"
					class="p-button-rounded p-button-text"
					@click="editChildren(slotProps.data)"
					v-tooltip="'Detail'"
				/>
			</template>
		</Column>
	</DataTable>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import AuthService from '@/service/AuthService';
// import { useToast } from 'primevue/usetoast';
import SpecSrc from '../../../service/SpecSrc';
import { useRouter } from 'vue-router';

const page = ref(1);
const rows = ref(10);
const tableList = ref();
const totalData = ref(0);
const router = useRouter();
const search = ref();
const loading = ref(false);
// eslint-disable-next-line no-undef
const props = defineProps({
	company: { type: Object },
	vendor_id: { type: Object },
	status: { type: Object },
	item_number: { type: Object },
	item_description: { type: Object },
	preparerId: { type: Object },
	searchTable: { type: Number },
});

onMounted(async () => {
	console.log('isi props,', props);
	if (props.searchTable > 0) {
		populateData();
	}
});

const populateData = async () => {
	const info = await AuthService.whoAmi();
	if (info.status === 200) {
		loading.value = true;
		const payload = {
			db_instance: props.company ? props.company.db_instance : null,
			org_id: props.company ? props.company.value : null,
			vendor_id: props.vendor_id ? props.vendor_id.vendor_id : null,
			status: props.status ? props.status.status : null,
			item_num: props.item_number ? props.item_number.item_number : null,
			item_description: props.item_description
				? props.item_description.item_desc
				: null,
			preparer: props.preparerId ? props.preparerId.user_id : null,
			search: search.value ? search.value : null,
			page: page.value,
			rows: rows.value,
		};
		console.log('payload', payload);
		const res = await SpecSrc.getTableData(payload);
		if (res == undefined || res.status === null) {
			setTimeout(async () => {
				await populateData();
			}, 1000);
		} else if (res.status === 200) {
			tableList.value = res.data.data;
			totalData.value = res.data.totalData[0].totalData;
			loading.value = false;
		} else {
			tableList.value = [];
			totalData.value = 0;
			loading.value = false;
		}
	} else {
		window.alert('User Expired');
	}
};

const editChildren = async (event) => {
	//
	router.push({
		name: 'EditSpecSRC',
		params: {
			id: event.SPEC_SRC_ID,
		},
	});
};

const onPage = async (event) => {
	rows.value = event.rows;
	page.value = event.page + 1;
	await populateData();
};

// const keydown = async () => {
//   await populateData()
// }
</script>
