import batchListApi from "./api/SpbBindBatchApi";
class ListNotaDebit {
    async getTableNota(payload){
        return batchListApi.post('spb/listnotadebit/getTable', payload);
    }
    async getDnNo(payload){
        return batchListApi.post('spb/listnotadebit/getDnNo', payload);
    }
    async getDetail(id){
        return batchListApi.get("spb/listnotadebit/" + id);
    }
    async getAttachmentFilePIC(payload) {
        return batchListApi.get(`/spb/listnotadebit/attachmentfile/${payload}`);
    }
    async getDebitFile(invoiceId, leveltenant) {
        return batchListApi.get(`/spb/listnotadebit/getdebitfile/${invoiceId}/${leveltenant}`);
    }
    async getReturnFile(invoiceId, leveltenant) {
        return batchListApi.get(`/spb/listnotadebit/getreturnfile/${invoiceId}/${leveltenant}`);
    }
}

export default new ListNotaDebit();