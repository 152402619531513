<template>
	<div v-if="props.searchAction && !props.poNumber" class="mb-4">
		<Message :closable="false"
			>This search result is limited to PO created this week. Please
			select the PO Number for wider and more specific data.</Message
		>
	</div>
	<DataTable
		:value="invoiceList"
		:lazy="true"
		:paginator="true"
		:rows="5"
		:loading="loading"
		:totalRecords="totalData"
		@page="onPage($event)"
		@sort="onSort($event)"
		:rowsPerPageOptions="[5, 10, 15, 25]"
		responsiveLayout="scroll"
		:scrollable="true"
		scrollHeight="600px"
		scrollDirection="both"
		:sortable="true"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #header>
			<div>
				<span class="p-input-icon-left" style="width: 100%">
					<i class="pi pi-search" />
					<InputText
						v-model="invoiceFilter"
						style="width: 100%"
						placeholder="Search Return"
						@keyup.enter="onEnterSearch($event)"
					/>
				</span>
			</div>
		</template>
		<template #empty>{{ searchMessage }}</template>
		<template #loading>Loading data, please wait...</template>
		<Column
			header="PO Number"
			field="PO_NUMBER"
			:sortable="true"
			style="width: 15%"
		>
			<template #body="{ data }">
				{{ data.PO_NUMBER || '-' }}
			</template>
		</Column>
		<Column
			header="Product"
			field="ITEM_DESCRIPTION"
			:sortable="true"
			style="width: 15%"
		>
			<template #body="{ data }">
				{{ data.ITEM_DESCRIPTION || '-' }}
			</template>
		</Column>
		<Column
			header="Line"
			field="LINE_NUM"
			:sortable="true"
			style="width: 9%"
		>
			<template #body="{ data }">
				{{ data.LINE_NUM || '-' }}
			</template>
		</Column>
		<Column
			header="Qty PO"
			field="QUANTITY_PO"
			:sortable="true"
			style="width: 15%"
		>
			<template #body="{ data }">
				{{ data.QUANTITY_PO || '-' }}
			</template>
		</Column>
		<Column
			header="Qty Receipt"
			field="QUANTITY_RECEIVED"
			:sortable="true"
			style="width: 15%"
		>
			<template #body="{ data }">
				{{ data.QUANTITY_RECEIVED || '-' }}
			</template>
		</Column>
		<Column
			header="Receipt Date"
			field="RECEIPT_DATE"
			:sortable="true"
			style="width: 15%"
		>
			<template #body="{ data }">
				{{
					data.RECEIPT_DATE ? formattingDate(data.RECEIPT_DATE) : '-'
				}}
			</template>
		</Column>
		<Column
			header="Receipt No"
			field="RECEIPT_NUM"
			:sortable="true"
			style="width: 20%"
		>
			<template #body="{ data }">
				{{ data.RECEIPT_NUM || '-' }}
			</template>
		</Column>
		<Column
			header="Invoice No"
			field="INVOICE_NO"
			:sortable="true"
			style="width: 20%"
		>
			<template #body="{ data }">
				{{ data.INVOICE_NO || '-' }}
			</template>
		</Column>
		<Column
			header="Invoice Date"
			field="INVOICE_DATE"
			:sortable="true"
			style="width: 10%"
		>
			<template #body="{ data }">
				{{
					data.INVOICE_DATE ? formattingDate(data.INVOICE_DATE) : '-'
				}}
			</template>
		</Column>
		<Column
			header="Invoice Status"
			field="STATUS"
			:sortable="true"
			style="width: 10%"
		>
			<template #body="{ data }">
				{{ data.STATUS || '-' }}
			</template>
		</Column>
		<Column
			header="Batch No"
			field="BATCH_NO"
			:sortable="true"
			style="width: 20%"
		>
			<template #body="{ data }">
				{{ data.BATCH_NO || '-' }}
			</template>
		</Column>
		<Column
			header="Batch Date"
			field="BATCH_DATE"
			:sortable="true"
			style="width: 12%"
		>
			<template #body="{ data }">
				{{ data.BATCH_DATE ? formattingDate(data.BATCH_DATE) : '-' }}
			</template>
		</Column>
		<Column
			header="Payment Schedule"
			field="PAYMENT_SCHEDULE"
			:sortable="true"
			style="width: 10%"
		>
			<template #body="{ data }">
				{{
					data.PAYMENT_SCHEDULE
						? formattingDate(data.PAYMENT_SCHEDULE)
						: '-'
				}}
			</template>
		</Column>
		<Column
			header="Payment Description"
			field="PAYMENT_DESCRIPTION"
			:sortable="true"
			style="width: 20%"
		>
			<template #body="{ data }">
				{{ data.PAYMENT_DESCRIPTION || '-' }}
			</template>
		</Column>
	</DataTable>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect } from 'vue';
import AuthService from '@/service/AuthService';
import PoHistoryService from '../../../service/PoHistoryService';

const company = ref();

onMounted(async () => {
	console.log('props Table Search ::', props);
	company.value = props.company;
	const info = await AuthService.whoAmi();
	if (info.status === 200) {
		levelTenant.value = info.data.data.leveltenant;
		await populateTable();
	}
});
// eslint-disable-next-line no-undef
const props = defineProps({
	company: {
		type: Object,
	},
	vendorId: {
		type: Object,
	},
	poNumber: {
		type: String,
	},
	searchAction: {
		type: Boolean,
	},
	resetSearch: {
		type: Boolean,
	},
	levelTenant: {
		type: String,
	},
});

// const tableData = ref([]);
const invoiceList = ref();
const totalData = ref(0);
const totalPage = ref(0);
const loading = ref(false);
const page = ref(1);
// const rowsPerPage = ref(5);
const levelTenant = ref();
const invoiceFilter = ref();
// const router = useRouter();
// const emit = defineEmits(["result"]);
const emit = defineEmits(['searchComponent']);
const searchComponent = ref();
const search = ref();
const limitPerPage = ref(5);
const sortField = ref();
const sortOrder = ref();
const searchMessage = ref('Click search for find data Receipt');

watchEffect(
	() =>
		// console.log('my god',remitBankValue.value),
		props.resetSearch,
	async () => {
		await resetTable();
	},
	emit('searchComponent', searchComponent)
);

const populateTable = async () => {
	console.log('Props', props);
	loading.value = true;
	if (props.searchAction && props.company && props.levelTenant) {
		const payload = {
			orgId: props.company ? props.company.value : null,
			dbInstance: props.company ? props.company.db_instance : null,
			vendorId: props.vendorId ? props.vendorId : null,
			poNumber: props.poNumber ? props.poNumber.PONO : null,
			levelTenant: props.levelTenant ? props.levelTenant : null,
			page: page.value ? page.value : null,
			limitPage: limitPerPage.value ? limitPerPage.value : null,
			sortField: sortField.value ? sortField.value : null,
			sortOrder: sortOrder.value ? sortOrder.value : null,
			search: search.value ? search.value : null,
		};
		let resultSearch = await PoHistoryService.getlistreturn(payload);
		console.log('resultSearch ::', resultSearch);
		invoiceList.value = resultSearch.data.data;
		if (resultSearch.data.data.length === 0) {
			searchMessage.value = 'No Data Found';
		}
		totalData.value = resultSearch.data.totalData;
		totalPage.value = resultSearch.data.totalPage;

		const searchValue = {
			...payload,
			isSearch: true,
		};
		searchComponent.value = searchValue;
		emit('searchComponent', searchComponent.value);
	}
	loading.value = false;
};

const onPage = async (event) => {
	// loading.value = true
	page.value = event.page + 1;
	limitPerPage.value = event.rows;
	await populateTable();
};

const onSort = async (event) => {
	// loading.value = true
	page.value = 1;
	limitPerPage.value = event.rows;
	sortField.value = event.sortField;
	sortOrder.value = event.sortOrder;
	await populateTable();
};

const onEnterSearch = async (event) => {
	console.log('check search', event.target.value);
	search.value = event.target.value;
	if (search.value !== null) {
		await populateTable();
	}
};

const formattingDate = (date, withTime) => {
	const theDate = new Date(date);
	const strDate =
		theDate.getFullYear() +
		'-' +
		(theDate.getMonth() + 1) +
		'-' +
		theDate.getDate();
	const strTime =
		theDate.getHours() +
		':' +
		theDate.getMinutes() +
		':' +
		theDate.getSeconds();
	if (withTime) {
		return `${strDate} ${strTime}`;
	} else {
		return `${strDate}`;
	}
};

const resetTable = async () => {
	invoiceList.value = null;
};
</script>

<style scoped>
.input-disabled {
	color: #000 !important;
}

.p-chip.custom-chip {
	padding: 4px 12px;
	border-radius: 8px;
	font-size: 12px;
	font-weight: 600;
}

.chip-open {
	background: #0ea5e9;
	color: #fff;
}
.chip-rejected {
	background: var(--primary-color);
	color: #fff;
}
.chip-review {
	background: #f59e0b;
	color: #fff;
}
.chip-posted {
	background: #10b981;
	color: #fff;
}
.chip-processed {
	background: #2563eb;
	color: #fff;
}
</style>
