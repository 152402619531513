<!--<template>-->
    <template>
      <div class="Grid">

        <Toast />

        <div class="col-12">
          <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
        </div>

        <!--  Search  -->
        <div class="col-12">
          <div class="card">
            <div class="flex flex-row justify-content-between">
              <div class="col flex-column align-item-center log:col-auto">
                <span>
                    <CompaniesDropdown v-model="company" class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0" :disabled="!(userInfo?userInfo.leveltenant === 2:false)"/>
                    <SupplierDropdown v-if="!(userInfo?userInfo.leveltenant === 2:false)" v-model="supplier" :company="company?company:null"
                                    class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0" :key="supplierReload"/>
                    <ColumnsDropdown v-model="column" class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"/>
                    <SearchInput v-model="columnSearch" :inputType="column? column.type: ''" :key="searchReload"
                                 class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"/>
                </span>
                <span>
                    <Button label="Go" class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0" @click="goClick" :disabled="(userInfo ? (userInfo.leveltenant === 2 && isSupplierAction ) : false)"/>
                    <Button label="Clear Search" class="p-button-info w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0" @click="clearClick" :disabled="(userInfo ? (userInfo.leveltenant === 2 && isSupplierAction ) : false)"/>
                </span>
                <Button icon="pi pi-file" class="p-button-secondary" label="Export" @click="exportToExcel" style="float: right;" :disabled="(userInfo ? (userInfo.leveltenant === 2 && isSupplierAction ) : false)"/>
              </div>
            </div>
          </div>

        </div>

        <div class="col-12 mb-4">
          <div class="card">
            <DetailReport :column="column?column.col:null" :columnSearch="columnSearch" :company="company?company:null"
                          :supplier="supplier?supplier:null" :key="reload"></DetailReport>
          </div>

        </div>
      </div>
    </template>


<script setup>
  import {ref, watch} from 'vue';
  import ColumnsDropdown from "@/components/trackingpo/components/ColumnsDropdown";
  import { useToast } from "primevue/usetoast";
  import DetailReport from '@/components/trackingpo/components/DetailReport';
  import SearchInput from "@/components/components/SearchInput";
  import XLSX from 'xlsx'
  import AuthService from '../../service/AuthService';
  import { getTrackingPOByExcell } from "@/service/TrackingPOReport";
  import CompaniesDropdown from "../components/CompaniesDropdown";
  import SupplierDropdown from "@/components/components/SupplierDropdown";
  import SimpleCrypto from "simple-crypto-js";
  // import PoFulfillment from "@/service/PoFulfillment";
  import LovService from "@/service/LovService";

  const breadcrumbHome = { icon: "pi pi-home", to: "/" };
  const breadcrumbItems = [
    {
      label: "Tracking PO Report",
      to: "/trackingporeport",
    },
  ];
  const company = ref();
  const column = ref();
  const columnSearch = ref();
  const toast = useToast();
  const reload = ref(0);
  const page = ref(1);
  const searchReload = ref(0);
  const supplier = ref(null);
  const supplierReload = ref(0);
  const userInfo = ref();
  const isSupplierAction = ref(false);

  watch(() => company.value, () => {
    if (userInfo.value.leveltenant !== 2) {
      supplier.value = null;
    }
    supplierReload.value++;
  })

  AuthService.whoAmi().then(async (response) => {
    if (response.status === 200) {
      userInfo.value = response.data.data;
      if (userInfo.value.leveltenant === 2) {
        //get company
        isSupplierAction.value = true
        const respCbN = await LovService.getCompanyByName(userCompany());
        company.value = respCbN.data.data[0];
        //get supplier
        const param = {
          db_instance: company.value.db_instance,
          org_id: company.value.value
        }
        const respVendor = await LovService.getSuppBindComp(response.data.data.id);
        const vendorId = respVendor.data.data[0].vendor_id;
        console.log('cek vendor id',vendorId)

        // const vendorName = respVendor.data.data[0][0].vendorname;
        const respSuppName = await LovService.getSupplierName(param);
        if (respSuppName.status === 200) {
          const suppList = respSuppName.data.data;
          for (const s of suppList) {
            if (s.vendor_id === vendorId) {
              supplier.value = s;
              isSupplierAction.value = false
              break;
            }
          }
           if (supplier.value === null) {
                this.$router.push('/dashboard')
                window.alert("Connection Unstable, ID not Recognize")
          }
        }
      } else {
        // 
        // const response = await LovService.getcompanydb(userInfo.value.bioaddress);
        // const companies = response.data.data;
        // for (const c of companies) {
        //   if (parseInt(c.value) === userInfo.value.idsubtenant) {
        //     company.value = c;
        //     break;
        //   }
        // }
        const respCbN = await LovService.getCompanyByName(userCompany());
        company.value = respCbN.data.data[0];
      }
      // reload.value++;
    }
  })

// const exportTo = async () => {
//     let paramsPrintROO =
//         company.value.id
//       sessionStorage.setItem("paramsPrintROO", JSON.stringify(paramsPrintROO));
// }

 const exportToExcel = async () => {
  if (reload.value === 0) {
    toast.add({
      severity: "error",
      summary: "Data is Empty, Please click button Search before Export",
      life: 3000,
    });
    return
  }
  if (!company.value) {
    toast.add({
      severity: "error",
      summary: "Please select Company",
      life: 3000,
    });
    return
  }
     await AuthService.whoAmi().then((value) => {
       if (value.status === 200) {
         setTimeout(async () => {
          let columnValue = column.value? column.value.col : null;
          let columnSearchValue = columnSearch.value? columnSearch.value : undefined;
          let companyValue = company.value? company.value.value : null;
          let dbInstance = company.value? company.value.db_instance : null;
          let supplierValue = supplier.value? supplier.value.vendor_id : null;
           await getTrackingPOByExcell(
             columnValue,
             columnSearchValue,
             companyValue,
             supplierValue,
             dbInstance
           ).then((respond) => {
             if (respond.length !== 0) {
               
               for (var ast of respond) {
                 const arrival = ast.EST_GOOD_READINESS;
                 let atSite = new Date(arrival);
                 const needByDate = ast.ARRIVAL_AT_SITE;
                 let byDate = new Date(needByDate);
                 const manufacture = ast.EST_DELIVERY;
                 let manuf = new Date(manufacture);
                 const supp = ast.EST_ARRIVAL_AT_SITE;
                 let essSupp = new Date(supp);
                 const ship = ast.DELIVERY_DATE;
                 let shipDate = new Date(ship);
                 ast.EST_GOOD_READINESS = (
                   atSite.getDate() +
                   "-" +
                   atSite.toString().substring(4, 7) +
                   "-" +
                   atSite.getFullYear()
                 ).toUpperCase();
                 ast.ARRIVAL_AT_SITE = (
                   byDate.getDate() +
                   "-" +
                   byDate.toString().substring(4, 7) +
                   "-" +
                   byDate.getFullYear()
                 ).toUpperCase();
                 ast.EST_DELIVERY = (
                   manuf.getDate() +
                   "-" +
                   manuf.toString().substring(4, 7) +
                   "-" +
                   manuf.getFullYear()
                 ).toUpperCase();
                 ast.EST_ARRIVAL_AT_SITE = (
                   essSupp.getDate() +
                   "-" +
                   essSupp.toString().substring(4, 7) +
                   "-" +
                   essSupp.getFullYear()
                 ).toUpperCase();
                 ast.DELIVERY_DATE = (
                   shipDate.getDate() +
                   "-" +
                   shipDate.toString().substring(4, 7) +
                   "-" +
                   shipDate.getFullYear()
                 ).toUpperCase();
               }
               let today = new Date();
               let dd = String(today.getDate()).padStart(2, "0");
               let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
               let yyyy = today.getFullYear();
               let combinedDate = dd + mm + yyyy;
               let filename = "Tracking PO Report _" + combinedDate + ".xlsx";
               let data = respond;
               let ws = XLSX.utils.json_to_sheet(data);
               let wb = XLSX.utils.book_new();
               XLSX.utils.book_append_sheet(wb, ws, "Tracking PO Report");
               XLSX.writeFile(wb, filename);
             } else {
               toast.add({
                 severity: "error",
                 summary: "Data is Empty",
                 life: 3000,
               });
             }
           });
         }, 250);
       }
     });
   };


  const goClick = async () => {
    if (!company.value) {
      toast.add({
        severity: "error",
        summary: "Please select Company",
        life: 3000,
      });
      return
    }
    if ((column.value === undefined && (columnSearch.value === undefined || columnSearch.value === ''))) {
      reload.value++;
    } else if ((column.value === undefined && (columnSearch.value !== undefined || columnSearch.value !== '')) ||
      (column.value !== undefined && (columnSearch.value === undefined || columnSearch.value === ''))) {
      toast.add({
        severity: 'info',
        summary: 'Kolom kriteria dan nilai kosong',
        detail: 'Pilih kolom kriteria dan isi nilai untuk kolom sebelum tekan tombol Go',
        life: 3000
      });
    } else {
      reload.value++;
    }
  }

  const clearClick = () => {
    if ((column.value !== undefined && column.value !== null)
        && (columnSearch.value !== undefined && columnSearch.value  !== null)) {
      page.value = 1;
    }
    column.value = null;
    columnSearch.value = null;
    if (userInfo.value.leveltenant === 2) {
      company.value = null;
      // 
    } else {
      supplier.value = null;
    }

    reload.value++;
  }

  const userCompany = () => {
    const secretKey = 'some-unique-key';
    const simpleCrypto = new SimpleCrypto(secretKey);
    return simpleCrypto.decrypt(sessionStorage.getItem('dropdownCompany'));
  }

</script>

<style scoped>

</style>