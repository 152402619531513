import vendorApi from "./api/SpbAdminApi"
class masterVendor {
    async getUserFinance(payload){
        return vendorApi.post('/spb/mastervendor/getuserfinance', payload);
    }
    async getUserPurchasing(payload){
        return vendorApi.post('/spb/mastervendor/getuserpurchasing', payload);
    }
    async getCategory(payload){
        return vendorApi.post('/spb/mastervendor/getcategory', payload);
    }
    async getVendorSite(payload){
        return vendorApi.post('/spb/mastervendor/getvendorsite', payload);
    }
    async getVendor(payload){
        return vendorApi.post('/spb/mastervendor/getvendor', payload);
    }
    async getCostCenter(payload){
        return vendorApi.post('/spb/mastervendor/getcostcenter', payload);
    }
    async getAllData(payload){
        return vendorApi.post('/spb/mastervendor/getalldata', payload);
    }
    async getdetailVendorTable(payload){
        return vendorApi.post('/spb/mastervendor/detailmastervendortable', payload);
    }
    async getDetailHeader(payload){
        return vendorApi.post('/spb/mastervendor/detailheadervendor', payload);
    }
    async update(payload){
        return vendorApi.post('/spb/mastervendor/update', payload);
    }
    async create(payload){
        return vendorApi.post('/spb/mastervendor/create', payload);
    }
    
}

export default new masterVendor();