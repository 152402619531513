<template>
	<DataTable
		:value="masterVendorDetail"
		:lazy="true"
		:paginator="true"
		:rows="10"
		:loading="loading"
		:totalRecords="totalData"
		@page="onPage($event)"
		@sort="onSort($event)"
		:rowsPerPageOptions="[10, 20, 30]"
		responsiveLayout="scroll"
		:scrollable="true"
		scrollHeight="600px"
		:sortable="true"
		:rowId="null"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #header>
			<div>
				<span class="p-input-icon-left">
					<i class="pi pi-search" />
					<InputText
						v-model="search"
						v-tooltip.right="'Please Press Enter to Search'"
						placeholder="Search Data"
						@keyup.enter="populateTable()"
						@page="onPage($event)"
					/>
				</span>
			</div>
		</template>
		<template #empty>Data Not found</template>
		<template #loading>Loading data, please wait...</template>

		<Column header="Company" field="COMPANY"> </Column>
		<Column header="Category" field="CATEGORY_NAME"> </Column>
		<Column header="vendor" field="vendor_name"> </Column>
		<Column header="User Finance" field="user_name"> </Column>
		<Column header="Status"
			><template #body="{ data }">
				<Button
					v-if="data.status === 'ACTIVE'"
					v-tooltip="'Active'"
					class="p-button-rounded p-button-text p-button-lg p-button-success"
					icon="pi pi-check-circle"
					@click="toggleLineStatus(data.id)"
				/>
				<Button
					v-else-if="data.status == NULL"
					class="p-button-rounded p-button-text p-button-lg"
					label=""
				/>
				<Button
					v-else
					v-tooltip="'Inactive'"
					class="p-button-rounded p-button-text p-button-lg"
					icon="pi pi-times-circle"
					@click="toggleLineStatus(data.id)"
				/>
				<!-- <Button
          v-if="data.status === 'ACTIVE'"
          v-tooltip="'Active'"
          class="p-button-rounded p-button-text p-button-lg p-button-success"
          icon="pi pi-check-circle"
          @click="toggleLineStatus(data.id)"
					/>
			<Button
          v-else
          v-tooltip="'Inactive'"
          class="p-button-rounded p-button-text p-button-lg"
          icon="pi pi-times-circle"
          @click="toggleLineStatus(data.id)"
					/> -->
			</template>
		</Column>
	</DataTable>
</template>

<script setup>
import { onMounted, ref, defineEmits } from 'vue';
import VendorServices from '../../../service/masterVendorService';
import AuthService from '@/service/AuthService';
import PoFulfillment from '@/service/PoFulfillment';
import LovService from '@/service/LovService';
import { useRoute } from 'vue-router';

const company = ref();
const supplier = ref();
const emit = defineEmits('masterVendorDetail');

onMounted(async () => {
	company.value = props.company;
	const info = await AuthService.whoAmi();
	if (info.status === 200) {
		levelTenant.value = info.data.data.leveltenant;
		if (levelTenant.value !== 2) {
			loading.value = true;
			supplier.value = props.supplier;
			// loading.value = true
			await populateTable();
		} else {
			if (company.value && !supplier.value) {
				const param = {
					db_instance: props.company.db_instance,
					org_id: props.company.value,
				};
				const respVendor = await PoFulfillment.getSuppBindComp(
					info.data.data.id
				);
				const vendorName = respVendor.data.data[0][0].vendorname;
				const respSuppName = await LovService.getSupplierName(param);
				if (respSuppName.status === 200) {
					const suppList = respSuppName.data.data;
					for (const s of suppList) {
						if (s.vendor_name === vendorName) {
							supplier.value = s;
							break;
						}
					}
				}
				await populateTable();
			}
			// loading.value = true
		}
	}
});
// eslint-disable-next-line no-undef
const props = defineProps({
	company: {
		type: Object,
	},
	supplier: {
		type: Object,
	},
});

const masterVendorDetail = ref();
const loading = ref(false);
const page = ref(1);
const rowsPerPage = ref(10);
const sortColumn = ref();
const sortType = ref();
const levelTenant = ref();
const tableData = ref([]);
// const batchList = ref();
const totalData = ref(0);
const search = ref();
// const router = useRouter();
const route = useRoute();
const maping_id = ref(route.params.maping_id);

const populateTable = async () => {
	loading.value = false;
	if (props.company !== null && props.company !== undefined) {
		const payload = {
			maping_id: maping_id.value,
			page: page.value,
			rows: rowsPerPage.value,
			search: search.value ? search.value : null,
		};
		tableData.value = await VendorServices.getdetailVendorTable(payload);
		masterVendorDetail.value = tableData.value.data.data.data;
		totalData.value = tableData.value.data.data.totalData[0].totalData;
		emit('masterVendorDetail', masterVendorDetail);
	}
	loading.value = false;
};

const onPage = async (event) => {
	// loading.value = true
	page.value = event.page + 1;
	rowsPerPage.value = event.rows;
	await populateTable();
};

const onSort = async (event) => {
	// loading.value = true
	page.value = 1;
	rowsPerPage.value = event.rows;
	sortColumn.value = event.sortField;
	sortType.value = event.sortOrder;
	await populateTable();
};

const toggleLineStatus = (id) => {
	masterVendorDetail.value = masterVendorDetail.value.map((item) => {
		if (item.id === id) {
			return {
				...item,
				status: item.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
			};
		}

		return item;
	});

	emit('masterVendorDetail', masterVendorDetail);
};
</script>
<style scoped></style>
