<template>
	<DataTable
		:value="invoiceList"
		:lazy="true"
		:paginator="true"
		:rows="5"
		:loading="loading"
		:totalRecords="totalData"
		@page="onPage($event)"
		@sort="onSort($event)"
		:rowsPerPageOptions="[5, 10, 15, 25]"
		responsiveLayout="scroll"
		:scrollable="true"
		scrollHeight="600px"
		scrollDirection="both"
		:sortable="true"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #header>
			<div>
				<span class="p-input-icon-left" style="width: 100%">
					<i class="pi pi-search" />
					<InputText
						v-model="invoiceFilter"
						style="width: 100%"
						placeholder="Search Receipt"
						@keyup.enter="onEnterSearch($event)"
					/>
				</span>
			</div>
		</template>
		<template #empty>{{ searchMessage }}</template>
		<template #loading>Loading data, please wait...</template>
		<Column header="Company" field="NAME" style="width: 15%">
		</Column>
		<Column
			header="Supplier"
			field="vendor_name"
			:sortable="true"
			style="width: 15%"
		>
		</Column>
		<Column
			header="PO Number"
			field="po_number"
			:sortable="true"
			style="width: 15%"
		>
		</Column>
		<Column header="Line" field="line" style="width: 5%"> </Column>
		<Column
			header="Receipt Number"
			field="receipt_number"
			:sortable="true"
			style="width: 15%"
		>
		</Column>
		<Column header="Receipt Date" :sortable="true" style="width: 12%">
			<template #body="{ data }">
				{{ formattingDate(data.receipt_date) }}
			</template>
		</Column>
		<Column
			header="Item Number"
			field="item_number"
			:sortable="true"
			style="width: 15%"
		>
		</Column>
		<Column
			header="Item Description"
			field="item_description"
			:sortable="true"
			style="width: 20%"
		>
		</Column>
		<Column header="UOM" field="uom" :sortable="true" style="width: 12%">
		</Column>
		<Column
			header="PO Qty"
			field="po_qty"
			:sortable="true"
			style="width: 15%"
		>
		</Column>
		<Column
			header="Supplier Lot"
			field="supplier_lot"
			:sortable="true"
			style="width: 15%"
		>
		</Column>
		<Column
			v-if="props.levelTenant == 1"
			header="Receipt Lot"
			field="receipt_lot"
			:sortable="true"
			style="width: 15%"
		>
		</Column>
		<Column
			v-if="props.levelTenant == 1"
			header="Receipt Qty"
			field="receipt_qty"
			:sortable="true"
			style="width: 9%"
		>
		</Column>
		<!-- <Column
      class="call-button-container"
      header="Actions"
      selectionMode="single"
    >
      <template #body="{ data }">
        <Button
          icon="pi pi-eye"
          class="p-button-rounded p-button-text"
          @click="detail(data)"
          v-tooltip="'Detail'"
        />
      </template>
    </Column> -->
	</DataTable>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect } from 'vue';
// import { useRouter } from "vue-router";
import AuthService from '@/service/AuthService';
// import LovService from "@/service/LovService";
// import PoFulfillment from "@/service/PoFulfillment";
import receiptService from '../../../service/ReceiptService';

const company = ref();
// const supplier = ref();

onMounted(async () => {
	//
	company.value = props.company;
	const info = await AuthService.whoAmi();
	if (info.status === 200) {
		levelTenant.value = info.data.data.leveltenant;
		await populateTable();
	}
});
// eslint-disable-next-line no-undef
const props = defineProps({
	company: {
		type: Object,
	},
	vendorId: {
		type: Object,
	},
	dateFrom: {
		type: String,
	},
	dateTo: {
		type: String,
	},
	poNumber: {
		type: String,
	},
	searchAction: {
		type: Boolean,
	},
	resetSearch: {
		type: Boolean,
	},
	levelTenant: {
		type: String,
	},
});

// const tableData = ref([]);
const invoiceList = ref();
const totalData = ref(0);
const totalPage = ref(0);
const loading = ref(false);
const page = ref(1);
// const rowsPerPage = ref(5);
const levelTenant = ref();
const invoiceFilter = ref();
// const router = useRouter();
// const emit = defineEmits(["result"]);
const emit = defineEmits(['searchComponent']);
const searchComponent = ref();
const search = ref();
const limitPerPage = ref(5);
const sortField = ref();
const sortOrder = ref();
const searchMessage = ref('Click search for find data Receipt');

watchEffect(
	() =>
		// //
		props.resetSearch,
	async () => {
		await resetTable();
	},
	// emit("result", invoiceList)
	emit('searchComponent', searchComponent)
);

// watch(
//   () => props.resetSearch, async () => {
//     await resetTable()
//   }
// );

const populateTable = async () => {
	//
	loading.value = true;
	if (props.searchAction && props.company && props.levelTenant) {
		const payload = {
			orgId: props.company ? props.company.value : null,
			dbInstance: props.company ? props.company.db_instance : null,
			vendorId: props.vendorId ? props.vendorId : null,
			dateFrom: props.dateFrom ? props.dateFrom : null,
			dateTo: props.dateTo
				? new Date(props.dateTo).setHours(23, 59, 59)
				: null,
			poNumber: props.poNumber ? props.poNumber : null,
			levelTenant: props.levelTenant ? props.levelTenant : null,
			page: page.value ? page.value : null,
			limitPage: limitPerPage.value ? limitPerPage.value : null,
			sortField: sortField.value ? sortField.value : null,
			sortOrder: sortOrder.value ? sortOrder.value : null,
			search: search.value ? search.value : null,
		};

		let resultSearch = await receiptService.getlistreceipt(payload);
		//
		invoiceList.value = resultSearch.data.data;
		if (resultSearch.data.data.length === 0) {
			searchMessage.value = 'No Data Found';
		}
		totalData.value = resultSearch.data.totalData;
		totalPage.value = resultSearch.data.totalPage;

		const searchValue = {
			...payload,
			isSearch: true,
		};
		searchComponent.value = searchValue;
		emit('searchComponent', searchComponent.value);
	}
	loading.value = false;
};

//format dete dexa
const formattingDate = (date, withTime) => {
	const theDate = new Date(date);
	const strDate =
		theDate.getFullYear() +
		'-' +
		(theDate.getMonth() + 1) +
		'-' +
		theDate.getDate();
	const strTime =
		theDate.getHours() +
		':' +
		theDate.getMinutes() +
		':' +
		theDate.getSeconds();
	if (withTime) {
		return `${strDate} ${strTime}`;
	} else {
		return `${strDate}`;
	}
};

const onPage = async (event) => {
	// loading.value = true
	page.value = event.page + 1;
	limitPerPage.value = event.rows;
	await populateTable();
};

const onSort = async (event) => {
	// loading.value = true
	page.value = 1;
	limitPerPage.value = event.rows;
	sortField.value = event.sortField;
	sortOrder.value = event.sortOrder;
	await populateTable();
};

// const detail = async (rowIndex) => {
//   //
//   router.push({
//     name: "DetailSearchInvoiceNonProxy",
//     params: {
//       id: rowIndex.EINVOICE_ID,
//     },
//   });
// };

const onEnterSearch = async (event) => {
	// //
	// //
	//
	search.value = event.target.value;
	if (search.value !== null) {
		await populateTable();
	}
};

const resetTable = async () => {
	invoiceList.value = null;
};
</script>

<style scoped>
.input-disabled {
	color: #000 !important;
}

.p-chip.custom-chip {
	padding: 4px 12px;
	border-radius: 8px;
	font-size: 12px;
	font-weight: 600;
}

.chip-open {
	background: #0ea5e9;
	color: #fff;
}
.chip-rejected {
	background: var(--primary-color);
	color: #fff;
}
.chip-review {
	background: #f59e0b;
	color: #fff;
}
.chip-posted {
	background: #10b981;
	color: #fff;
}
.chip-processed {
	background: #2563eb;
	color: #fff;
}
</style>
