<template>
	<DataTable
		:value="invoiceList"
		:lazy="true"
		:paginator="true"
		:rows="10"
		:loading="loading"
		:totalRecords="totalData"
		@page="onPage($event)"
		@sort="onSort($event)"
		:rowsPerPageOptions="[10, 20, 30]"
		responsiveLayout="scroll"
		:scrollable="true"
		scrollHeight="600px"
		scrollDirection="both"
		:sortable="true"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #header>
			<div>
				<span class="p-input-icon-left" style="col-12">
					<i class="pi pi-search" />
					<InputText
						v-model="invoiceFilter"
						placeholder="Search Invoice"
						@keydown.enter="keydown($event)"
					/>
				</span>
			</div>
		</template>
		<template #empty>No Invoice found</template>
		<template #loading>Loading data</template>
		<Column header="Company" field="NAME" style="width: 20%"> </Column>
		<Column header="Supplier" field="vendor_name" style="width: 20%">
		</Column>
		<Column header="Invoice No" field="INVOICE_NO" style="width: 20%">
		</Column>
		<Column header="Invoice Type" field="INVOICE_TYPE" style="width: 20%">
		</Column>
		<Column
			header="Date"
			field="INVOICE_DATE"
			:sortable="true"
			style="width: 15%"
		>
		</Column>
		<Column header="Amount" field="TOTAL_AMOUNT" style="width: 20%">
		</Column>
		<Column header="Status" field="STATUS" style="width: 15%"> </Column>
		<Column
			class="call-button-container"
			header="Actions"
			selectionMode="single"
			alignFrozen="right"
			frozen
		>
			<template #body="slotProps">
				<Button
					icon="pi pi-eye"
					class="p-button-rounded p-button-text"
					@click="editChildren(slotProps.data)"
					v-tooltip="'Detail'"
				/>
			</template>
		</Column>
	</DataTable>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import AuthService from '@/service/AuthService';
import LovService from '@/service/LovService';
import PoFulfillment from '@/service/PoFulfillment';
import { InvoiceTypeEnum } from "../../../constants/POTypeEnum"
import SearchInvoicePo from '../../../service/SearchInvoicePo';

const company = ref();
const supplier = ref();
const lazyParams = ref(null);

onMounted(async () => {
	company.value = props.company;
	const info = await AuthService.whoAmi();

	lazyParams.value = {
		sortField: null,
		sortOrder: null,
	};

	if (info.status === 200) {
		levelTenant.value = info.data.data.leveltenant;
		if (levelTenant.value !== 2) {
			// loading.value = true
			supplier.value = props.supplier;
			await populateTable();
		} else {
			if (company.value && !supplier.value) {
				const param = {
					db_instance: props.company.db_instance,
					org_id: props.company.value,
				};
				const respVendor = await PoFulfillment.getSuppBindComp(
					info.data.data.id
				);
				const vendorName = respVendor.data.data[0][0].vendorname;
				const respSuppName = await LovService.getSupplierName(param);
				if (respSuppName.status === 200) {
					const suppList = respSuppName.data.data;
					for (const s of suppList) {
						if (s.vendor_name === vendorName) {
							supplier.value = s;
							break;
						}
					}
				}
				await populateTable();
			}
			// loading.value = true
		}
	}
});
// eslint-disable-next-line no-undef
const props = defineProps({
	company: {
		type: Object,
	},
	supplier: {
		type: Object,
	},
	batchNumber: { type: Object },
	poNumber: { type: Object },
	poType: { type: Object },
	status: { type: String }
});

const invoiceList = ref();
const totalData = ref(0);
const loading = ref(false);
const page = ref(1);
const rowsPerPage = ref(10);
const levelTenant = ref();
const invoiceFilter = ref();
const router = useRouter();
const emit = defineEmits(['loading']);
const search = ref();

watchEffect(() =>
	//
	emit('loading', loading)
);

const populateTable = async () => {
	//
	console.log(props.poNumber, "PO NUMBER")
	console.log(props.poType, "POT")
	console.log(props.status, "STATUS NYA")
	if (props.company !== null && props.company !== undefined) {
		const payload = {
			org_id: company.value ? company.value.value : null,
			db_instance: company.value ? company.value.db_instance : null,
			suppValue: supplier.value ? supplier.value.vendor_id : null,
			batch_no: props.batchNumber ? props.batchNumber.BATCH_NO : null,
			invoice_no: props.poNumber ? props.poNumber : null,
			search: invoiceFilter.value ? invoiceFilter.value : null,
			page: page.value,
			rows: rowsPerPage.value,
			sort: lazyParams.value.sortField,
			sortOrder: lazyParams.value.sortOrder,
			invoice_type: props.poType ? props.poType.name : null,
			// status: props.status
		};

		console.log(payload, "PATLOAD")
		loading.value = true;
		const res = await SearchInvoicePo.getSearchInvoicePo(payload);

		invoiceList.value = res.data.data.data;
		totalData.value = res.data.data.totalData[0].totalData;
		for (var id of invoiceList.value) {
			id.INVOICE_DATE = dateFormat2(id.INVOICE_DATE);
		}
		loading.value = false;
	}
	// loading.value = false;
};

function dateFormat2(d) {
	var monthShortNames = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];
	var t = new Date(d);
	return (
		t.getDate() +
		' ' +
		monthShortNames[t.getMonth()] +
		' ' +
		t.getFullYear() +
		' '
	);
}

const onPage = async (event) => {
	loading.value = true;
	//
	page.value = event.page + 1;
	rowsPerPage.value = event.rows;
	await populateTable();
};

const onSort = async (event) => {
	loading.value = true;
	console.log(event, "EVENT")
	page.value = 1;
	lazyParams.value = event;

	await populateTable();
};

const editChildren = async (rowIndex) => {
	console.log(rowIndex, "ROW INDEX")
	if(rowIndex.INVOICE_TYPE === InvoiceTypeEnum.Procurement || rowIndex.INVOICE_TYPE === InvoiceTypeEnum.ProcurementProxy){
		router.push({
			name: 'DetailSearchInvoiceWithPoProc',
			params: { id: rowIndex.EINVOICE_ID },
		});
		return;
	}

	if(rowIndex.INVOICE_TYPE === InvoiceTypeEnum.Prepayment || rowIndex.INVOICE_TYPE === InvoiceTypeEnum.PrepaymentProxy){
		router.push({
			name: 'DetailSearchInvoiceWithPoPrepayment',
			params: { id: rowIndex.EINVOICE_ID },
		});
		return;
	}

	router.push({
		name: 'DetailSearchInvoiceWithPoStandart',
		params: { id: rowIndex.EINVOICE_ID },
	});
};

const keydown = async (event) => {
	//
	//
	search.value = event;
	if (search.value !== null) {
		await populateTable();
	}
};
</script>

<style scoped></style>
