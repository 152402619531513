<template>
    <div>
    <AutoComplete :suggestions="filteredPreparer" @complete="searchPreparer($event)" :dropdown="true" field="user_name" v-model="preparer" placeholder="Preparer" :disabled="loading" forceSelection>
        <template #item="slotProp">
        {{ slotProp.item.user_name }}
        </template>
    </AutoComplete>
    </div>
</template>

<script setup>
import {onMounted, ref, watchEffect, defineEmits} from 'vue'
import AuthService from '../../../service/AuthService';
import SpecSrc from '../../../service/SpecSrc';

// eslint-disable-next-line no-undef
const props = defineProps({
    company: {
        type: Object
    }
});

// eslint-disable-next-line no-undef
const preparer = ref();
const preparerList = ref();
const filteredPreparer = ref();
const loading = ref(false);
const emit = defineEmits(['preparer'])

watchEffect(() => {
    emit("preparer", preparer)
})

onMounted( async ()=>{
    await populateData()
})

// watch(
//   () => props.company,
//   () => {
//     if (props.company.db_instance !== null) {
//       populateData()
//     }
//   }
// );

const populateData = async () => {
  AuthService.whoAmi().then(async (response) => {
    loading.value = true
    if (response.status === 200){
        if (props.company !== null){
            const payload = {
                db_instance : props.company.db_instance?props.company.db_instance:'', 
                org_id : props.company.value?props.company.value:'', 
                }
            const res = await SpecSrc.getPreparer(payload)
            // console.log('isi res', res)
            if (res == undefined || res.status === null){
              setTimeout( async () => {
                await populateData()
              }, 1000);
            } else if (res.status === 200) {
              preparerList.value = await res.data.data
              loading.value = false
            } else {
              preparerList.value = {user_name : 'Not Found'}
              loading.value = false
            }
        }
    } else {
      preparerList.value = {user_name : 'Not Found'}
      loading.value = false
    }
    
  })
}

const searchPreparer = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredPreparer.value = [...preparerList.value];
    } else {
      filteredPreparer.value = preparerList.value.filter((comp) => {
        return comp.user_name
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};
</script>