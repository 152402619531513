<template>
  <div>
    <div class="col-12 md:col-12 lg:col-4">
    <span class="p-float-label">
      <Textarea
          v-model="remark"
          rows="2"
          class="w-full mb-3 lg:col-auto lg:mr-3 lg:mb-0"
          id="remark"
          :disabled="props.disable"
      />
    </span>
      <Button label="Save" @click="save"
              class="p-button-success w-full mb-3 lg:w-auto lg:mr-3 lg:mb-0"
              :disabled="props.disable"/>
    </div>
    <div class="col-12 md:col-12 lg:col-4">
      <div class="comment">
        <div v-for="remark in remarks" :key="remark.REMARK_DATE" style="margin-bottom: 10px">
          <div class="comment-heading">
            <div class="comment-author">
              {{ remark.fullname }} on {{ convertDate(remark.remark_date) }}
            </div>
          </div>
          <div class="comment-info">
            <p>
              {{ remark.remark }}
            </p>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script async setup>
import {getRemarks, saveRemark} from "@/service/CAPAServices";
import {ref} from "vue";

const remarks = ref();
const remark = ref();

// eslint-disable-next-line no-undef
const props = defineProps({
  id: {
    type: String
  },
  user: {
    type: Object
  },
  disable: {
    type: Boolean
  }
})

if (props.id) {
  remarks.value = await getRemarks(props.id)
}

const convertDate = (data) => {
  let convertedDate = null;
  let option = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  };
  convertedDate = new Date(data).toLocaleDateString("id-ID", option);
  if (convertedDate == "Invalid Date" || convertedDate == "1 Jan 1970") {
    convertedDate = "";
  }
  return convertedDate;
};

const save = async () => {
  if (remark.value === null || remark.value === undefined || remark.value === '') return;

  const result = await saveRemark(props.id, props.user.id, remark.value)
  if (result === 'success') {
    remarks.value = await getRemarks(props.id)
  }
  remark.value = null
}

</script>

<style>
.comment {
  position: relative;
  margin: 20px auto;
}

.comment-heading {
  display: flex;
  align-items: center;
  height: 30px;
  font-size: 14px;
}

.comment-info {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 10px;
}

.comment-author {
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  text-decoration: none;
}

.comment-author:hover {
  text-decoration: underline;
}
</style>