// import AuthService from "../../service/AuthService";

class Guard {
	async guardMyroute(to, from, next) {
		try{
			// const data = await AuthService.whoAmi()
			if (to.name == 'resetpassword' || sessionStorage.authKey != null) {
				next();
			} else {
				alert('Session has expired\nPlease login again')
				this.clearSessionStorage()
				next('/login');
			}
		} catch (e) {
			alert('Session has expired\nPlease login again')
			this.clearSessionStorage()
			next('/login');
		}
		
	}

	guardAdmin(to, from, next) {
		try{
			if (to.name == 'resetpassword' || sessionStorage.authKey != null) {
				if (sessionStorage.idUser == 2) {
					next();
				} else {
					next('/dashboard');
				}
			} else {
				this.clearSessionStorage()
				next('/login');
			}
		} catch (e) {
			alert('Session has expired\nPlease login again')
			this.clearSessionStorage()
			next('/login');
		}
		
	}

	guardEmployeeInternal(to, from, next) {
		try{
			if (to.name == 'resetpassword' || sessionStorage.authKey != null) {
				if (sessionStorage.idUser == 2 || sessionStorage.leveltenant == 1) {
					next();
				} else {
					next('/dashboard');
				}
			} else {
				this.clearSessionStorage()
				next('/login');
			}
		} catch (e) {
			alert('Session has expired\nPlease login again')
			this.clearSessionStorage()
			next('/login');
		}
		
	}

	guardInternal(to, from, next) {
		try {
			if (to.name == 'resetpassword' || sessionStorage.authKey != null) {
				if (
					sessionStorage.leveltenant == 1 ||
					sessionStorage.leveltenant == 3
				) {
					next();
				} else {
					next('/dashboard');
				}
			} else {
				this.clearSessionStorage()
				next('/login');
			}
		} catch (e) {
			alert('Session has expired\nPlease login again')
			this.clearSessionStorage()
			next('/login');
		}
		
	}

	guardSupplier(to, from, next) {
		try {
			
			if (to.name == 'resetpassword' || sessionStorage.authKey != null) {
				if (sessionStorage.leveltenant == 2 || sessionStorage.leveltenant == 3) {
					next();
				} else {
					next('/dashboard');
				}
			} else {
				this.clearSessionStorage()
				next('/login');
			}
		} catch (e) {
			alert('Session has expired\nPlease login again')
			this.clearSessionStorage()
			next('/login');
		}
		
	}

	clearSessionStorage(){
		sessionStorage.removeItem('authKey'); 
		sessionStorage.removeItem('companydb');
		sessionStorage.removeItem('dropdownCompany');  
		sessionStorage.removeItem('idUser'); 
		sessionStorage.removeItem('leveltenant'); 
		sessionStorage.removeItem('list'); 
		sessionStorage.removeItem('vendorId'); 
		sessionStorage.removeItem('mailToCompany'); 
		sessionStorage.removeItem('loggedIn'); 
	}
}



export default new Guard();
