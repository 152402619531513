<template>
    <!-- eslint-disable -->
    <div class="grid">
        <div class="col-12">
            <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
        </div>
        <div class="col-12">
            <div class="card">
                <div class="surface-section">
                    <div class="font-medium text-2xl text-900 mb-3">Notification Detail</div>

                    <ul class="list-none p-0 m-0">
                        <li class="flex align-items-center py-3 px-2 border-top-0 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Module</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{modulename}}</div>
                            <div class="w-6 md:w-2 flex justify-content-end">

                            </div>
                        </li>
                        <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Sender</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{sender.toString()}}
                            </div>
                            <div class="w-6 md:w-2 flex justify-content-end">

                            </div>
                        </li>
                        <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Date</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{receivedDate.toString().toUpperCase()}}
                            </div>
                            <div class="w-6 md:w-2 flex justify-content-end">

                            </div>
                        </li>
                        <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Recipient</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                {{emailRecipient.toString()}}
                            </div>
                            <div class="w-6 md:w-2 flex justify-content-end">

                            </div>
                        </li>
                        <li
                            class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Subject</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                {{subject}}</div>
                            <div class="w-6 md:w-2 flex justify-content-end">

                            </div>
                        </li>
                        <li
                            class="flex align-items-center py-3 px-2 border-top-0 border-bottom-0 surface-border flex-wrap">
                            <div class="text-500 w-6 font-medium">Message</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                <br />Dear Purchasing Team,<br /><br>We have confirmed your Planning Order with details as follow:
                            </div>
                            <div class="w-6 md:w-2 flex justify-content-end">

                            </div>
                        </li>
                        <li
                            class="flex justify-content py-3 px-2 border-top-0 border-bottom-0 surface-border flex-wrap">
                            <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1 line-height-3">
                                <strong>Planning Order No. : </strong>{{poNum}}<br />
                                <strong>Planning Order Date : </strong>{{poDateOne}}<br />
                                <strong>Must Respond by Date : </strong>{{respondDateOne}} <br />
                            </div>
                            <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1 line-height-3">
                                <strong>Rev :</strong> 0<br />
                                <strong>Description :</strong> <br />
                                <strong>Respond By :</strong> {{respondBy}}<br />
                                
                            </div>
                        </li>
                        <br />
                        <!-- <DataTable :value="notifplannos" v-model:selection="selectedNotifPlannos" dataKey="id"
                            :paginator="false" :rows="5" v-model:filters="filters"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            responsiveLayout="scroll">
                            <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field"
                                :sortable="false" />
                        </DataTable> -->
                        <DataTable :value="notifplannos" v-model:selection="selectedNotifPlannos" dataKey="id"
                            :paginator="false" :rows="5" v-model:filters="filters"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            responsiveLayout="scroll">
                            <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field"
                                :sortable="false" />
                        </DataTable>
                        <li
                            class="flex align-items-center py-3 px-2 border-top-0 border-bottom-0 surface-border flex-wrap">
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                <br />For detailed information of this Planning Order, please visit <router-link
                                    :to="'/mgm/settings/planningorder/' + poHeaderId" class="text-blue-500">here</router-link>.<br /><br />
                                Regards,<br />
                                Supplier Team</div>
                            <div class="w-6 md:w-2 flex justify-content-end">
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <router-link to="/dashboard"><Button label="Return" class="mr-2 mb-2" /></router-link>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import NotificationService from '../../../service/NotificationService'
// import AuthService from '../../../service/AuthService'
export default {
 data() {
     return {
         breadcrumbHome: {icon: 'pi pi-home', to: '/dashboard'},
		 breadcrumbItems: [
		 	{label:'Dashboard', to: '/dashboard'},
		 	{label:'Notification Detail'}
		 ],

         modulename: null,
         sender: [],        
         columns: null,
         notifplannos: null,
		 notifplanno: {},
		 selectedNotifPlannos: null,
         poNum: null,
         vendorname: null,
         poDate: [],
         poDateOne: {},
         respondDate: [],
         respondDateOne: [],
         vendorId: [],
         orgId: [],
         dbInstance: [],
         emailRecipient: [],
         emailRecipientOne: null,
         receivedDate: {},
         respondBy: null,
         poHeaderId: null,
         subject: this.$route.params.subjectMessage
       }
    },

	created() {
        this.columns = [
            {field: 'pr_line_num', header: 'Line'},
            {field: 'item_num', header: 'Item Number'},
            {field: 'item_desc', header: 'Item Description'},
            {field: 'plan_qty', header: 'Qty'},
            {field: 'uom', header: 'UOM'},
            {field: 'ship_to_desc', header: 'Location'},
            {field: 'currency', header: 'Currency'},
            {field: 'confirm_price', header: 'Confirm Price'},
            {field: 'confirm_qty', header: 'Confirm Qty'},
            {field: 'confirm_ship_date', header: 'Confirm Arrival at Site\n(based on incoterm)'},
            {field: 'remarks', header: 'Remark'},
        ]
	},
	mounted() {
	    console.clear()
	    
	    let getSbjmessage = this.$route.params.subjectMessage;
	    var getPoNum = getSbjmessage.substring(0, getSbjmessage.indexOf('has'));
	    let trimThisOne = getPoNum.replace('Planning Order #', '').replace(/ /g, '');
	    this.poNum = trimThisOne
	    this.modulename = this.$route.params.nameModule;
	    NotificationService.getNotifDetailPlanno(this.poNum).then((response) => {
	        if (response.data.status === 200) {
	            this.notifplannos = response.data.data[0];
	            this.poHeaderId = response.data.data[0][0]['plan_order_id']
	            this.notifplannos.forEach(element => {
	                this.poDate.push(element.plan_order_date);
	                this.respondDate.push(element.response_date);
	                this.vendorId.push(element.vendor_id);
	                this.orgId.push(element.org_id);
	                this.dbInstance.push(element.db_instance);
	            });
	            let poDateReformat = new Date(this.poDate[0])
	            this.poDateOne = (poDateReformat.getDate() + '-' + poDateReformat.toString().substr(4, 3) + '-' + poDateReformat.getFullYear()).toUpperCase();
	            let respDateReformat = new Date(this.respondDate[0]);
	            this.respondDateOne = (respDateReformat.getDate() + '-' + respDateReformat.toString().substr(4, 3) + '-' + respDateReformat.getFullYear()).toUpperCase();
	        } else {
	            window.alert('Ini sepertinya sisaan testing\nKembali ke Dashboard...');
	            this.$router.push('/dashboard');
	        }
	    });
	    NotificationService.getNotifDetailPlannoSender({
	        something: JSON.stringify(this.$route.params.subjectMessage)
	    }).then((resp) => {
	        if (resp.data.status === 200) {
	            this.sender = resp.data.data[0][0].sender;
	            this.respondBy = resp.data.data[0][0].usrkktname;
	            this.emailRecipient = resp.data.data[0][0].recipient;
	            let isodate = new Date(resp.data.data[0][0].receiveddate.substring(0, 10));
	            this.receivedDateConvert = isodate.getDate() + '-' + isodate.toString().substr(4, 3) + '-' + isodate.getFullYear() + ' ' + isodate.getHours() + ':' + isodate.getMinutes() + ':' + isodate.getSeconds();
	            this.receivedDate = resp.data.data[0][0].receiveddate;
	        } else {
	            window.alert('Ini sepertinya sisaan testing\nKembali ke Dashboard...');
	            this.$router.push('/dashboard');
	        }
	    });

	},
}
</script>
<style scoped>
</style>