<template>
	<div class="grid">
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<Toast />

		<div class="col-12">
			<div class="card">
				<!-- <h5>User</h5> -->
				<div class="flex flex-row justify-content-between">
					<div class="flex flex-row">
						<!-- <i class="pi pi-search" style="font-size: 2rem; padding: 5px; margin-right: 4px"></i>                            -->
						<!-- <InputText type="text" v-model="search" class="mr-2"></InputText> -->
						<div class="mb-2 lg:mb-0 mr-4">
							<span class="p-input-icon-left p-input-icon-right">
								<i class="pi pi-user" />
								<InputText
									type="text"
									placeholder="Search"
									v-model="search"
								/>
								<i class="pi pi-search" />
							</span>
						</div>
						<Button
							label="Go"
							class="p-button-primary mr-2 mb-2 my-auto"
							@click="searchFilter"
						/>
						<Button
							label="Clear"
							class="p-button-secondary mr-2 mb-2 my-auto"
							@click="clearFilter"
						/>
					</div>
					<div class="flex flex-row">
						<!-- <Button label="Action" class="p-button-secondary mr-2 mb-2 my-auto" /> -->
						<Button
							label="New User"
							@click="createUser"
							class="p-button-primary mr-2 mb-2 my-auto"
							:disabled="adminStatus"
						/>
						<Button
							label="Excel"
							@click="exportExcel"
							class="p-button-warning mr-2 mb-2 my-auto"
							:disabled="adminStatus"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12">
			<div class="card">
				<DataTable
					:value="filtered ? filtered : uservalue"
					v-model:selection="selectedProducts"
					dataKey="id"
					:paginator="true"
					:rows="5"
					v-model:filters="filters"
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
					:rowsPerPageOptions="[5, 10, 25]"
					sortField="created_at"
					sortOrder="-1"
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
					responsiveLayout="scroll"
					:globalFilterFields="[
						'fullname',
						'userid',
						'tenantname',
						'bioemailactive',
					]"
				>
					<Column
						v-for="column in column"
						:key="column.field"
						:field="column.field"
						:header="column.header"
						:style="{ width: column.width }"
						:sortable="true"
					></Column>
					<Column header="Active" style="width: 50px">
						<template #body="slotProps">
							<span
								v-if="slotProps.data.active"
								class="pi pi-check"
							/>
							<span v-else class="pi pi-times" />
						</template>
					</Column>

					<Column header="Action" :style="{ width: '150px' }">
						<template #body="slotProps">
							<Button
								icon="pi pi-search"
								class="p-button-rounded p-button-success mr-2"
								@click="open(slotProps.data)"
							/>
							<Button
								icon="pi pi-pencil"
								class="p-button-rounded p-button-warning mr-2"
								@click="editUser(slotProps.data)"
							/>
							<Button
								icon="pi pi-ban"
								class="p-button-rounded p-button-danger mr-2"
								@click="deleteSession(slotProps.data)"
								v-tooltip.right="'Delete Session id '+ slotProps.data.id"
							/>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
		<Dialog
			header="Detail User"
			v-model:visible="display"
			:breakpoints="{ '960px': '75vw' }"
			:style="{ width: '30vw' }"
			:modal="true"
		>
			<p class="line-height-4 m-0">
				<span class="font-bold">User Name : </span>
				{{ detailData.userid }}<br />
				<span class="font-bold">Full Name : </span>
				{{ detailData.fullname }}<br />
				<span class="font-bold">Description : </span>
				{{ detailData.description }}<br />
				<span class="font-bold">Creator Name : </span>
				{{ detailData.creatorname }}<br />
				<span class="font-bold">Group Name : </span>
				{{ detailData.groupname }}<br />
				<span class="font-bold">Level Name : </span>
				{{ detailData.levelname }}<br />
				<span class="font-bold">Start Date : </span>
				{{ new Date(detailData.created_at).toDateString() }}<br />
				<span class="font-bold">End Date : </span>
				{{ new Date(detailData.updated_at).toDateString() }}<br />
				<span class="font-bold">Active Status : </span>
				{{ detailData.active === 1 ? 'YES' : 'NO' }}<br />
			</p>
			<template #footer>
				<Button
					label="Ok"
					@click="close"
					icon="pi pi-check"
					class="p-button-outlined"
				/>
			</template>
		</Dialog>
		<Dialog
			header="Confirmation"
			v-model:visible="displayConfirmation"
			:style="{ width: '350px' }"
			:modal="true"
		>
			<div class="flex align-items-center justify-content-center">
				<i
					class="pi pi-exclamation-triangle mr-3"
					style="font-size: 2rem"
				/>
				<span>Are you sure you want to proceed?</span>
			</div>
			<template #footer>
				<Button
					label="No"
					icon="pi pi-times"
					@click="closeConfirmation"
					class="p-button-text"
				/>
				<Button
					label="Yes"
					icon="pi pi-check"
					@click="closeConfirmation"
					class="p-button-text"
					autofocus
				/>
			</template>
		</Dialog>
	</div>
</template>

<script>
import UserService from '../../service/UserManagerService';
import AuthService from '../../service/AuthService';
import XLSX from 'xlsx';

export default {
	data() {
		return {
			display: false,
			displayConfirmation: false,
			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [
				{ label: 'Admin', to: '/dashboard' },
				{ label: 'Users', to: '/mgm/user/userslist' },
			],
			loading: true,
			uservalue: null,
			search: null,
			filtered: null,
			adminStatus: true,
			column: [
				{ header: 'Full Name', field: 'fullname', width: '200px' },
				{ header: 'User Name', field: 'userid', width: '150px' },
				{ header: 'Group Menu', field: 'groupname', width: '150px' },
				{ header: 'Type', field: 'levelname', width: '150px' },
				{ header: 'Description', field: 'description', width: '150px' },
			],
			detailData: {},
		};
	},

	methods: {
		exportExcel() {
			let data = this.filtered ? this.filtered : this.uservalue;
			if (data.length !== 0) {
				let today = new Date();
				let dd = String(today.getDate()).padStart(2, '0');
				let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
				let yyyy = today.getFullYear();
				let combinedDate = dd + mm + yyyy;
				let filename = 'User List_' + combinedDate + '.xlsx';

				let ws = XLSX.utils.json_to_sheet(data);
				let wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, 'My Worksheet');
				XLSX.writeFile(wb, filename);
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Data is Empty',
					life: 3000,
				});
			}
		},
		open(data) {
			this.detailData = data;
			this.display = true;
		},
		close() {
			this.display = false;
		},
		createUser() {
			this.$router.push('/user/0');
		},
		searchFilter() {
			this.filtered = this.searchUser;
		},
		clearFilter() {
			this.filtered = null;
			this.search = null;
		},
		editUser(data) {
			this.$router.push({ name: 'userCreate', params: { id: data.id } });
		},
		deleteSession(data) {
			console.log("delete session :", data)
			try {
				UserService.deleteSession(data.id).then(res => {
					try {
						if (res.status == 200) {
							this.$toast.add({
								severity: 'success',
								summary: 'Delete Session',
								detail: 'Deleted session for user ' + data.fullname,
								life: 3000
							});
						} else {
							this.$toast.add({
								severity: 'error',
								summary: 'Connection Unstable',
								life: 3000
							});
						}
					} catch (error) {
						if (!error.response) {
							this.$toast.add({
								severity: 'error',
								summary: 'Connection Unstable',
								detail: 'Error: Network Error',
								life: 3000
							});
							// network error
						} else {
							this.$toast.add({
								severity: 'error',
								summary: 'Connection Unstable',
								detail: error.response.data.message,
								life: 3000
							});
						}
					}
				})
			} catch (error) {
				if (!error.response) {
							this.$toast.add({
								severity: 'error',
								summary: 'Connection Unstable',
								detail: 'Error: Network Error',
								life: 3000
							});
							// network error
						} else {
							this.$toast.add({
								severity: 'error',
								summary: 'Connection Unstable',
								detail: error.response.data.message,
								life: 3000
							});
						}
			}
		},
		formatDate(date) {
			var month = this.pad2(date.getMonth() + 1); //months (0-11)
			var day = this.pad2(date.getDate()); //day (1-31)
			var year = date.getFullYear();

			var formattedDate = day + '/' + month + '/' + year;
			return formattedDate;
		},
	},
	computed: {
		searchUser() {
			return this.uservalue.filter((item) => {
				return (
					item.fullname
						.toLowerCase()
						.match(this.search.toLowerCase()) ||
					item.userid
						?.toLowerCase()
						.match(this.search.toLowerCase()) ||
					item.fullname
						?.toLowerCase()
						.match(this.search.toLowerCase()) ||
					item.bioemailactive
						?.toLowerCase()
						.match(this.search.toLowerCase()) ||
					item.tenantname
						?.toLowerCase()
						.match(this.search.toLowerCase()) ||
					item.groupname
						?.toLowerCase()
						.match(this.search.toLowerCase()) ||
					item.levelname
						?.toLowerCase()
						.match(this.search.toLowerCase())
				);
			});
		},
	},
	created() {},
	mounted() {
		try {
			AuthService.whoAmi().then((value) => {
				if (value.data.status === 200) {
					if (
						value.data.data.leveltenant === 1 &&
						value.data.data.id !== 2
					) {
						UserService.retriveUsers()
							.then((response) => {
								this.uservalue = response.data.data;
								this.loading = false;
								this.adminStatus = true;
							})
							.catch((e) => {
								console.log(e);
							});
					} else {
						UserService.retriveUsers()
							.then((response) => {
								this.uservalue = response.data.data;
								this.adminStatus = false;
								this.loading = false;
							})
							.catch((e) => {
								console.log(e);
							});
					}
				}
			});
		} catch (error) {
			AuthService.loggedOut();
		}
	},
};
</script>
