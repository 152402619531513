<template>
    <span class="p-float-label">
        <AutoComplete :suggestions="filteredPay" @complete="searchpaygroup($event)" :dropdown="true" field="VALUE" v-model="paygroupValue" forceSelection>
        <template #item="slotProp">
        {{ slotProp.item.VALUE }}
        </template>
    </AutoComplete>
        <label for="autocomplete">Pay Group</label>
    </span>
  
  
</template>

<script setup>
import {onMounted, ref, watchEffect, defineEmits} from 'vue'
import AuthService from '../../../service/AuthService';
import invoiceNonPOservice from "@/service/InvoiceNonPOservice";

// eslint-disable-next-line no-undef
const props = defineProps({
  db_instance: {type: Object},
  vendor_id: {type: Object},
})

// eslint-disable-next-line no-undef
const filteredPay = ref([]);
const paygroup = ref([]);
const paygroupValue = ref();
const emit = defineEmits(['paygroup'])

onMounted(() => {
  populateData()
})

watchEffect(() => 
  // 
  emit('paygroup',paygroupValue)
)

const populateData = () => {
  AuthService.whoAmi().then(async (response) => {
    // 
      if (response.status === 200){
      const data = await invoiceNonPOservice.getListPayGroup(props.db_instance);
      // 
      paygroup.value = data.data.data
    }
   
    
  })
}


const searchpaygroup = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredPay.value = [...paygroup.value]
    } else {
      filteredPay.value = paygroup.value.filter((comp) => {
        return comp.VALUE.toLowerCase().match(event.query.toLowerCase());
      })
    }
  }, 250);
}
</script>

<style scoped>

</style>