<template>
	<!-- eslint-disable -->
	<div class="grid">
		<div class="col-12 p-fluid">
			<div class="card">
				<div class="grid formgrid" style="margin: 1em 0 0 0">
					<div class="col-12 mb-5 lg:col-2 lg:mb-0">
						<span class="p-float-label">
							<!-- <Dropdown id="dropdown" :options="suppliers" v-model="selectedSupplier" optionLabel=""></Dropdown> -->
							<AutoComplete
								v-model="selectedSupplier"
								:suggestions="filteredSupplier"
								@complete="searchSupplier($event)"
								:dropdown="true"
								field=""
								forceSelection
							>
								<template #item="slotProps">
									<div class="country-item">
										<div class="ml-2">
											{{ slotProps.item }}
										</div>
									</div>
								</template>
							</AutoComplete>
							<label for="dropdown">Supplier</label>
						</span>
					</div>
					<div class="col-12 mb-5 lg:col-2 lg:mb-0">
						<span class="p-float-label p-input-icon-right">
							<InputText
								type="search"
								id="inputtext"
								v-model="search"
								@keydown.enter="searchAny()"
							/>
							<label for="inputtext">DO Number</label>
						</span>
					</div>
					<div class="col-12 mb-2 lg:col-2 lg:mb-0">
						<span class="p-float-label">
							<Dropdown
								id="dropdown"
								style="width: 100%"
								:options="statusOptions"
								v-model="selectedStatus"
								optionLabel=""
								@click="warningBroken()"
							></Dropdown>
							<label for="dropdown">Status</label>
						</span>
					</div>
					<div class="col-12 mb-2 lg:col-1 lg:mb-0">
						<Button label="Go" @click="searchAny()"></Button>
					</div>
					<div class="col-12 mb-2 lg:col-1 lg:mb-0">
						<Button
							class="p-button-secondary"
							label="Clear"
							@click="clearFilter()"
						></Button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- <sup>Data yang ditampilkan kemungkinan masih belum sesuai karena belum adanya backlog BE</sup> -->
	<DataTable
		:value="filtered ? filtered : nested"
		v-model:expandedRows="expandedRows"
		dataKey="do_num"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[5, 10, 15]"
		:paginator="true"
		rows="5"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
		responsiveLayout="scroll"
	>
		<Column :expander="true" headerStyle="width: 3rem" />
		<Column
			field="do_num"
			header="DO Number"
			style="width: 5rem; font-weight: 800"
			:sortable="false"
		></Column>
		<Column field="vendor_name" header=""></Column>
		<template #expansion="slotProps">
			<div class="orders-subtable">
				<DataTable
					:value="filtered ? filtered : slotProps.data.children"
					v-model:selection="selectedChildren"
					dataKey="line"
					removableSort
					responsiveLayout="scroll"
				>
					<Column
						v-for="col in columns"
						:field="col.field"
						:header="col.header"
						:key="col.field"
						:sortable="true"
					/>
					<!-- <Column headerStyle="width:4rem">
                        <template #body>
                            <Button icon="pi pi-search" @click="editChildren(slotProps.data.children)"/>
                        </template>
                    </Column> -->
				</DataTable>
			</div>
		</template>
	</DataTable>
	<br />
</template>
<script>
/* eslint-disable */
import { FilterMatchMode, FilterOperator } from 'primevue/api';
// import ProductService from '../../service/ProductService';
import CapaService from '../../service/CapaService';
export default {
	data() {
		return {
			expandedRowGroups: null,
			expandedRows: [],
			nested: [],
			columns: [],
			nestedchildren: [],
			selectedChildren: {},
			tabelexpand: null,
			supplier: null,
			isisupplier: null,
			filteredSupplier: null,
			filtered: null,
			selectedStatus: {},
			statusOptions: ['ALL'],
			search: null,
			suppliers: [],
			selectedSupplier: null,
			getChild: [],
		};
	},

	created() {
		this.columns = [
			{ field: 'line', header: 'Line' },
			{ field: 'item_num', header: 'Item Number' },
			{ field: 'item_desc', header: 'Item Description' },
			{ field: 'uom', header: 'UOM' },
			{ field: 'qty_order', header: 'Qty Order' },
			{ field: 'qty_ship', header: 'Qty Shipment' },
			{ field: 'delivery_date', header: 'Delivery Date' },
			{ field: 'arrival_at_site_date', header: 'Arrival at Site Date' },
			{ field: 'ship_to', header: 'Ship To' },
			{ field: 'status', header: 'Status' },
		];
		// this.getTableNested();
		// this.GetPreparer();
		// this.GetLOV();
		// this.GetSupplier();
	},
	mounted() {
		this.search = null;
		this.selectedStatus = 'ALL';
		this.selectedSupplier = '';
	},
	computed: {
		searchForm() {
			console.clear();
			if (this.selectedSupplier === 'ALL' || this.search === null) {
				return this.nested.filter((item) => {
					return (
						item.vendor_name.match(this.selectedSupplier) ||
						item.do_num
							.toLowerCase()
							.match(this.search.toLowerCase())
					);
				});
			} else {
				return this.nested.filter((item) => {
					return (
						item.vendor_name.match(this.selectedSupplier) &&
						item.do_num
							.toLowerCase()
							.match(this.search.toLowerCase())
					);
				});
			}
		},
	},
	methods: {
		// onPage(event) {
		// },
		warningBroken() {
			window.alert('Filter untuk status mengalami bug untuk saat ini');
		},
		searchSupplier(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredSupplier = [...this.suppliers];
				} else {
					this.filteredSupplier = this.suppliers.filter((item) => {
						return item
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		searchAny() {
			if (this.search === null) {
				this.search = '';
			}
			this.filtered = this.searchForm;
		},
		searchFilter() {
			this.filtered = this.searchStatus;
		},
		clearFilter() {
			console.clear();
			this.filtered = null;
			this.search = null;
			// this.selectedStatus = 'ALL';
			this.selectedSupplier = 'ALL';
		},
		getTableNested() {
			CapaService.getTableNested().then((response) => {
				this.nested = response.data.data.data;
				this.suppliers = [];
				this.suppliers.push('ALL');
				response.data.data.data.forEach((element) => {
					this.suppliers.push(element.vendor_name);
				});

			});
		},
	},
};
</script>
