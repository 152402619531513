<!--<template>-->
<template>
	<div class="Grid">
		<Toast />

		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<!--  Search  -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search CAPA</div>
				<div class="content-body">
					<div class="col-12 p-fluid">
						<div class="grid formgrid" style="margin: 1em 0 0 0">
							<div class="col-3 mb-5 lg:lcol-3">
								<span class="p-float-label">
									<CompaniesDropdown
										v-model="company"
										class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
										:disabled="
											!(userInfo
												? userInfo.leveltenant === 2
												: false)
										"
									/>
									<!-- <label for="inputtext">Select a company</label> -->
								</span>
							</div>
							<div class="col-3 mb-5 lg:lcol-3">
								<span class="p-float-label">
									<SupplierDropdown
										:disabled="
											userInfo
												? userInfo.leveltenant === 2
												: false
										"
										v-model="supplier"
										:company="company ? company : null"
										class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
										:key="supplierReload"
									/>
									<!-- <label for="inputtext">Select a company</label> -->
								</span>
							</div>
							<div class="col-3 mb-5 lg:lcol-3">
								<span class="p-float-label">
									<InputtextDocumentNumber
										v-model="documentNumber"
									></InputtextDocumentNumber>
									<!-- <label for="inputtext">Status</label> -->
								</span>
							</div>
							<div class="col-3 mb-5 lg:lcol-3">
								<span class="p-float-label">
									<DropdownStatusView
										class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
										v-model="status"
									></DropdownStatusView>
									<!-- <label for="inputtext">Status</label> -->
								</span>
							</div>
							<div
								v-if="
									!(userInfo
										? userInfo.leveltenant === 2
										: false)
								"
								class="col-3 mb-5 lg:lcol-3"
							>
								<span class="p-float-label">
									<PreparerDropdown
										class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
										placeholder="Select a preparer"
										v-model="preparer"
									/>
								</span>
							</div>
							<div class="col-1">
								<Button
									label="Search"
									@click="goClick"
									:disabled="disabledButton"
								/>
							</div>
							<div class="col-2">
								<Button
									label="Clear"
									class="p-button-info"
									@click="clearClick"
									:resetSearch="true"
									:disabled="disabledButton"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search Result</div>
				<div class="content-body">
					<div class="grid">
						<div class="col-auto ml-auto mb-4">
							<Button
								v-if="showButtonAdd"
								class="p-button-success"
								@click="handleAddNewCapa"
							>
								<span class="uppercase font-bold"
									>Add New CAPA</span
								>
							</Button>
						</div>
						<div class="col-12">
							<TableViewCapa
								:company="company ? company : null"
								:supplier="supplier ? supplier : null"
								:documentNumber="
									documentNumber ? documentNumber : null
								"
								:status="status ? status : null"
								:preparer="preparer ? preparer : null"
								:searchAction="
									searchAction ? searchAction : null
								"
								:key="reload"
							>
							</TableViewCapa>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import router from '@/router';

// Import service
import AuthService from '@/service/AuthService';
import LovService from '@/service/LovService';

// Import Component
import CompaniesDropdown from '../components/CompaniesDropdown.vue';
import SupplierDropdown from '../components/SupplierDropdown.vue';
import TableViewCapa from './components/TableViewCapa.vue';
import DropdownStatusView from './components/DropdownStatusView.vue';
import InputtextDocumentNumber from './components/InputtextDocumentNumber.vue';
// import Component VueJs
// import { useToast } from "primevue/usetoast";
import SimpleCrypto from 'simple-crypto-js';
import { ref, watch } from 'vue';
import PreparerDropdown from '../components/PreparerDropdown.vue';
import {filterAuthByVendorname} from "@/helper/filterAuthByVendorname";

const breadcrumbHome = { icon: 'pi pi-home', to: '/dashboard' };
const breadcrumbItems = [{ label: 'CAPA', to: './capa' }];
const company = ref();
// const column = ref();
// const columnSearch = ref();
// const toast = useToast();
const reload = ref(0);
// const page = ref(1);
// const searchReload = ref(0);
const isSupplierPage = ref();
const supplier = ref(null);
const supplierReload = ref(0);
const userInfo = ref();
const levelTenant = ref();
const documentNumber = ref();
const status = ref();
const preparer = ref();
const showButtonAdd = ref(false);
const searchAction = ref();
const disabledButton = ref(true);
const userCompany = () => {
	const secretKey = 'some-unique-key';
	const simpleCrypto = new SimpleCrypto(secretKey);
	return simpleCrypto.decrypt(sessionStorage.getItem('dropdownCompany'));
};
watch(
	() => company.value,
	() => {
		// supplier.value = null;
		supplierReload.value++;
		reload.value++;
	}
);

AuthService.whoAmi().then(async (response) => {
	userInfo.value = response.data.data;
	if (response.status === 200) {
		levelTenant.value = response.data.data.leveltenant;
		const respCbN = await LovService.getCompanyByName(userCompany());
		const companyTemp = respCbN.data.data[0];
		if (levelTenant.value !== 2) {
			company.value = companyTemp;
			isSupplierPage.value = false;
			showButtonAdd.value = true;
			disabledButton.value = false;
			// loading.value = true
		} else {
			company.value = companyTemp;
			isSupplierPage.value = true;
			showButtonAdd.value = false;
			if (companyTemp && !supplier.value) {
				const param = {
					db_instance: companyTemp.db_instance,
					org_id: companyTemp.value,
				};

				const checkAuth = await filterAuthByVendorname({
					info_id: userInfo.value.id,
					dbInstance: param.db_instance,
					value: param.org_id
				});

				if(checkAuth.supplier === null) await router.push("/dashboard");

				supplier.value = checkAuth.supplier;
				disabledButton.value = false;
			}
			// loading.value = true
		}
	}
});

const goClick = async () => {
	reload.value++;
	searchAction.value = true;
	// if ((column.value === undefined &&  (columnSearch.value === undefined || columnSearch.value === ''))) {
	//     reload.value++;
	//   } else if ((column.value === undefined &&  (columnSearch.value !== undefined || columnSearch.value !== '')) ||
	//       (column.value !== undefined &&  (columnSearch.value === undefined || columnSearch.value === ''))) {
	//     toast.add({severity:'info', summary: 'Kolom kriteria dan nilai kosong', detail:'Pilih kolom kriteria dan isi nilai untuk kolom sebelum tekan tombol Go', life: 3000});
	//   } else {
	//     reload.value++;
	// }
};
const clearClick = () => {
	searchAction.value = false;
	documentNumber.value = null;
	status.value = null;
	preparer.value = null;
	if (userInfo.value.leveltenant === 2) {
		company.value = null;
	} else {
		supplier.value = null;
	}

	reload.value++;
};
const handleAddNewCapa = () => {
	router.push({
		name: 'AddCapa',
		params: { id: 0 },
	});
};
</script>

<style scoped></style>
