<template>
    <div class="col-12 mb-4">      
        <Toast />

        <ProgressModal :progress="loading">
			<template #message>
				Saving invoice in progress. Please wait...
			</template>
		</ProgressModal>

        <ProgressModal :progress="loadingBAST">
			<template #message>
				Find bast detail. Please wait...
			</template>
		</ProgressModal>

        <div class="col-12">
            <div class="content-wrapper">
                <div class="content-header">
                    Create E-Invoice
                </div>

                <div class="content-body">
                    <div class="grid">
                        <div class="col-12 lg:col-4">
                            <div class="mb-5">
                                <span class="p-float-label ">
                                    <AutoComplete 
                                        class="w-full"
                                        :dropdown="true"
                                        v-model="company"
                                        :class="{
											'p-invalid': v$.company.$error,
										}"
                                        :suggestions="filteredCompany"
                                        field="name"
                                        forceSelection
                                        id="company"
                                        :disabled="status === InvoiceStatusEnum.Process || status === InvoiceStatusEnum.Submit"
                                        @complete="searchCompany($event)"
                                    >
                                        <template #item="{ item }">
                                            <div>
                                                {{ item.name }}
                                            </div>
                                        </template>    
                                    </AutoComplete>
                                    <label for="company">Company</label>
                                </span>
                                <div
                                    v-if="v$.company.$error"
                                    class="mt-1"
                                >
                                    <small
                                        class="p-error"
                                        style="font-size: 12px"
                                    >
                                        {{ v$.company.$errors[0].$message.replace('Value', 'Company') }}
                                    </small>
                                </div>
                            </div>
            
                            <div class="mb-5">
                                <span class="p-float-label">
                                    <InputText 
                                        class="w-full"
                                        id="invoiceNumber"
                                        v-model="invoiceNumber"
                                        :disabled="status === InvoiceStatusEnum.Process || status === InvoiceStatusEnum.Submit"
                                    />
                                    <label for="invoiceNumber">Invoice No</label>
                                </span>
                                <div
                                    v-if="
                                        v$.invoiceNumber.$error
                                    "
                                    class="mt-1"
                                >
                                    <small
                                        class="p-error"
                                        style="font-size: 12px"
                                    >
                                        {{ v$.invoiceNumber.$errors[0].$message.replace('Value', 'Invoice No') }}
                                    </small>
                                </div>
                            </div>      
                       
                            <div class="mb-5">
                                    <span class="p-float-label">
                                        <Calendar
                                            class="w-full"
                                            v-model="invoiceDate"
                                            id="invoiceDateId"
                                            dateFormat="d-M-yy"
                                            :showIcon="true"
                                            :disabled="status === InvoiceStatusEnum.Process || status === InvoiceStatusEnum.Submit"
                                        />
                                        <label for="invoiceDateId">Invoice Date</label>
                                    </span>
                                    <div
                                        v-if="
                                            v$.invoiceDate.$error
                                        "
                                        class="mt-1"
                                    >
                                        <small
                                            class="p-error"
                                            style="font-size: 12px"
                                        >
                                            {{ v$.invoiceDate.$errors[0].$message.replace('Value', 'Invoice Date') }}
                                        </small>
                                    </div>
                            </div>
                            
                            <div class="mb-5">
                                <span class="p-float-label">
                                    <Textarea 
                                        class="w-full"
                                        rows="4"
                                        v-model="description"
                                        id="descriptionId"
                                        :disabled="status === InvoiceStatusEnum.Process || status === InvoiceStatusEnum.Submit"
                                    />
                                    <label for="descriptionId">Description</label>
                                </span>
                                <div
                                    v-if="
                                        v$.description.$error
                                    "
                                    class="mt-1"
                                >
                                    <small
                                        class="p-error"
                                        style="font-size: 12px"
                                    >
                                        {{ v$.description.$errors[0].$message.replace('Value', 'Description') }}
                                    </small>
                                </div>
                            </div>

                            <div class="mb-5">
                                <span class="p-float-label">
                                    <AutoComplete 
                                        class="w-full"
                                        :dropdown="true"
                                        v-model="bank"
                                        :suggestions="filteredBank"
                                        field="name"
                                        forceSelection
                                        id="bankId"
                                        @complete="searchBank($event)"
                                        :disabled="status === InvoiceStatusEnum.Process || status === InvoiceStatusEnum.Submit"
                                    />
                                    <label for="bankId">Bank</label>
                                </span>
                                <div
                                    v-if="
                                        v$.bank.$error
                                    "
                                    class="mt-1"
                                >
                                    <small
                                        class="p-error"
                                        style="font-size: 12px"
                                    >
                                        {{ v$.bank.$errors[0].$message.replace('Value', 'Bank') }}
                                    </small>
                                </div>
                            </div>
                          
                            <div class="mb-5 w-full">
                                <span class="p-float-label">
                                    <AutoComplete 
                                        class="w-full"
                                        v-model="bast"
                                        :dropdown="true"
                                        :suggestions="filteredBAST"
                                        field="BAST_NUMBER"
                                        forceSelection
                                        id="bastId"
                                        :disabled="status"
                                        @complete="searchBAST($event)"
                                    />
                                    <label for="bastId">Bast</label>
                                </span>
                                <div
                                    v-if="
                                        v$.bast.$error
                                    "
                                    class="mt-1"
                                >
                                    <small
                                        class="p-error"
                                        style="font-size: 12px"
                                    >
                                        {{ v$.bast.$errors[0].$message.replace('Value', 'BAST') }}
                                    </small>
                                </div>
                            </div>
               
                        </div>

                        <div class="col-12 md:col-6 lg:col-4">
                            <div class="mb-5">
                                <span class="p-float-label">
                                    <InputText
                                        @change="handleChangeTax($event)"
                                        :disabled="status === InvoiceStatusEnum.Process || status === InvoiceStatusEnum.Submit"
                                        class="w-full"
                                        id="taxInvoiceNoId"
                                        v-model="taxInvoiceNo"
                                    />
                                    <label for="taxInvoiceNoId">Tax Invoice No</label>
                                </span>
                                <div
                                    v-if="
                                        v$.taxInvoiceNo.$error
                                    "
                                    class="mt-1"
                                >
                                    <small
                                        class="p-error"
                                        style="font-size: 12px"
                                    >
                                        {{ v$.taxInvoiceNo.$errors[0].$message.replace('Value', 'Tax Invoice No') }}
                                    </small>
                                </div>
                            </div>
                         
                            <div class="mb-5">
                                <span class="p-float-label">
                                    <Calendar
                                        id="taxInvoiceDate"
                                        v-model="taxInvoiceDate"
                                        dateFormat="d-M-yy"
                                        :showIcon="true"
                                        class="w-full"
                                        :disabled="status === InvoiceStatusEnum.Process || status === InvoiceStatusEnum.Submit"
                                    />
                                    <label for="taxInvoiceDate">Tax Invoice Date</label>
                                </span>
                                <div
                                    v-if="
                                        v$.taxInvoiceDate.$error
                                    "
                                    class="mt-1"
                                >
                                    <small
                                        class="p-error"
                                        style="font-size: 12px"
                                    >
                                        {{ v$.taxInvoiceDate.$errors[0].$message.replace('Value', 'Tax Invoice Date') }}
                                    </small>
                                </div>
                            </div>
                            
                            <InputText 
                                placeholder="Status"
                                :disabled="true"
                                class="w-full mb-5"
                                v-model="status"
                            />

                            <div class="mb-5 w-full">
                                <FileUpload
                                    ref="fileUpload"
                                    mode="advanced"
                                    class="w-full"
                                    :class="{
                                        'error-file': v$.file.$error,
                                    }"
                                    chooseLabel="Upload File"
                                    fileLimit="1"
                                    accept="application/pdf"
                                    :maxFileSize="5000000"
                                    :showUploadButton="false"
                                    :showCancelButton="false"
                                    :customUpload="true"
                                    @select="onSelectFile"
                                    @remove="onRemoveFile"
                                />
                            </div>

                      
                        </div>

                        <div class="col-12 lg:col-4">
                            <div class="form-card mb-5">
                                <div
                                    class="flex align-items-center justify-content-between mb-4"
                                >
                                    <span style="color: #000" class="font-semibold"
                                        >INVOICE AMOUNT</span
                                    >
                                    <span class="font-bold">
                                        {{ 
                                            (totalDPP &&
                                                formattingRound(totalDPP).toFixed(2)
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ','
                                                    ))
                                        }}
                                    </span>
                                </div>
                                <div
                                    class="flex align-items-center justify-content-between mb-4"
                                >
                                    <span style="color: #000" class="font-semibold"
                                        >TAX AMOUNT</span
                                    >
                                    <span class="font-bold">
                                        {{ (totalPPN && formattingRound(totalPPN)
											.toFixed(2)
											.replace(
												/\B(?=(\d{3})+(?!\d))/g,
												','
											)) }} </span>
                                </div>
                                <div
                                    class="flex align-items-center justify-content-between mb-4"
                                >
                                    <span style="color: #000" class="font-semibold"
                                        >TOTAL</span
                                    >
                                    <span class="font-bold">
                                        {{ 
                                            (totalInvoice &&
                                                formattingRound(totalInvoice)
                                                .toFixed(2)
                                                .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ','
                                                )
                                            )
                                        }}
                                    </span>
                                </div>
                                <div
                                    class="flex align-items-center justify-content-between"
                                >
                                    <span style="color: #000" class="font-semibold"
                                        >CURRENCY</span
                                    >
                                    <span class="font-bold">
                                        {{
                                            currency
                                        }}
                                    </span>
                                </div>
                            </div>

                            <div v-if="status === InvoiceStatusEnum.Reject" class="w-full">
                                <span>Reject Reason :</span>
                                <div class="mt-2 font-bold text-justify">{{ rejectReason }}</div>
                            </div>
                        </div>
                        
                    </div>

                    <div v-if="status" class="content-wrapper mb-8">
                        <div
                            class="content-header text-base font-bold"
                            style="color: #555 !important"
                        >
                            Attachment(s)
                        </div>
                        <div class="content-body">
                            <!-- Input Form -->
                            <div class="grid">
                                <div class="col-auto">
                                    <div v-if="savedFile" class="flex">
                                        <Button
                                            v-if="!loadingDownladFile"
                                            class="button-download font-normal"
                                            :label="savedFile.filename"
                                            icon="pi pi-download"
                                            @click="onDownload()"
                                        />
                                        <Button
                                            v-if="loadingDownladFile"
                                            class="button-download font-normal"
                                            :label="`${savedFile.filename} is downloading`"
                                            icon="pi pi-download"
                                            :disabled="true"
                                        />
                                        <Button
                                            v-if="status === 'DRAFT' && !invoiceId && currentUser && (currentUser.leveltenant === 2 || currentUser.leveltenant === 3)"
                                            type="button"
                                            class="p-button-rounded p-button-text ml-2"
                                            icon="pi pi-trash"
                                            @click="handleDeleteFile"
                                        />
                                    </div>
                                    <div v-else>&dash;</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid mb-6">
                        <div class="col-12">
                            <DataTable 
                                class="w-full" 
                                :value="filteredTable"
                                scrollHeight="600px"
                                scrollDirection="both"
                                responsiveLayout="scroll"
                                :scrollable="true"
                            >
                                <Column header="No Work Confirmation / BAST" field="bast_number" style="width: 25%;"/>
                                <Column header="PO Number" field="po_number" style="width: 15%;"/>
                                <Column header="PO Line" field="line_num" style="width: 15%;"/>
                                <Column header="Termin" field="termin" style="width: 15%;"/>
                                <Column header="Qty" field="quantity" style="width: 15%;"/>
                                <Column header="Price" field="price_override" style="width: 15%;">
                                    <template #body="{data}">
                                        <span>{{ 
                                            formattingRound(data.price_override)
                                            .toFixed(2)
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ','
                                            ) }}
                                        </span>
                                    </template>
                                </Column>
                                <Column header="Amount" field="total_dpp" style="width: 15%;">
                                    <template #body="{data}">
                                        <span>
                                            {{ 
                                                formattingRound(data.total_dpp).toFixed(2)
                                                .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ','
                                                )
                                            }}
                                        </span>
                                    </template>
                                </Column>
                                <Column header="Qty Progress" field="quantity_wo" style="width: 15%;">
                                    <template #body="{data}">
                                        {{ data.quantity_received * data.quantity_wo }}
                                    </template>
                                </Column>
                                <Column header="Confirm Amount" style="width: 15%;">
                                    <template #body="{data}">
                                        <span>
                                            {{ 
                                                (data.price_override * data.quantity_received)
                                                .toFixed(2)
                                                .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ','
                                                ) 
                                            }}
                                        </span>
                                    </template>
                                </Column>
                                <Column header="Confirm Progress (%)" style="width: 15%;">
                                    <template #body="{data}">
                                        <span>{{ ((data.quantity_received * data.quantity_wo)/data.quantity * 100) }}</span>
                                    </template>
                                </Column>
                                <Column header="VAT" style="width: 15%">
                                    <template #body="slotProps">
                                        <AutoComplete 
                                            forceSelection
                                            :dropdown="true"
                                            :suggestions="filteredVAT"
                                            @complete="searchVAT($event)"
                                            @change="onChangeVATValue(slotProps.index)"
                                            v-model="slotProps.data.vat_code"
                                            field="VAT_CODE"
                                            @item-select="onChangeVAT($event, slotProps.index)"
                                        />
                                    </template>
                                </Column>
                                <Column header="WHT" style="width: 15%">
                                    <template #body="slotProps">
                                        <AutoComplete 
                                            forceSelection
                                            :dropdown="true"
                                            :suggestions="filteredWHT"
                                            @complete="searchWHT($event)"
                                            @change="onChangeWHTValue(slotProps.index)"
                                            v-model="slotProps.data.wht_code"
                                            field="WHT_CODE"
                                            @item-select="onChangeWHT($event, slotProps.index)"
                                        />
                                    </template>
                                </Column>
                                <template #empty>
                                    <span>Data Not Found</span>
                                </template>
                            </DataTable>
                        </div>
                    </div>

                    <div class="grid justify-content-end">
                        <div class="col-12 md:col-6 lg:col-3" v-if="currentUser && (currentUser.leveltenant === 2 || currentUser.leveltenant === 1)">
                            <Button v-if="!status" :disabled="loading" 
                            class="p-button-success w-full"  @click="handleSave('S')">
                                <span class="w-full font-bold">SAVE</span>
                            </Button>
                            <Button v-if="status && status === InvoiceStatusEnum.Draft"  
                            class="p-button-success w-full"  @click="handleSave('U')">
                                <span class="w-full font-bold">SAVE</span>
                            </Button>
                        </div>

                        <div class="col-12 md:col-6 lg:col-3" v-if="!status">
                            <Button class="p-button-info w-full" @click="handleSave('SU')">
                                <span class="w-full font-bold">SUBMIT</span>
                            </Button>
                        </div>
                        <div class="col-12 md:col-6 lg:col-3" v-if="status && status === InvoiceStatusEnum.Draft">
                            <Button class="p-button-info w-full" @click="handleSave('SU')">
                                <span class="w-full font-bold">SUBMIT</span>
                            </Button>
                        </div>

                        <div 
                            v-if="status && (!isHaveBatch || isHaveBatch.length == 0) "
							class="col-12 md:col-6 lg:col-3">
							<Button
                                v-if="status !== 'REJECTED'"
								class="p-button-warning justify-content-center w-full"
								@click="handleBatch"
							>
								<span class="font-bold uppercase"
									>Add to Batch</span
								>
							</Button>
						</div>	
                        <div class="col-12 md:col-6 lg:col-3">
							<Button
                                v-if="status !== 'REJECTED'"
								class="p-button-danger justify-content-center w-full"
								@click="handlePrintCreateInvoice"
							>
								<span class="font-bold uppercase"
									>Print Goods Receipt Note</span
								>
							</Button>
						</div>	
                        <div class="col-12 md:col-6 lg:col-3">
							<Button
								class="justify-content-center p-button-secondary w-full"
								@click="$router.back()"
								><span class="w-full font-bold">BACK</span>
						        </Button
							>
						</div>
                    </div>

                    <Dialog
                        header="Add To Batch"
                        :draggable="false"
                        :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
                        :style="{ width: '25vw', overflow: 'hidden' }"
                        :modal="true"
                        :dismissableMask="true"
                        v-model:visible="displayModalBatch"
                    >
                        <div class="pt-3">
                            <AddToBatchModal
                                :dbInstance="company.db_instance"
                                :orgId="company.value"
                                :vendorId="vendorId"
                                @on-close="displayModalBatch = false"
                                @on-save="handleAddToBatch"
                            />
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    </div>
</template> 

<script setup>
    import {computed, onMounted, onUnmounted, ref, watch} from "vue"
    import {  useRoute, useRouter } from "vue-router";
    import {fakeBAST} from "./fakedata"
    import { useVuelidate } from '@vuelidate/core';
    import { required } from '@vuelidate/validators';
    import { useToast } from 'primevue/usetoast';
    import AuthService from '../../service/AuthService';
    import SimpleCrypto from 'simple-crypto-js';
    import LovService from '../../service/LovService';
    import PoFulfillment from '../../service/PoFulfillment';
    import InvoicePOService from "../../service/InvoicePOService";
    import PendingPOService from "../../service/PendingPOService";
    import PlanningOrderService from "../../service/PlanningOrderService";
    import Swal from 'sweetalert2'
    import {formattingDate} from "../../helper/formattingDate";
    import { InvoiceTypeEnum, PICEnum } from "../../constants/POTypeEnum";
    import ProgressModal from '../components/ProgressModal.vue';
    import { InvoiceStatusEnum } from "../../constants/InvoiceStatusEnum";
    import InvoiceNonPOservice from "../../service/InvoiceNonPOservice";
    import AddToBatchModal from "../components/AddToBatchModal.vue";
    import { formatRounding, formatCeil, formatFloor } from "../../helper/formatRounding";
import { UrlTab } from "../../constants/UrlTab";
    
  
    const router = useRouter();
    const toast = useToast();
    const route = useRoute();

    // const cannotZero = (value) => value !== 0;

    //init form
    const filteredVAT = ref([]);
    const isHaveBatch = ref(null);
    const getVAT = ref([]);
    const company = ref(null);
    const bank = ref(null);
    const bast = ref(null);
    const file = ref(null);
    const invoiceNumber = ref(null)
    const bankId = ref(null);
    const taxInvoiceDate = ref(null)
    const invoiceDate = ref(null);
    const description = ref(null);
    const taxInvoiceNo = ref(null);
    const errorExisting = ref(false);
    const currentUser = ref(null);
    const totalDPP = ref(0);
    const totalPPN = ref(0)
    const totalInvoice = ref(0);
    const vendorId = ref(null);
    const invoiceId = ref(sessionStorage.invoiceId);
    const status = ref();
    const rejectReason = ref();
    const disabledCompany = ref();
    const savedFile = ref();
    const loading = ref(false);
    const loadingBAST = ref(false);
    const savedInvoiceDetails = ref();
    const deletedFile = ref();
    const vendorName = ref();
    const loadingDownladFile = ref(false);
    const fileBuffer = ref(null);
    const headerId = ref(null);
    const getWHT = ref();
    const filteredWHT = ref([]);
    const currency = ref(null);
    const rounder = ref();
    const displayModalBatch = ref(false);

    const validationRules = computed(() => {
        let rules = {
            company: { required},
            invoiceNumber: { required },
            invoiceDate: { required },
            description: { required },
            taxInvoiceNo: { required },
            taxInvoiceDate: { required },
            bank: { required },
            file: { required },
            bast: { required },
            totalDPP: {required},
            totalPPN: {required},
            totalInvoice: {required}
        };

        return rules;
    });

    const v$ = useVuelidate(validationRules, {
        company,
        invoiceNumber,
        invoiceDate,
        description,
        taxInvoiceNo,
        taxInvoiceDate,
        bank,
        bast,
        file,
        totalDPP,
        totalPPN,
        totalInvoice
    });
   

    const filteredTable = ref([])

    //bast
    const listBAST = ref(fakeBAST(10))
    const filteredBAST = ref([]);
    // const tableData = ref(fakeDataProcurement(100, listBAST.value))

    //company
    const filteredCompany = ref([]);
    const companyList = ref([])


    //bank
    const filteredBank = ref([]);
    const bankList = ref([])


    //rules
    const disableSubmit = ref(false);


    


    //watcer
    watch(company, () => {handleReset(), console.log(v$.company, "VADAD")});
    watch(status, (text) => {
        console.log(text, "STATUS")

        if(text !== InvoiceStatusEnum.Process){
            disableSubmit.value = false;
        }else{
            disableSubmit.value = true;
        }
    })

    const handleReset = () => {
        invoiceNumber.value = null;
        invoiceDate.value = null;
        description.value = null;
        bank.value = null;
        bast.value = null;
        file.value = null;
        taxInvoiceDate.value = null;
        taxInvoiceNo.value = null;
        v$.value.$reset();
    }

    const handleAddToBatch = async (batchId) => {
        const payload = {
            batch_id: batchId,
            einvoice_id: route.params.id,
            amount: totalInvoice.value,
            creation_date: formattingDate(new Date(), true),
            created_by: currentUser.value.id,
            last_update_date: formattingDate(new Date(), true),
            last_updated_by: currentUser.value.id,
        };

        try {
            loading.value = true;
            const res = await InvoicePOService.addToBatch(payload);

            if (res.status === 201) {
                displayModalBatch.value = false;
                status.value !== 'DRAFT' ? console.log("OK") : status.value === 'OPEN';
                toast.add({
                    severity: 'success',
                    summary: 'Successfully add invoice to batch',
                    life: 3000,
                });
                await getInvoiceDetail(route.params.id);
            }
            loading.value = false;
        } catch (err) {
            console.log(err);
            loading.value = false;
            toast.add({
                severity: 'error',
                summary: 'Server error: Unsuccessfully add to batch',
                life: 3000,
            });
        }
    };



    const onChangeVATValue = async (index) => {
        
        filteredTable.value[index].percentage_rate = null;
        filteredTable.value[index].vat_code = null;
        filteredTable.value[index].vat_code_id = null;
        filteredTable.value[index].tax_rate_code = null;
        filteredTable.value[index].change = true;
        await getTotalPPN();

    };

    const onChangeWHTValue = async (event, index) => {
        filteredTable.value[index].wht_code = null;
    };

    const getCompany = async () => {
        if (currentUser.value.userid === 'dexaadmin') {
            // If user is admin
            const companyRes = LovService.getValueType('COMPANY');
            companyList.value = companyRes.data.data;
        } else if (currentUser.value.leveltenant === 2 || currentUser.value.leveltenant === 3) {
            // If user is supplier
            let payload = currentUser.value.id;
            const companySuppRes = await PoFulfillment.getCompanySupp(payload);

            try {
                const companySupp = companySuppRes.data.data;
                const companyValues = await Promise.all(
                    companySupp.map(async (element) => {
                        const res = await LovService.getCompanyByName(
                            element.company
                        );

                        const secretKey = 'some-unique-key';
                        const simpleCrypto = new SimpleCrypto(secretKey);
                        const decipherText = simpleCrypto.decrypt(
                            sessionStorage.getItem('dropdownCompany')
                        );

                        if (res.data.data[0].name === decipherText) {
                            company.value = res.data.data[0];
                        }

                        return res.data.data[0];
                    })
                );

                companyList.value = companyValues;
            } catch (e) {
                console.log(e);
                if (e !== 'Break') throw e;
            }
        } else if (currentUser.value.leveltenant === 1) {
            // If user is Finance
            console.log("IS FINANCE")
            const res = await LovService.getcompanydb(currentUser.value.bioaddress);
            companyList.value = res.data.data;
            console.log(res, "RESPONSE")
            try {
                const secretKey = 'some-unique-key';
                const simpleCrypto = new SimpleCrypto(secretKey);
                const decipherText = simpleCrypto.decrypt(
                    sessionStorage.getItem('dropdownCompany')
                );
                console.log("STEP ON")
                companyList.value.forEach((element) => {
                    if (element.name === decipherText) {
                        console.log(element, "ELEMENTAL")
                        company.value = element;
                        return;
                    }
                });

                
            } catch (e) {
                if (e !== 'Break') throw e;
            }
        }
    };

    watch(invoiceDate, async () =>{
        await getDropdownVat();
    })

    const getListBank = async (orgId, dbInstance, vendorId) => {
        const payload = {
            orgId,
            dbInstance,
            vendorId,
        };

        const res = await InvoicePOService.getListBank(payload);
    
        if (res.status === 200) {
            bankList.value = res.data.data.map((item) => {
                return {
                    id: item.ext_bank_account_id,
                    name: item.transfer_to,
                };
            });
            console.log(bankList.value, "TEST PREP")
            bank.value = bankList.value.find(
                (item) => item.id === bankId.value
            );
        }
    };

    //mounted
    onMounted(async () => {
        const resAuth = await AuthService.whoAmi();
	    currentUser.value = resAuth.data.data;
        console.log(route.params.date, "DAT")
        const parseDate = new Date(Number(route.query.date) ?? undefined)
        const dates = formatDateDexa(parseDate);
        console.log(currentUser.value, "TEST")
        if(currentUser.value.leveltenant === 2){
            await getCompany();
            await getVendorId();
            const payload = {
                vendorId: vendorId.value,
                orgId: company.value.value,
                dbInstance: company.value.db_instance,
                poNum: route.params.id,
                date: dates
            }

            const respVendor = await PoFulfillment.getSuppBindCompProc(
                resAuth.data.data.id
            );

            vendorName.value = respVendor.data.data[0][0].vendorname;

            if(route.query.v){
                await getListBank(
                    company.value.value,
                    company.value.db_instance,
                    vendorId.value
                );
                await getInvoiceDetail(route.params.id);
                await getDropdownVat();
                await getDropdownWHT();
            }else{
                await getListBank(company.value.value,sessionStorage.companydb,vendorId.value);
                await getListBAST(payload);
                await getDropdownVat();
                await getDropdownWHT();
            }
        }else if(currentUser.value.leveltenant === 1){
            await getCompany();
            let payload = {
                orgId: company.value.value,
                dbInstance: company.value.db_instance,
                poNum: route.params.id,
                date: dates
            }

            let payloadDev = {po_number: route.params.id}

            if(route.query.v){
                payloadDev.type = PICEnum.DetailProcurement
            }else{
                payloadDev.type = PICEnum.Procurement
            }

            const res = await PendingPOService.getVendorForPic(payloadDev)
            vendorName.value = res.data.data.vendor_name
            vendorId.value = res.data.data.vendor_id
            payload.vendorId = vendorId.value
            if(route.query.v){
                await getInvoiceDetail(route.params.id);
            }else{
                await getListBAST(payload);
            }
            await getDropdownVat();
            await getDropdownWHT();

            await getListBank(
                company.value.value,
                company.value.db_instance,
                vendorId.value
            );
        }else{
            await getCompany();

            await getInvoiceDetail(route.params.id);
            await getDropdownVat();
            await getDropdownWHT();
        }

    });
    
    const formatDateDexa = (parseDate) => {
        var date = new Date(parseDate);
        var respondDate =
            ('00' + (date.getMonth() + 1)).slice(-2) +
            '/' +
            ('00' + date.getDate()).slice(-2) +
            '/' +
            date.getFullYear() +
            ' ' +
            ('00' + date.getHours()).slice(-2) +
            ':' +
            ('00' + date.getMinutes()).slice(-2) +
            ':' +
            ('00' + date.getSeconds()).slice(-2);
        return respondDate;
    }

    const getDropdownWHT = async () => {
        if(!company.value) return;
        const result = await InvoiceNonPOservice.getListWht(
            company.value.value,
            company.value.db_instance
        );
        const noSelect = {
            WHT_RATE: 0,
            WHT_CODE_ID: 0,
            WHT_CODE: "- No Select -"
        }
        getWHT.value = [noSelect, ...result.data.data];
    };


    const formattingRound = (value) => {
        if(!rounder.value) return formatRounding(value);
        if(rounder.value.rounded.rounding_rule_code === "UP") return formatCeil(value,rounder.value.precision)
        if(rounder.value.rounded.rounding_rule_code === "DOWN") return formatFloor(value,rounder.value.precision)

        return formatRounding(value)
    }


    const getListBAST = async (payload) => {
        try {
            const bastHeader = await PendingPOService.getBASTNumberList(payload)
            listBAST.value = bastHeader.data.data.map((item,index) => {
                return{
                    BAST_NUMBER:  item.bast_number,
                    ID: index
                }
            });

            if(listBAST.value.length === 1){
                bast.value = listBAST.value[0]
            }
        } catch (error) {
            console.log(error)
        }
    }

    const onSelectFile = (e) => {
        file.value = e.files[0];
    };

    // Remove file attachment
    const onRemoveFile = (e) => {
        if (!e.files.length) {
            file.value = null;
        }
    };

    const handleBatch = () => {
        displayModalBatch.value = !displayModalBatch.value
    }
    
    watch(bast, async () => {
        if(!bast.value) return;

        if(!status.value){
            loadingBAST.value = true;
            const payload = {
                vendorId: vendorId.value ? vendorId.value : sessionStorage.vendorId,
                orgId: company.value.value,
                dbInstance: sessionStorage.companydb,
                bastNumber: bast.value.BAST_NUMBER
            }
            const bastDetail = await PendingPOService.getBASTDetailList(payload);

            const bastData = bastDetail.data.data[0];

            if(bastData['retensi_date']){
                const dateConvert = new Date(bastData['retensi_date']);
                const parsedRetention = Date.parse(dateConvert);
                const date = new Date();
                const parsedNewDate = Date.parse(date);

                if(bastData['retensi_date'] && parsedNewDate < parsedRetention){
                   
                    Swal.fire({
                        title: 'Cannot Choose BAST!',
                        text: 'Retention Date must be lower than this time',
                        showConfirmButton: true,
                        icon: 'info'
                    })
                    filteredTable.value=null;
                    bast.value = null;
                    loadingBAST.value = false;
                    return;
                }
            }

            delete bastData['retensi_date'];

            filteredTable.value = [bastData];
            currency.value = bastData.currency_code
            const rounding = await PendingPOService.getRounded({
                vendor_id: vendorId.value,
                curr_code: currency.value,
                db_instance: company.value.db_instance
            });

            rounder.value = rounding.data.data;
            
            await getDropdownVat()
            await getTotalPPN();
            loadingBAST.value = false;
        }
    })

    //searchValue
    const searchBAST = (event) => {
        filteredBAST.value = [...listBAST.value.filter((item) => item.BAST_NUMBER.includes(event.query.trim()))]
    }
    const searchCompany = (event) => {
        filteredCompany.value = [...companyList.value.filter((item) => item.name.toLocaleUpperCase().includes(event.query.trim().toLocaleUpperCase()))]
    }
    const searchBank = (event) => {
        filteredBank.value = [...bankList.value.filter((item) => item.name.toLocaleUpperCase().includes(event.query.trim().toLocaleUpperCase()))]
    }

    const getTotalPPN = async () => {
        const invoices = [];
        filteredTable.value.forEach((po) => {
            const invoice = {
                poHeaderId: po.po_header_id,
                poNumber: po.po_number,
                poLineId: po.po_line_id,
                transactionId: po.transaction_id,
                lineNum: po.line_num,
                itemNumber: po.item_number,
                vat_code: po.vat_code
            };
            invoices.push(invoice);
        });
        const payload = {
            dbInstance: sessionStorage.companydb,
            orgId: company.value.value,
            vendorId: vendorId.value,
            invoiceDate: invoiceDate.value,
            rows: invoices,
        };
        const result = await PendingPOService.getTotalPPNProc(payload);
        if (result.status === 200) {
            totalPPN.value = result.data.data[0].total_ppn;
            totalDPP.value = result.data.data[0].total_dpp;
            totalInvoice.value = totalDPP.value + totalPPN.value;
        } else {
            toast.add({
                severity: 'error',
                summary: 'Unable to get total ppn',
                life: 3000,
            });
        }
    };

    const getVendorId = async () => {
        const resVendorName = await PoFulfillment.getSuppBindCompProc(
            currentUser.value.id
        );
        vendorName.value = resVendorName.data.data[0][0].vendorname;
        const payloadSuppVal = {
            vendorName: resVendorName.data.data[0][0].vendorname,
        };

        const resVendorId = await PlanningOrderService.getSuppValue(
            payloadSuppVal,
            company.value.db_instance
        );
        vendorId.value = resVendorId.data.data[0].vendor_id;
    };

    const handleSubmit = async () => {
        try {
            let isFormValid = await v$.value.$validate();

            if(v$.value.$silentErrors.length === 1 ){
                if(v$.value.$silentErrors[0].$property === "file"){
                    isFormValid = true
                }
            }

            if (!isFormValid) {
                const errMessage =
                    v$.value.$errors[0].$validator === 'required'
                        ? 'Please fill all required fields'
                        : v$.value.$errors[0].$message[0][0];

                loading.value = false;
                toast.add({
                    severity: 'error',
                    summary: errMessage,
                    life: 3000,
                });
                return;
            }
            
            if(!status.value) {
                const resp = await saveInvoiceProcurement();
                if(resp.status !== 200) return;
            }else{
                const resp = await handleUpdate('SU');
                console.log(resp, "RESP")
                if(resp.status !== 200) return;
            }

            const result = await PendingPOService.submitInvoice({
                id: headerId.value ? Number(headerId.value) : Number(route.params.id),
                user_id: Number(sessionStorage.idUser),
                companyname: sessionStorage.mailToCompany,
                vendorname: vendorName.value,
                invoice_no: invoiceNumber.value,
                vendorId: vendorId.value,
                invoice_type: InvoiceTypeEnum.Procurement,
                org_id: company.value.value,
				db_instance: company.value.db_instance
            });


            if(result.status === 200){
                await getInvoiceDetail(headerId.value ? headerId.value : route.params.id);
                toast.add({
                    severity: 'success',
                    summary: 'Success to submit Invoice',
                    life: 3000,
                });

                Swal.fire({
                    title: "Success To Submit Invoice",
                    text: "Please send invoice to dexa immediately!",
                    icon: "success"
                });
                
                return;
            }
            
        } catch (error) {
            toast.add({
                severity: 'error',
                summary: 'Failed to submit Invoice',
                life: 3000,
            });
        }
    }

    const handleSave = (type) => {
        Swal.fire({
            title: 'Apakah Anda yakin menyimpan perubahan ini?',
            text: 'Anda tidak dapat mengembalikan tindakan ini!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya, Lanjutkan',
            cancelButtonText: 'Tidak, Batal'
        }).then(async(result) => {
            if (result.isConfirmed) {
            // Tindakan jika pengguna mengonfirmasi
            if(type === 'S'){
                await saveInvoiceProcurement()
            }else if(type === 'SU'){
                await handleSubmit();
            }else{
                await handleUpdate();
            }

            } else if (result.dismiss === Swal.DismissReason.cancel) {
            // Tindakan jika pengguna membatalkan
            Swal.fire('Dibatalkan', 'Tindakan dibatalkan', 'info')
            }
        })

    }

    const handleDeleteFile = () => {
        deletedFile.value = savedFile.value;
        savedFile.value = null;
    };

    const saveInvoiceProcurement = async () => {
        errorExisting.value = false;
        loading.value = true;
        // Validate required fields
        const isFormValid = await v$.value.$validate();

        
        if (!isFormValid) {
            const errMessage =
                v$.value.$errors[0].$validator === 'required'
                    ? 'Please fill all required fields'
                    : v$.value.$errors[0].$message[0][0];

            loading.value = false;
            toast.add({
                severity: 'error',
                summary: errMessage,
                life: 3000,
            });
            return{
                status: 400,
                message: 'failed'
            }
        }

        // Add file payload
        const formData = new FormData();
        formData.append('file', file.value);

        // Add body payload
        const payload = {
            header: {
                BAST_NUMBER: bast.value.BAST_NUMBER,
                INVOICE_NO: invoiceNumber.value,
                INVOICE_DATE: formattingDate(invoiceDate.value),
                REVISION_NUM: 0,
                TAX_NO: taxInvoiceNo.value,
                TAX_DATE: formattingDate(taxInvoiceDate.value),
                DESCRIPTION: description.value,
                VENDOR_ID: vendorId.value,
                INVOICE_AMOUNT: totalDPP.value,
                TAX_AMOUNT: totalPPN.value,
                TOTAL_AMOUNT: totalInvoice.value,
                SERVICE_AMOUNT: 0,
                ORG_ID: parseInt(company.value.value),
                STATUS: 'DRAFT',
                CREATION_DATE: formattingDate(new Date(), true),
                CREATED_BY: currentUser.value.id,
                LAST_UPDATE_DATE: formattingDate(new Date(), true),
                LAST_UPDATED_BY: currentUser.value.id,
                EXTERNAL_BANK_ACCOUNT_ID: bank.value.id,
                INVOICE_TYPE: sessionStorage.leveltenant == 2 
				    ? InvoiceTypeEnum.Procurement : InvoiceTypeEnum.ProcurementProxy,
                DB_INSTANCE: company.value.db_instance,
                RATE_TYPE: filteredTable.value[0].rate_type,
                CURRENCY_CODE: filteredTable.value[0].currency_code,
			    VENDOR_SITE_ID: filteredTable.value[0].vendor_site_id,
            },
            details: [],
        };

        console.log(filteredTable.value, "MAPPER")
        // Add detail payload
        const details = filteredTable.value.map((item) => {
            return {
                TERMIN: item.termin,
                PO_HEADER_ID: item.po_header_id,
                PO_LINE_ID: item.po_line_id,
                PO_NUMBER: item.po_number,
                LINE_NUM: item.line_num,
                ITEM_ID: item.item_id,
                ITEM_NUMBER: item.item_number,
                ITEM_DESCRIPTION: item.item_description,
                UNIT_MEAS_LOOKUP_CODE: item.unit_meas_lookup_code,
                UNIT_PRICE: item.unit_price,
                PRICE_OVERRIDE: item.price_override,
                QUANTITY: item.quantity,
                QUANTITY_WO: item.quantity_wo,
                QUANTITY_RECEIVED: item.quantity_received,
                AMOUNT: item.total_dpp,
                ORGANIZATION_ID: item.org_id,
                LINE_LOCATION_ID: item.line_location_id,
                VAT_CODE: item.vat_code ? item.vat_code : null,
                VAT_CODE_ID: item.vat_code_id ? item.vat_code_id: null,
                PERCENTAGE_RATE: item.percentage_rate ? item.percentage_rate : null,
                RECEIPT_NUM: item.receipt_num,
                PACKING_SLIP: item.packing_slip === 'NA' ? null : item.packing_slip,
                DISTRIBUTION_NUM: item.distribution_num,
                PO_DISTRIBUTION_ID: item.po_distribution_id,
                SHIPMENT_NUM: item.shipment_num,
                CREATION_DATE: formattingDate(new Date(), true),
                CREATED_BY: currentUser.value.id,
                LAST_UPDATE_DATE: formattingDate(new Date(), true),
                LAST_UPDATED_BY: currentUser.value.id,
                CURRENCY_CODE: item.currency_code,
                REVISION_NUM: item.revision_num,
                VENDOR_ID: item.vendor_id,
                WHT_CODE: item.wht_code ? item.wht_code.WHT_CODE : null,
                VENDOR_SITE_ID: item.vendor_site_id,
                SHIPMENT_LINE_ID: item.shipment_line_id,
                SHIPMENT_HEADER_ID: item.shipment_header_id,
                RCV_TRANSACTION_ID: item.transaction_id,
            };
        });
        payload.details = details;

        // Wrap body payload with formData
        const jsonPayload = JSON.stringify(payload);
        formData.append('payload', jsonPayload);

        try {
            // progress.value = true;

            const res = await PendingPOService.saveInvoiceStandart(formData);

            headerId.value = res.data.data.einvoice_id;
          

            if (res.status === 200) {
                // progress.value = false;
                invoiceId.value = res.data.data.einvoice_id;
                invoiceNumber.value = res.data.data.invoice_no;
                status.value = 'DRAFT';
                file.value = null;

                // getAttachmentFile(invoiceId.value);
                // await getFileBase64(invoiceId.value);
                loading.value = false;

                toast.add({
                    severity: 'success',
                    summary: 'Successfully save new invoice',
                    life: 3000,
                });

                await getInvoiceDetail(headerId.value)

                setTimeout(() => {
                    router.push({
                        name: 'CreateEinvoiceProcurement',
                        params: {
                            id: headerId.value
                        },
                        query: {
                            v: company.value.value,
                            i: company.value.db_instance
                        }
                    })
                },2000);

         
                
                return{
                    status: 200,
                    message: 'failed'
                }
            }
            loading.value = false;
            // progress.value = false;
        } catch (err) {
            loading.value = false;
            console.log(err);
            // progress.value = false;
            if (err.response.status === 400) {
                toast.add({
                    severity: 'error',
                    summary: err.response.data.message,
                    life: 3000,
                });
                return{
                    status: 400,
                    message: 'failed'
                }
            }
            toast.add({
                severity: 'error',
                summary: err.response.data.message ?? 'Server error: Unsuccessfully save invoice',
                life: 3000,
            });
            return{
                status: 400,
                message: 'failed'
            }
        }
    };

    const getInvoiceDetail = async (id) => {
        try {
            loading.value = true;
            const query = route.query;

            const payload = {
                dbInstance: query.i,
                orgId: company.value.value,
                vendorId: vendorId.value,
                levelTenant: currentUser.value.leveltenant,
            };

            const res = await InvoicePOService.getInvoiceDetail(id, payload);

            if (res.status === 200) {
                loading.value = false;
                const invoiceHeader = res.data.data.header;
                bast.value = invoiceHeader.bast_number;
                const invoiceDetails = res.data.data.details;
                const invoiceFile = res.data.data.file;

                // Assign invoice header
                invoiceNumber.value = invoiceHeader.invoice_no;
                invoiceDate.value = new Date(invoiceHeader.invoice_date);
                description.value = invoiceHeader.description;
                taxInvoiceNo.value = invoiceHeader.tax_no;
                taxInvoiceDate.value = new Date(invoiceHeader.tax_date);
                status.value = invoiceHeader.status;
                currency.value = invoiceHeader.currency_code
                bank.value = bankList.value.find(
                    (item) => item.id === invoiceHeader.external_bank_account_id
                );
                bankId.value = invoiceHeader.external_bank_account_id
                rejectReason.value = invoiceHeader.reason;
                disabledCompany.value = companyList.value.find(
                    (item) => parseInt(item.value) === invoiceHeader.org_id
                )['name'];
                vendorId.value = invoiceHeader.vendor_id;

                const rounding = await PendingPOService.getRounded({
                    vendor_id: vendorId.value,
                    curr_code: currency.value,
                    db_instance: company.value.db_instance
                });

                rounder.value = rounding.data.data;

                
                // Assign invoice details
                filteredTable.value = invoiceDetails.map((item) => {
                    return {
                        ...item,
                        id: item.transaction_id,
                    };
                });

                const respBatch = await PendingPOService.findIsBatch({
                    EINVOICE_ID: route.params.id,
                    VENDOR_ID: vendorId.value,
                    DB_INSTANCE: company.value.db_instance
                });

                isHaveBatch.value = respBatch.data.data;

                console.log(filteredTable.value, "TEST NYA")
                savedInvoiceDetails.value = invoiceDetails;

                // Assign invoice file
                savedFile.value = invoiceFile;
            
            }

            await getTotalPPN();

            loading.value = false;
        } catch (err) {
            console.log(err);
            loading.value = false;
            toast.add({
                severity: 'error',
                summary: 'Invoice data is not found',
                life: 3000,
            });
        }
    };

    const handleUpdate = async (type) => {
        loading.value = true;
        let isFormValid = await v$.value.$validate();
        const property = v$.value.$errors[0].$property
        
        if(v$.value.$silentErrors.length === 1 ){
            if(v$.value.$silentErrors[0].$property === "file"){
                isFormValid = true
            }
        }

        if (!isFormValid && property !== 'file') {
            loading.value = false;
            const errMessage =
                v$.value.$errors[0].$validator === 'required'
                    ? 'Please fill all required fields'
                    : v$.value.$errors[0].$message[0][0];

            toast.add({
                severity: 'error',
                summary: errMessage,
                life: 3000,
            });
            return{
                status: 400,
                message: 'failed'
            }
        }


        // Validation select PO
        if (!filteredTable.value.length) {
            toast.add({
                severity: 'error',
                summary: 'Please select PO for saving the invoice',
                life: 3000,
            });
            loading.value = false;
            return{
                status: 400,
                message: 'failed'
            }
        }

        // Validation on total amount
        if (!totalDPP.value || totalDPP.value === 0) {
            toast.add({
                severity: 'error',
                summary: 'Invoice amount must be greater than zero',
                life: 3000,
            });
            loading.value = false;
            return{
                status: 400,
                message: 'failed'
            }
        }

        // Validation if no file to send
        if (!file.value && deletedFile.value) {
            console.log('Please select file to upload');
            toast.add({
                severity: 'error',
                summary: 'Please select file to upload',
                life: 3000,
            });
            loading.value = false;
            return{
                status: 400,
                message: 'failed'
            }
        }

        // Add file payload
        const formData = new FormData();
        formData.append('file', file.value);

        // Add body payload
        const payload = {
            header: {
                EINVOICE_ID: parseInt(route.params.id),
                INVOICE_NO: invoiceNumber.value,
                INVOICE_DATE: formattingDate(invoiceDate.value),
                REVISION_NUM: 0,
                TAX_NO: taxInvoiceNo.value,
                TAX_DATE: formattingDate(taxInvoiceDate.value),
                DESCRIPTION: description.value,
                INVOICE_AMOUNT: totalDPP.value,
                TAX_AMOUNT: totalPPN.value,
                TOTAL_AMOUNT: totalInvoice.value,
                SERVICE_AMOUNT: 0,
                LAST_UPDATE_DATE: formattingDate(new Date(), true),
                LAST_UPDATED_BY: currentUser.value.id,
                EXTERNAL_BANK_ACCOUNT_ID: bank.value.id,
                VENDOR_ID: vendorId.value,
            },
            newDetails: [],
            removedDetails: [],
            countDetails: filteredTable.value.length,
        };

        // Add new details payload
        payload.newDetails = [];
        
        payload.details = filteredTable.value
            .filter(
                (item) =>
                    savedInvoiceDetails.value.some(
                        (val) => val.transaction_id === item.id
                    )
            )
            .map((item) => {
                return {
                    EINVOICE_ID: parseInt(route.params.id),
                    PO_HEADER_ID: item.po_header_id,
                    PO_LINE_ID: item.po_line_id,
                    PO_NUMBER: item.po_number,
                    LINE_NUM: item.line_num,
                    ITEM_ID: item.item_id,
                    ITEM_NUMBER: item.item_number,
                    ITEM_DESCRIPTION: item.item_description,
                    UNIT_MEAS_LOOKUP_CODE: item.unit_meas_lookup_code,
                    UNIT_PRICE: item.unit_price,
                    QUANTITY: item.quantity_received,
                    AMOUNT: item.total_dpp,
                    ORGANIZATION_ID: item.org_id,
                    LINE_LOCATION_ID: item.line_location_id,
                    RECEIPT_NUM: item.receipt_num,
                    PACKING_SLIP:
                        item.packing_slip === 'NA' ? null : item.packing_slip,
                    DISTRIBUTION_NUM: item.distribution_num,
                    PO_DISTRIBUTION_ID: item.po_distribution_id,
                    SHIPMENT_NUM: item.shipment_num,
                    CREATION_DATE: formattingDate(new Date(), true),
                    CREATED_BY: currentUser.value.id,
                    LAST_UPDATE_DATE: formattingDate(new Date(), true),
                    LAST_UPDATED_BY: currentUser.value.id,
                    CURRENCY_CODE: item.currency_code,
                    REVISION_NUM: item.revision_num,
                    VENDOR_ID: item.vendor_id,
                    PRICE_OVERRIDE: item.price_override,
                    VENDOR_SITE_ID: item.vendor_site_id,
                    SHIPMENT_LINE_ID: item.shipment_line_id,
                    SHIPMENT_HEADER_ID: item.shipment_header_id,
                    RCV_TRANSACTION_ID: item.transaction_id,
                    VAT_CODE: item.vat_code ? item.vat_code : null,
                    VAT_CODE_ID: item.vat_code_id ? item.vat_code_id: null,
                    WHT_CODE: item.wht_code ? item.wht_code.WHT_CODE : null,
                    PERCENTAGE_RATE: item.percentage_rate ? item.percentage_rate : null,
                    EINVOICE_LINE_ID: item.einvoice_line_id
                };
            });

        console.log(payload.details, "TEST")

        // Add payload removed details
        payload.removedDetails = savedInvoiceDetails.value
            .filter(
                (item) =>
                    !filteredTable.value.some((val) => val.id === item.transaction_id)
            )
            .map((item) => ({
                ITEM_DESCRIPTION: item.item_description,
                EINVOICE_LINE_ID: item.einvoice_line_id,
                TRANSACTION_ID: item.transaction_id,
            }));

        // Deleted payload
        if (deletedFile.value) {
            payload.deletedFile = deletedFile.value;
        } else {
            payload.deletedFile = savedFile.value;
        }

        // Wrap body payload with formData
        const jsonPayload = JSON.stringify(payload);
        formData.append('payload', jsonPayload);

        try {
            // progress.value = true;
            const res = await InvoicePOService.updateInvoice(route.params.id, formData);

            if (res.status === 200) {
              
                file.value = null;
                deletedFile.value = null;

                // Re-assign saved invoice details
                await getInvoiceDetail(route.params.id);

                getAttachmentFile(route.params.id);
                // await getFileBase64(route.params.id);
                loading.value = false;
                
                if(type !== 'SU'){
                    toast.add({
                        severity: 'success',
                        summary: 'Successfully edit invoice',
                        life: 3000,
                    });
                }

                return{
                    status: 200,
                    message: 'failed'
                }
                
            }
            loading.value = false;
        } catch (err) {
            console.log(err);
            loading.value = false;

            if (err.response.status === 400) {
                toast.add({
                    severity: 'error',
                    summary: err.response.data.message,
                    life: 3000,
                });
                return{
                    status: 400,
                    message: 'failed'
                }
            }

            toast.add({
			    severity: 'error',
                summary: err.response.data.message ?? 'Server error: Unsuccessfully save invoice',
                life: 3000,
            });

            return{
                status: 400,
                message: 'failed'
            }
	    }
    };

    const getAttachmentFile = async (invoiceId) => {
        try {
            const res = await InvoicePOService.getAttachmentFile(invoiceId);

            if (res.status === 200) {
                savedFile.value = res.data.data;
            }
        } catch (err) {
            console.log(err);
        }
    };

    const onDownload = async () => {
        try {
            loadingDownladFile.value = true;

            const leveltenant = sessionStorage.getItem('leveltenant');
            let id = route.params.id;
            if (!id) {
                id = invoiceId.value;
            }

            const res = await InvoicePOService.getFileBase64(
                id,
                leveltenant,
                vendorId.value
            );

            if (res.status === 200) {
                fileBuffer.value = res.data.data;
            }
        } catch (err) {
            loadingDownladFile.value = false;
            toast.add({
                severity: 'error',
                summary: 'Connection Unstable !',
                life: 3000,
            });
            throw Error('Error downloading file');
        }
        const linkSource = `data:application/pdf;base64,${fileBuffer.value}`;
        const downloadLink = document.createElement('a');
        const fileName = savedFile.value.filename;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        loadingDownladFile.value = false;
    };

    const getDropdownVat = async () => {
        const paramRet = 'RATE';
       
        const payload = {
            paramRet: paramRet,
            dbInstance: sessionStorage.companydb,
            invoiceDate: invoiceDate.value,
        };
        const result = await InvoiceNonPOservice.getListVat(payload);
        const noSelect = {
			PARAM_RET: "",
            PERCENTAGE_RATE: 0,
            TAX_RATE_ID: 0,
            TAX_RATE_CODE: null,
            EFFECTIVE_FROM: "1998-12-31T17:00:00.000Z",
            EFFECTIVE_TO: null,
            ACTIVE_FLAG: "Y",
            ATTRIBUTE1: "SUPPLIER PORTAL",
            VAT_CODE: "- No Select -"
		}

		getVAT.value = [noSelect, ...result.data.data];
		

        filteredTable.value.forEach((item) => {
            const getValue = getVAT.value.find((Q) => Q.VAT_CODE === item.vat_code || Q.TAX_RATE_CODE === item.vat_code);
            console.log(item, "ITEM HEHE")
            item.vat_code = getValue ? getValue.VAT_CODE : null
            item.vat_code_id = getValue ? getValue.TAX_RATE_ID : null
            item.percentage_rate = getValue ? getValue.PERCENTAGE_RATE : null
            item.vat_current = getValue ? getValue.VAT_CODE : null
        })

    };

    const searchVAT = (event) => {
        if (!event.query.trim().length) {
            filteredVAT.value = [...getVAT.value]
        }else {
            filteredVAT.value = getVAT.value.filter((val) => val.VAT_CODE.toLowerCase().includes(event.query.toLowerCase()));
        } 
    }

    const searchWHT = (event) => {
        if (!event.query.trim().length) {
            filteredWHT.value = [...getWHT.value]
        }else {
            filteredWHT.value = getWHT.value.filter((val) => val.WHT_CODE.toLowerCase().includes(event.query.toLowerCase()));
        } 
    }

    const onChangeVAT = async (event, index) => {
 		//----VALIDATION FROM----
         const splitz = event.value.VAT_CODE.split(" ")
		let rule = new Array();
		if(splitz.includes("EXCL")){
			filteredTable.value.forEach((item) => {
				const sp = typeof(item.vat_code) === 'string' ? 
					item.vat_code.split(" ") : []
				if(sp.includes("INCL") === true){
					rule.push(item);
					const vatData = getVAT.value.find((Q) => Q.VAT_CODE === filteredTable.value[index].vat_current);				
					filteredTable.value[index].percentage_rate = vatData.PERCENTAGE_RATE;
					filteredTable.value[index].vat_code = vatData.VAT_CODE;
					filteredTable.value[index].vat_code_id = vatData.TAX_RATE_ID;
					filteredTable.value[index].tax_rate_code = vatData.TAX_RATE_CODE;
					return;
				}
			});
			
			if(rule.length > 0) {
		
				return
			}
		}else if(splitz.includes("INCL")){
			filteredTable.value.forEach((item) => {
				const sp = typeof(item.vat_code) === 'string' ? 
					item.vat_code.split(" ") : item.vat_code.VAT_CODE.split(" ")
				const isTrue = item.vat_code !== null && sp.includes("INCL") == false;
				if(isTrue){
					rule.push(item);
					const vatData = getVAT.value.find((Q) => Q.VAT_CODE === filteredTable.value[index].vat_current);
					filteredTable.value[index].percentage_rate = vatData.PERCENTAGE_RATE;
					filteredTable.value[index].vat_code = vatData.VAT_CODE;
					filteredTable.value[index].vat_code_id = vatData.TAX_RATE_ID;
					filteredTable.value[index].tax_rate_code = vatData.TAX_RATE_CODE;
					return;
				}
			});

		
			if(rule.length > 0) {
				toast.add({
					severity: 'error',
					summary: 'Cannot choose different type of VAT Code',
					life: 3000,
				});
				return
			}
		}
		//---VALIDATION END---

		if (
			filteredTable.value[index].total_dpp === undefined 
		) {
			filteredTable.value[index].vat_code = null;
		} else {
			const vatData = getVAT.value.find((item) => item.TAX_RATE_ID === event.value.TAX_RATE_ID);

			if (vatData) {
				filteredTable.value[index].percentage_rate = vatData.PERCENTAGE_RATE;
				filteredTable.value[index].vat_code = vatData.VAT_CODE;
				filteredTable.value[index].vat_code_id = vatData.TAX_RATE_ID;
				filteredTable.value[index].change = true;
				await getTotalPPN();
			}
		}
	};

    const unwatchInvoiceDate = watch(invoiceDate, async () => {
        if (invoiceDate.value && filteredTable.value.length) {
            await getDropdownVat();
            await getTotalPPN();
        }
    });

    onUnmounted(() => {
        unwatchInvoiceDate()
    })

	const onChangeWHT = async (event, index) => {
		if (
			filteredTable.value[index].AMOUNT === undefined ||
			filteredTable.value[index].AMOUNT === null
		) {
			filteredTable.value[index].WHT_CODE = null;
		} else {
			let whtData = getWHT.value.find((item) => item.WHT_CODE_ID === event.value.WHT_CODE_ID);
			if (whtData) {
				filteredTable.value[index].wht_code = whtData.WHT_CODE;
			}
		}
	};

    const handlePrintCreateInvoice = async () => {
        loading.value = true;
        // Validate required fields
        if(!status.value){
            errorExisting.value = false;
            const isFormValid = await v$.value.$validate();        
            if (!isFormValid) {
                const errMessage =
                    v$.value.$errors[0].$validator === 'required'
                        ? 'Please fill all required fields'
                        : v$.value.$errors[0].$message[0][0];

                loading.value = false;
                toast.add({
                    severity: 'error',
                    summary: errMessage,
                    life: 3000,
                });
                return{
                    status: 400,
                    message: 'failed'
                }
            }
        }

        loading.value = false

		let url = UrlTab() + "/printcreateinvoiceproc"

		const payload = {
			org_id: company.value.value,
			db_instance: company.value.db_instance,
			vendor_id: vendorId.value,
			invoice_no: invoiceNumber.value,
			details: [],
		};

		payload.details = filteredTable.value.map((item) => {
            return{
                ...item,
                confirm_progress: formatRounding((formatRounding(item.quantity_received * item.quantity_wo)/item.quantity) * 100),
                confirm_amount: formattingRound(item.price_override * formatRounding(item.quantity_received))
                                                .toFixed(2)
                                                .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ','
                                                ),
                qty_progress: formatRounding(item.quantity_received * item.quantity_wo),
                price:  formattingRound(item.price_override)
                                            .toFixed(2)
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ','
                                            ),
                amount: formattingRound(item.total_dpp)
                                            .toFixed(2)
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ','
                                            )
            }
        })

		sessionStorage.setItem('paramscreateinvoice', JSON.stringify(payload));
		window.open(url, 'Print', 'height=720,width=1280');
	};

    const handleChangeTax = (event) => {
        const {value} = event.target;
        taxInvoiceNo.value = value.replace(/[a-zA-Z]/g,"").substring(0,20);
    }

</script>

<style scoped>
    .button-upload{
        background-color: #d4e3fc;
        color: black;
        border: none;
        overflow: hidden;
    }
    .button-upload:hover{
        background-color: #d4e3fc;
        color: black;
        border: none;
        opacity: 0.8;
    }

    .form-card {
        padding: 24px;
        border: 1px solid #e0e0e0;
        border-radius: 16px;
    }

    .overlay{
        display: flex;
        position: absolute;
        background-color: black;
        width: 100%;
        height: 100%;
    }

</style>