/* eslint-disable */
import spborder from './api/SpbOrderApi';
import spbadmin from './api/SpbAdminApi';
import spbKkt from './api/SpbKktApi';
import { encryptForm } from '../helper/encryptForm';
class PlanningOrder {
	getSearchTableNested(params) {
		return spborder.post(
			'/spb/spb_shipmentnotice/searchnestedtable', encryptForm({...params})
		);
	}
	Getitemdesc() {
		return spborder.get('/spb/spb_shipmentnotice/itemdescription');
	}
	getSearchDropdownCompany(payload) {
		return spborder.post('/spb/spb_shipmentnotice/getsupplier', payload);
	}
	getSearchDropdownSupp(payload) {
		return spborder.post('/spb/spb_shipmentnotice/getsupsearch', payload);
	}
	getSearchDropdownComp(payload) {
		return spborder.post('/spb/spb_shipmentnotice/getsupcomp', payload);
	}
	getDoNumber() {
		return spborder.get('/spb/spb_shipmentnotice/getdonumber');
	}
	getSearchDropdownPoNum(payload) {
		return spborder.post('/spb/spb_shipmentnotice/getposearch', payload);
	}
    GetLovStatus() {
		return spborder.post('/spb/spb_shipmentnotice/getstatus');
	}
	getCompanySupp(payload) {
		return spbadmin.get(
			'/spb/usermapping/getusermappingsbyiduser/' + payload
		);
	}

	////// INI EDIT

	getIdDetail(payload) {
		return spborder.post(`/spb/spb_shipmentnotice/getiddetail/` + payload);
	}
	getSuppBindComp(idUser) {
		return spborder.post('spb/spb_planningorder/getsuppbindcomp/' + idUser);
	}
	getRecipientMail(data) {
		return spbadmin.post(
			'/spb/notification/getPlannoRecipientMailPur',
			data
		);
	}
	sendPONotifSupp(payload) {
		const url = `spb/notification/sendPOnotifsupp`;
		return spbadmin.post(url, payload);
	}
	async sendPOEmail(data) {
    	return spbKkt.post("adm/send/emailPlannoToPurchasing", data);
	}
	getSuppValue(payload) {
		return spborder.post('/spb/spb_shipmentnotice/getvaluesupp', payload);
	}
	getEmployeeID(payload) {
		return spborder.post('/spb/spb_shipmentnotice/getemployeeid', payload);
	}
}
export default new PlanningOrder();
