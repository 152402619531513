<template>
  <div class="grid">
    <div class="col-12">
      <div class="col-12 p-fluid">
        <div class="col-12 mb-5 formatText">
          <div class="text-center" style="margin-bottom: 30px">
            <h3>BATCH / REKAP PENGIRIMAN INVOICE</h3>
            <h3>
              <vue-barcode :value="batchNum"></vue-barcode>
            </h3>
          </div>
          <p>Company To: {{ titleInv }}</p>
          <div style="display: flex; justify-content: space-between">
            <p>Supplier: {{ titleSupp }}</p>
            <p>Print: {{ this.formatDateDexa(new Date()) }}</p>
          </div>
          <div style="margin-bottom: 10px">
            <p>Total Amount : <b>{{ formatCurrency(totalAmount) }}</b></p>
          </div>
          <div style="margin-bottom: 20px">
            <p>Total Amount Without Tax : <b>{{ formatCurrency(totalWithoutTax) }}</b></p>
          </div>

          <br />
          <DataTable
            :value="rooTable"
            dataKey="pr_line_num"
            :loading="loading"
            showGridlines
          >
            <Column class="mr-3" header="No">
              <template #body="slotProps">
                {{ slotProps.index + 1 }}
              </template>
            </Column>
            <Column
              class="call-button-container"
              header="Invoice No"
              field="INVOICE_NO"
            ></Column>
            <Column
              class="call-button-container"
              header="Tax No"
              field="TAX_NO"
            ></Column>
            <Column
              v-if="userId !== '6663'"
              class="call-button-container"
              dataType="numeric"
              header="Amount"
            >
              <template #body="{ data }">
                {{ formatCurrency(data.AMOUNT) }}
              </template>
            </Column>
            <Column
              v-if="userId === '6663'"
              class="call-button-container"
              dataType="numeric"
              header="Amount With Tax"
            >
              <template #body="{ data }">
                {{ formatCurrency(data.AMOUNT) }}
              </template>
            </Column>
            <Column
              v-if="userId === '6663'"
              class="call-button-container"
              dataType="numeric"
              header="Amount Without Tax"
            >
              <template #body="{ data }">
                {{ formatCurrency(data.INVOICE_AMOUNT) }}
              </template>
            </Column>
            <Column class="w-30" header="Barcode" field="ITEM_NUMBER">
              <template #body="{ data }">
                <vue-barcode
                  :value="data.INVOICE_NO"
                  :options="{ height: '40', displayValue: false }"
                ></vue-barcode>
              </template>
            </Column>
          </DataTable>
          <br />
        </div>

        <div class="p-fluid grid formgrid" v-if="!hideButton">
          <div class="col-6 mb-2 lg:col-1 lg:mb-0">
            <Button
              type="button"
              @click="printNow()"
              label="Print"
              class="p-button-primary"
            />
          </div>
          <div class="col-6 mb-2 ml-2 lg:col-1 lg:mb-0">
            <Button
              type="button"
              @click="closeWindow()"
              label="Close"
              class="p-button-secondary"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
/* eslint-disable */
import AuthService from "../service/AuthService";
import CreateBatchServices from "../service/CreateBatchServices";

export default {
  data() {
    return {
      rooTable: null,
      titleSupp: null,
      titleInv: null,
      batchNum: null,
      paramsPrint: sessionStorage.getItem("paramsPrintROO"),
      currentDate: new Date(),
      hideButton: false,
      isSupplier: true,
      totalAmount: 0,
      totalWithoutTax: 0,
      userId: null
    };
  },

  created() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();
    let hh = today.getHours();
    let m = today.getMinutes();
    let d = today.getSeconds();
    this.combinedDate = yyyy + mm + dd + hh + m + d;
    console.log(sessionStorage.getItem("idUser"), 'ID USER')
    this.userId = sessionStorage.getItem("idUser");
    document.title = "batch-report" + "-" + this.combinedDate;

    AuthService.whoAmi().then((response) => {
      if (response.status === 200) {
        // response.data.data.leveltenant = 1
        CreateBatchServices.printBatchBarcode(this.paramsPrint).then(
          (respond) => {
            
            let respData = respond.data.data.data;
            let supp = respond.data.data.data[0].VENDOR_NAME;
            let comp = respond.data.data.data[0].NAME;
            let batchNo = respond.data.data.data[0].BATCH_NO;
            // for (var s of respData){
            //   s.VENDOR_NAME = this.vendor
            // }
            this.rooTable = respData;
            this.totalAmount = respData.reduce((a, b) => a + b.AMOUNT, 0)
            this.totalWithoutTax = respData.reduce((a, b) => a + b.INVOICE_AMOUNT, 0) 
            this.titleSupp = supp;
            this.titleInv = comp;
            this.batchNum = batchNo;
            if (this.rooTable.length < 1) {
              window.alert("No data found");
              window.close();
            }
          }
        );
        // 
        if (response.data.data.leveltenant == 1) {
          
          this.isSupplier = false;
        } else {
          
        }
      }
    });
  },
  methods: {
    closeWindow() {
      window.close();
    },
    formatCurrency(value) {
      if (value) return value.toLocaleString("id-ID");
      return;
    },
    formatDate() {
      this.formatDateDexa(new Date());
    },

    formatDateDexa(date) {
      var date = new Date(date);
      var respondDate =
        ("00" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        ("00" + date.getDate()).slice(-2) +
        "/" +
        date.getFullYear() +
        " " +
        ("00" + date.getHours()).slice(-2) +
        ":" +
        ("00" + date.getMinutes()).slice(-2) +
        ":" +
        ("00" + date.getSeconds()).slice(-2);
      return respondDate;
    },

    printNow() {
      this.hideButton = true;
      setTimeout(this.actualPrintNow, 300);
    },
    actualPrintNow() {
      if (this.hideButton === true) {
        window.print();
        if (document.hasFocus()) {
          this.hideButton = false;
        }
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.formatText p {
  font-size: 16px;
}
</style>