<template>
	<div class="grid">
		<Toast />
		<div class="col-12" v-if="this.breadcrumbsDiv">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<div class="col-12" v-if="!this.breadcrumbsDiv">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItemss" />
		</div>

		<!-- Shipment Info -->
		<div class="col-12 p-fluid mb-4">
			<div class="content-wrapper">
				<div class="content-header">Shipment Information</div>
				<div class="content-body">
					<div class="grid">
						<div class="col-12 mb-3 lg:col-4">
							<span class="p-float-label">
								<InputText
									type="text"
									id="doNumber"
									:class="{
										'p-invalid': inputErrorArray.some(
											(err) => err.name === 'doNumber'
										),
									}"
									:disabled="
										disabledFormat || status === 'DRAFT'
									"
									v-model="doNumber"
								/>
								<label for="doNumber">DO Number</label>
							</span>
							<div
								class="mt-1"
								v-if="
									inputErrorArray.some(
										(err) => err.name === 'doNumber'
									)
								"
							>
								<small class="p-error" style="font-size: 12px">
									{{
										inputErrorArray.find(
											(err) => err.name === 'doNumber'
										)['message']
									}}
								</small>
							</div>
						</div>
						<div class="col-12 mb-3 lg:col-4">
							<span class="p-float-label">
								<Calendar
									v-if="openPeriods"
									id="deliveryDate"
									v-model="deliveryDate"
									dateFormat="d M yy"
									:showIcon="true"
									:manualInput="false"
									:class="{
										'p-invalid': inputErrorArray.some(
											(err) => err.name === 'deliveryDate'
										),
									}"
									:disabled="
										disabledFormat ||
										!openPeriods ||
										!openPeriods.length ||
										status === 'DRAFT'
									"
									:minDate="firstPeriode"
									:maxDate="endPeriode"
								/>
								<label for="deliveryDate">Delivery Date</label>
							</span>
							<div
								class="mt-1"
								v-if="
									inputErrorArray.some(
										(err) => err.name === 'deliveryDate'
									)
								"
							>
								<small class="p-error" style="font-size: 12px">
									{{
										inputErrorArray.find(
											(err) => err.name === 'deliveryDate'
										)['message']
									}}
								</small>
							</div>
						</div>
						<div class="col-12 mb-3 lg:col-4" v-if="status">
							<span class="p-float-label">
								<InputText
									id="status"
									disabled
									v-model="status"
									v-bind:style="{ color: activeColor }"
								/>
								<label for="status">Status</label>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Freight Info -->
		<div class="col-12 p-fluid mb-4">
			<div class="content-wrapper">
				<div class="content-header">Freight Information</div>
				<div class="content-body" :disabled="!levelTenantDiv">
					<div class="grid">
						<div class="col-12 mb-3 lg:col-4">
							<div class="grid">
								<div class="col-12 mb-3">
									<span class="p-float-label">
										<InputText
											class="w-full"
											:class="{
												'p-invalid':
													inputErrorArray.some(
														(err) =>
															err.name ===
															'freightCarrier'
													),
											}"
											type="text"
											id="freightCarrier"
											:maxlength="25"
											v-model="freightCarrier"
											:disabled="
												disabledFormat ||
												!levelTenantDiv
											"
										/>
										<label for="freightCarrier"
											>Freight Carrier</label
										>
									</span>
									<div
										class="mt-1"
										v-if="
											inputErrorArray.some(
												(err) =>
													err.name ===
													'freightCarrier'
											)
										"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												inputErrorArray.find(
													(err) =>
														err.name ===
														'freightCarrier'
												)['message']
											}}
										</small>
									</div>
								</div>
								<div class="col-12 mb-3">
									<span class="p-float-label">
										<InputText
											class="w-full"
											:class="{
												'p-invalid':
													inputErrorArray.some(
														(err) =>
															err.name ===
															'awbNumber'
													),
											}"
											type="text"
											id="awbNumber"
											v-model="awbNumber"
											:disabled="
												disabledFormat ||
												!levelTenantDiv
											"
										/>
										<label for="awbNumber"
											>AWB/BL Number</label
										>
									</span>
									<div
										class="mt-1"
										v-if="
											inputErrorArray.some(
												(err) =>
													err.name === 'awbNumber'
											)
										"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												inputErrorArray.find(
													(err) =>
														err.name === 'awbNumber'
												)['message']
											}}
										</small>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 mb-3 lg:col-4">
							<span class="p-float-label">
								<Textarea
									id="comment"
									class="w-full"
									:class="{
										'p-invalid': inputErrorArray.some(
											(err) => err.name === 'comment'
										),
									}"
									rows="5"
									v-model="comment"
									:disabled="
										disabledFormat || !levelTenantDiv
									"
								/>
								<label for="comment">Comment</label>
							</span>
							<div
								class="mt-1"
								v-if="
									inputErrorArray.some(
										(err) => err.name === 'comment'
									)
								"
							>
								<small class="p-error" style="font-size: 12px">
									{{
										inputErrorArray.find(
											(err) => err.name === 'comment'
										)['message']
									}}
								</small>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Attachment Document -->
		<div class="col-12 p-fluid mb-4">
			<div class="content-wrapper">
				<div class="content-header">Attachment Document</div>
				<div class="content-body">
					<div v-if="status">
						<!-- Button upload doc -->
						<div class="mb-5">
							<Button
								v-if="!this.disabledFormat"
								class="p-button-success p-button-sm w-auto"
								icon="pi pi-upload"
								label="Upload Document"
								@click="handleClickUpload"
								:disabled="!levelTenantDiv"
							/>
						</div>
						<!-- Table Files -->
						<DataTable
							:value="documents"
							v-model:selection="selectedPo"
							selectionMode="multiple"
							:metaKeySelection="false"
							dataKey="ASN_DOC_ID"
							:paginator="true"
							:rows="5"
							:rowsPerPageOptions="[5, 10, 15]"
							:totalRecords="totalRecords"
							paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
							responsiveLayout="scroll"
						>
							<Column header="No" field="no">
								<template #body="slotProps">
									{{ slotProps.index + 1 }}
								</template>
							</Column>
							<Column
								header="Document Type"
								field="ASN_DOC_TYPE"
							></Column>
							<Column header="File" field="FILENAME">
								<template #body="{ data }">
									<Button
										v-if="!loadingDownload"
										class="button-download font-normal"
										:label="data.FILENAME"
										icon="pi pi-download"
										@click="handleFileDownload(data)"
									/>
									<Button
										v-if="loadingDownload"
										class="button-download font-normal"
										:label="`${data.FILENAME} is downloading`"
										disabled
									/>
								</template>
							</Column>
							<Column
								header="Need Original"
								field="need_original"
							></Column>
							<Column
								header="Validate"
								field="VALIDATE"
								dataType="boolean"
								style="text-align: left"
								headerStyle="text-align : center"
							>
								<template #body="slotProps">
									<i
										class="pi"
										:class="{
											'true-icon pi-check-circle':
												slotProps.data.VALIDATE,
											'false-icon pi-times-circle':
												!slotProps.data.VALIDATE,
										}"
										:style="{
											color: slotProps.data.colorValidate,
											fontSize: fontSize + 'px',
										}"
									></i>
								</template>
							</Column>
							<Column header="Remark">
								<template #body="slotProps">
									<InputText
										type="text"
										v-model="slotProps.data.REMARK"
										:disabled="
											disabledFormat || levelTenantDiv
										"
									/>
								</template>
							</Column>
							<!-- Empty state -->
							<template #empty>
								<p>No Data.</p>
							</template>
						</DataTable>
					</div>

					<div v-else>No Document</div>
					<div v-if="!levelTenantDiv">
						<div
							class="p-fluid grid formgrid"
							style="margin-top: 1.5rem; margin-bottom: 0.5rem"
						>
							<div class="col-0 mb-10 lg:col-0 lg:mb-0">
								<Button
									class="p-button-danger p-button-sm w-auto"
									icon="pi pi-times"
									label="Not Validate"
									@click="handleValidate(0)"
								/>
							</div>
							<div class="col-2 mb-2 lg:col-2 lg:mb-0">
								<Button
									class="p-button-success p-button-sm w-auto"
									icon="pi pi-check"
									label="Validate"
									@click="handleValidate(1)"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Items -->
		<div class="col-12 p-fluid mb-4">
			<div class="content-wrapper">
				<div class="content-header">Items</div>
				<div class="content-body">
					<DataTable
						:value="poItems"
						:paginator="true"
						:rows="10"
						:rowsPerPageOptions="[10, 20, 50]"
						:metaKeySelection="false"
						:totalRecords="totalRecords"
						ref="dt"
						dataKey="id"
						paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
						responsiveLayout="scroll"
						:scrollable="true"
						scrollHeight="600px"
						@page="onPage($event)"
						@sort="onSort($event)"
					>
						<Column
							class="call-button-container"
							header="PO Number"
							field="SEGMENT1"
							:sortable="true"
						></Column>
						<Column
							class="call-button-container"
							header="Line"
							field="LINE_NUM"
							:sortable="true"
						></Column>
						<Column
							class="call-button-container"
							header="Item Number"
							field="ITEM_NUMBER"
							:sortable="true"
						></Column>
						<Column
							class="call-button-container"
							header="Item Description"
							field="ITEM_DESCRIPTION"
						></Column>
						<Column
							class="call-button-container"
							header="Arrival at Site Date"
							field="DUE_DATE"
						></Column>
						<Column
							class="call-button-container"
							header="Qty. Order"
							field="QUANTITY"
						>
							<template #body="{ data }">
								{{ formatCurrency(data.QUANTITY) }}
							</template>
						</Column>
						<Column
							class="call-button-container"
							header="UOM"
							field="UOM"
							:sortable="true"
						></Column>
						<Column
							class="call-button-container"
							header="Ship To"
							field="SHIP_TO_NAME"
							:sortable="true"
						></Column>
						<Column
							class="call-button-container"
							header="Qty Shipped"
						>
							<template #body="{ data }">
								<InputNumber
									id="qtyShipped"
									:class="{
										'p-invalid':
											inputError &&
											inputErrorArray.some(
												(err) => err.id === data.id
											),
									}"
									min="0"
									mode="decimal"
									locale="id-ID"
									inputStyle="text-align: right"
									:minFractionDigits="2"
									:disabled="
										disabledFormat || !levelTenantDiv
									"
									v-model="data.QUANTITY_SHIPPED"
									@keyup="
										handleValidateQuantity($event, data)
									"
								/>
								<div class="mt-1" v-if="inputError">
									<div
										v-for="err in inputErrorArray"
										:key="err.id"
									>
										<div v-if="err.id === data.id">
											<small
												class="p-error"
												style="font-size: 12px"
											>
												{{ err.message }}
											</small>
										</div>
									</div>
								</div>
							</template>
						</Column>
					</DataTable>
				</div>
			</div>
		</div>
		<!-- Action buttons -->
		<div
			class="mb-0 mt-0 w-full flex justify-content-end align-items-center"
			v-if="this.levelTenantDiv"
		>
			<div
				class="mb-6 mt-4 w-full flex justify-content-end align-items-center"
				v-if="poItems"
			>
				<!-- If no status -->
				<div v-if="!status">
					<Button
						class="p-button-link"
						@click="
							this.$router.push({ name: 'CreateShipmentNotice' })
						"
						><span class="font-bold uppercase text-black-alpha-90"
							>Back</span
						></Button
					>

					<Button
						v-if="!loadingSave"
						class="p-button-success ml-3"
						:disabled="inputError"
						@click="handleSaveDraft"
					>
						<span class="font-bold uppercase">Save as draft</span>
					</Button>

					<!-- Button saving condition -->
					<Button v-else class="p-button-success ml-3" disabled>
						<span class="font-bold uppercase"
							>Saving as draft...</span
						>
					</Button>
				</div>

				<!-- If status is draft -->
				<div v-else>
					<Button
						v-if="this.breadcrumbsDiv"
						class="p-button-link"
						@click="this.$router.push({ name: 'ShipmentNotice' })"
						><span class="font-bold uppercase text-black-alpha-90"
							>Back</span
						></Button
					>
					<Button
						v-if="!this.breadcrumbsDiv"
						class="p-button-link"
						@click="this.$router.push({ name: 'ShipmentNotice' })"
						><span class="font-bold uppercase text-black-alpha-90"
							>Back</span
						></Button
					>
					<Button
						class="p-button-secondary ml-3"
						@click="handleSaveDraft"
						:disabled="inputError"
						v-if="!this.disabledFormat && !loadingSave"
						><span class="font-bold uppercase"
							>Save as Draft</span
						></Button
					>
					<!-- Button saving condition -->
					<Button
						v-if="!disabledFormat && loadingSave"
						class="p-button-secondary ml-3"
						disabled
					>
						<span class="font-bold uppercase"
							>Saving as draft...</span
						>
					</Button>
					<Button
						class="p-button-success ml-3"
						:disabled="inputError"
						@click="handleSubmit"
						v-if="!this.disabledFormat && !loadingSubmit"
					>
						<span class="font-bold uppercase">Submit</span>
					</Button>
					<!-- Button Submitting condition -->
					<Button
						class="p-button-success ml-3"
						disabled
						v-if="!this.disabledFormat && loadingSubmit"
					>
						<span class="font-bold uppercase">Submitting...</span>
					</Button>
				</div>
			</div>
		</div>

		<div
			class="mb-0 mt-0 w-full flex justify-content-end align-items-center"
			v-if="!this.levelTenantDiv"
		>
			<Button
				class="p-button-link"
				@click="this.$router.push({ name: 'ShipmentNotice' })"
				><span class="font-bold uppercase text-black-alpha-90"
					>Back</span
				></Button
			>
		</div>

		<!-- Upload Doc Modal -->
		<Dialog
			header="Upload Document"
			:breakpoints="{ '960px': '75vw', '640px': '100vw' }"
			:style="{ width: '75vw', overflow: 'hidden' }"
			:modal="true"
			:dismissableMask="true"
			v-model:visible="displayModal"
		>
			<div class="pt-3">
				<UploadDocumentCreateShipment
					:shipmentHeaderId="shipmentHeaderId"
					:doNumber="doNumber"
					:poItems="poItems"
					:parentDocuments="documents"
					:vendorId="vendorId"
					@onDelete="handleDeleteDocument"
					@onUpdate="handleUpdateDocument"
					@onSubmit="handleSubmitDocument"
				/>
			</div>
		</Dialog>
	</div>
</template>

<script>
/* eslint-disable */
import AuthService from '../../service/AuthService';
import CreateShipmentService from '../../service/CreateShipmentService';
import UploadDocumentCreateShipment from './uploaddocument/UploadDocumentCreateShipment.vue';

export default {
	name: 'AddCreateShipmentNotice',
	components: { UploadDocumentCreateShipment },
	data() {
		return {
			currentUserId: null,
			shipmentHeaderId: null,
			doNumber: null,
			deliveryDate: null,
			freightCarrier: null,
			awbNumber: null,
			comment: null,
			status: null,
			poItems: null,
			openPeriods: null,
			displayModal: null,
			disabledFormat: false,
			loadingSave: null,
			loadingSubmit: null,
			loadingDownload: null,
			documents: null,
			selectedPo: null,
			breadcrumbsDiv: true,
			inputError: null,
			inputErrorArray: [],
			levelTenantDiv: true,
			inputErrorMessage: null,
			vendorname: null,
			idUser: null,
			orgIdCompany: null,
			dbInstance: null,
			recipientMail: null,
			sender: null,
			payloadEmail: null,
			activeColor: 'red',
			colorValidate: [],
			fontSize: 30,
			breadcrumbHome: { icon: 'pi pi-home', to: '/' },
			breadcrumbItems: [
				{
					label: 'Create Shipment Notice',
					to: '/mgm/settings/createshipment',
				},
				{
					label: 'Add/Edit',
					to: `/mgm/settings/createshipment/add/${this.$route.params.doNumber}`,
				},
			],
			breadcrumbItemss: [
				{
					label: 'Shipment Notice',
					to: '/mgm/settings/shipment',
				},
				{
					label: 'Add/Edit',
					to: `/mgm/settings/createshipment/add/${this.$route.params.doNumber}`,
				},
			],
			vendorId: null,
		};
	},
	async created() {
		this.levelTenant = sessionStorage.getItem('leveltenant');
		if (this.levelTenant == 1) {
			this.levelTenantDiv = false;
		} else {
			this.levelTenantDiv = true;
		}
		this.currentUserId = sessionStorage.getItem('idUser');
		this.shipmentHeaderId = parseInt(this.$route.params.id);

		this.$watch(
			() => this.$route.params,
			() => {
				if (this.$route.name === 'AddCreateShipmentNotice') {
					this.shipmentHeaderId = parseInt(this.$route.params.id);
					this.getShipmentHeader(this.shipmentHeaderId);
				}
			}
		);

		// Get open period for delivery date
		this.getOpenPeriode();


		console.log("APAKAH SUDAH MULAI?")

		// Check if this is add page or update page
		if (this.shipmentHeaderId === 0) {
			// This is an add create shipment notice page
			// Fetch items from sessionStorage
			console.log("SHIPMENT 0")
			this.breadcrumbsDiv = true;
			const result = JSON.parse(
				sessionStorage.getItem('poForCreateShipment')
			);
			if (!result) {
				this.$router.push({ name: 'CreateShipmentNotice' });
			}
			this.poItems =
				result &&
				result.map((val) => {
					const remainingQty = val.QUANTITY - val.QUANTITY_SHIPPED;

					return {
						...val,
						QUANTITY_SHIPPED: remainingQty,
						TOTAL_QUANTITY_SHIPPED: val.QUANTITY_SHIPPED,
					};
				});
		} else {
			// This is an update create shipment notice page
			// Fetch po items from database
			console.log("GAS")
			this.getShipmentHeader(this.shipmentHeaderId);
			this.breadcrumbsDiv = false;
		}
	},
	computed: {
		firstPeriode() {
			const dateStr = this.openPeriods && this.openPeriods[0];
			const theDate = new Date(dateStr);
			const res = new Date(theDate.getFullYear(), theDate.getMonth(), 1);
			return res;
		},
		endPeriode() {
			const lastIndex = (this.openPeriods && this.openPeriods.length) - 1;
			const dateStr = this.openPeriods[lastIndex];
			const theDate = new Date(dateStr);
			const res = new Date(
				theDate.getFullYear(),
				theDate.getMonth() + 1,
				0
			);
			return res;
		},
	},
	methods: {
		formatCurrency(value) {
			if (value) return value.toLocaleString('id-ID');
			return;
		},
		formatDateDexa(date) {
			const formattedDate = new Date(date);
			const respondDate =
				('00' + (formattedDate.getMonth() + 1)).slice(-2) +
				'/' +
				('00' + formattedDate.getDate()).slice(-2) +
				'/' +
				formattedDate.getFullYear();
			return respondDate.replace('-', '');
		},
		async getOpenPeriode() {
			const dbInstance = sessionStorage.getItem('companydb');
			const res = await CreateShipmentService.getOpenPeriode({
				dbInstance,
			});
			if (res.status === 200) {
				this.openPeriods = res.data.data
					.map((val) => val.period)
					.sort((a, b) => new Date(a) - new Date(b));
			}
		},
		async getShipmentHeader(id) {
			console.log("MASUK HEADER", id)
			try {
				let res = null;
				if (this.levelTenant == 1) {
					res = await CreateShipmentService.getShipmentHeader(id);
				} else {
					const vendorId = sessionStorage.getItem('vendorId');
					res = await CreateShipmentService.getShipmentHeaderSupp(
						id,
						vendorId
					);
				}


				// Assign data
				this.orgIdCompany = res.data.data.org_id;
				this.vendorId = res.data.data.vendor_id;
				this.doNumber = res.data.data.do_number;
				this.freightCarrier = res.data.data.freight_carrier_code;
				this.awbNumber = res.data.data.awb_number;
				this.comment = res.data.data.comments;
				this.status = res.data.data.name;
				this.deliveryDate = new Date(res.data.data.delivery_date);
				if (this.status !== 'DRAFT') {
					this.disabledFormat = true;
					this.activeColor = 'green';
				}
				if (res.status === 200) {
					// Fetch document
					this.getDocumentTable(res.data.data.id);
					// Fetch asn items
					this.getShipmentItems(res.data.data.id);
				}
			} catch (err) {
				console.log(err);
				if (err.response.status === 404) {
					this.$toast.add({
						severity: 'error',
						summary: 'Data not found',
						life: 3000,
					});
				}
				setTimeout(() => {
					router.push('/dashboard');
				}, 1500);
			}
		},
		async getShipmentItems(id) {
			if (this.shipmentHeaderId === 0) {
				try {
					console.log("MASUK SANA")
					const res =
						await CreateShipmentService.getShipmentItemsShipment(
							id
						);

					let number = 0;

					console.log(res.data, "RESP DATA")

					this.poItems =
						res.data.data &&
						res.data.data.map((val) => {
							number++;

							const dueDate = new Date(
								val.due_date
							).toLocaleString('id-ID', {
								day: 'numeric',
								month: 'short',
								year: 'numeric',
							});

							return {
								id: number,
								SHIPMENT_LINE_ID: val.SHIPMENT_LINE_ID,
								PO_HEADER_ID: val.PO_HEADER_ID,
								PO_KEY_ID: val.PO_KEY_ID,
								PO_LINE_ID: val.PO_LINE_ID,
								SEGMENT1: val.SEGMENT1,
								LINE: val.LINE,
								ITEM_NUMBER: val.ITEM_NUMBER,
								ITEM_DESCRIPTION: val.ITEM_DESCRIPTION,
								DUE_DATE: dueDate,
								QUANTITY: val.QUANTITY,
								QUANTITY_SHIPPED: val.QUANTITY_SHIPPED,
								TOTAL_QUANTITY_SHIPPED:
									val.TOTAL_QUANTITY_SHIPPED,
								QUANTITY_SHIPPED: val.QUANTITY_SHIPPED,
								PO_TOLERANCE: val.PO_TOLERANCE,
								UOM: val.UOM,
								fob: val.fob,
								SHIP_TO_NAME: val.SHIP_TO_NAME,
							};
						});
				} catch (err) {
					console.log(err);
					if (err.response.status === 404) {
						this.$toast.add({
							severity: 'error',
							summary: 'Data not found',
							life: 3000,
						});
					}
				}
			} else {
				console.log("MASUK SINI")
				try {
					const res =
						await CreateShipmentService.getShipmentItemsShipment(
							id
						);

					let number = 0;

					this.poItems =
						res.data.data &&
						res.data.data.map((val) => {
							number++;

							const dueDate = new Date(
								val.DUE_DATE
							).toLocaleString('id-ID', {
								day: 'numeric',
								month: 'short',
								year: 'numeric',
							});

							return {
								id: number,
								SHIPMENT_LINE_ID: val.SHIPMENT_LINE_ID,
								PO_HEADER_ID: val.PO_HEADER_ID,
								PO_KEY_ID: val.PO_KEY_ID,
								PO_LINE_ID: val.PO_LINE_ID,
								SEGMENT1: val.SEGMENT1,
								LINE_NUM: val.LINE_NUM,
								ITEM_NUMBER: val.ITEM_NUMBER,
								ITEM_DESCRIPTION: val.ITEM_DESCRIPTION,
								DUE_DATE: dueDate,
								QUANTITY: val.QUANTITY,
								QUANTITY_SHIPPED: val.QUANTITY_SHIPPED,
								QUANTITY_SHIPPED_FORMULA:
									val.QUANTITY -
									(val.TOTAL_QUANTITY_SHIPPED -
										val.QUANTITY_SHIPPED) +
									(val.PO_TOLERANCE * val.QUANTITY) /
										100,
								QUANTITY_SHIPPED_MINUS: val.QUANTITY_SHIPPED,
								TOTAL_QUANTITY_SHIPPED:
									val.TOTAL_QUANTITY_SHIPPED,
								current_QUANTITY_SHIPPED: val.QUANTITY_SHIPPED,
								PO_TOLERANCE: val.PO_TOLERANCE,
								UOM: val.UOM,
								fob: val.fob,
								SHIP_TO_NAME: val.SHIP_TO_NAME,
							};
						});
				} catch (err) {
					console.log(err);
					if (err.response.status === 404) {
						this.$toast.add({
							severity: 'error',
							summary: 'Data not found',
							life: 3000,
						});
					}
				}
			}
		},
		async getDocumentTable(id) {
			if (this.shipmentHeaderId != 0) {
				const res = await CreateShipmentService.getDocoumentTable(id);
				if (res.status === 200) {
					this.documents = res.data.data;

					for (var i of this.documents) {
						if (i.VALIDITY === 0) {
							i.VALIDATE = false;
							i.colorValidate = 'red';
						} else {
							i.VALIDATE = true;
							i.colorValidate = 'green';
						}
						this.colorValidate = i.colorValidate;
					}
				}
			}
		},
		handleDeleteDocument(id) {
			this.documents = this.documents.filter(
				(doc) => doc.ASN_DOC_ID !== id
			);
			//this.displayModal = false;
		},
		handleUpdateDocument(data) {
			this.documents = this.documents.map((doc) => {
				if (doc.ASN_DOC_ID === data.id) {
					return { ...doc, FILENAME: data.filename };
				}

				return doc;
			});
			//this.displayModal = false;
		},
		handleSubmitDocument(data) {
			const newDoc = {
				ASN_DOC_ID: data.asn_doc_id,
				ASN_DOC_TYPE: data.asn_doc_type,
				SHIPMENT_HEADER_ID: data.shipment_header_id,
				FILENAME: data.filename,
				CREATION_DATE: data.creation_date,
				LAST_UPDATE_DATE: data.creation_date,
				MIME_TYPE: data.mime_type,
				REMARK: '',
				VALIDITY: 0,
				VALIDATE: false,
			};

			this.documents = [...this.documents, newDoc];
			//this.displayModal = false;
		},
		handleValidateQuantity(event, item) {
			if (this.shipmentHeaderId == 0) {
				const inputValue = event.target.value
					.replace(/\./g, '')
					.replace(/,/g, '.');
				const valueNumber = parseFloat(inputValue) || 0;

				item.QUANTITY_SHIPPED = valueNumber;

				if (valueNumber) {
					// Calculate remaining quantity available, include tolerance
					const remainingQuantity =
						Math.round(
							(item.quantity * (1 + item.po_tolerance / 100) -
								item.TOTAL_QUANTITY_SHIPPED) *
								100
						) / 100;

					if (valueNumber > remainingQuantity) {
						this.inputError = true;

						const errorIsFound = this.inputErrorArray.some(
							(val) => val.id === item.id
						);

						if (errorIsFound) {
							return;
						}

						this.inputErrorArray = [
							...this.inputErrorArray,
							{
								id: item.id,
								message: `Maximum number of quantity shipped exceeded, limit is ${remainingQuantity} at most`,
							},
						];
					} else {
						this.inputErrorArray = this.inputErrorArray.filter(
							(val) => val.id !== item.id
						);
					}
				}

				// Check if there is no error of max qty shipped
				if (
					!this.inputErrorArray.some((err) =>
						Object.hasOwn(err, 'id')
					)
				) {
					this.inputError = false;
					return;
				}
			} else {
				const inputValue = event.target.value
					.replace(/\./g, '')
					.replace(/,/g, '.');
				const valueNumber = parseFloat(inputValue) || 0;

				item.QUANTITY_SHIPPED = valueNumber;
				if (valueNumber) {
					// Calculate remaining quantity available, include tolerance
					const remainingQuantity =
						Math.round(item.QUANTITY_SHIPPED_formula * 100) / 100;

					if (valueNumber > remainingQuantity) {
						this.inputError = true;
						const errorIsFound = this.inputErrorArray.some(
							(val) => val.id === item.id
						);

						if (errorIsFound) {
							return;
						}

						this.inputErrorArray = [
							...this.inputErrorArray,
							{
								id: item.id,
								message: `Maximum number of quantity shipped exceeded, limit is ${remainingQuantity} at most`,
							},
						];
					} else {
						this.inputErrorArray = this.inputErrorArray.filter(
							(val) => val.id !== item.id
						);
					}
				}

				// Check if there is no error of max qty shipped
				if (
					!this.inputErrorArray.some((err) =>
						Object.hasOwn(err, 'id')
					)
				) {
					this.inputError = false;
					return;
				}
			}
		},
		handleClickUpload() {
			this.displayModal = true;
		},
		async handleFileDownload(data) {
			try {
				// const { ASN_DOC_ID: asnDocId, SHIPMENT_HEADER_ID: headerId } = data;
				// const res = await CreateShipmentService.downloadFileDocument({
				//   asnDocId,
				//   headerId,
				// });

				// if (res.status !== 200) {
				//   throw Error("Error downloading file");
				// }

				// const url = res.data.data;
				// window.location.href = url;
				this.loadingDownload = true;
				const leveltenant = this.levelTenant;
				const { ASN_DOC_ID: asnDocId, SHIPMENT_HEADER_ID: headerId } =
					data;
				const res =
					await CreateShipmentService.downloadFileDocumentWithLevelTenant(
						{
							asnDocId,
							headerId,
							leveltenant,
						}
					);

				if (res.status !== 200) {
					throw Error('Error downloading file');
				}

				const url = res.data.data;

				const linkSource = `data:application/pdf;base64,${url.fileBuffer}`;
				const downloadLink = document.createElement('a');
				const fileName = url.fileName;
				downloadLink.href = linkSource;
				downloadLink.download = fileName;
				downloadLink.click();
				this.loadingDownload = false;
			} catch (err) {
				console.log(err);
				this.loadingDownload = false;
				this.$toast.add({
					severity: 'error',
					summary: 'Connection Unstable !',
					life: 3000,
				});
			}
		},
		async submitAsnHeader(asnStatus) {
			const payload = {
				SHIPMENT_NUMBER: this.doNumber,
				SHIPPED_DATE: this.formatDateDexa(this.deliveryDate),
				// EXP_RECEIPT_DATE: this.formatDateDexa(new Date()),
				// PACKING_SLIP: 'A',
				// RECEIPT_NUM: 'A',
				FREIGHT_CARRIER_CODE: this.freightCarrier,
				WAYBILL_AIRBILL_NUM: this.awbNumber,
				NUM_OF_CONTAINERS: 0,
				VENDOR_ID: this.poItems[0].VENDOR_ID,
				// VENDOR_SITE_ID: this.poItems[0].vendor_id,
				ORG_ID: this.poItems[0].ORG_ID,
				DB_INSTANCE: this.poItems[0].DB_INSTANCE,
				COMMENTS: this.comment,
				ASN_STATUS: asnStatus,
				CREATION_DATE: this.formatDateDexa(new Date()),
				CREATED_BY: this.currentUserId,
				LAST_UPDATE_DATE: this.formatDateDexa(new Date()),
				LAST_UPDATED_BY: this.currentUserId,
				IDHEADER: this.shipmentHeaderId,
				// COURIER_NAME: 'ABC',
				// TRACKING_NO: '123456',
			};
			if (this.shipmentHeaderId === 0) {
				try {
					const res = await CreateShipmentService.submitAsnHeader(
						payload
					);
					return res;
				} catch (err) {
					console.log(err);
					return err;
				}
			} else {
				try {
					const res = await CreateShipmentService.saveAsnHeader(
						payload
					);
					return res;
				} catch (err) {
					console.log(err);
					return err;
				}
			}
		},
		async submitAsnLine(payload) {
			const dueDate = this.formatDateDexa(payload.PROMISED_DATE);
			const linePayload = {
				SHIPMENT_LINE_ID: payload.SHIPMENT_LINE_ID,
				SHIPMENT_HEADER_ID: payload.asn_header_id,
				PO_HEADER_ID: payload.PO_HEADER_ID,
				PO_LINE_ID: payload.PO_LINE_ID,
				PO_NUMBER: payload.SEGMENT1,
				PO_KEY_ID: payload.PO_KEY_ID,
				LINE_NUM: payload.LINE_NUM,
				INVENTORY_ITEM_ID: payload.ITEM_ID,
				DUE_DATE: dueDate,
				QUANTITY_ORDER: payload.QUANTITY,
				QUANTITY_RECEIVED: payload.QUANTITY_RECEIVED,
				QUANTITY_SHIPPED: payload.QUANTITY_SHIPPED,
				UOM: payload.UOM,
				// VENDOR_LOT_NUM: '123',
				SHIP_TO_LOCATION_ID: payload.SHIP_TO_LOCATION_ID,
				ORGANIZATION_ID: payload.ORG_ID,
				CREATION_DATE: this.formatDateDexa(new Date()),
				CREATED_BY: this.currentUserId,
				LAST_UPDATE_DATE: this.formatDateDexa(new Date()),
				LAST_UPDATED_BY: this.currentUserId,
				RESERVE_ID: this.currentUserId,
				ITEM_NO: payload.ITEM_NUMBER,
				ITEM_DESC: payload.ITEM_DESCRIPTION,
				IDHEADER: this.shipmentHeaderId,
				TOTAL: payload.TOTAL_QUANTITY_SHIPPED,
			};
			if (this.shipmentHeaderId === 0) {
				try {
					const res = await CreateShipmentService.submitAsnLines(
						linePayload
					);
					return res;
				} catch (err) {
					console.log(err);
					return err;
				}
			} else {
				try {
					const res = await CreateShipmentService.saveAsnLines(
						linePayload
					);
					return res;
				} catch (err) {
					console.log(err);
					return err;
				}
			}
		},
		async handleSaveDraft() {
			this.inputErrorArray = [];
			this.loadingSave = false;

			// Validation
			if (
				!this.doNumber ||
				!this.deliveryDate ||
				!this.freightCarrier ||
				!this.awbNumber ||
				!this.comment
			) {
				this.$toast.add({
					severity: 'error',
					summary: 'All fields are required',
					life: 3000,
				});

				if (!this.doNumber) {
					this.inputErrorArray.push({
						name: 'doNumber',
						message: 'This field is required',
					});
				}

				if (!this.deliveryDate) {
					this.inputErrorArray.push({
						name: 'deliveryDate',
						message: 'This field is required',
					});
				}

				if (!this.freightCarrier) {
					this.inputErrorArray.push({
						name: 'freightCarrier',
						message: 'This field is required',
					});
				}

				if (!this.awbNumber) {
					this.inputErrorArray.push({
						name: 'awbNumber',
						message: 'This field is required',
					});
				}

				if (!this.comment) {
					this.inputErrorArray.push({
						name: 'comment',
						message: 'This field is required',
					});
				}

				return;
			}

			// Validation max chars
			if (this.doNumber.length > 50) {
				this.$toast.add({
					severity: 'error',
					summary: 'Exceed maximum characters',
					life: 3000,
				});

				this.inputErrorArray.push({
					name: 'doNumber',
					message: 'DO Number must not exceed 50 characters',
				});

				return;
			}

			if (this.freightCarrier.length > 25) {
				this.$toast.add({
					severity: 'error',
					summary: 'Exceed maximum characters',
					life: 3000,
				});

				this.inputErrorArray.push({
					name: 'freightCarrier',
					message: 'Freight Carrier must not exceed 25 characters',
				});

				return;
			}

			if (this.awbNumber.length > 20) {
				this.$toast.add({
					severity: 'error',
					summary: 'Exceed maximum characters',
					life: 3000,
				});

				this.inputErrorArray.push({
					name: 'awbNumber',
					message: 'AWB/BL Number must not exceed 20 characters',
				});

				return;
			}

			if (this.comment.length > 1000) {
				this.$toast.add({
					severity: 'error',
					summary: 'Exceed maximum characters',
					life: 3000,
				});

				this.inputErrorArray.push({
					name: 'comment',
					message: 'Comment must not exceed 1.000 characters',
				});

				return;
			}

			// Insert/Update to ASN table
			if (this.shipmentHeaderId === 0) {
				// Submit data
				try {
					this.loadingSave = true;
					const resHeader = await this.submitAsnHeader('D');

					if (resHeader.status === 200) {
						const asnHeaderId = resHeader.data.data.id;
						let allLinesResponse = [];
						this.poItems.forEach(async (item) => {
							const payload = {
								...item,
								asn_header_id: asnHeaderId,
							};

							// Insert shipment
							const resLine = await this.submitAsnLine(payload);

							if (resLine.status !== 200) {
								this.loadingSave = false;

								if (
									resLine.response.data.message.includes(
										'Exceed maximum quantity can be shipped'
									)
								) {
									this.$toast.add({
										severity: 'error',
										summary: 'Error save shipment item',
										life: 3000,
									});
									this.$toast.add({
										severity: 'error',
										summary:
											'Exceed maximum quantity can be shipped',
										life: 3000,
									});
								}
								throw new Error('Error submit shipment item');
							}
							
							// Update qty shipped
							const resUpdate =
								await CreateShipmentService.updateQuantityShipped(
									{
										poKeyId: item.PO_KEY_ID,
										poHeaderId: item.PO_HEADER_ID,
										poLineId: item.PO_LINE_ID,
									},
									{ newQtyShipped: item.QUANTITY_SHIPPED }
								);

							if (resUpdate.status !== 200) {
								throw new Error(
									'Error update quantity shipped'
								);
							}

							allLinesResponse.push(resLine.status);
						});

						// Check all line status
						const isStatusOk = allLinesResponse.every(
							(val) => val === 200
						);

						if (isStatusOk) {
							this.loadingSave = false;

							// Clear PO Items in session storage
							sessionStorage.setItem('poForCreateShipment', null);

							// Update state
							this.poItems = this.poItems.map((item) => {
								const newTotQtyShipped =
									item.TOTAL_QUANTITY_SHIPPED +
									item.QUANTITY_SHIPPED;

								return {
									...item,
									TOTAL_QUANTITY_SHIPPED: newTotQtyShipped,
								};
							});

							this.$toast.add({
								severity: 'success',
								summary:
									'Shipment Notice was successfully created',
								life: 3000,
							});

							(() =>
								alert(
									'Shipment Notice was successfully created'
								))();

							// Push to add/edit page
							this.$router.push({
								name: 'AddCreateShipmentNotice',
								params: { id: asnHeaderId },
							});
						}

						this.loadingSave = false;
					} else {
						this.loadingSave = false;
						if (
							resHeader.response.status === 400 &&
							resHeader.response.data.message.includes(
								'Shipment number already exists'
							)
						) {
							this.$toast.add({
								severity: 'error',
								summary:
									'DO number already exists. It must be unique',
								life: 3000,
							});
							return;
						}

						throw new Error('Error create shipment notice');
					}
				} catch (err) {
					console.log(err.message);
					this.loadingSave = false;
					this.$toast.add({
						severity: 'error',
						summary: 'Error create shipment notice',
						life: 3000,
					});
				}
			} else {
				try {
					this.loadingSave = true;

					const resHeader = await this.submitAsnHeader('D');

					if (resHeader.status === 200) {
						const asnHeaderId = this.shipmentHeaderId;
						let allLinesResponse = [];

						this.poItems.forEach(async (item) => {
							const payload = {
								...item,
								asn_header_id: asnHeaderId,
							};
							//QUANTITY_TOLERANCE: payload.QUANTITY_SHIPPED + payload.TOTAL_QUANTITY_SHIPPED QUANTITY_SHIPPED TOTAL_QUANTITY_SHIPPED
							// Update shipment lines
							const resLine = await this.submitAsnLine(payload);
							if (resLine.status !== 200) {
								throw new Error('Error submit shipment Line');
							}

							// Update qty shipped
							const updatedQtyShipped =
								Math.round(
									(item.QUANTITY_SHIPPED -
										item.QUANTITY_SHIPPED_MINUS) *
										100
								) / 100;

							const resUpdate =
								await CreateShipmentService.updateQuantityShipped(
									{
										poKeyId: item.PO_KEY_ID,
										poHeaderId: item.PO_HEADER_ID,
										poLineId: item.PO_LINE_ID
									},
									{ newQtyShipped: updatedQtyShipped }
								);

							if (resUpdate.status !== 200) {
								throw Error('Error update quantity shipped');
							}

							allLinesResponse.push(resLine.status);
						});

						// Check all line status
						const isStatusOk = allLinesResponse.every(
							(val) => val === 200
						);
						this.status = 'DRAFT';
						if (isStatusOk) {
							this.loadingSave = false;

							// Update state
							this.poItems = this.poItems.map((item) => ({
								...item,
								QUANTITY_SHIPPED_minus: item.QUANTITY_SHIPPED,
							}));

							// Clear PO Items in session storage
							sessionStorage.removeItem('poForCreateShipment');

							this.$toast.add({
								severity: 'success',
								summary:
									'Shipment Notice was successfully updated',
								life: 3000,
							});

							(() =>
								alert(
									'Shipment Notice was successfully updated'
								))();
						}
						this.loadingSave = false;
					} else {
						throw new Error('Error submit shipmnet header');
					}
				} catch (err) {
					console.log(err.response);
					console.log('error create shipment header');
					this.loadingSave = false;
					this.$toast.add({
						severity: 'error',
						summary: 'Error create shipment notice',
						life: 3000,
					});
				}
			}
		},
		async handleValidate(valid) {
			var resId = await AuthService.whoAmi();
			var severity;
			var toast;
			if (valid === 1) {
				for (var i of this.selectedPo) {
					i.VALIDITY = valid;
					i.VALIDATE = true;
					i.colorValidate = 'green';
					severity = 'success';
					toast = 'Successfully validate File';
				}
			} else if (valid !== 1) {
				for (var index of this.selectedPo) {
					index.VALIDITY = valid;
					index.VALIDATE = false;
					index.colorValidate = 'red';
					severity = 'error';
					toast = 'File not validate';
				}
				const resHeader = await this.submitAsnHeader('D');
				if (resHeader.status === 200) {
					this.status = 'DRAFT';
					this.disabledFormat = false;
					this.activeColor = 'red';
				}
			}
			let payload = {
				date: this.formatDateDexa(new Date()),
				last_updated_by: resId.data.data.id,
				data: this.selectedPo,
			};
			const res = await CreateShipmentService.handleValidate(payload);
			if (res.status === 200) {
				this.$toast.add({
					severity: severity,
					summary: toast,
					life: 3000,
				});
				this.selectedPo = null;
			}
		},
		async handleSubmit() {
			this.inputErrorArray = [];
			this.loadingSubmit = false;

			// Check if all fields are not empty
			if (
				!this.doNumber ||
				!this.deliveryDate ||
				!this.freightCarrier ||
				!this.awbNumber ||
				!this.comment
			) {
				this.$toast.add({
					severity: 'error',
					summary: 'All fields are required',
					life: 3000,
				});

				if (!this.doNumber) {
					this.inputErrorArray.push({
						name: 'doNumber',
						message: 'This field is required',
					});
				}

				if (!this.deliveryDate) {
					this.inputErrorArray.push({
						name: 'deliveryDate',
						message: 'This field is required',
					});
				}

				if (!this.freightCarrier) {
					this.inputErrorArray.push({
						name: 'freightCarrier',
						message: 'This field is required',
					});
				}

				if (!this.awbNumber) {
					this.inputErrorArray.push({
						name: 'awbNumber',
						message: 'This field is required',
					});
				}

				if (!this.comment) {
					this.inputErrorArray.push({
						name: 'comment',
						message: 'This field is required',
					});
				}

				return;
			}

			// Check if documents is sufficient
			if (
				!this.documents ||
				this.documents.length < this.poItems.length
			) {
				this.$toast.add({
					severity: 'error',
					summary: 'Documents are not sufficient for create shipment',
					life: 3000,
				});
				return;
			}

			// Validation max chars
			if (this.doNumber.length > 50) {
				this.$toast.add({
					severity: 'error',
					summary: 'Exceed maximum characters',
					life: 3000,
				});

				this.inputErrorArray.push({
					name: 'doNumber',
					message: 'DO Number must not exceed 50 characters',
				});

				return;
			}

			if (this.freightCarrier.length > 25) {
				this.$toast.add({
					severity: 'error',
					summary: 'Exceed maximum characters',
					life: 3000,
				});

				this.inputErrorArray.push({
					name: 'freightCarrier',
					message: 'Freight Carrier must not exceed 25 characters',
				});

				return;
			}

			if (this.awbNumber.length > 20) {
				this.$toast.add({
					severity: 'error',
					summary: 'Exceed maximum characters',
					life: 3000,
				});

				this.inputErrorArray.push({
					name: 'awbNumber',
					message: 'AWB/BL Number must not exceed 20 characters',
				});

				return;
			}

			if (this.comment.length > 1000) {
				this.$toast.add({
					severity: 'error',
					summary: 'Exceed maximum characters',
					life: 3000,
				});

				this.inputErrorArray.push({
					name: 'comment',
					message: 'Comment must not exceed 1.000 characters',
				});

				return;
			}

			try {
				this.loadingSubmit = true;

				// const resGetEmail = await this.getEmailToPur();
				// if (resGetEmail.status === 200) {
				const resHeader = await this.submitAsnHeader('S');

				if (resHeader.status === 200) {
					const asnHeaderId = resHeader.data.data.id;
					let allLinesResponse = [];
					this.poItems.forEach(async (item) => {
						const payload = {
							...item,
							asn_header_id: asnHeaderId,
						};
						const resLine = await this.submitAsnLine(payload);

						if (resLine.status !== 200) {
							throw new Error('Error submit shipment Line');
						}

						// Update qty shipped
						const updatedQtyShipped =
							Math.round(
								(item.QUANTITY_SHIPPED -
									item.QUANTITY_SHIPPED_MINUS) *
									100
							) / 100;

						const resUpdate =
							await CreateShipmentService.updateQuantityShipped(
								{
									poKeyId: item.PO_KEY_ID,
									poHeaderId: item.PO_HEADER_ID,
									poLineId: item.PO_LINE_ID,
								},
								{ newQtyShipped: updatedQtyShipped }
							);

						if (resUpdate.status !== 200) {
							throw Error('Error update quantity shipped');
						}

						allLinesResponse.push(resLine.status);
					});

					this.payloadEmail = {
						details: this.poItems,
						awb_number: this.awbNumber,
						do_number: this.doNumber,
						vendor_name: this.vendorname,
						freight: this.freightCarrier,
						comment: this.comment,
						delivery_date: this.deliveryDate,
						recipient: this.recipientMail,
					};
					await this.sendNotifToPur();
					// Check all line status
					const isStatusOk = allLinesResponse.every(
						(val) => val === 200
					);
					this.status = 'SUBMITTED';
					this.disabledFormat = true;
					if (isStatusOk) {
						setTimeout(() => {
							this.$toast.add({
								severity: 'success',
								summary:
									'Successfully save shipment notice as submitted',
								life: 3000,
							});
						}, 2000);

						// Reset loading state
						this.loadingSubmit = false;

						// Clear PO Items in session storage
						sessionStorage.setItem('poForCreateShipment', null);

						// Redirect to shipment notice page
						this.$router.push({ name: 'ShipmentNotice' });
					}
					// }
				}
				this.loadingSubmit = false;
			} catch (err) {
				console.log(err);
				this.loadingSubmit = false;
				this.$toast.add({
					severity: 'error',
					summary: 'Error create shipment notice',
					life: 3000,
				});
			}
		},
		async sendNotifToPur() {
			const whoResp = await AuthService.whoAmi();
			this.idUser = whoResp.data.data.id;
			this.sender = whoResp.data.data.bioemailactive;

			const data = {
				module: 'CSNToPur',
				idUser: whoResp.data.data.id,
				companyname: sessionStorage.getItem('mailToCompany'),
				sendpodata: this.payloadEmail,
			};
			const res = await CreateShipmentService.getRecipientMail(data);
			if (res.status === 200) {
				this.dbInstance = res.data.data[0][0].DB_INSTANCE;
				var tampungEmail = [];
				// var getOrgId = null;
				var suppliername = null;
				res.data.data[0].forEach((element) => {
					tampungEmail.push(element.targetemailgroup);
					suppliername = element.vendorname;
				});
				this.recipientMail = tampungEmail
					.toString()
					.replace(/"/g, '')
					.replace(/[\[\]']+/g, '')
					.replace(/ /g, '')
					.replace(/,/g, ', ');
				this.vendorname = suppliername;
				// this.orgIdNotif = getOrgId;
				// return res;
			}
			// KIRIM NOTIFIKASI KE DASHBOARD
			const payload = {
				idmodule: '3',
				sbjmessage:
					'New Shipment Notice # ' +
					this.doNumber +
					' from ' +
					this.vendorname,
				// receiveddate: new Date(),
				status: 'Open',
				notif_type: 'Purchasing',
				sender: this.sender,
				recipient: this.recipientMail,
				dbInstance: this.dbInstance,
				orgIdComp: this.orgIdCompany,
				usersenderid: this.idUser,
				// userreceiveid: this.usersenderid,
			};
			const value = CreateShipmentService.sendPONotifSupp(payload);
			if (value.status === 200) {
				window.alert('Purchasing Successfully Notified');
				location.reload();
			}
		},
	},
};
</script>
