<template>
	<div class="grid">
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header uppercase">
					add/edit item spesification
				</div>
				<div class="content-body">
					<div class="col-12 p-fluid">
						<div class="grid formgrid" style="margin: 1em 0 0 0">
							<div class="col-12 mb-5 lg:col-4 bgInput">
								<span class="p-float-label">
									<InputText
										v-model="itemNumber"
										:class="{
											'p-invalid': v$.itemNumber.$error,
										}"
										class="w-full mb-2 p-inputtext lg:mb-0"
										:disabled="true"
									/>
									<label for="inputtext">Item Number</label>
								</span>
								<small
									v-if="v$.itemNumber.$error"
									class="p-error"
									>{{
										v$.itemNumber.$errors[0].$message.replace(
											'Value',
											'Item Number'
										)
									}}</small
								>
							</div>
							<div class="col-12 mb-5 lg:col-4 bgInput">
								<span class="p-float-label">
									<InputText
										v-model="itemDescription"
										class="w-full mb-2 lg:mb-0 p-inputtext"
										:disabled="true"
										:class="{
											'p-invalid': v$.itemNumber.$error,
										}"
									/>
									<label for="inputtext"
										>Item Description</label
									>
								</span>
								<small
									v-if="v$.itemDescription.$error"
									class="p-error"
									>{{
										v$.itemDescription.$errors[0].$message.replace(
											'Value',
											'Item Description'
										)
									}}</small
								>
							</div>
							<div class="col-12 mb-5 lg:col-2">
								<Button
									@click="handleClickModal()"
									class="p-button-secondary uppercase mr-3"
									label="select item"
								/>
							</div>
							<div class="col-12 mb-5 lg:col-4">
								<span class="p-float-label">
									<InputText
										v-model="material"
										:class="{
											'p-invalid': v$.material.$error,
										}"
										class="w-full mb-2 lg:mb-0"
									/>
									<label for="inputtext">Material</label>
								</span>
								<small
									v-if="v$.material.$error"
									class="p-error"
									>{{
										v$.material.$errors[0].$message.replace(
											'Value',
											'material'
										)
									}}</small
								>
							</div>
							<div class="col-12 mb-5 lg:col-4">
								<span class="p-float-label">
									<InputText
										v-model="color"
										:class="{
											'p-invalid': v$.color.$error,
										}"
										class="w-full mb-2 lg:mb-0"
									/>
									<label for="inputtext">Color</label>
								</span>
								<small v-if="v$.color.$error" class="p-error">{{
									v$.color.$errors[0].$message.replace(
										'Value',
										'color'
									)
								}}</small>
							</div>
							<div class="col-12 mb-5 lg:col-4">
								<span class="p-float-label">
									<InputText
										v-model="size"
										:class="{
											'p-invalid': v$.size.$error,
										}"
										class="w-full mb-2 lg:mb-0"
									/>
									<label for="inputtext">Size</label>
								</span>
								<small v-if="v$.size.$error" class="p-error">{{
									v$.size.$errors[0].$message.replace(
										'Value',
										'size'
									)
								}}</small>
							</div>
							<div class="col-12 mb-4 lg:col-4">
								<span class="p-float-label">
									<InputText
										v-model="remarkDesign"
										class="w-full mb-2 lg:mb-0"
										:class="{
											'p-invalid': v$.remarkDesign.$error,
										}"
									/>
									<label for="inputtext">Remark Design</label>
								</span>
								<small
									v-if="v$.remarkDesign.$error"
									class="p-error"
									>{{
										v$.remarkDesign.$errors[0].$message.replace(
											'Value',
											'Remark Design'
										)
									}}</small
								>
							</div>
						</div>
					</div>
					<div class="col-12 lg:col-4 ml-2">
						<span class="p-float-label mb-3">
							<label for="FileUpload">Attachment</label>
						</span>
						<div v-if="savedFile" class="flex">
							<a
								class="button-download p-button"
								:href="savedFile.url"
							>
								<span class="font-bold">
									<i class="pi pi-download mr-2" />
									{{ savedFile.filename }}
								</span>
							</a>
							<Button
								type="button"
								class="p-button-rounded p-button-text ml-2"
								icon="pi pi-trash"
								@click="handleDeleteFile"
							/>
						</div>
						<div v-else>
							<FileUpload
								ref="fileUpload"
								mode="advanced"
								class="w-full"
								chooseLabel="Upload File"
								fileLimit="1"
								accept="application/pdf, .xlsx, .xls"
								:maxFileSize="5000000"
								:showUploadButton="false"
								:showCancelButton="false"
								:customUpload="true"
								@select="onSelectFile"
								@remove="onRemoveFile"
							/>
						</div>
					</div>
					<div class="col-12" v-if="id != 0">
						<div class="text-right" style="margin: 1em 0 0 0">
							<div class="mb-4">
								<Button
									@click="handleBack()"
									class="p-button-secondary uppercase mr-3"
									label="back"
								/>
								<Button
									@click="handleUpdate()"
									class="p-button-success uppercase"
									:label="textUpdate"
									:disabled="disableButton"
								/>
							</div>
						</div>
					</div>
					<div class="col-12" v-else>
						<div class="text-right" style="margin: 1em 0 0 0">
							<div class="mb-4">
								<Button
									@click="handleBack()"
									class="p-button-secondary uppercase mr-3"
									label="back"
								/>
								<Button
									@click="handleSave()"
									class="p-button-success uppercase"
									:label="textCreate"
									:disabled="disableCreate"
								/>
							</div>
						</div>
					</div>
					<div class="col-12">
						<DataTable
							ref="dt"
							:value="dataLine"
							dataKey="id"
							:metaKeySelection="false"
							selectionMode="multiple"
							v-model:selection="selectedData"
							@row-select="onRowSelect"
							responsiveLayout="scroll"
							:scrollable="true"
							scrollHeight="600px"
							:key="dataLineReload"
							:loading="loadingData"
						>
							<Column header="Qty Min">
								<template #body="{ data, index }">
									<InputNumber
										v-model="data.QTY_MIN"
										type="text"
										:min="0"
										mode="decimal"
										locale="id-ID"
										:minFractionDigits="2"
										:maxFractionDigits="2"
										class="p-column-filter"
										placeholder="Qty Min"
										:class="{
											'p-invalid':
												v$.dataLine.$error &&
												v$.dataLine.$errors[0].$response
													.$data[index].QTY_MIN
													.$error,
										}"
									/>
									<div
										v-if="
											v$.dataLine.$error &&
											v$.dataLine.$errors[0].$response
												.$data[index].QTY_MIN.$error
										"
										class="mt-1"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.dataLine.$error &&
												v$.dataLine.$errors[0].$response
													.$errors[index].QTY_MIN[0]
													.$message
											}}
										</small>
									</div>
								</template>
							</Column>
							<Column header="Qty Max">
								<template #body="{ data, index }">
									<InputNumber
										v-model="data.QTY_MAX"
										type="text"
										:min="0"
										mode="decimal"
										locale="id-ID"
										:minFractionDigits="2"
										:maxFractionDigits="2"
										class="p-column-filter"
										placeholder="Qty Max"
										:class="{
											'p-invalid':
												v$.dataLine.$error &&
												v$.dataLine.$errors[0].$response
													.$data[index].QTY_MAX
													.$error,
										}"
									/>
									<div
										v-if="
											v$.dataLine.$error &&
											v$.dataLine.$errors[0].$response
												.$data[index].QTY_MAX.$error
										"
										class="mt-1"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.dataLine.$error &&
												v$.dataLine.$errors[0].$response
													.$errors[index].QTY_MAX[0]
													.$message
											}}
										</small>
									</div>
								</template>
							</Column>
							<Column header="Price">
								<template #body="{ data, index }">
									<InputNumber
										v-model="data.PRICE"
										type="text"
										:min="0"
										mode="decimal"
										locale="id-ID"
										:minFractionDigits="2"
										:maxFractionDigits="2"
										class="p-column-filter"
										placeholder="Price"
										:class="{
											'p-invalid':
												v$.dataLine.$error &&
												v$.dataLine.$errors[0].$response
													.$data[index].PRICE.$error,
										}"
									/>
									<div
										v-if="
											v$.dataLine.$error &&
											v$.dataLine.$errors[0].$response
												.$data[index].PRICE.$error
										"
										class="mt-1"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.dataLine.$error &&
												v$.dataLine.$errors[0].$response
													.$errors[index].PRICE[0]
													.$message
											}}
										</small>
									</div>
								</template>
							</Column>
							<Column header="Uom">
								<template #body="{ data, index }">
									<AutoComplete
										v-model="data.dataUom"
										type="text"
										:dropdown="true"
										class="p-column-filter"
										placeholder="Select Uom"
										field="unit_of_measure"
										:suggestions="filteredUom"
										@complete="searchUom($event)"
										forceSelection
										:class="{
											'p-invalid':
												v$.dataLine.$error &&
												v$.dataLine.$errors[0].$response
													.$data[index].dataUom
													.$error,
										}"
									/>
									<div
										v-if="
											v$.dataLine.$error &&
											v$.dataLine.$errors[0].$response
												.$data[index].dataUom.$error
										"
										class="mt-1"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.dataLine.$error &&
												v$.dataLine.$errors[0].$response.$errors[
													index
												].dataUom[0].$message.replace(
													'Value',
													'Uom'
												)
											}}
										</small>
									</div>
								</template>
							</Column>
							<Column header="Supplier">
								<template #body="{ data, index }">
									<AutoComplete
										v-model="data.dataVendor"
										type="text"
										:dropdown="true"
										class="p-column-filter"
										placeholder="Select Supplier"
										field="vendor_name"
										:suggestions="filteredVendors"
										@complete="searchVendors($event)"
										forceSelection
										:class="{
											'p-invalid':
												v$.dataLine.$error &&
												v$.dataLine.$errors[0].$response
													.$data[index].dataVendor
													.$error,
										}"
									/>
									<div
										v-if="
											v$.dataLine.$error &&
											v$.dataLine.$errors[0].$response
												.$data[index].dataVendor.$error
										"
										class="mt-1"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.dataLine.$error &&
												v$.dataLine.$errors[0].$response.$errors[
													index
												].dataVendor[0].$message.replace(
													'Value',
													'Supplier'
												)
											}}
										</small>
									</div>
								</template>
							</Column>

							<!-- Empty state -->
							<template #empty>
								<div>No item.</div>
							</template>
						</DataTable>
					</div>
					<div class="col-12 mt-3">
						<div class="text-right" style="margin: 1em 0 0 0">
							<div class="mb-4">
								<Button
									@click="addRev()"
									icon="pi pi-plus"
									class="p-button-success uppercase mr-3"
									label="add rev"
								/>
								<Button
									@click="handleDelete()"
									class="p-button-danger uppercase"
									label="delete checked"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Dialog
			v-model:visible="modal"
			:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
			:style="{ width: '50vw' }"
			:modal="true"
			:dismissableMask="true"
			:draggable="false"
		>
			<div class="pt-3">
				<itemSpec
					:supplierName="supplierInInvoiceHeader"
					@on-close="closeTOModal"
					@on-add="handleAddTO"
				/>
			</div>
		</Dialog>
	</div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useRouter, useRoute } from 'vue-router';
import itemSpec from '../components/PopupItemSpec.vue';
import AuthService from '../../../service/AuthService';
import SpecAndQuotation from '../../../service/SpecAndQuotation';
import SimpleCrypto from 'simple-crypto-js';
import LovService from '../../../service/LovService';
import { required, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

const dataLine = ref([]);
const selectedData = ref([]);
const itemNumber = ref();
const itemDescription = ref();
const material = ref();
const color = ref();
const size = ref();
const remarkDesign = ref();
const toast = useToast();
const router = useRouter();
const modal = ref(false);
const filteredVendors = ref([]);
const vendors = ref();
const dbInstance = ref();
const company = ref();
const listUom = ref();
const filteredUom = ref();
const route = useRoute();
const savedSpec = ref();
const file = ref();
const infoUser = ref();
const itemId = ref();
const savedFile = ref();
const deletedFile = ref();
const itemSpecId = ref();
const fileUpload = ref(null);
const disableButton = ref(false);
const textUpdate = ref('update');
const textCreate = ref('save');
const disableCreate = ref(false);
const itemExisting = ref();
const id = ref(route.params.id);
const fileCheck = ref(false);
const breadcrumbHome = { icon: 'pi pi-home', to: '/dashboard' };
const breadcrumbItems = [
	{
		label: 'Spec & Quotation',
		to: route.fullPath,
	},
];

watch(
	() => route.params.id,
	async newId => {
		await getData(newId); 
	}
)

onMounted(() => {
	AuthService.whoAmi().then(async (response) => {
		if (response.status === 200) {
			infoUser.value = response.data.data;
			const respCbN = await LovService.getCompanyByName(userCompany());
			company.value = respCbN.data.data[0];
			dbInstance.value = company.value.db_instance;
			if (id.value > 0) {
				await getData(id.value);
			} else {
				await vendorList();
				await uomList();
			}
		}
	});
});

const userCompany = () => {
	const secretKey = 'some-unique-key';
	const simpleCrypto = new SimpleCrypto(secretKey);
	return simpleCrypto.decrypt(sessionStorage.getItem('dropdownCompany'));
};

const getData = async (newId) => {
	try {
		const resultData = await SpecAndQuotation.getDataSpecQuotation(
			newId
		);

		const result = resultData.data;
		const type = typeof result;
		if (type === 'object') {
			itemNumber.value = result.dataHeader[0].ITEM_NUMBER;
			itemExisting.value = result.dataHeader[0].ITEM_NUMBER;
			itemDescription.value = result.dataHeader[0].ITEM_DESC;
			material.value = result.dataHeader[0].MATERIAL;
			color.value = result.dataHeader[0].COLOR;
			size.value = result.dataHeader[0].SPEC_SIZE;
			remarkDesign.value = result.dataHeader[0].WARN_DESIGN;
			itemId.value = result.dataHeader[0].ITEM_ID;
			itemSpecId.value = result.dataHeader[0].ITEM_SPEC_ID;

			for (const c of result.dataDetail) {
				c.id = c.ITEM_PRICE_ID;
			}
			//assign data table
			dataLine.value = result.dataDetail;

			//assign value line
			const detailSpec = dataLine.value;

			dataLine.value = detailSpec.map((item) => {
				return {
					...item,
					newId: item.ITEM_PRICE_ID,
				};
			});

			savedSpec.value = detailSpec;

			// let number = 0;

			await Promise.all([vendorList(), uomList(), getAttachment(newId)]);

			//assign supplier
			if (vendors.value) {
				dataLine.value.map((val) => {
					val.dataVendor = vendors.value.find(
						(element) => element.vendor_id === val.VENDOR_ID
					);
				});
			}

			//assign uom
			if (listUom.value) {
				dataLine.value.map((val) => {
					val.dataUom = listUom.value.find(
						(element) => element.unit_of_measure === val.UOM
					);
				});
			}

			console.log("id value", newId);
			id.value = await newId;
		} else {
			toast.add({
				severity: 'error',
				summary: 'Error',
				detail: `${result}`,
				life: 3000,
			});
		}
	} catch (error) {
		throw new Error(error);
	}
};

// Custom validator
const cannotZero = (value) => value !== 0;

//validation
const validationRules = computed(() => {
	const rules = {
		itemNumber: { required },
		itemDescription: { required },
		material: { required },
		color: { required },
		remarkDesign: { required },
		size: { required },
		dataLine: {
			$each: helpers.forEach({
				dataUom: { required },
				dataVendor: { required },
				QTY_MIN: {
					required,
					cannotZero: helpers.withMessage(
						'value must be greater than zero',
						cannotZero
					),
				},
				QTY_MAX: {
					required,
					cannotZero: helpers.withMessage(
						'value must be greater than zero',
						cannotZero
					),
				},
				PRICE: {
					required,
					cannotZero: helpers.withMessage(
						'value must be greater than zero',
						cannotZero
					),
				},
			}),
		},
	};
	return rules;
});

const v$ = useVuelidate(validationRules, {
	itemNumber,
	itemDescription,
	material,
	color,
	remarkDesign,
	size,
	dataLine,
});

const getAttachment = async (newId) => {
	try {
		const res = await SpecAndQuotation.getAttachmentFile(newId);

		if (res.status === 200) {
			savedFile.value = res.data.data;
		}
	} catch (error) {
		throw new Error(error);
	}
};

//list vendor
const vendorList = async () => {
	const res = await SpecAndQuotation.getListVendor({
		dbInstance: dbInstance.value,
	});
	try {
		if (res.status === 200) {
			vendors.value = res.data.data;
		}
	} catch (error) {
		throw new Error(error);
	}
};

//list uom
const uomList = async () => {
	const res = await SpecAndQuotation.getDataUom({
		dbInstance: dbInstance.value,
	});
	try {
		if (res.status === 200) {
			listUom.value = res.data.data;
		}
	} catch (error) {
		throw new Error(error);
	}
};

const handleAddTO = (selected) => {
	//
	itemNumber.value = selected.ITEM_NUMBER;
	itemDescription.value = selected.ITEM_DESC;
	itemId.value = selected.ITEM_ID;
	modal.value = false;

};

const handleClickModal = async () => {
	modal.value = true;
};

const closeTOModal = async () => {
	modal.value = false;
};

//on select file
const onSelectFile = (e) => {
	file.value = e.files[0];
};

//on Remove FIle
const onRemoveFile = (e) => {
	if (!e.files.length) {
		file.value = null;
	}
};

//handle delete
const handleDeleteFile = () => {
	deletedFile.value = savedFile.value;
	savedFile.value = null;
};

//add new row
const addRev = async () => {
	//last index
	let idx = dataLine.value.slice(-1);

	if (dataLine.value.length) {
		dataLine.value.push({
			ITEM_DESCRIPTION: null,
			QTY_MIN: 0,
			QTY_MAX: 0,
			PRICE: 0,
			dataUom: null,
			dataVendor: null,
			id: idx[0].id + 1,
		});
	} else {
		let newItems = [];
		let emptyObject = {
			ITEM_DESCRIPTION: null,
			QTY_MIN: 0,
			QTY_MAX: 0,
			PRICE: 0,
			dataUom: null,
			dataVendor: null,
		};

		newItems.push(JSON.parse(JSON.stringify(dataLine.value)));
		newItems[0].push(emptyObject);
		dataLine.value = newItems[0].map((item, index) => {
			return {
				id: index + 1,
				...item,
			};
		});
	}

	toast.add({
		severity: 'success',
		summary: 'New row added',
		life: 3000,
	});
};

const onRowSelect = () => {};

//delete row
const handleDelete = async () => {
	if (selectedData.value && selectedData.value.length) {
		dataLine.value = dataLine.value.filter(
			(val) => !selectedData.value.includes(val)
		);
	} else {
		toast.add({
			severity: 'error',
			summary: 'Please select item',
			life: 3000,
		});
	}
	selectedData.value = null;
};

//back page
const handleBack = () => {
	router.push({
		name: "SpecAndQuotation"
	});
};

//filtered suppliers
const searchVendors = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredVendors.value = [...vendors.value];
		} else {
			filteredVendors.value = vendors.value.filter((vendor) => {
				return (
					vendor.vendor_name &&
					vendor.vendor_name
						.toLowerCase()
						.match(event.query.toLowerCase())
				);
			});
		}
	}, 250);
};

//filtered suppliers
const searchUom = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredUom.value = [...listUom.value];
		} else {
			filteredUom.value = listUom.value.filter((item) => {
				return (
					item.unit_of_measure &&
					item.unit_of_measure
						.toLowerCase()
						.match(event.query.toLowerCase())
				);
			});
		}
	}, 250);
};

//format dete dexa
const formattingDate = (date, withTime) => {
	const theDate = new Date(date);
	const strDate =
		theDate.getFullYear() +
		'-' +
		(theDate.getMonth() + 1) +
		'-' +
		theDate.getDate();
	const strTime =
		theDate.getHours() +
		':' +
		theDate.getMinutes() +
		':' +
		theDate.getSeconds();
	if (withTime) {
		return `${strDate} ${strTime}`;
	} else {
		return `${strDate}`;
	}
};

//handle save
const handleSave = async () => {
	disableCreate.value = true;
	textCreate.value = 'loading...';
	const query = route.query;

	const isFormValid = await v$.value.$validate();

	if (!isFormValid) {
		toast.add({
			severity: 'error',
			summary: 'Please fill all required fields',
			life: 3000,
		});
		disableCreate.value = false;
		textCreate.value = 'save';
		return;
	}

	// if (!dataLine.value || dataLine.value.length === 0) {
	//   toast.add({
	//     severity: "error",
	//     summary: "Please add line",
	//     life: 3000,
	//   });
	//   disableCreate.value = false;
	//   textCreate.value = "save";
	//   return;
	// }

	if (
		!itemNumber.value ||
		!itemDescription.value ||
		!material.value ||
		!color.value ||
		!remarkDesign.value ||
		!size.value
	) {
		toast.add({
			severity: 'error',
			summary: 'Some fields are required',
			life: 3000,
		});
		disableCreate.value = false;
		textCreate.value = 'save';
		return;
	}

	const payload = {
		header: {
			ITEM_ID: itemId.value,
			ITEM_NUMBER: itemNumber.value,
			ITEM_DESC: itemDescription.value,
			MATERIAL: material.value,
			COLOR: color.value,
			WARN_DESIGN: remarkDesign.value,
			ORG_ID: parseInt(query.v),
			CREATED_BY: 1,
			CREATION_DATE: formattingDate(new Date()),
			LAST_UPDATED_BY: 0,
			LAST_UPDATE_DATE: null,
			SPEC_SIZE: size.value,
			DB_INSTANCE: query.i,
		},
		dataDetail: [],
	};

	payload.dataDetail = dataLine.value.map((item) => {
		return {
			VENDOR_ID: item.dataVendor ? item.dataVendor.vendor_id : null,
			QTY_MIN: item.QTY_MIN,
			QTY_MAX: item.QTY_MAX,
			PRICE: item.PRICE,
			VENDOR_NAME: item.dataVendor ? item.dataVendor.vendor_name : null,
			UOM: item.dataUom ? item.dataUom.unit_of_measure : null,
			CREATED_BY: 1,
			CREATION_DATE: formattingDate(new Date()),
			ITEM_ID: itemId.value,
		};
	});

	const jsonPayload = JSON.stringify(payload);
	let formData = new FormData();
	formData.append('payload', jsonPayload);
	formData.append('file', file.value);

	try {
		const res = await SpecAndQuotation.handleSave(formData);

		if (res.status === 201) {
			file.value = null;
			// fileUpload.value.clear();
			v$.value.$reset();
			textCreate.value = 'save';
			disableCreate.value = true;
			selectedData.value = null;
			
			setTimeout(() => {
				toast.add({
					severity: 'success',
					summary: 'Successfully edit spec and quotation',
					life: 3000,
				});
			}, 2000);

			const id = await res.data.data.ITEM_SPEC_ID
			router.push({
				name: "DetailSpecQuotation",
				params: {
					id: id,
				},
				query: { v: company.value.value, i: company.value.db_instance },
			});
			return;
		}
	} catch (error) {
		textCreate.value = 'save';
		disableCreate.value = false;

		if (error.message.includes('Network Error')) {
			toast.add({
				severity: 'error',
				summary: 'Network Error',
				detail: 'Connection Down. Please check on Spec And Quotation menu.',
			});
			return;
		}

		if (error.response.status === 400 || error.response.status === 403) {
			if (error.response.data.message.includes('exist')) {
				toast.add({
					severity: 'error',
					summary: error.response.data.message,
					life: 3000,
				});
				return;
			}
		}

		toast.add({
			severity: 'error',
			summary: 'Server Error: Unsuccessfully update',
			life: 3000,
		});
	}
};

//update data
const handleUpdate = async () => {
	disableButton.value = true;
	fileCheck.value = true;
	textUpdate.value = 'loading...';
	const query = route.query;

	const isFormValid = await v$.value.$validate();

	if (!isFormValid) {
		toast.add({
			severity: 'error',
			summary: 'Please fill all required fields',
			life: 3000,
		});
		disableButton.value = false;
		textUpdate.value = 'update';
		return;
	}

	// if (!dataLine.value || dataLine.value.length === 0) {
	//   toast.add({
	//     severity: "error",
	//     summary: "Please add line",
	//     life: 3000,
	//   });
	//   disableButton.value = false;
	//   textUpdate.value = "update";
	//   return;
	// }

	if (
		!itemNumber.value ||
		!itemDescription.value ||
		!material.value ||
		!color.value ||
		!remarkDesign.value ||
		!size.value
	) {
		toast.add({
			severity: 'error',
			summary: 'Some fields are required',
			life: 3000,
		});
		disableButton.value = false;
		textUpdate.value = 'update';
		return;
	}

	//wrap formData
	const formData = new FormData();
	formData.append('file', file.value);

	const payload = {
		header: {
			ITEM_ID: itemId.value,
			ITEM_NUMBER: itemNumber.value,
			ITEM_DESC: itemDescription.value,
			MATERIAL: material.value,
			COLOR: color.value,
			WARN_DESIGN: remarkDesign.value,
			ORG_ID: parseInt(query.v),
			CREATED_BY: 1,
			CREATION_DATE: formattingDate(new Date()),
			LAST_UPDATED_BY: 0,
			LAST_UPDATE_DATE: null,
			ITEM_SPEC_ID: itemSpecId.value,
			SPEC_SIZE: size.value,
			DB_INSTANCE: query.i,
		},
		exist: {
			itemExisting: itemExisting.value,
		},
		editDetails: [],
		newDetails: [],
		removedDetails: [],
		fileCheck: fileCheck.value,
	};

	//update
	payload.editDetails = dataLine.value
		.filter((val) =>
			savedSpec.value.some((item) => item.ITEM_PRICE_ID === val.newId)
		)
		.map((obj) => {
			return {
				ITEM_PRICE_ID: obj.ITEM_PRICE_ID,
				ITEM_SPEC_ID: obj.ITEM_SPEC_ID,
				VENDOR_ID: obj.dataVendor ? obj.dataVendor.vendor_id : null,
				QTY_MIN: obj.QTY_MIN,
				QTY_MAX: obj.QTY_MAX,
				PRICE: obj.PRICE,
				VENDOR_NAME: obj.dataVendor ? obj.dataVendor.vendor_name : null,
				UOM: obj.dataUom ? obj.dataUom.unit_of_measure : null,
				CREATED_BY: 1,
				CREATION_DATE: formattingDate(new Date()),
				ITEM_ID: itemId.value,
			};
		});

	//create
	payload.newDetails = dataLine.value
		.filter(
			(val) =>
				!savedSpec.value.some(
					(item) => item.ITEM_PRICE_ID === val.newId
				)
		)
		.map((item) => {
			return {
				ITEM_SPEC_ID: parseInt(id.value),
				VENDOR_ID: item.dataVendor ? item.dataVendor.vendor_id : null,
				QTY_MIN: item.QTY_MIN,
				QTY_MAX: item.QTY_MAX,
				PRICE: item.PRICE,
				VENDOR_NAME: item.dataVendor
					? item.dataVendor.vendor_name
					: null,
				UOM: item.dataUom ? item.dataUom.unit_of_measure : null,
				CREATED_BY: 1,
				CREATION_DATE: formattingDate(new Date()),
				ITEM_ID: itemId.value,
			};
		});

	//delete
	payload.removedDetails = savedSpec.value
		.filter(
			(item) =>
				!dataLine.value.some((val) => val.newId === item.ITEM_PRICE_ID)
		)
		.map((c) => {
			return {
				ITEM_PRICE_ID: c.ITEM_PRICE_ID,
				ITEM_SPEC_ID: c.ITEM_SPEC_ID,
			};
		});

	//
	// Deleted payload
	if (deletedFile.value) {
		fileCheck.value = true;
		payload.delFile = deletedFile.value;
	} else if (file.value === null && deletedFile.value === null) {
		fileCheck.value = false;
	} else if (file.value === undefined && fileCheck.value) {
		fileCheck.value = false;
		//
	}

	// Wrap payload with formData
	const jsonPayload = JSON.stringify(payload);
	formData.append('payload', jsonPayload);

	try {
		const res = await SpecAndQuotation.updateSpecAndQuotation(
			parseInt(id.value),
			formData
		);

		if (res.status === 200) {
			file.value = null;
			deletedFile.value = null;
			v$.value.$reset();

			await getAttachment(id.value);
			await getData(id.value);

			textUpdate.value = 'update';
			disableButton.value = false;
			fileCheck.value = false;
			selectedData.value = null;
			itemExisting.value = itemNumber.value;

			toast.add({
				severity: 'success',
				summary: 'Successfully edit spec and quotation',
				life: 3000,
			});
			return;
		}
	} catch (error) {
		textUpdate.value = 'update';
		disableButton.value = false;
		fileCheck.value = false;

		if (error.message.includes('Network Error')) {
			toast.add({
				severity: 'error',
				summary: 'Network Error',
				detail: 'Connection Down. Please check on Spec And Quotation menu.',
			});
			return;
		}

		if (error.response.status === 400 || error.response.status === 403) {
			if (error.response.data.message.includes('exist')) {
				toast.add({
					severity: 'error',
					summary: error.response.data.message,
					life: 3000,
				});
				return;
			}
		}

		toast.add({
			severity: 'error',
			summary: 'Server Error: Unsuccessfully update',
			life: 3000,
		});
	}
};
</script>

<style lang="scss" scoped>
.bgInput {
	.p-inputtext {
		background: rgb(223, 223, 223);
		color: rgb(0, 0, 0);
		&:last-child {
			margin-bottom: 0;
		}
	}
}
</style>
