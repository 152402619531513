import SpbReportApi from "./api/SpbReportApi";

class PoHistoryService {
  async getvendorbytenant(userId, companyName, dbInstance, orgId, levelTenant) {
    return SpbReportApi.get(
      `spb/pohistory/getvendorbytenant/${userId}/${companyName}/${dbInstance}/${orgId}/${levelTenant}`
    );
  }

  async getlistponumber(vendorId, dbInstance, orgId, levelTenant) {
    return SpbReportApi.get(
      `spb/pohistory/getlistponumber/${vendorId}/${dbInstance}/${orgId}/${levelTenant}`
    );
  }

  async getlistreturn(payload) {
    return SpbReportApi.post(`spb/pohistory/getlistreturn`, payload);
  }

  async getdownloaddata(payload) {
    return SpbReportApi.post(`spb/pohistory/getdownloaddata`, payload);
  }
}

export default new PoHistoryService();
