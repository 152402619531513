<template>
  <AutoComplete
    :suggestions="filteredPoNumber"
    @complete="search($event)"
    :dropdown="true"
    field="pono"
    forceSelection
  >
    <template #item="slotProp">
      {{ slotProp.item.pono }}
    </template>
  </AutoComplete>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect } from "vue";
import ReturnService from "../../../service/ReturnService";

const filteredPoNumber = ref([]);
const poNumberList = ref([]);
const poNumber = ref();
// const placeholder = ref("Select a Batch Number");

const emit = defineEmits(["poNumber"]);
watchEffect(() => emit("poNumber", poNumber));

// eslint-disable-next-line no-undef
const props = defineProps({
  company: {
    type: Object,
  },
  vendorId: {
    type: Object,
  },
  levelTenant: {
    type: String,
  },
});

onMounted(async () => {
  try {
    // 
    if (props.company && props.vendorId && props.levelTenant) {
      const result = await ReturnService.getlistponumber(
        props.vendorId,
        props.company.db_instance,
        props.company.value,
        props.levelTenant
      );
      
      poNumberList.value = await result.data;
    }

    // eslint-disable-next-line no-empty
  } catch (e) {
    throw new Error(e);
  }
});

const search = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredPoNumber.value = [...poNumberList.value];
    } else {
      filteredPoNumber.value = poNumberList.value.filter((poNum) => {
        
        return poNum.pono
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};
</script>
