<template>
  <AutoComplete
    :suggestions="filteredSupplier"
    @complete="searchSupplierSite($event)"
    :dropdown="true"
    field="vendor_mapping"
    :placeholder="placeholder"
    v-model="supplier"
    forceSelection
  >
    <template #item="slotProp">
      {{ slotProp.item.vendor_mapping }}
    </template>
  </AutoComplete>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect } from "vue";
//   import LovService from "@/service/LovService";
//   import SwithCompany from "@/service/SwithCompany";
// import AuthService from "@/service/AuthService";
import { getListSupplierSite } from "@/service/InvoiceProxyNonPOServices";

// eslint-disable-next-line no-undef
const filteredSupplier = ref([]);
const supplierList = ref([]);
const supplier = ref();
const placeholder = ref("Select a supplier");

const emit = defineEmits(["supplier"]);

watchEffect(() => emit("supplier", supplier));
// eslint-disable-next-line no-undef
const props = defineProps({
  company: {
    type: Object,
  },
  userId: {
    type: String,
  },
});

onMounted(async () => {
  try {
    // const response = await AuthService.whoAmi();
    // const user = response.data.data;
    if (props.company && props.userId) {
      const result = await getListSupplierSite(
        props.userId,
        props.company.db_instance,
        props.company.value,
        props.company.name
      );
      supplierList.value = result.data;
    }

    // eslint-disable-next-line no-empty
  } catch (e) {
    throw new Error(e);
  }
});

const searchSupplierSite = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredSupplier.value = [...supplierList.value];
    } else {
      filteredSupplier.value = supplierList.value.filter((supp) => {
        return supp.vendor_mapping
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};
</script>
