<template>
	<div class="grid">
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<div class="col-12 p-fluid">
			<div class="card">
				<div class="grid formgrid" style="margin: 1em 0 0 0">
					<div class="col-12 mb-5 lg:col-3 lg:mb-5">
						<span class="p-float-label">
							<AutoComplete
								v-model="company"
								:suggestions="filteredCompany"
								@complete="searchCompany($event)"
								:dropdown="true"
								field="name"
								forceSelection
								:disabled="!isSupplierPage"
							>
								<template #item="slotProps">
									<div class="supplier-item">
										<div class="ml-2">
											{{ slotProps.item.name }}
										</div>
									</div>
								</template>
							</AutoComplete>
							<label for="inputtext">Company</label>
						</span>
					</div>
					<div
						v-if="!isSupplierPage"
						class="col-12 mb-5 lg:col-3 lg:mb-5"
					>
						<span class="p-float-label">
							<AutoComplete
								v-model="supplier"
								:suggestions="filteredSupplier"
								@complete="searchSupplier($event)"
								:dropdown="true"
								field="vendor_name"
								:disabled="companyValue"
								forceSelection
							>
								<template #item="slotProps">
									<div class="supplier-item">
										<div class="ml-2">
											{{ slotProps.item.vendor_name }}
										</div>
									</div>
								</template>
							</AutoComplete>
							<label for="inputtext">Supplier</label>
						</span>
					</div>
					<div class="col-12 mb-5 lg:col-3 lg:mb-5">
						<span class="p-float-label">
							<AutoComplete
								v-model="planningOrder"
								:suggestions="filteredPlanningOrder"
								@complete="searchPoNumber($event)"
								field="segment1"
								:disabled="buttonDisable"
								forceSelection
							>
							</AutoComplete>
							<label for="inputtext">PO Number</label>
						</span>
					</div>
					<div class="col-12 mb-5 lg:col-3 lg:mb-5">
						<span class="p-float-label">
							<AutoComplete
								v-model="lov"
								:suggestions="filteredLov"
								@complete="searchLov($event)"
								:dropdown="true"
								field="name"
								:disabled="buttonDisable"
								forceSelection
							>
								<template #item="slotProps">
									<div class="lov-item">
										<div class="ml-2">
											{{ slotProps.item.name }}
										</div>
									</div>
								</template>
							</AutoComplete>
							<label for="inputtext">Status</label>
						</span>
					</div>
					<div
						class="col-12 mb-5 lg:col-3 lg:mb-5"
						v-if="!isSupplierPage"
					>
						<span class="p-float-label">
							<AutoComplete
								v-model="loe"
								:suggestions="filteredLoe"
								@complete="searchLoe($event)"
								:dropdown="true"
								field="name"
								forceSelection
							>
								<template #item="slotProps">
									<div class="preparer-item">
										<div class="ml-2">
											{{ slotProps.item.name }}
										</div>
									</div>
								</template>
							</AutoComplete>
							<label for="inputtext">L/O/E</label>
						</span>
					</div>
					<div
						class="col-12 mb-5 lg:col-3 lg:mb-5"
						v-if="!isSupplierPage"
					>
						<span class="p-float-label">
							<AutoComplete
								v-model="preparer"
								:suggestions="filteredPreparer"
								:disabled="preparerButton"
								@complete="searchPreparer($event)"
								:dropdown="true"
								field="user_name"
								forceSelection
							>
								<template #item="slotProps">
									<div class="preparer-item">
										<div class="ml-2">
											{{ slotProps.item.user_name }}
										</div>
									</div>
								</template>
							</AutoComplete>
							<label for="inputtext">Preparer</label>
						</span>
					</div>
					<div class="col-12 mb-2 lg:col-2 xl:col-1 lg:mb-5">
						<Button
							label="Go"
							@click="searchTableNested()"
							:disabled="this.buttonDisable"
						></Button>
					</div>
					<div class="col-12 mb-2 lg:col-2 xl:col-1 lg:mb-5">
						<Button
							class="p-button-secondary"
							label="Clear"
							@click="clearFilter"
						></Button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="card">
		<DataTable
			class="fixed-scroll-datatable"
			:value="nested"
			:lazy="true"
			:paginator="true"
			:rows="rows"
			v-model:expandedRows="expandedRows"
			ref="dt"
			dataKey="po_num"
			:totalRecords="totalRecords"
			:loading="loading"
			@page="onPage($event)"
			@sort="onSort($event)"
			removableSort
			responsiveLayout="scroll"
			:rowsPerPageOptions="[10, 20, 30]"
			:v-model="first"
		>
			<template #empty> {{ isEmpty }} </template>
			<Column :expander="true" style="width: 50px; flex: 0 0 0" />
			<Column field="po_num" header="PO Number" :sortable="true"></Column>
			<template #expansion="slotProps">
				<div class="orders-subtable">
					<DataTable
						:value="slotProps.data.children"
						removableSort
						responsiveLayout="scroll"
						:scrollable="true"
						scrollHeight="600px"
						scrollDirection="both"
					>
						<template #empty> {{ isEmpty }} </template>
						<Column header="Line" style="width: 9%" frozen>
							<template #body="slotProps">
								{{ slotProps.data.line_num }}
							</template>
						</Column>
						<!-- <Column field="id" header="Line" :sortable="true" style="text-align: center"></Column> -->
						<Column
							field="itemnum"
							header="Item Number"
							:sortable="true"
							style="width: 20%"
						></Column>
						<Column
							field="uom"
							header="UOM"
							:sortable="true"
							style="width: 12%"
						></Column>
						<Column
							field="itemdesc"
							header="Item Description"
							:sortable="true"
							style="width: 28%"
						></Column>
						<Column
							field="currency"
							header="Currency"
							:sortable="true"
							style="width: 15%; justify-content: center"
						></Column>
						<Column
							header="Unit Price"
							dataType="numeric"
							style="width: 15%"
							><template #body="{ data }">
								{{ formatCurrency(data.uprice) }}
							</template>
						</Column>
						<!-- <Column
							field="uprice"
							header="Uwdawdasdawe"
							:sortable="true"
							style="text-align: center"
						></Column> -->
						<Column
							header="Amount"
							dataType="numeric"
							style="width: 15%"
						>
							<template #body="{ data }">
								{{ formatCurrency(data.amount) }}
							</template></Column
						>
						<Column
							field="note"
							header="Note to Supplier"
							:sortable="true"
							style="width: 20%"
						></Column>
						<Column
							sortField="arrivalSort"
							field="arrival"
							header="Arrival at Site"
							:sortable="true"
							style="width: 15%"
						>
							<!-- <template #body="slotProps">
								{{
									new Date(
										slotProps.data.arrival
									).toLocaleString('id-ID', {
										day: 'numeric',
										month: 'short',
										year: 'numeric',
										hour: '2-digit',
										minute: '2-digit',
										second: '2-digit',
									})
								}}
							</template> -->
							<!-- <template #body="slotProps">
								{{ slotProps.data.arrival }}
							</template> -->
						</Column>
						<Column
							sortField="estarrivalSort"
							field="estarrival"
							header="Est. Arrival at Site"
							:sortable="true"
							style="width: 15%"
						>
							<!-- <template #body="slotProps"> -->
							<!-- {{
										new Date(
										slotProps.data.estarrival
									).toLocaleString('id-ID', {
										day: 'numeric',
										month: 'short',
										year: 'numeric',
										hour: '2-digit',
										minute: '2-digit',
										second: '2-digit',
									})
								}} -->

							<!-- {{ slotProps.data.estarrival }}
							</template> -->
						</Column>
						<Column
							sortField="creationSort"
							field="creation"
							header="Submit Date"
							:sortable="true"
							style="width: 15%"
						>
							<!-- <template #body="slotProps">
								{{
									new Date(
										slotProps.data.creation
									).toLocaleString('id-ID', {
										day: 'numeric',
										month: 'short',
										year: 'numeric',
									})
								}}
								{{ slotProps.data.shipment }}
							</template> -->
						</Column>
						<Column
							field="status"
							header="Line Status"
							:sortable="true"
							style="width: 15%"
						></Column>
						<Column
							field="loe"
							header="L/O/E"
							:sortable="true"
							style="width: 15%"
						></Column>
						<Column
							field="jit_status"
							header="JIT Status"
							:sortable="true"
							style="width: 15%"
						></Column>
						<Column
							header="View"
							selectionMode="single"
							style="width: 9%"
							alignFrozen="right"
							frozen
						>
							<template #body="slotProps">
								<Button
									icon="pi pi-search"
									class="p-button-rounded p-button-text"
									@click="editChildren(slotProps.data)"
								/>
							</template>
						</Column>
					</DataTable>
				</div>
			</template>
		</DataTable>
	</div>
</template>

<script>
/* eslint-disable */
import AuthService from '../../service/AuthService';
import LovService from '../../service/LovService';
import SimpleCrypto from 'simple-crypto-js';
import PoFulfillment from '../../service/PoFulfillment';
import BASTService from "../../service/BASTService";

export default {
	//  name: "poFulfillment-list",
	name: 'planningorder-list',
	data() {
		return {
			expandedRowGroups: null,
			isSupplierPage: false,
			first: 0,
			rowId: null,
			expandedRows: [],
			expandedRowGroups: [],
			nested: [],
			rows: 10,
			nestedchildren: [],
			groupdata: null,
			selectedChildren: null,
			loading: false,
			lazyParams: {},
			selectLazyParams: {},
			totalRecords: 0,
			totalRecordsGrup: 0,
			tabelexpand: null,
			company: null,
			companyValue: true,
			filteredCompany: null,
			isiCompany: null,
			supplier: null,
			isisupplier: null,
			filteredSupplier: null,
			selectedSupplier: null,
			supplierparse: null,
			search: null,
			filtered: null,
			preparer: null,
			isipreparer: null,
			filteredPreparer: null,
			preparerparse: null,
			selectedPreparer: null,
			lov: null,
			isilov: null,
			filteredLov: null,
			lovparse: null,
			selectedLov: null,
			planningOrder: null,
			isiPlanningOrder: null,
			filteredPlanningOrder: null,
			selectedPlanningOrder: null,
			loe: null,
			isiLoe: null,
			filteredLoe: null,
			button: 0,
			buttonDisable: true,
			buttonSearch: true,
			rowgroup: null,
			testname: null,
			onStorageUpdate: null,
			saveDb: null,
			preparerButton: true,
			suppBindToComp: [],
			isEmpty: null,
			suppName: null,
			// isSupplierPage:false,
			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [
				{ label: 'Po Fulfillment', to: '/mgm/settings/sysparamslist' },
			],
		};
	},
	watch: {
		company(newQuestion) {
			if (this.company != null) {
				sessionStorage.setItem('mailToCompany', this.company.name);
				this.planningOrder = null;
				this.lov = null;
				this.preparer = null;
				this.companyValue = false;
				this.preparerButton = false;
				if (
					typeof newQuestion === 'object' &&
					!Array.isArray(newQuestion) &&
					newQuestion !== null
				) {
					// this.getPlenno(newQuestion.name);
					this.companyValue = false;
					this.saveDb = this.company.db_instance;
					//
					sessionStorage.setItem('companydb', this.saveDb);

					//
					if (sessionStorage.leveltenant == 1 || sessionStorage.leveltenant == 3) {
						this.getValueCompany(newQuestion.name);
						this.GetPreparer();
						this.getPlenno();
						this.buttonDisable = false;
					} else {
						this.getValueCompany(newQuestion.name);
					}
				}
			}
		},
		supplier(newQuestion) {
			if (
				this.planningOrder == null &&
				this.lov == null &&
				this.preparer == null
			) {
				this.lov = null;
				this.preparer = null;
				this.buttonSearch = false;
				if (
					typeof newQuestion === 'object' &&
					!Array.isArray(newQuestion) &&
					newQuestion !== null
				) {
					this.getValueSup(newQuestion.name);
					//
					this.buttonDisable = false;
				}
			}
		},
		planningOrder(newQuestion) {
			if (this.supplier == null) {
				if (
					typeof newQuestion === 'object' &&
					!Array.isArray(newQuestion) &&
					newQuestion !== null
				) {
					// this.getValuePoNum(newQuestion.name);
					this.buttonDisable = false;
				}
			}
		},
		lov(newQuestion) {
			// if (this.supplier == null && this.planningOrder == null && this.preparer == null) {
			this.buttonDisable = false;
			// }
		},
		preparer(newQuestion) {
			// if (this.supplier == null && this.planningOrder == null && this.lov == null) {
			this.buttonDisable = false;
			// }
		},
	},
	created() {
		//
		// if (sessionStorage.leveltenant == 1) {
		//     if (sessionStorage.idUser == 2) {
		//         this.GetTableNested();
		//     }
		//     else {
		//
		//     }
		// }
		if (sessionStorage.leveltenant == 2) {
			AuthService.whoAmi().then((resp) => {
				//
				// let idUser = sessionStorage.getItem("idUser").toString();
				//
				PoFulfillment.getSuppBindComp(resp.data.data.id).then(
					(response) => {
						this.suppBindToComp.push(
							response.data.data[0][0].vendorname
						);
					}
				);
			});
		}
	},
	async mounted() {
		AuthService.whoAmi().then((resp) => {
			if (resp.status === 200) {
				this.getCompany();
				// this.GetPreparer();
				this.GetLOV();
				this.GetLOVLoe();
				if (resp.data.data.leveltenant == 1 || resp.data.data.leveltenant == 3) {
					this.getPoNumber();
				}
				if (resp.data.data.leveltenant == 2) {
					this.isSupplierPage = true;
					this.getValueCompany();
				}
				// this.isEmpty = 'Please Search for Data'
			}
		});
		this.isEmpty = 'Please Search for Data';
		// this.loading = true;
		this.lazyParams = {
			first: 0,
			sortField: null,
			sortOrder: null,
			rows: this.rows,
		};
		this.selectLazyParams = {
			first: 0,
			sortField: null,
			sortOrder: null,
			rows: this.rows,
		};
		if (sessionStorage.companydb) {
			this.testname = sessionStorage.companydb;
		}
	},
	computed: {
		searchUser() {
			const searchLc = this.search.toLowerCase();
			return this.nested.filter((item) => {
				return item.po_num.toLowerCase().includes(searchLc);
			});
		},
	},
	methods: {
		formatCurrency(value) {
			if (value) return value.toLocaleString('id-ID');
			return;
		},
		GetTableNested() {
			this.rows = 10;
			this.loading = true;
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					setTimeout(() => {
						PoFulfillment.GetTableNested({
							lazyEvent: JSON.stringify(this.lazyParams),
						}).then((response) => {
							this.nested = respond.data.header;
							this.totalRecords = response.data.totalRecords;
							this.loading = false;
							for (let id of this.nested) {
								//
								for (let index of id.children) {
									//
								}
							}

							//
						});
					}, Math.random() * 1000 + 250);
				}
			});
		},

		async searchTableNested() {
			this.expandedRows = null;
			this.rows = 10;
			this.button = 1;
			this.loading = true;
			let supplierSearch = this.supplier;
			if (this.supplier !== null) {
				supplierSearch = this.supplier.vendor_id;
			}
			let poSearch = this.planningOrder;
			if (this.planningOrder !== null) {
				poSearch = this.planningOrder.segment1;
			}
			let statusSearch = this.lov;
			if (this.lov !== null) {
				statusSearch = this.lov.value;
			}
			let loeStatus = this.loe;
			if (this.loe !== null) {
				loeStatus = this.loe.value;
			}
			let preparerSearch = this.preparer;
			if (this.preparer !== null) {
				preparerSearch = this.preparer.employee_id;
			}
			let org_id = this.company;
			if (this.company !== null) {
				if (sessionStorage.leveltenant == 1 || sessionStorage.leveltenant == 3) {
					org_id = this.company.value;
				}
				if (sessionStorage.leveltenant == 2) {
					org_id = this.company.value;
				}
			}
			const db_instance = this.company.db_instance
				? this.company.db_instance
				: null;
			const payload = {
				supplierSearch,
				poSearch,
				statusSearch,
				loeStatus,
				preparerSearch,
				org_id,
				db_instance,
			};

			this.buttonDisable = true;
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					setTimeout(() => {
						PoFulfillment.getSearchTableNested({
							...this.selectLazyParams,
							...payload,
						}).then((respond) => {
							if (respond.data.status == 200) {
								// this.nested = respond.data.nestedData;
								this.nested = respond.data.header;
								this.totalRecords = respond.data.totalRecords;
								this.loading = false;
								this.buttonDisable = false;
								for (let id of this.nested) {
									for (let index of id.children) {
										var option = {
											day: 'numeric',
											month: 'short',
											year: 'numeric',
										};
										index.arrivalSort = new Date(
											index.arrival
										).getTime();
										index.arrival = new Date(
											index.arrival
										).toLocaleDateString('id-ID', option);
										//
										if (
											index.arrival == 'Invalid Date' ||
											index.arrival == '1 Jan 1970'
										) {
											index.arrival = '';
										}
										index.estarrivalSort = new Date(
											index.estarrival
										).getTime();
										index.estarrival = new Date(
											index.estarrival
										).toLocaleDateString('id-ID', option);
										if (
											index.estarrival ==
												'Invalid Date' ||
											index.estarrival == '1 Jan 1970'
										) {
											index.estarrival = '';
										}
										index.shipment = new Date(
											index.shipment
										).toLocaleDateString('id-ID', option);
										if (
											index.shipment == 'Invalid Date' ||
											index.shipment == '1 Jan 1970'
										) {
											index.shipment = '';
										}
										index.creationSort = new Date(
											index.creation
										).getTime();
										index.creation = new Date(
											index.creation
										).toLocaleDateString('id-ID', option);
										if (
											index.creation == 'Invalid Date' ||
											index.creation == '1 Jan 1970'
										) {
											index.creation = '';
										}
										if (
											index.note === 'null' ||
											index.note === null
										) {
											index.note = '';
										}
										//
									}
								}
							} else {
								this.nested = null;
								this.totalRecords = null;
								this.isEmpty = 'No Data Found';
								this.loading = false;
								this.buttonDisable = false;
							}
						});
					}, Math.random() * 1000 + 250);
				}
			});
		},
		getCompany() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					if (value.data.data.leveltenant == 1 || value.data.data.leveltenant == 3 ) {
						if (value.data.data.userid == 'dexaadmin') {
							LovService.getValueType('COMPANY').then(
								(response) => {
									this.isiCompany = response.data.data;
									//
								}
							);
						}else if(value.data.data.leveltenant == 3){
							const secretKey = 'some-unique-key';
							const simpleCrypto = new SimpleCrypto(
								secretKey
							);
							const decipherText =
								simpleCrypto.decrypt(
									sessionStorage.getItem(
										'dropdownCompany'
									)
								);
							BASTService.getDetailCompany({company_name: decipherText})
								.then(
								(response) => {
									this.company = response.data.data;
									LovService.getcompanydb(this.company.db_instance).then(
										(responses) => {
											this.isiCompany = responses.data.data;
										}
									);
								}
							);
						}else {
							let payload = value.data.data.bioaddress;
							LovService.getcompanydb(payload).then(
									(response) => {
									// this.isiCompany = response.data.data
									//
									// let payload = {isi : response.data.data[0].company}
									//
									// PlanningOrder.getCompanyId(payload).then(response => {
									this.isiCompany = response.data.data;

									try {
										const secretKey = 'some-unique-key';
										const simpleCrypto = new SimpleCrypto(
											secretKey
										);
										const decipherText =
											simpleCrypto.decrypt(
												sessionStorage.getItem(
													'dropdownCompany'
												)
											);

										this.isiCompany.forEach((element) => {
											if (element.name === decipherText) {
												this.company = element;
												// this.searchTableNested();
												throw 'Break';
											}
										});
									} catch (e) {
										if (e !== 'Break') throw e;
									}

									// })
								}
							);
						}
					} else if (value.data.data.leveltenant == 2) {
						let payload = value.data.data.id;
						// PlanningOrder.getCompanySupp(payload).then(
						// 	(response) => {
						//
						// 		let company = [
						// 			{ name: response.data.data[0].company },
						// 		];
						// 		this.isiCompany = company;
						// 	}
						// );
						PoFulfillment.getCompanySupp(payload).then(
							(response) => {
								let payload = response.data.data;
								let companyList = [];

								try {
									payload.forEach((element) => {
										LovService.getCompanyByName(
											element.company
										).then((response) => {
											companyList.push(
												response.data.data[0]
											);

											const secretKey = 'some-unique-key';
											const simpleCrypto =
												new SimpleCrypto(secretKey);
											const decipherText =
												simpleCrypto.decrypt(
													sessionStorage.getItem(
														'dropdownCompany'
													)
												);

											if (
												response.data.data[0].name ===
												decipherText
											) {
												this.company =
													response.data.data[0];
												// this.searchTableNested();
											}
										});
									});
									this.isiCompany = companyList;
								} catch (e) {
									if (e !== 'Break') throw e;
								}
							}
						);
					}
				}
			});
		},
		async getValueCompany() {
			if(sessionStorage.leveltenant == 3){
				const respVendor = await PlanningOrderService.getCompanySupp(sessionStorage.idUser);
				console.log(respVendor, "VENDOR")
				this.isisupplier = respVendor.data.data.map((item) => {
					return{
						value: item.vendorid,
						name: item.fullname
					}
				});
			}else{
				let payload = {
					db_instance: this.company.db_instance,
					org_id: this.company.value,
				};
				AuthService.whoAmi().then((value) => {
					if (value.status === 200) {
						LovService.getSupplierName(payload).then((response) => {
							this.isisupplier = response.data.data;
							console.log("SINI YA?", response.data.data)
							//
							if (sessionStorage.getItem('leveltenant') == 2) {
								try {
									this.isisupplier.forEach((element) => {
										if (
											element.vendor_name ===
												this.suppBindToComp[0].toString() &&
											element.vendor_name !== null
										) {
											this.supplier = element;
											this.buttonDisable = false;
											throw 'Break';
										}
									});
								} catch (e) {
									if (e !== 'Break') throw e;
								}
								//
							}
						});
					}
				});
			}
		},
		async getPlenno() {
			const payload = { org_id: this.company.value };
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					PoFulfillment.getSearchDropdownComp(payload).then(
						(response) => {
							this.isiPlanningOrder = response.data.data;
						}
					);
				}
			});
		},
		getValueSup() {
			const payload = {
				supValue: this.supplier.vendor_id,
				org_id: this.company.value,
			};
			//
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					PoFulfillment.getSearchDropdownSupp(payload).then(
						(response) => {
							//
							this.isiPlanningOrder = response.data.data;
						}
					);
				}
			});
		},
		getPoNumber() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					PoFulfillment.getPoNumber().then((response) => {
						this.isiPlanningOrder = response.data.data;
					});
				}
			});
		},
		getValuePoNum() {
			const payload = { poValue: this.planningOrder.plan_order_num };
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					PoFulfillment.getSearchDropdownPoNum(payload).then(
						(response) => {
							console.log(response.data.data,"TESTING")
							this.isisupplier = response.data.data;
						}
					);
				}
			});
		},
		GetLOV() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					PoFulfillment.GetLovStatus().then((response) => {
						this.isilov = response.data.data;
					});
				}
			});
		},
		GetLOVLoe() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					PoFulfillment.GetLovStatusLoe().then((response) => {
						this.isiLoe = response.data.data;
						//
					});
				}
			});
		},
		GetPreparer() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					let payload = { isi: sessionStorage.companydb };
					PoFulfillment.GetPreparer(payload).then((response) => {
						this.isipreparer = response.data.data;
					});
				}
			});
		},
		getPlanningOrder() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					PoFulfillment.getPlanningOrder().then((response) => {
						this.isipreparer = response.data.data;
					});
				}
			});
		},
		searchSupplier(event) {
			console.log(this.isisupplier, "TEST")
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredSupplier = [...this.isisupplier];
				} else {
					this.filteredSupplier = this.isisupplier.filter((item) => {
						//
						if (item.vendor_name !== null) {
							return item.vendor_name
								.toLowerCase()
								.match(event.query.toLowerCase());
						}
					});
				}
			}, 250);
		},
		async searchCompany(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredCompany = [...this.isiCompany];
				} else {
					this.filteredCompany = this.isiCompany.filter((item) => {
						return item.name
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		searchPoNumber(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredPlanningOrder = [...this.isiPlanningOrder];
				} else {
					this.filteredPlanningOrder = this.isiPlanningOrder.filter(
						(item) => {
							if (item.segment1 !== null) {
								return item.segment1
									.toLowerCase()
									.match(event.query.toLowerCase());
							}
						}
					);
				}
			}, 250);
		},
		searchLov(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredLov = [...this.isilov];
				} else {
					this.filteredLov = this.isilov.filter((item) => {
						return item.name
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		searchLoe(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredLoe = [...this.isiLoe];
				} else {
					this.filteredLoe = this.isiLoe.filter((item) => {
						return item.name
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		searchPreparer(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredPreparer = [...this.isipreparer];
				} else {
					this.filteredPreparer = this.isipreparer.filter((item) => {
						return item.user_name
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		onSort(event) {
			switch (true) {
				case this.supplier === null &&
					this.planningOrder === null &&
					this.lov === null &&
					this.preparer === null:
					this.lazyParams = event;
					// this.GetTableNested();
					break;
				case this.company !== null ||
					this.supplier !== null ||
					this.planningOrder !== null ||
					this.lov !== null ||
					(this.preparer !== null && this.button === 1):
					this.selectLazyParams = event;
					this.searchTableNested();
					break;
			}
		},
		editChildren(rowIndex) {
			this.rowId = rowIndex;
	
			if (sessionStorage.leveltenant == 2 || Number(sessionStorage.leveltenant) === 3) {
				this.$router.push({
					name: 'DetailpoFulfillmentSupp',
					params: { id: this.rowId.po_key },
				});
			} else {
				this.$router.push({
					name: 'DetailpoFulfillment',
					params: { id: this.rowId.po_key },
				});
			}
		},
		onPage(event) {
			switch (true) {
				//nggak cari apa-apa
				case this.supplier === null &&
					this.planningOrder === null &&
					this.lov === null &&
					this.preparer === null &&
					this.button === 0:
					this.lazyParams = event;
					this.GetTableNested();
					break;
				//semuanya
				case this.company !== null ||
					this.supplier !== null ||
					this.planningOrder !== null ||
					this.lov !== null ||
					(this.preparer !== null && this.button === 1):
					this.selectLazyParams = event;
					this.searchTableNested();
					break;
			}
		},
		clearFilter() {
			// this.buttonSearch = true;
			AuthService.whoAmi().then((response) => {
				if (response.data.data.leveltenant == 1 || response.data.data.leveltenant === 3) {
					if (response.data.data.userid == 'dexaadmin') {
						this.expandedRows = null;
						this.planningOrder = null;
						this.supplier = null;
						this.company = null;
						this.buttonDisable = true;
						this.GetTableNested();
						this.lov = null;
						this.loe = null;
						this.preparer = null;
						this.button = 0;
						this.companyValue = true;
						this.preparerButton = true;
					} else {
						this.expandedRows = null;
						this.planningOrder = null;
						this.supplier = null;
						this.lov = null;
						this.loe = null;
						this.preparer = null;
						this.button = 0;
						this.getPlenno();
						// this.searchTableNested();
					}
				}
				if (response.data.data.leveltenant == 2) {
					this.expandedRows = null;
					this.planningOrder = null;
					this.lov = null;
					this.loe = null;
					this.preparer = null;
					this.button = 0;
					// this.searchTableNested();
				}
			});
		},
	},
};
</script>
<style scoped lang="scss">
// ::selection{
//     background-color: rgba(0,0,0,.15) !important;
// }

p-selectable-row p-highlight {
	background-color: rgba(0, 0, 0, 0.15) !important;
}

// input{
//     text-align: right;
// }
</style>
