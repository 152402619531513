<template>
	<div class="grid find-po-modal">
		<Toast />

		<!-- Content -->
		<div class="col-12 pt-4">
			<!-- Select Input  -->
			<div class="mb-6">
				<span class="p-float-label">
					<AutoComplete
						id="batchNumber"
						class="w-full"
						:disabled="!batchList"
						v-model="batchNumber"
						:suggestions="filteredBatchNumbers"
						@complete="searchBatchNumber($event)"
						:dropdown="true"
						field="batch_no"
						forceSelection
					/>
					<label for="batchNumber">Choose Batch Number</label>
				</span>
			</div>

			<!-- Action buttons -->
			<div class="grid">
				<div class="col-12 md:col-6">
					<Button
						v-if="!isLoading"
						class="w-full justify-content-center text-center p-button-success mr-2"
						:disabled="!batchNumber"
						@click="handleSave"
					>
						<span class="uppercase font-bold">Save</span>
					</Button>
					<Button
						v-else
						class="w-full justify-content-center text-center p-button-success mr-2"
						disabled
					>
						<span class="uppercase font-bold">Saving...</span>
					</Button>
				</div>
				<div class="col-12 md:col-6">
					<Button
						class="w-full justify-content-center text-center p-button-secondary"
						@click="$emit('onClose')"
					>
						<span class="uppercase font-bold">Cancel</span>
					</Button>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref, defineProps, defineEmits } from 'vue';
import InvoicePOService from '../../service/InvoicePOService';

const props = defineProps(['dbInstance', 'orgId', 'vendorId']);
const emit = defineEmits(['onSave']);

// State
const batchNumber = ref(null);
const batchList = ref(null);
const filteredBatchNumbers = ref(null);
const isLoading = ref(false);

onMounted(() => {
	getBatchNumbers();
});

// Get batch numbers
const getBatchNumbers = async () => {
	try {
		const payload = {
			dbInstance: props.dbInstance,
			orgId: props.orgId,
			vendorId: props.vendorId,
		};

		const res = await InvoicePOService.getBatchNumbers(payload);
		batchList.value = res.data.data;
	} catch (err) {
		console.log(err);
	}
};

// Search company on autocomplete
const searchBatchNumber = (event) => {
	if (!event.query.trim().length) {
		filteredBatchNumbers.value = [...batchList.value];
	} else {
		filteredBatchNumbers.value = batchList.value.filter((item) => {
			return item.batch_no.toLowerCase().match(event.query.toLowerCase());
		});
	}
};

// Save add invoice to batch
const handleSave = () => {
	isLoading.value = true;
	const batchId = batchNumber.value.batch_id;
	emit('onSave', batchId);
};
</script>
