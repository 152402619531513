/* eslint-disable */
import spborder from './api/SpbOrderApi';
import spbadmin from './api/SpbAdminApi';
import spbKkt from './api/SpbKktApi';
import { encryptForm } from '../helper/encryptForm';
class PlanningOrder {
	getTableSuppData;
	getTableData(payload) {
		return spborder.post(`/spb/spb_planningorder/tableopenreq`, payload);
	}
	getTableDataByVendorId(payload) {
		return spborder.post(
			`/spb/spb_planningorder/tableopenreqsupp`,
			payload
		);
	}
	getTableDataSupp(payload) {
		return spborder.post('/spb/spb_planningorder/tableopenreqsup', payload);
	}
	// GetTableNested(params) {
	// 	const queryParams = params
	// 		? Object.keys(params)
	// 				.map(
	// 					(k) =>
	// 						encodeURIComponent(k) +
	// 						'=' +
	// 						encodeURIComponent(params[k])
	// 				)
	// 				.join('&')
	// 		: '';
	// 	return spborder.post(
	// 		'/spb/spb_planningorder/getnestedtable?' + queryParams
	// 	);
	// }
	getSearchTableNested(params) {
		return spborder.post(
			'/spb/spb_planningorder/searchnestedtable?', encryptForm(params)
		);
	}
	GetPreparer(payload) {
		return spborder.post('/spb/spb_planningorder/getpreparer', payload);
	}
	GetSupplier() {
		return spborder.post('/spb/spb_planningorder/getsupplier');
	}
	getCompanybu() {
		return spborder.post('/spb/spb_planningorder/getcompany');
	}
	getCompany() {
		return spbadmin.post('/spb/proc/getcompany');
	}
	getCompanyId(id) {
		return spborder.post('/spb/spb_planningorder/getcompanysupp/', id);
	}
	getSearchDropdownCompany(payload) {
		return spborder.post('/spb/spb_planningorder/getsupplier', payload);
	}
	getSearchDropdownSupp(payload) {
		return spborder.post('/spb/spb_planningorder/getsupsearch', encryptForm(payload));
	}
	getSearchDropdownComp(payload) {
		return spborder.post('/spb/spb_planningorder/getsupcomp', payload);
	}
	getPoNumber() {
		return spborder.post('/spb/spb_planningorder/getponumber');
	}
	getSearchDropdownPoNum(payload) {
		return spborder.post('/spb/spb_planningorder/getposearch', payload);
	}
	GetLovStatus() {
		return spborder.post('/spb/spb_planningorder/getstatus');
	}
	getTableSearch(data) {
		return spborder.post(`/spb/spb_planningorder/tableopenreq/` + data);
	}
	getGroup() {
		return spborder.post(`/spb/spb_planningorder/getrowgroup`);
	}
	getCompanySupp(payload) {
		return spbadmin.get(
			'/spb/usermapping/getusermappingsbyiduser/' + payload
		);
	}

	// INI GET PLAN ORDER UNTUK DASHBOARD CHARTS
	getMostOrderedItems(payload,id) {
		return spborder.get(
			`/spb/spb_planningorder/mostOrderedItems/${payload}?vendorid=${id}`
		)
	}
	getMostOrderedSuppliers(payload) {
		return spborder.get(`/spb/spb_planningorder/mostOrderedSuppliers?org_id=${payload.org_id}&db_instance=${payload.db_instance}`);
	}

	getMonthlyPlanningOrders(payload,vendorId) {
		return spborder.get(
			`/spb/spb_planningorder/monthlyPlanningOrders/${payload}?vendorId=${vendorId}`
		);
	}
	////// INI EDIT

	getIdDetail(payload) {
		return spborder.post(`/spb/spb_planningorder/getiddetail`, payload);
	}
	getLocation(payload) {
		return spborder.post(`/spb/spb_planningorder/getlocation/` + payload);
	}
	getTableDetailPo(payload) {
		return spborder.post(
			'/spb/spb_planningorder/gettabledetailpo',
			payload
		);
	}
	getPoType() {
		return spborder.post(`/spb/spb_planningorder/getpotype`);
	}
	getTerms(payload) {
		return spborder.post(
			`/spb/spb_planningorder/gettabledetailterms/` + payload
		);
	}
	getTermsSupp(payload) {
		return spborder.post(
			`/spb/spb_planningorder/gettabledetailtermssupp/` + payload
		);
	}
	getUom(payload) {
		return spborder.post(`/spb/spb_planningorder/getuom`, payload);
	}
	getAllCurrencies(payload) {
		const url = 'spb/createpo/getallcurrencies/' + payload;
		return spborder.get(url);
	}
	getFreight(payload) {
		return spborder.post(`/spb/spb_planningorder/getfreight`, payload);
	}
	getTablePoNumber() {
		return spborder.post(`/spb/spb_planningorder/gettableponumber`);
	}
	getTop(payload) {
		return spborder.post(`/spb/spb_planningorder/gettop`, payload);
	}
	getIncoterm(payload) {
		return spborder.post(`/spb/spb_planningorder/getincoterm/` + payload);
	}
	changeStatus(payload) {
		const url = '/spb/spb_planningorder/changestatus';
		return spborder.post(url, payload);
	}
	searchPopPo(payload) {
		return spborder.post('/spb/spb_planningorder/searchpoppo', payload);
	}
	notAcceptStatus(payload) {
		return spborder.post('/spb/spb_planningorder/notacceptstatus', payload);
	}
	saveOption(payload) {
		return spborder.post('/spb/spb_planningorder/savesubmitpo', payload);
	}
	saveOptionSupp(payload) {
		return spborder.post(
			'/spb/spb_planningorder/savesubmitposupp',
			payload
		);
	}
	getSuppBindComp(idUser) {
		return spborder.post('spb/spb_planningorder/getsuppbindcomp/' + idUser);
	}
	submitPo(payload) {
		return spborder.post('/spb/spb_planningorder/submitpo', payload);
	}
	printPlannoPur(payload) {
		return spborder.post('/spb/spb_planningorder/getprintpopur/' + payload);
	}
	printPlannoSupp(payload) {
		return spborder.post(
			'/spb/spb_planningorder/getprintposupp/' + payload
		);
	}
	confirmSupp(payload) {
		return spborder.post('/spb/spb_planningorder/confirmSupp', payload);
	}
	getRecipientMail(data) {
		return spbadmin.post(
			'/spb/notification/getPlannoRecipientMailPur',
			data
		);
	}
	sendPONotifSupp(payload) {
		const url = `spb/notification/sendPOnotifsupp`;
		return spbadmin.post(url, payload);
	}
	async sendPOEmail(data) {
		return spbKkt.post('adm/send/emailPlannoToPurchasing', data);
	}
	submitApi(payload) {
		return spborder.post('/spb/spb_planningorder/submitapi', payload);
	}
	insertToInterface(payload) {
		return spborder.post(
			'/spb/spb_planningorder/inserttointerface',
			payload
		);
	}
	getSuppValue(payload, dbInstance) {
		return spbadmin.post(
			`/spb/proc/getvaluesupp/${
				dbInstance || sessionStorage.getItem('companydb')
			}`,
			encryptForm(payload)
		);
	}
	getSuppValueBu(payload, dbInstance) {
		return spborder.post(
			`/spb/spb_planningorder/getvaluesupp/${
				dbInstance || sessionStorage.getItem('companydb')
			}`,
			encryptForm(payload)
		);
	}
	getEmployeeID(payload) {
		return spborder.post('/spb/spb_planningorder/getemployeeid', payload);
	}
}
export default new PlanningOrder();
