<template>
	<form class="p-fluid">
		<DataTable
			id="table-detail-category"
			:value="tableList"
			:paginator="true"
			:rows="10"
			:rowsPerPageOptions="[10, 20, 30]"
			responsiveLayout="scroll"
			:scrollable="true"
			scrollHeight="600px"
			@page="onPage($event)"
			paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
			currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
		>
			<template #empty>{{ empty }}</template>
			<template #loading>Loading data</template>
			<Column header="Category Detail" headerStyle="min-width:10rem;">
				<template #body="{ data, index }">
					<div class="w-full">
						<InputText
							id="inputtext"
							class="w-full"
							:class="{
								'p-invalid':
									v$.tableList.$silentErrors.length &&
									v$.tableList.$silentErrors[0].$response
										.$data[index].CATEGORY_DETAIL.$error,
							}"
							type="text"
							placeholder="Details..."
							v-model="data.CATEGORY_DETAIL"
						/>
						<div
							v-if="
								v$.tableList.$silentErrors.length &&
								v$.tableList.$silentErrors[0].$response.$data[
									index
								].CATEGORY_DETAIL.$error
							"
							class="mt-1"
						>
							<small class="p-error" style="font-size: 12px">
								{{
									v$.tableList.$silentErrors.length &&
									v$.tableList.$silentErrors[0].$response
										.$errors[index].CATEGORY_DETAIL[0]
										.$message
								}}
							</small>
						</div>
					</div>
				</template>
			</Column>
			<Column header="COA" headerStyle="min-width:10rem;">
				<template #body="{ data, index }">
					<div class="w-full">
						<AutoComplete
							v-model="data.COA"
							class="w-full"
							:class="{
								'p-invalid':
									v$.tableList.$silentErrors.length &&
									v$.tableList.$silentErrors[0].$response
										.$data[index].COA.$error,
							}"
							:suggestions="filteredAccount"
							:dropdown="true"
							field="ACCOUNT"
							placeholder="Account"
							forceSelection
							@complete="searchAccount($event)"
						/>
						<div
							v-if="
								v$.tableList.$silentErrors.length &&
								v$.tableList.$silentErrors[0].$response.$data[
									index
								].COA.$error
							"
							class="mt-1"
						>
							<small class="p-error" style="font-size: 12px">
								{{
									v$.tableList.$silentErrors.length &&
									v$.tableList.$silentErrors[0].$response
										.$errors[index].COA[0].$message
								}}
							</small>
						</div>
					</div>
				</template>
			</Column>
			<Column
				v-if="props.data == 0"
				header="Action"
				style="justify-content: center"
			>
				<template #body="{ index }">
					<Button
						v-tooltip="'Delete'"
						class="p-button-rounded p-button-text"
						icon="pi pi-trash"
						@click="deleteLine(index)"
					/>
				</template>
			</Column>
			<Column v-else header="Active" style="justify-content: center">
				<template #body="{ data }">
					<Button
						v-if="data.status === 'N'"
						v-tooltip="'Inactive'"
						class="p-button-rounded p-button-text p-button-lg"
						icon="pi pi-times-circle"
						@click="toggleLineStatus(data.id)"
					/>
					<Button
						v-else
						v-tooltip="'Active'"
						class="p-button-rounded p-button-text p-button-lg p-button-success"
						icon="pi pi-check-circle"
						@click="toggleLineStatus(data.id)"
					/>
				</template>
			</Column>
		</DataTable>
	</form>
</template>

<script setup>
import { onMounted, ref, computed, watch, defineEmits, defineProps } from 'vue';
// import { useToast } from 'primevue/usetoast';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import AuthService from '@/service/AuthService';
import PoFulfillment from '@/service/PoFulfillment';
import LovService from '@/service/LovService';

const company = ref();
const supplier = ref();
const page = ref(0);
const empty = ref();
// const submitted = ref(false);
const tableList = ref([]);
const emit = defineEmits('tableList');
const user = ref();
const filteredAccount = ref([]);

onMounted(async () => {
	company.value = props.company;
	const info = await AuthService.whoAmi();
	user.value = info.data.data;
	if (info.status === 200) {
		levelTenant.value = info.data.data.leveltenant;
		if (levelTenant.value !== 2) {
			loading.value = true;
			supplier.value = props.supplier;
			//   loading.value = true
			populateTable();
		} else {
			if (company.value && !supplier.value) {
				const param = {
					db_instance: props.company.db_instance,
					org_id: props.company.value,
				};
				const respVendor = await PoFulfillment.getSuppBindComp(
					info.data.data.id
				);
				const vendorName = respVendor.data.data[0][0].vendorname;
				const respSuppName = await LovService.getSupplierName(param);
				if (respSuppName.status === 200) {
					const suppList = respSuppName.data.data;
					for (const s of suppList) {
						if (s.vendor_name === vendorName) {
							supplier.value = s;
							break;
						}
					}
				}
				populateTable();
			}
			// loading.value = true
		}
	}

	// Emit table list to parent comp
	// emit('tableList', tableList);
});

const props = defineProps({
	company: {
		type: Object,
	},
	data: {
		type: Object,
	},
	line: {
		type: Object,
	},
	category: {
		type: Object,
	},
	coa: {
		type: Object,
	},
	valid: {
		type: Boolean,
	},
	accountList: {
		type: Array,
	},
	dataTable: {
		type: Array,
	},
});

// const toast = useToast();
const loading = ref(false);
const levelTenant = ref();

// Validation rules
const rules = computed(() => ({
	tableList: {
		$each: helpers.forEach({
			CATEGORY_DETAIL: { required },
			COA: { required },
		}),
	},
}));

const v$ = useVuelidate(rules, { tableList });

// Watch
watch(
	() => props.line,
	(newValue) => {
		if (newValue > 0) {
			addLine();
		}
		// else{
		//   tableList.value = [];
		// }
	}
);

const searchAccount = async (event) => {
	const account = props.accountList;

	if (!event.query.trim().length) {
		filteredAccount.value = [...account];
	} else {
		filteredAccount.value = account.filter((acc) => {
			return acc.ACCOUNT.toLowerCase().match(event.query.toLowerCase());
		});
	}
};

const populateTable = () => {
	if (props.data == 0) {
		tableList.value = [];
		empty.value = 'No data. Please add line.';
	} else {
		tableList.value = props.dataTable;
	}
};

function dateFormat2(d) {
	var t = new Date(d);
	return `${t.getFullYear()}-${t.getMonth() + 1}-${t.getDate()}`;
}

const onPage = async (event) => {
	page.value = event.page * event.rows;
};

const addLine = async () => {
	const data = tableList.value.length + 1;
	tableList.value.push({
		id: data,
		CATEGORY_DETAIL: '',
		CONCATENATED_SEGMENTS: props.coa.CONCATENATED_SEGMENTS,
		COA: props.coa,
		CREATED_BY: user.value.id,
		CREATION_DATE: dateFormat2(new Date()),
		DB_INSTANCE: company.value.db_instance,
		ORG_ID: company.value.value,
		status: 'Y',
	});
	// toast.add({
	// 	severity: 'success',
	// 	summary: 'Line Create',
	// 	detail: 'Line Successfully Created',
	// 	life: 3000,
	// });
	emit('tableList', tableList);
};

const toggleLineStatus = (id) => {
	tableList.value = tableList.value.map((item) => {
		if (item.id === id) {
			return {
				...item,
				status: item.status === 'Y' ? 'N' : 'Y',
			};
		}

		return item;
	});

	emit('tableList', tableList);
};

const deleteLine = (idx) => {
	tableList.value = tableList.value.filter((el, index) => index !== idx);

	emit('tableList', tableList);
};
</script>

<style scoped></style>
