<template>
	<DataTable
		:value="itemNumberList"
		:lazy="true"
		:paginator="true"
		:rows="10"
		:loading="loading"
		:totalRecords="totalData"
		@page="onPage($event)"
		:rowsPerPageOptions="[10, 20, 30, 50]"
		responsiveLayout="scroll"
		:scrollable="true"
		scrollHeight="600px"
		scrollDirection="both"
		:sortable="true"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #empty>No Data found</template>
		<template #loading>Loading data</template>
		<Column header="Item Number" field="ITEM_NUMBER" style="width: 15%"> </Column>
		<Column header="Description" field="DESCRIPTION" style="width: 25%"> </Column>
		<Column header="Material" field="MATERIAL" style="width: 25%"> </Column>
		<Column header="Color" field="COLOR" style="width: 15%"> </Column>
		<Column header="Size" field="SIZE" style="width: 20%"> </Column>
		<Column header="Remark Design" field="REMARK_DESIGN" style="width: 25%"> </Column>
		<Column
			class="call-button-container"
			header="Actions"
			selectionMode="single"
			alignFrozen="right"
			frozen
		>
			<template #body="slotProps">
				<Button
					icon="pi pi-eye"
					class="p-button-rounded p-button-text"
					@click="editChildren(slotProps.data)"
					v-tooltip="'Detail'"
				/>
			</template>
		</Column>
	</DataTable>
</template>

<script setup>
import { ref, defineProps, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import AuthService from '../../../service/AuthService';
import LovService from '../../../service/LovService';
import SpecAndQuotation from '../../../service/SpecAndQuotation';
import SimpleCrypto from 'simple-crypto-js';

const itemNumberList = ref([]);
const page = ref(1);
const rowsPerPage = ref(10);
const totalData = ref(0);
const router = useRouter();
const loading = ref(false);
const company = ref();

const props = defineProps({
	searchGlobal: {
		type: Object,
	},
});

onMounted(() => {
	AuthService.whoAmi().then(async (response) => {
		if (response.status === 200) {
			//get company
			const respCbN = await LovService.getCompanyByName(userCompany());
			company.value = respCbN.data.data[0];

			await populateTable();
		}
	});
});

const userCompany = () => {
	const secretKey = 'some-unique-key';
	const simpleCrypto = new SimpleCrypto(secretKey);
	return simpleCrypto.decrypt(sessionStorage.getItem('dropdownCompany'));
};

// const qs = require('qs');

const populateTable = async () => {
	loading.value = true;
	const payload = {
		searchKeyword: props.searchGlobal ? props.searchGlobal : null,
		org_id: parseInt(company.value.value),
		db_instance: company.value.db_instance,
		page: page.value,
		rows: rowsPerPage.value,
	};

	const res = await SpecAndQuotation.getDataTable(payload);

	if (res.status === 200) {
		itemNumberList.value = res.data.data.data;
		totalData.value = res.data.data.totalData[0].totalData;
		loading.value = false;
	} else {
		loading.value = false;
	}
};

const onPage = async (event) => {
	loading.value = true;
	page.value = event.page + 1;
	rowsPerPage.value = event.rows;
	await populateTable();
};

const editChildren = async (rowIndex) => {
	router.push({
		name: 'DetailSpecQuotation',
		params: {
			id: rowIndex.id,
		},
		query: { v: company.value.value, i: company.value.db_instance },
	});
};
</script>
