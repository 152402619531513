<template>
  <AutoComplete
    :suggestions="filteredTerms"
    @complete="searcSupplierSite($event)"
    :dropdown="true"
    field="TERM_NAME"
    :placeholder="placeholder"
    v-model="payTerm"
    forceSelection
  >
    <template #item="slotProp">
      {{ slotProp.item.TERM_NAME }}
    </template>
  </AutoComplete>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect } from "vue";
//   import LovService from "@/service/LovService";
//   import SwithCompany from "@/service/SwithCompany";
// import AuthService from "@/service/AuthService";
import invoiceNonPOservice from "@/service/InvoiceNonPOservice";

// eslint-disable-next-line no-undef
const filteredTerms = ref([]);
const terms = ref([]);
const payTerm = ref();
const placeholder = ref("Select a Term");

const emit = defineEmits(["terms"]);

watchEffect(() => emit("terms", payTerm));

// eslint-disable-next-line no-undef
const props = defineProps({
  company: {
    type: Object,
  },
  vendorId: {
    type: String,
  },
  termsId: {
    type: String,
  },
});

onMounted(async () => {
  try {
    // const response = await AuthService.whoAmi();
    // const user = response.data.data;
    if (props.company) {
      const result = await invoiceNonPOservice.getListTerm(
        props.company.db_instance,
        props.vendorId
      );
      terms.value = result.data.data;
      if (props.termsId) {
        for (const termData of terms.value) {
          if (termData.TERM_ID == props.termsId) {
            payTerm.value = termData;
          }
        }
      }
    }

    // eslint-disable-next-line no-empty
  } catch (e) {
    throw new Error(e);
  }
});

const searcSupplierSite = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredTerms.value = [...terms.value];
    } else {
      filteredTerms.value = terms.value.filter((term) => {
        return term.TERM_NAME.toLowerCase().match(
          event.query.toLowerCase()
        );
      });
    }
  }, 250);
};
</script>
