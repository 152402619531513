<template>
    <div>
    <AutoComplete :suggestions="filtereditemDesc" @complete="searchItemDesc($event)" :dropdown="true" field="item_desc" v-model="itemDesc" placeholder="Item Description" :disabled="loading" forceSelection>
        <template #item="slotProp">
        {{ slotProp.item.item_desc }}
        </template>
    </AutoComplete>
    </div>
</template>

<script setup>
import {onMounted, ref, watchEffect, defineEmits} from 'vue'
import AuthService from '../../../service/AuthService';
import SpecSrc from '../../../service/SpecSrc';

// eslint-disable-next-line no-undef
const props = defineProps({
    company: {
        type: Object
    },
    supplier: {
        type: Object
    },
    status: {
        type: Object
    },
    item_num: {
        type: Object
    },
    preparer: {
        type: Object
    },
});

// eslint-disable-next-line no-undef
const itemDesc = ref();
const itemDescList = ref();
const filtereditemDesc = ref();
const loading = ref(false);
const emit = defineEmits(['itemDesc'])

watchEffect(() => {
    emit("itemDesc", itemDesc)
})

onMounted(async ()=>{
    await populateData()
})

const populateData = async () => {
  AuthService.whoAmi().then(async (response) => {
    loading.value = true
    if (response.status === 200){
        if (props.company !== null){
            const payload = {
                db_instance : props.company?props.company.db_instance:null,
                org_id : props.company?props.company.value:null,
                vendor_id : props.supplier?props.supplier.vendor_id:null,
                status : props.status?props.status.status:null,
                item_num : props.item_number?props.item_number.item_number:null,
                }
            const res = await SpecSrc.getItemDesc(payload)
            if (res == undefined || res.status === null){
              setTimeout( async () => {
                await populateData()
              }, 1000);
            } else if (res.status === 200) {
            itemDescList.value = res.data.data
            loading.value = false
            } else {
              itemDescList.value = {item_desc : 'Not Found'}
              loading.value = false
            }
        }
    } else {
      itemDescList.value = {item_desc : 'Not Found'}
      loading.value = false
    }
    
  })
}

const searchItemDesc = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filtereditemDesc.value = [...itemDescList.value];
    } else {
      filtereditemDesc.value = itemDescList.value.filter((comp) => {
        return comp.item_desc
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};
</script>