<template>
	<div>
		<Card>
			<template #title>
				<span class="text-xl">Top 10 Latest Invoices</span>
			</template>

			<template #content>
				<DataTable
					:value="listInvoice"
					responsiveLayout="scroll"
					:loading="isLoading"
				>
					<Column field="invoice_no" header="Invoice Number"></Column>
					<Column field="invoice_date" header="Invoice Date"></Column>
					<Column header="Status">
						<template #body="slotProps">
							<span
								class="font-bold statusOpen"
								v-if="slotProps.data.status === 'Open'"
								>{{ slotProps.data.status }}</span
							>
							<span class="font-bold statusClosed" v-else>{{
								slotProps.data.status
							}}</span>
						</template>
					</Column>
				</DataTable>
			</template>
		</Card>
	</div>
</template>

<script>
import SimpleCrypto from 'simple-crypto-js';
import AuthService from '../../service/AuthService';
import LovService from '../../service/LovService';
import PlanningOrder from '../../service/PlanningOrderService';
// import PoFulfillment from '../../service/PoFulfillment';
import InvoicePOService from '../../service/InvoicePOService';
export default {
	data() {
		return {
			receipts: [],
			vendorId: null,
			currentUser: null,
			isiCompany: null,
			company: null,
			isLoading: false,
			listInvoice: null,
			totalRecords: null,
		};
	},
	created() {
		AuthService.whoAmi().then((resp) => {
			if (resp.status === 200) {
				this.isLoading = true;
				this.getCompany(resp);
			}
		});
	},
	methods: {
		handleSearch(currentUser) {
			setTimeout(() => {
				let vendorId = sessionStorage.getItem('vendorId');
				// Body payload
				const payload = {
					levelTenant: currentUser.data.data.leveltenant,
					dbInstance: this.company.db_instance,
					orgId: this.company.value,
					vendorId: vendorId,
					status: 'OPEN',
					supplier: vendorId || null,
					invoiceNo: null,
				};

				// Query params
				const params = {
					query: JSON.stringify({
						limit: 10,
						page: 1,
					}),
				};

				try {
					InvoicePOService.getLastInvoice(params, payload).then(
						(res) => {
							if (res.status == 200) {
								this.listInvoice = res.data.data;
								this.isLoading = false;
							}
						}
					);
				} catch (err) {
					console.log(err);
				}
			}, 2000);
		},
		getCompany(value) {
			if (value.data.data.leveltenant == 1) {
				if (value.data.data.userid == 'dexaadmin') {
					PlanningOrder.getCompany().then((response) => {
						this.isiCompany = response.data.data;
					});
				} else {
					LovService.getcompanydb(value.data.data.bioaddress).then(
						(value) => {
							this.isiCompany = value.data.data;
							try {
								const secretKey = 'some-unique-key';
								const simpleCrypto = new SimpleCrypto(
									secretKey
								);
								const decipherText = simpleCrypto.decrypt(
									sessionStorage.getItem('dropdownCompany')
								);
								this.isiCompany.forEach((element) => {
									if (element.name === decipherText) {
										this.company = element;
										// this.searchTableNested();
										this.handleSearch(value);
										throw 'Break';
									}
								});
							} catch (e) {
								if (e !== 'Break') throw e;
							}
						}
					);
				}
			} else if (value.data.data.leveltenant == 2) {
				let isiCompany;
				LovService.getCompanyFromUsermapping(value.data.data.id).then(
					(response) => {
						isiCompany = response.data.data[0];
						try {
							const secretKey = 'some-unique-key';
							const simpleCrypto = new SimpleCrypto(secretKey);
							const decipherText = simpleCrypto.decrypt(
								sessionStorage.getItem('dropdownCompany')
							);
							isiCompany.forEach((element) => {
								if (element.name === decipherText) {
									this.company = element;
									// this.searchTableNested();
									this.handleSearch(value);

									throw 'Break';
								}
							});
						} catch (e) {
							if (e !== 'Break') throw e;
						}
					}
				);
			}
		},
	},
};
</script>

<style scoped>
.statusOpen {
	color: #77c664;
}

.statusClosed {
	color: #afafaf;
}
</style>
