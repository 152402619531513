<template>
    <span class="p-float-label">
        <AutoComplete :disabled="true" :suggestions="filteredTerm" :dropdown="true" field="TERM_NAME" v-model="termsValue" forceSelection>
        <template #item="slotProp">
        {{ slotProp.item.TERM_NAME }}
        </template>
    </AutoComplete>
        <label for="autocomplete">Terms</label>
    </span>
  
  
</template>

<script setup>
import {onMounted, ref, watchEffect, defineEmits} from 'vue'
import AuthService from '../../../service/AuthService';

// eslint-disable-next-line no-undef
const filteredTerm = ref([]);
const terms = ref([]);
const termsValue = ref({
    TERM_ID: 10006,
    TERM_NAME: "CASH",
    DESCRIPTION: "CASH"
});
const emit = defineEmits(['terms'])

onMounted(() => {
  populateData()
})

watchEffect(() => 
  // 
  emit('terms',termsValue)
)

const populateData = () => {
  AuthService.whoAmi().then(async (response) => {
    // 
      if (response.status === 200){
        terms.value = [
          {
              TERM_ID: 10006,
              TERM_NAME: "CASH",
              DESCRIPTION: "CASH"
          }
        ]        
      }
   
    
  })
}



</script>

<style scoped>

</style>