<template>
	<!-- eslint-disable -->
	<Toast />
	<div class="Grid">
		<div class="col-12 mb-4">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<!-- Search -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">MASTER CATEGORY</div>
				<div class="content-body">
					<div class="col-12 p-fluid">
						<div class="grid formgrid" style="margin: 1em 0 0 0">
							<div class="col-12 mb-5 lg:col-3">
								<CompaniesDropdown
									v-model="company"
									class="w-full mb-2 lg:w-auto lg:mb-0"
								/>
							</div>
							<div class="col-12 mb-5 lg:col-3 lg:mb-5">
								<span class="p-float-label">
									<Category
										v-model="category"
										:company="company ? company : null"
										class="w-full mb-2 lg:w-auto lg:mb-0"
										:key="categoryReload"
									/>
									<label>Category</label>
								</span>
							</div>
							<div class="col-12 mb-5 lg:col-3 lg:mb-5">
								<span class="p-float-label">
									<Account
										v-model="account"
										:company="company ? company : null"
										class="w-full mb-2 lg:w-auto lg:mb-0"
										:key="accountReload"
									/>
									<label>Account</label>
								</span>
							</div>
							<div class="mr-3 ml-2">
								<Button
									@click="handleSearch()"
									class="p-button-success uppercase"
									label="search"
									:disabled="loading"
								/>
							</div>
							<div>
								<Button
									@click="clearFilter()"
									class="p-button-secondary uppercase"
									label="clear"
								></Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Search results -->
		<div class="col-12 p-fluid">
			<div class="content-wrapper">
				<div class="content-header">Search Result</div>
				<div class="content-body">
					<div style="display: flex; justify-content: flex-end">
						<div class="mr-3 ml-2">
							<Button
								class="p-button-info uppercase"
								label="Create"
								:disabled="loading"
								@click="createMaster"
							/>
						</div>
					</div>
					<div class="mt-4">
						<TableMain
							:company="company ? company : null"
							:category="category ? category : null"
							:account="account ? account : null"
							:key="reloadTable"
							@loading="getLoading"
						></TableMain>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import AuthService from '../../service/AuthService';
import LovService from '../../service/LovService';
import SimpleCrypto from 'simple-crypto-js';
import CompaniesDropdown from '@/components/components/CompaniesDropdown';
// import SupplierDropdown from "@/components/components/SupplierDropdown";
import Account from '@/components/mastercategory/components/Account';
import Category from '@/components/mastercategory/components/Category';
import TableDetail from '@/components/mastercategory/components/TableCategoryDetail';
import TableMain from '@/components/mastercategory/components/TableSearch';
import PoFulfillment from '../../service/PoFulfillment';
export default {
	name: 'masterCategory',
	components: {
		CompaniesDropdown,
		// SupplierDropdown,
		Account,
		Category,
		TableDetail,
		TableMain,
	},
	data() {
		return {
			isSupplierpage: false,
			first: 0,
			rows: 10,
			loading: false,
			lazyParams: {},
			company: null,
			isiCompany: null,
			supplier: null,
			items: null,
			isEmpty: null,
			reload: 0,
			account: null,
			batchNumber: null,
			create: true,
			category: null,
			isButtonSearchDisable: null,
			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [
				{
					label: 'Master Category',
					to: '/mgm/master/mastercategory',
				},
			],
			categoryReload: ref(0),
			accountReload: ref(0),
			reloadTable: ref(0),
		};
	},
	watch: {
		company(newQuestion) {
			if (
				typeof newQuestion === 'object' &&
				!Array.isArray(newQuestion)
			) {
				this.supplier = null;
				this.category = null;
				this.account = null;
				this.categoryReload++;
				this.accountReload++;
			}
		},
		// supplier(newQuestion) {

		//   },

		// batchNumber(newQuestion) {

		//   },
		// account(newQuestion) {

		//   },
	},

	computed: {},

	created() {
		AuthService.whoAmi().then(async (response) => {
			// Check notification message
			const notifMasterCategory = sessionStorage.getItem(
				'notifMasterCategory'
			);
			if (notifMasterCategory) {
				this.$toast.add({
					severity: 'success',
					summary: `${notifMasterCategory}`,
					life: 3000,
				});
				sessionStorage.removeItem('notifMasterCategory');
			}

			function userCompany() {
				const secretKey = 'some-unique-key';
				const simpleCrypto = new SimpleCrypto(secretKey);
				return simpleCrypto.decrypt(
					sessionStorage.getItem('dropdownCompany')
				);
			}

			if (response.status === 200) {
				this.userInfo = response.data.data;
				if (this.userInfo.leveltenant === 2) {
					//get company
					this.isSupplierpage = true;
					const respCbN = await LovService.getCompanyByName(
						userCompany()
					);
					this.company = respCbN.data.data[0];
					//get supplier
					const param = {
						db_instance: this.company.db_instance,
						org_id: this.company.value,
					};
					const respVendor = await PoFulfillment.getSuppBindComp(
						response.data.data.id
					);
					const vendorName = respVendor.data.data[0][0].vendorname;
					const respSuppName = await LovService.getSupplierName(
						param
					);
					if (respSuppName.status === 200) {
						const suppList = respSuppName.data.data;
						for (const s of suppList) {
							if (s.vendor_name === vendorName) {
								this.supplier = s;
								break;
							}
						}
					}
				} else {
					const respCbN = await LovService.getCompanyByName(
						userCompany()
					);
					this.company = respCbN.data.data[0];
					// const response = await LovService.getcompanydb(this.userInfo.bioaddress);
					// const companies = response.data.data;
					// for (const c of companies) {
					//   if (parseInt(c.value) === this.userInfo.idsubtenant) {
					//     this.company = c;
					//     break;
					//   }
					// }
				}
			}
		});
	},
	async mounted() {
		this.isEmpty = 'Please Search for Data';
		// this.loading = true;
		this.lazyParams = {
			first: 0,
			rows: this.rows,
			sortField: null,
			sortOrder: null,
		};
	},

	methods: {
		async getLoading(value) {
			this.loading = value.value;
		},
		async handleSearch() {
			this.reloadTable++;
		},
		async clearFilter() {
			this.category = null;
			this.account = null;
		},
		async createMaster() {
			this.$router.push({
				name: 'detailMasterCategory',
				params: { id: 0 },
				query: { v: this.company.value, i: this.company.db_instance },
			});
		},
	},
};
</script>
<style scoped lang="scss">
.form-card {
	padding: 10px;
	border: 1px solid #e0e0e0;
	border-radius: 16px;
}
</style>
