<template>
 <!-- eslint-disable -->
	<div class="grid">
		<Toast />

		<!-- Breadcrumb -->
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<!-- Input Search -->
		<div class="col-12 mb-2">
			<div class="content-wrapper">
				<div class="content-header">Search</div>
				<div class="content-body">
					<div class="col-12 p-fluid">
						<div class="grid" style="margin: 1em 0 0 0">
							<div class="col-12 mb-5 lg:col-3">
								<CompaniesDropdown v-model="company" :disabled="!(userInfo?userInfo.leveltenant === 2:false)"/>
							</div>
							<div class="col-12 mb-5 lg:col-3" v-if="levelTenant !== 2">
								<SupplierDropdown v-if="!(userInfo?userInfo.leveltenant === 2:false)" v-model="supplier" 
								:company="company?company:null" :key="supplierReload"/>
							</div>
							<div class="col-12 mb-5 lg:col-2 lg:mb-5">
								<Status :company="company?company:null" :supplier="supplier?supplier:null" :item_num="itemNumber?itemNumber:null" v-model="status"
								:item_desc="itemDescription?itemDescription:null" :preparer="preparer?preparer:null" :key="reloadStatus" @status="getStatus" />
							</div>
							<div class="col-11 mb-5 lg:col-3 lg:mb-5" v-if="levelTenant !== 2">
								<ItemNum :company="company?company:null" :supplier="supplier?supplier:null" :status="status?status:null" v-model="itemNumber"
								:item_desc="itemDescription?itemDescription:null" :preparer="preparer?preparer:null" :key="reloadItemNum" @itemNumber="getItemNumber" />
							</div>
							<div class="col-1 mb-5 lg:col-1 lg:mb-5" v-if="levelTenant !== 2">
								<Button icon="pi pi-search" class="p-button-rounded p-button-info" @click="findItemNumber()"/>
							</div>
							<div class="col-12 mb-5 lg:col-3 lg:mb-5" v-if="levelTenant !== 2">
								<ItemDesc :company="company?company:null" :supplier="supplier?supplier:null" :status="status?status:null" v-model="itemDescription"
								:item_num="itemNumber?itemNumber:null" :preparer="preparer?preparer:null" :key="reloadItemDesc" @itemDesc="getItemDesc" />
							</div>
						</div>
						<div class="grid" style="margin: 0 0 0 0">
							<div class="col-12 mb-5 lg:col-3 lg:mb-5" v-if="levelTenant !== 2">
								<Preparer :company="company?company:null" :key="reloadPreparer" v-model="preparer" @preparer="getPreparer"/>
							</div>
							<div class="col-2 mb-5 lg:col-1 lg:mb-5">
								<Button
								@click="handleSearch()"
								class="p-button-success uppercase"
								label="search"
								:disabled="loading || button"
								/>
							</div>
							<div class="col-2 mb-5 lg:col-1 lg:mb-5">
								<Button
								@click="clearFilter()"
								class="p-button-secondary uppercase"
								label="clear"
								></Button>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>

		<!-- Search Result -->
		<div class="col-12 mb-5">
			<div class="content-wrapper">
				<div class="content-header">Search Result</div>
				<div class="content-body">
					<!-- Action button-->
					<div v-if="levelTenant === 1" class="flex justify-content-end mb-4">
						<Button
							class="p-button-success"
							@click="createSpecSrc()"
						>
							<span class="uppercase font-bold">
								<i class="pi pi-plus mr-2"></i>
								Add New Spec</span
							>
						</Button>
					</div>

					<!-- Table Result -->
					<TableSearch :company="company?company:null" :vendor_id="supplier?supplier:null"
					:status="status?status:null" :item_number="itemNumber?itemNumber:null" :item_description="itemDescription?itemDescription:null"
					:preparerId="preparer?preparer:null" :searchTable="search?search:0" :key="reloadTable" />
				</div>
			</div>
		</div>
	</div>

	<Dialog
		header="Item Number"
		v-model:visible="dialog"
		:breakpoints="{ '1080px': '160vw', '1080px': '180vw' }"
		:style="{ width: '50vw' }"
		:modal="true"
	>
		<div
			class="col-12 mb-12 md:col-12 lg:col-12 lg:mb-12"
			style="margin-top: 20px"
		>
			<!-- <InputText id="username" type="text" v-model="v$.refNumber.$model" :class="{'p-invalid':v$.refNumber.$invalid && submitted}" :maxlength="50"/> -->
			<ItemNumberEdit :company="company?company:null" :key="reload" :item_number="itemNumberFront?itemNumberFront:null" :idValue="idValue?idValue:0" @itemNumber="getItemNumberTable"/>
		</div>
	</Dialog>
	
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import AuthService from "../../service/AuthService";
// import { useToast } from 'primevue/usetoast';
import SimpleCrypto from 'simple-crypto-js';
import LovService from '../../service/LovService';
import PoFulfillment from '../../service/PoFulfillment';
import { useRouter } from "vue-router";
import CompaniesDropdown from "@/components/components/CompaniesDropdown"
import SupplierDropdown from "@/components/components/SupplierDropdown";
import Status from "./components/StatusSpecSrc.vue"
import ItemDesc from "./components/ItemDesc.vue"
import ItemNum from "./components/ItemNumber.vue"
import Preparer from "./components/Preparer.vue"
import TableSearch from "./components/TableSearchSrc.vue"
import ItemNumberEdit from './components/ItemNumberEdit.vue'
// import { stat } from "fs";


// eslint-disable-next-line no-undef
const company = ref();
const userInfo = ref();
const supplier = ref(null);
const isSupplierpage = ref(false);
const supplierReload = ref(0);
const reloadTable = ref(0);

const levelTenant = ref(0);
const router = useRouter();
const status = ref();
const itemNumber = ref();
const itemDescription = ref();
const preparer = ref();
const reloadItemNum = ref(0);
const reloadStatus = ref(0);
const reloadItemDesc = ref(0);
const reloadPreparer = ref(0);
const dialog = ref(false);
const search = ref(0)
const button = ref(true);
const breadcrumbHome = { icon: "pi pi-home", to: "/" };
const breadcrumbItems = [
  { label: "Spec SRC", to: "./trxcostlist" },
];
// Toast intance
// const toast = useToast();

onMounted(() => {
	checkUser()
})

watch(userInfo, (newValue) => {
//   
  if (newValue !== null){ 
	userInfo.value = newValue
	populateData();
  }
});

watch(company, (newValue) => {
	console.log('company', company)
	if (typeof newValue === 'object' && !Array.isArray(newValue) && newValue !== null){
		if (levelTenant.value == 1){ 
			supplierReload.value++
			supplier.value = null
			status.value = null
			itemNumber.value = null
			itemDescription.value = null
			preparer.value = null
		} else if (levelTenant.value == 2) {
			// console.log('isi data',supplier.value)
			status.value = null
			itemNumber.value = null
			itemDescription.value = null
			preparer.value = null
		} 
		reloadItemNum.value++
		reloadStatus.value++
		reloadItemDesc.value++
		reloadPreparer.value++
	}
	if (levelTenant.value === 1){
		if (company.value === null){
			button.value = true
		} else {
			button.value = false
		}
	}
	if (levelTenant.value === 2){
		if (company.value === null || supplier.value === null){
			button.value = true
		} else {
			button.value = false
		}
	}
});

watch(supplier, (newValue) => {
	if (typeof newValue === 'object' && !Array.isArray(newValue) && newValue !== null){
		if (userInfo.value.levelTenant !== 2){ 
			status.value = null
			itemNumber.value = null
			itemDescription.value = null
			preparer.value = null
		} 
		reloadItemNum.value++
		reloadStatus.value++
		reloadItemDesc.value++
	} 
	if (levelTenant.value === 2){
		if (supplier.value === null){
			button.value = true
		} else {
			button.value = false
		}
	}
});

watch(status, (newValue) => {
	if (typeof newValue === 'object' && !Array.isArray(newValue) && newValue !== null){
		itemNumber.value = null
		itemDescription.value = null
		preparer.value = null
		reloadItemNum.value++
		reloadItemDesc.value++
	}
});

watch(itemNumber, (newValue) => {
	if (typeof newValue === 'object' && !Array.isArray(newValue) && newValue !== null){
		itemDescription.value = null
		preparer.value = null
		reloadItemDesc.value++
	}
});

watch(itemDescription, (newValue) => {
	if (typeof newValue === 'object' && !Array.isArray(newValue) && newValue !== null){
		preparer.value = null
		reloadPreparer.value++
	}
});

const checkUser = async () => {
    checkTenant()
}

const populateData = async () => {
	supplierReload.value++
	reloadItemNum.value++
	reloadStatus.value++
	reloadPreparer.value++
}	

const checkTenant = async () => {
	AuthService.whoAmi().then(async (response) => {
		const userCompany = () => {
			const secretKey = 'some-unique-key';
			const simpleCrypto = new SimpleCrypto(secretKey);
			return simpleCrypto.decrypt(
				sessionStorage.getItem('dropdownCompany')
			);
		};
		if (response.status === 200) {
			userInfo.value = response.data.data;
			levelTenant.value = response.data.data.leveltenant;
			if (userInfo.value.leveltenant === 2) {
				//get company
                isSupplierpage.value = true;
				const respCbN = await LovService.getCompanyByName(
					userCompany()
				);
				company.value = respCbN.data.data[0];
				//get supplier
				// 
				const param = {
					db_instance: company.value.db_instance,
					org_id: company.value.value,
				};

				const respVendor = await PoFulfillment.getSuppBindComp(
					response.data.data.id
				);
				// 
				const vendorName = respVendor.data.data[0][0].vendorname;
				const respSuppName = await LovService.getSupplierName(param);
				if (respSuppName.status === 200) {
					//   
					const suppList = respSuppName.data.data;
					for (const s of suppList) {
						if (s.vendor_name === vendorName) {
							supplier.value = s;
							break;
						}
					}
					if (supplier.value === null) {
						router.push('/dashboard')
						window.alert("Connection Unstable, ID not Recognize")
					}
				}
				// 
			} else {
				const respCbN = await LovService.getCompanyByName(
					userCompany()
				);
				company.value = respCbN.data.data[0];
				// 
			}
		}


	});
};

const handleSearch = async () => {
	search.value++;
	reloadTable.value++
}

const clearFilter = async () => {
	if (levelTenant.value == 2){
		status.value = null
		reloadStatus.value++
	} else if (levelTenant.value == 1){
		status.value = null
		supplier.value = null
		itemDescription.value = null
		itemNumber.value = null
		preparer.value = null
		reloadItemNum.value++
		reloadStatus.value++
		reloadItemDesc.value++
		reloadPreparer.value++
	}
}

const createSpecSrc = () => {
	router.push({
		name: "EditSpecSRC",
		params: {
		id: 0,
		},
	});
}

const getStatus = (res) => {
	status.value = res.value
}

const getItemDesc = (res) => {
	itemDescription.value = res.value
	// console.log('res', itemDescription.value)

}

const getItemNumber = (res) => {
	itemNumber.value = res.value
}

const getPreparer = (res) => {
	preparer.value = res.value
	console.log(preparer.value)

}

const findItemNumber = () => {
	dialog.value = true;
};

const getItemNumberTable = async (event) => {
	// console.log("isi event", event)
	if (event.value !== null){
		itemNumber.value = event
		console.log('item', itemNumber.value)
		dialog.value = false
	}
}

</script>