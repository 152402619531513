import spbSupManApi from '@/service/api/SpbSupManApi';

class SpeReportService {
	getStatusList(params) {
		return spbSupManApi.get(`/spereport/statuslist/${params}`);
	}

	getGradeList(params) {
		return spbSupManApi.get(`/spereport/gradelist/${params}`);
	}

	getSupplierList(payload) {
		return spbSupManApi.post(`/spereport/supplierlist`, payload);
	}

	getReportList(params, payload) {
		const queryParams = params
			? Object.keys(params)
					.map(
						(k) =>
							encodeURIComponent(k) +
							'=' +
							encodeURIComponent(params[k])
					)
					.join('&')
			: '';

		return spbSupManApi.post(
			`/spereport/reportlist?${queryParams}`,
			payload
		);
	}

	getReportDetail(id, payload) {
		return spbSupManApi.post(`/spereport/reportdetail/${id}`, payload);
	}

	getCapaDetail(speId) {
		return spbSupManApi.get(`/spereport/capadetail/${speId}`);
	}

	submitReport(payload) {
		return spbSupManApi.post(`/spereport/submitreport`, payload);
	}

	updateReport(id, payload) {
		return spbSupManApi.put(`/spereport/updatereport/${id}`, payload);
	}

	submitCapa(payload) {
		return spbSupManApi.post(`/spereport/submitcapa`, payload);
	}

	updateCapa(id, payload) {
		return spbSupManApi.put(`/spereport/updatecapa/${id}`, payload);
	}

	downloadPurFile(speId, levelTenant) {
		return spbSupManApi.post(
			`/spereport/downloadpurfile/${speId}`,
			levelTenant
		);
	}

	downloadSupFile(speId, levelTenant) {
		return spbSupManApi.post(
			`/spereport/downloadsupfile/${speId}`,
			levelTenant
		);
	}
}

export default new SpeReportService();
