import { encryptForm } from "../helper/encryptForm";
import SpbOrderApi from "./api/SpbOrderApi";

class SpecSRC {
async getTableData(payload) {
    try {
        const sendData = await SpbOrderApi.post(
            '/spb/company/searchtable', encryptForm(payload)
        )
        if (sendData.status === 404){
            setTimeout( async () => {
                await this.getTableData(payload)
            }, 1000);
        } else {
            return sendData;
        }
    } catch (e) {
        console.log(e)
        await this.getTableData(payload)
    }
    // return SpbOrderApi.post('/spb/company/searchtable', payload);
}

async getStatus(payload) {
    try {
        const sendData = await SpbOrderApi.post(
            '/spb/company/getStatus', payload
        )
        // console.log('status',sendData)
        if (sendData.status === 404){
            setTimeout( async () => {
                await this.getStatus(payload)
            }, 1000);
        } else {
            return sendData;
        }
    } catch (e) {
        console.log(e)
        await this.getStatus(payload)
    }
    
    // return SpbOrderApi.post('/spb/company/getStatus', payload); 
}

// getStatus(params) {
//     const queryParams = params
//         ? Object.keys(params)
//                 .map(
//                     (k) =>
//                         encodeURIComponent(k) +
//                         '=' +
//                         encodeURIComponent(params[k])
//                 )
//                 .join('&')
//         : '';
//     const sendData = SpbOrderApi.get(
//         '/spb/company/getStatus?' + queryParams
//     )
//     if (sendData.status === 404){
//         this.getStatus(params)
//     } else {
//         return sendData;
//     }
    
// }

async getSupplier(payload) {
    try {
        const sendData = await SpbOrderApi.post('/spb/company/getSupplier', payload)
        // console.log('item number',sendData)
        if (sendData.status === 404){
            setTimeout( async () => {
                await this.getSupplier(payload)
            }, 1000);
        } else {
            console.log('supplier',sendData)
            return sendData;
        }
    }
    catch (e){
        // console.log('item number',e)
        console.log('supplier acattch',e)
        await this.getSupplier(payload)
    }
    // return SpbOrderApi.post('/spb/company/getSupplier', payload); 
}

async getItemNuber(payload) {
    try {
        const sendData = await SpbOrderApi.post(
            '/spb/company/getItemNumber', payload
        )
        // console.log('item number',sendData)
        if (sendData.status === 404){
            await this.getItemNuber(payload)
        } else {
            return sendData;
        }
    }
    catch (e){
        console.log('item number',e)
        await this.getItemNuber(payload)
    }
    
    // return SpbOrderApi.post('/spb/company/getItemNumber', payload); 
}

// getItemNuber(params) {
//     const queryParams = params
//         ? Object.keys(params)
//                 .map(
//                     (k) =>
//                         encodeURIComponent(k) +
//                         '=' +
//                         encodeURIComponent(params[k])
//                 )
//                 .join('&')
//         : '';
//     const sendData = SpbOrderApi.get(
//             '/spb/company/getItemNumber?' + queryParams
//         )
//         if (sendData.status === 404){
//             this.getItemNuber(params)
//         } else {
//             return sendData;
//         }
// }

async getItemDesc(payload) {
    try{
        const sendData = await SpbOrderApi.post(
            '/spb/company/getItemDescription', payload
        )
        // console.log('item desccription',sendData)
        if (sendData.status === 404){
            await this.getItemDesc(payload)
        } else {
            return sendData;
        }
    }
    catch(e){
        console.log('item description',e)
        await this.getItemDesc(payload)
    }
    
    // return SpbOrderApi.post('/spb/company/getItemDescription', payload); 
}

// getItemDesc(params) {
//     const queryParams = params
//         ? Object.keys(params)
//                 .map(
//                     (k) =>
//                         encodeURIComponent(k) +
//                         '=' +
//                         encodeURIComponent(params[k])
//                 )
//                 .join('&')
//         : '';
//     const sendData = SpbOrderApi.get(
//             '/spb/company/getItemDescription?' + queryParams
//         )
//         if (sendData.status === 404){
//             this.getItemDesc(params)
//         } else {
//             return sendData;
//         }
// }

// getPreparer(payload) {
//     return SpbOrderApi.post('/spb/company/getPreparer', payload); 
// }

async getPreparer(payload) {
    try{
        const sendData = await SpbOrderApi.post(
            '/spb/company/getPreparer', payload
        )
        // console.log('preparer', sendData)
        if (sendData.status === 404){
            await this.getPreparer(payload)
        } else {
            return sendData;
        }
    } catch (e) {
        console.log('staus preparer',e)
        await this.getPreparer(payload)
    }
    
}

async getItemNuberDetail(payload) {
    try {
        const sendData = await SpbOrderApi.post(
            '/spb/company/getItemNumberDetail', payload
        )
        if (sendData.status === 404){
            setTimeout( async () => {
                await this.getItemNuberDetail(payload)
            }, 1000);
        } else {
            return sendData;
        }
    } catch (e) {
        console.log(e)
        await this.getItemNuberDetail(payload)
    }
    // return SpbOrderApi.post('/spb/company/getItemNumberDetail', payload); 
}
async getViewHistory(payload) {
    try {
        const sendData = await SpbOrderApi.post(
            '/spb/company/getViewHistory', payload
        )
        if (sendData.status === 404){
            setTimeout( async () => {
                await this.getViewHistory(payload)
            }, 1000);
        } else {
            return sendData;
        }
    } catch (e) {
        console.log(e)
        await this.getViewHistory(payload)
    }
    // return SpbOrderApi.post('/spb/company/getViewHistory', payload); 
}

async saveAndCreateData(id,payload){
    // try {
    // const sendData = await SpbOrderApi.post(`/spb/company/save/${id}`, payload); 
    //     if (sendData.status === 404){
    //         setTimeout( async () => {
    //             await this.saveAndCreateData(payload)
    //         }, 1000);
    //     } else {
    //         return sendData;
    //     }
    // } catch (e) {
    //     console.log(e)
    //     await this.saveAndCreateData(payload)
    // }
    return SpbOrderApi.post(`/spb/company/save/${id}`, payload); 
}
async getDetailData(payload) {
    try {
        const sendData = await SpbOrderApi.post('/spb/company/getDetailData', payload); 
        if (sendData.status === 404){
            setTimeout( async () => {
                await this.getDetailData(payload)
            }, 1000);
        } else {
            return sendData;
        }
    } catch (e) {
        console.log(e)
        await this.getDetailData(payload)
    }
    // return SpbOrderApi.post('/spb/company/getDetailData', payload); 
}

async getAttachmentFile(payload){
    try {
        const sendData = await SpbOrderApi.get(`/spb/company/getAttachmentFile/${payload}`); 
        if (sendData.status === 404){
            setTimeout( async () => {
                await this.getAttachmentFile(payload)
            }, 1000);
        } else {
            return sendData;
        }
    } catch (e) {
        console.log(e)
        await this.getAttachmentFile(payload)
    }
    // return SpbOrderApi.get(`/spb/company/getAttachmentFile/${payload}`); 
}
async getAttachmentFileSupp(payload){
    try {
        const sendData = await SpbOrderApi.get(`/spb/company/getAttachmentFileSupp/${payload}`); 
        if (sendData.status === 404){
            setTimeout( async () => {
                await this.getAttachmentFileSupp(payload)
            }, 1000);
        } else {
            return sendData;
        }
    } catch (e) {
        console.log(e)
        await this.getAttachmentFileSupp(payload)
    }
    // return SpbOrderApi.get(`/spb/company/getAttachmentFileSupp/${payload}`); 
}
async downloadFileForPur(payload){
    try {
        const sendData = await SpbOrderApi.post(`/spb/company/downloadfilePurWithTenant`, payload); 
        if (sendData.status === 404){
            setTimeout( async () => {
                await this.downloadFileForPur(payload)
            }, 1000);
        } else {
            return sendData;
        }
    } catch (e) {
        console.log(e)
        await this.downloadFileForPur(payload)
    }
    // return SpbOrderApi.post(`/spb/company/downloadfilePurWithTenant`, payload); 
}
async downloadFileForSupp(payload){
    try {
        const sendData = await SpbOrderApi.post(`/spb/company/downloadfileSuppWithTenant`, payload); 
        if (sendData.status === 404){
            setTimeout( async () => {
                await this.downloadFileForSupp(payload)
            }, 1000);
        } else {
            return sendData;
        }
    } catch (e) {
        console.log(e)
        await this.downloadFileForSupp(payload)
    }
    // return SpbOrderApi.post(`/spb/company/downloadfileSuppWithTenant`, payload); 
}

}
export default new SpecSRC();