import spbinvoice from './api/SpbInvoiceApi';

class InvoiceCreateNonPOService {
	getCategoryVendor(payload) {
		return spbinvoice.post(`/createinvoicenonpo/categoryvendor`, payload);
	}

	getAvailableCategoryDetails(payload) {
		return spbinvoice.post(
			`/createinvoicenonpo/availablecategorydetails`,
			payload
		);
	}

	getSKNumbers(payload) {
		return spbinvoice.post(`/createinvoicenonpo/sknumbers`, payload);
	}

	getVatRate(payload) {
		return spbinvoice.post(`/createinvoicenonpo/vatrate`, payload);
	}

	getSupplierName(payload) {
		return spbinvoice.post(`/createinvoicenonpo/suppliername`, payload);
	}

	getListVendor(payload) {
		return spbinvoice.post(`/createinvoicenonpo/listvendor`, payload);
	}

	getReimburstGroups(payload) {
		return spbinvoice.post(`/createinvoicenonpo/reimburstgroups`, payload);
	}

	getTravelOrders(payload) {
		return spbinvoice.post(`/createinvoicenonpo/travelorders`, payload);
	}

	saveInvoice(payload) {
		return spbinvoice.post(`/createinvoicenonpo/saveinvoice`, payload);
	}
}

export default new InvoiceCreateNonPOService();
