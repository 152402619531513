<template>
	<!-- eslint-disable -->
	<Toast />
	<div class="Grid">
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<!-- Search -->
		<div
			class="col-12 mb-4"
			v-if="!(userInfo ? userInfo.leveltenant === 2 : false)"
		>
			<div class="content-wrapper">
				<div class="content-header">Search</div>
				<div class="content-body">
					<div class="col-12 p-fluid">
						<div class="grid formgrid" style="margin: 1em 0 0 0">
							<!-- <div class="col-12 mb-5 lg:col-4">
                <CompaniesDropdown
                  v-model="company"
                  class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
                  :disabled="!(userInfo ? userInfo.leveltenant === 3 : false)"
                />
              </div> -->
							<div
								class="col-12 mb-5 lg:col-4"
								v-if="!isSupplierpage"
							>
								<SupplierDropdown
									v-model="supplier"
									v-if="
										!(userInfo
											? userInfo.leveltenant === 2
											: false)
									"
									:company="company ? company : null"
									:key="supplierReload"
								/>
							</div>
							<div class="mr-3 ml-2">
								<Button
									@click="handleSearch(true)"
									class="p-button-success uppercase"
									label="search"
									:disabled="
										!company && !invoiceTo && !invoiceFrom
									"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- General information -->
		<div
			class="col-12 mb-4"
			v-if="!(userInfo ? userInfo.leveltenant === 1 : false)"
		>
			<div class="content-wrapper">
				<div class="content-header">Search</div>
				<div class="content-body">
					<div class="col-12 p-fluid">
						<div class="grid formgrid" style="margin: 1em 0 0 0">
							<!-- <div class="col-12 mb-5 lg:col-4">
                <CompaniesDropdown
                  v-model="company"
                  class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
                  :disabled="(userInfo ? userInfo.leveltenant === 2 : false)"
                />
              </div> -->
							<div class="col-12 mb-5 lg:col-6">
								<span class="p-float-label">
									<InputText
										v-model="supplierName"
										class="w-full"
										style="color: #000"
										disabled
									/>
									<label>Supplier</label>
								</span>
							</div>
							<div class="col-12 mb-5 lg:col-6">
								<span class="p-float-label">
									<InputText
										v-model="vatNumber"
										class="w-full"
										style="color: #000"
										disabled
									/>
									<label>VAT Number</label>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Address Information -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Address Information</div>
				<div class="content-body">
					<div class="mt-4">
						<TableAddressInformation
							:column="column ? column.col : null"
							:company="company ? company : null"
							:supplier="supplier ? supplier : null"
							:key="addressInformationReload"
						/>
					</div>
				</div>
			</div>
		</div>
		<!-- Comunication Information -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Comunication Information</div>
				<div class="content-body">
					<div class="mt-4">
						<TableCommunicationInformation
							:column="column ? column.col : null"
							:company="company ? company : null"
							:supplier="supplier ? supplier : null"
							:key="communicationInformationReload"
						/>
					</div>
				</div>
			</div>
		</div>

		<!-- Legal Information -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Legal Information</div>
				<div class="content-body">
					<div>
						<div class="col-12 mb-2">
							<Button
								v-if="isSupplierpage"
								@click="UploadNewDocument()"
								class="p-button-success uppercase"
								label="Upload New Document"
							/>
						</div>
						<TableLegalInformation
							:column="column ? column.col : null"
							:company="company ? company : null"
							:supplier="supplier ? supplier : null"
							:key="legalInformationReload"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import AuthService from '../../service/AuthService';
import LovService from '../../service/LovService';
// import PoFulfillment from "../../service/PoFulfillment";
import SimpleCrypto from 'simple-crypto-js';
// import CompaniesDropdown from "@/components/components/CompaniesDropdown";
import SupplierDropdown from '@/components/components/SupplierDropdown';
import TableAddressInformation from './components/TableAddressInformation.vue';
import TableCommunicationInformation from './components/TableCommunicationInformation.vue';
import TableLegalInformation from './components/TableLegalInformation.vue';
import SupplierMaster from '../../service/SupplierMasterServices';

const breadcrumbHome = { icon: 'pi pi-home', to: '/dashboard' };
const breadcrumbItems = [
	{
		label: 'Supplier Master',
		to: '/mgm/master/suppliermaster',
	},
];
const company = ref();
const supplier = ref();
const userInfo = ref();
const supplierName = ref();
const isSupplierpage = ref(true);
const supplierReload = ref(0);
const legalInformationReload = ref();
const addressInformationReload = ref();
const router = useRouter();
const vatNumber = ref();
const communicationInformationReload = ref();

watch(
	() => company.value,
	(newValue) => {
		if (typeof newValue === 'object' && !Array.isArray(newValue)) {
			supplierReload.value++;
		}
	}
);

onMounted(() => {
	AuthService.whoAmi().then(async (response) => {
		if (response.status === 200) {
			userInfo.value = response.data.data;

			if (userInfo.value.leveltenant === 2) {
				//get company
				isSupplierpage.value = true;
				const respCbN = await LovService.getCompanyByName(
					userCompany()
				);
				company.value = respCbN.data.data[0];
				//get supplier

				const param = {
					db_instance: company.value.db_instance,
					org_id: company.value.value,
				};

				const respVendor = await LovService.getSuppBindComp(
					response.data.data.id
				);
				console.log('cek suppbind com', respVendor);
				const vendorId = respVendor.data.data[0].vendor_id;
				console.log('cek vendor id', vendorId);

				const respSuppName = await LovService.getSupplierName(param);
				if (respSuppName.status === 200) {
					const suppList = respSuppName.data.data;
					for (const s of suppList) {
						if (s.vendor_id === vendorId) {
							supplier.value = s;
							supplierName.value = supplier.value.vendor_name;
							console.log('cek supplier', supplier.value);
							console.log(
								'cek supplier name',
								supplierName.value
							);
							break;
						}
					}

					legalInformationReload.value++;
					addressInformationReload.value++;
					communicationInformationReload.value++;
					await getVatNum();
				}
			} else {
				isSupplierpage.value = false;
				//
				const respCbN = await LovService.getCompanyByName(
					userCompany()
				);
				company.value = respCbN.data.data[0];

				//
			}
		}
	});
});

const userCompany = () => {
	const secretKey = 'some-unique-key';
	const simpleCrypto = new SimpleCrypto(secretKey);
	return simpleCrypto.decrypt(sessionStorage.getItem('dropdownCompany'));
};

const handleSearch = () => {
	legalInformationReload.value++;
	addressInformationReload.value++;
	communicationInformationReload.value++;
};

const UploadNewDocument = async () => {
	router.push({
		name: 'detailsuppliermaster',
		params: { id: 0 },
		query: {
			v: company.value.value,
			i: supplier.value ? supplier.value.vendor_id : null,
		},
	});
};

const getVatNum = async () => {
	try {
		const payload = {
			vendor_id: supplier.value ? supplier.value.vendor_id : null,
		};
		const res = await SupplierMaster.getVatNum(payload);
		//
		if (res.status === 200) {
			vatNumber.value = res.data.data.data[0].VAT_REG_NUM;
		}
	} catch (err) {
		console.log(err);
	}
};
</script>
