<template>
	<div class="grid">
		<Toast />

		<!-- Breadcrumb -->
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<!-- Shipment Information -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Shipment Information</div>
				<div class="content-body">
					<div class="grid">
						<div class="col-12 md:col-6 lg:col-4">
							<span class="p-float-label">
								<InputText
									id="doNumber"
									class="w-full"
									type="text"
									v-model="doNumber"
								/>
								<label for="doNumber">DO Number</label>
							</span>
						</div>
						<div class="col-12 md:col-6 lg:col-4">
							<span class="p-float-label">
								<Calendar
									class="w-full"
									dateFormat="dd M yy"
									:showIcon="true"
									v-model="deliveryDate"
								/>
								<label for="doNumber">Delivery Date</label>
							</span>
						</div>
						<div
							class="col-12 md:col-6 lg:col-4"
							v-if="statusValue"
						>
							<span class="p-float-label">
								<InputText
									id="status"
									class="w-full"
									type="text"
									v-model="status"
								/>
								<label for="status">Status</label>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Freight Information -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Freight Information</div>
				<div class="content-body">
					<div class="grid">
						<!-- Freight carrier -->
						<div class="col-12 md:col-6">
							<div class="mb-6">
								<span class="p-float-label">
									<InputText
										id="freightCarrier"
										class="w-full"
										type="text"
										v-model="freightCarrier"
									/>
									<label for="freightCarrier"
										>Freight Carrier</label
									>
								</span>
							</div>
							<div>
								<span class="p-float-label">
									<InputText
										id="freightNumber"
										class="w-full"
										type="text"
										v-model="freightNumber"
									/>
									<label for="freightNumber"
										>AWB/BL Number</label
									>
								</span>
							</div>
						</div>
						<!-- Comment -->
						<div class="col-12 md:col-6">
							<span class="p-float-label">
								<Textarea
									id="comment"
									class="w-full"
									rows="6"
									:autoResize="true"
									v-model="comment"
								/>
								<label for="comment">Comment</label>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Attachment Document -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Attachment Document</div>
				<div class="content-body">
					<!-- Button upload doc -->
					<div class="mb-5">
						<Button
							class="p-button-success p-button-sm w-auto"
							icon="pi pi-upload"
							label="Upload Document"
						/>
					</div>

					<!-- Table Files -->
					<DataTable :value="documents">
						<Column header="No" field="no"></Column>
						<Column
							header="Document Type"
							field="document_type"
						></Column>
						<Column header="File">
							<template #body> Filename </template>
						</Column>
						<Column
							header="Need Original"
							field="need_original"
						></Column>
						<Column header="Validate" field="validate"></Column>

						<!-- Empty state -->
						<template #empty>
							<p>No Data.</p>
						</template>
					</DataTable>
				</div>
			</div>
		</div>

		<!-- Items -->
		<div class="col-12 mb-6">
			<div class="content-wrapper">
				<div class="content-header">Items</div>
				<div class="content-body">
					<DataTable
						:value="purchaseOrders"
						:lazy="true"
						:paginator="true"
						:rows="10"
						:rowsPerPageOptions="[10, 20, 50]"
						:metaKeySelection="false"
						:loading="loading"
						:totalRecords="totalRecords"
						ref="dt"
						dataKey="id"
						paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
						responsiveLayout="scroll"
						selectionMode="multiple"
						v-model:selection="selectedPo"
						@page="onPage($event)"
						@sort="onSort($event)"
						@row-select="onRowSelect"
					>
						<Column
							header="PO Number"
							field="SEGMENT1"
							:sortable="true"
						></Column>
						<Column
							header="Line"
							field="PO_LINE_ID"
							:sortable="true"
						></Column>
						<Column
							header="Item Number"
							field="ITEM_NUMBER"
							:sortable="true"
						></Column>
						<Column
							header="Item Description"
							field="ITEM_DESCRIPTION"
							:sortable="true"
						></Column>
						<Column
							header="Arrival at Site Date"
							field="PROMISED_DATE"
							:sortable="true"
						></Column>
						<Column
							header="Quantity Order"
							field="QUANTITY"
						></Column>
						<Column header="UOM" field="UOM"></Column>
						<Column header="Qty Shipped" field="QUANTITY"></Column>

						<!-- Empty state -->
						<template #empty> No Data. </template>
					</DataTable>
				</div>
			</div>
		</div>

		<!-- Action Buttons -->
		<div class="mb-6">
			<Button class="p-button-link"
				><span class="font-bold uppercase text-black-alpha-90"
					>Back</span
				></Button
			>
			<Button class="p-button-secondary ml-3"
				><span class="font-bold uppercase">Save as Draft</span></Button
			>
			<Button class="p-button-success ml-3">
				<span class="font-bold uppercase">Submit</span>
			</Button>
		</div>
	</div>
</template>

<script>
import AuthService from '../../../service/AuthService';
import CreateShipmentService from '../../../service/CreateShipmentService';
export default {
	name: 'DetailShipmentNoticeSupp',
	data() {
		return {
			purchaseOrders: [],
			freightCarrier: null,
			statusValue: false,
			status: null,
			deliveryDate: null,
			idValue: this.$route.params.id,
			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [
				{
					label: 'Shipment Notice',
					to: '/mgm/settings/shipmentnotice',
				},
				{
					label: 'Create Shipment Notice Add/Edit',
					to: this.$route.path,
				},
			],
		};
	},
	watch: {},
	created() {
		AuthService.whoAmi().then((response) => {
			//
			if (response.status == 200) {
				const vendorId = sessionStorage.getItem('vendorId');
				CreateShipmentService.getDetailSupp(
					this.idValue,
					vendorId
				).then((response) => {
					this.deliveryDate = new Date(
						response.data.data[0].DELIVERY_INVOICE_DATE
					);
					this.freightCarrier = response.data.data[0].FREIGHT_TERMS;
					this.purchaseOrders = response.data.datatable;
				});
			}
		});
	},
	mounted() {},
	methods: {},
};
</script>
