import axios from 'axios';
import packageJson from '../../../package.json';
/* eslint-disable */
const spbKktApi = () => {

	const hostname = window.location.hostname;
	console.log("HOSTNAME::", hostname)
	let url = 'https://zkkt.isupplier-portal.com';
	
	if(hostname.includes('dev')) url = 'https://devkkt.isupplier-portal.com';
	else if(hostname.includes('149')) url = 'http://149.129.241.185:31012';
		else if(hostname.includes('spb')) url = 'https://zkkt.isupplier-portal.com'
	else url = 'http://localhost:3000'

	const defaultOptions = {
		baseURL: url,
	};

	let instance = axios.create(defaultOptions);

	instance.interceptors.request.use(function (config) {
		let token = sessionStorage.authKey;
		if (token != null) {
			token = token.replaceAll('"', '');
		}
		config.headers.Authorization = token ? `Bearer ${token}` : '';
		let fe_version = config.headers['fe-version'] || packageJson.version
		if(fe_version !== sessionStorage.getItem('fe-version') && (config.method == 'post' || config.method == 'get')){
			sessionStorage.setItem('fe-version', fe_version)
			// window.location.reload(true) // For new version, simply reload on any get
		}
		return config;
	});

	return instance;
};

export default spbKktApi();


