import spbKkt from "./api/SpbKktApi";
class ApplicationGroupService {
  reqGroup = {};
  groupSetupData = {};
  allModules = [];
  modules = [];
  menus = [];
  groupEditData = {};
  haveModuleAndMenus = false;
  async getAllModules(id) {
    const url = `adm/accmod/${id}`;
    return spbKkt.get(url);
  }

  async getAllMenuByModuleId(id) {
    const url = `adm/accmod/menusmodule/${id}`;
    return spbKkt.get(url);
  }

  async getAllMenuByModuleIdEdit(id) {
    const url = `adm/accmod/menusmodule/${id}`;
    return spbKkt.get(url);
  }

  async getAllMenuByModuleIdAndGroupId(id, groupid) {
    const url = `adm/accmod/menusmodule/${id}/${groupid}`;
    return spbKkt.get(url);
  }

  async getModuleWithMenu() {
    const url = 'adm/group/getallmodulewithmenu';
    return spbKkt.get(url);
  }
  
  async regisGroup(payload) {

    const url = 'adm/group/regisGroup';
    return spbKkt.post(url, payload);
  }

  async editGroup(payload) {
    const url = 'adm/group/editGroup';
    return spbKkt.post(url, payload);
  }

  async editGroupActive(payload) {
    const url = 'adm/group/editGroupActive';
    return spbKkt.post(url, payload);
  }

  async getAllGroup() {
    const url = 'adm/group/getAllGroup';
    return await spbKkt.get(url);
  }
  async getAllGroupForData() {
    const url = 'adm/group/getAllGroupForData';
    return await spbKkt.get(url);
  }
  async getExternalGroup(){
    const url = 'adm/group/getExternalGroup';
    return spbKkt.get(url);
  }

  async getGroupDetail(id) {
    const url = `adm/group/getDetailsGroup/${id}`;
    return spbKkt.get(url);
  }

  async deleteGroup(payload) {
    const url = 'adm/group/deleteGroup';
    return spbKkt.post(url, payload);
  }

  async getAllowedDelete(id) {
    const url = `adm/group/getalloweddelete/${id}`;
    return spbKkt.get(url);
  }
    
}
export default new ApplicationGroupService();
