<template>
  <div class="grid">
    <div class="col-12">
      <div class="col-12 p-fluid">
        <div class="col-12 mb-5 formatText">
          <div class="img-din">
            <img
              alt="Logo"
              :src="imgDinamic"
              style="width: 130px; object-fit: cover"
              v-if="isImage"
            />
          </div>
          <div class="mt-4" style="display: flex; justify-content: space-between">
            <h2>Goods Receipt Note</h2>
            <p>Print: {{ this.formatDateDexa(new Date()) }}</p>
          </div>
          <br />
          <DataTable
            :value="rooTable"
            v-model:expandedRows="expandedRows"
          >
            <template #empty> {{ isEmpty }} </template>
            <Column :expander="true" headerStyle="width: 3rem" />
            <Column
              field="INVOICE_NO"
              header="Invoice No"
            ></Column>
            <Column
              field="VENDOR_NAME"
              header="Supplier"
            ></Column>
            <template #expansion="slotProps">
              <div class="orders-subtable">
                <DataTable
                  :value="slotProps.data.children"
                  removableSort
                  responsiveLayout="scroll"
                >
                  <template #empty> Data Detail Empty </template>
                  <Column
                    class="call-button-container"
                    header="Receipt Num"
                    field="RECEIPT_NUM"
                  ></Column>
                  <Column header="Receipt Date" field="RECEIPT_DATE">
                    <template #body="{ data }">
                      {{
                        new Date(data.RECEIPT_DATE).toLocaleDateString(
                          "id-ID",
                          {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          }
                        )
                      }}
                    </template>
                  </Column>
                  <Column
                    class="call-button-container"
                    header="PO Number"
                    field="PO_NUMBER"
                  ></Column>
                  <Column
                    class="call-button-container"
                    header="Line PO"
                    field="LINE_NUM"
                  ></Column>
                  <Column
                    class="call-button-container"
                    header="Item Code"
                    field="ITEM_NUMBER"
                  ></Column>
                  <Column
                    class="call-button-container"
                    header="Item Desc"
                    field="ITEM_DESCRIPTION"
                  ></Column>
                  <Column
                    class="call-button-container"
                    header="QTY"
                    field="QUANTITY"
                  ></Column>
                  <Column
                    class="call-button-container"
                    header="UOM"
                    field="UNIT_MEAS_LOOKUP_CODE"
                  ></Column>
                </DataTable>
              </div>
            </template>
          </DataTable>
          <br />
        </div>

        <div class="p-fluid grid formgrid" v-if="!hideButton">
          <div class="col-6 mb-2 lg:col-1 lg:mb-0">
            <Button
              type="button"
              @click="printNow()"
              label="Print"
              class="p-button-primary"
            />
          </div>
          <div class="col-6 mb-2 ml-2 lg:col-1 lg:mb-0">
            <Button
              type="button"
              @click="closeWindow()"
              label="Close"
              class="p-button-secondary"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import AuthService from "../service/AuthService";
import CreateBatchServices from "../service/CreateBatchServices";
export default {
  data() {
    return {
      rooTable: null,
      titleSupp: null,
      titleInv: null,
      batchNum: null,
      paramsPrint: sessionStorage.getItem("paramsPrintROO"),
      currentDate: new Date(),
      hideButton: false,
      isSupplier: true,
      imgDinamic: null,
      company: null,
      isImage: false,
      expandedRows: null,
    };
  },
  created() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();
    let hh = today.getHours();
    let m = today.getMinutes();
    let d = today.getSeconds();
    this.combinedDate = yyyy + mm + dd + hh + m + d;

    document.title = "good-receipt" + "-" + this.combinedDate;

    AuthService.whoAmi().then(async (response) => {
      if (response.status === 200) {
        // response.data.data.leveltenant = 1
        await CreateBatchServices.printBatch(this.paramsPrint).then(
          (respond) => {
            
            let respData = respond.data.data.data;
            let supp = respond.data.data.data[0].VENDOR_NAME;
            let noInvoice = respond.data.data.data.INVOICE_NO;
            this.company = respond.data.data.data[0].ORG_ID;
            
            // for (var s of respData){
            //   s.VENDOR_NAME = this.vendor
            // }
            if (
              this.company !== null ||
              this.company !== "null" ||
              this.company !== undefined
            ) {
              if (this.company === 81) {
                this.isImage = true;
                this.imgDinamic = "layout/images/company logo/ferron.jpeg";
              } else if (this.company === 82) {
                this.isImage = true;
                this.imgDinamic = "layout/images/company logo/dexa medica.png";
              } else if (this.company === 1212) {
                this.isImage = true;
                this.imgDinamic =
                  "layout/images/company logo/beta pharmacon.png";
              } else if (this.company === 772) {
                this.isImage = true;
                this.imgDinamic = "layout/images/company logo/equilab.png";
              } else if (this.company === 972) {
                this.isImage = true;
                this.imgDinamic = "layout/images/company logo/fonko.png";
              }
            } else {
              this.isImage = false;
            }
            this.titleInv = noInvoice;
            this.titleSupp = supp;
            this.rooTable = respData;
            this.expandedRows = this.rooTable.filter((i)=> i.id)
            if (this.rooTable.length < 1) {
              window.alert("No data found");
              window.close();
            }
          }
        );
        // 
        if (response.data.data.leveltenant == 1) {
          
          this.isSupplier = false;
        } else {
          
        }
      }
    });
  },
  methods: {
    closeWindow() {
      window.close();
    },
    printNow() {
      this.hideButton = true;
      setTimeout(this.actualPrintNow, 300);
    },
    formatDateDexa(date) {
      var date = new Date(date);
      var respondDate =
        ("00" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        ("00" + date.getDate()).slice(-2) +
        "/" +
        date.getFullYear() +
        " " +
        ("00" + date.getHours()).slice(-2) +
        ":" +
        ("00" + date.getMinutes()).slice(-2) +
        ":" +
        ("00" + date.getSeconds()).slice(-2);
      return respondDate;
    },
    actualPrintNow() {
      if (this.hideButton === true) {
        window.print();
        if (document.hasFocus()) {
          this.hideButton = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.formatText p {
  font-size: 16px;
}
</style>