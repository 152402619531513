<template>
  <AutoComplete :suggestions="filteredColumns" @complete="searchColumns($event)" :dropdown="true" field="name" placeholder="Select a column" forceSelection>
    <template #item="slotProp">
      {{ slotProp.item.name }}
    </template>
  </AutoComplete>
</template>

<script setup>
import {onMounted, ref} from 'vue'

const filteredColumns = ref([]);
const columns = ref([]);

onMounted(() => {
  columns.value = [
    {col: "ISPO.SEGMENT1", name: 'PO Number', type: 'String'},
    {col: "ISPO.LINE_NUM", name: 'Line', type: 'String'},
    {col: "ISPO.ITEM_NUMBER", name: 'Item Number', type: 'String'},
    {col: "ISPO.ITEM_DESCRIPTION", name: 'Item Description', type: 'String'},
    {col: "ISPO.UOM", name: 'UOM', type: 'String'},
    {col: "ISPO.QUANTITY", name: 'PO Quantity', type: 'String'},
    {col: "ISPO.UNIT_PRICE", name: 'Unit Price', type: 'String'},
    {col: "ISPO.NEED_BY_DATE", name: 'Arrival At Site', type: 'Date'},
    {col: "ISPO.ESTIMATE_COMPLATION_DATE", name: 'Est. Good Readiness', type: 'Date'},
    {col: "ISPO.ESTIMATE_MANUFACTURER_DATE", name: 'Est. Delivery', type: 'Date'},
    {col: "ISPO.ESTIMATE_SUPPLIER_DATE", name: 'Est. Arrival At Site', type: 'Date'},
    {col: "ISASNH.SHIPPED_DATE", name: 'Delivery Date', type: 'Date'},
    {col: "ISASNH.SHIPMENT_NUMBER", name: 'DO Number', type: 'String'},
    {col: "ISASND.QUANTITY_SHIPPED", name: 'Quantity Shipped', type: 'String'},
    {col: "ATV.CLOSED_CODE", name: 'Closed Code', type: 'String'},
    {col: "ATV.QTY_RECEIPT", name: 'Quantity Receipt', type: 'String'},
  ]
})


const searchColumns = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredColumns.value = [...columns.value]
    } else {
      filteredColumns.value = columns.value.filter((comp) => {
        return comp.name.toLowerCase().match(event.query.toLowerCase());
      })
    }
  }, 250);
}
</script>

<style scoped>

</style>