<template>
	<Card>
		<template #title>
			<span class="text-xl">Shipment Notice</span>
		</template>
		<template #content>
			<div class="flex justify-content-between">
				<!-- Status -->
				<div>
					<p class="font-bold mb-0 mb-4">Status</p>
					<p class="mb-3">Draft</p>
					<p class="mb-3">Submitted</p>
					<p class="mb-0">Close</p>
				</div>

				<!-- Total -->
				<div>
					<p class="font-bold mb-0 mb-4">Total</p>
					<!-- This have to following the order of modules name -->
					<p class="mb-3">{{ statusDraft }}</p>
					<p class="mb-3">{{ statusSubmit }}</p>
					<p class="mb-3">{{ statusClose }}</p>
				</div>
			</div>
		</template>
		<template #footer>
			<Button
				label="Link"
				class="p-button-link font-bold"
				@click="openModal()"
			>
				<span class="mr-2">See details</span>
				<i class="pi pi-arrow-right"></i>
			</Button>
		</template>
	</Card>
	<Dialog
		header="Shipment Notice"
		v-model:visible="displayModal"
		:style="{ width: '90vw' }"
		:modal="true"
	>
		<div class="grid">
			<Toast />
			<div class="col-12 p-fluid">
				<div class="card">
					<div class="grid formgrid" style="margin: 1em 0 0 0">
						<div class="col-12 mb-5 lg:col-4 lg:mb-5">
							<span class="p-float-label">
								<AutoComplete
									v-model="company"
									:suggestions="filteredCompany"
									@complete="searchCompany($event)"
									:dropdown="true"
									field="name"
									forceSelection
									:disabled="!isSupplierPage"
								>
									<template #item="slotProps">
										<div class="supplier-item">
											<div class="ml-2">
												{{ slotProps.item.name }}
											</div>
										</div>
									</template>
								</AutoComplete>
								<label for="inputtext">Company</label>
							</span>
						</div>
						<div
							v-if="!isSupplierPage"
							class="col-12 mb-5 lg:col-4 lg:mb-5"
						>
							<span class="p-float-label">
								<AutoComplete
									v-model="supplier"
									:suggestions="filteredSupplier"
									@complete="searchSupplier($event)"
									:dropdown="true"
									field="vendor_name"
									:disabled="companyValue"
									forceSelection
								>
									<template #item="slotProps">
										<div class="supplier-item">
											<div class="ml-2">
												{{ slotProps.item.vendor_name }}
											</div>
										</div>
									</template>
								</AutoComplete>
								<label for="inputtext">Supplier</label>
							</span>
						</div>
						<div class="col-12 mb-5 lg:col-4 lg:mb-5">
							<span class="p-float-label">
								<AutoComplete
									v-model="doNumber"
									:suggestions="filteredDoNumber"
									@complete="searchDoNumber($event)"
									field="SHIPMENT_NUMBER"
									forceSelection
								>
								</AutoComplete>
								<label for="inputtext">Do Number</label>
							</span>
						</div>
						<!-- <div
						class="col-12 mb-5 lg:col-4 lg:mb-5"
					>
						<span class="p-float-label">
							<AutoComplete
								v-model="itemdesc"
								:suggestions="filteredItemDesc"
								:disabled="itemdescButton"
								@complete="searchitemdesc($event)"
								:dropdown="true"
								field="ITEM_DESC"
								forceSelection
							>
								<template #item="slotProps">
									<div class="preparer-item">
										<div class="ml-2">
											{{ slotProps.item.ITEM_DESC }}
										</div>
									</div>
								</template>
							</AutoComplete>
							<label for="inputtext">Item Description</label>
						</span>
					</div> -->
						<div class="col-12 mb-5 lg:col-4 lg:mb-5">
							<span class="p-float-label">
								<AutoComplete
									v-model="lov"
									:suggestions="filteredLov"
									@complete="searchLov($event)"
									:dropdown="true"
									field="name"
									forceSelection
								>
									<template #item="slotProps">
										<div class="lov-item">
											<div class="ml-2">
												{{ slotProps.item.name }}
											</div>
										</div>
									</template>
								</AutoComplete>
								<label for="inputtext">Status</label>
							</span>
						</div>
						<div class="col-12 mb-2 lg:col-2 xl:col-1 lg:mb-5">
							<Button
								label="Go"
								@click="searchTableNested()"
								:disabled="
									this.buttonDisable ||
									(currentUser.leveltenant === 2 && !supplier)
								"
							></Button>
						</div>
						<div class="col-12 mb-2 lg:col-2 xl:col-1 lg:mb-5">
							<Button
								class="p-button-secondary"
								label="Clear"
								@click="clearFilter()"
							></Button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- <div> {{sessionStorage.companydb}} </div> -->

		<div class="card">
			<DataTable
				:value="nested"
				:lazy="true"
				:paginator="true"
				:rows="rows"
				v-model:expandedRows="expandedRows"
				ref="dt"
				dataKey="id"
				:totalRecords="totalRecords"
				:loading="loading"
				@page="onPage($event)"
				@sort="onSort($event)"
				removableSort
				:rowsPerPageOptions="[10, 20, 30]"
				responsiveLayout="scroll"
				:v-model="first"
			>
				<template #empty> {{ isEmpty }} </template>
				<Column :expander="true" headerStyle="width: 3rem" />
				<Column
					field="do_num"
					header="AWB Number"
					:sortable="true"
				></Column>
				<template #expansion="slotProps">
					<div class="orders-subtable">
						<DataTable
							:value="slotProps.data.children"
							removableSort
							responsiveLayout="scroll"
						>
							<template #empty> Data Detail Empty </template>
							<Column header="Line" headerStyle="width:3em">
								<template #body="slotProps">
									{{ slotProps.index + 1 }}
								</template>
							</Column>
							<Column
								field="donum"
								header="DO Number"
								:sortable="true"
							></Column>
							<Column
								field="itemnum"
								header="Item Number"
								:sortable="true"
							></Column>
							<Column
								field="itemdesc"
								header="Item Description"
								:sortable="true"
							></Column>
							<Column
								field="uom"
								header="UOM"
								:sortable="true"
								style="text-align: left"
							></Column>
							<Column
								field="qtyorder"
								header="Qty Order"
								:sortable="true"
								style="text-align: center"
							>
								<template #body="{ data }">
									{{ formatCurrency(data.qtyorder) }}
								</template>
							</Column>
							<Column
								field="qtyshipped"
								header="Qty Shipped"
								:sortable="true"
								style="text-align: center"
							>
								<template #body="{ data }">
									{{ formatCurrency(data.qtyshipped) }}
								</template>
							</Column>
							<Column
								field="shipdate"
								header="Delivery Date"
								dataType="numeric"
								style="text-align: center"
							>
							</Column>
							<Column
								field="expdate"
								header="Arrival at Site Date"
								dataType="numeric"
								style="text-align: center"
							>
							</Column>
							<Column
								field="status"
								header="Line Status"
								:sortable="true"
								style="text-align: center"
							>
							</Column>
							<Column
								headerStyle="width:4rem"
								header="Action"
								selectionMode="single"
							>
								<template #body="slotProps">
									<Button
										icon="pi pi-pencil"
										class="p-button-rounded p-button-text"
										@click="editChildren(slotProps.data)"
									/>
								</template>
							</Column>
						</DataTable>
					</div>
				</template>
			</DataTable>
		</div>
	</Dialog>
</template>

<script>
/* eslint-disable */
import ShipmentNotice from '../../../service/ShipmentNotice';
import AuthService from '../../../service/AuthService';
import NotificationService from '../../../service/NotificationService';
import LovService from '../../../service/LovService';
import SimpleCrypto from 'simple-crypto-js';
import PoFulfillment from '../../../service/PoFulfillment';
import PlanningOrderService from '../../../service/PlanningOrderService';

export default {
	props: {
		notifications: Array,
	},
	data() {
		return {
			currentUser: null,
			displayModal: false,
			expandedRowGroups: null,
			isSupplierPage: false,
			first: 0,
			rowId: null,
			expandedRows: [],
			expandedRowGroups: [],
			nested: [],
			rows: 10,
			nestedchildren: [],
			groupdata: null,
			selectedChildren: null,
			loading: false,
			lazyParams: {},
			selectLazyParams: {},
			totalRecords: 0,
			totalRecordsGrup: 0,
			tabelexpand: null,
			company: null,
			companyValue: true,
			filteredCompany: null,
			isiCompany: null,
			supplier: null,
			isisupplier: null,
			filteredSupplier: null,
			selectedSupplier: null,
			supplierparse: null,
			search: null,
			filtered: null,
			itemdesc: null,
			isiItemDesc: null,
			filteredItemDesc: null,
			lov: null,
			isilov: null,
			filteredLov: null,
			lovparse: null,
			selectedLov: null,
			doNumber: null,
			isiDoNumber: null,
			filteredDoNumber: null,
			button: 0,
			buttonDisable: true,
			buttonSearch: true,
			rowgroup: null,
			testname: null,
			onStorageUpdate: null,
			saveDb: null,
			itemdescButton: true,
			suppBindToComp: null,
			poValue: null,
			isEmpty: null,
			suppName: null,
			vendorId: null,
			companyDash: null,
			statusDraft: 0,
			statusSubmit: 0,
			statusClose: 0,
		};
	},
	watch: {
		company(newQuestion) {
			if (this.company != null) {
				this.doNumber = null;
				this.lov = null;
				this.itemdesc = null;
				this.companyValue = false;
				this.itemdescButton = false;
				if (
					typeof newQuestion === 'object' &&
					!Array.isArray(newQuestion) &&
					newQuestion !== null
				) {
					this.getPlenno(newQuestion.name);
					this.companyValue = false;
					this.saveDb = this.company.db_instance;
					sessionStorage.setItem('companydb', this.saveDb);
					this.Getitemdesc();
					if (sessionStorage.leveltenant == 2) {
						this.getValueCompany(newQuestion.name);
					} else {
						this.buttonDisable = false;
						this.getValueCompany(newQuestion.name);
					}
				}
			}
		},
		supplier(newQuestion) {
			if (
				this.doNumber == null &&
				this.lov == null &&
				this.itemdesc == null
			) {
				this.lov = null;
				this.itemdesc = null;
				this.buttonSearch = false;
				if (
					typeof newQuestion === 'object' &&
					!Array.isArray(newQuestion) &&
					newQuestion !== null
				) {
					this.getValueSup(newQuestion.name);
					this.buttonDisable = false;
				}
			}
		},
		doNumber(newQuestion) {
			if (this.supplier == null) {
				if (
					typeof newQuestion === 'object' &&
					!Array.isArray(newQuestion) &&
					newQuestion !== null
				) {
					// this.getValuePoNum();
					this.buttonDisable = false;
				}
			}
		},
		lov(newQuestion) {
			// if (this.supplier == null && this.doNumber == null && this.itemdesc == null) {
			this.buttonDisable = false;
			// }
		},
		itemdesc(newQuestion) {
			// if (this.supplier == null && this.doNumber == null && this.lov == null) {
			this.buttonDisable = false;
			// }
		},
		poValue(newQuestion) {
			if (this.poValue != null) {
				this.getValueSup(newQuestion);
			}
		},
	},
	async created() {
		sessionStorage.removeItem('tableSession');
		const resp = await AuthService.whoAmi();
		this.currentUser = resp.data.data;
		if (resp.data.data.leveltenant == 2) {
			this.getValueSup();
			const response = await ShipmentNotice.getSuppBindComp(
				resp.data.data.id
			);
			this.suppBindToComp = response.data.data[0][0].vendorname;
			this.poValue = this.suppBindToComp;
			this.isSupplierPage = true;
			const payload = {
				vendorName: response.data.data[0][0].vendorname,
			};
			const respond = await PlanningOrderService.getSuppValue(payload);
			this.vendorId = respond.data.data[0].vendor_id;
			await this.OpenShipmentThisMonth();
		} else {
			const secretKey = 'some-unique-key';
			const simpleCrypto = new SimpleCrypto(secretKey);
			this.companyDash = simpleCrypto.decrypt(
				sessionStorage.getItem('dropdownCompany')
			);
			await this.OpenShipmentThisMonth();
		}
	},
	async mounted() {
		// CEK DULU TIPE USERNYA (purchasing/supplier/admin,dll)
		AuthService.whoAmi().then((resp) => {
			if (resp.status === 200) {
				this.getCompany();
				this.GetLOV();
				this.getDoNumber();
				if (resp.data.data.leveltenant == 2) {
					this.isSupplierPage = true;
				}
				this.isEmpty = 'Please Search for Data';
			}
		});

		// this.loading = true;
		this.lazyParams = {
			first: 0,
			sortField: null,
			sortOrder: null,
			rows: this.rows,
		};
		this.selectLazyParams = {
			first: 0,
			sortField: null,
			sortOrder: null,
			rows: this.rows,
		};
		if (sessionStorage.companydb) {
			this.testname = sessionStorage.companydb;
		}
	},
	methods: {
		async OpenShipmentThisMonth() {
			const secretKey = 'some-unique-key';
			const simpleCrypto = new SimpleCrypto(secretKey);
			const decipherText = simpleCrypto.decrypt(
				sessionStorage.getItem('dropdownCompany')
			);
			let payload = {
				vendorId: this.vendorId,
				company: decipherText,
			};
			console.log('companydash', this.companyDash);
			const res = await NotificationService.getShipmentTotal(payload);
			this.statusDraft = res.data.data[1][0].status_draft;
			this.statusSubmit = res.data.data[2][0].status_submit;
			this.statusClose = res.data.data[3][0].status_close;
		},
		openModal() {
			AuthService.whoAmi().then((resp) => {
				// if (resp.data.data.leveltenant == 1) {
				this.displayModal = true;
				// } else {
				// 	window.alert('Work in progress')
				// }
			});
		},
		formatCurrency(value) {
			if (value) return value.toLocaleString('id-ID');
			return;
		},
		async searchTableNested() {
			sessionStorage.setItem('mailToCompany', this.company.name);
			this.expandedRows = null;
			this.rows = 10;
			this.button = 1;
			this.loading = true;
			let supplierSearch = this.supplier;
			this.buttonDisable = true;
			if (this.supplier !== null) {
				supplierSearch = this.supplier.vendor_id;
			}
			let poSearch = this.doNumber;
			if (this.doNumber !== null) {
				poSearch = this.doNumber.SHIPMENT_NUMBER;
			}
			let statusSearch = this.lov;
			if (this.lov !== null) {
				statusSearch = this.lov.value;
			}
			let itemdescSearch = this.itemdesc;
			if (this.itemdesc !== null) {
				itemdescSearch = this.itemdesc.ITEM_DESC;
			}
			let org_id = this.company;
			let db_instance = '';
			if (this.company !== null) {
				// if (sessionStorage.leveltenant == 1) {
				// org_id = JSON.parse(this.company.value);
				// }
				// if (sessionStorage.leveltenant == 2) {
				org_id = this.company.value;
				db_instance = this.company.db_instance;
				// }
			}
			const payload = {
				supplierSearch,
				poSearch,
				statusSearch,
				itemdescSearch,
				org_id,
				db_instance,
			};
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					setTimeout(() => {
						ShipmentNotice.getSearchTableNested({
							lazyEvent: JSON.stringify(this.selectLazyParams),
							payload: JSON.stringify(payload),
						}).then((respond) => {
							if (respond.data.status == 200) {
								this.nested = respond.data.header;
								this.totalRecords = respond.data.totalRecords;
								this.loading = false;
								for (let id of this.nested) {
									for (let index of id.children) {
										index.shipdate = new Date(
											index.shipdate
										).toLocaleDateString('id-ID', {
											year: 'numeric',
											month: 'short',
											day: 'numeric',
										});
										index.expdate = new Date(
											index.expdate
										).toLocaleDateString('id-ID', {
											year: 'numeric',
											month: 'short',
											day: 'numeric',
										});
										if (index.expdate === '1 Jan 1970') {
											index.expdate = '';
										}
										if (index.shipdate === '1 Jan 1970') {
											index.shipdate = '';
										}
									}
								}
								this.buttonDisable = false;
							} else if (respond.data.status == 202) {
								this.nested = null;
								this.totalRecords = null;
								this.isEmpty = 'No Data Found';
								this.loading = false;
								this.buttonDisable = false;
							}
						});
					}, Math.random() * 1000 + 250);
				}
			});
		},
		getCompany() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					if (value.data.data.leveltenant == 1) {
						if (value.data.data.userid == 'dexaadmin') {
							LovService.getValueType('COMPANY').then(
								(response) => {
									this.isiCompany = response.data.data;
								}
							);
						} else {
							let payload = value.data.data.bioaddress;
							LovService.getcompanydb(payload).then(
								(response) => {
									this.isiCompany = response.data.data;
									try {
										const secretKey = 'some-unique-key';
										const simpleCrypto = new SimpleCrypto(
											secretKey
										);
										const decipherText =
											simpleCrypto.decrypt(
												sessionStorage.getItem(
													'dropdownCompany'
												)
											);

										this.isiCompany.forEach((element) => {
											if (element.name === decipherText) {
												this.company = element;
												// this.searchTableNested();
												throw 'Break';
											}
										});
									} catch (e) {
										if (e !== 'Break') throw e;
									}
									// })
								}
							);
						}
					} else if (value.data.data.leveltenant == 2) {
						let payload = value.data.data.id;
						ShipmentNotice.getCompanySupp(payload).then(
							(response) => {
								let payload = response.data.data;
								let companyList = [];
								try {
									payload.forEach((element) => {
										LovService.getCompanyByName(
											element.company
										).then((response) => {
											companyList.push(
												response.data.data[0]
											);

											const secretKey = 'some-unique-key';
											const simpleCrypto =
												new SimpleCrypto(secretKey);
											const decipherText =
												simpleCrypto.decrypt(
													sessionStorage.getItem(
														'dropdownCompany'
													)
												);
											// let companyList = JSON.parse(sessionStorage.getItem('list'))
											// this.isiCompany = companyList;

											if (
												response.data.data[0].name ===
												decipherText
											) {
												this.company =
													response.data.data[0];
												// this.searchTableNested();
												this.getValueSup();
											}
										});
									});
									this.isiCompany = companyList;
								} catch (e) {
									if (e !== 'Break') throw e;
								}
							}
						);
					}
				}
			});
		},
		getValueCompany() {
			let payload = {
				db_instance: this.company.db_instance,
				org_id: this.company.value,
			};
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					LovService.getSupplierName(payload).then((response) => {
						this.isisupplier = response.data.data;
						if (sessionStorage.getItem('leveltenant') == 2) {
							try {
								this.isisupplier.forEach((element) => {
									if (
										element.vendor_name ===
											this.suppBindToComp &&
										element.vendor_name !== null
									) {
										this.supplier = element;
										this.buttonDisable = false;
										// this.searchTableNested();
										throw 'Break';
									}
								});
							} catch (e) {
								if (e !== 'Break') throw e;
							}
						}
					});
				}
			});
		},
		async getPlenno() {
			const payload = { org_id: this.company.value };
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					ShipmentNotice.getSearchDropdownComp(payload).then(
						(response) => {
							this.isiDoNumber = response.data.data;
						}
					);
				}
			});
		},
		getValueSup() {
			this.buttonDisable = AuthService.whoAmi().then((value) => {
				if (value.data.data.leveltenant == 1) {
					const payload = {
						supValue: this.supplier.vendor_id,
						org_id: this.company.value,
					};
					ShipmentNotice.getSearchDropdownSupp(payload).then(
						(response) => {
							this.isiDoNumber = response.data.data;
						}
					);
				} else {
					const payload = {
						vendorName: this.poValue,
					};
					ShipmentNotice.getSuppValue(payload).then((response) => {
						this.suppName = response.data.data;

						const payloadSupp = {
							supValue:
								this.suppName &&
								this.suppName.length &&
								this.suppName[0].vendor_id,
							org_id: this.company && this.company.value,
						};
						ShipmentNotice.getSearchDropdownSupp(payloadSupp).then(
							(response) => {
								this.isiDoNumber = response.data.data;
							}
						);
					});
				}
			});
		},
		// getValuePoNum() {
		// 	const payload = { poValue: this.poValue };
		// 	AuthService.whoAmi().then((value) => {
		// 		if (value.status === 200) {
		// 			ShipmentNotice.getSearchDropdownPoNum(payload).then(
		// 				(response) => {
		// 					this.isisupplier = response.data.data;
		// 				}
		// 			);
		// 		}
		// 	});
		// },
		GetLOV() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					ShipmentNotice.GetLovStatus().then((response) => {
						this.isilov = response.data.data;
					});
				}
			});
		},
		Getitemdesc() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					ShipmentNotice.Getitemdesc().then((response) => {
						this.isiItemDesc = response.data.data;
					});
				}
			});
		},
		getDoNumber() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					ShipmentNotice.getDoNumber().then((response) => {
						this.isiDoNumber = response.data.data;
					});
				}
			});
		},
		searchSupplier(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredSupplier = [...this.isisupplier];
				} else {
					this.filteredSupplier = this.isisupplier.filter((item) => {
						if (item.vendor_name !== null) {
							return item.vendor_name
								.toLowerCase()
								.match(event.query.toLowerCase());
						}
					});
				}
			}, 250);
		},
		async searchCompany(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredCompany = [...this.isiCompany];
				} else {
					this.filteredCompany = this.isiCompany.filter((item) => {
						return item.name
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		searchDoNumber(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredDoNumber = [...this.isiDoNumber];
				} else {
					this.filteredDoNumber = this.isiDoNumber.filter((item) => {
						if (item.vendor_name !== null) {
							return item.SHIPMENT_NUMBER.toLowerCase().match(
								event.query.toLowerCase()
							);
						}
					});
				}
			}, 250);
		},
		searchLov(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredLov = [...this.isilov];
				} else {
					this.filteredLov = this.isilov.filter((item) => {
						return item.name
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		searchitemdesc(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredItemDesc = [...this.isiItemDesc];
				} else {
					this.filteredItemDesc = this.isiItemDesc.filter((item) => {
						return item.ITEM_DESC.toLowerCase().match(
							event.query.toLowerCase()
						);
					});
				}
			}, 250);
		},
		onSort(event) {
			switch (true) {
				case this.supplier === null &&
					this.doNumber === null &&
					this.lov === null &&
					this.itemdesc === null:
					this.lazyParams = event;
					// this.GetTableNested();
					break;
				case this.company !== null ||
					this.supplier !== null ||
					this.doNumber !== null ||
					this.lov !== null ||
					(this.itemdesc !== null && this.button === 1):
					this.selectLazyParams = event;
					this.searchTableNested();
					break;
			}
		},
		editChildren(rowIndex) {
			this.rowId = rowIndex;
			if (sessionStorage.leveltenant == 2) {
				this.$router.push({
					name: 'AddCreateShipmentNotice',
					params: { id: this.rowId.id },
				});
			} else {
				this.$router.push({
					name: 'AddCreateShipmentNotice',
					params: { id: this.rowId.id },
				});
			}
		},
		expandAll() {
			this.expandedRowGroups = this.rowgroup.filter(
				(p) => p.plan_order_num
			);
			this.expandedRows = this.nested.filter((p) => p.po_num);
			this.$toast.add({
				severity: 'success',
				summary: 'All Rows Expanded',
				life: 3000,
			});
		},
		onPage(event) {
			switch (true) {
				//nggak cari apa-apa
				case this.supplier === null &&
					this.doNumber === null &&
					this.lov === null &&
					this.itemdesc === null &&
					this.button === 0:
					this.lazyParams = event;
					// this.GetTableNested();
					break;
				//semuanya
				case this.company !== null ||
					this.supplier !== null ||
					this.doNumber !== null ||
					this.lov !== null ||
					(this.itemdesc !== null && this.button === 1):
					this.selectLazyParams = event;
					this.searchTableNested();
					break;
			}
		},
		clearFilter() {
			this.nested = null;
			this.buttonSearch = true;
			this.expandedRows = null;
			this.doNumber = null;
			if (sessionStorage.leveltenant == 2) {
			} else {
				this.supplier = null;
				if (sessionStorage.idUser == 2) {
				}
			}
			this.lov = null;
			this.itemdesc = null;
			this.Getitemdesc();
			this.GetLOV();
			this.getDoNumber();
		},
	},
	computed: {
		searchUser() {
			const searchLc = this.search.toLowerCase();
			return this.nested.filter((item) => {
				return item.po_num.toLowerCase().includes(searchLc);
			});
		},
		notificationModulesName() {
			const modulesName = this.notifications.map(
				(notif) => notif.modulename
			);

			const modulesSet = new Set();

			modulesName.forEach((name) => modulesSet.add(name));

			return modulesSet;
		},
		// Open
		notificationOpenByPlanningOrder() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Planning Order' &&
					notif.status === 'Open'
			);
			return notifs.length;
		},
		notificationOpenByPurchaseOrder() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Purchase Order' &&
					notif.status === 'Open'
			);
			return notifs.length;
		},
		notificationOpenByShipmentNotice() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Shipment Notice' &&
					notif.status === 'Open'
			);
			return notifs.length;
		},
		notificationOpenByCAPA() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'CAPA' && notif.status === 'Open'
			);
			return notifs.length;
		},
		notificationOpenBySupplierMaster() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Supplier Master' &&
					notif.status === 'Open'
			);
			return notifs.length;
		},
		// Closed
		notificationClosedByPlanningOrder() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Planning Order' &&
					notif.status === 'Closed'
			);
			return notifs.length;
		},
		notificationClosedByPurchaseOrder() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Purchase Order' &&
					notif.status === 'Closed'
			);
			return notifs.length;
		},
		notificationClosedByShipmentNotice() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Shipment Notice' &&
					notif.status === 'Closed'
			);
			return notifs.length;
		},
		notificationClosedByCAPA() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'CAPA' && notif.status === 'Closed'
			);
			return notifs.length;
		},
		notificationClosedBySupplierMaster() {
			const notifs = this.notifications.filter(
				(notif) =>
					notif.modulename === 'Supplier Master' &&
					notif.status === 'Closed'
			);
			return notifs.length;
		},
	},
};
</script>
