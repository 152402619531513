<template>
    <!-- eslint-disable -->
    <div class="grid">
        <div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
        <div class="col-12">
            <div class="card">
                <div class="surface-section">
                    <div class="font-medium text-2xl text-900 mb-3">Notification Detail</div>

                    <ul class="list-none p-0 m-0">
                        <li class="flex align-items-center py-3 px-2 border-top-0 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Module</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{modulename}}</div>
                            <div class="w-6 md:w-2 flex justify-content-end">

                            </div>
                        </li>
                        <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Sender</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{sender}}
                            </div>
                            <div class="w-6 md:w-2 flex justify-content-end">

                            </div>
                        </li>
                        <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Date</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{receivedDate}}</div>
                            <div class="w-6 md:w-2 flex justify-content-end">

                            </div>
                        </li>
                        <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Recipient</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                {{emailRecipient}}
                            </div>
                            <div class="w-6 md:w-2 flex justify-content-end">

                            </div>
                        </li>
                        <li
                            class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Subject</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                {{subject}}</div>
                            <div class="w-6 md:w-2 flex justify-content-end">

                            </div>
                        </li>
                        <li
                            class="flex align-items-center py-3 px-2 border-top-0 border-bottom-0 surface-border flex-wrap">
                            <div class="text-500 w-6 font-medium">Message</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                <br />Dear Purchasing Team,<br /> We have confirmed your Purchase Order with details as
                                follow:</div>
                            <div class="w-6 md:w-2 flex justify-content-end">

                            </div>
                        </li>
                        <li
                            class="flex justify-content py-3 px-2 border-top-0 border-bottom-0 surface-border flex-wrap">
                            <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1 line-height-3">
                                <strong>PO Number :</strong> {{poNum}}<br />
                                <strong>Buyer :</strong> {{buyer}}<br />
                                <strong>Currency :</strong> {{currency}}</div>
                            <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1 line-height-3">
                                <strong>Rev :</strong> {{rev}}<br />
                                <strong>Ship To :</strong> {{shipTo}}<br />
                                <strong>Confirm Date :</strong> {{poDate}}</div>
                        </li>
                        <br />
                        <DataTable :value="notifications" v-model:selection="selectedNotifications" dataKey="id"
                            :paginator="false" :rows="5" v-model:filters="filters"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            responsiveLayout="scroll">
                            <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field"
                                :sortable="false" />
                        </DataTable>
                        <li
                            class="flex align-items-center py-3 px-2 border-top-0 border-bottom-0 surface-border flex-wrap">
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                <br />For detailed information of this Purchase Order, please visit <router-link :to="'/mgm/settings/sysparamslist/' + poHeaderId" class="text-blue-500">here</router-link>.<br /><br />
                                Regards,<br />
                                Supplier Team</div>
                            <div class="w-6 md:w-2 flex justify-content-end">
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <router-link to="/dashboard"><Button label="Return" class="mr-2 mb-2"/></router-link>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import NotificationService from '../../../service/NotificationService';
export default {
 data() {
     return {
         breadcrumbHome: {icon: 'pi pi-home', to: '/dashboard'},
		 breadcrumbItems: [
		 	{label:'Dashboard', to: '/dashboard'},
		 	{label:'Notification Detail'}
		 ],
         modulename: this.$route.params.nameModule,
         poNum: null,
         vendorname: null,
         sender: null,
         buyer: null,
         currency: null,
         shipTo: null,
         rev: null,
         poDate: null,
         poHeaderId: null,
         emailRecipient: null,
         subject: this.$route.params.subjectMessage,
         receivedDate: null,
         columns: null,
         notifications: null,
		 notification: {},
		 selectedNotifications: null,
       }
    },

	async created() {
        var getPoNum = this.subject.substring(0,this.subject.indexOf('has been confirmed by'));
	    this.poNum = getPoNum.replace('Purchase Order #', '').replace(/ /g,'')
	    this.vendorname = this.poNum

	    this.columns = [{
	            field: 'LINE_NUM',
	            header: 'Line'
	        },
	        {
	            field: 'ITEM_NUMBER',
	            header: 'Item Number'
	        },
	        {
	            field: 'ITEM_DESCRIPTION',
	            header: 'Item Description'
	        },
	        {
	            field: 'UOM',
	            header: 'UOM'
	        },
	        {
	            field: 'UNIT_PRICE',
	            header: 'Unit Price'
	        },
	        {
	            field: 'QUANTITY',
	            header: 'Quantity'
	        },
	        {
	            field: 'NOTE_TO_VENDOR',
	            header: 'Note to Supplier'
	        },
	        {
	            field: 'NEED_BY_DATE',
	            header: 'Arrival at Site\n(based on incoterm)'
	        },
	        {
	            field: '',
	            header: 'Est. Goods Readyness'
	        },
	        {
	            field: '',
	            header: 'Est. Delivery'
	        },
            {
	            field: 'NEED_BY_DATE',
	            header: 'Est. Arrival at Site (Based on Incoterm)'
	        },
	        {
	            field: 'REMARKS',
	            header: 'Reason for not on time'
	        },
	    ]

	},
	async mounted() {
	    const resp = await NotificationService.getNotifDetailPlannoSender({
	        something: JSON.stringify(this.$route.params.subjectMessage)
	    })
	    this.sender = resp.data.data[0][0].sender
	    this.emailRecipient = resp.data.data[0][0].recipient
	    this.receivedDate = resp.data.data[0][0].receiveddate;
	    this.poDate = (resp.data.data[0][0].receiveddate).substring(0, 11);
	    if (resp.status == 200) {
	        const response = await NotificationService.getNotifDetailPurchaseOrder(this.poNum)
	        if (response.status == 200) {
	            let dataload = response.data.data[0]
	            if (dataload.length < 1) {
	                alert('Corrupted Data.' + '\n' + 'Redirecting to Dashboard...')
	                this.$router.replace('/dashboard')
	            }
	            for (const i in dataload) {
	                let convertDate = new Date(dataload[i]["NEED_BY_DATE"])
	                dataload[i]["NEED_BY_DATE"] = (convertDate.getDate() + '-' + convertDate.toString().substring(4, 7) + '-' + convertDate.getFullYear()).toUpperCase()
	                dataload[i]["QUANTITY"] = dataload[i]["QUANTITY"].toLocaleString('id-ID')
	                dataload[i]["UNIT_PRICE"] = dataload[i]["UNIT_PRICE"].toLocaleString('id-ID')
	                if (dataload[i]["NOTE_TO_VENDOR"] === null || (dataload[i]["NOTE_TO_VENDOR"]).includes('null')) {
	                    dataload[i]["NOTE_TO_VENDOR"] = ''
	                }
	            }
	            // let getDistinctLine = null
	            // let newSetArray = []
	            // dataload.forEach((element) => {
	            //     if (getDistinctLine !== element.LINE_NUM) {
	            //         newSetArray.push(element)
	            //         getDistinctLine = element.LINE_NUM
	            //     }
	            // })
	            // this.notifications = [...new Set(newSetArray)]
	            this.notifications = dataload;
	            this.buyer = dataload[0]['BUYER_NAME']
	            this.poHeaderId = dataload[0]['PO_HEADER_ID']
	            this.currency = dataload[0]['CURRENCY_CODE']
	            this.shipTo = dataload[0]['SHIP_TO_NAME']
	            this.rev = dataload[0]['REVISION_NUM']
	        } else {
	            this.notifications = [];
	        }
	    }

	},
}
</script>
<style scoped>

</style>