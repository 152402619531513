export const formatDate = (date) => {

	if(date){
		return new Intl.DateTimeFormat('id-ID', {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		}).format(new Date(date));
	}
	
	return "-"
};