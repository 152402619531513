<template>
	<AutoComplete
		:suggestions="filteredCompanies"
		@complete="searchCompany($event)"
		:dropdown="true"
		field="name"
		placeholder="Select a company"
		forceSelection
	>
		<template #item="slotProp">
			{{ slotProp.item.name }}
		</template>
	</AutoComplete>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import LovService from '@/service/LovService';
import SwithCompany from '@/service/SwithCompany';
import AuthService from '@/service/AuthService';
import SimpleCrypto from 'simple-crypto-js';

// eslint-disable-next-line no-undef
const filteredCompanies = ref([]);
const companies = ref([]);

const userCompany = () => {
	const secretKey = 'some-unique-key';
	const simpleCrypto = new SimpleCrypto(secretKey);
	return simpleCrypto.decrypt(sessionStorage.getItem('dropdownCompany'));
};

onMounted(async () => {
	try {
		const response = await AuthService.whoAmi();
		const user = response.data.data;

		if (user.leveltenant === 1 || user.leveltenant === 3) {
			if (user.userid === 'dexaadmin') {
				const tmp = await LovService.getValueType('COMPANY');
				companies.value = tmp.data.data;
			} else if (user.bioaddress === null) {
				const company = await LovService.getCompanyByName(
					userCompany()
				);

				const response = await LovService.getcompanydb(
					company.data.data[0].db_instance
				);
				companies.value = response.data.data;
			} else {
				const response = await LovService.getcompanydb(user.bioaddress);
				companies.value = response.data.data;
			}
		} else if (user.leveltenant === 2) {
			const userId = user.id;
			const response = await SwithCompany.getCompanyPayload(userId);
			if (response.status === 200) {
				const companyList = response.data.data;
				for (const c of companyList) {
					const resp = await LovService.getCompanyByName(c.company);
					const element = resp.data.data[0];
					companies.value.push(element);
				}
			}
		}
		// eslint-disable-next-line no-empty
	} catch (e) {}
});

const searchCompany = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredCompanies.value = [...companies.value];
		} else {
			filteredCompanies.value = companies.value.filter((comp) => {
				return comp.name
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
		}
	}, 250);
};
</script>
