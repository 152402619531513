<template>
	<!-- eslint-disable -->
	<Toast />
	<div class="card pt-6">

	<div class="col-6">
		<p id="label-key">Attach</p>
	<FileUpload ref="fileUploadDebit" mode="advanced" chooseLabel="Upload File" fileLimit="1" :maxFileSize="5000000"
		:showUploadButton="false" :showCancelButton="false" :customUpload="true" @select="onSelectFile"
		@remove="onRemoveFile">
		<template #empty>
			<p>No file selected. (Max file 5MB)</p>
		</template>
	</FileUpload>
	<Button label="Submit" @click="excelToTable"/>
	</div>

	<div>
		<DataTable :value="jitValue" responsiveLayout="scroll">
			<Column header="Item Material" field="item material"> </Column>
			<Column header="Material Description" field="material description"> </Column>
			<Column header="Item Product" field="item product"> </Column>
			<Column header="Product Description" field="product description"> </Column>
			<Column header="Batch No" field="batch no"> </Column>
			<Column header="Qty WO" field="qty wo"> </Column>
			<Column header="Total Reservation Qty" field="total reservation qty"> </Column>
			<Column header="Total On Hand Qty" field="total on hand qty"> </Column>
			<Column header="On Hand Locator Ret" field="on hand locator ret"> </Column>
			<Column header="Jumlah Pesanan" field="jumlah pesanan"> </Column>
			<Column header="Tanggal Deliver" field="tanggal deliver"> </Column>
			<Column header="Remark" field="remark"> </Column>

		</DataTable>
	</div>
	</div>
</template>

<script setup>
import { ref} from "vue";
import XLSX from "xlsx";

const file = ref(null);
let jitValue = ref([]);
const jitTotal = ref();


// const breadcrumbHome = { icon: "pi pi-home", to: "/dashboard" };
// const breadcrumbItems = [
//   {
//     label: "Nota Debit",
//     to: "/mgm/monitor/eventlog",
//   },
// ];

// Select file attachment
const onSelectFile = (e) => {
file.value = e.files[0];

};

const excelToTable = () => {
	var selectedFile = file.value;
	var reader = new FileReader();
	reader.onload = function (event) {
		var data = event.target.result;
		var workbook = XLSX.read(data, {
			type: 'binary'
		});
		workbook.SheetNames.forEach(function (sheetName) {

			var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
			// var json_object = JSON.stringify(XL_row_object);
			const isObject = obj =>
				Object.prototype.toString.call(obj) === "[object Object]"

			const lowerCaseObjectKeys = obj =>
				Object.fromEntries(Object.entries(obj).map(objectKeyMapper))

			// Converts keys to lowercase, detects object values
			// and sends them off for further conversion
			const objectKeyMapper = ([key, val]) =>
				([
					key.toLowerCase(),
					isObject(val) ?
					lowerCaseObjectKeys(val) :
					val
				])
			const newObj = lowerCaseObjectKeys(XL_row_object)
			jitValue.value = newObj;
			jitTotal.value = Object.keys(jitValue).length;
		})
	};

	reader.onerror = function (event) {
		console.error("File could not be read! Code " + event.target.error.code);
	};

	reader.readAsBinaryString(selectedFile);
}

</script>

<style scoped>
#label-key {
font-family: "Roboto";
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 36px;
/* identical to box height, or 257% */

color: #000000;
}

.form-card {
padding: 10px;
  border: 1px solid #e0e0e0;
border-radius: 16px;
}

</style>