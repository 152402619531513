import spbKkt from "./api/SpbKktApi";
class ForgotPasswordService {

    async changePassword(data) {
        return spbKkt.post('adm/send/changepassword', data);
    }
    
    async forgotPass(data) {
        return spbKkt.post('adm/send/forgotpassword', data);
    }
    async resetPass(payload) {
        return spbKkt.post(`adm/send/resetpassword/${payload.id}`, payload);
    }

}
export default new ForgotPasswordService();

