<template>
	<!-- eslint-disable -->
	<DataTable
		id="detailTableListInvoiceProxyNonPO"
		:value="tableList"
		:paginator="true"
		:rows="10"
		:loading="loading"
		:rowsPerPageOptions="[10, 20, 30]"
		responsiveLayout="scroll"
		:scrollable="true"
		scrollHeight="600px"
		scrollDirection="both"
		@page="onPage($event)"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #empty>No Invoice found</template>
		<template #loading>Loading data</template>
		<Column header="Description" style="width: 20%">
			<template #body="slotProps">
				<InputText
					id="inputtext"
					type="text"
					v-model="slotProps.data.ITEM_DESCRIPTION"
					:class="{
						'p-invalid':
							tableList[slotProps.index + page].VALIDATE_ITEM,
					}"
					:disabled="!props.forwarder || !props.disabled"
				/>
				<div v-if="tableList[slotProps.index + page].VALIDATE_ITEM">
					<small class="p-error">{{
						'Item Description is empty'
					}}</small>
				</div>
			</template>
		</Column>
		<Column header="Amount" style="width: 20%">
			<template #body="slotProps">
				<InputNumber
					autocomplete="off"
					id="locale-german"
					v-model="slotProps.data.AMOUNT"
					:minFractionDigits="2"
					locale="de-DE"
					:disabled="!props.disabled"
					:min="0"
					@input="onChangeText($event, slotProps.index + page)"
					:class="{
						'p-invalid': tableList[slotProps.index + page].RESPONSE,
					}"
				/>
				<div v-if="tableList[slotProps.index + page].RESPONSE">
					<small class="p-error">{{ 'Amount is empty' }}</small>
				</div>
			</template>
		</Column>
		<Column header="VAT" style="width: 20%">
			<template #body="slotProps">
				<Dropdown
					:editable="true"
					v-model="slotProps.data.VAT_CODE"
					@change="onChange($event, slotProps.index + page)"
					:options="getVAT"
					optionLabel="VAT_CODE"
					optionValue="TAX_RATE_ID"
					:disabled="!props.disabled"
				/>
				<!-- <div v-if="tableList[slotProps.index + page].RESPONSE_VAT">
					<small class="p-error">{{ 'VAT is empty' }}</small>
				</div> -->
			</template>
		</Column>
		<Column header="WHT" style="width: 20%">
			<template #body="slotProps">
				<Dropdown
					:editable="true"
					v-model="slotProps.data.WHT_CODE"
					:options="whtRates"
					optionLabel="WHT_CODE"
					optionValue="WHT_CODE_ID"
					style="width: 100%"
					:disabled="!props.disabled"
					@change="onChangeWHT($event, slotProps.index + page)"
				/>
			</template>
		</Column>
		<Column header="Account" style="width: 20%">
			<template #body="slotProps">
				<AutoComplete
					v-model="slotProps.data.ACCOUNT"
					field="account"
					:suggestions="filteredAccount"
					:dropdown="true"
					@complete="searchAccount($event)"
					@item-select="getAccountId($event, slotProps.index + page)"
					:disabled="!props.disabled || !accountData"
					forceSelection
					:class="{
						'p-invalid':
							tableList[slotProps.index + page].ACCOUNT_VALIDATE,
					}"
				/>
				<div v-if="tableList[slotProps.index + page].ACCOUNT_VALIDATE">
					<small class="p-error">{{ 'Account is empty' }}</small>
				</div>
			</template>
		</Column>
		<Column header="Ref Number" style="width: 20%">
			<template #body="slotProps">
				<div class="grid formgrid">
					<div class="col-10 mb-0 lg:col-10 lg:mb-0">
						<InputText
							id="inputtext"
							type="text"
							v-model="slotProps.data.REMARKS"
							disabled
						/>
					</div>
					<div class="col-1 mb-0 lg:col-1 lg:mb-0">
						<Button
							icon="pi pi-search"
							class="p-button-rounded p-button-text"
							@click="refPopup(slotProps.index + page)"
							v-tooltip="'Ref Number'"
							:disabled="!props.disabled"
						/>
					</div>
					
				</div>
			</template>
		</Column>

		<Column style="width: 10%"> 
				<template #body="slotProps">
					<div class="col-1 mb-0 lg:col-1 lg:mb-0">
						<Button
							icon="pi pi-trash"
							class="p-button-rounded p-button-text"
							@click="
								deleteLine(
									slotProps.data,
									slotProps.index + page
								)
							"
							v-tooltip="'Delete'"
							:disabled="!props.disabled"
						/>
					</div>
			</template>
		</Column>

	</DataTable>
	<Dialog
		header="Ref Number"
		v-model:visible="displayModal"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '50vw' }"
		:modal="true"
	>
		<div class="col-12 p-fluid mb-4">
			<DataTable
				:value="filtered ? filtered : tableListPopUp"
				responsiveLayout="scroll"
				selectionMode="single"
				:paginator="true"
				:metaKeySelection="false"
				:rows="5"
				:rowsPerPageOptions="[5, 10, 20, 50]"
				:loading="!tableListPopUp"
				paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
				currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
				dataKey="id"
				@rowSelect="onRowSelect"
			>
				<template #header>
					<div>
						<span class="p-input-icon-left" style="col-12">
							<i class="pi pi-search" />
							<InputText
								v-model="invoiceFilter"
								placeholder="Search"
								@keydown.enter="keydown()"
							/>
						</span>
					</div>
				</template>
				<template #empty>No Invoice found</template>
				<template #loading>Loading data</template>
				<Column header="No Travel Order" field="travel_order_no">
				</Column>
				<Column header="Name" field="guest_name"> </Column>
				<Column header="Start Date" field="start_date">
					<!-- <template #body="slotProps">
              {{
              new Date(slotProps.data.start_date).toLocaleDateString('id-ID', { day: 'numeric', month: 'short', year: 'numeric'}) }}
            </template> -->
				</Column>
				<Column header="End Date" field="end_date">
					<!-- <template #body="slotProps">
              {{
              new Date(slotProps.data.end_date).toLocaleDateString('id-ID', { day: 'numeric', month: 'short', year: 'numeric'}) }}
            </template> -->
				</Column>
			</DataTable>
		</div>
		<template #footer>
			<Button
				label="No"
				icon="pi pi-times"
				@click="closeModal"
				class="p-button-text"
			/>
			<Button
				label="Yes"
				icon="pi pi-check"
				@click="closeModal"
				autofocus
			/>
		</template>
	</Dialog>

	<Dialog
		header="Delete Reimburse"
		v-model:visible="deleteLineValue"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '50vw' }"
		:modal="true"
	>
		<div class="confirmation-content">
			<i
				class="pi pi-exclamation-triangle mr-3"
				style="font-size: 2rem"
			/>
			<span v-if="tableLineValue"
				>Are you sure you want to delete Line for
				<b>{{ tableLineValue.ITEM_DESCRIPTION }}</b> from
				Database?</span
			>
		</div>
		<template #footer>
			<Button
				label="No"
				icon="pi pi-times"
				class="p-button-text"
				@click="deleteLineValue = false"
			/>
			<Button
				label="Yes"
				icon="pi pi-check"
				class="p-button-text"
				@click="deleteTableLine"
			/>
		</template>
	</Dialog>
</template>

<script setup>
import { onMounted, ref, defineEmits, watch, computed } from 'vue';
import AuthService from '@/service/AuthService';
import PoFulfillment from '@/service/PoFulfillment';
import LovService from '@/service/LovService';
import { useToast } from 'primevue/usetoast';
import SearchInvoiceProxyNonPO from '../../../service/InvoiceProxyService';

const company = ref();
const supplier = ref();
// const idValue = ref()
const tableList = ref();
const reloadCat = ref(0);
const page = ref(0);
const line = ref({});
const emit = defineEmits(['tableList', 'onDeleteReimburse']);
const displayModal = ref(false);
const getVAT = ref();
const getVATBackup = ref(null);
const tableListPopUp = ref();
const invoiceFilter = ref();
// const filteredVAT = ref();

onMounted(async () => {
	company.value = props.company;
	//
	const info = await AuthService.whoAmi();
	if (info.status === 200) {
		levelTenant.value = info.data.data.leveltenant;
		if (levelTenant.value !== 2) {
			loading.value = true;
			supplier.value = props.supplier;
			//   loading.value = true
			await populateTable();
		} else {
			const param = {
				db_instance: props.company.db_instance,
				org_id: props.company.value,
			};
			const respVendor = await PoFulfillment.getSuppBindComp(
				info.data.data.id
			);
			const vendorName = respVendor.data.data[0][0].vendorname;
			const respSuppName = await LovService.getSupplierName(param);
			if (respSuppName.status === 200) {
				const suppList = respSuppName.data.data;
				for (const s of suppList) {
					if (s.vendor_name === vendorName) {
						supplier.value = s;
						break;
					}
				}
			}
			await populateTable();
			// loading.value = true
		}
	}
});
// eslint-disable-next-line no-undef
const props = defineProps({
	company: {
		type: Object,
	},
	data: {
		type: Object,
	},
	line: {
		type: Object,
	},
	vendor_id: {
		type: Object,
	},
	org_id: {
		type: Object,
	},
	db_instance: {
		type: Object,
	},
	disabled: {
		type: Boolean,
	},
	detailCat: {
		type: Object,
	},
	vendor_name: {
		type: Object,
	},
	invoice_date: {
		type: Date,
	},
	submit: {
		type: Boolean,
	},
	tableVuelidate: {
		type: Object,
	},
	cost_center: {
		type: Object,
	},
	forwarder: {
		type: Object,
	},
});

const toast = useToast();
const loading = ref(0);
const levelTenant = ref();
const payload = ref();
const deleteLineValue = ref();
const tableLineValue = ref();
const valuePopUP = ref();
const filtered = ref();
// const listAccount = ref();
const accountData = ref(null);
const filteredAccount = ref();
const whtRates = ref();
// const index = ref()

watch(
	() => props.line,
	(newValue) => {
		//
		if (newValue > 0) {
			addLine();
		}
	}
);

watch(
	() => props.invoice_date,
	(newValue) => {
		//
		if (newValue) {
			getDropdownVat();
		}
	}
);

watch(
	() => props.cost_center,
	() => {
		if (props.cost_center !== null) {
			getAccount();
		}
	}
);

watch(
	() => props.tableVuelidate,
	(newValue) => {
		console.log(props.tableVuelidate, "VUELIDATE")
		for (const i in tableList.value) {
			if (props.tableVuelidate !== null) {
				//
				if (newValue[0]['EINVOICE_REIMBURSMENT_ID']) {
					tableList.value[i].RESPONSE = false;
				} else {
					if (newValue[i].AMOUNT[0] !== undefined) {
						tableList.value[i].RESPONSE = true;
					}
					
					if (newValue[i].AMOUNT[0] == undefined) {
						tableList.value[i].RESPONSE = false;
					}
					// console.log(newValue[i].VAT_CODE[0], "VAT")
					if(newValue[i].VAT_CODE[0] !== undefined){
						tableList.value[i].RESPONSE_VAT = true;
					}

					if(newValue[i].VAT_CODE[0] === undefined){
						tableList.value[i].RESPONSE_VAT = false;
					}
				}
			} else {
				tableList.value[i].RESPONSE = false;
			}
		}
	}
);

watch(
	() => props.tableVuelidate,
	(newValue) => {
		for (const i in tableList.value) {
			if (props.tableVuelidate !== null) {
				//
				//
				if (newValue[0]['EINVOICE_REIMBURSMENT_ID']) {
					tableList.value[i].VALIDATE_ITEM = false;
				} else {
					//
					if (newValue[i].ITEM_DESCRIPTION[0] !== undefined) {
						tableList.value[i].VALIDATE_ITEM = true;
					} else if (newValue[i].ITEM_DESCRIPTION[0] == undefined) {
						tableList.value[i].VALIDATE_ITEM = false;
					}
				}
			} else {
				tableList.value[i].VALIDATE_ITEM = false;
			}
		}
	}
);

watch(
	() => props.tableVuelidate,
	(newValue) => {
		for (const i in tableList.value) {
			if (props.tableVuelidate !== null) {
				//
				//
				if (newValue[0]['EINVOICE_REIMBURSMENT_ID']) {
					tableList.value[i].ACCOUNT_VALIDATE = false;
				} else {
					//
					if (newValue[i].ACCOUNT[0] !== undefined) {
						tableList.value[i].ACCOUNT_VALIDATE = true;
					} else if (newValue[i].ACCOUNT[0] == undefined) {
						tableList.value[i].ACCOUNT_VALIDATE = false;
					}
				}
			} else {
				tableList.value[i].ACCOUNT_VALIDATE = false;
			}
		}
	}
);

watch(
	() => props.submit,
	(newValue) => {
		console.log(tableList.value, "TESTER")
		if (newValue !== null) {
			for (const i in tableList.value) {
				tableList.value[i].RESPONSE = false;
				tableList.value[i].VALIDATE_ITEM = false;
			}
		}
	}
);

const populateTable = async () => {
	//
	loading.value = true;
	if (props.data !== null) {
		//
		payload.value = {
			id: props.data ? props.data : null,
		};
		const data = await SearchInvoiceProxyNonPO.getDetailDataTable(
			payload.value
		);
		tableList.value = data.data.data.data;
		await tableList.value.forEach((element) => {
			element.RESPONSE = false;
			element.VALIDATE_ITEM = false;
			element.ACCOUNT_VALIDATE = false;
		});
		//
		await getDropdownVat();
		await getAccount();
		await whtDropdown();
		emit('tableList', tableList);
	}
	loading.value = false;
};

const populateTablePopUp = async () => {
	loading.value = true;
	if (props.supplier !== null) {
		const payload = {
			supplierName: props.vendor_name,
		};
		//
		const data = await SearchInvoiceProxyNonPO.getDetailDataTravelOrder(
			payload
		);
		await data.data.data.forEach((element) => {
			element.start_date = new Date(
				element.start_date
			).toLocaleDateString('en-US', {
				day: 'numeric',
				month: 'short',
				year: 'numeric',
			});
			element.end_date = new Date(element.end_date).toLocaleDateString(
				'en-US',
				{ day: 'numeric', month: 'short', year: 'numeric' }
			);
		});
		//
		tableListPopUp.value = data.data.data;
	}
	loading.value = false;
};

const onPage = async (event) => {
	//
	page.value = event.page * event.rows;
	//
};

const addLine = async () => {
	if (props.disabled) {
		const data = await SearchInvoiceProxyNonPO.getListDetailCat(payload);
		if (data.status === 200) {
			let index;
			if (tableList.value.length === 0) {
				index = 1;
			} else {
				index = tableList.value.length - 1;
			}
			line.value.EINVOICE_LINE_ID = data.data.data.data[0].ID + index;
			line.value.ITEM_DESCRIPTION = props.detailCat.category_detail;
			line.value.CATEGORY_DETAIL_ID = props.detailCat.category_detail_id;
			line.value.COA_ID = props.detailCat.account_id;
			line.value.ACCOUNT = tableList.value[tableList.value.length]
				? tableList.value[tableList.value.length].ACCOUNT
				: null;
			line.value.AMOUNT = null;
			line.value.PERCENTAGE_RATE = null;
			line.value.ISNEW = true;
			let add = true;
			tableList.value.forEach((element) => {
				if (
					element.ITEM_DESCRIPTION === line.value.ITEM_DESCRIPTION &&
					!props.forwarder
				) {
					toast.add({
						severity: 'error',
						summary: 'Cannot Add Line',
						detail: 'Category is already in Table',
						life: 3000,
					});
					throw add;
				} else {
					add = false;
				}
			});
			if (add && tableList.value.length) {
				toast.add({
					severity: 'error',
					summary: 'Cannot Add Line',
					detail: 'Category is already in Table',
					life: 3000,
				});
			} else {
				tableList.value.push(line.value);
				//
				toast.add({
					severity: 'success',
					summary: 'Line Create',
					detail: 'Line Successfully Created',
					life: 3000,
				});
				line.value = {};
				emit('tableList', tableList);
			}

			//
		} else {
			toast.add({
				severity: 'error',
				summary: 'Something Wrong',
				detail: 'Disconnected',
				life: 3000,
			});
		}
	} else {
		toast.add({
			severity: 'error',
			summary: 'Disabled',
			detail: "Can't add line",
			life: 3000,
		});
	}
};

const deleteLine = async (rowIndex, index) => {
	const payload = {
		idheader: parseInt(props.data),
		iddetail: rowIndex.EINVOICE_LINE_ID,
		value: false,
	};
	const data = await SearchInvoiceProxyNonPO.deleteLine(payload);
	if (data.data.data.data.length > 0) {
		tableLineValue.value = rowIndex;
		deleteLineValue.value = true;
	} else {
		tableList.value = tableList.value.filter((val, idx) => idx !== index);
		toast.add({
			severity: 'error',
			summary: 'Line Delete',
			detail: 'Line Successfully Deleted',
			life: 3000,
		});
		emit('tableList', tableList);

		// Delete reimburse table if deleted category is 'REIMBURSTMENT'
		if (rowIndex.ITEM_DESCRIPTION.includes('REIMBURSTMENT')) {
			emit('onDeleteReimburse', { value: false });
		}
	}
};

const deleteTableLine = async () => {
	const payload = {
		idheader: parseInt(props.data),
		iddetail: tableLineValue.value.EINVOICE_LINE_ID,
		value: true,
	};
	const data = await SearchInvoiceProxyNonPO.deleteLine(payload);
	if (data.status === 200) {
		deleteLineValue.value = false;
		tableList.value = tableList.value.filter(
			(val) =>
				val.EINVOICE_LINE_ID !== tableLineValue.value.EINVOICE_LINE_ID
		);
		toast.add({
			severity: 'error',
			summary: 'Line Delete',
			detail: 'Line Successfully Deleted',
			life: 3000,
		});
		emit('tableList', tableList);
		reloadCat.value++;
		emit('reloadDetailCat', reloadCat);
		//
		// Delete reimburse table if deleted category is 'REIMBURSTMENT'
		if (tableLineValue.value.ITEM_DESCRIPTION.includes('REIMBURSTMENT')) {
			emit('onDeleteReimburse', { value: true });
		}
	}
};

const refPopup = async (rowIndex) => {
	//
	valuePopUP.value = rowIndex;
	displayModal.value = true;
	populateTablePopUp();
};

const closeModal = async () => {
	displayModal.value = false;
};

const getDropdownVat = async () => {
	const payload = {
		db_instance: props.db_instance,
		invoice_date: props.invoice_date,
	};
	const data = await SearchInvoiceProxyNonPO.getVAT(payload);
	getVAT.value = data.data.data.data;
	getVAT.value.push({
		PERCENTAGE_RATE: null,
		VAT_CODE: 'No Select',
		TAX_RATE_ID: null,
	});
	//
	if (getVATBackup.value === null) {
		getVATBackup.value = getVAT.value;
	}
	for (const i in getVAT.value) {
		if (
			getVAT.value[i].PERCENTAGE_RATE !==
			getVATBackup.value[i].PERCENTAGE_RATE
		) {
			await tableList.value.forEach((element) => {
				element.PERCENTAGE_RATE = null;
				element.VAT_CODE = null;
				element.VAT_CODE_ID = null;
			});
			getVATBackup.value = getVAT.value;
		}
	}
	//
};

// const searchVAT = (event) => {
//   setTimeout(() => {
//     if (!event.query.trim().length) {
//       filteredVAT.value = [...getVAT.value]
//     } else {
//       filteredVAT.value = getVAT.value.filter((comp) => {
//         return comp.VAT_COD.toLowerCase().match(event.query.toLowerCase());
//       })
//     }
//   }, 250);
// }

const onChange = async (event, index) => {
	if (
		tableList.value[index].AMOUNT === undefined ||
		tableList.value[index].AMOUNT === null
	) {
		tableList.value[index].VAT_CODE = null;
		toast.add({
			severity: 'error',
			summary: 'Empty Amount',
			detail: 'Empty Amount',
			life: 3000,
		});
	} else {
		//
		if (event.value === null) {
			tableList.value[index].PERCENTAGE_RATE = null;
			tableList.value[index].VAT_CODE = null;
			tableList.value[index].VAT_CODE_ID = null;
			emit('tableList', tableList);
			//
		} else {
			const payload = { id: event.value };
			const data = await SearchInvoiceProxyNonPO.getVATID(payload);
			if (data.status === 200) {
				tableList.value[index].PERCENTAGE_RATE =
					data.data.data.data[0].PERCENTAGE_RATE;
				(tableList.value[index].VAT_CODE =
					data.data.data.data[0].VAT_CODE),
					(tableList.value[index].VAT_CODE_ID =
						data.data.data.data[0].TAX_RATE_ID);
				emit('tableList', tableList);
			} else {
				toast.add({
					severity: 'error',
					summary: 'Data not Found',
					detail: 'Data not Found',
					life: 3000,
				});
			}
		}
	}
};

const onChangeWHT = async (event, index) => {
	//
	if (event.value === null) {
		tableList.value[index].WHT_CODE = null;
		tableList.value[index].WHT_CODE_ID = null;
		emit('tableList', tableList);
	} else {
		const payload = {
			id: event.value,
			org_id: props.org_id,
			db_instance: props.db_instance,
		};
		const data = await SearchInvoiceProxyNonPO.getWHTID(payload);
		if (data.status === 200) {
			tableList.value[index].WHT_CODE = data.data.data.data[0].WHT_CODE;
			tableList.value[index].WHT_CODE_ID =
				data.data.data.data[0].WHT_CODE_ID;
		}
		emit('tableList', tableList);
	}
};

const getAccount = async () => {
	//
	if (
		props.cost_center !== null &&
		(props.cost_center.SEGMENT4 !== undefined ||
			props.cost_center.SEGMENT4 !== null ||
			props.cost_center.SEGMENT4 !== '')
	) {
		const payload = {
			orgId: props.org_id,
			dbInstance: props.db_instance,
			cost_center: props.cost_center.SEGMENT4,
		};

		//
		const res = await SearchInvoiceProxyNonPO.accountPic(payload);
		//
		if (res.status === 200) {
			//
			accountData.value = res.data.data.data;
		}
	}
};

const getAccountId = async (event, index) => {
	tableList.value[index].ACCOUNT = event.value.account;
	tableList.value[index].COA_ID = event.value.code_combination_id;
	emit('tableList', tableList);
};

const searchAccount = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredAccount.value = [...accountData.value];
		} else {
			filteredAccount.value = accountData.value.filter((val) => {
				if (val.account === null || val.account === undefined) {
					return val;
				}
				return val.account
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
		}
	}, 250);
};

const onChangeText = (event, index) => {
	if (tableList.value[index].PERCENTAGE_RATE !== null) {
		tableList.value[index].AMOUNT = event.value;
		emit('tableList', tableList);
	} else {
		tableList.value[index].AMOUNT = event.value;
		emit('tableList', tableList);
	}
};

const whtDropdown = async () => {
	const payload = {
		db_instance: props.db_instance,
		org_id: props.org_id,
	};
	const res = await SearchInvoiceProxyNonPO.whtPIC(payload);
	//
	if (res.status === 200) {
		//
		whtRates.value = res.data.data;
		whtRates.value.push({
			WHT_RATE: null,
			WHT_CODE_ID: null,
			WHT_CODE: 'No Select',
		});
	}
};

const keydown = async () => {
	filtered.value = filteredData.value;
};

const filteredData = computed(() =>
	tableListPopUp.value.filter((item) => {
		return (
			item.guest_name
				.toLowerCase()
				.match(invoiceFilter.value.toLowerCase()) ||
			item.travel_order_no
				.toLowerCase()
				.match(invoiceFilter.value.toLowerCase()) ||
			item.start_date
				.toLowerCase()
				.match(invoiceFilter.value.toLowerCase()) ||
			item.end_date.toLowerCase().match(invoiceFilter.value.toLowerCase())
		);
	})
);

const onRowSelect = (event) => {
	tableList.value[valuePopUP.value + page.value].REMARKS =
		event.data.travel_order_no +
		'#' +
		event.data.guest_name +
		'#' +
		event.data.start_date +
		'#' +
		event.data.end_date;
	displayModal.value = false;
};

// const onSort = async (event) => {
//   // loading.value = true
//   page.value = 1
//   rowsPerPage.value = event.rows;
//   sortColumn.value = event.sortField;
//   sortType.value = event.sortOrder;
//   await populateTable()
// }
</script>

<style scoped>
thead {
	z-index: 0;
}
</style>
