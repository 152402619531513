<template>
	<!-- eslint-disable -->
	<div class="grid" v-if="currentUser">
		<!-- Welcome user -->
		<!-- <div class="col-12 mb-4">
			<Card>
				<template #content>
					<p class="text-lg">
						<span>Welcome, </span>
						<span class="font-bold">Supplier</span>
					</p>
				</template>
			</Card>
		</div> -->

		<!-- Highlight cards -->
		<div class="col-12 md:col-6 lg:col-3 mb-4">
			<Card>
				<template #content>
					<div
						class="flex justify-content-between align-items-center"
					>
						<div>
							<p class="font-bold text-xl m-0 mb-2">
								Planning Order
							</p>
							<p>This month</p>
						</div>
						<div>
							<span class="text-6xl font-bold">
								{{ countPlanningOrders }}
							</span>
						</div>
					</div>
				</template>
			</Card>
		</div>
		<div class="col-12 md:col-6 lg:col-3 mb-4">
			<Card>
				<template #content>
					<div
						class="flex justify-content-between align-items-center"
					>
						<div>
							<p class="font-bold text-xl m-0 mb-2">Open PO</p>
							<p>This month</p>
						</div>
						<div>
							<span class="text-6xl font-bold">
								{{ countOpenPO }}
							</span>
						</div>
					</div>
				</template>
			</Card>
		</div>
		<div class="col-12 md:col-6 lg:col-3 mb-4">
			<Card>
				<template #content>
					<div
						class="flex justify-content-between align-items-center"
					>
						<div>
							<p class="font-bold text-xl m-0 mb-2">
								Shipment Notice
							</p>
							<p>This month</p>
						</div>
						<div>
							<span class="text-6xl font-bold">
								{{ countShipmentNotice }}
							</span>
						</div>
					</div>
				</template>
			</Card>
		</div>
		<div class="col-12 md:col-6 lg:col-3 mb-4">
			<Card>
				<template #content>
					<div
						class="flex justify-content-between align-items-center"
					>
						<div>
							<p class="font-bold text-xl m-0 mb-2">CAPA</p>
							<p>This month</p>
						</div>
						<div>
							<span class="text-6xl font-bold">
								{{ countCapa }}
							</span>
						</div>
					</div>
				</template>
			</Card>
		</div>

		<!-- List cards -->
		<div v-if="!isSupplier" class="col-12 md:col-6 lg:col-3 mb-4">
			<NotifCardList :notifications="notifications" />
		</div>
		<div v-if="isSupplier" class="col-12 md:col-6 lg:col-3 mb-4">
			<NotifCardListPur :notifications="notifications" />
		</div>
		<div class="col-12 md:col-6 lg:col-3 mb-4">
			<OpenPOCardList />
		</div>
		<div class="col-12 md:col-6 lg:col-3 mb-4">
			<ShipmentNoticeCardList />
		</div>
		<div class="col-12 md:col-6 lg:col-3 mb-4">
			<CapaCardList :capa="capa" />
		</div>

		<!-- Charts -->
		<div class="col-12 lg:col-6 mb-4">
			<MostOrderedChart v-if="leveltenant != 3" :currentUser="currentUser" />
		</div>
		<div class="col-12 lg:col-6 mb-4">
			<PlanningOrderChart v-if="leveltenant != 3" :currentUser="currentUser" />
		</div>

		<!-- Card table -->
		<div class="col-12 lg:col-6 mb-4">
			<LatestInvoices v-if="leveltenant != 3"/>
		</div>
		<div
			v-if="leveltenant ? leveltenant == 2 : false"
			class="col-12 lg:col-6 mb-4"
		>
			<LatestReceipts />
		</div>
	</div>
</template>

<script>
/* eslint-disable */
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import AuthService from '../service/AuthService';
import ProductService from '../service/ProductService';
import PlanningOrderService from '../service/PlanningOrderService';
import CapaService from '../service/CapaService';
import NotificationService from '../service/NotificationService';
import CapaDashboard from '@/components/dashboard/CapaList';
import ShipmentNotice from '@/components/dashboard/ShipmentNotice';
import PlanningOrder from '@/components/dashboard/PlanningOrder';
import NotifList from '@/components/dashboard/NotifList';
import InvoiceList from '@/components/dashboard/InvoiceList';
import OpenPO from '@/components/dashboard/OpenPO';
// NEW
import NotifCardList from '@/components/dashboard/cards/NotifCardList.vue';
import NotifCardListPur from '@/components/dashboard/cards/NotifCardListPur.vue';
// import AuthService from '../service/AuthService';
import OpenPOCardList from '@/components/dashboard/cards/OpenPOCardList.vue';
import ShipmentNoticeCardList from '@/components/dashboard/cards/ShipmentNoticeCardList.vue';
import CapaCardList from '@/components/dashboard/cards/CapaCardList.vue';
import MostOrderedChart from '@/components/dashboard/charts/MostOrderedChart.vue';
import PlanningOrderChart from '@/components/dashboard/charts/PlanningOrderChart.vue';
import LatestInvoices from '@/components/dashboard/LatestInvoices.vue';
import LatestReceipts from '@/components/dashboard/LatestReceipts.vue';
import SimpleCrypto from 'simple-crypto-js';
import LovService from '../service/LovService';

export default {
	components: {
		CapaDashboard,
		ShipmentNotice,
		PlanningOrder,
		NotifList,
		InvoiceList,
		OpenPO,
		// NEW
		NotifCardList,
		NotifCardListPur,
		OpenPOCardList,
		ShipmentNoticeCardList,
		CapaCardList,
		MostOrderedChart,
		PlanningOrderChart,
		LatestInvoices,
		LatestReceipts,
	},
	data() {
		return {
			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [{ label: 'Dashboard' }],
			items: [],
			// NEW
			currentUser: null,
			planningOrders: [],
			openPO: 0,
			capa: 0,
			shipmentNotice: 0,
			notifications: [],
			isSupplier: false,
			suppBindToComp: null,
			suppName: null,
			orgId: null,
			companyDash: null,
			leveltenant: 0,
			company: null,
		};
	},

	computed: {
		countPlanningOrders() {
			return this.planningOrders.length;
		},
		countOpenPO() {
			return this.openPO;
		},
		countCapa() {
			return this.capa;
		},
		countShipmentNotice() {
			return this.shipmentNotice;
		},
	},

	productService: null,

	async created() {
		const resp = await AuthService.whoAmi();
		const secretKey = 'some-unique-key';
		const simpleCrypto = new SimpleCrypto(secretKey);
		this.companyDash = simpleCrypto.decrypt(
			sessionStorage.getItem('dropdownCompany')
		);
		console.log('companyName', this.companyDash);
		this.leveltenant = await resp.data.data.leveltenant;
		if (resp.data.data.leveltenant === 2) {
			this.idUser = resp.data.data.id;
			const response = await LovService.getSuppBindComp(
				resp.data.data.id
			);
			this.suppName = await response.data.data[0].vendor_id;
			const respond = await LovService.getCompanyByName(this.companyDash);
			this.company = await respond.data.data[0];
			this.OpenPOThisMonth();
		} else {
			const secretKey = 'some-unique-key';
			const simpleCrypto = new SimpleCrypto(secretKey);
			this.companyDash = simpleCrypto.decrypt(
				sessionStorage.getItem('dropdownCompany')
			);
			const respond = await LovService.getCompanyByName(this.companyDash);
			this.company = await respond.data.data[0];
			this.OpenPOThisMonth();
		}
		this.productService = new ProductService();
		this.initFilters();
		this.initFilters2();

		// NEW
		this.getPlanningOrder();
		this.getOpenPO();
		this.getCapa();
		this.getShipmentNotice();
		await this.getNotifications();
	},
	mounted() {
		AuthService.whoAmi().then((respond) => {
			let currentTenantLvl = respond.data.data.leveltenant;
			this.currentUser = respond.data.data;
			if (currentTenantLvl === 1) {
				this.isSupplier = true;
			} else if (currentTenantLvl === 2) {
				this.isSupplier = false;
			}
		});
	},
	methods: {
		clearFilter() {
			this.initFilters();
			this.initFilters2();
		},
		initFilters() {
			this.filters = {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
				module: { value: null, matchMode: FilterMatchMode.CONTAINS },
				subject: { value: null, matchMode: FilterMatchMode.CONTAINS },
				date: { value: null, matchMode: FilterMatchMode.CONTAINS },
				status: { value: null, matchMode: FilterMatchMode.CONTAINS },
			};
		},
		initFilters2() {
			this.filters2 = {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
				supplier: { value: null, matchMode: FilterMatchMode.CONTAINS },
				doc_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
				doc_date: { value: null, matchMode: FilterMatchMode.CONTAINS },
				subject: { value: null, matchMode: FilterMatchMode.CONTAINS },
				resp_date: { value: null, matchMode: FilterMatchMode.CONTAINS },
				response: { value: null, matchMode: FilterMatchMode.CONTAINS },
				status: { value: null, matchMode: FilterMatchMode.CONTAINS },
			};
		},
		// NEW
		async getPlanningOrder() {
			if (this.leveltenant == 1) {
				const payload = {
					company: this.company.name,
					db_instance: this.company.db_instance,
					org_id: this.company.value,
				};
				// console.log('isi payload', payload)
				const res = await PlanningOrderService.getTableData(payload);
				this.planningOrders = res.data.data;
			} else {
				const payload = {
					company: this.company.name,
					db_instance: this.company.db_instance,
					org_id: this.company.value,
					vendor_id: this.suppName,
				};
				const res = await PlanningOrderService.getTableDataByVendorId(
					payload
				);
				this.planningOrders = res.data.data;
			}
		},
		async getOpenPO() {
			// const res = await PlanningOrderService.GetTableNested();
			// this.planningOrders = res.data.data;
		},
		async getCapa() {
			let payload = {
				vendorId: this.suppName,
				company: this.companyDash,
			};
			const res = await NotificationService.getCAPATotal(payload);
			this.capa = res.data.data[0][0].total_capa;
		},
		async getShipmentNotice() {
			let payload = {
				vendorId: this.suppName,
				company: this.companyDash,
			};
			const res = await NotificationService.getShipmentTotal(payload);
			this.shipmentNotice = res.data.data[0][0].total_shipment;
		},
		async getNotifications() {
			if (this.leveltenant === 2) {
				const vendorId = sessionStorage.getItem('vendorId');
				const res = await NotificationService.getAllNotificationsSupp(
					vendorId
				);
				this.notifications = res.data.data[0].filter((item) =>
					item.sbjmessage.includes(this.company.name)
				);
			} else {
				const payload = {
					org_id: this.company.value,
					db_instance: this.company.db_instance,
					module: 'ALL',
					status: 'ALL',
					leveltenant: 'Purchasing',
				};
				const res = await NotificationService.getPurNotificationsSearch(
					payload
				);
				this.notifications = res.data.data[0];
			}
		},
		async OpenPOThisMonth() {
			let payload = {
				vendorId: this.suppName,
				company: this.companyDash,
			};
			console.log('payload', payload);
			const res = await NotificationService.getOpenPOTotal(payload);
			this.openPO = res.data.data[0][0].total_po;
		},
	},
};
</script>
