import batchListApi from "./api/SpbBindBatchApi";


class batchList {
  async getDebitNote(payload) {
    return batchListApi.post("spb/notedebit/notedebit", payload);
  }

  async getHeader(payload) {
    return batchListApi.post(`/spb/notedebit/findHeader/`, payload);
  }
  async getDetail(payload) {
    return batchListApi.post(`/spb/notedebit/getDetail/`, payload);
  }
  async create(payload) {
    return batchListApi.post(`/spb/notedebit/createdebit/`, payload);
  }
}
export default new batchList();
