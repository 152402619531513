<template>
    <span class="p-float-label">
        <AutoComplete :suggestions="filteredTerm" @complete="searchTerms($event)" :dropdown="true" field="TERM_NAME" v-model="termsValue" forceSelection>
        <template #item="slotProp">
        {{ slotProp.item.TERM_NAME }}
        </template>
    </AutoComplete>
        <label for="autocomplete">Terms</label>
    </span>
  
  
</template>

<script setup>
import {onMounted, ref, watchEffect, defineEmits} from 'vue'
import AuthService from '../../../service/AuthService';
import InvoiceService from "../../../service/InvoiceSearchWPO"

// eslint-disable-next-line no-undef
const props = defineProps({
  db_instance: {type: Object},
  vendor_id: {type: Object},
  terms_id: {type: Object}
})

// eslint-disable-next-line no-undef
const filteredTerm = ref([]);
const terms = ref([]);
const termsValue = ref();
const emit = defineEmits(['terms'])

onMounted(() => {
  populateData()
})

watchEffect(() => 
  // 
  emit('terms',termsValue)
)

const populateData = () => {
  console.log(props.db_instance, "TERMIND")
  AuthService.whoAmi().then(async (response) => {
    const payload = { db_instance : props.db_instance, vendor_id : props.vendor_id }
      if (response.status === 200){
      const data = await InvoiceService.getTermsBill(payload)
      terms.value = data.data.data.data
      // 
      for (const s of terms.value) {
              if (s.TERM_ID === props.terms_id) {
                termsValue.value = s;
                break;
              }
              else{
                termsValue.value = null;
              }
            }
    }
   
    
  })
}


const searchTerms = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredTerm.value = [...terms.value]
    } else {
      filteredTerm.value = terms.value.filter((comp) => {
        return comp.TERM_NAME.toLowerCase().match(event.query.toLowerCase());
      })
    }
  }, 250);
}
</script>

<style scoped>

</style>