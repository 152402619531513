<template>
	<!-- eslint-disable -->
	<Toast />
	<div class="Grid">
		<ProgressModal :progress="progress">
			<template #message>
				{{ message }} data in progress.<br />Please wait...
			</template>
		</ProgressModal>
		<div class="col-12 mb-4">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<div class="detail-master-category col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Master Category</div>
				<div class="content-body">
					<form @submit.prevent="handleSubmit(!v$.$invalid)">
						<div class="col-12">
							<div class="detail-header grid">
								<div class="col-12 mb-5 lg:col-6">
									<span class="p-float-label">
										<InputText
											v-model="company.name"
											class="w-full"
											style="color: #000"
											disabled
										/>
										<label>Company</label>
									</span>
								</div>
								<div class="col-12 mb-5 lg:col-6">
									<span class="p-float-label">
										<InputText
											id="username"
											type="text"
											v-model="v$.category.$model"
											class="w-full"
											:class="{
												'p-invalid':
													v$.category.$invalid &&
													submitted,
											}"
											:disabled="idValue != 0"
											style="color: #000 !important"
										/>
										<label for="inputtext">Category</label>
									</span>
									<small
										v-if="
											(v$.category.$invalid &&
												submitted) ||
											v$.category.$pending.$response
										"
										class="p-error"
										>{{
											v$.category.required.$message.replace(
												'Value',
												'Category Value'
											)
										}}</small
									>
								</div>
								<div class="col-12 mb-5 lg:col-6">
									<span class="p-float-label">
										<Account
											v-model="v$.account.$model"
											:company="company ? company : null"
											:class="{
												'p-invalid':
													v$.account.$invalid &&
													submitted,
											}"
											class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
											:disabled="idValue != 0"
											style="color: #000 !important"
											:key="accountReload"
											:accountList="accountList"
										/>
										<label>Account</label>
									</span>
									<small
										v-if="
											(v$.account.$invalid &&
												submitted) ||
											v$.account.$pending.$response
										"
										class="p-error"
										>{{
											v$.account.required.$message.replace(
												'Value',
												'Account Value'
											)
										}}</small
									>
								</div>
							</div>

							<!-- Add line button -->
							<div
								style="display: flex; justify-content: flex-end"
							>
								<div
									class="mr-3 ml-2"
									style="margin: 1em 0 0 0"
								>
									<Button
										type="submit"
										class="p-button-success uppercase"
										label="add line"
										@click="submitData('A')"
									/>
								</div>
							</div>

							<!-- Category detail -->
							<div style="margin: 1em 0 0 0">
								<TableDetail
									:company="company ? company : null"
									:data="idValue ? idValue : null"
									:line="line ? line : null"
									:category="category ? category : null"
									:coa="account ? account : null"
									:valid="valid ? valid : null"
									:key="reloadTable"
									:accountList="accountList"
									:dataTable="tableList"
									@loading="getLoading"
									@tableList="getDataTable"
								></TableDetail>
							</div>

							<!-- Action button -->
							<div class="grid" style="margin: 2em 0 0 0">
								<div
									class="col-12 md:col-6 mb-2 lg:col-2 lg:mb-0"
								>
									<Button
										class="p-button-secondary uppercase w-full"
										label="Back"
										@click="submitData('B')"
									>
									</Button>
								</div>
								<div
									v-if="idValue == 0"
									class="col-12 md:col-6 mb-2 lg:col-2"
								>
									<Button
										type="submit"
										class="p-button-info uppercase w-full"
										label="Create"
										:disabled="loading"
										@click="submitData('C')"
									/>
								</div>
								<div
									v-if="idValue != 0"
									class="col-12 md:col-6 mb-2 lg:col-2"
								>
									<Button
										type="submit"
										class="p-button-success uppercase w-full"
										label="Update"
										:disabled="loading || !tableList"
										@click="submitData('U')"
									/>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue';
import Account from '@/components/mastercategory/components/Account';
import TableDetail from '@/components/mastercategory/components/TableCategoryDetail';
import { useVuelidate } from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';
import ProgressModal from '../../components/ProgressModal.vue';
import MasterCategory from '../../../service/MasterCategory';
import LovService from '../../../service/LovService';

export default {
	setup: () => ({ v$: useVuelidate() }),
	name: 'detailMasterCategory',
	components: {
		Account,
		TableDetail,
		ProgressModal,
	},
	data() {
		return {
			idValue: this.$route.params.id,
			loading: false,
			company: null,
			supplier: null,
			idUser: null,
			items: null,
			isEmpty: null,
			account: null,
			accountList: null,
			category: null,
			reloadTable: null,
			param: null,
			line: null,
			progress: false,
			submitted: false,
			buttonValue: null,
			valid: null,
			tableList: null,
			data: null,
			message: null,
			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [
				{ label: 'Master Category', to: '/mgm/master/mastercategory' },
				{ label: 'Detail Master Category', to: this.$route.fullPath },
			],
			supplierReload: ref(0),
			accountReload: ref(0),
		};
	},
	validations() {
		return {
			category: {
				required: requiredIf(
					this.buttonValue === 'A' ||
						this.buttonValue === 'C' ||
						this.buttonValue === 'U'
				),
			},
			account: {
				required: requiredIf(
					this.buttonValue === 'A' ||
						this.buttonValue === 'C' ||
						this.buttonValue === 'U'
				),
			},
		};
	},

	async created() {
		this.idUser = parseInt(sessionStorage.getItem('idUser'));
		const queryParams = this.$route.query;

		this.company = {
			value: queryParams.v,
			db_instance: queryParams.i,
		};

		const getCompanies = this.getCompanyList(this.company.db_instance);
		const getAccounts = this.getListAccount();

		await Promise.all([getCompanies, getAccounts]);

		if (this.idValue != 0) {
			const result = await MasterCategory.retriveCategoryById(
				this.idValue
			);

			// Assign result data to state
			let num = 0;
			this.category = result.data.data[0].category_name;
			this.tableList = result.data.data.map((item) => {
				num++;
				const COA = this.accountList.find(
					(v) => v.CODE_COMBINATION_ID === item.detail_account_id
				);
				return {
					...item,
					COA,
					id: num,
				};
			});
			this.account = this.accountList.find(
				(item) =>
					item.CODE_COMBINATION_ID ===
					result.data.data[0].header_account_id
			);
			this.reloadTable++;
		} else {
			this.reloadTable++;
		}
	},

	methods: {
		dateFormat2(d) {
			var t = new Date(d);
			return `${t.getFullYear()}-${t.getMonth() + 1}-${t.getDate()}`;
		},
		async getListAccount() {
			const payload = {
				db_instance: this.company.db_instance,
				org_id: this.company.value,
			};
			const res = await MasterCategory.getCoA(payload);
			this.accountList = res.data.data;
		},
		async getCompanyList() {
			const res = await LovService.getcompanydb(this.company.db_instance);
			this.company = res.data.data.find(
				(comp) =>
					comp.db_instance === this.company.db_instance &&
					comp.value === this.company.value
			);
		},
		async getLoading(value) {
			this.loading = value.value;
		},
		async submitData(value) {
			if (value === 'B') {
				this.$router.go(-1);
			} else {
				this.buttonValue = value;
			}
		},
		async getDataTable(value) {
			this.tableList = value.value;
		},
		async handleSubmit(isFormValid) {
			this.submitted = true;
			if (!isFormValid) {
				this.$toast.add({
					severity: 'error',
					summary: 'All Fields are Required',
					life: 3000,
				});
				return;
			}
			if (this.buttonValue === 'A') {
				this.line++;
			} else if (this.buttonValue === 'C') {
				// Validate if no details payload
				if (!this.tableList || !this.tableList.length) {
					this.$toast.add({
						severity: 'error',
						summary: 'Please add category details',
						life: 3000,
					});
					return;
				}

				this.progress = true;
				this.message = 'Create';
				this.tableList.forEach((element) => {
					delete element['id'];
				});

				const payload = {
					headerList: {
						CATEGORY_NAME: this.category,
						ACCOUNT_ID: this.account.CODE_COMBINATION_ID,
						ORG_ID: this.company.value,
						CREATED_BY: this.idUser,
						CREATION_DATE: this.dateFormat2(new Date()),
						DB_INSTANCE: this.company.db_instance,
						LAST_UPDATED_BY: this.idUser,
						LAST_UPDATE_DATE: this.dateFormat2(new Date()),
					},
					detailList: this.tableList.map((item) => ({
						ACCOUNT_ID: item.COA.CODE_COMBINATION_ID,
						CATEGORY_NAME: item.CATEGORY_DETAIL,
						CREATED_BY: item.CREATED_BY,
						CREATION_DATE: item.CREATION_DATE,
						DB_INSTANCE: item.DB_INSTANCE,
						ORG_ID: item.ORG_ID,
						LAST_UPDATED_BY: item.CREATED_BY,
						LAST_UPDATE_DATE: item.CREATION_DATE,
						STATUS: item.status,
					})),
				};

				try {
					const data = await MasterCategory.CreateCategory(payload);
					if (data.status === 200) {
						this.progress = false;
						// Set success notification message
						sessionStorage.setItem(
							'notifMasterCategory',
							'Success Create Data'
						);
						this.$router.push({ name: 'masterCategory' });
					} else {
						this.progress = false;
						this.$toast.add({
							severity: 'error',
							summary: 'Error Create Data',
							life: 3000,
						});
					}
				} catch (err) {
					console.log(err.response);
					this.progress = false;

					if (err.message.includes('Network Error')) {
						console.log('network error');
						this.$toast.add({
							severity: 'error',
							summary: 'Network Error',
							detail: 'Connection Down. Please check again your submission status.',
						});
						return;
					}

					if (
						err.response.status === 400 ||
						err.response.status === 403
					) {
						this.$toast.add({
							severity: 'error',
							summary: err.response.data.data,
							life: 3000,
						});
						return;
					}

					this.$toast.add({
						severity: 'error',
						summary: 'Server Error: Unsuccessfully create category',
						life: 3000,
					});
				}
			} else if (this.buttonValue === 'U') {
				this.progress = true;
				this.message = 'Update';

				const payload = {
					updatedLines: this.tableList
						.filter((item) => item.CATEGORY_DETAIL_ID)
						.map((item) => ({
							ACCOUNT_ID: item.COA.CODE_COMBINATION_ID,
							CATEGORY_DETAIL: item.CATEGORY_DETAIL,
							CATEGORY_DETAIL_ID: item.CATEGORY_DETAIL_ID,
							DB_INSTANCE: item.db_instance,
							ORG_ID: item.org_id,
							LAST_UPDATED_BY: this.idUser,
							LAST_UPDATE_DATE: this.dateFormat2(new Date()),
							STATUS: item.status,
						})),
					newLines: this.tableList
						.filter((item) => !item.CATEGORY_DETAIL_ID)
						.map((item) => ({
							ACCOUNT_ID: item.COA.CODE_COMBINATION_ID,
							CATEGORY_ID: parseInt(this.idValue),
							CATEGORY_DETAIL: item.CATEGORY_DETAIL,
							CATEGORY_DETAIL_ID: item.CATEGORY_DETAIL_ID,
							DB_INSTANCE: item.DB_INSTANCE,
							ORG_ID: item.ORG_ID,
							CREATED_BY: this.idUser,
							CREATION_DATE: this.dateFormat2(new Date()),
							LAST_UPDATED_BY: this.idUser,
							LAST_UPDATE_DATE: this.dateFormat2(new Date()),
							STATUS: item.status,
						})),
				};

				try {
					const res = await MasterCategory.UpdateCategoryDetail(
						this.idValue,
						payload
					);
					if (res.status === 200) {
						this.progress = false;
						// Set success notification message
						sessionStorage.setItem(
							'notifMasterCategory',
							'Success Update Data'
						);
						this.$router.push({ name: 'masterCategory' });
					}
					this.progress = false;
				} catch (err) {
					console.log(err.response);
					this.progress = false;

					if (err.response.status === 400) {
						return this.$toast.add({
							severity: 'error',
							summary: `${err.response.data.message}`,
							life: 3000,
						});
					}

					this.$toast.add({
						severity: 'error',
						summary: 'Error Update Data',
						life: 3000,
					});
				}
			}
			this.submitted = false;
		},
	},
};
</script>
<style scoped lang="scss">
.form-card {
	padding: 10px;
	border: 1px solid #e0e0e0;
	border-radius: 16px;
}
</style>
