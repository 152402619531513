<template>
	<Toast />

	<DataTable
		:value="tableList"
		:lazy="true"
		:paginator="true"
		:rows="5"
		:loading="loading"
		:totalRecords="totalData"
		@page="onPage($event)"
		@sort="onSort($event)"
		:rowsPerPageOptions="[5, 10, 20, 50]"
		responsiveLayout="scroll"
		:scrollable="true"
		scrollHeight="600px"
		:sortable="true"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #header>
			<div>
				<span class="p-input-icon-left" style="col-12">
					<i class="pi pi-search" />
					<InputText
						v-model="tableFilter"
						placeholder="Search Category"
						@keydown.enter="searchKeyword"
					/>
				</span>
			</div>
		</template>
		<template #empty>No Data found</template>
		<template #loading>Loading data</template>
		<Column header="Company" field="name" style="width: 20%">
		</Column>
		<Column
			header="Category"
			field="category_name"
			style="width: 20%"
		>
		</Column>
		<Column header="Account" field="account" style="width: 20%">
		</Column>
		<Column
			class="call-button-container"
			header="Actions"
			selectionMode="single"
			style="width: 20%"
		>
			<template #body="slotProps">
				<Button
					icon="pi pi-eye"
					class="p-button-rounded p-button-text"
					@click="editChildren(slotProps.data)"
					v-tooltip="'Detail'"
				/>
			</template>
		</Column>
	</DataTable>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import AuthService from '@/service/AuthService';
import LovService from '@/service/LovService';
import MasterCategory from '../../../service/MasterCategory';
import PoFulfillment from '@/service/PoFulfillment';

const toast = useToast();

const company = ref();
const supplier = ref();

onMounted(async () => {
	company.value = props.company;
	const info = await AuthService.whoAmi();
	if (info.status === 200) {
		levelTenant.value = info.data.data.leveltenant;
		if (levelTenant.value !== 2) {
			// loading.value = true
			supplier.value = props.supplier;
			await populateTable();
		} else {
			if (company.value && !supplier.value) {
				const param = {
					db_instance: props.company.db_instance,
					org_id: props.company.value,
				};
				const respVendor = await PoFulfillment.getSuppBindComp(
					info.data.data.id
				);
				const vendorName = respVendor.data.data[0][0].vendorname;
				const respSuppName = await LovService.getSupplierName(param);
				if (respSuppName.status === 200) {
					const suppList = respSuppName.data.data;
					for (const s of suppList) {
						if (s.vendor_name === vendorName) {
							supplier.value = s;
							break;
						}
					}
				}
				await populateTable();
			}
			// loading.value = true
		}
	}
});
// eslint-disable-next-line no-undef
const props = defineProps({
	company: {
		type: Object,
	},
	category: {
		type: Object,
	},
	account: {
		type: Object,
	},
	data: {
		type: Number,
	},
});

const tableData = ref([]);
const tableList = ref([]);
const totalData = ref(0);
const loading = ref(false);
const page = ref(1);
const rowsPerPage = ref(5);
const levelTenant = ref();
const tableFilter = ref();
const router = useRouter();
const emit = defineEmits(['loading']);
const search = ref();

watchEffect(() => emit('loading', loading));

const populateTable = async () => {
	try {
		if (props.company !== null && props.company !== undefined) {
			const payload = {
				org_id: props.company ? props.company.value : null,
				db_instance: props.company ? props.company.db_instance : null,
				category: props.category ? props.category : null,
				account: props.account ? props.account : null,
				search: tableFilter.value ? tableFilter.value : null,
				page: page.value,
				rows: rowsPerPage.value,
			};
			loading.value = true;
			tableData.value = await MasterCategory.getTableData(payload);

			if (tableData.value.status === 200) {
				tableList.value = tableData.value.data.data;
				totalData.value = tableData.value.data.totalData[0].totalData;
				loading.value = false;
			} else {
				loading.value = false;
			}
		} else if (props.data !== undefined && props.data > 0) {
			tableData.value = await MasterCategory.retriveCategoryById(
				props.data
			);
		}
	} catch (err) {
		console.log(err);
		loading.value = false;
		tableList.value = [];
		totalData.value = 0;
		toast.add({
			severity: 'error',
			summary: 'Server error',
			life: 3000,
		});
	}
};

const onPage = async (event) => {
	loading.value = true;
	page.value = event.page + 1;
	rowsPerPage.value = event.rows;
	await populateTable();
};

const editChildren = async (rowIndex) => {
	router.push({
		name: 'detailMasterCategory',
		params: { id: rowIndex.category_id },
		query: { v: props.company.value, i: props.company.db_instance },
	});
};

const searchKeyword = async (event) => {
	search.value = event;
	if (search.value !== null) {
		await populateTable();
	}
};
</script>

<style scoped></style>
