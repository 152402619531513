<template>
    <!-- eslint-disable -->
    <div class="grid">
        <div class="col-12">
            <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
        </div>
        <div class="col-12">
            <div class="card">
                <div class="surface-section">
                    <div class="font-medium text-2xl text-900 mb-3">Notification Detail</div>

                    <ul class="list-none p-0 m-0">
                        <li class="flex align-items-center py-3 px-2 border-top-0 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Module</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{modulename}}</div>
                            <div class="w-6 md:w-2 flex justify-content-end">

                            </div>
                        </li>
                        <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Sender</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{sender}}
                            </div>
                            <div class="w-6 md:w-2 flex justify-content-end">

                            </div>
                        </li>
                        <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Date</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{date}}</div>
                            <div class="w-6 md:w-2 flex justify-content-end">

                            </div>
                        </li>
                        <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Recipient</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                {{recipient}}
                            </div>
                            <div class="w-6 md:w-2 flex justify-content-end">

                            </div>
                        </li>
                        <li
                            class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Subject</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                {{subject}}</div>
                            <div class="w-6 md:w-2 flex justify-content-end">

                            </div>
                        </li>
                        <li
                            class="flex align-items-center py-3 px-2 border-top-0 border-bottom-0 surface-border flex-wrap">
                            <div class="text-500 w-6 font-medium">Message</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                <br />Dear Purchasing team,<br />Your order has been shipped on {{shipDate}}, with
                                details as
                                follow:</div>
                            <div class="w-6 md:w-2 flex justify-content-end">

                            </div>
                        </li>
                        <div class="grid">
                            <div class="col-12 md:col-6 p-fluid">
                                <div class="card">
                                    <h6>Shipment Information</h6>
                                    <li class="flex justify-content py-2 px-2 border-top-1 border-bottom-0 surface-border flex-wrap">
                                        <div class="text-900 w-full md:w-6 md:flex-order-0 flex-order-1 line-height-3">
                                            <strong>DO Number :</strong> {{doNum}} <br />
                                            <strong>Delivery Date :</strong> {{shipDate}} </div>
                                    </li>
                                </div>
                            </div>
                            <div class="col-12 md:col-6 p-fluid">
                                <div class="card">
                                    <h6>Freight Information</h6>
                                    <li class="flex justify-content py-2 px-2 border-top-1 border-bottom-0 surface-border flex-wrap">
                                        <div class="text-900 w-full md:w-6 md:flex-order-0 flex-order-1 line-height-3">
                                            <strong>Freight Carrier :</strong> {{freight}} <br />
                                            <strong>Comment :</strong> {{comment}} <br />
                                            <strong>AWB/BL Number :</strong> {{awbNum}} </div>
                                    </li>
                                </div>
                            </div>
                        </div>
                        <br />
                        <DataTable :value="notifications" v-model:selection="selectedNotifications" dataKey="id"
                            :paginator="false" :rows="5" v-model:filters="filters"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            responsiveLayout="scroll">
                            <Column v-for="col of notifColumns" :field="col.field" :header="col.header" :key="col.field"
                                :sortable="false" />
                        </DataTable>
                        <li class="flex align-items-center py-3 px-2 border-top-0 border-bottom-0 surface-border flex-wrap">
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                <br />For detailed information of this Shipment Notice, please visit <router-link
                                    to="/dashboard" class="text-blue-500">here</router-link>.<br /><br />
                                Regards,<br />
                                Supplier Portal Administrator</div>
                            <div class="w-6 md:w-2 flex justify-content-end">
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <router-link to="/dashboard"><Button label="Return" class="mr-2 mb-2" /></router-link>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import NotificationService from '../../../service/NotificationService';
export default {
    data() {
        return {
            breadcrumbHome: {
                icon: 'pi pi-home',
                to: '/dashboard'
            },
            breadcrumbItems: [{
                    label: 'Dashboard',
                    to: '/dashboard'
                },
                {
                    label: 'Notification Detail'
                }
            ],
            doNum: null,
            awbNum: null,
            modulename: this.$route.params.nameModule,
            sender: null,
            date: null,
            recipient: null,
            subject: this.$route.params.subjectMessage,
            freight: null,
            comment: null,
            shipDate: null,
            notifColumns: null,
            notifications: null,
            notification: {},
            selectedNotifications: null,
        }
    },

    created() {
        console.clear()
        let getPoNum = this.subject.substring(0,this.subject.indexOf('from'));
	    this.doNum = getPoNum.replace('New Shipment Notice # ', '').replace(/ /g,'')
        
        this.notifColumns = [{
                field: 'PO_NUMBER',
                header: 'PO Number'
            },
            {
                field: 'LINE_NUM',
                header: 'Line'
            },
            {
                field: 'ITEM_NO',
                header: 'Item Number'
            },
            {
                field: 'ITEM_DESC',
                header: 'Item Description'
            },
            {
                field: 'UOM',
                header: 'UOM'
            },
            {
                field: 'QUANTITY_ORDER',
                header: 'Qty Order'
            },
            {
                field: 'QUANTITY_SHIPPED',
                header: 'Qty Shipped'
            },
            {
                field: 'DUE_DATE',
                header: 'Arrival at Site Date'
            },
            {
                field: 'SHIP_TO_NAME',
                header: 'Ship To'
            },
        ]
    },
    mounted() {
        NotificationService.getNotifDetailShipmentNotice(this.doNum).then((response) => {
            if (response.status == 200) {
                let dataload = response.data.data[0]
                for (const i in dataload) {
                    dataload[i]["QUANTITY_ORDER"] = dataload[i]["QUANTITY_ORDER"].toLocaleString('id-ID')
                    dataload[i]["QUANTITY_SHIPPED"] = dataload[i]["QUANTITY_SHIPPED"].toLocaleString('id-ID')
                    let convertDate = new Date(dataload[i]["DUE_DATE"])
                    dataload[i]["DUE_DATE"] = (convertDate.getDate() + '-' + convertDate.toString().substring(4, 7) + '-' + convertDate.getFullYear()).toUpperCase()
                }
                this.notifications = dataload;
                this.awbNum = dataload[0]['WAYBILL_AIRBILL_NUM']
                this.freight = dataload[0]['FREIGHT_CARRIER_CODE']
                this.comment = dataload[0]['COMMENTS']
                // let creationDate = new Date(dataload[0]['CREATION_DATE'])
                // this.date = creationDate.getDate() + ' ' + creationDate.toString().substring(4, 7) + ' ' + creationDate.getFullYear()
                let deliverDate = new Date(dataload[0]['SHIPPED_DATE'])
                this.shipDate = (deliverDate.getDate() + '-' + deliverDate.toString().substring(4, 7) + '-' + deliverDate.getFullYear()).toUpperCase()
            } else {
                this.notifications = [];
            }
        });
        NotificationService.getNotifDetailPlannoSender({
            something: JSON.stringify(this.$route.params.subjectMessage)
        }).then((resp) => {
            this.sender = resp.data.data[0][0].sender
            this.recipient = resp.data.data[0][0].recipient
            let receivedDate = new Date(resp.data.data[0][0].receiveddate)
            this.date = (receivedDate.getDate() + ' ' + receivedDate.toString().substring(4, 7) + ' ' + receivedDate.getFullYear()).toUpperCase()
        })
    },
}
</script>
<style scoped>
 .header-strong {
     font-weight: 900;
 }

 .content-strong {
     font-weight: 500;
 }
</style>