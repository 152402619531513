<template>
  <div class="grid">
    <Toast />
    <ProgressModal :progress="progress">
      <template #message>
        Your Request in progress.<br />Please wait...
      </template>
    </ProgressModal>
    <div class="col-12">
      <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
    </div>
    <div class="col-12">
      <div class="content-wrapper mb-4">
        <div class="content-header">General Information</div>
        <div class="content-body">
          <div class="flex flex-row justify-content-between">
            <div class="col flex-column align-item-center log:col-auto">
              <div class="grid">
                <div class="col-12 md:col-12 lg:col-4">
                  <span class="p-float-label">
                    <SupplierDropdown
                      v-model="supplier"
                      class="w-full mb-2 lg:mr-3 lg:mb-0"
                      :company="company ? company : null"
                      :key="supplierReload"
                      :disabled="capaId !== '0'"
                      placeholder=" "
                    />
                    <label for="inputtext">Supplier</label>
                  </span>
                </div>
                <div class="col-12 md:col-12 lg:col-4">
                  <span class="p-float-label">
                    <InputText
                      v-model="documentNo"
                      class="w-full mb-2 lg:mb-0"
                      :disabled="capaId !== '0'"
                    />

                    <label for="inputtext">Document No</label>
                  </span>
                </div>
                <div class="col-12 md:col-12 lg:col-4">
                  <span class="p-float-label">
                    <PONumberDropdown
                      v-model="poNumber"
                      :company="company"
                      :supplier="supplier"
                      class="w-full mb-2 lg:mr-4 lg:mb-0"
                      :disabled="capaId !== '0'"
                      placeholder=" "
                      :key="poReload"
                    />
                    <label for="inputtext">PO Number</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col flex-column align-item-center log:col-auto"></div>
        </div>
      </div>

      <div class="content-wrapper mb-4 left">
        <div class="content-header">Finding Information</div>
        <div class="content-body">
          <div class="flex flex-row justify-content-between mb-4">
            <div class="col flex-column align-item-left log:col-auto">
              <div class="grid">
                <div class="col-12 md:col-12 lg:col-4">
                  <span class="p-float-label">
                    <Calendar
                      v-model="auditDate"
                      dateFormat="d-M-yy"
                      :showIcon="true"
                      class="w-full mb-3 lg:mb-0"
                      :disabled="capaId !== '0'"
                    />
                    <label for="inputtext">DR/Audit Date</label>
                  </span>
                </div>
                <div class="col-12 md:col-12 lg:col-4"></div>
              </div>
              <div class="grid">
                <div class="col-12 md:col-12 lg:col-4 mt-4">
                  <!-- <ButtonDownloadFile
                    v-if="soFilename"
                    :link="SOFileLink"
                    :label="soFilename"
                  /> -->
                  <Button
                    v-if="!loadingSoFile && soFilename"
                    class="button-download font-normal"
                    :label="soFilename"
                    icon="pi pi-download"
                    @click="onDownload()"
                  />
                  <Button
                    v-if="loadingSoFile"
                    class="button-download font-normal"
                    :label="`${soFilename} is downloading`"
                    icon="pi pi-download"
                    :disabled="true"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-12 md:col-12 lg:col-12">
                  <FileUpload @select="onSelect" @remove="onRemoveFile" />
                </div>
              </div>
              <div class="grid">
                <div class="col-12 md:col-12 lg:col-12">
                  <span class="p-float-label" style="margin-top: 5px">
                    <Textarea
                      v-model="finding"
                      rows="9"
                      cols="65"
                      class="w-full mb-3 lg:w-auto lg:mr-3 lg:mb-0"
                    />
                    <label for="inputtext">Subject</label>
                  </span>
                </div>
              </div>
            </div>
            <!-- <div class="col flex-column align-item-right log:col-auto" style="margin-left:10px">
              <h1 class="headline">Test Chat FE udah dari BE</h1>

              <main id="app">
                <section ref="chatArea" class="chat-area">
                  <p  class="message" v-for="message in messages" :class="{ 'message-out': message.author === 'purchasing', 'message-in': message.author !== 'purchasing' }" :key="message">
                    {{ message.body }}
                  </p>
                </section>

                <section>
                  <form @submit.prevent="sendMessage('in')" id="person1-form">
                    <label for="person1-input">Bob</label>
                    <input v-model="bobMessage" id="person1-input" type="text" placeholder="Type your message">
                    <button type="submit">Send</button>
                  </form>
                <button @click="clearAllMessages">Clear All</button>
                  <form @submit.prevent="sendMessage('out')" id="person2-form">
                    <label for="person2-input">You</label>
                    <input v-model="youMessage" id="person2-input" type="text" placeholder="Type your message">
                    <button type="submit">Send</button>
                  </form>
                </section>
              </main>
            </div> -->
          </div>
          <Button
            v-if="capaId === '0'"
            label="Create"
            @click="create()"
            class="p-button-success w-full mb-3 lg:w-auto lg:mr-3 lg:mb-0"
            :disabled="disableButton"
          />
          <Button
            v-if="capaId !== '0' && status === 'O'"
            label="Update"
            @click="update()"
            class="p-button-success w-full mb-3 lg:w-auto lg:mr-3 lg:mb-0"
            :disabled="disableButton"
          />
          <Button
            label="Back"
            @click="back()"
            class="p-button-secondary w-full mb-3 lg:w-auto lg:mr-3 lg:mb-0"
          />
          <Button
            v-if="capaId !== '0' && status === 'O'"
            label="Cancel CAPA"
            @click="cancelCAPA()"
            class="p-button-primary w-full mb-3 lg:w-auto lg:mr-3 lg:mb-0"
            :disabled="disableButton"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import SupplierDropdown from "@/components/components/SupplierDropdown";
import { ref, watch } from "vue";
// import download from "downloadjs";
import SimpleCrypto from "simple-crypto-js";
import AuthService from "@/service/AuthService";
import LovService from "@/service/LovService";
import FileUpload from "./components/FileUpload.vue";
import { useRouter, useRoute } from "vue-router";
import {
  createCAPA,
  getCAPA,
  updateCAPA,
  // getMessage,
  getSOfile,
} from "@/service/CAPAServices";
import { useToast } from "primevue/usetoast";
// import ButtonDownloadFile from "./components/ButtonDownloadFile.vue";
import ProgressModal from "./../components/ProgressModal.vue";
import PONumberDropdown from "@/components/capa/components/PONumberDropdown";
// import { atob } from "buffer";
// import { Blob } from "buffer";

const breadcrumbHome = { icon: "pi pi-home", to: "/dashboard" };
const breadcrumbItems = ref([
  {
    label: "Create CAPA",
    to: "/mgm/master/capa/addcapa/0",
  },
]);
const toast = useToast();
const company = ref();
const supplier = ref(null);
const documentNo = ref();
const poNumber = ref();
const auditDate = ref();
const finding = ref();
const supplierReload = ref(0);
const userInfo = ref();
const file = ref();
const route = useRoute();
const router = useRouter();
const capaId = ref();
const SOFileLink = ref();
const soFilename = ref();
const soFile = ref();
const dbInstance = ref();
const emailSender = ref();
const status = ref();
const progress = ref(false);
const disableButton = ref(true);
const poReload = ref(0);
const soFileBuffer = ref();
const loadingSoFile = ref(false);
// const bobMessage = ref();
// const youMessage = ref();
// const messages = ref([
//       {
//         body: 'Welcome to the chat, I\'m Bob!',
//         author: 'bob'
//       },
//       {
//         body: 'Thank you Bob',
//         author: 'you'
//       },
//       {
//         body: 'You\'re most welcome',
//         author: 'bob'
//       }
// ])
// const messages = ref();

if (route.params.id) {
  capaId.value = route.params.id;
}

watch(
  () => supplier.value,
  (newQuestion) => {
    if (typeof newQuestion === 'object' &&
					!Array.isArray(newQuestion) &&
					newQuestion !== null){
            poNumber.value = null;
            poReload.value++;
          }
  }
);

AuthService.whoAmi().then(async (response) => {
  if (response.status === 200) {
    // const payload1 = { org_id: 82, vendor_id: 1114 };
    // const datamessage = await getMessage(payload1);
    // messages.value = datamessage.data;
    userInfo.value = response.data.data;
    // emailSender.value = response.data.data.bioemailactive;
    const respCbN = await LovService.getCompanyByName(userCompany());
    company.value = respCbN.data.data[0];
    dbInstance.value = company.value.db_instance;
    supplierReload.value++;
    disableButton.value = false;
    if (capaId.value === "0") return;
    // console.info(capaId.value)
    // Get supplier
    const map = new Map();
    map.set("label", "Update CAPA");
    map.set("to", "/mgm/master/capa/addcapa/" + capaId.value);
    const breadcrumbItem = Object.fromEntries(map);
    breadcrumbItems.value[0] = breadcrumbItem;
    const resultData = await getCAPA(capaId.value);
    const capa = resultData.data;

    documentNo.value = capa[0].DOC_NUMBER;
    poNumber.value = capa[0].PO_NUMBER;
    let option = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    auditDate.value = new Date(capa[0].FINDING_DATE).toLocaleDateString(
      "id-ID",
      option
    );
    // auditDate.value = capa[0].FINDING_DATE;
    finding.value = capa[0].FINDING;
    soFilename.value = capa[0].SO_FILENAME;
    SOFileLink.value = resultData.SOFileLink;
    soFile.value = capa[0].SO_FILE;
    status.value = capa[0].STATUS;
    const payload = {
      db_instance: company.value.db_instance,
      org_id: company.value.value,
    };
    LovService.getSupplierName(payload).then((response) => {
      const type = typeof response.data.data;
      if (type === "string") {
        console.error("No supplier found");
      } else {
        const suppliers = response.data.data;
        suppliers.forEach((supp) => {
          if (supp.vendor_id === capa[0].VENDOR_ID) {
            supplier.value = supp;
          }
        });
        if (supplier.value === null) {
          router.push('/dashboard')
          window.alert("Connection Unstable, ID not Recognize")
        }
      }
    });
  }
});

const userCompany = () => {
  const secretKey = "some-unique-key";
  const simpleCrypto = new SimpleCrypto(secretKey);
  return simpleCrypto.decrypt(sessionStorage.getItem("dropdownCompany"));
};

const back = () => {
  router.back();
};

const onSelect = (event) => {
  file.value = event.files[0];
  return file.value;
};
const onRemoveFile = (event) => {
  if (!event.files.length) {
    file.value = null;
  }
};

const create = async () => {
  progress.value = true;
  let errors = [];

  if (!supplier.value) {
    errors.push("Supplier is Not Selected");
  }

  if (!documentNo.value) {
    errors.push("Document No is Empty");
  }

  // if (!poNumber.value) {
  //   errors.push("PO Number is Not Selected");
  // }

  if (!auditDate.value) {
    errors.push("DR/Audit Date is Not Selected");
  }

  if (soFilename.value) {
    // oke
  } else {
    if (file.value) {
      // oke
    } else {
      // not oke
      errors.push("File is Empty");
    }
  }

  if (!finding.value) {
    errors.push("Subject is Empty");
  }

  if (errors.length === 0) {
    try {
      let formData = new FormData();
      formData.append("vendor_id", supplier.value.vendor_id);
      formData.append("finding", finding.value);
      formData.append(
        "finding_date",
        new Date(auditDate.value).toLocaleString("en-US", {
          timezone: "Asia/Jakarta",
        })
      );
      formData.append("doc_number", documentNo.value);
      formData.append("created_by", userInfo.value.id);
      formData.append("last_updated_by", userInfo.value.id);
      formData.append("status", "O");
      formData.append("org_id", company.value.value);
      formData.append("po_number", poNumber.value ? poNumber.value.segment1 : null);
      formData.append("file", file.value);

      // email & notif formData
      formData.append("module", "createCAPA");
      formData.append("idmodule", "2");
      formData.append(
        "sbjmessage",
        "New CAPA # " + documentNo.value + " from "
      );
      formData.append("notif_type", "Supplier");
      formData.append("usersenderid", userInfo.value.id);
      formData.append("sender", emailSender.value);
      formData.append("statusNotif", "Open");
      formData.append("db_instance", dbInstance.value);
      formData.append("fileName", file.value ? file.value.name : null);
      formData.append("companyname", sessionStorage.getItem("mailToCompany"));
      formData.append("vendorname", supplier.value.vendor_name);

      const result = await createCAPA(formData);
      if (result.toUpperCase().includes("ERROR")) {
        toast.add({
          severity: "error",
          summary: "Can't encrypt PDF",
          detail: "PDF already has a password",
          life: 3000,
        });
      } else if (result.toUpperCase().includes("UNABLE")) {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Unable to Save Data",
          life: 3000,
        });
      } else {
        toast.add({
          severity: "info",
          summary: "Success",
          detail: "CAPA Successfully Created",
          life: 3000,
        });
        setTimeout(() => {
          router.back();
        }, 1500);
      }
    } catch (error) {
      console.log(error)
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Something Wrong, Unable to Save Data",
        life: 3000,
      });
      progress.value = false;
    }
  } else {
    // let messageError = "";
    for (const err of errors) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: err,
        life: 3000,
      });
      // messageError = messageError.concat(err + " n");
    }
  }

  progress.value = false;
};

const update = async () => {
  progress.value = true;

  let errors = [];

  if (!finding.value) {
    errors.push("Subject is Empty");
  }

  if (errors.length === 0) {
    let formData = new FormData();
    formData.append("finding", finding.value);
    formData.append("last_updated_by", userInfo.value.id);
    if (file.value) {
      formData.append("file", file.value);
      formData.append("vendor_id", supplier.value.vendor_id);
      formData.append("so_file", soFile.value);
      if (soFile.value === null || soFile.value === undefined) {
        formData.append("so_filename", "new");
        formData.append("so_mime_type", "new");
      } else {
        formData.append("so_filename", "update");
        formData.append("so_mime_type", "update");
      }
    }

    const result = await updateCAPA(capaId.value, formData);
    if (result.toUpperCase().includes("SUCCESS")) {
      toast.add({
        severity: "info",
        summary: "Success",
        detail: "CAPA is updated",
        life: 3000,
      });
      setTimeout(() => {
        location.reload();
      }, 1500);
    } else {
      toast.add({
        severity: "error",
        summary: "Failed",
        detail: "Unable to update CAPA",
        life: 3000,
      });
    }
  } else {
    for (const err of errors) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: err,
        life: 3000,
      });
      // messageError = messageError.concat(err + " n");
    }
  }

  progress.value = false;
};

const cancelCAPA = async () => {
  progress.value = true;
  const params = {
    last_updated_by: userInfo.value.id,
    status: "X",
  };
  const result = await updateCAPA(capaId.value, params);
  if (result.toUpperCase().includes("UNABLE")) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Unable to Save Data",
      life: 3000,
    });
  } else {
    toast.add({
      severity: "info",
      summary: "Success",
      detail: "CAPA has been Canceled",
      life: 3000,
    });
    progress.value = false;
    setTimeout(() => {
      location.reload();
    }, 1500);
  }
};

const onDownload = async () => {
  try {
    loadingSoFile.value = true;
    const leveltenant = sessionStorage.getItem("leveltenant");
    const resSoFile = await getSOfile(capaId.value, leveltenant);
    soFileBuffer.value = resSoFile;
  } catch (error) {
    loadingSoFile.value = false;
    toast.add({
      severity: "error",
      summary: "Connection Unstable !",
      life: 3000,
    });
    throw new Error("Error Downloading File");
  }

  const linkSource = `data:application/pdf;base64,${soFileBuffer.value}`;
  const downloadLink = document.createElement("a");
  const fileName = soFilename.value;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
  loadingSoFile.value = false;
};

// const onDownload = () => {
//   // const blobPDF = base64toBlob();
//   const blobPDF = base64toBlob(soFileBuffer.value, "application/pdf");

//   // download("hello world", "dlText.txt", "text/plain");
//   download(new Blob([blobPDF]), "filename.pdf", "application/pdf");
//   // downloadFile(
//   //   new Blob([soFileBuffer.value], { type: "application/octetstream" }),
//   //   "cobalagi.pdf"
//   // );
// };

// const downloadFile = (blob, fileName) => {
//   const link = document.createElement("a");
//   // create a blobURI pointing to our Blob
//   link.href = URL.createObjectURL(blob);
//   link.download = fileName;
//   // some browser needs the anchor to be in the doc
//   document.body.append(link);
//   link.click();
//   link.remove();
//   // in case the Blob uses a lot of memory
//   setTimeout(() => URL.revokeObjectURL(link.href), 7000);
// };

// const base64toBlob = (base64Data, contentType) => {
//   const sliceSize = 1024;
//   // const base64Data = soFileBuffer.value;
//   const byteCharacters = atob(base64Data, "base64");
//   // const byteCharacters = decodeURIComponent(escape(window.atob(base64Data)))
//   // const byteCharacters = Buffer.from(base64Data, "base64");
//   // const byteCharacters = soFileBuffer.value.data;
//   const bytesLength = byteCharacters.length;
//   const slicesCount = Math.ceil(bytesLength / sliceSize);
//   const byteArrays = new Array(slicesCount);

//   for (let sliceIndex = 0; sliceIndex < slicesCount; sliceIndex += 1) {
//     const begin = sliceIndex * sliceSize;
//     const end = Math.min(begin + sliceSize, bytesLength);

//     const bytes = new Array(end - begin);
//     for (let offset = begin, i = 0; offset < end; i += 1, offset += 1) {
//       bytes[i] = byteCharacters[offset];
//     }

//     byteArrays[sliceIndex] = new Uint8Array(bytes);
//   }

//   return new Blob(byteCharacters, { type: contentType });

//   // const blobPDF = base64toBlob();

//   // // download("hello world", "dlText.txt", "text/plain");
//   // download(new Blob([blobPDF]), "filename.pdf", "application/pdf");
// };

// const sendMessage = async (direction) => {
//   if (!youMessage.value && !bobMessage.value) {
//         return
//       }
//       if (direction === 'out') {
//         messages.value.push({body: youMessage.value, author: 'purchasing'})
//         youMessage.value = ''
//         const data = await sendMessage(messages)

//       } else if (direction === 'in') {
//         messages.value.push({body: bobMessage.value, author: 'supplier'})
//         bobMessage.value = ''
//       } else {
//         alert('something went wrong')
//       }
//       // Vue.nextTick(() => {
//       //   let messageDisplay = this.$refs.chatArea
//       //   messageDisplay.scrollTop = messageDisplay.scrollHeight
//       // })
// }

// const getMessage = async () => {
// }
</script>

<style scoped>
.headline {
  text-align: center;
  font-weight: 100;
  color: black;
}
.chat-area {
  /*   border: 1px solid #ccc; */
  background: white;
  height: 50vh;
  padding: 1em;
  overflow: auto;
  max-width: 350px;
  margin: 0 auto 2em auto;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.3);
}
.message {
  width: 45%;
  border-radius: 10px;
  padding: 0.5em;
  /*   margin-bottom: .5em; */
  font-size: 0.8em;
}
.message-out {
  background: #407fff;
  color: white;
  margin-left: 50%;
}
.message-in {
  background: #f1f0f0;
  color: black;
}
.chat-inputs {
  display: flex;
  justify-content: space-between;
}
#person1-input {
  padding: 0.5em;
}
#person2-input {
  padding: 0.5em;
}
</style>
