<template>
    <div class="grid">
        <Toast />
        <div class="col-12 mb-5">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

        <div class="col-12 mb-4">
            <div class="content-wrapper">
                <div class="content-header">
                    Search
                </div>
                <div class="content-body">
                    <div class="grid">
                        <div class="col-12 lg:col-3">
							<span class="p-float-label">
								<AutoComplete
									class="w-full"
									v-model="company"
									:suggestions="companies"
									field="name"
									:dropdown="true"
									forceSelection
									disabled
									id="company"
								/>
								<label for="company">Company</label>
							</span>
					
                        </div>
                        <div class="col-8 lg:col-3" >
                            <div class="p-inputgroup">
								<span class="p-float-label">
									<AutoComplete
										placeholder="Search PO Number"
										class="w-full"
										v-model="poNum"
										:suggestions="filteredPoNum"
										@complete="searchPoNum($event)"
										field="SEGMENT1"
										forceSelection
										:disabled="true"
										id="poNum"
									/>
									<label for="poNum">PO Number</label>
								</span>
								
								<!-- <Button
									type="button"
									icon="pi pi-search"
								/> -->
							</div>
                        </div>
                        <div class="col-12 lg:col-3">
							<Button
								:class="uppercase"
								class="p-button-danger mr-2"
								@click="searchPO"
								:disabled="true"
							>
								<span class="uppercase font-bold"
									>Search PO</span
								>
							</Button>
							<Button
								:class="uppercase"
								class="p-button-secondary"
								@click="clearForm"
                                :disabled="true"
								><span class="uppercase font-bold"
									>Clear</span
								></Button
							>
						</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 mb-4">
            <div class="content-wrapper">
                <div class="content-header">
                    Search Result Header
                </div>

                <div class="content-body">
                    <div class="grid align-items-start">
                        <div class="col-12 lg:col-6">
							<div class="grid align-items-center">
								<div class="col-12 lg:col-4">
									<label
										style="color: #828282"
										class="mr-4 font-bold text-lg"
										for="rev"
										>Rev</label
									>
								</div>
								<div class="col-12 lg:col-6">
									<InputText
										class="w-full"
										id="rev"
										type="text"
                                        v-model="selectedPO.REVISION_NUM"
										disabled
									/>
								</div>
							</div>
                            <div class="grid align-items-center">
								<div class="col-12 lg:col-4">
									<label
										style="color: #828282"
										class="mr-4 font-bold text-lg"
										for="supplier"
										>Supplier</label
									>
								</div>
								<div class="col-12 lg:col-6">
									<InputText
										class="w-full"
										id="supplier"
										type="text"
										disabled
                                        v-model="selectedPO.VENDOR_NAME"
									/>
								</div>
							</div>
                            <div class="grid align-items-center">
								<div class="col-12 lg:col-4">
									<label
										style="color: #828282"
										class="mr-4 font-bold text-lg"
										for="shipto"
										>Ship To</label
									>
								</div>
								<div class="col-12 lg:col-6">
									<InputText
										class="w-full"
										id="shipto"
										type="text"
										disabled
                                        v-model="selectedPO.SHIP_TO_NAME"
									/>
								</div>
							</div>
							<div class="grid align-items-center">
								<div class="col-12 lg:col-4">
									<label
										style="color: #828282"
										class="mr-4 font-bold text-lg"
										for="shipto"
										>Status</label
									>
								</div>
								<div class="col-12 lg:col-6">
									<InputText
										class="w-full"
										id="shipto"
										type="text"
										disabled
                                        v-model="selectedPO.STATUS_NAME"
									/>
								</div>
							</div>
						</div>

                        <div class="col-12 lg:col-6">
                            <!-- <div class="grid align-items-center">
								<div class="col-12 lg:col-4">
									<label
										style="color: #828282"
										class="mr-4 font-bold text-lg"
										for="podata"
										>PO Data</label
									>
								</div>
								<div class="col-12 lg:col-6">
									<InputText
										class="w-full"
										id="podata"
										type="text"
										disabled
                                        v-model="selectedPO.PO_HEADER_ID"
									/>
								</div>
							</div> -->
                            <div class="grid align-items-center">
								<div class="col-12 lg:col-4">
									<label
										style="color: #828282"
										class="mr-4 font-bold text-lg"
										for="buyer"
										>Buyer</label
									>
								</div>
								<div class="col-12 lg:col-6">
									<InputText
										class="w-full"
										id="buyer"
										type="text"
										disabled
                                        v-model="selectedPO.BUYER_NAME"
									/>
								</div>
							</div>
                            <div class="grid align-items-center">
								<div class="col-12 lg:col-4">
									<label
										style="color: #828282"
										class="mr-4 font-bold text-lg"
										for="currency"
										>Currency</label
									>
								</div>
								<div class="col-12 lg:col-6">
									<InputText
										class="w-full"
										id="currency"
										type="text"
										disabled
                                        v-model="selectedPO.CURRENCY_CODE"
									/>
								</div>
							</div>
                            <div class="grid align-items-center">
								<div class="col-12 lg:col-4">
									<label
										style="color: #828282"
										class="mr-4 font-bold text-lg"
										for="amount"
										>Amount</label
									>
								</div>
								<div class="col-12 lg:col-6">
									<InputText
										class="w-full"
										id="amount"
										type="text"
										disabled
                                        v-model="amount"
									/>
								</div>
							</div>
						</div>
                        

                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 mb-4">
            <div class="content-wrapper">
                <div class="content-header">
                    Search Result List
                </div>
                <div class="content-body">
                    <!-- v-model:selection="selectedPo" -->
					<div class="col-12">
						<DataTable
                            ref="dt"
							dataKey="id"
                            :metaKeySelection="false"
                            :value="poItems"
							responsiveLayout="scroll"
							:scrollable="true"
							scrollHeight="600px"
							selectionMode="multiple"
                            v-model:selection="selectedItem"
							@rowUnselect="onRowUnselect"
							@onRowSelect="onRowSelect"
						>
							<Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>
							<Column
								class="call-button-container"
								header="Line"
								field="LINE_NUM"
								:sortable="true"
							></Column>
							<Column
								class="call-button-container"
								header="Item Description"
								field="ITEM_DESCRIPTION"
							></Column>
							<Column
								class="call-button-container"
								header="PO Quantity"
								field="QUANTITY"
							></Column>
							<Column
								class="call-button-container"
								header="Pay Item"
								field="SHIPMENT_NUM"
								:sortable="true"
							></Column>
							<Column
								class="call-button-container"
								header="Termin Description"
								field="TERMIN"
							></Column>
							<Column
								class="call-button-container"
								header="Price"
								field="PRICE_OVERRIDE"
							>
                                <template #body="{data}">
                                    <span>{{ formatCurrency(data.PRICE_OVERRIDE) }}</span>
                                </template>
                            </Column>
							<Column
								class="call-button-container"
								header="Amount"
							>
                                <template #body="{data}">
                                    <span>{{ formatCurrency(data.PRICE_OVERRIDE * data.QUANTITY) }}</span>
                                </template>
                            </Column>
							<Column
								class="call-button-container"
								header="Confirm Quantity"
								field="CONFIRM_QUANTITY"
							>
								<template #body="slotProp">
									<InputText
										class="w-full"
										id="confirm_quantity"
										type="number"
										:disabled="status !== 'O'"
                                        v-model="slotProp.data.CONFIRM_QUANTITY"
										@change="handleChangeQuantity(slotProp.data.CONFIRM_QUANTITY,
										slotProp.index)"
									/>
								</template>
							</Column>
							<Column
								class="call-button-container"
								header="Confirm (%)"
								field="CONFIRM_PERCENTAGE"
							>
								<template #body="slotProp">
									<InputText
										class="w-full"
										id="confirm_percentage"
										type="number"
										:disabled="status !== 'O'"
                                        v-model="slotProp.data.CONFIRM_PERCENTAGE"
										@change="handleChangePercentage(slotProp.data.CONFIRM_PERCENTAGE,
										slotProp.index)"
									/>
								</template>
							</Column>
							<Column
								class="call-button-container"
								header="Confirm Amount"
								field="CONFIRM_AMOUNT"
							>
								<template #body="{data}">
									<span>{{ formatCurrency(data.CONFIRM_AMOUNT ?? 0) }}</span>
								</template>
							</Column>
							<Column
								class="call-button-container"
								header="Total Progress (%)"
								field="TOTAL_PROGRESS"
							>
								<template #body="{data}">
									<span>{{ formatCurrency(data.TOTAL_PROGRESS ?? 0) }}</span>
								</template>
							</Column>
							<!-- Empty state -->
							<template #empty>
								{{ emptyMessage }}
							</template>
						</DataTable>
					</div>
                </div>
            </div>
        </div>

		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">
					Upload File
				</div>

				<div class="content-body">

					
					<div class="grid mb-5 mt-5">
                        <div class="lg:col-4 col-12">
							<span class="p-float-label">
								<InputText
									class="w-full"
									id="bast_number"
									type="text"
									v-model="numBAST"
									:disabled="status !== 'O'"
								/>
								<label for="bast_number">BAST Number</label>
							</span>
                        </div>
                    </div>
				
					<div class="fileupload-buttonbar mb-5">
						<table>
							<tr>
								<td>
									<p>BAST File</p>
								</td>
								<td>
									<p>:</p>
								</td>
								<td>
									<span
										class="inline-block mx-2 font-bold"
										v-if="attachmentFile"
										>{{ attachmentFile.fileName }}</span
									>
								</td>

								<td v-if="attachmentFile">
									<p>
										<a
											:href="attachmentFile.url"
											class="p-button-download-bast"
											_target="blank"
										>
											<i class="pi pi-download"></i
											><span class="ml-2">Download</span>
										</a>
									</p>
								</td>
							</tr>
						</table>
					</div>				

					<div v-if="levelTenant === 2 && status === 'O'">
						<FileUpload
							id="uploadFile"
							ref="inputFile"
							chooseLabel="Choose File"
							uploadLabel="Upload File"
							fileLimit="1"
							accept="application/pdf"
							@select="onSelectFile"
							@remove="onRemoveFile"
							:customUpload="true"
							:maxFileSize="5000000"
							:showUploadButton="false"
							:showCancelButton="false"
							v-tooltip.left="'Max file 5MB'"
							:disabled="status !== 'O'"
						>
						<template #empty>
							<p>No file selected. (Max file 5MB)</p>
						</template>
					</FileUpload>
					</div>
					
					<div style="padding: 0rem 1rem" v-if="loadingUpload">
						<ProgressSpinner
							style="width: 25px; height: 25px"
							strokeWidth="6"
						/>
					</div>
					<p style="padding: 0rem 1rem" v-if="fileUploaded">
						{{ fileUploaded.filename }} has been uploaded
					</p>
				</div>
			</div>
		</div>

		<div class="col-12 mb-4">
			<div class="grid" v-if="role === 'Purchasing' && status === 'O'">
				<div class="col-12 lg:col-3">
					<Button label="Save" :disabled="progress" class="w-full p-button-info"
						@click="saveBAST('L')"
					/>
				</div>
				<div class="col-12 lg:col-3">
					<Button label="Submit"  :disabled="progress" class="w-full p-button-success"
                        @click="submitBAST"
                    />
				</div>
				<div class="col-12 lg:col-3">
					<Button label="Back" 
					@click="this.$router.back()"
					class="w-full p-button-primary"/>
				</div>
			</div>

			<div class="grid" v-if="role === 'Purchasing' && status === 'C'">
				<div class="col-12 lg:col-3">
					<Button label="Cancel" :disabled="progress" class="w-full p-button-danger"
						@click="cancelBAST()"
					/>
				</div>
			</div>

			<!-- <div class="grid" v-if="levelTenant === 1 && status === 'S'">
				<div class="col-12 lg:col-3">
					<Button label="Confirm" :disabled="!selectedPO || !numBAST || progress" class="w-full p-button-success"
						@click="confirmBAST"
					/>
				</div>
				<div class="col-12 lg:col-3">
					<Button label="Reject" :disabled="!selectedPO || !numBAST || progress" class="w-full p-button-danger"
                        @click="rejectBAST"
                    />
				</div>
			</div> -->
		</div>
    </div>
</template>

<script>
    import AuthService from '../../../service/AuthService';
	import PlanningOrderService from '../../../service/PlanningOrderService';
    import SimpleCrypto from "simple-crypto-js";
    import LovService from '../../../service/LovService';
    import BASTService from '../../../service/BASTService';

    export default{
        data(){
            return{
				params: this.$route.params.id,
                company: sessionStorage.getItem('mailToCompany'),
                selectedCompany: null,
                poNum: null,
                numBAST: null,
                companies: [],
                bastNum: [],
                selectedItem: [],
				attachmentFile: null,
				selectedPO: {
                    PO_HEADER_ID: null,
                    REVISION_NUM: null,
                    SHIP_TO_NAME: null,
                    CURRENCY_CODE: null,
                    VENDOR_NAME: null,
                    ORDER_DATE: null,
                    BUYER_NAME: null,
                    SEGMENT1: null,
                    JIT_COLUMN: null,
					EMAIL_REQUESTOR: [],
					EMAIL_PURCHASING: [],
					STATUS: null
                },
                role: null,
                poNumList: [],
                filteredPoNum: [],
                vendorId: null,
                amount: 0,
                breadcrumbHome: { icon: "pi pi-home", to: "/dashboard" },
                breadcrumbItems: [
					{
						label: 'List BAST',
						to: '/mgm/settings/listbast'
					},
                    {
                        label: "Edit BAST",
                        to: '/mgm/settings/editbast',
                    },
                ],
				poNumberList: [],
                poItems: [],
                submitApi: null,
                poValue: null,
                orgId: null,
                file: null,
                totalAmount: 0,
				loadingUpload: false,
				isEdit: false,
                detailBefore: [],
				levelTenant: sessionStorage.leveltenant,
				progress: false,
				numBASTBefore: null
            }
        },
		async created() {
			if(this.params !== undefined){
				this.isEdit = true
			}
			AuthService.whoAmi().then(async (response) => {
                function userCompany() {
                    const secretKey = 'some-unique-key';
                    const simpleCrypto = new SimpleCrypto(secretKey);
                    return simpleCrypto.decrypt(
                        sessionStorage.getItem('dropdownCompany')
                    );
                }

                const resultRole = await BASTService.getRoleUser({user_id: response.data.data.id});

                console.log(resultRole.data.data, "ROLE USER");

                this.role = resultRole.data.data.groupname;

				this.sender = response.data.data.bioemailactive;
				this.dbInstance = response.data.data.bioaddress;
				this.usersenderid = response.data.data.id;
				this.levelTenant = response.data.data.leveltenant;
                const respCbN = await LovService.getCompanyByName(
                    userCompany()
                );
             
                this.companies = respCbN.data.data[0];
                this.orgId = respCbN.data.data[0].value

                if(response.status !== 200) return;
   
                // if(response.data.data.leveltenant === 2){
                //     const payload = {
                //         db_instance: sessionStorage.getItem('companydb'),
                //         org_id: respCbN.data.data[0].value,
                //         vendor_id: sessionStorage.getItem('vendorId')
                //     }

                //     const resp = await BASTService.getHeaderList(payload);
				
                //     this.poNumList = resp.data.data;
      
                // }

                await this.fetchBASTDetail();

			});            
        },	
        watch:{
			poItems(text){
				console.log(text, "PO ITEMS")
			},
			selectedCompany(text){
				this.company = text.name
			},
            poNumList(text){
                console.log(text, "PO FILR")
            },
            selectedPO(text){
				console.log(text, "TEXT")
            },
            selectedItem(text){
				console.log(text, "TEST")
				if(this.status === 'S' || this.status === 'C'){
					if(text.length < 1){
						this.selectedItem = this.detailBefore
					}
				}

				if(text.length < 1){
					this.amount = 0
					this.totalAmount = 0;
					return;
				}
                this.amount = this.formatCurrency(text.reduce((a,b) => a + b.CONFIRM_AMOUNT, 0));
                this.totalAmount = text.reduce((a,b) => a + b.CONFIRM_AMOUNT, 0)
            },
            file(text){
                console.log(text, "FILE NAME")
            }
		},
		methods: {
			async fetchBASTDetail(){
				try {
					const res = await BASTService.getBASTDetail({bast_number: this.$route.params.id});
					this.poNum = res.data.data[0].PO_NUMBER
					this.numBAST = res.data.data[0].BAST_NUMBER
					this.numBASTBefore = res.data.data[0].BAST_NUMBER
					this.status = res.data.data[0].STATUS
					await this.getDetail(this.$route.params.id, res.data.data[0].STATUS);

					const resFile = await BASTService.getBASTFileDetail({bast_id: this.$route.params.id});
					console.log(resFile.data, "RES FILE")
					this.attachmentFile = resFile.data.data;
							
				} catch (error) {
					console.log("ERROR", error)
					return{
						status: 500,
						error: error, 
					}
				}
			},
			calculatePercentage(x,y){
				const calculate = (x / y) * 100
				
				return calculate
			},
			calculateAmount(x,y){
				const calculateAmount = (x*y)

				return calculateAmount;
			},
			calculatePercentToQuantity(x,y){
				const calculateToQuantity = (x * y) / 100

				return calculateToQuantity
			},
			calculateTotalProgress(item){
				console.log(item, "ITEM")
				if(item.TOTAL_PROGRESS_API){
					return Number(item.TOTAL_PROGRESS_API) + Number(item.CONFIRM_PERCENTAGE)
				}

				item.TOTAL_PROGRESS = item.CONFIRM_PERCENTAGE
				return item.TOTAL_PROGRESS
			},
			handleChangeQuantity(event, index){
				if(!event) {
					this.poItems[index]["CONFIRM_QUANTITY"] = 0
					return
				}
				
				console.log(event, "EVENT")

				if(event > this.poItems[index]["QUANTITY"]) this.poItems[index]["CONFIRM_QUANTITY"] = this.poItems[index]["QUANTITY"];
				else this.poItems[index]["CONFIRM_QUANTITY"] = Number(event)
				

				this.poItems[index]["CONFIRM_PERCENTAGE"] = this.calculatePercentage(
					this.poItems[index]["CONFIRM_QUANTITY"], 
					this.poItems[index]["QUANTITY"]
				)

				this.poItems[index]["CONFIRM_AMOUNT"] =  this.calculateAmount(
					this.poItems[index]["CONFIRM_QUANTITY"], 
					this.poItems[index]["PRICE_OVERRIDE"] 
				)
				
				this.poItems[index]["TOTAL_PROGRESS"] = this.calculateTotalProgress(this.poItems[index])

				const validateCount = this.poItems[index]["TOTAL_PROGRESS"]

				if(validateCount > 100){
						this.poItems[index]["CONFIRM_PERCENTAGE"] = 0;
						this.poItems[index]["CONFIRM_QUANTITY"] = 0
						this.poItems[index]["CONFIRM_AMOUNT"] = 0;
						this.poItems[index]["TOTAL_PROGRESS"] = this.poItems[index]["TOTAL_PROGRESS_API"]

						this.$toast.add({
							severity: 'error',
							summary: 'Confirm Percentage Bigger than Total Progress',
							life: 3000,
						});

						return
				}

				if(this.selectedItem && this.selectedItem.length > 0){
					const indexes = this.selectedItem.findIndex((item) => item.LINE_LOCATION_ID === this.poItems[index]["LINE_LOCATION_ID"]);
					if(indexes === -1) return;

					this.selectedItem[indexes]["CONFIRM_QUANTITY"] = this.poItems[index]["CONFIRM_QUANTITY"]
					this.selectedItem[indexes]["CONFIRM_PERCENTAGE"] = this.poItems[index]["CONFIRM_PERCENTAGE"]
					this.selectedItem[indexes]["CONFIRM_AMOUNT"] = this.poItems[index]["CONFIRM_AMOUNT"]
					
					this.amount = this.formatCurrency(this.selectedItem.reduce((a,b) => a + b.CONFIRM_AMOUNT, 0));
					this.totalAmount = this.selectedItem.reduce((a,b) => a + b.CONFIRM_AMOUNT, 0)
				}
			},
			handleChangePercentage(value,index){
				console.log(value, "VALUE PERCENTAGE")

				if(!value) {
					this.poItems[index]["CONFIRM_PERCENTAGE"] = 0
					return
				}

				if(value > 100) {
					this.poItems[index]["CONFIRM_PERCENTAGE"] = 100
				}else{
					this.poItems[index]["CONFIRM_PERCENTAGE"] = Number(value)
				}

				this.poItems[index]["CONFIRM_QUANTITY"] = this.calculatePercentToQuantity(
					this.poItems[index]["CONFIRM_PERCENTAGE"], 
					this.poItems[index]["QUANTITY"]
				)

				this.poItems[index]["CONFIRM_AMOUNT"] =  this.calculateAmount(
					this.poItems[index]["CONFIRM_QUANTITY"], 
					this.poItems[index]["PRICE_OVERRIDE"] 
				)

				const tes = typeof(this.poItems[index]["TOTAL_PROGRESS"]);

				console.log(tes, "TEST AJA")


				this.poItems[index]["TOTAL_PROGRESS"] = this.calculateTotalProgress(this.poItems[index])

				const validateCount = this.poItems[index]["TOTAL_PROGRESS"]

				console.log(validateCount, "VALIDATE COUNT")

				if(validateCount > 100){
						this.poItems[index]["CONFIRM_PERCENTAGE"] = 0;
						this.poItems[index]["CONFIRM_QUANTITY"] = 0
						this.poItems[index]["CONFIRM_AMOUNT"] = 0;
						this.poItems[index]["TOTAL_PROGRESS"] = this.poItems[index]["TOTAL_PROGRESS_API"]

						this.$toast.add({
							severity: 'error',
							summary: 'Confirm Percentage Bigger than Total Progress',
							life: 3000,
						});

						return
				}

				if(this.selectedItem && this.selectedItem.length > 0){
					const indexes = this.selectedItem.findIndex((item) => item.LINE_LOCATION_ID === this.poItems[index]["LINE_LOCATION_ID"]);
					if(indexes === -1) return;
					this.selectedItem[indexes]["CONFIRM_QUANTITY"] = this.poItems[index]["CONFIRM_QUANTITY"]
					this.selectedItem[indexes]["CONFIRM_PERCENTAGE"] = this.poItems[index]["CONFIRM_PERCENTAGE"]
					this.selectedItem[indexes]["CONFIRM_AMOUNT"] = this.poItems[index]["CONFIRM_AMOUNT"]
					
					this.amount = this.formatCurrency(this.selectedItem.reduce((a,b) => a + b.CONFIRM_AMOUNT, 0));
					this.totalAmount = this.selectedItem.reduce((a,b) => a + b.CONFIRM_AMOUNT, 0);
				}
			},
			async handleUploadFile() {
				let formData = new FormData();
				console.log( this.selectedPO.PO_HEADER_ID, "TES")
				console.log(this.file, "INI FILE")
				console.log(this.vendorId, "INI VENDOR")
				formData.append('file', this.file);
				formData.append('poHeaderId',  this.selectedPO.PO_HEADER_ID);
				formData.append('password', this.vendorId);

	
				// // Uploading file
				this.loadingUpload = true;
				const res = await BASTService.uploadFile(formData);

				if (res.status === 200) {
					this.fileUploaded = {
						filename: res.data.data.fileName,
						url: res.data.data.aossPayload.url,
						mimeType: res.data.data.mimeType,
						poFile: res.data.data.aossPayload,
					};
					// this.emailAttach.filename = res.data.data.fileName;
					this.$refs.inputFile.clear();
					this.loadingUpload = false;
					return res;
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'File Failed to Upload !',
						life: 3000,
					});
				}
				this.loadingUpload = false;
				await new Promise(resolve => setTimeout(resolve, 500));
			},
			async getCompanies() {
				const res = await PlanningOrderService.getCompany();
				this.companies = res.data.data;
			},
			handleChange(event){
				this.company = event.target.value
            },
            searchPoNum(event) {
                if (!event.query.trim().length) {
                    this.filteredPoNum = [...this.poNumberList];
                } else {
                    this.filteredPoNum = this.poNumList.filter((item) => item.SEGMENT1.toLowerCase().includes(event.query.toLowerCase()))
                }
            },
            async searchPO(){
               await this.getDetail()
            },
            searchBASTNum(event){
                console.log(event)
                this.bastNum = this.poNum.BAST.map((item, index) => {return{id: index, number: item}})
            },
            async getDetail(BAST_ID, status){
                const resp = await BASTService.getDetailBAST(JSON.stringify({
                    bast_id: BAST_ID
                }));

                this.selectedPO = {...resp.data.data.header}

                const payload = {
                    bast_id: Number(this.$route.params.id),
                    po_header_id: resp.data.data.header.PO_HEADER_ID
                }

                const respDetail = await BASTService.getBASTPoDetail(payload);

                this.vendorId = resp.data.data.header.VENDOR_ID

                const dataDetail = respDetail.data.data.detail.map((item) => {
                    return{
						id:item.LINE_LOCATION_ID,
						 ...item
						}
					}
                )

				this.selectedItem = resp.data.data.detail.map((item) => {
					item.TOTAL_PROGRESS_API = item.TOTAL_PROGRESS
					item.TOTAL_PROGRESS = item.TOTAL_PROGRESS + item.CONFIRM_PERCENTAGE
					return{
						id:item.LINE_LOCATION_ID,
						...item
					}
				});
				
                this.detailBefore = resp.data.data.detail.map((item) => {
					item.TOTAL_PROGRESS_API = item.TOTAL_PROGRESS
					item.TOTAL_PROGRESS = item.TOTAL_PROGRESS + item.CONFIRM_PERCENTAGE
					return{
						id:item.LINE_LOCATION_ID,
						...item
					}
				})

                this.amount = this.formatCurrency(this.selectedItem.reduce((a,b) => a + b.AMOUNT, 0));
                this.totalAmount = this.selectedItem.reduce((a,b) => a + b.AMOUNT, 0)
				
				if(status !== 'O'){
					this.poItems = [...this.selectedItem]
					return;
				}

                this.poItems = this.sortByShipmentAndLine([...this.selectedItem, ...dataDetail])
            },
			sortByShipmentAndLine(data) {
				const lineSort = data.sort((a, b) => {
					return a.LINE_NUM - b.LINE_NUM;
				});

				return lineSort
			},
            formatCurrency(value){
                if (value) return value.toLocaleString('id-ID');
                return;
            },
            onSelectFile(e){
                this.file = e.files[0]
            },
            formattingDate(date, withTime){
                const theDate = new Date(date);
                const strDate =
                    theDate.getFullYear() +
                    '-' +
                    (theDate.getMonth() + 1) +
                    '-' +
                    theDate.getDate();
                const strTime =
                    theDate.getHours() +
                    ':' +
                    theDate.getMinutes() +
                    ':' +
                    theDate.getSeconds();

                if (withTime) {
                    return `${strDate} ${strTime}`;
                } else {
                    return `${strDate}`;
                }
            },
			async validation(){
				const inputError = [];

				if(this.selectedItem.length < 1){
					inputError.push({
						name:'item',
						message: 'Please Selected one or more Line'
					})
				}

				if(!this.numBAST){
					inputError.push({
						name:'numBAST',
						message: 'Please Insert BAST Number'
					})
				
				}

				if(this.numBAST && this.numBAST.length < 8){
					inputError.push({
						name:'numBASTlength',
						message: 'Number bast must be minimum have 8 character'
					})
				}

				// const find = this.selectedItem.map((item) => !item.CONFIRM_QUANTITY || !item.CONFIRM_PERCENTAGE)

				// if(find){
				// 	inputError.push({
				// 		name:'Calculate',
				// 		message: 'Confirmed Quantity must be filled on selected item'
				// 	})
				// }

				return inputError;
			},	
            async saveBAST(type){
                try {
					this.progress = true
                    let payload = {}
             
					const validate = await this.validation();

					if(validate.length > 0){
						this.errorList = validate
						this.$toast.add({
							severity: 'error',
							summary: 'Failed!',
							detail: validate[0].message,
							life: 3000,
						});
						this.progress = false;
						return{
							status: 400,
							message: 'failed'
						}
					}

					const IS_BAST_HAVE_NULL = this.selectedItem.some((Q) => Q.CONFIRM_QUANTITY === 0 || Q.CONFIRM_QUANTITY === null);

					if(IS_BAST_HAVE_NULL){
						this.progress = false;
						this.$toast.add({
							severity: 'error',
							summary: 'Failed!',
							detail: 'Quantity must greater than 0',
							life: 3000,
						});
						return
					}


					let res;

					if(this.numBASTBefore !== this.numBAST){
						await BASTService.checkBastNum({
							bast_number: this.numBAST
						});
					}
					
					console.log(this.file, "TEST NTA")

					if(this.file){
						res = await this.handleUploadFile();
					}
					
				
                    let header = {
                        BAST_KEY_ID: this.$route.params.id,
                        ORG_ID: this.orgId,
                        VENDOR_ID: this.selectedPO.VENDOR_ID,
                        PO_NUMBER: this.selectedPO.SEGMENT1,
                        BAST_NUMBER: this.numBAST,
                        REVISION_NUM: this.selectedPO.REVISION_NUM,
                        CREATED_BY: Number(sessionStorage.getItem('idUser')),
                        CREATION_DATE: this.formattingDate(new Date(), true),
                        LAST_UPDATE_TIME: this.formattingDate(new Date(), true),
                        LAST_UPDATE_BY: Number(sessionStorage.getItem('idUser')),
                        STATUS: 'O',
						TYPE: type,
                        SHIP_TO_LOCATION_ID: this.selectedPO.SHIP_TO_LOCATION_ID,
                        PO_HEADER_ID: this.selectedPO.PO_HEADER_ID,
                        AMOUNT_TOTAL: Number(this.totalAmount),
						EMAIL_PURCHASING: this.selectedPO.EMAIL_PURCHASING,
						EMAIL_REQUESTOR: this.selectedPO.EMAIL_REQUESTOR,
						BUYER_NAME: this.selectedPO.BUYER_NAME,
						CURRENCY_CODE: this.selectedPO.CURRENCY_CODE,
						SHIP_TO_NAME: this.selectedPO.SHIP_TO_NAME
                    }


					if(res){
						console.log(res.data.data, "RESPONSE")
						header = {...header, FILE_NAME_OSS: res.data.data.aossPayload, FILE_NAME: res.data.data.fileName}
					}

				

                    const detail = this.selectedItem.map((item) => {
                        return{
                            PO_HEADER_ID: item.PO_HEADER_ID,
                            PO_LINE_ID: item.PO_LINE_ID,
                            AMOUNT: item.PRICE_OVERRIDE,
                            QUANTITY: item.QUANTITY,
                            ITEM_DESCRIPTION: item.ITEM_DESCRIPTION,
                            CREATED_BY: Number(sessionStorage.getItem('idUser')),
                            CREATION_DATE: this.formattingDate(new Date(), true),
                            LAST_UPDATE_TIME: this.formattingDate(new Date(), true),
                            LAST_UPDATE_BY: Number(sessionStorage.getItem('idUser')),
                            ORG_ID: this.orgId,
                            VENDOR_ID: this.vendorId,
                            BAST_NUMBER: this.numBAST,
                            LINE_NUM: item.LINE_NUM,
							TERMIN: item.TERMIN,
                            BAST_HEADER_ID: this.$route.params.id,
							ITEM_ID: item.ITEM_ID,
							LINE_LOCATION_ID: item.LINE_LOCATION_ID,
							SHIPMENT_NUM: item.SHIPMENT_NUM,
							PRICE_OVERRIDE: item.PRICE_OVERRIDE,
							REVISION_NUM: this.selectedPO.REVISION_NUM,
							CONFIRM_AMOUNT: item.CONFIRM_AMOUNT,
							CONFIRM_PERCENTAGE: item.CONFIRM_PERCENTAGE,
							CONFIRM_QUANTITY: item.CONFIRM_QUANTITY
                        }
                    });

                    const detailBeforeUpdate = this.detailBefore


                    payload.header = header;
                    payload.detail = detail;
                    payload.detailBeforeUpdate = detailBeforeUpdate
					
			
					await BASTService.editBAST(payload)

                    if(type === 'L'){
						this.$toast.add({
                        severity: "success",
                        summary: "Success",
                        detail: "BAST Successfully Edited",
                        life: 3000,
						});
						this.progress = false;

						return{
							status: 200,
							message: 'success'
						}
					}

                    await this.fetchBASTDetail();

					return{
						status: 200,
						message: 'success'
					}
                } catch (error) {
					console.log(error,"ERROR")
                  if(error.response){
                    this.$toast.add({
						severity: 'error',
						summary: 'Failed!',
						detail: error.response.data.message,
						life: 3000,
					});
					this.progress = false;

					return{
						status: 400,
						message: 'failed'
					}
                  }
                }
            },
			async cancelBAST(){
                try {
					this.progress = true

                    let payload = {
						BAST_KEY_ID: this.$route.params.id,
						PO_HEADER_ID: this.selectedPO.PO_HEADER_ID,
						REVISION_NUM: this.selectedPO.REVISION_NUM,
						STATUS: 'CANCEL',
						LAST_UPDATE_BY: Number(sessionStorage.getItem('idUser')),
						DB_INSTANCE: this.selectedPO.DB_INSTANCE
					}
					
					const cancelResp = await BASTService.cancelBAST(payload);

					if(cancelResp.status == 500){
						this.$toast.add({
							severity: 'error',
							summary: 'Failed!',
							detail: 'Failed to Cancel BAST',
							life: 3000,
						});

						return;
					}

					this.$toast.add({
						severity: 'success',
						summary: 'Success!',
						detail: 'Success to Cancel BAST',
						life: 3000,
					});

					return{
						status: 200,
						message: 'success'
					}
                } catch (error) {
					console.log(error,"ERROR")
                  if(error.response){
                    this.$toast.add({
						severity: 'error',
						summary: 'Failed!',
						detail: error.response.data.message,
						life: 3000,
					});
					this.progress = false;

					return{
						status: 400,
						message: 'failed'
					}
                  }
                }
            },
			async submitBAST(){
				try {
					this.progress = true;
					const resp = await this.saveBAST('S');

					if(resp.status !== 200){
						return;
					}

					const payload = {
						COMPANY: this.company,
						VENDOR_NAME: this.selectedPO.VENDOR_NAME,
						NO_BAST: this.numBAST,
						PO_NUM: this.poNum,
						BAST_ID: this.$route.params.id,
						BAST_TYPE: 'PROXY'
					}

					await BASTService.submitBAST(payload);

					this.$toast.add({
                        severity: "success",
                        summary: "Success",
                        detail: "BAST Successfully Submitted",
                        life: 3000,
                    });
					this.progress = false;
					await this.fetchBASTDetail();
				} catch (error) {
					console.log(error, "ERROR")
					if(error.response){
						this.$toast.add({
							severity: 'error',
							summary: 'Failed!',
							detail: error.response.data.message,
							life: 3000,
						});
					}
					this.progress = false;
				}
			},
			async confirmBAST(){
				try {
					this.progress = true;
					const payload = {
						COMPANY: this.company,
						VENDOR_NAME: this.selectedPO.VENDOR_NAME,
						NO_BAST: this.numBAST,
						PO_NUM: this.poNum,
						BAST_ID: this.$route.params.id,
						STATUS: 'C',
						SUPPLIER: this.selectedPO.CREATED_BY
					}

					console.log(payload, "PAYLOAD")

					let header = {
                        BAST_KEY_ID: this.$route.params.id,
                        ORG_ID: this.orgId,
                        VENDOR_ID: this.selectedPO.VENDOR_ID,
                        PO_NUMBER: this.selectedPO.PO_NUMBER,
                        BAST_NUMBER: this.numBAST,
                        REVISION_NUM: this.selectedPO.REVISION_NUM,
                        CREATED_BY: Number(sessionStorage.getItem('idUser')),
                        CREATION_DATE: this.formattingDate(new Date(), true),
                        LAST_UPDATE_TIME: this.formattingDate(new Date(), true),
                        LAST_UPDATE_BY: Number(sessionStorage.getItem('idUser')),
                        STATUS: 'C',
                        SHIP_TO_LOCATION_ID: this.selectedPO.SHIP_TO_LOCATION_ID,
                        PO_HEADER_ID: this.selectedPO.PO_HEADER_ID,
                        AMOUNT_TOTAL: Number(this.totalAmount),
						EMAIL_PURCHASING: this.selectedPO.EMAIL_PURCHASING,
						EMAIL_REQUESTOR: this.selectedPO.EMAIL_REQUESTOR,
						DB_INSTANCE: this.selectedPO.DB_INSTANCE,
                    }

					const detail = this.selectedItem.map((item) => {
                        return{
                            PO_HEADER_ID: item.PO_HEADER_ID,
                            PO_LINE_ID: item.PO_LINE_ID,
                            AMOUNT: item.AMOUNT,
                            QUANTITY: item.QUANTITY,
                            ITEM_DESCRIPTION: item.ITEM_DESCRIPTION,
                            CREATED_BY: Number(sessionStorage.getItem('idUser')),
                            CREATION_DATE: this.formattingDate(new Date(), true),
                            LAST_UPDATE_TIME: this.formattingDate(new Date(), true),
                            LAST_UPDATE_BY: Number(sessionStorage.getItem('idUser')),
                            ORG_ID: this.orgId,
                            VENDOR_ID: this.vendorId,
                            BAST_NUMBER: this.numBAST,
                            LINE_NUM: item.LINE_NUM,
							TERMIN: item.TERMIN,
                            BAST_HEADER_ID: this.$route.params.id,
							LINE_LOCATION_ID: item.LINE_LOCATION_ID,
							ITEM_ID: item.ITEM_ID,
							CONFIRM_AMOUNT: item.CONFIRM_AMOUNT,
							CONFIRM_PERCENTAGE: item.CONFIRM_PERCENTAGE,
							CONFIRM_QUANTITY: item.CONFIRM_QUANTITY
                        }
                    });

					payload.header = header;
					payload.detail = detail;

					await BASTService.picConfirmBAST(payload);

					await this.fetchBASTDetail();

					this.progress = false;
					this.$toast.add({
						severity: "success",
						summary: "Success",
						detail: "BAST Successfully Confirmed",
						life: 3000,
					});
				} catch (error) {
					console.log(error)
					if(error.response){
						this.$toast.add({
							severity: 'error',
							summary: 'Failed!',
							detail: error.response.data.message,
							life: 3000,
						});
					}else{
						alert("Fail to confirm BAST")
					}
				}
			},
			async rejectBAST(){
				try {
					this.progress = true;
					const payload = {
						COMPANY: this.company,
						VENDOR_NAME: this.selectedPO.VENDOR_NAME,
						NO_BAST: this.numBAST,
						PO_NUM: this.poNum,
						BAST_ID: this.$route.params.id,
						STATUS: 'O'
					}

					await BASTService.picRejectBAST(payload);


					await this.fetchBASTDetail();

					this.$toast.add({
						severity: "success",
						summary: "Success",
						detail: "BAST Successfully Rejected",
						life: 3000,
					});
					this.progress = false;
				} catch (error) {
					if(error.response){
						this.$toast.add({
							severity: 'error',
							summary: 'Failed!',
							detail: error.response.data.message,
							life: 3000,
						});
					}else{
						alert("Fail to confirm BAST")
					}
				}
			},
			onRowUnselect(data){
				console.log(data, "DATA")
				if(this.status === 'S' || this.status === 'C'){
					if(data.data){
						this.selectedItem.push(data.data)
					}else{
						this.selectedItem = this.detailBefore
					}
				}
			},
			onRemoveFile(){
				this.$refs.inputFile.clear();
			}
		}
    }
</script>

<style scoped>
	.p-button-download-bast{
		color: white;
		background: #e74c3c;
		border: 1px solid #C5E1A5;
		padding: 13px;
		border-radius: 5px;
		transition: .1s;
	}
	.p-button-download-bast:hover{
		color: white;
		background: #e74c3c;
		border: 1px solid #C5E1A5;
		padding: 13px;
		border-radius: 5px;
		opacity: 0.7;
	}
</style>