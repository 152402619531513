<template>
    <div>
    <AutoComplete :suggestions="filteredStatus" @complete="searchStatus($event)" :dropdown="true" field="status" v-model="status" placeholder="Status" :disabled="loading" forceSelection>
        <template #item="slotProp">
        {{ slotProp.item.status }}
        </template>
    </AutoComplete>
    </div>
</template>

<script setup>
import {onMounted, ref, watchEffect, defineEmits} from 'vue';
import AuthService from '../../../service/AuthService';
import SpecSrc from '../../../service/SpecSrc';

// eslint-disable-next-line no-undef
const props = defineProps({
    company: {
        type: Object
    },
    supplier: {
        type: Object
    },
    item_number: {
        type: Object
    },
    item_desc: {
        type: Object
    },
    preparer: {
        type: Object
    },
});

// eslint-disable-next-line no-undef
const status = ref();
const filteredStatus = ref();
const statusList = ref();
const loading = ref(false);

const emit = defineEmits(['status'])

watchEffect(() => {
    emit("status", status)
})

onMounted( async ()=>{
  await populateData()
})

const populateData = async () => {
//   
  AuthService.whoAmi().then(async (response) => {
    loading.value = true
    if (response.status === 200){
        if (props.company !== null ){
            const payload = {
                db_instance : props.company?props.company.db_instance:null,
                org_id : props.company?props.company.value:null,
                vendor_id : props.supplier?props.supplier.vendor_id:null,
                item_num : props.item_number?props.item_number.item_number:null,
                item_desc : props.itemDesc?props.itemDesc.item_desc:null,
            }
            const res = await SpecSrc.getStatus(payload)
            if (res == undefined || res.status === null){
              setTimeout( async () => {
                await populateData()
              }, 1000);
            } else if (res.status === 200) {
            statusList.value = res.data.data
            loading.value = false
            } else{
              statusList.value = { status : 'Not Found'}
              loading.value = false
            }
            }
    } else { 
      statusList.value = { status : 'Not Found'}
      loading.value = false
    }
    
  })
}

const searchStatus = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredStatus.value = [...statusList.value];
    } else {
      filteredStatus.value = statusList.value.filter((comp) => {
        return comp.status.toLowerCase().match(event.query.toLowerCase());
      });
    }
  }, 250);
};

// const getValue = (event) => {
//   supplierValue.value = event.value.vendor_name
//   supplier.value = event.value
//   emit("supplier", supplier)
// }
</script>