<template>
	<!-- eslint-disable -->
	<div class="Grid">
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<!-- Search -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Detail Vendor</div>
				<div class="content-body">
					<div class="col-12 p-fluid">
						<div class="grid formgrid" style="margin: 1em 0 0 0">
							<div class="col-12 mb-5 lg:col-4">
								<span class="p-float-label">
									<CompaniesDropdown
										v-model="companyDrop"
										class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
										:disabled="
											!(userInfo
												? userInfo.leveltenant === 2
												: false)
										"
									/>
									<label>Company</label>
								</span>
							</div>
							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<span class="p-float-label">
									<Category
										@remitCategory="getRemitCategory"
										:categoryId="
											categoryDrop ? categoryDrop : null
										"
										:company="company ? company : null"
										:supplier="supplier ? supplier : null"
										:key="categoryReload"
										:class="{
											'p-invalid':
												v$.remitCategory.$error,
										}"
										class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
									/>
									<label>Category</label>
									<small
										v-if="v$.remitCategory.$error"
										class="p-error"
										>{{
											v$.remitCategory.$errors[0].$message.replace(
												'Value',
												'Category'
											)
										}}</small
									>
								</span>
							</div>
							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<span class="p-float-label">
									<UserFinance
										@remitUserFinance="getRemitUserfinance"
										:userFinanceId="
											fndUser ? fndUser : null
										"
										:company="company ? company : null"
										:key="userFinanceReload"
										:class="{
											'p-invalid':
												v$.remitUserFinance.$error,
										}"
										:category="categoryName"
										class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
									/>
									<label> User Finance</label>
									<small
										v-if="v$.remitUserFinance.$error"
										class="p-error"
										>{{
											v$.remitUserFinance.$errors[0].$message.replace(
												'Value',
												'User Finance'
											)
										}}</small
									>
								</span>
							</div>
							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<span class="p-float-label">
									<CostCenter
										@remitCostCenter="getRemitCostCenter"
										:costCenterId="
											costCenterDrop
												? costCenterDrop
												: null
										"
										:company="company ? company : null"
										:supplier="supplier ? supplier : null"
										:key="costCenterReload"
										:class="{
											'p-invalid':
												v$.remitCostCenter.$error,
										}"
										class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
									/>
									<label>Cost Center</label>
									<small
										v-if="v$.remitCostCenter.$error"
										class="p-error"
										>{{
											v$.remitCostCenter.$errors[0].$message.replace(
												'Value',
												'Cost Center'
											)
										}}</small
									>
								</span>
							</div>
							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<span class="p-float-label">
									<Vendor
										@remitVendor="getRemitVendor"
										:vendorId="vendor ? vendor : null"
										:company="company ? company : null"
										:db_instance="
											company ? company.db_instance : null
										"
										:key="vendorReload"
										:vendorName="vendor ? vendor : null"
										:class="{
											'p-invalid': v$.remitVendor.$error,
										}"
										class="p-float-label"
									/>
									<label>Vendor</label>
									<small
										v-if="v$.remitVendor.$error"
										class="p-error"
										>{{
											v$.remitVendor.$errors[0].$message.replace(
												'Value',
												'Vendor '
											)
										}}</small
									>
								</span>
							</div>
							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<span class="p-float-label">
									<VendorSite
										@remitVendorSite="getRemitVendorSite"
										:vendorSiteId="
											vendorSiteDrop
												? vendorSiteDrop
												: null
										"
										:company="company ? company : null"
										:db_instance="company ? company : null"
										:vendor_id="vendor ? vendor : null"
										:key="vendorSiteReload"
										:class="{
											'p-invalid':
												v$.remitVendorSite.$error,
										}"
										class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
									/>
									<label>Vendor Site</label>
									<small
										v-if="v$.remitVendorSite.$error"
										class="p-error"
										>{{
											v$.remitVendorSite.$errors[0].$message.replace(
												'Value',
												'Vendor Site'
											)
										}}</small
									>
								</span>
							</div>

							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<div class="p-float-label p-input-icon-right">
									<InputText
										id="email"
										v-model="rekomendator"
										:class="{
											'p-invalid': v$.rekomendator.$error,
										}"
									/>
									<label for="email">Rekomendator</label>
									<small
										v-if="v$.rekomendator.$error"
										class="p-error"
										>{{
											v$.rekomendator.$errors[0].$message.replace(
												'Value',
												'Rekomendator'
											)
										}}
									</small>
									<small
										v-else-if="v$.rekomendator.$error"
										class="p-error"
										>{{
											v$.rekomendator.$errors[0].$message
										}}</small
									>
								</div>
							</div>

							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<div class="p-float-label p-input-icon-right">
									<InputText
										id="email"
										v-model="rekomendatorTwo"
									/>
									<label for="email">Rekomendator</label>
								</div>
							</div>

							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<div class="p-float-label p-input-icon-right">
									<InputText
										id="email"
										v-model="rekomendatorThree"
									/>
									<label for="email">Rekomendator</label>	
								</div>
							</div>

							<!--VALUE-->
							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<div class="p-float-label p-input-icon-right">
									<InputNumber
										class="col-number"
										:min="0"
										mode="decimal"
										locale="en-US"
										:minFractionDigits="2"
										:maxFractionDigits="2"
										style="text-align: right"
										v-model="rangeValue"
									/>
									<label for="email">Value</label>
									<!-- <small class="p-error">{{ generateError('value') }}</small> -->
								</div>
							</div>
							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<div class="p-float-label p-input-icon-right">
									<InputNumber
										class="col-number"
										:min="0"
										mode="decimal"
										locale="en-US"
										:minFractionDigits="2"
										:maxFractionDigits="2"
										style="text-align: right"
										v-model="rangeValueTwo"
									/>
									<label for="email">Value</label>
									<!-- <small class="p-error">{{ generateError('valueTwo') }}</small> -->
								</div>
							</div>
							<div class="col-12 mb-5 lg:col-4 lg:mb-5">
								<div class="p-float-label p-input-icon-right">
									<InputNumber
										class="col-number"
										:min="0"
										mode="decimal"
										locale="en-US"
										:minFractionDigits="2"
										:maxFractionDigits="2"
										style="text-align: right"
										v-model="rangeValueThree"
									/>
									<label for="email">Value</label>
									<!-- <small class="p-error">{{ generateError('valueThree') }}</small> -->
								</div>
							</div>
							<!--END VALUE-->

							<div class="mr-3 ml-2">
								<Button
									@click="update()"
									class="p-button-info uppercase"
									label="Update"
								/>
							</div>
							<div class="mr-3 ml-2">
								<Button
									class="p-button-secondary uppercase"
									label="Cancel"
									@click="cancel()"
								>
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Search results -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search Result</div>
				<div class="content-body">
					<div class="mt-4">
						<TableMasterVendorDetail
							:column="column ? column.col : null"
							:company="company ? company : null"
							:supplier="supplier ? supplier : null"
							:dataTable="masterVendorDetail"
							@masterVendorDetail="getDataTable"
							:key="reload"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref, watchEffect, computed } from 'vue';
import { email, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import AuthService from '../../service/AuthService';
import LovService from '../../service/LovService';
import PoFulfillment from '../../service/PoFulfillment';
import SimpleCrypto from 'simple-crypto-js';
import CompaniesDropdown from '@/components/components/CompaniesDropdown';
import UserFinance from '../../components/mastervendor/components/UserFinance';
import CostCenter from './components/CostCenter.vue';
import VendorSite from './components/VendorSite.vue';
// import Rekomendator from "./components/Rekomendator.vue";
import Category from './components/Category.vue';
import Vendor from './components/Vendor.vue';
import TableMasterVendorDetail from './components/TableMasterVendorDetail.vue';
import VendorServices from '../../service/masterVendorService';

const company = ref();
const categoryName = ref(null);
const supplier = ref();
const userInfo = ref();
const isSupplierpage = ref(true);
const categoryReload = ref(null);
const vendorReload = ref(null);
const userFinanceReload = ref(null);
const costCenterReload = ref(null);
const vendorSiteReload = ref(null);
const reload = ref(null);
const companyDrop = ref(null);
const categoryDrop = ref(null);
const fndUser = ref(null);
const costCenterDrop = ref(null);
const vendorSiteDrop = ref(null);
const rekomendator = ref(null);
const vendor = ref(null);
const vendorName = ref(null);
const route = useRoute();
const toast = useToast();
const router = useRouter();

const remitCategory = ref();
const remitUserFinance = ref();
const remitCostCenter = ref();
const remitVendorSite = ref();
const remitVendor = ref();
const masterVendorDetail = ref(null);
const rekomendatorTwo = ref(null);
const rekomendatorThree = ref(null);
const error = ref([])
const rangeValue = ref(null)
const rangeValueTwo = ref(null)
const rangeValueThree = ref(null)
const maping_id = ref(route.params.maping_id);
const breadcrumbHome = { icon: 'pi pi-home', to: '/dashboard' };
const breadcrumbItems = [
	{
		label: 'Master Vendor',
		to: '/mgm/master/vendor',
	},
	{
		label: 'Detail Master Vendor',
		to: route.fullPath,
	},
];

const validationRules = computed(() => {
	const rules = {
		rekomendator: { required, email },
		remitCategory: { required },
		remitUserFinance: { required },
		remitCostCenter: { required },
		remitVendor: { required },
		remitVendorSite: { required },
	};
	return rules;
});

const v$ = useVuelidate(validationRules, {
	rekomendator,
	remitCategory,
	remitUserFinance,
	remitCostCenter,
	remitVendor,
	remitVendorSite,
});

watchEffect(async () => {
	if (company.value !== null) {
		vendorReload.value++;
		categoryReload.value++;
		userFinanceReload.value++;
		reload.value++;
		costCenterReload.value++;
		vendorSiteReload.value++;
	}
});

// watch(
//   () => vendor.value,
//   (newValue) => {
//     if(typeof newValue === 'object' && !Array.isArray(newValue)) {
//       //
//     }
//     }
// )

onMounted(() => {
	AuthService.whoAmi().then(async (response) => {
		if (response.status === 200) {
			userInfo.value = response.data.data;
			if (userInfo.value.leveltenant === 2) {
				//get company
				isSupplierpage.value = true;
				const respCbN = await LovService.getCompanyByName(
					userCompany()
				);
				company.value = respCbN.data.data[0];

				//get supplier
				const param = {
					db_instance: company.value.db_instance,
					org_id: company.value.value,
				};

				const respVendor = await PoFulfillment.getSuppBindComp(
					response.data.data.id
				);

				const vendorName = respVendor.data.data[0][0].vendorname;
				const respSuppName = await LovService.getSupplierName(param);
				if (respSuppName.status === 200) {
					const suppList = respSuppName.data.data;
					for (const s of suppList) {
						if (s.vendor_name === vendorName) {
							supplier.value = s;
							break;
						}
					}
					// userSupplier.value = supplier.value
				}
			} else {
				await getData();
				const respCbN = await LovService.getCompanyByName(
					companyDrop.value
				);
				company.value = respCbN.data.data[0];
			}
		}
	});
});

const getRemitCategory = (value) => {
	remitCategory.value = value.value;
};

const getRemitUserfinance = (value) => {
	console.log(value.value, "VALUE")
	remitUserFinance.value = value.value;
};

const getRemitCostCenter = (value) => {
	remitCostCenter.value = value.value;
};

const getRemitVendorSite = (value) => {
	remitVendorSite.value = value.value;
};

const getRemitVendor = (value) => {
	remitVendor.value = value.value.vendor_id;
	if (remitVendor.value !== null) {
		vendor.value = value.value.vendor_id;
	}

	// vendorSiteReload.value++
};

const getDataTable = (value) => {
	masterVendorDetail.value = value.value;
};

const formattingDate = (date) => {
	const theDate = new Date(date);
	const strDate =
		theDate.getFullYear() +
		'-' +
		(theDate.getMonth() + 1) +
		'-' +
		theDate.getDate();

	return `${strDate}`;
};

const getData = async () => {
	const queryParams = route.query;
	company.value = {
		value: queryParams.v,
		db_instance: queryParams.i,
	};
	const paylaod = {
		maping_id: maping_id.value,
	};
	error.value = [];
	const result = await VendorServices.getDetailHeader(paylaod);
	console.log(result.data.data.data[0].CATEGORY_NAME, "RES DATA")
	categoryName.value = {
		CATEGORY_NAME: result.data.data.data[0].CATEGORY_NAME ? result.data.data.data[0].CATEGORY_NAME : null
	}
	// const type = typeof result;
	vendorName.value = result.data.data.data[0].vendor_name;
	companyDrop.value = result.data.data.data[0].COMPANY;
	categoryDrop.value = result.data.data.data[0].CATEGORY_ID;
	vendor.value = result.data.data.data[0].vendor_id;
	fndUser.value = result.data.data.data[0].user_id;
	const query = result.data.data.data[0].REKOMENDATOR.includes("[");
	const parsedData = query ? JSON.parse(result.data.data.data[0].REKOMENDATOR) : result.data.data.data[0].REKOMENDATOR
	
	if(Array.isArray(parsedData) === true){
		rekomendator.value = parsedData[0].rekomendator;
		rekomendatorTwo.value = parsedData[1].rekomendator;
		rekomendatorThree.value = parsedData[2].rekomendator;
		rangeValue.value = parsedData[0].value;
		rangeValueTwo.value = parsedData[1].value;
		rangeValueThree.value = parsedData[2].value;
	}else{
		rekomendator.value = parsedData
		rangeValue.value = null;
	}
	
	vendorSiteDrop.value = result.data.data.data[0].vendor_site_id;
	costCenterDrop.value = result.data.data.data[0].COST_CENTER;
};

// const handleSearch = () => {
//   reload.value++;
// };

const update = async () => {
	let payload = {}
	let type = '';

	if(!categoryDrop.value){
		const isFormValid = await v$.value.$validate();

		if (!isFormValid) {
			return;
		}
	}
	
	if(remitCategory.value.CATEGORY_NAME.toLocaleUpperCase().includes("PREPAYMENT")){
		console.log("FND USER", fndUser.value)
		type = 'PREPAYMENT'
		payload = {
			db_instance: company.value.db_instance,
			org_id: company.value.value,
			categoryDrop: categoryDrop.value,
			rekomendator: JSON.stringify([
				{
					rekomendator: rekomendator.value,
					value: rangeValue.value
				},
				{
					rekomendator: rekomendatorTwo.value,
					value: rangeValueTwo.value
				},
				{
					rekomendator: rekomendatorThree.value,
					value: rangeValueThree.value
				}
			]),
			maping_id: maping_id.value,
			fndUser: remitUserFinance.value.user_id,
			LAST_UPDATE_DATE: formattingDate(new Date(), true),
		}
	}else{
		const isFormValid = await v$.value.$validate();

		if (!isFormValid) {
			return;
		}
		console.log("UPDET BRO")
		type = 'NON PREPAYMENT'
		payload = {
			categoryDrop: remitCategory.value.CATEGORY_ID,
			costCenterDrop: remitCostCenter.value.SEGMENT4,
			vendorSiteDrop: remitVendorSite.value.vendor_site_id,
			rekomendator: JSON.stringify([
				{
					rekomendator: rekomendator.value,
					value: rangeValue.value
				},
				{
					rekomendator: rekomendatorTwo.value,
					value: rangeValueTwo.value
				},
				{
					rekomendator: rekomendatorThree.value,
					value: rangeValueThree.value
				}
			]),
			vendor: remitVendor.value,
			fndUser: remitUserFinance.value.user_id,
			maping_id: maping_id.value,
			STATUS: masterVendorDetail.value[0].status,
			LAST_UPDATE_DATE: formattingDate(new Date(), true),
		};
	}
	

	const update = await VendorServices.update({type,payload});
	// location.reload();
	if (update.status === 200) {
		toast.add({
			severity: 'success',
			summary: 'Update data success',
			life: 3000,
		});
	} else {
		toast.add({
			severity: 'error',
			summary: 'Update Error',
			life: 3000,
		});
		this.progress = false;
	}
};

const cancel = async () => {
	router.push({
		name: 'masterVendor',
	});
};

const userCompany = () => {
	const secretKey = 'some-unique-key';
	const simpleCrypto = new SimpleCrypto(secretKey);
	return simpleCrypto.decrypt(sessionStorage.getItem('dropdownCompany'));
};
</script>
