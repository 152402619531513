<template>
	<!-- eslint-disable -->
	<div
		class="card"
		style="background-color: #478fcd; height: 6.5em; margin: 0 0 -2em 0"
	>
		<h4 style="color: white">Top 10 Latest Invoices</h4>
	</div>
	<DataTable
		:value="top_invoices"
		v-model:selection="selectedInvoices"
		dataKey="id"
		:paginator="true"
		:rows="5"
		v-model:filters="filters3"
		paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
		:rowsPerPageOptions="[5, 10]"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
		responsiveLayout="scroll"
		:globalFilterFields="[
			'inv_num',
			'inv_date',
			'inv_amount',
			'tax_num',
			'tax_date',
			'tax_amount',
			'total',
			'status',
		]"
	>
		<Column
			field="inv_num"
			header="Invoice Number"
			:sortable="true"
			headerStyle="width:14%; min-width:10rem;"
		>
			<template #body="slotProps">
				{{ slotProps.data.inv_num }}
			</template>
		</Column>
		<Column
			field="inv_date"
			header="Invoice Date"
			:sortable="true"
			headerStyle="width:14%; min-width:10rem;"
		>
			<template #body="slotProps">
				{{ slotProps.data.inv_date }}
			</template>
		</Column>
		<Column
			field="inv_amount"
			header="Invoice Amount"
			:sortable="true"
			headerStyle="width:14%; min-width:10rem;"
		>
			<template #body="slotProps">
				{{ slotProps.data.inv_amount }}
			</template>
		</Column>
		<Column
			field="tax_num"
			header="Tax Number"
			:sortable="true"
			headerStyle="width:14%; min-width:10rem;"
		>
			<template #body="slotProps">
				{{ slotProps.data.tax_num }}
			</template>
		</Column>
		<Column
			field="tax_date"
			header="Tax Date"
			:sortable="true"
			headerStyle="width:14%; min-width:10rem;"
		>
			<template #body="slotProps">
				{{ slotProps.data.tax_date }}
			</template>
		</Column>
		<Column
			filterField="tax_amount"
			field="tax_amount"
			header="Tax Amount"
			:sortable="true"
			headerStyle="width:14%; min-width:10rem;"
		>
			<template #body="slotProps">
				{{ slotProps.data.tax_amount }}
			</template>
		</Column>
		<Column
			filterField="total"
			field="total"
			header="Total"
			:sortable="true"
			headerStyle="width:14%; min-width:10rem;"
		>
			<template #body="slotProps">
				{{ slotProps.data.total }}
			</template>
		</Column>
		<Column
			filterField="status"
			field="status"
			header="Status"
			:sortable="true"
			headerStyle="width:14%; min-width:10rem;"
		>
			<template #body="slotProps">
				{{ slotProps.data.status }}
			</template>
		</Column> </DataTable
	><br />
	<div
		class="card"
		style="background-color: #478fcd; height: 6.5em; margin: 0 0 -2em 0"
	>
		<h4 style="color: white">Top 10 Open Receipts</h4>
	</div>
	<DataTable
		:value="open_receipts"
		v-model:selection="selectedReceipts"
		dataKey="id"
		:paginator="true"
		:rows="5"
		v-model:filters="filters4"
		paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
		:rowsPerPageOptions="[5, 10]"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
		responsiveLayout="scroll"
		:globalFilterFields="[
			'po_no',
			'line_no',
			'item_no',
			'item_desc',
			'qty_po',
			'qty_rcv',
			'total1',
			'receipt_no',
			'trx_date',
		]"
	>
		<Column
			field="po_no"
			header="PO Number"
			:sortable="true"
			headerStyle="width:14%; min-width:10rem;"
		>
			<template #body="slotProps">
				{{ slotProps.data.po_no }}
			</template>
		</Column>
		<Column
			field="line_no"
			header="Line"
			:sortable="true"
			headerStyle="width:14%; min-width:10rem;"
		>
			<template #body="slotProps">
				{{ slotProps.data.line_no }}
			</template>
		</Column>
		<Column
			field="item_no"
			header="Item Number"
			:sortable="true"
			headerStyle="width:14%; min-width:10rem;"
		>
			<template #body="slotProps">
				{{ slotProps.data.item_no }}
			</template>
		</Column>
		<Column
			field="item_desc"
			header="Item Description"
			:sortable="true"
			headerStyle="width:14%; min-width:10rem;"
		>
			<template #body="slotProps">
				{{ slotProps.data.item_desc }}
			</template>
		</Column>
		<Column
			field="qty_po"
			header="Qty PO"
			:sortable="true"
			headerStyle="width:14%; min-width:10rem;"
		>
			<template #body="slotProps">
				{{ slotProps.data.qty_po }}
			</template>
		</Column>
		<Column
			filterField="qty_rcv"
			field="Qty Rcv"
			header="Tax Amount"
			:sortable="true"
			headerStyle="width:14%; min-width:10rem;"
		>
			<template #body="slotProps">
				{{ slotProps.data.qty_rcv }}
			</template>
		</Column>
		<Column
			filterField="total1"
			field="total1"
			header="Total"
			:sortable="true"
			headerStyle="width:14%; min-width:10rem;"
		>
			<template #body="slotProps">
				{{ slotProps.data.total1 }}
			</template>
		</Column>
		<Column
			filterField="receipt_no"
			field="receipt_no"
			header="Receipt No."
			:sortable="true"
			headerStyle="width:14%; min-width:10rem;"
		>
			<template #body="slotProps">
				{{ slotProps.data.receipt_no }}
			</template>
		</Column>
		<Column
			field="trx_date"
			header="Trx Date"
			:sortable="true"
			headerStyle="width:14%; min-width:10rem;"
		>
			<template #body="slotProps">
				{{ slotProps.data.trx_date }}
			</template>
		</Column>
	</DataTable>
</template>

<script>
/* eslint-disable */
import { FilterMatchMode, FilterOperator } from 'primevue/api';
// import ProductService from '../../service/ProductService';

export default {
	data() {
		return {
			breadcrumbHome: { icon: 'pi pi-home', to: '/' },
			breadcrumbItems: [{ label: 'Dashboard' }],
			items: [],

			top_invoices: null,
			invoice: {},
			selectedInvoices: null,

			open_receipts: null,
			receipts: {},
			selectedReceipts: null,

			openpo1s: null,
			openpo1: {},
			selectedOpenpo1: null,

			openpo2s: null,
			openpo2: {},
			selectedOpenpo2: null,
		};
	},
	// productService: null,

	created() {
		// this.productService = new ProductService();
	},
	mounted() {
		//    this.productService.getInvoice().then(inv_data => this.top_invoices = inv_data);
		// 	this.productService.getReceipt().then(receipt_data => this.open_receipts = receipt_data);
		// 	this.productService.getOpenpo1().then(data => this.openpo1s = data);
		// 	this.productService.getOpenpo2().then(data1 => this.openpo2s = data1);
		// this.productService.getCapa().then(data => this.capas = data);
	},
	methods: {},
};
</script>
