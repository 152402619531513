<template>
	<AutoComplete
		:suggestions="filteredPoNumber"
		@complete="search($event)"
		:dropdown="true"
		field="PONO"
		forceSelection
	>
		<template #item="slotProp">
			{{ slotProp.item.PONO }}
		</template>
	</AutoComplete>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect } from 'vue';
import PoHistoryService from '../../../service/PoHistoryService';

const filteredPoNumber = ref([]);
const poNumberList = ref([]);
const poNumber = ref();
// const placeholder = ref("Select a Batch Number");

const emit = defineEmits(['poNumber']);
watchEffect(() => emit('poNumber', poNumber));

// eslint-disable-next-line no-undef
const props = defineProps({
	company: {
		type: Object,
	},
	vendorId: {
		type: Object,
	},
	levelTenant: {
		type: String,
	},
});

onMounted(async () => {
	try {
		// console.log("props ::", props);
		if (props.company && props.vendorId && props.levelTenant) {
			const result = await PoHistoryService.getlistponumber(
				props.vendorId,
				props.company.db_instance,
				props.company.value,
				props.levelTenant
			);
			await result.data.forEach((element) => {
				if (element.PONO === null) {
					element.PONO = '';
				}
			});
			console.log('Result List PoNumber ::', result);

			poNumberList.value = await result.data;
		}

		// eslint-disable-next-line no-empty
	} catch (e) {
		throw new Error(e);
	}
});

const search = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredPoNumber.value = [...poNumberList.value];
    } else {
      filteredPoNumber.value = poNumberList.value.filter((poNum) => {
        console.log("poNum ::", poNum);
        return poNum.PONO
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};
</script>
