<template>
	<AutoComplete
		:suggestions="filteredStatus"
		@complete="searchBatch($event)"
		:dropdown="true"
		field="STATUS"
		placeholder="Status"
		:disabled="disable || !props.company || !props.supplier"
		forceSelection
	>
		<template #item="slotProp">
			{{ slotProp.item.STATUS }}
		</template>
	</AutoComplete>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import AuthService from '../../../service/AuthService';
import ListInvoiceNonPo from '../../../service/ListInvoiceNonPo';
// eslint-disable-next-line no-undef
const props = defineProps({
	company: { type: Object },
	supplier: { type: Object },
	invoiceNumber: { type: Object },
});
// eslint-disable-next-line no-undef
const filteredStatus = ref([]);
const status = ref([]);
const disable = ref(false);
onMounted(() => {
	populateData();
});
const populateData = () => {
	AuthService.whoAmi().then(async (response) => {
		if (response.status === 200) {
			disable.value = true;
			let payload;
			if (props.company !== null && props.supplier !== null) {
				payload = {
					org_id: props.company ? props.company.value : null,
					db_instance: props.company.db_instance,
					supValue: props.supplier ? props.supplier.vendor_id : null,
					invoice_num: props.invoiceNumber
						? props.invoiceNumber.INVOICE_NO
						: null,
				};
				const data = await ListInvoiceNonPo.getStatus(payload);
				status.value = data.data.data.data;
				disable.value = false;
			} else if (props.company !== null) {
				payload = {
					org_id: props.company ? props.company.value : null,
					db_instance: props.company.db_instance,
					supValue: props.supplier ? props.supplier.vendor_id : null,
					invoice_num: props.invoiceNumber
						? props.invoiceNumber.INVOICE_NO
						: null,
				};
				const data = await ListInvoiceNonPo.getStatus(payload);
				status.value = data.data.data.data;
				disable.value = false;
			}
		}
	});
};
const searchBatch = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredStatus.value = [...status.value];
		} else {
			filteredStatus.value = status.value.filter((comp) => {
				return comp.STATUS.toLowerCase().match(
					event.query.toLowerCase()
				);
			});
		}
	}, 250);
};
</script>

<style scoped></style>
