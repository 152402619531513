import { encryptForm } from "../helper/encryptForm";
import paymentApi from "./api/SpbReportApi"
class payment {
   
    async paymentReport(payload) {
        return paymentApi.post(`/spb/payment/searchPaymentReport`, payload)
    }

    async getPaymentReporttoExcel (payload) {
        return paymentApi.post(`/spb/payment/searchPaymentReportExcel` , payload)
    }

    async pprNumberList(payload) {
        return paymentApi.post(`/spb/payment/searchPPRNumber`, payload)
    }
    
    async  getReportPayment (payload) {
        return paymentApi.post(`/spb/payment/searchReportPayment/` , encryptForm(payload))
    }
    async  getReportPaymenttoExcel (payload) {
        return paymentApi.post(`/spb/payment/getDataExportToExcel/` , encryptForm(payload))
    }
    async getInvoiceNum(payload){
        return paymentApi.post('spb/payment/getInvoiceNum', payload);
    }

    async getInvoiceNumComp(payload){
        return paymentApi.post('spb/payment/getInvoiceNumComp', payload);
    }
  
    async  getReportPaymentSchedule (payload) {
        return paymentApi.post(`/spb/payment/searchReportPaymentSchedule/` , encryptForm(payload))
    }

    async  getReportPaymentShceduletoExcel (payload) {
        return paymentApi.post(`/spb/payment/exportReportPaymentSchedule/` , encryptForm(payload))
    }
    
}

export default new payment();