<template>
	<div class="grid list-invoice">
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<!-- Search input -->
		<div class="col-12 p-fluid mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search</div>
				<div v-if="currentUser" class="content-body">
					<div class="grid">
						<div class="col-12 md:col-6 lg:col-4 mb-4">
							<span class="p-float-label">
								<AutoComplete
									id="company"
									:disabled="!companyList"
									v-model="company"
									:suggestions="filteredCompany"
									@complete="searchCompany($event)"
									:dropdown="true"
									field="name"
									forceSelection
								/>
								<label for="company">Company</label>
							</span>
						</div>
						<div class="col-12 md:col-6 lg:col-4 mb-4">
							<span class="p-float-label">
								<Dropdown
									id="status"
									:disabled="!listStatus"
									:options="listStatus"
									optionLabel="value"
									v-model="status"
								/>
								<label for="status">Status</label>
							</span>
						</div>
						<div class="col-12 md:col-6 lg:col-4 mb-4">
							<span class="p-float-label">
								<AutoComplete
									id="supplier"
									:disabled="!supplierList"
									v-model="supplier"
									:suggestions="filteredSupplier"
									@complete="searchSupplier($event)"
									:dropdown="true"
									field="vendor_name"
									forceSelection
								/>
								<label for="supplier">Supplier</label>
							</span>
						</div>

						<!-- Search Buttons -->
						<div class="col-12 lg:col-4">
							<Button
								v-if="!isLoading"
								class="p-button-success w-auto mr-2"
								:disabled="disableSearchButton"
								@click="handleSearch"
							>
								<span class="font-bold uppercase">Search</span>
							</Button>
							<Button
								v-else
								class="p-button-success w-auto mr-2"
								disabled
							>
								<span class="font-bold uppercase"
									>Searching...</span
								>
							</Button>
							<Button
								class="w-auto p-button-secondary"
								@click="handleClear"
							>
								<span class="font-bold uppercase">Clear</span>
							</Button>
							<Button
								class="w-auto p-button-danger ml-2"
								@click="handleExport"
							>
								<span class="font-bold uppercase">Export</span>
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Search results -->
		<div class="col-12 p-fluid mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search Results</div>
				<div class="content-body">
					<div class="grid">
						<div class="col-12">
							<DataTable
								dataKey="id"
								responsiveLayout="scroll"
								ref="dt"
								:lazy="true"
								:paginator="true"
								:rows="5"
								:rowsPerPageOptions="[5, 10, 20, 50]"
								:loading="isLoading"
								:totalRecords="totalRecords"
								:value="listInvoice"
								:scrollable="true"
								scrollHeight="600px"
								scrollDirection="both"
								paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
								currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
								@page="handlePagination($event)"
								@sort="onSort($event)"
								removableSort
							>
								<!-- Search input -->
								<template #header>
									<div>
										<span class="p-input-icon-left w-full">
											<i class="pi pi-search" />
											<InputText
												class="w-full"
												type="text"
												placeholder="Search Invoice"
												v-model="searchKeyword"
												@keyup.enter="handleSearch"
												@page="onPage($event)"
											/>
										</span>
									</div>
								</template>

								<!-- Results Data -->
								<Column
									header="Supplier"
									field="vendor_name"
									style="width: 15%"
								/>
								<Column
									header="Tipe Invoice"
									field="type_form"
									style="width: 15%"
								>
									<template #body="{data}">
										{{data.type_form}}
									</template>
								</Column>
								<Column
									header="Invoice No"
									field="invoice_no"
									:sortable="true"
									style="width: 20%"
								/>
								<Column
									header="Invoice Amount"
									style="width: 9%"
								>
									<template #body="{ data }">
										{{
											new Intl.NumberFormat(
												'en-US'
											).format(data.invoice_amount)
										}}
									</template>
								</Column>
								<Column
									header="Invoice Date"
									style="width: 9%"
									field="invoice_date"
									:sortable="true"
								>
									<template #body="{ data }">
										{{ formatDate(data.invoice_date) }}
									</template>
								</Column>
								<Column
									header="Batch No"
									field="type_form"
									style="width: 15%"
								>
									<template #body="{data}">
										{{data.batch_no}}
									</template>
								</Column>
								<Column
									header="Payment Method"
									field="payment_method_lookup_code"
									style="width: 9%"
								>
									<template #body="{ data }">
										{{
											data.payment_method_lookup_code ||
											'-'
										}}
									</template>
								</Column>
								<Column
									header="Service Amount"
									style="width: 9%"
								>
									<template #body="{ data }">
										{{
											new Intl.NumberFormat(
												'en-US'
											).format(data.service_amount)
										}}
									</template>
								</Column>
								<Column header="Status" style="width: 15%">
									<template #body="{ data }">
										<Chip
											class="custom-chip"
											:class="`chip-${data.status.toLowerCase()}`"
										>
											{{ data.status }}
										</Chip>
									</template>
								</Column>
								<Column
									header="Tax No"
									field="tax_no"
									style="width: 15%"
								/>
								<Column header="Tax Amount" style="width: 9%">
									<template #body="{ data }">
										{{
											new Intl.NumberFormat(
												'en-US'
											).format(data.tax_amount)
										}}
									</template>
								</Column>
								<Column
									header="Tax Date"
									:sortable="true"
									field="tax_date"
									style="width: 9%"
								>
									<template #body="{ data }">
										{{ formatDate(data.tax_date) }}
									</template>
								</Column>
								<Column header="Total Amount" style="width: 9%">
									<template #body="{ data }">
										{{
											new Intl.NumberFormat(
												'en-US'
											).format(data.total_amount)
										}}
									</template>
								</Column>
								<Column
									header="Actions"
									style="width: 9%"
									alignFrozen="right"
									frozen
								>
									<template #body="{ data }">
										<Button
											icon="pi pi-eye"
											class="p-button-rounded p-button-text"
											@click="handleDetail(data)"
										/>
									</template>
								</Column>

								<!-- Empty statement -->
								<template #empty> No data found. </template>
							</DataTable>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref, watch, computed, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import SimpleCrypto from 'simple-crypto-js';
import AuthService from '../../service/AuthService';
import LovService from '../../service/LovService';
import InvoicePOService from '../../service/InvoicePOService';
import InvoiceCreateNonPOService from '../../service/InvoiceCreateNonPO';
import InvoiceListProxyService from '../../service/InvoiceListProxyService';
import { useToast } from "primevue/usetoast";
import XLSX from 'xlsx';

const toast = useToast();
// Router
const router = useRouter();
const route = useRoute();

// State
const currentUser = ref(null);
const company = ref(null);
const companyList = ref(null);
const filteredCompany = ref(null);
const listStatus = ref(null);
const status = ref(null);
const supplier = ref(null);
const loadingExport = ref(false);
const supplierList = ref(null);
const filteredSupplier = ref(null);
const searchKeyword = ref(null);
const listInvoice = ref(null);
const totalRecords = ref(0);
const isLoading = ref(false);
const lazyParams = ref(null);
const dt = ref(null);
const sortTable = ref(null);
const sortOrder = ref(null);
const search = ref(0);
const breadcrumbHome = ref({ icon: 'pi pi-home', to: '/dashboard' });
const breadcrumbItems = ref([
	{
		label: 'List Invoice Proxy',
		to: route.fullPath,
	},
]);

// Check if user logged in is PIC
const isUserPic = computed(
	() => currentUser.value && currentUser.value.leveltenant === 3
);

// Computed prop
const disableSearchButton = computed(() => {
	return !currentUser.value || !company.value;
});

// Mounted
onMounted(async () => {
	const resAuth = await AuthService.whoAmi();
	currentUser.value = resAuth.data.data;

	// Check if user is not PIC nor finance nor admin
	if (
		currentUser.value.leveltenant !== 3 &&
		currentUser.value.leveltenant !== 1 &&
		currentUser.value.userid !== 'dexaadmin'
	) {
		// Redirect to homepage
		router.push('/dashboard');
		return;
	}

	// Set lazy params
	lazyParams.value = {
		first: 0,
		page: 0,
		rows: dt.value.rows,
	};

	// Get company
	await getCompany();

	// Call get status list
	getStatusInvoice();

	// Call get suppler list
	getSupplierList();
});

// Unmounted
onUnmounted(() => {
	unwatch();
});

// Watch selected company change (for supplier users)
const unwatch = watch(company, (newCompany, oldCompany) => {
	// Watch if old company is not null
	if (oldCompany) {
		// Reset other input fields
		status.value = null;
		supplier.value = null;

		getSupplierList();
	}
});

// Formatting date
const formatDate = (date) => {
	return new Intl.DateTimeFormat('id-ID', {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	}).format(new Date(date));
};

// Get company list
const getCompany = async () => {
	if (currentUser.value.userid === 'dexaadmin') {
		// If user is admin
		const companyRes = LovService.getValueType('COMPANY');
		companyList.value = companyRes.data.data;
	} else if (currentUser.value.leveltenant === 3) {
		const userCompany = () => {
			const secretKey = 'some-unique-key';
			const simpleCrypto = new SimpleCrypto(secretKey);
			return simpleCrypto.decrypt(
				sessionStorage.getItem('dropdownCompany')
			);
		};

		const respCbN = await LovService.getCompanyByName(userCompany());
		company.value = respCbN.data.data[0];
	} else if (currentUser.value.leveltenant === 1) {
		// If user is Finance
		const res = await LovService.getcompanydb(currentUser.value.bioaddress);
		companyList.value = res.data.data;

		try {
			const secretKey = 'some-unique-key';
			const simpleCrypto = new SimpleCrypto(secretKey);
			const decipherText = simpleCrypto.decrypt(
				sessionStorage.getItem('dropdownCompany')
			);
			companyList.value.forEach((element) => {
				if (element.name === decipherText) {
					company.value = element;
					throw 'Break';
				}
			});
		} catch (e) {
			if (e !== 'Break') throw e;
		}
	}
};

// Get supplier list
const getSupplierList = async () => {
	const payload = {
		dbInstance: company.value.db_instance,
	};

	// Get supplier list for user Finance
	if (currentUser.value.leveltenant === 1) {
		try {
			const res = await InvoiceCreateNonPOService.getListVendor(payload);

			if (res.status === 200) {
				supplierList.value = res.data.data;
			}
		} catch (err) {
			console.log(err);
		}
	} else {
		try {
			// Get supplier list for user PIC
			payload.userId = currentUser.value.id;
			payload.companyName = company.value.name;

			const res = await InvoiceListProxyService.getSupplierList(payload);

			if (res.status === 200) {
				supplierList.value = res.data.data;
			}
		} catch (err) {
			console.log(err);
		}
	}
};

// Get status invoice list
const getStatusInvoice = async () => {
	try {
		const res = await InvoicePOService.getStatusInvoice();
		if (res.status === 200) {
			listStatus.value = res.data.data;
		}
	} catch (err) {
		console.log(err);
	}
};

// Search company on autocomplete
const searchCompany = (event) => {
	if (!event.query.trim().length) {
		filteredCompany.value = [...companyList.value];
	} else {
		filteredCompany.value = companyList.value.filter((item) => {
			return item.name.toLowerCase().match(event.query.toLowerCase());
		});
	}
};

// Search company on autocomplete
const searchSupplier = (event) => {
	if (!event.query.trim().length) {
		filteredSupplier.value = [...supplierList.value];
	} else {
		filteredSupplier.value = supplierList.value.filter((item) => {
			return (
				item.vendor_name &&
				item.vendor_name.toLowerCase().match(event.query.toLowerCase())
			);
		});
	}
};

// Handle pagination
const handlePagination = (event) => {
	lazyParams.value = event;
	handleSearch();
};

const onSort = async (event) => {
	sortTable.value = event.sortField;
	sortOrder.value = event.sortOrder;
	event.page = 0;
	lazyParams.value = event;

	if (search.value > 0) {
		await handleSearch();
	}
};

// Search invoice
const handleSearch = async () => {
	// Body payload
	const payload = {
		dbInstance: company.value.db_instance,
		orgId: company.value.value,
		status: status.value?.value || null,
		supplier: supplier.value?.vendor_id || null,
		searchKeyword: searchKeyword.value ? searchKeyword.value : null,
		userId: isUserPic.value ? currentUser.value.id : null,
		sort: sortTable.value ? sortTable.value : null,
		sortOrder: sortOrder.value ? sortOrder.value : null,
	};

	// Query params
	const params = {
		query: JSON.stringify({
			limit: lazyParams.value.rows,
			page: lazyParams.value.page + 1,
		}),
	};

	try {
		isLoading.value = true;
		const res = await InvoiceListProxyService.getListInvoice(
			params,
			payload
		);

		if (res.status === 200) {
			listInvoice.value = res.data.data.map((item) => ({
				...item,
				id: item.einvoice_id,
			}));
			totalRecords.value = res.data.count;
			search.value++;
		}

		isLoading.value = false;
	} catch (err) {
		console.log(err);
		isLoading.value = false;
	}
};

// Clear search
const handleClear = () => {
	status.value = null;
	supplier.value = null;
	searchKeyword.value = null;
	listInvoice.value = null;
	totalRecords.value = 0;
};

const handleExport = async () => {
	loadingExport.value = true;
	try {
		const payload = {
		dbInstance: company.value.db_instance,
		orgId: company.value.value,
		status: status.value?.value || null,
		supplier: supplier.value?.vendor_id || null,
		searchKeyword: searchKeyword.value ? searchKeyword.value : null,
		userId: isUserPic.value ? currentUser.value.id : null,
		sort: sortTable.value ? sortTable.value : null,
		sortOrder: sortOrder.value ? sortOrder.value : null,
		};

		const res = await InvoiceListProxyService.getExportPDF(
			payload
		);

		const data = res.data.data;

		let today = new Date();
		let dd = String(today.getDate()).padStart(2, '0');
		let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		let yyyy = today.getFullYear();
		let combinedDate = dd + mm + yyyy;
		let filename = 'List Invoice Proxy_' + combinedDate + '.xlsx';
		let ws = XLSX.utils.json_to_sheet(data);
		let wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'My Worksheet');
		XLSX.writeFile(wb, filename);


		loadingExport.value = false;
	} catch (error) {
		loadingExport.value = false;
		toast.add({
          severity: "error",
          summary: "Failed to export",
          detail: "Something issues on the server",
          life: 3000,
        });
	}
	
};

// Handle click detail
const handleDetail = (id) => {

	if(id.type_form){
		router.push({
			name: 'DetailListInvoiceProxySP1',
			params: { id: id.id },
			query: { v: company.value.value, i: company.value.db_instance },
		});
		return;
	}

	router.push({
		name: 'DetailListInvoiceProxy',
		params: { id: id.id },
		query: { v: company.value.value, i: company.value.db_instance },
	});
};
</script>

<style scoped>
.input-disabled {
	color: #000 !important;
}

.p-chip.custom-chip {
	padding: 4px 12px;
	border-radius: 8px;
	font-size: 12px;
	font-weight: 600;
}

.chip-open {
	background: #0ea5e9;
	color: #fff;
}
.chip-rejected {
	background: var(--primary-color);
	color: #fff;
}
.chip-review {
	background: #f59e0b;
	color: #fff;
}
.chip-posted {
	background: #10b981;
	color: #fff;
}
.chip-processed {
	background: #2563eb;
	color: #fff;
}
</style>
