<template>
	<div class="grid">
		<ProgressModal :progress="progress">
			<template #message>
				Save data in progress.<br />Please wait...
			</template>
		</ProgressModal>
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<!-- Shipment Info -->
		<div class="col-12 p-fluid mb-4">
			<div class="content-wrapper">
				<div class="content-header">{{detail}}</div>
				<div class="content-body">
                    <div class="grid formgrid">
                        <div class="col-12 mb-0 lg:col-12 lg:mb-0">
                        </div>
                    </div>
                    <form
                        @submit.prevent="handleSubmit(!v$.$invalid)"
                        class="p-fluid"
                    >
                        <div
                            class="grid formgrid"
                        >
                            <div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
                                <!-- <span
                                    style="color: #000; font-size: 15px;"
                                    class="font-semibold"
                                    >Item Number :</span
                                > -->
                                <div
                                    class="flex align-items-center justify-content-between mb-4"
                                >
                                    <span
                                        style="color: #000;display: flex; justify-content: flex-end"
                                        class="font-semibold col-2 lg:col-1  "
                                        >Item Number :</span
                                    >
                                    <InputText
                                    id="username"
                                    type="text"
                                    v-model="
                                        v$.itemNumber.$model
                                        "
                                    disabled
                                    />
                                    <span class="p-float-label col-8 lg:col-10" >
                                        <Button icon="pi pi-search" class="p-button-rounded p-button-info" @click="findItemNumber()" />
                                    </span>
                                </div>
                            </div>
                            <div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
                                <!-- <span
                                    style="color: #000; font-size: 15px;"
                                    class="font-semibold"
                                    >Item Number :</span
                                > -->
                                <div
                                    class="flex align-items-center justify-content-between mb-4"
                                >
                                    <span
                                        style="color: #000;display: flex; justify-content: flex-end"
                                        class="font-semibold col-2 lg:col-1 "
                                        >Description :</span
                                    >
                                    <InputText
                                        id="username"
                                        type="text"
                                        v-model="
                                            description
                                        "
                                    />
                                </div>
                            </div>
                            <div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
                                <!-- <span
                                    style="color: #000; font-size: 15px;"
                                    class="font-semibold"
                                    >Item Number :</span
                                > -->
                                <div
                                    class="flex align-items-left mb-4"
                                >
                                    <span
                                        style="color: #000;display: flex; justify-content: flex-end"
                                        class="font-semibold col-2 lg:col-1 "
                                        >UoM :</span
                                    >
                                    <div style="margin-top:-8px">
                                        <UoM :id="idValue?idValue:null" 
                                        :disabled="disabled?disabled:false" 
                                        :uomId="uomId?uomId:null"
                                        :orgId="orgId?orgId:null"
                                        @uom="getUom"
                                        :key="reload"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
                                <!-- <span
                                    style="color: #000; font-size: 15px;"
                                    class="font-semibold"
                                    >Item Number :</span
                                > -->
                                <div
                                    class="flex align-items-center justify-content-between mb-4"
                                >
                                    <span
                                        style="color: #000;display: flex; justify-content: flex-end"
                                        class="font-semibold col-2 lg:col-1  "
                                        >Supplier :</span
                                    >
                                    <InputText
                                    id="username"
                                    type="text"
                                    v-model="
                                        vendorName
                                        "
                                    disabled
                                    />
                                    <span class="p-float-label col-4 lg:col-6" >
                                        <Button icon="pi pi-search" class="p-button-rounded p-button-info" @click="findSupplier()" />
                                    </span>
                                </div>
                            </div>
                            <div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
                                <!-- <span
                                    style="color: #000; font-size: 15px;"
                                    class="font-semibold"
                                    >Item Number :</span
                                > -->
                                <div
                                    class="flex align-items-left mb-4"
                                >
                                    <span
                                        style="color: #000;display: flex; justify-content: flex-end"
                                        class="font-semibold col-2 lg:col-1 "
                                        >Operating Unit :</span
                                    >
                                    <div style="margin-top:-8px;margin-left:-8px" class="col-8 lg:col-4">
                                        <Operating :id="idValue?idValue:null" 
                                        :disabled="disabled"
                                        @operating="getOperating"
                                        :key="reload"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
                                <!-- <span
                                    style="color: #000; font-size: 15px;"
                                    class="font-semibold"
                                    >Item Number :</span
                                > -->
                                <div
                                    class="flex align-items-left mb-4"
                                >
                                    <span
                                        style="color: #000;display: flex; justify-content: flex-end"
                                        class="font-semibold col-2 lg:col-1 "
                                        >Organization :</span
                                    >
                                    <div style="margin-top:-8px;margin-left:-8px" class="col-10 lg:col-5">
                                        <Operating :id="idValue?idValue:null" 
                                        :disabled="disabled"
                                        @operating="getOperating"
                                        :key="reload"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
                                <!-- <span
                                    style="color: #000; font-size: 15px;"
                                    class="font-semibold"
                                    >Item Number :</span
                                > -->
                                <div
                                    class="flex align-items-center mb-4"
                                >
                                    <span
                                        style="color: #000;display: flex; justify-content: flex-end"
                                        class="font-semibold col-2 lg:col-1 "
                                        >Pack Size :</span
                                    >
                                    <div style="margin-left:-8px" class="col-4 lg:col-5">
                                        <InputText
                                            id="username"
                                            type="text"
                                            v-model="
                                                packSize
                                            "
                                        />
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
                                <!-- <span
                                    style="color: #000; font-size: 15px;"
                                    class="font-semibold"
                                    >Item Number :</span
                                > -->
                                <div
                                    class="flex align-items-left mb-4"
                                >
                                    <span
                                        style="color: #000;display: flex; justify-content: flex-end"
                                        class="font-semibold col-2 lg:col-1 "
                                        >Category :</span
                                    >
                                    <div style="margin-top:-8px;margin-left:-8px" class="col-6 lg:col-3">
                                        <Operating :id="idValue?idValue:null" 
                                        :disabled="disabled"
                                        @operating="getOperating"
                                        :key="reload"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
                                <!-- <span
                                    style="color: #000; font-size: 15px;"
                                    class="font-semibold"
                                    >Item Number :</span
                                > -->
                                <div
                                    class="flex align-items-left mb-4"
                                >
                                    <span
                                        style="color: #000;display: flex; justify-content: flex-end"
                                        class="font-semibold col-2 lg:col-1 "
                                        >Item Type :</span
                                    >
                                    <div style="margin-top:-8px;margin-left:-8px" class="col-4 lg:col-2">
                                        <Operating :id="idValue?idValue:null" 
                                        :disabled="disabled"
                                        @operating="getOperating"
                                        :key="reload"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
                                <!-- <span
                                    style="color: #000; font-size: 15px;"
                                    class="font-semibold"
                                    >Item Number :</span
                                > -->
                                <div
                                    class="flex align-items-left mb-4"
                                >
                                    <span
                                        style="color: #000;display: flex; justify-content: flex-end"
                                        class="font-semibold col-2 lg:col-1 "
                                        >QC Checking :</span
                                    >
                                    <div style="margin-top:-8px;margin-left:-8px" class="col-10 lg:col-5">
                                        <InputText
                                            id="username"
                                            type="text"
                                            v-model="
                                                qcChecking
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
                                <!-- <span
                                    style="color: #000; font-size: 15px;"
                                    class="font-semibold"
                                    >Item Number :</span
                                > -->
                                <div
                                    class="flex align-items-left mb-4"
                                >
                                    <span
                                        style="color: #000;display: flex; justify-content: flex-end"
                                        class="font-semibold col-2 lg:col-1 "
                                        >LT Release :</span
                                    >
                                    <div style="margin-top:-8px;margin-left:-8px" class="col-10 lg:col-5">
                                        <InputText
                                            id="username"
                                            type="text"
                                            v-model="
                                                ltRelease
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
                                <!-- <span
                                    style="color: #000; font-size: 15px;"
                                    class="font-semibold"
                                    >Item Number :</span
                                > -->
                                <div
                                    class="flex align-items-left mb-4"
                                >
                                    <span
                                        style="color: #000;display: flex; justify-content: flex-end"
                                        class="font-semibold col-2 lg:col-1 "
                                        >LT Send :</span
                                    >
                                    <div style="margin-top:-8px;margin-left:-8px" class="col-10 lg:col-5">
                                        <InputText
                                            id="username"
                                            type="text"
                                            v-model="
                                                ltSend
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
                                <!-- <span
                                    style="color: #000; font-size: 15px;"
                                    class="font-semibold"
                                    >Item Number :</span
                                > -->
                                <div
                                    class="flex align-items-left mb-4"
                                >
                                    <span
                                        style="color: #000;display: flex; justify-content: flex-end"
                                        class="font-semibold col-2 lg:col-1 "
                                        >LT Total :</span
                                    >
                                    <div style="margin-top:-8px;margin-left:-8px" class="col-10 lg:col-5">
                                        <InputText
                                            id="username"
                                            type="text"
                                            v-model="
                                                ltTotal
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
                                <!-- <span
                                    style="color: #000; font-size: 15px;"
                                    class="font-semibold"
                                    >Item Number :</span
                                > -->
                                <div
                                    class="flex align-items-left mb-4"
                                >
                                    <span
                                        style="color: #000;display: flex; justify-content: flex-end"
                                        class="font-semibold col-2 lg:col-1 "
                                        >ROP Vendor :</span
                                    >
                                    <div style="margin-top:-8px;margin-left:-8px" class="col-10 lg:col-5">
                                        <InputText
                                            id="username"
                                            type="text"
                                            v-model="
                                                ropVendor
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
                                <!-- <span
                                    style="color: #000; font-size: 15px;"
                                    class="font-semibold"
                                    >Item Number :</span
                                > -->
                                <div
                                    class="flex align-items-left mb-4"
                                >
                                    <span
                                        style="color: #000;display: flex; justify-content: flex-end"
                                        class="font-semibold col-2 lg:col-1 "
                                        >ROP Multiplier :</span
                                    >
                                    <div style="margin-top:-8px;margin-left:-8px" class="col-10 lg:col-5">
                                        <InputText
                                            id="username"
                                            type="text"
                                            v-model="
                                                ropMultiplier
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mb-1 lg:col-12 lg:mb-1" style="margin-top:5px">
                                <!-- <span
                                    style="color: #000; font-size: 15px;"
                                    class="font-semibold"
                                    >Item Number :</span
                                > -->
                                <div
                                    class="flex align-items-left mb-4"
                                >
                                    <span
                                        style="color: #000;display: flex; justify-content: flex-end"
                                        class="font-semibold col-2 lg:col-1 "
                                        >Lead Time :</span
                                    >
                                    <div style="margin-top:-8px;margin-left:-8px" class="col-10 lg:col-5">
                                        <InputText
                                            id="username"
                                            type="text"
                                            v-model="
                                                leadTime
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                            </div>
							</form>
					<form
						@submit.prevent="handleSubmit(!v$.$invalid)"
						class="p-fluid"
					>
						<div class="p-fluid grid formgrid">
							<div class="col-6 mb-2 lg:col-1 lg:mb-0">
								<Button
									:class="uppercase"
									class="p-button-secondary"
									label="Back"
									@click="button('B')"
								>
								</Button>
							</div>
							<div
								class="col-6 mb-2 lg:col-1 lg:mb-0"
                                v-if="!createDisabled"
							>
								<Button
									type="submit"
									label="Save"
									@click="button('S')"
									class="w-full p-button-success"
								/>
							</div>
							<div
								class="col-6 mb-2 lg:col-1 lg:mb-0"
                                v-if="createDisabled"
							>
								<Button
									type="confirm"
									label="Create"
									@click="button('C')"
									class="p-button-info"
								/>
							</div>
							
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>

<Dialog
    header="Item Number"
    v-model:visible="dialog"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '15vw' }"
    :modal="true"
>
    <div
        class="col-12 mb-12 md:col-12 lg:col-12 lg:mb-12"
        style="margin-top: 20px"
    >
        <Operating :id="idValue?idValue:null" 
        :disabled="disabled"
        @operating="getOperating"
        :key="reload"/>
    </div>
    <template #footer>
        <Button
            label="No"
            icon="pi pi-times"
            @click="closeModal"
            class="p-button-text"
        />
        <Button
            label="Yes"
            icon="pi pi-check"
            @click="yesModal"
            autofocus
        />
    </template>
</Dialog>

<Dialog
    header="Supplier"
    v-model:visible="dialogSupplier"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '15vw' }"
    :modal="true"
>
    <div
        class="col-12 mb-12 md:col-12 lg:col-12 lg:mb-12"
        style="margin-top: 20px"
    >
        <Operating :id="idValue?idValue:null" 
        :disabled="disabled"
        @operating="getOperating"
        :key="reload"/>
    </div>
    <template #footer>
        <Button
            label="No"
            icon="pi pi-times"
            @click="closeModal"
            class="p-button-text"
        />
        <Button
            label="Yes"
            icon="pi pi-check"
            @click="yesModal"
            autofocus
        />
    </template>
</Dialog>

</template>

<script setup>
// import AuthService from '../../../service/AuthService';
import AuthService from '../../../service/AuthService';
import SimpleCrypto from 'simple-crypto-js';
// import PoFulfillment from '../../../service/PoFulfillment';
import {filterAuthByVendorname} from "@/helper/filterAuthByVendorname";
import LovService from '../../../service/LovService';
import ItemJit from '../../../service/ItemJit';
import UoM from '../components/UoM.vue'
import Operating from '../components/Operating.vue'
import ProgressModal from '../../components/ProgressModal.vue';

import {
	useToast
} from 'primevue/usetoast';
import {
	computed,
	onMounted,
	ref
} from 'vue';
import {
	useVuelidate
} from '@vuelidate/core';
import {
	useRoute,
	useRouter
} from 'vue-router';
import {
	required,
} from '@vuelidate/validators';

// eslint-disable-next-line no-undef
const router = useRoute();
const routerPage = useRouter();
const company = ref();
const supplier = ref(null);
const userInfo = ref();
const toast = useToast();
const buttonValue = ref();
const submitted = ref();
const levelTenant = ref();
const idValue = ref(router.params.id);
const progress = ref(false);
const detail = ref();
const itemNumber = ref();
const description = ref();
const uom = ref();
const uomId = ref();
const uomValue = ref();
const vendorName = ref();
const operating = ref();
const orgId = ref();
const packSize = ref();
const category = ref();
const itemType = ref();
const qcChecking = ref();
const ltRelease = ref();
const ltSend = ref();
const ltTotal = ref();
const ropVendor = ref();
const ropMultiplier = ref();
const leadTime = ref();
const createDisabled = ref(false);
const dialog = ref(false);
const dialogSupplier = ref(false);
const reload = ref(0);
const breadcrumbHome = ref({
	icon: 'pi pi-home',
	to: '/'
});
const breadcrumbItems = ref([{
		label: 'Search Item Jit',
		to: '/mgm/settings/trxcostlist',
	},
	{
		label: detail,
		to: router.fullPath,
	},
]);

const validationRules = computed(() => {
	const rules = {
		itemNumber: {
			required
		}, 
	};
	return rules;
});

const v$ = useVuelidate(validationRules, {
    itemNumber,
});

onMounted(() => {
	populateData();
});

const populateData = () => {
	AuthService.whoAmi().then(async (response) => {
		if (response.status === 200) {
			checkTenant();
            
            if (idValue.value != 0){
                detail.value = 'Detail Item Jit'
                const payload = {id : idValue.value}
                const data = await ItemJit.getDetail(payload)
                itemNumber.value = data.data.data.data[0].item_number
                description.value = data.data.data.data[0].item_desc
                uom.value = data.data.data.data[0].uom
                uomId.value = data.data.data.data[0].uom_code
                vendorName.value = data.data.data.data[0].vendor_name
                operating.value = data.data.data.data[0].vendor_name
                orgId.value = data.data.data.data[0].org_id
                packSize.value = data.data.data.data[0].pack_size
                category.value = data.data.data.data[0].category
                itemType.value = data.data.data.data[0].item_type
                qcChecking.value = data.data.data.data[0].qc_checking
                ltRelease.value = data.data.data.data[0].lt_release
                ltSend.value = data.data.data.data[0].lt_send
                ltTotal.value = data.data.data.data[0].lt_total
                ropVendor.value = data.data.data.data[0].rop_vendor
                ropMultiplier.value = data.data.data.data[0].rop_plant_ml
                leadTime.value = data.data.data.data[0].lead_time
                reload.value++
            } else {
                detail.value = 'Create/Add Item Jit'
                createDisabled.value = true
            }
		}
	});
};

const checkTenant = async () => {
	AuthService.whoAmi().then(async (response) => {
		const userCompany = () => {
			const secretKey = 'some-unique-key';
			const simpleCrypto = new SimpleCrypto(secretKey);
			return simpleCrypto.decrypt(
				sessionStorage.getItem('dropdownCompany')
			);
		};
		if (response.status === 200) {
			userInfo.value = response.data.data;
			levelTenant.value = response.data.data.leveltenant;
			if (userInfo.value.leveltenant === 2) {
				//get company
				const respCbN = await LovService.getCompanyByName(
					userCompany()
				);
				company.value = respCbN.data.data[0];
				//get supplier
				const param = {
					db_instance: company.value.db_instance,
					org_id: company.value.value,
				};

				const checkAuth = await filterAuthByVendorname({
                info_id: userInfo.value.id,
                dbInstance: param.db_instance,
                value: param.org_id
                });

                if(checkAuth.supplier === null) router.push("/dashboard");

                supplier.value = checkAuth.supplier;
			} else {
				const respCbN = await LovService.getCompanyByName(
					userCompany()
				);
				company.value = respCbN.data.data[0];
			}
		}
	});
};

const getUom = async (event) => {
    uomValue.value = event.value
}

const button = async (status) => {
	if (status === 'B') {
		routerPage.back();
	} else {
		buttonValue.value = status;
	}
};

const handleSubmit = async (isFormValid) => {
    submitted.value = true;
    if (!isFormValid) {
        toast.add({
            severity: 'error',
            summary: 'All Fields are Required',
            life: 3000,
        });
        return;
    } else if (buttonValue.value === 'S'){
        try{
            progress.value = true
            const payload = {
                item_number: itemNumber.value,
                description: description.value, 
                uom : uom.value,
                uomId : uomId.value,
                vendor_name : vendorName.value, 
                operating : operating.value,
                org_id : orgId.value,
                pack_size : packSize.value,
                category : category.value,
                item_type : itemType.value,
                qc_checking : qcChecking.value,
                lt_release : ltRelease.value,
                lt_send : ltSend.value,
                lt_total : ltTotal.value,
                rop_vendor : ropVendor.value,
                rop_multiplier : ropMultiplier.value,
                lead_time : leadTime.value,
            }
            const save = await ItemJit.saveData(payload)
            if (save.status === 200){
                toast.add({
                    severity: 'success',
                    summary: 'Save Success',
                    life: 3000,
                });
            } else {
                toast.add({
                    severity: 'error',
                    summary: 'Data Error',
                    life: 3000,
                });
            }
            submitted.value = false;
            progress.value = false;
        } catch {
            toast.add({
                severity: 'error',
                summary: 'Disconnected',
                detail: 'internet is unstable',
                life: 3000,
            });
            location.reload()
        }
        
    }
    else if (buttonValue.value === 'C'){
        try{
            progress.value = true
            const payload = {
                item_number: itemNumber.value,
                description: description.value, 
                uom : uom.value,
                uomId : uomId.value,
                vendor_name : vendorName.value, 
                operating : operating.value,
                org_id : orgId.value,
                pack_size : packSize.value,
                category : category.value,
                item_type : itemType.value,
                qc_checking : qcChecking.value,
                lt_release : ltRelease.value,
                lt_send : ltSend.value,
                lt_total : ltTotal.value,
                rop_vendor : ropVendor.value,
                rop_multiplier : ropMultiplier.value,
                lead_time : leadTime.value,
            }
            const save = await ItemJit.createData(payload)
            if (save.status === 200){
                toast.add({
                    severity: 'success',
                    summary: 'Save Success',
                    life: 3000,
                });
            } else {
                toast.add({
                    severity: 'error',
                    summary: 'Data Error',
                    life: 3000,
                });
            }
            submitted.value = false;
            progress.value = false;
        } catch {
            toast.add({
                severity: 'error',
                summary: 'Disconnected',
                detail: 'internet is unstable',
                life: 3000,
            });
            location.reload()
        }
        
    }
};

const findItemNumber = async () => {
    dialog.value = true
}

const findSupplier = async () => {
    dialogSupplier.value = true
}

const closeModal = () => {
	dialog.value = false
    dialogSupplier.value = false
};

const yesModal = () => {
	dialog.value = false
    dialogSupplier.value = false
};
</script>

<style scoped>
.input-number,
:disabled {
	background: #a19f9f !important;
}

/* input.dropdown {
	color: #000 !important;
} */
.form-card {
	padding: 10px;
	border: 1px solid #e0e0e0;
	border-radius: 16px;
}
</style>
