<template>
  <!-- eslint-disable -->
  <Toast />
  <div class="Grid">
    <div class="col-12">
      <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
    </div>
    <!-- Search -->
    <div class="col-12 mb-4">
      <div class="content-wrapper">
        <div class="content-header">Search PO Interface</div>
        <div class="content-body">
          <div class="col-12 p-fluid">
            <div class="grid formgrid" style="margin: 1em 0 0 0">
            <div class="col-2 lg:col-1">
                <SplitButton icon="pi pi-search" :model="items" class="p-button-text mb-2"></SplitButton>
            </div>
              <div class="col-10 mb-5 lg:col-3">
                  <span class="p-float-label">
                    <InputText
                        id="inputtext"
                        type="text"
                        v-model="searchValue" 
                    />
                    <label for="inputtext">{{search}}</label>
                </span>
              </div>
              <div class="mr-3 ml-2">
                <Button
                  @click="handleSearch()"
                  class="p-button-success uppercase"
                  label="search"
                  :disabled="loading"
                />
              </div>
              <div>
                <Button
                  @click="clearFilter()"
                  class="p-button-secondary uppercase"
                  label="clear"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Search results -->
    <div class="col-12">
      <div class="content-wrapper">
        <div class="content-header">Search Result</div>
        <div class="content-body">
          <div class="mt-4">
            <TablePOInterface :search="searchValue?searchValue:null" :choose="valueChoose?valueChoose:null"
			:key="reloadTable"></TablePOInterface>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue-demi";
import AuthService from "../../service/AuthService";
import { useToast } from 'primevue/usetoast';
import SimpleCrypto from 'simple-crypto-js';
import LovService from '../../service/LovService';
import PoFulfillment from '../../service/PoFulfillment';
import TablePOInterface from '../pointerface/component/TablePoInterface.vue'

// eslint-disable-next-line no-undef
const company = ref();
const userInfo = ref();
const levelTenant = ref();
const supplier = ref();
const isSupplierpage = ref(false);
const reloadTable = ref(0);
const search = ref('All Columns');
const searchValue = ref();
// const ponumber = ref();
// const poNumberReload = ref();
const valueChoose = ref(null);
const breadcrumbHome = { icon: "pi pi-home", to: "/" };
const breadcrumbItems = [
  { label: "Search PO Interface", to: "./pointerface" },
];
// Toast intance
const toast = useToast();
const items = ref([
            {
                label: 'All Columns',
                command: () => {
                    toast.add({severity:'success', summary:'Change Search', detail:'Search for All Columns', life: 3000});
                    search.value = 'All Columns'
                    valueChoose.value = null
                }
            },
            {
                label: 'ID',
                command: () => {
                    toast.add({severity:'success', summary:'Change Search', detail:'Search for ID Columns', life: 3000});
                    search.value = 'ID'
                    valueChoose.value = 'id'
                }
            },
            {
                label: 'Process',
                command: () => {
                    toast.add({severity:'success', summary:'Change Search', detail:'Search for Process Columns', life: 3000});
                    search.value = 'Process'
                    valueChoose.value = 'process'
                }
            },
            {   label: 'Action',
                command: () => {
                    toast.add({severity:'success', summary:'Change Search', detail:'Search for Action Columns', life: 3000});
                    search.value = 'Action'
                    valueChoose.value = 'action'
				}
            },
            {   label: 'PO Number',
                command: () => {
                    toast.add({severity:'success', summary:'Change Search', detail:'Search for Po Number Columns', life: 3000});
                    search.value = 'PO Number'
                    valueChoose.value = 'ponumber'
				}
            },
            {   label: 'Org ID',
                command: () => {
                    toast.add({severity:'success', summary:'Change Search', detail:'Search for Org ID Columns', life: 3000});
                    search.value = 'Org ID'
                    valueChoose.value = 'orgid'
				}
            },
            {   label: 'PO Type',
                command: () => {
                    toast.add({severity:'success', summary:'Change Search', detail:'Search for PO Type Columns', life: 3000});
                    search.value = 'PO Type'
                    valueChoose.value = 'potype'
				}
            },
            {   label: 'Currency',
                command: () => {
                    toast.add({severity:'success', summary:'Change Search', detail:'Search for Currency Columns', life: 3000});
                    search.value = 'Currency'
                    valueChoose.value = 'currency'
				}
            },
            {   label: 'Supplier',
                command: () => {
                    toast.add({severity:'success', summary:'Change Search', detail:'Search for Supplier Columns', life: 3000});
                    search.value = 'Supplier'
                    valueChoose.value = 'supplier'
				}
            },
            {   label: 'ToP',
                command: () => {
                    toast.add({severity:'success', summary:'Change Search', detail:'Search for ToP Columns', life: 3000});
                    search.value = 'ToP'
                    valueChoose.value = 'top'
				}
            },
            {   label: 'Freight',
                command: () => {
                    toast.add({severity:'success', summary:'Change Search', detail:'Search for Freight Columns', life: 3000});
                    search.value = 'Freight'
                    valueChoose.value = 'freight'
				}
            },
            {   label: 'Intercom',
                command: () => {
                    toast.add({severity:'success', summary:'Change Search', detail:'Search for Intercom Columns', life: 3000});
                    search.value = 'Intercom'
                    valueChoose.value = 'intercom'
				}
            },
            {   label: 'View',
                command: () => {
                    toast.add({severity:'success', summary:'Change Search', detail:'Search for View Columns', life: 3000});
                    search.value = 'View'
                    valueChoose.value = 'view'
				}
            },
        ]);

onMounted(() => {
    populateData();
})

const populateData = () => {
    AuthService.whoAmi().then(async (response) => {
        if (response.status === 200){
            checkTenant()
            reloadTable.value++
        }
    })
}

const checkTenant = async () => {
	AuthService.whoAmi().then(async (response) => {
		const userCompany = () => {
			const secretKey = 'some-unique-key';
			const simpleCrypto = new SimpleCrypto(secretKey);
			return simpleCrypto.decrypt(
				sessionStorage.getItem('dropdownCompany')
			);
		};
		if (response.status === 200) {
			userInfo.value = response.data.data;
			levelTenant.value = response.data.data.leveltenant;
			if (userInfo.value.leveltenant === 2) {
				//get company
                isSupplierpage.value = true;
				const respCbN = await LovService.getCompanyByName(
					userCompany()
				);
				company.value = respCbN.data.data[0];
				//get supplier
				// 
				const param = {
					db_instance: company.value.db_instance,
					org_id: company.value.value,
				};

				const respVendor = await PoFulfillment.getSuppBindComp(
					response.data.data.id
				);
				// 
				const vendorName = respVendor.data.data[0][0].vendorname;
				const respSuppName = await LovService.getSupplierName(param);
				if (respSuppName.status === 200) {
					//   
					const suppList = respSuppName.data.data;
					for (const s of suppList) {
						if (s.vendor_name === vendorName) {
							supplier.value = s;
							break;
						}
					}
				}
				// 
			} else {
				const respCbN = await LovService.getCompanyByName(
					userCompany()
				);
				company.value = respCbN.data.data[0];
				// 
			}
		}
	});
};

const handleSearch = async () => {
    reloadTable.value++
}

const clearFilter = async () => {
    searchValue.value = null
    reloadTable.value++
}
</script>