import { encryptForm } from "../helper/encryptForm";
import invoice from "./api/SpbInvoiceApi";
class ListInvoiceNonPO {
    async getInvoiceNum(payload){
        return invoice.post('spb/listinvoicenonpo/invoicenumber', payload);
    }
    async getStatus(payload){
        return invoice.post('spb/listinvoicenonpo/statustable', payload);
    }
    async getTable(payload){
        return invoice.post('spb/listinvoicenonpo/tablelist', encryptForm(payload));
        // .catch(err => {
        //     if (err.request){
        //         return{status : 444, data : "Disconnected"}
        //     }
        // });
    }
    async getExport(payload){
        return invoice.post('spb/listinvoicenonpo/exportpdf', payload);
    }
    async getVAT(payload){
        return invoice.post('spb/listinvoicenonpo/vatdropdown', payload);
    }
    async getVATID(payload){
        return invoice.post('spb/listinvoicenonpo/vatdropdownid', payload);
    }
    async getDetailData(payload){
        return invoice.post('spb/listinvoicenonpo/detailheader', payload)
    }
    async getDetailDataTable(payload){
        return invoice.post('spb/listinvoicenonpo/detailtableline', payload)
    }
    async getDetailDataTravelOrder(payload){
        return invoice.post('spb/listinvoicenonpo/travelorders', payload)
    }
    async getDetailCat(payload){
        return invoice.post('spb/listinvoicenonpo/listcategory', payload)
    }
    async getDetailCatSK(payload){
        return invoice.post('spb/listinvoicenonpo/listcategorywsk', payload)
    }
    async getDetailTableReimburse(payload){
        return invoice.post('spb/listinvoicenonpo/detailtablereimburse', payload)
    }
    async getReferenceNumber(payload){
        return invoice.post('spb/listinvoicenonpo/referencenumber', payload)
    }
    async saveData(id, payload){
        return invoice.post(`spb/listinvoicenonpo/savedata/${id}`, payload);
        // .catch(err => {
        //     if (err.request){
        //         return{status : 444, data : "Disconnected"}
        //     }
        // });
    }
    async getVendors(payload){
        return invoice.post('spb/listinvoicenonpo/getvendors', payload)
    }
    async getGroupName(payload){
        return invoice.post('spb/listinvoicenonpo/getgroupname', payload)
    }
    async deleteReimburse(payload){
        return invoice.post('spb/listinvoicenonpo/deletereimburse', payload)
    }
    async deleteLine(payload){
        return invoice.post('spb/listinvoicenonpo/deleteline', payload)
    }
    async getSupplierName(payload){
        return invoice.post('spb/listinvoicenonpo/suppliername', payload)
    }
    async exportDataDownload(payload){
        return invoice.post('spb/listinvoicenonpo/exportdata', payload)
    }
    async getAttachmentFile(payload) {
		return invoice.post(`spb/listinvoicenonpo/attachmentfile`, payload);
	}
    async getListDetailCat() {
		return invoice.get(`spb/listinvoicenonpo/getseqdetailcat`);
	}
    async getListDetailReimburse() {
		return invoice.get(`spb/listinvoicenonpo/getseqdetailreimburse`);
	}
    async getFileBase64(invoiceId, leveltenant, vendorId) {
		return invoice.get(`/invoicepo/getfilebase64/${invoiceId}/${leveltenant}/${vendorId}`);
	}
}
export default new ListInvoiceNonPO();
