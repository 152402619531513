<template>
	<div class="grid">
		<ProgressModal :progress="progress">
			<template #message>
				Submit Purchase Order in progress.<br />Please wait...
			</template>
		</ProgressModal>
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		<ConfirmDialog group="positionDialog"></ConfirmDialog>
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<!-- PO Header -->
		<div class="col-12 p-fluid">
			<div class="card">
				<div class="p-fluid grid formgrid" style="margin-top: 0.5em">
					<div class="col-12 mb-4 md:col-6 lg:col-4 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								type="text"
								v-model="poSegment"
								disabled
							/>
							<label for="inputtext">PO Number</label>
						</span>
					</div>
					<div class="col-12 mb-4 md:col-6 lg:col-4 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								type="text"
								v-model="confirmDate"
								disabled
							/>
							<label for="inputtext">Confirm Date</label>
						</span>
					</div>
					<div class="col-12 mb-4 md:col-6 lg:col-4 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								type="text"
								v-model="statusHeader"
								disabled
							/>
							<label for="inputtext">Status</label>
						</span>
					</div>
					<div class="col-12 mb-4 md:col-6 lg:col-4 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								type="text"
								v-model="buyer"
								disabled
							/>
							<label for="inputtext">Buyer</label>
						</span>
					</div>
					<div class="col-12 mb-4 md:col-6 lg:col-4 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								type="text"
								v-model="revisionNum"
								disabled
							/>
							<label for="inputtext">Rev</label>
						</span>
					</div>
					<div class="col-12 mb-4 md:col-6 lg:col-4 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								type="text"
								v-model="shipTo"
								disabled
							/>
							<label for="inputtext">Ship To</label>
						</span>
					</div>
					<div class="col-12 mb-4 md:col-6 lg:col-4 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								type="text"
								v-model="currency"
								disabled
							/>
							<label for="inputtext">Currency</label>
						</span>
					</div>
					<div class="col-12 mb-4 md:col-6 lg:col-4 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								type="text"
								v-model="confirmBy"
								disabled
							/>
							<label for="inputtext">Confirm By</label>
						</span>
					</div>
				</div>

				<!-- Terms -->
				<div
					class="card"
					style="
						background-color: #ffecec;
						height: 2em;
						margin: 0 0 2em 0;
					"
				>
					<h5 style="color: #da3831; margin-top: -10px">Terms</h5>
				</div>
				<div class="p-fluid grid formgrid">
					<div class="col-12 mb-4 md:col-6 lg:col-4 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								type="text"
								v-model="top"
								disabled
							/>
							<label for="inputtext">ToP</label>
						</span>
					</div>
					<div class="col-12 mb-4 md:col-6 lg:col-4 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								type="text"
								v-model="freightTerms"
								disabled
							/>
							<label for="inputtext">Freight</label>
						</span>
					</div>
					<div class="col-12 mb-4 md:col-6 lg:col-4 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								type="text"
								v-model="incoterm"
								disabled
							/>
							<label for="inputtext">Incoterm</label>
						</span>
					</div>
				</div>

				<!-- Attachment File -->
				<div
					class="card"
					style="
						background-color: #ffecec;
						height: 2em;
						margin: 0 0 2em 0;
					"
				>
					<h5 style="color: #da3831; margin-top: -10px">
						Attachment Document
					</h5>
				</div>
				<!-- Download File button -->
				<div class="p-fileupload">
					<div class="fileupload-buttonbar">
						<table>
							<tr v-if="attachmentFilePur">
								<td>
									<h5>PO File</h5>
								</td>
								<td>
									<h5>:</h5>
								</td>
								<td>
									<h5>
										<span
											class="inline-block mx-2 font-bold"
											v-if="!filePurIsEmpty"
											>{{
												attachmentFilePur.FILENAME
											}}</span
										>
									</h5>
								</td>
								<td>
									<h5>
										<!-- <a
											:href="this.linkFilePur"
											class="p-button-success"
											v-if="!filePurIsEmpty"
											_target="blank"
										>
											<i class="pi pi-download"></i
											><span class="ml-2">Download</span>
										</a> -->
										<Button
											class="p-button-success"
											label="Download"
											icon="pi pi-download"
											@click="onDownload()"
											:disabled="loadingFilePur"
											v-if="
												!filePurIsEmpty &&
												!loadingFilePur
											"
										/>
										<Button
											class="p-button-success"
											label="File is Downloading"
											icon="pi pi-download"
											:disabled="loadingFilePur"
											v-if="
												!filePurIsEmpty &&
												loadingFilePur
											"
										/>
									</h5>
								</td>
								<td>
									<h5>
										<Button
											label="Remove"
											class="p-button-danger"
											@click="
												onRemovePur(
													this.filePur.PO_HEADER_ID
												)
											"
											v-if="status !== 'C'"
										>
											<i class="pi pi-times"></i>
											<span class="ml-2">Remove</span>
										</Button>
									</h5>
								</td>
							</tr>
							<tr v-if="attachmentFileSupp">
								<td>
									<h5>Confirm PO File</h5>
								</td>
								<td>
									<h5>:</h5>
								</td>
								<td>
									<h5>
										<span
											class="inline-block mx-2 font-bold"
											v-if="!fileSuppIsEmpty"
											>{{
												attachmentFileSupp.FILENAME
											}}</span
										>
									</h5>
								</td>
								<td>
									<h5>
										<!-- <a
                      :href="this.linkFileSupp"
                      class="p-button-success"
                      _target="blank"
                    >
                      <i class="pi pi-download"></i
                      ><span class="ml-2">Download</span>
                    </a> -->
										<Button
											class="p-button-success"
											label="Download"
											icon="pi pi-download"
											@click="onDownloadSupp()"
											:disabled="loadingFileSupp"
											v-if="
												!fileSuppIsEmpty &&
												!loadingFileSupp
											"
										/>
										<Button
											class="p-button-success"
											label="File is Downloading"
											icon="pi pi-download"
											:disabled="loadingFileSupp"
											v-if="
												!fileSuppIsEmpty &&
												loadingFileSupp
											"
										/>
									</h5>
								</td>
							</tr>
						</table>
					</div>
					<div class="p-fileupload-buttonbar"></div>
					<div class="p-fileupload-buttonbar"></div>
				</div>
				<div>
					<!-- <FileUpload
						id="uploadFile"
						mode="advanced"
						@select="onSelect"
						:multiple="false"
						:customUpload="true"
						accept="application/pdf"
						:maxFileSize="1000000"
						:showUploadButton="false"
						:showCancelButton="false"
						:fileLimit="1"
					>
					</FileUpload> -->
					<!-- :maxFileSize="1000000" -->
					<FileUpload
						id="uploadFile"
						mode="advanced"
						@select="onSelect"
						:multiple="false"
						:customUpload="true"
						accept=".pdf"
						:showUploadButton="false"
						:showCancelButton="false"
						:fileLimit="1"
						:maxFileSize="5000000"
						v-tooltip.left="'Max file 5MB'"
					>
						<template #empty>
							<p>No file selected. (Max file 5MB)</p>
						</template>
					</FileUpload>
					<!-- <FileUpload
			id="uploadFile"
			fileLimit=1
			chooseLabel="Upload File"
			chooseIcon="pi pi-upload"
			:customUpload="true"
			@uploader="handleUploadFile"
				/> -->
				</div>
			</div>
		</div>

		<!-- Items -->
		<div class="col-12 p-fluid">
			<div class="card">
				<div
					class="card"
					style="
						background-color: #ffecec;
						height: 2em;
						margin: 0 0 2em 0;
					"
				>
					<h5 style="color: #da3831; margin-top: -10px">Items</h5>
				</div>

				<div class="col-12 mb-5">
					<DataTable
						:value="detailItems"
						dataKey="line_num"
						:paginator="true"
						:rows="5"
						v-model:filters="filters"
						:rowClass="rowClass"
						removableSort
						sortField="line_num"
						:sortOrder="1"
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
						:rowsPerPageOptions="[5, 10, 25]"
						:loading="loading"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
						responsiveLayout="scroll"
						:scrollable="true"
						scrollHeight="600px"
					>
						<Column header="Line" style="width: 9%">
							<template #body="slotProps">
								{{ slotProps.index + 1 }}
							</template>
						</Column>
						<Column
							field="item_number"
							header="Item Number"
							style="width: 12%"
						></Column>
						<Column
							field="item_description"
							header="Item Description"
							style="width: 11%"
						></Column>
						<Column field="uom" header="UOM" style="width: 9%">
						</Column>
						<!-- <Column field="currency_code" header="Currency">
						</Column> -->
						<Column
							header="Unit Price"
							dataType="numeric"
							style="width: 9%"
						>
							<template #body="{ data }">
								{{ formatCurrency(data.unit_price) }}
							</template>
						</Column>
						<Column
							field="quantity"
							header="Quantity"
							dataType="numeric"
							style="width: 9%"
						>
						</Column>
						<Column
							field="note_to_vendor"
							header="Note to Supplier"
							style="width: 9%"
						>
						</Column>
						<Column
							field="promised_date"
							header="Arrival at Site (based at incoterm)"
							style="width: 9%"
						>
							<!-- <template #body="{ data }">
								{{ data.need_by_date }}
							</template> -->
						</Column>
						<Column
							field="estimate_supplier_date"
							header="Est. Arrival at Site (based at incoterm)"
							style="width: 9%"
						>
						</Column>
						<Column
							header="Reason for not on time"
							field="reason_not_on_time"
							style="width: 9%"
						>
						</Column>
						<Column
							field="name"
							header="Line Status"
							style="width: 9%"
						>
						</Column>
					</DataTable>
				</div>

				<div class="p-fluid grid formgrid">
					<div class="col-6 mb-2 lg:col-1 lg:mb-0">
						<Button
							type="button"
							label="Back"
							class="p-button-secondary"
							@click="back()"
						/>
					</div>
					<div class="col-6 mb-2 lg:col-1 lg:mb-0">
						<Button v-if="status !== 'C'" type="button" label="Save" @click="save()" />
					</div>
				</div>
			</div>
		</div>
	</div>
	<Dialog
		header="Validation File"
		v-model:visible="displayPilih"
		:breakpoints="{ '960px': '20vw' }"
		:style="{ width: '10vw' }"
		:modal="true"
	>
		<div class="confirmation-content">
			<span>No File</span>
		</div>
	</Dialog>
</template>

<script>
/* eslint-disable */
import PoFulfillment from '../../../service/PoFulfillment';
import AuthService from '../../../service/AuthService';
export default {
	name: 'po-fulfillment-detail',
	data() {
		return {
			progress: false,
			poKeyId: null,
			idValue: this.$route.params.id,
			headerValue: this.$route.params.idHeader,
			idDetail: null,
			poHeaderId: null,
			poSegment: null,
			confirmDate: null,
			status: null,
			buyer: null,
			revisionNum: null,
			currency: null,
			confirmBy: null,
			confirmReqId: null,
			confirmType: null,
			dbInstance: null,
			detailItems: null,
			detailTerms: null,
			detailFiles: null,
			filePur: null,
			filePurIsEmpty: false,
			fileSupp: null,
			fileSuppIsEmpty: false,
			location: null,
			isiLocation: null,
			filteredLocation: null,
			displayResponsive: false,
			changeTable: false,
			planningOrderTable: null,
			selectedPo: null,
			selectedTerms: null,
			termsTable: null,
			poType: null,
			isiPoType: null,
			filteredPoType: null,
			selectedPoType: null,
			uom: null,
			isiUom: null,
			freight: null,
			isiFreight: null,
			tablepopuppo: null,
			arrivalDate: null,
			top: null,
			isiTop: null,
			incoterm: null,
			isiIncoterm: null,
			status: null,
			changeStatus: null,
			unholdStatus: null,
			changeHold: false,
			changeUnhold: false,
			popUpPo: null,
			filteredPopUpPo: null,
			poNumber: null,
			qty: null,
			submitpo: false,
			isInvalid: true,
			displayValidasi: false,
			displayPilih: false,
			something: null,
			disabled: [],
			something: null,
			line: null,
			untukIndex: null,
			payloadNotAccept: null,
			displaySubmit: false,
			respondStatus: true,
			confOption: 'manual',
			statusHeader: null,
			idUser: null,
			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [
				{ label: 'Po Fulfillment', to: '/mgm/settings/sysparamslist' },
				{ label: 'Detail Po Fulfillment', to: this.$route.path },
			],
			linkFilePur: null,
			linkFileSupp: null,
			vendorId: null,
			soFileBuffer: null,
			spFileBuffer: null,
			loadingFilePur: false,
			loadingFileSupp: false,
		};
	},
	created() {
		if (this.idValue != 0) {
			AuthService.whoAmi().then((response) => {
				if (response.status === 200) {
					this.idUser = response.data.data.id;
					const payloadDetail = {
						headerId: this.idValue,
						leveltenant: response.data.data.leveltenant,
					};
					// Get PO Header
					PoFulfillment.getDetailHeader(payloadDetail).then(
						async (response) => {
							if ((await response.data.status) === 200) {
								this.poKeyId = await response.data.data
									.PO_KEY_ID;
								this.vendorId = await response.data.data
									.VENDOR_ID;
								this.statusHeader = await response.data.data
									.NAME;
								//
								this.poHeaderId = await response.data.data
									.PO_HEADER_ID;
								this.poSegment =
									response.data.data.SEGMENT1 &&
									response.data.data.SEGMENT1.toLowerCase() !==
										'null'
										? response.data.data.SEGMENT1
										: '';
								this.status = response.data.data.STATUS || '';
							
								this.buyer =
									response.data.data.BUYER_NAME &&
									response.data.data.BUYER_NAME.toLowerCase() !==
										'null'
										? response.data.data.BUYER_NAME
										: '';
								this.revisionNum =
									response.data.data.REVISION_NUM;
								this.shipTo =
									response.data.data.SHIP_TO_NAME &&
									response.data.data.SHIP_TO_NAME.toLowerCase() !==
										'null'
										? response.data.data.SHIP_TO_NAME
										: '';
								this.currency =
									response.data.data.CURRENCY_CODE &&
									response.data.data.CURRENCY_CODE.toLowerCase() !==
										'null'
										? response.data.data.CURRENCY_CODE
										: '';
								this.confirmBy =
									response.data.data.CONFIRM_SP_NAME &&
									response.data.data.CONFIRM_SP_NAME.toLowerCase() !==
										'null'
										? response.data.data.CONFIRM_SP_NAME
										: '';
								this.confirmReqId =
									response.data.data.CONFIRM_REQ_ID;
								this.confirmType =
									response.data.data.CONFIRM_TYPE &&
									response.data.data.CONFIRM_TYPE.toLowerCase() !==
										'null'
										? response.data.data.CONFIRM_TYPE
										: '';
								this.dbInstance =
									response.data.data.DB_INSTANCE &&
									response.data.data.DB_INSTANCE.toLowerCase() !==
										'null'
										? response.data.data.DB_INSTANCE
										: '';

								this.confirmDate =
									response.data.data.CONFIRM_DATE &&
									response.data.data.CONFIRM_DATE !==
										'0000-00-00'
										? new Date(
												response.data.data.CONFIRM_DATE
										  ).toLocaleString('id-ID', {
												day: 'numeric',
												month: 'short',
												year: 'numeric',
										  })
										: '';

								// Get PO Terms
								const resTerms =
									await PoFulfillment.getDetailTerms(
										this.poKeyId
									);

								this.detailTerms = resTerms.data.data[0];
								//
								// 	'PO Terms Res:',
								// 	this.detailTerms
								// );
								this.freightTerms =
									resTerms.data.data[0].FREIGHT_TERMS &&
									resTerms.data.data[0].FREIGHT_TERMS.toLowerCase() !==
										'null'
										? resTerms.data.data[0].FREIGHT_TERMS
										: '';
								// Make string capitalize
								this.freightTerms =
									this.freightTerms.toLowerCase();
								this.freightTerms =
									this.freightTerms.charAt(0).toUpperCase() +
									this.freightTerms.slice(1);

								// Get others
								this.getTop(this.dbInstance);
								this.getIncoterm(this.dbInstance);
								// Get PO Files
								PoFulfillment.getDetailFiles(
									this.poHeaderId
								).then((response) => {
									this.detailFiles = response.data.data;
								});

								// PoFulfillment.getDetailFiles(payload).then(
								// 	(response) => {
								//
								// 		this.detailFiles = response.data.data;
								// 	}
								// );
								// Get link download file
								const payloadDownload = {
									poHeaderId: this.poHeaderId,
									revisionNum: this.revisionNum,
									// userreceiveid: this.usersenderid,
								};
								await PoFulfillment.downloadFilePur(
									payloadDownload
								).then((response) => {
									if (response.status === 200) {
										this.linkFilePur = response.data.data;
									} else {
										this.$toast.add({
											severity: 'error',
											summary: 'Connection Unstable !',
											life: 3000,
										});
									}
								});

								await PoFulfillment.downloadFileSupp(
									payloadDownload
								).then((response) => {
									if (response.status === 200) {
										this.linkFileSupp = response.data.data;
									} else {
										this.$toast.add({
											severity: 'error',
											summary: 'Connection Unstable !',
											life: 3000,
										});
									}
								});

								// const payloadDownloadTenant = {
								//   poHeaderId: this.poHeaderId,
								//   revisionNum: this.revisionNum,
								//   leveltenant: sessionStorage.getItem("leveltenant"),
								//   vendorId: await response.data.data.VENDOR_ID,
								// };
								// await PoFulfillment.downloadFilePurWithTenant(
								//   payloadDownloadTenant
								// ).then(async (response) => {
								//   //

								//   if ((await respose.status) === 200) {
								//     this.soFileBuffer = await response.data.data;
								//   } else {
								//     this.$toast.add({
								//       severity: "error",
								//       summary: "Connection Unstable !",
								//       life: 3000,
								//     });
								//   }
								// });

								// await PoFulfillment.downloadFileSuppWithTenant(
								//   payloadDownloadTenant
								// ).then(async (response) => {
								//   //

								//   if ((await respose.status) === 200) {
								//     this.spFileBuffer = await response.data.data;
								//   } else {
								//     this.$toast.add({
								//       severity: "error",
								//       summary: "Connection Unstable !",
								//       life: 3000,
								//     });
								//   }
								// });
							}
						}
					);

					// Get PO Items
					if (this.idValue !== null) {
						const payload = this.idValue;

						PoFulfillment.getDetailItems(payload).then(
							(response) => {
								this.detailItems = response.data.data.map(
									(item) => {
										item.estimate_supplier_date = new Date(
											item.estimate_supplier_date
										).toLocaleString('id-ID', {
											day: 'numeric',
											month: 'short',
											year: 'numeric',
										});
										item.need_by_date = new Date(
											item.need_by_date
										).toLocaleString('id-ID', {
											day: 'numeric',
											month: 'short',
											year: 'numeric',
										});
										if (
											item.estimate_supplier_date ==
											'Invalid Date'
										) {
											item.estimate_supplier_date = '';
										}
										item.need_by_date = new Date(
											item.need_by_date
										);
										if (
											item.need_by_date ==
												'Invalid Date' ||
											item.need_by_date == '1 Jan 1970'
										) {
											item.need_by_date = '';
										}
										item.promised_date = new Date(
											item.promised_date
										).toLocaleString('id-ID', {
											day: 'numeric',
											month: 'short',
											year: 'numeric',
										});
										if (
											item.promised_date ==
												'Invalid Date' ||
											item.promised_date == '1 Jan 1970'
										) {
											item.promised_date = '';
										}
										if (
											item.note_to_vendor && item.note_to_vendor.toLowerCase() ===
											'null'
										) {
											item.note_to_vendor = '';
										}
										item.currency_code = this.currency;
										if (
											item.reason_not_on_time ===
												'null' ||
											item.reason_not_on_time === null
										) {
											item.reason_not_on_time = '';
										}
										// this.company.value ? this.company.value : null
										return item;
									}
								);
							}
						);
					}
				}
			});
		}
		// this.getLocation();
		// this.getPoType();
		// this.getUom();
		// this.getFreight();
		// this.getTablePoNumber();
	},
	watch: {},
	mounted() {},
	computed: {
		headerStatus() {
			// let status;
			// const itemStatus = this.statusHeader;
			// //
			// const isUseItemStatus =
			// 	this.detailItems &&
			// 	this.detailItems.every((item) => item.status === itemStatus);
			// if (isUseItemStatus) {
			// 	status = itemStatus;
			//
			// } else {
			// 	status = this.status;
			//
			// }
			// switch (status) {
			// 	case 'O':
			// 		return 'New';
			// 	case 'N':
			// 		return 'Not Confirmed';
			// 	case 'C':
			// 		return 'Confirmed';
			// 	case 'L':
			// 		return 'Cancelled';
			// 	case 'X':
			// 		return 'Close';
			// 	default:
			// 		return '';
			// }
		},
		attachmentFilePur() {
			let files = this.detailFiles;
			let file = null;
			if (files === null) {
				this.filePurIsEmpty = true;
			} else {
				for (const element of files) {
					//
					//
					if (
						element.PO_HEADER_ID == this.poHeaderId &&
						element.LEVEL_TENANT == 1 &&
						element.PO_SEQ == this.revisionNum
					) {
						file = element;
					}
				}
				if (file === null) {
					this.filePurIsEmpty = true;
				} else {
					this.filePurIsEmpty = false;
					this.filePur = file;
				}
			}

			return file;
		},
		attachmentFileSupp() {
			let files = this.detailFiles;
			let file = null;
			if (files == null) {
				this.fileSuppIsEmpty = true;
			} else {
				for (const element of files) {
					//
					//
					if (
						element.PO_HEADER_ID == this.poHeaderId &&
						element.LEVEL_TENANT == 2 &&
						element.PO_SEQ == this.revisionNum
					) {
						file = element;
					}
				}
				if (file === null) {
					this.fileSuppIsEmpty = true;
				} else {
					this.fileSuppIsEmpty = false;
					this.fileSupp = file;
				}
			}

			return file;
		},

		topValue() {
			return (
				this.isiTop &&
				this.isiTop.find(
					(val) =>
						this.detailTerms &&
						val.term_id === this.detailTerms.TERMS_ID
				)
			);
		},
		incotermValue() {
			return (
				this.isiIncoterm &&
				this.isiIncoterm.find(
					(val) =>
						this.isiIncoterm && val.value === this.detailTerms.FOB
				)
			);
		},
	},
	methods: {
		async onRemovePur(payload) {
			const payloadRemove = {
				poHeaderId: payload,
				revisionNum: this.revisionNum,
			};
			const res = await PoFulfillment.removeFilePur(payloadRemove);
			if (res.status === 200) {
				this.filePurIsEmpty = true;
				this.$toast.add({
					severity: 'success',
					summary: 'File has been Removed !',
					life: 3000,
				});
			}
		},
		async getTop(dbInstance) {
			const payload = { isi: dbInstance };
			const res = await PoFulfillment.getTop(payload);
			this.isiTop = res.data.data;
			this.top =
				(this.detailTerms &&
					this.isiTop.find(
						(val) => val.term_id === this.detailTerms.TERMS_ID
					)?.name) ||
				'';
		},
		async getIncoterm(dbInstance) {
			const payload = { dbInstance };
			const res = await PoFulfillment.getIncoterm(payload);
			this.isiIncoterm = res.data.data;
			this.incoterm =
				(this.detailTerms &&
					this.isiIncoterm.find(
						(val) => val.value === this.detailTerms.FOB
					)?.name) ||
				'';
		},
		onSelect(e) {
			this.fileUploaded = null;
			this.file = e.files[0];
		},
		async onUpload() {
			//this.loadingUpload = true;
			// let file = this.file
			// let fileUploaded = this.fileUploaded
			// let userId = sessionStorage.getItem('idUser');
			let formData = new FormData();
			formData.append('file', this.file);
			formData.append('poHeaderId', this.poHeaderId);
			formData.append('userId', this.idUser);
			formData.append('revisionNum', this.revisionNum);
			formData.append('password', this.vendorId);

			// Uploading file
			this.loadingUpload = true;
			let res = null;

			if (this.filePurIsEmpty === true) {
				res = await PoFulfillment.uploadFilePur(formData);
			} else {
				res = await PoFulfillment.uploadFileUpdatePur(formData);
			}
			console.log('isi status', res)
			if (res.status === 200) {
				this.fileUploaded = {
					filename: res.data.fileName,
					url: res.data.data.name,
					mimeType: res.data.mimeType,
				};
				// this.$refs.inputFile.clear();
				this.loadingUpload = false;
				this.progress = false;
				// this.attachmentFilePur();
				location.reload();
				return res;
			} else {
				this.progress = false;
				this.$toast.add({
					severity: 'error',
					summary: 'File Failed to Upload !',
					life: 3000,
				});
			}
		},
		formatCurrency(value) {
			if (value) return value.toLocaleString('id-ID');
			return;
		},
		formatDate(value) {
			const d = Date.parse(value);
			return new Date(d).toLocaleDateString('id-ID', {
				day: '2-digit',
				month: 'short',
				year: 'numeric',
			});
		},
		formatDateSend(date) {
			var d = new Date(date),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();

			if (month.length < 2) month = '0' + month;
			if (day.length < 2) day = '0' + day;

			return [year, month, day].join('-');
		},
		formatDateDexa(date) {
			var date = new Date(date);
			var respondDate =
				('00' + (date.getMonth() + 1)).slice(-2) +
				'/' +
				('00' + date.getDate()).slice(-2) +
				'/' +
				date.getFullYear();
			return respondDate;
		},
		getStatusName(status) {
			// let statusPo;
			switch (status) {
				case 'N':
					return 'Not Confirmed';
				case 'C':
					return 'Confirmed';
				case 'L':
					return 'Cancelled';
				case 'X':
					return 'Close';
				default:
					return 'New';
			}
		},
		back() {
			this.$router.push({ name: 'poFulfillment' });
		},
		save() {
			if (this.file == null) {
				this.displayPilih = true;
			} else {
				this.progress = true;
				this.onUpload();
			}
		},
		async onDownload() {
			this.loadingFilePur = true;
			const payloadDownloadTenant = {
				poHeaderId: this.poHeaderId,
				revisionNum: this.revisionNum,
				leveltenant: sessionStorage.getItem('leveltenant'),
				vendorId: this.vendorId,
			};
			try {
				const response = await PoFulfillment.downloadFilePurWithTenant(
					payloadDownloadTenant
				);
				//

				if (response.status === 200) {
					this.soFileBuffer = response.data.data;
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Connection Unstable !',
						life: 3000,
					});
					this.loadingFilePur = false;
					throw Error('Error downloading file');
				}
			} catch (error) {
				this.$toast.add({
					severity: 'error',
					summary: 'Connection Unstable !',
					life: 3000,
				});
				this.loadingFilePur = false;
				throw Error('Error downloading file');
			}

			const linkSource = `data:application/pdf;base64,${this.soFileBuffer}`;
			const downloadLink = document.createElement('a');
			const fileName = this.filePur.FILENAME;
			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.click();
			this.loadingFilePur = false;
		},

		async onDownloadSupp() {
			this.loadingFileSupp = true;
			const payloadDownloadTenant = {
				poHeaderId: this.poHeaderId,
				revisionNum: this.revisionNum,
				leveltenant: sessionStorage.getItem('leveltenant'),
				vendorId: this.vendorId,
			};

			try {
				const response = await PoFulfillment.downloadFileSuppWithTenant(
					payloadDownloadTenant
				);
				//

				if (response.status === 200) {
					this.spFileBuffer = response.data.data;
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Connection Unstable !',
						life: 3000,
					});
					this.loadingFileSupp = false;
					throw Error('Error downloading file');
				}
			} catch (error) {
				this.$toast.add({
					severity: 'error',
					summary: 'Connection Unstable !',
					life: 3000,
				});
				this.loadingFileSupp = false;
				throw Error('Error downloading file');
			}

			const linkSource = `data:application/pdf;base64,${this.spFileBuffer}`;
			const downloadLink = document.createElement('a');
			const fileName = this.fileSupp.FILENAME;
			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.click();
			this.loadingFileSupp = false;
		},
	},
};
</script>
<style scoped lang="scss">
.outofstock {
	font-weight: 700;
	color: #ff5252;
	text-decoration: line-through;
}

.lowstock {
	font-weight: 700;
	color: #ffa726;
}

.instock {
	font-weight: 700;
	color: #66bb6a;
}

::v-deep(.row-accessories) {
	background-color: rgba(0, 0, 0, 0.15) !important;
}

:selection {
	// background-color: #ffffff;
	color: #ff5252;
}
// input{
//     text-align: right;
// }

a.p-button-success {
	text-decoration: none;
	color: #ffffff;
	background: #22c55e;
	border: 1px solid #22c55e;
	padding: 0.75rem 1.25rem !important;
	font-size: 1rem !important;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s,
		box-shadow 0.2s;
	border-radius: 6px;
	display: inline-flex;
	align-items: center;
	vertical-align: bottom;
	text-align: center;
	overflow: hidden;
	position: relative;
	font-size: 0.875rem;
	padding: 0.65625rem 1.09375rem;
}
</style>
