<template>
  <AutoComplete
    :suggestions="filteredBatchDropdown"
    @complete="searchBatch($event)"
    :dropdown="true"
    field="BATCH_NO"
    :placeholder="placeholder"
    forceSelection
  >
    <template #item="slotProp">
      {{ slotProp.item.BATCH_NO }}
    </template>
  </AutoComplete>
</template>
<script setup>
import { onMounted, ref } from "vue";
import AuthService from "../../../service/AuthService";
import InvoiceNonPOservice from "../../../service/InvoiceNonPOservices";

const isiBatchNumber = ref([]);
const filteredBatchDropdown = ref([]);
const placeholder = ref("Select a Batch Number");

// eslint-disable-next-line no-undef
const props = defineProps({
  company: {
    type: Object,
  },
  supplier: {
    type: Object,
  },
  invoiceDropdown: {
    type: Object,
  },
});

onMounted(()=>{
  populateBatch()
})

const populateBatch = () => {
  AuthService.whoAmi().then(async (response) => {
    if (response.data.data.leveltenant === 1) {
       // 
    let payload
    if (props.company !== null && props.supplier !== null && props.invoiceDropdown !== null) {
      payload = {
        org_id: props.company.value,
        supValue: props.supplier.vendor_id,
        invoice_no: props.invoiceDropdown.INVOICE_NO,
        db_instance: props.company.db_instance
      }
      // 
      if (response.status === 200) {
        const data = await InvoiceNonPOservice.getBatchNumberwCompnInvoiceno(payload)
        // 
        isiBatchNumber.value = data.data.data.data
        // 
      }
    } else if (props.company !== null && props.supplier !== null) {
      payload = {
        org_id: props.company.value,
        supValue: props.supplier.vendor_id,
        db_instance: props.company.db_instance
      }
      // 
      if (response.status === 200) {
        const data = await InvoiceNonPOservice.getBatchNumberwCompnSupp(payload)
        isiBatchNumber.value = data.data.data.data
        // 
      }
    } else if (props.company !== null && props.invoiceDropdown !== null) {
      payload = {
        org_id: props.company.value,
        invoice_no: props.invoiceDropdown.INVOICE_NO,
        db_instance: props.company.db_instance
      }
      // 
      if (response.status === 200) {
        const data = await InvoiceNonPOservice.getBatchNumberwCompnInvoice(payload)
        isiBatchNumber.value = data.data.data.data
        // 
      }
    } else if (props.company !== null) {
      payload = {
        org_id: props.company.value,
        db_instance: props.company.db_instance
      }
      // 
      if (response.status === 200) {
        const data = await InvoiceNonPOservice.getBatchNumberwComp(payload)
        // 
        isiBatchNumber.value = data.data.data.data
        // 
      }
    }
    } else if (response.data.data.leveltenant === 3) {
       // 
    let payload
    if (props.company !== null && props.supplier !== null && props.invoiceDropdown !== null) {
      payload = {
        org_id: props.company.value,
        supValue: props.supplier.vendor_id,
        invoice_no: props.invoiceDropdown.INVOICE_NO,
        db_instance: props.company.db_instance
      }
      // 
      if (response.status === 200) {
        const data = await InvoiceNonPOservice.getBatchNumberwCompnInvoicenoPIC(payload)
        // 
        isiBatchNumber.value = data.data.data.data
        // 
      }
    } else if (props.company !== null && props.supplier !== null) {
      payload = {
        org_id: props.company.value,
        supValue: props.supplier.vendor_id,
        db_instance: props.company.db_instance
      }
      // 
      if (response.status === 200) {
        const data = await InvoiceNonPOservice.getBatchNumberwCompnSuppPIC(payload)
        isiBatchNumber.value = data.data.data.data
        // 
      }
    } else if (props.company !== null && props.invoiceDropdown !== null) {
      payload = {
        org_id: props.company.value,
        invoice_no: props.invoiceDropdown.INVOICE_NO,
        db_instance: props.company.db_instance,
        userId: response.data.data.id,
        companyname: props.company.name
      }
      // 
      if (response.status === 200) {
        const data = await InvoiceNonPOservice.getBatchNumberwCompnInvoicePIC(payload)
        isiBatchNumber.value = data.data.data.data
        // 
      }
    } else if (props.company !== null) {
      payload = {
        org_id: props.company.value,
        db_instance: props.company.db_instance,
        userId: response.data.data.id,
        companyname: props.company.name
      }
      // 
      if (response.status === 200) {
        const data = await InvoiceNonPOservice.getBatchNumberwCompPIC(payload)
        // 
        isiBatchNumber.value = data.data.data.data
        // 
      }
    }
    }

  })
};



const searchBatch = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredBatchDropdown.value = [...isiBatchNumber.value]
    } else {
      filteredBatchDropdown.value = isiBatchNumber.value.filter((comp) => {
        return comp.BATCH_NO.toLowerCase().match(event.query.toLowerCase());
      })
    }
  }, 250);
}
</script>