<template>
    <div class="grid">
        <div class="col-12">
            <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
        </div>

        <div class="col-12">
            <div class="content-wrapper">
                <div class="content-header">Detail Invoice Procurement</div>
                <div class="content-body">
                    <div class="grid">
                        <div class="lg:col-6 col-12">
                            <div class="form-card mb-5">
                                <div
                                    class="flex align-items-center justify-content-between mb-6"
                                >
                                    <span style="color: #000; text-transform: uppercase;" class="font-semibold"
                                        >Company</span
                                    >
                                    <span class="font-bold">
                                        {{ company }}
                                    </span>
                                </div>
                                <div
                                    class="flex align-items-center justify-content-between mb-6"
                                >
                                    <span style="color: #000; text-transform: uppercase;" class="font-semibold"
                                        >Supplier</span
                                    >
                                    <span class="font-bold">
                                        GLOBAL CHEMINDO MEGATRADING, PT </span>
                                </div>
                                <div
                                    class="flex align-items-center justify-content-between"
                                >
                                    <span tyle="color: #000; text-transform: uppercase;" class="font-semibold"
                                        >SUPPLIER SITE</span
                                    >
                                    <span class="font-bold">
                                        DOM-TRADE
                                    </span>
                                </div>
                            </div>

                            <div class="grid mb-5">
                                <div class="lg:col-6 col-12">
                                    <div class="p-inputgroup">
                                        <div class="p-float-label">
                                            <AutoComplete
                                                id="invoiceNumber"
                                                :suggestions="filteredInvoiceNo"
                                                @complete="searchInvoiceNo($event)"
                                                field="INVOICE_NO"
                                                v-model="invoiceNo"
                                                forceSelection
                                                class="w-full"    
                                            />
                                            <label for="invoiceNumber">Invoice Number</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="lg:col-6 col-12">
                                    <span class="p-float-label">
                                        <Calendar
                                            id="taxInvoiceDate"
                                            v-model="taxInvoiceDate"
                                            dateFormat="d-M-yy"
                                            :showIcon="true"
                                            class="w-full"
                                        />
                                        <label for="taxInvoiceDate">Invoice Date</label>
                                    </span>
                                </div>
                            </div>

                            <div class="grid mb-5">
                                <div class="lg:col-6 col-12">
                                    <span class="p-float-label">
                                        <Calendar
                                            id="taxInvoiceDate"
                                            v-model="taxInvoiceDate"
                                            dateFormat="d-M-yy"
                                            :showIcon="true"
                                            class="w-full"
                                        />
                                        <label for="taxInvoiceDate">GL Date Description</label>
                                    </span>
                                </div>
                                <div class="lg:col-6 col-12">
                                   
                                </div>
                            </div>

                            <div class="grid mb-5">
                                <div class="col-12">
                                    <span class="p-float-label">
                                        <InputText
                                            id="taxInvoiceDate"
                                            v-model="taxInvoiceDate"
                                            dateFormat="d-M-yy"
                                            :showIcon="true"
                                            class="w-full"
                                        />
                                        <label for="taxInvoiceDate">Description</label>
                                    </span>
                                </div>
                            </div>

                            <div class="grid mb-5">
                                <div class="col-12">
                                    <hr/>
                                </div>
                            </div>

                            <div class="grid mb-5">
                                <div class="lg:col-6 col-12">
                                    <span class="p-float-label">
                                        <InputText
                                            id="taxInvoiceDate"
                                            v-model="taxInvoiceDate"
                                            dateFormat="d-M-yy"
                                            :showIcon="true"
                                            class="w-full"
                                        />
                                        <label for="taxInvoiceDate">Tax Invoice No</label>
                                    </span>
                                </div>
                                <div class="lg:col-6 col-12">
                                    <span class="p-float-label">
                                        <Calendar
                                            id="taxInvoiceDate"
                                            v-model="taxInvoiceDate"
                                            dateFormat="d-M-yy"
                                            :showIcon="true"
                                            class="w-full"
                                        />
                                        <label for="taxInvoiceDate">Tax Invoice Date</label>
                                    </span>
                                </div>
                            </div>

                            <div class="grid mb-5">
                               <div class="lg:col-6 col-12">
                                    <div class="flex align-items-center">
                                        <div class="col-2 font-bold">
                                            FILE
                                        </div>
                                        <div class="col-1 font-bold">
                                            :
                                        </div>
                                        <div class="col-9">
                                            <input type="file" id="file" v-on:change="handleChangeFile($event)" hidden/>
                                            <Button 
                                                class="w-full button-upload"
                                                :label="file ? file.name : 'Upload File'"
                                                @click="openDialog"
                                            />
                                        </div>
                                    </div>
                               </div>
                            </div>

                            <div class="grid mb-5">
                                <div class="lg:col-6 col-12">
                                    <span class="p-float-label">
                                        <Textarea 
                                            class="w-full"
                                            rows="2"
                                            v-model="description"
                                            id="descriptionId"
                                        />
                                        <label for="descriptionId">Rejected Reason</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="lg:col-6 col-12">

                            <div class="form-card mb-5">
                                <div
                                    class="flex align-items-center justify-content-between mb-4"
                                >
                                    <span style="color: #000; text-transform: uppercase;" class="font-semibold"
                                        >Status</span
                                    >
                                    <span class="font-bold">
                                        OPEN
                                    </span>
                                </div>
                                <div
                                    class="flex align-items-center justify-content-between mb-4"
                                >
                                    <span style="color: #000; text-transform: uppercase;" class="font-semibold"
                                        >Invoice Amount</span
                                    >
                                    <span class="font-bold">
                                        {{ formatCurrency(1000000) }} </span>
                                </div>
                                <div
                                    class="flex align-items-center justify-content-between mb-4"
                                >
                                    <span style="color: #000; text-transform: uppercase;" class="font-semibold"
                                        >Tax Amount</span
                                    >
                                    <span class="font-bold">
                                        {{ formatCurrency(110000) }} </span>
                                </div>
                                <div
                                    class="flex align-items-center justify-content-between mb-4"
                                >
                                    <span style="color: #000; text-transform: uppercase;" class="font-semibold"
                                        >Total</span
                                    >
                                    <span class="font-bold">
                                        {{ formatCurrency(1110000) }} </span>
                                </div>
                                <div class="w-full mb-4">
                                    <hr />
                                </div>
                                <div
                                    class="flex align-items-center justify-content-between mb-4"
                                >
                                    <span style="color: #000; text-transform: uppercase;" class="font-semibold"
                                        >WHT</span
                                    >
                                    <span class="font-bold">
                                       (4.500,00) </span>
                                </div>
                                <div
                                    class="flex align-items-center justify-content-between mb-4"
                                >
                                    <span style="color: #000; text-transform: uppercase;" class="font-semibold"
                                        >Total Include WHT</span
                                    >
                                    <span class="font-bold">
                                       104.000,00</span>
                                </div>
                                <div class="w-full mb-4">
                                    <hr/>
                                </div>
                                <div
                                    class="flex align-items-center justify-content-between"
                                >
                                    <span style="color: #000; text-transform: uppercase;" class="font-semibold"
                                        >Currency</span
                                    >
                                    <span class="font-bold">
                                       IDR </span>
                                </div>
                            </div>

                            <div class="grid mb-5">
                                <div class="lg:col-6 col-12">
                                    <div class="p-inputgroup">
                                        <div class="p-float-label">
                                            <AutoComplete
                                                id="invoiceNumber"
                                                :suggestions="filteredInvoiceNo"
                                                @complete="searchInvoiceNo($event)"
                                                field="INVOICE_NO"
                                                v-model="invoiceNo"
                                                forceSelection
                                                class="w-full"
                                                
                                            />
                                            <label for="invoiceNumber">Remit to Bank Account</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="lg:col-6 col-12">
                                    <span class="p-float-label">
                                        <Calendar
                                            id="taxInvoiceDate"
                                            v-model="taxInvoiceDate"
                                            dateFormat="d-M-yy"
                                            :showIcon="true"
                                            class="w-full"
                                        />
                                        <label for="taxInvoiceDate">Payment Method</label>
                                    </span>
                                </div>
                            </div>

                            <div class="grid mb-5">
                                <div class="lg:col-6 col-12">
                                    <div class="p-float-label">
                                        <AutoComplete
                                            id="invoiceNumber"
                                            :suggestions="filteredInvoiceNo"
                                            @complete="searchInvoiceNo($event)"
                                            field="INVOICE_NO"
                                            v-model="invoiceNo"
                                            forceSelection
                                            class="w-full"
                                            :dropdown="true"
                                            
                                        />
                                        <label for="invoiceNumber">Terms</label>
                                    </div>
                                </div>
                            </div>

                            <div class="grid mb-5">
                                <div class="col-12">
                                    <hr/>
                                </div>
                            </div>

                            <div class="grid mb-5">
                                <div class="lg:col-6 col-12">
                                    <div class="p-inputgroup">
                                        <div class="p-float-label">
                                            <AutoComplete
                                                id="invoiceNumber"
                                                :suggestions="filteredInvoiceNo"
                                                @complete="searchInvoiceNo($event)"
                                                field="INVOICE_NO"
                                                v-model="invoiceNo"
                                                forceSelection
                                                class="w-full"
                                                :disabled="true"
                                                style="background-color: aliceblue;"
                                            />
                                            <label for="invoiceNumber">Tax Rate</label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div class="grid mb-5">
                        <div class="col-12">
                            <DataTable
                                class="w-full"
                                :value="tableList"
                                scrollHeight="600px"
                                scrollDirection="both"
                                responsiveLayout="scroll"
                                :scrollable="true"
                            >
                                <Column header="No" style="width: 5%;">
                                    <template #body="slotProp">
                                        <span>{{  slotProp.index + 1 }}</span>
                                    </template>
                                </Column>
                                <Column header="PO Number" style="width:15%">
                                    <template #body="{data}">
                                        <span>{{ data.po_number }}</span>
                                    </template>
                                </Column>
                                <Column header="PO Total Amount" style="width:15%">
                                    <template #body="{data}">
                                        <span>{{ formatCurrency(data.total_amount) }}</span>
                                    </template>
                                </Column>
                                <Column header="Prepayment No" style="width:15%">
                                    <template #body="{data}">
                                        <span>{{ formatCurrency(data.prepayment_no) }}</span>
                                    </template>
                                </Column>
                                <Column header="Percentage" style="width:15%">
                                    <template #body="{data}">
                                        <span>{{ data.percentage }}</span>
                                    </template>
                                </Column>
                                <Column header="Amount" style="width:15%">
                                    <template #body="{data}">
                                        <span>{{ formatCurrency(data.amount) }}</span>
                                    </template>
                                </Column>
                                <Column header="VAT" style="width:15%">
                                    <template #body="{data}">
                                        <AutoComplete
                                            id="invoiceNumber"
                                            :dropdown="true"
                                            :suggestions="filteredInvoiceNo"
                                            @complete="searchInvoiceNo($event)"
                                            field="INVOICE_NO"
                                            v-model="data.vat"
                                            forceSelection
                                            class="w-full"
                                        />
                                    </template>
                                </Column>
                                <Column header="WHT" style="width:15%">
                                    <template #body="{data}">
                                        <AutoComplete
                                            id="invoiceNumber"
                                            :dropdown="true"
                                            :suggestions="filteredInvoiceNo"
                                            @complete="searchInvoiceNo($event)"
                                            field="INVOICE_NO"
                                            v-model="data.wht"
                                            forceSelection
                                            class="w-full"
                                        />
                                    </template>
                                </Column>

                                <template #empty>
                                    Data Not Found
                                </template>
                            </DataTable>
                        </div>
                    </div>

                    <div class="grid">
                        <div class="col-2">
                            <Button class="p-button-secondary w-full">
                                <span class="w-full font-bold">Back</span>
                            </Button>
                        </div>
                        <div class="col-2">
                            <Button class="p-button-success w-full">
                                <span class="w-full font-bold">Submit</span>
                            </Button>
                        </div>
                        <div class="col-2">
                            <Button class="p-button-danger w-full">
                                <span class="w-full font-bold">Reject</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import {onMounted, ref} from 'vue';
    import { useRoute } from 'vue-router';
    import AuthService from '../../../service/AuthService';
    import LovService from '../../../service/LovService';
    import SimpleCrypto from 'simple-crypto-js';
import { formatCurrency } from '../../../helper/formatCurrency';
import { fakeDataProcurement } from '../seederFakingData';



    const route = useRoute();

    const breadcrumbHome = ref({ icon: "pi pi-home", to: "/dashboard" });
    const breadcrumbItems = ref([
        {
            label: "Search Invoice PO",
            to: '/mgm/monitor/searchinvoicewpo',
        },
        {
            label: "Detail Pending PO Prepayment",
            to: route.fullPath,
        },
    ]);


    const companies = ref();
    const company = ref();

    //list table
    const tableList = ref([])

    AuthService.whoAmi().then(async () => {
        function userCompany() {
            const secretKey = 'some-unique-key';
            const simpleCrypto = new SimpleCrypto(secretKey);
            return simpleCrypto.decrypt(
                sessionStorage.getItem('dropdownCompany')
            );
        }
        const respCbN = await LovService.getCompanyByName(
            userCompany()
        );
        companies.value = respCbN.data.data[0];
        company.value = respCbN.data.data[0].name
    });

    onMounted(() => {
        tableList.value = fakeDataProcurement(4)
        console.log(fakeDataProcurement(4),"FAKE")
    })
    
</script>

<style scoped>
    .form-card {
        padding: 24px;
        border: 1px solid #e0e0e0;
        border-radius: 16px;
    }

    .button-upload{
        background-color: #d4e3fc;
        color: black;
        border: none;
        overflow: hidden;
    }
    .button-upload:hover{
        background-color: #d4e3fc;
        color: black;
        border: none;
        opacity: 0.8;
    }

</style>