<template>
	<Card>
		<template #title>
			<p class="text-xl" v-if="currentUser.leveltenant === 2">
				Planning Order
			</p>
			<p class="text-xl" v-else>Open PR - Planning Order Statistic</p>
		</template>
		<template #content>
			<!-- Chart -->
			<div class="chart-container mt-4" v-if="chartData && !isLoading">
				<!-- Bar chart -->
				<Chart
					type="bar"
					:data="chartData"
					:options="chartOptions"
					width="100%"
					height="250"
				/>
			</div>

			<!-- Loading -->
			<div class="flex items-center justify-center" v-else>
				<ProgressSpinner style="width: 50px" />
			</div>
		</template>
		<!-- <template #footer>
			<Button
				label="Link"
				class="p-button-link font-bold"
				@click="openModal"
			>
				<span class="mr-2">See details</span>
				<i class="pi pi-arrow-right"></i>
			</Button>
		</template> -->
	</Card>
	<Dialog
		header="Planning Order List"
		v-model:visible="displayModal"
		:style="{ width: '80%' }"
		:modal="true"
	>
		<PlanningOrder />
	</Dialog>
</template>

<script>
import PlanningOrderService from '../../../service/PlanningOrderService';

const monthNames = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

export default {
	name: 'PlanningOrderChart',
	props: ['currentUser'],
	data() {
		return {
			chartData: null,
			chartOptions: null,
			isLoading: null,
		};
	},
	created() {
		if (this.currentUser && this.currentUser.leveltenant === 2) {
			this.getPlanningOrders(this.currentUser.id);
		} else {
			this.getPlanningOrders();
		}
	},
	methods: {
		async getPlanningOrders(userId) {
			let res;
			if (userId) {
				this.isLoading = true;
				const vendorId = sessionStorage.getItem('vendorId');
				res = await PlanningOrderService.getMonthlyPlanningOrders(
					userId,vendorId
				);
			} else {
				res = await PlanningOrderService.getMonthlyPlanningOrders();
			}
			this.calculateByMonth(res.data.data);
			this.isLoading = false;
		},
		calculateByMonth(planningOrders) {
			const itemsByMonth = planningOrders
				.map((item) => ({
					month: new Date(item.creation_date).getMonth(),
					year: new Date(item.creation_date).getFullYear(),
				}))
				.filter(
					(item) => item.year === new Date().getFullYear()
				)
				.map((item) => Object.values(item)[0])
				.reduce((acc, curr) => {
					return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
				}, {});
			const chartLabels = Object.keys(itemsByMonth).map(
				(el) => monthNames[el]
			);
			const chartValues = Object.values(itemsByMonth);

			this.setChartData(chartLabels, chartValues);
		},
		setChartData(labels, values) {
			// Set chart options
			this.chartOptions = {
				plugins: {
					legend: {
						display: false,
					},
				},
				scales: {
					x: {
						ticks: {
							color: '#495057',
						},
						grid: {
							color: '#fff',
						},
					},
					y: {
						ticks: {
							color: '#495057',
						},
						grid: {
							color: '#fff',
						},
					},
				},
			};

			// Set chart data
			this.chartData = {
				labels: labels,
				datasets: [
					{
						label: 'PO',
						barThickness: 20,
						borderRadius: 100,
						backgroundColor: [
							'#e32c24',
							'#de3831',
							'#DA3831',
							'#f25650',
							'#eb635e',
							'#e6605c',
							'#FF6D67',
							'#E16F6A',
							'#eb7c78',
							'#eb9591',
							'#de9692',
							'#FFA09C',
						],
						data: values,
					},
				],
			};
		},
	},
};
</script>

<style scoped>
.chart-container {
	width: 100%;
	height: 100%;
}
</style>
