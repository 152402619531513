import invoice from "./api/SpbInvoiceApi";
class SearchInvoiceWPO {
  async getLastReceipt(vendorId) {
    return invoice.get(`spb/searchinvoicewpo/getLastReceipt/${vendorId}`);
  }
  async getPONumberwComp(payload) {
    return invoice.post("spb/searchinvoicewpo/poNumberwComp", payload);
  }
  async getPONumberwCompnSupp(payload) {
    return invoice.post("spb/searchinvoicewpo/poNumberwCompnSupp", payload);
  }
  async getPONumberwCompnBatch(payload) {
    return invoice.post("spb/searchinvoicewpo/poNumberwCompnBatch", payload);
  }
  async getPONumberwCompnBatchno(payload) {
    return invoice.post("spb/searchinvoicewpo/poNumberwCompnBatchno", payload);
  }
  async getBatchNumberwComp(payload) {
    return invoice.post("spb/searchinvoicewpo/batchNumberwComp", payload);
  }
  async getBatchNumberwCompnInvoice(payload) {
    return invoice.post(
      "spb/searchinvoicewpo/batchNumberwCompnInvoice",
      payload
    );
  }
  async getBatchNumberwCompnSupp(payload) {
    return invoice.post("spb/searchinvoicewpo/batchNumberwCompnSupp", payload);
  }
  async getBatchNumberwCompnInvoiceno(payload) {
    return invoice.post(
      "spb/searchinvoicewpo/batchNumberwCompnInvoiceno",
      payload
    );
  }
  async searchInvoiceWPOTable(payload) {
    return invoice.post("spb/searchinvoicewpo/searchInvoiceWPOTable", payload);
  }
  async getDetailSearchInvoiceWPO(payload) {
    return invoice.get("spb/searchinvoicewpo/detailInvoiceWPO/" + payload);
  }
  async getTableDetailSearchInvoiceWPO(payload) {
    return invoice.get("spb/searchinvoicewpo/detailTableInvoiceWPO/" + payload);
  }
  async getTableDetailSearchInvoiceWPOPrepayment(payload) {
    return invoice.get("spb/searchinvoicewpo/detailTableInvoiceWPOPrepayment/" + payload);
  }
  async getRemitBank(payload) {
    return invoice.post("spb/searchinvoicewpo/remitBank", payload);
  }
  async getPaymentMethod(payload) {
    return invoice.post("spb/searchinvoicewpo/paymentMethod", payload);
  }
  async getTermsBill(payload) {
    return invoice.post("spb/searchinvoicewpo/termsBill", payload);
  }
  async getPphCode(payload) {
    return invoice.post("spb/searchinvoicewpo/pphCode", payload);
  }
  async getChargeNumber(payload) {
    return invoice.post("spb/searchinvoicewpo/chargeNumber", payload);
  }
  async saveSearchInvoiceWPO(payload) {
    return invoice.post("spb/searchinvoicewpo/save", payload);
  }
  async submitSearchInvoiceWPO(payload) {
    return invoice.post("spb/searchinvoicewpo/submit", payload);
  }
  async submitSearchInvoiceWPOPrepayment(payload) {
    return invoice.post("spb/searchinvoicewpo/submitprepayment", payload);
  }
  async rejectSearchInvoiceWPO(payload) {
    return invoice.post("spb/searchinvoicewpo/reject", payload);
  }
  async rejectSearchInvoiceWPOPrepayment(payload) {
    return invoice.post("spb/searchinvoicewpo/rejectprepayment", payload);
  }
  async getAttachmentFile(payload) {
    return invoice.get(`/invoicepo/attachmentfile/${payload}`);
  }
  getFileBase64(invoiceId, leveltenant, vendorId) {
    return invoice.get(
      `/invoicepo/getfilebase64/${invoiceId}/${leveltenant}/${vendorId}`
    );
  }
}

export default new SearchInvoiceWPO();
