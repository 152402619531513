<template>
    <div>
        <DataTable
            :value="dataList"
        >
            <Column header="Description">
                <template #body="slotProps">
                    <div>{{slotProps.data.description}}</div>
                </template>
            </Column>
            <Column header="Amount">
                <template #body="slotProps">
                    <div>{{slotProps.data.amount}}</div>
                </template>
            </Column>
            <Column header="Ref Number">
                <template #body="slotProps">
                    <div>{{slotProps.data.travelOrder.travelOrderNo}}</div>
                </template>
            </Column>
            <Column header="Action">
                <template #body="{data}">
                    <Button
                        icon="pi pi-plus-circle"
                        class="p-button-rounded p-button-text"
                        @click="addModal(data)"
                    />
                </template>    
            </Column>
        </DataTable>
    </div>
</template>

<script setup>

import {defineProps,watch,ref,defineEmits} from "vue"

const props = defineProps(['dataTable', 'selectItem']);
const emit = defineEmits(['selectItem']);

const dataList = ref(props.dataTable);

watch(() => props.dataTable, () => {
    dataList.value = props.dataTable
});

function addModal(data){
    console.log('data Add Line', data);
    emit('selectItem', data);
}



</script>