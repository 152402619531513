<template>
	<div class="grid">
		<Toast />

		<!-- Breadcrumb -->
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<!-- Shipment Information -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Shipment Information</div>
				<div class="content-body">
					<div class="grid">
						<div class="col-12 md:col-6 lg:col-4">
							<span class="p-float-label">
								<InputText
									id="doNumber"
									class="w-full"
									type="text"
									v-model="doNumber"
								/>
								<label for="doNumber">DO Number</label>
							</span>
						</div>
						<div class="col-12 md:col-6 lg:col-4">
							<span class="p-float-label">
								<Calendar
									class="w-full"
									dateFormat="dd.mm.yy"
									:showIcon="true"
									v-model="deliveryDate"
								/>
								<label for="doNumber">Delivery Date</label>
							</span>
						</div>
						<div class="col-12 md:col-6 lg:col-4">
							<span class="p-float-label">
								<InputText
									id="status"
									class="w-full"
									type="text"
									v-model="status"
								/>
								<label for="status">Status</label>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Freight Information -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Freight Information</div>
				<div class="content-body">
					<div class="grid">
						<!-- Freight carrier -->
						<div class="col-12 md:col-6">
							<div class="mb-6">
								<span class="p-float-label">
									<InputText
										id="freightCarrier"
										class="w-full"
										type="text"
										v-model="freightCarrier"
									/>
									<label for="freightCarrier"
										>Freight Carrier</label
									>
								</span>
							</div>
							<div>
								<span class="p-float-label">
									<InputText
										id="freightNumber"
										class="w-full"
										type="text"
										v-model="freightNumber"
									/>
									<label for="freightNumber"
										>AWB/BL Number</label
									>
								</span>
							</div>
						</div>
						<!-- Comment -->
						<div class="col-12 md:col-6">
							<span class="p-float-label">
								<Textarea
									id="comment"
									class="w-full"
									rows="6"
									:autoResize="true"
									v-model="comment"
								/>
								<label for="comment">Comment</label>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Attachment Document -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Attachment Document</div>
				<div class="content-body">
					<!-- Button upload doc -->
					<div class="mb-5">
						<Button
							class="p-button-secondary p-button-sm w-auto mr-2"
							label="Invalidate"
						/>
						<Button
							class="p-button-success p-button-sm w-auto"
							label="Validate"
						/>
					</div>

					<!-- Table Files -->
					<DataTable :value="documents">
						<Column header="No" field="no"></Column>
						<Column
							header="Document Type"
							field="document_type"
						></Column>
						<Column header="File">
							<template #body> Filename </template>
						</Column>
						<Column
							header="Need Original"
							field="need_original"
						></Column>
						<Column header="Validate" field="validate"></Column>
						<Column header="Remark" field="remark"></Column>

						<!-- Empty state -->
						<template #empty>
							<p>No Data.</p>
						</template>
					</DataTable>

					<!-- Input field -->
					<div class="grid mt-4">
						<div class="col-12 md:col-6 lg:col-4">
							<span class="p-float-label">
								<InputText
									id="courierName"
									class="w-full"
									type="text"
									disabled
									v-model="courierName"
								/>
								<label for="courierName">Courier Name</label>
							</span>
						</div>
						<div class="col-12 md:col-6 lg:col-4">
							<span class="p-float-label">
								<InputText
									id="trackingNo"
									class="w-full"
									type="text"
									disabled
									v-model="trackingNo"
								/>
								<label for="trackingNo">Tracking No</label>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Items -->
		<div class="col-12 mb-6">
			<div class="content-wrapper">
				<div class="content-header">Items</div>
				<div class="content-body">
					<DataTable
						:value="purchaseOrders"
						:lazy="true"
						:paginator="true"
						:rows="10"
						:rowsPerPageOptions="[10, 20, 50]"
						:metaKeySelection="false"
						:loading="loading"
						:totalRecords="totalRecords"
						ref="dt"
						dataKey="id"
						paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
						responsiveLayout="scroll"
						selectionMode="multiple"
						v-model:selection="selectedPo"
						@page="onPage($event)"
						@sort="onSort($event)"
						@row-select="onRowSelect"
					>
						<Column
							header="PO Number"
							field="po_number"
							:sortable="true"
						></Column>
						<Column
							header="Line"
							field="line"
							:sortable="true"
						></Column>
						<Column
							header="Item Number"
							field="item_number"
							:sortable="true"
						></Column>
						<Column
							header="Item Description"
							field="item_description"
							:sortable="true"
						></Column>
						<Column
							header="Qty Order"
							field="qty_order"
							:sortable="true"
						></Column>
						<Column
							header="Qty Shipped"
							field="qty_shipped"
							:sortable="true"
						></Column>
						<Column
							header="Arrival at Site Date"
							field="arrival_date"
							:sortable="true"
						></Column>
						<Column
							header="Quantity Order"
							field="qty_order"
						></Column>
						<Column header="Ship To" field="ship_to"></Column>

						<!-- Empty state -->
						<template #empty> No Data. </template>
					</DataTable>
					<div class="p-fluid grid formgrid" style="margin-top: 1cm">
						<div class="col-6 mb-2 lg:col-1 lg:mb-0">
							<Button
								type="button"
								label="Back"
								class="p-button-secondary"
								@click="back()"
							/>
						</div>
						<div class="col-6 mb-2 lg:col-1 lg:mb-0">
							<Button
								type="button"
								label="Save"
								@click="save()"
								:disabled="this.button"
							/>
						</div>
						<div class="col-6 mb-2 lg:col-1 lg:mb-0">
							<Button
								type="button"
								label="Submit"
								class="p-button-success"
								@click="submit()"
								:disabled="this.button"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Action Buttons -->
	</div>
</template>

<script>
export default {
	name: 'DetailShipmentNoticeSupp',
	data() {
		return {
			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [
				{
					label: 'Shipment Notice',
					to: '/mgm/settings/shipmentnotice',
				},
				{
					label: 'Add/Detail Shipment Notice',
					to: this.$route.path,
				},
			],
		};
	},
	watch: {},
	created() {},
	async mounted() {},
	computed: {},
	methods: {
		formatCurrency(value) {
			if (value) return value.toLocaleString('id-ID');
			return;
		},
	},
};
</script>
<style scoped lang="scss"></style>
