<template>
	<div class="col-12 p-fluid mb-4 list-invoice list-inv-proxy">
		<Toast />

		<div class="mb-3">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<!-- Progress modal -->
		<ProgressModal :progress="progress">
			<template #message>
				Saving invoice in progress. Please wait...
			</template>
		</ProgressModal>

		<div class="content-wrapper">
			<div class="content-header">Detail E-Invoice</div>
			<div v-if="currentUser" class="content-body">
				<div class="grid">
					<!-- Dropdown company -->
					<div
						class="col-12 p-0 pb-2 mb-6 border-bottom-1 border-gray-200"
					>
						<div class="col-12 md:col-6 lg:col-4">
							<div class="mb-5">
								<span class="p-float-label">
									<AutoComplete
										id="company"
										:class="{
											'p-invalid': v$.company.$error,
										}"
										:dropdown="true"
										:suggestions="filteredCompany"
										field="name"
										forceSelection
										disabled
										v-model="company"
										@complete="searchCompany($event)"
									>
										<template #item="{ item }">
											<div>
												{{ item.name }}
											</div>
										</template>
									</AutoComplete>
									<label for="company">Company</label>
								</span>
								<div v-if="v$.company.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.company.$errors[0].$message }}
									</small>
								</div>
							</div>
						</div>
					</div>

					<!-- Header Form -->
					<div
						class="col-12 grid p-0 pb-2 mb-4 border-bottom-1 border-gray-200"
					>
						<!-- Col 1 -->
						<div class="col-12 md:col-6 lg:col-4">
							<div class="mb-5">
								<span class="p-float-label">
									<InputText
										id="invoiceNo"
										:class="{
											'p-invalid':
												v$.invoiceNo.$error ||
												errorExisting,
										}"
										v-model="invoiceNo"
									/>
									<label for="invoiceNo">Invoice No</label>
								</span>
								<div v-if="v$.invoiceNo.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.invoiceNo.$errors[0].$message }}
									</small>
								</div>
								<div v-if="errorExisting" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										Invoice Number already exist
									</small>
								</div>
							</div>
							<div class="mb-5">
								<span class="p-float-label">
									<Calendar
										id="invoiceDate"
										:class="{
											'p-invalid': v$.invoiceDate.$error,
										}"
										:showIcon="true"
										dateFormat="d-M-yy"
										v-model="invoiceDate"
										@date-select="handleSelectInvoiceDate"
									/>
									<label for="invoiceDate"
										>Invoice Date</label
									>
								</span>
								<div v-if="v$.invoiceDate.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.invoiceDate.$errors[0].$message }}
									</small>
								</div>
							</div>
							<div class="mb-2">
								<span class="p-float-label">
									<Textarea
										id="description"
										:class="{
											'p-invalid': v$.description.$error,
										}"
										rows="5"
										v-model="description"
										:maxlength="230"
									/>
									<label for="description">Description</label>
								</span>
								<div v-if="v$.description.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.description.$errors[0].$message }}
									</small>
								</div>
							</div>
							<div class="mb-5">
								<label
									style="
										display: inline-block;
										font-size: 12px;
										padding-left: 0.75rem;
										margin-bottom: 4px;
										color: #6c757d;
									"
									>Attachment</label
								>
								<div v-if="savedFile" class="flex">
									<!-- <a
										class="button-download p-button"
										:href="savedFile.url"
									>
										<span class="font-bold">
											<i class="pi pi-download mr-2" />
											{{ savedFile.filename }}
										</span>
									</a> -->
									<Button
										v-if="!loadingDownladFile"
										class="button-download font-normal"
										:label="savedFile.filename"
										icon="pi pi-download"
										@click="onDownload()"
									/>
									<Button
										v-if="loadingDownladFile"
										class="button-download font-normal"
										:label="`${savedFile.filename} is downloading`"
										icon="pi pi-download"
										:disabled="true"
									/>
									<Button
										v-if="status === 'DRAFT' && isUserPic"
										type="button"
										class="p-button-rounded p-button-text ml-2"
										icon="pi pi-trash"
										@click="handleDeleteFile"
									/>
								</div>
								<div v-else>
									<FileUpload
										class="w-full"
										ref="fileUpload"
										mode="advanced"
										:class="{
											'error-file': v$.file.$error,
										}"
										style="justify-content: center"
										chooseLabel="Upload File"
										fileLimit="1"
										accept="application/pdf"
										:maxFileSize="5000000"
										:showUploadButton="false"
										:showCancelButton="false"
										:customUpload="true"
										@select="onSelectFile"
										@remove="onRemoveFile"
									>
										<template #content="{ files }">
											{{ files }}
										</template>
									</FileUpload>
									<div
										v-if="v$.file.$error"
										style="transform: translateY(-38px)"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{ v$.file.$errors[0].$message }}
										</small>
									</div>
								</div>
							</div>
						</div>
						<!-- Col 2 -->
						<div class="col-12 md:col-6 lg:col-4">
							<div class="mb-5">
								<span class="p-float-label">
									<InputText
										id="taxInvoiceNo"
										:class="{
											'p-invalid': v$.taxInvoiceNo.$error,
										}"
										v-model="taxInvoiceNo"
										@change="handleChangeTax($event)"
									/>
									<label for="taxInvoiceNo"
										>Tax Invoice No</label
									>
								</span>
								<div v-if="v$.taxInvoiceNo.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{
											v$.taxInvoiceNo.$errors[0].$message
										}}
									</small>
								</div>
							</div>
							<div class="mb-5">
								<span class="p-float-label">
									<Calendar
										id="taxInvoiceDate"
										:class="{
											'p-invalid':
												v$.taxInvoiceDate.$error,
										}"
										dateFormat="d-M-yy"
										:showIcon="true"
										v-model="taxInvoiceDate"
									/>
									<label for="taxInvoiceDate"
										>Tax Invoice Date</label
									>
								</span>
								<div
									v-if="v$.taxInvoiceDate.$error"
									class="mt-1"
								>
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{
											v$.taxInvoiceDate.$errors[0]
												.$message
										}}
									</small>
								</div>
							</div>
							<div class="mb-5">
								<span class="p-float-label">
									<AutoComplete
										forceSelection
										field="name"
										:class="{
											'p-invalid': v$.bank.$error,
										}"
										:disabled="!bankList"
										:dropdown="true"
										:suggestions="filteredBanks"
										v-model="bank"
										@complete="searchBank($event)"
									/>
									<label for="bank">Bank</label>
								</span>
								<div v-if="v$.bank.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.bank.$errors[0].$message }}
									</small>
								</div>
							</div>
							<div class="mb-5">
								<span class="p-float-label">
									<AutoComplete
										forceSelection
										field="currency_code"
										:class="{
											'p-invalid': v$.currency.$error,
										}"
										:disabled="!currencyList"
										:dropdown="true"
										:suggestions="filteredCurrencies"
										v-model="currency"
										@complete="searchCurrency($event)"
									/>
									<label>Currency</label>
								</span>
								<div v-if="v$.currency.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.currency.$errors[0].$message }}
									</small>
								</div>
							</div>
						</div>
						<!-- Col 3 -->
						<div class="col-12 md:col-6 lg:col-4">
							<div class="mb-5">
								<span class="p-float-label">
									<InputText
										class="statusInvoice"
										disabled
										v-model="status"
									/>
									<label for="statusInvoice">Status</label>
								</span>
							</div>
							<div class="mb-5 flex">
								<div class="flex-grow-1 mr-3">
									<span class="p-float-label">
										<InputText
											id="skNumber"
											:class="{
												'p-invalid':
													isForwarder &&
													v$.skNumber.$error,
											}"
											disabled
											style="color: #000 !important"
											v-model="skNumber"
										/>
										<label for="skNumber"
											>Reference Number</label
										>
									</span>
									<div
										v-if="isForwarder && v$.skNumber.$error"
										class="mt-1"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.skNumber.$errors[0].$message
											}}
										</small>
									</div>
								</div>
								<div>
									<Button
										type="button"
										class="p-button-secondary"
										:disabled="!company || !vendorId"
										@click="displaySKModal = true"
									>
										<span class="uppercase font-bold"
											>Find SK</span
										>
									</Button>
								</div>
							</div>
							<div class="mb-5">
								<span class="p-float-label">
									<Textarea
										v-model="rejectReason"
										rows="5"
										class="w-full"
										disabled
									/>
									<label>Reject Reason</label>
								</span>
							</div>
							<div class="mb-5">
								<span class="p-float-label">
									<Textarea
										v-model="recommendatorReason"
										rows="5"
										class="w-full"
										disabled
									/>
									<label>Recommendator Reason</label>
								</span>
							</div>
						</div>
					</div>

					<!-- Detail Form -->
					<div class="col-12 grid">
						<div class="col-12 md:col-6 lg:col-8 mb-3">
							<!-- Add category detail form -->
							<p class="text-2xl font-bold mb-5">Line</p>
							<div class="flex w-12 lg:w-10 xl:w-8">
								<div class="flex-grow-1">
									<span class="p-float-label">
										<AutoComplete
											id="detailCategory"
											:suggestions="filteredCategory"
											:dropdown="true"
											:disabled="
												!categoryDetail ||
												status !== 'DRAFT' ||
												!isUserPic
											"
											field="category_detail"
											forceSelection
											v-model="category"
											@complete="searchCategory($event)"
										/>
										<label for="detailCategory"
											>Detail Category</label
										>
									</span>
								</div>
								<Button
									type="button"
									class="p-button-success w-auto ml-3"
									:disabled="status !== 'DRAFT' || !isUserPic"
									@click="handleAddCategory"
								>
									<i class="pi pi-plus" />
									<span class="uppercase font-bold ml-2"
										>Add Line</span
									>
								</Button>
							</div>

							<!-- Warning message if vendor Forwarder not select SK Number yet -->
							<div
								v-if="isWarningShown"
								class="col-12 p-0 lg:col-8 mt-3"
							>
								<Message severity="warn" :closable="false"
									>Please select SK Number first</Message
								>
							</div>
						</div>

						<!-- Summary invoice -->
						<div class="col-12 md:col-6 lg:col-4 mb-3">
							<div class="form-card">
								<div
									class="flex align-items-center justify-content-between mb-4"
								>
									<span
										style="color: #000"
										class="font-semibold"
										>Total Line</span
									>
									<span class="font-bold">
										{{
											new Intl.NumberFormat('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}).format(summary.totalLine)
										}}
									</span>
								</div>
								<div
									class="flex align-items-center justify-content-between mb-4"
								>
									<span
										style="color: #000"
										class="font-semibold"
										>Total PPn</span
									>
									<span class="font-bold">
										{{
											new Intl.NumberFormat('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}).format(summary.totalPpn)
										}}
									</span>
								</div>
								<div
									class="flex align-items-center justify-content-between"
								>
									<span
										style="color: #000"
										class="font-semibold"
										>Total Invoice</span
									>
									<span class="font-bold">
										{{
											new Intl.NumberFormat('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}).format(summary.totalInvoice)
										}}
									</span>
								</div>
							</div>
						</div>

						<!-- Category table -->
						<div class="col-12 mb-8">
							<DataTable
								:value="selectedCategory"
								responsiveLayout="scroll"
								:scrollable="true"
								scrollHeight="600px"
							>
								<Column header="Description">
									<template #body="{ data, index }">
										<InputText
											type="text"
											style="color: #000 !important"
											:class="{
												'p-invalid':
													v$.selectedCategory
														.$error &&
													v$.selectedCategory
														.$errors[0].$response
														.$data[index]
														.description.$error,
											}"
											:disabled="isForwarder"
											v-model="data.description"
										/>
										<div
											v-if="
												v$.selectedCategory.$error &&
												v$.selectedCategory.$errors[0]
													.$response.$data[index]
													.description.$error
											"
											class="mt-1"
										>
											<small
												class="p-error"
												style="font-size: 12px"
											>
												{{
													v$.selectedCategory
														.$error &&
													v$.selectedCategory
														.$errors[0].$response
														.$errors[index]
														.description[0].$message
												}}
											</small>
										</div>
									</template>
								</Column>
								<Column header="Amount">
									<template #body="{ data, index }">
										<InputNumber
											class="col-number"
											:class="{
												'p-invalid':
													v$.selectedCategory
														.$error &&
													v$.selectedCategory
														.$errors[0].$response
														.$data[index].amount
														.$error,
											}"
											:min="0"
											mode="decimal"
											locale="en-US"
											:minFractionDigits="2"
											:maxFractionDigits="2"
											style="text-align: right"
											v-model="data.amount"
										/>
										<div
											v-if="
												v$.selectedCategory.$error &&
												v$.selectedCategory.$errors[0]
													.$response.$data[index]
													.amount.$error
											"
											class="mt-1"
										>
											<small
												class="p-error"
												style="font-size: 12px"
											>
												{{
													v$.selectedCategory
														.$error &&
													v$.selectedCategory
														.$errors[0].$response
														.$errors[index]
														.amount[0].$message
												}}
											</small>
										</div>
									</template>
								</Column>
								<Column header="VAT">
									<template #body="{ data }">
										<Dropdown
											class="w-full"
											:options="vatRates"
											optionLabel="vat_code"
											placeholder="Select VAT"
											v-model="data.vat"
										/>
									</template>
								</Column>
								<Column header="Ref Number">
									<template #body="{ data, index }">
										<div class="p-inputgroup">
											<InputText
												disabled
												placeholder="Ref Number"
												v-model="
													data.travelOrder
														.travelOrderNo
												"
											/>
											<Button
												icon="pi pi-search"
												class="p-button-secondary"
												:disabled="!supplierName"
												@click="openTOModal(index)"
											/>
										</div>
									</template>
								</Column>
								<Column v-if="status === 'DRAFT' && isUserPic">
									<template #body="{ index }">
										<Button
											icon="pi pi-trash"
											class="p-button-rounded p-button-text"
											@click="handleDeleteCategory(index)"
										/>
									</template>
								</Column>

								<!-- Empty state -->
								<template #empty>
									<div>No item.</div>
								</template>
							</DataTable>
						</div>

						<!-- Reimbursement section -->
						<div class="col-12 mb-6" v-if="showReimburseTable">
							<div
								class="flex align-items-center justify-content-between mb-3"
							>
								<p class="text-2xl font-bold mb-0">Reimburse</p>
								<Button
									type="button"
									class="p-button-success w-auto"
									:disabled="status !== 'DRAFT' || !isUserPic"
									@click="handleAddReimbursement"
								>
									<i class="pi pi-plus" />
									<span class="uppercase font-bold ml-2"
										>Add Line</span
									>
								</Button>
							</div>

							<!-- Reimburse table -->
							<DataTable
								:value="reimburseItems"
								responsiveLayout="scroll"
							>
								<Column header="Vendor">
									<template #body="{ data }">
										<AutoComplete
											v-model="data.vendor"
											forceSelection
											placeholder="Vendor"
											field="vendor_name"
											:disabled="!vendors"
											:suggestions="filteredVendors"
											@complete="searchVendors($event)"
										/>
									</template>
								</Column>
								<Column header="No Faktur Pajak">
									<template #body="{ data, index }">
										<InputText
											v-model="data.taxNo"
											:class="{
												'p-invalid':
													v$.reimburseItems.$error &&
													v$.reimburseItems.$errors[0]
														.$response.$data[index]
														.taxNo.$error,
											}"
											placeholder="Tax Number"
										/>
										<div
											v-if="
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$data[index]
													.taxNo.$error
											"
											class="mt-1"
										>
											<small
												class="p-error"
												style="font-size: 12px"
											>
												{{
													v$.reimburseItems.$error &&
													v$.reimburseItems.$errors[0]
														.$response.$errors[
														index
													].taxNo[0].$message
												}}
											</small>
										</div>
									</template>
								</Column>
								<Column header="Tanggal Faktur Pajak">
									<template #body="{ data, index }">
										<Calendar
											v-model="data.taxDate"
											class="line-calendar"
											:class="{
												'p-invalid':
													v$.reimburseItems.$error &&
													v$.reimburseItems.$errors[0]
														.$response.$data[index]
														.taxDate.$error,
											}"
											dateFormat="d-M-yy"
											:showIcon="true"
											placeholder="Select Date"
										/>
										<div
											v-if="
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$data[index]
													.taxDate.$error
											"
											class="mt-1"
										>
											<small
												class="p-error"
												style="font-size: 12px"
											>
												{{
													v$.reimburseItems.$error &&
													v$.reimburseItems.$errors[0]
														.$response.$errors[
														index
													].taxDate[0].$message
												}}
											</small>
										</div>
									</template>
								</Column>
								<Column header="Nilai Pajak">
									<template #body="{ data, index }">
										<InputNumber
											v-model="data.taxAmount"
											class="col-number"
											:class="{
												'p-invalid':
													v$.reimburseItems.$error &&
													v$.reimburseItems.$errors[0]
														.$response.$data[index]
														.taxAmount.$error,
											}"
											style="text-align: right"
											mode="decimal"
											locale="en-US"
											:minFractionDigits="2"
											:maxFractionDigits="2"
											:min="0"
										/>
										<div
											v-if="
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$data[index]
													.taxAmount.$error
											"
											class="mt-1"
										>
											<small
												class="p-error"
												style="font-size: 12px"
											>
												{{
													v$.reimburseItems.$error &&
													v$.reimburseItems.$errors[0]
														.$response.$errors[
														index
													].taxAmount[0].$message
												}}
											</small>
										</div>
									</template>
								</Column>
								<Column header="Group">
									<template #body="{ data, index }">
										<Dropdown
											:options="reimburstGroups"
											:class="{
												'p-invalid':
													v$.reimburseItems.$error &&
													v$.reimburseItems.$errors[0]
														.$response.$data[index]
														.group.$error,
											}"
											optionLabel="name"
											placeholder="Select Group"
											v-model="data.group"
										/>
										<div
											v-if="
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$data[index]
													.group.$error
											"
											class="mt-1"
										>
											<small
												class="p-error"
												style="font-size: 12px"
											>
												{{
													v$.reimburseItems.$error &&
													v$.reimburseItems.$errors[0]
														.$response.$errors[
														index
													].group[0].$message
												}}
											</small>
										</div>
									</template>
								</Column>
								<Column v-if="status === 'DRAFT' && isUserPic">
									<template #body="{ index }">
										<Button
											icon="pi pi-trash"
											class="p-button-rounded p-button-text"
											@click="
												handleDeleteReimburse(index)
											"
										/>
									</template>
								</Column>

								<!-- Empty state -->
								<template #empty>
									<div>No item.</div>
								</template>
							</DataTable>
						</div>
					</div>
				</div>

				<!-- Action buttons -->
				<div class="grid">
					<div
						v-if="status === 'DRAFT' && isUserPic"
						class="col-12 md:col-6 lg:col-3"
					>
						<Button
							v-if="!progress"
							class="p-button-success justify-content-center"
							:disabled="!invoiceNo"
							@click="handleSave"
						>
							<span class="font-bold uppercase">Save</span>
						</Button>
						<Button
							v-else
							class="p-button-success justify-content-center"
							disabled
						>
							<span class="font-bold uppercase">Saving...</span>
						</Button>
					</div>
					<div class="col-12 md:col-6 lg:col-3">
						<Button
							class="p-button-secondary justify-content-center"
							@click="$router.push({ name: 'ListInvoiceProxy' })"
						>
							<span class="font-bold uppercase">Back</span>
						</Button>
					</div>
				</div>

				<!-- Find SK Modal -->
				<Dialog
					header="Select SK"
					:draggable="false"
					:breakpoints="{ '960px': '75vw', '640px': '100vw' }"
					:style="{ width: '25vw', overflow: 'hidden' }"
					:modal="true"
					:dismissableMask="true"
					v-model:visible="displaySKModal"
				>
					<div class="pt-4">
						<FindSKModal
							:dbInstance="company.db_instance"
							:vendorId="vendorId"
							@on-close="displaySKModal = false"
							@on-save="addSKNumber"
						/>
					</div>
				</Dialog>

				<!-- Find Travel Order Modal -->
				<Dialog
					header=" "
					:draggable="false"
					:breakpoints="{ '960px': '75vw', '640px': '100vw' }"
					:style="{ width: '75vw', overflow: 'hidden' }"
					:modal="true"
					:dismissableMask="true"
					v-model:visible="displayTOModal"
				>
					<div class="pt-3">
						<FindTOModal
							:einvoiceId="props.id"
							:supplierName="supplierName"
							@on-close="closeTOModal"
							@on-add="handleAddTO"
						/>
					</div>
				</Dialog>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, watch, onMounted, defineProps } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import { useToast } from 'primevue/usetoast';
import AuthService from '../../service/AuthService';
import InvoicePOService from '../../service/InvoicePOService';
import InvoiceCreateNonPOService from '../../service/InvoiceCreateNonPO';
import InvoiceListProxyService from '../../service/InvoiceListProxyService';
import ProgressModal from '../components/ProgressModal.vue';
import FindSKModal from '../components/FindSKModal.vue';
import FindTOModal from '../components/FindTOModal.vue';

// Vue router
const router = useRouter();
const route = useRoute();

// State
const currentUser = ref(null);
const company = ref(null);
const vendorId = ref(null);
const categoryId = ref(null);
const supplierName = ref(null);
const invoiceNo = ref(null);
const invoiceDate = ref(null);
const description = ref(null);
const bank = ref(null);
const bankAccId = ref(null);
const taxInvoiceNo = ref(null);
const taxInvoiceDate = ref(null);
const status = ref(null);
const rejectReason = ref(null);
const recommendatorReason = ref(null);
const file = ref(null);
const fileUpload = ref(null);
const savedFile = ref(null);
const deletedFile = ref(null);
const skNumber = ref(null);
const bankList = ref(null);
const filteredBanks = ref(null);
const companyList = ref(null);
const filteredCompany = ref(null);
const currency = ref(null);
const currencyList = ref(null);
const filteredCurrencies = ref(null);
const categoryHeader = ref(null);
const categoryDetail = ref(null);
const category = ref(null);
const filteredCategory = ref(null);
const selectedCategory = ref([]);
const removedLines = ref([]);
const vatRates = ref(null);
const travelOrderIndex = ref(null);
const reimburseItems = ref([]);
const removedReimburseItems = ref([]);
const vendors = ref(null);
const filteredVendors = ref(null);
const reimburstGroups = ref(null);
const progress = ref(false);
const loading = ref(false);
const displaySKModal = ref(false);
const displayTOModal = ref(false);
const isWarningShown = ref(false);
const errorExisting = ref(false);
const fileBuffer = ref();
const loadingDownladFile = ref(false);
const breadcrumbHome = ref({ icon: 'pi pi-home', to: '/dashboard' });
const breadcrumbItems = ref([
	{
		label: 'Detail List Invoice Proxy',
		to: route.fullPath,
	},
]);

// Props
const props = defineProps(['id']);

// Check if supplier is forwarder
const isForwarder = computed(
	() =>
		categoryHeader.value &&
		categoryHeader.value.category_name.toLowerCase().includes('forwarder')
);

// Check if user logged in is PIC
const isUserPic = computed(
	() => currentUser.value && currentUser.value.leveltenant === 3
);

// Custom validator
const cannotZero = (value) => value !== 0;
const requireFile = () => !savedFile.value && !file.value;

// Vuelidate rules
const validationRules = computed(() => {
	const rules = {
		company: { required },
		invoiceNo: { required },
		invoiceDate: { required },
		description: { required },
		taxInvoiceNo: { required },
		taxInvoiceDate: { required },
		bank: { required },
		currency: { required },
		file: { requiredIfFunction: requiredIf(requireFile) },
		selectedCategory: {
			$each: helpers.forEach({
				description: { required },
				amount: {
					required,
					cannotZero: helpers.withMessage(
						'The amount value must be greater than zero',
						cannotZero
					),
				},
			}),
		},
		reimburseItems: {
			$each: helpers.forEach({
				taxNo: { required },
				taxDate: { required },
				taxAmount: {
					required,
					cannotZero: helpers.withMessage(
						'The amount value must be greater than zero',
						cannotZero
					),
				},
				group: { required },
			}),
		},
	};

	if (isForwarder.value) {
		rules.skNumber = { required };
	}

	return rules;
});

// Validator instance
const v$ = useVuelidate(validationRules, {
	company,
	invoiceNo,
	invoiceDate,
	description,
	taxInvoiceNo,
	taxInvoiceDate,
	skNumber,
	bank,
	currency,
	file,
	selectedCategory,
	reimburseItems,
});

// Toast intance
const toast = useToast();

// Summary invoice
const summary = computed(() => {
	const totalLine =
		(selectedCategory.value.length &&
			selectedCategory.value.reduce((accumulator, obj) => {
				return accumulator + obj.amount;
			}, 0)) ||
		0;

	const totalPpn =
		(selectedCategory.value.length &&
			selectedCategory.value
				.map((el) => {
					return el.vat && el.vat.value
						? el.vat.value * el.amount
						: 0;
				})
				.reduce((prevValue, currValue) => prevValue + currValue, 0)) ||
		0;

	const totalInvoice = totalLine + totalPpn;

	return {
		totalLine,
		totalPpn,
		totalInvoice,
	};
});

// Show reimburse table
const showReimburseTable = computed(() =>
	selectedCategory.value.some((cat) =>
		cat.description.toLowerCase().includes('reimburstment')
	)
);

// Mounted hook
onMounted(async () => {
	// Get current user and company list
	const getUser = getCurrentUser();
	const getComp = getCompany();

	await Promise.all([getUser, getComp]);

	// Get invoice detail
	await getInvoiceDetail(props.id);

	// Get category, supplier name, list bank, vat, reimburst group
	const getCategory = getCategoryVendor();
	const getSuppName = getSupplierName();
	const getBank = getListBank();
	const getCurrencies = getCurrencyList();
	const getVat = getVatRate();
	const getVendors = getListVendor();
	const getGroups = getReimburstGroups();

	await Promise.all([
		getSuppName,
		getBank,
		getCurrencies,
		getCategory,
		getVat,
		getVendors,
		getGroups,
	]);
});

// Unmounted hook
// onUnmounted(() => {
// 	unwatchCompany();
// });

// Watch selected company
// const unwatchCompany = watch(company, (newCompany, oldCompany) => {
// 	if (oldCompany) {
// 		bank.value = null;
// 		bankList.value = [];
// 		categoryHeader.value = null;
// 		categoryDetail.value = null;
// 		skNumber.value = null;
// 		vatRates.value = null;
// 		vendors.value = null;
// 		reimburstGroups.value = null;
// 		category.value = null;
// 		selectedCategory.value = [];
// 		reimburseItems.value = [];

// 		getListBank();
// 		getCategoryVendor();
// 		getVatRate();
// 		getListVendor();
// 		getReimburstGroups();
// 	}
// });

// Watch bank list if on detail invoice page
watch(bankList, (newBankList) => {
	bank.value = newBankList.find((item) => item.id === bankAccId.value);
});

// Watch vat rates
watch(vatRates, (newVatRates) => {
	// Populate vat rate data when first load
	selectedCategory.value =
		selectedCategory.value.length &&
		selectedCategory.value.map((cat) => {
			if (cat.vat && typeof cat.vat === 'number') {
				cat.vat = newVatRates.find(
					(vat) => vat.tax_rate_id === cat.vat
				);
			}

			return cat;
		});
});

// Get company
const getCompany = async () => {
	try {
		const res = await InvoiceListProxyService.getCompanyList();

		companyList.value = res.data.data;
	} catch (err) {
		console.log(err);
	}
};

// Get current user
const getCurrentUser = async () => {
	const resAuth = await AuthService.whoAmi();
	currentUser.value = resAuth.data.data;

	// Check if user is not PIC nor finance nor admin
	if (
		currentUser.value.leveltenant !== 3 &&
		currentUser.value.leveltenant !== 1 &&
		currentUser.value.userid !== 'dexaadmin'
	) {
		// Redirect to homepage
		router.push('/dashboard');
		return;
	}
};

// Get invoice detail
const getInvoiceDetail = async (id) => {
	try {
		loading.value = true;

		const query = route.query;

		const payload = {
			dbInstance: query.i,
			orgId: query.v,
			userId: isUserPic.value ? currentUser.value.id : null,
		};

		const res = await InvoiceListProxyService.getInvoiceDetail(id, payload);

		if (res.status === 200) {
			const invoiceHeader = res.data.data.header;
			const invoiceDetails = res.data.data.details;
			const invoiceReimbursements = res.data.data.reimbursements;
			const invoiceFile = res.data.data.file;

			// Assign invoice header
			categoryId.value = invoiceHeader.category_id;
			invoiceNo.value = invoiceHeader.invoice_no;
			invoiceDate.value = new Date(invoiceHeader.invoice_date);
			description.value = invoiceHeader.description;
			taxInvoiceNo.value = invoiceHeader.tax_no;
			taxInvoiceDate.value = new Date(invoiceHeader.tax_date);
			skNumber.value = invoiceHeader.reference_number;
			status.value = invoiceHeader.status;
			if(status.value === 'REVIEW'){
				var rekomendator = await invoiceHeader.REKOMENDATOR.substring(0, invoiceHeader.REKOMENDATOR.lastIndexOf('@') + 0);
				rekomendator = await rekomendator.replace(/\./g,' ').replace(/_/g,' ').toUpperCase();
				status.value = invoiceHeader.status + ' (Waiting Approval : ' + rekomendator + ')'
			}
			if (status.value === 'REJECT'){
				var rekomendatorreject = await invoiceHeader.REKOMENDATOR.substring(0, invoiceHeader.REKOMENDATOR.lastIndexOf('@') + 0); 
				rekomendatorreject = await rekomendatorreject.replace(/\./g, ' ').replace(/_/g, ' ').toUpperCase();
				status.value = invoiceHeader.status + ' (Rejected By : ' + rekomendatorreject + ')';
				}
			rejectReason.value = invoiceHeader.reason;
			recommendatorReason.value = invoiceHeader.recommendator_reason;
			bankAccId.value = invoiceHeader.external_bank_account_id;
			currency.value = invoiceHeader.currency_code;
			vendorId.value = invoiceHeader.vendor_id;
			company.value = companyList.value.find(
				(item) =>
					item.db_instance == invoiceHeader.db_instance &&
					parseInt(item.value) === invoiceHeader.org_id
			);

			// Mapping selected currency to currency list
			if (currencyList.value) {
				currency.value = currencyList.value.find(
					(curr) => curr.currency_code === currency.value
				);
			}

			// Assign invoice detail
			selectedCategory.value = invoiceDetails.map((item) => {
				const remarksArr = item.remarks && item.remarks.split('#');

				return {
					...item,
					description: item.item_description,
					amount: item.amount,
					vat: item.vat_code_id,
					travelOrder: {
						travelOrderNo: remarksArr ? remarksArr[0] : null,
						guestName: remarksArr ? remarksArr[1] : null,
						startDate: remarksArr ? remarksArr[2] : null,
						endDate: remarksArr ? remarksArr[3] : null,
					},
				};
			});

			// Mapping selected vat rate if there is vatRates list
			if (vatRates.value) {
				selectedCategory.value = selectedCategory.value.map((item) => {
					if (item.vat) {
						item.vat = vatRates.value.find(
							(vat) => vat.tax_rate_id === item.vat
						);
					}

					return item;
				});
			}

			// Assign invoice reimburse items
			reimburseItems.value = invoiceReimbursements.map((item) => {
				return {
					reimbursementId: item.einvoice_reimbursment_id,
					vendor: item.vendor_id,
					taxNo: item.tax_no,
					taxDate: new Date(item.tax_date),
					taxAmount: item.amount,
					group: item.group_name,
				};
			});

			// Mapping vendor id and selected group on reimburse items
			if (vendors.value && reimburstGroups.value) {
				reimburseItems.value = reimburseItems.value.map((item) => {
					// Mapping vendor id
					if (item.vendor) {
						item.vendor = vendors.value.find(
							(vendor) => vendor.vendor_id === item.vendor
						);
					}

					// Mapping group
					item.group = reimburstGroups.value.find(
						(grp) => grp.name === item.group
					);

					return item;
				});
			}

			// Assign invoice file
			savedFile.value = invoiceFile;
			await getFileBase64(id);
		}

		loading.value = false;
	} catch (err) {
		console.log(err);
		loading.value = false;
		toast.add({
			severity: 'error',
			summary: 'Invoice data is not found',
			life: 3000,
		});
	}
};

// Get Supplier Name
const getSupplierName = async () => {
	const payload = {
		dbInstance: company.value.db_instance,
		vendorId: vendorId.value,
	};

	try {
		const res = await InvoiceCreateNonPOService.getSupplierName(payload);

		if (res.status === 200) {
			supplierName.value = res.data.data;
		}
	} catch (err) {
		console.log(err);
	}
};

// Get bank account list
const getListBank = async () => {
	const payload = {
		dbInstance: company.value.db_instance,
		orgId: company.value.value,
		vendorId: vendorId.value,
	};

	const res = await InvoicePOService.getListBank(payload);

	if (res.status === 200) {
		bankList.value = res.data.data.map((item) => {
			return {
				id: item.ext_bank_account_id,
				name: item.transfer_to,
			};
		});
	}
};

// Get line categories
const getCategoryVendor = async () => {
	const payload = {
		dbInstance: company.value.db_instance,
		orgId: company.value.value,
		categoryId: categoryId.value,
	};

	const res = await InvoiceListProxyService.getCategory(payload);

	if (res.status === 200) {
		categoryHeader.value = res.data.data.categoryHeader;

		if (!isForwarder.value) {
			categoryDetail.value = res.data.data.categoryDetail;
		} else {
			categoryDetail.value = [];
		}
	}
};

// Get VAT rate options
const getVatRate = async () => {
	const payload = {
		dbInstance: company.value.db_instance,
		invoiceDate:
			invoiceDate.value && formattingDate(new Date(invoiceDate.value)),
	};

	const res = await InvoiceCreateNonPOService.getVatRate(payload);

	if (res.status === 200) {
		vatRates.value = res.data.data.map((item) => ({
			...item,
			value: Math.round((item.percentage_rate / 100) * 1000) / 1000,
		}));

		// Push no select option
		vatRates.value = [
			{ vat_code: '-No Select-', value: null },
			...vatRates.value,
		];
	}
};

// Get list vendor
const getListVendor = async () => {
	try {
		const res = await InvoiceCreateNonPOService.getListVendor({
			dbInstance: company.value.db_instance,
		});

		if (res.status === 200) {
			vendors.value = res.data.data;

			// Mapping vendor name on table raimbursemnet
			reimburseItems.value = reimburseItems.value.map((item) => {
				if (item.vendor) {
					const vendor = vendors.value.find(
						(vendor) => vendor.vendor_id === item.vendor
					);
					return {
						...item,
						vendor,
					};
				}

				return item;
			});
		}
	} catch (err) {
		console.log(err);
	}
};

// Get reimburst groups options
const getReimburstGroups = async () => {
	const payload = {
		dbInstance: company.value.db_instance,
		type: 'DXG_REIMBURST_GROUP',
	};

	try {
		const res = await InvoiceCreateNonPOService.getReimburstGroups(payload);

		if (res.status === 200) {
			reimburstGroups.value = res.data.data;

			// Mapping selected group dropdown on table raimbursemnet
			reimburseItems.value = reimburseItems.value.map((item) => {
				const group = reimburstGroups.value.find(
					(grp) => grp.name === item.group
				);

				return {
					...item,
					group,
				};
			});
		}
	} catch (err) {
		console.log(err);
	}
};

// Get currency list
const getCurrencyList = async () => {
	try {
		const res = await InvoiceListProxyService.getCurrencyList({
			dbInstance: company.value.db_instance,
		});

		if (res.status === 200) {
			currencyList.value = res.data.data;

			// Mapping selected currency
			currency.value = currencyList.value.find(
				(curr) => curr.currency_code === currency.value
			);
		}
	} catch (err) {
		console.log(err);
	}
};

// Search company on autocomplete
const searchCompany = (event) => {
	if (!event.query.trim().length) {
		filteredCompany.value = [...companyList.value];
	} else {
		filteredCompany.value = companyList.value.filter((item) => {
			return item.name.toLowerCase().match(event.query.toLowerCase());
		});
	}
};

// Search bank on autocomplete
const searchBank = (event) => {
	if (!event.query.trim().length) {
		filteredBanks.value = [...bankList.value];
	} else {
		filteredBanks.value = bankList.value.filter((bank) => {
			return bank.name.toLowerCase().match(event.query.toLowerCase());
		});
	}
};

// Search currency on autocomplete
const searchCurrency = (event) => {
	if (!event.query.trim().length) {
		filteredCurrencies.value = [...currencyList.value];
	} else {
		filteredCurrencies.value = currencyList.value.filter((curr) => {
			return curr.currency_code
				.toLowerCase()
				.match(event.query.toLowerCase());
		});
	}
};

// Search category on autocomplete
const searchCategory = async (event) => {
	isWarningShown.value = false;

	if (isForwarder.value) {
		// Check if SK Number is selected
		if (!skNumber.value) {
			isWarningShown.value = true;
			filteredCategory.value = [];
			return;
		}

		// Fetch category detail based on selected SK Number
		const payload = {
			categoryId: categoryId.value,
			dbInstance: company.value.db_instance,
			orgId: company.value.value,
			skNumber: skNumber.value,
			vendorId: vendorId.value,
		};

		const res = await InvoiceListProxyService.getAvailableCategoryDetails(
			payload
		);
		const categories = res.data.data;

		if (!event.query.trim().length) {
			filteredCategory.value = [...categories];
		} else {
			filteredCategory.value = categories.filter((category) => {
				return category.category_detail
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
		}
	} else {
		if (!event.query.trim().length) {
			filteredCategory.value = [...categoryDetail.value];
		} else {
			filteredCategory.value = categoryDetail.value.filter((category) => {
				return category.category_detail
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
		}
	}
};

// Search vendors on autocomplete
const searchVendors = async (event) => {
	if (!event.query.trim().length) {
		filteredVendors.value = [...vendors.value];
	} else {
		filteredVendors.value = vendors.value.filter((vendor) => {
			return (
				vendor.vendor_name &&
				vendor.vendor_name
					.toLowerCase()
					.match(event.query.toLowerCase())
			);
		});
	}
};

// Add SK Number
const addSKNumber = (data) => {
	skNumber.value = data.sk_number;
	displaySKModal.value = false;
	isWarningShown.value = false;

	if (isForwarder.value) {
		// Add removed details payload
		removedLines.value = [...removedLines.value, ...selectedCategory.value];
		removedReimburseItems.value = [
			...removedReimburseItems.value,
			...reimburseItems.value,
		];

		// Add po number to description field
		description.value = data.po_number;

		// Reset fields
		selectedCategory.value = [];
		category.value = null;
		reimburseItems.value = [];
	}
};

// Delete attachment file
const handleDeleteFile = () => {
	deletedFile.value = savedFile.value;
	savedFile.value = null;
};

// Select file upload
const onSelectFile = (e) => {
	file.value = e.files[0];
};

// Remove file upload
const onRemoveFile = (e) => {
	if (!e.files.length) {
		file.value = null;
	}
};

// React to select invoice date event
const handleSelectInvoiceDate = () => {
	// Reset vat options and selected vat
	vatRates.value = null;
	selectedCategory.value = selectedCategory.value.map((item) => ({
		...item,
		vat: null,
	}));
	getVatRate();
};

// Add line category
const handleAddCategory = () => {
	if (!category.value) {
		return;
	}

	if (isForwarder.value) {
		const isAdded = selectedCategory.value.some(
			(cat) =>
				cat.category_detail_id === category.value.category_detail_id
		);
		if (isAdded) {
			category.value = null;
			return;
		}

		selectedCategory.value.push({
			...category.value,
			description: category.value.category_detail,
			amount: 0,
			vat: null,
			travelOrder: {
				travelOrderNo: null,
				guestName: null,
				startDate: null,
				endDate: null,
			},
		});
	} else {
		selectedCategory.value.push({
			...category.value,
			description: category.value.category_detail,
			amount: 0,
			vat: null,
			travelOrder: {
				travelOrderNo: null,
				guestName: null,
				startDate: null,
				endDate: null,
			},
		});
	}

	category.value = null;
};

// Delete line category
const handleDeleteCategory = (deletedIndex) => {
	const item = selectedCategory.value.find(
		(el, index) => index === deletedIndex
	);

	// Push to remove details payload if category detail has been saved before
	if (item.einvoice_line_id) {
		removedLines.value.push(item);
	}

	// Delete from selected category array
	selectedCategory.value = selectedCategory.value.filter(
		(el, index) => index !== deletedIndex
	);

	// Check if deleted category is 'REIMBURSTMENT', then also delete table reimbursement
	const isReimburseExist = selectedCategory.value.some((cat) =>
		cat.description.toLowerCase().includes('reimburstment')
	);

	if (
		item.description.toLowerCase().includes('reimburstment') &&
		!isReimburseExist
	) {
		// Add reimburse items to deleted reimburse payload
		reimburseItems.value.forEach((item) => {
			if (item.reimbursementId) {
				removedReimburseItems.value.push(item);
			}
		});

		// Reset reimburse items array
		reimburseItems.value = [];
	}
};

// Open TO Modal
const openTOModal = (index) => {
	displayTOModal.value = true;
	travelOrderIndex.value = index;
};

// Close TO Modal
const closeTOModal = () => {
	displayTOModal.value = false;
	travelOrderIndex.value = null;
};

// Handle add TO
const handleAddTO = (selectedTO) => {
	selectedCategory.value = selectedCategory.value.map((category, index) => {
		if (index === travelOrderIndex.value) {
			category.travelOrder = {
				travelOrderNo: selectedTO.travel_order_no,
				guestName: selectedTO.guest_name,
				startDate: selectedTO.start_date,
				endDate: selectedTO.end_date,
			};
		}

		return category;
	});

	travelOrderIndex.value = null;
	displayTOModal.value = false;
};

// Add reimbursement item
const handleAddReimbursement = () => {
	reimburseItems.value = [
		...reimburseItems.value,
		{
			vendor: null,
			taxNo: null,
			taxDate: null,
			taxAmount: 0,
			group: null,
		},
	];
};

// Delete reimbursement item
const handleDeleteReimburse = (deletedIndex) => {
	// Find deleted item
	const item = reimburseItems.value.find(
		(el, index) => index === deletedIndex
	);

	// Push to remove reimburse items payload if the item has been saved before
	if (item.reimbursementId) {
		removedReimburseItems.value.push(item);
	}

	// Delete data from table reimbursement
	reimburseItems.value = reimburseItems.value.filter(
		(el, index) => index !== deletedIndex
	);
};

// Formatting date
const formattingDate = (date, withTime) => {
	const theDate = new Date(date);
	const strDate =
		theDate.getFullYear() +
		'-' +
		(theDate.getMonth() + 1) +
		'-' +
		theDate.getDate();
	const strTime =
		theDate.getHours() +
		':' +
		theDate.getMinutes() +
		':' +
		theDate.getSeconds();

	if (withTime) {
		return `${strDate} ${strTime}`;
	} else {
		return `${strDate}`;
	}
};

const handleChangeTax = (event) => {
	const {value} = event.target;
	taxInvoiceNo.value = value.replace(/[a-zA-Z]/g,"").substring(0,20)
}

// Save new invoice
const handleSave = async () => {
	// Reset validation state
	v$.value.$reset();
	errorExisting.value = false;

	// Validate required fields
	const isFormValid = await v$.value.$validate();
	if (!isFormValid) {
		let errMessage;

		if (v$.value.$errors[0].$validator === 'required') {
			errMessage = 'Please fill all required fields';
		} else {
			if (Array.isArray(v$.value.$errors[0].$message)) {
				errMessage = v$.value.$errors[0].$message.find(
					(err) => err.length
				)[0];
			} else {
				errMessage = v$.value.$errors[0].$message;
			}
		}

		toast.add({
			severity: 'error',
			summary: errMessage,
			life: 3000,
		});
		return;
	}

	// Validate if there is no details
	if (!selectedCategory.value.length) {
		toast.add({
			severity: 'error',
			summary: 'Please add invoice details',
			life: 3000,
		});
		return;
	}

	const formData = new FormData();

	// New file payload
	if (!savedFile.value && file.value) {
		formData.append('file', file.value);
	}

	// Add current payload for header and details
	const payload = {
		header: {
			EINVOICE_ID: props.id,
			INVOICE_NO: invoiceNo.value,
			INVOICE_DATE: formattingDate(invoiceDate.value),
			// REVISION_NUM: 0,
			TAX_NO: taxInvoiceNo.value,
			TAX_DATE: formattingDate(taxInvoiceDate.value),
			DESCRIPTION: description.value,
			CURRENCY_CODE: currency.value.currency_code,
			VENDOR_ID: vendorId.value,
			INVOICE_AMOUNT: summary.value.totalLine,
			TAX_AMOUNT: summary.value.totalPpn,
			TOTAL_AMOUNT: summary.value.totalInvoice,
			// SERVICE_AMOUNT: summary.value.totalPph,
			// WHT_CODE: ,
			// WHT_RATE: ,
			// ATTRIBUTE1: ,
			// ORG_ID: parseInt(company.value.value),
			// STATUS: 'DRAFT',
			// CREATION_DATE: formattingDate(new Date(), true),
			// CREATED_BY: currentUser.value.id,
			LAST_UPDATE_DATE: formattingDate(new Date(), true),
			LAST_UPDATED_BY: currentUser.value.id,
			// PAYMENT_METHOD_LOOKUP_CODE: ,
			// GL_DATE: ,
			// TERMS_ID: ,
			// REASON: ,
			// ACCTS_CODE_COMBINATION_ID: categoryHeader.value.account_id,
			EXTERNAL_BANK_ACCOUNT_ID: bank.value.id,
			// INVOICE_TYPE: 'INVOICE NON PO',
			// CATEGORY_ID: categoryHeader.value.category_id,
			// MAPING_ID: categoryHeader.value.maping_id,
			REFERENCE_NUMBER: skNumber.value,
			// VENDOR_SITE_ID: categoryHeader.value.vendor_site_id,
			// COST_CENTER: categoryHeader.value.cost_center,
			// DB_INSTANCE: company.value.db_instance,
		},
		details: selectedCategory.value
			.filter((item) => item.einvoice_line_id)
			.map((item, index) => {
				const dateOptions = {
					year: 'numeric',
					month: 'short',
					day: 'numeric',
				};
				const remarksString =
					item.travelOrder.travelOrderNo &&
					`${item.travelOrder.travelOrderNo}#${
						item.travelOrder.guestName
					}#${new Intl.DateTimeFormat('en-US', dateOptions).format(
						new Date(item.travelOrder.startDate)
					)}#${new Intl.DateTimeFormat('en-US', dateOptions).format(
						new Date(item.travelOrder.endDate)
					)}`;

				return {
					EINVOICE_LINE_ID: item.einvoice_line_id,
					LINE_NUM: index + 1,
					ITEM_DESCRIPTION: item.description,
					AMOUNT: item.amount,
					VAT_CODE:
						item.vat && item.vat.value ? item.vat.vat_code : null,
					LAST_UPDATE_DATE: formattingDate(new Date(), true),
					LAST_UPDATED_BY: currentUser.value.id,
					REMARKS: remarksString,
					// REVISION_NUM: 0,
					// CATEGORY_DETAIL_ID: item.category_detail_id,
					// COA_ID: item.account_id,
					VAT_CODE_ID:
						item.vat && item.vat.value
							? item.vat.tax_rate_id
							: null,
					CURRENCY_CODE: currency.value.currency_code,
				};
			}),
		newDetails: [],
		removedDetails: [],
		reimburseItems: reimburseItems.value
			.filter((item) => item.reimbursementId)
			.map((item) => {
				return {
					EINVOICE_REIMBURSMENT_ID: item.reimbursementId,
					VENDOR_ID: item.vendor ? item.vendor.vendor_id : null,
					TAX_NO: item.taxNo,
					TAX_DATE: formattingDate(item.taxDate),
					AMOUNT: item.taxAmount,
					LAST_UPDATED_BY: currentUser.value.id,
					LAST_UPDATE_DATE: formattingDate(new Date()),
					GROUP_NAME: item.group.value,
				};
			}),
		newReimburseItems: [],
		removedReimburseItems: [],
		deletedFile: null,
	};

	// New details payload
	if (selectedCategory.value.some((cat) => !cat.einvoice_line_id)) {
		payload.newDetails = selectedCategory.value
			.filter((category) => !category.einvoice_line_id)
			.map((item, index) => {
				const dateOptions = {
					year: 'numeric',
					month: 'short',
					day: 'numeric',
				};
				const remarksString =
					item.travelOrder.travelOrderNo &&
					`${item.travelOrder.travelOrderNo}#${
						item.travelOrder.guestName
					}#${new Intl.DateTimeFormat('en-US', dateOptions).format(
						new Date(item.travelOrder.startDate)
					)}#${new Intl.DateTimeFormat('en-US', dateOptions).format(
						new Date(item.travelOrder.endDate)
					)}`;

				// Calculate line num of invoice item
				const lineNum =
					payload.details.length +
					index +
					1 -
					payload.removedDetails.length;

				return {
					EINVOICE_ID: props.id,
					// PO_HEADER_ID: item.po_header_id,
					// PO_LINE_ID: item.po_line_id,
					// PO_NUMBER: item.po_number,
					LINE_NUM: lineNum,
					// ITEM_ID: item.item_id,
					// ITEM_NUMBER: item.item_number,
					ITEM_DESCRIPTION: item.description,
					// UNIT_MEAS_LOOKUP_CODE: item.unit_meas_lookup_code,
					// UNIT_PRICE: item.unit_price,
					// UNIT_PRICE_SUP: ,
					// QUANTITY: item.quantity_received,
					AMOUNT: item.amount,
					// NOTE_TO_VENDOR: ,
					ORGANIZATION_ID: company.value.value,
					// LINE_LOCATION_ID: item.line_location_id,
					VAT_CODE:
						item.vat && item.vat.value ? item.vat.vat_code : null,
					// RECEIPT_NUM: item.receipt_num,
					// PACKING_SLIP: item.packing_slip === 'NA' ? null : item.packing_slip,
					// DISTRIBUTION_NUM: item.distribution_num,
					// PO_DISTRIBUTION_ID: item.po_distribution_id,
					// SHIPMENT_NUM: item.shipment_num,
					CREATION_DATE: formattingDate(new Date(), true),
					CREATED_BY: currentUser.value.id,
					LAST_UPDATE_DATE: formattingDate(new Date(), true),
					LAST_UPDATED_BY: currentUser.value.id,
					REMARKS: remarksString,
					// ATTRIBUTE1: item,
					CURRENCY_CODE: currency.value.currency_code,
					REVISION_NUM: 0,
					// VENDOR_ID: item.vendor_id,
					// VENDOR_SITE_ID: item.vendor_site_id,
					// SHIPMENT_LINE_ID: item.shipment_line_id,
					// SHIPMENT_HEADER_ID: item.shipment_header_id,
					// RCV_TRANSACTION_ID: item.transaction_id,
					CATEGORY_DETAIL_ID: item.category_detail_id,
					// WHT_CODE: item,
					COA_ID: item.account_id,
					VAT_CODE_ID:
						item.vat && item.vat.value
							? item.vat.tax_rate_id
							: null,
					// WHT_CODE_ID: item,
					// GROUP_NAME: item,
				};
			});
	}

	// Removed details payload
	if (removedLines.value.length) {
		payload.removedDetails = removedLines.value.filter(
			(item) => item.einvoice_line_id
		);
	}

	// New reimburse items
	if (
		showReimburseTable.value &&
		reimburseItems.value.length &&
		reimburseItems.value.some((item) => !item.reimbursementId)
	) {
		payload.newReimburseItems = reimburseItems.value
			.filter((item) => !item.reimbursementId)
			.map((item) => {
				const coaId = selectedCategory.value.find((cat) =>
					cat.description.toLowerCase().includes('reimburstment')
				)['account_id'];

				return {
					EINVOICE_ID: props.id,
					VENDOR_ID: item.vendor ? item.vendor.vendor_id : null,
					TAX_NO: item.taxNo,
					TAX_DATE: formattingDate(item.taxDate),
					AMOUNT: item.taxAmount,
					COA_ID: coaId,
					ORG_ID: company.value.value,
					CREATED_BY: currentUser.value.id,
					CREATION_DATE: formattingDate(new Date()),
					LAST_UPDATED_BY: currentUser.value.id,
					LAST_UPDATE_DATE: formattingDate(new Date()),
					GROUP_NAME: item.group.value,
				};
			});
	}

	// Removed reimbursement items payload
	if (removedReimburseItems.value.length) {
		payload.removedReimburseItems = removedReimburseItems.value.filter(
			(item) => item.reimbursementId
		);
	}

	// Add deleted file payload
	if (deletedFile.value && file.value) {
		payload.deletedFile = deletedFile.value;
	}

	// Wrap payload with formData
	const jsonPayload = JSON.stringify(payload);
	formData.append('payload', jsonPayload);

	try {
		progress.value = true;

		const res = await InvoiceListProxyService.updateInvoice(
			props.id,
			formData
		);

		if (res.status === 200) {
			progress.value = false;
			invoiceNo.value = res.data.data.invoice_no;
			deletedFile.value = null;
			removedLines.value = [];
			removedReimburseItems.value = [];
			v$.value.$reset();

			if (file.value) {
				file.value = null;
				fileUpload.value.clear();
			}

			// Re-assign saved invoice details
			getInvoiceDetail(props.id);

			toast.add({
				severity: 'success',
				summary: 'Success',
				detail: 'Successfully update invoice',
				life: 3000,
			});

			return;
		}
		progress.value = false;
	} catch (err) {
		console.log(err.message);
		progress.value = false;

		if (err.message.includes('Network Error')) {
			console.log('network error');
			toast.add({
				severity: 'error',
				summary: 'Network Error',
				detail: 'Connection Down. Please check your invoice status on List Invoice Non PO menu.',
			});
			return;
		}

		if (err.response.status === 400 || err.response.status === 403) {
			if (err.response.data.message.includes('exist')) {
				errorExisting.value = true;
			}
			toast.add({
				severity: 'error',
				summary: 'Error',
				detail: err.response.data.message,
				life: 3000,
			});
			return;
		}

		toast.add({
			severity: 'error',
			summary: 'Server Error',
			detail: 'Unsuccessfully save new invoice',
			life: 3000,
		});
	}
};

const getFileBase64 = async (invoiceId) => {
	try {
		const leveltenant = sessionStorage.getItem('leveltenant');
		const res = await InvoiceListProxyService.getFileBase64(
			invoiceId,
			leveltenant,
			vendorId.value
		);

		if (res.status === 200) {
			fileBuffer.value = res.data.data;
		}
	} catch (err) {
		console.log(err);
	}
};

const onDownload = async () => {
	try {
		loadingDownladFile.value = true;
		const leveltenant = sessionStorage.getItem('leveltenant');
		const res = await InvoiceListProxyService.getFileBase64(
			props.id,
			leveltenant,
			vendorId.value
		);

		if (res.status === 200) {
			fileBuffer.value = res.data.data;
		}
	} catch (err) {
		loadingDownladFile.value = false;
		toast.add({
			severity: 'error',
			summary: 'Connection Unstable !',
			life: 3000,
		});
		throw Error('Error downloading file');
	}
	const linkSource = `data:application/pdf;base64,${fileBuffer.value}`;
	const downloadLink = document.createElement('a');
	const fileName = savedFile.value.filename;
	downloadLink.href = linkSource;
	downloadLink.download = fileName;
	downloadLink.click();
	loadingDownladFile.value = false;
};
</script>

<style scoped>
.form-card {
	padding: 24px;
	border: 1px solid #e0e0e0;
	border-radius: 16px;
}
</style>
