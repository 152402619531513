<template>
	<div class="grid">
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<!-- Input Search -->
		<div class="col-12 p-fluid mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search</div>
				<div class="content-body">
					<div class="grid formgrid">
						<div class="col-12 mb-3 lg:col-4">
							<span class="p-float-label">
								<AutoComplete
									v-model="company"
									:suggestions="filteredCompany"
									@complete="searchCompany($event)"
									:dropdown="true"
									field="name"
									forceSelection
								>
									<template #item="slotProps">
										<div class="supplier-item">
											<div class="ml-2">
												{{ slotProps.item.name }}
											</div>
										</div>
									</template>
								</AutoComplete>
								<label for="inputtext">Company</label>
							</span>
						</div>
						<div class="col-12 mb-3 lg:col-4">
							<span class="p-float-label">
								<Calendar
									v-model="dateFrom"
									dateFormat="d-M-yy"
									:showIcon="true"
								/>
								<label for="inputtext">Due Date From</label>
							</span>
						</div>
						<div class="col-12 mb-5 lg:col-4">
							<span class="p-float-label">
								<Calendar
									v-model="dateTo"
									dateFormat="d-M-yy"
									:showIcon="true"
								/>
								<label for="inputtext">Due Date To</label>
							</span>
						</div>
						<div class="col-12 mb-5 lg:col-4">
							<span class="p-float-label">
								<AutoComplete
									v-model="poNumber"
									:suggestions="filteredPoNumber"
									:disabled="!isiPoNumber"
									@complete="searchPoNumber($event)"
									field="po_number"
									forceSelection
								>
								</AutoComplete>
								<label for="inputtext">PO Number</label>
							</span>
						</div>
						<div class="col-12 mb-5 lg:col-4">
							<span class="p-float-label">
								<InputText
									type="text"
									v-model="itemDescription"
								/>
								<label for="inputtext">Item Description</label>
							</span>
						</div>
						<!-- Buttons -->
						<div class="col-12 lg:col-4">
							<Button
								class="p-button-success w-auto mr-2"
								@click="handleSearchPo"
								:disabled="isButtonSearchDisable"
							>
								<span class="font-bold uppercase">Search</span>
							</Button>
							<Button
								class="w-auto p-button-secondary uppercase"
								label="Clear"
								@click="clearFilter"
							></Button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Search results -->
		<div class="col-12 p-fluid mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search Result</div>
				<div class="content-body">
					<div class="grid">
						<!-- Action button -->
						<div class="col-auto ml-auto mb-4">
							<Button
								class="p-button-success"
								:disabled="
									!this.selectedPo ||
									this.selectedPo.length < 1
								"
								@click="handleAddShipment"
							>
								<span class="uppercase font-bold"
									>Add Shipment Notice</span
								>
							</Button>
						</div>

						<div class="col-12">
							<DataTable
								:value="purchaseOrders"
								:lazy="true"
								:paginator="true"
								:rows="10"
								:rowsPerPageOptions="[10, 20, 50]"
								:metaKeySelection="false"
								:loading="loading"
								:totalRecords="totalRecords"
								ref="dt"
								dataKey="id"
								paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
								currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
								responsiveLayout="scroll"
								:scrollable="true"
								scrollHeight="600px"
								selectionMode="multiple"
								v-model:selection="selectedPo"
								@page="onPage($event)"
								@sort="onSort($event)"
								@row-select="onRowSelect"
							>
								<Column
									class="call-button-container"
									header="PO Number"
									field="SEGMENT1"
									:sortable="true"
								></Column>
								<Column
									class="call-button-container"
									header="Line"
									field="LINE_NUM"
									:sortable="true"
								></Column>
								<Column
									class="call-button-container"
									header="Item Number"
									field="ITEM_NUMBER"
									:sortable="true"
								></Column>
								<Column
									class="call-button-container"
									header="Item Description"
									field="ITEM_DESCRIPTION"
								></Column>
								<Column
									class="call-button-container"
									header="UOM"
									field="UOM"
									:sortable="true"
								></Column>
								<Column
									class="call-button-container"
									header="Ship To"
									field="SHIP_TO_NAME"
								></Column>
								<Column
									class="call-button-container"
									header="Qty. Order"
									field="QUANTITY"
								></Column>
								<Column
									class="call-button-container"
									header="Qty. Open"
									field="QUANTITY_OPEN"
								>
								</Column>
								<Column
									class="call-button-container"
									header="Qty. Shipped"
									field="QUANTITY_SHIPPED"
								>
								</Column>
								<Column
									class="call-button-container"
									header="Due Date"
									field="DUE_DATE"
									:sortable="true"
								></Column>
								<Column header="View" alignFrozen="right" frozen>
									<template #body="{ data }">
										<Button
											icon="pi pi-search"
											class="p-button-rounded p-button-text"
											@click="
												this.$router.push({
													name: 'DetailpoFulfillmentSupp',
													params: {
														id: data.PO_KEY_ID,
													},
												})
											"
										/>
									</template>
								</Column>

								<!-- Empty state -->
								<template #empty>
									{{ emptyMessage }}
								</template>
							</DataTable>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- <div
			class="w-full mt-4 flex items-center justify-center"
			v-if="isLoading"
		>
			<ProgressSpinner style="width: 40px" />
		</div> -->
	</div>
</template>

<script>
import AuthService from '../../service/AuthService';
import LovService from '../../service/LovService';
import SimpleCrypto from 'simple-crypto-js';
import PoFulfillment from '../../service/PoFulfillment';
import PlanningOrder from '../../service/PlanningOrderService';
import CreateShipmentService from '../../service/CreateShipmentService';

export default {
	name: 'CreateShipmentNotice',
	data() {
		return {
			currentUser: null,
			rowId: null,
			purchaseOrders: null,
			selectedPo: null,
			loading: false,
			lazyParams: {},
			totalRecords: 0,
			emptyMessage: null,
			company: null,
			filteredCompany: null,
			isiCompany: null,
			poNumber: null,
			isiPoNumber: null,
			filteredPoNumber: null,
			dateFrom: null,
			dateTo: null,
			itemDescription: null,
			payloadSearch: null,
			saveDb: null,
			suppBindToComp: [],
			vendorId: null,
			isLoading: false,

			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [
				{
					label: 'Create Shipment Notice',
					to: '/mgm/settings/createshipment',
				},
			],
		};
	},
	computed: {
		isButtonSearchDisable() {
			// Update condition for disabling search button
			return !this.currentUser || !this.company || !this.vendorId;

			// if (this.currentUser && !this.vendorId && !this.company) {
			// 	return true;
			// } else {
			// 	return (
			// 		!this.company &&
			// 		!this.poNumber &&
			// 		!this.dateFrom &&
			// 		!this.dateTo &&
			// 		!this.itemDescription
			// 	);
			// }
		},
	},
	watch: {
		company(newQuestion) {
			if (this.company != null) {
				sessionStorage.setItem('mailToCompany', this.company.name);
				this.poNumber = null;
				this.itemDescription = null;
				this.dateFrom = null;
				this.dateTo = null;

				// Call PO Num list
				this.getPoNumber();

				if (
					typeof newQuestion === 'object' &&
					!Array.isArray(newQuestion) &&
					newQuestion !== null
				) {
					this.saveDb = this.company.db_instance;
					sessionStorage.setItem('companydb', this.saveDb);
				}
			}
		},
		vendorId(newQuestion) {
			if (this.vendorId != null && this.company) {
				if (newQuestion !== null && newQuestion > 0) {
					this.getPoNumber();
				}
			}
		},
	},
	async created() {
		try {
			const resAuth = await AuthService.whoAmi();
			this.currentUser = resAuth.data.data;

			if (
				this.currentUser.leveltenant === 2 ||
				this.currentUser.userid === 'dexaadmin'
			) {
				await this.getCompany();

				const resVendorName = await PoFulfillment.getSuppBindComp(
					this.currentUser.id
				);
				const payloadSuppVal = {
					vendorName: resVendorName.data.data[0][0].vendorname,
				};

				const resVendorId = await PlanningOrder.getSuppValue(
					payloadSuppVal
				);
				if (resVendorId.data.data[0].vendor_id == 'undefined') {
					this.$router.push('/dashboard');
					window.alert('Connection Unstable, ID not Recognize');
				}
				this.vendorId = resVendorId.data.data[0].vendor_id;

				// Pre-fetch search PO
				// await this.handleSearchPo();

				// this.getPoNumber();
			} else if (
				this.currentUser.leveltenant !== 2 &&
				this.currentUser.userid !== 'dexaadmin'
			) {
				// Redirect to homepage if user is purchasing and not an admin
				this.$router.push('/dashboard');
			}
		} catch (error) {
			window.alert('Connection Unstable, ID not Recognize');
			this.$router.push('/dashboard');
		}
	},
	async mounted() {
		// Set empty table message
		this.emptyMessage = 'Please Search for Data';

		// Set lazy params
		this.lazyParams = {
			first: 0,
			rows: this.$refs.dt.rows,
			sortField: null,
			sortOrder: null,
		};
	},
	methods: {
		async getCompany() {
			// If user is admin
			if (
				this.currentUser.leveltenant === 1 &&
				this.currentUser.userid === 'dexaadmin'
			) {
				LovService.getValueType('COMPANY').then((response) => {
					this.isiCompany = response.data.data;
				});
			} else {
				// If user is supplier
				this.isLoading = true;
				let payload = this.currentUser.id;
				PoFulfillment.getCompanySupp(payload)
					.then(async (response) => {
						const payload = response.data.data;

						try {
							const companyList = await Promise.all(
								payload.map(async (element) => {
									const res =
										await LovService.getCompanyByName(
											element.company
										);

									const secretKey = 'some-unique-key';
									const simpleCrypto = new SimpleCrypto(
										secretKey
									);
									const decipherText = simpleCrypto.decrypt(
										sessionStorage.getItem(
											'dropdownCompany'
										)
									);

									if (
										res.data.data[0].name === decipherText
									) {
										this.company = res.data.data[0];
									}

									return res.data.data[0];
								})
							);

							this.isiCompany = companyList;
							this.isLoading = false;
						} catch (e) {
							console.log(e);
							this.isLoading = false;
							if (e !== 'Break') throw e;
						}
					})
					.catch((err) => console.log(err));
			}
		},
		getPoNumber() {
			let payload = {
				org_id: this.company.value,
				db_instance: this.company.db_instance,
				supValue: this.vendorId,
			};

			CreateShipmentService.getSearchDropdownSupp(payload).then(
				(response) => {
					this.isiPoNumber = response.data.data;
				}
			);
		},
		searchCompany(event) {
			if (!event.query.trim().length) {
				this.filteredCompany = [...this.isiCompany];
			} else {
				this.filteredCompany = this.isiCompany.filter((item) => {
					return item.name
						.toLowerCase()
						.match(event.query.toLowerCase());
				});
			}
		},
		searchPoNumber(event) {
			if (!event.query.trim().length) {
				this.filteredPoNumber = [...this.isiPoNumber];
			} else {
				this.filteredPoNumber = this.isiPoNumber.filter((item) => {
					if (item.po_number !== null) {
						return item.po_number
							.toLowerCase()
							.match(event.query.toLowerCase());
					}
				});
			}
		},
		onPage(event) {
			this.lazyParams = event;
			this.handleSearchPo();
		},
		onSort(event) {
			this.lazyParams = event;
			this.handleSearchPo();
		},
		onRowSelect() {
			console.log('Select PO:', this.selectedPo);
		},
		clearFilter() {
			this.poNumber = null;
			this.itemDescription = null;
			this.dateFrom = null;
			this.dateTo = null;
		},
		handleAddShipment() {
			const selectedPoToAdd = this.selectedPo;

			sessionStorage.setItem(
				'poForCreateShipment',
				JSON.stringify(selectedPoToAdd)
			);

			this.$router.push({
				name: 'AddCreateShipmentNotice',
				params: { id: 0 },
				query: { vendorId: this.vendorId },
			});
		},
		async handleSearchPo() {
			// Reset selected PO
			this.selectedPo = null;

			// Set query params
			const queriesToBe = {
				page: this.lazyParams.page ? this.lazyParams.page + 1 : 0,
				limitPage: this.lazyParams.rows,
				sortField: this.lazyParams.sortField,
				sortOrder: this.lazyParams.sortOrder,
			};

			// console.log({ queriesToBe });

			// Check if payload is change, then reset pagination
			if (this.payloadSearch) {
				if (
					this.payloadSearch.lovValue !== this.company.value ||
					this.payloadSearch.poNumber !==
						(this.poNumber?.po_number ?? '') ||
					this.payloadSearch.dateFrom !== (this.dateFrom ?? '') ||
					this.payloadSearch.dateTo !== (this.dateTo ?? '') ||
					this.payloadSearch.itemDescription !==
						(this.itemDescription ?? '')
				) {
					// Reset pagination
					queriesToBe.page = 0;
				}
			}

			// const queries = {
			// 	query: JSON.stringify(queriesToBe),
			// };

			// Set payload
			this.payloadSearch = {
				lovValue: this.company.value,
				lovDBInstance: this.company.db_instance,
				vendorId: this.vendorId,
				poNumber: (this.poNumber?.po_number ?? this.poNumber) || '',
				dateFrom: this.dateFrom || '',
				dateTo: this.dateTo || '',
				itemDescription: this.itemDescription || '',
				query: queriesToBe,
			};

			// console.log({ payloadSearch: this.payloadSearch });
			try {
				this.loading = true;
				this.emptyMessage = 'Please Search for Data';

				const res = await CreateShipmentService.getListPo(
					this.payloadSearch
				);

				// console.log({ resSearch: res });

				if (res.status === 200) {
					let number = 0;

					this.purchaseOrders = res.data.data.map((po) => {
						number++;
						const dueDate = new Date(
							po.PROMISED_DATE
						).toLocaleString('id-ID', {
							day: 'numeric',
							month: 'short',
							year: 'numeric',
						});

						po.quantity_open = po.quantity_open || 0;
						po.quantity_shipped = po.quantity_shipped || 0;

						return {
							id: number,
							DUE_DATE: dueDate,
							...po,
						};
					});

					this.totalRecords = res.data.totalData;

					// Set empty message
					if (this.totalRecords === 0) {
						this.emptyMessage = 'No Data Found';
					}
				}

				this.loading = false;
			} catch (err) {
				console.log(err);
				this.loading = false;
				this.purchaseOrders = null;
				this.totalRecords = 0;
				this.emptyMessage = 'No Data Found';
			}
		},
	},
};
</script>
