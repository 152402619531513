<template>
	<div class="grid">
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<div class="col-12 p-fluid mb-4 create-invoice-po">
			<Toast />
			<!-- Progress modal -->
			<ProgressModal :progress="progress">
				<template #message>
					Saving invoice in progress. Please wait...
				</template>
			</ProgressModal>

			<div class="content-wrapper">
				<div class="content-header">Create Invoice Proxy Non PO</div>
				<div class="content-body">
					<div class="grid">
						<!-- kolom 1 -->
						<div
							class="md:col-6 lg:col-6 xs:col-12 sm:col-12 grid p-0 pb-0 mb-6 mr-1"
						>
							<div
								class="col-12 p-0 pb-2 border-bottom-1 border-gray-200"
							>
								<div class="col-12 mb-2">
									<span class="p-float-label">
										<CompaniesDropdown
											:class="{
												'p-invalid': v$.company.$error,
											}"
											v-model="company"
											@item-select="
												getCompanySelect($event)
											"
											:key="companyReload"
											forceSelection
										/>
									</span>
									<div v-if="v$.company.$error" class="mt-1">
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{ v$.company.$errors[0].$message }}
										</small>
									</div>
								</div>
								<div class="col-12 mb-2">
									<span class="p-float-label">
										<SupplierPICDropdown
											:class="{
												'p-invalid': v$.supplier.$error,
											}"
											v-model="supplier"
											:company="company ? company : null"
											:userId="userId"
											:key="supplierReload"
											@item-select="getSupplier($event)"
											forceSelection
										/>
									</span>
									<div v-if="v$.supplier.$error" class="mt-1">
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.supplier.$errors[0].$message
											}}
										</small>
									</div>
								</div>
							</div>
							<div class="col-12 mb-2 mt-3">
                                <span class="p-float-label">
									<AutoComplete
										v-model="receivedType"
										:suggestions="filteredReceivedType"
										:dropdown="true"
										field="TYPE_NAME"
										@complete="searchReceivedType($event)"
										@item-select="onSelectReceived($event)"
										forceSelection
									>
										<template #item="slotProp">
										{{ slotProp.item.TYPE_NAME }}
										</template>
									</AutoComplete>
									<label for="receivedType"
                                        >Received Type</label>
                                </span>
                                <div v-if="v$.receivedType.$error" class="mt-1">
                                    <small
                                        class="p-error"
                                        style="font-size: 12px"
                                    >
                                        {{
                                            v$.receivedType.$errors[0].$message.replace("Value", "Received Type")
                                        }}
                                    </small>
                                </div>
                            </div>
							<div v-if="receivedType && receivedType.TYPE_VALUE == 'panitia' " class="col-12 mb-2">
                                <span class="p-float-label">
									<AutoComplete
										v-model="typePenagihan"
										:suggestions="filteredTypeForm"
										:dropdown="true"
										field="TYPE"
										@complete="searchTypeForm($event)"
										forceSelection
										@item-select="onSelectPenagihan($event)"
									>
										<template #item="slotProp">
										{{ slotProp.item.TYPE }}
										</template>
									</AutoComplete>
									<label for="typePenagihan"
                                        >Type Invoice</label>
                                </span>
                                <div v-if="v$.typePenagihan.$error" class="mt-1">
                                    <small
                                        class="p-error"
                                        style="font-size: 12px"
                                    >
                                        {{
                                            v$.typePenagihan.$errors[0].$message
                                        }}
                                    </small>
                                </div>
                            </div>
							<div v-if="typePenagihan && typePenagihan.TYPE == 'Realization' " class="col-12 mb-2">
                                <span class="p-float-label">
									<AutoComplete
										v-model="invPrepaymentNo"
										:suggestions="filteredInvPrepaymentNo"
										field="INVOICE_NO"
										:dropdown="true"
										@complete="searchInvoicePrepaymentNo($event)"
										forceSelection
										@item-select="onChangeInvPrepaymentNo($event)"
									>
										<template #item="slotProp">
											{{ slotProp.item.INVOICE_COMBINATION }}
										</template>
									</AutoComplete>
									<label for="invPrepaymentNo">No Invoice Prepayment</label>
                                </span>
                                <div v-if="v$.invPrepaymentNo.$error" class="mt-1">
                                    <small
                                        class="p-error"
                                        style="font-size: 12px"
                                    >
                                        {{
                                            v$.invPrepaymentNo.$errors[0].$message
                                        }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-12 mb-2">
                                <span class="p-float-label">
                                    <STKNumberDropdown
                                        :class="{
                                            'p-invalid': v$.stkNumber.$error,
                                        }"
										
                                        id="stkNumber"
                                        v-model="stkNumber"
                                        :company="company"
										:receivedType="receivedType"
										:disabled="(!company || !supplier || !receivedType) || (typePenagihan && typePenagihan.TYPE == 'Realization')"
                                        :supplier="
                                            supplier ? supplier : null
                                        "
                                        :vendor_id="
                                            vendorId ? vendorId : null
                                        "
                                        :key="stkReload"
										@item-select="selectedSTKNumber($event)"
                                    />
                                    <label for="stkNumber"
                                        >Event PM Number</label>
                                </span>
                                <div v-if="v$.stkNumber.$error" class="mt-1">
                                    <small
                                        class="p-error"
                                        style="font-size: 12px"
                                    >
                                        {{
                                            v$.stkNumber.$errors[0].$message
                                        }}
                                    </small>
                                </div>
                            </div>
							<div class="col-6 mb-2">
								<span class="p-float-label">
									<InputText
										:class="{
											'p-invalid':
												v$.invoiceNo.$error ||
												errorExisting,
										}"
										id="invoiceNo"
										v-model="invoiceNo"
									/>
									<label for="invoiceNo"
										>Invoice Number</label
									>
								</span>
								<div v-if="v$.invoiceNo.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.invoiceNo.$errors[0].$message }}
									</small>
								</div>
							</div>
							<div class="col-6 mb-2">
								<span class="p-float-label">
									<InputText
										id="ppsNo"
										v-model="ppsNo"
									/>
									<label for="ppsNo"
										>PP MS Number</label
									>
								</span>
							</div>
							<div class="mb-2 sm:col-12 md:col-12 lg:col-6">
								<span class="p-float-label">
									<Calendar
										:class="{
											'p-invalid': v$.invoiceDate.$error,
										}"
										id="invoiceDate"
										v-model="invoiceDate"
										dateFormat="d-M-yy"
										:showIcon="true"
									/>
									<label for="invoiceDate"
										>Invoice Date</label
									>
								</span>
								<div v-if="v$.invoiceDate.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.invoiceDate.$errors[0].$message }}
									</small>
								</div>
							</div>
							<div class="mb-2 sm:col-12 md:col-12 lg:col-6">
								<span class="p-float-label">
									<Calendar
										id="glDate"
										v-model="glDate"
										dateFormat="d-M-yy"
										:showIcon="true"
									/>
									<label for="glDate">GL Date</label>
								</span>
							</div>
							<div class="col-12 mb-2">
								<span class="p-float-label">
									<Textarea
										id="description"
										rows="5"
										v-model="description"
										:maxlength="230"
									/>
									<label for="description">Description</label>
								</span>
							</div>

							<div class="mb-2 sm:col-12 md:col-12 lg:col-6">
								<span class="p-float-label">
									<InputText
										:class="{
											'p-invalid': v$.taxInvoiceNo.$error,
										}"
										id="taxInvoiceNo"
										v-model="taxInvoiceNo"
									/>
									<label for="taxInvoiceNo"
										>Tax Invoice No</label
									>
								</span>

								<div v-if="v$.taxInvoiceNo.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{
											v$.taxInvoiceNo.$errors[0].$message
										}}
									</small>
								</div>
							</div>

							<div class="mb-2 sm:col-12 md:col-12 lg:col-6">
								<span class="p-float-label">
									<Calendar
										:class="{
											'p-invalid':
												v$.taxInvoiceDate.$error,
										}"
										id="taxInvoiceDate"
										v-model="taxInvoiceDate"
										dateFormat="d-M-yy"
										:showIcon="true"
									/>
									<label for="taxInvoiceDate"
										>Tax Invoice Date</label
									>
								</span>

								<div
									v-if="v$.taxInvoiceDate.$error"
									class="mt-1"
								>
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{
											v$.taxInvoiceDate.$errors[0]
												.$message
										}}
									</small>
								</div>
							</div>

							<div class="col-12 mb-2">
								<span class="p-float-label mb-3">
									<label for="FileUpload">Attachment</label>
								</span>
								<FileUpload
									:class="{
										'p-invalid': v$.file.$error,
									}"
									class="w-full"
									ref="fileUpload"
									mode="advanced"
									chooseLabel="Upload Attachment"
									fileLimit="1"
									accept="application/pdf"
									:maxFileSize="5000000"
									:showUploadButton="false"
									:showCancelButton="false"
									:customUpload="true"
									@select="onSelectFile"
									@remove="onRemoveFile"
								/>
								<div v-if="v$.file.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.file.$errors[0].$message }}
									</small>
								</div>
							</div>
						</div>

						<!-- kolom 2 -->
						<div
							class="md:col-6 lg:col-6 xs:col-12 sm:col-12 grid p-0 pb-0 mb-6 ml-1"
						>
							<div class="grid col-12">
								<div class="col-6 col-auto ml-auto">
									<p>Status</p>
								</div>
								<div class="col-6 col-auto ml-auto">
									<p style="text-align: end">
										{{ invoiceStatus }}
									</p>
								</div>
							</div>
							<div class="grid col-12">
								<div class="col-6">
									<p>Invoice Amount</p>
								</div>
								<div class="col-6">
									<p style="text-align: end">
										{{
											new Intl.NumberFormat('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}).format(summary.totalLine)
										}}
									</p>
								</div>
							</div>
							<div class="grid col-12">
								<div class="col-6">
									<p>Tax Amount</p>
								</div>
								<div class="col-6">
									<p style="text-align: end">
										{{
											new Intl.NumberFormat('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}).format(summary.totalPpn)
										}}
									</p>
								</div>
							</div>
							<div class="grid col-12">
								<div class="col-6">
									<p>Total</p>
								</div>
								<div class="col-6">
									<p style="text-align: end">
										{{
											new Intl.NumberFormat('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}).format(summary.totalInvoice)
										}}
									</p>
								</div>
							</div>
							<div class="col-12">
								<span class="p-float-label">
									<CurrenciesDropdown
										:class="{
											'p-invalid': v$.currency.$error,
										}"
										id="currency"
										v-model="currency"
										:company="company"
										:key="curReload"
									/>
									<label for="currency"
										>Select Currency</label
									>
								</span>
								<div v-if="v$.currency.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.currency.$errors[0].$message }}
									</small>
								</div>
							</div>
							<div class="grid col-12">
								<div class="col-6">
									<span class="p-float-label">
										<RemitToBankDropdown
											:class="{
												'p-invalid': v$.billBank.$error,
											}"
											id="remitBank"
											v-model="billBank"
											:company="company"
											:supplier="
												supplier ? supplier : null
											"
											:vendor_id="
												vendorId ? vendorId : null
											"
											:key="remitReload"
										/>
										<label for="remitBank"
											>Remit to Bank</label
										>
									</span>
									<div v-if="v$.billBank.$error" class="mt-1">
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.billBank.$errors[0].$message
											}}
										</small>
									</div>
								</div>
								<div class="col-6">
									<span class="p-float-label" style="position: relative; right: -10px;" >
										<PaymentMethodsDropdown
											:class="{
												'p-invalid':
													v$.paymentMethod.$error,
											}"
											id="paymentMethod"
											v-model="paymentMethod"
											:company="company ? company : null"
											:supplier="
												supplier ? supplier : null
											"
											:vendor_id="
												vendorId ? vendorId : null
											"
											:key="payReload"
										/>
										<label for="paymentMethod"
											>Payment Method</label
										>
									</span>
									<div
										v-if="v$.paymentMethod.$error"
										class="mt-1"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.paymentMethod.$errors[0]
													.$message
											}}
										</small>
									</div>
								</div>
							</div>
							<div class="grid col-12 mb-2">
								<div class="col-6">
									<span class="p-float-label">
										<TermsDropdown
											:class="{
												'p-invalid': v$.term.$error,
											}"
											id="term"
											v-model="term"
											:company="company"
											:supplier="
												supplier ? supplier : null
											"
											:key="termReload"
										/>
										<label for="term">Select a Term</label>
									</span>
									<div v-if="v$.term.$error" class="mt-1">
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{ v$.term.$errors[0].$message }}
										</small>
									</div>
								</div>
								
							</div>
							<div class="col-12 mb-2">
								<span class="p-float-label">
									<CostCenterDropdown
										:class="{
											'p-invalid': v$.costCenter.$error,
										}"
										id="costCenter"
										v-model="costCenter"
										:company="company"
										:key="ccReload"
										:disabled="typePenagihan && typePenagihan.TYPE == 'Prepayment'"
										@item-select="getCostCenter($event)"
									/>
									<label for="costCenter">Cost Center</label>
								</span>
								<div v-if="v$.costCenter.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.costCenter.$errors[0].$message }}
									</small>
								</div>
							</div>
							<div v-if="userId == 50103" class="col-12 mb-2">
								<span class="p-float-label">
									<CCOwnerDropdown
										:class="{
											'p-invalid': v$.costCenter.$error,
										}"
										id="costCenter"
										v-model="ccOwner"
										:company="costCenter"
										:key="ccOwnerReload"
									/>
									<label for="ccOwner">Select CC Owner</label>
								</span>
								<div v-if="v$.ccOwner.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.ccOwner.$errors[0].$message }}
									</small>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 grid p-0 pb-0">

						<div class="col-2 mb-2">
							<span class="p-float-label">
								<InputText id="dummy" hidden="true" />
								<Button
									label="Add Line"
									class="p-button-success"
									@click="addModal()"
									:disabled="listDeleted.length == 0"
								/>
							</span>
						</div>
					
						<!-- Category table -->
						<div class="col-12 mb-2">
							<DataTable
								:value="selectedCategory"
								responsiveLayout="scroll"
								:scrollable="true"
								scrollHeight="600px"
								scrollDirection="both"
								:loading="loadingTable"
							>
								<Column header="Description" class="w-4">
									<template #body="{ data, index }">
										<InputText
											:disabled="isForwarder"
											type="text"
											style="color: #000 !important; width:100%;"
											v-model="data.description"
											:class="{
												'p-invalid':
													v$.selectedCategory
														.$error &&
													v$.selectedCategory
														.$errors[0].$response
														.$data[index]
														.description.$error,
											}"
										/>

										<div
											v-if="
												v$.selectedCategory.$error &&
												v$.selectedCategory.$errors[0]
													.$response.$data[index]
													.description.$error
											"
											class="mt-1"
										>
											<small
												class="p-error"
												style="font-size: 12px"
											>
												{{
													v$.selectedCategory
														.$error &&
													v$.selectedCategory
														.$errors[0].$response
														.$errors[index]
														.description[0].$message
												}}
											</small>
										</div>
									</template>
								</Column>
								<Column
									header="Amount"
									class="w-3"
								>
									<template #body="{ data, index }">
										<InputNumber
											:min="0"
											mode="decimal"
											locale="de-DE"
											style="width:100%;"
											:minFractionDigits="2"
											:maxFractionDigits="2"
											v-model="data.amount"
											:class="{
												'p-invalid':
													v$.selectedCategory
														.$error &&
													v$.selectedCategory
														.$errors[0].$response
														.$data[index].amount
														.$error,
											}"
										/>

										<div
											v-if="
												v$.selectedCategory.$error &&
												v$.selectedCategory.$errors[0]
													.$response.$data[index]
													.amount.$error
											"
											class="mt-1"
										>
											<small
												class="p-error"
												style="font-size: 12px"
											>
												{{
													v$.selectedCategory
														.$error &&
													v$.selectedCategory
														.$errors[0].$response
														.$errors[index]
														.amount[0].$message
												}}
											</small>
										</div>
									</template>
								</Column>
								<Column
									header="VAT"
									style="width:150px;"
								>
									<template #body="{ data, index }">
										<Dropdown
											v-model="data.vat"
											@change="
												onChangeVAT(
													$event,
													index
												)
											"
											:options="getVAT"
											optionLabel="vat_code"
											style="width: 100%"
											placeholder="- No Select -"
										/>

										<div
											v-if="
												v$.selectedCategory.$error &&
												v$.selectedCategory.$errors[0]
													.$response.$data[index]
													.vat.$error
											"
											class="mt-1"
										>
											<small
												class="p-error"
												style="font-size: 12px"
											>
												{{
													v$.selectedCategory
														.$error &&
													v$.selectedCategory
														.$errors[0].$response
														.$errors[index]
														.vat[0].$message
												}}
											</small>
										</div>
									</template>
								</Column>
								<Column
									header="WHT"
									style="width:150px;"
								>
									<template #body="slotProps">
										<Dropdown
											v-model="slotProps.data.wht"
											@change="
												onChangeWHT(
													$event,
													slotProps.index
												)
											"
											:options="getWHT"
											optionLabel="wht_code"
											style="width:100%;"
											placeholder="- No Select -"
											
										/>
									</template>
								</Column>
								<Column header="Account" style="width:370px">
									<template #body="{ data, index }">
										<AutoComplete
											:suggestions="filteredCoa"
											@complete="searchAccount($event)"
											:dropdown="true"
											field="account"
											placeholder="Account"
											v-model="data.account"
											style="width:100%;"
											@item-select="
												onChangeCOALine($event, index)
											"
											forceSelection
										>
										</AutoComplete>
									</template>
								</Column>
								<Column header="Account SL" style="width:370px" v-if="typePenagihan && typePenagihan.TYPE =='Prepayment'">
									<template #body="{ data, index }">
										<AutoComplete
											:suggestions="filteredCoa"
											@complete="searchAccount($event)"
											:dropdown="true"
											field="account"
											placeholder="Account"
											v-model="data.account_sl"
											style="width:100%;"
											@item-select="
												onChangeCOALineSL($event, index)
											"
											forceSelection
										>
										</AutoComplete>
									</template>
								</Column>
								<Column header="Ref Number" style="width:370px">
									<template #body="{ data }">
										<div class="p-inputgroup">
											<InputText
												style="width:100%;"
												:disabled="true"
												placeholder="Ref Number"
												v-model="
													data.travelOrder
														.travelOrderNo
												"
											/>
										</div>
									</template>
								</Column>
								<Column alignFrozen="right"
								frozen header="Action">
									<template #body="{index}">
										<Button
											icon="pi pi-trash"
											class="p-button-rounded p-button-text"
											@click="handleDeleteLine(index)"
										/>
									</template>
								</Column>
								

								<!-- Empty state -->
								<template #empty>
									<div>No item.</div>
								</template>
							</DataTable>
						</div>
					</div>

					<!-- Reimbursement section -->
					<div class="col-12 mb-6" v-if="showReimburseTable">
						<div
							class="flex align-items-center justify-content-between mb-3"
						>
							<p class="text-2xl font-bold mb-0">Reimburse</p>
							<Button
								type="button"
								class="p-button-success w-auto"
								@click="handleAddReimbursement"
							>
								<i class="pi pi-plus" />
								<span class="uppercase font-bold ml-2"
									>Add Line</span
								>
							</Button>
						</div>

						<!-- Reimburse table -->
						<DataTable
							:value="reimburseItems"
							responsiveLayout="scroll"
						>
							<Column header="Vendor">
								<template #body="{ data, index }">
									<AutoComplete
										v-model="data.vendor"
										:dropdown="true"
										forceSelection
										placeholder="Vendor"
										field="vendor_name"
										:suggestions="filteredVendors"
										@complete="searchVendors($event)"
										@item-select="
											onChangeVendor($event, index)
										"
									/>
								</template>
							</Column>
							<Column header="No Faktur Pajak">
								<!-- <template #body="{ data, index }"> -->
								<template #body="{ data, index }">
									<InputText
										v-model="data.taxNo"
										placeholder="Tax Number"
										:class="{
											'p-invalid':
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$data[index]
													.taxNo.$error,
										}"
									/>

									<div
										v-if="
											v$.reimburseItems.$error &&
											v$.reimburseItems.$errors[0]
												.$response.$data[index].taxNo
												.$error
										"
										class="mt-1"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$errors[index]
													.taxNo[0].$message
											}}
										</small>
									</div>
								</template>
							</Column>
							<Column header="Tanggal Faktur Pajak">
								<!-- <template #body="{ data, index }"> -->
								<template #body="{ data, index }">
									<Calendar
										v-model="data.taxDate"
										class="line-calendar"
										dateFormat="d-M-yy"
										:showIcon="true"
										placeholder="Select Date"
										:class="{
											'p-invalid':
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$data[index]
													.taxDate.$error,
										}"
									/>

									<div
										v-if="
											v$.reimburseItems.$error &&
											v$.reimburseItems.$errors[0]
												.$response.$data[index].taxDate
												.$error
										"
										class="mt-1"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$errors[index]
													.taxDate[0].$message
											}}
										</small>
									</div>
								</template>
							</Column>
							<Column header="Nilai Pajak">
								<!-- <template #body="{ data, index }"> -->
								<template #body="{ data, index }">
									<InputNumber
										v-model="data.taxAmount"
										class="col-number"
										mode="decimal"
										locale="en-US"
										:minFractionDigits="2"
										:maxFractionDigits="2"
										:min="0"
										:class="{
											'p-invalid':
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$data[index]
													.taxAmount.$error,
										}"
									/>

									<div
										v-if="
											v$.reimburseItems.$error &&
											v$.reimburseItems.$errors[0]
												.$response.$data[index]
												.taxAmount.$error
										"
										class="mt-1"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$errors[index]
													.taxAmount[0].$message
											}}
										</small>
									</div>
								</template>
							</Column>
							<Column header="Account">
								<template #body="{ data, index }">
									<AutoComplete
										:suggestions="filteredCoa"
										@complete="searchAccount($event)"
										:dropdown="true"
										field="account"
										placeholder="Account"
										v-model="data.account"
										style="width: 100%"
										@item-select="
											onChangeCOAReimburstment(
												$event,
												index
											)
										"
										forceSelection
									>
										<!-- <template #item="slotProp">
                        {{ slotProp.item.CONCATENATED_SEGMENTS }}
                      </template> -->
									</AutoComplete>
								</template>
							</Column>
							<Column header="Group">
								<!-- <template #body="{ data, index }"> -->
								<template #body="{ data, index }">
									<AutoComplete
										:class="{
											'p-invalid':
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$data[index]
													.group_name.$error,
										}"
										v-model="data.group_name"
										:suggestions="filteredGroup"
										placeholder="Group"
										@complete="searchGroup($event)"
										@item-select="
											getGroupValue($event, index)
										"
										:dropdown="true"
										field="group_name"
										forceSelection
									>
									</AutoComplete>

									<div
										v-if="
											v$.reimburseItems.$error &&
											v$.reimburseItems.$errors[0]
												.$response.$data[index]
												.group_name.$error
										"
										class="mt-1"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$errors[index]
													.group_name[0].$message
											}}
										</small>
									</div>
								</template>
							</Column>
							<Column>
								<template #body="{ index }">
									<Button
										icon="pi pi-trash"
										class="p-button-rounded p-button-text"
										@click="handleDeleteReimburse(index)"
									/>
								</template>
							</Column>

							<!-- Empty state -->
							<template #empty>
								<div>No item.</div>
							</template>
						</DataTable>
					</div>

					<div class="grid col-12 p-0 pb-0">
						<div class="lg:col-3 sm:col-12 md:col-12">
							<Button
								label="Save"
								class="p-button-success"
								@click="save()"
								:disabled="disabledButton"
							/>
						</div>
						<div class="lg:col-3 sm:col-12 md:col-12">
							<Button
								label="Cancel"
								class="p-button-secondary"
								@click="cancel()"
							/>
						</div>
					</div>
					<Dialog
						header="Select SK"
						:draggable="false"
						:breakpoints="{ '960px': '75vw', '640px': '100vw' }"
						:style="{ width: '25vw', overflow: 'hidden' }"
						:modal="true"
						:dismissableMask="true"
						v-model:visible="displaySKModal"
					>
						<div class="pt-4">
							<FindSKModal
								:dbInstance="company.db_instance"
								:vendorId="supplier.vendor_id"
								@on-close="displaySKModal = false"
								@on-save="addSKNumber"
							/>
						</div>
					</Dialog>
					<Dialog
						header=" "
						:draggable="false"
						:breakpoints="{ '960px': '75vw', '640px': '100vw' }"
						:style="{ width: '75vw', overflow: 'hidden' }"
						:modal="true"
						:dismissableMask="true"
						v-model:visible="displayTOModal"
					>
						<div class="pt-3">
							<FindTOModal
								:supplierName="supplier.vendor_name"
								@on-close="closeTOModal"
								@on-add="handleAddTO"
							/>
						</div>
					</Dialog>
					<Dialog
						header=" "
						:draggable="false"
						:breakpoints="{ '960px': '75vw', '640px': '100vw' }"
						:style="{ width: '75vw', overflow: 'hidden' }"
						:modal="true"
						:dismissableMask="true"
						v-model:visible="displayModal"
					>
						<div class="pt-3">
							<FindModal 
								:dataTable="listDeleted"
								@selectItem="getItemData"
							/>
						</div>
					</Dialog>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import CompaniesDropdown from '../components/CompaniesDropdown.vue';
// import SupplierDropdown from "../components/SupplierDropdown";
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import AuthService from '@/service/AuthService';
import LovService from '@/service/LovService';
// import PoFulfillment from '@/service/PoFulfillment';
import {filterAuthByVendorname} from "@/helper/filterAuthByVendorname"
import SimpleCrypto from 'simple-crypto-js';
// import { required } from "@vuelidate/validators";
// import DetailCategoryDropdown from "@/components/invoicepoproxy/components/DetailCategoryDropdown";
import STKNumberDropdown from './components/STKNumberDropdown.vue';
import RemitToBankDropdown from '@/components/invoicepoproxy/components/RemitToBankDropdown';
import PaymentMethodsDropdown from '@/components/invoicepoproxy/components/PaymentMethodsDropdown';
import TermsDropdown from '@/components/components/TermsDropdownSP1';
import CurrenciesDropdown from '@/components/components/CurrenciesDropdown';
import CostCenterDropdown from '@/components/invoicepoproxy/components/CostCenterDropdown';
import CCOwnerDropdown from './components/CCOwnerDropdown.vue'
import FindSKModal from '../components/FindSKModal.vue';
import FindTOModal from '../components/FindTOModal.vue';
import FindModal from './components/FindModal.vue';
import ProgressModal from '../components/ProgressModal.vue';
import {
	getListCoa,
	getVATList,
	getWHTList,
	getAllSupplier,
	saveInvoiceSP1,
	getDetailCostCenter,
	getInvoiceNumberPrepayment,
	getInvoiceDetailPrepayment,
	getDetailCOAID,
	getCOASLDetail,
	findCoaByNumber
} from '../../service/InvoiceProxyNonPOServices';

import { useToast } from 'primevue/usetoast';
import SupplierPICDropdown from './components/SupplierPICDropdown.vue';


const ppsNo = ref(null);
const route = useRoute();
const router = useRouter();
const company = ref();
const companyReload = ref([]);
const userInfo = ref();
const supplier = ref(null);
const supplierReload = ref(0);
const invoiceNo = ref();
const invoiceDate = ref(new Date());
const glDate = ref(new Date());
const description = ref();
const taxInvoiceNo = ref(0);
const taxInvoiceDate = ref();
const fileUpload = ref();
const file = ref();
const errorExisting = ref();
const category = ref();
const invoiceStatus = ref('DRAFT');
// const invoiceAmount = ref();
// const taxAmount = ref();
// const totalTax = ref();
const currency = ref({currency_code: 'IDR'});
const billBank = ref();
const paymentMethod = ref();
const term = ref({term_id: 10006, term_name: 'CASH'});
const ccOwner = ref();
const stkNumber = ref();
// const referenceNumber = ref();
const costCenter = ref();
const curReload = ref(0);
const remitReload = ref(0);
const stkReload = ref(0);
const payReload = ref(0);
const termReload = ref(0);
const ccReload = ref(0);
const ccOwnerReload = ref(0);
const displaySKModal = ref(false);
const skNumber = ref();
// const findReload = ref(0);

// const isForwarder = ref();
const selectedCategory = ref([]);
// const selectedCategoryHeader = ref([]);
const filteredCategory = ref(null);
const categoryHeader = ref(null);
const vendorId = ref();
const vendorSiteId = ref();
const displayModal = ref(false)


const reimburseItems = ref([]);

const getCOA = ref([]);
const filteredCoa = ref([]);
// const invoices = ref([]);

const getVAT = ref([]);
const getWHT = ref([]);

const getVendors = ref([]);
const filteredVendors = ref([]);

const filteredGroup = ref([]);

const displayTOModal = ref(false);
const travelOrderIndex = ref(null);

const loadingTable = ref(false);

const userId = ref();

const filteredTypeForm = ref([]);
const typeForm = ref([
	{ID:1, TYPE: 'Prepayment'},
	{ID:2, TYPE: 'Realization'},
	{ID:3, TYPE: 'Realization Without Prepayment'}
])
const typePenagihan = ref(null);

const receivedType = ref(null);
const filteredReceivedType = ref([]);
const receivedTypeList = ref([
	{ID:3, TYPE_VALUE: '', TYPE_NAME: '- No Select -'},
	{ID:1, TYPE_VALUE: 'panitia', TYPE_NAME: 'PANITIA'},
	{ID:2, TYPE_VALUE: 'vendor', TYPE_NAME: 'VENDOR'}
]);

const noInvPrepaymentList = ref([]);
const filteredInvPrepaymentNo = ref([]);
const invPrepaymentNo = ref(null);

const toast = useToast();

const progress = ref(false);
const disabledButton = ref(false);
const breadcrumbHome = { icon: 'pi pi-home', to: '/dashboard' };
const breadcrumbItems = [
	{
		label: 'Create Invoice Proxy',
		to: route.fullPath,
	},
];
const listDeleted = ref([]);


const searchTypeForm = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredTypeForm.value = [...typeForm.value];
    } else {
      filteredTypeForm.value = typeForm.value.filter((comp) => {
        return comp.TYPE
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};

const searchReceivedType = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredReceivedType.value = [...receivedTypeList.value];
    } else {
      filteredReceivedType.value = receivedTypeList.value.filter((comp) => {
        return comp.TYPE_NAME
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};

const searchInvoicePrepaymentNo = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredInvPrepaymentNo.value = [...noInvPrepaymentList.value];
    } else {
      filteredInvPrepaymentNo.value = noInvPrepaymentList.value.filter((comp) => {
        return comp.INVOICE_COMBINATION
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};




watch(() => companyReload.value++);


watch(() => typePenagihan.value, async (text) => {
	if(text['TYPE'] == 'Realization'){
		const respData = await getInvoiceNumberPrepayment({
			org_id: company.value.value,
			vendor_id: vendorId.value,
		});
		noInvPrepaymentList.value = respData.data.data.map((Q) => {
			const number = Q['OUTSTANDING_AMOUNT'];
			const value = new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(number);

			Q['INVOICE_COMBINATION'] = Q['INVOICE_NO'] + " - " + value;

			return Q;
		});

	}
})




watch(
	() => company.value,
	async () => {
		supplier.value = null;
		supplierReload.value++;
		curReload.value++;
		stkReload.value++;
		ccReload.value++;
		await getDropdownVat();
		await getDropdownWHT();
	}
);

watch(
	() => vendorId.value,
	async () => {
		payReload.value++;
		termReload.value++;
		remitReload.value++;
		stkReload.value++;
		typePenagihan.value = null;
		invPrepaymentNo.value = null;
		stkNumber.value = null;
		selectedCategory.value = []
		costCenter.value = null;
		description.value = null;
		receivedType.value = null;
	}
);

watch(() => receivedType.value, () => {
	stkReload.value++;
})


watch(
	() => invoiceDate.value,
	async () => {
		await getDropdownVat();
		await getDropdownWHT();
	}
);

const isForwarder = computed(
	() =>
		categoryHeader.value &&
		categoryHeader.value.some((item) =>
			item.category_name.toLowerCase().includes('forwarder')
		)
);

// Custom validator
const cannotZero = (value) => value > 0;

// const isRequiredVAT = () => {
// 	if(taxInvoiceNo.value && taxInvoiceNo.value !== '000.000-00.00000000') return true;
// 	return false
// }

// Vuelidate rules
const validationRules = computed(() => {

	const rules = {
		company: { required },
		supplier: { required },
		invoiceNo: { required },
		invoiceDate: { required },
		description: { required },
		taxInvoiceNo: { required },
		taxInvoiceDate: { required },
		currency: { required },
		billBank: { required },
		paymentMethod: { required },
		stkNumber: {required},
		typePenagihan: {required: requiredIf(receivedType.value && receivedType.value.TYPE_VALUE == 'panitia')},
		invPrepaymentNo: {required: requiredIf(typePenagihan.value && typePenagihan.value.TYPE == 'Realization')},
		receivedType: {required},
		term: { required },
		costCenter: { required },
		file: { required },
		ccOwner: { required: requiredIf(sessionStorage.getItem("idUser") == 50103) }, 
		selectedCategory: {
			$each: helpers.forEach({
				description: { required },
				vat: {required: requiredIf(() => { return taxInvoiceNo.value && taxInvoiceNo.value !== '000.000-00.00000000'; })},
				amount: {
					required,
					cannotZero: helpers.withMessage(
						'The amount value must be greater than zero',
						cannotZero
					),
				},
			}),
		},
		reimburseItems: {
			$each: helpers.forEach({
				taxNo: { required },
				taxDate: { required },
				taxAmount: {
					required,
					cannotZero: helpers.withMessage(
						'The amount value must be greater than zero',
						cannotZero
					),
				},
				group_name: { required },
			}),
		},
	};

	if (isForwarder.value) {
		rules.skNumber = { required };
	}

	return rules;
});

const v$ = useVuelidate(validationRules, {
	company,
	supplier,
	invoiceNo,
	invoiceDate,
	description,
	taxInvoiceNo,
	taxInvoiceDate,
	skNumber,
	currency,
	billBank,
	paymentMethod,
	term,
	costCenter,
	file,
	selectedCategory,
	reimburseItems,
	ccOwner,
	receivedType,
	stkNumber,
	typePenagihan,
	invPrepaymentNo
});

AuthService.whoAmi().then(async (response) => {
	if (response.status === 200) {
		userInfo.value = response.data.data;
		userId.value = response.data.data.id;
		if (userInfo.value.leveltenant === 2) {
			//get company
			const respCbN = await LovService.getCompanyByName(userCompany());
			company.value = respCbN.data.data[0];
			//get supplier
			const param = {
				db_instance: company.value.db_instance,
				org_id: company.value.value,
			};
			
			const checkAuth = await filterAuthByVendorname({
				info_id: userInfo.value.id,
				dbInstance: param.db_instance,
				value: param.org_id
			});

			if(checkAuth.supplier === null) router.push("/dashboard");

			supplier.value = checkAuth.supplier;
		} else {
			const respCbN = await LovService.getCompanyByName(userCompany());
			company.value = respCbN.data.data[0];
		}
		// reload.value++;
	}
});

const onSelectReceived = (event) => {
	if(event.value.TYPE_VALUE == ''){
		receivedType.value = null
	}

	typePenagihan.value = null;
	invPrepaymentNo.value = null;
	stkNumber.value = null;
	selectedCategory.value = []
	costCenter.value = null;
	description.value = null;
}

const onChangeInvPrepaymentNo = async (data) => {
	try {
		loadingTable.value = true;
		selectedCategory.value = [];
		let segment4 = '000';
		const responseInv = await getInvoiceDetailPrepayment({einvoice_id: data.value.EINVOICE_ID});
		const header = responseInv.data.data.respHeader[0];
		for(const q of responseInv.data.data.respDetail){
			const coaIDValue = await getDetailCOAID({coa_id: q.COA_SL});

			const splitData = coaIDValue.data.data['ACCOUNT'].split("-");
			segment4 = splitData[2];

			let vatData = null;
			
			for (const vat of getVAT.value) {
				if (vat.tax_rate_id == q['VAT_CODE_ID']) {
					vatData = vat;
				}
			}

			const spliter = coaIDValue.data.data['ACCOUNT'].split("");
			spliter.splice(spliter.length - 1, 1, "0");

			const coaFull = spliter.join("");

			const resultCOA = await findCoaByNumber({ACCOUNT: coaFull});
			const coaID = resultCOA.data.data.CODE_COMBINATION_ID;
			const ACCOUNTDATA = resultCOA.data.data.ACCOUNT;

			let descPreps = q.ITEM_DESCRIPTION.split(" - ");
			descPreps.splice(0,1)

			let obj = {
				description: descPreps.join(" - "),
				amount: q.AMOUNT,
				vat: null,
				coa_id: coaID,
				account: ACCOUNTDATA,
				travelOrder: {
					travelOrderNo: q.REMARKS,
					guestName: null,
					startDate: null,
					endDate: null,
				},
				coa_sl: coaID,
				account_sl: ACCOUNTDATA
			}
			
			if (vatData) {
				obj.percentage_rate =
					vatData.percentage_rate;
				obj.vat_code = vatData.vat_code;
				obj.tax_rate_id = vatData.tax_rate_id;
				obj.vat = vatData;
			}

			selectedCategory.value.push(obj);
			
		}

		currency.value = {currency_code: header['CURRENCY_CODE']}
		costCenter.value = {segment4: segment4}
		stkNumber.value = {kiCode: header['NO_KI']}
		let decpValue = header['DESCRIPTION'].split(" - ");
		decpValue.splice(0,1);
		description.value = decpValue.join(" - ")
		ppsNo.value = header['NO_PPS']
		taxInvoiceNo.value = header['TAX_NO']

		loadingTable.value = false;
	} catch (error) {
		loadingTable.value = false;
	}
}


//selected STK NUMBER
const selectedSTKNumber = async (event) => {
	loadingTable.value = true;
	selectedCategory.value = [];
	const dataBase = event.value.row;

	description.value = dataBase[0].accountName + " - " + event.value.eventName

	let coa_sl;

	if(typePenagihan.value && typePenagihan.value.TYPE == 'Prepayment'){
		description.value = "UM" + " - " + dataBase[0].accountName + " - " + event.value.eventName
	}


	for(const map of dataBase){

		const result = await getCOASLDetail({COST_CENTER: map.segment3});
		coa_sl = result.data.data;

		costCenter.value = {
			segment4: map.segment3
		}


		if(typePenagihan.value &&  typePenagihan.value.TYPE == 'Prepayment'){
			costCenter.value = {segment4: '000'}
		}


		const concated = `${map.segment1}-DOM-${map.segment3}-${map.segment4}-${map.segment5}-INA-000000`
		let responseData;

		responseData = await getDetailCostCenter({
			company_code: map.segment1,
			account: concated
		})

		let obj;

		if(typePenagihan.value && typePenagihan.value.TYPE == 'Prepayment'){
			obj = {
				description: "UM" + " - " + (map.accountName + " - " + event.value.eventName).substring(0,240),
				amount: map.accountValue,
				vat: null,
				coa_sl: responseData.data.data.CODE_COMBINATION_ID,
				account_sl: responseData.data.data.CODE_COMBINATION_ID ? concated : null,
				coa_id: coa_sl ? coa_sl['CODE_COMBINATION_ID'] : null,
				account: coa_sl ? coa_sl['ACCOUNT'] : null,
				travelOrder: {
					travelOrderNo: map.refNumber,
					guestName: null,
					startDate: null,
					endDate: null,
				},
			}
		}else{
			let desc = (map.accountName + " - " + event.value.eventName).substring(0,240)
			let descrip = desc.split(" - ")
			if(descrip[0] == "UM") descrip.splice(0,1);
			obj = {
				description: descrip.join(" - "),
				amount: map.accountValue,
				vat: null,
				coa_id: responseData.data.data.CODE_COMBINATION_ID,
				account: responseData.data.data.CODE_COMBINATION_ID ? concated : null,
				coa_sl: responseData.data.data.CODE_COMBINATION_ID,
				account_sl: responseData.data.data.CODE_COMBINATION_ID ? concated : null,
				travelOrder: {
					travelOrderNo: map.refNumber,
					guestName: null,
					startDate: null,
					endDate: null,
				},
			}
		}
		selectedCategory.value.push(obj);
	}

	selectedCategory.value.sort((a,b) => {return a.description.localeCompare(b.description)})

	loadingTable.value = false;
}


// Select file attachment
const onSelectFile = (e) => {
	file.value = e.files[0];
};

// Remove file attachment
const onRemoveFile = (e) => {
	if (!e.files.length) {
		file.value = null;
	}
};

const userCompany = () => {
	const secretKey = 'some-unique-key';
	const simpleCrypto = new SimpleCrypto(secretKey);
	return simpleCrypto.decrypt(sessionStorage.getItem('dropdownCompany'));
};

const getSupplier = async (event) => {
	supplier.value = event.value;
	vendorId.value = event.value.vendor_id;
	vendorSiteId.value = event.value.vendor_site_id;
	// term.value = null;
	billBank.value = null;
	paymentMethod.value = null;
	skNumber.value = null;
	// selectedCategory.value = [];
	filteredCategory.value = null;
	category.value = null;
	await getListAllSupplier();
};

const getCompanySelect = (event) => {
	company.value = event.value;
	supplier.value = null;
	vendorId.value = null;
	// term.value = null;
	billBank.value = null;
	paymentMethod.value = null;
	skNumber.value = null;
	// selectedCategory.value = [];
	filteredCategory.value = null;
	category.value = null;
};

const summary = computed(() => {
	const totalLine =
		(selectedCategory.value.length &&
			selectedCategory.value.reduce((accumulator, obj) => {
				return accumulator + obj.amount;
			}, 0)) ||
		0;

	const totalPpn =
		(selectedCategory.value.length &&
			selectedCategory.value
				.map((el) => {
					return el.vat && el.vat.percentage_rate
						? (el.vat.percentage_rate / 100) * el.amount
						: 0;
				})
				.reduce((prevValue, currValue) => prevValue + currValue, 0)) ||
		0;

	const totalInvoice = totalLine + totalPpn;

	return {
		totalLine,
		totalPpn,
		totalInvoice,
	};
});



const getCostCenter = (event) => {
	if (company.value) {
		costCenter.value = event.value;
		ccOwnerReload.value++;
		ccOwner.value = null;
		getDropdownCOA();
	}
};

watch(() => costCenter.value, () => {
	getDropdownCOA();
})


const getDropdownCOA = async () => {
	getCOA.value = [];
	const result = await getListCoa(
		company.value.value,
		company.value.db_instance,
		costCenter.value.segment4
	);
	getCOA.value = result.data.data;
};

const getDropdownVat = async () => {
	if (invoiceDate.value) {
		const paramRet = 'RATE';
		const payload = {
			dbInstance: company.value.db_instance,
			param: paramRet,
			invoiceDate: invoiceDate.value,
		};
		const result = await getVATList(payload);
		getVAT.value = result.data;
		if (selectedCategory.value.length > 0) {
			for (const index in selectedCategory.value) {
				let isExistVat = false;
				for (const vat of getVAT.value) {
					if (
						selectedCategory.value[index].vat_code === vat.vat_code
					) {
						isExistVat = true;
					}
				}

				if (!isExistVat) {
					selectedCategory.value[index].vat_code = null;
				}
			}
		}

		getVAT.value.push({
			value: null,
			tax_rate_id: null,
			vat_code: '- No Select -',
			percentage_rate: null,
		});
	}
};

const onChangeVAT = async (event, index) => {
	
	if (
		selectedCategory.value[index].amount === undefined ||
		selectedCategory.value[index].amount === null ||
		selectedCategory.value[index].amount === 0
	) {
		selectedCategory.value[index].vat_code = null;
		
		toast.add({
			severity: 'error',
			summary: 'Empty Amount',
			detail: 'Empty Amount',
			life: 3000,
		});
	} else {
		let vatData = null;
		for (const vat of getVAT.value) {
			if (vat.tax_rate_id == event.value.tax_rate_id) {
				vatData = vat;
			}
		}

		if (vatData) {
			selectedCategory.value[index].percentage_rate =
				vatData.percentage_rate;
			selectedCategory.value[index].vat_code = vatData.vat_code;
			selectedCategory.value[index].tax_rate_id = vatData.tax_rate_id;
			selectedCategory.value[index].vat = vatData;
		} else {
			toast.add({
				severity: 'error',
				summary: 'Data not Found',
				detail: 'Data not Found',
				life: 3000,
			});
		}

	}
};

const handleDeleteLine = (index) => {
	const array = [...selectedCategory.value];
	listDeleted.value.push(array[index]);
	array.splice(index,1);
	selectedCategory.value = array;
}

const getDropdownWHT = async () => {
	const result = await getWHTList(
		company.value.db_instance,
		company.value.value
	);

	getWHT.value.push({
		value: null,
		wht_code_id: null,
		wht_code: '- No Select -',
		order: 0
	});

	getWHT.value = sortingWHT(result.data);

};

const sortingWHT = (data) => {

	const obj = {
		"PPh4": 1,
		"PPh 4": 1,
		"PPh 4(2)": 1,
		"PPh21": 2,
		"PPh 21": 2,
		"PPh23": 3,
		"PPh 23": 3,
		"PPh26": 4,
		"PPh 26": 4
	}

	data.forEach(Q => {
		const spliter = Q['wht_code'].split("_");
		const final = obj[spliter[0]]		
		Q['order'] = final;
	});
	
	return data.sort((a,b) => {
		return a.order - b.order
	})
}


const onChangeWHT = async (event, index) => {
	if (
		selectedCategory.value[index].amount === undefined ||
		selectedCategory.value[index].amount === null
	) {
		selectedCategory.value[index].wht_code = null;
		toast.add({
			severity: 'error',
			summary: 'Empty Amount',
			detail: 'Empty Amount',
			life: 3000,
		});
	} else {
		let whtData = null;
		for (const wht of getWHT.value) {
			if (wht.wht_code_id == event.value.wht_code_id) {
				whtData = wht;
			}
		}
		if (whtData) {
			selectedCategory.value[index].wht_rate = whtData.wht_rate;
			selectedCategory.value[index].wht_code = whtData.wht_code;
			selectedCategory.value[index].wht_code_id = whtData.wht_code_id;
			selectedCategory.value[index].wht = whtData

			const modeling = modelingWHT(whtData.wht_code);
			const account = selectedCategory.value[index].account.split("");
			const accountLength = Number(account.length) - 1;
			account.splice(accountLength, 1, modeling);
			const concated = account.join("")

			const responseData = await getDetailCostCenter({
				company_code: 'DXM',
				account: concated
			})

			if(responseData.data.data.CODE_COMBINATION_ID){
				selectedCategory.value[index].account = concated
				selectedCategory.value[index].coa_id = responseData.data.data.CODE_COMBINATION_ID
			}


			const account_sl = selectedCategory.value[index].account_sl.split("");
			const accountSLLength = Number(account_sl.length) - 1;
			account_sl.splice(accountSLLength, 1, modeling);
			const concatedSL = account_sl.join("")

			const responseDataSL = await getDetailCostCenter({
				company_code: 'DXM',
				account: concatedSL
			})

			if(responseDataSL.data.data.CODE_COMBINATION_ID){
				selectedCategory.value[index].account_sl = concatedSL
				selectedCategory.value[index].coa_sl = responseDataSL.data.data.CODE_COMBINATION_ID
			}

		} else {
			toast.add({
				severity: 'error',
				summary: 'Data not Found',
				detail: 'Data not Found',
				life: 3000,
			});
		}
	}
};

const modelingWHT = (str) => {
	const splitWHT = str.split("_")

	if(splitWHT[0].includes("21")) return "1";
	else if(splitWHT[0].includes("23")) return "3";
	else if(splitWHT[0].includes("4")) return "4";
	else return "0";
}

const onSelectPenagihan = () => {
	selectedCategory.value = []
	stkNumber.value = null;
	invPrepaymentNo.value = null;
	costCenter.value = null;
	description.value = null;
}

const searchAccount = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredCoa.value = [...getCOA.value];
		} else {
			filteredCoa.value = getCOA.value.filter((coa) => {
				// return coa.ACCOUNT.toLowerCase().match(event.query.toLowerCase());
				return coa.account
					.toLowerCase()
					.includes(event.query.toLowerCase());
			});
		}
	}, 250);
};


const onChangeCOALine = async (event, index) => {
	let coaData = null;
	for (const coa of getCOA.value) {
		if (coa.code_combination_id == event.value.code_combination_id) {
			coaData = coa;
		}
	}
	if (coaData) {
		selectedCategory.value[index].account = coaData.account;
		selectedCategory.value[index].coa_id = coaData.code_combination_id;
		

		if(selectedCategory.value[index].account_sl == null){
			selectedCategory.value[index].account_sl = coaData.account;
			selectedCategory.value[index].coa_sl = coaData.code_combination_id;
		}


	} else {
		toast.add({
			severity: 'error',
			summary: 'Data not Found',
			detail: 'Data not Found',
			life: 3000,
		});
	}
};

const onChangeCOALineSL = async (event, index) => {
	let coaData = null;
	for (const coa of getCOA.value) {
		if (coa.code_combination_id == event.value.code_combination_id) {
			coaData = coa;
		}
	}
	if (coaData) {
		selectedCategory.value[index].account_sl = coaData.account;
		selectedCategory.value[index].coa_sl = coaData.code_combination_id;
	} else {
		toast.add({
			severity: 'error',
			summary: 'Data not Found',
			detail: 'Data not Found',
			life: 3000,
		});
	}
};

const onChangeCOAReimburstment = async (event, index) => {
	let coaData = null;
	for (const coa of getCOA.value) {
		if (coa.code_combination_id == event.value.code_combination_id) {
			coaData = coa;
		}
		// if (coa.code_combination_id == event.value) {
		//   coaData = coa;
		// }
	}
	if (coaData) {
		reimburseItems.value[index].account = coaData.account;
		reimburseItems.value[index].code_combination_id =
			coaData.code_combination_id;
		reimburseItems.value[index].coa_id = coaData.code_combination_id;
		// emit("tableList", searchCategory);
	} else {
		toast.add({
			severity: 'error',
			summary: 'Data not Found',
			detail: 'Data not Found',
			life: 3000,
		});
	}
};

// Open TO Modal

// Close TO Modal
const closeTOModal = () => {
	displayTOModal.value = false;
	travelOrderIndex.value = null;
};

// Handle add TO
// const handleAddTO = (selectedTO) => {
// 	selectedCategory.value = selectedCategory.value.map((category, index) => {
// 		if (index === travelOrderIndex.value) {
// 			category.travelOrder = {
// 				travelOrderNo: selectedTO.travel_order_no,
// 				guestName: selectedTO.guest_name,
// 				startDate: selectedTO.start_date,
// 				endDate: selectedTO.end_date,
// 			};
// 		}

// 		return category;
// 	});

// 	travelOrderIndex.value = null;
// 	displayTOModal.value = false;
// };




const getListAllSupplier = async () => {
	const result = await getAllSupplier(company.value.db_instance);
	getVendors.value = result.data;
};

const searchVendors = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredVendors.value = [...getVendors.value];
		} else {
			filteredVendors.value = getVendors.value.filter((vnd) => {
				return vnd.vendor_name
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
		}
	}, 250);
};

//format dete dexa
const formattingDate = (date, withTime) => {
	const theDate = new Date(date);
	const strDate =
		theDate.getFullYear() +
		'-' +
		(theDate.getMonth() + 1) +
		'-' +
		theDate.getDate();
	const strTime =
		theDate.getHours() +
		':' +
		theDate.getMinutes() +
		':' +
		theDate.getSeconds();
	if (withTime) {
		return `${strDate} ${strTime}`;
	} else {
		return `${strDate}`;
	}
};

const cancel = () => {
	router.push('/dashboard');
};

const save = async () => {
	// Validate required fields
	const isFormValid = await v$.value.$validate();
	if (!isFormValid) {
		const errMessage = 'Please fill all required fields';
		toast.add({
			severity: 'error',
			summary: errMessage,
			life: 3000,
		});
		return;
	}
	
	const payload = {
		header: {
			INVOICE_NO: invoiceNo.value,
			INVOICE_DATE: formattingDate(invoiceDate.value),
			REVISION_NUM: 0,
			TAX_NO: taxInvoiceNo.value,
			TAX_DATE: formattingDate(taxInvoiceDate.value),
			DESCRIPTION: description.value,
			CURRENCY_CODE: currency.value.currency_code,
			VENDOR_ID: vendorId.value,
			INVOICE_AMOUNT: summary.value.totalLine,
			TAX_AMOUNT: summary.value.totalPpn,
			TOTAL_AMOUNT: summary.value.totalInvoice,
			ORG_ID: parseInt(company.value.value),
			STATUS: invoiceStatus.value,
			CREATED_BY: userInfo.value.id,
			CREATION_DATE: formattingDate(new Date(), true),
			LAST_UPDATE_DATE: formattingDate(new Date(), true),
			LAST_UPDATED_BY: userInfo.value.id,
			PAYMENT_METHOD_LOOKUP_CODE: paymentMethod.value.payment_method,
			GL_DATE: formattingDate(glDate.value),
			TERMS_ID: term.value.term_id,
			ACCTS_CODE_COMBINATION_ID: null,
			EXTERNAL_BANK_ACCOUNT_ID: billBank.value.ext_bank_account_id,
			INVOICE_TYPE: 'INVOICE PROXY',
			CATEGORY_ID: 9276,
			MAPING_ID: 103129,
			REFERENCE_NUMBER: skNumber.value,
			VENDOR_SITE_ID: vendorSiteId.value,
			COST_CENTER: costCenter.value.segment4,
			DB_INSTANCE: company.value.db_instance,
			CC_OWNER: ccOwner.value ? ccOwner.value.USER_EMAIL : null,
			TYPE_FORM: 'SP1',
			BILLING_CATEGORY: typePenagihan.value ? typePenagihan.value.TYPE : null,
			NO_INVOICE_PREPAYMENT: invPrepaymentNo.value ? invPrepaymentNo.value.INVOICE_NO : null,
			NO_KI: stkNumber.value.kiCode,
			RECEIVED_TYPE:receivedType.value.TYPE_VALUE,
			NO_PPS: ppsNo.value,
		},
		dataLines: [],
		dataReimburstments: [],
	};

	await selectedCategory.value.forEach((item, index) => {
		let data = {
			LINE_NUM: index + 1,
			ITEM_DESCRIPTION: item.description,
			AMOUNT: item.amount,
			ORGANIZATION_ID: company.value.value,
			CREATED_BY: userInfo.value.id,
			CREATION_DATE: formattingDate(new Date(), true),
			LAST_UPDATE_DATE: formattingDate(new Date(), true),
			LAST_UPDATED_BY: userInfo.value.id,
			CURRENCY_CODE: currency.value.currency_code,
			REVISION_NUM: 0,
			VENDOR_ID: vendorId.value,
			VENDOR_SITE_ID: vendorSiteId.value,
			CATEGORY_DETAIL_ID: item.category_detail_id,
			VAT_CODE: item.vat_code !== '- No Select -' ? item.vat_code : null,
			WHT_CODE: item.wht_code !== '- No Select -' ? item.wht_code : null,
			COA_ID: item.coa_id,
			VAT_CODE_ID: item.tax_rate_id,
			WHT_CODE_ID: item.wht_code_id,
			REMARKS: item.travelOrder.travelOrderNo,
			COA_SL: item.coa_sl
		};
		payload.dataLines.push(data);
	});

	
	const jsonPayload = JSON.stringify(payload);
	let formData = new FormData();
	formData.append('payload', jsonPayload);
	formData.append('file', file.value);

	try {
		progress.value = true;

		const res = await saveInvoiceSP1(formData);

		if (res.status === 201) {
			// progress.value = false;
			invoiceNo.value = res.data.data.invoice_no;
			// status.value = "DRAFT";
			file.value = null;
			fileUpload.value.clear();
			v$.value.$reset();
			disabledButton.value = true;

			progress.value = false;

			toast.add({
				severity: 'success',
				summary: 'Successfully save new invoice',
				life: 3000,
			});

			return;
		}
		progress.value = false;
	} catch (err) {
		console.log(err.message);
		progress.value = false;

		if (err.message.includes('Network Error')) {
			console.log('network error');
			toast.add({
				severity: 'error',
				summary: 'Network Error',
				detail: 'Connection Down. Please check your invoice status on List Invoice Non PO menu.',
			});
			return;
		}

		if (err.response.status === 400 || err.response.status === 403) {
			if (err.response.data.message.includes('exist')) {
				errorExisting.value = true;
			}
			toast.add({
				severity: 'error',
				summary: err.response.data.message,
				life: 3000,
			});
			return;
		}

		toast.add({
			severity: 'error',
			summary: 'Server Error: Unsuccessfully save new invoice',
			life: 3000,
		});
	}
};

function addModal(){
	displayModal.value = true
}

function getItemData(data){
	const find = listDeleted.value.findIndex((Q) => Q.travelOrder.travelOrderNo === data.travelOrder.travelOrderNo);
	listDeleted.value.splice(find,1);
	selectedCategory.value.push(data);
} 

</script>
