<template>
	<AutoComplete
		v-model="selectedAccount"
		class="w-full"
		style="width: 100% !important"
		:suggestions="filteredAccount"
		@complete="searchAccount($event)"
		:dropdown="true"
		:disabled="!account.length"
		field="ACCOUNT"
		forceSelection
	>
		<template #item="slotProp">
			{{ slotProp.item.ACCOUNT }}
		</template>
	</AutoComplete>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import MasterCategory from '../../../service/MasterCategory';

// eslint-disable-next-line no-undef
const props = defineProps({
	company: { type: Object },
	value: { type: Object },
	accountList: { type: Array },
});
// // eslint-disable-next-line no-undef
const filteredAccount = ref([]);
const account = ref([]);

onMounted(() => {
	populateData();
});

const populateData = async () => {
	if (props.accountList) {
		account.value = props.accountList;
	} else {
		if (props.company !== null) {
			const payload = {
				db_instance: props.company.db_instance,
				org_id: props.company.value,
			};
			const data = await MasterCategory.getCoA(payload);

			if (data.status === 200) {
				account.value = data.data.data;
			}
		} else {
			account.value = [];
		}
	}
};

const searchAccount = (event) => {
	if (!event.query.trim().length) {
		filteredAccount.value = [...account.value];
	} else {
		filteredAccount.value = account.value.filter((comp) => {
			return comp.ACCOUNT.toLowerCase().match(event.query.toLowerCase());
		});
	}
};
</script>

<style scoped></style>
