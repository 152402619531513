<template>
    <Button
        class="button-download font-normal"
        :label="props.label"
        icon="pi pi-download"
        @click="handleFileDownload()"
    />
</template>

<script setup>
// import {
//     onMounted, 
//     ref} from 'vue'

// eslint-disable-next-line no-undef
const props = defineProps({

link: {
  type: String
},
label: {
  type: String
},
})
    
const handleFileDownload = async () => {
    try {
        window.location.href = props.link;
    } catch (err) {
        this.$toast.add({
            severity: 'error',
            summary: `Error downloading file`,
            life: 3000,
        });
    }
}
</script>
    