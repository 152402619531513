<template>
  <AutoComplete
    :suggestions="filteredPayGroup"
    @complete="searcSupplierSite($event)"
    :dropdown="true"
    field="VALUE"
    :placeholder="placeholder"
    v-model="payGroups"
    forceSelection
    @item-select="onSelect($event)"
  >
    <template #item="slotProp">
      {{ slotProp.item.VALUE }}
    </template>
  </AutoComplete>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect,  defineProps, watch } from "vue";
//   import LovService from "@/service/LovService";
//   import SwithCompany from "@/service/SwithCompany";
// import AuthService from "@/service/AuthService";
import invoiceNonPOservice from "@/service/InvoiceNonPOservice";

const props = defineProps({
  db_instance: {type: Object},
});


// eslint-disable-next-line no-undef
const filteredPayGroup = ref([]);
const payGroupList = ref([]);
const payGroups = ref();
const placeholder = ref("Select a Pay Group");

const emit = defineEmits(["payGroup"]);

onMounted(() => {
  populateData()
})

watchEffect(() => emit("payGroup", payGroups));

watch(payGroups.value, (text) => {
  console.log("Pay Group : ", text)
})
// eslint-disable-next-line no-undef


const populateData = async () => {
  const result = await invoiceNonPOservice.getListPayGroup(
    props.db_instance
  );
  payGroupList.value = result.data.data;
}

onMounted(()=>{
  if(props.company){
    populateData()
  }
});

const onSelect = (event) => {
  console.log(event.value)
  emit("payGroup", event.value.VALUE)
}

const searcSupplierSite = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredPayGroup.value = [...payGroupList.value];
    } else {
      filteredPayGroup.value = payGroupList.value.filter((pg) => {
        return pg.VALUE.toLowerCase().match(
          event.query.toLowerCase()
        );
      });
    }
  }, 250);
};
</script>
