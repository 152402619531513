<template>
	<AutoComplete
		:suggestions="filteredinvoiceNumber"
		@complete="searchinvoiceNumber($event)"
		:dropdown="true"
		field="INVOICE_NO"
		placeholder="Invoice Number"
		:disabled="disable || !props.company || !props.supplier"
		forceSelection
	>
		<template #item="slotProp">
			{{ slotProp.item.INVOICE_NO }}
		</template>
	</AutoComplete>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import AuthService from '../../../service/AuthService';
import ListInvoiceNonPo from '../../../service/ListInvoiceNonPo';
// eslint-disable-next-line no-undef
const props = defineProps({
	company: { type: Object },
	supplier: { type: Object },
	status: { type: Object },
});
// eslint-disable-next-line no-undef
const filteredinvoiceNumber = ref([]);
const invoiceNumber = ref([]);
const disable = ref(false);
onMounted(() => {
	populateData();
});
const populateData = () => {
	AuthService.whoAmi().then(async (response) => {
		if (response.status === 200) {
			disable.value = true;
			let payload;
			if (props.company !== null && props.supplier !== null) {
				payload = {
					org_id: props.company ? props.company.value : null,
					db_instance: props.company.db_instance,
					supValue: props.supplier ? props.supplier.vendor_id : null,
					status: props.status ? props.status.STATUS : null,
				};
				const data = await ListInvoiceNonPo.getInvoiceNum(payload);
				invoiceNumber.value = data.data.data.data;
				disable.value = false;
			} else if (props.company !== null) {
				payload = {
					org_id: props.company ? props.company.value : null,
					db_instance: props.company.db_instance,
					supValue: props.supplier ? props.supplier.vendor_id : null,
					status: props.status ? props.status.STATUS : null,
				};
				const data = await ListInvoiceNonPo.getInvoiceNum(payload);
				invoiceNumber.value = data.data.data.data;
				disable.value = false;
			}
		}
	});
};
const searchinvoiceNumber = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredinvoiceNumber.value = [...invoiceNumber.value];
		} else {
			filteredinvoiceNumber.value = invoiceNumber.value.filter((comp) => {
				return comp.INVOICE_NO.toLowerCase().match(
					event.query.toLowerCase()
				);
			});
		}
	}, 250);
};
</script>

<style scoped></style>
