<template>
	<div class="grid">
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" />
		</div>

		<!-- Content -->
		<CreateInvoiceNonPO
			v-if="currentUser"
			title="Create E-Invoice"
			:currentUser="currentUser"
		/>
	</div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AuthService from '../../../service/AuthService';
import CreateInvoiceNonPO from '../../components/CreateInvoiceNonPO.vue';

const router = useRouter();
const route = useRoute();

const currentUser = ref(null);

const breadcrumb = computed(() => {
	return {
		home: { icon: 'pi pi-home', to: '/dashboard' },
		items: [
			{
				label: 'Create Invoice Non PO',
				to: route.fullPath,
			},
		],
	};
});

onMounted(async () => {
	const resAuth = await AuthService.whoAmi();
	currentUser.value = resAuth.data.data;

	// Check if user is not supplier nor admin
	if (
		currentUser.value.leveltenant !== 2 &&
		currentUser.value.userid !== 'dexaadmin'
	) {
		// Redirect to homepage if user is purchasing and not admin
		router.push('/dashboard');
	}
});
</script>
