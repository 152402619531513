<template>
  <Toast />
  <DataTable
      :value="tableList"
      :paginator="true"
      :rows="10"
      :rowsPerPageOptions="[10, 20, 30]"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="600px"
      scrollDirection="both"
      @page="onPage($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
  >
    <template #empty>No Invoice found</template>
      <template #loading>Loading data</template>
      <Column header="Line" headerStyle="min-width:5rem;" style="width: 10%;">		
        <template #body="slotProps">
          {{ slotProps.index + 1 + page }}
        </template>
      </Column>
      <Column header="PO Number" field="PO_NUMBER" style="width: 15%;">
      </Column>
      <Column header="PO Total Amount" field="TOTAL_AMOUNT" style="width: 15%;" >
        <template #body="{ data }">
          {{
            Number.parseFloat(data.TOTAL_AMOUNT)
              .toFixed(2)
              .replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ','
              )
          }}
        </template>
      </Column>
      <Column header="Prepayment No" field="PREPAYMENT_NO"  style="width: 15%;">
      </Column>
      <Column header="Percentage" field="PERCENTAGE" style="width: 10%;" >
        <template #body="{data}">
          <span>{{ data.PERCENTAGE }} %</span>
        </template>
      </Column>
      <Column header="Amount" style="width: 15%;" >
      <template #body="{ data }">
        {{
          formatRounding(data.AMOUNT)
        }}
      </template>
    </Column>
    <Column header="VAT" style="width: 15%">
			<template #body="slotProps">
        <AutoComplete 
          forceSelection
          :dropdown="true"
          :suggestions="filteredVAT"
          @complete="searchVAT($event)"
          v-model="slotProps.data.VAT_CODE"
          @change="onChangeVATValue(slotProps.data.VAT_CODE,  slotProps.index + page)"
          field="VAT_CODE"
          @item-select="onChangeVAT($event, slotProps.index + page)"
        />
			</template>
		</Column>
    <Column header="WHT" style="width: 15%">
      <template #body="slotProps">
        <AutoComplete 
          forceSelection
          :dropdown="true"
          :suggestions="filteredWHT"
          @complete="searchWHT($event)"
          v-model="slotProps.data.WHT_CODE"
          field="WHT_CODE"
          @item-select="onChangeWHT($event, slotProps.index + page)"
        />
      </template>
    </Column>
    <Column header="Account" style="width: 25%">
      <template #body="slotProps">
        <AutoComplete
					v-model="slotProps.data.ACCOUNT"
					field="account"
					:suggestions="filteredAccount"
					:dropdown="true"
					@complete="searchAccount($event)"
					@item-select="getAccountId($event, slotProps.index)"
					:disabled="!accountData"
					forceSelection
					:class="{
						'p-invalid':
							tableList[slotProps.index + page].ACCOUNT_VALIDATE,
					}"
				/>
        <div v-if="tableList[slotProps.index + page].ACCOUNT_VALIDATE">
					<small class="p-error">{{ 'Account is empty' }}</small>
				</div>
      </template>
    </Column>
  </DataTable>
</template>

<script setup>
import {onMounted, ref, defineEmits, watch} from "vue";
import AuthService from "@/service/AuthService";
import PoFulfillment from "@/service/PoFulfillment";
import InvoiceService from "../../../service/InvoiceSearchWPO"
import LovService from "@/service/LovService";
import InvoiceNonPOservice from "../../../service/InvoiceNonPOservice";
import SearchInvoiceProxyNonPO from "../../../service/InvoiceProxyService"
import { formatRounding } from "../../../helper/formatRounding"
import { useToast } from "primevue/usetoast";

const toast = useToast();


const company = ref()
const supplier = ref()
// const idValue = ref()
const tableList = ref()
const page = ref(0)
const emit = defineEmits('tableList')
const getVAT = ref([]);
const getWHT = ref();
const filteredWHT = ref([]);
const accountData = ref(null);
const filteredAccount = ref([])
const filteredVAT = ref([])



watch(
	() => props.tableVuelidate,
	(newValue) => {
    console.log(newValue, "NEW VAL")
		for (const i in tableList.value) {
			if (props.tableVuelidate !== null) {
				//
				//
				if (newValue[0]['EINVOICE_REIMBURSMENT_ID']) {
					tableList.value[i].ACCOUNT_VALIDATE = false;
				} else {
					//
					if (newValue[i].ACCOUNT[0] !== undefined) {
						tableList.value[i].ACCOUNT_VALIDATE = true;
					} else if (newValue[i].ACCOUNT[0] == undefined) {
						tableList.value[i].ACCOUNT_VALIDATE = false;
					}
				}
			} else {
				tableList.value[i].ACCOUNT_VALIDATE = false;
			}
		}
	}
);


onMounted(async () => {
  company.value = props.company;
  // 
  const info = await AuthService.whoAmi();
  if (info.status === 200) {
    levelTenant.value = info.data.data.leveltenant;
    if (levelTenant.value !== 2) {
      loading.value = true
      supplier.value = props.supplier;
    //   loading.value = true
      await populateTable();
    } else {
      if (company.value && !supplier.value) {
        const param = {
          db_instance: props.company.db_instance,
          org_id: props.company.value
        }
        const respVendor = await PoFulfillment.getSuppBindCompProc(info.data.data.id);
        const vendorName = respVendor.data.data[0][0].vendorname;
        const respSuppName = await LovService.getSupplierName(param);
        if (respSuppName.status === 200) {
          const suppList = respSuppName.data.data;
          for (const s of suppList) {
            if (s.vendor_name === vendorName) {
              supplier.value = s;
              break;
            }
          }
        }
        await populateTable()
      }
      // loading.value = true

    }
  }
})
// eslint-disable-next-line no-undef
const props = defineProps({
  data: {
    type : Object
  },
  orgId: {
    type: Number
  },
  costCenter: {
    type: Object
  },
  db_instance: {
    type: String
  },
  tableVuelidate: {
    type: Object
  },
  invoiceDate: {
    type: String
  }
})

watch(props.invoiceDate, async () => {
  console.log(props.invoiceDate, "DATE")
  await getDropdownVat()
})

watch(tableList, () => {
  emit('tableList', tableList)
})




const loading = ref(false)
const levelTenant = ref()

const populateTable = async () => {
  loading.value = true
  // 
  if (props.data !== null ) {
    // 
   

    const data = await InvoiceService.getTableDetailSearchInvoiceWPOPrepayment(props.data?props.data:null)

    tableList.value = data.data.data.data.map((item) => {
      return{...item, vat_current: item.VAT_CODE}
    })

    console.log(tableList.value, "TAB VALUE")

    await getDropdownVat();
		await getDropdownWHT();
    await getAccount();
    
    // 
    emit('tableList', tableList)
  }
  loading.value = false;
}


watch(
	() => props.costCenter,
	() => {
		if (props.costCenter !== null) {
			getAccount();
		}
	}
);


const onPage = async (event) => {
  page.value = event.page * event.rows
}

const onChangeVAT = async (event, index) => {
   
  //----VALIDATION FROM----
  const splitz = event.value.VAT_CODE.split(" ")
  console.log(event.value, "VAL")
		let rule = new Array();
		if(splitz.includes("EXCL")){
			tableList.value.forEach((item) => {
				const sp = typeof(item.VAT_CODE) === 'string' ? 
					item.VAT_CODE.split(" ") : []

				if(sp.includes("INCL") === true){
					rule.push(item);
					const vatData = getVAT.value.find((Q) => Q.VAT_CODE === tableList.value[index].vat_current);
					
          tableList.value[index].PERCENTAGE_RATE = vatData.PERCENTAGE_RATE;
          tableList.value[index].VAT_CODE = vatData.VAT_CODE;
          tableList.value[index].VAT_CODE_ID = vatData.TAX_RATE_ID;
          tableList.value[index].TAX_RATE_CODE = vatData.TAX_RATE_CODE;
					return;
				}
			});
			
			if(rule.length > 0) {
		
				return
			}
		}else if(splitz.includes("INCL")){
			tableList.value.forEach((item) => {
				const sp = typeof(item.VAT_CODE) === 'string' ? 
					item.VAT_CODE.split(" ") : item.VAT_CODE.VAT_CODE.split(" ")
				const isTrue = item.VAT_CODE !== null && sp.includes("INCL") == false;
        console.log(sp, isTrue, splitz, "TEST")
				if(isTrue){
					rule.push(item);
					console.log(tableList.value[index].vat_current, "VAT CURRENT")
					// const vatData = getVAT.value.find((item) => item.TAX_RATE_ID === tableList.value[index].vat_current);
					const vatData = getVAT.value.find((Q) => Q.VAT_CODE === tableList.value[index].vat_current);
          tableList.value[index].PERCENTAGE_RATE = vatData.PERCENTAGE_RATE;
          tableList.value[index].VAT_CODE = vatData.VAT_CODE;
          tableList.value[index].VAT_CODE_ID = vatData.TAX_RATE_ID;
          tableList.value[index].TAX_RATE_CODE = vatData.TAX_RATE_CODE;
					return;
				}
			});

      console.log(rule, "RULE")

		
			if(rule.length > 0) {
				toast.add({
					severity: 'error',
					summary: 'Cannot choose different type of VAT Code',
					life: 3000,
				});
				return
			}
		}
		//---VALIDATION END---

  
  if (
    tableList.value[index].AMOUNT === undefined ||
    tableList.value[index].AMOUNT === null
  ) {
    tableList.value[index].VAT_CODE = null;
  } else {
    const vatData = getVAT.value.find((item) => item.TAX_RATE_ID === event.value.TAX_RATE_ID);

    if (vatData) {
      tableList.value[index].PERCENTAGE_RATE = vatData.PERCENTAGE_RATE;
      tableList.value[index].VAT_CODE = vatData.VAT_CODE;
      tableList.value[index].VAT_CODE_ID = vatData.TAX_RATE_ID;
      tableList.value[index].TAX_RATE_CODE = vatData.TAX_RATE_CODE;
      tableList.value[index].CHANGE = true;
      emit('tableList', tableList);
    }
  }
};

const getDropdownVat = async () => {
	const paramRet = 'RATE';
	const payload = {
		paramRet: paramRet,
		dbInstance: sessionStorage.companydb,
		invoiceDate: props.invoiceDate,
	};
	const result = await InvoiceNonPOservice.getListVat(payload);
	
  const noSelect = {
			PARAM_RET: "",
      PERCENTAGE_RATE: 0,
      TAX_RATE_ID: 0,
      TAX_RATE_CODE: null,
      EFFECTIVE_FROM: "1998-12-31T17:00:00.000Z",
      EFFECTIVE_TO: null,
      ACTIVE_FLAG: "Y",
      ATTRIBUTE1: "SUPPLIER PORTAL",
      VAT_CODE: "- No Select -"
		}

	getVAT.value = [noSelect, ...result.data.data];
};

const getDropdownWHT = async () => {
	const result = await InvoiceNonPOservice.getListWht(
		props.orgId,
		sessionStorage.companydb
	);

  console.log(result.data.data, "RESULT")

  const noSelect = {
      WHT_RATE: 0,
      WHT_CODE_ID: 0,
      WHT_CODE: "- No Select -"
  }
  getWHT.value = [noSelect, ...result.data.data];
};

const onChangeWHT = async (event, index) => {
	if (
		tableList.value[index].AMOUNT === undefined ||
		tableList.value[index].AMOUNT === null
	) {
		tableList.value[index].WHT_CODE = null;
	} else {
		let whtData = getWHT.value.find((item) => item.WHT_CODE_ID === event.value.WHT_CODE_ID);
		if (whtData) {
			tableList.value[index].WHT_RATE = whtData.WHT_RATE;
			tableList.value[index].WHT_CODE = whtData.WHT_CODE;
			tableList.value[index].WHT_CODE_ID = whtData.WHT_CODE_ID;
			tableList.value[index].CHANGE = true;
			emit('tableList', tableList);
		}
	}
};

const searchVAT = (event) => {
  if (!event.query.trim().length) {
    filteredVAT.value = [...getVAT.value]
  }else {
    filteredVAT.value = getVAT.value.filter((val) => val.VAT_CODE.toLowerCase().includes(event.query.toLowerCase()));
  } 
}

const searchWHT = (event) => {
  if (!event.query.trim().length) {
    filteredWHT.value = [...getWHT.value]
  }else {
    filteredWHT.value = getWHT.value.filter((val) => val.WHT_CODE.toLowerCase().includes(event.query.toLowerCase()));
  } 
}

const searchAccount = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredAccount.value = [...accountData.value];
		} else {
			filteredAccount.value = accountData.value.filter((val) => {
				if (val.account === null || val.account === undefined) {
					return val;
				}
				return val.account
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
		}
	}, 250);
};

const getAccountId = async (event, index) => {
  
	tableList.value[index].ACCOUNT = event.value.account;
	tableList.value[index].COA_ID = event.value.code_combination_id;
  tableList.value[index].ACCOUNT_VALIDATE = false;
	emit('tableList', tableList);
};

const getAccount = async () => {
	//
	if (
		props.costCenter !== null &&
		(props.costCenter.SEGMENT4 !== undefined ||
			props.costCenter.SEGMENT4 !== null ||
			props.costCenter.SEGMENT4 !== '')
	) {
		const payload = {
			orgId: props.orgId,
			dbInstance: props.db_instance,
			cost_center: props.costCenter.SEGMENT4,
		};

		//
		const res = await SearchInvoiceProxyNonPO.accountPic(payload);
		//
		if (res.status === 200) {
			//

			accountData.value = res.data.data.data;

      tableList.value = tableList.value.map((item) => {
        return{...item, 
          ACCOUNT: accountData.value.find((Q) => Q.code_combination_id === item.ACCOUNT).account,
          COA_ID: accountData.value.find((Q) => Q.code_combination_id === item.ACCOUNT).code_combination_id
        }
      })
		}
	}
};

const onChangeVATValue = (event,index) => {
    const relate = getVAT.value.find((item) => item.VAT_CODE === event);
    console.log(event, relate)
    if(!relate){
      tableList.value[index].PERCENTAGE_RATE = null;
      tableList.value[index].VAT_CODE = null;
      tableList.value[index].VAT_CODE_ID = null;
      tableList.value[index].TAX_RATE_CODE = null;
      tableList.value[index].CHANGE = true;
      emit('tableList', tableList);
    }
}




// const onSort = async (event) => {
//   // loading.value = true
//   page.value = 1
//   rowsPerPage.value = event.rows;
//   sortColumn.value = event.sortField;
//   sortType.value = event.sortOrder;
//   await populateTable()
// }

</script>

<style scoped>

</style>