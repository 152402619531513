import spbAdmin from "./api/SpbAdminApi";
class ItemJit {
    getTable(payload) {
        return spbAdmin.post('spb/itemjit/gettable', payload);
    }
    getDetail(payload){
        return spbAdmin.post('spb/itemjit/getDetail', payload);
    }
    getUoMDB(payload){
        return spbAdmin.post('spb/itemjit/getuomdb', payload);
    }
    saveData(payload){
        return spbAdmin.post('spb/itemjit/save', payload);
    }
    createData(payload){
        return spbAdmin.post('spb/itemjit/create', payload);
    }

}
export default new ItemJit();