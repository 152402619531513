<template>
	<div class="grid requisition">
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<!-- Search input -->
		<div class="col-12 p-fluid mb-4">
			<div class="content-wrapper">
				<div class="content-header">Open Requisition</div>
				<div v-if="currentUser" class="content-body">
					<div class="grid align-items-center">
						<!-- Company -->
						<div class="col-12 mb-4 md:col-6 lg:col-3 lg:mb-0">
							<span class="p-float-label">
								<AutoComplete
									disabled
									:suggestions="filteredCompany"
									:dropdown="true"
									field="name"
									forceSelection
									v-model="company"
								/>
								<label>Company</label>
							</span>
						</div>
						<!-- Supplier list -->
						<div class="col-12 mb-4 md:col-6 lg:col-3 lg:mb-0">
							<span class="p-float-label">
								<AutoComplete
									id="supplier"
									:disabled="!supplierList"
									v-model="supplier"
									:suggestions="filteredSupplier"
									@complete="searchSupplier($event)"
									:dropdown="true"
									field="vendor_name"
									forceSelection
								/>
								<label for="supplier">Supplier</label>
							</span>
						</div>
						<!-- Filter column -->
						<div class="col-12 mb-4 md:col-6 lg:col-3 lg:mb-0">
							<span class="p-float-label">
								<AutoComplete
									id="column"
									:disabled="!columnList"
									:suggestions="filteredColumn"
									:dropdown="true"
									field="name"
									forceSelection
									v-model="column"
									@complete="searchColumn($event)"
									@change="searchKeyword = null"
								/>
								<label for="column">Select a Column</label>
							</span>
						</div>
						<!-- Input search -->
						<div class="col-12 mb-4 md:col-6 lg:col-3 lg:mb-0">
							<div>
								<div v-if="column && column.type === 'Number'">
									<div>
										<!-- Input number for qty's field -->
										<div v-if="column.value === 'pr_qty'">
											<span class="p-float-label">
												<InputNumber
													id="searchKeyword"
													autocomplete="off"
													mode="decimal"
													:minFractionDigits="2"
													:maxFractionDigits="2"
													v-model="searchKeyword"
												/>
												<label for="searchKeyword"
													>Input Search Keyword</label
												>
											</span>
										</div>
										<!-- Input number for others field -->
										<div v-else>
											<span class="p-float-label">
												<InputNumber
													id="searchKeyword"
													autocomplete="off"
													:useGrouping="false"
													v-model="searchKeyword"
												/>
												<label for="searchKeyword"
													>Input Search Keyword</label
												>
											</span>
										</div>
									</div>
								</div>
								<!-- Input search for date -->
								<div
									v-else-if="column && column.type === 'Date'"
								>
									<span class="p-float-label">
										<Calendar
											id="searchKeyword"
											autocomplete="off"
											dateFormat="d-M-yy"
											v-model="searchKeyword"
										/>
										<label for="searchKeyword"
											>Input Search Keyword</label
										>
									</span>
								</div>
								<!-- Input search for text -->
								<div v-else>
									<!-- Dropdown for status field -->
									<div
										v-if="
											column && column.value === 'status'
										"
									>
										<span class="p-float-label">
											<Dropdown
												id="searchKeyword"
												:options="statusList"
												optionLabel="name"
												v-model="searchKeyword"
											/>
											<label for="searchKeyword"
												>Input Search Keyword</label
											>
										</span>
									</div>
									<!-- Input for other text fields -->
									<div v-else>
										<span class="p-float-label">
											<InputText
												id="searchKeyword"
												autocomplete="off"
												v-model="searchKeyword"
											/>
											<label for="searchKeyword"
												>Input Search Keyword</label
											>
										</span>
									</div>
								</div>
							</div>
						</div>
						<!-- Report list dropdown -->
						<div
							class="col-12 mb-4 md:col-6 lg:col-3 lg:mb-0 lg:mt-4"
						>
							<span class="p-float-label">
								<Dropdown
									id="reports"
									scrollHeight="300px"
									optionLabel="name"
									optionGroupLabel="label"
									optionGroupChildren="items"
									:options="reportList"
									v-model="selectedReport"
								/>
								<label for="reports">Reports</label>
							</span>
						</div>

						<!-- Action buttons -->
						<div class="col-auto mb-4 px-2 lg:mb-0 lg:mt-4">
							<Button
								v-if="!isLoading"
								class="p-button-success w-auto mr-2"
								:disabled="!company"
								@click="handleSearch"
							>
								<span class="font-bold uppercase">Search</span>
							</Button>
							<Button
								v-else
								class="p-button-success w-auto mr-2"
								disabled
							>
								<span class="font-bold uppercase"
									>Searching...</span
								>
							</Button>
							<Button
								v-if="!isLoadingExport"
								class="w-auto p-button-secondary mr-2"
								:disabled="!requisitionList"
								@click="handleExport"
							>
								<span class="font-bold uppercase">
									<i class="pi pi-file" />
									Export</span
								>
							</Button>
							<Button
								v-else
								class="w-auto p-button-secondary mr-2"
								disabled
							>
								<span class="font-bold uppercase">
									<i class="pi pi-file" />
									Exporting...</span
								>
							</Button>
							<Button
								class="w-auto p-button-secondary"
								@click="handleClear"
							>
								<span class="font-bold uppercase">Clear</span>
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Search results -->
		<div class="col-12 p-fluid mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search Results</div>
				<div class="content-body">
					<div class="grid">
						<div class="col-12">
							<DataTable
								dataKey="id"
								responsiveLayout="scroll"
								ref="dt"
								:lazy="true"
								:paginator="true"
								:rows="5"
								:rowsPerPageOptions="[5, 10, 20, 50]"
								:loading="isLoading"
								:totalRecords="totalRecords"
								:value="requisitionList"
								removableSort
								paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
								currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
								:scrollable="true"
								scrollHeight="600px"
								scrollDirection="both"
								@page="handlePagination($event)"
							>
								<Column
									header="Company"
									field="company_name"
									style="width: 15%"
								>
									<template #body="{ data }">
										{{ data.company_name || '-' }}
									</template>
								</Column>
								<Column
									header="Supplier"
									field="vendor_name"
									:sortable="true"
									style="width: 15%"
								>
									<template #body="{ data }">
										{{ data.vendor_name || '-' }}
									</template>
								</Column>
								<Column
									header="PR Number"
									field="pr_num"
									:sortable="true"
									style="width: 15%"
								>
									<template #body="{ data }">
										{{ data.pr_num || '-' }}
									</template>
								</Column>
								<Column
									header="Num"
									field="pr_line_num"
									:sortable="true"
									style="width: 9%"
								>
									<template #body="{ data }">
										{{ data.pr_line_num || '-' }}
									</template>
								</Column>
								<Column
									header="Item Number"
									field="item_num"
									:sortable="true"
									style="width: 15%"
								>
									<template #body="{ data }">
										{{ data.item_num || '-' }}
									</template>
								</Column>
								<Column
									header="Item Description"
									field="item_desc"
									:sortable="true"
									style="width: 20%"
								>
									<template #body="{ data }">
										{{ data.item_desc || '-' }}
									</template>
								</Column>
								<Column
									header="Qty"
									field="pr_qty"
									:sortable="true"
									style="width: 9%"
								>
									<template #body="{ data }">
										{{
											new Intl.NumberFormat(
												'en-US'
											).format(data.pr_qty) || '-'
										}}
									</template>
								</Column>
								<Column
									header="UOM"
									field="uom"
									:sortable="true"
									style="width: 9%"
								>
									<template #body="{ data }">
										{{ data.uom || '-' }}
									</template>
								</Column>
								<Column
									header="Need By Date"
									:sortable="true"
									style="width: 9%"
								>
									<template #body="{ data }">
										{{ data.formatted_need_by_date || '-' }}
									</template>
								</Column>
								<Column
									header="Category"
									field="category"
									:sortable="true"
									style="width: 9%"
								>
									<template #body="{ data }">
										{{ data.category || '-' }}
									</template>
								</Column>
								<Column
									header="Mfg"
									field="manuf"
									:sortable="true"
									style="width: 9%"
								>
									<template #body="{ data }">
										{{ data.manuf || '-' }}
									</template>
								</Column>
								<Column
									header="Plan Order Num"
									field="plan_order_num"
									:sortable="true"
									style="width: 15%"
								>
									<template #body="{ data }">
										{{ data.plan_order_num || '-' }}
									</template>
								</Column>
								<Column
									header="PO Num"
									field="po_number"
									:sortable="true"
									style="width: 15%"
								>
									<template #body="{ data }">
										{{ data.po_number || '-' }}
									</template>
								</Column>
								<Column
									header="Status"
									field="status_name"
									:sortable="true"
									style="width: 9%"
								>
									<template #body="{ data }">
										{{ data.status_name || '-' }}
									</template>
								</Column>

								<!-- Empty statement -->
								<template #empty> No data found. </template>
							</DataTable>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import SimpleCrypto from 'simple-crypto-js';
import XLSX from 'xlsx';
import AuthService from '../../service/AuthService';
import LovService from '../../service/LovService';
import InvoiceCreateNonPOService from '../../service/InvoiceCreateNonPO';
import RequisitionService from '../../service/RequisitionService';

// Router
const route = useRoute();
const router = useRouter();

// State
const currentUser = ref(null);
const company = ref(null);
const companyList = ref(null);
const filteredCompany = ref(null);
const supplier = ref(null);
const supplierList = ref(null);
const filteredSupplier = ref(null);
const column = ref(null);
const columnList = ref(null);
const filteredColumn = ref(null);
const searchKeyword = ref(null);
const reportList = ref(null);
const statusList = ref(null);
const selectedReport = ref(null);
const dt = ref(null);
const lazyParams = ref(null);
const requisitionList = ref(null);
const totalRecords = ref(0);
const isLoading = ref(false);
const isLoadingExport = ref(false);

const breadcrumbHome = ref({ icon: 'pi pi-home', to: '/dashboard' });
const breadcrumbItems = ref([
	{
		label: 'Requisition',
		to: route.fullPath,
	},
]);

// Composables
const toast = useToast();

// Mounted
onMounted(async () => {
	const resAuth = await AuthService.whoAmi();
	currentUser.value = resAuth.data.data;

	// Check if user is not supplier nor finance nor admin
	if (
		currentUser.value.leveltenant !== 1 &&
		currentUser.value.userid !== 'dexaadmin'
	) {
		// Redirect to homepage if user is purchasing and not admin
		router.push('/dashboard');
		return;
	}

	// Set lazy params
	lazyParams.value = {
		first: 0,
		page: 0,
		rows: dt.value.rows,
	};

	await getCompany();

	getSupplierList();
	getColumnList();
	getReportList();
	getStatusList();
});

// Formatting date
const formattingDate = (date) => {
	const theDate = new Date(date);

	// Str date 1
	const strDate1 =
		theDate.getMonth() +
		1 +
		'/' +
		theDate.getDate() +
		'/' +
		theDate.getFullYear();

	// Str date 2
	const strDate2 = `${theDate.getFullYear()}-${(
		'0' +
		(theDate.getMonth() + 1)
	).slice(-2)}-${('0' + theDate.getDate()).slice(-2)}`;

	return [strDate1, strDate2];
};

// Function for check status name
const checkStatusName = (status) => {
	let statusName;

	// Mapping status name
	switch (status) {
		case 'S':
			statusName = 'Processed';
			break;
		case 'N':
			statusName = 'Not Accept';
			break;
		case 'C':
			statusName = 'Cancelled';
			break;
		case 'X':
			statusName = 'Completed';
			break;
		case 'T':
			statusName = 'To Be Processed';
			break;
		case 'D':
			statusName = 'New';
			break;
	}

	return statusName;
};

// Search supplier
const searchSupplier = (event) => {
	if (!event.query.trim().length) {
		filteredSupplier.value = [...supplierList.value];
	} else {
		filteredSupplier.value = supplierList.value.filter((item) => {
			return (
				item.vendor_name &&
				item.vendor_name.toLowerCase().match(event.query.toLowerCase())
			);
		});
	}
};

// Search column
const searchColumn = (event) => {
	// Reset searchKeyword
	searchKeyword.value = null;

	// Find column list
	if (!event.query.trim().length) {
		filteredColumn.value = [...columnList.value];
	} else {
		filteredColumn.value = columnList.value.filter((item) => {
			return (
				item.name &&
				item.name.toLowerCase().match(event.query.toLowerCase())
			);
		});
	}
};

// Get company
const getCompany = async () => {
	try {
		const res = await LovService.getcompanydb(currentUser.value.bioaddress);
		companyList.value = res.data.data;

		const secretKey = 'some-unique-key';
		const simpleCrypto = new SimpleCrypto(secretKey);
		const decipherText = simpleCrypto.decrypt(
			sessionStorage.getItem('dropdownCompany')
		);
		companyList.value.forEach((element) => {
			if (element.name === decipherText) {
				company.value = element;
				throw 'Break';
			}
		});
	} catch (e) {
		if (e !== 'Break') throw e;
	}
};

// Get list vendor
const getSupplierList = async () => {
	try {
		const res = await InvoiceCreateNonPOService.getListVendor({
			dbInstance: company.value.db_instance,
		});

		if (res.status === 200) {
			supplierList.value = res.data.data;
		}
	} catch (err) {
		console.log(err);
	}
};

// Get column list
const getColumnList = () => {
	const columns = [
		{ name: 'PR Number', value: 'pr_num', type: 'Number' },
		{ name: 'Num', value: 'pr_line_num', type: 'Number' },
		{ name: 'Item Number', value: 'item_num', type: 'String' },
		{ name: 'Item Description', value: 'item_desc', type: 'String' },
		{ name: 'Quantity', value: 'pr_qty', type: 'Number' },
		{ name: 'UOM', value: 'uom', type: 'String' },
		{ name: 'Need By Date', value: 'need_by_date', type: 'Date' },
		// { name: 'Category', value, type: },
		{ name: 'Mfg', value: 'manufacturer_description', type: 'String' },
		{ name: 'Supplier', value: 'vendor_name', type: 'String' },
		{ name: 'Plan Order Num', value: 'plan_order_num', type: 'String' },
		{ name: 'PO Num', value: 'po_number', type: 'String' },
		{ name: 'Status', value: 'status', type: 'String' },
	];

	columnList.value = columns;
};

// Get report list
const getReportList = () => {
	const reports = [
		{
			label: 'Default',
			items: [{ name: 'Primary Report', value: '*' }],
		},
		{
			label: 'Public',
			items: [
				{ name: 'Outstanding PR - Active Ingredient', value: 'C' },
				{ name: 'Outstanding PR - Excipient', value: 'D' },
				{ name: 'Outstanding PR - Packaging', value: 'E' },
			],
		},
	];

	reportList.value = reports;
};

// Get status list
const getStatusList = () => {
	const list = [
		{
			name: 'Processed',
			value: 'S',
		},
		{
			name: 'Not Accept',
			value: 'N',
		},
		{
			name: 'Cancelled',
			value: 'C',
		},
		{
			name: 'Completed',
			value: 'X',
		},
		{
			name: 'To Be Processed',
			value: 'T',
		},
		{
			name: 'New',
			value: 'D',
		},
	];

	statusList.value = list;
};

// Handle pagination
const handlePagination = (event) => {
	lazyParams.value = event;
	handleSearch();
};

// Handle search
const handleSearch = async () => {
	try {
		const payload = {
			dbInstance: company.value.db_instance,
			orgId: company.value.value,
			vendorId: supplier.value ? supplier.value.vendor_id : null,
			column: column.value || null,
			searchKeyword: searchKeyword.value || null,
			report: selectedReport.value ? selectedReport.value.value : null,
		};

		// Check if no keyword is enter
		if (column.value && !searchKeyword.value) {
			toast.add({
				severity: 'warn',
				summary: 'Please enter a search keyword',
				life: 3000,
			});

			return;
		}

		// Check if column type is date
		if (column.value && column.value.type === 'Date') {
			payload.searchKeyword = formattingDate(searchKeyword.value);
		}

		// Check if column field is status
		if (column.value && column.value.value === 'status') {
			payload.searchKeyword = searchKeyword.value.value;
		}

		// Query params
		const params = {
			query: JSON.stringify({
				limit: lazyParams.value.rows,
				page: lazyParams.value.page + 1,
			}),
		};

		// Searching
		isLoading.value = true;
		const res = await RequisitionService.getRequisitionList(
			params,
			payload
		);

		if (res.status === 200) {
			isLoading.value = false;
			totalRecords.value = res.data.data.count;
			requisitionList.value = res.data.data.data.map((item) => {
				// Mapping company name
				const companyName = companyList.value.find(
					(comp) => parseInt(comp.value) === item.org_id
				)['name'];

				// Formatting date
				const formattedNeedByDate = new Intl.DateTimeFormat('id-ID', {
					year: 'numeric',
					month: 'short',
					day: 'numeric',
				}).format(new Date(item.need_by_date));

				// Mapping status name
				const statusName = item.status
					? checkStatusName(item.status)
					: null;

				return {
					...item,
					company_name: companyName,
					formatted_need_by_date: formattedNeedByDate,
					status_name: statusName,
				};
			});

			return requisitionList.value;
		}

		isLoading.value = false;
	} catch (err) {
		isLoading.value = false;
	}
};

// Handle export to excel
const handleExport = async () => {
	try {
		// Set lazy params
		lazyParams.value = {
			page: 0,
			rows: 0,
		};

		isLoadingExport.value = true;

		const res = await handleSearch();

		// Set data
		const data = res.map((item) => ({
			Company: item.company_name,
			Supplier: item.vendor_name,
			'PR Number': item.pr_num,
			Num: item.pr_line_num,
			'Item Number': item.item_num,
			'Item Description': item.item_desc,
			Quantity: item.pr_qty,
			UOM: item.uom,
			'Need By Date': item.need_by_date,
			Category: null,
			Mfg: item.manuf,
			'Plan Order Num': item.plan_order_num,
			'PO Num': item.po_number,
			Status: item.status_name,
		}));

		// Exporting data
		const today = new Date();
		const dd = String(today.getDate()).padStart(2, '0');
		const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		const yyyy = today.getFullYear();
		const combinedDate = dd + mm + yyyy;

		const filename = 'Requisition _' + combinedDate + '.xlsx';
		const ws = XLSX.utils.json_to_sheet(data);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Requisition');
		XLSX.writeFile(wb, filename);

		isLoadingExport.value = false;
	} catch (err) {
		isLoadingExport.value = false;
	}
};

// Handle clear
const handleClear = () => {
	supplier.value = null;
	column.value = null;
	searchKeyword.value = null;
	selectedReport.value = null;
	requisitionList.value = null;
	totalRecords.value = 0;
};
</script>

<style scoped>
.input-disabled {
	color: #000 !important;
}
</style>
