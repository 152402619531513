<template>
	<div class="grid">
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<Toast />
		<div class="col-12">
			<div class="card">
				<div class="formgrid grid">
					<div class="field col-12 md:col-6">
						<div class="field"></div>
						<div class="field">
							<label
								>User Name
								<small class="p-error">*</small></label
							>
							<!-- <span v-if="error.fullname" class="p-error">Full Name is Required</span> -->
							<InputText
								type="text"
								v-model="userName"
								class="w-full mb-3"
								inputClass="w-full"
								inputStyle="padding:1rem"
								disabled
							></InputText>
						</div>
						<div class="field">
							<label
								>New Password
								<small class="p-error">*</small></label
							>
							<!-- <span v-if="error.newPassword" class="p-error">New Password is Required</span> -->
							<Password
								v-model="newPassword"
								:toggleMask="true"
								:feedback="false"
								class="w-full mb-3"
								inputClass="w-full"
								inputStyle="padding:1rem"
							></Password>
						</div>
						<div class="field">
							<label
								>Confirm Password
								<small class="p-error">*</small></label
							>
							<!-- <span v-if="error.confirmPassword" class="p-error">Confirm Password is Required</span> -->
							<Password
								v-model="confirmPassword"
								:toggleMask="true"
								:feedback="false"
								class="w-full mb-3"
								inputClass="w-full"
								inputStyle="padding:1rem"
							></Password>
						</div>
					</div>
				</div>
			</div>
			<div class="card">
				<div>
					<Button
						label="Save"
						@click="resetNow"
						class="p-button-primary mr-2 mb-2 my-auto"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { UrlTab } from '../../constants/UrlTab';
import AuthService from '../../service/AuthService';
import ForgotPasswordService from '../../service/ForgotPasswordService';

export default {
	data() {
		return {
			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [
				{ label: 'Change Password', to: this.$route.fullPath },
			],
			userName: null,
			newPassword: null,
			confirmPassword: null,
		};
	},
	watch: {},

	methods: {
		async resetNow() {
			let payload;
			payload = {
				password: this.newPassword,
				confirmPassword: this.confirmPassword,
				id: this.userName,
			};
			if (this.newPassword === this.confirmPassword) {
				try {
					const resp = await ForgotPasswordService.changePassword(
						payload
					);
					if (resp.status === 200) {
						this.$toast.add({
							severity: 'success',
							summary: 'Password successfully changed',
							detail: 'Redirect to login page',
							life: 3000,
						});
						setTimeout(() => {
							// window.alert('Password successfully changed');
							sessionStorage.clear();
							this.redirectLogin();
						}, 1500);
					}
				} catch (error) {
					this.$toast.add({
						severity: 'error',
						summary: 'Change Password Error',
						detail: 'Cannot connect to network',
						life: 3000,
					});
					// window.alert('Change Password Error', error);
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Password does not match',
					life: 3000,
				});
				// window.alert('Password does not match');
			}
		},
		async redirectLogin() {
			let url = UrlTab() + '/login';
			window.location.replace(url);
		},
	},
	mounted() {
		try {
			AuthService.whoAmi().then((value) => {
				if (value.data.status === 200) {
					this.userName = value.data.data.userid;
				}
			});
		} catch (error) {
			AuthService.loggedOut();
		}
	},
};
</script>
