<template>
	<div class="grid">
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<div class="col-12 p-fluid mb-4 create-invoice-po">
			<Toast />
			<!-- Progress modal -->
			<ProgressModal :progress="progress">
				<template #message>
					Saving invoice in progress. Please wait...
				</template>
			</ProgressModal>

			<div class="content-wrapper">
				<div class="content-header">Create Invoice Proxy Non PO</div>
				<div class="content-body">
					<div class="grid">
						<!-- kolom 1 -->
						<div
							class="md:col-6 lg:col-6 xs:col-12 sm:col-12 grid p-0 pb-0 mb-6 mr-1"
						>
							<div
								class="col-12 p-0 pb-0 mb-5 border-bottom-1 border-gray-200"
							>
								<div class="col-12 mb-2">
									<span class="p-float-label">
										<CompaniesDropdown
											:class="{
												'p-invalid': v$.company.$error,
											}"
											v-model="company"
											@item-select="
												getCompanySelect($event)
											"
											:key="companyReload"
											forceSelection
										/>
									</span>
									<div v-if="v$.company.$error" class="mt-1">
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{ v$.company.$errors[0].$message }}
										</small>
									</div>
								</div>

								<div class="col-12">
									<span class="p-float-label">
										<SupplierPICDropdown
											:class="{
												'p-invalid': v$.supplier.$error,
											}"
											v-model="supplier"
											:company="company ? company : null"
											:userId="userId"
											:key="supplierReload"
											@item-select="getSupplier($event)"
											forceSelection
										/>
										<!-- <SupplierDropdown
                      v-model="supplier"
                      :company="company ? company : null"
                      :key="supplierReload"
                      @item-select="getSupplier($event)"
                      forceSelection
                    /> -->
									</span>
									<div v-if="v$.supplier.$error" class="mt-1">
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.supplier.$errors[0].$message
											}}
										</small>
									</div>
								</div>
							</div>

							<div class="col-12 mb-2">
								<span class="p-float-label">
									<InputText
										:class="{
											'p-invalid':
												v$.invoiceNo.$error ||
												errorExisting,
										}"
										id="invoiceNo"
										v-model="invoiceNo"
									/>
									<label for="invoiceNo"
										>Invoice Number</label
									>
								</span>
								<div v-if="v$.invoiceNo.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.invoiceNo.$errors[0].$message }}
									</small>
								</div>
							</div>
							<div class="mb-2 sm:col-12 md:col-12 lg:col-6">
								<span class="p-float-label">
									<Calendar
										:class="{
											'p-invalid': v$.invoiceDate.$error,
										}"
										id="invoiceDate"
										v-model="invoiceDate"
										dateFormat="d-M-yy"
										:showIcon="true"
									/>
									<label for="invoiceDate"
										>Invoice Date</label
									>
								</span>
								<div v-if="v$.invoiceDate.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.invoiceDate.$errors[0].$message }}
									</small>
								</div>
							</div>
							<div class="mb-2 sm:col-12 md:col-12 lg:col-6">
								<span class="p-float-label">
									<Calendar
										id="glDate"
										v-model="glDate"
										dateFormat="d-M-yy"
										:showIcon="true"
									/>
									<label for="glDate">GL Date</label>
								</span>
							</div>
							<div class="col-12 mb-2">
								<span class="p-float-label">
									<Textarea
										id="description"
										rows="5"
										v-model="description"
										:maxlength="230"
									/>
									<label for="description">Description</label>
								</span>
							</div>

							<div class="mb-2 sm:col-12 md:col-12 lg:col-6">
								<span class="p-float-label">
									<InputText
										:class="{
											'p-invalid': v$.taxInvoiceNo.$error,
										}"
										id="taxInvoiceNo"
										v-model="taxInvoiceNo"
										@change="handleChangeTax($event)"
									/>
									<label for="taxInvoiceNo"
										>Tax Invoice No</label
									>
								</span>

								<div v-if="v$.taxInvoiceNo.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{
											v$.taxInvoiceNo.$errors[0].$message
										}}
									</small>
								</div>
							</div>

							<div class="mb-2 sm:col-12 md:col-12 lg:col-6">
								<span class="p-float-label">
									<Calendar
										:class="{
											'p-invalid':
												v$.taxInvoiceDate.$error,
										}"
										id="taxInvoiceDate"
										v-model="taxInvoiceDate"
										dateFormat="d-M-yy"
										:showIcon="true"
									/>
									<label for="taxInvoiceDate"
										>Tax Invoice Date</label
									>
								</span>

								<div
									v-if="v$.taxInvoiceDate.$error"
									class="mt-1"
								>
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{
											v$.taxInvoiceDate.$errors[0]
												.$message
										}}
									</small>
								</div>
							</div>

							<div class="col-12 mb-2">
								<span class="p-float-label mb-3">
									<label for="FileUpload">Attachment</label>
								</span>
								<FileUpload
									:class="{
										'p-invalid': v$.file.$error,
									}"
									class="w-full"
									ref="fileUpload"
									mode="advanced"
									chooseLabel="Upload Tax Attachment"
									fileLimit="1"
									accept="application/pdf"
									:maxFileSize="5000000"
									:showUploadButton="false"
									:showCancelButton="false"
									:customUpload="true"
									@select="onSelectFile"
									@remove="onRemoveFile"
								/>
								<div v-if="v$.file.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.file.$errors[0].$message }}
									</small>
								</div>
							</div>
						</div>

						<!-- kolom 2 -->
						<div
							class="md:col-6 lg:col-6 xs:col-12 sm:col-12 grid p-0 pb-0 mb-6 ml-1"
						>
							<div class="grid col-12">
								<div class="col-6 col-auto ml-auto">
									<p>Status</p>
								</div>
								<div class="col-6 col-auto ml-auto">
									<p style="text-align: end">
										{{ invoiceStatus }}
									</p>
								</div>
							</div>
							<div class="grid col-12">
								<div class="col-6">
									<p>Invoice Amount</p>
								</div>
								<div class="col-6">
									<p style="text-align: end">
										{{
											new Intl.NumberFormat('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}).format(summary.totalLine)
										}}
									</p>
								</div>
							</div>
							<div class="grid col-12">
								<div class="col-6">
									<p>Tax Amount</p>
								</div>
								<div class="col-6">
									<p style="text-align: end">
										{{
											new Intl.NumberFormat('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}).format(summary.totalPpn)
										}}
									</p>
								</div>
							</div>
							<div class="grid col-12">
								<div class="col-6">
									<p>Total</p>
								</div>
								<div class="col-6">
									<p style="text-align: end">
										{{
											new Intl.NumberFormat('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}).format(summary.totalInvoice)
										}}
									</p>
								</div>
							</div>
							<div class="col-12">
								<span class="p-float-label">
									<CurrenciesDropdown
										:class="{
											'p-invalid': v$.currency.$error,
										}"
										id="currency"
										v-model="currency"
										:company="company"
										:key="curReload"
									/>
									<label for="currency"
										>Select Currency</label
									>
								</span>
								<div v-if="v$.currency.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.currency.$errors[0].$message }}
									</small>
								</div>
							</div>
							<div class="grid col-12">
								<div class="col-6">
									<span class="p-float-label">
										<RemitToBankDropdown
											:class="{
												'p-invalid': v$.billBank.$error,
											}"
											id="remitBank"
											v-model="billBank"
											:company="company"
											:supplier="
												supplier ? supplier : null
											"
											:vendor_id="
												vendorId ? vendorId : null
											"
											:key="remitReload"
										/>
										<label for="remitBank"
											>Remit to Bank</label
										>
									</span>
									<div v-if="v$.billBank.$error" class="mt-1">
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.billBank.$errors[0].$message
											}}
										</small>
									</div>
								</div>
								<div class="col-6">
									<span class="p-float-label">
										<PaymentMethodsDropdown
											:class="{
												'p-invalid':
													v$.paymentMethod.$error,
											}"
											id="paymentMethod"
											v-model="paymentMethod"
											:company="company ? company : null"
											:supplier="
												supplier ? supplier : null
											"
											:vendor_id="
												vendorId ? vendorId : null
											"
											:key="payReload"
										/>
										<label for="paymentMethod"
											>Payment Method</label
										>
									</span>
									<div
										v-if="v$.paymentMethod.$error"
										class="mt-1"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.paymentMethod.$errors[0]
													.$message
											}}
										</small>
									</div>
								</div>
							</div>
							<div class="grid col-12 mb-2">
								<div class="col-6">
									<span class="p-float-label">
										<TermsDropdown
											:class="{
												'p-invalid': v$.term.$error,
											}"
											id="term"
											v-model="term"
											:company="company"
											:supplier="
												supplier ? supplier : null
											"
											:key="termReload"
										/>
										<label for="term">Select a Term</label>
									</span>
									<div v-if="v$.term.$error" class="mt-1">
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{ v$.term.$errors[0].$message }}
										</small>
									</div>
								</div>
								
							</div>
				
							<div class="col-9 mb-2">
								<span class="p-float-label">
									<InputText
										id="refNum"
										v-model="skNumber"
										:disabled="true"
									/>
									<label for="refNum">Reference Number</label>
								</span>
							</div>
							<div class="col-3 mb-2">
								<Button
									:disabled="!vendorId"
									label="Find SK"
									class="p-button-success"
									@click="displaySKModal = true"
								/>
							</div>
							<div class="col-12 mb-2">
								<span class="p-float-label">
									<CostCenterDropdown
										:class="{
											'p-invalid': v$.costCenter.$error,
										}"
										id="costCenter"
										v-model="costCenter"
										:company="company"
										:key="ccReload"
										@item-select="getCostCenter($event)"
									/>
									<label for="costCenter">Cost Center</label>
								</span>
								<div v-if="v$.costCenter.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.costCenter.$errors[0].$message }}
									</small>
								</div>
							</div>
							<div v-if="userId == 50103" class="col-12 mb-2">
								<span class="p-float-label">
									<CCOwnerDropdown
										:class="{
											'p-invalid': v$.costCenter.$error,
										}"
										id="costCenter"
										v-model="ccOwner"
										:company="costCenter"
										:key="ccOwnerReload"
									/>
									<label for="ccOwner">Select CC Owner</label>
								</span>
								<div v-if="v$.ccOwner.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.ccOwner.$errors[0].$message }}
									</small>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 grid p-0 pb-0">
						<div class="col-12 mb-2">
							<span class="p-float-label">
								<InputText id="dummy" hidden="true" />
								<label for="dummy">Line</label>
							</span>
						</div>
						<!-- detail category -->
						<div class="col-4 mb-2">
							<!-- <DetailCategoryDropdown v-model="category" /> -->
							<span class="p-float-label">
								<AutoComplete
									id="detailCategory"
									:suggestions="filteredCategory"
									:dropdown="true"
									:disabled="supplier === null"
									field="category_detail"
									forceSelection
									v-model="category"
									@complete="searchCategory($event)"
								/>
								<label for="detailCategory"
									>Detail Category</label
								>
							</span>
						</div>
						<div class="col-1 mb-2 ml-auto">
							<Button
								label="Add Line"
								class="p-button-success"
								@click="handleAddCategory"
							/>
						</div>
						<!-- Category table -->
						<div class="col-12 mb-2">
							<DataTable
								:value="selectedCategory"
								responsiveLayout="scroll"
							>
								<Column header="Description">
									<template #body="{ data, index }">
										<InputText
											:disabled="isForwarder"
											type="text"
											style="color: #000 !important"
											v-model="data.description"
											:class="{
												'p-invalid':
													v$.selectedCategory
														.$error &&
													v$.selectedCategory
														.$errors[0].$response
														.$data[index]
														.description.$error,
											}"
										/>

										<div
											v-if="
												v$.selectedCategory.$error &&
												v$.selectedCategory.$errors[0]
													.$response.$data[index]
													.description.$error
											"
											class="mt-1"
										>
											<small
												class="p-error"
												style="font-size: 12px"
											>
												{{
													v$.selectedCategory
														.$error &&
													v$.selectedCategory
														.$errors[0].$response
														.$errors[index]
														.description[0].$message
												}}
											</small>
										</div>
									</template>
								</Column>
								<Column
									header="Amount"
									headerStyle="max-width: 10rem"
								>
									<template #body="{ data, index }">
										<InputNumber
											:min="0"
											mode="decimal"
											locale="de-DE"
											:minFractionDigits="2"
											:maxFractionDigits="2"
											v-model="data.amount"
											:class="{
												'p-invalid':
													v$.selectedCategory
														.$error &&
													v$.selectedCategory
														.$errors[0].$response
														.$data[index].amount
														.$error,
											}"
										/>

										<div
											v-if="
												v$.selectedCategory.$error &&
												v$.selectedCategory.$errors[0]
													.$response.$data[index]
													.amount.$error
											"
											class="mt-1"
										>
											<small
												class="p-error"
												style="font-size: 12px"
											>
												{{
													v$.selectedCategory
														.$error &&
													v$.selectedCategory
														.$errors[0].$response
														.$errors[index]
														.amount[0].$message
												}}
											</small>
										</div>
									</template>
								</Column>
								<Column
									header="VAT"
									headerStyle="max-width: 8rem"
								>
									<!-- <template #body="{ data }">
                    <Dropdown
                      :options="vatRates"
                      optionLabel="vat_code"
                      placeholder="Select VAT"
                      v-model="data.vat"
                    />
                  </template> -->
									<template #body="slotProps">
										<Dropdown
											:editable="true"
											v-model="slotProps.data.vat_code"
											@change="
												onChangeVAT(
													$event,
													slotProps.index
												)
											"
											:options="getVAT"
											optionLabel="vat_code"
											optionValue="tax_rate_id"
											style="width: 100%"
											placeholder="- No Select -"
											forceSelection
										/>
									</template>
								</Column>
								<Column
									header="WHT"
									headerStyle="max-width: 8rem"
								>
									<!-- <template #body="{ data }">
                    <Dropdown
                      :options="vatRates"
                      optionLabel="vat_code"
                      placeholder="Select VAT"
                      v-model="data.vat"
                    />
                  </template> -->
									<template #body="slotProps">
										<Dropdown
											:editable="true"
											v-model="slotProps.data.wht_code"
											@change="
												onChangeWHT(
													$event,
													slotProps.index
												)
											"
											:options="getWHT"
											optionLabel="wht_code"
											optionValue="wht_code_id"
											style="width: 100%"
											placeholder="- No Select -"
											forceSelection
										/>
									</template>
								</Column>
								<Column header="Account">
									<template #body="{ data, index }">
										<AutoComplete
											:suggestions="filteredCoa"
											@complete="searchAccount($event)"
											:dropdown="true"
											field="account"
											placeholder="Account"
											v-model="data.account"
											style="width: 100%"
											@item-select="
												onChangeCOALine($event, index)
											"
											forceSelection
										>
											<!-- <template #item="slotProp">
                        {{ slotProp.item.CONCATENATED_SEGMENTS }}
                      </template> -->
										</AutoComplete>
									</template>
								</Column>
								<Column header="Ref Number">
									<template #body="{ data, index }">
										<div class="p-inputgroup">
											<InputText
												:disabled="true"
												placeholder="Ref Number"
												v-model="
													data.travelOrder
														.travelOrderNo
												"
											/>
											<Button
												icon="pi pi-search"
												class="p-button-secondary"
												@click="openTOModal(index)"
											/>
										</div>
									</template>
								</Column>
								<Column>
									<template #body="{ index }">
										<Button
											icon="pi pi-trash"
											class="p-button-rounded p-button-text"
											@click="handleDeleteCategory(index)"
										/>
									</template>
								</Column>

								<!-- Empty state -->
								<template #empty>
									<div>No item.</div>
								</template>
							</DataTable>
						</div>
					</div>

					<!-- Reimbursement section -->
					<div class="col-12 mb-6" v-if="showReimburseTable">
						<div
							class="flex align-items-center justify-content-between mb-3"
						>
							<p class="text-2xl font-bold mb-0">Reimburse</p>
							<Button
								type="button"
								class="p-button-success w-auto"
								@click="handleAddReimbursement"
							>
								<i class="pi pi-plus" />
								<span class="uppercase font-bold ml-2"
									>Add Line</span
								>
							</Button>
						</div>

						<!-- Reimburse table -->
						<DataTable
							:value="reimburseItems"
							responsiveLayout="scroll"
						>
							<Column header="Vendor">
								<template #body="{ data, index }">
									<AutoComplete
										v-model="data.vendor"
										:dropdown="true"
										forceSelection
										placeholder="Vendor"
										field="vendor_name"
										:suggestions="filteredVendors"
										@complete="searchVendors($event)"
										@item-select="
											onChangeVendor($event, index)
										"
									/>
								</template>
							</Column>
							<Column header="No Faktur Pajak">
								<!-- <template #body="{ data, index }"> -->
								<template #body="{ data, index }">
									<InputText
										v-model="data.taxNo"
										placeholder="Tax Number"
										:class="{
											'p-invalid':
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$data[index]
													.taxNo.$error,
										}"
									/>

									<div
										v-if="
											v$.reimburseItems.$error &&
											v$.reimburseItems.$errors[0]
												.$response.$data[index].taxNo
												.$error
										"
										class="mt-1"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$errors[index]
													.taxNo[0].$message
											}}
										</small>
									</div>
								</template>
							</Column>
							<Column header="Tanggal Faktur Pajak">
								<!-- <template #body="{ data, index }"> -->
								<template #body="{ data, index }">
									<Calendar
										v-model="data.taxDate"
										class="line-calendar"
										dateFormat="d-M-yy"
										:showIcon="true"
										placeholder="Select Date"
										:class="{
											'p-invalid':
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$data[index]
													.taxDate.$error,
										}"
									/>

									<div
										v-if="
											v$.reimburseItems.$error &&
											v$.reimburseItems.$errors[0]
												.$response.$data[index].taxDate
												.$error
										"
										class="mt-1"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$errors[index]
													.taxDate[0].$message
											}}
										</small>
									</div>
								</template>
							</Column>
							<Column header="Nilai Pajak">
								<!-- <template #body="{ data, index }"> -->
								<template #body="{ data, index }">
									<InputNumber
										v-model="data.taxAmount"
										class="col-number"
										mode="decimal"
										locale="en-US"
										:minFractionDigits="2"
										:maxFractionDigits="2"
										:min="0"
										:class="{
											'p-invalid':
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$data[index]
													.taxAmount.$error,
										}"
									/>

									<div
										v-if="
											v$.reimburseItems.$error &&
											v$.reimburseItems.$errors[0]
												.$response.$data[index]
												.taxAmount.$error
										"
										class="mt-1"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$errors[index]
													.taxAmount[0].$message
											}}
										</small>
									</div>
								</template>
							</Column>
							<Column header="Account">
								<template #body="{ data, index }">
									<AutoComplete
										:suggestions="filteredCoa"
										@complete="searchAccount($event)"
										:dropdown="true"
										field="account"
										placeholder="Account"
										v-model="data.account"
										style="width: 100%"
										@item-select="
											onChangeCOAReimburstment(
												$event,
												index
											)
										"
										forceSelection
									>
										<!-- <template #item="slotProp">
                        {{ slotProp.item.CONCATENATED_SEGMENTS }}
                      </template> -->
									</AutoComplete>
								</template>
							</Column>
							<Column header="Group">
								<!-- <template #body="{ data, index }"> -->
								<template #body="{ data, index }">
									<AutoComplete
										:class="{
											'p-invalid':
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$data[index]
													.group_name.$error,
										}"
										v-model="data.group_name"
										:suggestions="filteredGroup"
										placeholder="Group"
										@complete="searchGroup($event)"
										@item-select="
											getGroupValue($event, index)
										"
										:dropdown="true"
										field="group_name"
										forceSelection
									>
									</AutoComplete>

									<div
										v-if="
											v$.reimburseItems.$error &&
											v$.reimburseItems.$errors[0]
												.$response.$data[index]
												.group_name.$error
										"
										class="mt-1"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$errors[index]
													.group_name[0].$message
											}}
										</small>
									</div>
								</template>
							</Column>
							<Column>
								<template #body="{ index }">
									<Button
										icon="pi pi-trash"
										class="p-button-rounded p-button-text"
										@click="handleDeleteReimburse(index)"
									/>
								</template>
							</Column>

							<!-- Empty state -->
							<template #empty>
								<div>No item.</div>
							</template>
						</DataTable>
					</div>

					<div class="grid col-12 p-0 pb-0">
						<div class="lg:col-3 sm:col-12 md:col-12">
							<Button
								label="Save"
								class="p-button-success"
								@click="save()"
								:disabled="disabledButton"
							/>
						</div>
						<div class="lg:col-3 sm:col-12 md:col-12">
							<Button
								label="Cancel"
								class="p-button-secondary"
								@click="cancel()"
							/>
						</div>
					</div>
					<Dialog
						header="Select SK"
						:draggable="false"
						:breakpoints="{ '960px': '75vw', '640px': '100vw' }"
						:style="{ width: '25vw', overflow: 'hidden' }"
						:modal="true"
						:dismissableMask="true"
						v-model:visible="displaySKModal"
					>
						<div class="pt-4">
							<FindSKModal
								:dbInstance="company.db_instance"
								:vendorId="supplier.vendor_id"
								@on-close="displaySKModal = false"
								@on-save="addSKNumber"
							/>
						</div>
					</Dialog>
					<Dialog
						header=" "
						:draggable="false"
						:breakpoints="{ '960px': '75vw', '640px': '100vw' }"
						:style="{ width: '75vw', overflow: 'hidden' }"
						:modal="true"
						:dismissableMask="true"
						v-model:visible="displayTOModal"
					>
						<div class="pt-3">
							<FindTOModal
								:supplierName="supplier.vendor_name"
								@on-close="closeTOModal"
								@on-add="handleAddTO"
							/>
						</div>
					</Dialog>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import CompaniesDropdown from '../components/CompaniesDropdown.vue';
// import SupplierDropdown from "../components/SupplierDropdown";
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import AuthService from '@/service/AuthService';
import LovService from '@/service/LovService';
// import PoFulfillment from '@/service/PoFulfillment';
import {filterAuthByVendorname} from "@/helper/filterAuthByVendorname"
import SimpleCrypto from 'simple-crypto-js';
// import { required } from "@vuelidate/validators";
// import DetailCategoryDropdown from "@/components/invoicepoproxy/components/DetailCategoryDropdown";
import RemitToBankDropdown from '@/components/invoicepoproxy/components/RemitToBankDropdown';
import PaymentMethodsDropdown from '@/components/invoicepoproxy/components/PaymentMethodsDropdown';
import TermsDropdown from '@/components/components/TermsDropdown';
import CurrenciesDropdown from '@/components/components/CurrenciesDropdown';
import CostCenterDropdown from '@/components/invoicepoproxy/components/CostCenterDropdown';
import CCOwnerDropdown from './components/CCOwnerDropdown.vue'
import FindSKModal from '../components/FindSKModal.vue';
import FindTOModal from '../components/FindTOModal.vue';
import ProgressModal from '../components/ProgressModal.vue';
import {
	getCategoryVendorService,
	getAvailableCategoryDetails,
	getListCoa,
	getVATList,
	getWHTList,
	getListGroup,
	getAllSupplier,
	saveInvoice,
} from '../../service/InvoiceProxyNonPOServices';

import { useToast } from 'primevue/usetoast';
import SupplierPICDropdown from './components/SupplierPICDropdown.vue';

const route = useRoute();
const router = useRouter();
const company = ref();
const companyReload = ref([]);
const userInfo = ref();
const supplier = ref(null);
const supplierReload = ref(0);
const invoiceNo = ref();
const invoiceDate = ref();
const glDate = ref();
const description = ref();
const taxInvoiceNo = ref();
const taxInvoiceDate = ref();
const fileUpload = ref();
const file = ref();
const errorExisting = ref();
const category = ref();
const invoiceStatus = ref('DRAFT');
// const invoiceAmount = ref();
// const taxAmount = ref();
// const totalTax = ref();
const currency = ref();
const billBank = ref();
const paymentMethod = ref();
const term = ref();
const ccOwner = ref();
// const referenceNumber = ref();
const costCenter = ref();
const curReload = ref(0);
const remitReload = ref(0);
const payReload = ref(0);
const termReload = ref(0);
const ccReload = ref(0);
const ccOwnerReload = ref(0);
const displaySKModal = ref(false);
const skNumber = ref();
const isWarningShown = ref();
// const isForwarder = ref();
const selectedCategory = ref([]);
// const selectedCategoryHeader = ref([]);
const filteredCategory = ref(null);
const categoryHeader = ref(null);
const categoryDetail = ref(null);
const vendorId = ref();
const vendorSiteId = ref();

const reimburseItems = ref([]);

const getCOA = ref([]);
const filteredCoa = ref([]);
// const invoices = ref([]);

const getVAT = ref([]);
const getWHT = ref([]);

const getVendors = ref([]);
const filteredVendors = ref([]);

const group = ref();
const filteredGroup = ref([]);

const displayTOModal = ref(false);
const travelOrderIndex = ref(null);

const userId = ref();

const toast = useToast();

const progress = ref(false);
const disabledButton = ref(false);
const breadcrumbHome = { icon: 'pi pi-home', to: '/dashboard' };
const breadcrumbItems = [
	{
		label: 'Create Invoice Proxy',
		to: route.fullPath,
	},
];



watch(() => companyReload.value++);

watch(() => ccOwner.value, () =>{
	console.log(ccOwner.value, "VALUE CC OWNER")
})

watch(() => costCenter.value, () => {
	console.log(costCenter.value, "COST CENTER VALUE")	
})

watch(
	() => company.value,
	() => {
		supplier.value = null;
		supplierReload.value++;
		curReload.value++;
		// remitReload.value++;
		ccReload.value++;
	}
);

watch(
	() => vendorId.value,
	async () => {
		payReload.value++;
		termReload.value++;
		remitReload.value++;
		await getCategoryVendor();
	}
);

watch(
	() => invoiceDate.value,
	async () => {
		await getDropdownVat();
		await getDropdownWHT();
	}
);

const isForwarder = computed(
	() =>
		categoryHeader.value &&
		categoryHeader.value.some((item) =>
			item.category_name.toLowerCase().includes('forwarder')
		)
);

// Custom validator
const cannotZero = (value) => value > 0;

// Vuelidate rules
const validationRules = computed(() => {
	const rules = {
		company: { required },
		supplier: { required },
		invoiceNo: { required },
		invoiceDate: { required },
		description: { required },
		taxInvoiceNo: { required },
		taxInvoiceDate: { required },
		currency: { required },
		billBank: { required },
		paymentMethod: { required },
		term: { required },
		costCenter: { required },
		file: { required },
		ccOwner: { required: requiredIf(sessionStorage.getItem("idUser") == 50103) }, 
		selectedCategory: {
			$each: helpers.forEach({
				description: { required },
				amount: {
					required,
					cannotZero: helpers.withMessage(
						'The amount value must be greater than zero',
						cannotZero
					),
				},
			}),
		},
		reimburseItems: {
			$each: helpers.forEach({
				taxNo: { required },
				taxDate: { required },
				taxAmount: {
					required,
					cannotZero: helpers.withMessage(
						'The amount value must be greater than zero',
						cannotZero
					),
				},
				group_name: { required },
			}),
		},
	};

	if (isForwarder.value) {
		rules.skNumber = { required };
	}

	return rules;
});

const v$ = useVuelidate(validationRules, {
	company,
	supplier,
	invoiceNo,
	invoiceDate,
	description,
	taxInvoiceNo,
	taxInvoiceDate,
	skNumber,
	currency,
	billBank,
	paymentMethod,
	term,
	costCenter,
	file,
	selectedCategory,
	reimburseItems,
	ccOwner
});

AuthService.whoAmi().then(async (response) => {
	if (response.status === 200) {
		userInfo.value = response.data.data;
		userId.value = response.data.data.id;
		if (userInfo.value.leveltenant === 2) {
			//get company
			const respCbN = await LovService.getCompanyByName(userCompany());
			company.value = respCbN.data.data[0];
			//get supplier
			const param = {
				db_instance: company.value.db_instance,
				org_id: company.value.value,
			};
			
			const checkAuth = await filterAuthByVendorname({
				info_id: userInfo.value.id,
				dbInstance: param.db_instance,
				value: param.org_id
			});

			if(checkAuth.supplier === null) router.push("/dashboard");

			supplier.value = checkAuth.supplier;
		} else {
			const respCbN = await LovService.getCompanyByName(userCompany());
			company.value = respCbN.data.data[0];
		}
		// reload.value++;
	}
});

// Select file attachment
const onSelectFile = (e) => {
	file.value = e.files[0];
};

// Remove file attachment
const onRemoveFile = (e) => {
	if (!e.files.length) {
		file.value = null;
	}
};

const userCompany = () => {
	const secretKey = 'some-unique-key';
	const simpleCrypto = new SimpleCrypto(secretKey);
	return simpleCrypto.decrypt(sessionStorage.getItem('dropdownCompany'));
};

const getSupplier = async (event) => {
	supplier.value = event.value;
	vendorId.value = event.value.vendor_id;
	vendorSiteId.value = event.value.vendor_site_id;
	term.value = null;
	billBank.value = null;
	paymentMethod.value = null;
	skNumber.value = null;
	selectedCategory.value = [];
	filteredCategory.value = null;
	category.value = null;
	await getGroup();
	await getListAllSupplier();
};

const getCompanySelect = (event) => {
	company.value = event.value;
	supplier.value = null;
	vendorId.value = null;
	term.value = null;
	billBank.value = null;
	paymentMethod.value = null;
	skNumber.value = null;
	selectedCategory.value = [];
	filteredCategory.value = null;
	category.value = null;
};

const summary = computed(() => {
	const totalLine =
		(selectedCategory.value.length &&
			selectedCategory.value.reduce((accumulator, obj) => {
				return accumulator + obj.amount;
			}, 0)) ||
		0;

	const totalPpn =
		(selectedCategory.value.length &&
			selectedCategory.value
				.map((el) => {
					return el.vat && el.vat.percentage_rate
						? (el.vat.percentage_rate / 100) * el.amount
						: 0;
				})
				.reduce((prevValue, currValue) => prevValue + currValue, 0)) ||
		0;

	const totalInvoice = totalLine + totalPpn;

	return {
		totalLine,
		totalPpn,
		totalInvoice,
	};
});

const selectedCategoryHeader = computed(() => {
	// Return null if no selected category detail
	if (!selectedCategory.value.length) {
		return null;
	}

	// Find cat header based on selected cat detail
	const catId =
		selectedCategory.value.length && selectedCategory.value[0].category_id;

	return categoryHeader.value.find((item) => item.category_id === catId);
});

const addSKNumber = (data) => {
	skNumber.value = data.sk_number;
	displaySKModal.value = false;
	isWarningShown.value = false;
	reimburseItems.value = [];
	// Add PO Number to description field and reset selected category detail if user is forwarder
	if (isForwarder.value) {
		description.value = data.po_number;
		selectedCategory.value = [];
		category.value = null;
	}
};

const searchCategory = async (event) => {
	isWarningShown.value = false;

	if (isForwarder.value) {
		// Check if SK Number is selected
		if (!skNumber.value) {
			isWarningShown.value = true;
			filteredCategory.value = [];
			return;
		}

		// Fetch category detail based on selected SK Number
		const payload = {
			categoryId: categoryHeader.value.map((item) => item.category_id),
			dbInstance: company.value.db_instance,
			orgId: company.value.value,
			skNumber: skNumber.value,
			vendorId: supplier.value.vendor_id,
		};

		const res = await getAvailableCategoryDetails(payload);
		const categories = res.data.data;

		if (!event.query.trim().length) {
			filteredCategory.value = [...categories];
		} else {
			filteredCategory.value = categories.filter((category) => {
				return category.category_detail
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
		}
	} else {
		if (!event.query.trim().length) {
			filteredCategory.value = [...categoryDetail.value];
		} else {
			filteredCategory.value = categoryDetail.value.filter((category) => {
				return category.category_detail
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
		}
	}
};

// Delete line category
const handleDeleteCategory = (deletedIndex) => {
	toast.removeAllGroups();

	selectedCategory.value = selectedCategory.value.filter(
		(el, index) => index !== deletedIndex
	);

	// Check if reimburse category is not selected
	const isReimburseExist = selectedCategory.value.some((cat) =>
		cat.description.toLowerCase().includes('reimburstment')
	);
	if (!isReimburseExist) {
		// Reset reimburse items array
		reimburseItems.value = [];
	}
};

const handleAddCategory = () => {
	toast.removeAllGroups();

	// Check if no selected category
	if (!category.value) {
		return;
	}

	// Check if category detail is from different category id
	if (selectedCategoryHeader.value) {
		if (
			category.value.category_id !==
			selectedCategoryHeader.value.category_id
		) {
			category.value = null;
			toast.add({
				severity: 'warn',
				summary: 'Cannot add detail',
				detail: 'Please add category detail from the same category ID',
				life: null,
			});
			return;
		}
	}

	// Check if forwarder
	if (isForwarder.value) {
		const isAdded = selectedCategory.value.some(
			(cat) =>
				cat.category_detail_id === category.value.category_detail_id
		);
		if (isAdded) {
			category.value = null;
			return;
		}

		selectedCategory.value.push({
			...category.value,
			description: category.value.category_detail,
			amount: 0,
			vat: null,
			coa_id: category.value.account_id,
			account: category.value.account,
			travelOrder: {
				travelOrderNo: null,
				guestName: null,
				startDate: null,
				endDate: null,
			},
		});
	} else {
		selectedCategory.value.push({
			...category.value,
			description: category.value.category_detail,
			amount: 0,
			vat: null,
			coa_id: category.value.account_id,
			account: category.value.account,
			travelOrder: {
				travelOrderNo: null,
				guestName: null,
				startDate: null,
				endDate: null,
			},
		});
	}

	category.value = null;
};

// Get line categories
const getCategoryVendor = async () => {
	const payload = {
		dbInstance: company.value.db_instance,
		orgId: company.value.value,
		vendorId: supplier.value.vendor_id,
	};
	try {
		const res = await getCategoryVendorService(payload);

		if (res.status === 200) {
			categoryHeader.value = res.data.data.categoryHeader;

			if (!isForwarder.value) {
				categoryDetail.value = res.data.data.categoryDetail;
			} else {
				categoryDetail.value = [];
			}
		} else {
			categoryHeader.value = [];
			categoryDetail.value = [];
		}
	} catch (error) {
		// throw Error(error);
		console.log(error);
		categoryDetail.value = [];
	}
};

const getCostCenter = (event) => {
	if (company.value) {
		costCenter.value = event.value;
		ccOwnerReload.value++;
		ccOwner.value = null;
		getDropdownCOA();
	}
};

console.log(ccOwner.value, "CC OWNER")

const getDropdownCOA = async () => {
	getCOA.value = [];
	const result = await getListCoa(
		company.value.value,
		company.value.db_instance,
		costCenter.value.segment4
	);
	getCOA.value = result.data.data;
};

const getDropdownVat = async () => {
	if (invoiceDate.value) {
		const paramRet = 'RATE';
		const payload = {
			dbInstance: company.value.db_instance,
			param: paramRet,
			invoiceDate: invoiceDate.value,
		};
		const result = await getVATList(payload);
		getVAT.value = result.data;
		if (selectedCategory.value.length > 0) {
			for (const index in selectedCategory.value) {
				let isExistVat = false;
				for (const vat of getVAT.value) {
					if (
						selectedCategory.value[index].vat_code === vat.vat_code
					) {
						isExistVat = true;
					}
				}

				if (!isExistVat) {
					selectedCategory.value[index].vat_code = null;
				}
			}
		}

		getVAT.value.push({
			value: null,
			tax_rate_id: null,
			vat_code: '- No Select -',
			percentage_rate: null,
		});
	}
};

const onChangeVAT = async (event, index) => {
	if (
		selectedCategory.value[index].amount === undefined ||
		selectedCategory.value[index].amount === null ||
		selectedCategory.value[index].amount === 0
	) {
		selectedCategory.value[index].vat_code = null;
		toast.add({
			severity: 'error',
			summary: 'Empty Amount',
			detail: 'Empty Amount',
			life: 3000,
		});
	} else {
		let vatData = null;
		for (const vat of getVAT.value) {
			if (vat.tax_rate_id == event.value) {
				vatData = vat;
			}
		}
		if (vatData) {
			selectedCategory.value[index].percentage_rate =
				vatData.percentage_rate;
			selectedCategory.value[index].vat_code = vatData.vat_code;
			selectedCategory.value[index].tax_rate_id = vatData.tax_rate_id;
			selectedCategory.value[index].vat = vatData;
		} else {
			toast.add({
				severity: 'error',
				summary: 'Data not Found',
				detail: 'Data not Found',
				life: 3000,
			});
		}
	}
};

const getDropdownWHT = async () => {
	const result = await getWHTList(
		company.value.db_instance,
		company.value.value
	);

	getWHT.value = result.data;
	getWHT.value.push({
		value: null,
		wht_code_id: null,
		wht_code: '- No Select -',
	});
};

const onChangeWHT = async (event, index) => {
	if (
		selectedCategory.value[index].amount === undefined ||
		selectedCategory.value[index].amount === null
	) {
		selectedCategory.value[index].wht_code = null;
		toast.add({
			severity: 'error',
			summary: 'Empty Amount',
			detail: 'Empty Amount',
			life: 3000,
		});
	} else {
		let whtData = null;
		for (const wht of getWHT.value) {
			if (wht.wht_code_id == event.value) {
				whtData = wht;
			}
		}
		if (whtData) {
			selectedCategory.value[index].wht_rate = whtData.wht_rate;
			selectedCategory.value[index].wht_code = whtData.wht_code;
			selectedCategory.value[index].wht_code_id = whtData.wht_code_id;
		} else {
			toast.add({
				severity: 'error',
				summary: 'Data not Found',
				detail: 'Data not Found',
				life: 3000,
			});
		}
	}
};

const searchAccount = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredCoa.value = [...getCOA.value];
		} else {
			filteredCoa.value = getCOA.value.filter((coa) => {
				// return coa.ACCOUNT.toLowerCase().match(event.query.toLowerCase());
				return coa.account
					.toLowerCase()
					.includes(event.query.toLowerCase());
			});
		}
	}, 250);
};

const onChangeCOALine = async (event, index) => {
	let coaData = null;
	for (const coa of getCOA.value) {
		if (coa.code_combination_id == event.value.code_combination_id) {
			coaData = coa;
		}
		// if (coa.code_combination_id == event.value) {
		//   coaData = coa;
		// }
	}
	if (coaData) {
		selectedCategory.value[index].account = coaData.account;
		selectedCategory.value[index].code_combination_id =
			coaData.code_combination_id;
		selectedCategory.value[index].coa_id = coaData.code_combination_id;
		// emit("tableList", searchCategory);
	} else {
		toast.add({
			severity: 'error',
			summary: 'Data not Found',
			detail: 'Data not Found',
			life: 3000,
		});
	}
};

const onChangeCOAReimburstment = async (event, index) => {
	let coaData = null;
	for (const coa of getCOA.value) {
		if (coa.code_combination_id == event.value.code_combination_id) {
			coaData = coa;
		}
		// if (coa.code_combination_id == event.value) {
		//   coaData = coa;
		// }
	}
	if (coaData) {
		reimburseItems.value[index].account = coaData.account;
		reimburseItems.value[index].code_combination_id =
			coaData.code_combination_id;
		reimburseItems.value[index].coa_id = coaData.code_combination_id;
		// emit("tableList", searchCategory);
	} else {
		toast.add({
			severity: 'error',
			summary: 'Data not Found',
			detail: 'Data not Found',
			life: 3000,
		});
	}
};

// Open TO Modal
const openTOModal = (index) => {
	displayTOModal.value = true;
	travelOrderIndex.value = index;
};

// Close TO Modal
const closeTOModal = () => {
	displayTOModal.value = false;
	travelOrderIndex.value = null;
};

// Handle add TO
const handleAddTO = (selectedTO) => {
	selectedCategory.value = selectedCategory.value.map((category, index) => {
		if (index === travelOrderIndex.value) {
			category.travelOrder = {
				travelOrderNo: selectedTO.travel_order_no,
				guestName: selectedTO.guest_name,
				startDate: selectedTO.start_date,
				endDate: selectedTO.end_date,
			};
		}

		return category;
	});

	travelOrderIndex.value = null;
	displayTOModal.value = false;
};

// Show reimburse table
const showReimburseTable = computed(() =>
	selectedCategory.value.some((cat) =>
		cat.description.toLowerCase().includes('reimburstment')
	)
);

const handleAddReimbursement = () => {
	const reimburseCategoryData = selectedCategory.value.find((cat) =>
		cat.description.toLowerCase().includes('reimburstment')
	);
	reimburseItems.value = [
		...reimburseItems.value,
		{
			vendor: null,
			taxNo: null,
			taxDate: null,
			account: reimburseCategoryData.account,
			coa_id: reimburseCategoryData.account_id,
			taxAmount: 0,
			group_name: null,
		},
	];
};

// Delete reimbursement item
const handleDeleteReimburse = (deletedIndex) => {
	reimburseItems.value = reimburseItems.value.filter(
		(el, index) => index !== deletedIndex
	);
};

const getGroup = async () => {
	//   const payload = { db_instance: props.company.db_instance };
	//   const data = await ListInvoiceNonPo.getGroupName(payload);
	//   if (data.status === 200) {
	//     group.value = data.data.data.data;
	//   }
	const result = await getListGroup(company.value.db_instance);
	group.value = result.data.data;
};

const getGroupValue = (event, index) => {
	reimburseItems.value[index].group_name = event.value.group_name;
};

const searchGroup = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredGroup.value = [...group.value];
		} else {
			filteredGroup.value = group.value.filter((comp) => {
				return comp.name.toLowerCase().match(event.query.toLowerCase());
			});
		}
	}, 250);
};

const getListAllSupplier = async () => {
	const result = await getAllSupplier(company.value.db_instance);
	getVendors.value = result.data;
};

const onChangeVendor = (event, index) => {
	reimburseItems.value[index].vendor_id = event.value.vendor_id;
};

const handleChangeTax = (event) => {
	const {value} = event.target;
	taxInvoiceNo.value = value.replace(/[a-zA-Z]/g,"").substring(0,20);
}

const searchVendors = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredVendors.value = [...getVendors.value];
		} else {
			filteredVendors.value = getVendors.value.filter((vnd) => {
				return vnd.vendor_name
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
		}
	}, 250);
};

//format dete dexa
const formattingDate = (date, withTime) => {
	const theDate = new Date(date);
	const strDate =
		theDate.getFullYear() +
		'-' +
		(theDate.getMonth() + 1) +
		'-' +
		theDate.getDate();
	const strTime =
		theDate.getHours() +
		':' +
		theDate.getMinutes() +
		':' +
		theDate.getSeconds();
	if (withTime) {
		return `${strDate} ${strTime}`;
	} else {
		return `${strDate}`;
	}
};

const cancel = () => {
	router.push('/dashboard');
};

const save = async () => {
	// Validate required fields
	const isFormValid = await v$.value.$validate();
	if (!isFormValid) {
		const errMessage = 'Please fill all required fields';
		toast.add({
			severity: 'error',
			summary: errMessage,
			life: 3000,
		});
		return;
	}
	const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
	const payload = {
		header: {
			INVOICE_NO: invoiceNo.value,
			INVOICE_DATE: formattingDate(invoiceDate.value),
			REVISION_NUM: 0,
			TAX_NO: taxInvoiceNo.value,
			TAX_DATE: formattingDate(taxInvoiceDate.value),
			DESCRIPTION: description.value,
			CURRENCY_CODE: currency.value.currency_code,
			VENDOR_ID: vendorId.value,
			INVOICE_AMOUNT: summary.value.totalLine,
			TAX_AMOUNT: summary.value.totalPpn,
			TOTAL_AMOUNT: summary.value.totalInvoice,
			ORG_ID: parseInt(company.value.value),
			STATUS: invoiceStatus.value,
			CREATED_BY: userInfo.value.id,
			CREATION_DATE: formattingDate(new Date(), true),
			LAST_UPDATE_DATE: formattingDate(new Date(), true),
			LAST_UPDATED_BY: userInfo.value.id,
			PAYMENT_METHOD_LOOKUP_CODE: paymentMethod.value.payment_method,
			GL_DATE: formattingDate(glDate.value),
			TERMS_ID: term.value.term_id,
			ACCTS_CODE_COMBINATION_ID: selectedCategoryHeader.value.account_id,
			EXTERNAL_BANK_ACCOUNT_ID: billBank.value.ext_bank_account_id,
			INVOICE_TYPE: 'INVOICE PROXY',
			CATEGORY_ID: selectedCategoryHeader.value.category_id,
			MAPING_ID: selectedCategoryHeader.value.maping_id,
			REFERENCE_NUMBER: skNumber.value,
			VENDOR_SITE_ID: vendorSiteId.value,
			COST_CENTER: costCenter.value.segment4,
			DB_INSTANCE: company.value.db_instance,
			CC_OWNER: ccOwner.value ? ccOwner.value.USER_EMAIL : null
		},

		dataLines: [],
		dataReimburstments: [],
	};

	await selectedCategory.value.forEach((item, index) => {
		let data = {
			LINE_NUM: index + 1,
			ITEM_DESCRIPTION: item.description,
			AMOUNT: item.amount,
			ORGANIZATION_ID: company.value.value,
			CREATED_BY: userInfo.value.id,
			CREATION_DATE: formattingDate(new Date(), true),
			LAST_UPDATE_DATE: formattingDate(new Date(), true),
			LAST_UPDATED_BY: userInfo.value.id,
			CURRENCY_CODE: currency.value.currency_code,
			REVISION_NUM: 0,
			VENDOR_ID: vendorId.value,
			VENDOR_SITE_ID: vendorSiteId.value,
			CATEGORY_DETAIL_ID: item.category_detail_id,
			VAT_CODE: item.vat_code !== '- No Select -' ? item.vat_code : null,
			WHT_CODE: item.wht_code !== '- No Select -' ? item.wht_code : null,
			COA_ID: item.coa_id,
			VAT_CODE_ID: item.tax_rate_id,
			WHT_CODE_ID: item.wht_code_id,
			REMARKS: item.travelOrder.travelOrderNo ? `${item.travelOrder.travelOrderNo}#${item.travelOrder.guestName}#${new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(item.travelOrder.startDate))}#${new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(item.travelOrder.endDate))}`: null,
		};
		payload.dataLines.push(data);
	});

	await reimburseItems.value.forEach((element) => {
		let data = {
			VENDOR_ID: element.vendor_id,
			TAX_NO: element.taxNo,
			TAX_DATE: formattingDate(element.taxDate),
			AMOUNT: element.taxAmount,
			COA_ID: element.coa_id,
			ORG_ID: company.value.value,
			CREATED_BY: userInfo.value.id,
			CREATION_DATE: formattingDate(new Date(), true),
			LAST_UPDATED_BY: userInfo.value.id,
			LAST_UPDATE_DATE: formattingDate(new Date(), true),
			GROUP_NAME: element.group_name,
		};
		payload.dataReimburstments.push(data);
	});

	const jsonPayload = JSON.stringify(payload);
	let formData = new FormData();
	formData.append('payload', jsonPayload);
	formData.append('file', file.value);

	try {
		progress.value = true;

		const res = await saveInvoice(formData);

		if (res.status === 201) {
			// progress.value = false;
			invoiceNo.value = res.data.data.invoice_no;
			// status.value = "DRAFT";
			file.value = null;
			fileUpload.value.clear();
			v$.value.$reset();
			disabledButton.value = true;

			progress.value = false;

			toast.add({
				severity: 'success',
				summary: 'Successfully save new invoice',
				life: 3000,
			});

			return;
		}
		progress.value = false;
	} catch (err) {
		console.log(err.message);
		progress.value = false;

		if (err.message.includes('Network Error')) {
			console.log('network error');
			toast.add({
				severity: 'error',
				summary: 'Network Error',
				detail: 'Connection Down. Please check your invoice status on List Invoice Non PO menu.',
			});
			return;
		}

		if (err.response.status === 400 || err.response.status === 403) {
			if (err.response.data.message.includes('exist')) {
				errorExisting.value = true;
			}
			toast.add({
				severity: 'error',
				summary: err.response.data.message,
				life: 3000,
			});
			return;
		}

		toast.add({
			severity: 'error',
			summary: 'Server Error: Unsuccessfully save new invoice',
			life: 3000,
		});
	}
};
</script>
