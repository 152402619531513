<template>
  <AutoComplete
    :suggestions="filteredPaymentMethod"
    @complete="searcSupplierSite($event)"
    :dropdown="true"
    field="PAYMENT_METHOD"
    :placeholder="placeholder"
    v-model="payMethod"
    forceSelection
  >
    <template #item="slotProp">
      {{ slotProp.item.PAYMENT_METHOD }}
    </template>
  </AutoComplete>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect } from "vue";
//   import LovService from "@/service/LovService";
//   import SwithCompany from "@/service/SwithCompany";
// import AuthService from "@/service/AuthService";
import invoiceNonPOservice from "@/service/InvoiceNonPOservice";

// eslint-disable-next-line no-undef
const filteredPaymentMethod = ref([]);
const paymentMethods = ref([]);
const payMethod = ref();
const placeholder = ref("Select a Payment Method");

const emit = defineEmits(["paymentMethod"]);

watchEffect(() => emit("paymentMethod", payMethod));
// eslint-disable-next-line no-undef
const props = defineProps({
  company: {
    type: Object,
  },
  vendorId: {
    type: String,
  },
  paymentMethod: {
    type: String,
  },
});

onMounted(async () => {
  try {
    // const response = await AuthService.whoAmi();
    // const user = response.data.data;
    if (props.company && props.vendorId) {
      const result = await invoiceNonPOservice.getListPaymentMethod(
        props.company.db_instance,
        props.vendorId
      );
      paymentMethods.value = result.data.data;
      if (props.paymentMethod) {
        for (const payMethodData of paymentMethods.value) {
          if (payMethodData.PAYMENT_METHOD == props.paymentMethod) {
            payMethod.value = payMethodData;
          }
        }
      }
    }

    // eslint-disable-next-line no-empty
  } catch (e) {
    throw new Error(e);
  }
});

const searcSupplierSite = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredPaymentMethod.value = [...paymentMethods.value];
    } else {
      filteredPaymentMethod.value = paymentMethods.value.filter((bank) => {
        return bank.PAYMENT_METHOD.toLowerCase().match(
          event.query.toLowerCase()
        );
      });
    }
  }, 250);
};
</script>
