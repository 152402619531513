<template>
	<div class="grid">
		<Toast />

		<!-- Breadcrumb -->
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<!-- Input Search -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Purchasing Information</div>
				<div class="content-body">
					<div class="grid">
						<div class="col-12 mb-3 md:col-6 lg:col-4 lg:mb-5">
							<span class="p-float-label">
								<AutoComplete
									class="w-full"
									v-model="supplier"
									:suggestions="filteredSupplier"
									@complete="searchSupplier($event)"
									:dropdown="true"
									field="vendor_name"
									:disabled="companyValue"
									forceSelection
								>
									<template #item="slotProps">
										<div class="supplier-item">
											<div class="ml-2">
												{{ slotProps.item.vendor_name }}
											</div>
										</div>
									</template>
								</AutoComplete>
								<label for="inputtext">Supplier</label>
							</span>
						</div>
						<div class="lg:col-offset-8"></div>

						<div class="col-12 mb-3 md:col-6 lg:col-4 lg:mb-5">
							<span class="p-float-label">
								<AutoComplete
									id="itemNumber"
									class="w-full"
									v-model="itemNumber"
									:suggestions="filteredItemNumbers"
									@complete="searchItemNumber($event)"
									:dropdown="true"
									field="name"
									forceSelection
								>
									<template #item="slotProps">
										<div class="supplier-item">
											<div class="ml-2">
												{{ slotProps.item.name }}
											</div>
										</div>
									</template>
								</AutoComplete>
								<label for="itemNumber">Item Number</label>
							</span>
						</div>
						<div class="col-12 mb-3 md:col-6 lg:col-4 lg:mb-5">
							<span class="p-float-label">
								<InputText
									id="itemDescription"
									class="w-full"
									type="text"
									v-model="itemDescription"
								/>
								<label for="itemDescription"
									>Item Description</label
								>
							</span>
						</div>
						<div class="col-12 mb-3 md:col-6 lg:col-4 lg:mb-5">
							<Button class="p-button-secondary mr-2">
								<span class="font-bold">Select Item</span>
							</Button>
						</div>

						<div class="col-12 mb-3 md:col-6 lg:col-4 lg:mb-5">
							<span class="p-float-label">
								<InputText
									id="specNumber"
									class="w-full"
									type="text"
									v-model="specNumber"
								/>
								<label for="specNumber">Spec Number</label>
							</span>
						</div>
						<div class="col-12 mb-3 md:col-6 lg:col-4 lg:mb-5">
							<span class="p-float-label">
								<InputText
									id="specVersion"
									class="w-full"
									type="text"
									v-model="specVersion"
								/>
								<label for="specVersion">Spec Version</label>
							</span>
						</div>

						<div class="lg:col-offset-4"></div>
						<div class="col-12 mb-3 md:col-6 lg:col-4 lg:mb-5">
							<span class="p-float-label">
								<Calendar
									id="effectiveDate"
									class="w-full"
									v-model="effectiveDate"
									dateFormat="dd.mm.yy"
									:showIcon="true"
								/>
								<label for="effectiveDate"
									>Effective Date</label
								>
							</span>
						</div>
						<div class="col-12 mb-3 md:col-6 lg:col-4 lg:mb-5">
							<span class="p-float-label">
								<Calendar
									id="uploadDate"
									class="w-full"
									v-model="uploadDate"
									dateFormat="dd.mm.yy"
									:showIcon="true"
								/>
								<label for="uploadDate">Upload Date</label>
							</span>
						</div>
						<div class="lg:col-offset-4"></div>

						<div class="col-12 mb-3 md:col-6 lg:col-4 lg:mb-5">
							<FileUpload
								ref="fileUpload"
								mode="basic"
								chooseLabel="Upload File"
								fileLimit="1"
								:maxFileSize="5000000"
								accept="application/pdf"
								:showUploadButton="false"
								:showCancelButton="false"
								:customUpload="true"
								@select="onSelectFile"
							/>
						</div>
						<div class="md:col-offset-12 lg:col-offset-8"></div>

						<!-- Buttons -->
						<div class="col align-items-center gap-4">
							<Button
								class="w-full justify-content-center p-button-success mb-3 lg:mr-2 lg:mb-0 lg:w-auto"
							>
								<span class="font-bold uppercase"
									>Submit Spec</span
								>
							</Button>
							<Button
								class="w-full justify-content-center p-button-secondary lg:w-auto"
								><span class="font-bold uppercase"
									>Clear</span
								></Button
							>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AddSpecSRC',
	data() {
		return {
			breadcrumbHome: { icon: 'pi pi-home', to: '/' },
			breadcrumbItems: [
				{
					label: 'Spec SRC',
					to: '/mgm/settings/specsrc',
				},
				{
					label: 'Add Spec SRC',
					to: '/mgm/settings/specsrc/add',
				},
			],
		};
	},
};
</script>
