import { createRouter, createWebHashHistory } from 'vue-router';
import HomePage from './components/HomePage.vue';
import NotifDetails from './components/dashboard/NotifList.vue';
import PlannoDetail from './components/dashboard/notifdetails/PlannoDetail.vue';
import PODetail from './components/dashboard/notifdetails/PODetail.vue';
import PODetailPur from './components/dashboard/notifdetails/PODetailPur.vue';
import PlannoDetailPur from './components/dashboard/notifdetails/PlannoDetailPur.vue';
import CapaDetail from './components/dashboard/notifdetails/CapaDetail.vue';
import CapaDetailPur from './components/dashboard/notifdetails/CapaDetailPur.vue';
import ShipmentDetail from './components/dashboard/notifdetails/ShipmentDetail.vue';
// import UserPriveleges from './components/user/UserPriveleges.vue'
import Guard from './app/guard/Guard';
import TestPage from './components/planningorder/PlanningOrder.vue';
import User from './components/user/UserList.vue';
import Group from './components/applicationgroup/ApplicationGroup.vue';
import GroupDetail from './components/applicationgroup/applicationgroupdetail/ApplicationGroupDetail.vue';
import CreatePo from './components/createpo/CreatePo.vue';
import SubmitPo from './components/submitpo/SubmitPo.vue';
import Pofulfillment from './components/pofulfillment/poFulfillment.vue';
import DetailPofulfillment from './components/pofulfillment/detailpofulfillment/DetailPoFulfillment.vue';
import CreateShipmentNotice from './components/createshipmentnotice/CreateShipmentNotice.vue';
import AddCreateShipmentNotice from './components/createshipmentnotice/AddCreateShipmentNotice.vue';
import DetailCreateShipmentNoticeSupp from './components/createshipmentnotice/detailcreateshipmentnotice/DetailCreateShipmentNoticeSupp.vue';
import UploadDocumentCreateShipment from './components/createshipmentnotice/uploaddocument/UploadDocumentCreateShipment.vue';
import ShipmentNotice from './components/shipmentnotice/ShipmentNotice.vue';
import DetailShipmentNoticeSupp from './components/shipmentnotice/detail/DetailShipmentNoticeSupp.vue';
import DetailShipmentNoticePur from './components/shipmentnotice/detail/DetailShipmentNoticePur.vue';
import RegisterOutstandingOrderSupp from './components/registeroutstandingorder/RegisterOutstandingOrder.vue';
import SpecSRC from './components/specsrc/SpecSRC.vue';
import AddSpecSRC from './components/specsrc/AddSpecSRC.vue';
import EditSpecSRC from './components/specsrc/EditSpecSRC.vue';
import OutstandingPoReport from './components/outstandingpo/OutstandingPoReport.vue';
import UpdateCapaPur from './components/capa/UpdateCapaPur.vue';
import UpdateCapaSupp from './components/capa/UpdateCapaSupp.vue';
import ListSpeReport from './components/spereport/ListSpeReport.vue';
import CreateSpeReport from './components/spereport/CreateSpeReport.vue';
import DetailSpeReport from './components/spereport/DetailSpeReport.vue';

import TrackingPOReport from './components/trackingpo/TrackingPOReport';
import Requisition from './components/requisition/Requisition.vue';
import BatchList from './components/batchList/BatchList.vue';
import BatchListDetail from './components/batchList/detailBatchList/DetailBatchList.vue';
import CreateBatch from './components/createbatch/CreateBatch.vue';
// import CapaPage from "./components/capa/CapaFrontPage.vue";
import InvoiceWithPo from './components/searchinvoicewithpo/SearchInvoiceWPO.vue';
import DetailInvoiceWithPo from './components/searchinvoicewithpo/detailsearchinvoicewpo/DetailSearchInvoiceWPO.vue';
import ListInvoicePO from './components/invoicepo/listinvoice/List.vue';
import DetailListInvoicePO from './components/invoicepo/listinvoice/Detail.vue';
import CreateInvoicePO from './components/invoicepo/createinvoice/Create.vue';
import DocumentSetup from './components/documentsetup/DocumentSetup.vue';
import ViewCapa from './components/capa/ViewCapa.vue';
import ChangePassword from './components/changepassword/ChangePassword.vue';
import AddCapa from './components/capa/AddCapa.vue';
import reportInvoicePayment from './components/reportinvoicepayment/ReportInvoicePayment.vue';
import ReportinvoicepaymentSchedule from './components/reportinvoicepayment/ReportInvoicePaymentSchedule';
import CreateInvoiceNonPO from './components/invoicenonpo/createinvoice/Create.vue';
import ListInvoiceNonPO from './components/listinvoicenonpo/ListInvoiceNonPO.vue';
import DetailListInvoiceNonPO from './components/listinvoicenonpo/detaillistinvoicenonpo/DetailListInvoiceNonPo.vue';
import ListInvoiceProxy from './components/listinvoiceproxy/ListInvoiceProxy.vue';
import DetailListInvoiceProxy from './components/listinvoiceproxy/DetailListInvoiceProxy';
import DetailListInvoiceProxySP1 from './components/listinvoiceproxy/DetailListInvoiceProxySP1';
import MasterCategory from './components/mastercategory/MasterCategory';
import DetailMasterCategory from './components/mastercategory/detailmastercategory/DetailMasterCategory';
import MasterVendor from './components/mastervendor/MasterVendorView';
import MasterVendorDetail from './components/mastervendor/MasterVendorDetail';
import SearchInvoiceFinPic from './components/searchinvoicenonpo/SearchInvoiceFinPic.vue';
import DetailInvoiceNonPOFinance from './components/invoicenonpo/searchinvoice/DetailInvoiceNonPOFinance.vue';
import DetailInvoiceNonPOpic from './components/searchinvoicenonpo/detail/DetailInvoiceNonPOpic.vue';
import SearchInvoiceNonProxy from './components/searchinvoiceproxy/SearchInvoiceNonProxy.vue';
import DetailSearchInvoiceNonProxy from './components/searchinvoiceproxy/detailsearchinvoicenonpoproxy/DetailSearchInvoiceNonProxy.vue';
import DetailSearchInvoiceNonProxySP1 from './components/searchinvoiceproxy/detailsearchinvoicenonpoproxy/DetailSearchInvoiceNonProxySP1.vue';
import CreateInvoiceProxy from './components/invoicepoproxy/CreateInvoice.vue';

import CreateInvoiceProxyPanitia from './components/invoicepoproxy/CreateInvoicePanitia.vue';

import ListNotaDebit from './components/listnotadebit/ListNotaDebit.vue';
import DetailListNota from './components/listnotadebit/detail/DetailListNota.vue';
import CreateNotaDebit from './components/notadebit/NotaDebit.vue';
import UploadJit from './components/jit/UploadJit.vue';
import PoHistory from './components/pohistory/PoHistory.vue';
import InvoiceReport from './components/invoicereport/InvoiceReport.vue';
import Return from './components/return/Return.vue';
import Receipt from './components/receipt/Receipt.vue';
import PoInterface from './components/pointerface/PoInterface.vue';
import ItemJIT from './components/itemjit/ItemJit.vue';
import DetailItemJIT from './components/itemjit/detailitemjit/DetailItemJit.vue';
import SpecAndQuotation from './components/spec & quotation/SpecAndQuotation.vue';
import DetailSpecQuotation from './components/spec & quotation/detailSpecAndQuotation/DetailSpecQuotation.vue';
import SupplierMaster from './components/suppliermaster/SupplierMasterView.vue';
import DetailSupplierMaster from './components/suppliermaster/detailsuppliermaster/DetailSupplierMaster.vue';

//ui improvement
import SearchPendingPOSupp from './components/searchpendingpo/SearchPendingPOSupp.vue';
import CreateEinvoiceStandart from './components/einvoicestandart/Create.vue';
import CreateEinvoicePrepayment from './components/einvoiceprepayment/CreateEinvoicePrepayment.vue';
import CreateEinvoicePrepaymentPur from './components/einvoiceprepayment/CreateEinvoicePrepaymentPur.vue';
import CreateEinvoiceProcurement from './components/createeinvoiceprocurement/CreateProcurement.vue';
import CreateEinvoiceProcurementPurchasing from './components/createeinvoiceprocurement/CreateEinvoiceProcurementPurchasing.vue';
import SearchInvoiceWPONonSupp from './components/searchinvoicepo/SearchInvoiceWPONonSupp.vue';
import CreateBAST from './components/bast/create/CreateBAST.vue'
import CreateBASTProxy from './components/bast/create/CreateBASTProxy.vue'
import EditBAST from './components/bast/edit/EditBAST.vue'
import EditBASTProxy from './components/bast/edit/EditBASTProxy.vue'
import ListBAST from './components/bast/list/ListBAST.vue'
import ListBASTProxy from './components/bast/list/ListBASTProxy.vue'
import DetailInvoiceProcurementFinance from './components/detailinvoicepopending/procurement/DetailInvoiceProcurementFinance'
import ListInvoicePendingPO from './components/listinvoicependingpo/ListInvoicePendingPO.vue'
import DetailInvoicePrepaymentFinance from './components/detailinvoicepopending/prepayment/DetailInvoicePrepaymentFinance';
import DetailInvoiceWithPoProc from './components/searchinvoicewithpo/detailsearchinvoicewpo/DetailSearchInvoiceWPOProc.vue';
import DetailInvoiceWithPoStandart from './components/searchinvoicewithpo/detailsearchinvoicewpo/DetailSearchInvoiceWPOStandart.vue';
import ListInvoicePur from './components/invoicepo/listinvoice/ListInvoicePur.vue';
import ListWPOPur from './components/invoicepo/listinvoice/ListWPOPur.vue';
import DetailInvoiceWithPoPrepayment from './components/searchinvoicewithpo/detailsearchinvoicewpo/DetailSearchInvoiceWPOPrepayment.vue';
import PaymentReport from './components/reportinvoicepayment/PaymentReport.vue';

const routes = [
	{
		path: '/',
		redirect: '/login',
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: HomePage,
	},
	{
		path: '/dashboard/notifdetails',
		name: 'notifdetails',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: NotifDetails,
	},
	{
		path: '/dashboard/notifdetails/plannodetail',
		name: 'plannodetail',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: PlannoDetail,
	},
	{
		path: '/dashboard/notifdetails/plannodetailpur',
		name: 'plannodetailpur',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: PlannoDetailPur,
	},
	{
		path: '/dashboard/notifdetails/podetail',
		name: 'podetail',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: PODetail,
	},
	{
		path: '/dashboard/notifdetails/podetailpur',
		name: 'podetailpur',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: PODetailPur,
	},
	{
		path: '/dashboard/notifdetails/capadetail',
		name: 'capadetail',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: CapaDetail,
	},
	{
		path: '/dashboard/notifdetails/capadetailpur',
		name: 'capadetailpur',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: CapaDetailPur,
	},
	{
		path: '/dashboard/notifdetails/shipmentdetail',
		name: 'shipmentdetail',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: ShipmentDetail,
	},
	{
		path: '/mgm/user/userslist',
		name: 'user',
		beforeEnter(to, from, next) {
			// Guard.guardMyroute(to, from, next);
			Guard.guardEmployeeInternal(to, from, next);
		},
		component: User,
		children: [
			{ path: 'detail', name: 'user.detail', component: TestPage },
			{ path: 'detail#/:id', component: HomePage },
		],
	},
	{
		path: '/mgm/acl/grouplist',
		name: 'group',
		beforeEnter(to, from, next) {
			Guard.guardAdmin(to, from, next);
		},
		component: Group,
	},
	{
		path: '/mgm/acl/grouplist/:id',
		name: 'groupListDetails',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: GroupDetail,
		props: true,
	},
	{
		path: '/user/:id',
		name: 'userCreate',
		// beforeEnter(to, from, next) {
		//     Guard.guardMyroute(to, from, next);
		// },

		component: () => import('./components/user/UserCreate.vue'),
	},
	{
		// path: '/mgm/acl/grouplist',
		// name: 'grouplist',
		// beforeEnter(to, from, next) {
		//     Guard.guardMyroute(to, from, next);
		// },
		// component: UserPriveleges,
		path: '/mgm/user/userslist',
		name: 'userList',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: () => import('./components/user/UserList.vue'),
	},
	{
		path: '/mgm/settings/planningorder',
		name: 'planningOrder',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},

		component: () => import('./components/planningorder/PlanningOrder.vue'),
	},
	{
		path: '/mgm/settings/planningorder/:id',
		name: 'planningOrderDetail',
		beforeEnter(to, from, next) {
			Guard.guardInternal(to, from, next);
		},
		component: () =>
			import(
				'./components/planningorder/detailplanningorder/DetailPlanningOrder.vue'
			),
	},
	{
		path: '/mgm/settings/planningordersupp/:id',
		name: 'planningOrderDetailSupp',
		beforeEnter(to, from, next) {
			Guard.guardSupplier(to, from, next);
		},
		component: () =>
			import(
				'./components/planningorder/detailplanningorder/DetailPlanningOrderSupp.vue'
			),
	},
	{
		path: '/printposupp',
		name: 'printposupp',
		component: () => import('./pages/PrintPlanningOrderSupp.vue'),
	},
	{
		path: '/mgm/settings/createpo',
		name: 'planningorder',
		props: true,
		beforeEnter(to, from, next) {
			Guard.guardInternal(to, from, next);
		},
		component: CreatePo,
	},
	{
		path: '/mgm/settings/submitpo',
		name: 'SubmitPo',
		props: true,
		beforeEnter(to, from, next) {
			Guard.guardInternal(to, from, next);
		},
		component: SubmitPo,
	},
	{
		path: '/mgm/settings/sysparamslist',
		name: 'poFulfillment',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: Pofulfillment,
	},
	{
		path: '/mgm/settings/sysparamslist/:id',
		name: 'DetailpoFulfillment',
		beforeEnter(to, from, next) {
			Guard.guardInternal(to, from, next);
		},
		component: DetailPofulfillment,
	},
	{
		path: '/mgm/settings/sysparamslistsupp/:id',
		name: 'DetailpoFulfillmentSupp',
		beforeEnter(to, from, next) {
			Guard.guardSupplier(to, from, next);
		},
		component: () =>
			import(
				'./components/pofulfillment/detailpofulfillment/DetailPoFulfillmentSupp.vue'
			),
	},
	{
		path: '/mgm/settings/createshipment',
		name: 'CreateShipmentNotice',
		beforeEnter(to, from, next) {
			Guard.guardSupplier(to, from, next);
		},
		component: CreateShipmentNotice,
	},
	{
		path: '/mgm/settings/createshipmentsupp/:id',
		name: 'DetailCreateShipmentNoticeSupp',
		beforeEnter(to, from, next) {
			Guard.guardSupplier(to, from, next);
		},
		component: DetailCreateShipmentNoticeSupp,
	},
	{
		path: '/mgm/settings/createshipment/add/:id',
		name: 'AddCreateShipmentNotice',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: AddCreateShipmentNotice,
	},
	{
		path: '/mgm/settings/createshipment/:doNumber/uploaddocument',
		name: 'UploadDocumentCreateShipment',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: UploadDocumentCreateShipment,
	},
	{
		path: '/mgm/settings/shipment',
		name: 'ShipmentNotice',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: ShipmentNotice,
	},
	{
		path: '/mgm/monitor/batchcreate',
		name: 'createBatch',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: CreateBatch,
	},
	{
		path: '/mgm/monitor/searchinvoicewpo',
		name: 'SearchInvoiceWPONonSupp',
		beforeEnter(to, from, next) {
			Guard.guardInternal(to, from, next);
		},
		component: SearchInvoiceWPONonSupp,
	},
	{
		path: '/mgm/monitor/listinvoicependingpo',
		name: 'ListInvoicePendingPO',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: ListInvoicePendingPO,
	},
	{
		path: '/mgm/settings/createbast',
		name: 'CreateBAST',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: CreateBAST,
	},
	{
		path: '/mgm/settings/createbastproxy',
		name: 'CreateBASTProxy',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: CreateBASTProxy,
	},
	{
		path: '/mgm/settings/editbast/:id',
		name: 'EditBAST',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: EditBAST,
	},
	{
		path: '/mgm/settings/editbastproxy/:id',
		name: 'EditBASTProxy',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: EditBASTProxy,
	},
	{
		path: '/mgm/settings/detailinvoiceprocurement',
		name: 'DetailInvoiceProcurementFinance',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: DetailInvoiceProcurementFinance,
	},
	{
		path: '/mgm/settings/detailinvoiceprepayment',
		name: 'DetailInvoicePrepaymentFinance',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: DetailInvoicePrepaymentFinance,
	},
	{
		path: '/mgm/settings/listbast',
		name: 'ListBAST',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: ListBAST,
	},
	{
		path: '/mgm/settings/listbastproxy',
		name: 'ListBASTProxy',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: ListBASTProxy,
	},
	{
		path: '/mgm/settings/detailshipmentnoticesupp/:id',
		name: 'DetailShipmentNoticeSupp',
		beforeEnter(to, from, next) {
			Guard.guardSupplier(to, from, next);
		},
		component: DetailShipmentNoticeSupp,
	},
	{
		path: '/mgm/settings/detailshipmentnoticepur/:id',
		name: 'DetailShipmentNoticePur',
		beforeEnter(to, from, next) {
			Guard.guardInternal(to, from, next);
		},
		component: DetailShipmentNoticePur,
	},
	{
		path: '/mgm/settings/specsrc',
		name: 'SpecSRC',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: SpecSRC,
	},
	{
		path: '/mgm/settings/specsrc/add',
		name: 'AddSpecSRC',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: AddSpecSRC,
	},
	{
		path: '/mgm/settings/specsrc/edit/:id',
		name: 'EditSpecSRC',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: EditSpecSRC,
	},
	{
		path: '/mgm/settings/lovdynamic',
		name: 'lovdynamic',
		beforeEnter(to, from, next) {
			Guard.guardAdmin(to, from, next);
		},
		component: () => import('./components/lov/LovList.vue'),
	},
	{
		path: '/mgm/settings/lovdetail',
		name: 'lovdetail',
		beforeEnter(to, from, next) {
			Guard.guardAdmin(to, from, next);
		},
		component: () => import('./components/lov/LovDetail.vue'),
	},
	{
		path: '/mgm/settings/lovedit',
		name: 'lovedit',
		beforeEnter(to, from, next) {
			Guard.guardAdmin(to, from, next);
		},
		component: () => import('./components/lov/LovEdit.vue'),
	},
	{
		path: '/mgm/settings/register',
		name: 'registerOutstanding',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},

		component: RegisterOutstandingOrderSupp,
	},
	{
		path: '/mgm/settings/outstandingporeport',
		name: 'outstandingPoReport',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},

		component: OutstandingPoReport,
	},
	{
		path: '/mgm/settings/reportinvoicepayment',
		name: 'reportInvoicepayment',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},

		component: reportInvoicePayment,
	},
	{
		path: '/mgm/monitor/paymentreport',
		name: 'paymentReport',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},

		component: PaymentReport,
	},
	{
		path: '/mgm/settings/reportinvoicepaymentschedule',
		name: 'reportinvoicepaymentschedule',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},

		component: ReportinvoicepaymentSchedule,
	},
	{
		path: '/mgm/settings/netmgm',
		name: 'trackingPOReport',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},

		component: TrackingPOReport,
	},
	{
		path: '/mgm/settings/requisition',
		name: 'Requisition',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: Requisition,
	},
	{
		path: '/mgm/monitor/batchList',
		name: 'BatchList',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: BatchList,
	},
	{
		path: '/mgm/master/capa',
		name: 'viewCapa',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: ViewCapa,
	},
	{
		path: '/mgm/master/capa/addcapa/:id',
		name: 'AddCapa',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},

		component: AddCapa,
	},
	{
		path: '/mgm/master/spereport',
		name: 'ListSpeReport',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},

		component: ListSpeReport,
	},
	{
		path: '/mgm/master/spereport/create',
		name: 'CreateSpeReport',
		beforeEnter(to, from, next) {
			Guard.guardInternal(to, from, next);
		},

		component: CreateSpeReport,
	},
	{
		path: '/mgm/master/spereport/:id',
		name: 'DetailSpeReport',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: DetailSpeReport,
		props: true,
	},
	{
		path: '/mgm/master/changepassword',
		name: 'ChangePassword',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: ChangePassword,
	},
	{
		path: '/mgm/monitor/batchListDetail/:batch_id',
		name: 'BatchListDetail',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: BatchListDetail,
	},
	{
		path: '/mgm/monitor/invoicepo',
		name: 'searchInvoiceWithPo',

		beforeEnter(to, from, next) {
			Guard.guardInternal(to, from, next);
		},
		component: InvoiceWithPo,
	},
	{
		path: '/mgm/monitor/invoicepo/detail/:id',
		name: 'DetailSearchInvoiceWithPo',
		beforeEnter(to, from, next) {
			Guard.guardInternal(to, from, next);
		},

		component: DetailInvoiceWithPo,
	},
	{
		path: '/mgm/monitor/invoicepostandart/detail/:id',
		name: 'DetailSearchInvoiceWithPoStandart',
		beforeEnter(to, from, next) {
			Guard.guardInternal(to, from, next);
		},

		component: DetailInvoiceWithPoStandart,
	},
	{
		path: '/mgm/monitor/invoicepoproc/detail/:id',
		name: 'DetailSearchInvoiceWithPoProc',
		beforeEnter(to, from, next) {
			Guard.guardInternal(to, from, next);
		},

		component: DetailInvoiceWithPoProc,
	},
	{
		path: '/mgm/monitor/invoicepopp/detail/:id',
		name: 'DetailSearchInvoiceWithPoPrepayment',
		beforeEnter(to, from, next) {
			Guard.guardInternal(to, from, next);
		},

		component: DetailInvoiceWithPoPrepayment,
	},
	{
		path: '/mgm/monitor/listinvoice',
		name: 'ListInvoicePO',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: ListInvoicePO,
	},
	{
		path: '/mgm/monitor/listinvoicepoproxy',
		name: 'ListWPOPur',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: ListWPOPur,
	},
	{
		path: '/mgm/monitor/listinvoicepoprepayment',
		name: 'ListInvoicePOPrepayment',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: ListInvoicePur,
	},
	{
		path: '/mgm/monitor/listinvoice/detail/:id',
		name: 'DetailListInvoicePO',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		props: true,
		component: DetailListInvoicePO,
	},
	{
		path: '/mgm/monitor/createinvoicepo/:id',
		name: 'CreateInvoicePO',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: CreateInvoicePO,
	},
	{
		path: '/mgm/monitor/createinvoicenonpo',
		name: 'CreateInvoiceNonPO',
		beforeEnter(to, from, next) {
			Guard.guardSupplier(to, from, next);
		},
		component: CreateInvoiceNonPO,
	},
	{
		path: '/mgm/monitor/listinvoicenonpo',
		name: 'ListInvoiceNonPO',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: ListInvoiceNonPO,
	},
	{
		path: '/mgm/monitor/listinvoicenonpo/:id',
		name: 'DetailListInvoiceNonPo',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: DetailListInvoiceNonPO,
	},
	{
		path: '/mgm/monitor/listinvoiceproxy',
		name: 'ListInvoiceProxy',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: ListInvoiceProxy,
	},
	{
		path: '/mgm/monitor/listinvoiceproxy/:id',
		name: 'DetailListInvoiceProxy',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		props: true,
		component: DetailListInvoiceProxy,
	},
	{
		path: '/mgm/monitor/detailproxysp1/:id',
		name: 'DetailListInvoiceProxySP1',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		props: true,
		component: DetailListInvoiceProxySP1,
	},
	{
		path: '/mgm/settings/docsetuplist',
		name: 'documentSetup',
		beforeEnter(to, from, next) {
			Guard.guardAdmin(to, from, next);
		},

		component: DocumentSetup,
	},
	{
		path: '/mgm/master/mastercategory',
		name: 'masterCategory',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: MasterCategory,
	},
	{
		path: '/mgm/master/mastercategory/detail/:id',
		name: 'detailMasterCategory',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: DetailMasterCategory,
	},
	{
		path: '/mgm/master/vendor',
		name: 'masterVendor',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: MasterVendor,
	},
	{
		path: '/mgm/master/vendor/detail/:maping_id',
		name: 'masterVendorDetail',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},

		component: MasterVendorDetail,
	},
	{
		path: '/mgm/master/vendor/detail/:maping_id',
		name: 'masterVendorDetail',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},

		component: MasterVendorDetail,
	},
	{
		path: '/mgm/master/capa/editcapapur/:id',
		name: 'UpdateCapaPur',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},

		component: UpdateCapaPur,
	},
	{
		path: '/mgm/master/capa/editcapasupp/:id',
		name: 'UpdateCapaSupp',
		beforeEnter(to, from, next) {
			Guard.guardSupplier(to, from, next);
		},
		component: UpdateCapaSupp,
	},
	{
		path: '/mgm/monitor/searchinvoicenonpo',
		name: 'SearchInvoiceFinPic',
		beforeEnter(to, from, next) {
			Guard.guardInternal(to, from, next);
		},
		component: SearchInvoiceFinPic,
	},
	{
		path: '/mgm/monitor/detailInvoiceNonPOFinance/:id',
		name: 'detailInvoiceNonPOFinance',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},

		component: DetailInvoiceNonPOFinance,
	},
	{
		path: '/mgm/monitor/searchinvoicenonpo/detailnonpo/:id',
		name: 'DetailInvoiceNonPOpic',
		beforeEnter(to, from, next) {
			Guard.guardInternal(to, from, next);
		},

		component: DetailInvoiceNonPOpic,
	},
	{
		path: '/mgm/monitor/searchinvoicenonproxy',
		name: 'SearchInvoiceProxy',
		beforeEnter(to, from, next) {
			Guard.guardInternal(to, from, next);
		},
		component: SearchInvoiceNonProxy,
	},
	{
		path: '/mgm/monitor/listpendingpo',
		name: 'SearchPendingPOSupp',
		// beforeEnter(to, from, next) {
		// 	Guard.guardSupplier(to, from, next);
		// },
		component: SearchPendingPOSupp,
	},
	{
		path: '/mgm/settings/createeinvoiceprepayment/:id',
		name: 'CreateEinvoicePrepayment',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: CreateEinvoicePrepayment,
	},
	{
		path: '/mgm/settings/createeinvoiceprepaymentpur/:id',
		name: 'CreateEinvoicePrepaymentPur',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: CreateEinvoicePrepaymentPur,
	},
	{
		path: '/mgm/settings/createeinvoicestandart/:id',
		name: 'CreateEinvoiceStandart',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: CreateEinvoiceStandart,
	},
	{
		path: '/mgm/monitor/createeinvoiceprocurement/purchasing',
		name: 'CreateEinvoiceProcurementPurchasing',
		beforeEnter(to, from, next) {
			Guard.guardInternal(to, from, next);
		},
		component: CreateEinvoiceProcurementPurchasing,
	},
	{
		path: '/mgm/settings/createeinvoiceprocurement/:id',
		name: 'CreateEinvoiceProcurement',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: CreateEinvoiceProcurement,
	},
	{
		path: '/mgm/monitor/searchinvoicenonproxy/detail/:id',
		name: 'DetailSearchInvoiceNonProxy',
		beforeEnter(to, from, next) {
			Guard.guardInternal(to, from, next);
		},
		component: DetailSearchInvoiceNonProxy,
	},
	{
		path: '/mgm/monitor/searchinvoicenonproxysp1/detail/:id',
		name: 'DetailSearchInvoiceNonProxySP1',
		beforeEnter(to, from, next) {
			Guard.guardInternal(to, from, next);
		},
		component: DetailSearchInvoiceNonProxySP1,
	},
	{
		path: '/mgm/monitor/createinvoiceproxy',
		name: 'CreateInvoiceProxy',
		beforeEnter(to, from, next) {
			Guard.guardInternal(to, from, next);
		},
		component: CreateInvoiceProxy,
	},
	{ 
		path: '/mgm/monitor/createinvoiceproxysp1',
		name: 'CreateInvoiceProxyPanitia',
		// beforeEnter(to, from, next) {
		// 	Guard.guardInternal(to, from, next);
		// },
		component: CreateInvoiceProxyPanitia,
	},
	{
		path: '/mgm/monitor/eventloglist',
		name: 'ListNotaDebit',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},

		component: ListNotaDebit,
	},
	{
		path: '/mgm/monitor/eventloglist/detail/:id',
		name: 'DetailListNota',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},

		component: DetailListNota,
	},
	{
		path: '/mgm/monitor/eventlog',
		name: 'CreateNotaDebit',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},

		component: CreateNotaDebit,
	},
	{
		path: '/mgm/monitor/uploadjit',
		name: 'UploadJit',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},

		component: UploadJit,
	},
	{
		path: '/mgm/monitor/invoicereport',
		name: 'InvoiceReport',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},

		component: InvoiceReport,
	},
	{
		path: '/mgm/master/returnlist',
		name: 'Return',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: Return,
	},
	{
		path: '/mgm/master/pohistory',
		name: 'PoHistory',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: PoHistory,
	},
	{
		path: '/mgm/master/receipt',
		name: 'Receipt',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: Receipt,
	},
	{
		path: '/mgm/settings/trxcostlist',
		name: 'ItemJit',
		beforeEnter(to, from, next) {
			Guard.guardAdmin(to, from, next);
		},

		component: ItemJIT,
	},
	{
		path: '/mgm/settings/trxcostlist/detail/:id',
		name: 'DetailItemJit',
		beforeEnter(to, from, next) {
			Guard.guardAdmin(to, from, next);
		},

		component: DetailItemJIT,
	},
	{
		path: '/mgm/settings/pointerface',
		name: 'PoInterface',
		beforeEnter(to, from, next) {
			Guard.guardAdmin(to, from, next);
		},

		component: PoInterface,
	},
	{
		path: '/mgm/settings/specquotelist',
		name: 'SpecAndQuotation',
		beforeEnter(to, from, next) {
			Guard.guardInternal(to, from, next);
		},

		component: SpecAndQuotation,
	},
	{
		path: '/mgm/settings/detailspecquotelist/:id',
		name: 'DetailSpecQuotation',
		beforeEnter(to, from, next) {
			Guard.guardInternal(to, from, next);
		},

		component: DetailSpecQuotation,
	},
	{
		path: '/mgm/master/suppliermaster',
		name: 'SupplierMaster',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},

		component: SupplierMaster,
	},
	{
		path: '/mgm/master/suppliermaster/detail/:id',
		name: 'detailsuppliermaster',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: DetailSupplierMaster,
	},
	{
		path: '/formlayout',
		name: 'formlayout',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: () => import('./components/FormLayoutDemo.vue'),
	},
	{
		path: '/input',
		name: 'input',
		beforeEnter(to, from, next) {
			Guard.guardMyroute(to, from, next);
		},
		component: () => import('./components/InputDemo.vue'),
	},
	{
		path: '/floatlabel',
		name: 'floatlabel',
		component: () => import('./components/FloatLabelDemo.vue'),
	},
	{
		path: '/invalidstate',
		name: 'invalidstate',
		component: () => import('./components/InvalidStateDemo.vue'),
	},
	{
		path: '/button',
		name: 'button',
		component: () => import('./components/ButtonDemo.vue'),
	},
	{
		path: '/table',
		name: 'table',
		component: () => import('./components/TableDemo.vue'),
	},
	{
		path: '/list',
		name: 'list',
		component: () => import('./components/ListDemo.vue'),
	},
	{
		path: '/tree',
		name: 'tree',
		component: () => import('./components/TreeDemo.vue'),
	},
	{
		path: '/panel',
		name: 'panel',
		component: () => import('./components/PanelsDemo.vue'),
	},
	{
		path: '/overlay',
		name: 'overlay',
		component: () => import('./components/OverlayDemo.vue'),
	},
	{
		path: '/media',
		name: 'media',
		component: () => import('./components/MediaDemo.vue'),
	},
	{
		path: '/mgm',
		component: () => import('./components/MenuDemo.vue'),
		children: [
			{
				path: '',
				component: () => import('./components/menu/PersonalDemo.vue'),
			},
			{
				path: '/menu/seat',
				component: () => import('./components/menu/SeatDemo.vue'),
			},
			{
				path: '/menu/payment',
				component: () => import('./components/menu/PaymentDemo.vue'),
			},
			{
				path: '/menu/confirmation',
				component: () =>
					import('./components/menu/ConfirmationDemo.vue'),
			},
		],
	},
	{
		path: '/messages',
		name: 'messages',
		component: () => import('./components/MessagesDemo.vue'),
	},
	{
		path: '/file',
		name: 'file',
		component: () => import('./components/FileDemo.vue'),
	},
	{
		path: '/chart',
		name: 'chart',
		component: () => import('./components/ChartDemo.vue'),
	},
	{
		path: '/misc',
		name: 'misc',
		component: () => import('./components/MiscDemo.vue'),
	},
	{
		path: '/crud',
		name: 'crud',
		component: () => import('./pages/CrudDemo.vue'),
	},
	{
		path: '/timeline',
		name: 'timeline',
		component: () => import('./pages/TimelineDemo.vue'),
	},
	{
		path: '/empty',
		name: 'empty',
		component: () => import('./components/EmptyPage.vue'),
	},
	{
		path: '/documentation',
		name: 'documentation',
		component: () => import('./components/Documentation.vue'),
	},
	{
		path: '/blocks',
		name: 'blocks',
		component: () => import('./components/BlocksDemo.vue'),
	},
	{
		path: '/icons',
		name: 'icons',
		component: () => import('./components/IconsDemo.vue'),
	},
	{
		path: '/landing',
		name: 'landing',
		component: () => import('./pages/LandingDemo.vue'),
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('./pages/Login.vue'),
	},
	{
		path: '/error',
		name: 'error',
		component: () => import('./pages/Error.vue'),
	},
	{
		path: '/notfound',
		name: 'notfound',
		component: () => import('./pages/NotFound.vue'),
	},
	{
		path: '/printpopur',
		name: 'printpopur',
		component: () => import('./pages/PrintPlanningOrderPur.vue'),
	},
	{
		path: '/resetpassword',
		name: 'resetpassword',
		component: () => import('./pages/ResetPassword.vue'),
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.name === 'AddCreateShipmentNotice' && !savedPosition) {
			return { top: 0 };
		}

		if (
			to.name === 'DetailpoFulfillmentSupp' &&
			from.name === 'CreateShipmentNotice' &&
			!savedPosition
		) {
			return { top: 0 };
		}
	},
});

export default router;
