<template>
	<!-- eslint-disable -->
	<DataTable
		id="detailTableListInvoiceProxyNonPO"
		:value="tableList"
		:paginator="true"
		:rows="10"
		:loading="loading"
		:rowsPerPageOptions="[10, 20, 30]"
		responsiveLayout="scroll"
		:scrollable="true"
		scrollHeight="600px"
		scrollDirection="both"
		@page="onPage($event)"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #empty>No Invoice found</template>
		<template #loading>Loading data</template>
		<Column header="Description" style="width: 45%">
			<template #body="slotProps">
				<InputText
					style="width:100%;"
					id="inputtext"
					type="text"
					v-model="slotProps.data.ITEM_DESCRIPTION"
					:class="{
						'p-invalid':
							tableList[slotProps.index + page].VALIDATE_ITEM,
					}"
					:disabled="!props.forwarder || !props.disabled"
				/>
				<div v-if="tableList[slotProps.index + page].VALIDATE_ITEM">
					<small class="p-error">{{
						'Item Description is empty'
					}}</small>
				</div>
			</template>
		</Column>
		<Column header="Amount" style="width: 20%">
			<template #body="slotProps">
				<InputNumber
					autocomplete="off"
					id="locale-german"
					v-model="slotProps.data.AMOUNT"
					:minFractionDigits="2"
					locale="de-DE"
					:disabled="!props.disabled"
					:min="0"
					@input="onChangeText($event, slotProps.index + page)"
					:class="{
						'p-invalid': tableList[slotProps.index + page].RESPONSE,
					}"
				/>
				<div v-if="tableList[slotProps.index + page].RESPONSE">
					<small class="p-error">{{ 'Amount is empty' }}</small>
				</div>
			</template>
		</Column>
		<Column header="VAT" style="width: 150px">
			<template #body="slotProps">
				<Dropdown
					v-model="slotProps.data.VAT"
					@change="onChange($event, slotProps.index + page)"
					:options="getVAT"
					style="width:100%"
					optionLabel="VAT_CODE"
					:disabled="!props.disabled"
				/>
				<!-- <div v-if="tableList[slotProps.index + page].RESPONSE_VAT">
					<small class="p-error">{{ 'VAT is empty' }}</small>
				</div> -->
			</template>
		</Column>
		<Column header="WHT" style="width: 150px">
			<template #body="slotProps">
				<Dropdown
					v-model="slotProps.data.WHT"
					:options="whtRates"
					optionLabel="WHT_CODE"
					style="width: 100%"
					:disabled="!props.disabled"
					@change="onChangeWHT($event, slotProps.index + page)"
				/>
			</template>
		</Column>
		<Column header="Account" style="width: 45%">
			<template #body="slotProps">
				<AutoComplete
					v-model="slotProps.data.ACCOUNT"
					field="account"
					:suggestions="filteredAccount"
					:dropdown="true"
					@complete="searchAccount($event)"
					@item-select="getAccountId($event, slotProps.index + page)"
					:disabled="!props.disabled || !accountData"
					forceSelection
					style="width: 100%"
					:class="{
						'p-invalid':
							tableList[slotProps.index + page].ACCOUNT_VALIDATE,
					}"
				/>
				<div v-if="tableList[slotProps.index + page].ACCOUNT_VALIDATE">
					<small class="p-error">{{ 'Account is empty' }}</small>
				</div>
			</template>
		</Column>
		<Column header="Account SL" style="width: 45%">
			<template #body="slotProps">
				<AutoComplete
					v-model="slotProps.data.ACCOUNT_SL"
					field="account"
					:suggestions="filteredAccount"
					:dropdown="true"
					style="width: 100%"
					@complete="searchAccount($event)"
					@item-select="getAccountIdSL($event, slotProps.index + page)"
					:disabled="!props.disabled || !accountData"
					forceSelection
					:class="{
						'p-invalid':
							tableList[slotProps.index + page].ACCOUNT_VALIDATE,
					}"
				/>
				<div v-if="tableList[slotProps.index + page].ACCOUNT_VALIDATE">
					<small class="p-error">{{ 'Account is empty' }}</small>
				</div>
			</template>
		</Column>
		<Column header="Ref Number" style="width: 45%">
			<template #body="slotProps">
				<InputText
					class="w-full"
					id="inputtext"
					type="text"
					style="width: 100%"
					v-model="slotProps.data.REMARKS"
					disabled
				/>
			</template>
		</Column>

	</DataTable>
	<Dialog
		header="Ref Number"
		v-model:visible="displayModal"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '50vw' }"
		:modal="true"
	>
		<div class="col-12 p-fluid mb-4">
			<DataTable
				:value="filtered ? filtered : tableListPopUp"
				responsiveLayout="scroll"
				selectionMode="single"
				:paginator="true"
				:metaKeySelection="false"
				:rows="5"
				:rowsPerPageOptions="[5, 10, 20, 50]"
				:loading="!tableListPopUp"
				paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
				currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
				dataKey="id"
				@rowSelect="onRowSelect"
			>
				<template #header>
					<div>
						<span class="p-input-icon-left" style="col-12">
							<i class="pi pi-search" />
							<InputText
								v-model="invoiceFilter"
								placeholder="Search"
								@keydown.enter="keydown()"
							/>
						</span>
					</div>
				</template>
				<template #empty>No Invoice found</template>
				<template #loading>Loading data</template>
				<Column header="No Travel Order" field="travel_order_no">
				</Column>
				<Column header="Name" field="guest_name"> </Column>
				<Column header="Start Date" field="start_date">
					<!-- <template #body="slotProps">
              {{
              new Date(slotProps.data.start_date).toLocaleDateString('id-ID', { day: 'numeric', month: 'short', year: 'numeric'}) }}
            </template> -->
				</Column>
				<Column header="End Date" field="end_date">
					<!-- <template #body="slotProps">
              {{
              new Date(slotProps.data.end_date).toLocaleDateString('id-ID', { day: 'numeric', month: 'short', year: 'numeric'}) }}
            </template> -->
				</Column>
			</DataTable>
		</div>
		<template #footer>
			<Button
				label="No"
				icon="pi pi-times"
				@click="closeModal"
				class="p-button-text"
			/>
			<Button
				label="Yes"
				icon="pi pi-check"
				@click="closeModal"
				autofocus
			/>
		</template>
	</Dialog>

	<Dialog
		header="Delete Reimburse"
		v-model:visible="deleteLineValue"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '50vw' }"
		:modal="true"
	>
		<div class="confirmation-content">
			<i
				class="pi pi-exclamation-triangle mr-3"
				style="font-size: 2rem"
			/>
			<span v-if="tableLineValue"
				>Are you sure you want to delete Line for
				<b>{{ tableLineValue.ITEM_DESCRIPTION }}</b> from
				Database?</span
			>
		</div>
		<template #footer>
			<Button
				label="No"
				icon="pi pi-times"
				class="p-button-text"
				@click="deleteLineValue = false"
			/>
			<Button
				label="Yes"
				icon="pi pi-check"
				class="p-button-text"
				@click="deleteTableLine"
			/>
		</template>
	</Dialog>
</template>

<script setup>
import { onMounted, ref, defineEmits, watch, computed } from 'vue';
import AuthService from '@/service/AuthService';
import PoFulfillment from '@/service/PoFulfillment';
import LovService from '@/service/LovService';
import { useToast } from 'primevue/usetoast';
import SearchInvoiceProxyNonPO from '../../../service/InvoiceProxyService';
import {getDetailCostCenter,getDetailCOAID} from '../../../service/InvoiceProxyNonPOServices'

const company = ref();
const supplier = ref();
// const idValue = ref()
const tableList = ref();
const reloadCat = ref(0);
const page = ref(0);
const emit = defineEmits(['tableList', 'onDeleteReimburse']);
const displayModal = ref(false);
const getVAT = ref();
const getVATBackup = ref(null);
const tableListPopUp = ref();
const invoiceFilter = ref();
// const filteredVAT = ref();

onMounted(async () => {
	company.value = props.company;
	//
	const info = await AuthService.whoAmi();
	if (info.status === 200) {
		levelTenant.value = info.data.data.leveltenant;
		if (levelTenant.value !== 2) {
			loading.value = true;
			supplier.value = props.supplier;
			//   loading.value = true
			await populateTable();
		} else {
			const param = {
				db_instance: props.company.db_instance,
				org_id: props.company.value,
			};
			const respVendor = await PoFulfillment.getSuppBindComp(
				info.data.data.id
			);
			const vendorName = respVendor.data.data[0][0].vendorname;
			const respSuppName = await LovService.getSupplierName(param);
			if (respSuppName.status === 200) {
				const suppList = respSuppName.data.data;
				for (const s of suppList) {
					if (s.vendor_name === vendorName) {
						supplier.value = s;
						break;
					}
				}
			}
			await populateTable();
			// loading.value = true
		}
	}
});
// eslint-disable-next-line no-undef
const props = defineProps({
	company: {
		type: Object,
	},
	data: {
		type: Object,
	},
	line: {
		type: Object,
	},
	vendor_id: {
		type: Object,
	},
	org_id: {
		type: Object,
	},
	db_instance: {
		type: Object,
	},
	disabled: {
		type: Boolean,
	},
	detailCat: {
		type: Object,
	},
	vendor_name: {
		type: Object,
	},
	invoice_date: {
		type: Date,
	},
	submit: {
		type: Boolean,
	},
	tableVuelidate: {
		type: Object,
	},
	cost_center: {
		type: Object,
	},
	forwarder: {
		type: Object,
	},
});

const toast = useToast();
const loading = ref(0);
const levelTenant = ref();
const payload = ref();
const deleteLineValue = ref();
const tableLineValue = ref();
const valuePopUP = ref();
const filtered = ref();
// const listAccount = ref();
const accountData = ref(null);
const filteredAccount = ref();
const whtRates = ref([]);
// const index = ref()


watch(
	() => props.invoice_date,
	(newValue) => {
		//
		if (newValue) {
			getDropdownVat();
		}
	}
);

watch(
	() => props.cost_center,
	() => {
		if (props.cost_center !== null) {
			getAccount();
		}
	}
);

watch(
	() => props.tableVuelidate,
	(newValue) => {
		console.log(props.tableVuelidate, "VUELIDATE")
		for (const i in tableList.value) {
			if (props.tableVuelidate !== null) {
				//
				if (newValue[0]['EINVOICE_REIMBURSMENT_ID']) {
					tableList.value[i].RESPONSE = false;
				} else {
					if (newValue[i].AMOUNT[0] !== undefined) {
						tableList.value[i].RESPONSE = true;
					}
					
					if (newValue[i].AMOUNT[0] == undefined) {
						tableList.value[i].RESPONSE = false;
					}
					// console.log(newValue[i].VAT_CODE[0], "VAT")
					if(newValue[i].VAT_CODE[0] !== undefined){
						tableList.value[i].RESPONSE_VAT = true;
					}

					if(newValue[i].VAT_CODE[0] === undefined){
						tableList.value[i].RESPONSE_VAT = false;
					}
				}
			} else {
				tableList.value[i].RESPONSE = false;
			}
		}
	}
);

watch(
	() => props.tableVuelidate,
	(newValue) => {
		for (const i in tableList.value) {
			if (props.tableVuelidate !== null) {
				//
				//
				if (newValue[0]['EINVOICE_REIMBURSMENT_ID']) {
					tableList.value[i].VALIDATE_ITEM = false;
				} else {
					//
					if (newValue[i].ITEM_DESCRIPTION[0] !== undefined) {
						tableList.value[i].VALIDATE_ITEM = true;
					} else if (newValue[i].ITEM_DESCRIPTION[0] == undefined) {
						tableList.value[i].VALIDATE_ITEM = false;
					}
				}
			} else {
				tableList.value[i].VALIDATE_ITEM = false;
			}
		}
	}
);

watch(
	() => props.tableVuelidate,
	(newValue) => {
		for (const i in tableList.value) {
			if (props.tableVuelidate !== null) {
				//
				//
				if (newValue[0]['EINVOICE_REIMBURSMENT_ID']) {
					tableList.value[i].ACCOUNT_VALIDATE = false;
				} else {
					//
					if (newValue[i].ACCOUNT[0] !== undefined) {
						tableList.value[i].ACCOUNT_VALIDATE = true;
					} else if (newValue[i].ACCOUNT[0] == undefined) {
						tableList.value[i].ACCOUNT_VALIDATE = false;
					}
				}
			} else {
				tableList.value[i].ACCOUNT_VALIDATE = false;
			}
		}
	}
);

watch(
	() => props.submit,
	(newValue) => {
		console.log(tableList.value, "TESTER")
		if (newValue !== null) {
			for (const i in tableList.value) {
				tableList.value[i].RESPONSE = false;
				tableList.value[i].VALIDATE_ITEM = false;
			}
		}
	}
);

const populateTable = async () => {
	//
	loading.value = true;
	if (props.data !== null) {
		//
		payload.value = {
			id: props.data ? props.data : null,
		};
		const data = await SearchInvoiceProxyNonPO.getDetailDataTable(
			payload.value
		);
		tableList.value = data.data.data.data;

		for(const element of tableList.value){
			element.RESPONSE = false;
			element.VALIDATE_ITEM = false;
			element.ACCOUNT_VALIDATE = false;


			if(element.COA_SL){
				const resp = await getDetailCOAID({coa_id: element.COA_SL});
				element.ACCOUNT_SL = resp.data.data ? resp.data.data['ACCOUNT'] : null;
			}else{
				element.ACCOUNT_SL = null
			}

		}

		console.log(tableList.value, 'TAB LIST VALUE')

		//
		await getDropdownVat();
		await getAccount();
		await whtDropdown();

		tableList.value.forEach((data)=>{
			data['WHT'] = {
				WHT_CODE: data['WHT_CODE'],
				WHT_CODE_ID: data['WHT_CODE_ID'],
				WHT_RATE: 0
			}
		});

		emit('tableList', tableList);
	}
	loading.value = false;
};


const onPage = async (event) => {
	//
	page.value = event.page * event.rows;
	//
};



const deleteTableLine = async () => {
	const payload = {
		idheader: parseInt(props.data),
		iddetail: tableLineValue.value.EINVOICE_LINE_ID,
		value: true,
	};
	const data = await SearchInvoiceProxyNonPO.deleteLine(payload);
	if (data.status === 200) {
		deleteLineValue.value = false;
		tableList.value = tableList.value.filter(
			(val) =>
				val.EINVOICE_LINE_ID !== tableLineValue.value.EINVOICE_LINE_ID
		);
		toast.add({
			severity: 'error',
			summary: 'Line Delete',
			detail: 'Line Successfully Deleted',
			life: 3000,
		});
		emit('tableList', tableList);
		reloadCat.value++;
		emit('reloadDetailCat', reloadCat);
		//
		// Delete reimburse table if deleted category is 'REIMBURSTMENT'
		if (tableLineValue.value.ITEM_DESCRIPTION.includes('REIMBURSTMENT')) {
			emit('onDeleteReimburse', { value: true });
		}
	}
};



const closeModal = async () => {
	displayModal.value = false;
};

const getDropdownVat = async () => {
	const payload = {
		db_instance: props.db_instance,
		invoice_date: props.invoice_date,
	};
	const data = await SearchInvoiceProxyNonPO.getVAT(payload);
	getVAT.value = data.data.data.data;
	getVAT.value.push({
		PERCENTAGE_RATE: null,
		VAT_CODE: 'No Select',
		TAX_RATE_ID: null,
	});
	//
	if (getVATBackup.value === null) {
		getVATBackup.value = getVAT.value;
	}
	for (const i in getVAT.value) {
		if (
			getVAT.value[i].PERCENTAGE_RATE !==
			getVATBackup.value[i].PERCENTAGE_RATE
		) {
			await tableList.value.forEach((element) => {
				element.PERCENTAGE_RATE = null;
				element.VAT_CODE = null;
				element.VAT_CODE_ID = null;
			});
			getVATBackup.value = getVAT.value;
		}
	}


	await tableList.value.forEach((vat) => {
		if(!vat['VAT_CODE_ID']) return

		const finderItem = getVAT.value.find((Q) => Q['TAX_RATE_ID'] == vat['VAT_CODE_ID']);

		if(finderItem){
			vat['VAT'] = {
				...finderItem
			}
		}
	})

	//
};


const onChange = async (event, index) => {
	console.log(event.value, 'EVENT VALUE KOK')

	if (
		tableList.value[index].AMOUNT === undefined ||
		tableList.value[index].AMOUNT === null
	) {
		tableList.value[index].VAT_CODE = null;
		toast.add({
			severity: 'error',
			summary: 'Empty Amount',
			detail: 'Empty Amount',
			life: 3000,
		});
	} else {
		//
		if (event.value === null) {
			tableList.value[index].PERCENTAGE_RATE = null;
			tableList.value[index].VAT_CODE = null;
			tableList.value[index].VAT_CODE_ID = null;
			emit('tableList', tableList);
			//
		} else {

			const payload = { id: event.value.TAX_RATE_ID };
			const data = await SearchInvoiceProxyNonPO.getVATID(payload);
			if (data.status === 200) {
				tableList.value[index].PERCENTAGE_RATE = data.data.data.data[0].PERCENTAGE_RATE;
				tableList.value[index].VAT_CODE = data.data.data.data[0].VAT_CODE
				tableList.value[index].VAT_CODE_ID = data.data.data.data[0].TAX_RATE_ID

				tableList.value[index].VAT = {
					...event.value
				}

				emit('tableList', tableList);
			} else {
				toast.add({
					severity: 'error',
					summary: 'Data not Found',
					detail: 'Data not Found',
					life: 3000,
				});
			}
		}
	}
};

const onChangeWHT = async (event, index) => {
	//
	console.log(event.value,index, 'LKEONFE')

	
	if (event.value.WHT_CODE_ID === null) {
		tableList.value[index].WHT_CODE = null;
		tableList.value[index].WHT_CODE_ID = null;
		emit('tableList', tableList);
	} else {
		const payload = {
			id: event.value.WHT_CODE_ID,
			org_id: props.org_id,
			db_instance: props.db_instance,
		};
		const data = await SearchInvoiceProxyNonPO.getWHTID(payload);
		if (data.status === 200) {
			tableList.value[index].WHT_CODE = data.data.data.data[0].WHT_CODE;
			tableList.value[index].WHT_CODE_ID = data.data.data.data[0].WHT_CODE_ID;
			tableList.value[index].WHT = {
				WHT_CODE: data.data.data.data[0].WHT_CODE,
				WHT_CODE_ID:data.data.data.data[0].WHT_CODE_ID,
				WHT_RATE: event.value.WHT_RATE
			}

			
			const modeling = modelingWHT(data.data.data.data[0].WHT_CODE);
			const account = tableList.value[index].ACCOUNT.split("");
			const accountLength = Number(account.length) - 1;
			account.splice(accountLength, 1, modeling);
			const concated = account.join("")

			const accountSL = tableList.value[index].ACCOUNT_SL.split("");
			const accountSLLength = Number(accountSL.length) - 1;
			accountSL.splice(accountSLLength, 1, modeling);
			const concatedSL = accountSL.join("")
		
			const responseData = await getDetailCostCenter({
				company_code: 'DXM',
				account: concated
			})

			const responseDataSL = await getDetailCostCenter({
				company_code: 'DXM',
				account: concatedSL
			})



			if(responseData.data.data.CODE_COMBINATION_ID){
				tableList.value[index].ACCOUNT = concated
				tableList.value[index].COA_ID = responseData.data.data.CODE_COMBINATION_ID
			}

			if(responseDataSL.data.data.CODE_COMBINATION_ID){
				tableList.value[index].ACCOUNT_SL = concatedSL
				tableList.value[index].COA_SL = responseDataSL.data.data.CODE_COMBINATION_ID
			}

		}

	

		emit('tableList', tableList);
	}

	console.log(tableList.value, 'WKWKWKW')
};

const modelingWHT = (str) => {
	const splitWHT = str.split("_")

	if(splitWHT[0].includes("21")) return "1";
	else if(splitWHT[0].includes("23")) return "3";
	else if(splitWHT[0].includes("4")) return "4";
	else return "0";
}

const getAccount = async () => {
	//
	if (
		props.cost_center !== null &&
		(props.cost_center.SEGMENT4 !== undefined ||
			props.cost_center.SEGMENT4 !== null ||
			props.cost_center.SEGMENT4 !== '')
	) {
		const payload = {
			orgId: props.org_id,
			dbInstance: props.db_instance,
			cost_center: props.cost_center.SEGMENT4,
		};

		//
		const res = await SearchInvoiceProxyNonPO.accountPic(payload);
		//
		if (res.status === 200) {
			//
			accountData.value = res.data.data.data;
		}
	}
};

const getAccountId = async (event, index) => {
	tableList.value[index].ACCOUNT = event.value.account;
	tableList.value[index].COA_ID = event.value.code_combination_id;
	emit('tableList', tableList);
};

const getAccountIdSL = async (event, index) => {
	tableList.value[index].ACCOUNT_SL = event.value.account;
	tableList.value[index].COA_SL = event.value.code_combination_id;
	emit('tableList', tableList);
};

const searchAccount = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredAccount.value = [...accountData.value];
		} else {
			filteredAccount.value = accountData.value.filter((val) => {
				if (val.account === null || val.account === undefined) {
					return val;
				}
				return val.account
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
		}
	}, 250);
};

const onChangeText = (event, index) => {
	if (tableList.value[index].PERCENTAGE_RATE !== null) {
		tableList.value[index].AMOUNT = event.value;
		emit('tableList', tableList);
	} else {
		tableList.value[index].AMOUNT = event.value;
		emit('tableList', tableList);
	}
};

const whtDropdown = async () => {
	const payload = {
		db_instance: props.db_instance,
		org_id: props.org_id,
	};
	const res = await SearchInvoiceProxyNonPO.whtPIC(payload);
	//
	if (res.status === 200) {
		//
		whtRates.value.push({
			WHT_RATE: null,
			WHT_CODE_ID: null,
			WHT_CODE: 'No Select',
			ORDER: 0
		});
		whtRates.value = [...whtRates.value, ...sortingWHT(res.data.data)]

		whtRates.value.forEach((Q) => {
			delete Q['ORDER']
		})
	}



	


};

const sortingWHT = (data) => {

	const obj = {
		"PPh21": 1,
		"PPh 21": 1,
		"PPh23": 2,
		"PPh 23": 2,
		"PPh4": 3,
		"PPh 4": 3,
		"PPh 4(2)": 3,
		"PPh26": 4,
		"PPh 26": 4
	}

	data.forEach(Q => {
		const spliter = Q['WHT_CODE'].split("_");
		const final = obj[spliter[0]]		
		Q['ORDER'] = final;
	});

	return data.sort((a,b) => {
		return a.ORDER - b.ORDER
	})
}

const keydown = async () => {
	filtered.value = filteredData.value;
};

const filteredData = computed(() =>
	tableListPopUp.value.filter((item) => {
		return (
			item.guest_name
				.toLowerCase()
				.match(invoiceFilter.value.toLowerCase()) ||
			item.travel_order_no
				.toLowerCase()
				.match(invoiceFilter.value.toLowerCase()) ||
			item.start_date
				.toLowerCase()
				.match(invoiceFilter.value.toLowerCase()) ||
			item.end_date.toLowerCase().match(invoiceFilter.value.toLowerCase())
		);
	})
);

const onRowSelect = (event) => {
	tableList.value[valuePopUP.value + page.value].REMARKS =
		event.data.travel_order_no +
		'#' +
		event.data.guest_name +
		'#' +
		event.data.start_date +
		'#' +
		event.data.end_date;
	displayModal.value = false;
};

// const onSort = async (event) => {
//   // loading.value = true
//   page.value = 1
//   rowsPerPage.value = event.rows;
//   sortColumn.value = event.sortField;
//   sortType.value = event.sortOrder;
//   await populateTable()
// }
</script>

<style scoped>
thead {
	z-index: 0;
}
</style>
