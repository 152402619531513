<template>
    <AutoComplete 
        :suggestions="filteredColumns" 
        @complete="searchColumns($event)" 
        :dropdown="true" 
        field="name" 
        placeholder="Select a status"
        forceSelection
        >
        <template #item="slotProp">
        {{ slotProp.item.name }}
        </template>
    </AutoComplete> 
</template>

<script setup>
import {onMounted, ref} from 'vue'

const filteredColumns = ref([]);
const columns = ref([]);

onMounted(() => {
  columns.value = [
    {col: "O", name: 'Open', type: 'String'},
    {col: "C", name: 'Closed', type: 'String'},
    {col: "X", name: 'Cancel', type: 'String'},
  ]
})


const searchColumns = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredColumns.value = [...columns.value]
    } else {
      filteredColumns.value = columns.value.filter((comp) => {
        return comp.name.toLowerCase().match(event.query.toLowerCase());
      })
    }
  }, 250);
}
</script>

<style scoped>

</style>