<template>
	<!-- <Toast /> -->
	<div v-if="editMode">
		<DataTable
			:value="docList"
			:paginator="false"
			:rows="10"
			dataKey="id"
			selectionMode="multiple"
			v-model:selection="selectedRow"
			:metaKeySelection="false"
			@rowSelect="onRowSelect"
			@rowUnselect="onRowUnselect"
			paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
			:rowsPerPageOptions="[10, 15, 20, 25, 30]"
			currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
		>
			<template #empty>No data found</template>
			<template #loading>Loading data, please wait...</template>
			<Column header="Line">
				<template #body="slotProps">
					<InputText
						class="w-full"
						:class="{
							'p-invalid':
								inputError &&
								inputError.some(
									(err) =>
										err.id === slotProps.data.id &&
										err.lineError
								),
						}"
						v-model="slotProps.data.ASN_LINE_NUM"
					/>
					<div
						class="mt-1"
						v-if="
							inputError &&
							inputError.some(
								(err) =>
									err.id === slotProps.data.id &&
									err.lineError
							)
						"
					>
						<small
							class="p-error font-bold"
							style="font-size: 12px"
						>
							{{
								inputError.find(
									(err) => err.id === slotProps.data.id
								)['lineError']
							}}
						</small>
					</div>
				</template>
			</Column>
			<Column header="Incoterm" field="ASN_FOB"> </Column>
			<Column header="Type">
				<template #body="slotProps">
					<Dropdown
						class="w-full"
						:options="types"
						v-model="slotProps.data.ASN_DOC_TYPE"
					/>
				</template>
			</Column>
			<Column header="Description">
				<template #body="slotProps">
					<InputText
						class="w-full"
						:class="{
							'p-invalid':
								inputError &&
								inputError.some(
									(err) =>
										err.id === slotProps.data.id &&
										err.descriptionError
								),
						}"
						v-model="slotProps.data.ASN_DOC_DESC"
					/>
					<div
						class="mt-1"
						v-if="
							inputError &&
							inputError.some(
								(err) =>
									err.id === slotProps.data.id &&
									err.descriptionError
							)
						"
					>
						<small
							class="p-error font-bold"
							style="font-size: 12px"
						>
							{{
								inputError.find(
									(err) => err.id === slotProps.data.id
								)['descriptionError']
							}}
						</small>
					</div>
				</template>
			</Column>
			<Column header="Original" field="NEED_ORI">
				<template #body="slotProps">
					<Dropdown
						class="w-full"
						:options="oriChoice"
						v-model="slotProps.data.NEED_ORI"
					/>
				</template>
			</Column>
			<Column header="Active">
				<template #body="slotProps">
					<Dropdown
						class="w-full"
						:options="activeChoice"
						v-model="slotProps.data.ACTIVE"
					/>
				</template>
			</Column>
		</DataTable>
	</div>

	<div v-if="!editMode">
		<DataTable
			:value="docList"
			:paginator="true"
			:rows="10"
			dataKey="id"
			v-model:selection="selectedRow"
			@page="onPage($event)"
			paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
			:rowsPerPageOptions="[10, 15, 20, 25, 30]"
			currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
		>
			<template #empty>No data found</template>
			<template #loading>Loading data, please wait...</template>
			<Column header="Line" field="ASN_LINE_NUM"> </Column>
			<Column header="Incoterm" field="ASN_FOB"> </Column>
			<Column header="Type" field="ASN_DOC_TYPE"> </Column>
			<Column header="Description" field="ASN_DOC_DESC"> </Column>
			<Column header="Original" field="NEED_ORI"> </Column>
			<Column header="Active" field="ACTIVE"> </Column>
		</DataTable>
	</div>
</template>

<script setup>
import { watch, defineEmits, defineProps, onMounted, ref } from 'vue';
import { searchDocSetup, getDocSetupTable } from '@/service/DocsetupService';
import AuthService from '@/service/AuthService';
// import { useToast } from "primevue/usetoast";

const incoterm = ref();
let editMode = ref(false);
onMounted(async () => {
	incoterm.value = props.incoterm;
	const auth = await AuthService.whoAmi();
	if (auth.status === 200) {
		sessionStorage.setItem('selectedRowsDocSetup', '[]');
		levelTenant.value = auth.data.data.leveltenant;
		await populateTable();
		// await onPage(event)
	}
});
// eslint-disable-next-line no-undef
const props = defineProps({
	column: {
		type: String,
	},
	columnSearch: {
		type: String,
	},
	company: {
		type: Object,
	},
	supplier: {
		type: Object,
	},
	newSearch: {
		type: String,
	},
	newRow: {
		type: String,
	},
	latestRow: {
		type: Object,
	},
	inputError: {
		type: null,
	},
});

watch(
	() => props.newSearch,
	async () => {
		await searchTable();
		// await insertNewRows()
	}
);
const deletedRow = ref([]);
watch(
	() => props.latestRow,
	async () => {
		deletedRow.value.push(props.latestRow);
		let alterDocList = docList.value;
		for (const i in docList.value) {
			for (const z in deletedRow.value) {
				if (
					alterDocList[i].id.toString() ===
					deletedRow.value[z].toString()
				) {
					delete alterDocList.splice([i], 1);
					docList.value = alterDocList;
					delete deletedRow.value[z];
					deletedRow.value.splice([z], 1);
					sessionStorage.setItem('selectedRowsDocSetup', '[]');
				}
			}
		}
	}
);
const emit = defineEmits(['passRows', 'passRowsB', 'addMode', 'rowInsert']);
watch(
	() => props.newRow,
	async () => {
		if (props.newRow !== 0) {
			editMode.value = true;
			emit('addMode', true);
			await insertNewRows();
		}
	}
);
const docList = ref();
let selectedRow = ref();
const loading = ref(true);
const levelTenant = ref();
const types = ref([]);
const oriChoice = ref(['Yes', 'No']);
const activeChoice = ref(['Yes', 'No']);

const populateTable = async () => {
	if (
		(props.column !== null && props.column !== undefined) ||
		props.incoterm
	) {
		docList.value = await getDocSetupTable();
	} else {
		docList.value = await getDocSetupTable();
		let typesArray = [];
		let uniqueId = 1;
		docList.value.forEach((el) => {
			el.id = uniqueId;
			typesArray.push(el.ASN_DOC_TYPE);
			uniqueId++;
		});
		types.value = [...new Set(typesArray)];
		if (types.value !== null) {
			loading.value = false;
		}
	}
};
let uniqueId = ref(1);
const searchTable = async () => {
	uniqueId.value = 1;
	if (props.newSearch !== undefined) {
		const payload = {
			inputSearch: props.newSearch,
		};
		const newTable = await searchDocSetup(payload);
		sessionStorage.setItem('arrayEditDocsetup', JSON.stringify(newTable));
		docList.value = JSON.parse(sessionStorage.getItem('arrayEditDocsetup'));
		// let typesArray = []

		docList.value.forEach((el) => {
			el.ASN_FOB = props.newSearch;
			el.id = uniqueId.value;
			uniqueId.value++;
		});
		return newTable;
	}
};

const onRowSelect = async () => {
	sessionStorage.setItem(
		'selectedRowsDocSetup',
		JSON.stringify(selectedRow.value)
	);
	emit('passRows', selectedRow.value);
};

const onRowUnselect = async (event) => {
	const storedRows = JSON.parse(
		sessionStorage.getItem('selectedRowsDocSetup')
	);
	for (const i in storedRows) {
		if (storedRows[i].ASN_SETUP_ID == event.data.ASN_SETUP_ID) {
			storedRows.splice([i], 1);
		}
	}
	sessionStorage.setItem('selectedRowsDocSetup', JSON.stringify(storedRows));
	emit('passRowsB', selectedRow.value);
};

const insertNewRows = async () => {
	let newItems = [];

	// let oldLength = docList.value.length
	// if (docList.value.length === oldLength) {
	//   docList.value.length++
	let unique = docList.value[docList.value.length-1].ASN_LINE_NUM
	let uniqueNum = unique + 1
	let emptyObject = {
		ASN_SETUP_ID: null,
		ACTIVE: 'No',
		ASN_DOC_DESC: '',
		ASN_LINE_NUM: uniqueNum,
		ASN_DOC_TYPE: 'AWB',
		ASN_FOB: props.newSearch,
		CREATED_BY: 1,
		LAST_UPDATED_BY: 1,
		NEED_ORI: 'No',
		id: uniqueId.value++,
		draftNewRow: 1,
	};
	newItems.push(JSON.parse(JSON.stringify(docList.value)));
	newItems[0].push(emptyObject);
	// var keys = Object.keys(newItems[0])
	// keys.sort((a, b) => {
	//   return newItems[0][b] - newItems[0][a];
	// })
	docList.value = newItems[0];
	// emit('rowInsert', keys.length)
};
const onPage = async () => {
	// 
};
</script>

<style scoped></style>
