import SpbInvoiceNonPO from "./api/SpbInvoiceApi";

class InvoiceNonPOSearch {
  async getBatchNumberwComp(payload) {
    return SpbInvoiceNonPO.post("spb/invoicenonpo/batchNumberwComp", payload);
  }
  async getBatchNumberwCompnInvoice(payload) {
    return SpbInvoiceNonPO.post(
      "spb/invoicenonpo/batchNumberwCompnInvoice",
      payload
    );
  }
  async getBatchNumberwCompnSupp(payload) {
    return SpbInvoiceNonPO.post(
      "spb/invoicenonpo/batchNumberwCompnSupp",
      payload
    );
  }
  async getBatchNumberwCompnInvoiceno(payload) {
    return SpbInvoiceNonPO.post(
      "spb/invoicenonpo/batchNumberwCompnInvoiceno",
      payload
    );
  }
  async getInvoiceNumberwComp(payload) {
    return SpbInvoiceNonPO.post("spb/invoicenonpo/invoiceNumberwComp", payload);
  }
  async getInvoiceNumberwCompnInvoice(payload) {
    return SpbInvoiceNonPO.post(
      "spb/invoicenonpo/invoiceNumberwCompnInvoice",
      payload
    );
  }
  async getInvoiceNumberwCompnSupp(payload) {
    return SpbInvoiceNonPO.post(
      "spb/invoicenonpo/invoiceNumberwCompnSupp",
      payload
    );
  }
  async getInvoiceNumberwCompnInvoiceno(payload) {
    return SpbInvoiceNonPO.post(
      "spb/invoicenonpo/invoiceNumberwCompnInvoiceno",
      payload
    );
  }
  async getInvoiceNumberwCompPic(payload) {
    return SpbInvoiceNonPO.post(
      "spb/invoicenonpo/invoiceNumberwCompPIC",
      payload
    );
  }
  async getInvoiceNumberwCompnInvoicePic(payload) {
    return SpbInvoiceNonPO.post(
      "spb/invoicenonpo/invoiceNumberwCompnInvoicePIC",
      payload
    );
  }
  async getInvoiceNumberwCompnSuppPic(payload) {
    return SpbInvoiceNonPO.post(
      "spb/invoicenonpo/invoiceNumberwCompnSuppPIC",
      payload
    );
  }
  async getInvoiceNumberwCompnInvoicenoPic(payload) {
    return SpbInvoiceNonPO.post(
      "spb/invoicenonpo/invoiceNumberwCompnInvoicenoPIC",
      payload
    );
  }
  async getTableInvoiceNonPO(payload) {
    return SpbInvoiceNonPO.post("spb/invoicenonpo/searchInvoiceNonPO", payload);
  }
  async getTableInvoiceNonPOpic(payload) {
    return SpbInvoiceNonPO.post(
      "spb/invoicenonpo/searchInvoiceNonPOpic",
      payload
    );
  }
  async getInvoiceNonPOById(id) {
    return SpbInvoiceNonPO.get("spb/searchinvoicenonpo/" + id);
  }
  async getListBankPIC(payload) {
    return SpbInvoiceNonPO.post("spb/searchinvoicenonpo/listbankPIC", payload);
  }
  async getSupplierSitePIC(payload) {
    return SpbInvoiceNonPO.post(
      "spb/searchinvoicenonpo/suppliersitePIC",
      payload
    );
  }
  async lineCategoryPIC(payload) {
    return SpbInvoiceNonPO.post(
      "spb/searchinvoicenonpo/linecategoryPIC",
      payload
    );
  }
  async costCenterPIC(payload) {
    return SpbInvoiceNonPO.post(
      "spb/searchinvoicenonpo/costcenterPIC",
      payload
    );
  }
  async getTermPIC(payload) {
    return SpbInvoiceNonPO.post("spb/searchinvoicenonpo/termPIC", payload);
  }
  async paymentMethodPIC(payload) {
    return SpbInvoiceNonPO.post(
      "spb/searchinvoicenonpo/paymentmethodPIC",
      payload
    );
  }
  async whtPIC(payload) {
    return SpbInvoiceNonPO.post("spb/searchinvoicenonpo/whtCodePIC", payload);
  }
  async vatPIC(payload) {
    return SpbInvoiceNonPO.post("spb/searchinvoicenonpo/vatCodePIC", payload);
  }
  async skNumberPIC(payload) {
    return SpbInvoiceNonPO.post("spb/searchinvoicenonpo/sknumbersPIC", payload);
  }
  async getListVendor(payload) {
    return SpbInvoiceNonPO.post(`spb/searchinvoicenonpo/listvendor`, payload);
  }
  async getReimburstGroups(payload) {
    return SpbInvoiceNonPO.post(
      `spb/searchinvoicenonpo/reimburstgroups`,
      payload
    );
  }

  async travelOrderPIC(payload) {
    return SpbInvoiceNonPO.post(
      "spb/searchinvoicenonpo/travelordersPIC",
      payload
    );
  }

  async accountPic(payload) {
    return SpbInvoiceNonPO.post(
      "spb/searchinvoicenonpo/accountdetail",
      payload
    );
  }

  async accountexistPic(payload) {
    return SpbInvoiceNonPO.post(
      "spb/searchinvoicenonpo/accountdetailexist",
      payload
    );
  }

  async updateInvoicenonPO(id, payload) {
    return SpbInvoiceNonPO.post(
      `spb/searchinvoicenonpo/updateinvoice/${id}`,
      payload
    );
  }

  async rejectInvoicenonPO(id, payload) {
    return SpbInvoiceNonPO.post(
      `spb/searchinvoicenonpo/rejectinvoice/${id}`,
      payload
    );
  }

  async getTermId(payload) {
    return SpbInvoiceNonPO.post("spb/searchinvoicenonpo/gettermid", payload);
  }

  async submitRekomendator(payload) {
    return SpbInvoiceNonPO.post(
      `spb/searchinvoicenonpo/submit-rekomendator`,
      payload
    );
  }

  async exportinvoice(payload) {
    return SpbInvoiceNonPO.post(
      "spb/searchinvoicenonpo/printinvoicenonpo",
      payload
    );
  }

  async getPoVendorSiteById(id) {
    return SpbInvoiceNonPO.get(
      `spb/searchinvoicenonpo/getpovendorsitebyid/${id}`
    );
  }
  async getListPoVendorSite(orgId, dbInstance, vendorId) {
    return SpbInvoiceNonPO.get(
      `spb/searchinvoicenonpo/getlistpovendorsite?orgId=${orgId}&dbInstance=${dbInstance}&vendorId=${vendorId}`
    );
  }
  // async getListVat(paramRet, dbInstance, invoiceDate) {
  //   return SpbInvoiceNonPO.get(
  //     `spb/searchinvoicenonpo/getListVat?paramRet=${paramRet}&dbInstance=${dbInstance}&invoiceDate=${invoiceDate}`
  //   );
  // }
  async getListVat(payload) {
    return SpbInvoiceNonPO.post(`spb/searchinvoicenonpo/getListVat`, payload);
  }
  async getListWht(orgId, dbInstance) {
    return SpbInvoiceNonPO.get(
      `spb/searchinvoicenonpo/getListWht?orgId=${orgId}&dbInstance=${dbInstance}`
    );
  }
  async getCoaById(id) {
    return SpbInvoiceNonPO.get(`spb/searchinvoicenonpo/getCoaById/${id}`);
  }
  async getListBank(orgId, dbInstance, vendorId) {
    return SpbInvoiceNonPO.get(
      `spb/searchinvoicenonpo/getListBank?orgId=${orgId}&dbInstance=${dbInstance}&vendorId=${vendorId}`
    );
  }
  async getListPaymentMethod(dbInstance, vendorId) {
    return SpbInvoiceNonPO.get(
      `spb/searchinvoicenonpo/getListPaymentMethod?vendorId=${vendorId}&dbInstance=${dbInstance}`
    );
  }
  async getListTerm(dbInstance, vendorId) {
    return SpbInvoiceNonPO.get(
      `spb/searchinvoicenonpo/getListTerm?vendorId=${vendorId}&dbInstance=${dbInstance}`
    )
  }
  async getListCostCenter(orgId, dbInstance) {
    return SpbInvoiceNonPO.get(
      `spb/searchinvoicenonpo/getListCostCenter?orgId=${orgId}&dbInstance=${dbInstance}`
    );
  }
  async getAttachmentFilePIC(payload) {
    return SpbInvoiceNonPO.get(`/invoicepo/attachmentfile/${payload}`);
  }
  async getAvailableCategoryDetails(payload) {
    return SpbInvoiceNonPO.post(
      `/createinvoicenonpo/availablecategorydetails`,
      payload
    );
  }
  async getCategoryVendor(payload) {
    return SpbInvoiceNonPO.post(`/createinvoicenonpo/categoryvendor`, payload);
  }
  async getListVendorFin(orgId, dbInstance) {
    return SpbInvoiceNonPO.get(
      `spb/searchinvoicenonpo/getListVendor?orgId=${orgId}&dbInstance=${dbInstance}`
    );
  }
  async getListCoa(orgId, dbInstance, costCenter) {
    return SpbInvoiceNonPO.get(
      `spb/searchinvoicenonpo/getListCoa?orgId=${orgId}&dbInstance=${dbInstance}&costCenter=${costCenter}`
    );
  }
  async getListGroup(type, dbInstance) {
    return SpbInvoiceNonPO.get(
      `spb/searchinvoicenonpo/getListGroup?type=${type}&dbInstance=${dbInstance}`
    );
  }

  async updateInvoiceFin(id, payload) {
    return SpbInvoiceNonPO.post(
      `spb/searchinvoicenonpo/updateinvoicefin/${id}`,
      payload
    );
  }
  async rejectInvoiceFin(id, payload) {
    return SpbInvoiceNonPO.post(
      `spb/searchinvoicenonpo/rejectinvoicefin/${id}`,
      payload
    );
  }
  async submitInvoiceNonPo(id, payload) {
    return SpbInvoiceNonPO.post(
      `spb/searchinvoicenonpo/submitinvoicenonpo/${id}`,
      payload
    );
  }

  async getListPayGroup(dbInstance) {
    return SpbInvoiceNonPO.get(
      `spb/searchinvoicenonpo/getListPayGroup?dbInstance=${dbInstance}`
    );
  }

  async getListCategoryDetail(orgId, dbInstance, categoryId) {
    return SpbInvoiceNonPO.get(
      `spb/searchinvoicenonpo/getListCategoryDetail?orgId=${orgId}&dbInstance=${dbInstance}&categoryId=${categoryId}`
    );
  }

  async getDetailCat(payload) {
    return SpbInvoiceNonPO.post(
      "spb/searchinvoicenonpo/getlistcategoryfin",
      payload
    );
  }
  async getDetailCatSK(payload) {
    return SpbInvoiceNonPO.post(
      "spb/searchinvoicenonpo/getListCategoryWSK",
      payload
    );
  }

  async getListDetailCat() {
    return SpbInvoiceNonPO.get(`spb/searchinvoicenonpo/getseqdetailcat`);
  }

  async getcategorybyid(id) {
    return SpbInvoiceNonPO.get(`spb/searchinvoicenonpo/getcategorybyid/${id}`);
  }

  async getFileBase64(invoiceId, leveltenant, vendorId) {
		return SpbInvoiceNonPO.get(`/invoicepo/getfilebase64/${invoiceId}/${leveltenant}/${vendorId}`);
	}
}

export default new InvoiceNonPOSearch();
