<template>
	<AutoComplete
		:suggestions="filteredVendor"
		field="vendor_name"
		@complete="searchCompany($event)"
		v-model="vendorValue"
		:disabled="!vendorName.length || props.category === CategoryEnum.Prepayment"
		forceSelection
	/>
</template>

<script setup>
import { onMounted, ref, defineEmits, watch } from 'vue';
import AuthService from '../../../service/AuthService';
import vendorServices from '../../../service/masterVendorService';
import {CategoryEnum} from '../../../constants/CategoryEnum'

// eslint-disable-next-line no-undef
const filteredVendor = ref([]);
const vendorName = ref([]);
const vendorValue = ref();
const emit = defineEmits(['remitVendor']);
// eslint-disable-next-line no-undef
const props = defineProps({
	company: { type: Object },
	db_instance: { type: Object },
	vendorName: { type: Object },
	category: {type: String}
});

onMounted(() => {
	populateData();
});

// watchEffect(() =>
//   emit('remitVendor',vendorValue),
// )
watch(
	() => vendorValue.value,
	(newValue) => {
		if (typeof newValue === 'object' && !Array.isArray(newValue)) {
			emit('remitVendor', vendorValue);
		}
	}
);

const populateData = () => {
	AuthService.whoAmi().then(async (response) => {
		let payload;
		if (props.company !== null) {
			payload = {
				db_instance: props.db_instance,
			};
			if (response.status === 200) {
				const data = await vendorServices.getVendor(payload);
				vendorName.value = data.data.data.data;

				for (const s of vendorName.value) {
					if (s.vendor_id === props.vendorName) {
						vendorValue.value = s;
						break;
					} else {
						vendorValue.value = null;
					}
				}
			}
		}
	});
};

const searchCompany = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredVendor.value = [...vendorName.value]
    } else {
      filteredVendor.value = vendorName.value.filter((comp) => {
        return comp.vendor_name.toLowerCase().match(event.query.toLowerCase());
      })
    }
  }, 250);
}
</script>

<style scoped></style>
