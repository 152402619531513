<template>
	<div class="grid list-invoice">
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<!-- Search input -->
		<div class="col-12 p-fluid mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search</div>
				<div v-if="currentUser" class="content-body">
					<div class="grid">
						<!-- Dropdown company -->
						<div class="col-12 md:col-6 lg:col-3 mb-4">
							<span class="p-float-label">
								<AutoComplete
									id="company"
									:class="
										currentUser.leveltenant === 1 &&
										'input-disabled'
									"
									:disabled="
										!companyList ||
										currentUser.leveltenant === 1
									"
									v-model="company"
									:suggestions="filteredCompany"
									@complete="searchCompany($event)"
									:dropdown="true"
									field="name"
									forceSelection
								/>
								<label for="company">Company</label>
							</span>
						</div>
						<!-- Show dropdown supplier if user is Purchasing -->
						<div
							v-if="!isUserSupplier"
							class="col-12 md:col-6 lg:col-3"
						>
							<span class="p-float-label">
								<SupplierDropdown
									v-if="company"
									:placeholder="''"
									:company="company"
									v-model="supplier"
								/>
								<label>Select a supplier</label>
							</span>
						</div>
						<!-- Dropdown status -->
						<div class="col-12 md:col-6 lg:col-3 mb-4">
							<span class="p-float-label">
								<Dropdown
									id="status"
									:options="statusList"
									optionLabel="name"
									v-model="status"
								/>
								<label for="status">Status</label>
							</span>
						</div>

						<!-- Search Buttons -->
						<div class="col-12 lg:col-4">
							<Button
								v-if="!isLoading"
								class="p-button-success w-auto mr-2"
								:disabled="disableSearchButton"
								@click="handleSearch"
							>
								<span class="font-bold uppercase">Search</span>
							</Button>
							<Button
								v-else
								class="p-button-success w-auto mr-2"
								disabled
							>
								<span class="font-bold uppercase"
									>Searching...</span
								>
							</Button>
							<Button
								class="w-auto p-button-secondary"
								@click="handleClear"
							>
								<span class="font-bold uppercase">Clear</span>
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Search results -->
		<div class="col-12 p-fluid mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search Results</div>
				<div class="content-body">
					<div class="grid">
						<!-- Action button -->
						<div
							v-if="!isUserSupplier"
							class="col-auto ml-auto mb-4"
						>
							<RouterLink
								:to="{ name: 'CreateSpeReport' }"
								class="p-button p-button-success"
							>
								<span class="uppercase font-bold"
									>Create Report</span
								>
							</RouterLink>
						</div>

						<!-- Data table -->
						<div class="col-12">
							<DataTable
								dataKey="id"
								responsiveLayout="scroll"
								ref="dt"
								:lazy="true"
								:paginator="true"
								:rows="5"
								:rowsPerPageOptions="[5, 10, 20, 50]"
								:loading="isLoading"
								:totalRecords="totalRecords"
								:value="reportList"
								:scrollable="true"
								scrollHeight="600px"
								scrollDirection="both"
								paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
								currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
								@page="handlePagination($event)"
							>
								<!-- Results Data -->
								<Column header="No SPE" field="spe_no" style="width: 15%"/>
								<Column header="Supplier" field="vendor_name" style="width: 20%"/>
								<Column header="Status" style="width: 20%">
									<template #body="{ data }">
										<Chip
											class="custom-chip"
											:class="`chip-${data.status_name.toLowerCase()}`"
										>
											{{ data.status_name }}
										</Chip>
									</template>
								</Column>
								<Column header="Grade" field="grade_name" style="width: 20%">
									<template #body="{ data }">
										<p class="flex align-items-center jus">
											<span>{{ data.grade_name }}</span>
											<Badge
												class="ml-2"
												:severity="
													data.grade === 'B' ||
													data.grade === 'P'
														? 'danger'
														: 'success'
												"
											></Badge>
										</p>
									</template>
								</Column>
								<Column header="Period" field="period_name" style="width: 20%" />
								<Column header="Actions">
									<template #body="{ data }">
										<Button
											icon="pi pi-eye"
											class="p-button-rounded p-button-text"
											@click="
												handleClickDetail(data.spe_id)
											"
										/>
									</template>
								</Column>

								<!-- Empty statement -->
								<template #empty> No data found. </template>
							</DataTable>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import SimpleCrypto from 'simple-crypto-js';
import AuthService from '../../service/AuthService';
import LovService from '../../service/LovService';
import PlanningOrderService from '../../service/PlanningOrderService';
import PoFulfillment from '../../service/PoFulfillment';
import SpeReportService from '../../service/SpeReportService';
import SupplierDropdown from '../components/SupplierDropdown.vue';

// Composables
const router = useRouter();
const route = useRoute();

// State
const currentUser = ref(null);
const company = ref(null);
const vendorId = ref(null);
const companyList = ref(null);
const filteredCompany = ref(null);
const status = ref(null);
const statusList = ref(null);
const supplier = ref(null);
const reportList = ref(null);
const totalRecords = ref(0);
const isLoading = ref(false);
const lazyParams = ref(null);
const dt = ref(null);
const breadcrumbHome = ref({ icon: 'pi pi-home', to: '/dashboard' });
const breadcrumbItems = ref([
	{
		label: 'SPE Report',
		to: route.fullPath,
	},
]);

// Computed for disable search button
const disableSearchButton = computed(() => {
	if (currentUser.value.leveltenant === 1) {
		return !company.value || !statusList.value;
	} else {
		return !company.value || !vendorId.value || !statusList.value;
	}
});

// Compute whether user is supplier
const isUserSupplier = computed(
	() => currentUser.value && currentUser.value.leveltenant === 2
);

// Mounted
onMounted(async () => {
	const resAuth = await AuthService.whoAmi();
	currentUser.value = resAuth.data.data;
	console.log('Current user', currentUser.value);

	// Check if user is not supplier nor finance nor admin
	if (
		currentUser.value.leveltenant !== 2 &&
		currentUser.value.leveltenant !== 1 &&
		currentUser.value.userid !== 'dexaadmin'
	) {
		// Redirect to homepage if user is purchasing and not admin
		router.push('/dashboard');
		return;
	}

	// Set lazy params
	lazyParams.value = {
		first: 0,
		page: 0,
		rows: dt.value.rows,
	};

	if (currentUser.value.leveltenant === 2) {
		// Get company and vendor ID if user is supplier
		await getCompany();
		await getVendorId();
	} else {
		// Get company
		await getCompany();
	}

	// Get status list
	getStatusList();
});

// Belum butuh watch disini
// // Watch selected company change (for supplier users)
// const unwatch = watch(company, (newCompany, oldCompany) => {
// 	// Watch if old company is not null
// 	if (oldCompany) {
// 		// Reset other input fields
// 		status.value = null;
// 	}
// });

// Get company list
const getCompany = async () => {
	if (currentUser.value.userid === 'dexaadmin') {
		// If user is admin
		const companyRes = LovService.getValueType('COMPANY');
		companyList.value = companyRes.data.data;
	} else if (currentUser.value.leveltenant === 2) {
		// If user is supplier
		let payload = currentUser.value.id;
		const companySuppRes = await PoFulfillment.getCompanySupp(payload);
		console.log('Company supp', companySuppRes);
		
		try {
			const companySupp = companySuppRes.data.data;
			const companyValues = await Promise.all(
				companySupp.map(async (element) => {
					const res = await LovService.getCompanyByName(
						element.company
					);

					console.log(res.data.data[0], "RESPONSE GET BY NAME")
					const secretKey = 'some-unique-key';
					const simpleCrypto = new SimpleCrypto(secretKey);
					const decipherText = simpleCrypto.decrypt(
						sessionStorage.getItem('dropdownCompany')
					);

					if (res.data.data[0].name === decipherText) {
						company.value = res.data.data[0];
					}

					return res.data.data[0];
				})
			);

			companyList.value = companyValues;
		} catch (e) {
			console.log(e);
			if (e !== 'Break') throw e;
		}
	} else if (currentUser.value.leveltenant === 1) {
		// If user is Finance
		const res = await LovService.getcompanydb(currentUser.value.bioaddress);

		companyList.value = res.data.data;

		try {
			const secretKey = 'some-unique-key';
			const simpleCrypto = new SimpleCrypto(secretKey);
			const decipherText = simpleCrypto.decrypt(
				sessionStorage.getItem('dropdownCompany')
			);
			companyList.value.forEach((element) => {
				if (element.name === decipherText) {
					company.value = element;
					throw 'Break';
				}
			});
		} catch (e) {
			console.log(e);
			if (e !== 'Break') throw e;
		}
	}
};

// Get vendor id of logged in supplier
const getVendorId = async () => {
	// Get vendor id
	const resVendorName = await PoFulfillment.getSuppBindComp(
		currentUser.value.id
	);
	console.log(company.value, "THIS IS COMPANY VALUE")
	console.log('isi res user', resVendorName.data.data[0][0].vendorname);
	const payloadSuppVal = {
		vendorName: resVendorName.data.data[0][0].vendorname,
	};

	const resVendorId = await PlanningOrderService.getSuppValue(payloadSuppVal,company.value.db_instance);
	console.log(resVendorId.data.data, "RESP VENDOR SUPP VALUE")
	vendorId.value = resVendorId.data.data[0].vendor_id;
	console.log("MASUK KESINI GA YA")
	console.log('vendor id', vendorId.value);
};

// Get status list
const getStatusList = async () => {
	try {
		const params = 'STATUSSPEREPORT';

		const res = await SpeReportService.getStatusList(params);

		if (res.status === 200) {
			// Skip status Draft for user Supplier
			if (currentUser.value.leveltenant === 2) {
				statusList.value = res.data.data.filter(
					(item) => item.value !== 'D'
				);
			} else {
				statusList.value = res.data.data;
			}
		}
	} catch (err) {
		console.log(err);
	}
};

// Search company on autocomplete
const searchCompany = (event) => {
	if (!event.query.trim().length) {
		filteredCompany.value = [...companyList.value];
	} else {
		filteredCompany.value = companyList.value.filter((item) => {
			return item.name.toLowerCase().match(event.query.toLowerCase());
		});
	}
};

// Search supplier on autocomplete
// const searchSupplier = (event) => {
// 	if (!event.query.trim().length) {
// 		filteredSupplier.value = [...supplierList.value];
// 	} else {
// 		filteredSupplier.value = supplierList.value.filter((item) => {
// 			return (
// 				item.vendor_name &&
// 				item.vendor_name.toLowerCase().match(event.query.toLowerCase())
// 			);
// 		});
// 	}
// };

// Handle pagination
const handlePagination = (event) => {
	lazyParams.value = event;
	handleSearch();
};

// Search invoice
const handleSearch = async () => {
	// Create payload
	const payload = {
		dbInstance: company.value.db_instance,
		orgId: company.value.value,
		vendorId: null,
		status: status.value?.value || null,
		supplier: supplier.value?.vendor_id || null,
		levelTenant: currentUser.value.leveltenant,
	};

	if (isUserSupplier.value) {
		payload.vendorId = vendorId.value;
	}

	console.log('payload search', payload);

	// Query params
	const params = {
		query: JSON.stringify({
			limit: lazyParams.value.rows,
			page: lazyParams.value.page + 1,
		}),
	};

	try {
		isLoading.value = true;
		const res = await SpeReportService.getReportList(params, payload);
		console.log('Search res', res);

		if (res.status === 200) {
			reportList.value = res.data.data.map((item) => {
				const statusName = statusList.value.find(
					(val) => val.value === item.status
				)['name'];

				return {
					...item,
					status_name: statusName,
				};
			});
			totalRecords.value = res.data.count;
		}

		isLoading.value = false;
	} catch (err) {
		console.log(err);
		isLoading.value = false;
	}
};

// Clear search
const handleClear = () => {
	status.value = null;
	supplier.value = null;
	reportList.value = null;
	totalRecords.value = 0;
};

// Handle click detail
const handleClickDetail = (id) => {
	router.push({
		name: 'DetailSpeReport',
		params: { id },
		query: { v: company.value.value, i: company.value.db_instance },
	});
};
</script>

<style scoped>
.p-badge {
	min-width: 6px;
	width: 6px;
	height: 6px;
}
.p-chip.custom-chip {
	padding: 4px 12px;
	border-radius: 8px;
	font-size: 12px;
	font-weight: 600;
}

.chip-submitted,
.chip-confirmed {
	background: #0ea5e9;
	color: #fff;
}
.chip-closed {
	background: #10b981;
	color: #fff;
}
</style>
