<template>
	<AutoComplete
		:suggestions="filteredCcOwner"
		:dropdown="true"
		@complete="searchCcOwner($event)"
		field="USER_EMAIL"
		placeholder=" "
		forceSelection
		:disabled="!props.company"
	>
		<template #item="slotProps">
			<div> {{slotProps.item.USER_EMAIL}} </div>
		</template>
	</AutoComplete>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { getCCOwner } from '@/service/InvoiceProxyNonPOServices';

// eslint-disable-next-line no-undef
const props = defineProps({
	company: {
		type: Object,
	},
});

const filteredCcOwner = ref();
const ccOwnerList = ref();

onMounted(async () => {
	if (props.company) {
		const payload = {
			cc_company: props.company.company_code,
			cc_value: props.company.segment4
		}

		const resCcOwnerList = await getCCOwner(payload);
		console.log(resCcOwnerList.data, "DATA DOG")
		ccOwnerList.value = resCcOwnerList.data.data;
	}
});

const searchCcOwner = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredCcOwner.value = [...ccOwnerList.value];
		} else {
			filteredCcOwner.value = ccOwnerList.value.filter((w) => {
				return w.USER_EMAIL
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
		}
	}, 250);
};

console.log(props.company, "FILTERED CC OWNER")
</script>

<style scoped></style>
