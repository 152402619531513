<template>
  <AutoComplete
    :suggestions="filteredSuppliers"
    @complete="searchSupplier($event)"
    :dropdown="true"
    field="vendor_name"
    :placeholder="placeholder"
    forceSelection
  >
    <template #item="slotProp">
      {{ slotProp.item.vendor_name }}
    </template>
  </AutoComplete>
</template>

<script setup>
import { onMounted, ref } from "vue";
import AuthService from "@/service/AuthService";
import LovService from "@/service/LovService";

// eslint-disable-next-line no-undef
const props = defineProps({
  company: {
    type: Object,
  },
});
// eslint-disable-next-line no-undef
const filteredSuppliers = ref([]);
const suppliers = ref([]);
const placeholder = ref("Select a supplier");

onMounted(async () => {
  populate();
});

const populate = () => {
  AuthService.whoAmi().then(async (response) => {
    if (props.company !== null && props.company !== undefined) {
      const payload = {
        db_instance: props.company.db_instance,
        org_id: props.company.value,
      };
      if (response.status === 200) {
        LovService.getSupplierName(payload).then((response) => {
          const type = typeof response.data.data;
          if (type === "string") {
            placeholder.value = "No supplier found";
          } else {
            
						let supp = response.data.data;
						let supList = [];
						supp.forEach(element => {
							if(element.vendor_name != null){
								supList.push(element)
							}
						});
            suppliers.value = supList;
          }
        });
      }
    }
  });
};

const searchSupplier = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredSuppliers.value = [...suppliers.value];
    } else {
      filteredSuppliers.value = suppliers.value.filter((supp) => {
        if (supp.vendor_name === null || supp.vendor_name === undefined) {
          return supp;
        }
        return supp.vendor_name
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};
</script>
