<template>
	<!-- eslint-disable -->
	<Toast />
	<div class="Grid">
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<!-- Search -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search INVOICE</div>
				<div class="content-body">
					<div class="col-12 p-fluid">
						<div class="grid formgrid" style="margin: 1em 0 0 0">
							<div class="col-12 mb-5 lg:col-3">
								<CompaniesDropdown
									v-model="company"
									class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
									:disabled="
										!(userInfo
											? userInfo.leveltenant === 2
											: false)
									"
								/>
							</div>
							<div
								v-if="!isSupplierpage"
								class="col-12 mb-5 lg:col-3"
							>
								<SupplierDropdown
									v-if="
										!(userInfo
											? userInfo.leveltenant === 2
											: false)
									"
									v-model="supplier"
									:company="company ? company : null"
									class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
									:key="supplierReload"
								/>
							</div>
							<!-- <div class="col-12 mb-5 lg:col-3 lg:mb-5">
								<BatchNumber
									v-model="batchNumber"
									:company="company ? company : null"
									:supplier="supplier ? supplier : null"
									:poNumber="poNumber ? poNumber : null"
									class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
									:key="batchReload"
								/>
							</div> -->
							<div class="col-12 mb-5 lg:col-3 lg:mb-5">
								<InvoiceNumber
									:company="company ? company : null"
									:supplier="supplier ? supplier : null"
									:batchNumber="
										batchNumber ? batchNumber : null
									"
									@invoiceNum="getInvoiceNum"
									class="w-full mb-2 lg:w-auto lg:mr-3 lg:mb-0"
									:key="ponumberReload"
								/>
							</div>

							<div class="col-12 mb-5 lg:col-3 lg:mb-5">
								<AutoComplete 
									:dropdown="true"
									placeholder="Invoice Type" 
									class="w-full"
									field="name"
									v-model="poType"
									:suggestions="filteredPoType"
									@complete="searchPoType($event)"
									forceSelection
								/>
							</div>

							<!-- <div class="col-12 mb-5 lg:col-3 lg:mb-5">
								<span class="p-float-label">
									<Dropdown
										id="status"
										:options="listStatus"
										optionLabel="value"
										:disabled="!listStatus"
										v-model="status"
									/>
									<label for="status">Status</label>
								</span>
							</div> -->

							<div class="mr-3 ml-2">
								<Button
									@click="handleSearch()"
									class="p-button-success uppercase"
									label="search"
									:disabled="loading || !company"
								/>
							</div>
							<div>
								<Button
									@click="clearFilter()"
									class="p-button-secondary uppercase"
									label="clear"
									:disabled="!company"
								></Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Search results -->
		<div class="col-12">
			<div class="content-wrapper">
				<div class="content-header">Search Result</div>
				<div class="content-body">
					<div class="mt-4">
						<TableInvoiceWPO
							:poType="poType ? poType : null"
							:company="company ? company : null"
							:supplier="supplier ? supplier : null"
							:batchNumber="batchNumber ? batchNumber : null"
							:poNumber="poNumber ? poNumber : null"
							:status="status ? status.value : null"
							:key="reloadTable"
							@loading="getLoading"
						></TableInvoiceWPO>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
import { ref, watch } from 'vue';
import TableInvoiceWPO from '@/components/searchinvoicewithpo/components/TableSearchInvoiceWPO';
import AuthService from '../../service/AuthService';
import LovService from '../../service/LovService';
import SimpleCrypto from 'simple-crypto-js';
import CompaniesDropdown from '@/components/components/CompaniesDropdown';
import SupplierDropdown from '@/components/components/SupplierDropdown';
import BatchNumber from '@/components/searchinvoicewithpo/components/BatchNumber';
import InvoiceNumber from './components/InvoiceNumber';
import { InvoiceTypeEnum } from '../../constants/POTypeEnum';
import InvoicePOService from '../../service/InvoicePOService';

export default {
	name: 'searchInvoiceWithPo',
	components: {
    CompaniesDropdown,
    SupplierDropdown,
    TableInvoiceWPO,
    BatchNumber,
    InvoiceNumber
},
	data() {
		return {
			isSupplierpage: false,
			first: 0,
			rows: 10,
			rowId: null,
			selectedPo: null,
			loading: false,
			lazyParams: {},
			selectLazyParams: {},
			company: null,
			companyValue: true,
			filteredCompany: null,
			isiCompany: null,
			supplier: null,
			isisupplier: null,
			filteredSupplier: null,
			selectedSupplier: null,
			supplierparse: null,
			filtered: null,
			buttonSave: true,
			buttonConfirm: true,
			items: null,
			isEmpty: null,
			poType: null,
			reload: 0,
			poNumber: null,
			batchNumber: null,
			isButtonSearchDisable: null,
			status:null,
			filteredPoType: [],
			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [
				{
					label: 'Search Invoice With PO',
					to: '/mgm/monitor/invoicepo',
				},
			],
			supplierReload: ref(0),
			batchReload: ref(0),
			ponumberReload: ref(0),
			reloadTable: ref(0),
			poTypeList: [
				{id: 1, name: InvoiceTypeEnum.Prepayment},
				{id: 2, name: InvoiceTypeEnum.Procurement},
				{id: 3, name: InvoiceTypeEnum.Standard},
				{id: 4, name: InvoiceTypeEnum.PrepaymentProxy},
				{id: 5, name: InvoiceTypeEnum.ProcurementProxy},
				{id: 6, name: InvoiceTypeEnum.StandardProxy}
			],
			listStatus: null,
		};
	},
	watch: {
		status(text){
			console.log(text, "LOAD STATUS")
		},
		poNumber(text){
			console.log(text, "PO NUM")
		},
		company(newQuestion) {
			if (
				typeof newQuestion === 'object' &&
				!Array.isArray(newQuestion)
			) {
				this.supplier = null;
				this.supplierReload++;
				this.ponumberReload++;
				this.batchReload++;
			}
		},
		supplier(newQuestion) {
			// if (this.company !== null){
			if (
				typeof newQuestion === 'object' &&
				!Array.isArray(newQuestion)
			) {
				this.batchNumber = null;
				this.poNumber = null;
				this.ponumberReload++;
				this.batchReload++;
			}
		},

		batchNumber(newQuestion) {
			if (
				typeof newQuestion === 'object' &&
				!Array.isArray(newQuestion)
			) {
				if (this.company !== null && this.poNumber === null) {
					this.ponumberReload++;
				} else if (this.supplier !== null && this.poNumber === null) {
					this.ponumberReload++;
				} else if (
					this.supplier !== null &&
					this.batchNumber !== null &&
					this.poNumber !== null
				) {
					this.ponumberReload++;
				}
			}
		},
		poNumber(newQuestion) {
			if (
				typeof newQuestion === 'object' &&
				!Array.isArray(newQuestion)
			) {
				if (this.company !== null && this.poNumber !== null) {
					this.batchReload++;
				} else if (
					this.supplier !== null &&
					this.batchNumber === null
				) {
					this.batchReload++;
				} else if (
					this.supplier !== null &&
					this.poNumber !== null &&
					this.batchNumber !== null
				) {
					this.batchReload++;
				}
			}
		},
		// loading(){
		//   if
		// }
	},

	computed: {},

	created() {
		AuthService.whoAmi().then(async (response) => {
			
			function userCompany() {
				const secretKey = 'some-unique-key';
				const simpleCrypto = new SimpleCrypto(secretKey);
				return simpleCrypto.decrypt(
					sessionStorage.getItem('dropdownCompany')
				);
			}

			if (response.status === 200) {
				this.userInfo = response.data.data;
	
				// await this.getStatusInvoice();
				if (this.userInfo.leveltenant === 2) {
					//get company
					const respCbN = await LovService.getCompanyByName(
						userCompany()
					);
					this.company = respCbN.data.data[0];
					//
					//get supplier
					const param = {
						db_instance: this.company.db_instance,
						org_id: this.company.value,
					};
					const respVendor = await LovService.getSuppBindComp(
						response.data.data.id
					);
					// const vendorName = respVendor.data.data[0][0].vendorname;
					const vendorId = respVendor.data.data[0].vendor_id;
					console.log('cek vendor id', vendorId);

					const respSuppName = await LovService.getSupplierName(
						param
					);
					if (respSuppName.status === 200) {
						const suppList = respSuppName.data.data;
						for (const s of suppList) {
							if (s.vendor_id === vendorId) {
								this.supplier = s;
								break;
							}
						}
						if (this.supplier === null) {
							this.$router.push('/dashboard');
							window.alert(
								'Connection Unstable, ID not Recognize'
							);
						}
					}
				} else {
					const respCbN = await LovService.getCompanyByName(
						userCompany()
					);
					this.company = respCbN.data.data[0];
					this.reloadTable++;
					// const response = await LovService.getcompanydb(this.userInfo.bioaddress);
					// const companies = response.data.data;
					// for (const c of companies) {
					//   if (parseInt(c.value) === this.userInfo.idsubtenant) {
					//     this.company = c;
					//     break;
					//   }
					// }
				}
			}
		});
	},
	async mounted() {
		this.isEmpty = 'Please Search for Data';
		// this.loading = true;
		this.lazyParams = {
			first: 0,
			rows: this.rows,
			sortField: null,
			sortOrder: null,
		};
	
	},

	methods: {
		async getStatusInvoice(){
			try {
				console.log("GET STATUS INVOICE")
				const res = await InvoicePOService.getStatusInvoice();
				if (res.status === 200) {
					this.listStatus = res.data.data;
				}
			} catch (err) {
				console.log(err);
			}
		},
		searchPoType(event){
			this.filteredPoType = this.poTypeList.filter((item) => item.name.toLowerCase().includes(event.query.toLowerCase()))
		},
		getInvoiceNum(item){
			this.poNumber = item.value.INVOICE_NO
		},
		getLoading(value) {
			this.loading = value.value;
		},
		onPage(event) {
			this.lazyParams = event;
			this.handleSearchPo(false);

			//
		},
		onSort(event) {
			this.lazyParams = event;
			this.handleSearchPo(false);
		},

		formatDateDexa(date) {
			var date = new Date(date);
			var respondDate =
				('00' + (date.getMonth() + 1)).slice(-2) +
				'/' +
				('00' + date.getDate()).slice(-2) +
				'/' +
				date.getFullYear() +
				' ' +
				('00' + date.getHours()).slice(-2) +
				':' +
				('00' + date.getMinutes()).slice(-2) +
				':' +
				('00' + date.getSeconds()).slice(-2);
			return respondDate;
		},
		handleSearch() {
			this.reloadTable++;
		},
		clearFilter() {
			this.batchNumber = null;
			this.poNumber = null;
			this.batchReload++;
			this.ponumberReload++;
			this.poType = null
			this.reloadTable++;
			if (this.userInfo.leveltenant === 1) {
				this.supplier = null;
				this.supplierReload++;
			}
		},
	},
};
</script>
<style scoped lang="scss"></style>
