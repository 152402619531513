<template>
	<div class="grid">
		<div class="col-12 p-fluid">
			<div class="card">
				<div class="grid formgrid" style="margin: 1em 0 0 0">
					<div class="col-12 mb-5 lg:col-3 lg:mb-0">
						<span class="p-float-label">
							<Dropdown
								v-model="dropdownItem"
								:options="dropdownItems"
								optionLabel="name"
							></Dropdown>
							<label for="inputtext">Supplier</label>
						</span>
					</div>
					<div class="col-12 mb-5 lg:col-2 lg:mb-0">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								type="text"
								v-model="search"
								@keydown.enter="searchFilter"
							/>
							<label for="inputtext">Search PO Number</label>
						</span>
					</div>
					<div class="col-12 mb-2 lg:col-2 lg:mb-0">
						<span class="p-float-label">
							<Dropdown
								v-model="dropdownItem"
								:options="dropdownItems"
								optionLabel="name"
							></Dropdown>
							<label for="inputtext">Status</label>
						</span>
					</div>
					<div class="col-12 mb-2 lg:col-1 lg:mb-0">
						<Button label="Go" @click="searchFilter"></Button>
					</div>
					<div class="col-12 mb-2 lg:col-1 lg:mb-0">
						<Button
							class="p-button-secondary"
							label="Clear"
							@click="clearFilter"
						></Button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div
		class="card"
		style="background-color: #478fcd; height: 4em; margin: 0 0 2em 0"
	>
		<h4 style="color: white; margin: -0.4rem 0 0 0">Search Result</h4>
	</div>

	<DataTable
		:value="filtered ? filtered : nested"
		v-model:expandedRows="expandedRows"
		dataKey="id"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[5, 10, 15]"
		:paginator="true"
		rows="3"
		removableSort
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
		responsiveLayout="scroll"
	>
		<Column :expander="true" headerStyle="width: 3rem" />
		<Column field="po_num" header="PO NUM" :sortable="true"></Column>
		<template #expansion="slotProps">
			<div class="orders-subtable">
				<DataTable
					:value="slotProps.data.children"
					removableSort
					responsiveLayout="scroll"
				>
					<Column
						field="id"
						header="Line"
						:sortable="true"
						style="text-align: center"
					></Column>
					<Column
						field="itemnum"
						header="Item Number"
						:sortable="true"
					></Column>
					<Column
						field="itemdesc"
						header="Item Description"
						:sortable="true"
					></Column>
					<Column
						field="uom"
						header="UOM"
						:sortable="true"
						style="text-align: center"
					></Column>
					<Column
						field="ship"
						header="Location"
						:sortable="true"
						headerStyle="min-width:3rem;"
					></Column>
					<Column
						field="currency"
						header="Currency"
						:sortable="true"
						style="text-align: center"
					></Column>
					<Column
						field="currency"
						header="Unit Price"
						:sortable="true"
						style="text-align: center"
					></Column>
					<Column
						field="cprice"
						header="Confirm Price"
						:sortable="true"
						style="text-align: center"
					></Column>
					<Column
						field="cqty"
						header="Confirm Qty"
						:sortable="true"
						style="text-align: center"
					></Column>
					<Column
						field="cdate"
						header="Confirm Arrival at Site"
						:sortable="true"
						style="text-align: center"
					></Column>
					<Column
						field="ponum"
						header="PO Number"
						:sortable="true"
						style="text-align: center"
					></Column>
					<Column
						field="status"
						header="Line Status"
						:sortable="true"
						style="text-align: center"
					></Column>
					<Column headerStyle="width:4rem">
						<template #body>
							<Button icon="pi pi-search" />
						</template>
					</Column>
				</DataTable>
			</div>
		</template>
	</DataTable>
</template>

<script>
/* eslint-disable */
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import PlanningOrder from '../../service/PlanningOrderService';
import { reactive, ref, toRef, unref } from '@vue/reactivity';
export default {
	name: 'planningorder-list',
	data() {
		return {
			expandedRowGroups: null,
			expandedRows: [],
			nested: [],
			// loading: false,
			tabelexpand: null,
			search: null,
			filtered: null,
			breadcrumbHome: { icon: 'pi pi-home', to: '/' },
			breadcrumbItems: [{ label: 'Dashboard' }],
			items: [
				{
					label: 'Select Column',
					icon: 'pi pi-table',
				},
				{
					label: 'Filter',
					icon: 'pi pi-filter',
				},
				{
					label: 'Format',
					icon: 'pi pi-times',
				},
				{
					label: 'Save Report',
					icon: 'pi pi-save',
				},
				{
					label: 'Download',
					icon: 'pi pi-download',
				},
				{
					separator: true,
				},
				{
					label: 'Home',
					icon: 'pi pi-home',
				},
			],
		};
	},

	created() {
		// this.GetTableNested();
	},
	mounted() {},
	mounted() {},
	computed: {
		searchUser() {
			const searchLc = this.search.toLowerCase();
			return this.nested.filter((item) => {
				return item.po_num.toLowerCase().includes(searchLc);
			});
		},
	},
	methods: {
		GetTableNested() {
			PlanningOrder.GetTableNested().then((response) => {
				this.nested = response.data.nestedData;
			});
		},
		onRowExpand(event) {
			this.$toast.add({
				severity: 'info',
				summary: 'Product Expanded',
				detail: event.data.name,
				life: 3000,
			});
		},
		onRowCollapse(event) {
			this.$toast.add({
				severity: 'success',
				summary: 'Product Collapsed',
				detail: event.data.name,
				life: 3000,
			});
		},
		searchFilter() {
			this.filtered = this.searchUser;
		},
		clearFilter() {
			this.filtered = null;
			this.search = null;
		},
	},
};
</script>
