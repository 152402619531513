function pad2(n) {
    return (n < 10 ? '0' : '') + n;
}


function formatDate(date) {
    var month = pad2(date.getMonth() + 1); //months (0-11)
    var day = pad2(date.getDate()); //day (1-31)
    var year;
    if (date.getFullYear() < 0) {
        year = date.getFullYear() * -1;
    } else {
        year = date.getFullYear();
    }
    var combinedNeedDate = new Date(month + '/' + day + '/' + year);
    let formattedDate = [];
    formattedDate[0] =
        combinedNeedDate.getDate() +
        '-' +
        combinedNeedDate.toString().substr(4, 3) +
        '-' +
        combinedNeedDate.getFullYear();
    formattedDate[1] =
        combinedNeedDate.getMonth() +
        1 +
        '/' +
        combinedNeedDate.getDate() +
        '/' +
        combinedNeedDate.getFullYear();

    return formattedDate;
}



export const FunctionCreatePO = {
    pushDraftB: (select, topValue) => {
        for (const reqData of select) {
            delete reqData['resv_1'];
            delete reqData['resv_2'];
            reqData['ori_qty'] = reqData['pr_qty'];
            reqData['uom'] = reqData['plan_uom'];
            reqData['plan_order_date'] =
                new Date().toLocaleDateString('en-US');
            reqData['response_date'] =
                new Date().toLocaleDateString('en-US');
            reqData['pr_num_and_line'] =
                reqData['pr_num'] +
                '/' +
                reqData['pr_line_num'];
            reqData['top_name'] = topValue.find(
                (value) => value?.term_id == reqData['terms_id']
            )?.name;
            reqData['need_by_date'] = formatDate(
                new Date(reqData['need_by_date'])
            )[1];
        }


        return select;
    }
}