<template>
    <AutoComplete :suggestions="filteredInvoiceDropdown" @complete="searchInvoice($event)" :dropdown="true" field="INVOICE_NO"
        :placeholder="placeholder" forceSelection>
        <template #item="slotProp">
            {{ slotProp.item.INVOICE_NO }}
        </template>
    </AutoComplete>
</template>

<script setup>
import { onMounted, ref } from "vue";
import AuthService from "../../../service/AuthService";
import InvoiceNonPOservice from "../../../service/InvoiceNonPOservices";

const isiInvoiceNumber = ref([]);
const filteredInvoiceDropdown = ref([]);
const placeholder = ref("Select a Invoice Number");

// eslint-disable-next-line no-undef
const props = defineProps({
  company: {
    type: Object,
  },
  supplier: {
    type: Object,
  },
  batchdropdown: {
    type: Object,
  },
});

onMounted(()=>{
    populateInvoice()
});

const populateInvoice = () => {
    AuthService.whoAmi().then(async (response) => {
        if (response.data.data.leveltenant === 1) {
            // 
            let payload
            if (props.company !== null && props.supplier !== null && props.batchdropdown !== null) {
                payload = {
                    org_id: props.company.value,
                    supValue: props.supplier.vendor_id,
                    batch_no: props.batchdropdown.BATCH_NO,
                    db_instance: props.company.db_instance
                }
                // 
                if (response.status === 200) {
                    const data = await InvoiceNonPOservice.getInvoiceNumberwCompnInvoiceno(payload)
                    // 
                    isiInvoiceNumber.value = data.data.data.data
                    // 
                }
            } else if (props.company !== null && props.supplier !== null) {
                payload = {
                    org_id: props.company.value,
                    supValue: props.supplier.vendor_id,
                    db_instance: props.company.db_instance
                }
                // 
                if (response.status === 200) {
                    const data = await InvoiceNonPOservice.getInvoiceNumberwCompnSupp(payload)
                    isiInvoiceNumber.value = data.data.data.data
                    // 
                }
            } else if (props.company !== null && props.batchdropdown !== null) {
                payload = {
                    org_id: props.company.value,
                    batch_no: props.batchdropdown.BATCH_NO,
                    db_instance: props.company.db_instance,
                }
                // 
                if (response.status === 200) {
                    const data = await InvoiceNonPOservice.getInvoiceNumberwCompnInvoice(payload)
                    isiInvoiceNumber.value = data.data.data.data
                    // 
                }
            } else if (props.company !== null) {
                payload = {
                    org_id: props.company.value,
                    db_instance: props.company.db_instance
                }
                // 
                if (response.status === 200) {
                    const data = await InvoiceNonPOservice.getInvoiceNumberwComp(payload)
                    // 
                    isiInvoiceNumber.value = data.data.data.data
                    // 
                }
            }

        } else if (response.data.data.leveltenant === 3) {
            let payload
            if (props.company !== null && props.supplier !== null && props.batchdropdown !== null) {
                payload = {
                    org_id: props.company.value,
                    supValue: props.supplier.vendor_id,
                    batch_no: props.batchdropdown.BATCH_NO,
                    db_instance: props.company.db_instance
                }
                // 
                if (response.status === 200) {
                    const data = await InvoiceNonPOservice.getInvoiceNumberwCompnInvoicenoPic(payload)
                    // 
                    isiInvoiceNumber.value = data.data.data.data
                    // 
                }
            } else if (props.company !== null && props.supplier !== null) {
                payload = {
                    org_id: props.company.value,
                    supValue: props.supplier.vendor_id,
                    db_instance: props.company.db_instance
                }
                // 
                if (response.status === 200) {
                    const data = await InvoiceNonPOservice.getInvoiceNumberwCompnSuppPic(payload)
                    isiInvoiceNumber.value = data.data.data.data
                    // 
                }
            } else if (props.company !== null && props.batchdropdown !== null) {
                payload = {
                    org_id: props.company.value,
                    batch_no: props.batchdropdown.BATCH_NO,
                    db_instance: props.company.db_instance,
                    userId: response.data.data.id,
                    companyname: props.company.name
                }
                // 
                if (response.status === 200) {
                    const data = await InvoiceNonPOservice.getInvoiceNumberwCompnInvoicePic(payload)
                    isiInvoiceNumber.value = data.data.data.data
                    // 
                }
            } else if (props.company !== null) {
                payload = {
                    org_id: props.company.value,
                    db_instance: props.company.db_instance,
                    userId: response.data.data.id,
                    companyname: props.company.name
                }
                // 
                if (response.status === 200) {
                    const data = await InvoiceNonPOservice.getInvoiceNumberwCompPic(payload)
                    // 
                    isiInvoiceNumber.value = data.data.data.data
                    // 
                }
            }
        }

    })
};

const searchInvoice = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
        filteredInvoiceDropdown.value = [...isiInvoiceNumber.value]
    } else {
        filteredInvoiceDropdown.value = isiInvoiceNumber.value.filter((comp) => {
        return comp.INVOICE_NO.toLowerCase().match(event.query.toLowerCase());
      })
    }
  }, 250);
}
</script>