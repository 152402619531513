<template>
    <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
            <div class="col-12 xl:col-6" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);">
                <div class="h-full w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                    <div class="text-center mb-5">
                        <img :src="'layout/images/DXG Supplier Portal dan Billing Invoice 03-06.png'" alt="Image" class="mb-5" style="width:181px; height:100px;">
                        <div class="text-900 text-3xl font-medium mb-3"></div>
                        <span class="text-600 font-medium">Forgot Password</span>
                    </div>
                
                    <div class="w-full md:w-10 mx-auto">
                        <label for="bioname" class="block text-900 text-xl font-medium mb-2">Username</label>
                        <InputText id="bioname" v-model="userid" type="text" class="w-full mb-3" placeholder="Insert your username..." style="padding:1rem;" @keydown.enter="submit()" />
                        <!-- <label for="email" class="block text-900 text-xl font-medium mb-2">Email</label>
                        <InputText id="email" v-model="email" type="text" class="w-full mb-3" placeholder="Insert your email..." style="padding:1rem;" /> -->
                        <router-link to="/login"><Button label="Back" class="w-3 p-3 mt-4 text-xl p-button-warning"></button></router-link>
                        <Button label="Submit" class="w-8 p-3 mt-4 ml-3 text-xl" @click="submit()"></button>
                        <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id">{{msg.content}}</Message>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ForgotPasswordService from '../service/ForgotPasswordService'
// import DefaultMenu from '../app/_files/appadmin.json';
export default {
    data() {
        return {
            // email: '',
            userid: null,
            messages: [],
            count: 0
        }
    },
    mounted(){
        //nothing
    },
    methods: {
        async submit() {
            console.clear();
            const payload = {
                userid: this.userid
            };

            const response = await ForgotPasswordService.forgotPass(payload)
            
            // 
            if (response.data.status === 200) {
                this.messages = [{
                    severity: 'info',
                    content: 'Email Sent',
                    id: 1
                }, ]
                setTimeout(() => {
                    this.$router.push('/login');
                }, 3000);
            } else {
                this.messages = [{
                    severity: 'error',
                    content: 'User Not Found',
                    id: 1
                }, ]
            }
        }
    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return 'white';
            return 'dark';
        }
    }
}
</script>

<style scoped>
.pi-eye {
    transform:scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform:scale(1.6);
    margin-right: 1rem;
}
</style>