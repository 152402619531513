<template>
	<div></div>
</template>

<script>
	export default {
		name: "AppFooter",
		methods: {
			// footerImage() {
			// 	return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
			// }
		},
		computed: {
			darkTheme() {
				return this.$appState.darkTheme;
			}
		}
	}
</script>