<template>
	<div class="grid">
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<div class="col-12">
			<div class="content-wrapper mb-4">
				<div class="content-header">LIST NOTA DEBIT</div>
				<div class="content-body">
					<div class="grid">
						<div class="col-12 md:col-3 p-fluid mb-3">
							<span class="p-float-label">
								<AutoComplete
									v-model="company"
									:disabled="disableComp"
									:suggestions="filteredCompanies"
									@complete="searchCompany($event)"
									:dropdown="true"
									field="name"
									placeholder="Select a Company"
									forceSelection
								>
									<template #item="slotProp">
										{{ slotProp.item.name }}
									</template>
								</AutoComplete>
							</span>
						</div>
						<div
							class="col-12 md:col-3 p-fluid mb-3"
							v-if="!isSupplier"
						>
							<span class="p-float-label">
								<AutoComplete
									v-model="supplier"
									:key="supplierReload"
									:suggestions="filteredSupplier"
									@complete="searchSupplier($event)"
									:dropdown="true"
									field="vendor_name"
									placeholder="Select a Supplier"
									forceSelection
								>
									<template #item="slotProp">
										{{ slotProp.item.vendor_name }}
									</template>
								</AutoComplete>
							</span>
						</div>
						<div class="col-12 md:col-3">
							<span class="p-float-label">
								<Calendar
									v-model="debitFrom"
									dateFormat="d-M-yy"
									:showIcon="true"
									class="w-full mb-3 lg:mb-0"
								/>
								<label for="inputtext"
									>Debit Note Date From</label
								>
							</span>
						</div>
						<div class="col-12 md:col-3">
							<span class="p-float-label">
								<Calendar
									v-model="debitTo"
									dateFormat="d-M-yy"
									:showIcon="true"
									class="w-full mb-3 lg:mb-0"
								/>
								<label for="inputtext"
									>Debit Note Date To</label
								>
							</span>
						</div>
						<div class="col-12 md:col-3 p-fluid">
							<span class="p-float-label">
								<DnNo
									v-model="dnNumber"
									:key="reloadDnNumber"
									:company="company ? company : null"
									:supplier="supplier ? supplier : null"
								/>
							</span>
						</div>
						<div class="col-12 md:col-3">
							<span class="p-float-label">
								<Button
									icon="pi pi-search"
									label="find"
									:disabled="disableSearch"
									class="uppercase p-button-success"
									@click="findDebit()"
								/>
							</span>
						</div>
					</div>
				</div>
			</div>
			<!-- search result -->
		</div>
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search Results</div>
				<div class="content-body">
					<div class="mt-2">
						<TableListNota
							:debitFrom="debitFrom ? debitFrom : null"
							:debitTo="debitTo ? debitTo : null"
							:company="company ? company : null"
							:supplier="supplier ? supplier : null"
							:batchdropdown="
								batchdropdown ? batchdropdown : null
							"
							:invoiceDropdown="
								invoiceDropdown ? invoiceDropdown : null
							"
							:dnNumber="dnNumber ? dnNumber : null"
							:key="reloadTable"
							@result="getLoading"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import TableListNota from './components/TableListNota.vue';
import DnNo from './components/DnNo.vue';
import LovService from '../../service/LovService';
import PoFulfillment from '../../service/PoFulfillment';
import SimpleCrypto from 'simple-crypto-js';
import AuthService from '@/service/AuthService';

const debitFrom = ref();
const debitTo = ref();
const currentUser = ref();
const companyList = ref();
const company = ref();
// const vendorId= ref();
const disableSearch = ref(true);
const filteredCompanies = ref([]);
const isSupplier = ref(false);
const supplier = ref();
const suppliers = ref([]);
const filteredSupplier = ref([]);
const reloadTable = ref(0);
const dnNumber = ref();
const reloadDnNumber = ref(0);
const supplierReload = ref(0);
const disableComp = ref(false);

const breadcrumbHome = { icon: 'pi pi-home', to: '/dashboard' };
const breadcrumbItems = [
	{
		label: 'List Nota Debit',
		to: '/mgm/monitor/eventloglist',
	},
];

watch(
	() => company.value,
	() => {
		supplierReload.value++;
		dnNumber.value = null;
		reloadDnNumber.value++;
	}
);

watch(
	() => company.value && supplier.value,
	() => {
		dnNumber.value = null;
		reloadDnNumber.value++;
	}
);

onMounted(async () => {
	await getCompany();

	if (currentUser.value.leveltenant === 2) {
		const companyRes = getCompany();
		const vendorRes = getVendorId();
		isSupplier.value = true;
		disableComp.value = false;
		await Promise.all([companyRes, vendorRes]);
	} else if (currentUser.value.leveltenant === 1) {
		disableSearch.value = false;
		disableComp.value = true;
		await getVendorId();
	}
});

const getCompany = async () => {
	const userLogin = await AuthService.whoAmi();
	if (userLogin.status === 200) {
		currentUser.value = userLogin.data.data;

		if (currentUser.value.userid === 'dexaadmin') {
			// If user is admin
			const companyRes = LovService.getValueType('COMPANY');
			companyList.value = companyRes.data.data;
		} else if (currentUser.value.leveltenant === 2) {
			// If user is supplier
			let payload = currentUser.value.id;
			const companySuppRes = await PoFulfillment.getCompanySupp(payload);

			try {
				const companySupp = companySuppRes.data.data;
				const companyValues = await Promise.all(
					companySupp.map(async (element) => {
						const res = await LovService.getCompanyByName(
							element.company
						);

						const secretKey = 'some-unique-key';
						const simpleCrypto = new SimpleCrypto(secretKey);
						const decipherText = simpleCrypto.decrypt(
							sessionStorage.getItem('dropdownCompany')
						);

						if (res.data.data[0].name === decipherText) {
							company.value = res.data.data[0];
						}

						return res.data.data[0];
					})
				);

				companyList.value = companyValues;
			} catch (e) {
				if (e !== 'Break') throw e;
			}
		} else if (currentUser.value.leveltenant === 1) {
			// If user is Finance
			const res = await LovService.getcompanydb(
				currentUser.value.bioaddress
			);

			companyList.value = res.data.data;

			try {
				const secretKey = 'some-unique-key';
				const simpleCrypto = new SimpleCrypto(secretKey);
				const decipherText = simpleCrypto.decrypt(
					sessionStorage.getItem('dropdownCompany')
				);
				companyList.value.forEach((element) => {
					if (element.name === decipherText) {
						company.value = element;
						throw 'Break';
					}
				});
			} catch (e) {
				if (e !== 'Break') throw e;
			}
		}
	}
};

// Get VendorId
const getVendorId = async () => {
	let payload = {
		db_instance: company.value.db_instance,
		org_id: company.value.value,
	};
	const response = await LovService.getSupplierName(payload);
	let supp = response.data.data;
	let supList = [];
	supp.forEach((element) => {
		if (element.vendor_name != null) {
			supList.push(element);
		}
	});
	suppliers.value = supList;
	if (currentUser.value.leveltenant === 2) {
		disableSearch.value = true;
		const param = {
			db_instance: company.value.db_instance,
			org_id: company.value.value,
		};

		const respVendor = await PoFulfillment.getSuppBindComp(
			currentUser.value.id
		);
		const vendorName = respVendor.data.data[0][0].vendorname;
		const respSuppName = await LovService.getSupplierName(param);
		if (respSuppName.status === 200) {
			const suppList = respSuppName.data.data;
			for (const s of suppList) {
				if (s.vendor_name === vendorName) {
					supplier.value = s;
					disableSearch.value = false;
					break;
				}
			}
		}
	}
};

//search debit
const findDebit = async () => {
	reloadTable.value++;
};

const searchCompany = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredCompanies.value = [...companyList.value];
		} else {
			filteredCompanies.value = companyList.value.filter((comp) => {
				return comp.name
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
		}
	}, 250);
};

const searchSupplier = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredSupplier.value = [...suppliers.value];
		} else {
			filteredSupplier.value = suppliers.value.filter((supp) => {
				if (
					supp.vendor_name === null ||
					supp.vendor_name === undefined
				) {
					return supp;
				}
				return supp.vendor_name
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
		}
	}, 250);
};
</script>
