<template>
	<!-- eslint-disable -->
	<div class="grid">
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<!-- Search -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Batch Detail</div>
				<div class="content-body">
					<div class="col-12 p-fluid">
						<div class="grid formgrid" style="margin: 1em 0 0 0">
							<div class="col-12 mb-5 lg:col-3">
								<span class="p-float-label">
									<InputText
										id="vendorName"
										v-model="vendorName"
										disabled
										type="text"
									/>
									<label for="inputtext">Vendor Name</label>
								</span>
							</div>
							<div class="col-12 mb-5 lg:col-3">
								<span class="p-float-label">
									<InputText
										id="companyName"
										v-model="companyName"
										disabled
										type="text"
									/>
									<label for="inputtext">Company Name</label>
								</span>
							</div>
							<div class="col-12 mb-5 lg:col-3">
								<span class="p-float-label">
									<InputText
										id="batchNo"
										v-model="batchNo"
										disabled
										type="text"
									/>
									<label for="inputtext">Batch No</label>
								</span>
							</div>
							<div class="col-12 mb-5 lg:col-3">
								<span class="p-float-label">
									<InputText
										id="statusBatch"
										v-model="statusBatch"
										disabled
										type="text"
									/>
									<label for="inputtext">Status</label>
								</span>
							</div>
						</div>
						<DetailsBatchView
							:column="column ? column.col : null"
							:columnSearch="columnSearch"
							:company="company ? company : null"
							:supplier="supplier ? supplier : null"
							:key="reload"
						></DetailsBatchView>
					</div>
					<div class="col-6 mb-2 lg:col-1 lg:mb-0">
						<Button
							:class="uppercase"
							class="p-button-secondary"
							label="Back"
							@click="back()"
						>
						</Button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useRoute, useRouter } from 'vue-router';
import AuthService from '@/service/AuthService';
// import LovService from "@/service/LovService";
// import PoFulfillment from "@/service/PoFulfillment";
import DetailsBatchView from '../components/DetailsBatchView.vue';
import BatchListService from '../../../service/batchList';

const company = ref();
const supplier = ref();
const vendorName = ref(0);
const companyName = ref(0);
const batchNo = ref(0);
const statusBatch = ref(0);
const userInfo = ref();
const route = useRoute();
const router = useRouter();
const batch_id = ref(route.params.batch_id);
const toast = useToast();
const reload = ref(0);
const breadcrumbHome = { icon: 'pi pi-home', to: '/dashboard' };
const breadcrumbItems = [
	{
		label: 'Detail Bacth List',
		to: route.fullPath,
	},
];

AuthService.whoAmi().then(async (response) => {
	if (response.status === 200) {
		userInfo.value = response.data.data;
		const levelTenant = response.data.data.leveltenant;
		await getData(levelTenant);
	}
});
const getData = async (levelTenant) => {
	try {
		let result = null;
		if (levelTenant === 2) {
			const vendorId = sessionStorage.getItem('vendorId');
			result = await BatchListService.getBatchListSupp(
				batch_id.value,
				vendorId
			);
		} else {
			result = await BatchListService.getBatchList(batch_id.value);
		}
		console.log('result batch list', result)
		if (result.data.data.status !== 'Error') {
			const type = typeof result;
			if (type === 'object') {
				vendorName.value = result.data.data.data[0].vendor_name;
				companyName.value = result.data.data.data[0].NAME;
				batchNo.value = result.data.data.data[0].BATCH_NO;
				statusBatch.value = result.data.data.data[0].STATUSBATCH;
				reload.value++
			}
		} else {
			toast.add({
				severity: 'error',
				summary: 'Failed',
				detail: 'Batch Not Found',
				life: 3000,
			});
			// router.push('/dashboard');
		}
	} catch (error) {
		// console.log('error try', error)
		toast.add({
			severity: 'error',
			summary: 'Failed',
			detail: 'Batch Not Found',
			life: 3000,
		});
		// router.push('/dashboard');
	}
};
const back = async () => {
	router.push({
		name: 'BatchList',
	});
};

// onMounted(async () => {
//   company.value = props.company;
//   const info = await AuthService.whoAmi();
//   if (info.status === 200) {
//     levelTenant.value = info.data.data.leveltenant;
//     if (levelTenant.value !== 2) {
//       loading.value = true;
//       supplier.value = props.supplier;
//       await populateTable();
//     } else {
//       if (company.value && !supplier.value) {
//         const param = {
//           db_instance: props.company.db_instance,
//           org_id: props.company.value,
//         };
//         const respVendor = await PoFulfillment.getSuppBindComp(
//           info.data.data.id
//         );
//         const vendorName = respVendor.data.data[0][0].vendorname;
//         const respSuppName = await LovService.getSupplierName(param);
//         if (respSuppName.status === 200) {
//           const suppList = respSuppName.data.data;
//           for (const s of suppList) {
//             if (s.vendor_name === vendorName) {
//               supplier.value = s;
//               break;
//             }
//           }
//         }
//         await populateTable();
//       }
//       // loading.value = true
//     }
//   }
// });
// eslint-disable-next-line no-undef
// const props = defineProps({
//   company: {
//     type: Object,
//   },
//   supplier: {
//     type: Object,
//   },
// });

// const loading = ref(false);
// const levelTenant = ref();

// const populateTable = async () => {
//   loading.value = true;
//   // const id = this.idValue;
//   const detailView = await BatchListService.getDetailBatchListView;
//   loading.value = false;
// };
</script>
