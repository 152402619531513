<template>
    <span class="p-float-label">
        <AutoComplete
        v-model="supplierSitesValue"
        :suggestions="filteredSuppSite"
        :dropdown="true"
        @complete="searchSuppSite($event)"
        @item-select="getValue($event)"
        field="suppliersite"
        forceSelection
        >
        <template #item="slotProp">
            {{ slotProp.item.vendor_site_code }}
        </template>
        </AutoComplete>
        <label for="inputtext">Supplier Site</label>
    </span>
</template>

<script setup>
import {onMounted, ref, watchEffect, defineEmits} from 'vue'
import AuthService from '../../../service/AuthService';
import InvoiceNonPOservice from "../../../service/InvoiceNonPOservice";

// eslint-disable-next-line no-undef
const props = defineProps({
  db_instance: {type: Object},
  org_id: {type: Object},
  vendor_id:{type: Object},
  supplier_Site:{type: Object},
  disabled: {
    type : Boolean
  }
})

// eslint-disable-next-line no-undef
const filteredSuppSite = ref([])
const supplierSiteList = ref([])
const supplierSitesValue = ref();
const supplierSites = ref();
const emit = defineEmits(['supplierSite'])

onMounted(() => {
    populateData()
})

// watch(
//   () => supplierSitesValue.value,
//   () => {
//     if (supplierSitesValue.value.length === 0) {
//       supplierSites.value = null
//       emit("supplierSite", supplierSites)
//     }
//   }
// );

watchEffect(() => 
  // 
  emit("supplierSite", supplierSites)
)

const populateData = () => {
    AuthService.whoAmi().then(async (response) => {
        if (response.status === 200){
            const payload = {
                org_id: props.org_id,
                vendor_id: props.vendor_id,
                db_instance: props.db_instance,
            };
            const res = await InvoiceNonPOservice.getSupplierSitePIC(payload);
            supplierSiteList.value = res.data.data
            for (const s of supplierSiteList.value) {
              if (s.vendor_site_id === props.supplier_Site) {
                supplierSitesValue.value = s.vendor_site_code;
                supplierSites.value = s
                emit("supplierSite", supplierSites)
                break;
              }
              else{
                supplierSitesValue.value = null;
                supplierSites.value = null
                emit("supplierSite", supplierSites)
              }
            }
        }
    })
}

const searchSuppSite = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredSuppSite.value = [...supplierSiteList.value];
    } else {
      filteredSuppSite.value = supplierSiteList.value.filter((comp) => {
        return comp.vendor_site_code
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};

const getValue = (event) => {
  supplierSitesValue.value = event.value.vendor_site_code
  supplierSites.value = event.value
  emit("supplierSite", supplierSites)
}

</script>

<style scoped>
</style>