<template>
	<div class="grid">
		<Toast />
		<div class="col-12 mb-5">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">
					Search
				</div>
				<div class="content-body">
					<div class="grid">
						<div class="col-12 lg:col-3">
							<span class="p-float-label">
								<AutoComplete class="w-full" v-model="company" :suggestions="companies" field="name"
									:dropdown="true" forceSelection disabled id="company" />
								<label for="company">Company</label>
							</span>
						</div>
						<div class="col-8 lg:col-3">
							<span class="p-float-label">
								<AutoComplete v-model="supplier" :suggestions="filteredSupplier"
									@complete="searchSupplier($event)" :dropdown="true" field="name" class="w-full"
									:disabled="!isisupplier" @change="check" forceSelection>
								</AutoComplete>
								<label for="inputtext">Supplier</label>
							</span>
							<p style="display:none;">{{ txt }}</p>
						</div>
						<div class="col-8 lg:col-3">
							<div class="p-float-label">
								<span class="p-float-label">
									<AutoComplete class="w-full" :dropdown="true" v-model="poNum"
										:suggestions="filteredPoNum" @complete="searchPoNum($event)" field="SEGMENT1"
										forceSelection :disabled="!supplier" id="PONUM" />
									<label for="PONUM">PO Number</label>
								</span>
							</div>
						</div>
						<div class="col-12 lg:col-3">
							<Button :class="uppercase" class="p-button-danger mr-2" @click="searchPO"
								:disabled="this.disableButton">
								<span class="uppercase font-bold">Search PO</span>
							</Button>
							<Button :class="uppercase" class="p-button-secondary" @click="handleReset"><span
									class="uppercase font-bold">Clear</span></Button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">
					Search Result Header
				</div>

				<div class="content-body">
					<div class="grid align-items-start">
						<div class="col-12 lg:col-6">
							<div class="grid align-items-center">
								<div class="col-12 lg:col-4">
									<label style="color: #828282" class="mr-4 font-bold text-lg" for="rev">Rev</label>
								</div>
								<div class="col-12 lg:col-6">
									<InputText class="w-full" id="rev" type="text" v-model="selectedPO.REVISION_NUM"
										disabled />
								</div>
							</div>
							<div class="grid align-items-center">
								<div class="col-12 lg:col-4">
									<label style="color: #828282" class="mr-4 font-bold text-lg"
										for="supplier">Supplier</label>
								</div>
								<div class="col-12 lg:col-6">
									<InputText class="w-full" id="supplier" type="text" disabled
										v-model="selectedPO.VENDOR_NAME" />
								</div>
							</div>
							<div class="grid align-items-center">
								<div class="col-12 lg:col-4">
									<label style="color: #828282" class="mr-4 font-bold text-lg" for="shipto">Ship
										To</label>
								</div>
								<div class="col-12 lg:col-6">
									<InputText class="w-full" id="shipto" type="text" disabled
										v-model="selectedPO.SHIP_TO_NAME" />
								</div>
							</div>
							<div class="grid align-items-center">
								<div class="col-12 lg:col-4">
									<label style="color: #828282" class="mr-4 font-bold text-lg"
										for="shipto">Status</label>
								</div>
								<div class="col-12 lg:col-6">
									<InputText class="w-full" id="shipto" type="text" disabled value="DRAFT" />
								</div>
							</div>
						</div>

						<div class="col-12 lg:col-6">
							<!-- <div class="grid align-items-center">
								<div class="col-12 lg:col-4">
									<label
										style="color: #828282"
										class="mr-4 font-bold text-lg"
										for="podata"
										>PO Data</label
									>
								</div>
								<div class="col-12 lg:col-6">
									<InputText
										class="w-full"
										id="podata"
										type="text"
										disabled
                                        v-model="selectedPO.PO_HEADER_ID"
									/>
								</div>
							</div> -->
							<div class="grid align-items-center">
								<div class="col-12 lg:col-4">
									<label style="color: #828282" class="mr-4 font-bold text-lg"
										for="buyer">Buyer</label>
								</div>
								<div class="col-12 lg:col-6">
									<InputText class="w-full" id="buyer" type="text" disabled
										v-model="selectedPO.BUYER_NAME" />
								</div>
							</div>
							<div class="grid align-items-center">
								<div class="col-12 lg:col-4">
									<label style="color: #828282" class="mr-4 font-bold text-lg"
										for="currency">Currency</label>
								</div>
								<div class="col-12 lg:col-6">
									<InputText class="w-full" id="currency" type="text" disabled
										v-model="selectedPO.CURRENCY_CODE" />
								</div>
							</div>
							<div class="grid align-items-center">
								<div class="col-12 lg:col-4">
									<label style="color: #828282" class="mr-4 font-bold text-lg"
										for="amount">Amount</label>
								</div>
								<div class="col-12 lg:col-6">
									<InputText class="w-full" id="amount" type="text" disabled v-model="amount" />
								</div>
							</div>
						</div>


					</div>
				</div>
			</div>
		</div>

		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">
					Search Result List
				</div>
				<div class="content-body">
					<!-- v-model:selection="selectedPo" -->
					<div class="col-12">
						<DataTable ref="dt" dataKey="id" :metaKeySelection="false" :value="poItems"
							responsiveLayout="scroll" :scrollable="true" scrollHeight="600px" selectionMode="multiple"
							v-model:selection="selectedItem">
							<Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>
							<Column class="call-button-container" header="Line" field="LINE_NUM" :sortable="true">
							</Column>
							<Column class="call-button-container" header="Item Description" field="ITEM_DESCRIPTION">
							</Column>
							<Column class="call-button-container" header="PO Quantity" field="QUANTITY"></Column>
							<Column class="call-button-container" header="Pay Item" field="SHIPMENT_NUM"
								:sortable="true"></Column>
							<Column class="call-button-container" header="Termin Description" field="TERMIN"></Column>
							<Column class="call-button-container" header="Price" field="PRICE_OVERRIDE">
								<template #body="{ data }">
									<span>{{ formatCurrency(data.PRICE_OVERRIDE) }}</span>
								</template>
							</Column>
							<Column class="call-button-container" header="Amount">
								<template #body="{ data }">
									<span>{{ formatCurrency(data.PRICE_OVERRIDE * data.QUANTITY) }}</span>
								</template>
							</Column>
							<Column class="call-button-container" header="Confirm Quantity" field="CONFIRM_QUANTITY">
								<template #body="slotProp">
									<InputText class="w-full" id="confirm_quantity" type="number"
										v-model="slotProp.data.CONFIRM_QUANTITY" @change="handleChangeQuantity(slotProp.data.CONFIRM_QUANTITY,
											slotProp.index)" />
								</template>
							</Column>
							<Column class="call-button-container" header="Progress (%)" field="CONFIRM_PERCENTAGE">
								<template #body="slotProp">
									<InputText class="w-full" id="confirm_percentage" type="number"
										v-model="slotProp.data.CONFIRM_PERCENTAGE" @change="handleChangePercentage(slotProp.data.CONFIRM_PERCENTAGE,
											slotProp.index)" />
								</template>
							</Column>
							<Column class="call-button-container" header="Confirm Amount" field="CONFIRM_AMOUNT">
								<template #body="{ data }">
									<span>{{ formatCurrency(data.CONFIRM_AMOUNT ?? 0) }}</span>
								</template>
							</Column>
							<Column class="call-button-container" header="Total Delivered" field="TOTAL_DELIVERED">
								<template #body="{ data }">
									<span>{{ formatCurrency(data.TOTAL_PROGRESS ?? 0) }}</span>
								</template>
							</Column>
							<Column class="call-button-container" header="Total Progress (%)" field="TOTAL_PROGRESS">
								<template #body="{ data }">
									<span>{{ formatCurrency(data.TOTAL_PROGRESS ?? 0) }}</span>
								</template>
							</Column>
							<!-- Empty state -->
							<template #empty>
								{{ emptyMessage }}
							</template>
						</DataTable>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">
					Upload File
				</div>

				<div class="content-body">

					<div class="grid mb-5">
						<div class="lg:col-4 col-12">
							<span class="p-float-label">
								<InputText class="w-full" id="bast_number" type="text" v-model="numBAST" />
								<label for="bast_number">BAST Number</label>
							</span>
						</div>
					</div>

					<FileUpload id="uploadFile" ref="inputFile" chooseLabel="Choose File" uploadLabel="Upload File"
						fileLimit="1" accept="application/pdf" @select="onSelectFile" @remove="onRemoveFile"
						:customUpload="true" :maxFileSize="5000000" :showUploadButton="false" :showCancelButton="false"
						v-tooltip.left="'Max file 5MB'">
						<template #empty>
							<p>No file selected. (Max file 5MB)</p>
						</template>
					</FileUpload>
					<div style="padding: 0rem 1rem" v-if="loadingUpload">
						<ProgressSpinner style="width: 25px; height: 25px" strokeWidth="6" />
					</div>
					<p style="padding: 0rem 1rem" v-if="fileUploaded">
						{{ fileUploaded.filename }} has been uploaded
					</p>
				</div>
			</div>
		</div>

		<div class="col-12 mb-4">
			<div class="grid" v-if="isEdit === false">
				<div class="col-12 lg:col-3">
					<Button label="Save" :disabled="progress" class="w-full p-button-info" @click="saveBAST('L')" />
				</div>
				<div class="col-12 lg:col-3">
					<Button label="Submit" :disabled="progress" class="w-full p-button-success" @click="submitBAST" />
				</div>
				<div class="col-12 lg:col-3">
					<Button label="Back" @click="this.$router.back()" class="w-full p-button-primary" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AuthService from '../../../service/AuthService';
import PlanningOrderService from '../../../service/PlanningOrderService';
import SimpleCrypto from "simple-crypto-js";
import LovService from '../../../service/LovService';
import BASTService from '../../../service/BASTService';
import PoFulfillment from '../../../service/PoFulfillment'
import Swal from "sweetalert2"


export default {
	data() {
		return {
			params: this.$route.params.id,
			company: null,
			selectedCompany: null,
			txt: null,
			poNum: null,
			numBAST: null,
			companies: [],
			bastNum: [],
			isisupplier: [],
			selectedItem: [],
			selectedPO: {
				PO_HEADER_ID: null,
				REVISION_NUM: null,
				SHIP_TO_NAME: null,
				CURRENCY_CODE: null,
				VENDOR_NAME: null,
				ORDER_DATE: null,
				BUYER_NAME: null,
				SEGMENT1: null,
				JIT_COLUMN: null,
				EMAIL_REQUESTOR: [],
				EMAIL_PURCHASING: []
			},
			supplier: null,
			poNumList: [],
			filteredPoNum: [],
			vendorId: sessionStorage.getItem('vendorId'),
			amount: 0,
			breadcrumbHome: { icon: "pi pi-home", to: "/dashboard" },
			breadcrumbItems: [
				{
					label: "Create BAST",
					to: '/mgm/settings/createbast',
				},
			],
			poNumberList: [],
			poItems: [],
			submitApi: null,
			poValue: null,
			orgId: null,
			file: null,
			totalAmount: 0,
			loadingUpload: false,
			isEdit: false,
			bastId: null,
			progress: false,
			errorList: [],
			companyData: null
		}
	},
	async mounted() {
		AuthService.whoAmi().then(async (response) => {
			function userCompany() {
				const secretKey = 'some-unique-key';
				const simpleCrypto = new SimpleCrypto(secretKey);
				return simpleCrypto.decrypt(
					sessionStorage.getItem('dropdownCompany')
				);
			}

			console.log(userCompany(), "COMP")

			await this.getVendorList(userCompany());

			this.sender = response.data.data.bioemailactive;
			this.dbInstance = response.data.data.bioaddress;
			this.usersenderid = response.data.data.id;
			this.levelTenant = response.data.data.leveltenant;
			const respCbN = await LovService.getCompanyByName(
				userCompany()
			);

			this.companies = respCbN.data.data[0];
			this.orgId = respCbN.data.data[0].value
			this.company = userCompany();

			sessionStorage.setItem('mailToCompany', userCompany());

			if (response.status !== 200) return;

			if (response.data.data.leveltenant === 2) {
				const payload = {
					db_instance: sessionStorage.getItem('companydb'),
					org_id: respCbN.data.data[0].value,
					vendor_id: sessionStorage.getItem('vendorId')
				}

				const resp = await BASTService.getHeaderList(payload);

				this.poNumList = resp.data.data;
			}

		});
	},
	watch: {
		async supplier(text) {

			if (!text) return;

			const payload = {
				db_instance: this.companyData.db_instance,
				org_id: this.companyData.value,
				vendor_id: text.value
			}

			if (!text.value) return;

			const resp = await BASTService.getHeaderList(payload);
			this.vendorId = text.value
			this.poNumList = resp.data.data;
		},
		selectedCompany(text) {
			this.company = text.name
		},
		poNumList(text) {
			console.log(text, "PO FILR")
		},
		selectedPO(text) {
			console.log(text, "SELECCTED PO")
		},
		selectedItem(text) {
			this.amount = this.formatCurrency(text.reduce((a, b) => a + b.CONFIRM_AMOUNT, 0));
			this.totalAmount = text.reduce((a, b) => a + b.CONFIRM_AMOUNT, 0)
		},
		file(text) {
			console.log(text, "FILE NAME")
		},
		poItems(text) {
			console.log(text, "TABLE CHANGE")
		},
		txt(text) {
			console.log(text, "TEXT")
			if (text.length === 0 || text === '' || text === null) {
				this.filteredSupplier = this.isisupplier
			}
		}
	},
	methods: {
		async handleUploadFile() {
			let formData = new FormData();
			console.log(this.selectedPO.PO_HEADER_ID, "TES")
			console.log(this.file, "INI FILE")
			console.log(this.vendorId, "INI VENDOR")
			formData.append('file', this.file);
			formData.append('poHeaderId', this.selectedPO.PO_HEADER_ID);
			formData.append('password', this.vendorId);


			// // Uploading file
			this.loadingUpload = true;
			const res = await BASTService.uploadFile(formData);

			console.log(res.data, "TEST AJA")

			if (res.status === 200) {
				this.fileUploaded = {
					filename: res.data.data.fileName,
					url: res.data.data.aossPayload.url,
					mimeType: res.data.data.mimeType,
				};
				// this.emailAttach.filename = res.data.data.fileName;
				this.$refs.inputFile.clear();
				this.loadingUpload = false;
				return res;
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'File Failed to Upload !',
					life: 3000,
				});
			}
			this.loadingUpload = false;
			await new Promise(resolve => setTimeout(resolve, 500));
		},
		async getCompanies() {
			const res = await PlanningOrderService.getCompany();
			this.companies = res.data.data;
		},
		handleChange(event) {
			this.company = event.target.value
		},

		async searchPO() {
			this.selectedItem = [];
			await this.getDetail()
		},
		searchBASTNum(event) {
			console.log(event)
			this.bastNum = this.poNum.BAST.map((item, index) => { return { id: index, number: item } })
		},
		async getDetail() {
			const resp = await BASTService.getDetailPo(JSON.stringify({
				po_number: this.poNum.SEGMENT1
			}));
			this.selectedPO = { ...resp.data.data.header }

			const dataDetail = resp.data.data.detail.map((item, index) => {
				return {
					id: index,
					CONFIRM_AMOUNT: 0,
					CONFIRM_PERCENTAGE: 0,
					CONFIRM_QUANTITY: 0,
					...item
				}
			}
			)

			this.poItems = [...dataDetail]
		},
		formatCurrency(value) {
			if (value) return value.toLocaleString('id-ID');
			return value;
		},
		onSelectFile(e) {
			this.file = e.files[0]
		},
		formattingDate(date, withTime) {
			const theDate = new Date(date);
			const strDate =
				theDate.getFullYear() +
				'-' +
				(theDate.getMonth() + 1) +
				'-' +
				theDate.getDate();
			const strTime =
				theDate.getHours() +
				':' +
				theDate.getMinutes() +
				':' +
				theDate.getSeconds();

			if (withTime) {
				return `${strDate} ${strTime}`;
			} else {
				return `${strDate}`;
			}
		},
		async saveBAST(status) {
			try {
				this.progress = true

				const validate = await this.validation();

				if (validate.length > 0) {
					this.errorList = validate
					Swal.fire({
						title: 'Failed',
						text: validate[0].message,
						icon: 'info',
						showConfirmButton: false,
						timer: 2000
					});
					this.progress = false;
					return {
						status: 400,
						message: validate[0].message
					};
				}

				const IS_BAST_HAVE_NULL = this.selectedItem.some((Q) => Q.CONFIRM_QUANTITY === 0 || Q.CONFIRM_QUANTITY === null);

				if (IS_BAST_HAVE_NULL) {
					this.progress = false;
					this.$toast.add({
						severity: 'error',
						summary: 'Failed!',
						detail: 'Quantity must greater than 0',
						life: 3000,
					});
					return {
						status: 400,
						message: 'Quantity Must Be Greater Than 0'
					}
				}

				let payload = {}
				const responseCheck = await BASTService.checkBastNum({
					bast_number: this.numBAST
				})

				if (responseCheck.data.status !== 200) {
					return {
						status: responseCheck.data.status,
						message: responseCheck.data.message
					};
				}

				const res = await this.handleUploadFile();

				const header = {
					ORG_ID: this.orgId,
					VENDOR_ID: this.vendorId,
					PO_NUMBER: this.selectedPO.SEGMENT1,
					BAST_NUMBER: this.numBAST,
					REVISION_NUM: this.selectedPO.REVISION_NUM,
					CREATED_BY: Number(sessionStorage.getItem('idUser')),
					CREATION_DATE: this.formattingDate(new Date(), true),
					LAST_UPDATE_TIME: this.formattingDate(new Date(), true),
					LAST_UPDATE_BY: Number(sessionStorage.getItem('idUser')),
					STATUS: 'O',
					TYPE: status,
					FILE_NAME: this.file.name,
					SHIP_TO_LOCATION_ID: this.selectedPO.SHIP_TO_LOCATION_ID,
					PO_HEADER_ID: this.selectedPO.PO_HEADER_ID,
					AMOUNT_TOTAL: Number(this.totalAmount),
					FILE_NAME_OSS: res.data.data.aossPayload,
					EMAIL_PURCHASING: this.selectedPO.EMAIL_PURCHASING,
					EMAIL_REQUESTOR: this.selectedPO.EMAIL_REQUESTOR,
					DB_INSTANCE: this.selectedPO.DB_INSTANCE,
					BUYER_NAME: this.selectedPO.BUYER_NAME,
					CURRENCY_CODE: this.selectedPO.CURRENCY_CODE,
					SHIP_TO_NAME: this.selectedPO.SHIP_TO_NAME
				}

				const detail = this.selectedItem.map((item) => {
					return {
						PO_HEADER_ID: item.PO_HEADER_ID,
						PO_LINE_ID: item.PO_LINE_ID,
						AMOUNT: item.PRICE_OVERRIDE,
						QUANTITY: item.QUANTITY,
						ITEM_DESCRIPTION: item.ITEM_DESCRIPTION,
						CREATED_BY: Number(sessionStorage.getItem('idUser')),
						CREATION_DATE: this.formattingDate(new Date(), true),
						LAST_UPDATE_TIME: this.formattingDate(new Date(), true),
						LAST_UPDATE_BY: Number(sessionStorage.getItem('idUser')),
						ORG_ID: this.orgId,
						VENDOR_ID: this.vendorId,
						BAST_NUMBER: this.numBAST,
						LINE_NUM: item.LINE_NUM,
						TERMIN: item.TERMIN,
						ITEM_ID: item.ITEM_ID,
						LINE_LOCATION_ID: item.LINE_LOCATION_ID,
						SHIPMENT_NUM: item.SHIPMENT_NUM,
						PRICE_OVERRIDE: item.PRICE_OVERRIDE,
						REVISION_NUM: this.selectedPO.REVISION_NUM,
						CONFIRM_AMOUNT: item.CONFIRM_AMOUNT,
						CONFIRM_PERCENTAGE: item.CONFIRM_PERCENTAGE,
						CONFIRM_QUANTITY: item.CONFIRM_QUANTITY,
						RETENTION_DATE: item.RETENTION_DATE
					}
				});


				payload.header = header;
				payload.detail = detail;

				const resp = await BASTService.saveBAST(payload);

				this.bastId = resp.data.data;

				if (status === 'L') {
					Swal.fire({
						title: 'Success',
						text: "BAST Successfully Created",
						icon: 'success',
						showConfirmButton: false,
						timer: 2000
					});
					this.handleReset();
					this.progress = false;
					// setTimeout(() => {window.location.reload();}, 1000);
					return {
						status: 200,
						message: 'Success to save BAST'
					}
				}

				return {
					status: 200,
					message: 'Success to save BAST'
				}

			} catch (error) {
				console.log(error)
				if (error.response) {
					Swal.fire({
						title: 'Failed',
						text: error.response.data.message,
						icon: 'error',
						showConfirmButton: false,
						timer: 2000
					});
					this.progress = false;
				}
			} finally {
				this.progress = false;
			}
		},
		async validation() {
			const inputError = [];

			if (this.selectedItem.length < 1) {
				inputError.push({
					name: 'item',
					message: 'Please Selected one or more Line'
				})
			}


			if (!this.numBAST) {
				inputError.push({
					name: 'numBAST',
					message: 'Please Insert BAST Number'
				})

			}

			if (this.numBAST && this.numBAST.length < 8) {

				inputError.push({
					name: 'numBASTlength',
					message: 'Number bast must be minimum have 8 character'
				})
			}

			if (!this.file || this.file === '') {
				inputError.push({
					name: 'file',
					message: 'Please Uploaded File'
				})
			}

			return inputError;
		},
		async submitBAST() {
			try {
				const resp = await this.saveBAST('S');

				if (resp.status !== 200) {
					return;
				}

				const validate = await this.validation();

				if (validate.length > 0) {
					this.errorList = validate
					this.$toast.add({
						severity: 'error',
						summary: 'Failed!',
						detail: validate[0].message,
						life: 3000,
					});

					return;
				}


				const IS_BAST_HAVE_NULL = this.selectedItem.some((Q) => Q.QUANTITY === 0 || Q.QUANTITY === null);

				if (IS_BAST_HAVE_NULL) {
					this.progress = false;
					this.$toast.add({
						severity: 'error',
						summary: 'Failed!',
						detail: 'Quantity must greater than 0',
						life: 3000,
					});
					return
				}


				const payload = {
					COMPANY: this.company,
					VENDOR_NAME: this.selectedPO.VENDOR_NAME,
					NO_BAST: this.numBAST,
					PO_NUM: this.poNum.SEGMENT1,
					BAST_ID: this.bastId,
					BAST_TYPE: 'PROXY'
				}

				await BASTService.submitBAST(payload);

				this.$toast.add({
					severity: "success",
					summary: "Success",
					detail: "BAST Successfully Submitted",
					life: 3000,
				});

				setTimeout(() => { window.location.reload(); }, 2500)
			} catch (error) {
				this.disableButton = false;
				if (error.response) {
					this.$toast.add({
						severity: 'error',
						summary: 'Failed!',
						detail: error.response.data.message,
						life: 3000,
					});
				}
			}
		},
		handleReset() {
			this.selectedCompany = null,
				this.poNum = null,
				this.numBAST = null,
				this.companies = [],
				this.bastNum = [],
				this.selectedItem = [],
				this.selectedPO = {
					PO_HEADER_ID: null,
					REVISION_NUM: null,
					SHIP_TO_NAME: null,
					CURRENCY_CODE: null,
					VENDOR_NAME: null,
					ORDER_DATE: null,
					BUYER_NAME: null,
					SEGMENT1: null,
					JIT_COLUMN: null,
				},
				// this.poNumList = []
				this.filteredPoNum = []
			this.vendorId = null
			this.amount = 0
			this.poNumberList = []
			this.poItems = []
			this.submitApi = null
			this.poValue = null
			this.file = null
			this.totalAmount = 0
			this.fileUploaded = null
		},

		onRemoveFile() {
			console.log('REMOVE')
			this.file = null;
		},
		searchPoNum(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredPoNum = [...this.poNumList];
				} else {
					this.filteredPoNum = this.poNumList.filter((item) => item.SEGMENT1.toLowerCase().includes(event.query.toLowerCase()))
				}
			}, 250);
		},
		async getVendorList(companys) {
			const resDetailCompany = await BASTService.getDetailCompany({ company_name: companys });

			const companyRes = resDetailCompany.data.data;

			const payload = {
				db_instance: companyRes.db_instance,
				org_id: companyRes.value
			}

			this.companyData = companyRes;

			const supp = await LovService.getSupplierName(payload);

			this.isisupplier = supp.data.data.map((item) => {
				return {
					value: item.vendor_id,
					name: item.vendor_name,
					db_instance: item.db_instance
				}
			});
		},
		getCompany() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					if (value.data.data.leveltenant == 1 || value.data.data.leveltenant == 3) {
						if (value.data.data.userid == 'dexaadmin') {
							LovService.getValueType('COMPANY').then(
								(response) => {
									this.isiCompany = response.data.data;
									//
								}
							);
						} else if (value.data.data.leveltenant == 3) {
							const secretKey = 'some-unique-key';
							const simpleCrypto = new SimpleCrypto(
								secretKey
							);
							const decipherText =
								simpleCrypto.decrypt(
									sessionStorage.getItem(
										'dropdownCompany'
									)
								);
							BASTService.getDetailCompany({ company_name: decipherText })
								.then(
									(response) => {
										this.company = response.data.data;
										LovService.getcompanydb(this.company.db_instance).then(
											(responses) => {
												this.isiCompany = responses.data.data;
											}
										);
									}
								);
						} else {
							let payload = value.data.data.bioaddress;
							LovService.getcompanydb(payload).then(
								(response) => {
									// this.isiCompany = response.data.data
									//
									// let payload = {isi : response.data.data[0].company}
									//
									// PlanningOrder.getCompanyId(payload).then(response => {
									this.isiCompany = response.data.data;

									try {
										const secretKey = 'some-unique-key';
										const simpleCrypto = new SimpleCrypto(
											secretKey
										);
										const decipherText =
											simpleCrypto.decrypt(
												sessionStorage.getItem(
													'dropdownCompany'
												)
											);

										this.isiCompany.forEach((element) => {
											if (element.name === decipherText) {
												this.company = element;
												// this.searchTableNested();
												throw 'Break';
											}
										});
									} catch (e) {
										if (e !== 'Break') throw e;
									}

									// })
								}
							);
						}
					} else if (value.data.data.leveltenant == 2) {
						let payload = value.data.data.id;
						PoFulfillment.getCompanySupp(payload).then(
							(response) => {
								let payload = response.data.data;
								let companyList = [];

								try {
									payload.forEach((element) => {
										LovService.getCompanyByName(
											element.company
										).then((response) => {
											companyList.push(
												response.data.data[0]
											);

											const secretKey = 'some-unique-key';
											const simpleCrypto =
												new SimpleCrypto(secretKey);
											const decipherText =
												simpleCrypto.decrypt(
													sessionStorage.getItem(
														'dropdownCompany'
													)
												);

											if (
												response.data.data[0].name ===
												decipherText
											) {
												this.company =
													response.data.data[0];
												// this.searchTableNested();
											}
										});
									});
									this.isiCompany = companyList;
								} catch (e) {
									if (e !== 'Break') throw e;
								}
							}
						);
					}
				}
			});
		},
		async getValueCompany() {
			if (sessionStorage.leveltenant == 3) {
				const respVendor = await PlanningOrderService.getCompanySupp(sessionStorage.idUser);
				console.log(respVendor, "VENDOR")
				this.isisupplier = respVendor.data.data.map((item) => {
					return {
						value: item.vendorid,
						name: item.fullname
					}
				});
			} else {
				let payload = {
					db_instance: this.company.db_instance,
					org_id: this.company.value,
				};
				AuthService.whoAmi().then((value) => {
					if (value.status === 200) {
						LovService.getSupplierName(payload).then((response) => {
							this.isisupplier = response.data.data;
							console.log("SINI YA?", response.data.data)
							//
							if (sessionStorage.getItem('leveltenant') == 2) {
								try {
									this.isisupplier.forEach((element) => {
										if (
											element.vendor_name ===
											this.suppBindToComp[0].toString() &&
											element.vendor_name !== null
										) {
											this.supplier = element;
											this.buttonDisable = false;
											throw 'Break';
										}
									});
								} catch (e) {
									if (e !== 'Break') throw e;
								}
								//
							}
						});
					}
				});
			}
		},
		check(q) {
			console.log(q, "QUE")
		},
		searchSupplier(event) {
			setTimeout(() => {
				this.txt = event.query
				console.log(event.query, "EV")
				console.log(this.isisupplier, "ISI SUPP")
				if (!event.query.trim().length || event.query === '' || event.query === null) {
					console.log("MASUK KE IF SUPP")
					this.filteredSupplier = this.isisupplier
				} else {
					console.log("MASUK KE ELSE SUPP")
					this.filteredSupplier = this.isisupplier.filter((item) => {
						return item.name.toLowerCase().match(event.query.toLowerCase())
					});
				}
			}, 250)
		},
		calculatePercentage(x, y) {
			const calculate = (x / y) * 100

			return calculate
		},
		calculateAmount(x, y) {
			const calculateAmount = (x * y)

			return calculateAmount;
		},
		calculatePercentToQuantity(x, y) {
			const calculateToQuantity = (x * y) / 100

			return calculateToQuantity
		},
		calculateTotalProgress(item) {
			console.log(item, "ITEM")
			if (item.TOTAL_PROGRESS_API) {
				console.log("MASUK SINI GA YA?")
				return Number(item.TOTAL_PROGRESS_API) + Number(item.CONFIRM_PERCENTAGE)
			}

			item.TOTAL_PROGRESS = item.CONFIRM_PERCENTAGE
			return item.TOTAL_PROGRESS
		},
		handleChangeQuantity(event, index) {
			if (!event) {
				this.poItems[index]["CONFIRM_QUANTITY"] = 0
				return
			}

			if (event > this.poItems[index]["QUANTITY"]) {
				this.poItems[index]["CONFIRM_QUANTITY"] = this.poItems[index]["QUANTITY"];
			} else {
				this.poItems[index]["CONFIRM_QUANTITY"] = event
			}

			this.poItems[index]["CONFIRM_PERCENTAGE"] = this.calculatePercentage(
				this.poItems[index]["CONFIRM_QUANTITY"],
				this.poItems[index]["QUANTITY"]
			)

			this.poItems[index]["CONFIRM_AMOUNT"] = this.calculateAmount(
				this.poItems[index]["CONFIRM_QUANTITY"],
				this.poItems[index]["PRICE_OVERRIDE"]
			)

			this.poItems[index]["TOTAL_PROGRESS"] = this.calculateTotalProgress(this.poItems[index])

			const validateCount = this.poItems[index]["TOTAL_PROGRESS"]

			console.log(validateCount, "VALIDATE COUNT")

			if (validateCount > 100) {
				this.poItems[index]["CONFIRM_PERCENTAGE"] = 0;
				this.poItems[index]["CONFIRM_QUANTITY"] = 0;
				this.poItems[index]["CONFIRM_AMOUNT"] = 0;
				this.poItems[index]["TOTAL_PROGRESS"] = this.poItems[index]["TOTAL_PROGRESS_API"]

				this.$toast.add({
					severity: 'error',
					summary: 'Confirm Percentage Bigger than Total Progress',
					life: 3000,
				});

				return
			}

			if (this.selectedItem && this.selectedItem.length > 0) {
				const indexes = this.selectedItem.findIndex((item) => item.LINE_LOCATION_ID === this.poItems[index]["LINE_LOCATION_ID"]);
				if (indexes === -1) return;
				this.selectedItem[indexes]["CONFIRM_QUANTITY"] = this.poItems[index]["CONFIRM_QUANTITY"]
				this.selectedItem[indexes]["CONFIRM_PERCENTAGE"] = this.poItems[index]["CONFIRM_PERCENTAGE"]
				this.selectedItem[indexes]["CONFIRM_AMOUNT"] = this.poItems[index]["CONFIRM_AMOUNT"]

				this.amount = this.formatCurrency(this.selectedItem.reduce((a, b) => a + b.CONFIRM_AMOUNT, 0));
				this.totalAmount = this.selectedItem.reduce((a, b) => a + b.CONFIRM_AMOUNT, 0)
			}
		},
		handleChangePercentage(value, index) {
			if (!value) {
				this.poItems[index]["CONFIRM_PERCENTAGE"] = 0
				return
			}

			if (value > 100) {
				this.poItems[index]["CONFIRM_PERCENTAGE"] = 100
			} else {
				this.poItems[index]["CONFIRM_PERCENTAGE"] = value
			}

			console.log(this.poItems[index], "PO ITEMS")

			this.poItems[index]["CONFIRM_QUANTITY"] = this.calculatePercentToQuantity(
				this.poItems[index]["CONFIRM_PERCENTAGE"],
				this.poItems[index]["QUANTITY"]
			)

			this.poItems[index]["CONFIRM_AMOUNT"] = this.calculateAmount(
				this.poItems[index]["CONFIRM_QUANTITY"],
				this.poItems[index]["PRICE_OVERRIDE"]
			)

			this.poItems[index]["TOTAL_PROGRESS"] = this.calculateTotalProgress(this.poItems[index])

			const validateCount = this.poItems[index]["TOTAL_PROGRESS"]


			if (validateCount > 100) {
				this.poItems[index]["CONFIRM_PERCENTAGE"] = 0;
				this.poItems[index]["CONFIRM_QUANTITY"] = 0
				this.poItems[index]["CONFIRM_AMOUNT"] = 0;
				this.poItems[index]["TOTAL_PROGRESS"] = this.poItems[index]["TOTAL_PROGRESS_API"]

				this.$toast.add({
					severity: 'error',
					summary: 'Confirm Percentage Bigger than Total Progress',
					life: 3000,
				});

				return
			}

			if (this.selectedItem && this.selectedItem.length > 0) {
				const indexes = this.selectedItem.findIndex((item) => item.LINE_LOCATION_ID === this.poItems[index]["LINE_LOCATION_ID"]);
				if (indexes === -1) return;
				this.selectedItem[indexes]["CONFIRM_QUANTITY"] = this.poItems[index]["CONFIRM_QUANTITY"]
				this.selectedItem[indexes]["CONFIRM_PERCENTAGE"] = this.poItems[index]["CONFIRM_PERCENTAGE"]
				this.selectedItem[indexes]["CONFIRM_AMOUNT"] = this.poItems[index]["CONFIRM_AMOUNT"]

				this.amount = this.formatCurrency(this.selectedItem.reduce((a, b) => a + b.CONFIRM_AMOUNT, 0));
				this.totalAmount = this.selectedItem.reduce((a, b) => a + b.CONFIRM_AMOUNT, 0)
			}
		},
	},
}

</script>

<style scoped>
.text-warn {
	color: red;
}

.bg-secondary {
	background-color: gainsboro;
}
</style>