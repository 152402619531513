<template>
  <AutoComplete
    :suggestions="filteredMethods"
    @complete="searchMethods($event)"
    :dropdown="true"
    field="payment_method"
    forceSelection
  >
    <template #item="slotProp">
      {{ slotProp.item.payment_method }}
    </template>
  </AutoComplete>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { getPaymentMethods } from "@/service/InvoiceProxyNonPOServices";

// eslint-disable-next-line no-undef
const filteredMethods = ref([]);
const methods = ref([]);

// eslint-disable-next-line no-unused-vars,no-undef
const props = defineProps({
  company: {
    type: Object,
  },
  supplier: {
    type: Object,
  },
  vendor_id: {
    type: Object,
  },
});

onMounted(async () => {
  try {
    //Query dari backend
    if (props.company && props.vendor_id) {
      const resPayMethod = await getPaymentMethods(
        props.company.db_instance,
        props.vendor_id
      );
      methods.value = resPayMethod.data;
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
});

const searchMethods = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredMethods.value = [...methods.value];
    } else {
      filteredMethods.value = methods.value.filter((comp) => {
        return comp.payment_method
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};
</script>
