<template>
	<div class="grid find-po-modal">
		<Toast />

		<!-- Content -->
		<div class="col-12 mb-2">
			<div class="content-wrapper">
				<div class="content-header">Find PO</div>
				<div class="content-body">
					<!-- Search input -->
					<div v-if="props.type !== PoTypeEnum.Prepayment" class="grid">
						<div class="col-12 lg:col-3 md:col-6 mb-5">
							<span class="p-float-label">
								<Calendar
									id="fromDate"
									class="w-full"
									v-model="fromDate"
									dateFormat="d-M-yy"
									:showIcon="true"
								/>
								<label for="fromDate">From Date</label>
							</span>
						</div>
						<div class="col-12 lg:col-3 md:col-6 mb-5">
							<span class="p-float-label">
								<Calendar
									id="toDate"
									class="w-full"
									v-model="toDate"
									dateFormat="d-M-yy"
									:showIcon="true"
								/>
								<label for="toDate">To Date</label>
							</span>
						</div>
						<div class="col-12 lg:col-3 md:col-6 mb-5">
							<span class="p-float-label">
								<AutoComplete
									id="fromPo"
									class="w-full"
									:disabled="!poNumbers"
									v-model="fromPoNumber"
									:suggestions="filteredPoNumbers"
									:virtualScrollerOptions="{
										itemSize: 30,
									}"
									@complete="searchPoNumbers($event)"
									field="po_number"
									forceSelection
								>
								</AutoComplete>
								<label for="fromPo">From PO</label>
							</span>
						</div>
						<div class="col-12 lg:col-3 md:col-6 mb-5">
							<span class="p-float-label">
								<AutoComplete
									id="toPo"
									class="w-full"
									:disabled="!poNumbers"
									v-model="toPoNumber"
									:suggestions="filteredPoNumbers"
									:virtualScrollerOptions="{
										itemSize: 30,
									}"
									@complete="searchPoNumbers($event)"
									field="po_number"
									forceSelection
								>
								</AutoComplete>
								<label for="toPo">To PO</label>
							</span>
						</div>

						<div class="col-auto mb-8">
							<Button
								class="p-button-success mr-2"
								:disabled="!poNumbers"
								@click="handleSearch"
							>
								<span class="uppercase font-bold">Search</span>
							</Button>
							<Button
								class="p-button-secondary"
								@click="handleReset"
							>
								<span class="uppercase font-bold">Reset</span>
							</Button>
						</div>
					</div>

					<div v-if="props.type === PoTypeEnum.Prepayment" class="mb-3">
						<Button
							class="p-button-success mr-2"
							:disabled="props.type !== PoTypeEnum.Prepayment"
							@click="handleSearch"
						>
							<span class="uppercase font-bold">Search</span>
						</Button>
					</div>

					<!-- Table result -->
					<div class="mb-5">
						<DataTable
							dataKey="id"
							responsiveLayout="scroll"
							selectionMode="multiple"
							ref="dt"
							:lazy="true"
							:paginator="true"
							:rows="5"
							:rowsPerPageOptions="[5, 10, 20, 50]"
							:metaKeySelection="false"
							:loading="isLoading"
							:totalRecords="totalRecords"
							:value="poItems"
							paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
							v-model:selection="selectedPo"
							@page="onPage($event)"
						>
							<!-- Search input -->
							<template v-if="!props.type" #header>
								<div>
									<span class="p-input-icon-left w-full">
										<i class="pi pi-search" />
										<InputText
											class="w-full"
											type="text"
											placeholder="Search"
											:disabled="!poNumbers"
											v-model="searchKeyword"
											@keyup.enter="handleSearch"
											@page="onPage($event)"
										/>
									</span>
								</div>
							</template>

							<!-- Results Data -->
							<Column
								header="Product Description"
								field="item_description"
							>
							</Column>
							<Column header="PO Number" field="po_number">
							</Column>
							<Column header="PO Line" field="line_num"> </Column>
							<Column header="Receipt Num" field="receipt_num">
							</Column>
							<Column header="Packing Slip" field="packingSlip">
							</Column>
							<Column header="Qty Receipt">
								<template #body="{ data }">
									{{
										data.quantity_received.toLocaleString(
											'en-US'
										)
									}}
								</template>
							</Column>
							<Column header="Price">
								<template #body="{ data }">
									{{
										Number.parseFloat(data.unit_price)
											.toFixed(2)
											.replace(
												/\B(?=(\d{3})+(?!\d))/g,
												','
											)
									}}
								</template>
							</Column>
							<Column header="Total">
								<template #body="{ data }">
									{{
										Number.parseFloat(data.total_dpp)
											.toFixed(2)
											.replace(
												/\B(?=(\d{3})+(?!\d))/g,
												','
											)
									}}
								</template>
							</Column>

							<!-- Empty statement -->
							<template #empty> No data found. </template>
						</DataTable>
					</div>
					<div>
						<Button
							class="p-button-success mr-2"
							:disabled="!selectedPo.length"
							@click="handleAdd"
						>
							<span class="uppercase font-bold">Add</span>
						</Button>
						<Button
							class="p-button-secondary"
							@click="$emit('onClose')"
						>
							<span class="uppercase font-bold">Close</span>
						</Button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed } from 'vue';
import { ref, defineEmits, defineProps, onMounted } from 'vue';
import InvoicePOService from '../../service/InvoicePOService';
import { PoTypeEnum } from '../../constants/POTypeEnum';

// Props
const props = defineProps(['dbInstance', 'orgId', 'vendorId', 'banList', 'type', 'poNumber']);

// Define emits
const emit = defineEmits(['onAdd']);

// State
const fromDate = ref(null);
const toDate = ref(null);
const fromPoNumber = ref(null);
const toPoNumber = ref(null);
const searchKeyword = ref(null);
const poNumbers = ref(null);
const filteredPoNumbers = ref(null);
const poItems = ref(null);
const selectedPo = ref([]);
const isLoading = ref(false);
const totalRecords = ref(0);
const lazyParams = ref(null);
const dt = ref(null);

// Computed format date
const formattedFromDate = computed(() => {
	const formatted = fromDate.value && new Date(fromDate.value);
	const month = `${formatted.getMonth() + 1}`,
		date = `${formatted.getDate()}`,
		year = `${formatted.getFullYear()}`;

	return `${year}-${month.length < 2 ? `0${month}` : month}-${
		date.length < 2 ? `0${date}` : date
	}`;
});

const formattedToDate = computed(() => {
	const formatted = toDate.value && new Date(toDate.value);
	const month = `${formatted.getMonth() + 1}`,
		date = `${formatted.getDate()}`,
		year = `${formatted.getFullYear()}`;

	return `${year}-${month.length < 2 ? `0${month}` : month}-${
		date.length < 2 ? `0${date}` : date
	}`;
});

// Mounted hook
onMounted(async () => {
	const { dbInstance, orgId, vendorId } = props;

	lazyParams.value = {
		first: 0,
		page: 0,
		rows: dt.value.rows,
	};

	const payload = {
		dbInstance,
		orgId,
		vendorId,
	};

	const res = await InvoicePOService.getPoNumbers(payload);
	poNumbers.value = res.data.data;
});

// Handle search PO
const handleSearch = async () => {
	isLoading.value = true;

	const { dbInstance, orgId, vendorId } = props;

	const payload = {
		fromDate: fromDate.value && formattedFromDate.value,
		toDate: toDate.value && formattedToDate.value,
		fromPoNumber: fromPoNumber.value && fromPoNumber.value.po_number,
		toPoNumber: toPoNumber.value && toPoNumber.value.po_number,
		searchKeyword: searchKeyword.value,
		type: props.type ? props.type : null,
		poNumber: props.poNumber ? props.poNumber : null,
		dbInstance,
		orgId,
		vendorId,
	};

	const params = {
		query: JSON.stringify({
			limit: lazyParams.value.rows,
			page: lazyParams.value.page + 1,
		}),
	};

	let res;
	
	console.log(props.type, "PROPS TYPE")
	if(props.type === PoTypeEnum.Standart){
		res = await InvoicePOService.getListPoStandard(params, payload);
	}else{
		res = await InvoicePOService.getListPoPrepayment(params, payload);
	}

	if (res.status === 200) {
		poItems.value = res.data.data.map((item) => {
			const packingSlip =
				item.packing_slip && item.packing_slip !== 'NA'
					? item.packing_slip
					: '';

			return {
				...item,
				id: item.transaction_id,
				packingSlip,
			};
		});

		poItems.value = poItems.value.filter((Q) => {
			console.log(props.banList.shipment_line_id, "BAN LIST")
			let is_true;

			if(props.type === PoTypeEnum.Standart){
				is_true = props.banList.some((y) => 
					y.shipment_line_id === Q.shipment_line_id
				);
			}else{
				is_true = props.banList.some((y) => 
					y.po_number === Q.po_number && y.line_num === Q.line_num
				);
			}


			if(is_true === true){
				return;
			}
			return Q;
		})

		totalRecords.value = res.data.count;
		isLoading.value = false;
	}
};

// Search PO Number
const searchPoNumbers = (event) => {
	if (!event.query.trim().length) {
		filteredPoNumbers.value = [...poNumbers.value];
	} else {
		filteredPoNumbers.value = poNumbers.value.filter((item) => {
			if (item.po_number !== null) {
				return item.po_number
					.toLowerCase()
					.match(event.query.toLowerCase());
			}
		});
	}
};

// Handle pagination
const onPage = (event) => {
	lazyParams.value = event;
	handleSearch();
};



// Reset search input
const handleReset = () => {
	fromDate.value = null;
	toDate.value = null;
	fromPoNumber.value = null;
	toPoNumber.value = null;
	searchKeyword.value = null;

	poItems.value = null;
	totalRecords.value = 0;
};

// Add selected PO
const handleAdd = async () => {
	const items = selectedPo.value;
	emit('onAdd', items);
};
</script>
