<template>
    <AutoComplete :suggestions="filteredDebitNote" @complete="searchDNno($event)" field="dn_no"
        :placeholder="placeholder" :dropdown="true" forceSelection>
        <template #item="slotProp">
            {{ slotProp.item.dn_no }}
        </template>
    </AutoComplete>
</template>

<script setup>
import { ref, onMounted } from "vue";
import ListNotaDebit from "../../../service/ListNotaDebit";
import AuthService from "../../../service/AuthService";

const isiDebitNote = ref([]);
const filteredDebitNote = ref([]);
const placeholder = ref("Select Debit Note No");

// eslint-disable-next-line no-undef
const props = defineProps({
  company: {
    type: Object,
  },
  supplier: {
    type: Object,
  },
});

onMounted(()=>{
  populateBatch()
})

const populateBatch = async () => {
    AuthService.whoAmi().then(async (response) => {
        let payload
        if(props.company !== null && props.supplier !== null) {
            payload  = {
                org_id: props.company.value,
                db_instance: props.company.db_instance,
                vendor_id: props.supplier.vendor_id,
            }
            console.log('payload Dn No', payload)
            if (response.status === 200) {
                const res = await ListNotaDebit.getDnNo(payload);
                isiDebitNote.value = res.data.data
            }
        } else if (props.company !== null){
            payload  = {
                org_id: props.company.value,
                db_instance: props.company.db_instance,
            }
            console.log('payload Dn No', payload)
            if (response.status === 200) {
                const res = await ListNotaDebit.getDnNo(payload);
                isiDebitNote.value = res.data.data
            }
        }
    })
}

const searchDNno = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
        filteredDebitNote.value = [...isiDebitNote.value]
    } else {
        filteredDebitNote.value = isiDebitNote.value.filter((comp) => {
        return comp.dn_no.toLowerCase().match(event.query.toLowerCase());
      })
    }
  }, 250);
}
</script>
