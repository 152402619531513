<template>
	<AutoComplete
		:suggestions="filteredBatch"
		@complete="searchBatch($event)"
		:dropdown="true"
		field="BATCH_NO"
		placeholder="Select a Batch Number"
		:disabled="!batchNumber.length"
		forceSelection
	>
		<template #item="slotProp">
			{{ slotProp.item.BATCH_NO }}
		</template>
	</AutoComplete>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import AuthService from '../../../service/AuthService';
import InvoiceService from '../../../service/InvoiceSearchWPO';

// eslint-disable-next-line no-undef
const props = defineProps({
	company: { type: Object },
	supplier: { type: Object },
	poNumber: { type: Object },
});
// eslint-disable-next-line no-undef
const filteredBatch = ref([]);
const batchNumber = ref([]);

onMounted(() => {
	populateData();
});

const populateData = () => {
	AuthService.whoAmi().then(async (response) => {
		//
		let payload;
		if (
			props.company !== null &&
			props.supplier !== null &&
			props.poNumber !== null
		) {
			payload = {
				org_id: props.company.value,
				db_instance: props.company.db_instance,
				supValue: props.supplier.vendor_id,
				invoice_no: props.poNumber.invoice_no,
			};
			//
			if (response.status === 200) {
				const data = await InvoiceService.getBatchNumberwCompnInvoiceno(
					payload
				);
				// console.log(data.data.data.data);
				batchNumber.value = data.data.data.data;
				// console.log('isi batch w comp n inv', batchNumber.value);
			}
		} else if (props.company !== null && props.supplier !== null) {
			payload = {
				org_id: props.company.value,
				db_instance: props.company.db_instance,
				supValue: props.supplier.vendor_id,
			};
			//
			if (response.status === 200) {
				const data = await InvoiceService.getBatchNumberwCompnSupp(
					payload
				);
				batchNumber.value = data.data.data.data;
				// console.log(
				// 	'isi batch number w comp n supp',
				// 	batchNumber.value
				// );
			}
		} else if (props.company !== null && props.poNumber !== null) {
			payload = {
				org_id: props.company.value,
				db_instance: props.company.db_instance,
				invoice_no: props.poNumber.invoice_no,
			};
			//
			if (response.status === 200) {
				const data = await InvoiceService.getBatchNumberwCompnInvoice(
					payload
				);
				batchNumber.value = data.data.data.data;
				// console.log(
				// 	'isi data batch number w comp invoice',
				// 	batchNumber.value
				// );
			}
		} else if (props.company !== null) {
			payload = {
				org_id: props.company.value,
				db_instance: props.company.db_instance,
			};
			// console.log('isi company',payload)
			if (response.status === 200) {
				const data = await InvoiceService.getBatchNumberwComp(payload);
				//
				batchNumber.value = data.data.data.data;
				// console.log('isi batch number', batchNumber.value);
			}
		}
	});
};

const searchBatch = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredBatch.value = [...batchNumber.value];
		} else {
			filteredBatch.value = batchNumber.value.filter((comp) => {
				return comp.BATCH_NO.toLowerCase().match(
					event.query.toLowerCase()
				);
			});
		}
	}, 250);
};
</script>

<style scoped></style>
