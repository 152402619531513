<template>
  <span class="p-float-label">
    <AutoComplete
      :suggestions="filteredCategory"
      @complete="searchCat($event)"
      @item-select="getDetailCat($event)"
      :dropdown="true"
      field="category_detail"
      v-model="detailCategoryValue"
      :disabled="props.disabled"
      forceSelection
    >
      <template #item="slotProp">
        {{ slotProp.item.category_detail }}
      </template>
    </AutoComplete>
    <label for="autocomplete">Detail Category</label>
  </span>
</template>

<script setup>
import { onMounted, ref, defineEmits, watch, watchEffect } from "vue";
import AuthService from "../../../../service/AuthService";
import ListInvoiceNonPo from "../../../../service/InvoiceNonPOservice";

// eslint-disable-next-line no-undef
const props = defineProps({
  db_instance: { type: Object },
  company: { type: Object },
  data: { type: Object },
  ref_num: { type: Object },
  categoryId: { type: Object },
  disabled: {
    type: Boolean,
  },
  supplier: { type: Object },
});

// eslint-disable-next-line no-undef
const filteredCategory = ref([]);
const detailCategory = ref([]);
const detailCategoryValue = ref();
const forwarder = ref(true);
const emit = defineEmits(["detailCategory"]);

onMounted(() => {
  populateData();
});

watch(
  () => props.ref_num,
  (newValue) => {
    if (newValue !== null) {
      if (!forwarder.value) {
        populateData();
      }
    }
  }
);

watchEffect(() =>
  emit("detailCategory", detailCategoryValue)
);

const populateData = () => {
  AuthService.whoAmi().then(async (response) => {
    if (response.status === 200) {
      if (props.db_instance !== null) {
        const payload = {
          categoryId: props.categoryId ? props.categoryId : null,
          dbInstance: props.company.db_instance
            ? props.company.db_instance
            : null,
          orgId: props.company.value ? props.company.value : null,
          vendorId: props.supplier ? props.supplier : null,
        };
        const data = await ListInvoiceNonPo.getDetailCat(payload);
        data.data.data.data.categoryHeader.forEach((element) => {
          if (element.category_name.toLowerCase().includes("forwarder")) {
            forwarder.value = false;
          }
        });
        if (forwarder.value) {
          detailCategory.value = data.data.data.data.categoryDetail;
        } else {
          const payloadSK = {
            categoryId: props.categoryId ? props.categoryId : null,
            dbInstance: props.company.db_instance
              ? props.company.db_instance
              : null,
            orgId: props.company.value ? props.company.value : null,
            skNumber: props.ref_num ? props.ref_num : null,
          };
          const res = await ListInvoiceNonPo.getDetailCatSK(payloadSK);
          detailCategory.value = res.data.data.data;
        }
        emit("detailCategory", detailCategoryValue);
      }
    }
  });
};

const getDetailCat = (event) => {
  detailCategoryValue.value = event.value;
};

const searchCat = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredCategory.value = [...detailCategory.value];
    } else {
      filteredCategory.value = detailCategory.value.filter((comp) => {
        return comp.category_detail
          .toLowerCase()
          .match(event.query.toLowerCase());
      });
    }
  }, 250);
};
</script>

<style scoped></style>
