<template>
  <AutoComplete
    :suggestions="filteredPayGroup"
    @complete="searcSupplierSite($event)"
    :dropdown="true"
    field="VALUE"
    :placeholder="placeholder"
    v-model="payGroup"
    forceSelection
  >
    <template #item="slotProp">
      {{ slotProp.item.VALUE }}
    </template>
  </AutoComplete>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect } from "vue";
//   import LovService from "@/service/LovService";
//   import SwithCompany from "@/service/SwithCompany";
// import AuthService from "@/service/AuthService";
import invoiceNonPOservice from "@/service/InvoiceNonPOservice";

// eslint-disable-next-line no-undef
const filteredPayGroup = ref([]);
const payGroupList = ref([]);
const payGroup = ref();
const placeholder = ref("Select a Pay Group");

const emit = defineEmits(["payGroup"]);

watchEffect(() => emit("payGroup", payGroup));
// eslint-disable-next-line no-undef
const props = defineProps({
  company: {
    type: Object,
  },
});

onMounted(async () => {
  try {
    // const response = await AuthService.whoAmi();
    // const user = response.data.data;
    if (props.company) {
      const result = await invoiceNonPOservice.getListPayGroup(
        props.company.db_instance
      );
      payGroupList.value = result.data.data;
      // if (props.paymentMethod) {
      //   for (const payMethodData of payGroupList.value) {
      //     if (payMethodData.PAYMENT_METHOD == props.paymentMethod) {
      //         payGroup.value = payMethodData;
      //     }
      //   }
      // }
    }

    // eslint-disable-next-line no-empty
  } catch (e) {
    throw new Error(e);
  }
});

const searcSupplierSite = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredPayGroup.value = [...payGroupList.value];
    } else {
      filteredPayGroup.value = payGroupList.value.filter((pg) => {
        return pg.PAYMENT_METHOD.toLowerCase().match(
          event.query.toLowerCase()
        );
      });
    }
  }, 250);
};
</script>
