<template>
    <div>
    <DataTable
        :value="tableList"
        :lazy="true"
        :paginator="true"
        :rows="10"
        :loading="loading"
        :totalRecords="totalData"
        @page="onPage($event)"
        @sort="onSort($event)"
        selectionMode="single"
        @rowSelect="onRowSelect"
        :rowsPerPageOptions="[10, 20, 30]"
        responsiveLayout="scroll"
        :sortable="true"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
    >
      <template #header>
          <div>
          <span class="p-input-icon-left" style="col-12">
              <i class="pi pi-search" />
              <InputText v-model="itemNumber" placeholder="Search Item Number" @keydown.enter="keydown($event)"/>
          </span>
          </div>
      </template>
      <template #empty>Item Number Not Found </template>
      <template #loading>Loading data</template>
      <Column header="Item Number" field="item_num">
      </Column>
      <Column header="Description" field="description">
      </Column>
      <Column header="UoM" field="uom">
      </Column>
    </DataTable>
    </div>
</template>

<script setup>
import { onMounted, ref, watch, watchEffect, defineEmits} from 'vue'
import AuthService from '../../../service/AuthService';
import SpecSrc from '../../../service/SpecSrc';

// eslint-disable-next-line no-undef
const props = defineProps({
    company: {
        type: Object
    }
});

// eslint-disable-next-line no-undef
const tableList = ref();
const totalData = ref(0);
const page = ref(1);
const rows = ref(10)
const loading = ref(false);
const itemNumber = ref();
const itemNumberValue = ref()
const emit = defineEmits(['itemNumberValue'])

watchEffect(() => {
  
})

watch(
  // () => props.company,
  // () => {
  //   if (props.company.db_instance !== null) {
  //     populateData()
  //   }
  // }
);

onMounted( async ()=>{
    await populateData()
})


const populateData = async () => {
  AuthService.whoAmi().then(async (response) => {
    loading.value = true
    if (response.status === 200){
        if (props.company !== null){
            const payload = {db_instance : props.company.db_instance,
            page : page.value,
            rows : rows.value,
            item_num : itemNumber.value?itemNumber.value:null}
            const res = await SpecSrc.getItemNuberDetail(payload)
            if (res == undefined || res.status === null){
              setTimeout( async () => {
                await populateData()
              }, 1000);
            } else if (res.status === 200) {
              tableList.value = res.data.data
              totalData.value = res.data.totalData[0].totalData
              loading.value = false
            } else {
              tableList.value = null
              totalData.value = 0
              loading.value = false
            }
            
        }
    } else {
      tableList.value = []
      totalData.value = 0
    }
  })
}

const keydown = async () => {
  await populateData()
}

const onPage = async (event) => {
  rows.value = event.rows
  page.value = event.page + 1
  await populateData()
}

const onRowSelect = async (event) => {
  itemNumberValue.value = event.data
  emit("itemNumber", itemNumberValue.value)
}

</script>