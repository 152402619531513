
import spborder from "./api/SpbOrderApi";
import spbadmin from './api/SpbAdminApi';
import spbKkt from './api/SpbKktApi';
import { encryptForm } from '../helper/encryptForm';

class RegisterOutstandingOrder {
    getPoNumber() {
        return spborder.get("spb/spb_outstandingorder/getponumber");
    }

    getPoNumberList(payload) {
        return spborder.post("spb/spb_outstandingorder/listPOFromROO", payload);
    }

    getSuppBindComp(idUser) {
        return spborder.post('spb/spb_pofulfillment/getsuppbindcomp/' + idUser);
    }
    getCompanySupp(payload) {
        return spbadmin.get(
            '/spb/usermapping/getusermappingsbyiduser/' + payload
        );
    }
    getPoNumSupp(payload) {
        return spborder.post(
            '/spb/spb_outstandingorder/getponumbercom', payload
        );
    }
    //untuk nyarik data po number berdasarkan company dan supplier
    getSearchDropdownSupp(payload) {
        return spborder.post(
            '/spb/spb_outstandingorder/getponumbercomsupp', payload
        );

    }
    getSuppValue(payload) {
        return spborder.post('/spb/spb_planningorder/getvaluesupp', payload);
}
    printROO(payload) {
        return spborder.post('/spb/spb_outstandingorder/printroo', payload);
    }

    getAllData(params) {
        const queryParams = params ?
            Object.keys(params)
            .map(
                (k) =>
                encodeURIComponent(k) +
                '=' +
                encodeURIComponent(params[k])
            )
            .join('&') :
            '';
        return spborder.post(
            '/spb/spb_outstandingorder/getalldata?' + queryParams
        );
    }
    
    getSearchTableNested(params) {
		return spborder.post(
			'/spb/spb_outstandingorder/searchnestedtable', encryptForm(params)
		);
	}

    allnestedtable(params) {
		const queryParams = params
			? Object.keys(params)
					.map(
						(k) =>
							encodeURIComponent(k) +
							'=' +
							encodeURIComponent(params[k])
					)
					.join('&')
			: '';
		return spborder.post(
			'/spb/spb_outstandingorder/allnestedtable?' + queryParams
		);
	}

    exportData(search) {
        return spborder.post(
            '/spb/spb_outstandingorder/export', encryptForm(search)
        );

    }

    oracleData(params, search) {
		return spborder.post(
			'/spb/spb_outstandingorder/oracletable', search
		);
    }

    search(params, search) {
        const queryParams = params ?
            Object.keys(params)
            .map(
                (k) =>
                encodeURIComponent(k) +
                '=' +
                encodeURIComponent(params[k])
            )
            .join('&') :
            '';
        return spborder.post(
            '/spb/spb_outstandingorder/search?' + queryParams, search
        );

    }
    getListPo(payload) {
		return spborder.post(
			`/spb/spb_outstandingorder/getListOrder`,
			encryptForm(payload)
		);
	}

    outstandingOrder(payload) {
		return spborder.post(
			`/spb/spb_outstandingorder/getListOrder`,
			encryptForm(payload)
		);
	}
saveButton(payload) {
  return spborder.post('/spb/spb_outstandingorder/saveRegisterOutstanding', payload);
}
confirmButton(payload) {
  return spborder.post('/spb/spb_outstandingorder/submitRegisterOutstanding', payload);
}
getRecipientMail(data) {
    return spbadmin.post(
        '/spb/notification/getPlannoRecipientMailPur',
        data
    );
}
async sendPOEmail(data) {
    return spbKkt.post("adm/send/emailROOToPurchasing", data);
}
getLoe(payload) {
    return spborder.post('/spb/spb_outstandingorder/getLoe', payload);
    
}




}

export default new RegisterOutstandingOrder();