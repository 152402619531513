export function UrlTab(){
    const hostname = window.location.hostname;
    // let url = 'https://spb.isupplier-portal.com/#';
    let url = '';
    if(hostname.includes('dev')) url = 'https://dev.isupplier-portal.com/#';
    else if(hostname.includes('149')) url = 'http://149.129.241.185:31010/#'
    else if(hostname.includes('spb')) url = 'https://spb.isupplier-portal.com/#';
    else url = 'http://localhost:8080/#'

    return url
}