<template>
	<!-- eslint-disable -->
	<DataTable
		:value="tableList"
		:lazy="true"
		:paginator="true"
		:rows="10"
		:totalRecords="totalData"
		:loading="loading"
		:rowsPerPageOptions="[10, 20, 30]"
		responsiveLayout="scroll"
		@page="onPage($event)"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #empty>{{ empty }}</template>
		<template #loading>Loading data</template>
		<Column header="ID" field="po_header_id" headerStyle="min-width:5rem;">
		</Column>
		<Column header="Process" field="process_code" headerStyle="min-width:10rem;">
		</Column>
		<Column header="Action" field="action" headerStyle="min-width:4rem;">
		</Column>
		<Column header="PO Number" field="po_header_id" headerStyle="min-width:10rem;">
		</Column>
		<Column header="Org ID" field="org_id" headerStyle="min-width:10rem;">
		</Column>
		<Column header="PO Type"  headerStyle="min-width:10rem;">
		</Column>
		<Column header="Currency" field="currency_code" headerStyle="min-width:5rem;">
		</Column>
		<Column header="Supplier" field="vendor_name" headerStyle="min-width:10rem;">
		</Column>
		<Column header="ToP" headerStyle="min-width:10rem;">
		</Column>
		<Column header="Freight" field="freight_terms" headerStyle="min-width:10rem;">
		</Column>
		<Column header="Incoterm" headerStyle="min-width:10rem;">
		</Column>
		<Column header="View" headerStyle="min-width:10rem;">
		</Column>
	</DataTable>
</template>

<script setup>
import { onMounted, ref} from 'vue';
import AuthService from '../../../service/AuthService';
import PoInterface from '../../../service/PoInterface';


// eslint-disable-next-line no-undef
const props = defineProps({
	search: {
		type: Object,
	},
	choose: {
		type: Object,
	}
});

onMounted(async () => {
	populateTable()
});


const loading = ref(false);
const page = ref(1)
const rowsPerPage = ref(10)
const tableList = ref();
const totalData = ref(0);
const empty = ref('Search for Data')
// const index = ref()

	
const populateTable = async () => {
	AuthService.whoAmi().then(async (response) => {
		if (response.status === 200){
			loading.value = true;
			const payload = { search : props.search ? props.search : null, 
			choose : props.choose ? props.choose : null,
			page : page.value, 
			rows : rowsPerPage.value}
			const data = await PoInterface.getTable(payload)
			loading.value = true
			if (data.data.data.totalData[0].totalData > 0){
				tableList.value = data.data.data.data
				totalData.value = data.data.data.totalData[0].totalData
			} else {
				tableList.value = []
				totalData.value = []
				empty.value = 'Data not Found'
			}
		loading.value = false;
		} else {
			window.alert('Session is expired')
		}
	})
};

const onPage = async (event) => {
  loading.value = true
  page.value = event.page + 1;
  rowsPerPage.value = event.rows;
  await populateTable();
}


</script>

<style scoped></style>
