<template>
	<!-- Create Input Form -->
	<div class="col-12 p-fluid mb-4 create-invoice-po">
		<Toast />

		<!-- Progress modal -->
		<ProgressModal :progress="progress">
			<template #message>
				Saving invoice in progress. Please wait...
			</template>
		</ProgressModal>

		<div class="content-wrapper">
			<div class="content-header">{{ title }}</div>
			<div class="content-body">
				<div class="grid">
					<!-- Dropdown company -->
					<div
						class="col-12 p-0 pb-2 mb-6 border-bottom-1 border-gray-200"
					>
						<div class="col-12 md:col-6 lg:col-4">
							<div class="mb-5">
								<span class="p-float-label">
									<AutoComplete
										id="company"
										:disabled="!companyList"
										:class="{
											'p-invalid': v$.company.$error,
										}"
										v-model="company"
										:suggestions="filteredCompany"
										@complete="searchCompany($event)"
										:dropdown="true"
										field="name"
										forceSelection
									>
										<template #item="{ item }">
											<div>
												{{ item.name }}
											</div>
										</template>
									</AutoComplete>
									<label for="company">Company</label>
								</span>
								<div v-if="v$.company.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.company.$errors[0].$message }}
									</small>
								</div>
							</div>
						</div>
					</div>

					<!-- Header Form -->
					<div
						class="col-12 grid p-0 pb-2 mb-4 border-bottom-1 border-gray-200"
					>
						<!-- Col 1 -->
						<div class="col-12 md:col-6 lg:col-4">
							<div class="mb-5">
								<span class="p-float-label">
									<InputText
										id="invoiceNo"
										:class="{
											'p-invalid':
												v$.invoiceNo.$error ||
												errorExisting,
										}"
										v-model="invoiceNo"
									/>
									<label for="invoiceNo">Invoice No</label>
								</span>
								<div v-if="v$.invoiceNo.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.invoiceNo.$errors[0].$message }}
									</small>
								</div>
								<div v-if="errorExisting" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										Invoice Number already exist
									</small>
								</div>
							</div>
							<div class="mb-5">
								<span class="p-float-label">
									<Calendar
										id="invoiceDate"
										:class="{
											'p-invalid': v$.invoiceDate.$error,
										}"
										v-model="invoiceDate"
										dateFormat="d-M-yy"
										:showIcon="true"
									/>
									<label for="invoiceDate"
										>Invoice Date</label
									>
								</span>
								<div v-if="v$.invoiceDate.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.invoiceDate.$errors[0].$message }}
									</small>
								</div>
							</div>
							<div class="mb-5">
								<span class="p-float-label">
									<Textarea
										id="description"
										:class="{
											'p-invalid': v$.description.$error,
										}"
										rows="5"
										v-model="description"
										:maxlength="230"
									/>
									<label for="description">Description</label>
								</span>
								<div v-if="v$.description.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.description.$errors[0].$message }}
									</small>
								</div>
							</div>
						</div>
						<!-- Col 2 -->
						<div class="col-12 md:col-6 lg:col-4">
							<div class="mb-5">
								<span class="p-float-label">
									<InputText
										id="taxInvoiceNo"
										@change="handleChangeTax($event)"
										:disabled="!invoiceDate"
										v-model="taxInvoiceNo"
										:class="{
											'p-invalid': v$.taxInvoiceNo.$error,
										}"
									/>
									<label for="taxInvoiceNo"
										>Tax Invoice No</label
									>
								</span>
								<div v-if="v$.taxInvoiceNo.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{
											v$.taxInvoiceNo.$errors[0].$message
										}}
									</small>
								</div>
							</div>
							<div class="mb-5">
								<span class="p-float-label">
									<Calendar
										id="taxInvoiceDate"
										:class="{
											'p-invalid':
												v$.taxInvoiceDate.$error,
										}"
										dateFormat="d-M-yy"
										:showIcon="true"
										v-model="taxInvoiceDate"
									/>
									<label for="taxInvoiceDate"
										>Tax Invoice Date</label
									>
								</span>
								<div
									v-if="v$.taxInvoiceDate.$error"
									class="mt-1"
								>
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{
											v$.taxInvoiceDate.$errors[0]
												.$message
										}}
									</small>
								</div>
							</div>
							<div class="mb-5">
								<FileUpload
									class="w-full"
									ref="fileUpload"
									mode="advanced"
									:class="{
										'error-file': v$.file.$error,
									}"
									chooseLabel="Upload File"
									fileLimit="1"
									accept="application/pdf"
									:maxFileSize="5000000"
									:showUploadButton="false"
									:showCancelButton="false"
									:customUpload="true"
									@select="onSelectFile"
									@remove="onRemoveFile"
								/>
							</div>
						</div>
						<!-- Col 3 -->
						<div class="col-12 md:col-6 lg:col-4">
							<div class="mb-5">
								<span class="p-float-label">
									<InputText
										class="statusInvoice"
										disabled
										v-model="status"
									/>
									<label for="statusInvoice">Status</label>
								</span>
							</div>
							<div class="mb-5 flex">
								<div class="flex-grow-1 mr-3">
									<span class="p-float-label">
										<InputText
											id="skNumber"
											:class="{
												'p-invalid':
													isForwarder &&
													v$.skNumber.$error,
											}"
											disabled
											style="color: #000 !important"
											v-model="skNumber"
										/>
										<label for="skNumber"
											>Reference Number</label
										>
									</span>
									<div
										v-if="isForwarder && v$.skNumber.$error"
										class="mt-1"
									>
										<small
											class="p-error"
											style="font-size: 12px"
										>
											{{
												v$.skNumber.$errors[0].$message
											}}
										</small>
									</div>
								</div>
								<div>
									<Button
										type="button"
										class="p-button-secondary"
										:disabled="!company || !vendorId"
										@click="displaySKModal = true"
									>
										<span class="uppercase font-bold"
											>Find SK</span
										>
									</Button>
								</div>
							</div>
							<div class="mb-5">
								<span class="p-float-label">
									<AutoComplete
										forceSelection
										field="name"
										:class="{
											'p-invalid': v$.bank.$error,
										}"
										:disabled="!bankList"
										:dropdown="true"
										:suggestions="filteredBanks"
										v-model="bank"
										@complete="searchBank($event)"
									/>
									<label for="bank">Bank</label>
								</span>
								<div v-if="v$.bank.$error" class="mt-1">
									<small
										class="p-error"
										style="font-size: 12px"
									>
										{{ v$.bank.$errors[0].$message }}
									</small>
								</div>
							</div>
						</div>
					</div>

					<!-- Detail Form -->
					<div class="col-12 grid">
						<div class="col-12 md:col-6 lg:col-8 mb-3">
							<!-- Add category detail form -->
							<p class="text-2xl font-bold mb-5">Line</p>
							<div class="flex w-12 lg:w-10 xl:w-8">
								<div class="flex-grow-1">
									<span class="p-float-label">
										<AutoComplete
											id="detailCategory"
											:suggestions="filteredCategory"
											:dropdown="true"
											:disabled="!categoryDetail"
											field="category_detail"
											forceSelection
											v-model="category"
											@complete="searchCategory($event)"
										/>
										<label for="detailCategory"
											>Detail Category</label
										>
									</span>
								</div>
								<Button
									type="button"
									class="p-button-success w-auto ml-3"
									@click="handleAddCategory"
								>
									<i class="pi pi-plus" />
									<span class="uppercase font-bold ml-2"
										>Add Line</span
									>
								</Button>
							</div>

							<!-- Warning message if vendor Forwarder not select SK Number yet -->
							<div
								v-if="isWarningShown"
								class="col-12 p-0 lg:col-8 mt-3"
							>
								<Message severity="warn" :closable="false"
									>Please select SK Number first</Message
								>
							</div>
						</div>

						<!-- Summary invoice -->
						<div class="col-12 md:col-6 lg:col-4 mb-3">
							<div class="form-card">
								<div
									class="flex align-items-center justify-content-between mb-4"
								>
									<span
										style="color: #000"
										class="font-semibold"
										>Total Line</span
									>
									<span class="font-bold">
										{{
											new Intl.NumberFormat('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}).format(summary.totalLine)
										}}
									</span>
								</div>
								<div
									class="flex align-items-center justify-content-between mb-4"
								>
									<span
										style="color: #000"
										class="font-semibold"
										>Total PPn</span
									>
									<span class="font-bold">
										{{
											new Intl.NumberFormat('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}).format(summary.totalPpn)
										}}
									</span>
								</div>
								<div
									class="flex align-items-center justify-content-between"
								>
									<span
										style="color: #000"
										class="font-semibold"
										>Total Invoice</span
									>
									<span class="font-bold">
										{{
											new Intl.NumberFormat('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}).format(summary.totalInvoice)
										}}
									</span>
								</div>
							</div>
						</div>

						<!-- Category table -->
						<div class="col-12 mb-8">
							<DataTable
								:value="selectedCategory"
								responsiveLayout="scroll"
							>
								<Column header="Description">
									<template #body="{ data, index }">
										<InputText
											type="text"
											style="color: #000 !important"
											:class="{
												'p-invalid':
													v$.selectedCategory
														.$error &&
													v$.selectedCategory
														.$errors[0].$response
														.$data[index]
														.description.$error,
											}"
											:disabled="isForwarder"
											v-model="data.description"
										/>
										<div
											v-if="
												v$.selectedCategory.$error &&
												v$.selectedCategory.$errors[0]
													.$response.$data[index]
													.description.$error
											"
											class="mt-1"
										>
											<small
												class="p-error"
												style="font-size: 12px"
											>
												{{
													v$.selectedCategory
														.$error &&
													v$.selectedCategory
														.$errors[0].$response
														.$errors[index]
														.description[0].$message
												}}
											</small>
										</div>
									</template>
								</Column>
								<Column
									header="Amount"
									headerStyle="max-width: 10rem;"
								>
									<template #body="{ data, index }">
										<InputNumber
											class="col-number"
											:class="{
												'p-invalid':
													v$.selectedCategory
														.$error &&
													v$.selectedCategory
														.$errors[0].$response
														.$data[index].amount
														.$error,
											}"
											:min="0"
											mode="decimal"
											locale="en-US"
											:minFractionDigits="2"
											:maxFractionDigits="2"
											style="text-align: right"
											v-model="data.amount"
										/>
										<div
											v-if="
												v$.selectedCategory.$error &&
												v$.selectedCategory.$errors[0]
													.$response.$data[index]
													.amount.$error
											"
											class="mt-1"
										>
											<small
												class="p-error"
												style="font-size: 12px"
											>
												{{
													v$.selectedCategory
														.$error &&
													v$.selectedCategory
														.$errors[0].$response
														.$errors[index]
														.amount[0].$message
												}}
											</small>
										</div>
									</template>
								</Column>
								<Column
									header="VAT"
									headerStyle="max-width: 8rem"
								>
									<template #body="{ data }">
										<Dropdown
											:options="vatRates"
											optionLabel="vat_code"
											placeholder="Select VAT"
											v-model="data.vat"
										/>
									</template>
								</Column>
								<Column header="Ref Number">
									<template #body="{ data, index }">
										<div class="p-inputgroup">
											<InputText
												placeholder="Ref Number"
												:disabled="true"
												v-model="
													data.travelOrder
														.travelOrderNo
												"
											/>
											<Button
												icon="pi pi-search"
												class="p-button-secondary"
												:disabled="!supplierName"
												@click="openTOModal(index)"
											/>
										</div>
									</template>
								</Column>
								<Column>
									<template #body="{ index }">
										<Button
											icon="pi pi-trash"
											class="p-button-rounded p-button-text"
											@click="handleDeleteCategory(index)"
										/>
									</template>
								</Column>

								<!-- Empty state -->
								<template #empty>
									<div>No item.</div>
								</template>
							</DataTable>
						</div>

						<!-- Reimbursement section -->
						<div class="col-12 mb-6" v-if="showReimburseTable">
							<div
								class="flex align-items-center justify-content-between mb-3"
							>
								<p class="text-2xl font-bold mb-0">Reimburse</p>
								<Button
									type="button"
									class="p-button-success w-auto"
									@click="handleAddReimbursement"
								>
									<i class="pi pi-plus" />
									<span class="uppercase font-bold ml-2"
										>Add Line</span
									>
								</Button>
							</div>

							<!-- Reimburse table -->
							<DataTable
								:value="reimburseItems"
								responsiveLayout="scroll"
							>
								<Column header="Vendor">
									<template #body="{ data }">
										<AutoComplete
											v-model="data.vendor"
											forceSelection
											placeholder="Vendor"
											field="vendor_name"
											:disabled="!vendors"
											:suggestions="filteredVendors"
											@complete="searchVendors($event)"
										/>
									</template>
								</Column>
								<Column header="No Faktur Pajak">
									<template #body="{ data, index }">
										<InputText
											v-model="data.taxNo"
											:class="{
												'p-invalid':
													v$.reimburseItems.$error &&
													v$.reimburseItems.$errors[0]
														.$response.$data[index]
														.taxNo.$error,
											}"
											placeholder="Tax Number"
										/>
										<div
											v-if="
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$data[index]
													.taxNo.$error
											"
											class="mt-1"
										>
											<small
												class="p-error"
												style="font-size: 12px"
											>
												{{
													v$.reimburseItems.$error &&
													v$.reimburseItems.$errors[0]
														.$response.$errors[
														index
													].taxNo[0].$message
												}}
											</small>
										</div>
									</template>
								</Column>
								<Column header="Tanggal Faktur Pajak">
									<template #body="{ data, index }">
										<Calendar
											v-model="data.taxDate"
											class="line-calendar"
											:class="{
												'p-invalid':
													v$.reimburseItems.$error &&
													v$.reimburseItems.$errors[0]
														.$response.$data[index]
														.taxDate.$error,
											}"
											dateFormat="d-M-yy"
											:showIcon="true"
											placeholder="Select Date"
										/>
										<div
											v-if="
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$data[index]
													.taxDate.$error
											"
											class="mt-1"
										>
											<small
												class="p-error"
												style="font-size: 12px"
											>
												{{
													v$.reimburseItems.$error &&
													v$.reimburseItems.$errors[0]
														.$response.$errors[
														index
													].taxDate[0].$message
												}}
											</small>
										</div>
									</template>
								</Column>
								<Column header="Nilai Pajak">
									<template #body="{ data, index }">
										<InputNumber
											v-model="data.taxAmount"
											class="col-number"
											:class="{
												'p-invalid':
													v$.reimburseItems.$error &&
													v$.reimburseItems.$errors[0]
														.$response.$data[index]
														.taxAmount.$error,
											}"
											mode="decimal"
											locale="en-US"
											:minFractionDigits="2"
											:maxFractionDigits="2"
											:min="0"
										/>
										<div
											v-if="
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$data[index]
													.taxAmount.$error
											"
											class="mt-1"
										>
											<small
												class="p-error"
												style="font-size: 12px"
											>
												{{
													v$.reimburseItems.$error &&
													v$.reimburseItems.$errors[0]
														.$response.$errors[
														index
													].taxAmount[0].$message
												}}
											</small>
										</div>
									</template>
								</Column>
								<Column header="Group">
									<template #body="{ data, index }">
										<Dropdown
											:options="reimburstGroups"
											:class="{
												'p-invalid':
													v$.reimburseItems.$error &&
													v$.reimburseItems.$errors[0]
														.$response.$data[index]
														.group.$error,
											}"
											optionLabel="name"
											placeholder="Select Group"
											v-model="data.group"
										/>
										<div
											v-if="
												v$.reimburseItems.$error &&
												v$.reimburseItems.$errors[0]
													.$response.$data[index]
													.group.$error
											"
											class="mt-1"
										>
											<small
												class="p-error"
												style="font-size: 12px"
											>
												{{
													v$.reimburseItems.$error &&
													v$.reimburseItems.$errors[0]
														.$response.$errors[
														index
													].group[0].$message
												}}
											</small>
										</div>
									</template>
								</Column>
								<Column>
									<template #body="{ index }">
										<Button
											icon="pi pi-trash"
											class="p-button-rounded p-button-text"
											@click="
												handleDeleteReimburse(index)
											"
										/>
									</template>
								</Column>

								<!-- Empty state -->
								<template #empty>
									<div>No item.</div>
								</template>
							</DataTable>
						</div>
					</div>
				</div>

				<!-- Action buttons -->
				<div class="grid">
					<div class="col-12 md:col-6 lg:col-3">
						<Button
							v-if="!progress"
							class="p-button-success justify-content-center"
							:disabled="
								status === 'DRAFT' || !vendorId || !company
							"
							@click="handleSave"
						>
							<span class="font-bold uppercase">Save</span>
						</Button>
						<Button
							v-else
							class="p-button-success justify-content-center"
							disabled
						>
							<span class="font-bold uppercase">Saving...</span>
						</Button>
					</div>
					<div class="col-12 md:col-6 lg:col-3">
						<Button
							class="p-button-secondary justify-content-center"
							@click="handleCancel"
						>
							<span class="font-bold uppercase">Reset</span>
						</Button>
					</div>
				</div>

				<!-- Find SK Modal -->
				<Dialog
					header="Select SK"
					:draggable="false"
					:breakpoints="{ '960px': '75vw', '640px': '100vw' }"
					:style="{ width: '25vw', overflow: 'hidden' }"
					:modal="true"
					:dismissableMask="true"
					v-model:visible="displaySKModal"
				>
					<div class="pt-4">
						<FindSKModal
							:dbInstance="company.db_instance"
							:vendorId="vendorId"
							@on-close="displaySKModal = false"
							@on-save="addSKNumber"
						/>
					</div>
				</Dialog>

				<!-- Find Travel Order Modal -->
				<Dialog
					header=" "
					:draggable="false"
					:breakpoints="{ '960px': '75vw', '640px': '100vw' }"
					:style="{ width: '75vw', overflow: 'hidden' }"
					:modal="true"
					:dismissableMask="true"
					v-model:visible="displayTOModal"
				>
					<div class="pt-3">
						<FindTOModal
							:supplierName="supplierName"
							@on-close="closeTOModal"
							@on-add="handleAddTO"
						/>
					</div>
				</Dialog>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onUnmounted, defineProps } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { useToast } from 'primevue/usetoast';
import SimpleCrypto from 'simple-crypto-js';
import LovService from '../../service/LovService';
import PlanningOrderService from '../../service/PlanningOrderService';
import PoFulfillment from '../../service/PoFulfillment';
import InvoicePOService from '../../service/InvoicePOService';
import InvoiceCreateNonPOService from '../../service/InvoiceCreateNonPO';
import ProgressModal from './ProgressModal.vue';
import FindSKModal from '../components/FindSKModal.vue';
import FindTOModal from './FindTOModal.vue';

// State
const currentUser = ref(props.currentUser);
const company = ref(null);
const vendorId = ref(null);
const vendorName = ref(null);
const supplierName = ref(null);
const invoiceNo = ref(null);
const invoiceDate = ref(null);
const description = ref(null);
const bank = ref(null);
const taxInvoiceNo = ref(null);
const taxInvoiceDate = ref(null);
const status = ref(null);
const file = ref(null);
const fileUpload = ref(null);
const skNumber = ref(null);
const bankList = ref(null);
const filteredBanks = ref(null);
const companyList = ref(null);
const filteredCompany = ref(null);
const categoryHeader = ref(null);
const categoryDetail = ref(null);
const category = ref(null);
const filteredCategory = ref(null);
const selectedCategory = ref([]);
const vatRates = ref(null);
const travelOrderIndex = ref(null);
const reimburseItems = ref([]);
const vendors = ref(null);
const filteredVendors = ref(null);
const reimburstGroups = ref(null);
const progress = ref(false);
const displaySKModal = ref(false);
const displayTOModal = ref(false);
const isWarningShown = ref(false);
const errorExisting = ref(false);

// Props
const props = defineProps(['title', 'id', 'currentUser']);

// Check if supplier is forwarder
const isForwarder = computed(
	() =>
		categoryHeader.value &&
		categoryHeader.value.some((item) =>
			item.category_name.toLowerCase().includes('forwarder')
		)
);

// Custom validator
const cannotZero = (value) => value !== 0;

// Vuelidate rules
const validationRules = computed(() => {
	const rules = {
		company: { required },
		invoiceNo: { required },
		invoiceDate: { required },
		description: { required },
		taxInvoiceNo: { required },
		taxInvoiceDate: { required },
		bank: { required },
		file: { required },
		selectedCategory: {
			$each: helpers.forEach({
				description: { required },
				amount: {
					required,
					cannotZero: helpers.withMessage(
						'The amount value must be greater than zero',
						cannotZero
					),
				},
			}),
		},
		reimburseItems: {
			$each: helpers.forEach({
				taxNo: { required },
				taxDate: { required },
				taxAmount: {
					required,
					cannotZero: helpers.withMessage(
						'The amount value must be greater than zero',
						cannotZero
					),
				},
				group: { required },
			}),
		},
	};

	if (isForwarder.value) {
		rules.skNumber = { required };
	}

	return rules;
});

// Validator instance
const v$ = useVuelidate(validationRules, {
	company,
	invoiceNo,
	invoiceDate,
	description,
	taxInvoiceNo,
	taxInvoiceDate,
	skNumber,
	bank,
	file,
	selectedCategory,
	reimburseItems,
});

// Toast intance
const toast = useToast();

// Summary invoice
const summary = computed(() => {
	const totalLine =
		(selectedCategory.value.length &&
			selectedCategory.value.reduce((accumulator, obj) => {
				return accumulator + obj.amount;
			}, 0)) ||
		0;

	const totalPpn =
		(selectedCategory.value.length &&
			selectedCategory.value
				.map((el) => {
					return el.vat && el.vat.value
						? el.vat.value * el.amount
						: 0;
				})
				.reduce((prevValue, currValue) => prevValue + currValue, 0)) ||
		0;

	const totalInvoice = totalLine + totalPpn;

	return {
		totalLine,
		totalPpn,
		totalInvoice,
	};
});

// Show reimburse table
const showReimburseTable = computed(() =>
	selectedCategory.value.some((cat) =>
		cat.description.toLowerCase().includes('reimburstment')
	)
);

// Selected category header
const selectedCategoryHeader = computed(() => {
	// Return null if no selected category detail
	if (!selectedCategory.value.length) {
		return null;
	}

	// Find cat header based on selected cat detail
	const catId =
		selectedCategory.value.length && selectedCategory.value[0].category_id;

	return categoryHeader.value.find((item) => item.category_id === catId);
});

// Mounted hook
onMounted(async () => {
	// Pre-fetch company and vendor
	// const companyRes = getCompany();
	// const vendorRes = getVendorId();

	// await Promise.all([companyRes, vendorRes]);

	await getCompany();
	await getVendorId();

	// Get supplier name, list bank, category, vat, reimburst group
	const getSuppName = getSupplierName();
	const getBank = getListBank();
	const getCategory = getCategoryVendor();
	const getVat = getVatRate();
	const getVendors = getListVendor();
	const getGroups = getReimburstGroups();

	await Promise.all([
		getSuppName,
		getBank,
		getCategory,
		getVat,
		getVendors,
		getGroups,
	]);
});

// Unmounted hook
onUnmounted(() => {
	unwatchCompany();
	unwatchInvoiceDate();
});

watch(company, () =>{ handleCancel() })

// Watch selected company
const unwatchCompany = watch(company, async (newCompany, oldCompany) => {
	if (oldCompany) {
		bank.value = null;
		bankList.value = [];
		categoryHeader.value = null;
		categoryDetail.value = null;
		skNumber.value = null;
		description.value = null;
		vatRates.value = null;
		vendors.value = null;
		reimburstGroups.value = null;
		category.value = null;
		selectedCategory.value = [];
		reimburseItems.value = [];

		vendorId.value = null; // Reset old vendorId

		// Get new vendorId for new company
		const res = await PlanningOrderService.getSuppValue(
			{
				vendorName: vendorName.value,
			},
			company.value.db_instance
		);
		vendorId.value = res.data.data[0].vendor_id;

		getListBank();
		getCategoryVendor();
		getVatRate();
		getListVendor();
		getReimburstGroups();
	}

	toast.removeAllGroups();
});

// Watch invoice date for fetching list vat rate
const unwatchInvoiceDate = watch(invoiceDate, () => {
	// Reset vat options and selected vat
	vatRates.value = null;
	selectedCategory.value = selectedCategory.value.map((item) => ({
		...item,
		vat: null,
	}));
	getVatRate();
});

// Get dropdown company
const getCompany = async () => {
	if (currentUser.value.userid === 'dexaadmin') {
		// If user is admin
		const companyRes = LovService.getValueType('COMPANY');
		companyList.value = companyRes.data.data;
	} else if (currentUser.value.leveltenant === 2) {
		// If user is supplier
		let payload = currentUser.value.id;
		const companySuppRes = await PoFulfillment.getCompanySupp(payload);

		try {
			const companySupp = companySuppRes.data.data;
			const companyValues = await Promise.all(
				companySupp.map(async (element) => {
					const res = await LovService.getCompanyByName(
						element.company
					);

					const secretKey = 'some-unique-key';
					const simpleCrypto = new SimpleCrypto(secretKey);
					const decipherText = simpleCrypto.decrypt(
						sessionStorage.getItem('dropdownCompany')
					);

					if (res.data.data[0].name === decipherText) {
						company.value = res.data.data[0];
					}

					return res.data.data[0];
				})
			);

			companyList.value = companyValues;
		} catch (e) {
			console.log(e);
			if (e !== 'Break') throw e;
		}
	}
};

// Get VendorId of logged in user
const getVendorId = async () => {
	const resVendorName = await PoFulfillment.getSuppBindComp(
		currentUser.value.id
	);
	vendorName.value = resVendorName.data.data[0][0].vendorname;
	const payloadSuppVal = {
		vendorName: resVendorName.data.data[0][0].vendorname,
	};

	const resVendorId = await PlanningOrderService.getSuppValue(
		payloadSuppVal,
		company.value.db_instance
	);
	vendorId.value = resVendorId.data.data[0].vendor_id;
};

// Get Supplier Name
const getSupplierName = async () => {
	const payload = {
		dbInstance: company.value.db_instance,
		vendorId: vendorId.value,
	};

	try {
		const res = await InvoiceCreateNonPOService.getSupplierName(payload);

		if (res.status === 200) {
			supplierName.value = res.data.data;
		}
	} catch (err) {
		console.log(err);
	}
};

// Get bank account list
const getListBank = async () => {
	const payload = {
		dbInstance: company.value.db_instance,
		orgId: company.value.value,
		vendorId: vendorId.value,
	};

	const res = await InvoicePOService.getListBank(payload);

	if (res.status === 200) {
		bankList.value = res.data.data.map((item) => {
			return {
				id: item.ext_bank_account_id,
				name: item.transfer_to,
			};
		});
	}
};

// Get line categories
const getCategoryVendor = async () => {
	const payload = {
		dbInstance: company.value.db_instance,
		orgId: company.value.value,
		vendorId: vendorId.value,
	};

	const res = await InvoiceCreateNonPOService.getCategoryVendor(payload);

	if (res.status === 200) {
		categoryHeader.value = res.data.data.categoryHeader;

		if (!isForwarder.value) {
			categoryDetail.value = res.data.data.categoryDetail;
		} else {
			categoryDetail.value = [];
		}
	}
};

// Get VAT rate options
const getVatRate = async () => {
	const payload = {
		dbInstance: company.value.db_instance,
		invoiceDate:
			invoiceDate.value && formattingDate(new Date(invoiceDate.value)),
	};

	const res = await InvoiceCreateNonPOService.getVatRate(payload);

	if (res.status === 200) {
		vatRates.value = res.data.data.map((item) => ({
			...item,
			value: Math.round((item.percentage_rate / 100) * 1000) / 1000,
		}));

		// Push no select option
		vatRates.value = [
			{ vat_code: '-No Select-', value: null },
			...vatRates.value,
		];
	}
};

// Get list vendor
const getListVendor = async () => {
	try {
		const res = await InvoiceCreateNonPOService.getListVendor({
			dbInstance: company.value.db_instance,
		});

		if (res.status === 200) {
			vendors.value = res.data.data;
		}
	} catch (err) {
		console.log(err);
	}
};

// Get reimburst groups options
const getReimburstGroups = async () => {
	const payload = {
		dbInstance: company.value.db_instance,
		type: 'DXG_REIMBURST_GROUP',
	};

	try {
		const res = await InvoiceCreateNonPOService.getReimburstGroups(payload);

		if (res.status === 200) {
			reimburstGroups.value = res.data.data;
		}
	} catch (err) {
		console.log(err);
	}
};

// Search company on autocomplete
const searchCompany = (event) => {
	if (!event.query.trim().length) {
		filteredCompany.value = [...companyList.value];
	} else {
		filteredCompany.value = companyList.value.filter((item) => {
			return item.name.toLowerCase().match(event.query.toLowerCase());
		});
	}
};

// Search bank on autocomplete
const searchBank = (event) => {
	if (!event.query.trim().length) {
		filteredBanks.value = [...bankList.value];
	} else {
		filteredBanks.value = bankList.value.filter((bank) => {
			return bank.name.toLowerCase().match(event.query.toLowerCase());
		});
	}
};

// Search category on autocomplete
const searchCategory = async (event) => {
	isWarningShown.value = false;

	if (isForwarder.value) {
		// Check if SK Number is selected
		if (!skNumber.value) {
			isWarningShown.value = true;
			filteredCategory.value = [];
			return;
		}

		// Fetch category detail based on selected SK Number
		const payload = {
			categoryId: categoryHeader.value.map((item) => item.category_id),
			dbInstance: company.value.db_instance,
			orgId: company.value.value,
			skNumber: skNumber.value,
			vendorId: vendorId.value,
		};

		const res = await InvoiceCreateNonPOService.getAvailableCategoryDetails(
			payload
		);
		const categories = res.data.data;

		if (!event.query.trim().length) {
			filteredCategory.value = [...categories];
		} else {
			filteredCategory.value = categories.filter((category) => {
				return category.category_detail
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
		}
	} else {
		if (!event.query.trim().length) {
			filteredCategory.value = [...categoryDetail.value];
		} else {
			filteredCategory.value = categoryDetail.value.filter((category) => {
				return category.category_detail
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
		}
	}
};

// Search vendors on autocomplete
const searchVendors = async (event) => {
	if (!event.query.trim().length) {
		filteredVendors.value = [...vendors.value];
	} else {
		filteredVendors.value = vendors.value.filter((vendor) => {
			return (
				vendor.vendor_name &&
				vendor.vendor_name
					.toLowerCase()
					.match(event.query.toLowerCase())
			);
		});
	}
};

// Select file attachment
const onSelectFile = (e) => {
	file.value = e.files[0];
};

// Remove file attachment
const onRemoveFile = (e) => {
	if (!e.files.length) {
		file.value = null;
	}
};

// Add SK Number
const addSKNumber = (data) => {
	skNumber.value = data.sk_number;
	displaySKModal.value = false;
	isWarningShown.value = false;

	// Add PO Number to description field and reset selected category detail if user is forwarder
	if (isForwarder.value) {
		description.value = data.po_number;
		selectedCategory.value = [];
		category.value = null;
	}
};

// Add line category
const handleAddCategory = () => {
	toast.removeAllGroups();

	// Check if no selected category
	if (!category.value) {
		return;
	}

	// Check if category detail is from different category id
	if (selectedCategoryHeader.value) {
		if (
			category.value.category_id !==
			selectedCategoryHeader.value.category_id
		) {
			category.value = null;
			toast.add({
				severity: 'warn',
				summary: 'Cannot add detail',
				detail: 'Please add category detail from the same category ID',
				life: null,
			});
			return;
		}
	}

	// Check if forwarder
	if (isForwarder.value) {
		const isAdded = selectedCategory.value.some(
			(cat) =>
				cat.category_detail_id === category.value.category_detail_id
		);
		if (isAdded) {
			category.value = null;
			return;
		}

		const isCoa_Null = category.value.account_id;
		if(!isCoa_Null){
			toast.add({
				severity: 'error',
				summary: 'Cannot add detail',
				detail: 'Kategori tidak memiliki Credential of Account',
				life: null,
			});
			return;
		}

		selectedCategory.value.push({
			...category.value,
			description: category.value.category_detail,
			amount: 0,
			vat: null,
			travelOrder: {
				travelOrderNo: null,
				guestName: null,
				startDate: null,
				endDate: null,
			},
		});
	} else {
		selectedCategory.value.push({
			...category.value,
			description: category.value.category_detail,
			amount: 0,
			vat: null,
			travelOrder: {
				travelOrderNo: null,
				guestName: null,
				startDate: null,
				endDate: null,
			},
		});
	}

	category.value = null;
};

// Delete line category
const handleDeleteCategory = (deletedIndex) => {
	toast.removeAllGroups();

	selectedCategory.value = selectedCategory.value.filter(
		(el, index) => index !== deletedIndex
	);

	// Check if reimburse category is not selected
	const isReimburseExist = selectedCategory.value.some((cat) =>
		cat.description.toLowerCase().includes('reimburstment')
	);
	if (!isReimburseExist) {
		// Reset reimburse items array
		reimburseItems.value = [];
	}
};

// Add reimbursement item
const handleAddReimbursement = () => {
	reimburseItems.value = [
		...reimburseItems.value,
		{
			vendor: null,
			taxNo: null,
			taxDate: null,
			taxAmount: 0,
			group: null,
		},
	];
};

// Delete reimbursement item
const handleDeleteReimburse = (deletedIndex) => {
	reimburseItems.value = reimburseItems.value.filter(
		(el, index) => index !== deletedIndex
	);
};

// Open TO Modal
const openTOModal = (index) => {
	displayTOModal.value = true;
	travelOrderIndex.value = index;
};

// Close TO Modal
const closeTOModal = () => {
	displayTOModal.value = false;
	travelOrderIndex.value = null;
};

const handleChangeTax = (event) => {
	const {value} = event.target;
	taxInvoiceNo.value = value.replace(/[a-zA-Z]/g,"").substring(0,20);
}

// Handle add TO
const handleAddTO = (selectedTO) => {
	selectedCategory.value = selectedCategory.value.map((category, index) => {
		if (index === travelOrderIndex.value) {
			category.travelOrder = {
				travelOrderNo: selectedTO.travel_order_no,
				guestName: selectedTO.guest_name,
				startDate: selectedTO.start_date,
				endDate: selectedTO.end_date,
			};
		}

		return category;
	});

	travelOrderIndex.value = null;
	displayTOModal.value = false;
};

// Formatting date
const formattingDate = (date, withTime) => {
	const theDate = new Date(date);
	const strDate =
		theDate.getFullYear() +
		'-' +
		(theDate.getMonth() + 1) +
		'-' +
		theDate.getDate();
	const strTime =
		theDate.getHours() +
		':' +
		theDate.getMinutes() +
		':' +
		theDate.getSeconds();

	if (withTime) {
		return `${strDate} ${strTime}`;
	} else {
		return `${strDate}`;
	}
};

// Save new invoice
const handleSave = async () => {
	errorExisting.value = false;

	// Validate required fields
	const isFormValid = await v$.value.$validate();
	if (!isFormValid) {
		const errMessage =
			v$.value.$errors[0].$validator === 'required'
				? 'Please fill all required fields'
				: v$.value.$errors[0].$message[0][0];

		toast.add({
			severity: 'error',
			summary: errMessage,
			life: 3000,
		});
		return;
	}

	// Validate if there is no details
	if (!selectedCategory.value.length) {
		toast.add({
			severity: 'error',
			summary: 'Please add invoice details',
			life: 3000,
		});
		return;
	}

	// Check if there is details amount of 0
	if (selectedCategory.value.some((val) => val.amount === 0)) {
		toast.add({
			severity: 'error',
			summary: 'The amount value must be greater than zero',
			life: 3000,
		});
		return;
	}

	// Add file payload
	const formData = new FormData();
	formData.append('file', file.value);

	// Add body payload header
	const payload = {
		header: {
			INVOICE_NO: invoiceNo.value,
			INVOICE_DATE: formattingDate(invoiceDate.value),
			REVISION_NUM: 0,
			TAX_NO: taxInvoiceNo.value,
			TAX_DATE: formattingDate(taxInvoiceDate.value),
			DESCRIPTION: description.value,
			CURRENCY_CODE: 'IDR',
			VENDOR_ID: vendorId.value,
			INVOICE_AMOUNT: summary.value.totalLine,
			TAX_AMOUNT: summary.value.totalPpn,
			TOTAL_AMOUNT: summary.value.totalInvoice,
			// SERVICE_AMOUNT: summary.value.totalPph,
			// WHT_CODE: ,
			// WHT_RATE: ,
			// ATTRIBUTE1: ,
			ORG_ID: parseInt(company.value.value),
			STATUS: 'DRAFT',
			CREATION_DATE: formattingDate(new Date(), true),
			CREATED_BY: currentUser.value.id,
			LAST_UPDATE_DATE: formattingDate(new Date(), true),
			LAST_UPDATED_BY: currentUser.value.id,
			// PAYMENT_METHOD_LOOKUP_CODE: ,
			// GL_DATE: ,
			// TERMS_ID: ,
			// REASON: ,
			ACCTS_CODE_COMBINATION_ID: selectedCategoryHeader.value.account_id,
			EXTERNAL_BANK_ACCOUNT_ID: bank.value.id,
			INVOICE_TYPE: 'INVOICE NON PO',
			CATEGORY_ID: selectedCategoryHeader.value.category_id,
			MAPING_ID: selectedCategoryHeader.value.maping_id,
			REFERENCE_NUMBER: skNumber.value,
			VENDOR_SITE_ID: selectedCategoryHeader.value.vendor_site_id,
			COST_CENTER: selectedCategoryHeader.value.cost_center,
			DB_INSTANCE: company.value.db_instance,
		},
		details: [],
		reimburseItems: null,
	};

	// Add payload category details
	payload.details = selectedCategory.value.map((item, index) => {
		const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
		const remarksString =
			item.travelOrder.travelOrderNo &&
			`${item.travelOrder.travelOrderNo}#${
				item.travelOrder.guestName
			}#${new Intl.DateTimeFormat('en-US', dateOptions).format(
				new Date(item.travelOrder.startDate)
			)}#${new Intl.DateTimeFormat('en-US', dateOptions).format(
				new Date(item.travelOrder.endDate)
			)}`;

		return {
			// EINVOICE_ID: ,
			// PO_HEADER_ID: item.po_header_id,
			// PO_LINE_ID: item.po_line_id,
			// PO_NUMBER: item.po_number,
			LINE_NUM: index + 1,
			// ITEM_ID: item.item_id,
			// ITEM_NUMBER: item.item_number,
			ITEM_DESCRIPTION: item.description,
			// UNIT_MEAS_LOOKUP_CODE: item.unit_meas_lookup_code,
			// UNIT_PRICE: item.unit_price,
			// UNIT_PRICE_SUP: ,
			// QUANTITY: item.quantity_received,
			AMOUNT: item.amount,
			// NOTE_TO_VENDOR: ,
			ORGANIZATION_ID: company.value.value,
			// LINE_LOCATION_ID: item.line_location_id,
			VAT_CODE:
				item.vat && !item.vat.vat_code.includes('-No Select-')
					? item.vat.vat_code
					: null,
			// RECEIPT_NUM: item.receipt_num,
			// PACKING_SLIP: item.packing_slip === 'NA' ? null : item.packing_slip,
			// DISTRIBUTION_NUM: item.distribution_num,
			// PO_DISTRIBUTION_ID: item.po_distribution_id,
			// SHIPMENT_NUM: item.shipment_num,
			CREATION_DATE: formattingDate(new Date(), true),
			CREATED_BY: currentUser.value.id,
			LAST_UPDATE_DATE: formattingDate(new Date(), true),
			LAST_UPDATED_BY: currentUser.value.id,
			REMARKS: remarksString,
			// ATTRIBUTE1: item,
			// CURRENCY_CODE: item.currency_code,
			REVISION_NUM: 0,
			// VENDOR_ID: item.vendor_id,
			// VENDOR_SITE_ID: item.vendor_site_id,
			// SHIPMENT_LINE_ID: item.shipment_line_id,
			// SHIPMENT_HEADER_ID: item.shipment_header_id,
			// RCV_TRANSACTION_ID: item.transaction_id,
			CATEGORY_DETAIL_ID: item.category_detail_id,
			// WHT_CODE: item,
			COA_ID: item.account_id,
			VAT_CODE_ID:
				item.vat && !item.vat.vat_code.includes('-No Select-')
					? item.vat.tax_rate_id
					: null,
			// WHT_CODE_ID: item,
			// GROUP_NAME: item,
		};
	});

	// Add payload reimburse items
	if (showReimburseTable.value && reimburseItems.value.length) {
		payload.reimburseItems = reimburseItems.value.map((item) => {
			const coaId = selectedCategory.value.find((cat) =>
				cat.description.toLowerCase().includes('reimburstment')
			)['account_id'];

			return {
				VENDOR_ID: item.vendor ? item.vendor.vendor_id : null,
				TAX_NO: item.taxNo,
				TAX_DATE: formattingDate(item.taxDate),
				AMOUNT: item.taxAmount,
				COA_ID: coaId,
				ORG_ID: company.value.value,
				CREATED_BY: currentUser.value.id,
				CREATION_DATE: formattingDate(new Date()),
				LAST_UPDATED_BY: currentUser.value.id,
				LAST_UPDATE_DATE: formattingDate(new Date()),
				GROUP_NAME: item.group.value,
			};
		});
	}

	// Wrap payload with formData
	const jsonPayload = JSON.stringify(payload);
	formData.append('payload', jsonPayload);

	try {
		progress.value = true;

		const res = await InvoiceCreateNonPOService.saveInvoice(formData);

		if (res.status === 201) {
			progress.value = false;
			invoiceNo.value = res.data.data.invoice_no;
			status.value = 'DRAFT';
			file.value = null;
			fileUpload.value.clear();
			v$.value.$reset();

			toast.add({
				severity: 'success',
				summary: 'Successfully save new invoice',
				life: 3000,
			});

			return;
		}
		progress.value = false;
	} catch (err) {
		console.log(err.message);
		progress.value = false;

		if (err.message.includes('Network Error')) {
			console.log('network error');
			toast.add({
				severity: 'error',
				summary: 'Network Error',
				detail: 'Connection Down. Please check your invoice status on List Invoice Non PO menu.',
			});
			return;
		}

		if (err.response.status === 400 || err.response.status === 403) {
			if (err.response.data.message.includes('exist')) {
				errorExisting.value = true;
			}
			toast.add({
				severity: 'error',
				summary: err.response.data.message,
				life: 3000,
			});
			return;
		}

		toast.add({
			severity: 'error',
			summary: 'Server Error: Unsuccessfully save new invoice',
			life: 3000,
		});
	}
};

// Cancel create invoice
const handleCancel = () => {
	// Reset form
	invoiceNo.value = null;
	invoiceDate.value = null;
	description.value = null;
	taxInvoiceNo.value = null;
	taxInvoiceDate.value = null;
	bank.value = null;
	skNumber.value = null;
	status.value = null;
	file.value = null;
	fileUpload.value.clear();
	selectedCategory.value = [];
	reimburseItems.value = [];

	// Reset validation state
	v$.value.$reset();
	errorExisting.value = false;
};
</script>

<style scoped>
.form-card {
	padding: 24px;
	border: 1px solid #e0e0e0;
	border-radius: 16px;
}

input.statusInvoice,
input#disabledCompany {
	color: #000 !important;
}
</style>
