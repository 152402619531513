<template>
	<div class="Grid">
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search</div>
				<div class="content-body">
					<div class="col-12 p-fluid">
						<div class="grid formgrid" style="margin: 1em 0 0 0">
							<div class="col-12 mb-5 lg:col-3">
								<CompanyDropdown v-model="company" disabled />
							</div>
							<div
								class="col-12 mb-5 lg:col-3"
								v-if="levelUser === 1"
							>
								<SupplierDropdown
									v-model="supplier"
									:company="company ? company : null"
									:key="supplierReload"
								/>
							</div>
							<div
								class="col-12 mb-5 lg:col-3"
								v-if="levelUser === 3"
							>
								<SupplierDropdownPic
									v-model="supplier"
									:company="company ? company : null"
									:key="supplierReload"
								/>
							</div>
							<div class="col-12 mb-5 lg:col-3">
								<BatchNumberDropdown
									v-model="batchdropdown"
									:company="company ? company : null"
									:supplier="supplier ? supplier : null"
									:invoiceDropdown="
										invoiceDropdown ? invoiceDropdown : null
									"
									:key="batchReload"
								/>
							</div>
							<div class="col-12 mb-5 lg:col-3">
								<InvoiceDropdown
									v-model="invoiceDropdown"
									:company="company ? company : null"
									:supplier="supplier ? supplier : null"
									:batchdropdown="
										batchdropdown ? batchdropdown : null
									"
									:key="invoiceReload"
								/>
							</div>
							<div class="mr-3 ml-2">
								<Button
									@click="handleSearch()"
									class="p-button-success uppercase"
									label="search"
									:disabled="loading"
								/>
							</div>
							<div>
								<Button
									@click="clearFilter()"
									class="p-button-secondary uppercase"
									label="clear"
								></Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- search result -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search Results</div>
				<div class="content-body">
					<div class="mt-4">
						<TableInvoiceNonPO
							:company="company ? company : null"
							:supplier="supplier ? supplier : null"
							:batchdropdown="
								batchdropdown ? batchdropdown : null
							"
							:invoiceDropdown="
								invoiceDropdown ? invoiceDropdown : null
							"
							:key="reloadTable"
							@result="getLoading"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import CompanyDropdown from './components/Company.vue';
import BatchNumberDropdown from './components/BatchNumberDrop.vue';
import SupplierDropdown from './components/SupplierDropdown.vue';
import SupplierDropdownPic from '../createbatch/components/SupplierDropdown.vue';
import PoFulfillment from '../../service/PoFulfillment';
import LovService from '../../service/LovService';
import SimpleCrypto from 'simple-crypto-js';
import AuthService from '../../service/AuthService';
import InvoiceDropdown from './components/InvoiceDropdown.vue';
import TableInvoiceNonPO from './components/TableInvoiceNonPO.vue';
import { useToast } from 'primevue/usetoast';
import { useRouter } from 'vue-router';

const router = useRouter();
const company = ref();
const batchdropdown = ref();
const batchReload = ref(0);
const supplier = ref(null);
const userInfo = ref();
const supplierReload = ref(0);
const invoiceDropdown = ref();
const invoiceReload = ref(0);
const loading = ref(false);
const reloadTable = ref(0);
const resultSearch = ref();
const toast = useToast();
const levelUser = ref();

const breadcrumbHome = { icon: 'pi pi-home', to: '/dashboard' };
const breadcrumbItems = [
	{
		label: 'Search Invoice Non PO',
		to: '/mgm/monitor/searchinvoicenonpo',
	},
];

watch(
	() => company.value,
	() => {
		supplier.value = null;
		supplierReload.value++;
		batchdropdown.value = null;
		batchReload.value++;
		invoiceDropdown.value = null;
		invoiceReload.value++;
	}
);

watch(
	() => company.value && supplier.value,
	() => {
		batchdropdown.value = null;
		batchReload.value++;
		invoiceDropdown.value = null;
		invoiceReload.value++;
	}
);

watch(
	() => company.value && batchdropdown.value,
	() => {
		invoiceReload.value++;
	}
);

watch(
	() => company.value && invoiceDropdown.value,
	() => {
		batchReload.value++;
	}
);

watch(
	() => company.value && supplier.value && batchdropdown.value,
	() => {
		invoiceReload.value++;
	}
);

watch(
	() => company.value && supplier.value && invoiceDropdown.value,
	() => {
		batchReload.value++;
	}
);

// watch(batchdropdown, invoiceDropdown, (newQuestion) => {
//
//   typeof newQuestion === "object" && !Array.isArray(newQuestion);
// });

onMounted(() => {
	AuthService.whoAmi().then(async (response) => {
		const userCompany = () => {
			const secretKey = 'some-unique-key';
			const simpleCrypto = new SimpleCrypto(secretKey);
			return simpleCrypto.decrypt(
				sessionStorage.getItem('dropdownCompany')
			);
		};
		if (response.status === 200) {
			userInfo.value = response.data.data;
			levelUser.value = response.data.data.leveltenant;

			if (userInfo.value.leveltenant === 2) {
				//get company
				const respCbN = await LovService.getCompanyByName(
					userCompany()
				);
				company.value = respCbN.data.data[0];
				//get supplier

				const param = {
					db_instance: company.value.db_instance,
					org_id: company.value.value,
				};

				const respVendor = await PoFulfillment.getSuppBindComp(
					response.data.data.id
				);

				const vendorName = respVendor.data.data[0][0].vendorname;
				const respSuppName = await LovService.getSupplierName(param);
				if (respSuppName.status === 200) {
					const suppList = respSuppName.data.data;
					for (const s of suppList) {
						if (s.vendor_name === vendorName) {
							supplier.value = s;
							break;
						}
					}
					if (supplier.value === null) {
						router.push('/dashboard');
						window.alert('Connection Unstable, ID not Recognize');
					}
				}
			} else {
				const respCbN = await LovService.getCompanyByName(
					userCompany()
				);
				company.value = respCbN.data.data[0];
			}
		}
	});
});

const getLoading = (res) => {
	resultSearch.value = res.value;
};

const handleSearch = () => {
	if (!company.value) {
		toast.add({
			severity: 'info',
			summary: 'Please Select Company',
			life: 3000,
		});
		return;
	}
	reloadTable.value++;
};

const clearFilter = () => {
	supplier.value = null;
	batchdropdown.value = null;
	invoiceDropdown.value = null;
	resultSearch.value = null;
	batchReload.value++;
	invoiceReload.value++;
};
</script>
