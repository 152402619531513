<template>
  <AutoComplete
    :suggestions="filteredStatus"
    @complete="searchStatus($event)"
    :dropdown="true"
    field="name"
    placeholder="Status"
    forceSelection
  >
    <template #item="slotProp">
      {{ slotProp.item.name }}
    </template>
  </AutoComplete>
</template>

<script setup>
import { watch, ref } from "vue";
import AuthService from "../../../service/AuthService";
import BatchListService from "../../../service/batchList";

// eslint-disable-next-line no-undef
const filteredStatus = ref([]);
const statusBatchList = ref([]);
const placeholder = ref("select a status batch");
// eslint-disable-next-line no-undef
const props = defineProps({
  company: { type: Object },
  supplier: { type: Object },
});

// onMounted(() => {
//   populateData();
// });

// const populateData = () => {
//   AuthService.whoAmi().then(async (response) => {
//     let payload;
//     if (props.company !== null && props.supplier !== null) {
//       payload = {
//         org_id: props.company.value,
//         supValue: props.supplier.vendor_id,
//       };
//       if (response.status === 200) {
//         const data = await BatchListService.getLovStatus(payload);
//         statusBatchList.value = data.data.data.data;
//       }
//     } else if (props.company !== null) {
//       payload = { org_id: props.company.value };
//       if (response.status === 200) {
//         const data = await BatchListService.getStatuswComp(payload);
//         statusBatchList.value = data.data.data.data;
//       }
//     }
//   });
// };


watch(async () => {
  if (
    (props.company !== null && props.company !== undefined) ||
    (props.supplier !== null && props.supplier !== undefined)
  ) {
    const payload = {
      org_id: props.company.value,
      suppVal: props.supplier? props.supplier.vendor_id.toString() :  null,
    }
    await AuthService.whoAmi().then(async (value) => {
      if (value.status === 200) {
        await BatchListService.getLovStatus(payload).then((response) => {
          const type = typeof response.data.data.data;
          if (type === "string") {
            placeholder.value = "No supplier found";
          } else {
            statusBatchList.value = response.data.data.data;
          }
        });
      }
    });
  }
});


const searchStatus = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredStatus.value = [...statusBatchList.value];
    } else {
      filteredStatus.value = statusBatchList.value.filter((comp) => {
        return comp.name.toLowerCase().match(event.query.toLowerCase());
      });
    }
  }, 250);
};
</script>

<style scoped></style>
