<template>
	<!-- eslint-disable -->
	<div class="grid">
		<ProgressModal :progress="progress">
			<template #message>
				Sending to Supplier in progress.<br />Please wait...
			</template>
		</ProgressModal>
		<!-- Notif Toast -->
		<Toast />

		<!-- Breadcrumb -->
		<div class="col-12 mb-5">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<!-- Search -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search</div>
				<div class="content-body">
					<div class="grid">
						<div class="col-12 lg:col-4">
							<div class="flex align-items-center">
								<span class="font-bold mr-3">Company</span>
								<AutoComplete
									class="w-full"
									@item-select="onSelectCompany"
									v-model="selectedCompany"
									:suggestions="filteredCompanies"
									@complete="searchCompany($event)"
									field="name"
									:dropdown="true"
									forceSelection
									disabled
								/>
							</div>
						</div>
						<div class="col-12 lg:col-4">
							<div class="p-inputgroup">
								<AutoComplete
									placeholder="Search PO Number"
									class="w-full"
									v-model="poNumber"
									:suggestions="filteredPoNum"
									@complete="searchPoNum($event)"
									field="PO_NUM"
									:disabled="this.disableButton"
									forceSelection
								/>
								<!-- <InputText
									type="text"
									v-model="poNumber.SEGMENT1"
									placeholder="Search PO Number"
								/> -->
								<Button
									type="button"
									icon="pi pi-search"
									@click="openSearch"
									aria:haspopup="true"
									aria-controls="overlay_panel"
									:disabled="this.disableButton"
								/>
							</div>

							<span class="p-input-icon-left w-full">
								<OverlayPanel
									ref="op"
									appendTo="body"
									:showCloseIcon="true"
									id="overlay_panel"
									style="width: 450px"
									:breakpoints="{ '960px': '75vw' }"
								>
									<DataTable
										:value="poNumberList"
										v-model:selection="poNumber"
										selectionMode="single"
										:paginator="true"
										:rows="5"
										@rowSelect="onProductSelect"
										responsiveLayout="scroll"
									>
										<!-- <Column
											field="PO_HEADER_ID"
											header="PO ID"
											sortable
											style="width: 50%"
										></Column> -->
										<Column
											field="SEGMENT1"
											header="PO Number"
											sortable
											style="width: 25%"
										></Column>
										<Column
											field="REVISION_NUM"
											header="Revision Num"
											sortable
											style="width: 25%"
										></Column>
										<Column
											field="VENDOR_NAME"
											header="Supplier Name"
											sortable
											style="width: 50%"
										></Column>
										<template #empty>
											<div v-if="loadingPoNum">
												Loading...
											</div>
											<div v-else>
												No records found for this
												company.
											</div>
										</template>
										<!-- <Column
											field="SHIP_TO_LOCATION_ID"
											header="Location ID"
											sortable
											style="width: 50%"
										></Column>
										<Column
											field="CURRENCY_CODE"
											header="Currency"
											sortable
											style="width: 50%"
										></Column> -->
									</DataTable>
								</OverlayPanel>
							</span>
						</div>
						<div class="col-12 lg:col-4">
							<Button
								:class="uppercase"
								class="p-button-success mr-2"
								@click="searchPO"
								:disabled="this.disableButton"
							>
								<span class="uppercase font-bold"
									>Search PO</span
								>
							</Button>
							<Button
								:class="uppercase"
								class="p-button-secondary"
								@click="clearForm"
								><span class="uppercase font-bold"
									>Clear</span
								></Button
							>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Search results -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Search Result</div>
				<div class="content-body">
					<div class="grid align-items-start">
						<!-- Left column -->
						<div class="col-12 lg:col-6">
							<div class="grid align-items-center">
								<div class="col-12 lg:col-4">
									<label
										style="color: #828282"
										class="mr-4 font-bold text-lg"
										for="rev"
										>Rev</label
									>
								</div>
								<div class="col-12 lg:col-6">
									<InputText
										class="w-full"
										id="rev"
										type="text"
										v-model="poValue.REVISION_NUM"
										disabled
									/>
								</div>
							</div>
							<div class="grid align-items-center">
								<div class="col-12 lg:col-4">
									<label
										style="color: #828282"
										class="mr-4 font-bold text-lg"
										for="supplier"
										>Supplier</label
									>
								</div>
								<div class="col-12 lg:col-6">
									<InputText
										class="w-full"
										id="supplier"
										type="text"
										v-model="poValue.VENDOR_NAME"
										disabled
									/>
								</div>
							</div>
							<div class="grid align-items-center">
								<div class="col-12 lg:col-4">
									<label
										style="color: #828282"
										class="mr-4 font-bold text-lg"
										for="ship-to"
										>Ship To</label
									>
								</div>
								<div class="col-12 lg:col-6">
									<InputText
										class="w-full"
										id="ship-to"
										type="text"
										v-model="poValue.SHIP_TO_NAME"
										disabled
									/>
								</div>
							</div>
							<div class="grid align-items-center">
								<div class="col-12 lg:col-4">
									<label
										style="color: #828282"
										class="mr-4 font-bold text-lg"
										for="ship-to"
										>JIT Status</label
									>
								</div>
								<div class="col-12 lg:col-6">
									<InputText
										class="w-full"
										id="ship-to"
										type="text"
										v-model="poValue.JIT_COLUMN"
										disabled
									/>
								</div>
							</div>
						</div>
						<!-- Right column -->
						<div class="col-12 lg:col-6">
							<div class="grid align-items-center">
								<div class="col-12 lg:col-4">
									<label
										style="color: #828282"
										class="mr-4 font-bold text-lg"
										for="order-date"
										>Order Date</label
									>
								</div>
								<div class="col-12 lg:col-6">
									<InputText
										class="w-full"
										id="order-date"
										type="text"
										v-model="poValue.ORDER_DATE"
										disabled
									/>
								</div>
							</div>
							<div class="grid align-items-center">
								<div class="col-12 lg:col-4">
									<label
										style="color: #828282"
										class="mr-4 font-bold text-lg"
										for="buyer"
										>Buyer</label
									>
								</div>
								<div class="col-12 lg:col-6">
									<InputText
										class="w-full"
										id="buyer"
										type="text"
										v-model="poValue.BUYER_NAME"
										disabled
									/>
								</div>
							</div>
							<div class="grid align-items-center">
								<div class="col-12 lg:col-4">
									<label
										style="color: #828282"
										class="mr-4 font-bold text-lg"
										for="currency"
										>Currency</label
									>
								</div>
								<div class="col-12 lg:col-6">
									<InputText
										class="w-full"
										id="currency"
										type="text"
										v-model="poValue.CURRENCY_CODE"
										disabled
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Attachment Document -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Upload File</div>
				<div class="content-body">
					<div class="">
						<!-- :maxFileSize="1000000" -->
						<FileUpload
							id="uploadFile"
							ref="inputFile"
							chooseLabel="Choose File"
							uploadLabel="Upload File"
							fileLimit="1"
							accept="application/pdf"
							@select="onSelectFile"
							@remove="onRemoveFile"
							:customUpload="true"
							:maxFileSize="5000000"
							:showUploadButton="false"
							:showCancelButton="false"
							v-tooltip.left="'Max file 5MB'"
						>
							<template #empty>
								<p>No file selected. (Max file 5MB)</p>
							</template>
						</FileUpload>
						<div style="padding: 0rem 1rem" v-if="loadingUpload">
							<ProgressSpinner
								style="width: 25px; height: 25px"
								strokeWidth="6"
							/>
						</div>
						<p style="padding: 0rem 1rem" v-if="fileUploaded">
							{{ fileUploaded.filename }} has been uploaded
						</p>
					</div>
				</div>
			</div>
		</div>

		<!-- Items -->
		<div class="col-12 mb-4">
			<div class="content-wrapper">
				<div class="content-header">Items</div>
				<div class="content-body">
					<div>
						<!-- Data table -->
						<div v-if="poItems">
							<DataTable
								:value="poItems"
								responsiveLayout="scroll"
							>
								<Column header="Line">
									<template #body="slotProps">
										{{ slotProps.index + 1 }}
									</template>
								</Column>
								<Column
									field="ITEM_NUMBER"
									header="Item Number"
								></Column>
								<Column
									field="ITEM_DESCRIPTION"
									header="Item Description"
								></Column>
								<Column field="UOM" header="UOM"></Column>
								<Column
									field="CURRENCY_CODE"
									header="Currency"
								></Column>
								<Column
									field="UNIT_PRICE"
									header="Unit Price"
								></Column>
								<Column field="QUANTITY" header="Quantity">
								</Column>
								<Column field="AMOUNT" header="Amount"></Column>
								<Column header="Arrival at W/H">
									<template #body="slotProps">
										{{
											new Date(
												slotProps.data.PROMISED_DATE
											).toLocaleDateString('id-ID', {
												day: 'numeric',
												month: 'short',
												year: 'numeric',
											})
										}}
									</template>
								</Column>
								<Column
									field="TYPE_PO"
									header="PO Type"
								></Column>
								<Column
									field="NOTE_TO_VENDOR_FIX"
									header="Note to Supplier"
								></Column>
							</DataTable>
						</div>

						<!-- If no data -->
						<p v-else>No data found.</p>

						<!-- Action button for send PO to supplier -->
						<div
							class="flex align-items-center mt-5"
							v-if="poItems"
						>
							<div class="col-12 lg:col-4">
								<div class="flex align-items-center">
									<span class="font-bold mr-3">Preparer</span>
									<AutoComplete
										class="w-full"
										v-model="selectedPreparer"
										:suggestions="filteredPreparer"
										@complete="searchPreparer($event)"
										field="user_name"
										:dropdown="true"
										forceSelection
									/>
								</div>
							</div>
							<Button
								class="p-button-success ml-2"
								@click="handleSendToSupplier"
							>
								<span class="font-bold">Send to Supplier</span>
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
import AuthService from '../../service/AuthService';
import PlanningOrderService from '../../service/PlanningOrderService';
import SubmitPoService from '../../service/SubmitPOService';
import Preparer from './Preparer.vue';
import LovService from '../../service/LovService';
import SimpleCrypto from 'simple-crypto-js';
import ApplicationGroupService from '../../service/ApplicationGroupService';
import ProgressModal from '../components/ProgressModal.vue';

export default {
	name: 'submitpo-list',
	components: { Preparer, ProgressModal },
	data() {
		return {
			currentUser: null,
			companies: [],
			selectedCompany: null,
			filteredCompanies: null,
			selectedPreparer: null,
			filteredPreparer: null,
			filteredPoNum: null,
			poNumber: null,
			poNumberSelected: null,
			poNumberList: [],
			poValue: {
				PO_HEADER_ID: null,
				REVISION_NUM: null,
				SHIP_TO_NAME: null,
				CURRENCY_CODE: null,
				VENDOR_NAME: null,
				ORDER_DATE: null,
				BUYER_NAME: null,
				SEGMENT1: null,
				JIT_COLUMN: null,
			},
			poItems: null,
			poTerm: null,
			isSubmitting: false,
			file: null,
			fileUploaded: null,
			loadingUpload: false,
			loadingPoNum: false,
			vendorId: null,
			orgId: null,
			levelTenant: null,
			orgIdNotif: null,
			emailRecipient: null,
			emailPreparer: null,
			sender: null,
			dbInstance: null,
			usersenderid: null,
			submitApi: null,
			preparers: null,
			disableButton: true,
			emailAttach: {
				filename: null,
				url: null,
			},
			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [
				{ label: 'Submit PO', to: '/mgm/settings/submitpo' },
			],
			progress: false,
		};
	},
	watch: {
		selectedCompany(event) {
			if (typeof event == 'object') {
				// this.poNumber = null;
				this.poNumber = null;
				// this.poItems = null;
				this.poNumberList = null;

				let payload = {
					DB_INSTANCE: this.selectedCompany.db_instance,
					ORG_ID: this.selectedCompany.value,
				};

				this.loadingPoNum = true;
				SubmitPoService.getPoNum(payload).then((resp) => {
					if (resp.data.status === 200) {
						//
						this.poNumberList = resp.data.data.map((val) => ({
							...val,
							PO_NUM: `${val.SEGMENT1} (${val.REVISION_NUM})`,
						}));

						this.disableButton = false;
					} else if (resp.data.status === 202) {
						this.poNumberList = [];
					}
					this.loadingPoNum = false;
				});
			} else {
				this.poItems = null;
				this.poNumberList = null;
			}
		},
	},
	async created() {
		AuthService.whoAmi().then(async (response) => {
			this.sender = response.data.data.bioemailactive;
			this.dbInstance = response.data.data.bioaddress;
			this.usersenderid = response.data.data.id;
			this.levelTenant = response.data.data.leveltenant;

			this.preparers = await SubmitPoService.getPreparers(
				response.data.data.bioaddress === undefined
					? 'COMET'
					: response.data.data.bioaddress
			);
		});

		// Check who current logged in user is
		const res = await AuthService.whoAmi();

		if (res.status === 200) {
			this.currentUser = res.data.data;

			if (this.currentUser.leveltenant == 1) {
				// Check if is admin or not and get companies
				// if (this.currentUser.userid == 'dexaadmin') {
				// 	this.getCompanies();
				// } else {
				// 	this.getCompaniesByUserId(this.currentUser.id);
				// }
				if (this.currentUser.userid === 'danu_h') {
					this.getCompanies();
				} else {
					LovService.getcompanydb(res.data.data.bioaddress).then(
						(value) => {
							this.companies = value.data.data;
							try {
								const secretKey = 'some-unique-key';
								const simpleCrypto = new SimpleCrypto(
									secretKey
								);
								const decipherText = simpleCrypto.decrypt(
									sessionStorage.getItem('dropdownCompany')
								);
								this.companies.forEach((element) => {
									if (element.name === decipherText) {
										this.selectedCompany = element;
										throw 'Break';
									}
								});
							} catch (e) {
								if (e !== 'Break') throw e;
							}
						}
					);

					// this.$router.push('/dashboard');
				}
			}
		}
	},
	methods: {
		searchPreparer(event) {
			this.selectedPreparer = event.query;
			this.filteredPreparer = this.preparers.filter((preparer) => {
				return preparer.user_name
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
			//
		},
		pad2(n) {
			return (n < 10 ? '0' : '') + n;
		},
		formatDate(date) {
			var month = this.pad2(date.getMonth() + 1); //months (0-11)
			var day = this.pad2(date.getDate()); //day (1-31)
			var year;
			if (date.getFullYear() < 0) {
				year = date.getFullYear() * -1;
			} else {
				year = date.getFullYear();
			}
			var combinedNeedDate = new Date(month + '/' + day + '/' + year);
			let formattedDate = [];

			formattedDate[0] =
				combinedNeedDate.getDate() +
				'-' +
				combinedNeedDate.toString().substr(4, 3) +
				'-' +
				combinedNeedDate.getFullYear();
			formattedDate[1] =
				combinedNeedDate.getMonth() +
				1 +
				'/' +
				combinedNeedDate.getDate() +
				'/' +
				combinedNeedDate.getFullYear();

			'ISI FORMATED DATE 1',
				formattedDate[1],
				combinedNeedDate.getMonth() +
					1 +
					'/' +
					combinedNeedDate.getDate() +
					'/' +
					combinedNeedDate.getFullYear();
			return formattedDate;
		},
		formatDateSend(date) {
			var d = new Date(date),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();

			if (month.length < 2) month = '0' + month;
			if (day.length < 2) day = '0' + day;

			return [year, month, day].join('-');
		},
		formatDateDexa(date) {
			var date = new Date(date);
			var respondDate =
				('00' + (date.getMonth() + 1)).slice(-2) +
				'/' +
				('00' + date.getDate()).slice(-2) +
				'/' +
				date.getFullYear() +
				' ' +
				('00' + date.getHours()).slice(-2) +
				':' +
				('00' + date.getMinutes()).slice(-2) +
				':' +
				('00' + date.getSeconds()).slice(-2);
			return respondDate.replace('-', '');
		},
		searchCompany(event) {
			if (!event.query.trim().length) {
				this.filteredCompanies = [...this.companies];
			} else {
				this.filteredCompanies = this.companies.filter((company) => {
					return company.name
						.toLowerCase()
						.match(event.query.toLowerCase());
				});
			}
		},
		onSelectCompany(e) {
			// Reset form if selected company change
			if (
				this.selectedCompany &&
				this.selectedCompany.name !== e.value.name
			) {
			}
		},
		searchPoNum(event) {
			if (!event.query.trim().length) {
				this.filteredPoNum = [...this.poNumberList];
			} else {
				this.filteredPoNum = this.poNumberList.filter((company) => {
					return company.PO_NUM.toLowerCase().match(
						event.query.toLowerCase()
					);
				});
			}
		},
		async getCompanies() {
			const res = await PlanningOrderService.getCompany();

			this.companies = res.data.data;
		},
		async getCompaniesByUserId(userId) {
			// Get companies by user id
			const res = await PlanningOrderService.getCompanySupp(userId);

			const payload = {
				isi: res.data.data[0].company,
			};

			const resCompany = await PlanningOrderService.getCompanyId(payload);

			this.companies = resCompany.data.data;
		},
		openSearch(event) {
			this.poNumberList = null;

			let payload = {
				DB_INSTANCE: this.selectedCompany.db_instance,
				ORG_ID: this.selectedCompany.value,
			};

			this.loadingPoNum = true;
			SubmitPoService.getPoNum(payload).then((resp) => {
				if (resp.data.status === 200) {
					this.poNumberList = resp.data.data.map((val) => ({
						...val,
						PO_NUM: `${val.SEGMENT1} (${val.REVISION_NUM})`,
					}));
				} else if (resp.data.status === 202) {
					this.poNumberList = [];
				}
				this.loadingPoNum = false;
			});
			this.$refs.op.toggle(event);
		},
		async searchPO() {
			// Search PO Value
			const resp = await SubmitPoService.getPo(
				this.poNumber.PO_HEADER_ID,
				this.poNumber.REVISION_NUM
			).then(async (resp) => {
				if ((await resp.data.status) === 200) {
					this.submitApi = resp.data.data[0];
					this.poValue = resp.data.data[0];
					this.vendorId = await resp.data.data[0].VENDOR_ID;
					this.orgId = resp.data.data[0].ORG_ID;

					'vendorId::',
						this.vendorId + '\n' + 'orgId::',
						resp.data.data[0].ORG_ID;
					//
					this.preparers.forEach((element) => {
						if (
							element.user_name.includes(this.poValue.BUYER_NAME)
						) {
							//
							this.selectedPreparer = element;
						}
					});
					this.poValue.ORDER_DATE = this.formatDate(
						new Date(this.poValue.ORDER_DATE)
					)[0];

					// Search PO Items list
					SubmitPoService.getPoList(
						this.poNumber.PO_HEADER_ID,
						this.poNumber.REVISION_NUM
					).then((res) => {
						if (res.data.status === 200) {
							// this.poValue = res.data.data[0];

							this.poItems = res.data.data.map((item) => {
								// Format date
								item.NEED_BY_DATE = this.formatDate(
									new Date(item.NEED_BY_DATE)
								)[0];
								if (
									item.NOTE_TO_VENDOR_FIX === 'null' ||
									item.NOTE_TO_VENDOR === null
								) {
									item.NOTE_TO_VENDOR = '';
								}
								// Add currency field
								item.CURRENCY_CODE = this.poValue.CURRENCY_CODE;
								return item;
							});
						} else if (res.data.status === 202) {
							this.poItems = null;
						}
					});

					// Search PO Term
					SubmitPoService.getPoTerm(
						this.poNumber.PO_HEADER_ID,
						this.poNumber.REVISION_NUM
					).then((res) => {
						if (res.data.status === 200) {
							this.poTerm = res.data.data[0];

							// let sortPoItems = res.data.data[0].sort(function(a, b){
							// return a.LINE_NUM - b.LINE_NUM;
							// });
							// this.poItems = sortPoItems;
						}
					});
				}
			});
		},
		onSelectCompany() {
			if (this.selectedCompany && this.poValue.PO_HEADER_ID) {
				this.clearForm();
			}
		},
		onSelectFile(e) {
			this.file = e.files[0];
		},
		onRemoveFile(e) {
			this.file = null;
		},
		async handleUploadFile() {
			let formData = new FormData();
			formData.append('file', this.file);
			formData.append('poHeaderId', this.poValue.PO_HEADER_ID);
			formData.append('password', this.vendorId);

			// Uploading file
			this.loadingUpload = true;
			const res = await SubmitPoService.sendPoFile(formData);

			if (res.status === 200) {
				this.fileUploaded = {
					filename: res.data.data.fileName,
					url: res.data.data.aossPayload.url,
					mimeType: res.data.data.mimeType,
					poFile: res.data.data.aossPayload,
				};
				this.emailAttach.filename = res.data.data.fileName;

				//

				this.$refs.inputFile.clear();
				this.loadingUpload = false;
				return res;
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'File Failed to Upload !',
					life: 3000,
				});
			}
			this.loadingUpload = false;
			// await new Promise(resolve => setTimeout(resolve, 500));
		},
		async handleEmailAttachment() {
			const payloadDownload = {
				poHeaderId: this.poValue.PO_HEADER_ID,
				revisionNum: this.poValue.REVISION_NUM,
			};
			const res = await SubmitPoService.downloadFilePur(payloadDownload);
			if (res.status === 200) {
				this.emailAttach.url = res.data.data;

				return res;
			}
		},
		async submitPoHeader() {
			const preparer = {
				userIdPreparer: this.selectedPreparer.user_id,
			};
			
			// const response = await SubmitPoService.getPreparerMail(
			// 	data
			// )
			// this.emailPreparer =
			// 	response.data.data[0][0][
			// 		'email_address'
			// 	];
			//
			// 	'email preparer::',
			// 	this.emailPreparer
			// );
			const orderDate = this.formatDateDexa(this.poValue.ORDER_DATE);
			// const confirmDate = this.formatDateDexa(this.poValue.CONFIRM_DATE);
			const header = {
				PO_KEY_ID: this.poValue.PO_KEY_ID,
				PO_HEADER_ID: this.poValue.PO_HEADER_ID,
				SEGMENT1: this.poValue.SEGMENT1,
				REVISION_NUM: this.poValue.REVISION_NUM,
				SHIP_TO_LOCATION_ID: this.poValue.SHIP_TO_LOCATION_ID,
				COMMENTS: this.poValue.COMMENTS,
				AGENT_ID: this.poValue.AGENT_ID,
				CURRENCY_CODE: this.poValue.CURRENCY_CODE,
				ATTRIBUTE1: this.poValue.ATTRIBUTE1,
				BUYER_NAME: this.poValue.BUYER_NAME,
				ORG_ID: this.poValue.ORG_ID,
				SHIP_TO_NAME: this.poValue.SHIP_TO_NAME,
				AUTHORIZATION_STATUS: this.poValue.AUTHORIZATION_STATUS,
				STATUS: 'O',
				VENDOR_ID: this.poValue.VENDOR_ID,
				VENDOR_NAME: this.poValue.VENDOR_NAME,
				ORDER_DATE: orderDate,
				CREATION_DATE: this.formatDateDexa(new Date()),
				CREATED_BY: this.currentUser.id,
				LAST_UPDATE_DATE: this.formatDateDexa(new Date()),
				LAST_UPDATED_BY: this.currentUser.id,
				ACTIVE: this.poValue.ACTIVE,
				CONFIRM_DATE: null,
				CONFIRM_FLAG: this.poValue.CONFIRM_FLAG,
				CONFIRM_REQ_ID: this.poValue.CONFIRM_FLAG,
				CONFIRM_SP_NAME: this.poValue.CONFIRM_SP_NAME,
				MANUFACTURER: this.poValue.MANUFACTURER,
				DB_INSTANCE: this.poValue.DB_INSTANCE,
				JIT_FLAG: this.poValue.JIT_COLUMN,
			};



			const detail = this.poItems.map((item) => {
				const needByDate = this.formatDateDexa(item.NEED_BY_DATE);
				const estComplationDate = this.formatDateDexa(
					item.ESTIMATE_COMPLATION_DATE
				);
				const estManufacturerDate = this.formatDateDexa(
					item.ESTIMATE_MANUFACTURER_DATE
				);
				const estSupplierDate = this.formatDateDexa(
					item.ESTIMATE_SUPPLIER_DATE
				);
				// const ceationDate = this.formatDateDexa(new Date());
				const lastUpdateDate = this.formatDateDexa(
					item.LAST_UPDATE_DATE
				);
				const promisedDate = this.formatDateDexa(item.PROMISED_DATE);
				const deliveryInvoiceDate = this.formatDateDexa(
					item.DELIVERY_INVOICE_DATE
				);
				// const confirmDate = this.formatDateDexa(item.CONFIRM_DATE);
				return {
					PO_LINE_ID: item.PO_LINE_ID,
					PO_HEADER_ID: item.PO_HEADER_ID,
					PO_KEY_ID: this.poValue.PO_KEY_ID,
					LINE_NUM: item.LINE_NUM,
					ITEM_ID: item.ITEM_ID,
					ITEM_NUMBER: item.ITEM_NUMBER,
					ITEM_DESCRIPTION: item.ITEM_DESCRIPTION,
					UNIT_MEAS_LOOKUP_CODE: item.UNIT_MEAS_LOOKUP_CODE,
					UNIT_PRICE: item.UNIT_PRICE,
					QUANTITY: item.QUANTITY,
					NOTE_TO_VENDOR: item.NOTE_TO_VENDOR_FIX,
					ORG_ID: item.ORG_ID,
					NEED_BY_DATE: needByDate,
					ESTIMATE_COMPLATION_DATE: estComplationDate,
					ESTIMATE_MANUFACTURER_DATE: estManufacturerDate,
					ESTIMATE_SUPPLIER_DATE: estSupplierDate,
					DELIVERY_INVOICE_DATE: deliveryInvoiceDate,
					CREATION_DATE: this.formatDateDexa(new Date()),
					CREATED_BY: this.currentUser.id,
					LAST_UPDATE_DATE: lastUpdateDate,
					LAST_UPDATED_BY: this.currentUser.id,
					AMOUNT: item.AMOUNT,
					REMARKS: item.REMARKS,
					PROMISED_DATE: promisedDate,
					OT_CODE: item.OT_CODE,
					ATTRIBUTE5: item.ATTRIBUTE5,
					ATTRIBUTE8: item.ATTRIBUTE8,
					ATTRIBUTE9: item.ATTRIBUTE9,
					STATUS: 'O',
					CONFIRM_DATE: this.formatDateDexa(new Date()),
					ATTRIBUTE3: item.ATTRIBUTE3,
					DB_INSTANCE: item.DB_INSTANCE,
					SEGMENT1: item.SEGMENT1,
					UOM: item.UOM,
					TYPE_PO: item.TYPE_PO,
					REVISION_NUM: this.poValue.REVISION_NUM,
					STAT_DAY: item.STAT_DAY,
					MANUFACTURER: item.MANUFACTURER_NAME
				};
			});

			const terms = {
				PO_KEY_ID: this.poValue.PO_KEY_ID,
				PO_HEADER_ID: this.poTerm.PO_HEADER_ID,
				TERMS_ID: this.poTerm.TERMS_ID,
				FREIGHT_TERMS: this.poTerm.FREIGHT_TERMS,
				FREIGHT_CARRIER: this.poTerm.FREIGHT_CARRIER,
				FOB: this.poTerm.FOB,
			};

			const podoc = {
				PO_HEADER_ID: this.poValue.PO_HEADER_ID,
				PO_FILE: this.fileUploaded.poFile,
				CREATION_DATE: this.formatDateDexa(new Date()),
				CREATED_BY: this.currentUser.id,
				LAST_UPDATE_DATE: this.formatDateDexa(new Date()),
				LAST_UPDATED_BY: this.currentUser.id,
				FILENAME: this.fileUploaded.filename,
				MIME_TYPE: this.fileUploaded.mimeType,
				PO_SEQ: this.poValue.REVISION_NUM,
				LEVEL_TENANT: this.levelTenant,
			};

			const api = {
				db_instance: this.submitApi.DB_INSTANCE,
				po_key_id: this.submitApi.PO_KEY_ID,
				po_header_id: this.submitApi.PO_HEADER_ID,
				segment1: this.submitApi.SEGMENT1,
				revision_num: this.submitApi.REVISION_NUM,
				vendor_id: this.vendorId,
				org_id: this.submitApi.ORG_ID,
				status: 'O',
				creation_date: this.formatDateDexa(new Date()),
				confirm_date: this.formatDateDexa(new Date()),
			};
			const emailData = {
				// vendor_id: this.vendorId,
				// org_id: this.orgId,
				module: 'submitpo',
				companyname: this.selectedCompany.name,
				vendorname: this.poValue.VENDOR_NAME,
				PO_HEADER_ID: this.poValue.PO_HEADER_ID,
				CREATION_DATE: new Date(),
				CREATED_BY: this.currentUser.leveltenant,
				LAST_UPDATE_DATE: new Date(),
				LAST_UPDATED_BY: new Date(),
				MIME_TYPE: this.fileUploaded
					? this.fileUploaded.mimeType
					: null,
				PO_SEQ: null,
				EMAIL_RECIPIENT: null,
				EMAIL_PREPARER: null,
				SENDER: this.sender,
				COMPANY_PUR: this.selectedCompany.name,
				BUYER_PO: this.poValue.BUYER_NAME,
				CURRENCY: this.poValue.CURRENCY_CODE,
				ORDER_DATE: this.poValue.ORDER_DATE,
				PO_ITEMS: this.poItems,
				FILE_NAME: this.emailAttach ? this.emailAttach.filename : null,
				FILE_URL: null,
				userIdPreparer: this.selectedPreparer.user_id,
				idmodule: '4',
				sbjmessage:
					'New Purchase Order #' + this.poNumber.SEGMENT1 + ' from ',
				// receiveddate: new Date(),
				status: 'Open',
				notif_type: 'Supplier',
				sender: this.sender,
				recipient: null,
				usersenderid: this.usersenderid,
				userreceiveid: this.vendorId,
				dbInstance: this.selectedCompany.db_instance,
				orgIdComp: this.orgId,
				// dbInstance: this.dbInstance,
			};

			

			const payload = {
				header: header,
				detail: detail,
				terms: terms,
				podoc: podoc,
				dataapi: api,
				preparer: preparer,
				emailData: emailData,
			};

			const res = await SubmitPoService.submitPoHeader(payload);
			if (res.status === 200) {
				this.progress = false;
				return res;
			} else {
				this.progress = false;
				return res;
			}

			// }
		},
		async submitPoDetail() {
			const payload = this.poItems.map((item) => {
				const needByDate = this.formatDateDexa(item.NEED_BY_DATE);
				const estComplationDate = this.formatDateDexa(
					item.ESTIMATE_COMPLATION_DATE
				);
				const estManufacturerDate = this.formatDateDexa(
					item.ESTIMATE_MANUFACTURER_DATE
				);
				const estSupplierDate = this.formatDateDexa(
					item.ESTIMATE_SUPPLIER_DATE
				);
				// const ceationDate = this.formatDateDexa(new Date());
				const lastUpdateDate = this.formatDateDexa(
					item.LAST_UPDATE_DATE
				);
				const promisedDate = this.formatDateDexa(item.PROMISED_DATE);
				const deliveryInvoiceDate = this.formatDateDexa(
					item.DELIVERY_INVOICE_DATE
				);
				const confirmDate = this.formatDateDexa(item.CONFIRM_DATE);

				return {
					PO_LINE_ID: item.PO_LINE_ID,
					PO_HEADER_ID: item.PO_HEADER_ID,
					PO_KEY_ID: this.poValue.PO_KEY_ID,
					LINE_NUM: item.LINE_NUM,
					ITEM_ID: item.ITEM_ID,
					ITEM_NUMBER: item.ITEM_NUMBER,
					ITEM_DESCRIPTION: item.ITEM_DESCRIPTION,
					UNIT_MEAS_LOOKUP_CODE: item.UNIT_MEAS_LOOKUP_CODE,
					UNIT_PRICE: item.UNIT_PRICE,
					QUANTITY: item.QUANTITY,
					NOTE_TO_VENDOR: item.NOTE_TO_VENDOR_FIX,
					ORG_ID: item.ORG_ID,
					NEED_BY_DATE: needByDate,
					ESTIMATE_COMPLATION_DATE: estComplationDate,
					ESTIMATE_MANUFACTURER_DATE: estManufacturerDate,
					ESTIMATE_SUPPLIER_DATE: estSupplierDate,
					DELIVERY_INVOICE_DATE: deliveryInvoiceDate,
					CREATION_DATE: this.formatDateDexa(new Date()),
					CREATED_BY: this.currentUser.id,
					LAST_UPDATE_DATE: lastUpdateDate,
					LAST_UPDATED_BY: this.currentUser.id,
					AMOUNT: item.AMOUNT,
					REMARKS: item.REMARKS,
					PROMISED_DATE: promisedDate,
					OT_CODE: item.OT_CODE,
					ATTRIBUTE5: item.ATTRIBUTE5,
					ATTRIBUTE8: item.ATTRIBUTE8,
					ATTRIBUTE9: item.ATTRIBUTE9,
					STATUS: 'O',
					CONFIRM_DATE: this.formatDateDexa(new Date()),
					ATTRIBUTE3: item.ATTRIBUTE3,
					DB_INSTANCE: item.DB_INSTANCE,
					SEGMENT1: item.SEGMENT1,
					UOM: item.UOM,
					TYPE_PO: item.TYPE_PO,
					REVISION_NUM: this.poValue.REVISION_NUM,
					STAT_DAY: item.STAT_DAY,
					MANUFACTURER: item.MANUFACTURER_NAME
				};
			});

			const res = await SubmitPoService.submitPoDetail(payload);

			if (res.status === 200) {
				return res;
			}
		},
		async submitPoTerm() {
			const payload = {
				PO_KEY_ID: this.poValue.PO_KEY_ID,
				PO_HEADER_ID: this.poTerm.PO_HEADER_ID,
				TERMS_ID: this.poTerm.TERMS_ID,
				FREIGHT_TERMS: this.poTerm.FREIGHT_TERMS,
				FREIGHT_CARRIER: this.poTerm.FREIGHT_CARRIER,
				FOB: this.poTerm.FOB,
			};

			const res = await SubmitPoService.submitPoTerm(payload);

			if (res.status === 200) {
				return res;
			}
		},
		async submitPoDoc() {
			// Insert PO File data to DB
			const payload = {
				PO_HEADER_ID: this.poValue.PO_HEADER_ID,
				PO_FILE: this.fileUploaded.poFile,
				CREATION_DATE: this.formatDateDexa(new Date()),
				CREATED_BY: this.currentUser.id,
				LAST_UPDATE_DATE: this.formatDateDexa(new Date()),
				LAST_UPDATED_BY: this.currentUser.id,
				FILENAME: this.fileUploaded.filename,
				MIME_TYPE: this.fileUploaded.mimeType,
				PO_SEQ: this.poValue.REVISION_NUM,
				LEVEL_TENANT: this.levelTenant,
			};

			const res = await SubmitPoService.submitPoDoc(payload);

			if (res.status === 200) {
				return res;
			}
		},
		async submitApiData() {
			const resp = await SubmitPoService.getVendorId(
				this.submitApi.VENDOR_NAME
			);
			const payload = {
				db_instance: this.submitApi.DB_INSTANCE,
				po_key_id: this.submitApi.PO_KEY_ID,
				po_header_id: this.submitApi.PO_HEADER_ID,
				segment1: this.submitApi.SEGMENT1,
				revision_num: this.submitApi.REVISION_NUM,
				vendor_id: resp.data.data[0].vendor_id,
				org_id: this.submitApi.ORG_ID,
				status: 'O',
				creation_date: this.formatDateDexa(new Date()),
				confirm_date: this.formatDateDexa(new Date()),
			};
			// const payloadheader = {
			// 	PO_KEY_ID: this.poValue.PO_KEY_ID,
			// 	PO_HEADER_ID: this.poValue.PO_HEADER_ID,
			// 	SEGMENT1: this.poValue.SEGMENT1,
			// 	REVISION_NUM: this.poValue.REVISION_NUM,
			// 	SHIP_TO_LOCATION_ID: this.poValue.SHIP_TO_LOCATION_ID,
			// 	COMMENTS: this.poValue.COMMENTS,
			// 	AGENT_ID: this.poValue.AGENT_ID,
			// 	CURRENCY_CODE: this.poValue.CURRENCY_CODE,
			// 	ATTRIBUTE1: this.poValue.ATTRIBUTE1,
			// 	BUYER_NAME: this.poValue.BUYER_NAME,
			// 	ORG_ID: this.poValue.ORG_ID,
			// 	SHIP_TO_NAME: this.poValue.SHIP_TO_NAME,
			// 	AUTHORIZATION_STATUS: this.poValue.AUTHORIZATION_STATUS,
			// 	STATUS: 'O',
			// 	VENDOR_ID: this.poValue.VENDOR_ID,
			// 	VENDOR_NAME: this.poValue.VENDOR_NAME,
			// 	ORDER_DATE: orderDate,
			// 	CREATION_DATE: this.formatDateDexa(new Date()),
			// 	CREATED_BY: this.currentUser.id,
			// 	LAST_UPDATE_DATE: this.formatDateDexa(new Date()),
			// 	LAST_UPDATED_BY: this.currentUser.id,
			// 	ACTIVE: this.poValue.ACTIVE,
			// 	CONFIRM_DATE: this.formatDateDexa(new Date()),
			// 	CONFIRM_FLAG: this.poValue.CONFIRM_FLAG,
			// 	CONFIRM_REQ_ID: this.poValue.CONFIRM_FLAG,
			// 	CONFIRM_SP_NAME: this.poValue.CONFIRM_SP_NAME,
			// 	// CONFIRM_TYPE: this.poValue.CONFIRM_TYPE,
			// 	DB_INSTANCE: this.poValue.DB_INSTANCE,
			// };

			const submitApiDexa = await SubmitPoService.submitApi(payload);
			if (submitApiDexa.status === 200) {
				return submitApiDexa;
			}
		},
		async handleSendToSupplier() {
			this.progress = true;
			if (!this.isSubmitting) {
				// Set flag isSubmitting
				this.isSubmitting = true;

				// If there is file attachment
				if (this.file) {
					const resFile = await this.handleUploadFile();

					if (resFile === undefined || resFile === 'undefined') {
						this.progress = false;
						alert('File error');
						location.reload();
					} else if (resFile.status === 200) {
						// Insert PO to DB
						try {
							console.log('----submit po=----')
							const resPoHeader = await this.submitPoHeader();
							if (resPoHeader.status === 200) {
								// const resPoDetail = await this.submitPoDetail();
								// if (resPoDetail.status === 200) {
								//
								// const resPoTerm = await this.submitPoTerm();
								// if (resPoTerm.status === 200) {
								//
								// const resPoDoc = await this.submitPoDoc();
								// if (resPoDoc.status === 200) {
								//
								// const resPoSubmit = await this.submitApiData();
								// if (resPoSubmit.status === 200) {
								// const resPOMailAttach = await this.handleEmailAttachment();
								// If success submit PO
								//
								if (
									resPoHeader.status === 200
									// resPOMailAttach.status === 200
								) {
									this.$toast.add({
										severity: 'success',
										summary:
											'PO Successfully Send to Supplier',
										life: 3000,
									});
									// Remove PO Header from is_purchase_order_header_oracle;
									await SubmitPoService.removePoHeader(
										this.poValue.PO_HEADER_ID
									);
									// const resPoDoc = await SubmitPoService.sendPoDoc(fileData);
									//
									//

									// await this.sendEmailToSupp()

									// Reset flag isSubmitting and clear form
									this.isSubmitting = false;
									this.progress = false;
									this.clearForm();
								}
								// }
								// }
								// }
								// }
							} else if (resPoHeader.data.email === null) {
								//
								this.isSubmitting = false;
								this.progress = false;
								this.$toast.add({
									severity: 'error',
									summary: `Send Email Error`,
									life: 3000,
								});
							} else {
								//
								this.isSubmitting = false;
								this.progress = false;
								this.$toast.add({
									severity: 'error',
									summary: `Data error`,
									life: 3000,
								});
							}
						} catch (err) {
							this.progress = false;
							//
							// If PO Header is already exist
							if (
								err.response.status === 400 &&
								err.response.data.data.includes('Already Exist')
							) {
								//
								this.$toast.add({
									severity: 'error',
									summary: `Error: PO with ID of ${this.poValue.SEGMENT1} is already exist`,
									life: 3000,
								});
							} else {
								//
								this.$toast.add({
									severity: 'error',
									summary:
										'Error: Unsuccessfully send PO to supplier',
									life: 3000,
								});
							}
							// Reset flag isSubmitting and clear form
							this.isSubmitting = false;
						}
					} else {
						//
						this.progress = false;
						this.$toast.add({
							severity: 'error',
							summary: 'Data is undefined',
							life: 3000,
						});
					}
				} else {
					// Submit PO without upload file
					// Insert PO to DB
					try {
						if (this.file) {
							const resPoHeader = await this.submitPoHeader();
							if (resPoHeader.status === 200) {
								const resPoDetail = await this.submitPoDetail();
								if (resPoDetail.status === 200) {
									const resPoTerm = await this.submitPoTerm();
									const data = {
										userIdPreparer:
											this.selectedPreparer.user_id,
									};
									const response =
										await SubmitPoService.getPreparerMail(
											data
										);
									this.emailPreparer =
										response.data.data[0][0][
											'email_address'
										];

									setTimeout(this.sendEmailToSupp(), 500);
									// If success submit PO
									if (
										resPoHeader.status === 200 &&
										resPoDetail.status === 200 &&
										resPoTerm.status === 200
									) {
										this.$toast.add({
											severity: 'success',
											summary:
												'PO Successfully Send to Supplier',
											life: 3000,
										});
										// Remove PO Header from is_purchase_order_header_oracle;
										await SubmitPoService.removePoHeader(
											this.poValue.PO_HEADER_ID
										);
										// Reset flag isSubmitting and clear form
										this.isSubmitting = false;
										this.clearForm();
									}
								}
							}
						} else {
							this.progress = false;
							this.$toast.add({
								severity: 'error',
								summary: 'Please choose a file',
								life: 3000,
							});
							this.isSubmitting = false;
						}
					} catch (err) {
						this.progress = false;

						// If PO Header is already exist
						if (
							err.response.status === 400 &&
							err.response.data.data.includes('Already Exist')
						) {
							this.$toast.add({
								severity: 'error',
								summary: `Error: PO with ID of ${this.poValue.PO_HEADER_ID} is already exist`,
								life: 3000,
							});
						} else {
							this.progress = false;
							this.$toast.add({
								severity: 'error',
								summary:
									'Error: Unsuccessfully send PO to supplier',
								life: 3000,
							});
						}
						// Reset flag isSubmitting and clear form
						this.isSubmitting = false;
					}
				}
			}
		},
		async sendEmailToSupp() {
			const data = {
				// vendor_id: this.vendorId,
				// org_id: this.orgId,
				module: 'submitpo',
				companyname: this.selectedCompany.name,
				vendorname: this.poValue.VENDOR_NAME,
				PO_HEADER_ID: this.poValue.PO_HEADER_ID,
				CREATION_DATE: new Date(),
				CREATED_BY: this.currentUser.leveltenant,
				LAST_UPDATE_DATE: new Date(),
				LAST_UPDATED_BY: new Date(),
				MIME_TYPE: this.fileUploaded
					? this.fileUploaded.mimeType
					: null,
				PO_SEQ: null,
				EMAIL_RECIPIENT: this.emailRecipient,
				EMAIL_PREPARER: this.emailPreparer,
				SENDER: this.sender,
				COMPANY_PUR: this.selectedCompany.name,
				BUYER_PO: this.poValue.BUYER_NAME,
				CURRENCY: this.poValue.CURRENCY_CODE,
				ORDER_DATE: this.poValue.ORDER_DATE,
				PO_ITEMS: this.poItems,
				FILE_NAME: this.emailAttach ? this.emailAttach.filename : null,
				FILE_URL: this.emailAttach.url,
				// dbInstance: this.dbInstance,
			};

			const resval = await SubmitPoService.getPurchaseRecipientMail(data);

			if (resval.data.status === 200) {
				const payload = {
					idmodule: '4',
					sbjmessage:
						'New Purchase Order #' +
						this.poNumber.SEGMENT1 +
						' from ' +
						this.selectedCompany.name,
					// receiveddate: new Date(),
					status: 'Open',
					notif_type: 'Supplier',
					sender: this.sender,
					recipient: resval.data.emails,
					usersenderid: this.usersenderid,
					userreceiveid: this.vendorId,
					dbInstance: this.selectedCompany.db_instance,
					orgIdComp: this.orgId,
				};

				const response = await SubmitPoService.sendNotifPurchaseOrder(
					payload
				);
				if (response.data.status == 200) {
					this.$toast.add({
						severity: 'success',
						summary: 'Supplier successfully notified!',
						life: 3000,
					});
					this.file = null;
					this.fileUploaded = null;
				}
			}
		},
		clearForm() {
			// this.selectedCompany = null;
			// this.poNumberList = [];
			this.filteredPoNum = null;
			this.poNumber = null;
			this.poValue = {
				PO_HEADER_ID: null,
				REVISION_NUM: null,
				SHIP_TO_NAME: null,
				CURRENCY_CODE: null,
				VENDOR_NAME: null,
				ORDER_DATE: null,
				BUYER_NAME: null,
				SEGMENT1: null,
			};
			this.poItems = null;
		},
	},
};
</script>

<style scoped></style>
