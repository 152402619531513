<template>
	<AutoComplete
		:suggestions="filteredCostCenter"
		:dropdown="true"
		@complete="searchCostCenter($event)"
		field="segment4"
		placeholder=" "
		forceSelection
	/>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { getCostcenter } from '@/service/InvoiceProxyNonPOServices';

// eslint-disable-next-line no-undef
const props = defineProps({
	company: {
		type: Object,
	},
});

const filteredCostCenter = ref();
const costCenter = ref();

onMounted(async () => {
	if (props.company) {
		const resCostCenter = await getCostcenter(
			props.company.db_instance,
			props.company.value
		);
		costCenter.value = resCostCenter.data;
	}
});

const searchCostCenter = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			filteredCostCenter.value = [...costCenter.value];
		} else {
			filteredCostCenter.value = costCenter.value.filter((w) => {
				return w.segment4
					.toLowerCase()
					.match(event.query.toLowerCase());
			});
		}
	}, 250);
};
</script>

<style scoped></style>
