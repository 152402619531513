<template>
    <!-- <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%;">
            <div class="col-12 mt-5 xl:mt-0 text-center">
                <img src="layout/images/logo-error.svg" alt="SPB logo" class="mb-5" style="width:81px; height:60px;">
            </div>
            <div class="col-12 xl:col-6" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, rgba(233, 30, 99, 0.4) 10%, rgba(33, 150, 243, 0) 30%);">
                <div class="h-full w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                    <div class="grid flex flex-column align-items-center">
                        <div class="flex justify-content-center align-items-center bg-pink-500 border-circle" style="height:3.2rem; width:3.2rem;">
                            <i class="pi pi-fw pi-exclamation-circle text-2xl text-50"></i>
                        </div>
                        <h1 class="font-bold text-5xl text-900 mb-2">Error Occured</h1>
                        <span class="text-600">Requested resource is not available.</span>
                        <img src="layout/images/asset-error.svg" alt="Error" class="mt-5" width="80%">
                        <div class="col-12 mt-5 text-center">
                            <i class="pi pi-fw pi-arrow-left text-blue-500 mr-2" style="vertical-align:center;"></i>
                            <router-link to="/" class="text-blue-500">Go to Dashboard</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="grid">
        <Toast />
        <ConfirmDialog></ConfirmDialog>
        <ConfirmDialog group="positionDialog"></ConfirmDialog>
        <div class="col-12">
            <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
        </div>
        <div class="col-12 p-fluid">
            <div class="card">
                <div class="card" style="background-color: #478fcd; height: 2em; margin: 0 0 2em 0;">
                    <h5 style="color: white; margin-top:-10px"> Add/Edit Planning Order </h5>
                </div>
                <div class="p-fluid grid formgrid">
                    <div class="col-12 mb-4 lg:col-6 lg:mb-5">
                        <span class="p-float-label">
                            <InputText id="inputtext" type="text" v-model="planningOrderNum"
                                v-bind:disabled="planningOrderNum" />
                            <label for="inputtext">Planning Order No</label>
                        </span>
                    </div>
                    <div class="col-12 mb-4 lg:col-6 lg:mb-5">
                        <span class="p-float-label">
                            <InputText id="inputtext" type="text" v-model="revisition" disabled />
                            <label for="inputtext">Rev</label>
                        </span>
                    </div>
                    <div class="col-12 mb-4 lg:col-6 lg:mb-5">
                        <span class="p-float-label">
                            <!-- <InputText id="inputtext" type="text" v-model="planningOrderDate" v-bind:disabled="planningOrderDate"/> -->
                            <InputText id="inputtext" type="text" v-model="planningOrderDate"
                                v-bind:disabled="planningOrderDate" />
                            <label for="inputtext">Planning Order Date</label>
                        </span>
                    </div>
                    <div class="col-12 mb-4 lg:col-6 lg:mb-5">
                        <span class="p-float-label">
                            <InputText id="inputtext" type="text" v-model="planningOrderStatus" disabled />
                            <label for="inputtext">Status</label>
                        </span>
                    </div>
                    <div class="col-12 mb-4 lg:col-6 lg:mb-5">
                        <span class="p-float-label">
                            <InputText id="inputtext" type="text" v-model="respondDate" v-bind:disabled="respondDate" />
                            <label for="inputtext">Must Respond Date</label>
                        </span>
                    </div>
                    <div class="col-12 mb-4 lg:col-6 lg:mb-5">
                        <span class="p-float-label">
                            <InputText id="inputtext" type="text" v-model="respondBy" v-bind:disabled="respondBy" />
                            <label for="inputtext">Respond By</label>
                        </span>
                    </div>
                </div>
                <div class="p-fluid grid formgrid">
                    <div class="col-6 mb-4 lg:col-1 lg:mb-5">
                        <Button label="Print" />
                    </div>
                </div>

            </div>
        </div>


        <div class="col-12 p-fluid">
            <div class="card">
                <div class="p-fluid grid formgrid" style="margin-top:1.5rem;margin-bottom: 0.5rem;">
                    <div class="col-6 mb-1 lg:col-2 lg:mb-0">
                        <Button label="Planning Order" class="p-button-secondary p-button-text" @click="openPo" />
                    </div>
                    <div class="col-6 mb-1 lg:col-1 lg:mb-0">
                        <Button label="Terms" class="p-button-secondary p-button-text" @click="openTerms" />
                    </div>
                </div>
                <div class="col-12 mb-5" v-if="!this.changeTable">
                    <DataTable :value="planningOrderTable" dataKey="pr_line_num" :paginator="true" :rows="5"
                        v-model:filters="filters" v-model:selection="selectedPo" removableSort
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        :rowsPerPageOptions="[5,10,25]" :loading="loading"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        responsiveLayout="scroll">
                        <!-- <Column selectionMode="multiple" headerStyle="width: 3em"></Column> -->
                        <Column field="pr_line_num" header="Line" :sortable="true" headerStyle="min-width:5rem;">
                        </Column>
                        <Column field="item_num" header="Item Number" :sortable="true" headerStyle="min-width:12rem;">
                        </Column>
                        <Column field="item_desc" header="Item Description" :sortable="true"
                            headerStyle="min-width:20rem;"></Column>
                        <Column field="uom" header="UOM" headerStyle="min-width:8rem;">
                        </Column>
                        <Column field="plan_qty" header="Qty" headerStyle="min-width:8rem;" style="text-align: center">
                        </Column>
                        <Column field="ship_to_desc" header="Location" :sortable="true" headerStyle="min-width:20rem;">
                        </Column>
                        <Column field="note_to_supplier" header="Note to Supplier" :sortable="true"
                            headerStyle="min-width:20rem;">
                        </Column>
                        <Column field="manufacturer_description" header="Manufacturer" :sortable="true"
                            headerStyle="min-width:20rem;">
                        </Column>
                        <Column field="currency" header="Currency" :sortable="true" headerStyle="min-width:8rem;"
                            style="text-align: center">
                            <template #body="slotProps">
                                <Dropdown v-model="slotProps.data.currency" :options="currenciesList"
                                    optionLabel="currency_code" optionValue="currency_code" />
                            </template>
                        </Column>
                        <Column field="confirm_price" header="Confirm Price" :sortable="true"
                            headerStyle="min-width:8rem;" style="text-align: center">
                            <template #body="slotProps">
                                <Textarea v-model="slotProps.data.confirm_price" :autoResize="true" rows="1"
                                    cols="30" />
                                </template>
                </Column>
                <Column field="confirm_qty" header="Confirm Qty" :sortable="true" headerStyle="min-width:8rem;" style="text-align: center">
                <template #body="slotProps">
                    <Textarea v-model="slotProps.data.confirm_qty" :autoResize="true" rows="1" cols="30"/>
                </template>
                </Column>
                <Column field="remarks" header="Remark" :sortable="true" headerStyle="min-width:10rem;">
                <template #body="slotProps">
                        <Textarea v-model="slotProps.data.remarks3" :autoResize="true" rows="5" cols="30" />
                    </template>
                </Column>
                <!-- <Column header="Reason for not Accept" headerStyle="min-width:20rem;">
                    <template #body="slotProps">
                        <Textarea v-model="slotProps.data.remarks3" :autoResize="true" rows="5" cols="30" />
                    </template>
                </Column> -->
                <Column field="name" header="Line Status" :sortable="true"
                    headerStyle=" min-width:12rem;">
                </Column>
            </DataTable>
            </div>

            <!-- <div class="col-12 mb-5" v-if="this.changeTable">
            <DataTable :value="termsTable" dataKey="id" :paginator="true"
                :rows="5" v-model:filters="filters"
                removableSort paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[5,10,25]" :loading="loading"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                responsiveLayout="scroll">
                <Column field="pr_line_num" header="Line" :sortable="true" headerStyle="min-width:5rem;"></Column>
                <Column field="item_num" header="Item Number" :sortable="true" headerStyle="min-width:12rem;"></Column>
                <Column field="item_desc" header="Item Description" :sortable="true" headerStyle="min-width:20rem;"></Column>
                <Column field="need_by_date" header="Arrival at Site" style="text-align: center" dateFormat="dd-M-yy"></Column>
                <Column field="need_by_date" header="Arrival at W/H" style="text-align: center" dateFormat="dd-M-yy"></Column>
                <Column field="top_name" header="ToP" style="text-align: center">
                </Column>
                <Column field="freight_terms" header="Freight" style="text-align: center">
                </Column>
                
                <Column field="confirm_ship_date" header="Confirm Arrival at Site" style="text-align: center">
                    <template #body="slotProps">
                    <Calendar id="confirmShipDate" v-model="slotProps.data.confirm_ship_date" :style=" {width:'150px'}" :showIcon="true" dateFormat="dd-M-yy" inputStyle="text-transform: uppercase"/>
                </template>
                </Column>
                <Column field="fob_sp" header="Incoterm" headerStyle="min-width:8rem;">

                </Column>
            </DataTable>
            </div> -->

        </div> 
        
        </div>
</div>
</template>
<script>
 /* eslint-disable */
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import PlanningOrder from '../service/PlanningOrderService'
import { reactive, ref, toRef, unref } from '@vue/reactivity';
import AuthService from '../service/AuthService'
export default {
 name: "planningorder-list",
 data() {
     return {
        idValue : this.$route.params.id,
        idDetail : null,
        isiDetail : null,
        planningOrderNum : null,
        planningOrderDate : null,
        supplier : null,
        respondBy : null,
        revisition : null,
        planningOrderStatus : null,
        respondDate : null,
        location : null,
        isiLocation : null,
        filteredLocation : null,
        displayResponsive: false,
        changeTable : false,
        planningOrderTable : null,
        selectedPo : null,
        termsTable : null,
        poType: null,
        isiPoType: null,
        filteredPoType: null,
        selectedPoType: null,
        uom: null,
        currenciesList:null,
        isiUom: null,
        freight: null,
        isiFreight: null,
        tablepopuppo: null,
        arrivalDate: null,
        top : null,
        isiTop : null,
        incoterm: null,
        isiIncoterm: null,
        status : null,
        changeStatus: null,
        unholdStatus : null,
        changeHold: false,
        changeUnhold: false,
        popUpPo: null,
        filteredPopUpPo: null,
        poNumber: null,
        qty: null,
        submitpo : false,
        isInvalid : true,
        displayValidasi : false,
        displayPilih : false,
        something: null,
		breadcrumbHome: {icon: 'pi pi-home', to: '/'},
			breadcrumbItems: [
				{label:'Planning Order', to: '/mgm/settings/planningorder'},
                {label:'Detail Planning Order'}
			],
		 
        
       }
    },
	created() {
		if (this.idValue != 0){
            AuthService.whoAmi().then(response => {
                if (response.status === 200){
                    // 
                    const payload = this.idValue;
                    this.respondBy = response.data.data.userid;
                    PlanningOrder.getIdDetail(payload).then(response => {
                        // 
                        this.isiDetail = response.data.data
                        // 
                        this.planningOrderNum = response.data.data[0].plan_order_num
                        let getMustRespDate = response.data.data[0].response_date;
                        let dateProcessA = getMustRespDate.substring(0,2);
                        let dateProcessB = getMustRespDate.substring(3,5);
                        let dateProcessC = getMustRespDate.substring(6,10);
                        let combineMustRespDate = dateProcessB + '/' + dateProcessA + '/' + dateProcessC;
                        let newDateA = new Date(combineMustRespDate.substring(0,10));
                        this.respondDate = newDateA.getDate()+'-'+newDateA.toString().substr(4,3).toUpperCase()+'-'+newDateA.getFullYear();
                        this.supplier = response.data.data[0].vendor_name
                        // this.respondBy = response.data.data[0].response_by
                        this.revisition = response.data.data[0].revisition
                        this.planningOrderStatus = response.data.data[0].status
                        if (response.data.data[0].status === 'HOLD'){
                            // 
                            this.changeHold = true;
                            this.changeUnhold = false;
                            this.submitpo = false;
                        }
                        if (response.data.data[0].status !== 'HOLD'){
                            // 
                            this.changeHold = false;
                            this.changeUnhold = true;
                            this.submitpo = true;
                        }
                        let dateResp = response.data.data[0].plan_order_date;
                        let dateRespProcessA = dateResp.substring(0,2);
                        let dateRespProcessB = dateResp.substring(3,5);
                        let dateRespProcessC = dateResp.substring(6,10);
                        let combinedDateResp = dateRespProcessB + '/' + dateRespProcessA + '/' + dateRespProcessC;
                        let newDate = new Date(combinedDateResp.substring(0,10));
                        this.dateRespConvert = newDate.getDate()+'-'+newDate.toString().substr(4,3)+'-'+newDate.getFullYear();
                        this.planningOrderDate = this.dateRespConvert.toUpperCase();
                        if (this.planningOrderNum !== null){
                            const payload = response.data.data[0].plan_order_num
                            PlanningOrder.getTableDetailPo(payload).then(response => {
                                this.planningOrderTable = response.data.data
                                
                            })
                            PlanningOrder.getTermsSupp(payload).then(response => {
                                this.termsTable = response.data.data
                                // let date = new Date(response.data.data.confirm_ship_date)
                                
                            })
                        }
                        
                    })
                }
                
            })
        }
        this.getLocation()
        this.getPoType()
        this.getUom()
        this.getFreight()
        this.getTablePoNumber()
        this.getTop()
        this.getIncoterm()
		},
	mounted() {
	    PlanningOrder.getAllCurrencies().then(value => {
	        this.currenciesList = value.data.data;
	    });





	},
	computed: {
		// respondDate(){
        //     const date = new Date()
        //     date.setDate(date.respondBy()+ 1)
        //     return date
        // }
	},
	methods: {
		getLocation(){
            AuthService.whoAmi().then(response => {
                if (response.status === 200){
                    PlanningOrder.getLocation().then(response => {
                        // 
                        this.isiLocation = response.data.data
                    })
                }
            })
        },
        searchLocation(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredLocation = [...this.isiLocation];
                }
                else {
                    this.filteredLocation = this.isiLocation.filter((item) => {
                        return item.ship_to_desc.toLowerCase().match(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
        getPoType(){
            AuthService.whoAmi().then(response => {
                if (response.status === 200 ){
                    PlanningOrder.getPoType().then(response => {
                        this.isiPoType = response.data.data
                    })
                }
            })
        },
        getFreight(){
            AuthService.whoAmi().then(response => {
                if (response.status === 200 ){
                    PlanningOrder.getFreight().then(response => {
                        this.isiFreight = response.data.data
                    })
                }
            })
        },
        getTop(){
            AuthService.whoAmi().then(response => {
                if (response.status === 200 ){
                    PlanningOrder.getTop().then(response => {
                        this.isiTop = response.data.data
                    })
                }
            })
        },
        getIncoterm(){
            AuthService.whoAmi().then(response => {
                if (response.status === 200 ){
                    if (sessionStorage.companydb !== null){
                         const payload = sessionStorage.companydb
                        
                        PlanningOrder.getIncoterm(payload).then(response => {
                            this.isiIncoterm = response.data.data
                            
                        })
                    }
                }
            })
        },
        getTablePoNumber(){
            // AuthService.whoAmi().then(response => {
            //     if (response === 200 ){
                     PlanningOrder.getTablePoNumber().then(response => {
                        this.tablepopuppo = response.data.data
                        // 
                    })
            //     }
            // })
        },
        searchPoType(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredPoType = [...this.isiPoType];
                }
                else {
                    this.filteredPoType = this.isiPoType.filter((item) => {
                        return item.name.toLowerCase().match(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
        getUom(){
            AuthService.whoAmi().then(response => {
                if (response.status === 200){
                    PlanningOrder.getUom().then(response => {
                        this.isiUom = response.data.data
                        
                    })
                }
            })
        },
        changeStatusHold(){
            if (this.planningOrderStatus !== 'H' || this.planningOrderStatus !== 'HOLD'){
                this.changeHold = true
                this.changeUnhold = false
                this.submitpo = false
                let payload = { status : 'H', id : this.idValue}
                PlanningOrder.changeStatus(payload).then(response => {
                    
                    this.$toast.add({severity: 'success', summary: 'Status Change', detail:'Change Status to Hold', life: 3000});
                    this.planningOrderStatus = "HOLD"
                })
            }
        },
        changeStatusUnhold(){
            if (this.planningOrderStatus === 'H' || this.planningOrderStatus === 'HOLD'){
                this.changeUnhold = true
                this.changeHold = false
                this.submitpo = true
                let payload = { status : 'C', id : this.idValue}
                PlanningOrder.changeStatus(payload).then(response => {
                    
                    this.$toast.add({severity: 'success', summary: 'Status Change', detail:'Change Status to Confirmed', life: 3000});
                    this.planningOrderStatus = "CONFIRMED"
                })
            }   
        },
        searchPopUpPo(event){
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredPopUpPo = [...this.tablepopuppo];
                }
                else {
                    this.filteredPopUpPo = this.tablepopuppo.filter((item) => {
                        return item.segment1.toLowerCase().match(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
        openResponsive() {
            this.displayResponsive = true;
        },
        closeResponsive() {
            this.displayResponsive = false;
            this.popUpPo = null;
        },
        submitResponsive() {
            this.displayResponsive = false;
            this.poNumber = this.popUpPo.segment1;
            
        },
        openPo(){
            
            this.changeTable = false;
        },
        openTerms(){
            
            this.changeTable = true;
        },
        back(){
            this.$router.push({name: 'planningOrder' });
        },
        notAccept(){
            var isTest = true;
            if (this.selectedPo == null || this.selectedPo.length == 0 ){
                this.displayPilih = true
            }
            for (let i = 0; i < this.selectedPo.length; i++) {
                if (this.selectedPo[i].remarks3.length == 0) 
                { 
                    isTest = false
                    this.displayValidasi = true
                    break; 
                }
                
                }
                if (isTest){
                    // 
                    var options = {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit"
                    }
                    var date = new Date().toLocaleDateString("id", options);
                    var something = 4
                    var notAccept = "N"
                    let payload = {isi: this.selectedPo, date: date, updatedby: something, status: notAccept, plan_order_id: this.idValue}
                    
                    PlanningOrder.notAcceptStatus(payload).then(response => {
                        
                    })
                    AuthService.whoAmi().then(response => {
                        
                    })

                }
                              
        }
		
		}    
	}

</script>