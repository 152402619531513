<template>
	<!-- Create Input Form -->
	<div class="col-12 p-fluid mb-4 create-invoice-po">
		<Toast />

		<!-- Progress modal -->
		<ProgressModal :progress="progress">
			<template #message>
				Saving invoice in progress. Please wait...
			</template>
		</ProgressModal>

		<div class="content-wrapper">
			<div class="content-header">{{ title }}</div>
			<div class="content-body">
				<!-- Input Form -->
				<div class="grid mb-8">
					<!-- Col 1 -->
					<div class="col-12 md:col-6 lg:col-4">
						<!-- Dropdown company if page is create invoice -->
						<div v-if="!props.id" class="mb-5">
							<span class="p-float-label">
								<AutoComplete id="company" :disabled="true" :class="{
									'p-invalid': inputError.some(
										(err) => err.name === 'company'
									),
								}" v-model="company" :disable="true" :suggestions="filteredCompany" @complete="searchCompany($event)" :dropdown="true"
									field="name" forceSelection>
									<template #item="{ item }">
										<div>
											{{ item.name }}
										</div>
									</template>
								</AutoComplete>
								<label for="company">Company</label>
							</span>
							<div class="mt-1" v-if="
								inputError.some(
									(err) => err.name === 'company'
								)
							">
								<small class="p-error" style="font-size: 12px">
									{{
										inputError.find(
											(err) => err.name === 'company'
										)['msg']
									}}
								</small>
							</div>
						</div>
						<div class="mb-5">
							<span class="p-float-label">
								<InputText readonly id="siteName" v-model="siteName" />
								<label for="invoiceNo">Site Name</label>
							</span>
							<div class="mt-1" v-if="
								inputError.some(
									(err) => err.name === 'invoiceNo'
								)
							">
								<small class="p-error" style="font-size: 12px">
									{{
										inputError.find(
											(err) => err.name === 'invoiceNo'
										)['msg']
									}}
								</small>
							</div>
						</div>
						<div class="mb-5">
							<span class="p-float-label">
								<InputText id="invoiceNo" :class="{
									'p-invalid': inputError.some(
										(err) => err.name === 'invoiceNo'
									),
								}" v-model="invoiceNo" />
								<label for="invoiceNo">Invoice No</label>
							</span>
							<div class="mt-1" v-if="
								inputError.some(
									(err) => err.name === 'invoiceNo'
								)
							">
								<small class="p-error" style="font-size: 12px">
									{{
										inputError.find(
											(err) => err.name === 'invoiceNo'
										)['msg']
									}}
								</small>
							</div>
						</div>
						<div class="mb-5">
							<span class="p-float-label">
								<Calendar id="invoiceDate" :class="{
									'p-invalid': inputError.some(
										(err) => err.name === 'invoiceDate'
									),
								}" v-model="invoiceDate" dateFormat="d-M-yy" :showIcon="true" />
								<label for="invoiceDate">Invoice Date</label>
							</span>
							<div class="mt-1" v-if="
								inputError.some(
									(err) => err.name === 'invoiceDate'
								)
							">
								<small class="p-error" style="font-size: 12px">
									{{
										inputError.find(
											(err) => err.name === 'invoiceDate'
										)['msg']
									}}
								</small>
							</div>
						</div>

						<!-- Company field if page is detail invoice -->
						<div v-if="props.id" class="mb-5">
							<span class="p-float-label">
								<InputText id="disabledCompany" disabled v-model="disabledCompany" />
								<label for="disabledCompany">Company</label>
							</span>
						</div>

						<div class="mb-5">
							<span class="p-float-label">
								<Textarea id="description" :class="{
									'p-invalid': inputError.some(
										(err) => err.name === 'desc'
									),
								}" rows="5" v-model="description" :maxlength="230" />
								<label for="description">Description</label>
							</span>
							<div class="mt-1" v-if="
								inputError.some(
									(err) => err.name === 'desc'
								)
							">
								<small class="p-error" style="font-size: 12px">
									{{
										inputError.find(
											(err) => err.name === 'desc'
										)['msg']
									}}
								</small>
							</div>
						</div>
						<div class="mb-5">
							<span class="p-float-label">
								<AutoComplete forceSelection field="name" :class="{
									'p-invalid': inputError.some(
										(err) => err.name === 'bank'
									),
								}" :disabled="!bankList" :dropdown="true" :suggestions="filteredBanks" v-model="bank"
									@complete="searchBank($event)" />
								<label for="bank">Bank</label>
							</span>
							<div class="mt-1" v-if="
								inputError.some(
									(err) => err.name === 'bank'
								)
							">
								<small class="p-error" style="font-size: 12px">
									{{
										inputError.find(
											(err) => err.name === 'bank'
										)['msg']
									}}
								</small>
							</div>
						</div>
					</div>
					<!-- Col 2 -->
					<div class="col-12 md:col-6 lg:col-4">
						<div class="mb-5">
							<span class="p-float-label">
								<InputText
									id="taxInvoiceNo" 
									:class="{
										'p-invalid': inputError.some(
											(err) => err.name === 'taxInvoiceNo'
										),
									}"
									@change="handleChangeTax($event)"
									v-model="taxInvoiceNo"
								/>
								<label for="taxInvoiceNo">Tax Invoice No</label>
							</span>
							<div class="mt-1" v-if="
								inputError.some(
									(err) => err.name === 'taxInvoiceNo'
								)
							">
								<small class="p-error" style="font-size: 12px">
									{{
										inputError.find(
											(err) => err.name === 'taxInvoiceNo'
										)['msg']
									}}
								</small>
							</div>
						</div>
						<div class="mb-5">
							<span class="p-float-label">
								<Calendar id="taxInvoiceDate" :class="{
									'p-invalid': inputError.some(
										(err) =>
											err.name === 'taxInvoiceDate'
									),
								}" dateFormat="d-M-yy" :showIcon="true" v-model="taxInvoiceDate" />
								<label for="taxInvoiceDate">Tax Invoice Date</label>
							</span>
							<div class="mt-1" v-if="
								inputError.some(
									(err) => err.name === 'taxInvoiceDate'
								)
							">
								<small class="p-error" style="font-size: 12px">
									{{
										inputError.find(
											(err) =>
												err.name === 'taxInvoiceDate'
										)['msg']
									}}
								</small>
							</div>
						</div>
						<div class="mb-5">
							<span class="p-float-label">
								<InputText class="statusInvoice" disabled v-model="status" />
								<label for="statusInvoice">Status</label>
							</span>
						</div>
						<div class="mb-5">
							<FileUpload ref="fileUpload" mode="advanced" :class="{
								'error-file': inputError.some(
									(err) => err.name === 'file'
								),
							}" chooseLabel="Upload File" fileLimit="1" accept="application/pdf" :maxFileSize="5000000"
								:showUploadButton="false" :showCancelButton="false" :customUpload="true"
								@select="onSelectFile" @remove="onRemoveFile" />
						</div>
					</div>
					<!-- Col 3 -->
					<div class="col-12 md:col-6 lg:col-4">
						<!-- Summary card -->
						<div class="form-card mb-5">
							<div class="flex align-items-center justify-content-between mb-4">
								<span style="color: #000" class="font-semibold">INVOICE AMOUNT</span>
								<span class="font-bold">
									{{
										(invoiceAmount &&
											formattingRound(invoiceAmount)
												.toFixed(2)
												.replace(
													/\B(?=(\d{3})+(?!\d))/g,
													','
												)) ||
										(totalDPP &&
											formattingRound(totalDPP)
												.toFixed(2)
												.replace(
													/\B(?=(\d{3})+(?!\d))/g,
													','
												))
									}}
								</span>
							</div>
							<div class="flex align-items-center justify-content-between mb-4">
								<span style="color: #000" class="font-semibold">TAX AMOUNT</span>
								<span class="font-bold">
									{{
										(taxAmount &&
											formattingRound(taxAmount)
												.toFixed(2)
												.replace(
													/\B(?=(\d{3})+(?!\d))/g,
													','
												)) ||
										(totalPPN &&
											formattingRound(totalPPN)
												.toFixed(2)
												.replace(
													/\B(?=(\d{3})+(?!\d))/g,
													','
												))
									}}
								</span>
							</div>
							<div class="flex align-items-center justify-content-between mb-4">
								<span style="color: #000" class="font-semibold">TOTAL</span>
								<span class="font-bold">
									{{
										(totalAmount &&
											formattingRound(totalAmount)
												.toFixed(2)
												.replace(
													/\B(?=(\d{3})+(?!\d))/g,
													','
												)) ||
										(summary.totalInvoice &&
											formattingRound(
												summary.totalInvoice
											)
												.toFixed(2)
												.replace(
													/\B(?=(\d{3})+(?!\d))/g,
													','
												))
									}}
								</span>
							</div>
							<div class="flex align-items-center justify-content-between">
								<span style="color: #000" class="font-semibold">CURRENCY</span>
								<span class="font-bold">
									{{
										currency
									}}
								</span>
							</div>
						</div>
						<div v-if="props.id && status === 'DRAFT'" class="mb-5">
							<p class="mr-2 font-semibold" style="color: #000">
								Reject Reason:
							</p>
							<p class="ml-4">
								{{ rejectReason || '-' }}
							</p>
						</div>
					</div>
				</div>

				<!-- Attachments -->
				<div v-if="props.id || invoiceId" class="content-wrapper mb-8">
					<div class="content-header text-base font-bold" style="color: #555 !important">
						Attachment(s)
					</div>
					<div class="content-body">
						<!-- Input Form -->
						<div class="grid">
							<div class="col-auto">
								<div v-if="savedFile" class="flex">
									<!-- <a
										class="button-download p-button"
										:href="savedFile.url"
									>
										<span class="font-bold">
											<i class="pi pi-download mr-2" />
											{{ savedFile.filename }}
										</span>
									</a> -->
									<Button v-if="!loadingDownladFile" class="button-download font-normal"
										:label="savedFile.filename" icon="pi pi-download" @click="onDownload()" />
									<Button v-if="loadingDownladFile" class="button-download font-normal"
										:label="`${savedFile.filename} is downloading`" icon="pi pi-download"
										:disabled="true" />
									<Button v-if="status === 'DRAFT' && !invoiceId" type="button"
										class="p-button-rounded p-button-text ml-2" icon="pi pi-trash"
										@click="handleDeleteFile" />
								</div>
								<div v-else>&dash;</div>
							</div>
						</div>
					</div>
				</div>

				<!-- List PO -->
				<div class="grid mb-6">
					<div v-if="!props.id || (props.id && status === 'DRAFT')" class="col-auto ml-auto mb-3">
						<Button class="p-button-success" :disabled="!company || !vendorId" @click="displayModal = true">
							<span class="uppercase font-bold">Search Pending PO</span>
						</Button>
					</div>
					<div class="col-12">
						<DataTable :value="selectedPo" :scrollable="true" scrollHeight="600px" scrollDirection="both"
							responsiveLayout="scroll">
							<Column header="No" style="width: 8%">
								<template #body="{ index }">
									{{ index + 1 }}
								</template>
							</Column>
							<Column header="PO Number" field="po_number" style="width: 15%"></Column>
							<Column header="PO Line" field="line_num" style="width: 9%"></Column>
							<Column header="Product" field="item_description" style="width: 20%"></Column>
							<Column header="Price" style="width: 15%">
								<template #body="{ data }">
									{{
										formattingRound(data.unit_price)
											.toFixed(2)
											.replace(
												/\B(?=(\d{3})+(?!\d))/g,
												','
											)
									}}
								</template>
							</Column>
							<Column header="Qty" style="width: 9%">
								<template #body="{ data }">
									{{
										data.quantity_received.toLocaleString(
											'en-US'
										)
									}}
								</template>
							</Column>
							<Column header="Amount" style="width: 15%">
								<template #body="{ data }">
									{{
										formattingRound(data.total_dpp)
											.toFixed(2)
											.replace(
												/\B(?=(\d{3})+(?!\d))/g,
												','
											)
									}}
								</template>
							</Column>
							<Column header="VAT" style="width: 15%">
								<template #body="slotProps">
									<AutoComplete forceSelection :dropdown="true"
										@change="onChangeVATValue(slotProps.index)" :suggestions="filteredVAT"
										@complete="searchVAT($event)" v-model="slotProps.data.vat_code" field="VAT_CODE"
										@item-select="onChangeVAT($event, slotProps.index)" />
								</template>
							</Column>
							<Column header="WHT" style="width: 15%">
								<template #body="slotProps">
									<AutoComplete forceSelection :dropdown="true" :suggestions="filteredWHT"
										@complete="searchWHT($event)" v-model="slotProps.data.wht_code" field="WHT_CODE"
										@change="onChangeWHTValue(slotProps.index)"
										@item-select="onChangeWHT($event, slotProps.index)" />
								</template>
							</Column>
							<Column v-if="
								!props.id ||
								(props.id && status === 'DRAFT')
							" header="Actions" alignFrozen="right" style="width: 9%" frozen>
								<template #body="{ data }">
									<Button icon="pi pi-trash" class="p-button-rounded p-button-text"
										@click="handleDelete(data.transaction_id)" />
								</template>
							</Column>

							<!-- Empty state -->
							<template #empty> No selected item. </template>
						</DataTable>
					</div>
				</div>

				<!-- Action buttons -->
				<div>
					<!-- Create invoice buttons -->
					<div v-if="!props.id" class="grid">
						<div class="col-12 md:col-6 lg:col-3">
							<Button v-if="!progress" class="p-button-success justify-content-center" :disabled="invoiceId ||
								disabledButtonSave ||
								!company ||
								!vendorId
								" @click="handleSave">
								<span class="font-bold uppercase">Save</span>
							</Button>
							<Button v-else class="p-button-success justify-content-center" disabled>
								<span class="font-bold uppercase">Saving...</span>
							</Button>
						</div>
						<div class="col-12 md:col-6 lg:col-3">
							<Button v-if="!progress" class="p-button-info justify-content-center" :disabled="invoiceId ||
								disabledButtonSave ||
								!company ||
								!vendorId
								" @click="handleSubmit">
								<span class="font-bold uppercase">Submit</span>
							</Button>
							<Button v-else class="p-button-info justify-content-center" disabled>
								<span class="font-bold uppercase">Submitting...</span>
							</Button>
						</div>
						<div class="col-12 md:col-6 lg:col-3">
							<Button class="p-button-danger justify-content-center" @click="handlePrintCreateInvoice">
								<span class="font-bold uppercase">Print Goods Receipt Note</span>
							</Button>
						</div>
						<div class="col-12 md:col-6 lg:col-3">
							<Button class="justify-content-center p-button-secondary"
								@click="$router.push({ name: 'SearchPendingPOSupp' })"><span
									class="font-bold uppercase text-black-alpha-90"
									:class="status === 'DRAFT' && 'underline'">Back</span></Button>
						</div>
					</div>

					<!-- Detail invoice buttons -->
					<div v-if="props.id && !loading" class="grid justify-content-end">
						<div v-if="status === 'DRAFT'" class="col-12 md:col-6 lg:col-4">
							<Button v-if="!progress" class="p-button-success justify-content-center"
								@click="handleUpdate">
								<span class="font-bold uppercase">Save</span>
							</Button>
							<Button v-else class="p-button-success justify-content-center" disabled>
								<span class="font-bold uppercase">Saving...</span>
							</Button>
						</div>
						<div class="col-12 md:col-6 lg:col-3">
							<Button class="p-button-secondary justify-content-center" @click="handlePrintDetailInvoice">
								<span class="font-bold uppercase">Print Goods Receipt Note</span>
							</Button>
						</div>
						<div class="col-12 md:col-6 lg:col-3">
							<Button class="justify-content-center p-button-secondary"
								@click="$router.push({ name: 'ListInvoicePO' })"><span
									class="font-bold uppercase text-black-alpha-90"
									:class="status === 'DRAFT' && 'underline'">Back</span></Button>
						</div>
					</div>
				</div>

				<!-- Find PO Modal -->
				<Dialog header=" " :draggable="false" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
					:style="{ width: '75vw', overflow: 'hidden' }" :modal="true" :dismissableMask="true"
					v-model:visible="displayModal">
					<div class="pt-3">
						<FindPOModal :dbInstance="props.id ? $route.query.i : poOu.db_instance
							" :orgId="props.id ? $route.query.v : poOu.value" :vendorId="vendorId" @on-add="handleAddPo"
							@on-close="displayModal = false" :banList="selectedPo" :type="typeValue"
							:poNumber="poNumber" />
					</div>
				</Dialog>

				<!-- Add To Batch Modal -->
				<Dialog header="Add To Batch" :draggable="false" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
					:style="{ width: '25vw', overflow: 'hidden' }" :modal="true" :dismissableMask="true"
					v-model:visible="displayModalBatch">
					<div class="pt-3">
						<AddToBatchModal :dbInstance="company.db_instance" :orgId="company.value" :vendorId="vendorId"
							@on-close="displayModalBatch = false" @on-save="handleAddToBatch" />
					</div>
				</Dialog>
			</div>
		</div>
	</div>
</template>

<script setup>
import Swal from "sweetalert2";
import { computed, onMounted, ref, watch, defineProps, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import SimpleCrypto from 'simple-crypto-js';
import LovService from '../../service/LovService';
import PlanningOrderService from '../../service/PlanningOrderService';
import PoFulfillment from '../../service/PoFulfillment';
import FindPOModal from '../components/FindPOModal.vue';
import ProgressModal from '../components/ProgressModal.vue';
import AddToBatchModal from '../components/AddToBatchModal';
import InvoicePOService from '../../service/InvoicePOService';
import PendingPOService from '../../service/PendingPOService';
import InvoiceNonPOservice from '../../service/InvoiceNonPOservice';
import { PICEnum, InvoiceTypeEnum, PoTypeEnum } from "../../constants/POTypeEnum";
// import ItemJit from '../../service/ItemJit';
import { formatRounding, formatCeil, formatFloor } from '../../helper/formatRounding';
import { UrlTab } from "../../constants/UrlTab";

// State
const invoiceId = ref(null);
const currentUser = ref(props.currentUser);
const company = ref(null);
const invoiceNo = ref(null);
const invoiceDate = ref(null);
const description = ref(null);
const bank = ref(null);
const taxInvoiceNo = ref(null);
const taxInvoiceDate = ref(null);
const status = ref(null);
const file = ref(null);
const fileUpload = ref(null);
const isPphChecked = ref(null);
const pphValue = ref(null);
const filteredBanks = ref(null);
const vendorId = ref(null);
const vendorName = ref(null);
const companyList = ref(null);
const bankList = ref(null);
const filteredCompany = ref(null);
const displayModal = ref(false);
const displayModalBatch = ref(false);
const selectedPo = ref([]);
const inputError = ref([]);
const progress = ref(false);
const loading = ref(false);
const disabledCompany = ref(null);
const rejectReason = ref(null);
const savedInvoiceDetails = ref(null);
const savedFile = ref(null);
const deletedFile = ref(null);
const totalPPN = ref(0);
const totalDPP = ref(0);
const invoiceAmount = ref(null);
const taxAmount = ref(null);
const totalAmount = ref(null);
const disabledButtonSave = ref(false);
const fileBuffer = ref();
const loadingDownladFile = ref(false);
const getVAT = ref([]);
const filteredVAT = ref([]);
const getWHT = ref();
const filteredWHT = ref([]);
const headerId = ref(null);
const typeValue = ref(null)
const poNumber = ref(null)
const currency = ref(null)
const rounder = ref();
const route = useRoute();
const router = useRouter();
const poOu = ref(null);
const siteName = ref(null);


const toast = useToast();

const props = defineProps(['title', 'id', 'currentUser']);

// Summary invoice
const summary = computed(() => {
	const totalPph = (isPphChecked.value && pphValue.value) || 0;

	const totalInvoice = totalDPP.value + totalPPN.value;

	return {
		totalPph,
		totalInvoice,
	};
});

// Mounted hook
onMounted(async () => {
	// Pre-fetch company and vendor
	console.log("___PREFETCH___")
	if (currentUser.value.leveltenant === 2) {
		await getCompany();
		await getVendorId();
	} else {
		await getCompany();
		let payload = { po_number: route.params.id }

		if (route.query.v) {
			payload.type = PICEnum.DetailStandard
		} else {
			payload.type = PICEnum.Standard
		}

		const res = await PendingPOService.getVendorForPic(payload)
		vendorName.value = res.data.data.vendor_name
		vendorId.value = res.data.data.vendor_id
		await getDetailPendingPo(vendorId.value);
	}

	// Call detail invoice if in detail page
	if (props.id) {
		await getListBank(route.query.v, route.query.i, vendorId.value);
		await getInvoiceDetail(props.id);
		await getDropdownVat();

		return;
	}

	console.log("SEBELUM BANK")
	// Get list bank
	await getListBank(
		company.value.value,
		company.value.db_instance,
		vendorId.value
	);

	await getDetailPendingPo(vendorId.value);
	await getDropdownVat();

});


const getDropdownWht = async () => {
	console.log(company.value, "COMPER")
	if (company.value) {
		const result = await InvoiceNonPOservice.getListWht(
			company.value.value,
			company.value.db_instance
		);

		console.log(result.data, "RESULT")

		const noSelect = {
			WHT_RATE: 0,
			WHT_CODE_ID: 0,
			WHT_CODE: "- No Select -"
		}
		getWHT.value = [noSelect, ...result.data.data];
	}
};

const getDetailPendingPo = async (id) => {
	try {
		console.log(id, vendorId.value, "VAL")
		const payload = {
			dbInstance: company.value.db_instance,
			orgId: company.value ? company.value.value : null,
			vendorId: vendorId.value ? vendorId.value : id,
			poNumber: route.params.id,
		}


		const result = await PendingPOService.getDetailPendingPo(payload);
		// typeValue.value = result.data.data[0].po_type
		typeValue.value = PoTypeEnum.Standart
		poNumber.value = result.data.data[0].po_number
		selectedPo.value = result.data.data;
		currency.value = result.data.data[0].currency_code

		const resultCompanyName = await PendingPOService.findCompanyById({
			orgId: result.data.data[0].org_id
		})

		poOu.value = {
			value: result.data.data[0].org_id,
			db_instance: result.data.data[0].db_instance,
			company_name: resultCompanyName.data.data[0].NAME
		};

		const rounding = await PendingPOService.getRounded({
			vendor_id: vendorId.value,
			curr_code: currency.value,
			db_instance: company.value.db_instance
		});

		siteName.value = resultCompanyName.data.data[0].NAME
		rounder.value = rounding.data.data;
	} catch (error) {
		console.log(error);
	}
}

// Unmounted hook
onUnmounted(() => {
	unwatchCompany();
	unwatchSelectedPo();
	unwatchInvoiceDate();
});

const formattingRound = (value) => {
	if (!rounder.value) return formatRounding(value);
	if (rounder.value.rounded.rounding_rule_code === "UP") return formatCeil(value, rounder.value.precision)
	if (rounder.value.rounded.rounding_rule_code === "DOWN") return formatFloor(value, rounder.value.precision)

	return formatRounding(value)
}

watch(company, () => {
	handleReset()
})

// Watch company selected
const unwatchCompany = watch(company, async (newCompany, oldCompany) => {
	if (oldCompany) {
		bankList.value = [];

		vendorId.value = null; // Reset old vendorId

		// Get new vendorId for new company
		const res = await PlanningOrderService.getSuppValue(
			{
				vendorName: vendorName.value,
			},
			company.value.db_instance
		);
		vendorId.value = res.data.data[0].vendor_id;

		await getListBank(
			newCompany.value,
			newCompany.db_instance,
			vendorId.value
		);
	}
});

// Watch selected PO to get total PPN
const unwatchSelectedPo = watch(selectedPo, async () => {
	if (selectedPo.value.length) {
		disabledButtonSave.value = true;

		disabledButtonSave.value = false;

		await getTotalPPN();
	} else {
		totalPPN.value = 0;
		totalDPP.value = 0;
	}
});

// Watch invoice date to get total PPN
const unwatchInvoiceDate = watch(invoiceDate, async () => {
	if (invoiceDate.value && selectedPo.value.length) {
		disabledButtonSave.value = true;

		disabledButtonSave.value = false;
		await getTotalPPN();
	}
});

watch(selectedPo, () => {
	console.log(selectedPo.value, "VALS")
})

watch(invoiceDate, async () => {
	await getDropdownVat();
})

// Get total PPN
const getTotalPPN = async () => {
	const invoices = [];
	console.log(selectedPo.value, "VAL")
	selectedPo.value.forEach((po) => {
		const invoice = {
			poHeaderId: po.po_header_id,
			poNumber: po.po_number,
			poLineId: po.po_line_id,
			transactionId: po.transaction_id,
			lineNum: po.line_num,
			itemNumber: po.item_number,
			vat_code: po.vat_code
		};
		invoices.push(invoice);
	});
	const payload = {
		dbInstance: props.id ? route.query.i : company.value.db_instance,
		orgId: props.id ? route.query.v : company.value.value,
		vendorId: vendorId.value,
		invoiceDate: invoiceDate.value,
		rows: invoices,
		paramRet: 'RATE'
	};
	const result = await PendingPOService.getTotalPPN(payload);
	console.log(result, "RESULT")
	if (result.status === 200) {
		totalPPN.value = result.data.data[0].total_ppn;
		totalDPP.value = result.data.data[0].total_dpp;
	} else {
		toast.add({
			severity: 'error',
			summary: 'Unable to get total ppn',
			life: 3000,
		});
	}
};

// Formatting date
const formattingDate = (date, withTime) => {
	const theDate = new Date(date);
	const strDate =
		theDate.getFullYear() +
		'-' +
		(theDate.getMonth() + 1) +
		'-' +
		theDate.getDate();
	const strTime =
		theDate.getHours() +
		':' +
		theDate.getMinutes() +
		':' +
		theDate.getSeconds();

	if (withTime) {
		return `${strDate} ${strTime}`;
	} else {
		return `${strDate}`;
	}
};

// Get company list
const getCompany = async () => {
	if (currentUser.value.userid === 'dexaadmin') {
		// If user is admin
		const companyRes = LovService.getValueType('COMPANY');
		companyList.value = companyRes.data.data;
	} else if (currentUser.value.leveltenant == 2) {
		// If user is supplier
		let payload = currentUser.value.id;
		const companySuppRes = await PoFulfillment.getCompanySupp(payload);
	
		try {
			const companySupp = companySuppRes.data.data;
			const companyValues = await Promise.all(
				companySupp.map(async (element) => {
					const res = await LovService.getCompanyByName(
						element.company
					);

					const secretKey = 'some-unique-key';
					const simpleCrypto = new SimpleCrypto(secretKey);
					const decipherText = simpleCrypto.decrypt(
						sessionStorage.getItem('dropdownCompany')
					);

					if (res.data.data[0].name === decipherText) {
						company.value = res.data.data[0];
						console.log(company.value, "COMP VALUE")
					}

					await getDropdownWht();

					return res.data.data[0];
				})
			);

			companyList.value = companyValues;
		} catch (e) {
			console.log(e);
			if (e !== 'Break') throw e;
		}
	} else if (currentUser.value.leveltenant == 1) {
		// If user is Finance
		const res = await LovService.getcompanydb(currentUser.value.bioaddress);
		companyList.value = res.data.data;

		try {
			const secretKey = 'some-unique-key';
			const simpleCrypto = new SimpleCrypto(secretKey);
			const decipherText = simpleCrypto.decrypt(
				sessionStorage.getItem('dropdownCompany')
			);
			companyList.value.forEach(async (element) => {
				if (element.name === decipherText) {
					company.value = element;
					await getDropdownWht();
					throw 'Break';
				}
			});
		} catch (e) {
			if (e !== 'Break') throw e;
		}
	}
};

// Get VendorId of logged in user
const getVendorId = async () => {
	const resVendorName = await PoFulfillment.getSuppBindCompProc(
		currentUser.value.id
	);
	vendorName.value = resVendorName.data.data[0][0].vendorname;
	const payloadSuppVal = {
		vendorName: resVendorName.data.data[0][0].vendorname,
	};

	const resVendorId = await PlanningOrderService.getSuppValue(
		payloadSuppVal,
		company.value.db_instance
	);
	vendorId.value = resVendorId.data.data[0].vendor_id;
};

// Get bank account list
const getListBank = async (orgId, dbInstance, vendorId) => {
	const payload = {
		orgId,
		dbInstance,
		vendorId,
	};

	const res = await InvoicePOService.getListBank(payload);

	if (res.status === 200) {
		bankList.value = res.data.data.map((item) => {
			return {
				id: item.ext_bank_account_id,
				name: item.transfer_to,
			};
		});
	}
};

// Search company on autocomplete
const searchCompany = (event) => {
	if (!event.query.trim().length) {
		filteredCompany.value = [...companyList.value];
	} else {
		filteredCompany.value = companyList.value.filter((item) => {
			return item.name.toLowerCase().match(event.query.toLowerCase());
		});
	}
};

// Search bank on autocomplete
const searchBank = (event) => {
	if (!event.query.trim().length) {
		filteredBanks.value = [...bankList.value];
	} else {
		filteredBanks.value = bankList.value.filter((bank) => {
			return bank.name.toLowerCase().match(event.query.toLowerCase());
		});
	}
};


// Add selected PO
const handleAddPo = (items) => {
	const newItems = items.filter(
		(item) => !selectedPo.value.some((val) => val.id === item.id)
	);
	selectedPo.value = [...selectedPo.value, ...newItems];
	displayModal.value = false;
};

// Delete selected PO
const handleDelete = async (id) => {
	selectedPo.value = selectedPo.value.filter((po) => po.transaction_id !== id);
};

// Select file attachment
const onSelectFile = (e) => {
	file.value = e.files[0];
};

// Remove file attachment
const onRemoveFile = (e) => {
	if (!e.files.length) {
		file.value = null;
	}
};

// Get attachment file
const getAttachmentFile = async (invoiceId) => {
	try {
		const res = await InvoicePOService.getAttachmentFile(invoiceId);

		if (res.status === 200) {
			savedFile.value = res.data.data;
		}
	} catch (err) {
		console.log(err);
	}
};

// const getFileBase64 = async (invoiceId) => {
// 	try {
// 		const leveltenant = sessionStorage.getItem('leveltenant')
// 		const res = await InvoicePOService.getFileBase64(invoiceId, leveltenant, vendorId.value);

// 		if (res.status === 200) {
// 			fileBuffer.value = res.data.data;
// 		}
// 	} catch (err) {
// 	}
// };

// Delete attach file
const handleDeleteFile = () => {
	deletedFile.value = savedFile.value;
	savedFile.value = null;
};

// Get invoice detail
const getInvoiceDetail = async (id) => {
	try {
		loading.value = true;

		const query = route.query;

		const payload = {
			dbInstance: query.i ? query.i : company.value.db_instance,
			orgId: query.v ? query.v : company.value.value,
			vendorId: vendorId.value,
			levelTenant: currentUser.value.leveltenant,
		};

		const res = await InvoicePOService.getInvoiceDetail(id, payload);

		if (res.status === 200) {
			const invoiceHeader = res.data.data.header;
			const invoiceDetails = res.data.data.details;
			const invoiceFile = res.data.data.file;

			// Assign invoice header
			invoiceNo.value = invoiceHeader.invoice_no;
			invoiceDate.value = new Date(invoiceHeader.invoice_date);
			description.value = invoiceHeader.description;
			taxInvoiceNo.value = invoiceHeader.tax_no;
			taxInvoiceDate.value = new Date(invoiceHeader.tax_date);
			status.value = invoiceHeader.status;
			bank.value = bankList.value.find(
				(item) => item.id === invoiceHeader.external_bank_account_id
			);
			console.log(companyList.value, 'COMPANY VALUE')
			rejectReason.value = invoiceHeader.reason;
			disabledCompany.value = companyList.value.find(
				(item) => parseInt(item.value) === invoiceHeader.org_id
			)['name'];
			vendorId.value = invoiceHeader.vendor_id;
			currency.value = invoiceHeader.currency_code
			// await getFileBase64(id);
			// Assign pph amount
			if (invoiceHeader.service_amount) {
				isPphChecked.value = true;
				pphValue.value = invoiceHeader.service_amount;
			}

			// Assign total invoice amount if detail is open or above
			if (status.value !== 'DRAFT') {
				invoiceAmount.value = invoiceHeader.invoice_amount;
				taxAmount.value = invoiceHeader.tax_amount;
				totalAmount.value = invoiceHeader.total_amount;
			}

			console.log(invoiceDetails, "IDETAILS")

			// Assign invoice details
			selectedPo.value = invoiceDetails.map((item) => {
				return {
					...item,
					id: item.transaction_id,
				};
			});
			savedInvoiceDetails.value = invoiceDetails;

			// Assign invoice file
			savedFile.value = invoiceFile;
		}

		loading.value = false;
	} catch (err) {
		console.log(err);
		loading.value = false;
		toast.add({
			severity: 'error',
			summary: 'Invoice data is not found',
			life: 3000,
		});
	}
};

const validation = async () => {
	inputError.value = [];

	// Validation required input field
	if (
		!company.value ||
		!invoiceNo.value ||
		!invoiceDate.value ||
		!description.value ||
		!bank.value ||
		!taxInvoiceNo.value ||
		!taxInvoiceDate.value
	) {
		console.log('Some fields are required');
		toast.add({
			severity: 'error',
			summary: 'Some fields are required',
			life: 3000,
		});

		if (!company.value) {
			inputError.value.push({
				name: 'company',
				msg: 'This field is required',
			});
		}
		if (!invoiceNo.value) {
			inputError.value.push({
				name: 'invoiceNo',
				msg: 'This field is required',
			});
		}
		if (!invoiceDate.value) {
			inputError.value.push({
				name: 'invoiceDate',
				msg: 'This field is required',
			});
		}
		if (!description.value) {
			inputError.value.push({
				name: 'desc',
				msg: 'This field is required',
			});
		}
		if (!bank.value) {
			inputError.value.push({
				name: 'bank',
				msg: 'This field is required',
			});
		}
		if (!taxInvoiceNo.value) {
			inputError.value.push({
				name: 'taxInvoiceNo',
				msg: 'This field is required',
			});
		}
		if (!taxInvoiceDate.value) {
			inputError.value.push({
				name: 'taxInvoiceDate',
				msg: 'This field is required',
			});
		}

		return true;
	}

	// Validation attachment file
	if (!file.value) {
		console.log('Please select file to upload');
		toast.add({
			severity: 'error',
			summary: 'Please select file to upload',
			life: 3000,
		});
		inputError.value.push({
			name: 'file',
			msg: 'This field is required',
		});
		return true;
	}

	// Validation select PO
	if (!selectedPo.value.length) {
		console.log('Please select PO for creating invoice');
		toast.add({
			severity: 'error',
			summary: 'Please select PO for creating an invoice',
			life: 3000,
		});
		return true;
	}

	// Validation on total amount
	if (!totalDPP.value || totalDPP.value === 0) {
		toast.add({
			severity: 'error',
			summary: 'Invoice amount must be greater than zero',
			life: 3000,
		});
		return true;
	}

	return false;
}

// Save invoice
const handleSave = async () => {
	const isError = await validation();

	if (isError) {
		return;
	}

	// Add file payload
	const formData = new FormData();
	formData.append('file', file.value);

	// Add body payload
	const payload = {
		header: {
			INVOICE_NO: invoiceNo.value,
			INVOICE_DATE: formattingDate(invoiceDate.value),
			REVISION_NUM: 0,
			TAX_NO: taxInvoiceNo.value,
			TAX_DATE: formattingDate(taxInvoiceDate.value),
			DESCRIPTION: description.value,
			// CURRENCY_CODE: selectedPo.value[0].currency_code,
			VENDOR_ID: vendorId.value,
			INVOICE_AMOUNT: totalDPP.value,
			TAX_AMOUNT: totalPPN.value,
			TOTAL_AMOUNT: summary.value.totalInvoice,
			SERVICE_AMOUNT: summary.value.totalPph,
			INVOICE_TYPE: sessionStorage.leveltenant == 2
				? InvoiceTypeEnum.Standard : InvoiceTypeEnum.StandardProxy,
			// WHT_CODE: ,
			// WHT_RATE: ,
			// ATTRIBUTE1: ,
			ORG_ID: parseInt(poOu.value.value),
			STATUS: 'DRAFT',
			CREATION_DATE: formattingDate(new Date(), true),
			CREATED_BY: currentUser.value.id,
			LAST_UPDATE_DATE: formattingDate(new Date(), true),
			LAST_UPDATED_BY: currentUser.value.id,
			// PAYMENT_METHOD_LOOKUP_CODE: ,
			RATE_TYPE: selectedPo.value[0].rate_type,
			// GL_DATE: ,
			// TERMS_ID: ,
			// REASON: ,
			// ACCTS_CODE_COMBINATION_ID: ,
			EXTERNAL_BANK_ACCOUNT_ID: bank.value.id,
			// INVOICE_TYPE: ,
			// CATEGORY_ID: ,
			// MAPING_ID: ,
			// REFERENCE_NUMBER: ,
			// VENDOR_SITE_ID: ,
			// COST_CENTER: ,
			CURRENCY_CODE: selectedPo.value[0].currency_code,
			VENDOR_SITE_ID: selectedPo.value[0].vendor_site_id,
			DB_INSTANCE: poOu.value.db_instance,
		},
		details: [],
	};

	// Add detail payload
	const details = selectedPo.value.map((item) => {
		return {
			// EINVOICE_ID: ,
			PO_HEADER_ID: item.po_header_id,
			PO_LINE_ID: item.po_line_id,
			PO_NUMBER: item.po_number,
			LINE_NUM: item.line_num,
			ITEM_ID: item.item_id,
			ITEM_NUMBER: item.item_number,
			ITEM_DESCRIPTION: item.item_description,
			UNIT_MEAS_LOOKUP_CODE: item.unit_meas_lookup_code,
			UNIT_PRICE: item.unit_price,
			PRICE_OVERRIDE: item.price_override,
			// UNIT_PRICE_SUP: ,
			QUANTITY: item.quantity_received,
			QUANTITY_RECEIVED: item.quantity_received,
			AMOUNT: item.total_dpp,
			// NOTE_TO_VENDOR: ,
			ORGANIZATION_ID: item.org_id,
			LINE_LOCATION_ID: item.line_location_id,
			VAT_CODE: item.vat_code ? item.vat_code : null,
			WHT_CODE: item.wht_code ? item.wht_code.WHT_CODE : null,
			VAT_CODE_ID: item.vat_code_id ? item.vat_code_id : null,
			PERCENTAGE_RATE: item.percentage_rate ? item.percentage_rate : null,
			RECEIPT_NUM: item.receipt_num,
			PACKING_SLIP: item.packing_slip === 'NA' ? null : item.packing_slip,
			DISTRIBUTION_NUM: item.distribution_num,
			PO_DISTRIBUTION_ID: item.po_distribution_id,
			SHIPMENT_NUM: item.shipment_num,
			CREATION_DATE: formattingDate(new Date(), true),
			CREATED_BY: currentUser.value.id,
			LAST_UPDATE_DATE: formattingDate(new Date(), true),
			LAST_UPDATED_BY: currentUser.value.id,
			// REMARKS: item,
			// ATTRIBUTE1: item,
			CURRENCY_CODE: item.currency_code,
			REVISION_NUM: item.revision_num,
			VENDOR_ID: item.vendor_id,
			VENDOR_SITE_ID: item.vendor_site_id,
			SHIPMENT_LINE_ID: item.shipment_line_id,
			SHIPMENT_HEADER_ID: item.shipment_header_id,
			RCV_TRANSACTION_ID: item.transaction_id,
			// CATEGORY_DETAIL_ID:,
			// WHT_CODE: item,
			// COA_ID: item,
			// VAT_CODE_ID: item,
			// WHT_CODE_ID: item,
			// GROUP_NAME: item,
		};
	});
	payload.details = details;

	// Wrap body payload with formData
	const jsonPayload = JSON.stringify(payload);
	formData.append('payload', jsonPayload);

	try {
		progress.value = true;

		const res = await PendingPOService.saveInvoiceStandart(formData);

		if (res.status === 200) {
			progress.value = false;
			invoiceId.value = res.data.data.einvoice_id;
			invoiceNo.value = res.data.data.invoice_no;
			status.value = 'DRAFT';
			file.value = null;
			fileUpload.value.clear();
			headerId.value = res.data.data.einvoice_id

			getAttachmentFile(invoiceId.value);
			// await getFileBase64(invoiceId.value);

			toast.add({
				severity: 'success',
				summary: 'Successfully save new invoice',
				life: 3000,
			});

			setTimeout(() => {
				router.push({
					name: 'CreateInvoicePO',
					params: {
						id: headerId.value
					},
					query: {
						v: poOu.value.value,
						i: poOu.value.db_instance
					}
				});
			}, 3000)

			return {
				status: 200,
				message: 'success'
			}
		}
		progress.value = false;
	} catch (err) {
		console.log(err);
		progress.value = false;
		if (err.response.status === 400) {
			if (err.response.data.message.includes('exist')) {
				inputError.value.push({
					name: 'invoiceNo',
					msg: err.response.data.message,
				});
			}
			toast.add({
				severity: 'error',
				summary: err.response.data.message,
				life: 3000,
			});
			return {
				status: 400,
				message: 'failed'
			}
		}
		toast.add({
			severity: 'error',
			summary: 'Server error: Unsuccessfully save invoice',
			life: 3000,
		});

		return {
			status: 400,
			message: 'failed'
		}
	}
};

// Update invoice
const handleUpdate = async () => {
	const isError = await validation();

	if (isError) {
		return;
	}

	// Add file payload
	const formData = new FormData();
	formData.append('file', file.value);

	// Add body payload
	const payload = {
		header: {
			EINVOICE_ID: parseInt(props.id),
			INVOICE_NO: invoiceNo.value,
			INVOICE_DATE: formattingDate(invoiceDate.value),
			REVISION_NUM: 0,
			TAX_NO: taxInvoiceNo.value,
			TAX_DATE: formattingDate(taxInvoiceDate.value),
			DESCRIPTION: description.value,
			INVOICE_AMOUNT: totalDPP.value,
			TAX_AMOUNT: totalPPN.value,
			TOTAL_AMOUNT: summary.value.totalInvoice,
			SERVICE_AMOUNT: summary.value.totalPph,
			LAST_UPDATE_DATE: formattingDate(new Date(), true),
			LAST_UPDATED_BY: currentUser.value.id,
			EXTERNAL_BANK_ACCOUNT_ID: bank.value.id,
			VENDOR_ID: vendorId.value,
		},
		newDetails: [],
		removedDetails: [],
		countDetails: selectedPo.value.length,
	};

	// Add new details payload
	payload.newDetails = selectedPo.value
		.filter(
			(item) =>
				!savedInvoiceDetails.value.some(
					(val) => val.transaction_id === item.id
				)
		)
		.map((item) => {
			return {
				EINVOICE_ID: parseInt(props.id),
				PO_HEADER_ID: item.po_header_id,
				PO_LINE_ID: item.po_line_id,
				PO_NUMBER: item.po_number,
				LINE_NUM: item.line_num,
				ITEM_ID: item.item_id,
				ITEM_NUMBER: item.item_number,
				ITEM_DESCRIPTION: item.item_description,
				UNIT_MEAS_LOOKUP_CODE: item.unit_meas_lookup_code,
				UNIT_PRICE: item.unit_price,
				QUANTITY: item.quantity_received,
				AMOUNT: item.total_dpp,
				ORGANIZATION_ID: item.org_id,
				LINE_LOCATION_ID: item.line_location_id,
				VAT_CODE: item.vat_code ? item.vat_code : null,
				VAT_CODE_ID: item.vat_code_id ? item.vat_code_id : null,
				PERCENTAGE_RATE: item.percentage_rate ? item.percentage_rate : null,
				RECEIPT_NUM: item.receipt_num,
				PACKING_SLIP:
					item.packing_slip === 'NA' ? null : item.packing_slip,
				DISTRIBUTION_NUM: item.distribution_num,
				PO_DISTRIBUTION_ID: item.po_distribution_id,
				SHIPMENT_NUM: item.shipment_num,
				CREATION_DATE: formattingDate(new Date(), true),
				CREATED_BY: currentUser.value.id,
				LAST_UPDATE_DATE: formattingDate(new Date(), true),
				LAST_UPDATED_BY: currentUser.value.id,
				CURRENCY_CODE: item.currency_code,
				REVISION_NUM: item.revision_num,
				VENDOR_ID: item.vendor_id,
				VENDOR_SITE_ID: item.vendor_site_id,
				SHIPMENT_LINE_ID: item.shipment_line_id,
				SHIPMENT_HEADER_ID: item.shipment_header_id,
				RCV_TRANSACTION_ID: item.transaction_id,
			};
		});

	// Add payload removed details
	payload.removedDetails = savedInvoiceDetails.value
		.filter(
			(item) =>
				!selectedPo.value.some((val) => val.id === item.transaction_id)
		)
		.map((item) => ({
			ITEM_DESCRIPTION: item.item_description,
			EINVOICE_LINE_ID: item.einvoice_line_id,
			TRANSACTION_ID: item.transaction_id,
		}));

	// Deleted payload
	if (deletedFile.value) {
		payload.deletedFile = deletedFile.value;
	} else {
		payload.deletedFile = savedFile.value;
	}

	// Wrap body payload with formData
	const jsonPayload = JSON.stringify(payload);
	formData.append('payload', jsonPayload);

	try {
		progress.value = true;
		const res = await InvoicePOService.updateInvoice(props.id, formData);

		if (res.status === 200) {
			fileUpload.value.clear();
			file.value = null;
			deletedFile.value = null;

			// Re-assign saved invoice details
			await getInvoiceDetail(props.id);

			getAttachmentFile(props.id);
			// await getFileBase64(props.id);

			toast.add({
				severity: 'success',
				summary: 'Successfully edit invoice',
				life: 3000,
			});

			return {
				status: 200,
				message: 'failed'
			}
		}
		progress.value = false;
	} catch (err) {
		console.log(err);
		progress.value = false;

		if (err.response.status === 400) {
			if (err.response.data.message.includes('exist')) {
				inputError.value.push({
					name: 'invoiceNo',
					msg: err.response.data.message,
				});
			}

			toast.add({
				severity: 'error',
				summary: err.response.data.message,
				life: 3000,
			});
			return {
				status: 400,
				message: 'failed'
			}
		}

		toast.add({
			severity: 'error',
			summary: err.response.data.message ?? 'Server error: Unsuccessfully save invoice',
			life: 3000,
		});

		return {
			status: 400,
			message: 'failed'
		}
	}
};

// Add invoice to batch
const handleAddToBatch = async (batchId) => {
	const payload = {
		batch_id: batchId,
		einvoice_id: invoiceId.value,
		amount: summary.value.totalInvoice,
		creation_date: formattingDate(new Date(), true),
		created_by: currentUser.value.id,
		last_update_date: formattingDate(new Date(), true),
		last_updated_by: currentUser.value.id,
	};

	try {
		progress.value = true;
		const res = await InvoicePOService.addToBatch(payload);

		if (res.status === 201) {
			displayModalBatch.value = false;
			status.value = 'OPEN';
			toast.add({
				severity: 'success',
				summary: 'Successfully add invoice to batch',
				life: 3000,
			});
		}
		progress.value = false;
	} catch (err) {
		console.log(err);
		progress.value = false;
		toast.add({
			severity: 'error',
			summary: 'Server error: Unsuccessfully add to batch',
			life: 3000,
		});
	}
};

// Reset form invoice
const handleReset = () => {
	invoiceNo.value = null;
	invoiceDate.value = null;
	description.value = null;
	bank.value = null;
	taxInvoiceNo.value = null;
	taxInvoiceDate.value = null;
	file.value = null;
	isPphChecked.value = null;
	pphValue.value = null;
	selectedPo.value = [];
	inputError.value = [];
	status.value = null;
	invoiceId.value = null;

	fileUpload.value.clear();
};


const handleChangeTax = (event) => {
	const {value} = event.target;
	taxInvoiceNo.value = value.replace(/[a-zA-Z]/g,"").substring(0,20);
}


const onDownload = async () => {
	try {
		loadingDownladFile.value = true;

		const leveltenant = sessionStorage.getItem('leveltenant');
		let id = props.id;
		if (!id) {
			id = invoiceId.value;
		}

		const res = await InvoicePOService.getFileBase64(
			id,
			leveltenant,
			vendorId.value
		);

		if (res.status === 200) {
			fileBuffer.value = res.data.data;
		}
	} catch (err) {
		loadingDownladFile.value = false;
		toast.add({
			severity: 'error',
			summary: 'Connection Unstable !',
			life: 3000,
		});
		throw Error('Error downloading file');
	}
	const linkSource = `data:application/pdf;base64,${fileBuffer.value}`;
	const downloadLink = document.createElement('a');
	const fileName = savedFile.value.filename;
	downloadLink.href = linkSource;
	downloadLink.download = fileName;
	downloadLink.click();
	loadingDownladFile.value = false;
};


const onChangeVAT = async (event, index) => {


	//----VALIDATION FROM----
	const splitz = event.value.VAT_CODE.split(" ")
	let rule = new Array();
	if (splitz.includes("EXCL")) {
		selectedPo.value.forEach((item) => {
			const sp = typeof (item.vat_code) === 'string' ?
				item.vat_code.split(" ") : []
			if (sp.includes("INCL") === true) {
				rule.push(item);
				const vatData = getVAT.value.find((Q) => Q.VAT_CODE === selectedPo.value[index].vat_current);

				selectedPo.value[index].percentage_rate = vatData.PERCENTAGE_RATE;
				selectedPo.value[index].vat_code = vatData.VAT_CODE;
				selectedPo.value[index].vat_code_id = vatData.TAX_RATE_ID;
				selectedPo.value[index].tax_rate_code = vatData.TAX_RATE_CODE;
				return;
			}
		});

		if (rule.length > 0) {

			return
		}
	} else if (splitz.includes("INCL")) {
		selectedPo.value.forEach((item) => {
			const sp = typeof (item.vat_code) === 'string' ?
				item.vat_code.split(" ") : item.vat_code.VAT_CODE.split(" ")
			const isTrue = item.vat_code !== null && sp.includes("INCL") == false;
			if (isTrue) {
				rule.push(item);
				console.log(selectedPo.value[index].vat_current, "VAT CURRENT")
				// const vatData = getVAT.value.find((item) => item.TAX_RATE_ID === selectedPo.value[index].vat_current);
				const vatData = getVAT.value.find((Q) => Q.VAT_CODE === selectedPo.value[index].vat_current);
				selectedPo.value[index].percentage_rate = vatData.PERCENTAGE_RATE;
				selectedPo.value[index].vat_code = vatData.VAT_CODE;
				selectedPo.value[index].vat_code_id = vatData.TAX_RATE_ID;
				selectedPo.value[index].tax_rate_code = vatData.TAX_RATE_CODE;
				return;
			}
		});


		if (rule.length > 0) {
			toast.add({
				severity: 'error',
				summary: 'Cannot choose different type of VAT Code',
				life: 3000,
			});
			return
		}
	}
	//---VALIDATION END---




	if (
		selectedPo.value[index].total_dpp === undefined
	) {
		selectedPo.value[index].vat_code = null;
	} else {
		const vatData = getVAT.value.find((item) => item.TAX_RATE_ID === event.value.TAX_RATE_ID);

		if (vatData) {
			selectedPo.value[index].percentage_rate = vatData.PERCENTAGE_RATE;
			selectedPo.value[index].vat_code = vatData.VAT_CODE;
			selectedPo.value[index].vat_code_id = vatData.TAX_RATE_ID;
			selectedPo.value[index].tax_rate_code = vatData.TAX_RATE_CODE;
			selectedPo.value[index].change = true;
			await getTotalPPN();
		}
	}
};

const onChangeVATValue = async (index) => {

	selectedPo.value[index].percentage_rate = null;
	selectedPo.value[index].vat_code = null;
	selectedPo.value[index].vat_code_id = null;
	selectedPo.value[index].tax_rate_code = null;
	selectedPo.value[index].change = true;
	await getTotalPPN();

};

const onChangeWHTValue = async (event, index) => {
	selectedPo.value[index].wht_code = null;
};


const searchVAT = (event) => {
	if (!event.query.trim().length) {
		filteredVAT.value = [...getVAT.value]
	} else {
		filteredVAT.value = getVAT.value.filter((val) => val.VAT_CODE.toLowerCase().includes(event.query.toLowerCase()));
	}
}

const searchWHT = (event) => {
	if (!event.query.trim().length) {
		filteredWHT.value = [...getWHT.value]
	} else {
		filteredWHT.value = getWHT.value.filter((val) => val.WHT_CODE.toLowerCase().includes(event.query.toLowerCase()));
	}
}

const onChangeWHT = async (event, index) => {
	if (
		selectedPo.value[index].AMOUNT === undefined ||
		selectedPo.value[index].AMOUNT === null
	) {
		selectedPo.value[index].WHT_CODE = null;
	} else {
		let whtData = getWHT.value.find((item) => item.WHT_CODE_ID === event.value.WHT_CODE_ID);
		if (whtData) {
			selectedPo.value[index].wht_code = whtData.WHT_CODE;
		}
	}
};


const getDropdownVat = async () => {
	const paramRet = 'RATE';
	const payload = {
		paramRet: paramRet,
		dbInstance: sessionStorage.companydb,
		invoiceDate: invoiceDate.value,
	};
	const result = await InvoiceNonPOservice.getListVat(payload);
	const noSelect = {
		PARAM_RET: "",
		PERCENTAGE_RATE: 0,
		TAX_RATE_ID: 0,
		TAX_RATE_CODE: null,
		EFFECTIVE_FROM: "1998-12-31T17:00:00.000Z",
		EFFECTIVE_TO: null,
		ACTIVE_FLAG: "Y",
		ATTRIBUTE1: "SUPPLIER PORTAL",
		VAT_CODE: "- No Select -"
	}

	getVAT.value = [noSelect, ...result.data.data];


	selectedPo.value.forEach((item) => {
		const getValue = getVAT.value.find((Q) => Q.TAX_RATE_CODE === item.vat_code || Q.VAT_CODE === item.vat_code);
		console.log(getValue, getVAT.value, item.vat_code, "INI YA")
		item.vat_code = getValue ? getValue.VAT_CODE : null
		item.vat_code_id = getValue ? getValue.TAX_RATE_ID : null
		item.percentage_rate = getValue ? getValue.PERCENTAGE_RATE : null
		item.vat_current = getValue ? getValue.VAT_CODE : null
	})

};

const handlePrintCreateInvoice = () => {
	inputError.value = [];

	// Validation required input field before print
	if (
		!company.value ||
		!invoiceNo.value ||
		!invoiceDate.value ||
		!description.value ||
		!bank.value ||
		!taxInvoiceNo.value ||
		!taxInvoiceDate.value
	) {
		toast.add({
			severity: 'error',
			summary: 'Please fill all required fields first',
			life: 3000,
		});

		if (!company.value) {
			inputError.value.push({
				name: 'company',
				msg: 'This field is required',
			});
		}
		if (!invoiceNo.value) {
			inputError.value.push({
				name: 'invoiceNo',
				msg: 'This field is required',
			});
		}
		if (!invoiceDate.value) {
			inputError.value.push({
				name: 'invoiceDate',
				msg: 'This field is required',
			});
		}
		if (!description.value) {
			inputError.value.push({
				name: 'desc',
				msg: 'This field is required',
			});
		}
		if (!bank.value) {
			inputError.value.push({
				name: 'bank',
				msg: 'This field is required',
			});
		}
		if (!taxInvoiceNo.value) {
			inputError.value.push({
				name: 'taxInvoiceNo',
				msg: 'This field is required',
			});
		}
		if (!taxInvoiceDate.value) {
			inputError.value.push({
				name: 'taxInvoiceDate',
				msg: 'This field is required',
			});
		}

		return;
	}

	// Validation select PO
	if (!selectedPo.value.length) {
		toast.add({
			severity: 'error',
			summary: 'Please select a PO first',
			life: 3000,
		});
		return;
	}

	let url = UrlTab() + '/printcreateinvoice'

	const payload = {
		org_id: company.value.value,
		db_instance: company.value.db_instance,
		vendor_id: vendorId.value,
		invoice_no: invoiceNo.value,
		details: [],
	};

	payload.details = selectedPo.value.map((item) => ({
		receipt_num: item.receipt_num,
		receipt_date: item.receipt_date,
		po_number: item.po_number,
		line_num: item.line_num,
		item_number: item.item_number,
		item_description: item.item_description,
		qty: item.quantity_received,
		uom: item.unit_meas_lookup_code,
	}));

	sessionStorage.setItem('paramscreateinvoice', JSON.stringify(payload));
	window.open(url, 'Print', 'height=720,width=1280');
};

const handleSubmit = async () => {
	try {
		const isError = await validation();

		if (isError) {
			return;
		}


		if (!status.value) {
			const resp = await handleSave();
			if (resp.status !== 200) return;
		} else {
			const resp = await handleUpdate();
			if (resp.status !== 200) return;
		}
		console.log(headerId.value, "HEADER ID VALUE")

		const result = await PendingPOService.submitInvoice({
			id: headerId.value ? headerId.value : Number(route.params.id),
			user_id: Number(sessionStorage.idUser),
			companyname: company.value.name,
			vendorname: vendorName.value,
			invoice_no: invoiceNo.value,
			invoice_type: InvoiceTypeEnum.Standard,
			prepayment_flag: null,
			vendorId: vendorId.value,
			org_id: company.value.value,
			db_instance: company.value.db_instance
		});

		if (result.status === 200) {

			await getInvoiceDetail(headerId.value ? headerId.value : route.params.id);
			toast.add({
				severity: 'success',
				summary: 'Success to submit Invoice',
				life: 3000,
			});


			Swal.fire({
				title: "Success To Submit Invoice",
				text: "Please send invoice to dexa immediately!",
				icon: "success"
			});


			return;

		}

	} catch (error) {
		toast.add({
			severity: 'error',
			summary: 'Failed to submit Invoice',
			life: 3000,
		});
	}
}
</script>

<style scoped>
.form-card {
	padding: 24px;
	border: 1px solid #e0e0e0;
	border-radius: 16px;
}

input.statusInvoice,
input#disabledCompany {
	color: #000 !important;
}
</style>
