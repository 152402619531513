/* eslint-disable */
import { encryptForm } from '../helper/encryptForm';
import spbinvoice from './api/SpbInvoiceApi';

class BASTService {
	getHeaderList(payload) {
		return spbinvoice.post(`/spb/bast/getponumber`, payload);
	}

	getDetailPo(payload) {
		return spbinvoice.post(`/spb/bast/getdetailponumber`, payload);
	}

	getDetailBAST(payload) {
		return spbinvoice.post(`/spb/bast/getbastdetaildata`, payload);
	}

	checkBastNum(payload){
		return spbinvoice.post(`/spb/bast/checkbastnum`, payload);
	}

	saveBAST(payload){
		return spbinvoice.post(`/spb/bast/savebast`, payload);
	}

	getListBAST(payload){
		return spbinvoice.post(`/spb/bast/listbast`, encryptForm(payload));
	}

	getBASTDetail(payload){
		return spbinvoice.post(`/spb/bast/getbastdetail`, payload);
	}

	getBASTDetailList(payload){
		return spbinvoice.post(`/spb/bast/getdetailbastlist`, payload);
	}

	editBAST(payload){
		return spbinvoice.post(`/spb/bast/editbast`, payload);
	}
	
	getBASTPoDetail(payload){
		return spbinvoice.post(`/spb/bast/getbastpodetail`, payload);
	}

	getBASTFileDetail(payload){
		return spbinvoice.post(`/spb/bast/getbastfiledetail`, payload);
	}

	submitBAST(payload){
		return spbinvoice.post(`/spb/bast/submitbast`, payload);
	}

	cancelBAST(payload){
		return spbinvoice.post(`/spb/bast/cancelbast`, payload);
	}

	picConfirmBAST(payload){
		return spbinvoice.post(`/spb/bast/picconfirmbast`, payload);
	}

	picRejectBAST(payload){
		return spbinvoice.post(`/spb/bast/picrejectbast`, payload);
	}

	getListBastNo(payload){
		return spbinvoice.post(`/spb/bast/listbastno`, payload);
	}
	
	getDetailCompany(payload){
		return spbinvoice.post(`/spb/bast/getcompanydetail`, payload);
	}

	uploadFile(payload){
		return spbinvoice.post(`/spb/bast/uploadfilebast`, payload);
	}

	getRoleUser(payload){
		return spbinvoice.post(`/spb/bast/getroleuser`, payload);
	}

}
export default new BASTService();
