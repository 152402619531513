<template>
    <span class="p-float-label">
        <AutoComplete :suggestions="filteredBank" @complete="searchBatch($event)" :dropdown="true" field="TRANSFER_TO" v-model="operating" :disabled="props.disabled" forceSelection>
        <template #item="slotProp">
        {{ slotProp.item.TRANSFER_TO }}
        </template>
    </AutoComplete>
        <label for="autocomplete">Operating</label>
    </span>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import AuthService from '../../../service/AuthService';


// eslint-disable-next-line no-undef
const props = defineProps({
  id: {type: Object},
  disabled: {type: Object}
})

// eslint-disable-next-line no-undef
const operating = ref();

onMounted(() => {
  populateData()
})

// watchEffect(() => 
//   emit('remit',remitBankValue)
// )

const populateData = () => {
  AuthService.whoAmi().then(async (response) => {
    console.log(response)
    
    
  })
}


// const searchBatch = (event) => {
//   setTimeout(() => {
//     if (!event.query.trim().length) {
//       filteredBank.value = [...remitBank.value]
//     } else {
//       filteredBank.value = remitBank.value.filter((comp) => {
//         return comp.TRANSFER_TO.toLowerCase().match(event.query.toLowerCase());
//       })
//     }
//   }, 250);
// }
</script>

<style scoped>

</style>