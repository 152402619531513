import spbKkt from './api/SpbKktApi';
import packageJson from '../../package.json';
import { UrlTab } from '../constants/UrlTab';
class AuthService {
    authKey = false;
    isLogin = false;
    whoAmi() {
        try {
            let fe_version = packageJson.version
            console.log('fe_version before', fe_version)
            let url = UrlTab() + "/login"
            // if(fe_version !== sessionStorage.getItem('fe-version')){
            //     console.log('fe_version after', fe_version)
            //     sessionStorage.setItem('fe-version', fe_version)
            //     return spbKkt.get('adm/auth/who/' + fe_version);
            // } 
            const res = spbKkt.get('adm/auth/who').then((result) => {
                try {
                    
                    console.log('result ::', result)
                    console.log('result version ::', result.data.data.version)
                    console.log('package json version ::', packageJson.version)
                    if (result.status === 200 && result.data.data.version === packageJson.version) {
                        console.log('is true')
                        return result;
                    } else {
                        alert('Session expired')
                        window.location.replace(url)
                        window.location.reload(true);
                    }

                } catch (error) {
                    if(this.isLogin){
                        console.log("Error from auth catch", error)
                        this.isLogin = false
                        alert('Session expired')
                        window.location.replace(url)
                        window.location.reload(true);
                    }
                    
                }
                
                
            }).catch(error =>{
                console.log(this.isLogin)
                if(this.isLogin){
                    console.log("Error from auth a", error)
                    this.isLogin = false
                    alert('Session expired')
                    window.location.replace(`${url}/#/login`)
                    window.location.reload(true);
                }
            });
            return res;

        } catch (e) {
            console.log("Error from auth", e)
            this.isLogin = false
            window.location.reload(true)
        }
    }

	setAuthKey(key) {
		this.authKey = key;
	}

    setIsLogin(status){
        this.isLogin = status;
    }

    getAuthKey(){
        return sessionStorage.authKey;
    }
    async signOutApp(payload) {
        try {
            return await spbKkt.post("adm/auth/signout", payload);
        } catch (e) {
          console.log("Error AuthService.js", e);
        }
      }

	loggedOut() {
		console.log('Logged Out');
		localStorage.clear;
		this.authKey = false;
	}
	apake() {
		return spbKkt.get('/');
	}
}
export default new AuthService();
