<template>
	<AutoComplete
		:suggestions="filteredInvoiceNum"
		@complete="searchInvoice($event)"
		:dropdown="true"
		field="INVOICE_NUM"
		placeholder="invoice To"
		forceSelection
	>
		<template #item="slotProp">
			{{ slotProp.item.INVOICE_NUM }}
		</template>
	</AutoComplete>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import AuthService from '../../../service/AuthService';
import paymentService from '../../../service/ReportPayment';

// eslint-disable-next-line no-undef
const filteredInvoiceNum = ref([]);
const invoiceTo = ref([]);
// eslint-disable-next-line no-undef
const props = defineProps({
	company: { type: Object },
	supplier: { type: Object },
});

onMounted(() => {
	populateData();
	console.log('props invoice to', props);
});

const populateData = () => {
	AuthService.whoAmi().then(async () => {
		let payload;
		if (props.company !== null && props.supplier !== null) {
			payload = {
				org_id: props.company.value,
				suppVal: props.supplier.vendor_id,
				db_instance: props.company.db_instance,
			};
			console.log('payload poNumber to', payload);
			const data = await paymentService.getInvoiceNum(payload);
			invoiceTo.value = data.data.data.data;
			console.log('isi data invoice to', invoiceTo.value);
		} else if (props.company !== null) {
			payload = {
				org_id: props.company.value,
				db_instance: props.company.db_instance,
			};
			console.log('payload invoice to', payload);
			const data = await paymentService.getInvoiceNumComp(payload);
			console.log('isi data invoice to org_id', data);
			invoiceTo.value = data.data.data.data;
			console.log('isi data invoice to', invoiceTo.value);
		}
	});
};

const searchInvoice = (event) => {
	setTimeout(() => {
		if (!event.query.trim().length) {
			//
			//
			filteredInvoiceNum.value = [...invoiceTo.value];
		} else {
			filteredInvoiceNum.value = invoiceTo.value.filter((comp) => {
				return comp.INVOICE_NUM.toLowerCase().match(
					event.query.toLowerCase()
				);
			});
		}
	}, 250);
};
</script>
<style scoped></style>
