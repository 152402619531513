<template>
	<div class="grid">
		<div class="col-12 p-fluid">
			<div class="card">
				<div class="grid formgrid" style="margin: 1em 0 0 0">
					<div class="col-12 mb-5 lg:col-2 lg:mb-0">
						<span class="p-float-label">
							<AutoComplete
								v-model="company"
								:suggestions="filteredCompany"
								@complete="searchCompany($event)"
								:dropdown="true"
								field="name"
								forceSelection
							>
								<template #item="slotProps">
									<div class="supplier-item">
										<div class="ml-2">
											{{ slotProps.item.name }}
										</div>
									</div>
								</template>
							</AutoComplete>
							<label for="inputtext">Company</label>
						</span>
					</div>
					<div
						v-if="!isSupplierPage"
						class="col-12 mb-5 lg:col-2 lg:mb-0"
					>
						<span class="p-float-label">
							<AutoComplete
								v-model="supplier"
								:suggestions="filteredSupplier"
								@complete="searchSupplier($event)"
								:dropdown="true"
								field="vendor_name"
								:disabled="companyValue"
								forceSelection
							>
								<template #item="slotProps">
									<div class="supplier-item">
										<div class="ml-2">
											{{ slotProps.item.vendor_name }}
										</div>
									</div>
								</template>
							</AutoComplete>
							<label for="inputtext">Supplier</label>
						</span>
					</div>
					<div class="col-12 mb-5 lg:col-2 lg:mb-0">
						<span class="p-float-label">
							<AutoComplete
								v-model="planningOrder"
								:suggestions="filteredPlanningOrder"
								@complete="searchPoNumber($event)"
								field="plan_order_num"
								forceSelection
							>
							</AutoComplete>
							<label for="inputtext">Planning Order No</label>
						</span>
					</div>
					<div class="col-12 mb-2 lg:col-1 lg:mb-0">
						<Button
							label="Go"
							:disabled="this.buttonDisable"
						></Button>
					</div>
					<div class="col-12 mb-2 lg:col-1 lg:mb-0">
						<Button
							class="p-button-secondary"
							label="Clear"
							@click="clearFilter()"
						></Button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="card">
		<DataTable
			:value="nested"
			:lazy="true"
			:paginator="true"
			:rows="rows"
			v-model:expandedRows="expandedRows"
			ref="dt"
			dataKey="po_num"
			:totalRecords="totalRecords"
			:loading="loading"
			@page="onPage($event)"
			@sort="onSort($event)"
			removableSort
			:rowsPerPageOptions="[10, 20, 30]"
			responsiveLayout="scroll"
			:v-model="first"
		>
			<template #empty> No Data Found </template>
			<Column :expander="true" headerStyle="width: 3rem" />
			<Column field="po_num" header="Planning Order No"></Column>
			<template #expansion="slotProps">
				<div class="orders-subtable">
					<DataTable
						:value="slotProps.data.children"
						dataKey="itemnum"
						removableSort
						responsiveLayout="scroll"
					>
						<template #empty> No Data Found </template>
						<Column header="Line" headerStyle="width:3em">
							<template #body="slotProps">
								{{ slotProps.index + 1 }}
							</template>
						</Column>
						<!-- <Column field="id" header="Line"  style="text-align: center"></Column> -->
						<Column
							field="itemnum"
							header="Item Number"
							style="width: 300px"
						></Column>
						<Column
							field="itemdesc"
							header="Item Description"
							style="width: 300px"
						></Column>
						<Column
							field="uom"
							header="UOM"
							style="width: 100px"
						></Column>
						<Column
							field="ship"
							header="Location"
							style="width: 180px"
						></Column>
						<Column
							field="currency"
							header="Currency"
							style="text-align: center; width: 100px"
						></Column>
						<Column
							field="currency"
							header="Unit Price"
							style="text-align: center; width: 200px"
						></Column>
						<Column
							field="cprice"
							header="Confirm Price"
							style="text-align: center; width: 200px"
						></Column>
						<Column
							field="cqty"
							header="Confirm Qty"
							style="text-align: center; width: 200px"
						></Column>
						<Column
							field="cdate"
							header="Confirm Arrival at Site"
							style="text-align: center; width: 200px"
						>
							<template #body="slotProps">
								{{
									new Date(
										slotProps.data.cdate
									).toLocaleString('id-ID', {
										day: 'numeric',
										month: 'short',
										year: 'numeric',
									})
								}}
							</template>
						</Column>
						<Column
							field="prnum"
							header="PR Number"
							style="text-align: center; width: 50px"
						></Column>
						<Column
							field="ponum"
							header="PO Number"
							style="text-align: center; width: 200px"
						></Column>
						<Column
							field="status"
							header="Line Status"
							style="text-align: center; width: 200px"
						></Column>
					</DataTable>
				</div>
			</template>
		</DataTable>
	</div>
</template>

<script>
/* eslint-disable */
import PlanningOrder from '../../service/PlanningOrderService';
import AuthService from '../../service/AuthService';
import LovService from '../../service/LovService';
import SimpleCrypto from 'simple-crypto-js';
export default {
	name: 'planningorder-list',
	data() {
		return {
			expandedRowGroups: null,
			isSupplierPage: false,
			first: 0,
			rowId: null,
			expandedRows: [],
			expandedRowGroups: [],
			nested: [],
			rows: 10,
			nestedchildren: [],
			groupdata: null,
			selectedChildren: null,
			loading: false,
			lazyParams: {},
			selectLazyParams: {},
			totalRecords: 0,
			totalRecordsGrup: 0,
			tabelexpand: null,
			company: null,
			companyValue: true,
			filteredCompany: null,
			isiCompany: null,
			supplier: null,
			isisupplier: null,
			filteredSupplier: null,
			selectedSupplier: null,
			supplierparse: null,
			search: null,
			filtered: null,
			preparer: null,
			isipreparer: null,
			filteredPreparer: null,
			preparerparse: null,
			selectedPreparer: null,
			lov: null,
			isilov: null,
			filteredLov: null,
			lovparse: null,
			selectedLov: null,
			planningOrder: null,
			isiPlanningOrder: null,
			filteredPlanningOrder: null,
			selectedPlanningOrder: null,
			button: 0,
			buttonDisable: true,
			buttonSearch: true,
			rowgroup: null,
			testname: null,
			onStorageUpdate: null,
			saveDb: null,
			preparerButton: true,
			suppBindToComp: [],
			// isSupplierPage:false,
			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [
				{ label: 'Planning Order', to: '/mgm/settings/planningorder' },
			],
		};
	},
	watch: {
		company(newQuestion) {
			if (this.company != null) {
				this.planningOrder = null;
				this.lov = null;
				this.preparer = null;
				this.companyValue = false;
				this.buttonDisable = false;
				this.preparerButton = false;
				if (
					typeof newQuestion === 'object' &&
					!Array.isArray(newQuestion) &&
					newQuestion !== null
				) {
					this.getValueCompany(newQuestion.name);
					this.getPlenno(newQuestion.name);
					this.companyValue = false;
					this.saveDb = this.company.db_instance;
					sessionStorage.setItem('companydb', this.saveDb);
					this.GetPreparer();
				}
			}
		},
		supplier(newQuestion) {
			if (
				this.planningOrder == null &&
				this.lov == null &&
				this.preparer == null
			) {
				this.lov = null;
				this.preparer = null;
				this.buttonSearch = false;

				if (
					typeof newQuestion === 'object' &&
					!Array.isArray(newQuestion) &&
					newQuestion !== null
				) {
					this.getValueSup(newQuestion.name);
					this.buttonDisable = false;
				} else {
					this.supplier = null;
				}
			} else if (this.supplier == undefined) {
				this.supplier = null;
			}
		},
		planningOrder(newQuestion) {
			if (this.supplier == null) {
				if (
					typeof newQuestion === 'object' &&
					!Array.isArray(newQuestion) &&
					newQuestion !== null
				) {
					// this.getValuePoNum(newQuestion.name);
					this.buttonDisable = false;
				}
			}
		},
		lov(newQuestion) {
			this.buttonDisable = false;
		},
		preparer(newQuestion) {
			this.buttonDisable = false;
		},
	},
	created() {
		this.getCompany();
		this.GetLOV();
		if (sessionStorage.leveltenant == 1) {
			if (sessionStorage.idUser == 2) {
				this.GetTableNested();
			} else {
			}
		}

		if (sessionStorage.leveltenant == 2) {
			let idUser = sessionStorage.getItem('idUser').toString();
			PlanningOrder.getSuppBindComp(idUser).then((response) => {
				this.suppBindToComp.push(response.data.data[0][0].vendorname);
			});
		}
		this.getPoNumber();
		this.testSession();

		if (sessionStorage.leveltenant == 2) {
			let idUser = sessionStorage.getItem('idUser').toString();
			PlanningOrder.getSuppBindComp(idUser).then((response) => {
				this.suppBindToComp.push(response.data.data[0][0].vendorname);
			});
		}
		this.getPoNumber();
		// this.getPlenno();
	},
	async mounted() {
		// CEK DULU TIPE USERNYA (purchasing/supplier/admin,dll)
		AuthService.whoAmi().then((resp) => {
			if (resp.status === 200) {
				this.getCompany();
				// this.GetPreparer();
				this.GetLOV();
				this.GetTableNested();
				this.getPoNumber();
				if (sessionStorage.getItem('leveltenant') == 2) {
					this.isSupplierPage = true;
				}
			}
		});

		this.loading = true;
		this.lazyParams = {
			first: 0,
			sortField: null,
			sortOrder: null,
			rows: this.rows,
		};
		this.selectLazyParams = {
			first: 0,
			sortField: null,
			sortOrder: null,
			rows: this.rows,
		};
		if (sessionStorage.companydb) {
			this.testname = sessionStorage.companydb;
		}
	},
	computed: {
		searchUser() {
			const searchLc = this.search.toLowerCase();
			return this.nested.filter((item) => {
				return item.po_num.toLowerCase().includes(searchLc);
			});
		},
	},
	methods: {
		GetTableNested() {
			this.rows = 10;
			this.loading = true;
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					setTimeout(() => {
						PlanningOrder.GetTableNested({
							lazyEvent: JSON.stringify(this.lazyParams),
						}).then((response) => {
							this.nested = response.data.nestedData;
							this.totalRecords = response.data.totalRecords;
							this.loading = false;
							for (let id of this.nested) {
								for (let index of id.children) {
									index.cdate = Date(
										index.cdate
									).toLocaleString('id-ID');
								}
							}
						});
					}, Math.random() * 1000 + 250);
				}
			});
		},

		// searchTableNested() {
		//     console.log("isi org)id", this.company)
		//     this.expandedRows = null;
		//     this.rows = 10;
		//     this.button = 1;
		//     this.loading = true;
		//     let supplierSearch = this.supplier
		//     if (this.supplier !== null) {
		//         supplierSearch = this.supplier.vendor_id
		//     }
		//     let poSearch = this.planningOrder
		//     if (this.planningOrder !== null) {
		//         poSearch = this.planningOrder.plan_order_num
		//     }
		//     let statusSearch = this.lov
		//     if (this.lov !== null) {
		//         statusSearch = this.lov.value
		//     }
		//     let preparerSearch = this.preparer
		//     if (this.preparer !== null) {
		//         preparerSearch = this.preparer.person_id
		//     }
		//     let org_id = this.company
		//     if (this.company !== null) {
		//         if (sessionStorage.leveltenant == 1) {
		//                 // console.log(this.company.name)
		//                 org_id = JSON.parse(this.company.value)
		//             }
		//             // else {
		//             //     org_id = this.company.id
		//             // }
		//         if (sessionStorage.leveltenant == 2) {
		//             org_id = this.company.value
		//         }
		//     }
		//     const payload = {
		//         supplierSearch,
		//         poSearch,
		//         statusSearch,
		//         preparerSearch,
		//         org_id
		//     }
		//     AuthService.whoAmi().then(value => {
		//         if (value.status === 200) {
		//             setTimeout(() => {
		//                 PlanningOrder.getSearchTableNested({
		//                         lazyEvent: JSON.stringify(this.selectLazyParams),
		//                         payload: JSON.stringify(payload)

		//                     })
		//                     .then(respond => {
		//                         console.log('isi nested',respond)
		//                         // this.nested = respond.data.nestedData;
		//                         // this.totalRecords = respond.data.totalRecords;
		//                         this.loading = false;
		//                         for (let id of this.nested){
		//                                for (let index of id.children){
		//                                index.cdate = Date(index.cdate).toLocaleString("id-ID")
		//                               // console.log("melatonin",index)
		//                             }
		//                         }
		//                     });
		//             }, Math.random() * 1000 + 250);
		//         }
		//     })
		// },
		getCompany() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					if (value.data.data.leveltenant == 1) {
						if (value.data.data.userid == 'dexaadmin') {
							PlanningOrder.getCompany().then((response) => {
								this.isiCompany = response.data.data;
								console.log('dawdsdaw', this.isiCompany);
							});
						} else {
							LovService.getcompanydb(value.data.data.bioaddress).then((value) => {
								this.isiCompany = value.data.data;
								try {
									const secretKey = 'some-unique-key';
									const simpleCrypto = new SimpleCrypto(
										secretKey
									);
									const decipherText = simpleCrypto.decrypt(
										sessionStorage.getItem(
											'dropdownCompany'
										)
									);
									this.isiCompany.forEach((element) => {
										if (element.name === decipherText) {
											this.company = element;
											// this.searchTableNested();
											throw 'Break';
										}
									});
								} catch (e) {
									if (e !== 'Break') throw e;
								}
							});
						}
					}
					if (value.data.data.leveltenant == 2) {
						LovService.getCompanyFromUsermapping(
							value.data.data.id
						).then((response) => {
							this.isiCompany = response.data.data[0];
							try {
								const secretKey = 'some-unique-key';
								const simpleCrypto = new SimpleCrypto(
									secretKey
								);
								const decipherText = simpleCrypto.decrypt(
									sessionStorage.getItem('dropdownCompany')
								);
								this.isiCompany.forEach((element) => {
									if (element.name === decipherText) {
										this.company = element;
										// this.searchTableNested();
										throw 'Break';
									}
								});
							} catch (e) {
								if (e !== 'Break') throw e;
							}
						});
					}
				}
			});
		},
		getValueCompany() {
			let payload = {
				db_instance: this.company.db_instance,
				org_id: this.company.value,
			};
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					LovService.getSupplierName(payload).then((response) => {
						this.isisupplier = response.data.data;
						if (sessionStorage.getItem('leveltenant') == 2) {
							try {
								this.isisupplier.forEach((element) => {
									if (
										element.vendor_name ===
											this.suppBindToComp[0].toString() &&
										element.vendor_name !== null
									) {
										this.supplier = element;
										// this.searchTableNested();
										throw 'Break';
									}
								});
								if (this.supplier === null) {
									this.$router.push('/dashboard');
									window.alert(
										'Connection Unstable, ID not Recognize'
									);
								}
							} catch (e) {
								if (e !== 'Break') throw e;
							}
						}
					});
				}
			});
		},
		async getPlenno() {
			const payload = { org_id: this.company.value };
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					PlanningOrder.getSearchDropdownComp(payload).then(
						(response) => {
							this.isiPlanningOrder = response.data.data;
						}
					);
				}
			});
		},
		getValueSup() {
			const payload = {
				supValue: this.supplier.vendor_id,
				org_id: this.company.value,
			};
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					PlanningOrder.getSearchDropdownSupp(payload).then(
						(response) => {
							this.isiPlanningOrder = response.data.data;
						}
					);
				}
			});
		},
		getPoNumber() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					PlanningOrder.getPoNumber().then((response) => {
						this.isiPlanningOrder = response.data.data;
					});
				}
			});
		},
		getValuePoNum() {
			const payload = { poValue: this.planningOrder.plan_order_num };
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					PlanningOrder.getSearchDropdownPoNum(payload).then(
						(response) => {
							this.isisupplier = response.data.data;
						}
					);
				}
			});
		},
		GetLOV() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					PlanningOrder.GetLovStatus().then((response) => {
						this.isilov = response.data.data;
					});
				}
			});
		},
		GetPreparer() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					let payload = { isi: sessionStorage.companydb };
					PlanningOrder.GetPreparer(payload).then((response) => {
						this.isipreparer = response.data.data;
					});
				}
			});
		},
		getPlanningOrder() {
			AuthService.whoAmi().then((value) => {
				if (value.status === 200) {
					PlanningOrder.getPlanningOrder().then((response) => {
						this.isipreparer = response.data.data;
					});
				}
			});
		},
		searchSupplier(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredSupplier = [...this.isisupplier];
				} else {
					this.filteredSupplier = this.isisupplier.filter((item) => {
						if (item.vendor_name !== null) {
							return item.vendor_name
								.toLowerCase()
								.match(event.query.toLowerCase());
						}
					});
				}
			}, 250);
		},
		async searchCompany(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredCompany = [...this.isiCompany];
				} else {
					this.filteredCompany = this.isiCompany.filter((item) => {
						return item.name
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		searchPoNumber(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredPlanningOrder = [...this.isiPlanningOrder];
				} else {
					this.filteredPlanningOrder = this.isiPlanningOrder.filter(
						(item) => {
							if (item.vendor_name !== null) {
								return item.plan_order_num
									.toLowerCase()
									.match(event.query.toLowerCase());
							}
						}
					);
				}
			}, 250);
		},
		searchLov(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredLov = [...this.isilov];
				} else {
					this.filteredLov = this.isilov.filter((item) => {
						return item.name
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		searchPreparer(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredPreparer = [...this.isipreparer];
				} else {
					this.filteredPreparer = this.isipreparer.filter((item) => {
						return item.user_name
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		onSort(event) {
			switch (true) {
				case this.supplier === null &&
					this.planningOrder === null &&
					this.lov === null &&
					this.preparer === null:
					this.lazyParams = event;
					this.GetTableNested();
					break;
				case this.company !== null ||
					this.supplier !== null ||
					this.planningOrder !== null ||
					this.lov !== null ||
					(this.preparer !== null && this.button === 1):
					this.selectLazyParams = event;
					// this.searchTableNested();
					break;
			}
		},
		editChildren(rowIndex) {
			this.rowId = rowIndex;
			if (sessionStorage.leveltenant == 2) {
				this.$router.push({
					name: 'planningOrderDetailSupp',
					params: { id: this.rowId.id },
				});
			} else {
				this.$router.push({
					name: 'planningOrderDetail',
					params: { id: this.rowId.id },
				});
			}
		},
		expandAll() {
			this.expandedRowGroups = this.rowgroup.filter(
				(p) => p.plan_order_num
			);
			this.expandedRows = this.nested.filter((p) => p.po_num);
			this.$toast.add({
				severity: 'success',
				summary: 'All Rows Expanded',
				life: 3000,
			});
		},
		testSession() {
			if (localStorage.companydb !== null) {
			}
		},
		onPage(event) {
			switch (true) {
				//nggak cari apa-apa
				case this.supplier === null &&
					this.planningOrder === null &&
					this.lov === null &&
					this.preparer === null &&
					this.button === 0:
					this.lazyParams = event;
					this.GetTableNested();
					break;
				//semuanya
				case this.company !== null ||
					this.supplier !== null ||
					this.planningOrder !== null ||
					this.lov !== null ||
					(this.preparer !== null && this.button === 1):
					this.selectLazyParams = event;
					// this.searchTableNested();
					break;
			}
		},
		clearFilter() {
			// this.buttonSearch = true;
			this.expandedRows = null;
			this.planningOrder = null;
			// this.searchTableNested();
			this.lov = null;
			this.preparer = null;
			// this.button = 0;
			this.companyValue = true;
			this.preparerButton = true;
			this.GetPreparer();
			this.GetLOV();
			// this.GetSupplier();
			this.getPoNumber();
		},
		editChildren(rowIndex) {
			this.rowId = rowIndex;
			if (sessionStorage.leveltenant == 2) {
				this.$router.push({
					name: 'planningOrderDetailSupp',
					params: { id: this.rowId.id },
				});
			} else {
				this.$router.push({
					name: 'planningOrderDetail',
					params: { id: this.rowId.id },
				});
			}
		},
	},
};
</script>
<style scoped lang="scss">
p-selectable-row p-highlight {
	background-color: rgba(0, 0, 0, 0.15) !important;
}
</style>
