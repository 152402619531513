<template>
<div class="grid">
        <div class="col-12">
            <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
        </div>

        <div class="col-12">

            <!-- search content -->
            <div class="content-wrapper mb-4">
                <div class="content-header">
                    SEARCH
                </div>

                <div class="content-body">
                    <div class="grid">
                        <div class="col-12 lg:col-3">
                            <AutoComplete 
                                :dropdown="true"
                                placeholder="Company" 
                                class="w-full"
                                v-model="companyName"
                                :disabled="true"
                            />
                        </div>
                        <div class="col-12 lg:col-3">
                            <div class="p-inputgroup">
                                <AutoComplete
                                    placeholder="PO Number" 
                                    :suggestions="filteredPo"
                                    @complete="searchPo($event)"
                                    field="po_number"
                                    v-model="poNumber"
                                    forceSelection
                                    class="w-full"
                                />
                            </div>
                        </div>
                        <div class="col-12 lg:col-3">
                            <AutoComplete 
                                :dropdown="true"
                                placeholder="PO Type" 
                                class="w-full"
                                field="name"
                                v-model="poType"
                                :suggestions="filteredPoType"
                                @complete="searchPoType($event)"
                                forceSelection
                            />
                        </div>

                        <div
							v-if="userInfo && userInfo.leveltenant === 1"
							class="col-12 lg:col-3"
						>
							<span class="p-float-label">
								<AutoComplete
									id="supplier"
									:disabled="!supplierList"
									v-model="supplier"
                                    class="w-full"
									:suggestions="filteredSupplier"
									@complete="searchSupplier($event)"
									:dropdown="true"
									field="name"
									forceSelection
								/>
								<label for="supplier">Supplier</label>
							</span>
						</div>
                        
                        <div class="col-12 lg:col-3">
                            <div class="grid">
                                <div class="col-12 lg:col-6">
                                    <Button class="p-button-primary w-full" :disabled="disableButton" @click="handleSearch('R')">
                                        <span class="font-bold text-center w-full">SEARCH</span>
                                    </Button>
                                </div>
                                <div class="col-12 lg:col-6">
                                    <Button class="p-button-secondary w-full text-center" @click="clear">
                                        <span class="font-bold text-center w-full">CLEAR</span>
                                    </Button>
                                </div>
                            </div>
                        </div>

                        
                    </div>
                </div>
                
                
            </div>

            <!-- table content -->
            <div class="content-wrapper">
                <div class="content-header">
                    Search Result
                </div>

                <div class="content-body">
                    <DataTable
                        :value="tableList"
                        dataKey="id"
                        :scrollable="true"
                        :rows="5"
                        :lazy="true"
                        :rowsPerPageOptions="[5, 10, 20, 50]"
                        :paginator="true"
                        scrollHeight="600px"
                        scrollDirection="both"
                        responsiveLayout="scroll"
                        ref="dt"
                        :loading="loadingTable"
                        :totalRecords="totalRecords"
                        @page="onPage($event)"
                        :sortable="true"
                        @sort="onSort($event)"
                    >
                        <Column header="PO Number" :sortable="true" field="po_number" style="width:15%;"/>
                        <Column header="Site Name" field="site_name" style="width:25%;"/>
                        <Column v-if="userInfo && userInfo.leveltenant === 1" header="Vendor Name" style="width: 20%;" field="vendor_name" />
                        <Column header="PO Type" field="po_type" style="width:25%;"/>
                        <Column header="Prepayment" field="prepayment_flag" style="width:25%;"/>
                        <Column header="Receipt Date" :sortable="true" field="receipt_date" style="width:20%;">
                            <template #body="{data}">
                                <span>{{ formatDate(data.receipt_date) }}</span>
                            </template>
                        </Column>
                        <Column
                            header="Actions"
                            style="width: 15%"
                            alignFrozen="right"
                            frozen
                        >
                            <template #body="{data}">
                                <Button
                                    icon="pi pi-eye"
                                    class="p-button-rounded p-button-text"
                                    @click="handleClick(data)"
                                />
                            </template>
                        </Column>

                        <template #empty>
                            Data Not Found
                        </template>
                    </DataTable>
                </div>
            </div>


        </div>
</div>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {formatDate} from "../../helper/formatDate.js"
import AuthService from '../../service/AuthService';
import LovService from '../../service/LovService';
import SimpleCrypto from 'simple-crypto-js';
import PendingPOService from '../../service/PendingPOService';
import { PoTypeEnum } from '../../constants/POTypeEnum';


const router = useRouter();
const route = useRoute();
const breadcrumbHome = ref({ icon: "pi pi-home", to: "/dashboard" });
const breadcrumbItems = ref([
    {
        label: "List Pending PO",
        to: route.fullPath,
    },
]);
const disableButton = ref(false);
const companyList = ref([]);
const companyName = ref();
const company = ref();
const userInfo = ref();
const supplier = ref(null);
const listPO = ref([]);
const poNumber = ref();
const filteredPo = ref();
const tableList = ref([]);
const loadingTable = ref(false);
const totalRecords = ref(null);
const lazyParams = ref(null);
const dt = ref(null);
const vendorIds = ref(null);
const supplierList = ref(null);
const filteredSupplier = ref(null);

const filteredPoType = ref([]);
const poTypeList = ref([
    {id:1, name: PoTypeEnum.Prepayment},
    {id:2, name: PoTypeEnum.Procurement},
    {id:3, name: PoTypeEnum.Standart}
])
const poType = ref(null);

onMounted(() => {
    lazyParams.value = {
		first: 0,
		page: 0,
		rows: dt.value.rows ? dt.value.rows : 5,
	};
})

const searchPoType = async (event) => {
    filteredPoType.value = poTypeList.value.filter((item) => item.name.toLowerCase().includes(event.query.toLowerCase()))
}

const searchPo = async (event) => {
    filteredPo.value = listPO.value.filter((item) => item.po_number.toLowerCase().includes(event.query.toLowerCase()))
}

watch(poType, (text) => {
    console.log(text, "PO TYPE")
})

const handleSearch = async (status) => {
    try {
        loadingTable.value = true;
        console.log(poNumber.value, lazyParams.value, supplier.value, "TEST")

        console.log(poType.value, 'PO TYPE VALUE')

        let poTypeName = null;

        if(poType.value){
            poTypeName = {...poType.value};
            poTypeName['name'] = `DEXA ${poType.value['name']}`;
        }

        let payload = {
            dbInstance: company.value.db_instance,
            orgId: company.value.value,
            poNumber: poNumber.value ? poNumber.value : null,
            limit: lazyParams.value ? lazyParams.value.rows : 5,
			page: status === 'R' ? 1 : lazyParams.value.page + 1,
            poTypeParam: poTypeName
        }

        if(userInfo.value.leveltenant === 2){
            payload.vendorId = vendorIds.value;
        }else{
            payload.vendorId = supplier.value ? supplier.value.value : null;
        }
        
        await getPoNum(
            company.value.db_instance,
            company.value.value,
            payload.vendorId
        )

        const resp = await PendingPOService.getListPendingPo(payload);
        tableList.value = resp.data.data
        totalRecords.value = resp.data.count
        loadingTable.value = false;
    } catch (error) {
        loadingTable.value = false;
        console.log(error)
    }
}

const onSort = (event) => {
    console.log(event, "EVENT")
}

const onPage = (event) => {
    console.log("PAGE", event)
	lazyParams.value = event;
	handleSearch();
};

const clear = async () => {
    try {
        loadingTable.value = true;
        poNumber.value = null;
        poType.value = null;
        supplier.value = null;
        let payload = {
            dbInstance: company.value.db_instance,
            orgId: company.value.value,
            vendorId: vendorIds.value,
            poNumber: poNumber.value ? poNumber.value : null,
            limit: lazyParams.value ? lazyParams.value.rows : 5,
			page: status === 'R' ? 1 : lazyParams.value.page + 1,
            poTypeParam: poType.value ? poType.value : null
        }

        await getPoNum(
            company.value.db_instance,
            company.value.value,
            vendorIds.value
        )

        const resp = await PendingPOService.getListPendingPo(payload);
        tableList.value = resp.data.data
        totalRecords.value = resp.data.count
        loadingTable.value = false;
    } catch (error) {
        alert("Failed to load data")
    }
}

AuthService.whoAmi().then(async (response) => {
        function userCompany() {
            const secretKey = 'some-unique-key';
            const simpleCrypto = new SimpleCrypto(secretKey);
            return simpleCrypto.decrypt(
                sessionStorage.getItem('dropdownCompany')
            );
        }

        if (response.status === 200) {
            userInfo.value = response.data.data;
            if (userInfo.value.leveltenant === 2) {
                disableButton.value = true;
                //get company
                const respCbN = await LovService.getCompanyByName(
                    userCompany()
                );
                company.value = respCbN.data.data[0];
                companyName.value = respCbN.data.data[0].name
                //get supplier
                const param = {
                    db_instance: company.value.db_instance,
                    org_id: company.value.value,
                };
                const respVendor = await LovService.getSuppBindComp(
                    response.data.data.id
                );
                // const vendorName = respVendor.data.data[0][0].vendorname;
                const vendorId = respVendor.data.data[0].vendor_id;
   
                vendorIds.value = vendorId;

                disableButton.value = false;

                const respSuppName = await LovService.getSupplierName(
                    param
                );
       
                if (respSuppName.status === 200) {
                    const suppList = respSuppName.data.data;
                    console.log(suppList, "TEST")
                    for (const s of suppList) {
                        console.log(s.vendor_id, vendorId)
                        if (s.vendor_id === vendorId) {
                            supplier.value = s;
                            break;
                        }
                    }

                    // if (supplier.value === null) {
                    //     router.push('/dashboard');
                    //     window.alert(
                    //         'Connection Unstable, ID not Recognize'
                    //     );
                    // }
                }

                await getPoNum(
                    company.value.db_instance,
                    company.value.value,
                    vendorIds.value
                )
                

            } else {
                const res = await LovService.getcompanydb(userInfo.value.bioaddress);
                companyList.value = res.data.data;
                console.log(companyList.value, "COMP VAL")
                const secretKey = "some-unique-key";
                const simpleCrypto = new SimpleCrypto(secretKey);
                const decipherText = simpleCrypto.decrypt(
                    sessionStorage.getItem("dropdownCompany")
                );

                companyList.value.forEach((element) => {
                    if (element.name === decipherText) {
                        company.value = element;
                    }
                });
           
                const respCbN = await LovService.getCompanyByName(
                    userCompany()
                );
                // await getVendorId();
                company.value = respCbN.data.data[0];
                companyName.value = respCbN.data.data[0].name
                loadingTable.value = false;

                const payload = {
                    db_instance: company.value.db_instance,
                    org_id: company.value.value
                }

			    const supp = await LovService.getSupplierName(payload);

                supplierList.value = supp.data.data.map((item) => {
                    return{
                        value: item.vendor_id,
                        name: item.vendor_name,
                        db_instance: item.db_instance
                    }
                });

                await getPoNum(
                    company.value.db_instance,
                    company.value.value,
                    null
                )
            }
            
            loadingTable.value = false;
        }
        loadingTable.value = false;
    });

const getPoNum = async (dbInstance, orgId, vendorId) => {

    const payload = {
        dbInstance: dbInstance,
        orgId: orgId,
        vendorId: vendorId,
    }

    const responseNum = await PendingPOService.getPONumberList(payload);
    console.log(responseNum, "NUM")
    listPO.value = await responseNum.data.data.map((item,index) => {return{id: index, ...item}})
}    

const handleClick = (value) => {
    const {po_type, po_number, prepayment_flag, receipt_date} = value;

    console.log(po_type, "PO TYOE")

    if(po_type === PoTypeEnum.Prepayment && prepayment_flag === "Y"){
        router.push({
            name: "CreateEinvoicePrepayment",
            params: {id: po_number}
        });
    }

    if(po_type === PoTypeEnum.Standart || (PoTypeEnum.Prepayment && prepayment_flag !== "Y" || !prepayment_flag)){
        router.push({
            name: "CreateEinvoiceStandart",
            params: {id: po_number}
        });
    }

    if(po_type === PoTypeEnum.Procurement){
        const dt = new Date(receipt_date).toLocaleString("en-US", { timeZone: "Asia/Jakarta" });
        const parsed = Date.parse(dt);
        router.push({
            name: "CreateEinvoiceProcurement",
            params: {id: po_number},
            query: {date: parsed}
        });
    }
}



const searchSupplier = (event) => {
	if (!event.query.trim().length) {
		filteredSupplier.value = [...supplierList.value];
	} else {
		filteredSupplier.value = supplierList.value.filter((item) => {
			return (
				item.name &&
				item.name.toLowerCase().match(event.query.toLowerCase())
			);
		});
	}
};

</script>

<style scoped>
    .p-chip.custom-chip {
        padding: 4px 12px;
        border-radius: 8px;
        font-size: 12px;
        font-weight: 600;
    }

    .chip-open {
        background: #0ea5e9;
        color: #fff;
    }
    .chip-rejected {
        background: var(--primary-color);
        color: #fff;
    }
    .chip-review {
        background: #f59e0b;
        color: #fff;
    }
    .chip-posted {
        background: #10b981;
        color: #fff;
    }
    .chip-processed {
        background: #2563eb;
        color: #fff;
    }
</style>