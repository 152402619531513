/* eslint-disable */
import spborder from "./api/SpbOrderApi";
import spbadmin from "./api/SpbAdminApi";
import spbKkt from "./api/SpbKktApi";
import { encryptForm } from "../helper/encryptForm";

class CreateShipment {
  // getListPo(params, payload) {
  //   const queryParams = params
  //     ? Object.keys(params)
  //         .map(
  //           (k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k])
  //         )
  //         .join("&")
  //     : "";
  //   return spborder.post(
  //     `/spb/spb_createshipmentnotice/listpo?${queryParams}`,
  //     payload
  //   );
  // }
  getListPo(payload) {
    return spborder.post(
      `/spb/spb_createshipmentnotice/listpo`, encryptForm(payload)
    );
  }
  getShipmentHeader(payload) {
    return spborder.get(
      `/spb/spb_createshipmentnotice/fetchSipmentHeader/${payload}`
    );
  }
  getShipmentHeaderSupp(id, vendorId) {
    return spborder.get(
      `/spb/spb_createshipmentnotice/fetchSipmentHeaderSupp?id=${id}&vendorId=${vendorId}`
    );
  }
  getShipmentItems(payload) {
    return spborder.get(
      `/spb/spb_createshipmentnotice/fetchSipmentItems/${payload}`
    );
  }
  getDocoumentTable(payload) {
    return spborder.get(
      `/spb/spb_createshipmentnotice/getDocumentTable/${payload}`
    );
  }
  getShipmentItemsShipment(payload) {
    return spborder.get(
      `/spb/spb_createshipmentnotice/fetchSipmentItemsShipment/${payload}`
    );
  }
  getIdDetailShipment(payload) {
    return spborder.post(
      `/spb/spb_createshipmentnotice/gettabledetailshipmentt/` + payload
    );
  }
  updateQuantityShipped(params, payload) {
    return spborder.put(
      `/spb/spb_createshipmentnotice/updateQuantityShipped/${params.poKeyId}/${params.poHeaderId}/${params.poLineId}`,
      payload
    );
  }

  getOpenPeriode(payload) {
    return spborder.post(
      `/spb/spb_createshipmentnotice/fetchOpenPeriode`,
      payload
    );
  }

  getDcoumentTypes(payload) {
    return spborder.post(
      `/spb/spb_createshipmentnotice/fetchDocumentTypes`,
      payload
    );
  }
  getDocuments(payload) {
    return spborder.get(
      `/spb/spb_createshipmentnotice/fetchDocuments/${payload}`
    );
  }
  sendFileDocument(payload) {
    return spborder.post(`/spb/spb_createshipmentnotice/uploadFile`, payload);
  }
  removeFileDocument(params) {
    return spborder.post(
      `/spb/spb_createshipmentnotice/removeFIle?headerId=${params.headerId}&asnDocId=${params.docId}`
    );
  }
  updateFileDocument(params, payload) {
    return spborder.post(
      `/spb/spb_createshipmentnotice/updateFile?headerId=${params.headerId}&asnDocId=${params.docId}`,
      payload
    );
  }
  downloadFileDocument(params) {
    return spborder.get(
      `/spb/spb_createshipmentnotice/downloadFile?headerId=${params.headerId}&asnDocId=${params.asnDocId}`
    );
  }
  downloadFileDocumentWithLevelTenant(params) {
    return spborder.get(
      `/spb/spb_createshipmentnotice/downloadFileWithLeveltenant?headerId=${params.headerId}&asnDocId=${params.asnDocId}&leveltenant=${params.leveltenant}`
    );
  }
  handleValidate(payload) {
    return spborder.post(
      `/spb/spb_createshipmentnotice/handleValidate`,
      payload
    );
  }
  getSearchDropdownSupp(payload) {
    return spborder.post("/spb/spb_createshipmentnotice/getsupsearch", payload);
  }

  //detail
  getDetail(payload) {
    return spborder.post(
      `/spb/spb_createshipmentnotice/getdetailcreateshipment/` + payload
    );
  }

  getDetailSupp(id, vendorId) {
    return spborder.post(
      `/spb/spb_createshipmentnotice/getdetailcreateshipmentsupp?id=${id}&vendorId=${vendorId}`
    );
  }

  // Submit Data
  submitAsnHeader(payload) {
    return spborder.post(
      `/spb/spb_createshipmentnotice/submitAsnHeader`,
      payload
    );
  }
  submitAsnLines(payload) {
    return spborder.post(
      `/spb/spb_createshipmentnotice/submitAsnLines`,
      payload
    );
  }

  //Save Data
  saveAsnHeader(payload) {
    return spborder.post(
      `/spb/spb_createshipmentnotice/saveAsnHeader`,
      payload
    );
  }
  saveAsnLines(payload) {
    return spborder.post(`/spb/spb_createshipmentnotice/saveAsnLines`, payload);
  }
  getRecipientMail(data) {
    return spbadmin.post("/spb/notification/getPlannoRecipientMailPur", data);
  }
  sendPONotifSupp(payload) {
    const url = `spb/notification/sendPOnotifsupp`;
    return spbadmin.post(url, payload);
  }
  sendPOEmail(data) {
    return spbKkt.post("adm/send/emailCSNToPur", data);
  }


  getGBPA(data){
    return spborder.post("/spb/gbpa/find-gbpa", data);
  }
}

export default new CreateShipment();
