<template>
	<Toast />
	<DataTable
		:value="tableList"
		:lazy="true"
		:paginator="true"
		:rows="10"
		:loading="loading"
		:totalRecords="totalData"
		@page="onPage($event)"
		@sort="onSort($event)"
		:rowsPerPageOptions="[10, 20, 30]"
		responsiveLayout="scroll"
		:scrollable="true"
		scrollHeight="600px"
		scrollDirection="both"
		removableSort
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
	>
		<template #header>
			<div>
				<span class="p-input-icon-left" style="col-12">
					<i class="pi pi-search" />
					<InputText
						v-model="invoiceFilter"
						placeholder="Search Invoice"
						@keydown.enter="keydown($event)"
					/>
				</span>
			</div>
		</template>
		<template #empty>No Invoice found</template>
		<template #loading>Loading data</template>
		<Column header="Company" field="NAME" style="width: 15%;"> </Column>
		<Column header="Supplier" field="vendor_name" style="width: 15%">
		</Column>
		<Column
			header="Invoice No"
			field="INVOICE_NO"
			:sortable="true"
			style="width: 20%"
		>
		</Column>
		<Column
			header="Invoice Amount"
			field="INVOICE_AMOUNT"
			style="width: 15%"
		>
		</Column>
		<Column
			header="Payment Method"
			field="PAYMENT_METHOD_LOOKUP_CODE"
			style="width: 10%"
		>
		</Column>
		<Column
			header="Service Amount"
			field="SERVICE_AMOUNT"
			style="width: 10%"
		>
		</Column>
		<Column header="Status" style="width: 10%">
			<template #body="{ data }">
				<Chip
					class="custom-chip"
					:class="`chip-${data.STATUS.toLowerCase()}`"
				>
					{{ data.STATUS }}
				</Chip>
			</template>
		</Column>
		<Column header="Tax No" field="TAX_NO" style="width: 15%"> </Column>
		<Column header="Tax Amount" field="TAX_AMOUNT" style="width: 15%">
		</Column>
		<Column
			header="Tax Date"
			field="TAX_DATE"
			:sortable="true"
			style="width: 15%"
		>
			<template #body="{ data }">
				{{ formatDate(data.TAX_DATE) }}
			</template>
		</Column>
		<Column header="Total Amount" field="TOTAL_AMOUNT" style="width: 15%">
		</Column>
		<Column
			class="call-button-container"
			header="Actions"
			selectionMode="single"
			style="width: 10%"
			alignFrozen="right"
			frozen
		>
			<template #body="slotProps">
				<Button
					icon="pi pi-eye"
					class="p-button-rounded p-button-text"
					@click="editChildren(slotProps.data)"
					v-tooltip="'Detail'"
				/>
			</template>
		</Column>
	</DataTable>
</template>

<script setup>
import { onMounted, ref, defineEmits, watchEffect, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
// import AuthService from '@/service/AuthService';
// import LovService from '@/service/LovService';
import ListInvoicenonPO from '../../../service/ListInvoiceNonPo';
// import PoFulfillment from '@/service/PoFulfillment';
const company = ref();
const supplier = ref();
const toast = useToast();
onMounted(async () => {
	company.value = props.company;
	supplier.value = props.supplier;

	await populateTable();

	// const info = await AuthService.whoAmi();
	// if (info.status === 200) {
	// 	levelTenant.value = info.data.data.leveltenant;
	// 	if (levelTenant.value !== 2) {
	// 		// loading.value = true
	// 		supplier.value = props.supplier;
	// 		await populateTable();
	// 	} else {
	// 		if (company.value && !supplier.value) {
	// 			const param = {
	// 				db_instance: props.company.db_instance,
	// 				org_id: props.company.value,
	// 			};
	// 			const respVendor = await PoFulfillment.getSuppBindComp(
	// 				info.data.data.id
	// 			);
	// 			const vendorName = respVendor.data.data[0][0].vendorname;
	// 			const respSuppName = await LovService.getSupplierName(param);
	// 			if (respSuppName.status === 200) {
	// 				const suppList = respSuppName.data.data;
	// 				for (const s of suppList) {
	// 					if (s.vendor_name === vendorName) {
	// 						supplier.value = s;
	// 						break;
	// 					}
	// 				}
	// 			}
	// 			await populateTable();
	// 		}
	// 		// loading.value = true
	// 	}
	// }
});
// eslint-disable-next-line no-undef
const props = defineProps({
	company: {
		type: Object,
	},
	supplier: {
		type: Object,
	},
	status: { type: Object },
	invoiceNumber: { type: Object },

});
const tableData = ref([]);
const tableList = ref();
const totalData = ref(0);
const loading = ref(false);
const page = ref(1);
const rowsPerPage = ref(10);
// const levelTenant = ref();
const invoiceFilter = ref();
const router = useRouter();
const emit = defineEmits(['loading']);
const search = ref();
const sortTable = ref(null);
const sortOrder = ref(null);
const searchValue = ref(0);


watch(() => props.company,(text) => {
	company.value = text;
})


watchEffect(() => emit('loading', loading));

const populateTable = async () => {
	if (company.value !== null && company.value !== undefined) {
		const payload = {
			db_instance: company.value.db_instance
				? company.value.db_instance
				: null,
			org_id: company.value ? company.value.value : null,
			suppValue: supplier.value ? supplier.value.vendor_id : null,
			status: props.status ? props.status.STATUS : null,
			invoice_no: props.invoiceNumber
				? props.invoiceNumber.INVOICE_NO
				: null,
			search: invoiceFilter.value ? invoiceFilter.value : null,
			page: page.value,
			rows: rowsPerPage.value,
			sort: sortTable.value ? sortTable.value : null,
			sortOrder: sortOrder.value ? sortOrder.value : null,
		};

		console.log("PAYLOAD", payload)
		loading.value = true;
		tableData.value = await ListInvoicenonPO.getTable(payload);
		if (tableData.value.status === 200) {
			tableList.value = tableData.value.data.data.data;
			totalData.value = tableData.value.data.data.totalData[0].totalData;
			loading.value = false;
			searchValue.value++;
		} else if (tableData.value.status === 444) {
			toast.add({
				severity: 'error',
				summary: 'Disconnected',
				detail: 'internet is unstable',
				life: 3000,
			});
			loading.value = false;
		} else {
			loading.value = false;
		}
	}
	// loading.value = false;
};
const onPage = async (event) => {
	loading.value = true;
	page.value = event.page + 1;
	rowsPerPage.value = event.rows;
	await populateTable();
};

const onSort = async (event) => {
	// console.log('isi event biatch', event)
	page.value = 1; // Reset page to 1
	rowsPerPage.value = event.rows;
	sortTable.value = event.sortField;
	sortOrder.value = event.sortOrder;
	if (searchValue.value > 0) {
		await populateTable();
	}
};

const editChildren = async (rowIndex) => {
	router.push({
		name: 'DetailListInvoiceNonPo',
		params: { id: rowIndex.EINVOICE_ID },
		query: { v: company.value.value, i: company.value.db_instance },
	});
};

const formatDate = (date) => {
	return new Intl.DateTimeFormat('id-ID', {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	}).format(new Date(date));
};

const keydown = async (event) => {
	search.value = event;
	if (search.value !== null) {
		await populateTable();
	}
};
</script>

<style scoped>
.p-chip.custom-chip {
	padding: 4px 12px;
	border-radius: 8px;
	font-size: 12px;
	font-weight: 600;
}

.chip-open {
	background: #0ea5e9;
	color: #fff;
}
.chip-rejected {
	background: var(--primary-color);
	color: #fff;
}
.chip-review {
	background: #f59e0b;
	color: #fff;
}
.chip-posted {
	background: #10b981;
	color: #fff;
}
.chip-processed {
	background: #2563eb;
	color: #fff;
}
</style>
